import { Col, Image, Row, Select, Typography } from "antd";
import classNames from "classnames";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import {
  ArrowDownOutlined,
  BoldAddCircleIcon,
  CheckedIcon,
  ImageMaterialDefault,
  SearchIcon,
} from "constants/icons.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./fnb-select-options.scss";
import { hasPermission } from "utils/helpers";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";

export function FnbSelectOptions({
  className = "",
  allowClear,
  value,
  options,
  addNewItem,
  isNameExisted,
  name,
  mode = undefined, // 'multiple' | 'tags'
  defaultValue,
  disabled,
  dropdownRender,
  placeholder,
  style,
  dropdownStyle,
  showSearch = true,
  onChange,
  onSelect,
  onFocus,
  onBlur,
  onSearch,
  searchValue,
  onClear,
  suffixIcon,
  prefixIcon = undefined,
  listHeight,
  multiLine = false,
  showThumbnail,
  fixed,
  noTranslateOptionName = false,
  placement,
  isShowSearchIcon = false,
  isEditProduct,
  isNameSelected = false,
  permission,
  ...rest
}) {
  const [t] = useTranslation();
  const [values, setValues] = useState();
  const classNameSelect = classNames({
    "fnb-select-admin": true,
    [`${className}`]: true,
    "fnb-select-admin--show-prefix-icon": prefixIcon !== undefined,
  });
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  const pageData = {
    add: t("button.add"),
    cannotFindOption: t("option.cannotFindOption"),
    addNewAsNewOption: t("option.addNewAsNewOption"),
    option: t("option.title"),
    topping: t("option.topping"),
    selectedOption: t("option.selectedOption"),
  };

  useEffect(() => {
    setValues(value);
  }, [value]);

  const filterOption = (input, option) => {
    const newOption = t(option?.label);
    const inputStr = input.removeVietnamese();
    const label = newOption?.removeVietnamese();
    return label?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
  };

  return (
    <>
      <Select
        {...rest}
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        className={`${classNameSelect} ${isShowSearchIcon ? "show-search-icon" : ""}`}
        placeholder={placeholder}
        allowClear={allowClear}
        value={value}
        mode={mode}
        defaultValue={defaultValue}
        disabled={disabled}
        style={style}
        dropdownStyle={dropdownStyle}
        showSearch={showSearch}
        onChange={onChange}
        onSelect={onSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        onClear={onClear}
        onSearch={onSearch}
        searchValue={searchValue}
        filterOption={filterOption}
        suffixIcon={
          suffixIcon || (
            <div className="arrow-down">
              <ArrowDownOutlined />
            </div>
          )
        }
        menuItemSelectedIcon={<CheckedIcon />}
        popupClassName={"fnb-select-dropdown-admin"}
        maxTagCount={multiLine ? "responsive" : ""}
        placement={placement}
        showArrow
        optionFilterProp="children"
        listItemHeight={0}
        listHeight={listHeight || 300}
        optionLabelProp={showThumbnail ? "label" : ""}
        dropdownRender={(menu) => (
          <>
            <Row align="middle" justify="center" className="dropdown-option-add">
              {name && !isNameExisted ? (
                <>
                  <Col span={24}>
                    <div className="empty-text">{pageData.cannotFindOption}</div>
                    {hasPermission(permission) && (
                      <Row align="middle" justify="center">
                        <div className="add-new-select w-100" onClick={addNewItem}>
                          <BoldAddCircleIcon className="icon-btn-add-new-select" />
                          <div className="keyword-add">
                            {pageData.addNewAsNewOption
                              .replace("{{name}}", name?.toLowerCase())
                              .replace("{{option}}", (pageData.option || t("option.title"))?.toLowerCase())}
                          </div>
                        </div>
                      </Row>
                    )}
                  </Col>
                </>
              ) : (
                (!options || options?.length === 0) && <FnbEmpty />
              )}
              {isNameSelected && name && (
                <>
                  <Col span={24}>
                    <div className="name-selected-text">
                      {t(pageData.selectedOption, {
                        name: name?.toLowerCase(),
                      })}
                    </div>
                  </Col>
                </>
              )}
              <Col span={24}>{menu}</Col>
            </Row>
          </>
        )}
      >
        {showThumbnail
          ? options?.map((item) => (
              <Select.Option
                key={item?.value}
                id={item?.value}
                name={item?.label}
                value={item?.value}
                label={item?.label}
                disabled={item?.disabled}
              >
                <div className="product-option-box">
                  <div className="img-box">
                    {item?.thumbnail ? <Image preview={false} src={item?.thumbnail} /> : <ImageMaterialDefault />}
                  </div>
                  <Typography ellipsis={true} className="product-name">
                    {item?.label}
                    {item?.isTopping && (
                      <div className="fnb-chip fnb-chip__option--default" style={{ marginLeft: 12 }}>
                        <Typography style={{ color: "#ff7d07" }}>{pageData.topping}</Typography>
                      </div>
                    )}
                  </Typography>
                </div>
              </Select.Option>
            ))
          : options?.map((item) => (
              <Select.Option
                key={item.value}
                id={item.value}
                name={item?.label}
                value={item.value}
                label={item?.label}
                disabled={item?.disabled}
              >
                <FnbTooltip
                  title={noTranslateOptionName ? item?.label : t(item?.label)}
                  maxWidth={"440px"}
                  onlyShowWhenEllipsis
                  disabledStopPropagation={isTouchDevice}
                  hideTooltip={isTouchDevice}
                >
                  <FnbTypography text={noTranslateOptionName ? item?.label : t(item?.label)} />
                </FnbTooltip>
              </Select.Option>
            ))}
      </Select>
      {isShowSearchIcon && <SearchIcon className={`fnb-select-search__icon-prefix`} />}
    </>
  );
}
