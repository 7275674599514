/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, DatePicker, Form, message, Radio, Row, TimePicker, Typography, Space } from "antd";
import { FnbSelectHyperlink } from "components/fnb-select-hyperlink/fnb-select-hyperlink";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";

import { DELAYED_TIME } from "constants/default.constants";
import { FnbUploadNoticationCampaignConstants } from "constants/ftn-upload-img-notification-campaign";
import { HYPERLINK_SELECT_OPTION } from "constants/hyperlink.constants";
import {
  CalendarNewIconBold,
  CheckboxCheckedIcon,
  HyperlinkNotificationListIcon,
} from "constants/icons.constants";

import { FnbCancelButton } from "components/cancel-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbTextAreaWithCount } from "components/fnb-text-area-with-count/fnb-text-area-with-count.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import {
  EnumResultCreateOrUpdate,
  ListNotificationCampaignSendByEvent,
  ListNotificationCampaignSendingType,
  NotificationCampaignSendingType,
} from "constants/notification-campaign.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { enumSendingType } from "constants/sending-type-notification-campaign";
import { DateFormat } from "constants/string.constants";
import notificationCampaignDataService from "data-services/notification-campaign/notification-campaign.service";
import debounce from "lodash/debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { getValidationMessages, isStoreInternational } from "utils/helpers";
import i18n from "utils/i18n";
import { Hyperlink } from "../../../../constants/hyperlink.constants";
import customerSegmentDataService from "../../../../data-services/customer-segment/customer-segment-data.service";
import "./create-notification-campaign.page.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import FnbCard from "components/fnb-card/fnb-card.component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";

export default function CreateNotificationCampaign() {
  const [t] = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const reduxState = useSelector((state) => state);
  const { Text } = Typography;
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isChangeFormGotIt, setIsChangeFormGotIt] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [sendingTypeSelected, setSendingTypeSelected] = useState(ListNotificationCampaignSendingType[0]?.key);
  const [image, setImage] = useState(null);
  const [hyperlink, setHyperlink] = useState(HYPERLINK_SELECT_OPTION[0]?.id);
  const [hyperlinkSelectOptions, setHyperlinkSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerSegmentInSore, setCustomerSegmentInStore] = useState([]);
  const [requestingCustomerSegmentInStore, setRequestingCustomerSegmentInStore] = useState(false);
  const [isAllCustomersChecked, setIsAllCustomersChecked] = useState(true);
  const [selectedCustomerSegments, setSelectedCustomerSegments] = useState({
    isShowCount: false,
    customerSegmentCount: 0,
    customerCount: 0,
  });
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [searchValue, setSearchValue] = useState("");
  const [textAreaCount, setTextAreaCount] = useState(0);
  const isInternational = isStoreInternational();

  const pageData = {
    btnCancel: t("button.cancel"),
    btnAddNew: t("button.create"),

    title: t("marketing.notificationCampaign.titleMessage"),
    titleCreate: t("marketing.notificationCampaign.titleCreate"),
    campaignName: t("marketing.notificationCampaign.campaignName"),
    enterCampaignName: t("marketing.notificationCampaign.enterCampaignName"),
    pleaseEnterCampaignName: t("marketing.notificationCampaign.pleaseEnterCampaignName"),
    titleEventType: t("marketing.notificationCampaign.eventType.title"),
    installTheAppEvent: t("marketing.notificationCampaign.eventType.installTheAppEvent"),
    titleSendingType: t("marketing.notificationCampaign.sendingType.title"),
    sendByEvent: t("marketing.notificationCampaign.sendingType.sendByEvent"),
    sendBySpecificTime: t("marketing.notificationCampaign.sendingType.sendBySpecificTime"),
    sendNow: t("marketing.notificationCampaign.sendingType.sendNow"),
    sendNotificationIn: t("marketing.notificationCampaign.sendNotificationIn"),
    hoursAfterTheEvent: t("marketing.notificationCampaign.hoursAfterTheEvent"),
    pleaseEnterHour: t("marketing.notificationCampaign.pleaseEnterHour"),
    message: t("marketing.notificationCampaign.message"),
    enterTitle: t("marketing.notificationCampaign.enterTitle"),
    pleaseEnterTitle: t("marketing.notificationCampaign.pleaseEnterTitle"),
    messageContent: t("marketing.notificationCampaign.messageContent"),
    enterMessageContent: t("marketing.notificationCampaign.enterMessageContent"),
    pleaseEnterContent: t("marketing.notificationCampaign.pleaseEnterContent"),
    image: t("marketing.notificationCampaign.image"),
    hyperlink: t("marketing.notificationCampaign.hyperlink"),
    pleaseSelectHyperlink: t("marketing.notificationCampaign.pleaseSelectHyperlink"),
    generalInformation: t("title.generalInformation"),
    guideline: {
      title: t("marketing.notificationCampaign.guideline.title"),
      content: t("marketing.notificationCampaign.guideline.content"),
    },
    imageSizeTooBig: "marketing.notificationCampaign.imageSizeTooBig",
    messageErrorFormat: "marketing.notificationCampaign.acceptFileImageTypes",
    date: t("marketing.notificationCampaign.date"),
    sendingTime: t("marketing.notificationCampaign.sendingTime"),
    selectDate: t("marketing.notificationCampaign.selectDate"),
    selectTime: t("marketing.notificationCampaign.selectTime"),
    pleaseSelectDate: t("marketing.notificationCampaign.pleaseSelectDate"),
    pleaseSelectStartTime: t("marketing.notificationCampaign.pleaseSelectStartTime"),
    createSuccessfullyMessage: t("marketing.notificationCampaign.createSuccessfullyMessage"),
    createIsNotSuccessfullyMessage: t("marketing.notificationCampaign.createIsNotSuccessfullyMessage"),
    pleaseInputFirebaseCredential: t("marketing.notificationCampaign.pleaseInputFirebaseCredential"),
    notyetConfigNotification: t("marketing.notificationCampaign.systemNotyetConfig"),
    pleaseSelectTimeFrameHasBecomePast: t("discountCode.formCreate.pleaseSelectTimeframeHasBecomePast"),
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    upload: {
      addFromUrl: t("upload.addFromUrl"),
      addFile: t("upload.addFile"),
    },
    bestDisplayImage: t("messages.imageBestDisplay"),
    discardBtn: t("button.discard"),
    ignoreBtn: t("button.ignore"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("form.notificationTitle"),
      content: t("marketing.notificationCampaign.leaveForm"),
    },
    buttonIGotIt: t("form.buttonIGotIt"),
    customer: {
      allCustomers: t("marketing.notificationCampaign.customer.allCustomers"),
      selectCustomerGroups: t("marketing.notificationCampaign.customer.selectCustomerGroups"),
      selectCustomerGroupsRequiredMessage: t(
        "marketing.notificationCampaign.customer.selectCustomerGroupsRequiredMessage",
      ),
    },
  };

  useEffect(() => {
    if (isInternational) {
      history.push("/home");
    }
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const initData = () => {
    form.setFieldsValue({
      name: "",
      sendNotificationTime: 1,
      specificTime: null,
      title: "",
      content: "",
      thumbnail: "",
      hyperlinkOption: HYPERLINK_SELECT_OPTION[0]?.id,
      url: "",
      statusId: null,
      sendingTypeId: ListNotificationCampaignSendingType[0]?.key,
      event: ListNotificationCampaignSendByEvent[0]?.key,
      isAllCustomers: isAllCustomersChecked,
      customerSegmentIds: [],
    });

    setHyperlinkSelectOptions([
      HYPERLINK_SELECT_OPTION[0],
      {
        id: Hyperlink.MY_NOTIFICATION,
        name: "menuManagement.menuItem.hyperlink.notificationList",
        icon: <HyperlinkNotificationListIcon />,
      },
    ]);

    void initCustomerSegmentList();
  };

  const initCustomerSegmentList = async () => {
    setRequestingCustomerSegmentInStore(true);
    const customerSegmentListResult = await customerSegmentDataService.getCustomerSegmentByStoreIdAsync();
    setCustomerSegmentInStore(customerSegmentListResult);
    setRequestingCustomerSegmentInStore(false);
  };

  const countDistinctObjectInArray = (arrayDistinct, array) => {
    array?.forEach((y) => {
      if (!arrayDistinct.includes(y)) {
        arrayDistinct.push(y);
      }
    });
    return arrayDistinct;
  };

  const onUpdateCustomerSegment = (values) => {
    const selectedCustomersSegment = customerSegmentInSore?.filter((x) => values.indexOf(x.id) > -1);

    const distinctCustomers = [];
    selectedCustomersSegment?.forEach((x) => {
      if (x?.customers?.length > 0) {
        countDistinctObjectInArray(distinctCustomers, x.customers);
      }
    });

    const formGeneralTabFieldsValue = { ...form.getFieldsValue() };
    const formGeneralTabFieldsValueEdit = { ...formGeneralTabFieldsValue, customerSegmentIds: values };
    form.setFieldsValue({ formGeneralTabFieldsValueEdit });

    setSelectedCustomerSegments({
      isShowCount: values?.length > 0,
      customerSegmentCount: values?.length ?? 0,
      customerCount: distinctCustomers?.length ?? 0,
    });
  };

  const onChangeAllCustomersCheckBox = useCallback((e) => {
    setIsAllCustomersChecked(e.target.checked);
    form?.validateFields(["customerSegmentIds"])?.then()?.catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAllCustomersCheckboxDisabled = useMemo(() => {
    let formValue = form?.getFieldsValue();
    let { sendingTypeId, event } = formValue;
    return (
      (sendingTypeId === 0 && event === 0) ||
      (sendingTypeId === 0 && !event) ||
      (sendingTypeSelected === 0 && event === 0) ||
      (sendingTypeSelected === 0 && !event)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.getFieldsValue(), sendingTypeSelected]);

  const onClickCreatePage = async () => {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        var selectDateTime = null;
        let currentDateTime = new Date();
        if (values?.sendingTypeId === enumSendingType.SendBySpecificTime) {
          selectDateTime = new Date(
            moment(values?.selectDate).year(),
            moment(values?.selectDate).month(),
            moment(values?.selectDate).date(),
            moment(values?.selectTime).hours(),
            moment(values?.selectTime).minutes(),
            moment(values?.selectTime).seconds(),
          );

          if (selectDateTime < currentDateTime) {
            message.error(pageData.pleaseSelectTimeFrameHasBecomePast);
            setIsLoading(false);
            return;
          }
        }

        let imageUrl = image?.url;
        if (!Boolean(imageUrl)) {
          imageUrl = reduxState?.session?.storeLogo;
        }

        let isAllCustomers = values?.isAllCustomers;
        if (
          (values?.sendingTypeId === ListNotificationCampaignSendingType[0]?.key &&
            values?.event === ListNotificationCampaignSendByEvent[0]?.key) ||
          (values?.sendingTypeId === ListNotificationCampaignSendingType[0]?.key && !values?.event)
        ) {
          isAllCustomers = true;
        }

        let request = {
          ...values,
          hyperlinkOption: hyperlink,
          sendingTypeId: sendingTypeSelected,
          thumbnail: imageUrl,
          specificTime: selectDateTime,
          isAllCustomers: isAllCustomers,
        };

        try {
          let res = await notificationCampaignDataService.createNotificationCampaignAsync(request);

          if (res === EnumResultCreateOrUpdate.Success) {
            message.success(pageData.createSuccessfullyMessage);
            redirectToNotificationCampaign();
          } else if (res === EnumResultCreateOrUpdate.NotFirebaseCredential) {
            redirectToNotificationCampaignGotIt();
          } else {
            message.error(pageData.createIsNotSuccessfullyMessage);
          }
          setIsLoading(false);
        } catch (errs) {
          form.setFields(getValidationMessages(errs));
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };
  const onDiscardGotIt = () => {
    setIsChangeFormGotIt(false);
  };

  const clickCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      redirectToNotificationCampaign();
    }
  };

  const redirectToNotificationCampaign = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/marketing/notification-campaign");
    }, DELAYED_TIME);
  };

  const redirectToNotificationCampaignGotIt = () => {
    setIsChangeFormGotIt(true);
    setTimeout(() => {
      history.push("/marketing/notification-campaign");
    }, DELAYED_TIME);
  };

  const onChangeHyperlink = (id) => {
    setHyperlink(id);
    let formValue = form.getFieldsValue();
    formValue.url = null;
    form.setFieldsValue(formValue);
  };

  const setTime = (value) => {
    form.setFieldValue("selectTime", value);
    form.setFields([
      {
        name: "selectTime",
        errors: [],
      },
    ]);
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  /**
   * Disable Hour From Start Time
   */

  const getListPreviousHourNumberFromNow = () => {
    var hours = [];
    let formValue = form.getFieldsValue();

    let { selectDate } = formValue;
    var selectDateForUser = new Date(moment(selectDate).year(), moment(selectDate).month(), moment(selectDate).date());
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (selectDateForUser.toDateString() === currentDate.toDateString()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  /**
   * Disable Hour Minute From Start Time
   * @param {*} selectedHour
   */

  const getListPreviousMinutesNumberFromNow = (selectedHour) => {
    var minutes = [];
    let formValue = form.getFieldsValue();
    let { selectDate } = formValue;
    var selectDateForUser = new Date(moment(selectDate).year(), moment(selectDate).month(), moment(selectDate).date());
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (selectDateForUser.toDateString() === currentDate.toDateString()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  const handleChangeSendType = (value) => {
    setSendingTypeSelected(value);
  };

  const onChangeImage = (file) => {
    setImage(file);
  };

  const renderSendByEvent = () => {
    return (
      <Row className="w-100">
        <Col span={24}>
          <Form.Item
            name={"event"}
            label={pageData.titleEventType}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <FnbSelectSingle
              option={ListNotificationCampaignSendByEvent?.map((item) => ({
                id: item.key,
                name: t(item.name),
              }))}
              showSearch
              className="w-100"
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderSendBySpecificTime = () => {
    return (
      <Row className="w-100" gutter={12}>
        <Col xs={14} lg={8}>
          <Form.Item
            name={"selectDate"}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseSelectDate} />,
              },
            ]}
            label={pageData.sendingTime}
          >
            <DatePicker
              suffixIcon={<CalendarNewIconBold />}
              placeholder={pageData.selectDate}
              className="fnb-date-picker w-100"
              format={DateFormat.DD_MM_YYYY}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Col xs={8} lg={3} className="d-flex align-items-end">
          <Form.Item
            name={"selectTime"}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseSelectStartTime} />,
              },
            ]}
          >
            <TimePicker
              className="fnb-date-picker time-clock-picker"
              dropdownClassName="fnb-date-time-picker-dropdown fnb-date-time-picker-dropdown-style"
              format={"HH:mm"}
              placeholder={pageData.selectTime}
              onSelect={(time) => {
                setTime(moment(time, "HH:mm"));
              }}
              disabledHours={getListPreviousHourNumberFromNow}
              disabledMinutes={getListPreviousMinutesNumberFromNow}
              style={{ width: "150px" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderSendingType = () => {
    switch (sendingTypeSelected) {
      case NotificationCampaignSendingType.SendByEvent:
        return renderSendByEvent();
      case NotificationCampaignSendingType.SendBySpecificTime:
        return renderSendBySpecificTime();
      default:
        return <></>;
    }
  };

  return (
    <>
      <Form
        onFinish={debounce(async () => {
          await onClickCreatePage();
        }, 1000)}
        form={form}
        onFieldsChange={() => setIsChangeForm(true)}
        layout="vertical"
        autoComplete="off"
        className="create-notification-campaign custom-form"
      >
        <FnbHeadingPage
          title={pageData.titleCreate}
          guideline={pageData.guideline}
          listButtons={[<FnbCancelButton onOk={clickCancel} />]}
          permissionCreate={PermissionKeys.CREATE_NOTIFICATION_CAMPAIGN}
          typeBtnCreate="submit"
        />
        <Row>
          <FnbCard title={pageData.generalInformation} className="w-100">
            <>
              <Space direction="vertical" size={24} className="w-100">
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.pleaseEnterCampaignName} />,
                    },
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                  className="w-100"
                  label={pageData.campaignName}
                >
                  <FnbInput
                    className="fnb-input-with-count"
                    showCount
                    maxLength={100}
                    placeholder={pageData.enterCampaignName}
                  />
                </Form.Item>
                <Form.Item name={"sendingTypeId"} label={pageData.titleSendingType}>
                  <Radio.Group
                    onChange={(e) => handleChangeSendType(e.target.value)}
                    defaultValue={sendingTypeSelected}
                  >
                    {ListNotificationCampaignSendingType?.map((item, index) => {
                      let name = t(item?.name);
                      return (
                        <>
                          <Radio key={item?.key} value={item?.key}>
                            {name}
                          </Radio>
                          {isMobile &&
                            item?.key === NotificationCampaignSendingType.SendByEvent &&
                            sendingTypeSelected === NotificationCampaignSendingType.SendByEvent &&
                            renderSendByEvent()}
                          {isMobile &&
                            item?.key === NotificationCampaignSendingType.SendBySpecificTime &&
                            sendingTypeSelected === NotificationCampaignSendingType.SendBySpecificTime &&
                            renderSendBySpecificTime()}
                        </>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
                <Row>{!isMobile && renderSendingType()}</Row>
                {!isAllCustomersCheckboxDisabled && (
                  <div style={{ marginBottom: 12 }}>
                    <Form.Item name={"isAllCustomers"} valuePropName="checked">
                      <FnbCheckBox onChange={onChangeAllCustomersCheckBox}>
                        {pageData.customer.allCustomers}
                      </FnbCheckBox>
                    </Form.Item>
                    <Form.Item
                      className="select-control create-notification-campaign-multiple-selection"
                      name="customerSegmentIds"
                      validateStatus={isAllCustomersChecked ? "success" : "validating"}
                      rules={[
                        {
                          required: !isAllCustomersChecked,
                          message: (
                            <InputValidateMessage message={pageData.customer.selectCustomerGroupsRequiredMessage} />
                          ),
                        },
                        {
                          validator: async () => {
                            if (!isAllCustomersChecked) {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <FnbSelectMultiple
                            getPopupContainer={(trigger) => trigger.parentNode}
                            onChange={(e) => onUpdateCustomerSegment(e)}
                            menuItemSelectedIcon={<CheckboxCheckedIcon />}
                            placeholder={pageData.customer.selectCustomerGroups}
                            optionFilterProp="children"
                            showArrow
                            showSearch={true}
                            allowClear={true}
                            id="fnb-select-multiple-customer-segment"
                            option={customerSegmentInSore?.map((item) => ({
                              key: item.id,
                              id: item.id,
                              name: item.name,
                            }))}
                            disabled={isAllCustomersChecked}
                            loading={requestingCustomerSegmentInStore}
                            hideItemSelected={isAllCustomersChecked}
                      >

                      </FnbSelectMultiple>
                    </Form.Item>
                    {!isAllCustomersChecked && selectedCustomerSegments?.isShowCount && (!searchValue || searchValue?.length <= 0) && (
                      <div style={{ marginTop: 6 }}>
                        <span
                          className="fnb-typography__type--b1-regular"
                          dangerouslySetInnerHTML={{
                            __html: `${t("marketing.notificationCampaign.customer.selectedXSegmentsYCustomers", {
                              totalSegment: selectedCustomerSegments?.customerSegmentCount,
                              totalCustomer: selectedCustomerSegments?.customerCount,
                            })}`,
                          }}
                        ></span>
                      </div>
                    )}    
                  </div>
                )}
              </Space>
            </>
          </FnbCard>
        </Row>
        <Row className="mt-3">
          <FnbCard title={pageData.message} className="w-100">
            <Row gutter={[12, 24]}>
              <Col sm={24} lg={16}>
                <Space className="w-100" direction="vertical" size={24}>
                  <Form.Item
                    name={"title"}
                    rules={[
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.pleaseEnterTitle} />,
                      },
                      {
                        type: "string",
                        max: 50,
                      },
                    ]}
                    className="w-100"
                    label={pageData.title}
                  >
                    <FnbInput showCount maxLength={50} placeholder={pageData.enterTitle} />
                  </Form.Item>
                  <Form.Item
                    name={"content"}
                    className="w-100"
                    rules={[
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.pleaseEnterContent} />,
                      },
                      {
                        type: "string",
                        max: 255,
                      },
                    ]}
                    label={pageData.messageContent}
                  >
                    <FnbTextAreaWithCount
                      textAreaCount={textAreaCount}
                      placeholder={pageData.enterMessageContent}
                      maxLength={255}
                      rows={4}
                      onChange={(event) => {
                        setTextAreaCount(event.target.value.length);
                      }}
                    ></FnbTextAreaWithCount>
                  </Form.Item>
                  <Row className="w-100 select-hyperlink">
                    <Col sm={24} xs={24} lg={24}>
                      <Form.Item name={"hyperlinkOption"} label={pageData.hyperlink}>
                        <FnbSelectHyperlink
                          showSearch
                          fixed
                          defaultValue={hyperlink}
                          onChange={onChangeHyperlink}
                          option={hyperlinkSelectOptions}
                          className="create-notification"
                          showIcon={false}
                        ></FnbSelectHyperlink>
                      </Form.Item>
                    </Col>
                  </Row>
                </Space>
              </Col>
              <Col sm={24} lg={8} className="margin-image">
                <FnbTypography text={pageData.image} variant={theme.typography["b1-medium"]} />
                <Row className={`non-image ${image !== null ? "have-image" : ""}`} style={{ marginTop: 8 }}>
                  <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
                    <div style={{ display: "flex" }}>
                      <FnbUploadImageComponent
                        buttonText={pageData.upload.addFile}
                        onChange={onChangeImage}
                        maxFileSize={1048576}
                        messageTooBigSize={pageData.imageSizeTooBig}
                        messageErrorFormat={pageData.messageErrorFormat}
                        acceptType={FnbUploadNoticationCampaignConstants.ACCEPT_TYPES}
                      />
                      <a className="upload-image-url" hidden={image !== null ? true : false}>
                        {pageData.upload.addFromUrl}
                      </a>
                    </div>
                  </Col>
                  <Col span={24} className="text-non-image" hidden={image !== null ? true : false}>
                    <Text disabled>{pageData.media.textNonImage}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FnbCard>
        </Row>
      </Form>
      <ConfirmDialogComponent
        visible={showConfirm}
        skipPermission={true}
        onCancel={onDiscard}
        onOk={redirectToNotificationCampaign}
      />
      <ConfirmDialogComponent
        visible={isChangeFormGotIt}
        skipPermission={true}
        onOk={onDiscardGotIt}
        type={ConfirmStyle.NOTIFICATION}
      />
    </>
  );
}
