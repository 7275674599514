import { hasPermission } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { FnbButton } from "components/fnb-button/fnb-button";

export function FnbEditButton({ className, onClick, permission, disabled, iconHeader, text, htmlType, idControl }) {
  const [t] = useTranslation();

  return (
    <>
      {!permission || hasPermission(permission) ? (
        <FnbButton
          iconHeader={iconHeader}
          className={`${className}`}
          onClick={onClick}
          type={htmlType}
          disabled={disabled}
          id={idControl}
          text={text ?? t("button.update")}
        />
      ) : (
        <></>
      )}
    </>
  );
}
