import { Typography } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { useEffect, useState } from "react";
import { formatDate, formatShortDate, getCurrency } from "utils/helpers";
import "./detail-purchase-order-history.page.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import purchaseOrderHistoryDataService from "data-services/purchase-order-history/purchase-order-history-data.service";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { PurchaseOrderStatus } from "constants/purchase-order-status.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbBreadcrumb } from "components/fnb-breadcrumb/fnb-breadcrumb";
import { DateFormat } from "constants/string.constants";
import { useMediaQuery } from "react-responsive";
import { actionType } from "constants/staff-activities.constants";
const { Text } = Typography;

export default function DetailPurchaseOrderHistory({ isShowModal = false, id, t, handleCancel }) {
  const [purchaseOrderHistoryBefore, setPurchaseOrderHistoryBefore] = useState({});
  const [purchaseOrderMaterialsBefore, setPurchaseOrderMaterialsBefore] = useState([]);
  const [purchaseOrderHistoryAfter, setPurchaseOrderHistoryAfter] = useState({});
  const [purchaseOrderMaterialsAfter, setPurchaseOrderMaterialsAfter] = useState([]);
  const [lastedUpdateBy, setLastedUpdateBy] = useState("");
  const [lastedUpdateTime, setLastedUpdateTime] = useState(null);
  const [lastedActionId, setLastedActionId] = useState(0);
  const pageData = {
    backTo: t("button.backTo"),
    title: t("purchaseOrderHistory.history"),
    edit: t("button.edit"),
    print: t("button.print"),
    approve: t("button.approve"),
    order: t("button.order"),
    complete: t("button.complete"),
    cancel: t("button.cancel"),
    option: t("button.option"),
    generalInfo: t("settings.createGroupPermission.titleGeneralInformation"),
    approvePurchaseOrderSuccess: t("messages.approvePurchaseOrderSuccess"),
    orderPurchaseOrderSuccess: t("messages.orderPurchaseOrderSuccess"),
    cancelPurchaseOrderSuccess: t("messages.cancelPurchaseOrderSuccess"),
    completePurchaseOrderSuccess: t("messages.completePurchaseOrderSuccess"),
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      materialName: t("table.materialName"),
      material: t("material.title"),
      quantity: t("table.quantity"),
      importUnit: t("table.importUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseEnterNameUnit"),
      importPrice: t("table.importPrice"),
      total: t("table.total"),
      totalCost: t("table.totalCost"),
      action: t("table.action"),
      inventory: t("table.inventory"),
      branchName: t("table.branchName"),
    },
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("purchaseOrder.status.approved"),
      complete: t("purchaseOrder.status.completed"),
      cancel: t("purchaseOrder.status.canceled"),
      ordering: t("purchaseOrder.status.ordering"),
    },
    supplier: t("supplier.title"),
    destinationLabel: t("purchaseOrder.destinationLabel"),
    note: t("form.note"),
    materialInformation: t("purchaseOrder.materialInformation"),
    confirmCancel: t("leaveDialog.confirmCancel"),
    ignore: t("button.ignore"),
    cancelPurchaseOrder: t("purchaseOrder.cancelPurchaseOrder"),
    viewHistory: t("button.viewHistory"),
    moreAction: t("button.moreAction"),
    before: t("purchaseOrderHistory.before"),
    after: t("purchaseOrderHistory.after"),
    viewDetail: t("purchaseOrderHistory.viewDetail"),
    lastUpdateBy: t("purchaseOrderHistory.lastUpdateBy"),
    action: t("purchaseOrderHistory.action"),
    time: t("purchaseOrderHistory.time"),
    actionType: {
      created: t("purchaseOrderHistory.actionType.created"),
      updateStatus: t("purchaseOrderHistory.actionType.updateStatus"),
      cancelled: t("purchaseOrderHistory.actionType.cancelled"),
      edited: t("purchaseOrderHistory.actionType.edited"),
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  const fetchData = async () => {
    await purchaseOrderHistoryDataService.getDetailPurchaseOrderHistoryAsync(id).then((res) => {
      if (res) {
        const { purchaseOrderHistoriesBefore, purchaseOrderHistoriesAfter, lastedUpdateBy, lastedUpdateTime, lastedActionId } = res;
        setPurchaseOrderHistoryBefore(purchaseOrderHistoriesBefore);
        if (purchaseOrderHistoriesBefore) {
          let dataSourceBefore = mappingToDataTable(purchaseOrderHistoriesBefore.purchaseOrderMaterialHistories);
          setPurchaseOrderMaterialsBefore(dataSourceBefore);
        }
        setPurchaseOrderHistoryAfter(purchaseOrderHistoriesAfter);
        let dataSourceAfter = mappingToDataTable(purchaseOrderHistoriesAfter.purchaseOrderMaterialHistories);
        setPurchaseOrderMaterialsAfter(dataSourceAfter);
        setLastedActionId(lastedActionId);
        setLastedUpdateBy(lastedUpdateBy);
        setLastedUpdateTime(lastedUpdateTime);
      }
    });
  };

  const mappingToDataTable = (data) => {
    let materials = [];
    data?.map((item, index) => {
      let material = {
        no: index + 1,
        sku: item?.sku,
        materialId: item?.materialId,
        materialName: item?.materialName,
        quantity: item?.quantity,
        unitName: item?.unitName,
        price: item?.price,
        total: item?.total,
      };
      materials.push(material);
    });
    return materials;
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "center",
        width: "5rem",
      },
      {
        title: pageData.table.sku,
        dataIndex: "sku",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <FnbTooltip onlyShowWhenEllipsis>{record?.sku}</FnbTooltip>
            </>
          );
        },
      },
      {
        title: pageData.table.materialName,
        dataIndex: "material",
        align: "left",
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis title={record?.materialName} maxWidthContent={300}>
              <FnbTypography.Link
                to={`/inventory/material/detail/${record?.materialId}`}
                target="_blank"
                text={record?.materialName}
              />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.quantity,
        dataIndex: "quantity",
        width: "15%",
        align: "center",
      },
      {
        title: pageData.table.importUnit,
        dataIndex: "unitName",
        width: "15%",
        align: "center",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis maxWidthContent={300}>{record?.unitName}</FnbTooltip>;
        },
      },
      {
        title: `${pageData.table.importPrice} (${getCurrency()})`,
        dataIndex: "price",
        align: "center",
        width: "15%",
        render: (_, record) => {
          return <>{record?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>;
        },
      },
      {
        title: pageData.table.total,
        dataIndex: "total",
        align: "center",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis>{record?.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</FnbTooltip>;
        },
      },
    ];
    return columns;
  };

  const renderStatus = (status) => {
    switch (status) {
      case PurchaseOrderStatus.Approved:
        return <FnbBadge variant="primary" text={pageData.status.approved} />;
      case PurchaseOrderStatus.Canceled:
        return <FnbBadge variant="error" text={pageData.status.cancel} />;
      case PurchaseOrderStatus.Completed:
        return <FnbBadge variant="success" text={pageData.status.complete} />;
      case PurchaseOrderStatus.Ordering:
        return <FnbBadge variant="warning" text={pageData.status.ordering} />;
      default:
        return <FnbBadge variant="new" text={pageData.status.new} />;
    }
  }

  const renderAction = (action) => {
    switch (action) {
      case actionType.Created:
        return <FnbTypography text={pageData.actionType.created} />;
      case actionType.UpdateStatus:
        return <FnbTypography text={pageData.actionType.updateStatus} />;
      case actionType.Cancelled:
        return <FnbTypography text={pageData.actionType.cancelled} />;
      case actionType.Edited:
        return <FnbTypography text={pageData.actionType.edited} />;
      default:
        return <></>;
    }
  }

  return (
    <FnbModal closable={true} titleCustom={<FnbBreadcrumb
      items={[
        {
          onClick: handleCancel,
          title: <FnbTooltip onlyShowWhenEllipsis className="detail-history-title"><span>{pageData.title}</span></FnbTooltip>,
        },
        {
          title: <FnbTooltip maxWidthContent={isMobile ? "calc(100% - 55px)" : "100%"} onlyShowWhenEllipsis title={<Text className="purchase-order-code">{pageData.viewDetail} {purchaseOrderHistoryAfter?.code} {formatShortDate(formatDate(purchaseOrderHistoryAfter?.createdTime, DateFormat.YYYY_MM_DD))}</Text>} />,
        },
      ]}
    />} width={"90vw"} hideFooter className="detail-purchase-order-page-history" visible={isShowModal} handleCancel={handleCancel} content={
      <>
        <div className="card-summary">
          <div className="label-item">
            <div className="label">
              <FnbTypography text={`${pageData.lastUpdateBy}: `} />
            </div>
            <div className="content">
              <FnbTypography text={purchaseOrderHistoryAfter?.createdBy} />
            </div>
          </div>
          <div className="label-item">
            <div className="label">
              <FnbTypography text={`${pageData.action}: `} />
            </div>
            <div className="content">
              {renderAction(purchaseOrderHistoryAfter?.actionId)}
            </div>
          </div>
          <div className="label-item">
            <div className="label">
              <FnbTypography text={`${pageData.time}: `} />
            </div>
            <div className="content">
              {lastedUpdateTime && <FnbTypography text={formatDate(purchaseOrderHistoryAfter?.createdTime, DateFormat.HH_MM_DD_MM_YYYY_)} />}
            </div>
          </div>
        </div>
        <div className="card-parent">

          <FnbTypography className="title big-size" text={pageData.before} style={{ textAlign: "center" }} />
          <div className="card-po">
            {purchaseOrderHistoryBefore && <>
              <FnbCard className="card-general-info">
                <div className="general-info">
                  <div className="title-info">
                    <div>
                      <FnbTypography className="title" text={pageData.generalInfo} />
                      <hr className="title-hr" />
                    </div>
                    {purchaseOrderHistoryBefore?.statusId && renderStatus(purchaseOrderHistoryBefore?.statusId)}
                  </div>
                  <div className="label-item">
                    <div className="label">
                      <FnbTypography text={`${pageData.supplier}:`} />
                    </div>
                    <div className="content">
                      <FnbTypography.Link to={`/inventory/supplier/${purchaseOrderHistoryBefore?.supplierId}`} text={purchaseOrderHistoryBefore?.supplierName} />
                    </div>
                  </div>
                  <div className="label-item">
                    <div className="label">
                      <FnbTypography text={`${pageData.destinationLabel}:`} />
                    </div>
                    <div className="content">
                      <FnbTooltip onlyShowWhenEllipsis={true}>{purchaseOrderHistoryBefore?.storeBranchName}</FnbTooltip>
                    </div>
                  </div>
                  <div className="label-item">
                    <div className="label">
                      <FnbTypography text={`${pageData.note}:`} />
                    </div>
                    <div className="content text-area">
                      <FnbTypography text={purchaseOrderHistoryBefore?.note} />
                    </div>
                  </div>
                </div>
              </FnbCard>
              <FnbCard className="mt-1 card-material-info">
                <FnbTypography className="title" text={pageData.materialInformation} />
                <hr className="title-hr" />
                <FnbTable dataSource={purchaseOrderMaterialsBefore} columns={columnsMaterial()} />
              </FnbCard></>}
          </div>
        </div>
        <div className="card-parent">
          <FnbTypography className="title big-size" text={pageData.after} style={{ textAlign: "center" }} />
          <div className="card-po">
            <FnbCard className="card-general-info">
              <div className="general-info">
                <div className="title-info">
                  <div>
                    <FnbTypography className="title" text={pageData.generalInfo} />
                    <hr className="title-hr" />
                  </div>
                  {renderStatus(purchaseOrderHistoryAfter?.statusId)}
                </div>
                <div className="label-item">
                  <div className="label">
                    <FnbTypography text={`${pageData.supplier}:`} />
                  </div>
                  <div className="content">
                    <FnbTypography.Link to={`/inventory/supplier/${purchaseOrderHistoryAfter?.supplierId}`} text={purchaseOrderHistoryAfter?.supplierName} />
                  </div>
                </div>
                <div className="label-item">
                  <div className="label">
                    <FnbTypography text={`${pageData.destinationLabel}:`} />
                  </div>
                  <div className="content">
                    <FnbTooltip onlyShowWhenEllipsis={true}>{purchaseOrderHistoryAfter?.storeBranchName}</FnbTooltip>
                  </div>
                </div>
                <div className="label-item">
                  <div className="label">
                    <FnbTypography text={`${pageData.note}:`} />
                  </div>
                  <div className="content text-area">
                    <FnbTypography text={purchaseOrderHistoryAfter?.note} />
                  </div>
                </div>
              </div>
            </FnbCard>
            <FnbCard className="mt-1 card-material-info">
              <FnbTypography className="title" text={pageData.materialInformation} />
              <hr className="title-hr" />
              <FnbTable dataSource={purchaseOrderMaterialsAfter} columns={columnsMaterial()} />
            </FnbCard>
          </div>
        </div>
      </>
    } />
  );
}
