import http from "../../utils/http-common";

const controller = "referralPoint";

const activeReferralPointAsync = (data) => {
  return http.put(`/${controller}/active`, data);
};

const referralPointConfigDataService = {
  activeReferralPointAsync,
};
export default referralPointConfigDataService;
