import { Region } from "constants/region.constants";
import { env } from "env";
import { useEffect, useLayoutEffect } from "react";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";

// This function creates a script element and loads Google Analytics with the provided Measurement ID
function loadGA4Script(measurementId) {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", measurementId);
}

const GoogleAnalytics = () => {
  const history = useHistory();
  const isVietNam = Region.Local === env.REACT_APP_REGION;
  const GoogleAnalyticKeys = {
    VN_GA4_ID: env.REACT_APP_VN_GA4_ID,
    INTERNATIONAL_GA4_ID: env.REACT_APP_INTERNATIONAL_GA4_ID,
    VN_GTM_ID: env.REACT_APP_VN_GTM_ID,
    INTERNATIONAL_GTM_ID: env.REACT_APP_INTERNATIONAL_GTM_ID,
  };
  useEffect(() => {
    const measurementId = isVietNam ? GoogleAnalyticKeys.VN_GA4_ID : GoogleAnalyticKeys.INTERNATIONAL_GA4_ID;
    loadGA4Script(measurementId);
    const tagManagerArgs = {
      gtmId: isVietNam ? GoogleAnalyticKeys.VN_GTM_ID : GoogleAnalyticKeys.INTERNATIONAL_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useLayoutEffect(() => {
    const unlisten = history.listen((location) => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: location.pathname + location.search,
        },
      });

      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    });

    // Trigger the first pageview manually
    // Tracking for tag manager
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: window.location.pathname + window.location.search,
      },
    });

    // Tracking for GA4
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    return () => {
      unlisten();
    };
  }, [history]);

  return null; // This component doesn't render anything
};

export default GoogleAnalytics;
