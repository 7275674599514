import "./chart-staff-revenute.style.scss";
import { useEffect, useRef, useState } from "react";
import {
  StaffRevenueClearIcon,
  StaffRevenueHideIcon,
  StaffRevenueShowIcon,
  StaffRevenueLeftHandleIcon,
  StaffRevenueRightHandleIcon,
  TipIcon,
} from "constants/icons.constants";
import StaffRevenueChart from "./chart-staff-revenue.component";
import { Rnd } from "react-rnd";
import { useDispatch, useSelector } from "react-redux";
import {
  getChartDataStaffRevenue,
  staffRevenueActions,
  staffRevenueSelector,
} from "store/modules/staff-revenue/staff-revenue.reducer";
import {
  CLASSNAME_CHART_STAFF,
  COLORS_COLUMN_CHART,
  ID_ELEMENT_CHART_STAFF,
  SPACING_CHART,
} from "../data/chart-staff-revenue.data";
import ChartYAxisStaff from "./chart-y-axis.component";
import { NumberUnitConstant, NumberUnitLocalization, ValueNumberUnit } from "constants/number-unit-type.constants";
import { useMediaQuery } from "react-responsive";
import { formatNumberDecimalOrInteger, getCurrency } from "utils/helpers";
import { getUnitNumberType } from "../../../../utils/helpers";
import { FnbButton } from "components/fnb-button/fnb-button";

function WrapperStaffRevenueChart(props) {
  const { t, onClearFilterParams } = props;
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [maxIndex] = useState(isMobile ? 9 : 19);
  const dispatch = useDispatch();
  const staffRevenue = useSelector(staffRevenueSelector);
  const { isShowStaffSummary, filter } = staffRevenue;
  const isShowStaffNumber = useSelector(staffRevenueSelector).chart.showStaffNumber;
  const isShowSelectedRange = useSelector(staffRevenueSelector).chart.isShowSelectedRange;
  const numberUnitType = useSelector(staffRevenueSelector).chart.numberUnitType;
  const stepRevenue = useSelector(staffRevenueSelector).chart.stepRevenue;
  const staffSummaryParams = useSelector(staffRevenueSelector).staffSummaryParams;
  const columns = useSelector(staffRevenueSelector).chart.columns;
  const chart = useSelector(staffRevenueSelector).chart;
  const [avgRevenuePerStaff, setAvgRevenuePerStaff] = useState();
  const [numberUnit, setNumberUnit] = useState();
  const translatedData = {
    showStaffNumber: t("report.staff.showStaffNumber", "Show staff number"),
    hideStaffNumber: t("report.staff.hideStaffNumber", "Hide staff number"),
    clear: t("button.clear", "Clear"),
    avgPerStaffChart: t("report.staff.avgPerStaffChart", "Avg"),
    tipOnChart: t("report.staff.tipOnChart"),
  };
  const [currency, setCurrency] = useState("");
  const handleOnClickShowStaffNumber = () => {
    dispatch(
      staffRevenueActions.setShowStaffNumber({
        isShow: !isShowStaffNumber,
      }),
    );
    dispatch(staffRevenueActions.reloadColorChart());
  };
  const [maxWidthTooltip, setMaxWidthTooltip] = useState(230);

  useEffect(() => {
    dispatch(
      getChartDataStaffRevenue({
        branchId: staffSummaryParams.branchId,
        startDate: staffSummaryParams.startDate,
        endDate: staffSummaryParams.endDate,
        timeZone: new Date().getTimezoneOffset() / 60,
        businessSummaryWidgetFilter: staffSummaryParams.businessSummaryWidgetFilter,
        columnQuantity: isMobile ? 10 : 20,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffSummaryParams]);

  useEffect(() => {
    if (columns?.length > 0) {
      let end = 0,
        begin = 0;
      if (filter?.index?.start === null) {
        begin = 0;
        end = columns?.length;
      } else {
        begin = filter?.index?.start;
        end = filter?.index?.end + 1;
      }
      let sumRevenue = 0;
      let sumStaffNumber = 0;
      for (let i = begin; i < end; i++) {
        sumRevenue += columns[i]?.sumRevenue;
        sumStaffNumber += columns[i]?.staffNumber;
      }
      let unitOfNumberType = getUnitNumberType(sumRevenue / sumStaffNumber);
      unitOfNumberType = unitOfNumberType > NumberUnitConstant.THOUSAND ? unitOfNumberType : NumberUnitConstant.DEFAULT;
      const unit = ValueNumberUnit[unitOfNumberType];
      const avg = (sumRevenue / sumStaffNumber / unit).toFixed(unitOfNumberType === NumberUnitConstant.DEFAULT ? 0 : 1);
      setNumberUnit(unitOfNumberType);
      avg > 0 ? setAvgRevenuePerStaff(avg) : setAvgRevenuePerStaff(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.index, columns]);

  useEffect(() => {
    // Unmount component reset all state in redux
    return () => {
      dispatch(staffRevenueActions.clearStartAndEndRevenue());
      dispatch(staffRevenueActions.resetStaffSummaryParams());
      dispatch(staffRevenueActions.resetChartState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [styleRrn] = useState({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const [positionRange, setPositionRange] = useState({
    x: 48,
    y: isMobile ? 112 : 127,
    width: 172,
    height: 184,
  });

  const getColumnGapChart = () => {
    const columns = document.getElementsByClassName(CLASSNAME_CHART_STAFF.COLUMN);
    if (columns?.length > 0) {
      return columns[1].getBoundingClientRect().left - columns[0].getBoundingClientRect().right;
    } else return 10;
  };

  // eslint-disable-next-line no-unused-vars
  const getPaddingWrapperChart = () => {
    if (window.innerWidth >= 1200) {
      return SPACING_CHART.lg.PADDING_CHART;
    }
    return 16;
  };

  const getColumnWidthChart = () => {
    const columns = document.getElementsByClassName(CLASSNAME_CHART_STAFF.COLUMN);
    if (columns?.length > 0) {
      return columns[0].getBoundingClientRect().width;
    }
  };

  useEffect(() => {
    reloadRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.index]);

  //Show selected after hold mouse for .3s
  const mouseEventHoldClickColumnRef = useRef(null);
  const mouseDownSelecetedRange = (event) => {
    mouseEventHoldClickColumnRef.current = setTimeout(() => {
      const classList = event.target.classList;
      if ([...classList]?.includes(CLASSNAME_CHART_STAFF.COLUMN)) {
        dispatch(
          staffRevenueActions.setShowSelectedRange({
            isShow: true,
          }),
        );
      }
    }, 300);
  };

  const mouseUpSelectedRange = () => {
    if (mouseEventHoldClickColumnRef.current) {
      clearTimeout(mouseEventHoldClickColumnRef.current);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      onChangeSizeBrowser();
      setCurrency(getCurrency());
    }, 200);
  }, []);

  const onChangeSizeBrowser = () => {
    const chart = document.getElementById("chart-staff-revenue-id");
    const buttonShowStaffNumber = document.getElementById("btn-chart-show-staff-number-id");
    if (chart && buttonShowStaffNumber) {
      setMaxWidthTooltip(buttonShowStaffNumber.offsetLeft - 36);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", mouseDownSelecetedRange, { passive: true });
    window.addEventListener("mouseup", mouseUpSelectedRange, { passive: true });
    window.addEventListener("resize", onChangeSizeBrowser, { passive: true });
    return () => {
      window.removeEventListener("mousedown", mouseDownSelecetedRange);
      window.removeEventListener("mouseup", mouseUpSelectedRange);
      window.addEventListener("resize", onChangeSizeBrowser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragStop = (_e, d) => {
    setPositionRange({ ...positionRange, x: d.x });
    handleSetRange(null, null, true);
  };

  const onDrag = (_e, d) => {
    handleSetRange(null, null, false, true);
  };

  useEffect(() => {
    setTimeout(() => {
      reloadRange();
      dispatch(staffRevenueActions.reloadColorChart());
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowStaffSummary]);

  const reloadRange = () => {
    const columns = document.getElementsByClassName(CLASSNAME_CHART_STAFF.COLUMN);
    if (columns?.length > 0 && filter.index.start !== null) {
      const chart = document.getElementById(ID_ELEMENT_CHART_STAFF.WRAPPER_CHART);
      const barSeries = document.getElementsByClassName(CLASSNAME_CHART_STAFF.BAR_SERIES)[0];
      const barSeriesLeft = barSeries.getBoundingClientRect().left;
      const prefix = 19;
      const beginChart = chart.getBoundingClientRect().left;
      const begin = columns[filter.index.start]?.getBoundingClientRect().left;
      const end = columns[filter.index.end]?.getBoundingClientRect().right;
      const paddingLeft = !isMobile ? (prefix - (barSeriesLeft - beginChart)) * 2 : 0;
      setPositionRange({
        ...positionRange,
        x: begin - beginChart + (!isMobile ? getColumnWidthChart() / 2 : 0) + paddingLeft,
        width: end - begin,
      });
    }
  };

  const handleSetRange = (begin = null, end = null, isStopModified = false, isOnDragging = false) => {
    const chart = document.getElementById(ID_ELEMENT_CHART_STAFF.WRAPPER_CHART);
    const columns = document.getElementsByClassName(CLASSNAME_CHART_STAFF.COLUMN);

    let beginCenterRange, endCenterRange;
    if (begin === null) {
      const centerRange = document.getElementById(ID_ELEMENT_CHART_STAFF.CENTER_RANGE);

      beginCenterRange = centerRange.getBoundingClientRect().left;
      endCenterRange = centerRange.getBoundingClientRect().right;
    } else {
      beginCenterRange = begin;
      endCenterRange = end;
    }

    let beginIndex = null;
    let endIndex = null;
    if (chart) {
      if (columns?.length > 0) {
        for (let index = 0; index < columns.length; index++) {
          const leftColumn = columns[index].getBoundingClientRect().left;
          const rightColumn = columns[index].getBoundingClientRect().right;
          if (
            (rightColumn > beginCenterRange - 1 && rightColumn < endCenterRange && isStopModified) ||
            (leftColumn > beginCenterRange - 1 && leftColumn < endCenterRange) ||
            (isOnDragging && leftColumn > beginCenterRange - 1 - getColumnWidthChart() && leftColumn < endCenterRange)
          ) {
            columns[index].style.fill = COLORS_COLUMN_CHART.SELECTED;
            if (beginIndex === null || index < beginIndex) {
              beginIndex = index;
            }
            if (endIndex === null || index > endIndex) {
              endIndex = index;
            }
          } else {
            columns[index].style.fill = COLORS_COLUMN_CHART.DEFAULT;
          }
        }
      }
      if (isStopModified) {
        const startRevenue = beginIndex * stepRevenue;
        const endRevenue = (endIndex + 1) * stepRevenue;
        dispatch(
          staffRevenueActions.setStartAndEndRevenue({
            startRevenue: startRevenue,
            endRevenue: endRevenue,
            startIndex: beginIndex,
            endIndex: endIndex,
          }),
        );
      }
    }
  };

  const onResizeChart = (_e, _direction, ref, _delta, position) => {
    const chart = document.getElementById(ID_ELEMENT_CHART_STAFF.WRAPPER_CHART);
    const beginWrapperChart = chart.getBoundingClientRect().left;
    if (chart) {
      const beginChart = beginWrapperChart + position.x - getColumnWidthChart() * (isMobile ? 1 : 1.5);
      const endChart =
        beginChart + parseFloat(ref.style.width.replace("px", "")) + getColumnWidthChart() - getColumnGapChart() * 2;
      handleSetRange(beginChart, endChart);
    }
  };

  const onResizeChartStop = (_e, _direction, ref, _delta, position) => {
    setPositionRange({
      ...positionRange,
      width: ref.style.width,
      height: ref.style.height,
      ...position,
    });
    handleSetRange(null, null, true);
  };

  const handleClear = () => {
    onClearFilterParams();
  };

  const getAverageRevenue = () => {
    if (numberUnit > NumberUnitConstant.THOUSAND) {
      return `${formatNumberDecimalOrInteger(avgRevenuePerStaff)} ${
        numberUnit ? t(`${NumberUnitLocalization[numberUnit !== 0 ? numberUnit : numberUnitType]}`).toLowerCase() : ""
      } ${currency}`;
    } else {
      return `${formatNumberDecimalOrInteger(avgRevenuePerStaff)} ${currency}`;
    }
  };
  return (
    <div className="staff-revenue-chart-container">
      <div className="chart-header">
        {isDesktop && (
          <div
            className="tip-hover-chart"
          >
            <TipIcon className="tip-hover-chart__icon" />
            <span className="tip-hover-chart__text">{translatedData.tipOnChart}</span>
          </div>
        )}
        <div className="group-button-chart">
          <FnbButton
            variant="secondary-purple"
            id="btn-chart-show-staff-number-id"
            onClick={handleOnClickShowStaffNumber}
            text={isShowStaffNumber ? translatedData.hideStaffNumber : translatedData.showStaffNumber}
            iconHeader={isShowStaffNumber ? <StaffRevenueHideIcon /> : <StaffRevenueShowIcon />}
            className="btn-show-staff-number"
          />
          {(filter.index.start !== 0 ||
            filter.index.end !== maxIndex ||
            isShowSelectedRange ||
            !staffSummaryParams.isDefault) && (
            <FnbButton
              variant="secondary-purple"
              text={translatedData.clear}
              onClick={handleClear}
              iconHeader={<StaffRevenueClearIcon />}
            />
          )}
        </div>
      </div>
      <div className="avg-chart-container">
        <span
          className="avg-chart-text"
          style={chart.maxRevenue > 0 ? { visibility: "visible" } : { visibility: "hidden" }}
        >{`${translatedData.avgPerStaffChart}: ${getAverageRevenue()}`}</span>
      </div>
      <div className="chart-staff-revenue" id="chart-staff-revenue-id">
        <div className="wrapper-chart-staff-revenue" id="wrapper-chart-staff-revenue-id">
          <ChartYAxisStaff />
          <StaffRevenueChart />
          <span className="xaxis-zero">0</span>
          <div className="xasis-arrow">
            <hr className="xasis-arrow__line" />
            <div className="xasis-arrow__triangle-right" />
          </div>
        </div>

        <div className="currency-right-corner">
          <span style={chart.maxRevenue > 0 ? { visibility: "visible" } : { visibility: "hidden" }}>
            {numberUnitType > 0 && t(`${NumberUnitLocalization[numberUnitType]}`)}
          </span>
          <span>{currency}</span>
        </div>
      </div>
      {isShowSelectedRange && (
        <Rnd
          className="rnd-range-staff-chart"
          style={styleRrn}
          size={{ width: positionRange.width, height: positionRange.height }}
          position={{ x: positionRange.x, y: positionRange.y }}
          onDragStop={(e, d) => {
            onDragStop(e, d);
          }}
          onDrag={(e, d) => {
            onDrag(e, d);
          }}
          dragAxis="x"
          onResize={(e, direction, ref, delta, position) => {
            onResizeChart(e, direction, ref, delta, position);
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            onResizeChartStop(e, direction, ref, delta, position);
          }}
          enableResizing={{ bottom: false, top: false, left: true, right: true }}
        >
          <div className="drag-range-chart" id="Resizable">
            <StaffRevenueLeftHandleIcon className="drag-range-chart__left" />
            <div className="drag-range-chart__center" id={ID_ELEMENT_CHART_STAFF.CENTER_RANGE}></div>
            <StaffRevenueRightHandleIcon className="drag-range-chart__right" />
          </div>
        </Rnd>
      )}
    </div>
  );
}

export default WrapperStaffRevenueChart;
