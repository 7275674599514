import { Col, Row, message } from "antd";
import "./styles.scss";
import { useEffect, useState } from "react";
import CashierScreen from "./components/cashier-screen";
import LeftMenuConfiguration, { SCREEN_OPERATION } from "./components/left-menu";
import CustomerScreen from "./components/customer-screen";
import POSKitchenScreen from "./components/pos-kichen";
import storeDataService from "data-services/store/store-data.service";
import { StoreSettingConstants } from "constants/store-setting.constants";
import { useTranslation } from "react-i18next";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { useDispatch, useSelector } from "react-redux";
import { setInformationPublishStore } from "store/modules/session/session.actions";
import { informationPublishStoreSelector } from "store/modules/session/session.reducers";

const Operation = (props) => {
  const { isChangeForm, onChangeForm } = props;
  const dispatch = useDispatch();
  const informationPublishStore = useSelector(informationPublishStoreSelector);
  const [screenActive, setScreenActive] = useState(SCREEN_OPERATION.CASHIER);
  const [screenSelectPending, setScreenSelectPending] = useState(null);
  const [defaultStoreConfig, setDefaultStoreConfig] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const { t } = useTranslation();

  const pageData = {
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
    updateSuccess: t("configuration.updateSuccess"),
  };
  const updateStoreConfig = async (data, messageNotify = "") => {
    const newStoreConfig = {
      ...defaultStoreConfig,
      ...data,
    };
    const payload = {
      store: newStoreConfig,
      storeSetting: StoreSettingConstants.OPERATION_CONFIG,
    };
    const response = await storeDataService.updateStoreManagementAsync(payload);
    if (response) {
      //save redux information store
      dispatch(
        setInformationPublishStore({
          ...informationPublishStore,
          isDeposit: payload?.store?.isDeposit,
        }),
      );
      message.success(messageNotify ? messageNotify : pageData.updateSuccess);
      setDefaultStoreConfig(newStoreConfig);
    } else {
      message.error(messageNotify ? messageNotify : pageData.updateSuccess);
    }
  };

  const renderRightScreen = (screenActive) => {
    switch (screenActive) {
      case SCREEN_OPERATION.CASHIER:
        return (
          <CashierScreen
            isChangeForm={isChangeForm}
            onChangeForm={onChangeForm}
            t={t}
            defaultStoreConfig={defaultStoreConfig}
            updateStoreConfig={updateStoreConfig}
          />
        );
      case SCREEN_OPERATION.CUSTOMER:
        return <CustomerScreen isChangeForm={isChangeForm} onChangeForm={onChangeForm} t={t} />;
      case SCREEN_OPERATION.POS_KITCHEN:
        return (
          <POSKitchenScreen
            isChangeForm={isChangeForm}
            isStoreHasKitchen={defaultStoreConfig?.isStoreHasKitchen}
            onChangeForm={onChangeForm}
            t={t}
            defaultStoreConfig={defaultStoreConfig}
            updateStoreConfig={updateStoreConfig}
          />
        );
      default:
        return <></>;
    }
  };

  const initData = async () => {
    const response = await storeDataService.getStoreByIdAsync();
    if (response) {
      if (response.store.reScanUnconfirmedOrdersInMinutes === 0) {
        response.store.reScanUnconfirmedOrdersInMinutes = 10;
      }
      setDefaultStoreConfig(response.store);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const onOk = () => {
    setScreenActive(screenSelectPending);
    onChangeForm(false);
    setShowConfirm(false);
  };

  const onCancel = () => {
    setShowConfirm(false);
  };

  return (
    <div className="page-configuration-operation">
      <Row gutter={[12, 12]}>
        <Col xs={24} xl={6}>
          <LeftMenuConfiguration
            t={t}
            isStoreHasKitchen={defaultStoreConfig?.isStoreHasKitchen}
            screenActive={screenActive}
            setScreenActive={setScreenActive}
            updateStoreConfig={updateStoreConfig}
            isChangeForm={isChangeForm}
            setShowConfirm={setShowConfirm}
            setScreenSelectPending={setScreenSelectPending}
          />
        </Col>
        <Col xs={24} xl={18}>
          <div className="right-screen-operation">{renderRightScreen(screenActive)}</div>
        </Col>
      </Row>
      <div style={{ position: "absolute" }}>
        <DeleteConfirmComponent
          title={pageData.leaveDialog.confirmation}
          content={pageData.leaveDialog.content}
          visible={showConfirm}
          skipPermission={true}
          cancelText={pageData.leaveDialog.discardBtn}
          okText={pageData.leaveDialog.btnConfirmLeave}
          onCancel={onCancel}
          onOk={onOk}
          isChangeForm={isChangeForm}
        />
      </div>
    </div>
  );
};

export default Operation;
