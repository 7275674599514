import { CheckOutlined } from "@ant-design/icons";
import React from "react";
import "./fnb-step.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import theme from "theme";
import FnbTypography from "components/fnb-typography/fnb-typography";

export const Step = ({
  currentStep,
  index,
  onChange,
  className,
  description,
  icon,
  iconFinish,
  onClick,
  title,
  isProcessing,
  typography = {
    variant: theme.typography["b2-regular"],
    color: theme.colors.primary.main,
  },
}) => {
  const onClickStep = () => {
    if (onClick) {
      onClick(index);
    }

    if (onChange) {
      onChange(index);
    }
  };

  return (
    <>
      <div
        className={`fnb-steps-item${currentStep === index ? " fnb-steps-item-process" : ""}${
          currentStep !== index ? " fnb-steps-item-wait" : ""
        }${currentStep > index ? " fnb-steps-item-finish" : ""}${className ? ` ${className}` : ""}${
          currentStep > index && isProcessing === true ? " processing" : ""
        }`}
      >
        <div className="fnb-steps-item-container">
          {icon ? (
            <div className="fnb-steps-item-icon-custom" onClick={onClickStep}>
              { currentStep > index ? iconFinish ?? icon : icon}
            </div>
          ) : (
            <div className="fnb-steps-item-icon" onClick={onClickStep}>
              <CheckOutlined />
            </div>
          )}
          <div className="fnb-steps-item-content">
            <div className="fnb-steps-item-status"></div>
          </div>
          <div className="item-content" onClick={onClickStep}>
            <FnbTooltip className="fnb-steps-item-title" onlyShowWhenEllipsis>
              <FnbTypography
                variant={typography.variant}
                color={typography.color ?? theme.colors.primary.main}
                text={title}
              />
            </FnbTooltip>
            <FnbTooltip className="fnb-steps-item-description" onlyShowWhenEllipsis>
              {description}
            </FnbTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export function FnbSteps({ currentStep, className, onChange, children }) {
  const renderChildren = () => {
    return React.Children.map(children, (child, index) => {
      if (child != null) {
        return React.cloneElement(child, {
          onChange,
          currentStep,
          index,
        });
      }
    });
  };

  return (
    <div className={`fnb-steps fnb-steps-horizontal fnb-steps-label-horizontal ${className}`}>{renderChildren()}</div>
  );
}
