export const OrderTypeConstants = {
  InStore: 0,
  Delivery: 1,
  TakeAway: 2,
  Online: 3,
  PickUp: 4,
};

export const OrderTypeConstantTranslate = {
  InStore: "feeAndTax.servingType.instore",
  Delivery: "feeAndTax.servingType.delivery",
  TakeAway: "feeAndTax.servingType.takeAway",
  Online: "feeAndTax.servingType.online",
  PickUp: "feeAndTax.servingType.pickup",
};

export const OrderServiceTypeLocalization = {
  [OrderTypeConstants.InStore]: "feeAndTax.servingType.instore",
  [OrderTypeConstants.Delivery]: "feeAndTax.servingType.delivery",
  [OrderTypeConstants.TakeAway]: "feeAndTax.servingType.takeAway",
  [OrderTypeConstants.Online]: "feeAndTax.servingType.delivery",
  [OrderTypeConstants.PickUp]: "feeAndTax.servingType.pickup",
};
