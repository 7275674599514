import "moment/locale/vi";
import "../online-store-management/online-store-management.scss";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Card, Col, message, Skeleton } from "antd";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { AdvancedSettings } from "constants/online-store.constants";
import storeAdvancedSettingsDataService from "data-services/store-advanced-settings/store-advanced-settings.service";
import "./advanced-settings.page.scss";
import FormSectionAdvancedSetting from "./components/form-section.component";
const KEY_TAB_PANE = {
  WEBSITE_TAB: "website-tab",
  MOBILE_APP_TAB: "mobile-app-tab",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.WEBSITE_TAB]: 0,
  [KEY_TAB_PANE.MOBILE_APP_TAB]: 1,
};

export default function AdvancedSettingsPage(props) {
  const [t] = useTranslation();
  const state = useSelector((state) => state);
  // eslint-disable-next-line no-unused-vars
  const [advancedSettings, setAdvancedSettings] = useState(null);

  const [googleAnalytics, setGoogleAnalytics] = useState(null);
  const [meta, setMeta] = useState(null);
  const [metaMesseger, setMetaMesseger] = useState(null);
  const [zaloOA, setZaloOA] = useState(null);
  const [isFirstTimeLoad, setIsFirstLoad] = useState(true);
  const pageData = {
    titlePage: t("onlineStore.advancedSettings.headerTitle"),
    googleAnalyticsTitle: t("onlineStore.advancedSettings.googleAnalytics.title"),
    googleAnalyticsContent: t("onlineStore.advancedSettings.googleAnalytics.content"),
    googleAnalyticsCode: t("onlineStore.advancedSettings.googleAnalytics.googleAnalyticsCode"),
    enterYourGoogleAnalyticsCode: t("onlineStore.advancedSettings.googleAnalytics.enterYourGoogleAnalyticsCode"),
    googleAnalyticsSaveSuccess: t("onlineStore.advancedSettings.googleAnalytics.googleAnalyticsSaveSuccess"),
    googleAnalyticsSaveFail: t("onlineStore.advancedSettings.googleAnalytics.googleAnalyticsSaveFail"),

    metaPixelTitle: t("onlineStore.advancedSettings.metaPixel.title"),
    metaPixelContent: t("onlineStore.advancedSettings.metaPixel.content"),
    facebookPixelId: t("onlineStore.advancedSettings.metaPixel.facebookPixelId"),
    facebookAppId: t("onlineStore.advancedSettings.metaPixel.facebookAppId"),
    enterYourFacebookPixelId: t("onlineStore.advancedSettings.metaPixel.enterYourFacebookPixelId"),
    enterYourFacebookAppId: t("onlineStore.advancedSettings.metaPixel.enterYourFacebookAppId"),
    metaPixelIntergrationSuccess: t("onlineStore.advancedSettings.metaPixel.metaPixelIntergrationSuccess"),
    metaPixelIntergrationFail: t("onlineStore.advancedSettings.metaPixel.metaPixelIntergrationFail"),

    messegerTitle: t("onlineStore.advancedSettings.messeger.title"),
    messegerContent: t("onlineStore.advancedSettings.messeger.content"),
    showFacebookMessengerFloatingButton: t("onlineStore.advancedSettings.messeger.showFacebookMessengerFloatingButton"),
    facebookPageId: t("onlineStore.advancedSettings.messeger.facebookPageId"),
    enterYourFacebookPageId: t("onlineStore.advancedSettings.messeger.enterYourFacebookPageId"),
    messegerIntergrationSuccess: t("onlineStore.advancedSettings.messeger.messegerIntergrationSuccess"),

    zaloTitle: t("onlineStore.advancedSettings.zalo.title"),
    zaloContent: t("onlineStore.advancedSettings.zalo.content"),
    showZaloFloatingButton: t("onlineStore.advancedSettings.zalo.showZaloFloatingButton"),
    zaloOfficialAccountId: t("onlineStore.advancedSettings.zalo.zaloOfficialAccountId"),
    enterYourZaloAccountId: t("onlineStore.advancedSettings.zalo.enterYourZaloAccountId"),
    welcomeText: t("onlineStore.advancedSettings.zalo.welcomeText"),
    welcomeTextExample: t("onlineStore.advancedSettings.zalo.welcomeTextExample"),
    zaloIntergrationSuccess: t("onlineStore.advancedSettings.zalo.zaloIntergrationSuccess"),

    onlineTitle: t("onlineStore.title"),
    websiteTitle: t("onlineStore.website"),
    mobileAppTitle: t("onlineStore.mobileApp.title"),
    tabNameAccount: t("myAccount.tabName.account"),
    subscription: t("myAccount.tabName.subscription"),
  };
  const handleSaveGoogleAnalytics = async (values) => {
    const request = {
      type: AdvancedSettings.GoogleAnalytics,
      clientId: values?.gaCode,
    };
    setGoogleAnalytics(request);
    const res = await storeAdvancedSettingsDataService.createAdvanceSettingsAsync(request);
    if (res) {
      message.success(pageData.googleAnalyticsSaveSuccess);
    } else {
      message.error(pageData.googleAnalyticsSaveFail);
    }
  };

  const handleSaveFacebookPixel = async (values) => {
    const request = {
      type: AdvancedSettings.FacebookPixel,
      clientId: values?.pixelId,
    };
    setMeta(request);
    const res = await storeAdvancedSettingsDataService.createAdvanceSettingsAsync(request);
    if (res) {
      message.success(pageData.metaPixelIntergrationSuccess);
    } else {
      message.error(pageData.metaPixelIntergrationFail);
    }
  };

  const handleSaveFacebookMessenger = async (values) => {
    const request = {
      type: AdvancedSettings.MetaMessenger,
      clientId: values?.messegerId ?? "",
      isEnabled: values?.switchStatus,
    };
    setMetaMesseger(request);
    const res = await storeAdvancedSettingsDataService.createAdvanceSettingsAsync(request);
    if (res) {
      message.success(pageData.messegerIntergrationSuccess);
    }
  };

  const handleSaveZaloChat = async (values) => {
    const request = {
      type: AdvancedSettings.ZaloOAChat,
      clientId: values?.zaloId ?? "",
      isEnabled: values?.switchStatus,
    };
    setZaloOA(request);
    const res = await storeAdvancedSettingsDataService.createAdvanceSettingsAsync(request);
    if (res) {
      message.success(pageData.zaloIntergrationSuccess);
    }
  };

  useEffect(() => {
    getListSetting();
  }, []);

  const getListSetting = async () => {
    const res = await storeAdvancedSettingsDataService.getAdvanceSettingsAsync();
    setAdvancedSettings(res?.advancedSettings);
  };

  useEffect(() => {
    if (advancedSettings) {
      const filteredGA = advancedSettings?.find((item) => item.type === AdvancedSettings.GoogleAnalytics);
      const filteredMeta = advancedSettings?.find((item) => item.type === AdvancedSettings.FacebookPixel);
      const filteredMesseger = advancedSettings?.find((item) => item.type === AdvancedSettings.MetaMessenger);
      const filteredZalo = advancedSettings?.find((item) => item.type === AdvancedSettings.ZaloOAChat);
      setGoogleAnalytics(filteredGA);
      setMeta(filteredMeta);
      setMetaMesseger(filteredMesseger);
      setZaloOA(filteredZalo);
      setIsFirstLoad(false);
    }
  }, [advancedSettings]);

  return (
    <>
      <FnbHeadingPage title={pageData.titlePage} />
      <div className="advanced-settings-body">
        {isFirstTimeLoad ? (
          <Card className="card-advanced-setting">
            <Skeleton />
          </Card>
        ) : (
          <>
            <Card className="card-advanced-setting">
              <Col span={24}>
                <FormSectionAdvancedSetting
                  title={pageData.googleAnalyticsTitle}
                  description={pageData.googleAnalyticsContent}
                  fields={[
                    {
                      name: "gaCode",
                      label: pageData.googleAnalyticsCode,
                      placeholder: pageData.enterYourGoogleAnalyticsCode,
                      maxLength: 100,
                    },
                  ]}
                  onSave={(values) => handleSaveGoogleAnalytics(values)}
                  initialValues={googleAnalytics != null ? { gaCode: googleAnalytics?.clientId } : undefined}
                />
              </Col>
            </Card>
            <Card className="card-advanced-setting">
              <Col span={24}>
                <FormSectionAdvancedSetting
                  title={pageData.metaPixelTitle}
                  description={pageData.metaPixelContent}
                  fields={[
                    {
                      name: "pixelId",
                      label: pageData.facebookPixelId,
                      placeholder: pageData.enterYourFacebookPixelId,
                      maxLength: 100,
                    },
                  ]}
                  onSave={(values) => {
                    handleSaveFacebookPixel(values);
                  }}
                  initialValues={meta != null ? { pixelId: meta?.clientId, appId: meta?.appId } : undefined}
                />
              </Col>
            </Card>
            <Card className="card-advanced-setting">
              <Col span={24}>
                <FormSectionAdvancedSetting
                  title={pageData.messegerTitle}
                  description={pageData.messegerContent}
                  switchTitle={pageData.showFacebookMessengerFloatingButton}
                  fields={[
                    {
                      name: "messegerId",
                      label: pageData.facebookPageId,
                      placeholder: pageData.enterYourFacebookPageId,
                      maxLength: 100,
                    },
                  ]}
                  onSave={(values) => {
                    handleSaveFacebookMessenger(values);
                  }}
                  initialValues={
                    metaMesseger != null
                      ? { messegerId: metaMesseger?.clientId, switchStatus: metaMesseger?.isEnabled ?? false }
                      : { messegerId: "", switchStatus: false }
                  }
                />
              </Col>
            </Card>
            <Card className="card-advanced-setting">
              <Col span={24}>
                <FormSectionAdvancedSetting
                  title={pageData.zaloTitle}
                  description={pageData.zaloContent}
                  switchTitle={pageData.showZaloFloatingButton}
                  fields={[
                    {
                      name: "zaloId",
                      label: pageData.zaloOfficialAccountId,
                      placeholder: pageData.enterYourZaloAccountId,
                      maxLength: 100,
                    },
                  ]}
                  onSave={(values) => {
                    handleSaveZaloChat(values);
                  }}
                  initialValues={
                    zaloOA != null
                      ? {
                          zaloId: zaloOA?.clientId,
                          switchStatus: zaloOA?.isEnabled ?? false,
                        }
                      : {
                          zaloId: "",
                          switchStatus: false,
                        }
                  }
                />
              </Col>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
