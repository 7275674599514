import { Col, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ContactInformationImageDefault from "../../../../assets/images/bg-contact-information-default.png";
import ContactImageDefault from "../../../../assets/images/contact-feedback-default.jpg";
import { BCButton } from "../../../../components/BCButton";
import { FnbInput } from "../../../../components/fnb-input/fnb-input.component";
import { backgroundTypeEnum } from "../../../../constants/store-web-page.constants";
import "./styles.scss";

const ContactFeedback = (props) => {
  const { isCustomize, config, general } = props;
  const [t] = useTranslation();
  const { TextArea } = Input;
  const { contactUs } = config;

  const colorGroup =
    general?.color?.colorGroups?.find((a) => a?.id === contactUs?.colorGroupId) ?? general?.color?.colorGroups?.[0];
  const corlorConfigBtnSend = {
    buttonBackgroundColor: colorGroup?.buttonBackgroundColor,
    buttonBorderColor: colorGroup?.buttonBorderColor,
  };
  const themePageConfig = {
    colorGroup: corlorConfigBtnSend,
  };
  const backgroundStyles = {
    background: `url(${ContactImageDefault}) no-repeat center center`,
  };

  const StyledArea = styled.div`
    textarea {
      background: ${colorGroup?.buttonBackgroundColor} !important;
      border-radius: ${isCustomize ? "12px" : "6px"};
    }
  `;

  function getBackgroundContact() {
    const contact = contactUs;
    if (!contact) return {};
    let styles = {};
    if (contact?.backgroundType === backgroundTypeEnum.Color) {
      styles = {
        backgroundColor: contact?.backgroundColor,
      };
    } else {
      styles = {
        background: `url(${
          contact?.backgroundImage ? contact?.backgroundImage : ContactInformationImageDefault
        }) no-repeat center center`,
        backgroundSize: "cover",
      };
    }
    return styles;
  }

  const translateData = {
    contactUS: t("contact.contactUS", "CONTACT US"),
    contact: t("menu.contact", "Contact"),
    haveQuestion: t("contact.haveQuestion", "HAVE QUESTION?"),
    callUsOrVisitPlace: t("contact.callUsOrVisitPlace", "CALL US OR VISIT PLACE"),
    open: t("contact.open", "Open"),
    sendUSMessage: t("contact.sendUSMessage", "SEND US MESSAGE"),
    yourName: t("contact.yourName", "Your name"),
    enterYourPhoneNumber: t("contact.enterYourPhoneNumber", "Your phone number"),
    enterYourEmail: t("contact.enterYourEmail", "Your email address"),
    subject: t("contact.subject", "Subject"),
    message: t("contact.message", "Message"),
    pleaseYourName: t("contact.pleaseYourName", "Your full name"),
    pleaseEnterYourPhoneNumber: t("contact.pleaseEnterYourPhoneNumber"),
    pleaseEnterYourEmail: t("contact.pleaseEnterYourEmail"),
    pleaseSubject: t("contact.pleaseSubject"),
    pleaseMessage: t("contact.pleaseMessage", "Your message"),
    today: t("contact.today", "Today"),
    isOpen: t("contact.isOpen", "Is Open"),
    send: t("contact.send", "Send"),
    sendUsFeedback: t("contact.sendUsFeedback", "Send us feedback"),
    pleaseTellMeWhatYouThink: t("contact.pleaseTellMeWhatYouThink", "Please tell me what you think"),
    subTitleFeedback: t(
      "contact.subTitleFeedback",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas integer nunc mi, ac aliquet pretium platea. Nisl eu nulla nunc, risus eu, volutpat. Blandit in cursus purus nibh.",
    ),
  };

  const isMediumScreen = useMediaQuery({ maxWidth: 1199 });
  const screenRatioFeedbackSection = isCustomize ? 14 : isMediumScreen ? 4 : 12;

  return (
    <div style={getBackgroundContact()} className="contact-feedback w-100">
      <div className="contact-feedback-detail" style={backgroundStyles}>
        <Row className="w-100 h-100 contact-feedback-detail-section">
          <Col span={screenRatioFeedbackSection} className="description">
            <span
              className="h-100"
              dangerouslySetInnerHTML={{
                __html: `${contactUs?.description ?? ""}`,
              }}
            ></span>
          </Col>
          <Col span={24 - screenRatioFeedbackSection} className="contact-container">
            <Row className="my-16 title">{contactUs?.title ? contactUs?.title : translateData.sendUsFeedback}</Row>
            <Row className="my-16 sub-title">
              {contactUs?.subTitle ? contactUs?.subTitle : isCustomize ? translateData.subTitleFeedback : ""}
            </Row>
            <Row className="my-16 h-72 name">
              <FnbInput
                className="fnb-input-with-count h-72"
                placeholder={translateData.pleaseYourName}
                maxLength={100}
                showCount={false}
              />
            </Row>
            <Row className="my-16 h-72 email">
              <FnbInput
                className="fnb-input-with-count h-72"
                placeholder={translateData.enterYourEmail}
                maxLength={100}
                showCount={false}
              />
            </Row>
            <Row className="my-16 h-72 phone">
              <FnbInput
                className="fnb-input-with-count h-72"
                placeholder={translateData.enterYourPhoneNumber}
                maxLength={100}
                showCount={false}
              />
            </Row>

            <Row className="my-16 your-message">
              <StyledArea className="w-100">
                <TextArea
                  placeholder={translateData.pleaseMessage}
                  allowClear
                  showCount={false}
                  bordered={false}
                  style={{ height: 120, resize: "none" }}
                />
              </StyledArea>
            </Row>

            <Row className="my-16 btn-send" justify={"end"}>
              <BCButton themePageConfig={themePageConfig}>{translateData.send}</BCButton>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactFeedback;
