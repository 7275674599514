import { Col, DatePicker, Form, message, Radio, Row, TimePicker, Space } from "antd";
import { FnbImageSelectComponent } from "components/fnb-image-select/fnb-image-select.component";
import { FnbSelectHyperlink } from "components/fnb-select-hyperlink/fnb-select-hyperlink";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { DELAYED_TIME } from "constants/default.constants";
import { FnbUploadNoticationCampaignConstants } from "constants/ftn-upload-img-notification-campaign";
import { HYPERLINK_SELECT_OPTION } from "constants/hyperlink.constants";
import {
  CalendarNewIconBold,
  CheckboxCheckedIcon,
  HyperlinkNotificationListIcon,
} from "constants/icons.constants";
import {
  EnumResultCreateOrUpdate,
  ListNotificationCampaignSendByEvent,
  ListNotificationCampaignSendingType,
  NotificationCampaignSendingType,
} from "constants/notification-campaign.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { enumSendingType } from "constants/sending-type-notification-campaign";
import { DateFormat } from "constants/string.constants";
import notificationCampaignDataService from "data-services/notification-campaign/notification-campaign.service";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { convertUtcToLocalTime, isStoreInternational } from "utils/helpers";
import { Hyperlink } from "../../../../constants/hyperlink.constants";
import customerSegmentDataService from "../../../../data-services/customer-segment/customer-segment-data.service";
import "./edit-notification-campaign.page.scss";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbCancelButton } from "components/cancel-button";
import { FnbEditButton } from "components/fnb-edit-button/fnb-edit-button.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import theme from "theme";
import { FnbTextAreaWithCount } from "components/fnb-text-area-with-count/fnb-text-area-with-count.component";

export default function EditNotificationCampaign(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const reduxState = useSelector((state) => state);
  const fnbImageSelectRef = React.useRef();
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [sendingTypeSelected, setSendingTypeSelected] = useState(ListNotificationCampaignSendingType[0]?.key);
  const [isChangeFormGotIt, setIsChangeFormGotIt] = useState(false);
  const [hyperlinkSelectOptions, setHyperlinkSelectOptions] = useState([
    HYPERLINK_SELECT_OPTION[0],
    {
      id: Hyperlink.MY_NOTIFICATION,
      name: "menuManagement.menuItem.hyperlink.notificationList",
      icon: <HyperlinkNotificationListIcon />,
    },
  ]);
  const [hyperlink, setHyperlink] = useState(HYPERLINK_SELECT_OPTION[0]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [customerSegmentInSore, setCustomerSegmentInStore] = useState([]);
  const [requestingCustomerSegmentInStore, setCustomerRequestingSegmentInStore] = useState(false);
  const [isAllCustomersChecked, setIsAllCustomersChecked] = useState(true);
  const [selectedCustomerSegments, setSelectedCustomerSegments] = useState({
    isShowCount: false,
    customerSegmentCount: 0,
    customerCount: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [textAreaCount, setTextAreaCount] = useState(0);

  //Galaxy Fold2: 884, Galaxy Tab S7: 800, IpadPro 11: 834, IpadAir 4: 820
  const isTabletOrMobile = useMediaQuery({ maxWidth: 884 });
  const isInternational = isStoreInternational();

  const pageData = {
    btnCancel: t("button.cancel"),
    btnAddNew: t("button.create"),
    btnSave: t("button.save"),

    title: t("marketing.notificationCampaign.titleMessage"),
    titleEdit: t("marketing.notificationCampaign.titleEdit"),
    campaignName: t("marketing.notificationCampaign.campaignName"),
    enterCampaignName: t("marketing.notificationCampaign.enterCampaignName"),
    pleaseEnterCampaignName: t("marketing.notificationCampaign.pleaseEnterCampaignName"),
    titleEventType: t("marketing.notificationCampaign.eventType.title"),
    installTheAppEvent: t("marketing.notificationCampaign.eventType.installTheAppEvent"),
    titleSendingType: t("marketing.notificationCampaign.sendingType.title"),
    sendByEvent: t("marketing.notificationCampaign.sendingType.sendByEvent"),
    sendBySpecificTime: t("marketing.notificationCampaign.sendingType.sendBySpecificTime"),
    sendNow: t("marketing.notificationCampaign.sendingType.sendNow"),
    sendNotificationIn: t("marketing.notificationCampaign.sendNotificationIn"),
    hoursAfterTheEvent: t("marketing.notificationCampaign.hoursAfterTheEvent"),
    pleaseEnterHour: t("marketing.notificationCampaign.pleaseEnterHour"),
    message: t("marketing.notificationCampaign.message"),
    enterTitle: t("marketing.notificationCampaign.enterTitle"),
    pleaseEnterTitle: t("marketing.notificationCampaign.pleaseEnterTitle"),
    messageContent: t("marketing.notificationCampaign.messageContent"),
    enterMessageContent: t("marketing.notificationCampaign.enterMessageContent"),
    pleaseEnterContent: t("marketing.notificationCampaign.pleaseEnterContent"),
    image: t("marketing.notificationCampaign.image"),
    hyperlink: t("marketing.notificationCampaign.hyperlink"),
    pleaseSelectHyperlink: t("marketing.notificationCampaign.pleaseSelectHyperlink"),
    generalInformation: t("title.generalInformation"),
    guideline: {
      title: t("marketing.notificationCampaign.guideline.title"),
      content: t("marketing.notificationCampaign.guideline.content"),
    },
    imageSizeTooBig: "marketing.notificationCampaign.imageSizeTooBig",
    messageErrorFormat: "marketing.notificationCampaign.acceptFileImageTypes",
    date: t("marketing.notificationCampaign.date"),
    selectDate: t("marketing.notificationCampaign.selectDate"),
    selectTime: t("marketing.notificationCampaign.selectTime"),
    pleaseSelectDate: t("marketing.notificationCampaign.pleaseSelectDate"),
    pleaseSelectStartTime: t("marketing.notificationCampaign.pleaseSelectStartTime"),
    updateSuccessfullyMessage: t("marketing.notificationCampaign.updateSuccessfullyMessage"),
    updateIsNotSuccessfullyMessage: t("marketing.notificationCampaign.updateIsNotSuccessfullyMessage"),
    pleaseInputFirebaseCredential: t("marketing.notificationCampaign.pleaseInputFirebaseCredential"),
    pleaseSelectTimeFrameHasBecomePast: t("discountCode.formCreate.pleaseSelectTimeframeHasBecomePast"),
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    upload: {
      addFromUrl: t("upload.addFromUrl"),
      addFile: t("upload.addFile"),
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    notyetConfigNotification: t("marketing.notificationCampaign.systemNotyetConfig"),
    buttonIGotIt: t("form.buttonIGotIt"),
    customer: {
      allCustomers: t("marketing.notificationCampaign.customer.allCustomers"),
      selectCustomerGroups: t("marketing.notificationCampaign.customer.selectCustomerGroups"),
      selectCustomerGroupsRequiredMessage: t(
        "marketing.notificationCampaign.customer.selectCustomerGroupsRequiredMessage",
      ),
    },
  };

  useEffect(() => {
    if (isInternational) {
      history.push("/home");
    }
    
    async function fetchData() {
      await getInitDataAsync();
    }
    fetchData();
  }, []);

  const getInitDataAsync = async () => {
    const { notificationCampaignId } = match?.params;
    if (notificationCampaignId) {
      const res = await notificationCampaignDataService.getNotificationCampaignByIdAsync(notificationCampaignId);
      if (res) {
        initData(res.notificationCampaign);
      }
    }
  };

  const initData = (value) => {
    let selectedDateToLocal = null;
    if (fnbImageSelectRef && fnbImageSelectRef.current) {
      fnbImageSelectRef.current.setImageUrl(value?.thumbnail);
      setSelectedImage(value?.thumbnail);
    }
    setSendingTypeSelected(value?.sendingTypeId);
    if (value?.specificTime) {
      selectedDateToLocal = convertUtcToLocalTime(value?.specificTime);
    }
    setHyperlink(value?.hyperlinkOption);
    setTextAreaCount(value?.content?.length);

    form.setFieldsValue({
      name: value?.name,
      sendNotificationTime: value?.sendNotificationTime,
      specificTime: value?.specificTime,
      title: value?.title,
      content: value?.content,
      thumbnail: value?.thumbnail,
      hyperlinkOption: value?.hyperlinkOption,
      url: value?.url,
      statusId: value?.statusId,
      sendingTypeId: value?.sendingTypeId,
      event: value?.event,
      selectDate: selectedDateToLocal,
      selectTime: selectedDateToLocal,
      isAllCustomers: value?.isAllCustomers,
      customerSegmentIds: value?.customerSegmentIds,
    });
    setIsAllCustomersChecked(value?.isAllCustomers);
    void initCustomerSegmentList(value?.customerSegmentIds);
  };

  const initCustomerSegmentList = async (customerSegmentIds) => {
    setCustomerRequestingSegmentInStore(true);
    const customerSegmentListResult = await customerSegmentDataService.getCustomerSegmentByStoreIdAsync();
    void onUpdateCustomerSegment(customerSegmentIds, customerSegmentListResult);
    setCustomerSegmentInStore(customerSegmentListResult);
    setCustomerRequestingSegmentInStore(false);
  };

  const countDistinctObjectInArray = (arrayDistinct, array) => {
    array?.forEach((y) => {
      if (!arrayDistinct.includes(y)) {
        arrayDistinct.push(y);
      }
    });
    return arrayDistinct;
  };

  const onUpdateCustomerSegment = (values, initCustomerSegmentInSore = []) => {
    let selectedCustomersSegment = customerSegmentInSore?.filter((x) => values.indexOf(x.id) > -1);
    if (!selectedCustomersSegment || selectedCustomersSegment?.length <= 0) {
      selectedCustomersSegment = initCustomerSegmentInSore?.filter((x) => values.indexOf(x.id) > -1);
    }

    const distinctCustomers = [];
    selectedCustomersSegment?.forEach((x) => {
      if (x?.customers?.length > 0) {
        countDistinctObjectInArray(distinctCustomers, x.customers);
      }
    });

    const formGeneralTabFieldsValue = { ...form.getFieldsValue() };
    const formGeneralTabFieldsValueEdit = { ...formGeneralTabFieldsValue, customerSegmentIds: values };
    form.setFieldsValue({ formGeneralTabFieldsValueEdit });

    setSelectedCustomerSegments({
      isShowCount: values?.length > 0,
      customerSegmentCount: values?.length ?? 0,
      customerCount: distinctCustomers?.length ?? 0,
    });
  };

  const onChangeAllCustomersCheckBox = useCallback((e) => {
    setIsAllCustomersChecked(e.target.checked);
    form?.validateFields(["customerSegmentIds"])?.then()?.catch();
  }, []);

  const isAllCustomersCheckboxDisabled = useMemo(() => {
    let formValue = form?.getFieldsValue();
    let { sendingTypeId, event } = formValue;
    return (
      (sendingTypeId === 0 && event === 0) ||
      (sendingTypeId === 0 && !event) ||
      (sendingTypeSelected === 0 && event === 0) ||
      (sendingTypeSelected === 0 && !event)
    );
  }, [form?.getFieldsValue(), sendingTypeSelected]);

  const onClickCreatePage = async () => {
    form.validateFields().then(async (values) => {
      var selectDateTime = null;
      let currentDateTime = new Date();
      var imageUrl = "";
      if (fnbImageSelectRef && fnbImageSelectRef.current) {
        imageUrl = fnbImageSelectRef.current.getImageUrl();
      }
      if (values?.sendingTypeId === enumSendingType.SendBySpecificTime) {
        selectDateTime = new Date(
          moment(values?.selectDate).year(),
          moment(values?.selectDate).month(),
          moment(values?.selectDate).date(),
          moment(values?.selectTime).hours(),
          moment(values?.selectTime).minutes(),
          moment(values?.selectTime).seconds(),
        );

        if (selectDateTime < currentDateTime) {
          message.error(pageData.pleaseSelectTimeFrameHasBecomePast);
          return;
        }
      }

      if (!Boolean(imageUrl)) {
        imageUrl = reduxState?.session?.storeLogo;
      }

      let isAllCustomers = values?.isAllCustomers;
      if (
        (values?.sendingTypeId === ListNotificationCampaignSendingType[0]?.key &&
          values?.event === ListNotificationCampaignSendByEvent[0]?.key) ||
        (values?.sendingTypeId === ListNotificationCampaignSendingType[0]?.key && !values?.event)
      ) {
        isAllCustomers = true;
      }

      let request = {
        notificationCampaign: {
          ...values,
          hyperlinkOption: hyperlink,
          sendingTypeId: sendingTypeSelected,
          thumbnail: imageUrl,
          specificTime: selectDateTime,
          id: match?.params?.notificationCampaignId,
          isAllCustomers: isAllCustomers,
        },
      };
      let res = await notificationCampaignDataService.updateNotificationCampaignAsync(request);
      if (res === EnumResultCreateOrUpdate.Success) {
        message.success(pageData.updateSuccessfullyMessage);
        redirectToNotificationCampaign();
      } else if (res === EnumResultCreateOrUpdate.NotFirebaseCredential) {
        redirectToNotificationCampaignGotIt();
      } else {
        message.error(pageData.updateIsNotSuccessfullyMessage);
      }
    });
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };
  const onDiscardGotIt = () => {
    setIsChangeFormGotIt(false);
  };

  const clickCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      redirectToNotificationCampaign();
    }
  };

  const setTime = (value) => {
    form.setFieldValue("selectTime", value);
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const redirectToNotificationCampaign = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/marketing/notification-campaign");
    }, DELAYED_TIME);
  };
  const redirectToNotificationCampaignGotIt = () => {
    setIsChangeFormGotIt(true);
    setTimeout(() => {
      history.push("/marketing/notification-campaign");
    }, DELAYED_TIME);
  };

  const onChangeHyperlink = (id) => {
    setHyperlink(id);
    let formValue = form.getFieldsValue();
    formValue.url = null;
    form.setFieldsValue(formValue);
  };

  const handleChangeSendType = (value) => {
    setSendingTypeSelected(value);
  };

  const renderSendByEvent = () => {
    return (
      <Row className="w-100">
        <Col sm={24} xs={24} lg={12}>
          <Form.Item
            name={"event"}
            label={pageData.titleEventType}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <FnbSelectSingle
              option={ListNotificationCampaignSendByEvent?.map((item) => ({
                id: item.key,
                name: t(item.name),
              }))}
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderSendBySpecificTime = () => {
    return (
      <Row className="w-100 d-flex" style={{ alignItems: "flex-end" }} gutter={12}>
        <Col xs={24} sm={13} lg={7}>
          <Form.Item
            name={"selectDate"}
            rules={[
              {
                required: true,
                message: pageData.pleaseSelectDate,
              },
            ]}
            label={pageData.date}
          >
            <DatePicker
              suffixIcon={<CalendarNewIconBold />}
              placeholder={pageData.selectDate}
              className="fnb-date-picker w-100"
              format={DateFormat.DD_MM_YYYY}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={10} lg={4}>
          <Form.Item
            name={"selectTime"}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseSelectStartTime} />,
              },
            ]}
          >
            <TimePicker
              className="fnb-date-picker"
              dropdownClassName="fnb-date-time-picker-dropdown fnb-date-time-picker-dropdown-style"
              format={"HH:mm"}
              placeholder={pageData.selectTime}
              onSelect={(time) => {
                setTime(moment(time, "HH:mm"));
              }}
              disabledHours={getListPreviousHourNumberFromNow}
              disabledMinutes={getListPreviousMinutesNumberFromNow}
              style={{ width: "150px" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderSendingTypeMobile = (index) => {
    if (sendingTypeSelected === index) {
      switch (sendingTypeSelected) {
        case NotificationCampaignSendingType.SendByEvent:
          return renderSendByEvent();
        case NotificationCampaignSendingType.SendBySpecificTime:
          return renderSendBySpecificTime();
        default:
          return <></>;
      }
    }
  };

  const renderSendingType = () => {
    switch (sendingTypeSelected) {
      case NotificationCampaignSendingType.SendByEvent:
        return renderSendByEvent();
      case NotificationCampaignSendingType.SendBySpecificTime:
        return renderSendBySpecificTime();
      default:
        return <></>;
    }
  };

  /**
   * Disable Hour From Start Time
   */
  const getListPreviousHourNumberFromNow = () => {
    var hours = [];
    let formValue = form.getFieldsValue();
    let { selectDate } = formValue;
    var selectDateForUser = new Date(moment(selectDate).year(), moment(selectDate).month(), moment(selectDate).date());
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (selectDateForUser.toDateString() === currentDate.toDateString()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };
  /**
   * Disable Hour Minute From Start Time
   * @param {*} selectedHour
   */
  const getListPreviousMinutesNumberFromNow = (selectedHour) => {
    var minutes = [];
    let formValue = form.getFieldsValue();
    let { selectDate } = formValue;
    var selectDateForUser = new Date(moment(selectDate).year(), moment(selectDate).month(), moment(selectDate).date());
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (selectDateForUser.toDateString() === currentDate.toDateString()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  return (
    <>
      <Form
        onFinish={onClickCreatePage}
        form={form}
        onFieldsChange={() => setIsChangeForm(true)}
        layout="vertical"
        autoComplete="off"
        className="custom-form form-edit-notification-campaign"
      >
        <FnbHeadingPage
          title={pageData.titleEdit}
          guideline={pageData.guideline}
          listButtons={[
            <FnbCancelButton onOk={clickCancel} />,
            <FnbEditButton
              htmlType="submit"
              disabled={!isChangeForm}
              permission={PermissionKeys.EDIT_NOTIFICATION_CAMPAIGN}
            />,
          ]}
        />
        <Row>
          <FnbCard className="w-100" title={pageData.generalInformation}>
            <>
              <Space className="w-100" direction="vertical" size={24} style={{ paddingBottom: 12 }}>
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.pleaseEnterCampaignName} />,
                    },
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                  className="w-100"
                  label={pageData.campaignName}
                >
                  <FnbInput showCount maxLength={100} placeholder={pageData.enterCampaignName} />
                </Form.Item>
                <Form.Item name={"sendingTypeId"} label={pageData.titleSendingType}>
                  <Radio.Group
                    onChange={(e) => handleChangeSendType(e.target.value)}
                    defaultValue={sendingTypeSelected}
                  >
                    {ListNotificationCampaignSendingType?.map((item, index) => {
                      let name = t(item?.name);
                      return (
                        <>
                          <Radio key={item?.key} value={item?.key}>
                            <FnbTypography text={name} />
                          </Radio>
                          {isTabletOrMobile && <Row>{renderSendingTypeMobile(index)}</Row>}
                        </>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
                {!isTabletOrMobile && <Row>{renderSendingType()}</Row>}
                {!isAllCustomersCheckboxDisabled && (
                  <div>
                    <Form.Item name={"isAllCustomers"} valuePropName="checked">
                      <FnbCheckBox onChange={onChangeAllCustomersCheckBox}>
                        <FnbTypography text={pageData.customer.allCustomers} />
                      </FnbCheckBox>
                    </Form.Item>
                    <Form.Item
                      name="customerSegmentIds"
                      validateStatus={isAllCustomersChecked ? "success" : "validating"}
                      rules={[
                        {
                          required: !isAllCustomersChecked,
                          message: (
                            <InputValidateMessage message={pageData.customer.selectCustomerGroupsRequiredMessage} />
                          ),
                        },
                        {
                          validator: async () => {
                            if (!isAllCustomersChecked) {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <FnbSelectMultiple
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(e) => onUpdateCustomerSegment(e)}
                        menuItemSelectedIcon={<CheckboxCheckedIcon />}
                        placeholder={pageData.customer.selectCustomerGroups}
                        optionFilterProp="children"
                        showArrow
                        showSearch={true}
                        allowClear={true}
                        id="fnb-select-multiple-customer-segment"
                        option={customerSegmentInSore?.map((item) => ({
                          key: item.id,
                          id: item.id,
                          name: item.name,
                        }))}
                        disabled={isAllCustomersChecked}
                        loading={requestingCustomerSegmentInStore}
                        hideItemSelected={isAllCustomersChecked}
                      />
                    </Form.Item>
                    {!isAllCustomersChecked && selectedCustomerSegments?.isShowCount && (!searchValue || searchValue?.length <= 0) && (
                      <div style={{ marginTop: 6 }}>
                        <span
                          className="fnb-typography__type--b1-regular"
                          dangerouslySetInnerHTML={{
                            __html: `${t("marketing.notificationCampaign.customer.selectedXSegmentsYCustomers", {
                              totalSegment: selectedCustomerSegments?.customerSegmentCount,
                              totalCustomer: selectedCustomerSegments?.customerCount,
                            })}`,
                          }}
                        ></span>
                      </div>
                    )}
                  </div>
                )}
              </Space>
            </>
          </FnbCard>
        </Row>
        <Row className="mt-3">
          <FnbCard className="w-100" title={pageData.message}>
            <Row gutter={[24, 24]}>
              <Col sm={24} lg={16}>
                <Space direction="vertical" size={24} className="w-100">
                  <Form.Item
                    name={"title"}
                    rules={[
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.pleaseEnterTitle} />,
                      },
                      {
                        type: "string",
                        max: 50,
                      },
                    ]}
                    className="w-100"
                    label={pageData.title}
                  >
                    <FnbInput showCount maxLength={50} placeholder={pageData.enterTitle} />
                  </Form.Item>
                  <Form.Item
                    name={"content"}
                    className="w-100"
                    rules={[
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.pleaseEnterContent} />,
                      },
                      {
                        type: "string",
                        max: 255,
                      },
                    ]}
                    label={pageData.messageContent}
                  >
                    <FnbTextAreaWithCount
                      textAreaCount={textAreaCount}
                      placeholder={pageData.enterMessageContent}
                      maxLength={255}
                      rows={4}
                      onChange={(event) => {
                        setTextAreaCount(event.target.value.length);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name={"hyperlinkOption"} label={pageData.hyperlink}>
                    <FnbSelectHyperlink
                      showSearch
                      fixed
                      defaultValue={HYPERLINK_SELECT_OPTION[0]?.id}
                      onChange={onChangeHyperlink}
                      option={hyperlinkSelectOptions}
                    ></FnbSelectHyperlink>
                  </Form.Item>
                </Space>
              </Col>
              <Col sm={24} lg={8}>
                <FnbTypography variant={theme.typography["b1-medium"]} text={pageData.image} />
                <FnbImageSelectComponent
                  ref={fnbImageSelectRef}
                  maxFileSize={1048576}
                  messageTooBigSize={pageData.imageSizeTooBig}
                  isShowBestDisplay={false}
                  messageErrorFormat={pageData.messageErrorFormat}
                  acceptType={FnbUploadNoticationCampaignConstants.ACCEPT_TYPES}
                />
              </Col>
            </Row>
          </FnbCard>
        </Row>
      </Form>
      <ConfirmDialogComponent
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        onCancel={onDiscard}
        onOk={redirectToNotificationCampaign}
      />
      <ConfirmDialogComponent
        className={"notification-dialog"}
        visible={isChangeFormGotIt}
        skipPermission={true}
        onOk={onDiscardGotIt}
        type={ConfirmStyle.NOTIFICATION}
      />
    </>
  );
}
