import branchDataService from "../../data-services/branch-data.services";
import countryDataService from "../../data-services/country-data.service";

export const getCountriesAsync = async () => {
  const response = await countryDataService.getCountryList();
  if (response && response.data) {
    return response.data;
  }

  return [];
};

export const getBranchesByCustomerAddress = async (
  lat,
  lng,
  isNotSelectCustomerAddress = false,
  nearestStoreBranches,
) => {
  const filteredBranches = nearestStoreBranches?.map((branch) => ({
    branchId: branch?.branchId,
    distance: branch?.distance,
    lat: branch?.lat,
    lng: branch?.lng,
  }));
  const response = await branchDataService.getBranchesByCustomerAddressAsync(
    lat,
    lng,
    isNotSelectCustomerAddress,
    JSON.stringify(filteredBranches),
  );
  return response;
};
