import styled from "styled-components";
import theme from "theme";

export const ItemStepGuidelineStyle = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  border-radius: 12px 0 0 12px;
  background: ${(p) => (p.isSelected ? theme.colors.default.white : "unset")};
  transition: background 0.2s ease, box-shadow 0.2s ease;
  ${(p) =>
    p.isSelected &&
    `
      -webkit-box-shadow: inset 2px 0px 0px 0px ${theme.colors.primary[50]};
      -moz-box-shadow: inset 2px 0px 0px 0px ${theme.colors.primary[50]};
      box-shadow: inset 2px 0px 0px 0px ${theme.colors.primary[50]};
    `}

  @media (max-width: ${theme.screen.xl}) and (min-width: ${theme.screen.sm}) {
    align-items: flex-start;
  }
  @media (max-width: 576px) {
    border-radius: 12px;
    max-height: 52px;
  }
`;

export const WrapperIconTick = styled.div`
  display: flex;
`;

export const CircleStep = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.primary[50]};
`;

export const WrapperStepNumberIcon = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: ${(p) => (p.isFinish ? theme.colors.primary.main : "unset")};
`;
