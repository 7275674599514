import { env } from "../env";
import { createHttp } from "../utils/http-common";

const controller = "file";

const uploadFileAsync = (data) => {
  const http = createHttp();
  const baseURL = `${env.REACT_APP_ROOT_DOMAIN}/api${env.API_VERSION ?? ''}`;
  return http.post(`/${controller}/upload`, data, {
    baseURL: baseURL,
  });
};

const fileDataService = {
  uploadFileAsync,
};

export default fileDataService;
