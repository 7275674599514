import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { OrderTypeConstantTranslate, OrderTypeConstants } from "constants/order-type-status.constants";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { formatDate, formatNumberDecimalOrInteger, getCurrency } from "utils/helpers";
import "./detail-fee.component.scss";

const { Text } = Typography;

export default function FeeDetailComponent(props) {
  const [t] = useTranslation();
  const { isModalVisible, closeFeeDetailModal, feeDetail, actionButtons } = props;

  const pageData = {
    titleDetailFee: t("feeAndTax.titleViewDetail"),
    feeName: t("feeAndTax.createFee.feeName"),
    feeValue: t("feeAndTax.table.value"),
    enterFeeName: t("feeAndTax.createFee.enterFeeName"),
    percentIcon: "%",
    autoApplied: t("feeAndTax.autoApplied"),
    servingTypes: t("feeAndTax.createFee.servingTypes"),
    branchName: t("feeAndTax.createFee.branchName"),
    allBranches: t("feeAndTax.createFee.allBranches"),
    description: t("feeAndTax.createFee.description"),
    percent: t("feeAndTax.percent"),
    table: {
      no: t("feeAndTax.table.no"),
      codeBranch: t("feeAndTax.table.codeBranch"),
      nameBranch: t("feeAndTax.table.nameBranch"),
      listBranch: t("feeAndTax.table.listBranch"),
    },
    start: t("feeAndTax.table.start"),
    end: t("feeAndTax.table.end"),
    orderTypeNames: Object.keys(OrderTypeConstants),
    cancelButton: t("button.cancel"),
    btnStop: t("button.stop"),
    btnDelete: t("button.delete"),

    instore: t("feeAndTax.servingType.instore"),
    takeAway: t("feeAndTax.servingType.takeAway"),
    delivery: t("feeAndTax.servingType.delivery"),
    online: t("feeAndTax.servingType.online"),
    leaveButton: t("button.leave"),
    tooltipAutoApplied: t("feeAndTax.createFee.tooltipAutoApplied"),
  };

  const isModalLeaveOnly = actionButtons?.delete.hasPermission === false && actionButtons?.stop.hasPermission === false;
  const buttonLeaveClasses = () => {
    return isModalLeaveOnly ? `ant-btn ant-btn-primary` : `ant-btn fee-detail__cancel__button`;
  };

  const columnFees = [
    {
      title: pageData.table.no,
      dataIndex: "no",
      key: "no",
      width: "10%",
      align: "center",
      render: (_, record, index) => <p>{index + 1}</p>,
    },
    {
      title: pageData.table.codeBranch,
      dataIndex: "code",
      key: "code",
      width: "30%",
      align: "center",
    },
    {
      title: pageData.table.nameBranch,
      dataIndex: "name",
      key: "name",
      width: "60%",
      align: "center",
    },
  ];

  const servingTypeOptions = pageData.orderTypeNames
    .filter((key) => OrderTypeConstants[key] !== OrderTypeConstants.Online)
    .map((name) => {
      return { label: t(OrderTypeConstantTranslate[name]), value: OrderTypeConstants[name] };
    });

  const renderContent = () => {
    return (
      <>
        {/* NAME */}
        <div className="row">
          <Row className="pb-1">
            <Text className="row__title">{pageData.feeName}</Text>
          </Row>
          <Row>
            <Text className="row__detail">{feeDetail?.name}</Text>
          </Row>
        </div>

        {/* VALUE */}
        {feeDetail?.isPercentage ? (
          <div className="row">
            <Row className="pb-1">
              <Text className="row__title">{pageData.feeValue}</Text>
            </Row>
            <Row>
              <Text className="row__detail">
                {feeDetail?.value} {pageData.percentIcon}
              </Text>
            </Row>
          </div>
        ) : (
          <div className="row">
            <Row className="pb-1">
              <Text className="row__title">{pageData.feeValue}</Text>
            </Row>
            <Row>
              <Text className="row__detail">{formatNumberDecimalOrInteger(feeDetail?.value) + getCurrency()}</Text>
            </Row>
          </div>
        )}

        {/* START/END DATE */}
        <Row>
          <Col span={12}>
            {feeDetail?.startDate && (
              <div className="row">
                <Row className="pb-1">
                  <Text className="row__title">{pageData.start}</Text>
                </Row>
                <Row>
                  <Text className="row__detail">{formatDate(feeDetail?.startDate)}</Text>
                </Row>
              </div>
            )}
          </Col>
          <Col span={12}>
            <div className="row">
              <Row className="pb-1">
                <Text className="row__title">{pageData.end}</Text>
              </Row>
              <Row>
                <Text className="row__detail">
                  {feeDetail?.endDate === undefined ? "-" : formatDate(feeDetail?.endDate)}
                </Text>
              </Row>
            </div>
          </Col>
        </Row>

        {/* AUTO APPLIED */}
        <Space style={{ marginBottom: 20 }}>
          <FnbCheckBox className="w-100" checked={feeDetail?.isAutoApplied} disabled></FnbCheckBox>
          <FnbTypography variant="b2-regular" color="#00000040" text={pageData.autoApplied} />
          <FnbTooltip
            placement="top"
            title={
              <FnbTypography
                color={theme.colors.default.white}
                variant={theme.typography["b2-regular"]}
                text={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: pageData.tooltipAutoApplied,
                    }}
                  />
                }
              />
            }
            overlayInnerStyle={{ width: "300px" }}
          >
            <FnbTypography
              color="#00000040"
              variant={theme.typography["h3-regular"]}
              text={<ExclamationCircleOutlined />}
            />
          </FnbTooltip>
        </Space>

        {/* SERVING TYPES */}
        <div className="row">
          <Row className="pb-1">
            <Text className="row__title">{pageData.servingTypes}</Text>
          </Row>
          <Row>
            <FnbCheckBox.Group
              options={servingTypeOptions}
              className="servingTypes__container"
              disabled
              value={feeDetail?.servingTypes.map((type) => {
                return type.code;
              })}
            />
          </Row>
        </div>

        {/* BRANCHES */}
        <div className="row">
          <Row className="pb-1">
            <Text className="row__title">{pageData.branchName}</Text>
          </Row>
          <Row>
            {feeDetail?.isShowAllBranches === true ? (
              <Text className="row__detail">{pageData.allBranches}</Text>
            ) : (
              <ul className="feeBranches__container">
                {feeDetail?.feeBranches.map((branch) => {
                  return <li className="feeBranches__item">{branch.name}</li>;
                })}
              </ul>
            )}
          </Row>
        </div>

        {/* DESCRIPTION */}
        <div className="row">
          <Row className="pb-1">
            <Text className="row__title">{pageData.description}</Text>
          </Row>
          <Row>
            {feeDetail?.description !== "" && feeDetail?.description !== undefined ? (
              <p className="row__detail">{feeDetail?.description}</p>
            ) : (
              "-"
            )}
          </Row>
        </div>
      </>
    );
  };

  const renderActionButtons = () => {
    return (
      <Space>
        <FnbButton
          minWidth={"120px"}
          variant={isModalLeaveOnly ? "primary" : "tertiary"}
          text={isModalLeaveOnly ? pageData.leaveButton : pageData.cancelButton}
          onClick={closeFeeDetailModal}
        />
        {actionButtons?.delete.hasPermission && (
          <FnbButton minWidth={"120px"} text={pageData.btnDelete} danger onClick={actionButtons?.delete.onDeleteFee} />
        )}
        {actionButtons?.stop.hasPermission && (
          <FnbButton minWidth={"120px"} onClick={actionButtons?.stop.onStopFee} text={pageData.btnStop} />
        )}
      </Space>
    );
  };

  return (
    <>
      <FnbModal
        closable={false}
        width={"778px"}
        visible={isModalVisible}
        title={pageData.titleDetailFee}
        cancelText={pageData.cancel}
        handleCancel={closeFeeDetailModal}
        content={renderContent()}
        okText={pageData.titleDetailFee}
        onOk={() => {}}
        footer={renderActionButtons()}
      />
    </>
  );
}
