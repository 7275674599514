import { StampTemplatePreviewComponent } from "pages/settings/stamp/components/stamp-template-preview.component";
import { useTranslation } from "react-i18next";

const StampPreview = ({ stampConfigs, stampData }) => {
  const { t } = useTranslation();
  return (
    <div className="stamp-preview-setting">
      <div
        className="stamp-preview-setting-description"
        dangerouslySetInnerHTML={{ __html: t("productManagement.otherSetting.stampPreviewDescription") }}
      ></div>
      <div className="stamp-preview-setting-template">
        {stampConfigs && stampData && (
          <StampTemplatePreviewComponent stampConfig={stampConfigs} stampData={stampData} />
        )}
      </div>
    </div>
  );
};

export default StampPreview;
