/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Form, InputNumber, Radio, Row, message, Space } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbFroalaEditor from "components/fnb-froala-editor";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { ActivateStatus } from "constants/activate-status.constants";
import { TableName } from "constants/areaTable.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { SizeScreen } from "constants/size-screen.constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "utils/check-screen";
import { checkOnKeyPressValidation } from "utils/helpers";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import SelectEditComponent from "components/select-edit-new-item/select-edit-new-item";
import { LockMultipleCalls } from "themes/services/promotion.services";
import areaDataService from "data-services/area/area-data.service";
import "./edit-table.component.scss";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import { UploadLogoIcon } from "constants/icons.constants";

export default function EditAreaTable(props) {
  const { isModalVisible, listArea, storeBranchId, onCancel, areaTableDataService, handleDelete, selectedAreaTable } =
    props;
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initTableImage, setInitTableImage] = useState(null);
  const [descriptionAreaTable, setDescriptionAreaTable] = useState("");
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const checkScreen = useWindowDimensions();
  const widthModal = checkScreen === SizeScreen.IS_TABLET ? 984 : 1336;
  const imageRef = React.useRef(null);
  const selectNewItemFuncs = React.useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [id, setId] = useState(null);
  const [nameTable, setNameTable] = useState(null);
  const [isAreaNameExisted, setIsAreaNameExisted] = useState(false);
  const [nameArea, setNameArea] = useState("");
  const [listAreaNew, setListAreaNew] = useState(null);
  const [newTableName, setNewTableName] = useState("");

  const pageData = {
    active: t("areaTable.active"),
    inActive: t("areaTable.inactive"),
    titleUpdateTable: t("areaTable.tableForm.titleUpdateTable"),
    areaName: t("areaTable.areaForm.areaName"),
    selectArea: t("areaTable.areaForm.selectArea"),
    pleaseSelectArea: t("areaTable.areaForm.pleaseSelectArea"),
    updateTable: t("areaTable.tableForm.updateTable"),
    nameTable: t("areaTable.tableForm.nameTable"),
    namePlaceholder: t("areaTable.tableForm.namePlaceholder"),
    numberOfSeat: t("areaTable.tableForm.numberSeat"),
    validNumberSeat: t("areaTable.tableForm.validNumberSeat"),
    addNew: t("button.addNew"),
    update: t("button.update"),
    cancel: t("button.cancel"),
    updateTableSuccess: t("areaTable.updateTableSuccess"),
    updateTableFail: t("areaTable.updateTableFail"),
    status: t("areaTable.status"),
    max: 999999999,
    image: t("areaTable.image"),
    upload: {
      addFile: t("productManagement.generalInformation.uploadImages"),
      addFromUrl: t("upload.addFromUrl"),
    },
    deActive: t("button.deactive"),
    delete: t("button.delete"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    tableNameMaxLength: 50,
    description: t("form.description"),
    nameTableRequired: t("areaTable.tableForm.nameTableIsRequired"),
    areaNameExisted: t("areaTable.areaForm.AreaNameExisted"),
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    var req = {
      ...values,
      areaId: selectedArea,
      imageUrl: image?.url || values?.imageUrl,
      isActive: isActive,
      shortDescription: values?.description?.replace(/<.*?>/gm, "").slice(0, 200),
    };
    const res = await areaTableDataService.updateAreaTableByAreaIdAsync(req);
    if (res) {
      message.success(pageData.updateTableSuccess);
    } else {
      message.error(pageData.updateTableFail);
    }
    setIsLoading(false);
    handleCancelAndResetForm();
  };

  useEffect(() => {
    if (!isModalVisible) {
      setNewTableName("");
    }
  }, [isModalVisible]);

  useEffect(() => {
    setListAreaNew(listArea);
  }, [listArea]);

  useEffect(() => {
    setTimeout(() => {
      getEditData(selectedAreaTable);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAreaTable]);

  useEffect(() => {
    if (initTableImage != null) {
      setImage(initTableImage);
      imageRef.current?.setImage(initTableImage);
    }
  }, [initTableImage]);

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const getEditData = (data) => {
    if (data) {
      form.setFieldsValue({
        id: data?.id,
        storeBranchId: storeBranchId,
        areaId: data?.areaId,
        isActive: data?.isActive,
        name: data?.name,
        numberOfSeat: data?.numberOfSeat,
        description: data?.description,
        imageUrl: data?.imageUrl,
      });
      setId(data.id);
      setIsActive(data?.isActive);
      setSelectedArea(data?.areaId);
      setDescriptionAreaTable(data?.description);
      setNameTable(data?.name);
      if (data?.imageUrl) {
        setInitTableImage(data?.imageUrl);
        setImage(data?.imageUrl);
        if (imageRef && imageRef?.current) {
          imageRef?.current?.setImage(data?.imageUrl);
        }
      } else {
        setInitTableImage(null);
        setImage(null);
      }

      if (selectNewItemFuncs.current) {
        selectNewItemFuncs.current(data?.areaId);
        setIsAreaNameExisted(true);
      }
    }
  };

  const handleCancelAndResetForm = () => {
    setNewTableName("");
    setIsLoading(false);
    setFormDataChanged(false);
    setInitTableImage(null);
    setImage(null);
    imageRef?.current?.setImage(null);
    form.resetFields();
    onCancel(selectedArea);
  };

  const onUploadImage = (file) => {
    setFormDataChanged(true);
    setImage(file);
  };

  const onHandleDelete = async () => {
    await setFormDataChanged(false);
    handleDelete(id);
  };

  const onRenameAreaTable = (event) => {
    setNameTable(event?.target?.value);
    setNewTableName(event.target.value);
  };

  const onChangeArea = (id, listAreaPresent) => {
    // Creates default table name when the user not input table name
    let tableName = newTableName;
    if (!tableName) {
      const listArea = listAreaPresent || listAreaNew;
      const area = listArea?.find((area) => area?.id === id);
      const count = area?.areaTables?.length ?? 0;
      tableName = `${TableName} ${count + 1}`;
    }
    
    if (tableName) {
      form.setFieldsValue({
        name: tableName,
        areaId: id,
      });
      setSelectedArea(id);
      setFormDataChanged(true);
      if (selectNewItemFuncs.current) {
        selectNewItemFuncs.current(id);
      }
    }
  };

  //Enter Area name and check existed
  const onNameChange = (value) => {
    if (listAreaNew?.filter((area) => area?.name?.trim() === value?.trim())?.length > 0) {
      setIsAreaNameExisted(true);
    } else {
      setIsAreaNameExisted(false);
    }
    setNameArea(value);
  };

  //Handle add New Area
  const addNewArea = async (e) => {
    if (nameArea) {
      e.preventDefault();
      let req = {
        storeBranchId: storeBranchId,
        name: nameArea,
      };
      LockMultipleCalls(
        async () => {
          const res = await areaDataService.createAreaManagementAsync(req);
          if (res?.succeeded) {
            const id = res?.data?.id;
            const name = res?.data?.name;
            const newItem = {
              id: id,
              name: name,
              areaTables: [],
            };
            const newData = listAreaNew ? [newItem, ...listAreaNew] : [newItem];
            setListAreaNew(newData);
            onChangeArea(id, newData);
            setFormDataChanged(true);
          } else {
            message.error(pageData.areaNameExisted);
          }
        },
        "Lock_createNewArea",
        1000,
      );
    }
  };

  const renderInputImage = () => {
    return (
      <Col xl={7} md={7} xs={24} className="w-100">
        <Row className="upload-product-image">
          <FnbTypography text={pageData.image} variant={theme.typography["b1-medium"]} />
          <Row className={`non-image ${image !== null ? "have-image" : ""}`} style={{ marginTop: 8 }}>
            <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
              <Form.Item name={"imageUrl"}>
                <FnbCustomUploadImageComponent
                  ref={imageRef}
                  iconUpload={<UploadLogoIcon />}
                  buttonText={pageData.upload.addFile}
                  onChange={onUploadImage}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Col>
    );
  };

  return (
    <FnbModal
      className="modal-add-table"
      title={pageData.titleUpdateTable}
      handleCancel={handleCancelAndResetForm}
      visible={isModalVisible}
      footer={(null, null)}
      width={widthModal}
      hideFooter
      content={
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFieldsChange={() => {
            if (formDataChanged === false) {
              setFormDataChanged(true);
            }
          }}
          className="custom-form"
          layout="vertical"
        >
          <Row gutter={16}>
            {checkScreen === SizeScreen.IS_MOBILE ? renderInputImage() : null}
            <Col xl={17} md={17} xs={24} className="container-input">
              <Space className="w-100" direction="vertical" size={24}>
                <Row gutter={[12, 24]}>
                  <Col span={12} xs={24} md={12} xl={12}>
                    <Form.Item
                      name={"name"}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.nameTableRequired} />,
                        },
                      ]}
                      className="w-100"
                      label={pageData.nameTable}
                    >
                      <FnbInput
                        showCount
                        className="w-100"
                        placeholder={pageData.namePlaceholder}
                        maxLength={pageData.tableNameMaxLength}
                        onChange={onRenameAreaTable}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} md={12} xl={12}>
                    <Form.Item name="isActive" className="checkbox-status" label={pageData.status}>
                      <Radio.Group onChange={(e) => setIsActive(e.target.value)} value={isActive}>
                        <Radio value={ActivateStatus.Activate}>
                          <FnbTypography text={pageData.active} />
                        </Radio>
                        <Radio value={ActivateStatus.Deactivate}>
                          <FnbTypography text={pageData.inActive} />
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[12, 24]} className="box-area-seat">
                  <Col span={12} xs={24} md={12} xl={12} className="dropdown-area">
                    <Form.Item name={"areaId"} className="w-100" label={pageData.areaName}>
                      <SelectEditComponent
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showSearch
                        value={selectedArea}
                        functions={selectNewItemFuncs}
                        placeholder={pageData.selectArea}
                        pleaseEnterName={pageData.pleaseSelectArea}
                        nameExisted={pageData.areaNameExisted}
                        btnAddNew={pageData.addNew}
                        listOption={listAreaNew?.map((item) => ({
                          id: item?.id,
                          name: item?.name,
                        }))}
                        onChangeOption={(id) => onChangeArea(id)}
                        onSearch={onNameChange}
                        addNewItem={addNewArea}
                        isNameExisted={isAreaNameExisted}
                        name={nameArea}
                        isEditProduct={true}
                        category={pageData.areaName}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} md={12} xl={12} className="input-seat">
                    <Form.Item
                      name={"numberOfSeat"}
                      rules={[
                        {
                          validator(rule, value) {
                            if (value == null || value === undefined || value < 1) {
                              return Promise.reject(<InputValidateMessage message={pageData.validNumberSeat} />);
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                      className="w-100"
                      label={pageData.numberOfSeat}
                    >
                      <InputNumber
                        id="minQuantity"
                        className="w-100 fnb-input-number"
                        placeholder={pageData.numberOfSeat}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onKeyPress={(event) => {
                          const checkValidKey = checkOnKeyPressValidation(event, "minQuantity", 0, pageData.max, 0);
                          if (!checkValidKey) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name={"description"} label={pageData.description} style={{ minHeight: "470px" }}>
                  <FnbFroalaEditor
                    value={descriptionAreaTable}
                    onChange={(value) => {
                      if (value !== "" && value !== "<div></div>");
                      setDescriptionAreaTable(value);
                    }}
                    charCounterMax={-1}
                    heightMin={300}
                    heightMax={300}
                  />
                </Form.Item>
              </Space>
            </Col>
            {checkScreen !== SizeScreen.IS_MOBILE ? renderInputImage() : null}
          </Row>
          <Row className="float-center justify-content-center" style={{ marginTop: 24 }}>
            <div className="d-flex flex-wrap text-white">
              <div class="p-2 flex-fill">
                <DeleteConfirmComponent
                  buttonText={pageData.delete}
                  buttonType={"NotIcon"}
                  title={pageData.confirmDelete}
                  content={formatDeleteMessage(nameTable)}
                  okText={pageData.btnDelete}
                  className={"delete-button"}
                  cancelText={pageData.btnIgnore}
                  permission={PermissionKeys.DELETE_AREA_TABLE}
                  cancelButtonProps={{
                    style: { border: "none !important", color: "none !important" },
                  }}
                  onOk={onHandleDelete}
                />
              </div>
              <FnbCancelButton
                buttonText={pageData.cancel}
                className="mr-1 width-100 btnCancel"
                key="back"
                showWarning={formDataChanged}
                onOk={handleCancelAndResetForm}
              />
              <FnbButton type="submit" disabled={!formDataChanged || isLoading} text={pageData.update} />
            </div>
          </Row>

          <Form.Item name="storeBranchId" hidden="true"></Form.Item>
          <Form.Item name={"id"} hidden="true"></Form.Item>
        </Form>
      }
    />
  );
}
