import { Col, Form, message, Row } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbPageHeader } from "components/fnb-page-header/fnb-page-header";
import { DELAYED_TIME } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import menuManagementDataService from "data-services/menu-management/menu-management-data.service";
import pageDataService from "data-services/page/page-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDate } from "utils/helpers";
import "./edit-page-management.page.scss";
import { FnbButton } from "components/fnb-button/fnb-button";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbFroalaEditor from "components/fnb-froala-editor";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";

export default function EditPageManagement(props) {
  const { t } = useTranslation();
  const { history } = props;
  const [initData, setInitData] = useState("");
  const lastSavedTimeDefault = formatDate(new Date(), DateFormat.DD_MMM_YYYY);
  const state = useSelector((state) => state);
  const userFullName = state?.session?.currentUser?.fullName;
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState(false);
  const [messageDeleteConfirmPopup, setMessageDeleteConfirmPopup] = useState(null);
  const [textBtnOkDeleteConfirmPopup, setTextBtnOkDeleteConfirmPopup] = useState(null);
  const [isShowBtnCancelDeleteConfirmPopup, setIsShowBtnCancelDeleteConfirmPopup] = useState(false);
  const [titlePopupDeletePage, setTitlePopupDeletePage] = useState();
  const [pageIdValue, setPageIdValue] = useState();
  const [menuList, setMenuList] = useState([]);
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [pageContentEditor, setPageContentEditor] = useState("");

  const pageData = {
    btnCancel: t("button.cancel"),
    btnUpdate: t("button.update"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),

    editPage: t("onlineStore.pageManagement.editPage"),
    pageName: t("onlineStore.pageManagement.pageName"),
    createPageInfo: t("onlineStore.pageManagement.createPageInfo"),
    pageNamePlaceholder: t("onlineStore.pageManagement.pageNamePlaceholder"),
    pageNameValidation: t("onlineStore.pageManagement.pageNameValidation"),
    pageContent: t("onlineStore.pageManagement.pageContent"),
    pageContentValidation: t("onlineStore.pageManagement.pageContentValidation"),
    updatePageSuccess: t("onlineStore.pageManagement.updatePageSuccess"),
    updatePageFailed: t("onlineStore.pageManagement.updatePageFailed"),
    createdBy: t("onlineStore.pageManagement.createdBy"),
    createdTime: t("onlineStore.pageManagement.createdTime"),
    updatedTime: t("onlineStore.pageManagement.updatedTime"),
    generalInformation: t("title.generalInformation"),
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    titleDeleteConfirmPopup: t("messages.pageConfirmationDeleteTitle"),
    titleWarningPopup: t("messages.warning"),
    btnOkDeleteConfirmPopup: t("button.delete"),
    btnIgnoreDeleteConfirmPopup: t("button.deletePageIgnore"),
    btnIGotItDeleteConfirmPopup: t("button.iGotIt"),
    pageLinkedToMultipleMenuItem: t("messages.pageLinkedToMultipleMenuItem"),
    pageDeletedSuccessfully: t("messages.pageDeletedSuccessfully"),
    pageDeletedNotSuccessfully: t("messages.pageDeletedNotSuccessfully"),
    pageContentPlaceholder: t("onlineStore.pageManagement.pageContentPlaceholder"),
    btnSaveChanges: t("button.saveChanges"),
  };

  useEffect(() => {
    getInitDataAsync();
  }, []);

  const getInitDataAsync = async () => {
    const { id } = props?.match?.params;
    let promises = await pageDataService.getPageByIdAsync(id);
    form.setFieldsValue(promises.page);

    setInitData(promises.page);
    setPageContentEditor(promises?.page?.pageContent);
  };

  const onChangePageContentEditor = (value) => {
    if (value !== "" && value !== "<div></div>") setIsChangeForm(true);
    if (pageContentEditor !== value && pageContentEditor !== "") {
      setIsChangeForm(true);
    }
    setPageContentEditor(value);
    form.setFieldValue("pageContent", value);
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      let request = {
        page: {
          ...values,
          pageContent: pageContentEditor,
        },
      };
      let res = await pageDataService.updatePageAsync(request);
      if (res) {
        message.success(pageData.updatePageSuccess);
        redirectToPageManagement();
      } else {
        message.error(pageData.updatePageFailed);
      }
    });
  };

  const onDeletePage = async () => {
    const menuItemList = await menuManagementDataService.getMenuItemReferenceToPageByPageIdAsync(initData?.id);
    let messageDeleteConfirm = <></>;
    let menuItemUrl = "";
    if (menuItemList?.length > 0 && menuItemList?.length <= 1) {
      menuItemUrl = `/menu-management/edit/${menuItemList[0]?.menuId}`;
      messageDeleteConfirm = t("messages.pageLinkedToMenuItem", {
        menuItemName: menuItemList[0]?.menuItemName,
        menuItemUrl: menuItemUrl,
      });
      setTitlePopupDeletePage(pageData.titleWarningPopup);
      setTextBtnOkDeleteConfirmPopup(pageData.btnIGotItDeleteConfirmPopup);
      setIsShowBtnCancelDeleteConfirmPopup(false);
    } else if (menuItemList?.length > 1) {
      messageDeleteConfirm = (
        <>
          <span>{pageData.pageLinkedToMultipleMenuItem}</span>
          <div className="message-confirm-group-menu-item">
            {menuItemList?.map((menuItem) => {
              menuItemUrl = `/menu-management/edit/${menuItem?.menuId}`;
              return (
                <div className="message-confirm-sub">
                  <span className="linked-menu-item-name-dot"></span>
                  <a href={menuItemUrl} className="linked-menu-item-name" target="_blank" rel="noreferrer">
                    {menuItem?.menuItemName}
                  </a>
                </div>
              );
            })}
          </div>
        </>
      );
      setTitlePopupDeletePage(pageData.titleWarningPopup);
      setTextBtnOkDeleteConfirmPopup(pageData.btnIGotItDeleteConfirmPopup);
      setIsShowBtnCancelDeleteConfirmPopup(false);
    } else if (menuItemList?.length <= 0) {
      messageDeleteConfirm = t(`messages.pageConfirmDeleteMessage`, {
        pageName: initData?.pageName,
        pageUrl: menuItemUrl,
      });
      setTitlePopupDeletePage(pageData.titleDeleteConfirmPopup);
      setTextBtnOkDeleteConfirmPopup(pageData.btnOkDeleteConfirmPopup);
      setIsShowBtnCancelDeleteConfirmPopup(true);
    }

    setMenuList(menuItemList);
    setMessageDeleteConfirmPopup(messageDeleteConfirm);
    setPageIdValue(initData?.pageId);
    setIsShowDeleteConfirmPopup(true);
  };

  const onCancelDeletePage = () => {
    setIsShowDeleteConfirmPopup(false);
  };

  const onOkDeletePage = async () => {
    const pageDeleteResult = await pageDataService.deletePageByIdAsync(initData?.id);
    if (pageDeleteResult?.isSuccess) {
      redirectToPageManagement();
      message.success(pageData.pageDeletedSuccessfully);
    } else {
      setIsShowDeleteConfirmPopup(false);
      if (pageDeleteResult?.message?.length > 0) {
        message.error(t(pageDeleteResult?.message));
      } else {
        message.error(pageData.pageDeletedNotSuccessfully);
      }
      message.error(t(pageDeleteResult?.message));
    }
  };

  const redirectToPageManagement = () => {
    setIsShowDeleteConfirmPopup(false);
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/online-store/page-management");
    }, DELAYED_TIME);
  };

  return (
    <>
      <FnbPageHeader
        actionDisabled={!isChangeForm}
        title={pageData.editPage}
        isCompactButtons={false}
        actionButtons={[
          {
            action: <FnbButton onClick={onFinish} text={pageData.btnSaveChanges} disabled={!isChangeForm} />,
            permission: PermissionKeys.EDIT_PAGE,
          },
          {
            action: <FnbButton text={pageData.btnDelete} variant="tertiary" danger={true} onClick={onDeletePage} />,
            permission: PermissionKeys.DELETE_PAGE,
          },
          {
            action: <FnbCancelButton showWarning={isChangeForm} onOk={redirectToPageManagement} />,
          },
        ]}
      />
      <Form
        form={form}
        className="create-qr-code custom-form"
        layout="vertical"
        autoComplete="off"
        onFieldsChange={() => setIsChangeForm(true)}
      >
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <FnbCard title={pageData.generalInformation}>
              <Row gutter={[16, 16]}>
                <Col sm={24} lg={24} className="w-100">
                  <Form.Item
                    name="pageName"
                    label={pageData.pageName}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pageNameValidation}
                          />
                        ),
                      },
                    ]}
                  >
                    <FnbInput showCount placeholder={pageData.pageNamePlaceholder} maxLength={100} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col sm={24} lg={24} className="w-100">
                  <Form.Item
                    name="pageContent"
                    label={pageData.pageContent}
                    value={pageContentEditor}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pageContentValidation}
                          />
                        ),
                      },
                    ]}
                  >
                    {/* Content */}
                    <div className="footer__content">
                      <div className="footer__content_editor">
                        <FnbFroalaEditor
                          value={pageContentEditor}
                          placeholder={pageData.pageContentPlaceholder}
                          onChange={(value) => onChangePageContentEditor(value)}
                          charCounterMax={-1}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FnbCard>
          </Col>
          <Col span={8}>
            <FnbCard title={pageData.createPageInfo}>
              <Row gutter={[24, 24]}>
                <Col sm={24} lg={24} className="w-100">
                  <Row className="mb-2">
                    <Col span={24}>
                      <div className="left-column">{pageData.createdBy}</div>
                      <div className="right-column">
                        <div className="fnb-form-label-right">{userFullName ? userFullName : "-"}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={24}>
                      <div className="left-column">{pageData.createdTime}</div>
                      <div className="right-column">
                        <div className="fnb-form-label-right">
                          {initData?.createdTime ? formatDate(initData?.createdTime, DateFormat.DD_MMM_YYYY) : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="left-column">{pageData.updatedTime}</div>
                      <div className="right-column">
                        <div className="fnb-form-label-right">{lastSavedTimeDefault ? lastSavedTimeDefault : "-"}</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FnbCard>
          </Col>
        </Row>
        <Form.Item name={"id"} hidden="true"></Form.Item>
      </Form>

      <ConfirmDialogComponent
        title={titlePopupDeletePage}
        visible={isShowDeleteConfirmPopup}
        okText={textBtnOkDeleteConfirmPopup}
        cancelText={pageData.btnIgnore}
        onCancel={onCancelDeletePage}
        type={ConfirmStyle.DELETE}
        onOk={isShowBtnCancelDeleteConfirmPopup === true ? onOkDeletePage : onCancelDeletePage}
        contentElement={
          menuList?.length <= 1 ? (
            <div
              className="content-delete-page-popup"
              dangerouslySetInnerHTML={{ __html: `${messageDeleteConfirmPopup}` }}
            ></div>
          ) : (
            <div className="content-delete-page-popup">{messageDeleteConfirmPopup}</div>
          )
        }
      />
    </>
  );
}
