import styled from "styled-components";
import theme from "theme";

export const HeadingFreeTrialStyled = styled.div`
  background: ${theme.colors.primary.main};
  height: 60px;
`;

export const HeadingFreeTrialContent = styled.div`
  display: flex;
  column-gap: 24px;
  height: 100%;
  justify-content: center;
  align-items: center;

`;
