import { Card } from "antd";
import taxDataService from "data-services/tax/tax-data.service";
import React from "react";
import { useTranslation } from "react-i18next";
import CreateNewTax from "./components/create-tax.component";
import TableTax from "./components/table-tax-management.component";

export default function TaxManagement(props) {
  const { visibleAddTax, onChangeVisibleAddTax } = props;
  const [t] = useTranslation();
  const tableFuncs = React.useRef(null);

  const onCompletedAddNewTax = () => {
    onChangeVisibleAddTax && onChangeVisibleAddTax(false);
    tableFuncs.current();
  };

  const onCancelAddNewTax = () => {
    onChangeVisibleAddTax && onChangeVisibleAddTax(false);
  };

  return (
    <>
      <Card className="fnb-card-custom">
        <TableTax
          t={t}
          taxDataService={taxDataService}
          tableFuncs={tableFuncs}
          handleAddNewTax={onChangeVisibleAddTax}
        />
      </Card>
      <CreateNewTax
        t={t}
        isModalVisible={visibleAddTax}
        handleCompleted={onCompletedAddNewTax}
        handleCancel={onCancelAddNewTax}
      />
    </>
  );
}
