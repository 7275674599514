import { Row, Col } from "antd";
import { DateFormat } from "constants/string.constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./summary-shift.component.scss";
import reportDataService from "data-services/report/report-data.service";
import { BagStickIcon, MoneyIcon, ReceiptShiftIcon, DiscountShapeIcon } from "constants/icons.constants";
import { getCurrencyWithSymbol, getCurrency } from "utils/helpers";
import CardSummaryShift from "./card-summary-shift/index";

export default function SummaryShift(props) {
  const [t] = useTranslation();
  const { selectedDate, typeOptionDate, branchId } = props;
  const [dataSummary, setDataSummary] = useState({});

  const pageData = {
    summaryTitle: {
      order: t("report.shift.order"),
      soldProducts: t("report.shift.soldProducts"),
      discount: t("report.shift.totalDiscount"),
      grossRevenue: t("report.shift.grossRevenue"),
    },
    orders: t("report.shift.orders", "Orders"),
    products: t("report.shift.products", "Products"),
  };

  useEffect(() => {
    if (selectedDate || branchId || typeOptionDate)
      getShiftsSummaryByFilterAsync(branchId, selectedDate, typeOptionDate);
  }, [selectedDate, branchId, typeOptionDate]);

  const getShiftsSummaryByFilterAsync = async (branchId, date, typeOptionDate) => {
    let startDate = moment(date?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(date?.endDate).format(DateFormat.MM_DD_YYYY);
    var timeZone = new Date().getTimezoneOffset() / 60;
    await reportDataService
      .getShiftsSummaryAsync({
        branchId: branchId,
        startDate: startDate,
        endDate: endDate,
        typeOptionDate: typeOptionDate,
        timeZone: timeZone,
      })
      .then((response) => {
        setDataSummary(response);
      });
  };

  return (
    <Row className="summary-shift" gutter={[16, 16]}>
      <Col span={24} sm={12} xl={6}>
        <CardSummaryShift
          summaryItem={dataSummary?.order}
          title={pageData.summaryTitle.order}
          icon={<ReceiptShiftIcon />}
          suffixData={pageData.orders}
        />
      </Col>
      <Col span={24} sm={12} xl={6}>
        <CardSummaryShift
          summaryItem={dataSummary?.soldProducts}
          title={pageData.summaryTitle.soldProducts}
          icon={<BagStickIcon />}
          suffixData={pageData.products}
        />
      </Col>
      <Col span={24} sm={12} xl={6}>
        <CardSummaryShift
          summaryItem={dataSummary?.discount}
          title={pageData.summaryTitle.discount}
          icon={<DiscountShapeIcon />}
          suffixData={getCurrency()}
        />
      </Col>
      <Col span={24} sm={12} xl={6}>
        <CardSummaryShift
          summaryItem={dataSummary?.grossRevenue}
          title={pageData.summaryTitle.grossRevenue}
          icon={<MoneyIcon />}
          suffixData={getCurrency()}
        />
      </Col>
    </Row>
  );
}
