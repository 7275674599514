import http from "../../utils/http-common";

const controller = "package";

const getPackagesPricingAsync = () => {
  return http.get(`/${controller}/get-packages-pricing`);
};

const createBankTransferPaymentAsync = (data) => {
  return http.post(`/${controller}/create-bank-transfer-payment`, data);
};

const createVNPayRequestAsync = (data) => {
  return http.post(`/${controller}/create-vnpay-payment`, data);
};

const updateVNPayAsync = (data) => {
  return http.post(`/${controller}/update-vnpay-payment`, data);
};

const getListPackageOrderAsync = () => {
  return http.get(`/${controller}/get-list-package-order`);
};

const checkStoreAppAvailable = () => {
  return http.get(`/${controller}/check-storeapp-available`);
};

const createStorePackagePayPalPayment = (data) => {
  return http.post(`/${controller}/store-package/paypal`, data);
};

const getPackagesAsync = () => {
  return http.get(`/${controller}`);
}

const getFunctionAsync = () => {
  return http.get(`/${controller}/get-functions`);
}

const checkStoreWebAvailableAsync = () => {
  return http.get(`/${controller}/check-store-web-available`);
};

const packageDataService = {
  getPackagesPricingAsync,
  createBankTransferPaymentAsync,
  createVNPayRequestAsync,
  updateVNPayAsync,
  getListPackageOrderAsync,
  checkStoreAppAvailable,
  createStorePackagePayPalPayment,
  getPackagesAsync,
  getFunctionAsync,
  checkStoreWebAvailableAsync
};

export default packageDataService;
