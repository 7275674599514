import { Select } from "antd";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { ImageMaterialDefault, SearchIcon } from "constants/icons.constants";
import { formatNumberDecimalOrInteger } from "utils/helpers";
import "./fnb-select-material.scss";
import classNames from "classnames";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { Container, WrapperLeftColumn, WrapperRightColumn } from "./styled";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

export default function FnbSelectMaterialComponent({
  t,
  onChangeEvent,
  materialList,
  className,
  controlId,
  status,
  nameMaterial = {
    maxWidthName: 450,
    maxWidthTooltip: 350,
  },
}) {
  const isMobile = useMediaQuery({ maxHeight: 930 });

  const pageData = {
    searchMaterial: t("productManagement.recipe.placeholder"),
    table: {
      inventory: t("table.inventory"),
    },
  };

  const classNameSelect = classNames({
    "fnb-select-single-admin": true,
    [`${className}`]: true,
    "fnb-select-single-admin--show-prefix-icon": true,
  });

  return (
    <>
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        value={null}
        placeholder={pageData.searchMaterial}
        showSearch={true}
        onChange={onChangeEvent}
        className={classNameSelect}
        suffixIcon=""
        filterOption={(input, option) => {
          const inputStr = input.removeVietnamese().trim().toLowerCase();
          return (
            option?.sku?.removeVietnamese().trim().toLowerCase().indexOf(inputStr) >= 0 ||
            option?.name?.removeVietnamese().trim().toLowerCase().indexOf(inputStr) >= 0
          );
        }}
        listHeight={isMobile ? 250 : 500}
        placement="bottomLeft"
        id={controlId}
        status={status}
        prefixCls=""
        notFoundContent={<FnbEmpty />}
      >
        {materialList?.map((item) => (
          <Option key={item?.id} sku={item?.sku} name={item?.name}>
            <Container>
              <WrapperLeftColumn>
                {item?.thumbnail ? (
                  <Thumbnail src={item?.thumbnail} width={56} height={56} />
                ) : (
                  <ImageMaterialDefault width={56} height={56} />
                )}
                <div>
                  <FnbTooltip
                    onlyShowWhenEllipsis
                    title={item?.name}
                    width={nameMaterial.maxWidthTooltip}
                    maxWidthContent={nameMaterial.maxWidthName}
                  >
                    <FnbTypography variant={theme.typography["b1-bold"]} text={item?.name} />
                  </FnbTooltip>
                  <FnbTooltip
                    onlyShowWhenEllipsis
                    title={item?.sku}
                    width={nameMaterial.maxWidthTooltip}
                    maxWidthContent={nameMaterial.maxWidthName}
                  >
                    <FnbTypography text={item?.sku} color={theme.colors.gray[60]} />
                  </FnbTooltip>
                </div>
              </WrapperLeftColumn>
              <WrapperRightColumn>
                <FnbTypography text={pageData.table.inventory + ":"} color={theme.colors.gray[60]} />
                <FnbTypography
                  variant={theme.typography["b1-bold"]}
                  text={`${item?.quantity ? formatNumberDecimalOrInteger(item?.quantity) : 0} (${item?.unitName})`}
                />
              </WrapperRightColumn>
            </Container>
          </Option>
        ))}
      </Select>
      <SearchIcon className={`fnb-select-single-admin__icon-prefix`} />
    </>
  );
}
