import { Col, Form, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { useState } from "react";
import MemberModal from "../member-modal";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { useHistory } from "react-router-dom";

export default function TablePermissionGroup(props) {
  const { t, onEditItem } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [listmember, setListMember] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const history = useHistory();

  const pageData = {
    no: t("table.no"),
    groupName: t("settings.groupPermissionTable.groupName"),
    member: t("settings.groupPermissionTable.member"),
    createdBy: t("settings.groupPermissionTable.createdBy"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  //name columns Permission Group
  const columnsPermissionGroup = [
    {
      title: pageData.no,
      dataIndex: "no",
      key: "no",
      width: "5rem",
    },
    {
      title: pageData.groupName,
      dataIndex: "groupName",
      key: "groupName",
      width: "50%",
      ellipsis: true,
      render: (value, record) => {
        return (
          <FnbTooltip className="fnb-text-nowrap" title={value} onlyShowWhenEllipsis>
            <FnbTypography text={value} />
          </FnbTooltip>
        );
      },
    },
    {
      title: pageData.member,
      dataIndex: "member",
      key: "member",
      width: "20%",
      render: (_, record) => <span>{record?.member}</span>,
    },
    {
      title: pageData.createdBy,
      dataIndex: "createdBy",
      key: "createdBy",
    },
  ];

  const handleClickGroupMember = (record) => {
    const dataMember = [
      {
        staffCode: "ST-1001",
        staffName: "Nguyen Van A",
      },
    ];
    setListMember(dataMember);
    setIsModalVisible(true);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onRemoveItem = (record) => {
    /// TODO: remove item
  };

  const onRemoveMember = (record) => {
    /// TODO: remove member
  };

  return (
    <Form className="form-staff">
      <Row gutter={[24, 24]} className="row-reverse">
        <Col md={24} lg={24} xl={4}>
          <div className="action-add-new-button">{props?.renderAddNewButton && props?.renderAddNewButton()}</div>
        </Col>
        <Col md={24} lg={24} xl={20}></Col>
      </Row>
      <Row>
        <FnbTable
          className="fnb-table form-table table-permission"
          columns={columnsPermissionGroup}
          dataSource={props.dataSource}
          pageSize={props.pageSize}
          currentPageNumber={props?.currentPage}
          total={props.total}
          onChangePage={props.onChangePage}
          numberRecordCurrent={() => {
            let numberRecordCurrent = props?.currentPage * props.pageSize ?? 0;
            if (numberRecordCurrent > props.total) {
              numberRecordCurrent = props.total;
            }
            return numberRecordCurrent;
          }}
          search={{
            placeholder: t("settings.searchGroupPermissionPlaceHolder"),
            onChange: props.onSearch,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditItem,
          }}
          emptyText={
            <div className="content-collapse-empty-text">
              <FnbTypography.Link
                variant={theme.typography["b1-bold-underlined"]}
                underline={true}
                text={pageData.addNew}
                onClick={() => history.push("/permission-group/create-new")}
              />
              {pageData.toCreateData}
            </div>
          }
        />
      </Row>
      <MemberModal
        listmember={listmember}
        isModalVisible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        onRemoveMember={onRemoveMember}
      />
    </Form>
  );
}
