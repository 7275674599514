const AddressCheckIcon = ({ fillColor = "#BBAC5E" }) => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00015 10.17L2.53015 6.69999C2.34317 6.51301 2.08957 6.40797 1.82515 6.40797C1.56072 6.40797 1.30712 6.51301 1.12015 6.69999C0.933168 6.88696 0.828125 7.14056 0.828125 7.40499C0.828125 7.53592 0.853914 7.66557 0.904019 7.78653C0.954124 7.90749 1.02756 8.0174 1.12015 8.10999L5.30015 12.29C5.69015 12.68 6.32015 12.68 6.71015 12.29L17.2901 1.70999C17.4771 1.52301 17.5822 1.26941 17.5822 1.00499C17.5822 0.74056 17.4771 0.486964 17.2901 0.299986C17.1032 0.113008 16.8496 0.00796509 16.5851 0.00796509C16.3207 0.00796509 16.0671 0.113008 15.8801 0.299986L6.00015 10.17Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default AddressCheckIcon;
