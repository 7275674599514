import React from "react";
import { Card } from "antd";
import TableCustomerMemberShip from "./components/table-customer-membership.component";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

/**
 * Page Membership Management
 * description: page manage customer primary template
 */
export default function CustomerMembershipManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    membershipManagement: t("membership.title"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    btnAddNew: t("button.addNew"),
  };

  const gotoAddNewMembershipLevelPage = () => {
    history.push("/membership/create");
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.membershipManagement}
        permissionCreate={PermissionKeys.CREATE_MEMBERSHIP_LEVEL}
        onCreate={() => gotoAddNewMembershipLevelPage()}
      />
      <div className="clearfix"></div>
      <Card className="fnb-card-custom">
        <TableCustomerMemberShip />
      </Card>
    </>
  );
}
