import { getInternationalRegion } from "../../../utils/helpers";
import areaDefaultImage from "../../assets/images/area-default-image.jpg";
import areaTableDefaultImage from "../../assets/images/area-table-default-image.jpg";
import defaultTable from "../../assets/images/default-table.jpg";

const isInternationalRegion = getInternationalRegion();

export const areasDefaultLocal = [
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a94d1",
    name: "Sân Thượng",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 5,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec72",
        name: "Table 4",
        numberOfSeat: 5,
        imageUrl: defaultTable,
        description: "Table 4",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec22",
        name: "Table 5",
        numberOfSeat: 8,
        imageUrl: defaultTable,
        description: "Table 5",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec42",
        name: "Table 6",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 6",
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a80d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a24d1",
    name: "Trệt",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 5,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec72",
        name: "Table 4",
        numberOfSeat: 5,
        imageUrl: defaultTable,
        description: "Table 4",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec22",
        name: "Table 5",
        numberOfSeat: 8,
        imageUrl: defaultTable,
        description: "Table 5",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec42",
        name: "Table 6",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 6",
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a90d1",
    name: "Floor 1",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 2,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 4,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 4,
        imageUrl: defaultTable,
        description: "Table 3",
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a90d2",
    name: "Floor 2",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 2,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 4,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 4,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece3",
        name: "Table 4",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a90d3",
    name: "Floor 3",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 2,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 4,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 4,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece3",
        name: "Table 4",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
    ],
  },
];

export const areasDefaultInternational = [
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a94d1",
    name: "Terrace",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 5,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec72",
        name: "Table 4",
        numberOfSeat: 5,
        imageUrl: defaultTable,
        description: "Table 4",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec22",
        name: "Table 5",
        numberOfSeat: 8,
        imageUrl: defaultTable,
        description: "Table 5",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec42",
        name: "Table 6",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 6",
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a80d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a24d1",
    name: "Ground floor",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 5,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec72",
        name: "Table 4",
        numberOfSeat: 5,
        imageUrl: defaultTable,
        description: "Table 4",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec22",
        name: "Table 5",
        numberOfSeat: 8,
        imageUrl: defaultTable,
        description: "Table 5",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bec42",
        name: "Table 6",
        numberOfSeat: 9,
        imageUrl: defaultTable,
        description: "Table 6",
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a90d1",
    name: "Floor 1",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 2,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 4,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 4,
        imageUrl: defaultTable,
        description: "Table 3",
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a90d2",
    name: "Floor 2",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 2,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 4,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 4,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece3",
        name: "Table 4",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
    ],
  },
  {
    storeId: "6e529eb3-59eb-4106-847f-f29e0ebc85a1",
    storeBranchId: "8320d46a-a286-4d7c-851d-0d0f103a90d7",
    id: "8320d46a-a286-4d7c-851d-0d0f103a90d3",
    name: "Floor 3",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    tables: [
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece6",
        name: "Table 1",
        imageUrl: defaultTable,
        numberOfSeat: 2,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece1",
        name: "Table 2",
        imageUrl: defaultTable,
        numberOfSeat: 4,
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece2",
        name: "Table 3",
        numberOfSeat: 4,
        imageUrl: defaultTable,
        description: "Table 3",
      },
      {
        id: "ad0dbe86-c6ff-4aa9-910a-a11b397bece3",
        name: "Table 4",
        imageUrl: defaultTable,
        numberOfSeat: 8,
      },
    ],
  },
];

export const areasDefault = isInternationalRegion ? areasDefaultInternational : areasDefaultLocal;
export const defaultImageReservation = {
  icon: {
    area: areaDefaultImage,
    areaTable: areaTableDefaultImage,
  },
};
