import styled from 'styled-components';


export const Container = styled.div`
    position: relative;

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        box-shadow: none;
        border-color: #50429B !important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #d8dae1 !important;
        border-radius: 12px;
        box-shadow: none !important;
    }

    .ant-select-multiple .ant-select-selector {
        padding: 0 6px;
    }

    .ant-select-multiple .ant-select-selection-placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: ${(props) => props.theme.colors.text.main};
    }

    .ant-select-multiple .ant-select-selection-search-input,
    .ant-select-multiple .ant-select-selection-search-mirror {
        height: 38px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: ${(props) => props.theme.colors.text.main};
    }

    .ant-select-multiple .ant-select-selection-item {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 32px;
        background: transparent;
        border: 1px solid transparent;
        cursor: pointer;       
        background-color: #E9E5FF;
        padding-inline-start: 5px;
        border-radius: 8px;

        .ant-select-selection-item-content {
            margin: 0;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: ${(props) => props.theme.colors.text.main};
        } 
        
        .ant-select-selection-item-remove {
            fill: #50429B;
        }
    }

    .ant-select-selection-overflow {
        gap: 8px;
        .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-rest):not(.ant-select-selection-overflow-item-suffix):not(:last-child) {
            max-width: 100%;
        }   
    } 

    .ant-select-multiple {
        gap: 8px;
        .ant-select-arrow {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(25%, -25%);
            color: ${(props) => props.theme.colors.primary.main};
        }
    }

    .ant-select-focused {
        .ant-select-selection-placeholder {
            display: none;
        }
    }

    .ant-select-multiple.ant-select-allow-clear {
        &:hover {
            .ant-select-arrow {
                visibility: hidden;
            }  
        }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 600;
    }
`;

export const Menus = styled.div`
    .ant-select-item {
        gap: 8px;
        padding: 0;
        margin: 0 6px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; 
        letter-spacing: 0.3px;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        border-radius: 8px;
        background-color: #E9E5FF;

        .ant-select-item-option-state .anticon-check {
            margin: 0 6px;
        }
    }

    .ant-select-item-option-state {
        display: flex;
        align-items: center;
    }
`
export const TooltipContent = styled.ul`
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`

export const NoDataFounded = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 60px;
        height: 60px;
    }

    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: ${(props) => props.theme.colors.text.placeholder};
    }
`