import { Card, Col, Image, Row, Space, message } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import defaultUserIcon from "assets/images/default-user.png";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import PageTitle from "components/page-title";
import { ExportIcon } from "constants/icons.constants";
import { images } from "constants/images.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { orderStatusLocalization } from "constants/order-status.constants";
import { OrderServiceTypeLocalization } from "constants/order-type-status.constants";
import { paymentMethodType } from "constants/payment-method.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { Platform, platformNames } from "constants/platform.constants";
import { TYPE } from "constants/report.constants";
import { ClassicMember, DateFormat } from "constants/string.constants";
import orderDataService from "data-services/order/order-data.service";
import reportDataService from "data-services/report/report-data.service";
import storeDataService from "data-services/store/store-data.service";
import { useRedirect } from "hooks/useRedirect";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import languageService from "services/language/language.service";
import theme from "theme";
import { capitalize } from "utils";
import {
  executeAfter,
  formatCurrencyWithoutSuffix,
  formatDate,
  formatPhoneNumber,
  getColorForChart,
  getCurrency,
  handleDownloadFile,
  hasPermission,
} from "utils/helpers";
import FilterOrderReport from "./filter-order-report.component";
import "./index.scss";
import TransactionPieChart from "./transaction-pie-chart/index";

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_KEY_SEARCH = "";
export default function TableOrder(props) {
  const [t] = useTranslation();
  const {
    currentPageNumber,
    pageSize,
    keySearch,
    setOrderTransactionReport,
    mappingToDataTable,
    setKeySearch,
    setCurrentPageNumber,
    setPageSize,
    orderManagementReportFilters,
  } = props;
  const [countFilter, setCountFilter] = useState(0);
  const { handleRedirect, target } = useRedirect();
  const pageData = {
    createdTime: t("report.staff.createdTime"),
    id: t("table.id"),
    status: t("table.status"),
    type: t("table.type"),
    detail: t("table.detail"),
    customer: t("table.customer"),
    rank: t("customer.rank"),
    point: t("customer.point"),
    total: t("table.total"),
    paymentMethod: t("payment.paymentMethod"),
    discount: t("promotion.table.discount"),
    grossTotal: t("table.grossTotal"),
    subtotal: t("table.subtotal"),
    feeAndTax: t("order.feeAndTax"),
    shippingFee: t("order.shippingFee"),
    txt_reduce: t("dashboard.txt_reduce"),
    txt_increase: t("dashboard.txt_increase"),
    allBranch: t("dashboard.allBranch"),
    date: {
      yesterday: t("dashboard.compareDate.yesterday"),
      previousDay: t("dashboard.compareDate.previousDay"),
      lastWeek: t("dashboard.compareDate.lastWeek"),
      previousWeek: t("dashboard.compareDate.previousWeek"),
      lastMonth: t("dashboard.compareDate.lastMonth"),
      previousMonth: t("dashboard.compareDate.previousMonth"),
      lastYear: t("dashboard.compareDate.lastYear"),
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    noDataFound: t("table.noDataFound"),
    topSellingProductTitle: t("dashboard.topSellingProduct.title"),
    topSellingProductSeemore: t("dashboard.topSellingProduct.seemore"),
    topSellingProductItems: t("dashboard.topSellingProduct.items"),
    topCustomerTitle: t("dashboard.topCustomer.title"),
    btnExport: t("button.export"),
    totalOrder: t("order.totalOrder"),
    canceled: t("order.canceled"),
    inStore: t("order.inStore"),
    takeAway: t("order.takeAway"),
    goFnBApp: t("order.goFnBApp"),
    storeWeb: t("order.storeWeb"),
    storeApp: t("order.storeApp"),
    summary: t("report.summary"),
    orderManagement: t("order.orderManagement"),
    search: t("order.placeholderSearchByOrderId"),
    allTypes: t("order.allTypes"),
    allMethods: t("order.allMethods"),
    allCustomer: t("order.allCustomer"),
    all: t("order.all"),
    platform: t("platform.title"),
    orderByPlatform: t("platform.orderByPlatform"),
    serviceType: t("reportRevenue.serviceType"),
    orderByServiceType: t("reportRevenue.orderByServiceType"),
    orderByStatus: t("reportRevenue.orderByStatus"),
    no: t("table.no"),
    orderId: t("order.orderId"),
    totalBill: t("order.totalBill"),
    availablePoint: t("order.availablePoint"),
    viewDetail: t("marketing.emailCampaign.summary.viewDetail"),
    exportSuccessful: t("messages.exportSuccessful"),
  };
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const [branchId, setBranchId] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [revenueByPlatform, setRevenueByPlatform] = useState([]);
  const [revenueByServiceType, setRevenueByServiceType] = useState([]);
  const [revenueByOrderStatus, setRevenueByOrderStatus] = useState([]);
  const [pageNumberFilter, setPageNumberFilter] = useState(null);
  const filterOrderReportRef = useRef(null);
  const [listOrders, setListOrder] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [salesChannel, setSalesChannel] = useState([]);

  useEffect(() => {
    setPageNumberFilter(currentPageNumber);
    getOrderTransactionPieChartByFilter(branchId, selectedDate, typeOptionDate);
    const payloadTableOrder = {
      branchId: branchId,
      date: selectedDate,
      typeOptionDate: typeOptionDate,
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      keySearch: DEFAULT_KEY_SEARCH,
    };
    getOrderReportByFilterAsync(payloadTableOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderStatusLocal = {
    0: t("orderStatus.new"),
    1: t("orderStatus.returned"),
    2: t("orderStatus.canceled"),
    3: t("orderStatus.toConfirm"),
    4: t("orderStatus.processing"),
    5: t("orderStatus.delivering"),
    6: t("orderStatus.completed"),
    7: t("orderStatus.draft"),
  };

  const orderType = {
    0: t("serviceType.inStore"),
    1: t("serviceType.delivery"),
    2: t("serviceType.takeAway"),
    3: t("serviceType.delivery"),
    4: t("serviceType.pickup"),
  };

  const renderCustomerItem = (data) => {
    return (
      <>
        <div style={{ width: "370px" }}>
          <Space direction="vertical" size={12} className="w-100">
            <Space size={12}>
              <Image
                src={data?.thumbnail ?? "error"}
                width={80}
                height={80}
                style={{ borderRadius: 12, objectFit: "cover" }}
                fallback={images.imgDefault}
              />
              <Space size={4} direction="vertical">
                <div className="order-report-transaction-rank">
                  <Paragraph
                    className="paragraph-rank"
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    <FnbTooltip onlyShowWhenEllipsis={true} title={data.rank ? data.rank : ClassicMember}>
                      <span className="text-name">{data.rank ? data.rank : ClassicMember}</span>
                    </FnbTooltip>
                  </Paragraph>
                </div>
                <FnbTypography text={data?.fullName} variant={theme.typography["b1-bold"]} />
                <FnbTypography
                  variant={theme.typography["b1-regular"]}
                  color={theme.colors.gray[70]}
                  text={formatPhoneNumber(data?.phoneCode, data.phoneNumber)}
                />
              </Space>
            </Space>
            <FnbTypography
              variant={theme.typography["b1-regular"]}
              text={`${pageData.availablePoint}: ${data.availablePoint}`}
            />
            <FnbButton
              width="100%"
              text={pageData.viewDetail}
              variant="secondary-purple"
              onClick={() => handleOnCustomerClick(data)}
            />
          </Space>
        </div>
      </>
    );
  };

  const renderPaymentDetailItem = (data) => {
    return (
      <>
        <div style={{ width: "370px", paddingLeft: "8px", paddingRight: "8px" }}>
          <Row className="order-report-detail">
            <Col span={12}>{`${pageData.subtotal}:`}</Col>
            <Col span={12} className="amount-text">
              {formatCurrencyWithoutSuffix(data?.grossTotal)}
            </Col>
          </Row>
          <Row className="order-report-detail">
            <Col span={12}>{`${pageData.discount}:`}</Col>
            <Col span={12} className="amount-text">
              -{formatCurrencyWithoutSuffix(data.discount)}
            </Col>
          </Row>
          <Row className="order-report-detail">
            <Col span={12}>{`${pageData.feeAndTax}:`}</Col>
            <Col span={12} className="amount-text">
              {formatCurrencyWithoutSuffix(data.totalFeeTax + data.deliveryFee)}
            </Col>
          </Row>
          <Row className="order-report-detail">
            <Col span={12}>{`${capitalize(pageData.paymentMethod)}:`}</Col>
            <Col span={12} className="amount-text">
              {data.paymentMethod}
            </Col>
          </Row>
          <hr />
          <Row className="order-report-total">
            <Col span={12}>
              <b>{`${pageData.total}:`}</b>
            </Col>
            <Col span={12} className="amount-text">
              <b>{formatCurrencyWithoutSuffix(data.totalAmount)}</b>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FnbButton
                width="100%"
                text={pageData.viewDetail}
                variant="tertiary"
                className="order-report-transaction-popup-detail-btn"
                onClick={() => handleOnRowClick(data)}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const getColumnOrder = [
    {
      title: pageData.orderId,
      dataIndex: "code",
      key: "code",
      width: "8rem",
      align: "center",
      render: (_, { code }) => {
        return <span>{code}</span>;
      },
    },
    {
      title: pageData.createdTime,
      dataIndex: "createdTime",
      align: "left",
      width: "12rem",
      render: (_, { createdTime }) => {
        return formatDate(createdTime, DateFormat.HH_mm_DD_MMM_YYYY);
      },
    },
    {
      title: pageData.type,
      dataIndex: "orderTypeName",
      key: "orderTypeName",
      align: "center",
      width: "11rem",
      render: (_, { orderTypeId }) => {
        return orderType[orderTypeId];
      },
    },
    {
      title: pageData.customer,
      dataIndex: "customer",
      key: "customer",
      width: "25rem",
      align: "left",
      render: (_, record) => {
        record = record ?? {};
        return record.customerId ? (
          <Row>
            <Col span={2}>
              <Image
                src={record?.thumbnail ?? "error"}
                width={52}
                height={52}
                style={{ borderRadius: 4, objectFit: 'cover' }}
                fallback={images.imgDefault}
              />
            </Col>
            <Col span={22} style={{ paddingLeft: "20px" }}>
              <Row style={{ marginTop: "-3px" }}>
                <FnbChip
                  isVisible={true}
                  title={<FnbTypography.Link text={record?.fullName} variant={theme.typography["b1-bold"]} />}
                  detail={renderCustomerItem(record)}
                  variant={"secondary"}
                  isDisplay={true}
                  placement={"bottomLeft"}
                  isBorder={false}
                  maxWidth={400}
                ></FnbChip>
              </Row>
              <Row style={{ marginTop: "3px", marginLeft: "10px" }}>
                <FnbTypography
                  variant={theme.typography["b1-regular"]}
                  color={theme.colors.gray[70]}
                  text={formatPhoneNumber(record?.phoneCode, record.phoneNumber)}
                />
              </Row>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={2}>
              <Image
                src={defaultUserIcon ?? "error"}
                width={52}
                height={52}
                style={{ borderRadius: 4 }}
                fallback={images.imgDefault}
              />
            </Col>
            <Col span={22} style={{ paddingLeft: "16px" }}>
              <Row style={{ marginLeft: "10px" }}>
                <FnbTypography text="Guest" />
              </Row>
              <Row style={{ marginLeft: "10px", marginTop: "-3px" }}>
                <FnbTypography text="N/A" />
              </Row>
            </Col>
          </Row>
        );
      },
    },
    {
      title: `${pageData.totalBill}(${getCurrency()})`,
      dataIndex: "detail",
      key: "detail",
      width: "25rem",
      align: "right",
      ellipsis: true,
      render: (_, record) => {
        record = record ?? {};
        return (
          <>
            <Row style={{ justifyContent: "end" }}>
              <FnbChip
                isVisible={true}
                title={
                  <FnbTypography.Link
                    text={formatCurrencyWithoutSuffix(record.totalAmount)}
                    variant={theme.typography["b1-bold"]}
                  />
                }
                detail={renderPaymentDetailItem(record)}
                variant={"secondary"}
                isDisplay={true}
                placement={"bottomLeft"}
                isBorder={false}
                maxWidth={400}
              ></FnbChip>
            </Row>
            <Row style={{ justifyContent: "end", marginRight: "5px" }}>
              <FnbTypography
                text={record?.paymentMethodName}
                color={theme.colors.gray[70]}
                variant={theme.typography["b1-regular"]}
              />
            </Row>
          </>
        );
      },
    },
    {
      title: pageData.platform,
      dataIndex: "platformId",
      key: "platformId",
      width: "12rem",
      align: "left",
      render: (_, record) => {
        const id = record?.storeFoodyPlatformId ?? record?.platformId;
        const name = salesChannel.find((obj) => obj.id?.toLowerCase() === id?.toLowerCase())?.name;
        return <FnbTypography text={name} />;
      },
    },
    {
      title: pageData.status,
      dataIndex: "statusName",
      key: "statusName",
      align: "center",
      width: "11rem",
      render: (_, { statusId }) => {
        const orderStatusColor = {
          0: "new-order-color",
          1: "returned-order-color",
          2: "canceled-order-color",
          3: "to-confirm-order-color",
          4: "processing-order-color",
          5: "delivering-order-color",
          6: "completed-order-color",
          7: "draft-order-color",
        };
        return <span className={`order-status ${orderStatusColor[statusId]}`}>{`${orderStatusLocal[statusId]}`}</span>;
      },
    },
  ];

  const handleClearFilter = () => {
    filterOrderReportRef.current.clearFilter();
  };

  // #region handle Change Branch
  const handleChangeBranch = async (value) => {
    let branchIdSelected = value;
    if (branchIdSelected !== null) {
      setBranchId(branchIdSelected);
    } else {
      branchIdSelected = "";
      setBranchId(null);
    }
    getOrderInfoByFilter(branchIdSelected, selectedDate, typeOptionDate, pageNumberFilter, pageSize, keySearch);
    getOrderTransactionPieChartByFilter(branchIdSelected, selectedDate, typeOptionDate);
    setPageNumberFilter(DEFAULT_PAGE_NUMBER);
    setKeySearch(DEFAULT_KEY_SEARCH);
    const payloadTableOrder = {
      branchId: branchIdSelected,
      date: selectedDate,
      typeOptionDate: typeOptionDate,
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      keySearch: DEFAULT_KEY_SEARCH,
    };
    await getOrderReportByFilterAsync(payloadTableOrder);
    handleClearFilter();
  };

  const getOrderInfoByFilter = (branchId, date, typeOptionDate, currentPageNumber, pageSize, keySearch) => {
    let startDate = moment(date?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(date?.endDate).format(DateFormat.MM_DD_YYYY);
    let req = {
      branchId: branchId ?? "",
      startDate: startDate,
      endDate: endDate,
      typeOptionDate: typeOptionDate,
      pageNumber: currentPageNumber,
      pageSize: pageSize,
      keySearch: keySearch,
    };
    orderDataService.getOrderManagementAsync(req).then((res) => {
      if (res) {
        setOrderTransactionReport(res.orderTransactionReport);
      }
    });
  };

  const onSelectedDatePicker = async (date, typeOptionDate) => {
    setSelectedDate(date);
    setTypeOptionDate(typeOptionDate);
    getOrderInfoByFilter(branchId, date, typeOptionDate, pageNumberFilter, pageSize, keySearch);
    getOrderTransactionPieChartByFilter(branchId, date, typeOptionDate);
    setPageNumberFilter(DEFAULT_PAGE_NUMBER);
    setKeySearch(DEFAULT_KEY_SEARCH);
    const payloadTableOrder = {
      branchId: branchId,
      date: date,
      typeOptionDate: typeOptionDate,
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      keySearch: DEFAULT_KEY_SEARCH,
    };
    await getOrderReportByFilterAsync(payloadTableOrder);
    handleClearFilter();
  };

  const handleSearchOrder = async (keySearch = "") => {
    executeAfter(500, async () => {
      setKeySearch(keySearch);
      const payloadTableOrder = {
        branchId: branchId,
        date: selectedDate,
        typeOptionDate: typeOptionDate,
        pageNumber: DEFAULT_PAGE_NUMBER,
        keySearch: keySearch,
      };
      await getOrderReportByFilterAsync(payloadTableOrder);
    });
  };

  const handleChangePage = async (pageNumber, pageSize) => {
    setCurrentPageNumber(pageNumber);
    setPageSize(pageSize);
    const payloadTableOrder = {
      branchId: branchId,
      date: selectedDate,
      typeOptionDate: typeOptionDate,
      pageNumber: pageNumber,
      keySearch: keySearch,
    };
    await getOrderReportByFilterAsync(payloadTableOrder);
  };

  const getFilterParams = () => {
    const formValues = filterOrderReportRef.current?.getDataForm();
    let paymentMethodTypeId = paymentMethodType.All;
    const isEnterpriseMethod = paymentMethods?.some(
      (payment) => payment.type === paymentMethodType.Enterprise && payment.id === formValues?.paymentMethodId,
    );
    const isPersonalMethod = paymentMethods?.some(
      (payment) => payment.type === paymentMethodType.Personal && payment.id === formValues?.paymentMethodId,
    );
    paymentMethodTypeId = isEnterpriseMethod
      ? paymentMethodType.Enterprise
      : isPersonalMethod
      ? paymentMethodType.Personal
      : paymentMethodType.All;

    return {
      customerId: formValues?.customerId ?? "",
      orderStatusId: formValues?.orderStatusId ?? "",
      paymentMethodId: formValues?.paymentMethodId ?? "",
      paymentMethodTypeId: paymentMethodTypeId,
      serviceTypeId: formValues?.serviceTypeId ?? "",
      salesChannel: formValues?.salesChannel ?? "",
    };
  };

  // #region Get data table order management
  const getOrderReportByFilterAsync = async (data) => {
    let startDate = moment(data?.date?.startDate ? data?.date?.startDate : selectedDate.startDate).format(
      DateFormat.MM_DD_YYYY,
    );
    let endDate = moment(data?.date?.endDate ? data?.date?.endDate : selectedDate.endDate).format(
      DateFormat.MM_DD_YYYY,
    );

    const filterParams = getFilterParams();
    const params = {
      branchId: data?.branchId ? data?.branchId : branchId,
      startDate: startDate,
      endDate: endDate,
      typeOptionDate: data?.typeOptionDate ? data.typeOptionDate : typeOptionDate,
      pageNumber: data?.pageNumber ? data?.pageNumber : pageNumberFilter,
      pageSize: DEFAULT_PAGE_SIZE,
      keySearch: data?.keySearch !== undefined ? data.keySearch : keySearch,
      customerId: filterParams?.customerId,
      orderStatusId: filterParams?.orderStatusId,
      paymentMethodId: filterParams?.paymentMethodId,
      paymentMethodTypeId: filterParams?.paymentMethodTypeId,
      serviceTypeId: filterParams?.serviceTypeId,
      salesChannel: filterParams?.salesChannel,
    };
    await orderDataService.getOrderReportByFilterAsync(params).then((response) => {
      if (response) {
        setListOrder(mappingToDataTable(response.orders));
        setTotalOrder(response.total);
        setCurrentPageNumber(response.pageNumber);
      }
    });
  };

  const onClickFilterButton = async (event) => {
    if (orderManagementReportFilters.serviceTypes) {
      const allServiceType = {
        id: "",
        name: pageData.allTypes,
      };
      const serviceTypes = [allServiceType, ...orderManagementReportFilters.serviceTypes];
      setServiceTypes(serviceTypes);
    }

    if (orderManagementReportFilters.paymentMethods) {
      const allPaymentMethod = {
        id: "",
        name: pageData.allMethods,
        group: "",
        type: -1,
      };
      const paymentMethods = [allPaymentMethod, ...orderManagementReportFilters.paymentMethods];
      setPaymentMethods(paymentMethods);
    }

    if (orderManagementReportFilters.customers) {
      const allCustomer = {
        id: "",
        name: pageData.allCustomer,
      };
      const customers = [allCustomer, ...orderManagementReportFilters.customers];
      setCustomers(customers);
    }

    if (orderManagementReportFilters.orderStatus) {
      const allOrderStatus = {
        id: "",
        name: pageData.all,
      };
      const orderStatus = [allOrderStatus, ...orderManagementReportFilters.orderStatus];
      setOrderStatus(orderStatus);
    }
  };

  const onClearFilter = () => {
    filterOrderReportRef?.current?.clearFilter();
  };

  /* Transaction Order by Pie Chart */
  const getOrderTransactionPieChartByFilter = (branchId, date, typeOptionDate) => {
    let startDate = moment(date?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(date?.endDate).format(DateFormat.MM_DD_YYYY);
    let req = {
      branchId: branchId,
      startDate: startDate,
      endDate: endDate,
      typeOptionDate: typeOptionDate,
      type: TYPE.TRANSACTION,
    };
    orderDataService.getRevenueByTypeAsync(req).then((res) => {
      if (res) {
        // sort by descending by totalOrder
        res.revenueByPlatforms?.sort(function (a, b) {
          return b.totalOrder - a.totalOrder;
        });
        res.revenueByServiceTypes?.sort(function (a, b) {
          return b.totalOrder - a.totalOrder;
        });
        res.revenueByOrderStatus?.sort(function (a, b) {
          return b.totalOrder - a.totalOrder;
        });
        setRevenueByPlatform(res.revenueByPlatforms);
        onSetDataForServiceType(res.revenueByServiceTypes);
        onSetRevenueOrderStatus(res.revenueByOrderStatus);
      }
    });

    storeDataService.getAllPlatformActivatedAsync(false, true).then((resSalesChannel) => {
      resSalesChannel.platforms = resSalesChannel.platforms.filter(
        (item) => item.id.toLowerCase() !== Platform.AdminWebsite.toLowerCase(),
      );
      if (resSalesChannel) {
        const salesChannels = resSalesChannel.platforms;
        setSalesChannel(salesChannels);
      }
    });
  };

  const onSetDataForServiceType = (response) => {
    setRevenueByServiceType(
      [...response].map((item) => {
        return {
          ...item,
          name: OrderServiceTypeLocalization[item?.id],
        };
      }),
    );
  };

  const onSetRevenueOrderStatus = (response) => {
    setRevenueByOrderStatus(
      [...response].map((item) => {
        return {
          ...item,
          name: orderStatusLocalization[item?.id],
        };
      }),
    );
  };

  const getDataPlatformForPieChart = () => {
    const platformColor = getColorForChart(249, 100, 24, revenueByPlatform?.length);
    return revenueByPlatform?.map((item, index) => {
      item = item ?? {};
      return {
        label: item.name,
        value: item.totalOrder,
        color: platformColor[index],
        totalAmount: item.totalAmount,
        totalOrder: item.totalOrder,
      };
    });
  };

  const getDataServiceTypeForPieChart = () => {
    const serviceTypeColor = getColorForChart(29, 100, 50, revenueByServiceType?.length);
    return revenueByServiceType?.map((item, index) => {
      item = item ?? {};
      return {
        label: t(item?.name),
        value: item.totalOrder,
        color: serviceTypeColor[index],
        totalAmount: item.totalAmount,
        totalOrder: item.totalOrder,
      };
    });
  };

  const getDataOrderStatusColorForPieChart = () => {
    const statusColor = getColorForChart(249, 100, 24, revenueByOrderStatus?.length);
    return revenueByOrderStatus?.map((item, index) => {
      item = item ?? {};
      return {
        label: t(item?.name),
        value: item.totalOrder,
        color: statusColor[index],
        totalAmount: item.totalAmount,
        totalOrder: item.totalOrder,
      };
    });
  };

  const renderPieChartSummary = () => {
    return (
      <Row gutter={[24, 24]} className="order-summary-pie-chart">
        <Col xs={24} sm={24} md={24} lg={8} className="order-summary-pie-chart-item">
          <div className="pie-chart-element">
            <div className="pie-chart-element-wrapper pie-chart-order-by-platform">
              <TransactionPieChart
                dataSourceRevenue={revenueByPlatform}
                getDataForPieChart={getDataPlatformForPieChart()}
                titleBack={pageData.platform.toUpperCase()}
                chartName={pageData.orderByPlatform}
                width="120%"
                height="120%"
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} className="order-summary-pie-chart-item">
          {/* OrderByServiceType */}
          <div className="pie-chart-element">
            <div className="pie-chart-element-wrapper">
              <TransactionPieChart
                dataSourceRevenue={revenueByServiceType}
                getDataForPieChart={getDataServiceTypeForPieChart()}
                titleBack={pageData.serviceType.toUpperCase()}
                chartName={pageData.orderByServiceType}
                width="120%"
                height="120%"
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} className="order-summary-pie-chart-item">
          {/* OrderByStatus */}
          <div className="pie-chart-element">
            <div className="pie-chart-element-wrapper">
              <TransactionPieChart
                dataSourceRevenue={revenueByOrderStatus}
                getDataForPieChart={getDataOrderStatusColorForPieChart()}
                titleBack={pageData.status.toUpperCase()}
                chartName={pageData.orderByStatus}
                width="120%"
                height="120%"
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const handleOnCustomerClick = ({ customerId }) => {
    if (!customerId) return;
    if (hasPermission(PermissionKeys.VIEW_CUSTOMER)) {
      handleRedirect(`/customer/detail/${customerId}`, target.blank);
    }
  };

  const handleOnRowClick = ({ id }) => {
    if (!id) return;
    if (hasPermission(PermissionKeys.VIEW_ORDER_REPORT)) {
      handleRedirect(`/report/order/detail/${id}`, target.blank);
    }
  };

  const exportOrdersAsync = async () => {
    let languageCode = languageService.getLang();
    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);
    let timeZone = new Date().getTimezoneOffset() / 60;
    let param = {
      languageCode: languageCode,
      branchId: branchId,
      startDate: startDate,
      endDate: endDate,
      timeZone: timeZone,
      typeOptionDate: typeOptionDate,
    };
    try {
      var response = await reportDataService.exportOrdersAsync(param);
      handleDownloadFile(response);
      message.success(pageData.exportSuccessful);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };

  return (
    <React.Fragment>
      <Col span={24}>
        <Row gutter={[24, 24]} align="middle" justify="center" className="top-dashboard">
          <Col span={24}>
            <FnbHeadingPage
              title={pageData.summary}
              isCompactButtons={false}
              listButtons={[
                <FnbListBranches onChangeEvent={handleChangeBranch} />,
                <FnbDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
                />,
                <FnbButton
                  permission={PermissionKeys.EXPORT_ORDER_REPORT}
                  text={pageData.btnExport}
                  iconHeader={<ExportIcon />}
                  onClick={exportOrdersAsync}
                ></FnbButton>,
              ]}
            />
          </Col>
        </Row>
      </Col>
      <Row className="mt-4 mb-4">{renderPieChartSummary()}</Row>
      <Row className="fnb-row-page-header">
        <Col span={24}>
          <PageTitle content={pageData.orderManagement} />
        </Col>
      </Row>
      <Card className="fnb-card-custom w-100">
        <FnbTable
          className="report-transaction-order-table"
          columns={getColumnOrder}
          pageSize={pageSize}
          dataSource={listOrders}
          currentPageNumber={currentPageNumber}
          total={totalOrder}
          onChangePage={handleChangePage}
          search={{
            placeholder: pageData.search,
            onChange: handleSearchOrder,
          }}
          filter={{
            onClickFilterButton: onClickFilterButton,
            totalFilterSelected: countFilter,
            onClearFilter: onClearFilter,
            buttonTitle: pageData.btnFilter,
            component: (
              <FilterOrderReport
                className={"filter-order"}
                fetchDataOrderReport={getOrderReportByFilterAsync}
                serviceTypes={serviceTypes}
                paymentMethods={paymentMethods}
                customers={customers}
                orderStatus={orderStatus}
                setCountFilter={setCountFilter}
                typeOptionDate={typeOptionDate}
                ref={filterOrderReportRef}
                salesChannel={salesChannel}
              />
            ),
            isShowModelOnMoblie: true,
          }}
          onRowClick={handleOnRowClick}
        />
      </Card>
    </React.Fragment>
  );
}
