import { Tabs } from "antd";
import storeDataService from "data-services/store/store-data.service";
import { useTranslation } from "react-i18next";
import Display from "./components/display/display.component";
import "./general.component.scss";
import StoreGeneralConfiguration from "./components/store-general-configuration/store-general-configuration.component";

export default function General(props) {
  const [t, i18n] = useTranslation();

  return (
    <div>
      <StoreGeneralConfiguration t={t} i18n={i18n} storeDataService={storeDataService} {...props} />
    </div>
  );
}
