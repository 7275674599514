import { Typography } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { EnumTransferMaterialStatus } from "constants/transfer-material-history.constant";
import transferMaterialHistoryDataService from "data-services/transfer-material-history/transfer-material-history-data.service";
import moment from "moment";
import { FilterPopover } from "pages/inventory/transfer-material-history/components/filter-popover.component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { executeAfter, formatDate } from "utils/helpers";
import "./index.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function TableTransferMaterialHistory(props) {
  const [t] = useTranslation();
  const history = useHistory();

  const pageData = {
    transferMaterialHistory: t("transferMaterialHistory.title"),
    searchPlaceholder: t("transferMaterialHistory.searchPlaceholder"),
    showingRecordMessage: t("transferMaterialHistory.showingRecordMessage"),
    from: t("transferMaterialHistory.from"),
    destination: t("transferMaterialHistory.destination"),
    updatedBy: t("transferMaterialHistory.updatedBy"),
    status: t("transferMaterialHistory.status"),
    time: t("transferMaterialHistory.time"),
    lastUpdatedBy: t("transferMaterialHistory.lastUpdatedBy"),
    material: t("transferMaterialHistory.material"),
    transferId: t("transferMaterialHistory.id"),
    new: t("transferMaterialHistory.new"),
    inProgress: t("transferMaterialHistory.inProgress"),
    delivering: t("transferMaterialHistory.delivering"),
    completed: t("transferMaterialHistory.completed"),
    canceled: t("transferMaterialHistory.cancelled"),
    all: t("transferMaterialHistory.all"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    today: t("optionDatetime.today"),
    yesterday: "dashboard.compareDate.yesterday",
    filter: t("button.filter"),
    ingredient: t("transferMaterialHistory.ingredient"),
    to: t("transferMaterialHistory.to"),
    lastUploadBy: t("transferMaterialHistory.lastUploadBy"),
  };

  const filterPopoverRef = React.useRef();
  const [totalTransferMaterialHistory, setTotalTransferMaterialHistory] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [keySearch, setKeySearch] = useState("");
  const [showPopover, setShowPopover] = useState(true);
  const [countFilter, setCountFilter] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [fromWarehouseList, setFromWarehouseList] = useState([]);
  const [toWarehouseList, setToWarehouseList] = useState([]);
  const [updatedByList, setUpdatedByList] = useState([]);
  const [dataFilter, setDataFilter] = useState({});
  const [titleConditionCompare, setTitleConditionCompare] = useState(pageData.yesterday);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const isMobile = useMediaQuery({ maxWidth: 575 });

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  }, []);

  const getColumnsTransferMaterialHistory = () => {
    const columnsTransferMaterialHistory = [
      {
        title: pageData.time,
        dataIndex: "time",
        key: "time",
        width: 200,
        align: "left",
        className: "grid-text-column",
        render: (_, record) => {
          return <span>{formatDate(record?.createdTime, "HH:mm DD MMM YYYY")}</span>;
        },
      },
      {
        title: pageData.transferId,
        dataIndex: "transferId",
        key: "transferId",
        width: 165,
        align: "left",
        className: "grid-column-transfer-id grid-column-nowrap-text",
        render: (value, record) => {
          return <span>{value}</span>;
        },
      },
      {
        title: pageData.ingredient,
        dataIndex: "material",
        key: "material",
        align: "left",
        ellipsis: true,
        sorter: (pre, current) => pre?.quantityMaterial - current?.quantityMaterial,
        render: (_, record) => {
          return <span>{record?.quantityMaterial}</span>;
        },
      },
      {
        title: pageData.from,
        dataIndex: "from",
        key: "from",
        width: 242,
        align: "left",
        className: "grid-text-column",
        render: (_, record) => <FnbTooltip onlyShowWhenEllipsis={true}>{ record?.fromWarehouseName}</FnbTooltip>,
      },
      {
        title: pageData.to,
        dataIndex: "destination",
        key: "destination",
        width: 242,
        align: "left",
        className: "grid-text-column",
        render: (_, record) => <FnbTooltip onlyShowWhenEllipsis={true}>{record?.toWarehouseName}</FnbTooltip>,
      },
      {
        title: pageData.lastUploadBy,
        dataIndex: "lastUpdatedBy",
        key: "lastUpdatedBy",
        width: 200,
        align: "left",
        className: "grid-text-column grid-column-nowrap-text",
        render: (_, record) => <FnbTooltip onlyShowWhenEllipsis={true}>{record?.lastUpdatedName}</FnbTooltip>,
      },
      {
        title: pageData.status,
        dataIndex: "status",
        key: "status",
        width: "9rem",
        align: "center",
        className: "grid-text-column",
        render: (_, record) => {
          return renderTransferMaterialStatus(record?.statusId);
        },
      },
    ];

    return columnsTransferMaterialHistory;
  };

  const renderTransferMaterialStatus = (statusId) => {
    let variant;
    let text;
    switch (statusId) {
      case EnumTransferMaterialStatus.Inprogress:
        variant = "warning";
        text = pageData.inProgress;
        break;
      case EnumTransferMaterialStatus.Completed:
        variant = "success";
        text = pageData.completed;
        break;
      case EnumTransferMaterialStatus.Delivering:
        variant = "primary";
        text = pageData.delivering;
        break;
      case EnumTransferMaterialStatus.Canceled:
        variant = "error";
        text = pageData.canceled;
        break;
      default:
        variant = "disabled";
        text = pageData.new;
    }

    return (
      <div className="status-container">
        <FnbBadge variant={variant} text={text} />
      </div>
    );
  };

  const fetchDatableAsync = async (
    pageNumber,
    pageSize,
    keySearch,
    fromWarehouseId,
    toWarehouseId,
    updatedId,
    statusId,
    startDate,
    endDate,
  ) => {
    const response = await transferMaterialHistoryDataService.getAllTransferMaterialHistoryAsync(
      pageNumber,
      pageSize,
      keySearch,
      fromWarehouseId ?? "",
      toWarehouseId ?? "",
      updatedId ?? "",
      statusId ?? "",
      startDate ?? "",
      endDate ?? "",
    );
    const data = mappingToDataTableTransferMaterialHistory(response?.transferMaterialHistories);
    setTotalTransferMaterialHistory(response?.totalRecords);
    setCurrentPageNumber(pageNumber);
    setDataSource(data);
  };

  const mappingToDataTableTransferMaterialHistory = (data) => {
    return data?.map((item, index) => {
      return {
        id: item?.id,
        transferMaterialId: item?.transferMaterialId,
        transferId: item?.transferCode,
        createdTime: item?.createdTime,
        fromWarehouseId: item?.fromWarehouseId,
        fromWarehouseName: item?.fromWarehouseName,
        fromWarehouseType: item?.fromWarehouseType,
        toWarehouseId: item?.toWarehouseId,
        toWarehouseName: item?.toWarehouseName,
        toWarehouseType: item?.toWarehouseType,
        quantityMaterial: item?.quantityMaterial,
        lastUpdatedId: item?.lastUpdatedId,
        lastUpdatedName: item?.lastUpdatedName,
        statusId: item?.statusId,
      };
    });
  };

  const onChangePage = async (pageNumber, pageSize) => {
    fetchDatableAsync(
      pageNumber,
      pageSize,
      keySearch,
      dataFilter?.fromWarehouseId,
      dataFilter?.toWarehouseId,
      dataFilter?.updatedId,
      dataFilter?.statusId,
      selectedDate?.startDate,
      selectedDate?.endDate,
    );
  };

  const onSearchTransferMaterialHistory = (keySearch) => {
    setKeySearch(keySearch);
    executeAfter(500, async () => {
      await fetchDatableAsync(
        1,
        tableSettings.pageSize,
        keySearch,
        dataFilter?.fromWarehouseId,
        dataFilter?.toWarehouseId,
        dataFilter?.updatedId,
        dataFilter?.statusId,
        selectedDate?.startDate,
        selectedDate?.endDate,
      );
    });
  };

  const filterComponent = () => {
    return (
      showPopover && (
        <FilterPopover
          fetchDataTransferMaterialHistory={handleFilter}
          fromWarehouseList={fromWarehouseList}
          toWarehouseList={toWarehouseList}
          updatedByList={updatedByList}
          ref={filterPopoverRef}
        />
      )
    );
  };

  const onClearFilter = async (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
  };

  const onClickFilterButton = async (event) => {
    if (!event?.defaultPrevented) {
      setShowPopover(true);
    }

    var resPlaceList = await transferMaterialHistoryDataService.getAllTransferMaterialPlaceAsync();
    if (resPlaceList) {
      const allFromOption = {
        id: "",
        name: pageData.all,
      };
      const placeIdList = [allFromOption, ...resPlaceList.placeList];
      setFromWarehouseList(placeIdList);
      setToWarehouseList(placeIdList);
    }

    var resUpdatedUserList = await transferMaterialHistoryDataService.getAllUpdatedUserTransferMaterial();
    if (resUpdatedUserList) {
      const allUpdatedByOption = {
        id: "",
        name: pageData.all,
      };
      const updatedIdList = [allUpdatedByOption, ...resUpdatedUserList.updatedIdList];
      setUpdatedByList(updatedIdList);
    }
  };

  const handleFilter = (data) => {
    setCountFilter(data?.count);
    setDataFilter(data);
    fetchDatableAsync(
      1,
      tableSettings.pageSize,
      keySearch,
      data?.fromWarehouseId,
      data?.toWarehouseId,
      data?.updatedId,
      data?.statusId,
      selectedDate?.startDate,
      selectedDate?.endDate,
    );
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    fetchDatableAsync(
      1,
      tableSettings.pageSize,
      keySearch,
      dataFilter?.fromWarehouseId,
      dataFilter?.toWarehouseId,
      dataFilter?.updatedId,
      dataFilter?.statusId,
      date?.startDate,
      date?.endDate,
    );
  };

  const onConditionCompare = (key) => {
    let titleConditionCompare = "";
    switch (key) {
      case OptionDateTime.today:
        titleConditionCompare = pageData.date.yesterday;
        break;
      case OptionDateTime.yesterday:
        titleConditionCompare = pageData.date.previousDay;
        break;
      case OptionDateTime.thisWeek:
        titleConditionCompare = pageData.date.lastWeek;
        break;
      case OptionDateTime.lastWeek:
        titleConditionCompare = pageData.date.previousWeek;
        break;
      case OptionDateTime.thisMonth:
        titleConditionCompare = pageData.date.lastMonth;
        break;
      case OptionDateTime.lastMonth:
        titleConditionCompare = pageData.date.previousMonth;
        break;
      case OptionDateTime.thisYear:
        titleConditionCompare = pageData.date.lastYear;
        break;
      default:
        break;
    }
    setTitleConditionCompare(titleConditionCompare);
  };

  const onRowClick = (record, _index) => {
    history.push(`/inventory/transfer-material/view/${record?.transferMaterialId}`);
  }

  return (
    <React.Fragment>
      <FnbTable
        className="table-transfer-material-history"
        columns={getColumnsTransferMaterialHistory()}
        pageSize={tableSettings.pageSize}
        dataSource={dataSource}
        currentPageNumber={currentPageNumber}
        total={totalTransferMaterialHistory}
        onChangePage={onChangePage}
        search={{
          placeholder: pageData.searchPlaceholder,
          onChange: onSearchTransferMaterialHistory,
        }}
        filter={{
          onClickFilterButton: onClickFilterButton,
          totalFilterSelected: countFilter,
          onClearFilter: onClearFilter,
          buttonTitle: pageData.filter,
          component: filterComponent(),
        }}
        calendarComponent={{
          onSelectedDatePicker: onSelectedDatePicker,
          selectedDate: selectedDate,
          onConditionCompare: onConditionCompare,
        }}
        footerMessage={pageData.showingRecordMessage}
        rowKey={"id"}
        scrollY={isMobile ? 300 : 550}
        onRowClick={onRowClick}
      />
    </React.Fragment>
  );
}
