import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Form } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { useTranslation } from "react-i18next";
import "../staff.page.scss";

const FilterStaff = forwardRef((props, ref) => {
  const { groupPermissions, branches } = props;

  const [t] = useTranslation();
  const [form] = Form.useForm();
  const defaultValue = "";

  useEffect(() => {
    form.setFieldsValue({ branchId: "", permissionId: "" });
  }, []);

  const onResetForm = () => {
    form?.resetFields();
    onApplyFilter();
  };

  //#region PageData
  const pageData = {
    filter: {
      button: t("button.filter"),
      resetData: t("button.resetData"),
      branch: {
        title: t("productManagement.filter.branch.title"),
        all: t("productManagement.filter.branch.all"),
        placeholder: t("productManagement.filter.branch.placeholder"),
      },
      permission: {
        title: t("staffManagement.permission.filter.title"),
        all: t("staffManagement.permission.filter.all"),
        placeholder: t("staffManagement.permission.filter.placeholder"),
      },
    },
  };
  //#endregion

  const onApplyFilter = () => {
    let formValue = form.getFieldsValue();
    formValue.count = countFilterControl(formValue);

    props.fetchDataProducts(formValue);
  };

  const countFilterControl = (formValue) => {
    let countBranch = formValue?.branchId === "" || formValue?.branchId === undefined ? 0 : 1;
    let countPermission = formValue?.permissionId === "" || formValue?.permissionId === undefined ? 0 : 1;

    return countBranch + countPermission;
  };

  const onResetFilter = () => {
    let formData = { branchId: "", permissionId: "", count: 0 };
    form.setFieldsValue(formData);
    props.fetchDataProducts(formData);
  };

  useImperativeHandle(ref, () => ({
    resetFilter() {
      onResetFilter();
    },
  }));

  return (
    <Form layout="vertical" form={form} onFieldsChange={onApplyFilter}>
      <Form.Item name="branchId" label={pageData.filter.branch.title}>
        <FnbSelectSingle
          placeholder={pageData.filter.branch.placeholder}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={branches}
        />
      </Form.Item>
      <Form.Item name="permissionId" label={pageData.filter.permission.title}>
        <FnbSelectSingle
          placeholder={pageData.filter.permission.placeholder}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={groupPermissions}
        />
      </Form.Item>
    </Form>
  );
});

export default FilterStaff;
