import { useEffect, useRef } from "react";
import { SCROLL_DIRECTION } from "constants/default.constants";

function useDragScroll(selector, scrollDirection = SCROLL_DIRECTION.HORIZONTAL) {
  const dragState = useRef({
    isDragging: false,
    x: 0,
    y: 0,
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const element = document.querySelector(selector);

    const handleMouseDown = (e) => {
      dragState.current = {
        isDragging: true,
        x: e.clientX,
        y: e.clientY,
        left: element.scrollLeft,
        top: element.scrollTop,
      };
      element.style.cursor = "grabbing";
      element.style.userSelect = "none";
    };

    const handleMouseMove = (e) => {
      const { isDragging, x, y, left, top } = dragState.current;
      if (!isDragging) return;

      const dx = e.clientX - x;
      const dy = e.clientY - y;

      if (scrollDirection === SCROLL_DIRECTION.HORIZONTAL || scrollDirection === SCROLL_DIRECTION.BOTH) {
        element.scrollLeft = left - dx;
      }
      if (scrollDirection === SCROLL_DIRECTION.VERTICAL || scrollDirection === SCROLL_DIRECTION.BOTH) {
        element.scrollTop = top - dy;
      }
    };

    const handleMouseUp = () => {
      dragState.current = { ...dragState.current, isDragging: false };
      element.style.cursor = "grab";
      element.style.removeProperty("user-select");
    };

    if (element) {
      element.addEventListener("mousedown", handleMouseDown);
      element.addEventListener("mousemove", handleMouseMove);
      element.addEventListener("mouseup", handleMouseUp);

      return () => {
        element.removeEventListener("mousedown", handleMouseDown);
        element.removeEventListener("mousemove", handleMouseMove);
        element.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [selector, dragState, scrollDirection]);

  return null;
}

export default useDragScroll;
