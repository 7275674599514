import { Input } from "antd";
import "./fnb-input.component.scss";
import classNames from "classnames";

const FnbInputPhoneNumber = ({
  id,
  defaultValue,
  value,
  allowClear,
  className = "",
  placeholder,
  onChange,
  autoFocus,
  disabled = false,
  suffix,
  prefix,
  onPaste = undefined,
  ...rest
}) => {
  const classNameInput = classNames({
    "fnb-input-admin": true,
    "fnb-input-admin--disabled": disabled,
    [`${className}`]: true,
  });

  return (
    <Input
      id={id}
      value={value}
      allowClear={allowClear}
      onChange={onChange}
      className={classNameInput}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      prefix={prefix}
      onInput={(e) => {
        if (e.target.value) {
          const numbers = e.target.value.replace(/[^0-9]/g, "");
          e.target.value = numbers;
        }
      }}
      {...rest}
    />
  );
};

export default FnbInputPhoneNumber;
