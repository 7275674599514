import { useTranslation } from "react-i18next";
import "./index.scss";

/**
 * Badge Status
 * @param {bool} status - Status of the badge
 * @returns Active or Inactive label
 */
export function BadgeStatus(props) {
  const [t] = useTranslation();
  const { isActive, text } = props;
  const pageData = {
    active: t("status.active"),
    inactive: t("status.inactive"),
  };

  let content = text;
  if (!Boolean(text)) {
    content = isActive ? pageData.active : pageData.inactive;
  }

  return <span className={`badge-status ${isActive ? 'active' : 'default'}`}>
    <span> {content}</span>
  </span>;
}
