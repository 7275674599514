import styled from "styled-components";
import theme from "theme";

export const FlexRow = styled.div`
  display: flex;
  column-gap: ${(p) => (p.columnGap ? p.columnGap : 0)}px;
  flex-wrap: ${(p) => (p.wrap ? "wrap" : "nowrap")};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(p) => (p.rowGap ? p.rowGap : 0)}px;
`;

export const ULContent = styled.ul`
  margin-bottom: 0;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

export const LIItem = styled.li`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #000;
`;

export const LIItemLink = styled.a`
  text-decoration: underline !important;
  color: ${theme.colors.primary.main};
  font-weight: 500;
  &:hover {
    text-decoration: underline !important;
    color: ${theme.colors.primary[80]};
  }
`;
