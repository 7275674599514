import { Card } from "antd";
import { tableSettings } from "constants/default.constants";
import staffDataService from "data-services/staff/staff-data.service";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TablePermissionGroup from "./table-permission-group";
import "./index.scss";

export default function PermissionGroupComponent(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const { screenKey } = props;

  const [listGroupPermission, setListGroupPermission] = useState([]);
  const [totalGroupPermission, setTotalGroupPermission] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    async function fetchData() {
      initDataTableGroupPermission(tableSettings.page, tableSettings.pageSize, keySearch);
    }

    fetchData();
  }, []);

  const initDataTableGroupPermission = async (pageNumber, pageSize, keySearch) => {
    if (!pageNumber || !pageSize) {
      return;
    }

    let dataRequest = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      keySearch: keySearch,
      screenKey: screenKey,
    };

    //get list group-permission
    const response = await staffDataService.getDataStaffManagementAsync(dataRequest);
    let groupPermissions = mappingToDataTableGroupPermissions(pageNumber, response?.groupPermissions);
    setListGroupPermission(groupPermissions);
    setTotalGroupPermission(response?.total);
  };

  const mappingToDataTableGroupPermissions = (pageNumber, groupPermissions) => {
    return groupPermissions?.map((i, index) => {
      return {
        id: i?.id,
        no: (pageNumber - 1) * tableSettings.pageSize + index + 1,
        groupName: i?.name,
        member: i?.numberOfMember,
        createdBy: i?.createdByStaffName,
      };
    });
  };

  const onChangePage = async (pageNumber, pageSize) => {
    //get list group-permission by pageNumber, pageSize
    initDataTableGroupPermission(pageNumber, pageSize, keySearch);
    setCurrentPage(pageNumber);
  };

  const onSearchGroupPermission = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        initDataTableGroupPermission(tableSettings.page, tableSettings.pageSize, keySearch);
      }, 500),
    );
  };

  const onEditPermissionGroup = (_selectedRowKeys, selectedRows) => {
    const { id } = selectedRows?.[0];
    history.push(`/permission-group/edit/${id}`);
  };

  return (
    <div>
      <Card className="fnb-card-custom staff-card-border">
        <div>
          <TablePermissionGroup
            t={t}
            dataSource={listGroupPermission}
            total={totalGroupPermission}
            pageSize={tableSettings.pageSize}
            currentPage={currentPage}
            onChangePage={onChangePage}
            onSearch={onSearchGroupPermission}
            onEditItem={onEditPermissionGroup}
          />
        </div>
      </Card>
    </div>
  );
}
