import { Tabs } from "antd";
import { TRANSACTION_TABPANE_KEY } from "constants/report.constants";
import OrderManagement from "pages/report/order/order.page";
import { useTranslation } from "react-i18next";
import { PermissionKeys } from "../../../constants/permission-key.constants";
import { hasPermission } from "../../../utils/helpers";
import ReportProductTransaction from "../components/product-report/product-report.component";
import ReservationManagement from "../reservation/management";
import ShiftManagement from "../shift/shift-management/shift.page";
import "./report-transaction.page.scss";

const { TabPane } = Tabs;

export default function ReportTransaction(props) {
  const [t] = useTranslation();
  const pageData = {
    orderTitle: t("dashboard.order"),
    reservationTitle: t("reservation.title"),
    shiftTitle: t("report.shift.title"),
    productTitle: t("menu.product"),
    comboTitle: "Combo",
  };

  return (
    <Tabs
      defaultActiveKey={props?.match?.params?.tabId ?? TRANSACTION_TABPANE_KEY.ORDER}
      className="transaction-report-tabs"
    >
      {hasPermission(PermissionKeys.VIEW_ORDER_REPORT) && (
        <TabPane tab={pageData.orderTitle} key="1">
          <OrderManagement />
        </TabPane>
      )}
      {hasPermission(PermissionKeys.VIEW_RESERVATION_REPORT) && (
        <TabPane tab={pageData.reservationTitle} key="2">
          <ReservationManagement />
        </TabPane>
      )}
      {hasPermission(PermissionKeys.VIEW_SHIFT_REPORT) && (
        <TabPane tab={pageData.shiftTitle} key="3">
          <ShiftManagement />
        </TabPane>
      )}
      {hasPermission(PermissionKeys.VIEW_SOLD_PRODUCT_REPORT) && (
        <TabPane tab={pageData.productTitle} key="4">
          <ReportProductTransaction />
        </TabPane>
      )}
      {hasPermission(PermissionKeys.VIEW_SOLD_PRODUCT_REPORT) && (
        <TabPane tab={pageData.comboTitle} key="5">
          <p>Combo component here</p>
        </TabPane>
      )}
    </Tabs>
  );
}
