import {Col, Form, message, Row, Typography} from "antd";
import { FnbCancelButton } from "components/cancel-button";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { DELAYED_TIME } from "constants/default.constants";
import { RoutePathConstants } from "constants/route-key-path.constants";
import branchDataService from "data-services/branch/branch-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom";
import FnbFroalaEditor from "components/fnb-froala-editor";
import "./create-new-kitchen.component.scss";
import { CustomTrashIcon, SearchIcon, Product } from "constants/icons.constants";
import ProductCategorySearchModal from "./product-category-search-modal.component";
import { FnbTable } from "../../../../components/fnb-table/fnb-table";
import { Thumbnail } from "../../../../components/thumbnail/thumbnail";
import FnbTooltip from "../../../../components/fnb-tooltip/fnb-tooltip";
import { formatNumberDecimalOrInteger } from "../../../../utils/helpers";
import kitchenDataService from "../../../../data-services/kitchen/kitchen-data.service";
import { FnbModal } from "../../../../components/fnb-modal/fnb-modal-component";
import i18n from "../../../../utils/i18n";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";

export default function CreateNewKitchen() {
  const [t] = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const branchIdFromKitchenManagement = location?.state?.selectedBranchId;

  const pageData = {
    createNewKitchen: t("kitchen.createNewKitchen"),
    btnAddNew: t("button.addNew"),
    generalInformation: t("kitchen.generalInformation"),
    kitchenName: t("kitchen.kitchenName"),
    branch: t("kitchen.branch"),
    description: t("kitchen.description"),
    productCategoryList: t("kitchen.productCategoryList"),
    enterTheKitchenName: t("kitchen.enterTheKitchenName"),
    pleaseEnterTheKitchenName: t("kitchen.pleaseEnterTheKitchenName"),
    kitchenNameMaximum: t("kitchen.kitchenNameMaximum"),
    thisKitchenNameAlreadyExists: t("kitchen.thisKitchenNameAlreadyExists"),
    pleaseSelectAtLeastOneProductCategory: t("kitchen.pleaseSelectAtLeastOneProductCategory"),
    searchForProductCategory: t("kitchen.searchForProductCategory"),
    createKitchenSuccessfully: t("kitchen.createKitchenSuccessfully"),
    saveSuccessfully: t("kitchen.saveSuccessfully"),
    productCategory: t("kitchen.productCategory"),
    numberOfProduct: t("kitchen.numberOfProduct"),
    products: t("kitchen.products"),
    pleaseSelectCategory: t("kitchen.pleaseSelectCategory"),
    alreadyIn: t("kitchen.alreadyIn"),
    categoriesHaveProductsInProgress: t("kitchen.categoriesHaveProductsInProgress"),
    notificationTitle: t("form.notificationTitle"),
    btnIGotIt: t("button.iGotIt"),
    descriptionPlaceholder: t("kitchen.descriptionPlaceholder"),
  };
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState(location?.state?.selectedBranchId);
  const [kitchenDescription, setKitchenDescription] = useState("");
  const [isShowModalSearch, setIsShowModalSearch] = useState(false);
  const [listSelectedCategories, setListSelectedCategories] = useState([]);
  const [processingCategories, setProcessingCategories] = useState([]);
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();

  useEffect(() => {
    void getBranches();
  }, []);

  useEffect(() => {
    if (branches?.length > 0) {
      if (branchIdFromKitchenManagement) {
        handleSetDefaultBranch(branchIdFromKitchenManagement);
      } else {
        handleSetDefaultBranch(branches?.[0]?.id);
      }
    }
  }, [branches]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors?.length > 0 && arr.push(field.name), arr), []);
    void form.validateFields(errorFields);
  }, [i18n.language]);

  const getBranches = async () => {
    const branchesResponse = await branchDataService.getAllBranchsAsync();
    if (branchesResponse) {
      setBranches(branchesResponse?.branchs);
      updateLastTimeGetBranches();
    }
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };


  const handleSubmitForm = () => {
    form.validateFields().then(async () => {
      const fieldValue = form.getFieldsValue(["kitchenName", "kitchenBranch", "kitchenDescription"]);
      const request = {
        name: fieldValue?.kitchenName,
        branchId: fieldValue?.kitchenBranch,
        description: fieldValue?.kitchenDescription,
        categoryIds: listSelectedCategories?.map(category => category.id),
      };
      const res = await kitchenDataService.createNewKitchenAsync(request);
      if (res) {
        switch (res?.status) {
          case 409:
            form.setFields([
              {
                name: "kitchenName",
                errors: [pageData.thisKitchenNameAlreadyExists],
              }
            ]);
            break;
          case 200:
            message.success(pageData.createKitchenSuccessfully);
            handleDiscard();
            break;
          case 423:
            setProcessingCategories(res?.inProgressCategoryNames);
            break;
          default:
            break;
        }
      }
    });
  };

  const handleSetDefaultBranch = (branchId) => {
    const formValue = form.getFieldsValue();
    formValue.kitchenBranch = branchId;
    form.setFieldsValue(formValue);
    setSelectedBranchId(branchId);
  };

  const handleDiscard = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push(RoutePathConstants.kitchenManagement, { branchId: selectedBranchId });
    }, DELAYED_TIME);
  };

  const handleClickInputSearch = () => {
    setIsShowModalSearch(true);
  };

  const onRemoveCategory = (category) => {
    setListSelectedCategories(prevState => {
      const newCategories = prevState.filter((x) => x.id !== category.id);
      if (!newCategories || newCategories?.length <= 0) {
        form.setFields([
          {
            name: "productCategoryList",
            errors: [pageData.pleaseSelectAtLeastOneProductCategory],
          }
        ]);
      }
      return newCategories;
    });
  };

  const handleSelectListCategories = (categories) => {
    setListSelectedCategories(categories);
    if (categories && categories?.length > 0) {
      form.setFields([
        {
          name: "productCategoryList",
          errors: [],
        }
      ]);
    }
  };

  const listButton = [
    <FnbCancelButton showWarning={isChangeForm} onOk={() => handleDiscard()} />,
    <FnbAddNewButton text={pageData.btnAddNew} onClick={() => handleSubmitForm()} hideIcon={true} />,
  ];

  const columnsCategory = () => {
    return [
      {
        title: pageData.productCategory,
        dataIndex: "name",
        width: "38%",
        align: "left",
        render: (_, record, index) => {
          return (
            <Col style={{flex: 1}}>
              <Row style={{gap: 12}}>
                {record?.thumbnail ? (
                  <Thumbnail width={52} height={52} src={record?.thumbnail}/>
                ) : (
                  <Product width={52} height={52}/>
                )}
                <Col className={"table-product-category-name"}>
                  <FnbTooltip title={record?.name} onlyShowWhenEllipsis></FnbTooltip>
                </Col>
              </Row>
            </Col>
          );
        },
      },
      {
        title: "",
        dataIndex: "kitchenName",
        align: "center",
        width: "19%",
        render: (_, record, index) => {
          if (!record || !record?.kitchenName) return null;
          return (
            <Row style={{ justifyContent: "center" }}>
              <Col className={"table-kitchen-name"}>
                <Typography className={"table-kitchen-name-text"}>
                  {pageData.alreadyIn}&nbsp;
                  <span style={{ fontWeight: 700 }}>{record?.kitchenName}</span>
                </Typography>
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.numberOfProduct,
        dataIndex: "numberOfProduct",
        align: "right",
        width: "38%",
        render: (_, record, index) => {
          return (
            <Row className={"table-num-of-product"}>
              <Col>{`${formatNumberDecimalOrInteger(record?.numberOfProduct ?? 0)} ${pageData.products}`}</Col>
            </Row>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        align: "left",
        width: "5%",
        render: (_, record, index) => (
          <div
            className="icon-delete-price pt-3"
            onClick={() => {
              onRemoveCategory(record);
            }}
          >
            <CustomTrashIcon color="#50429B"/>
          </div>
        ),
      },
    ];
  };

  const notificationProcessingCategory = () => {
    return (
      <div>
        <div className="div-message-cannot-delete-kitchen">
          <div className="message-cannot-delete-kitchen">
            {pageData.categoriesHaveProductsInProgress}
          </div>
        </div>
        <div className="div-category-list-processing">
          {processingCategories?.map((item, index) => {
            return (
              <Row className="row-category-list-processing">
                <Typography key={`processing-category-name-${index}`} className="category-name">
                  {item}
                </Typography>
              </Row>
            );
          })}
        </div>
        <div className="div-button-i-got-it">
          <div className="button-i-got-it" onClick={() => setProcessingCategories([])}>{pageData.btnIGotIt}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <FnbHeadingPage title={pageData.createNewKitchen} listButtons={listButton} />
      <Form form={form} onFieldsChange={() => setIsChangeForm(true)} className="form-create-new-kitchen">
        <FnbCard title={pageData.generalInformation}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <div className="div-short-line-blue"></div>
            </Col>
            <Col span={24}>
              <h4 className="fnb-form-label">
                {pageData.kitchenName}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item
                name="kitchenName"
                rules={[
                  { required: true, message: <InputValidateMessage message={pageData.pleaseEnterTheKitchenName} /> },
                  {
                    type: "string",
                    max: 100,
                    message: <InputValidateMessage message={pageData.kitchenNameMaximum} />,
                  },
                ]}
              >
                <FnbInput
                  showCount
                  maxLength={100}
                  className="fnb-input-with-count"
                  placeholder={pageData.enterTheKitchenName}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <h4 className="fnb-form-label">
                {pageData.branch}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item name="kitchenBranch">
                <FnbSelectAddNewItem
                  onChange={(value) => {
                    setListSelectedCategories([]);
                    setSelectedBranchId(value);
                  }}
                  options={branches?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  showSearch
                  noTranslateOptionName
                  defaultValue={selectedBranchId}
                  fieldItemName={pageData.branch.toLowerCase()}
                  onAddNewItem={onAddNewBranch}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <h4 className="fnb-form-label">{pageData.description}</h4>
              <Form.Item name="kitchenDescription">
                <FnbFroalaEditor
                  value={kitchenDescription}
                  onChange={(value) => {
                    setKitchenDescription(value);
                  }}
                  charCounterMax={255}
                  placeholder={pageData.descriptionPlaceholder}
                  heightMin={300}
                />
              </Form.Item>
            </Col>
          </Row>
        </FnbCard>
        <FnbCard title={pageData.productCategoryList} className="product-category-list-container">
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <div className="div-short-line-blue"></div>
            </Col>
            <Col span={24} onClick={handleClickInputSearch}>
              <Form.Item
                name="productCategoryList"
                className="container-input-search-product-category"
                rules={[
                  {
                    message: <InputValidateMessage message={pageData.pleaseSelectAtLeastOneProductCategory} />,
                    validator: (_, value) => {
                      if (listSelectedCategories && listSelectedCategories?.length > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(pageData.pleaseSelectAtLeastOneProductCategory);
                      }
                    },
                  },
                ]}
              >
                <FnbInput
                  id={'btn-search-category'}
                  className="fnb-input"
                  size="large"
                  placeholder={pageData.searchForProductCategory}
                  prefix={<SearchIcon style={{cursor: "pointer"}}/>}
                  type={TYPE_INPUT.BUTTON}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-3">
            {listSelectedCategories?.length > 0 && (
              <Col span={24}>
                <FnbTable
                  columns={columnsCategory()}
                  dataSource={listSelectedCategories}
                  total={listSelectedCategories?.length}
                  numberRecordCurrent={listSelectedCategories?.length}
                  showPaging={false}
                  scrollY={96 * 5}
                />
              </Col>
            )}
          </Row>
        </FnbCard>
      </Form>
      <ProductCategorySearchModal
        visible={isShowModalSearch}
        handleCancel={() => setIsShowModalSearch(false)}
        listSelectedCategories={listSelectedCategories}
        setListSelectedCategories={handleSelectListCategories}
        branchId={selectedBranchId}
      />
      <FnbModal
        className={"notification-kitchen-processing-category"}
        width={440}
        title={pageData.notificationTitle}
        visible={processingCategories && processingCategories?.length > 0}
        handleCancel={() => setProcessingCategories([])}
        hideFooter={true}
        content={notificationProcessingCategory()}
      />
    </>
  );
}
