import Background from "assets/images/bg.jpg";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    min-width: 1600px;
    background-color: #ffffff;
    background-image: ${`url(${Background})`};
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 100% auto;
    z-index: 1;
  }

  @media (max-width: 992px) {
    &::before {
      min-width: 800px;
      transform: rotate(180deg);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  z-index: 2;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperLogo = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 340px;
    height: 340px;
  }

  @media (max-width: 1200px) {
    img {
      width: 300px;
      height: 300px;
    }
  }

  @media (max-width: 992px) {
    width: 100%;

    img {
      width: 200px;
      height: 200px;
    }
  }

  @media (max-width: 575px) {
    img {
      width: 150px;
      height: 150px;
    }
  }
`;

export const WrapperForm = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .ant-form,
  .sent-form {
    width: 490px;
    height: auto;
    padding: 24px;
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow: 8px 8px 120px 0px #0000000d;

    .ant-form-item {
      margin-bottom: 0;
      .ant-form-item-explain-error {
        position: unset;
      }
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .ant-form-item-control-input-content {
        .ant-input-prefix {
          margin-right: 12px;

          svg {
            color: #50429b;
          }
        }
      }
    }
  }

  .sent-form {
    display: flex;
    flex-direction: column;
    gap: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

    .expired-time span {
      color: #db1b1b;
    }
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    .ant-form,
    .sent-form {
      width: 100%;
      box-shadow: none;
    }
  }
`;

export const Title = styled.div`
  p {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #342b64;
    margin-bottom: 12px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2f2d39;
  }
`;

export const SendingStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: ${(props) => (props?.invalidToken ? "#E2A108" : "#059735")};

  svg {
    width: 32px;
    height: 32px;

    & > path {
      stroke: ${(props) => (props?.invalidToken ? "#E2A108" : "#059735")};
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  gap: 8px;

  @media (max-width: 575px) {
    padding-top: 12px;
  }
`;

export const Errors = styled.div`
  svg {
    margin-right: 4px;
    vertical-align: bottom;
  }

  .register-account {
    color: #50429b;
    cursor: pointer;
    text-decoration-line: underline;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }
`;
