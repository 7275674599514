import { Card, Col, Form, Input, Row, message, Typography } from "antd";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { CopyIcon, GeneralConfigInfoCircle, UploadLogoIcon, QRWarningOutline } from "constants/icons.constants";
import { StoreSettingConstants } from "constants/store-setting.constants";
import { forwardRef, useImperativeHandle, useState, useRef, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInformationPublishStore, setStoreLogo, setStoreInformation } from "store/modules/session/session.actions";
import { QRCode } from "react-qrcode-logo";
import { env } from "env";
import { FnbGoogleMap } from "components/fnb-google-map/google-map.component";
import { PlacesAutocomplete } from "components/places-auto-complete/places-auto-complete.component";
import { getAddressDetails } from "utils/google";
import { FORMAT_GOOGLE_ADDRESS } from "constants/google.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { useHistory } from "react-router-dom";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";
import { getAllStaff } from "store/modules/common/common.reducers";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { FnbSelectSingleIcon } from "components/fnb-select-single-icon/fnb-select-single-icon";
import { CircleFlag } from "react-circle-flags";
import packageDataService from "data-services/package/package-data.service";
import fileDataService from "data-services/file/file-data.service";
import goFnbLogo from "assets/images/go-fnb-login-logo.png";
import "../store-general-configuration.style.scss";
import i18n from "utils/i18n";
import { FnbButton } from "components/fnb-button/fnb-button";
import { POSTAL_CODE_REGEX } from "constants/default.constants";
import { getDefaultCountry, getFullAddress } from "utils/helpers";
import { isValidPhoneNumber } from "react-phone-number-input";
import { IsoPhoneNumberConstants } from "constants/phone-number.constants";

export const CardGeneralInfo = forwardRef((props, ref) => {
  const { t, className, storeDataService, setGeneralAddress, isConfirmOk = false } = props;
  const [formGeneral] = Form.useForm();
  const dispatch = useDispatch();
  const informationPublishStore = useSelector((state) => state?.session?.informationPublishStore);
  const [initDataAddress, setInitDataAddress] = useState(null);
  const [initStoreData, setInitStoreData] = useState(null);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [wards, setWards] = useState([]);
  const [wardsByDistrictId, setWardsByDistrictId] = useState([]);
  const [districtsByCityId, setDistrictsByCityId] = useState([]);
  const [isDefaultCountry, setIsDefaultCountry] = useState(true);
  const [phoneCode, setPhoneCode] = useState(null);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [image, setImage] = useState(null);
  const [storeId, setStoreId] = useState({});
  const [logo, setLogo] = useState();
  const bgColor = "#f7f5ff";
  const logoPadding = 2;
  const offset = 0;
  const size = 176;
  const logoRadius = 34;
  const logoWidth = 40;
  const logoHeight = 40;
  const googleMapRef = useRef();
  const autoCompleteRef = useRef();
  const [storeAppAvailable, setStoreAppAvailable] = useState(false);
  const history = useHistory();
  const uploadStoreLogoRef = useRef();
  const reduxState = useSelector((state) => state);
  const [countryOption, setCountryOption] = useState([]);
  const [fullStates, setFullStates] = useState([]);
  const [location, setLocation] = useState(null);

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSaveChanges: t("button.saveChanges"),
    enter: t("form.enter"),
    storeName: t("registerAccount.storeName"),
    store: t("settings.tabStore"),
    updateSuccess: t("messages.isUpdatedSuccessfully"),
    inputStoreName: t("registerAccount.inputStoreName"),
    fullName: t("form.fullName"),
    enterFullName: t("form.enterFullName"),
    country: t("form.country"),
    validCountry: t("BranchBankTransfer.validCountry"),
    inputPhone: t("form.inputPhone"),
    validPhone: t("form.validPhone"),
    validPhonePattern: t("form.validPhonePattern"),
    phoneValidation: t("supplier.phoneValidation"),
    mustBeBetweenThreeAndFifteenCharacters: t("form.mustBeBetweenThreeAndFifteenCharacters"),
    allowNumberOnly: t("form.allowNumberOnly"),
    email: t("form.email"),
    validEmail: t("form.validEmail"),
    inputEmail: t("form.inputEmail"),
    validEmailPattern: t("form.validEmailPattern"),
    currency: t("form.currency"),
    selectCurrency: t("form.selectCurrency"),
    businessModel: t("form.businessModel"),
    selectBusinessModel: t("form.selectBusinessModel"),
    address: t("form.address"),
    inputBranchAddressPlaceholder: t("form.inputAddress"),
    validAddress: t("form.validAddress"),
    inputAddress: t("form.inputAddress"),
    addressTwo: t("form.addressTwo"),
    inputAddressOne: t("form.inputAddressOne"),
    inputAddressTwo: t("form.inputAddressTwo"),
    province: t("form.province"),
    selectProvince: t("form.selectProvince"),
    validSelectProvince: t("form.validSelectProvince"),
    district: t("form.district"),
    selectDistrict: t("form.selectDistrict"),
    validDistrict: t("form.validDistrict"),
    ward: t("form.ward"),
    selectWard: t("form.selectWard"),
    validWard: t("form.validWard"),
    city: t("form.city"),
    inputCity: t("form.inputCity"),
    validCity: t("form.validCity"),
    state: t("form.state"),
    selectState: t("form.selectState"),
    zip: t("form.zip"),
    inputZip: t("form.inputZip"),
    validZip: t("form.validZip"),
    invalidZip: t("form.invalidZip"),
    citySearchTextMaxLength: 255,
    leaveForm: t("messages.leaveForm"),
    confirmation: t("leaveDialog.confirmation"),
    confirmLeave: t("button.confirmLeave"),
    discard: t("button.discard"),
    generalInformation: t("title.generalInformation"),
    storeID: t("registerAccount.storeID"),
    storeCode: t("registerAccount.storeCode"),
    copiedSucess: t("supplier.copiedSuccess"),
    automaticallySaveStore: t("registerAccount.automaticallySaveStore"),
    businessId: t("registerAccount.businessId"),
    businessCode: t("registerAccount.businessCode"),
    businessName: t("registerAccount.businessName"),
    enterBusinessName: t("registerAccount.enterBusinessName"),
    fullBusinessName: t("registerAccount.fullBusinessName"),
    enterFullBusinessName: t("registerAccount.enterFullBusinessName"),
    phone: t("registerAccount.phone"),
    logo: {
      title: t("registerAccount.logo.title"),
      subTitle: t("registerAccount.logo.subTitle"),
      subTitle1: t("registerAccount.logo.subTitle1"),
      subTitle2: t("registerAccount.logo.subTitle2"),
      subTitle3: t("registerAccount.logo.subTitle3"),
      uploadImage: t("registerAccount.logo.uploadImage"),
      textNonImage: t("registerAccount.logo.textNonImage"),
      bestDisplayImage: t("registerAccount.logo.bestDisplayImage"),
    },
    mobileAppQRCode: t("registerAccount.mobileAppQRCode"),
    downloadQRImage: t("registerAccount.downloadQRImage"),
    validProvince: t("registerAccount.validProvince"),
    warningText1: t("registerAccount.warningText1"),
    warningText2: t("registerAccount.warningText2"),
    seePlans: t("store.qrCodeConfiguration.seePlans"),
  };

  useEffect(() => {
    handleChangeForm(isChangeForm);
  }, [isChangeForm]);

  const handleChangeForm = (data) => {
    const { onChangeForm } = props;
    if (onChangeForm) onChangeForm(data);
  };
  const prefixSelector = <label>+{phoneCode}&nbsp;&nbsp;|</label>;

  useImperativeHandle(ref, () => ({
    setInitGeneralInfoData(addressData, storeData) {
      setInitDataAddress(addressData);
      setInitStoreData(storeData);
      initDataGeneralInfo(addressData, storeData);
    },
  }));

  const initDataGeneralInfo = (initDataAddress, initStoreData) => {
    if (initDataAddress) {
      const { states, countries, cities, districts, wards, defaultCountry } = initDataAddress;
      setCountries(countries);
      setCities(cities);
      setDistricts(districts);
      setWards(wards);
      countryOptionSelector(countries);
      setFullStates(states);

      if (initStoreData) {
        const { store, staff } = initStoreData;
        const { city, country, district, ward, state, cityTown, latitude, longitude } = store?.address;
        setStoreId(store?.id);
        setPhoneCode(country?.phonecode);
        onChangeCity(city?.id);
        onChangeDistrict(district?.id);
        getStoreLogo(store?.logo);
        checkStoreAppAvailable();

        let address1 = store?.address?.address1;
        let cityId = city?.id;
        let districtId = district?.id;
        let wardId = ward?.id;
        let countryId = country?.id || store?.address?.countryId;
        let fullAddress = "";
        const isDefault = getDefaultCountry(country?.iso);
        ///Set location
        let currentAddress = address1 || "";
        let currentWard = "";
        let districtName = "";
        let cityName = "";
        let stateName = "";
        let countryName = "";
        if (isDefault) {
          currentWard = wards?.find((ward) => ward?.id === wardId);
          districtName = districts?.find((district) => district?.id === districtId)?.name;
          cityName = cities?.find((city) => city?.id === cityId)?.name;
        } else {
          stateName = state?.name;
          countryName = countries?.find((country) => country?.id === countryId).name;
        }

        fullAddress = getFullAddress(isDefault, currentAddress, currentWard, districtName, cityName, stateName, countryName);

        ///Set google map marker
        const currentBranchLocation = { lat: latitude, lng: longitude };

        if (autoCompleteRef && autoCompleteRef.current) {
          autoCompleteRef.current.setAddressWhenEdit(fullAddress);
        }

        if (googleMapRef && googleMapRef.current) {
          googleMapRef.current.setCenter(currentBranchLocation);
        }

        let districtsFilteredByCity = districts?.filter((district) => district?.cityId === city?.id) ?? [];
        setDistrictsByCityId(districtsFilteredByCity);

        let wardsFilteredByCity = wards?.filter((ward) => ward?.districtId === district?.id) ?? [];
        setWardsByDistrictId(wardsFilteredByCity);
        setIsDefaultCountry(isDefault);

        const countryStates = states?.filter((state) => state?.countryCode === country?.iso);
        setStates(countryStates);

        formGeneral.setFieldsValue({
          store: {
            ...store,
          },
          staff: {
            ...staff,
          },
        });
      }
    }
  };

  const onCountryChange = (countryId) => {
    let country = countries?.find((country) => country?.id === countryId);
    setPhoneCode(country?.phonecode);
    const countryStates = fullStates?.filter((state) => state?.countryCode === country?.iso);
    setStates(countryStates);
    const isDefault = getDefaultCountry(country?.iso);
    setIsDefaultCountry(isDefault);
    setTimeout(() => {
      let formValue = formGeneral.getFieldsValue();
      if (isDefault) {
        formValue.store.address.district.id = null;
        formValue.store.address.ward.id = null;
        formValue.store.address.city.id = null;
      } else {
        formValue.store.address.address2 = null;
        formValue.store.address.postalCode = null;
        formValue.store.address.state.id = null;
      }
      formValue.store.address.address1 = null;
      formValue.store.address.cityTown = null;
      formGeneral.setFieldsValue(formValue);

      //Valid PhoneNumber by CountryCode
      formGeneral.validateFields([["staff", "phoneNumber"]]);
    }, 200);

    ///Set region for select address autocomplete
    if (autoCompleteRef && autoCompleteRef.current) {
      autoCompleteRef.current.setDefaultCountry(isDefault);
      autoCompleteRef.current.clearCurrentLocation();
    }
  };

  const onChangeCity = (event) => {
    let districtsFilteredByCity = districts?.filter((item) => item?.cityId === event) ?? [];
    setDistrictsByCityId(districtsFilteredByCity);

    if (isDefaultCountry) {
      let formValue = formGeneral.getFieldsValue();
      formValue.store.address.district.id = null;
      formValue.store.address.ward.id = null;
      formGeneral.setFieldsValue(formValue);
    }
  };

  const onChangeDistrict = (event) => {
    let wardsFilteredByCity = wards?.filter((item) => item?.districtId === event) ?? [];
    setWardsByDistrictId(wardsFilteredByCity);

    if (isDefaultCountry) {
      let formValue = formGeneral.getFieldsValue();
      formValue.store.address.ward.id = null;
      formGeneral.setFieldsValue(formValue);
    }
  };

  const onChangeForm = () => {
    if (!isChangeForm) {
      setIsChangeForm(true);
    }
    handleChangeForm(true);
  };

  const onSaveGeneralInfo = async () => {
    formGeneral.validateFields().then(async (values) => {
      values.store.logo = image;
      values.store.address.latitude = location?.center?.lat;
      values.store.address.longitude = location?.center?.lng;
      const res = await storeDataService?.updateStoreManagementAsync(values);
      if (res) {
        if (setGeneralAddress) setGeneralAddress(values?.store?.address);
        //save redux information store
        dispatch(
          setInformationPublishStore({
            ...informationPublishStore,
            title: values?.store?.title,
            fullName: values?.staff?.fullName,
          }),
        );
        message.success(`${pageData.store} ${values?.store?.title} ${pageData.updateSuccess}`);
        setIsChangeForm(false);
        setInitStoreData({
          ...values,
          isDefaultCountry,
        });
        dispatch(setStoreLogo(image));
        let infoStore = reduxState?.session?.informationPublishStore;
        //save redux information store
        dispatch(setInformationPublishStore({ ...infoStore, logo: image }));

        storeDataService.getStoreInformationAsync().then((response) => {
          dispatch(setStoreInformation(response));
        });
      }
    });
  };

  function CopyIdButton() {
    let formValue = formGeneral.getFieldsValue();
    navigator.clipboard.writeText(formValue?.store?.id);
    message.success(pageData.copiedSucess);
  }

  function CopyCodeButton() {
    let formValue = formGeneral.getFieldsValue();
    navigator.clipboard.writeText(formValue?.store?.code);
    message.success(pageData.copiedSucess);
  }

  const onChangeImage = (file) => {
    setImage(file?.url);
  };

  const handleDownload = () => {
    const canvas = document.getElementById("react-qrcode-logo");
    if (canvas) {
      const url = canvas?.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = "download-app-qr-code.png";
      link.href = url;
      link.click();
    }
  };

  const onSelectLocation = (location, addressInfo) => {
    setIsChangeForm(true);
    setLocation(location);
    const addressInfoData = getAddressDetails(addressInfo ?? []);
    onSetFormAddressValue(location?.address, addressInfoData);

    ///Set google map marker
    if (googleMapRef?.current) {
      googleMapRef.current.setCenter(location?.center);
    }
    if (autoCompleteRef?.current) {
      autoCompleteRef.current.setIsError(false);
    }
  };

  /// Set form address value when input address google map
  const onSetFormAddressValue = (address, addressInfo) => {
    if (address) {
      if (isDefaultCountry) {
        const addressGoogleMap = getAddressGoogleMap(address, addressInfo);

        ///Filter address id
        setDistrictsByCityId(addressGoogleMap?.districtsFilteredByCity);
        setWardsByDistrictId(addressGoogleMap?.wardsFilteredByCity);

        ///Set address field value
        let formValue = formGeneral.getFieldsValue();
        formValue.store.address.address1 = addressGoogleMap?.address;
        formValue.store.address.city.id = addressGoogleMap?.cityId;
        formValue.store.address.district.id = addressGoogleMap?.districtId;
        formValue.store.address.ward.id = addressGoogleMap?.wardId;
        formGeneral.setFieldsValue(formValue);
      } else {
        const street = address.split(",");
        const mainAddress = street.length > 0 ? street[0] : "";
        const cityTown = street.length > 1 ? street[1] : "";
        let stateId = states?.find((x) =>
          addressInfo?.city?.trim().toLowerCase().includes(x.name?.trim().toLowerCase()),
        )?.id;

        ///Set address field value
        let formValue = formGeneral.getFieldsValue();
        formValue.store.address.address1 = mainAddress;
        formValue.store.address.state.id = stateId;
        formValue.store.address.cityTown = cityTown;
        formGeneral.setFieldsValue(formValue);
      }
    }
  };

  //This function is used to get the address from Google Map
  function getAddressGoogleMap(addressMap, addressInfo) {
    if (!Boolean(addressMap)) {
      return;
    }

    const splitAddress = addressMap.split(",");
    const mainAddress =
      splitAddress.length === FORMAT_GOOGLE_ADDRESS?.defaultLength
        ? splitAddress[0]
        : splitAddress[0].concat(", ", splitAddress[1]);
    let city = splitAddress[splitAddress.length === FORMAT_GOOGLE_ADDRESS?.defaultLength ? 3 : 4];
    let district = splitAddress[splitAddress.length === FORMAT_GOOGLE_ADDRESS?.defaultLength ? 2 : 3];
    let fullWard = splitAddress[splitAddress.length === FORMAT_GOOGLE_ADDRESS?.defaultLength ? 1 : 2];

    if (splitAddress && splitAddress?.length <= 3) {
      city = addressInfo?.city;
      district = addressInfo?.district;
      fullWard = addressInfo?.ward;
    }

    const ward = fullWard?.replace("phường", "")?.replace("Phường", "");

    ///Find address id
    let cityId = cities?.find((x) => city?.trim().toLowerCase().includes(x.name?.trim().toLowerCase()))?.id;

    let districtsFilteredByCity = districts?.filter((item) => item.cityId === cityId) ?? [];
    let districtId = districtsFilteredByCity?.find(
      (x) => district?.trim().toLowerCase() === x.name?.trim().toLowerCase(),
    )?.id;

    let wardsFilteredByCity = wards?.filter((item) => item.districtId === districtId) ?? [];
    let wardId = wardsFilteredByCity?.find((x) => ward?.trim().toLowerCase() === x.name?.trim().toLowerCase())?.id;
    if (!wardId) {
      wardId = wardsFilteredByCity?.find(
        (x) =>
          ward?.trim().toLowerCase().includes("(") & ward?.trim().toLowerCase().includes(")") &&
          ward?.trim().toLowerCase().includes(x.name?.trim().toLowerCase()),
      )?.id;
    }
    return {
      address: mainAddress?.trim(),
      cityId: cityId,
      districtsFilteredByCity: districtsFilteredByCity,
      districtId: districtId,
      wardsFilteredByCity: wardsFilteredByCity,
      wardId: wardId,
    };
  }

  const onSetEmptyLocation = () => {
    //setBranchLocation(null);
  };

  const onCancel = () => {
    setIsChangeForm(false);
    initDataGeneralInfo(initDataAddress, initStoreData);
  };

  useEffect(() => {
    if (initDataAddress && initStoreData) {
      initDataGeneralInfo(initDataAddress, initStoreData);
      setIsChangeForm(false);
    }
  }, [isConfirmOk]);

  useEffect(() => {
    if (image) {
      void getBase64Image(image);
    } else {
      setLogo(undefined);
    }
  }, [image]);

  const getBase64Image = async (url) => {
    const res = await fileDataService.getBase64Image(url);
    if (res?.imageData) {
      setLogo(res?.imageData);
    }
  };

  const checkStoreAppAvailable = async () => {
    const res = await packageDataService.checkStoreAppAvailable();
    if (res?.isAvailable) {
      setStoreAppAvailable(res?.isAvailable);
    }
  };

  useEffect(() => {
    const canvas = document.getElementById("react-qrcode-logo");
    if (canvas && logo) {
      const ctx = canvas.getContext("2d");
      const image = new Image();

      const roundedImage = (x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
      };

      image.onload = () => {
        ctx.save();

        const dWidthLogo = logoWidth;
        const dHeightLogo = logoHeight;
        const dxLogo = (size - dWidthLogo) / 2;
        const dyLogo = (size - dHeightLogo) / 2;

        ctx.beginPath();
        ctx.arc(size / 2, size / 2, dWidthLogo / 2 + logoPadding, 0, Math.PI * 2, false);
        ctx.arc(size / 2, size / 2, 0, 0, Math.PI * 2, true);
        ctx.strokeStyle = bgColor;
        ctx.fillStyle = bgColor;
        ctx.fill();

        roundedImage(dxLogo, dyLogo, dWidthLogo, dHeightLogo, logoRadius);
        ctx.clip();
        ctx.drawImage(image, dxLogo, dyLogo, dWidthLogo, dHeightLogo);

        ctx.restore();
      };
      image.src = logo;
    }
  }, [logo]);

  const onOpenMyAccount = () => {
    const defaultTab = {
      key: 2,
    };

    history.push({
      pathname: "/my-account",
      state: defaultTab,
    });
  };

  const getStoreLogo = (logo) => {
    if (uploadStoreLogoRef?.current) {
      uploadStoreLogoRef.current.setImage(logo);
    }
    setImage(logo);
  };

  useLayoutEffect(() => {
    dispatch(getAllStaff());
  }, []);

  useEffect(() => {
    const errorFields = formGeneral
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    formGeneral.validateFields(errorFields);
  }, [i18n.language]);

  const countryOptionSelector = (countries) => {
    let result = countries?.map((country) => {
      return {
        label: (
          <Row className="col-country">
            <CircleFlag className="circle-icon" countryCode={country?.iso?.toLowerCase()} height={20} />
            <div className="country-name">{t(country?.nicename)}</div>
          </Row>
        ),
        value: country?.id,
        labelSearch: country?.nicename,
      };
    });
    setCountryOption(result);
  };

  return (
    <div className={className}>
      <Card className="fnb-card w-100 card-general">
        <Form form={formGeneral} autoComplete="off" onChange={onChangeForm}>
          <Row className="card-title-box">
            <Col span={18} className="d-flex-align-center content-help">
              <div className="icon-help">
                <GeneralConfigInfoCircle />
              </div>
              <div className="auto-save-title">{pageData.automaticallySaveStore}</div>
            </Col>
            <Col span={6} className="content-btn">
              {isChangeForm && (
                <div className="list-group-button">
                  <FnbButton minWidth="100px" text={pageData.btnCancel} variant="tertiary" onClick={onCancel} />
                  <FnbButton
                    minWidth="140px"
                    text={pageData.btnSaveChanges}
                    onClick={onSaveGeneralInfo}
                    permission={PermissionKeys.ADMIN}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={[28, 28]}>
            <Col lg={12} span={24}>
              <h4 className="fnb-form-label">{pageData.businessId}</h4>
              <Form.Item name={["store", "id"]} className="mb-0">
                <FnbInput
                  disabled
                  maxLength={255}
                  suffix={<CopyIcon style={{ cursor: "pointer" }} onClick={CopyIdButton} />}
                ></FnbInput>
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h4 className="fnb-form-label">{pageData.businessCode}</h4>
              <Form.Item name={["store", "code"]} className="mb-0">
                <FnbInput
                  disabled
                  maxLength={255}
                  suffix={<CopyIcon style={{ cursor: "pointer" }} onClick={CopyCodeButton} />}
                ></FnbInput>
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h4 className="fnb-form-label">
                {pageData.businessName}
                <span className="required-field">*</span>
              </h4>
              <Form.Item
                name={["store", "title"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.enterBusinessName} />
                    ),
                  },
                ]}
                className="mb-0"
              >
                <FnbInput placeholder={pageData.enterBusinessName} maxLength={100} showCount />
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h4 className="fnb-form-label">
                {pageData.fullBusinessName}
                <span className="required-field">*</span>
              </h4>
              <Form.Item
                name={["staff", "fullName"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.enterFullBusinessName}
                      />
                    ),
                  },
                ]}
                className="mb-0"
              >
                <FnbInput placeholder={pageData.enterFullBusinessName} maxLength={50} showCount />
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h4 className="fnb-form-label">{pageData.logo.title}</h4>
              <Row className={image ? "have-image" : "non-image"}>
                <Col className="content-image-product">
                  <div className="image-product bussiness-logo">
                    <Form.Item name={["store", "logo"]}>
                      <FnbUploadImageComponent
                        ref={uploadStoreLogoRef}
                        buttonText={pageData.logo.uploadImage}
                        iconUpload={<UploadLogoIcon />}
                        onChange={onChangeImage}
                        onRemove={onChangeForm}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col className="text-non-image" hidden={image ? true : false}>
                  <Typography disabled className="sub-title-logo">
                    {<h4 className="fnb-form-label-help">{pageData.logo.subTitle}</h4>}
                    {image && (
                      <ul>
                        <li>{pageData.logo.subTitle1}</li>
                        <li>{pageData.logo.subTitle2}</li>
                      </ul>
                    )}
                    {!image && pageData.logo.subTitle1}
                    {!image && <br />}
                    {!image && pageData.logo.subTitle2}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col lg={12} span={24}>
              <h4 className="fnb-form-label">{pageData.mobileAppQRCode}</h4>
              <Row className={"have-image"}>
                <Col className="content-image-product">
                  <div className="card-qr-code qr-code qr-code-wrapper image-product">
                    {!storeAppAvailable ? (
                      <>
                        <div className="qr-warning-img">
                          <QRWarningOutline />
                        </div>
                      </>
                    ) : storeAppAvailable && logo ? (
                      <QRCode
                        size={size}
                        quietZone={offset}
                        bgColor={bgColor}
                        value={`${env.REACT_APP_ROOT_DOMAIN}/api${
                          env.API_VERSIONS ?? ""
                        }/file/get-download-link/${storeId}`}
                        qrStyle={"dots"}
                      />
                    ) : (
                      <QRCode
                        size={size}
                        quietZone={offset}
                        logoPadding={logoPadding}
                        bgColor={bgColor}
                        logoPaddingStyle={"circle"}
                        removeQrCodeBehindLogo={true}
                        value={`${env.REACT_APP_ROOT_DOMAIN}/api${
                          env.API_VERSIONS ?? ""
                        }/file/get-download-link/${storeId}`}
                        logoImage={goFnbLogo}
                        qrStyle={"dots"}
                      />
                    )}
                  </div>
                </Col>
                <Col className="text-non-image">
                  <Typography disabled className="sub-title-logo">
                    {!storeAppAvailable ? (
                      <>
                        <div className="qr-warning-text">{pageData.warningText1}</div>
                        <div onClick={() => onOpenMyAccount()} className="qr-warning-link">
                          <a>{pageData.seePlans}</a>
                        </div>
                        <div className="qr-warning-text mt-1">{pageData.warningText2}</div>
                      </>
                    ) : (
                      <>
                        <h4 className="fnb-form-label-help">{pageData.logo.subTitle3}</h4>
                        <div className={"download-qr-btn"} onClick={handleDownload}>
                          {pageData.downloadQRImage}
                        </div>
                      </>
                    )}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col lg={8} span={24}>
              <h4 className="fnb-form-label">
                {pageData.country}
                <span className="required-field">*</span>
              </h4>
              <Form.Item
                name={["store", "address", "countryId"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validCountry} />
                    ),
                  },
                ]}
                className="mb-0"
              >
                <FnbSelectSingleIcon
                  size="large"
                  showSearch
                  autoComplete="none"
                  onChange={(e) => {
                    onCountryChange(e);
                    onChangeForm();
                  }}
                  option={countryOption}
                />
              </Form.Item>
              <Form.Item hidden={true} name={["store", "address", "country", "phonecode"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} span={24}>
              <h4 className="fnb-form-label">
                {pageData.phone}
                <span className="required-field">*</span>
              </h4>
              <Form.Item
                name={["staff", "phoneNumber"]}
                className="mb-0"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.phoneValidation} />
                    ),
                  },
                  {
                    pattern: /^\d+$/g,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.allowNumberOnly} />
                    ),
                  },
                  () => ({
                    validator(_, value) {
                      if (value?.length > 0) {
                        const isValid = isValidPhoneNumber(value, IsoPhoneNumberConstants[phoneCode]);
                        if (isValid) return Promise.resolve();
                        else
                          return Promise.reject(<InputValidateMessage message={pageData.validPhonePattern} />);
                      } else {
                        return Promise.reject();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  className="fnb-input-addon-before"
                  size="large"
                  placeholder={pageData.inputPhone}
                  addonBefore={prefixSelector}
                  maxLength={15}
                />
              </Form.Item>
            </Col>
            <Col lg={8} span={24}>
              <h4 className="fnb-form-label">{pageData.email}</h4>
              <Form.Item
                name={["staff", "account", "email"]}
                className="mb-0"
                rules={[
                  {
                    required: false,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.emailValidation} />
                    ),
                  },
                  {
                    type: "email",
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.emailInvalidEmail} />
                    ),
                  },
                ]}
              >
                <FnbInput disabled placeholder={pageData.inputEmail} maxLength={100} />
              </Form.Item>
            </Col>

            {/* Hidden values */}
            <Form.Item hidden name={["storeSetting"]} initialValue={StoreSettingConstants.GENERAL_CONFIG}></Form.Item>
            <Form.Item hidden name={["staff", "id"]}></Form.Item>
            <Form.Item hidden name={["store", "addressId"]}></Form.Item>
          </Row>

          {isDefaultCountry ? (
            <>
              <Row gutter={[28, 28]} className="pt-4">
                <Col lg={12} span={24}>
                  <Row gutter={[28, 28]}>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.address}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        name={["store", "address", "address1"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.validAddress}
                              />
                            ),
                          },
                        ]}
                        className="mb-0"
                      >
                        <FnbInput placeholder={pageData.inputAddress} maxLength={255} showCount />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.province}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        className="mb-0"
                        name={["store", "address", "city", "id"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.validProvince}
                              />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          size="large"
                          placeholder={pageData.selectProvince}
                          onChange={(e) => {
                            onChangeCity(e);
                            onChangeForm();
                          }}
                          showSearch
                          autoComplete="none"
                          option={cities?.map((item, index) => ({
                            id: item.id,
                            name: item.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.district}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        className="mb-0"
                        name={["store", "address", "district", "id"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.validDistrict}
                              />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          size="large"
                          placeholder={pageData.selectDistrict}
                          onChange={(e) => {
                            onChangeDistrict(e);
                            onChangeForm();
                          }}
                          showSearch
                          autoComplete="none"
                          option={districtsByCityId?.map((item, index) => ({
                            id: item.id,
                            name: `${item?.prefix} ${item.name}`,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.ward}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        name={["store", "address", "ward", "id"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validWard} />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          size="large"
                          placeholder={pageData.selectWard}
                          showSearch
                          onChange={onChangeForm}
                          option={wardsByDistrictId?.map((item, index) => ({
                            id: item.id,
                            name: `${item?.prefix} ${item.name}`,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} span={24}>
                  <div className="branch-google-map-container">
                    <FnbGoogleMap ref={googleMapRef} className="google-map-box">
                      <PlacesAutocomplete
                        inputClassName="input-address"
                        addressPopoverClassName="input-address-popover"
                        textOverflowClassName="input-address-text-overflow"
                        ref={autoCompleteRef}
                        inputPlaceholder={pageData.inputBranchAddressPlaceholder}
                        onSelectLocation={onSelectLocation}
                        onEmptyLocation={onSetEmptyLocation}
                      ></PlacesAutocomplete>
                    </FnbGoogleMap>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={[28, 28]} className="pt-4">
                <Col lg={12} span={24}>
                  <Row gutter={[28, 28]}>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.address}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item name={["store", "address", "latitude"]} hidden={true}></Form.Item>
                      <Form.Item name={["store", "address", "longitude"]} hidden={true}></Form.Item>
                      <Form.Item
                        name={["store", "address", "address1"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.validAddress}
                              />
                            ),
                          },
                        ]}
                        className="mb-0"
                      >
                        <FnbInput placeholder={pageData.inputAddressOne} maxLength={255} showCount />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">{pageData.addressTwo}</h4>
                      <Form.Item name={["store", "address", "address2"]} className="mb-0">
                        <FnbInput placeholder={pageData.inputAddressTwo} maxLength={255} showCount />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.city}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        name={["store", "address", "cityTown"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validCity} />
                            ),
                          },
                        ]}
                        className="mb-0 input-new"
                      >
                        <FnbInput placeholder={pageData.inputCity} />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.state}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        className="mb-0"
                        name={["store", "address", "state", "id"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.selectState} />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          size="large"
                          placeholder={pageData.selectProvince}
                          showSearch
                          onChange={onChangeForm}
                          option={states?.map((item, index) => ({
                            id: item.id,
                            name: item.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} className="w-100">
                      <h4 className="fnb-form-label">
                        {pageData.zip}
                        <span className="required-field">*</span>
                      </h4>
                      <Form.Item
                        name={["store", "address", "postalCode"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validZip} />
                            ),
                          },
                          {
                            pattern: POSTAL_CODE_REGEX,
                            message: (
                              <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.invalidZip} />
                            ),
                          },
                        ]}
                        className="input-new"
                      >
                        <FnbInput
                          placeholder={pageData.inputZip}
                          maxLength={50}
                          onInput={(event) => (event.target.value = event.target.value?.toUpperCase())}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} span={24}>
                  <div className="branch-google-map-container">
                    <FnbGoogleMap ref={googleMapRef} className="google-map-box">
                      <PlacesAutocomplete
                        inputClassName="input-address"
                        addressPopoverClassName="input-address-popover"
                        textOverflowClassName="input-address-text-overflow"
                        ref={autoCompleteRef}
                        inputPlaceholder={pageData.inputBranchAddressPlaceholder}
                        onSelectLocation={onSelectLocation}
                        onEmptyLocation={onSetEmptyLocation}
                      ></PlacesAutocomplete>
                    </FnbGoogleMap>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Card>
    </div>
  );
});
