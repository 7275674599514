import { Col, Progress } from "antd";
import { ReactComponent as RightCornerBackground } from "assets/images/system-guideline-background-right-corner.svg";
import backgroundSystemGuideline from "assets/images/system-guideline-vector-background.svg";
import backgroundSystemGuidelineMobile from "assets/images/system-guideline-vector-background-mobile.svg";
import styled from "styled-components";
import theme from "theme";

export const SystemGuidelineStyle = styled.div`
  border: 1px solid ${theme.colors.primary[50]};
  border-radius: 16px;
  background-image: url(${backgroundSystemGuideline}), linear-gradient(180deg, #4c3ab6 0%, #f7f5ff 100%);
  background-size: cover;
  padding: 24px;
  flex-direction: column;
  display: ${(p) => (p.isShow ? "flex" : "none")};

  @media (max-width: 576px) {
    padding: 12px;
    background-image: url(${backgroundSystemGuidelineMobile}), linear-gradient(180deg, #4c3ab6 0%, #f7f5ff 100%);
    background-repeat: no-repeat;
  }
`;

export const HeadingSystemGuideline = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const WrapperProgress = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const WrapperCurrentStep = styled.div`
  display: flex;
  width: fit-content;
  visibility: ${(p) => (p.isShow ? "visible" : "hidden")};

  svg {
    margin-left: -10px;
    z-index: 1;
  }
`;

export const InfoStep = styled.div`
  height: 40px;
  width: 67px;
  background: ${theme.colors.secondary[70]};
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    bottom: -11px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 8px;
    background-color: #ff8c24;
    clip-path: path(
      "M29.2298 4.96686 L25 8 L20.7702 4.96686 C16.6928 2.04303 11.8017 0.470588 6.78432 0.470588 H0 V0 H50 V0.470588 H43.2157 C38.1983 0.470588 33.3072 2.04304 29.2298 4.96686 Z"
    );
    /* Vendor prefix for better browser support */
    -webkit-clip-path: path(
      "M29.2298 4.96686 L25 8 L20.7702 4.96686 C16.6928 2.04303 11.8017 0.470588 6.78432 0.470588 H0 V0 H50 V0.470588 H43.2157 C38.1983 0.470588 33.3072 2.04304 29.2298 4.96686 Z"
    );
  }
`;

export const ProgressStep = styled(Progress)`
  top: -5px;
  .ant-progress-outer {
    .ant-progress-inner {
      .ant-progress-bg {
        height: 12px !important;
      }
    }
  }
`;

export const LeftColumnSteps = styled(Col)`
  background: #f7f5ff;
  border-radius: 16px 0 0 16px;
  padding: 12px 0 12px 12px;
`;

export const RightColumnSteps = styled(Col)`
  border-radius: 0 16px 16px 0;
  overflow: hidden;
`;

export const RightCornerBackgroundStyle = styled(RightCornerBackground)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const BottomContentGuidelineMobile = styled.div`
  background: #f7f5ff;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const WrapperItemStepMobileMode = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-height: 48px;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(1, 1, 1, 1);
  ${(p) =>
    p.isSelected &&
    `
      max-height: 400px;
    `}
`;

export const WrapperContentItemStepMobile = styled.div`
  border-radius: 12px;
  overflow: hidden;
`;
