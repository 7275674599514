import { Row, Space } from "antd";
import PageTitle from "components/page-title";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import "./index.scss";

import storeDataService from "data-services/store/store-data.service";
import commonService from "data-services/common/common-data.service";
import DeliveryPage from "./delivery";
import General from "./general/general.component";
import Localization from "./localization";
import PaymentMethodPage from "./payment-method";
import OtherFoodyPlatformPage from "./other-foody-platform";
import GoogleConfig from "./google-config/google-config.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import PrinterPage from "./printer";
import Operation from "./operation";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { settingsActions } from "store/modules/settings/settings.reducer";
import { isStoreInternational } from "utils/helpers";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import { paymentMethod } from "constants/payment-method.constants";

const KEY_TAB_PANE = {
  GENERAL: "general",
  PLATFORMS: "platforms",
  DELIVERY: "delivery",
  PAYMENT: "payment",
  BILL_AND_TICKETS: "bill_and_tickets",
  OPERATION: "operation",
  COMMUNICATIONS: "communications",
  LOCALIZATION: "localization",
};
const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.GENERAL]: 0,
  [KEY_TAB_PANE.OPERATION]: 1,
  [KEY_TAB_PANE.PAYMENT]: 2,
  [KEY_TAB_PANE.DELIVERY]: 3,
  [KEY_TAB_PANE.PLATFORMS]: 4,
  [KEY_TAB_PANE.BILL_AND_TICKETS]: 5,
  [KEY_TAB_PANE.COMMUNICATIONS]: 6,
  [KEY_TAB_PANE.LOCALIZATION]: 7,
};
const DEFAULT_SCREEN = KEY_TAB_PANE.GENERAL;

export default function Settings(props) {
  const { t, storeId } = props;
  const history = useHistory();
  const tabActive = new URLSearchParams(window.location.search).get("tabActive");
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [activeScreen, setActiveScreen] = useState(tabActive ?? DEFAULT_SCREEN);
  const [countryCodes, setCountryCodes] = useState([]);
  const dispatch = useDispatch();
  const refTab = useRef(tabActive ?? DEFAULT_SCREEN);
  const isInternational = isStoreInternational();
  const [isValidPayPal, setIsValidPayPal] = useState(true);

  useEffect(() => {
    const mainContentElement = document.getElementById("main-body-id");
    mainContentElement.classList.add("main-body__configuration");
    return () => {
      mainContentElement.classList.remove("main-body__configuration");
      dispatch(settingsActions.unMountComponent());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pageData = {
    title: t("configuration.title"),
    platforms: t("configuration.platforms", "Other platforms"),
    delivery: t("configuration.delivery", "Delivery"),
    payment: t("configuration.payment", "Payment"),
    billAndTickets: t("configuration.billAndTickets", "Bill & Tickets"),
    operation: t("configuration.operation", "Operation"),
    communications: t("configuration.communications", "Communications"),
    localization: t("configuration.localization", "Localization"),
    general: t("store.general", "General"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };

  useLayoutEffect(() => {
    getCountryCodesAsync();
  }, []);

  const getCountryCodesAsync = async () => {
    await commonService
      .getCountryCodesAsync()
      .then((res) => {
        const { countries } = res;
        if (countries?.length > 0) {
          setCountryCodes(countries?.sort((pre, current) => pre?.name.localeCompare(current?.name)));
        }
      })
      .catch(() => {
        setCountryCodes([]);
      });
  };

  const onChangeForm = (value) => {
    setIsChangeForm(value);
  };

  const handleChangeScreen = (activeKey) => {
    if (isChangeForm) {
      setShowConfirm(true);
      refTab.current = activeKey;
    } else {
      setShowConfirm(false);
      history.push({
        pathname: "/config",
        search: `?tabActive=${activeKey}`,
      });
      setActiveScreen(activeKey);
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onOk = () => {
    if (!isValidPayPal) {
      const enablePaymentConfigRequest = {
        enumId: paymentMethod.PayPal,
        isActive: false,
      };
      paymentConfigDataService.enablePaymentConfigAsync(enablePaymentConfigRequest);
    }
    setShowConfirm(false);
    setIsChangeForm(false);
    setActiveScreen(refTab.current);
  };

  let screens = [
    {
      name: pageData.general,
      key: KEY_TAB_PANE.GENERAL,
      component: (
        <General
          t={t}
          storeDataService={storeDataService}
          storeId={storeId}
          onChangeForm={onChangeForm}
          isChangeFormData={isChangeForm}
          setIsChangeForm={setIsChangeForm}
        />
      ),
    },
    {
      name: pageData.operation,
      key: KEY_TAB_PANE.OPERATION,
      component: <Operation isChangeForm={isChangeForm} onChangeForm={onChangeForm} />,
    },
    {
      name: pageData.payment,
      key: KEY_TAB_PANE.PAYMENT,
      component: (
        <PaymentMethodPage
          onChangeForm={onChangeForm}
          isChangeForm={isChangeForm}
          setIsValidPayPal={setIsValidPayPal}
        />
      ),
    },
    {
      name: pageData.delivery,
      key: KEY_TAB_PANE.DELIVERY,
      component: (
        <DeliveryPage t={t} countryCodes={countryCodes} isChangeForm={isChangeForm} onChangeForm={onChangeForm} />
      ),
    },
    {
      name: pageData.platforms,
      key: KEY_TAB_PANE.PLATFORMS,
      component: <OtherFoodyPlatformPage onChangeForm={onChangeForm} isChangeForm={isChangeForm} />,
    },
    {
      name: pageData.billAndTickets,
      key: KEY_TAB_PANE.BILL_AND_TICKETS,
      component: (
        <PrinterPage
          t={t}
          onChangeForm={onChangeForm}
          isChangeFormData={isChangeForm}
          setIsChangeForm={setIsChangeForm}
        />
      ),
    },
    {
      name: pageData.communications,
      key: KEY_TAB_PANE.COMMUNICATIONS,
      component: (
        <GoogleConfig
          t={t}
          storeDataService={storeDataService}
          onChangeForm={onChangeForm}
          isChangeFormData={isChangeForm}
          setIsChangeForm={setIsChangeForm}
        />
      ),
    },
    {
      name: pageData.localization,
      key: KEY_TAB_PANE.LOCALIZATION,
      component: <Localization />,
    },
  ];

  if (isInternational) {
    screens = screens?.filter((tab) => tab?.key !== KEY_TAB_PANE.LOCALIZATION);
  }

  return (
    <div class="page-configuration">
      <div className="content-configuration-page">
        <Row className="fnb-row-page-header">
          <Space className="page-title">
            <PageTitle content={pageData.title} />
          </Space>
          <Space className="page-action-group"></Space>
        </Row>
        <FnbTabPane
          screens={screens}
          activeScreen={activeScreen}
          indexKeyTabPane={INDEX_KEY_TAB_PANE}
          onChange={handleChangeScreen}
          defaultScreen={DEFAULT_SCREEN}
          firstKeyTabPane={KEY_TAB_PANE.GENERAL}
          lastKeyTabPane={isInternational ? KEY_TAB_PANE.COMMUNICATIONS : KEY_TAB_PANE.LOCALIZATION}
          setTabPaneDisplayFullWidth={true}
          destroyInactiveTabPane={true}
        />
      </div>
      <div style={{ position: "absolute" }}>
        <DeleteConfirmComponent
          title={pageData.leaveDialog.confirmation}
          content={pageData.leaveDialog.content}
          visible={showConfirm}
          skipPermission={true}
          cancelText={pageData.leaveDialog.discardBtn}
          okText={pageData.leaveDialog.btnConfirmLeave}
          onCancel={onDiscard}
          onOk={onOk}
          isChangeForm={isChangeForm}
        />
      </div>
    </div>
  );
}
