import { BookGuidelineIcon, PromotionGuidelineIcon } from "constants/icons.constants";
import { useMediaQuery } from "react-responsive";
import "./fnb-guideline.component.scss";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

export function FnbGuideline({
  placement,
  title,
  content,
  nameTooltip,
  styles,
  largeButton,
  className = "",
  icon,
  iconClassName = "",
}) {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const titleTooltip = (title, content) => {
    return (
      <div className={`guideline-wrapper ${className}`}>
        <div className="guideline-header">
          <span className="icon">
            <BookGuidelineIcon />
          </span>
          {title}
        </div>
        {typeof content === "object" ? (
          content
        ) : (
          <p className="guideline-content" dangerouslySetInnerHTML={{ __html: content }}></p>
        )}
      </div>
    );
  };

  return (
    <FnbTooltip
      iconClassName={iconClassName}
      variant="secondary"
      overlayClassName={`${nameTooltip !== undefined ? `fnb-guideline-${nameTooltip}` : "fnb-guideline"}`}
      overlayStyle={styles}
      placement={placement}
      title={titleTooltip(title, content)}
      trigger={isMobile ? "click" : "hover"}
      disabledInnerPadding
    >
      {largeButton ? (
        <FnbButton variant="tertiary" className="custom-tooltip-button" text={<PromotionGuidelineIcon />} />
      ) : !icon ? (
        <PromotionGuidelineIcon />
      ) : (
        icon
      )}
    </FnbTooltip>
  );
}
