import { Col, message, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import menuManagementDataService from "data-services/menu-management/menu-management-data.service";
import pageDataService from "data-services/page/page-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatDate, hasPermission } from "utils/helpers";
import "../page-management.page.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { DateFormat } from "constants/string.constants";
import theme from "theme";

export default function TablePageManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const pageDetailLink = "/online-store/page-management/detail-page-management";
  const pageEditLink = "/online-store/page-management/edit-page-management";
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState(false);
  const [messageDeleteConfirmPopup, setMessageDeleteConfirmPopup] = useState(null);
  const [textBtnOkDeleteConfirmPopup, setTextBtnOkDeleteConfirmPopup] = useState(null);
  const [isShowBtnCancelDeleteConfirmPopup, setIsShowBtnCancelDeleteConfirmPopup] = useState(false);
  const [titlePopupDeletePage, setTitlePopupDeletePage] = useState();
  const [pageIdValue, setPageIdValue] = useState();
  const [menuList, setMenuList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const pageData = {
    search: t("form.searchName"),
    no: t("table.no"),
    action: t("onlineStore.pageManagement.action"),
    pageName: t("onlineStore.pageManagement.pageName"),
    createdBy: t("onlineStore.pageManagement.createdBy"),
    createdTime: t("onlineStore.pageManagement.createdTime"),
    button: {
      btnDelete: t("button.delete"),
      btnIgnoreDelete: t("marketing.qrCode.ignoreText"),
    },
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    deleteQrCodeSuccess: t("onlineStore.pageManagement.deletePageSuccess"),
    deleteDeleteFail: t("onlineStore.pageManagement.deletePageFail"),
    titleDeleteConfirmPopup: t("messages.pageConfirmationDeleteTitle"),
    titleWarningPopup: t("messages.warning"),
    btnOkDeleteConfirmPopup: t("button.delete"),
    btnIgnoreDeleteConfirmPopup: t("button.deletePageIgnore"),
    btnIGotItDeleteConfirmPopup: t("button.iGotIt"),
    pageLinkedToMultipleMenuItem: t("messages.pageLinkedToMultipleMenuItem"),
    pageDeletedSuccessfully: t("messages.pageDeletedSuccessfully"),
    pageDeletedNotSuccessfully: t("messages.pageDeletedNotSuccessfully"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  const getColumns = () => {
    const columnsPage = [
      {
        title: pageData.no,
        dataIndex: "no",
        key: "no",
        align: "left",
        width: "5rem",
      },
      {
        title: pageData.pageName,
        dataIndex: "pageName",
        key: "pageName",
        width: "65%",
        align: "left",
        className: "grid-text-column",
        render: (_, record) => {
          return (
            <>
              <Row>
                <Col span={24}>
                  <FnbTooltip title={record?.pageName} onlyShowWhenEllipsis>
                    <FnbTypography className="text-line-clamp-1" text={record?.pageName} />
                  </FnbTooltip>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div
                    className="text-line-clamp-2"
                    dangerouslySetInnerHTML={{
                      __html: record?.pageContent?.replace(/<.*?>/gm, "").slice(0, 400),
                    }}
                  ></div>
                </Col>
              </Row>
            </>
          );
        },
      },
      {
        title: pageData.createdBy,
        dataIndex: "createdBy",
        key: "createdBy",
        width: "15%",
        align: "left",
        className: "grid-text-column",
      },
      {
        title: pageData.createdTime,
        dataIndex: "createdTime",
        key: "createdTime",
        className: "grid-time-column",
        width: "15%",
        render: (_, record) => {
          return record?.createdTime ? formatDate(record?.createdTime, DateFormat.DD_MMM_YYYY) : "-";
        },
      },
    ];

    return columnsPage;
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch) => {
    const response = await pageDataService.getAllPageAsync(pageNumber, pageSize, keySearch);
    const data = response?.pages.map((page, index) => ({
      no: index + 1,
      ...page,
    }));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);

    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
  };

  const handleSearchByName = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        fetchDatableAsync(1, tableSettings.pageSize, keySearch);
      }, 500),
    );
  };

  const onChangePage = async (pageNumber, pageSize) => {
    fetchDatableAsync(pageNumber, pageSize, keySearch);
  };

  const onEditItem = (id) => {
    return history.push(`${pageEditLink}/${id}`);
  };

  const onDeleteItem = async (_selectedRowKeys, selectedRows) => {
    const { id: pageId, pageName } = selectedRows?.[0];
    const menuItemList = await menuManagementDataService.getMenuItemReferenceToPageByPageIdAsync(pageId);
    let messageDeleteConfirm = <></>;
    let menuItemUrl = "";
    if (menuItemList?.length > 0 && menuItemList?.length <= 1) {
      menuItemUrl = `/menu-management/edit/${menuItemList[0]?.menuId}`;
      messageDeleteConfirm = t("messages.pageLinkedToMenuItem", {
        menuItemName: menuItemList[0]?.menuItemName,
        menuItemUrl: menuItemUrl,
      });
      setTitlePopupDeletePage(pageData.titleWarningPopup);
      setTextBtnOkDeleteConfirmPopup(pageData.btnIGotItDeleteConfirmPopup);
      setIsShowBtnCancelDeleteConfirmPopup(false);
    } else if (menuItemList?.length > 1) {
      messageDeleteConfirm = (
        <>
          <span>{pageData.pageLinkedToMultipleMenuItem}</span>
          <div className="message-confirm-group-menu-item">
            {menuItemList?.map((menuItem) => {
              menuItemUrl = `/menu-management/edit/${menuItem?.menuId}`;
              return (
                <div className="message-confirm-sub">
                  <span className="linked-menu-item-name-dot"></span>
                  <a href={menuItemUrl} className="linked-menu-item-name" target="_blank" rel="noreferrer">
                    {menuItem?.menuItemName}
                  </a>
                </div>
              );
            })}
          </div>
        </>
      );
      setTitlePopupDeletePage(pageData.titleWarningPopup);
      setTextBtnOkDeleteConfirmPopup(pageData.btnIGotItDeleteConfirmPopup);
      setIsShowBtnCancelDeleteConfirmPopup(false);
    } else if (menuItemList?.length <= 0) {
      messageDeleteConfirm = t(`messages.pageConfirmDeleteMessage`, { pageName: pageName });
      setTitlePopupDeletePage(pageData.titleDeleteConfirmPopup);
      setTextBtnOkDeleteConfirmPopup(pageData.btnOkDeleteConfirmPopup);
      setIsShowBtnCancelDeleteConfirmPopup(true);
    }

    setMenuList(menuItemList);
    setMessageDeleteConfirmPopup(messageDeleteConfirm);
    setPageIdValue(pageId);
    setIsShowDeleteConfirmPopup(true);
  };

  const onCancelDeletePage = () => {
    setIsShowDeleteConfirmPopup(false);
  };

  const onOkDeletePage = async () => {
    const pageDeleteResult = await pageDataService.deletePageByIdAsync(pageIdValue);
    if (pageDeleteResult?.isSuccess) {
      fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
      setIsShowDeleteConfirmPopup(false);
      message.success(pageData.pageDeletedSuccessfully);
    } else {
      setIsShowDeleteConfirmPopup(false);
      if (pageDeleteResult?.message?.length > 0) {
        message.error(t(pageDeleteResult?.message));
      } else {
        message.error(pageData.pageDeletedNotSuccessfully);
      }
    }
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onRowClick = (record) => {
    history.push(`${pageDetailLink}/${record?.id}`);
  };

  return (
    <>
      <Row className="form-staff">
        <FnbTable
          className="table-striped-rows"
          columns={getColumns()}
          pageSize={tableSettings.pageSize}
          dataSource={dataSource}
          currentPageNumber={currentPageNumber}
          total={totalRecords}
          onChangePage={onChangePage}
          search={{
            placeholder: pageData.search,
            onChange: handleSearchByName,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditItem,
            onDelete: onDeleteItem,
          }}
          editPermission={PermissionKeys.EDIT_PAGE}
          deletePermission={PermissionKeys.DELETE_PAGE}
          rowKey={"id"}
          //onRowClick={hasPermission(PermissionKeys.VIEW_PAGE) && onRowClick} // TODO until there is US view Page
          emptyText={
            hasPermission(PermissionKeys.CREATE_PAGE) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={() => history.push("/online-store/page-management/create-page-management")}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
      </Row>

      <ConfirmDialogComponent
        title={titlePopupDeletePage}
        visible={isShowDeleteConfirmPopup}
        okText={textBtnOkDeleteConfirmPopup}
        cancelText={pageData.btnIgnore}
        onCancel={onCancelDeletePage}
        type={ConfirmStyle.DELETE}
        onOk={isShowBtnCancelDeleteConfirmPopup === true ? onOkDeletePage : onCancelDeletePage}
        contentElement={
          menuList?.length <= 1 ? (
            <div
              className="content-delete-page-popup"
              dangerouslySetInnerHTML={{ __html: `${messageDeleteConfirmPopup}` }}
            ></div>
          ) : (
            <div className="content-delete-page-popup">{messageDeleteConfirmPopup}</div>
          )
        }
      />
    </>
  );
}
