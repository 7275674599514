import { Image } from "antd";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { CustomInfoCircleIcon } from "constants/icons.constants";
import { images } from "constants/images.constants";
import { Platform } from "constants/platform.constants";
import storeDataService from "data-services/store/store-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";

const PlatformPreview = ({ platformId }) => {
  const { t } = useTranslation();
  const [storeInfo, setStoreInfo] = useState({});

  useEffect(() => {
    storeDataService.getStoreByIdAsync().then((data) => {
      setStoreInfo(data?.store);
    });
  }, []);
  const pageData = {
    hint: {
      howToSetUpProductVariants: t("productManagement.hint.howToSetUpProductVariants"),
      howToSetUpProductRecipes: t("productManagement.hint.howToSetUpProductRecipes"),
      optionDescriptionTooltip: t("productManagement.hint.optionDescriptionTooltip"),
      POSCashRegister: t("productManagement.hint.platformGuide.POSCashRegister"),
      POSDevices: t("productManagement.hint.platformGuide.POSDevices"),
      POSServing: t("productManagement.hint.platformGuide.POSServing"),
      storeApp: t("productManagement.hint.platformGuide.storeApp"),
      storeWebIs: t("productManagement.hint.platformGuide.storeWebIs"),
      storeWebLink: t("productManagement.hint.platformGuide.storeWebLink"),
      storeWebExample: t("productManagement.hint.platformGuide.storeWebExample"),
    },
  };
  switch (platformId.toString()) {
    case Platform.POS.toLowerCase():
      return (
        <>
          <FnbTooltip
            autoAdjustOverflow
            placement="topRight"
            maxWidth="524px"
            variant="secondary"
            title={() => (
              <div className="guide-text">
                <FnbTypography className="pos-devices" text={pageData.hint.POSCashRegister + ": "} />
                {<span dangerouslySetInnerHTML={{ __html: pageData.hint.POSDevices }}></span>}
              </div>
            )}
            arrowPointAtCenter={true}
          >
            <CustomInfoCircleIcon />
          </FnbTooltip>
        </>
      );
    case Platform.StoreWebsite.toLowerCase():
      return (
        <>
          <FnbTooltip
            autoAdjustOverflow
            placement="topRight"
            maxWidth="524px"
            variant="secondary"
            title={() => (
              <div className="store-web-guide-tooltip guide-text">
                <FnbTypography text={pageData.hint.storeWebIs} />
                <a className="store-web-link" href={storeInfo?.domainName} target={"_blank"} rel="noreferrer">
                  {pageData.hint.storeWebLink}
                </a>
                <FnbTypography text={pageData.hint.storeWebExample} />
                <div className="store-web-preview-image">
                  <Image
                    style={{ border: `1px solid ${theme.colors.primary[100]}`, borderRadius: "12px" }}
                    src={images.storeWebGuide}
                  />
                </div>
              </div>
            )}
            arrowPointAtCenter={true}
          >
            <CustomInfoCircleIcon />
          </FnbTooltip>
        </>
      );
    case Platform.StoreMobileApp.toLowerCase():
      return (
        <>
          <FnbTooltip
            autoAdjustOverflow
            placement="top"
            maxWidth="384px"
            variant="secondary"
            title={() => (
              <div className="store-app-guide-tooltip guide-text">
                {
                  <span
                    className="storeApp-preview-text"
                    dangerouslySetInnerHTML={{ __html: pageData.hint.storeApp }}
                  />
                }
                <div className="storeApp-preview-image">
                  <Image
                    style={{ border: `1px solid ${theme.colors.primary[100]}`, borderRadius: "12px" }}
                    src={images.storeAppGuide}
                  />
                </div>
              </div>
            )}
            arrowPointAtCenter={true}
          >
            <CustomInfoCircleIcon />
          </FnbTooltip>
        </>
      );
    case Platform.POSServing.toLowerCase():
      return (
        <>
          <FnbTooltip
            autoAdjustOverflow
            placement="topRight"
            maxWidth="524px"
            variant="secondary"
            title={() => (
              <div className="guide-text">
                {<span dangerouslySetInnerHTML={{ __html: pageData.hint.POSServing }}></span>}
              </div>
            )}
            arrowPointAtCenter={true}
          >
            <CustomInfoCircleIcon />
          </FnbTooltip>
        </>
      );
    default:
      return <></>;
  }
};

export default PlatformPreview;
