import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { resetPasswordSelector, STATUS_RESET_PASSWORD } from "../../../../../modules/auth/auth.reducer";
import "./forgot-password.style.scss";
import { ForgetPasswordTheme1 } from "./forgot-password.styled";
import InputNewPassword from "./input-new-password.component";
import InputOTP from "./input-otp.component";
import InputPhoneNumber from "./input-phone-number.component";
import { useEffect, useMemo, useState } from "react";
import { themeConfigSelector } from "../../../../../modules/session/session.reducers";
import PageType from "../../../../constants/page-type.constants";

const ForgotPassword = ({
  currentCountryCode,
  countryOptions,
  countries,
  handleRedirectToRegisterForm,
  setMode,
  setIsLoginByPassword,
  defaultThemePageConfig
}) => {
  const { t } = useTranslation();
  const { currentStatus } = useSelector(resetPasswordSelector);
  const pageConfigs = useSelector(themeConfigSelector)?.pages;
  const colorGroups = useSelector(themeConfigSelector)?.general?.color?.colorGroups;
  const [themePageConfig, setThemePageConfig] = useState(defaultThemePageConfig);

  useEffect(() => {
    const config = pageConfigs.find((item) => {
      return item?.id === PageType.LOGIN_PAGE;
    })?.config;
    const colorGroup = colorGroups.find((item) => {
      return item?.id === config?.colorGroupId;
    });
    setThemePageConfig({ ...config, colorGroup });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfigs]);

  const customStyles = useMemo(() => {
    const isBackgroundColor = themePageConfig?.backgroundType === 1;
    if (isBackgroundColor) {
      return {
        background: themePageConfig?.backgroundColor,
      };
    }
    return {
      backgroundImage: "url(" + themePageConfig?.backgroundImage + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }, [themePageConfig]);

  return (
    <ForgetPasswordTheme1 style={customStyles}>
      {currentStatus === STATUS_RESET_PASSWORD.INPUT_PHONE_NUMBER && (
        <InputPhoneNumber
          themePageConfig={themePageConfig}
          countries={countries}
          defaultIso={currentCountryCode}
          countryOptions={countryOptions}
          t={t}
          handleRedirectToRegisterForm={handleRedirectToRegisterForm}
        />
      )}
      {currentStatus === STATUS_RESET_PASSWORD.INPUT_OTP && <InputOTP themePageConfig={themePageConfig} />}
      {currentStatus === STATUS_RESET_PASSWORD.INPUT_NEW_PASSWORD && (
        <InputNewPassword
          themePageConfig={themePageConfig}
          t={t}
          setMode={setMode}
          setIsLoginByPassword={setIsLoginByPassword}
        />
      )}
    </ForgetPasswordTheme1>
  );
};

export default ForgotPassword;
