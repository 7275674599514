import "./styles.scss";
import React from "react";
import { Steps } from "antd";
import { TickCircleStepIcon } from "constants/icons.constants";

export const FnbStepItemStatus = {
  WAIT: "wait",
  PROCESS: "process",
  FINISH: "finish",
  ERROR: "error",
};

const FnbSteps = ({
  items, // {description, status  }
  direction = "vertical",
  className = "",
}) => {
  const stepItems = items?.map((item) => {
    return { icon: <TickCircleStepIcon />, ...item };
  });
  return <Steps current={2} className={`fnb-steps ${className}`} items={stepItems} direction={direction} />;
};

export default FnbSteps;
