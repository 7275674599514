import http from "utils/http-common";

const controller = "order-packages";

const getListAsync = async (data) => {
  const { page = 0, size = 20, status = "" } = data;
  const result = await http.get(`/${controller}/get-data-by-store?page=${page}&size=${size}&status=${status}`);
  return result;
};

const getPaymentTransactionsAsync = async (data) => {
  const { id, page = 0, size = 20 } = data;
  const result = await http.get(`/${controller}/payment-transactions/${id}?page=${page}&size=${size}`);
  return result;
};

const cancelRenewAsync = async (id) => {
  const result = await http.put(`/${controller}/cancel-renew/${id}`);
  return result;
};

const orderPackageService = {
  getListAsync,
  getPaymentTransactionsAsync,
  cancelRenewAsync,
};

export default orderPackageService;
