import { useTranslation } from "react-i18next";
import { BucketGeneralCustomizeIcon } from "../../../assets/icons.constants";
import PageType from "../../../constants/page-type.constants";
import defaultConfig from "../../../default-store.config";
import CustomizationCollapseBlock from "../../../components/customization-block-component/customization-block.page";
import SelectBackgroundComponent from "../../../components/select-background.component";
import SelectColorGroupComponent from "../../../components/select-color-group.component";
import blogsBackgroundDefaultImage from "../../../assets/images/blog-home-page-default.png";
import { useEffect } from "react";

export default function BlogCustomizeComponent(props) {
    const { form, clickToScroll } = props;
    const { getFieldsValue } = form;
    const [t] = useTranslation();
    const translateData = {
        generalCustomization: t("onlineStore.introductionConfiguration.generalCustomization", "General customization"),
    };

    const defaultThemePageConfig = defaultConfig?.pages?.find((p) => p.id === PageType.HOME_PAGE);

    useEffect(() => {
        setTimeout(() => {
            setFocusElement(clickToScroll);
        }, 100);
    }, []);

    const setFocusElement = (elementId) => {
        try {
            const element = document.querySelector(elementId);
            if (element) {
                // set border element on focused
                element.className = "tc-on-focus";
                element.scrollIntoView({ behavior: "smooth", block: "center" });
                window.oldElements = elementId;
            }
        } catch { }
    };

    const renderGeneralCustomization = () => {
        return (
            <>
                <SelectBackgroundComponent
                    {...props}
                    defaultColorPath="config.blogs.generalCustomization.backgroundColor"
                    defaultConfig={defaultConfig}
                    formItemPreName={["config", "blogs", "generalCustomization"]}
                    backgroundCustomize={getFieldsValue()?.config?.blogs?.generalCustomization}
                    defaultThemeColor={defaultThemePageConfig?.config?.blogs?.generalCustomization?.backgroundColor}
                    defaultImage={blogsBackgroundDefaultImage}
                />
                <SelectColorGroupComponent {...props} formItemPreName={["config", "blogs", "generalCustomization"]} />
            </>
        );
    };

    return (
        <CustomizationCollapseBlock
            title={translateData.generalCustomization}
            isNormal={true}
            content={renderGeneralCustomization()}
            defaultActiveKey={1}
            isShowKey={true}
            icon={<BucketGeneralCustomizeIcon />}
            className="customization-block-blog"
            clickToScroll={clickToScroll}
        />
    );
}
