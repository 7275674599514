import { RecordIcon } from "constants/icons.constants";
import "./fnb-badge.style.scss";
import { memo } from "react";
import { Typography } from "antd";
const { Text } = Typography;

export const FnbBadgeVariant = {
  PRIMARY: "primary",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  NEW: "new",
  HIDDEN: "hidden",
};

export const FnbBadge = memo((props) => {
  const {
    className = "",
    variant = FnbBadgeVariant.PRIMARY, // primary , success, error, warning
    text = "",
    frontIcon,
    backIcon,
    style,
    minHeight = 32,
    width = "fit-content", // number or text
    fontVariant = "medium", // regular: 12px, medium: 14px, larger: 16px;
    justifyContent = "center", // center , end, flex-end ....
    children,
    textStyle = "",
  } = props;

  return (
    <div
      className={`fnb-badge fnb-badge--${variant} ${className}`}
      style={{ ...style, minHeight: minHeight, width: width, justifyContent: justifyContent }}
    >
      {frontIcon && <div className="fnb-badge__dv-icon fnb-badge__dv-icon--font-icon">{frontIcon}</div>}
      <Text style={textStyle} className={`fnb-badge__p-text fnb-badge__p-text--${fontVariant}`}>
        {text ?? children}
      </Text>
      {backIcon && <div className="fnb-badge__dv-icon fnb-badge__dv-icon--back-icon">{backIcon}</div>}
    </div>
  );
});
