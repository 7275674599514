import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { checkOutOfStockProductList } from "../../../../../services/material/check-out-of-stock.service";
import mapperOutOfStockServices from "../../../../../services/product/product-mapping.services";
import { isNonEmptyArray } from "../../../../../utils/helpers";
import FlashSaleProductComponent from "../flash-sale-product/flash-sale-product.component";
import "./flash-sale-slider.component.scss";

export default function FlashSaleSliderComponent(props) {
  const { products, flashSaleStatus } = props;
  const branchAddress = useSelector((state) => state?.session?.deliveryAddress?.branchAddress);
  const branchIdSelected = window?.isStoreAppWebView ? window.branchIdOnStoreApp : branchAddress?.id;
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });

  // Out of stock
  const [productPrices, setProductPrices] = useState([]);

  async function fetchOutOfStockData(productPrices) {
    const outOfStockData = await checkOutOfStockProductList(branchIdSelected, true, productPrices);
    setProductPrices(outOfStockData);
  }

  useEffect(() => {
    if (!isNonEmptyArray(products)) return;
    const productPricesData = mapperOutOfStockServices.mapperProducts2Variants(products);

    fetchOutOfStockData(productPricesData);
  }, [products]);

  let spaceBetween = 32;
  if (isMaxWidth575) {
    spaceBetween = 16;
  }
  const fitSpaceRightLastSlide = -32 * (products?.length - 1) - spaceBetween;

  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={32}
      slidesOffsetAfter={fitSpaceRightLastSlide}
      freeMode={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        576: {
          slidesOffsetAfter: fitSpaceRightLastSlide,
        },
      }}
      modules={[FreeMode, Pagination]}
      className="flash-sale-slider"
    >
      {products.map((item, index) => {
        const outOfStock = productPrices?.filter((i) => item?.productPriceId === i.productPriceId && i.outOfStock);
        return (
          <SwiperSlide key={index}>
            <FlashSaleProductComponent
              product={item}
              index={index}
              flashSaleStatus={flashSaleStatus}
              isOutOfStock={isNonEmptyArray(outOfStock)}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
