import React from "react";
import "./CancelReasonBox.scss";
const CancelReasonBox = ({ cancelReason, translateData }) => {
  return (
    <div className="cancel-reason-box">
      <p className="reason-content">
        <b>{translateData.cancelReason}: </b>
        {cancelReason}
      </p>
    </div>
  );
};
export default CancelReasonBox;
