import React from "react";
import { useTranslation } from "react-i18next";
import { TrashFill } from "constants/icons.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

export function FnbTrashFillIcon(props) {
  const [t] = useTranslation();
  return (
    <div className="fnb-table-action-icon">
      <FnbTooltip placement="top" title={t("button.delete")}>
        <TrashFill className="icon-svg-hover" />
      </FnbTooltip>
    </div>
  );
}
