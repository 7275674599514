import { Col } from "antd";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTable } from "components/fnb-table/fnb-table";
import styled from "styled-components";
import theme from "theme";

export const FnbModalAddNewToppingStyled = styled(FnbModal)`
  display: ${(p) => (p.displayNoneModal === true ? "none !important" : "inline-flex")};
  .ant-modal-body {
    min-height: 330px;
    max-height: 75vh;
    overflow-y: auto;
    .form-item-platforms-modal-add-new-topping {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0;
        }
        @media (max-width: ${theme.screen.md}) {
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 12px;
        }
      }
      .checkbox-platform-container {
        display: inline-flex;
        align-items: flex-end;
        .checkbox-platform {
          width: fit-content;
        }
      }
    }
  }
`;

export const WrapInputQuantityIngredient = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const TableListIngredients = styled(FnbTable)`
  padding: 0;
  .ant-table-cell {
    height: 95px;
    .ant-form-item-control {
      .ant-form-item-explain {
        .ant-form-item-explain-error {
          min-width: 240px;
        }
      }
    }
  }
`;

export const InfoTotalCostTable = styled.div`
  display: flex;
  column-gap: 6px;
  margin-top: 8px;
  justify-content: flex-end;
`;

export const ColPrintSticker = styled(Col)`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const TitleCustomModalAddTax = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  button {
    position: absolute;
    left: 0;
  }
`;

export const WraptTipRecipe = styled.div`
  margin-top: 28px;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
