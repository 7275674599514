import { Card, message } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import productDataService from "data-services/product/product-data.service";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { executeAfter, hasPermission, Sort } from "utils/helpers";
import TableProduct from "./products-in-category.modal";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { ConfirmStyle, ConfirmViewActiveItemStyle } from "constants/level-menu.constants";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

export default function TableProductCategory(props) {
  const { setShowAddCategory } = props;
  const history = useHistory();
  const [t] = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isVisibleConfirmDelete, setIsVisibleConfirmDelete] = useState(false);
  const [categorySelect, setCategorySelect] = useState([]);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [activeCombos, setActiveCombos] = useState(null);
  const cardRef = useRef();
  const pageData = {
    btnFilter: t("button.filter"),
    productCategoryDeleteSuccess: t("productCategory.productCategoryDeleteSuccess"),
    productCategoryUpdateSuccess: t("productCategory.productCategoryUpdateSuccess"),
    productCategoryDeleteFail: t("productCategory.productCategoryDeleteFail"),
    productCategoryRelatedMessage: t("productCategory.productCategoryRelatedMessage"),
    table: {
      searchPlaceholder: t("productCategory.table.searchPlaceholder"),
      no: t("table.no"),
      name: t("productCategory.table.name"),
      priority: t("productCategory.table.priority"),
      product: t("productCategory.table.product"),
    },
    product: {
      title: t("productCategory.product.title"),
      placeholder: t("productCategory.product.placeholder"),
    },
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  useEffect(() => {
    fetchDataTableAsync(currentPageNumber, tableConfigs.pageSize, "");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("table-product-category").getElementsByTagName("table")?.item(0);
      if (element) {
        element.parentElement.parentElement.style.height = `${element.clientHeight + 11}px`;
      }
    }, 100);
  }, [dataSource]);

  const fetchDataTableAsync = async (pageNumber, pageSize, keySearch) => {
    const response = await productCategoryDataService.getProductCategoriesAsync(pageNumber, pageSize, keySearch);
    const data = response?.productCategories.map((s) => mappingRecordToColumns(s));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
  };

  const reload = async () => {
    await fetchDataTableAsync(currentPageNumber, tableConfigs.pageSize, "");
  };

  const mappingRecordToColumns = (item) => {
    return {
      key: item?.id,
      index: item?.no,
      id: item?.id,
      name: item?.name,
      priority: item?.priority,
      numberOfProduct: item?.numberOfProduct,
      products: item?.products,
    };
  };

  const onEditRowKeys = (selectedRowKeys, selectedRows) => {
    history.push(`/product/product-category/edit/${selectedRows[0]?.id}`);
    setSelectedRowKeys([]);
  };

  const onDeleteRowKeys = async (selectedRowKeys, selectedRows) => {
    handleDeleteAction(selectedRows[0]);
    setCategorySelect(selectedRows);
  };

  const onHandleSelectedProductCategory = async (productCategory, keySearch) => {
    try {
      const responseData = await productDataService.getProductsByCategoryIdAsync(productCategory?.id, keySearch);
      if (responseData) {
        const records = responseData?.productsByCategoryId?.map((item) => {
          return {
            key: item?.productId,
            productId: item?.productId,
            name: item?.name ?? "N/A",
            unitName: item?.unitName ?? "N/A",
            thumbnail: item?.thumbnail,
            isTopping: item?.isTopping,
          };
        });
        setShowProductsModal(true);
        setSelectedProductCategory(productCategory);
        setDataModal(records);
        await getProducts(records);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handelRemoveProductModal = (productId) => {
    let addDataItemProduct = allProducts.filter((item) => item?.productId === productId);
    setProducts(products.concat(addDataItemProduct));

    const newData = dataModal.filter((item) => item?.productId !== productId);
    setDataModal(newData);
  };

  const onCloseModal = async () => {
    setSelectedProductCategory(null);
    setDataModal([]);
    setShowProductsModal(false);
    setProducts([]);
  };

  const onSubmitModal = async () => {
    try {
      const data = dataModal.map((item) => {
        return {
          productId: item?.productId,
          name: item?.name,
          thumbnail: "",
          unitName: "",
          index: item?.index,
        };
      });
      const putData = {
        productCategoryId: selectedProductCategory?.id,
        productByCategoryIdModel: [],
      };
      putData["productByCategoryIdModel"] = data;
      const res = await productDataService.updateProductByCategoryAsync(putData);
      if (res) {
        message.success(pageData.productCategoryUpdateSuccess);
        await reload();
        onCloseModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableConfigs = {
    pageSize: tableSettings.pageSize,
    columns: [
      {
        title: pageData.table.no,
        dataIndex: "index",
        width: "5rem",
        key: "index",
        render: (value) => value + 1,
      },
      {
        title: pageData.table.name,
        dataIndex: "name",
        key: "name",
        className: "category-name-column",
        sorter: (a, b) => Sort(a?.name, b?.name),
        render: (value) => {
          return (
            <div className="column-product-name">
              <FnbTooltip onlyShowWhenEllipsis={true}>{value}</FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.table.product,
        dataIndex: "numberOfProduct",
        key: "numberOfProduct",
        width: "50%",
        align: "right",
        render: (_, record) => {
          if (record?.numberOfProduct > 0) {
            return (
              <FnbTypography.Link
                onClick={() => onHandleSelectedProductCategory(record, "")}
                text={record?.numberOfProduct}
              />
            );
          } else {
            return <span>{record?.numberOfProduct}</span>;
          }
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDataTableAsync(page, pageSize, "");
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        await fetchDataTableAsync(1, tableConfigs.pageSize, keySearch);
      });
    },
  };

  const onSearchProductModal = async (keySearch) => {
    executeAfter(500, async () => {
      await onHandleSelectedProductCategory(selectedProductCategory, keySearch);
    });
  };

  const getProducts = async (records) => {
    try {
      var res = await productDataService.getAllProductIncludedProductUnitAsync();
      if (res) {
        const productsToAddModel = res.productsToAddModel;
        setAllProducts(productsToAddModel);
        var productIds = records.map(function (item) {
          return item["productId"];
        });
        const result = productsToAddModel.filter((item) => !productIds.includes(item?.productId));
        setProducts(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectedProduct = (productId) => {
    let selectedProducts = products.find((item) => item?.productId === productId);
    const productItem = {
      key: selectedProducts?.productId,
      productId: selectedProducts?.productId,
      name: selectedProducts?.name,
      unitName: selectedProducts?.unitName,
      thumbnail: selectedProducts?.thumbnail,
    };
    setProducts(products.filter((item) => item?.productId !== productId));
    setDataModal([...dataModal, productItem]);
  };

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleDeleteAction = async (category) => {
    if (category) {
      var res = await productCategoryDataService.getCombosByProductCategoryIdAsync(category.id);
      if (res && res?.activeCombos?.length > 0) {
        let message = t(pageData.productCategoryRelatedMessage, {
          name: category.name,
        });
        setNotificationMessage(message);
        if (hasPermission(PermissionKeys.VIEW_COMBO)) {
          setActiveCombos(
            res.activeCombos.map((combo) => {
              const label = combo.name;
              const link = `/combo/detail/${combo.id}`;
              return { label, link };
            }),
          );
        } else {
          setActiveCombos(
            res.activeCombos.map((combo) => {
              const label = combo.name;
              const link = `/combo/detail/${combo.id}`;
              return { label, link };
            }),
          );
        }

        setIsModalNotificationVisible(true);
      } else {
        setIsVisibleConfirmDelete(true);
      }
    } else {
      setIsVisibleConfirmDelete(true);
    }
  };

  return (
    <Card className="fnb-card-custom" id="table-product-category" ref={cardRef}>
      <FnbTable
        columns={tableConfigs.columns}
        pageSize={tableConfigs.pageSize}
        dataSource={dataSource}
        currentPageNumber={currentPageNumber}
        total={totalRecords}
        onChangePage={tableConfigs.onChangePage}
        editPermission={PermissionKeys.EDIT_PRODUCT_CATEGORY}
        deletePermission={PermissionKeys.DELETE_PRODUCT_CATEGORY}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectedRowKeysChange,
          onEdit: onEditRowKeys,
          onDelete: onDeleteRowKeys,
        }}
        search={{
          placeholder: pageData.table.searchPlaceholder,
          onChange: tableConfigs.onSearch,
        }}
        onRowClick={(record) =>
          hasPermission(PermissionKeys.VIEW_PRODUCT) && onHandleSelectedProductCategory(record, "")
        }
        emptyText={
          hasPermission(PermissionKeys.CREATE_PRODUCT_CATEGORY) && (
            <div className="content-collapse-empty-text">
              <FnbTypography.Link
                variant={theme.typography["b1-bold-underlined"]}
                underline={true}
                text={pageData.addNew}
                onClick={() => setShowAddCategory(true)}
              />
              {pageData.toCreateData}
            </div>
          )
        }
      />
      <TableProduct
        title={selectedProductCategory?.name}
        showProductsModal={showProductsModal}
        onCancel={onCloseModal}
        dataModal={dataModal}
        products={products}
        onSelectedProduct={onSelectedProduct}
        setDataModal={setDataModal}
        onRemoveItemModal={handelRemoveProductModal}
        onSearchProductModal={onSearchProductModal}
        onSubmitModal={onSubmitModal}
      />
      <ConfirmDialogComponent
        visible={isModalNotificationVisible}
        onOk={() => {
          setIsModalNotificationVisible(false);
        }}
        onCancel={() => {
          setIsModalNotificationVisible(false);
        }}
        content={notificationMessage}
        activeItems={activeCombos}
        type={ConfirmStyle.NOTIFICATION}
        typeViewActiveItems={ConfirmViewActiveItemStyle.LIST}
      />
      <ConfirmDialogComponent
        visible={isVisibleConfirmDelete}
        ItemNameInContent={categorySelect[0]?.name}
        onOk={async () => {
          try {
            const res = await productCategoryDataService.deleteProductCategoryByIdAsync(categorySelect[0]?.id);
            if (res) {
              message.success(pageData.productCategoryDeleteSuccess);
              setSelectedRowKeys([]);
            } else {
              message.error(pageData.productCategoryDeleteFail);
            }
            await fetchDataTableAsync(currentPageNumber, tableConfigs.pageSize, "");
            setIsVisibleConfirmDelete(false);
          } catch (error) {
            console.log(error);
          }
        }}
        onCancel={() => {
          setIsVisibleConfirmDelete(false);
        }}
        type={ConfirmStyle.DELETE}
      />
    </Card>
  );
}
