import { Col, Form, Space, Row, message } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import FnbFroalaEditor from "components/fnb-froala-editor";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { TableName } from "constants/areaTable.constants";
import { SizeScreen } from "constants/size-screen.constants";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "utils/check-screen";
import { checkOnKeyPressValidation } from "utils/helpers";
import { LockMultipleCalls } from "themes/services/promotion.services";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import SelectEditComponent from "components/select-edit-new-item/select-edit-new-item";
import "./create-new-table.component.scss";
import areaDataService from "data-services/area/area-data.service";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import { UploadLogoIcon } from "constants/icons.constants";

export default function AddNewAreaTable(props) {
  const { isModalVisible, listArea, storeBranchId, onCancel, areaTableDataService, areaId, onOk } = props;
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const [image, setImage] = useState(null);
  const [descriptionAreaTable, setDescriptionAreaTable] = useState("");
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const selectNewItemFuncs = React.useRef(null);
  const imageRef = useRef(null);
  const checkScreen = useWindowDimensions();
  const widthModal = checkScreen === SizeScreen.IS_TABLET ? 984 : 1336;
  const [isAreaNameExisted, setIsAreaNameExisted] = useState(false);
  const [nameArea, setNameArea] = useState("");
  const [listAreaNew, setListAreaNew] = useState(null);
  const [newTableName, setNewTableName] = useState("");

  const pageData = {
    titleAddNewTable: t("areaTable.tableForm.titleAddNewTable"),
    areaName: t("areaTable.areaForm.areaName"),
    selectArea: t("areaTable.areaForm.selectArea"),
    pleaseSelectArea: t("areaTable.areaForm.pleaseSelectArea"),
    areaNameExisted: t("areaTable.areaForm.AreaNameExisted"),
    addtable: t("areaTable.tableForm.addtable"),
    nameTable: t("areaTable.tableForm.nameTable"),
    nameTableRequired: t("areaTable.tableForm.nameTableIsRequired"),
    namePlaceholder: t("areaTable.tableForm.namePlaceholder"),
    numberOfSeat: t("areaTable.seat"),
    validNumberSeat: t("areaTable.tableForm.validNumberSeat"),
    addNew: t("areaTable.addNew"),
    cancel: t("button.cancel"),
    createTableSuccess: t("messages.createTableSuccess"),
    createTableUnSuccess: t("messages.createTableUnSuccess"),
    description: t("form.description"),
    max: 999999999,
    image: t("areaTable.image"),
    upload: {
      addFile: t("productManagement.generalInformation.uploadImages"),
      addFromUrl: t("upload.addFromUrl"),
    },
    tableNameMaxLength: 50,
  };

  useEffect(() => {
    if (!isModalVisible) {
      setNewTableName("");
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (listArea?.length > 0) {
      setListAreaNew(listArea);
    }
    else {
      setListAreaNew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listArea]);

  useEffect(() => {
    setNewTableName("");
    onChangeArea(areaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId]);

  const onFinish = async () => {
    setFormDataChanged(false);
    var formValue = form.getFieldsValue();
    formValue.imageUrl = image?.url;
    let req = {
      ...formValue,
      areaId: selectedArea,
      shortDescription: descriptionAreaTable?.replace(/<.*?>/gm, "")?.slice(0, 200),
    };
    LockMultipleCalls(
      async () => {
        const res = await areaTableDataService.createAreaTableByAreaIdAsync(req);
        if (res?.succeeded) {
          message.success(pageData.createTableSuccess);
          onOk(selectedArea);
          handleCancelAndResetForm();
        } else {
          if (res?.message) {
            message.error(t(res?.message));
          } else {
            message.success(pageData.createTableUnSuccess);
          }
        }
      },
      "Lock_createNewTable",
      1000,
    );
  };

  const onChangeArea = (id, listAreaPresent) => {
    if (id) {
      // Creates default table name when the user not input table name
      let tableName = newTableName;
      if (!tableName) {
        const listArea = listAreaPresent || listAreaNew;
        const area = listArea?.find((area) => area?.id === id);
        const count = area?.areaTables?.length ?? 0;
        tableName = `${TableName} ${count + 1}`;
      }

      if (tableName) {
        form.setFieldsValue({
          name: tableName,
          areaId: id,
        });
        setSelectedArea(id);
        if (selectNewItemFuncs.current) {
          selectNewItemFuncs.current(id);
          setIsAreaNameExisted(true);
        }
      }
    } else {
      setSelectedArea(null);
      form.setFieldsValue({
        name: "",
        areaId: null,
      });
    }
  };

  //Enter Area name and check existed
  const onNameChange = (value) => {
    if (listAreaNew?.filter((area) => area?.name?.trim() === value?.trim())?.length > 0) {
      setIsAreaNameExisted(true);
    } else {
      setIsAreaNameExisted(false);
    }
    setNameArea(value);
  };

  //Handle add New Area
  const addNewArea = async (e) => {
    if (nameArea) {
      e.preventDefault();
      let req = {
        storeBranchId: storeBranchId,
        name: nameArea,
      };
      LockMultipleCalls(
        async () => {
          const res = await areaDataService.createAreaManagementAsync(req);
          if (res?.succeeded) {
            const id = res?.data?.id;
            const name = res?.data?.name;
            const newItem = {
              id: id,
              name: name,
              areaTables: [],
            };
            const newData = listAreaNew ? [newItem, ...listAreaNew] : [newItem];
            setListAreaNew(newData);
            onChangeArea(id, newData);
            setFormDataChanged(true);
            setIsAreaNameExisted(true);
          } else {
            message.error(pageData.areaNameExisted);
          }
        },
        "Lock_createNewArea",
        1000,
      );
    }
  };

  const handleCancelAndResetForm = () => {
    setNewTableName("");
    setFormDataChanged(false);
    setDescriptionAreaTable(null);
    imageRef?.current?.setImage(null);
    setImage(null);
    form.resetFields();
  };

  const onUploadImage = (file) => {
    setFormDataChanged(true);
    setImage(file);
  };

  const renderInputImage = () => {
    return (
      <Col xl={7} md={7} xs={24}>
        <Row className="upload-product-image">
          <FnbTypography variant={theme.typography["b1-medium"]} text={pageData.image} />
          <Row className={`non-image ${image !== null ? "have-image" : ""}`} style={{ marginTop: 8 }}>
            <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
              <Form.Item name={"imageUrl"}>
                <FnbCustomUploadImageComponent
                  ref={imageRef}
                  iconUpload={<UploadLogoIcon />}
                  buttonText={pageData.upload.addFile}
                  onChange={onUploadImage}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Col>
    );
  };

  return (
    <FnbModal
      className="modal-add-table"
      title={pageData.titleAddNewTable}
      visible={isModalVisible}
      handleCancel={onCancel}
      footer={(null, null)}
      width={widthModal}
      hideFooter={true}
      content={
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFieldsChange={() => {
            if (formDataChanged === false) {
              setFormDataChanged(true);
            }
          }}
          className="custom-form"
          layout="vertical"
        >
          <Row gutter={16}>
            {checkScreen === SizeScreen.IS_MOBILE ? renderInputImage() : null}
            <Col xl={17} md={17} xs={24} className="container-input">
              <Space direction="vertical" size={24} className="w-100">
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.nameTableRequired} />,
                    },
                  ]}
                  className="w-100"
                  label={pageData.nameTable}
                >
                  <FnbInput
                    showCount
                    className="w-100"
                    placeholder={pageData.namePlaceholder}
                    maxLength={pageData.tableNameMaxLength}
                    onChange={(event) => setNewTableName(event.target.value)}
                  />
                </Form.Item>
                <Row gutter={[12, 24]} className="box-area-seat">
                  <Col span={12} xl={12} md={12} xs={24} className="dropdown-area">
                    <Form.Item
                      name={"areaId"}
                      className="w-100"
                      label={pageData.areaName}
                      rules={[
                        {
                          required: true,
                          message: pageData.pleaseSelectArea,
                        },
                      ]}
                    >
                      <SelectEditComponent
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showSearch
                        value={selectedArea}
                        functions={selectNewItemFuncs}
                        placeholder={pageData.selectArea}
                        pleaseEnterName={pageData.pleaseSelectArea}
                        nameExisted={pageData.areaNameExisted}
                        btnAddNew={pageData.addNew}
                        listOption={listAreaNew?.map((item) => ({
                          id: item?.id,
                          name: item?.name,
                        }))}
                        onChangeOption={(id) => onChangeArea(id)}
                        onSearch={onNameChange}
                        addNewItem={addNewArea}
                        isNameExisted={isAreaNameExisted}
                        name={nameArea}
                        isEditProduct={true}
                        category={pageData.areaName}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xl={12} md={12} xs={24} className="input-seat">
                    <Form.Item
                      name={"numberOfSeat"}
                      rules={[
                        {
                          required: true,
                          message: pageData.validNumberSeat,
                        },
                        {
                          validator(rule, value) {
                            if (value == null || value === undefined || value < 1) {
                              return Promise.reject(<InputValidateMessage message={pageData.validNumberSeat} />);
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                      validateFirst
                      className="w-100"
                      label={pageData.numberOfSeat}
                    >
                      <FnbInputNumber
                        id="minQuantity"
                        className="w-100"
                        placeholder={pageData.numberOfSeat}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onKeyPress={(event) => {
                          const checkValidKey = checkOnKeyPressValidation(event, "minQuantity", 0, pageData.max, 0);
                          if (!checkValidKey) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name={"description"} label={pageData.description} style={{ minHeight: "470px" }}>
                  <FnbFroalaEditor
                    value={descriptionAreaTable}
                    onChange={(value) => {
                      if (value !== "" && value !== "<div></div>");
                      setDescriptionAreaTable(value);
                    }}
                    charCounterMax={-1}
                    heightMin={300}
                    heightMax={300}
                  />
                </Form.Item>
              </Space>
            </Col>

            {checkScreen !== SizeScreen.IS_MOBILE ? renderInputImage() : null}
          </Row>
          <Row className="float-center justify-content-center" style={{ marginTop: 24, gap: "8px" }}>
            <FnbCancelButton buttonText={pageData.cancel} key="back" showWarning={formDataChanged} onOk={onCancel} />
            <FnbButton text={pageData.addNew} type="submit" disabled={!formDataChanged} />
          </Row>
        </Form>
      }
    />
  );
}
