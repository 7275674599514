import { useState, useEffect } from "react";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import TableProductCategory from "./components/table-product-category.component";
import FormNewProductCategory from "./components/form-new-product-category.component";
import { PermissionKeys } from "constants/permission-key.constants";
import "./index.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

/**
 * Page Product Management
 * description: page manage product primary template
 */
export default function ProductCategoryManagement(props) {
  const { t, productDataService, branchDataService } = props;
  const [showAddNewProductCategoryForm, setShowAddNewProductCategoryForm] = useState(false);

  const pageData = {
    title: t("productCategory.title"),
    button: {
      addNew: t("button.addNew"),
    },
  };

  useEffect(() => {});

  return (
    <>
      {!showAddNewProductCategoryForm && (
        <FnbHeadingPage
          title={pageData.title}
          permissionCreate={PermissionKeys.CREATE_PRODUCT_CATEGORY}
          onCreate={() => setShowAddNewProductCategoryForm(true)}
        />
      )}
      {showAddNewProductCategoryForm ? (
        <>
          <FormNewProductCategory
            t={t}
            onCompleted={() => setShowAddNewProductCategoryForm(false)}
            productCategoryDataService={productCategoryDataService}
            productDataService={productDataService}
            branchDataService={branchDataService}
          />
        </>
      ) : (
        <TableProductCategory setShowAddCategory={setShowAddNewProductCategoryForm} />
      )}
    </>
  );
}
