import { Card } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableNotificationCampaign from "./components/table-notification-campaign.component";
import "./notification-campaign.page.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { isStoreInternational } from "utils/helpers";
import { useEffect } from "react";

export function NotificationCampaign(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const notificationCampaignCreateLink = "/marketing/notification-campaign/create-notification-campaign";
  const isInternational = isStoreInternational();

  const pageData = {
    title: t("marketing.notificationCampaign.title"),
    addNew: t("button.addNew"),
    guideline: {
      title: t("marketing.notificationCampaign.guideline.title"),
      content: t("marketing.notificationCampaign.guideline.content"),
    },
  };

  useEffect(() => {
    if (isInternational) {
      history.push("/home");
    }
  }, []);

  return (
    <>
      <FnbHeadingPage
        guideline={pageData.guideline}
        title={pageData.title}
        onCreate={() => history.push(notificationCampaignCreateLink)}
        permissionCreate={PermissionKeys.CREATE_NOTIFICATION_CAMPAIGN}
      />
      <Card className="fnb-card-custom">
        <TableNotificationCampaign />
      </Card>
    </>
  );
}
