import http from "../../utils/http-common";

const controller = "kitchen";

const getKitchensByBranchIdWithPagingAsync = (data) => {
  return http.get(
    `/${controller}/by-branch-paging?branchId=${data?.branchId}&pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}&keySearch=${data?.keySearch}`,
  );
};

const deleteKitchenByIdAsync = (id) => {
  return http.delete(`/${controller}/${id}`);
};

const verifyKitchenByIdAsync = (data) => {
  return http.post(`/${controller}/verify`, data);
};

const createNewKitchenAsync = (data) => {
  return http.post(`/${controller}`, data);
};

const getKitchenByIdAsync = (kitchenId) => {
  return http.get(`/${controller}/${kitchenId}`);
};

const updateKitchenByIdAsync = (kitchenId, data) => {
  return http.put(`/${controller}/${kitchenId}`, data);
};

const createNewDefaultKitchenByStoreIdAsync = () => {
  return http.post(`/${controller}/create-default`, {});
};

const kitchenDataService = {
  getKitchensByBranchIdWithPagingAsync,
  deleteKitchenByIdAsync,
  verifyKitchenByIdAsync,
  createNewKitchenAsync,
  getKitchenByIdAsync,
  updateKitchenByIdAsync,
  createNewDefaultKitchenByStoreIdAsync
};

export default kitchenDataService;
