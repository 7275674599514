/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Form, Input, Row, message, Space } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import FnbFroalaEditor from "components/fnb-froala-editor";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { SizeScreen } from "constants/size-screen.constants";
import areaDataService from "data-services/area/area-data.service";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "utils/check-screen";
import "./create-new-area.component.scss";
import { LockMultipleCalls } from "themes/services/promotion.services";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import { UploadLogoIcon } from "constants/icons.constants";

export default function AddNewArea(props) {
  const [t] = useTranslation();
  const { isModalVisible, handleCancel, storeBranchId, onOk, setActiveKey } = props;
  const [form] = Form.useForm();
  const imageRef = useRef(null);
  const checkScreen = useWindowDimensions();
  const widthModal = checkScreen === SizeScreen.IS_TABLET ? 984 : 1336;
  const [image, setImage] = useState(null);
  const [descriptionArea, setDescriptionArea] = useState("");

  const pageData = {
    area: t("area.area"),
    addNewArea: t("area.addNewArea"),
    areaName: t("area.areaName"),
    image: t("area.image"),
    areaNameMaxLength: 100,
    description: t("form.description"),
    descriptionMaxLength: 2000,
    cancel: t("button.cancel"),
    addNew: t("area.addNew"),
    add: t("button.add"),
    enterAreaName: t("area.enterAreaName"),
    pleaseEnterAreaName: t("area.pleaseEnterAreaName"),
    areaAddedSuccessfully: t("area.areaAddedSuccessfully"),
    areaAddedUnSuccessfully: t("area.areaAddedUnSuccessfully"),
    upload: {
      addFile: t("productManagement.generalInformation.uploadImages"),
      addFromUrl: t("upload.addFromUrl"),
    },
  };

  useEffect(() => {
    form.setFieldsValue({
      storeBranchId: storeBranchId,
    });
  });

  const [formDataChanged, setFormDataChanged] = useState(false);

  const onCancelAfterConfirm = async () => {
    resetImage();
    setFormDataChanged(false);
    setDescriptionArea(null);
    await form.resetFields();
    handleCancel();
  };

  const onFinish = async (values) => {
    values.imageUrl = image?.url;
    var req = { ...values, shortDescription: values?.description?.replace(/<.*?>/gm, "").slice(0, 200) };
    setFormDataChanged(false);
    LockMultipleCalls(
      async () => {
        const res = await areaDataService.createAreaManagementAsync(req);
        if (res?.succeeded) {
          setActiveKey(res?.data?.id);
          form.resetFields();
          resetImage();
          message.success(pageData.areaAddedSuccessfully);
          onCancelAfterConfirm();
          onOk();
        } else {
          if (res?.message) {
            message.error(t(res?.message));
          } else {
            message.success(pageData.areaAddedUnSuccessfully);
          }
        }
      },
      "Lock_createNewArea",
      1000,
    );
  };

  const onUploadImage = (file) => {
    setFormDataChanged(true);
    setImage(file);
  };

  const resetImage = () => {
    setImage(null);
    imageRef?.current?.setImage(null);
  };

  const renderInputImage = () => {
    return (
      <Col xl={7} md={7} xs={24} className="w-100" style={{ marginBottom: 24 }}>
        <Row className="w-100 upload-product-image" gutter={[0, 8]}>
          <FnbTypography variant={theme.typography["b1-medium"]} text={t(pageData.image)} />
          <Row className={`non-image ${image !== null ? "have-image" : ""}`} style={{ marginTop: 8 }}>
            <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
              <Form.Item name={"imageUrl"}>
                <FnbCustomUploadImageComponent
                  ref={imageRef}
                  iconUpload={<UploadLogoIcon />}
                  buttonText={pageData.upload.addFile}
                  onChange={onUploadImage}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Col>
    );
  };

  return (
    <FnbModal
      className="modal-add-area"
      title={pageData.addNewArea}
      handleCancel={onCancelAfterConfirm}
      visible={isModalVisible}
      footer={(null, null)}
      hideFooter={true}
      centered
      width={widthModal}
      content={
        <Form
          autoComplete="off"
          form={form}
          name="basic"
          className="custom-form"
          onFinish={onFinish}
          onKeyDown={(event) => {
            if (event.keyCode === 13 || event.key === "Enter") event.preventDefault();
          }}
          onFieldsChange={() => {
            if (formDataChanged === false) {
              setFormDataChanged(true);
            }
          }}
          layout="vertical"
        >
          <Space direction="vertical" size={0}>
            <Row gutter={12}>
              {checkScreen === SizeScreen.IS_MOBILE ? renderInputImage() : null}
              <Col xl={17} md={17} xs={24}>
                <Space direction="vertical" size={24}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.pleaseEnterAreaName} />,
                      },
                    ]}
                    label={pageData.areaName}
                  >
                    <FnbInput showCount placeholder={pageData.enterAreaName} maxLength={pageData.areaNameMaxLength} />
                  </Form.Item>
                  <Form.Item name="description" label={pageData.description} style={{ minHeight: "570px" }}>
                    <FnbFroalaEditor
                      value={descriptionArea}
                      onChange={(value) => {
                        if (value !== "" && value !== "<div></div>");
                        setDescriptionArea(value);
                      }}
                      charCounterMax={-1}
                      heightMin={400}
                      heightMax={400}
                    />
                  </Form.Item>
                  <Form.Item name="storeBranchId" className="d-none" label={pageData.areaName}>
                    <Input type="hidden" />
                  </Form.Item>
                </Space>
              </Col>
              {checkScreen !== SizeScreen.IS_MOBILE ? renderInputImage() : null}
            </Row>
            <Row className="float-center justify-content-center">
              <FnbCancelButton
                buttonText={pageData.cancel}
                className="mr-1 width-100 button-cancel-addnew"
                key="back"
                showWarning={formDataChanged}
                onOk={onCancelAfterConfirm}
              />
              <FnbButton type="submit" disabled={!formDataChanged} text={pageData.addNew} style={{ minWidth: 95 }} />
            </Row>
          </Space>
        </Form>
      }
    />
  );
}
