import { Modal } from "antd";
import styled from "styled-components";

export const ContentHeadingGuideline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
`;
export const YoutubeLogoStyle = styled.img`
  width: 95px;
  height: 24px;
  cursor: pointer;
`;

export const RelativeContainIframe = styled.div`
  position: relative;
  height: 292px;
`;

export const AbsoluteContainIframe = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  /* display: ${(p) => (p.isInteractWithYoutube ? "none" : "flex")}; */
`;

const BaseModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;
export const ModalFullScreenPlayVideo = styled(BaseModal)`
  width: 100% !important;
  max-width: 900px;
  padding-left: 12px;
  padding-right: 12px;
  .ant-modal-content {
    background: transparent;
    box-shadow: unset;
    .ant-modal-body {
      height: 508px;
    }
  }
`;

export const IFramePlayVideo = styled.iframe`
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 505px;
  margin-bottom: -6px;
`;

export const ModalGuidanceMobile = styled(BaseModal)`
  width: 100% !important;
  padding-left: 4px;
  padding-right: 4px;
  max-width: 575px;
  top: ${(p) => (p.isMobileHorizontalMode ? "-34px" : 0)} !important;
  .ant-modal-content {
    background: transparent;
    box-shadow: unset;
    .ant-modal-body {
      height: 275px;
      width: 100%;
      padding: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 22px;
      ${(p) =>
        p.isMobileHorizontalMode &&
        `
          max-height: 234px;
          position: relative;
          .fnb-button-view-more-wiki {
            position: absolute;
            bottom: -72px;
          }
        `}
    }
  }
`;

export const IFramePlayVideoMobile = styled.iframe`
  width: 100%;
  max-width: 575px;
  height: 100%;
  margin-bottom: -6px;
  ${(p) =>
    p.isMobileHorizontalMode &&
    `
      height: 500px;
    `}
`;
