import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import parse from "html-react-parser";

const Countdown = (props) => {
  const { expired, onExpired } = props;
  const [t] = useTranslation();
  const [date, setDate] = useState();

  useEffect(() => {
    const timer = setInterval(() => setTimer(), 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const setTimer = () => {
    const startTime = moment();
    const endTime = moment(expired);
    const diff = endTime.diff(startTime);

    if (diff > 1) {
      const duration = moment.duration(diff);
      const minutes = parseInt(duration.asMinutes()) % 60;
      const seconds = parseInt(duration.asSeconds()) % 60;
      setDate(`${formatNumber(minutes, true)}${formatNumber(seconds)}`);
    } else {
      setDate("");
      onExpired && onExpired();
    }
  };

  const formatNumber = (value, suffix) => {
    if (value > 0) {
      const formatted = ("0" + value).slice(-2);
      return `${formatted}${suffix ? ":" : ""}`;
    } else {
      return `00${suffix ? ":" : ""}`;
    }
  };

  return <>{parse(t("forgotPassword.tryToResend", { expiredTime: date }))}</>;
};

export default Countdown;
