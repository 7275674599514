/**
 * DO NOT EDIT THIS FILE
 */

const PageType = {
  HOME_PAGE: "726F7453-5765-6265-5061-676500000001",
  PRODUCT_LIST: "726F7453-5765-6265-5061-676500000002",
  PRODUCT_DETAIL: "726F7453-5765-6265-5061-676500000003",
  COMBO_DETAIL: "726F7453-5765-6265-5061-676500000004",
  BOOKING: "726F7453-5765-6265-5061-676500000005",
  CART_PAGE: "726F7453-5765-6265-5061-676500000006",
  CHECKOUT_PAGE: "726F7453-5765-6265-5061-676500000007",
  REGISTER_PAGE: "726F7453-5765-6265-5061-676500000008",
  LOGIN_PAGE: "726F7453-5765-6265-5061-676500000009",
  FORGOT_PASSWORD_PAGE: "726F7453-5765-6265-5061-67650000000A",
  MY_PROFILE_PAGE: "726F7453-5765-6265-5061-67650000000B",
  CONTACT_PAGE: "726F7453-5765-6265-5061-67650000000C",
  ABOUT_US_PAGE: "726F7453-5765-6265-5061-67650000000D",
  BLOG_PAGE: "726F7453-5765-6265-5061-67650000000E",
  BLOG_DETAIL_PAGE: "726F7453-5765-6265-5061-67650000000F",
  DOWNLOAD_APP_PAGE: "726F7453-5765-6265-5061-67650000000G",
  RESERVE_TABLE_PAGE: "726F7453-5765-6265-5061-67650000000H",
};

export default PageType;
