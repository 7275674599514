import { Modal, Tabs } from "antd";
import jwt_decode from "jwt-decode";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import branchDataService from "../../../data-services/branch-data.services";
import loginDataService from "../../../data-services/login-data.service";
import { setCartItems, setDeliveryAddress, setNearestStoreBranches } from "../../../modules/session/session.actions";
import { useAppCtx } from "../../../providers/app.provider";
import { getBranchesByCustomerAddress } from "../../../services/addressServices";
import shoppingCartService from "../../../services/shopping-cart/shopping-cart.service";
import { getInitialSelectedBranchInfo } from "../../../utils/helpers";
import { getStorage, localStorageKeys } from "../../../utils/localStorage.helpers";
import { DeliveryIcon, PickUpIcon } from "../../assets/icons.constants";
import { enumOrderType } from "../../constants/enums";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog.component";
import { CustomerAddresses } from "../customer-address/customer-address.component";
import { StoreBranchAddressSelector } from "./../store-branch-address-selector/store-branch-address-selector.component";
import "./delivery-address-selector.style.scss";
import { ReactComponent as LocationIcon } from "./location-icon.svg";
import { PlacesAutocompleteComponent } from "./places-autocomplete/places-autocomplete.component";

const StyledTab = styled.div`
  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    .ant-tabs-tab-active {
      background: #f1eedf;
      .ant-tabs-tab-btn {
        background: ${(props) => props.color.buttonBackgroundColor};
        .label-tabs .label-text {
          color: ${(props) => props.color.buttonTextColor} !important;
        }
      }
    }
  }
`;

export const DeliveryAddressSelectorComponent = forwardRef((props, ref) => {
  const { colorGroup, config } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const autoCompleteRef = useRef();
  const storeBranchAddressSelectorRef = useRef();
  const isClearCustomerAddressRef = useRef(false);
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const nearestStoreBranches = useSelector((state) => state?.session?.nearestStoreBranches);
  const [isVisibleModalDeliveryTo, setIsVisibleModalDeliveryTo] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [hasCustomerAddresses, setHasCustomerAddresses] = useState(false); // TODO: check login token
  const [openStoreBranchAddressSelector, setOpenStoreBranchAddressSelector] = useState(false);
  const [customerAddress, setCustomerAddress] = useState(null);
  const [storeBranchSelected, setStoreBranchSelected] = useState(null);
  const [itemsWillRemove, setItemsWillRemove] = useState(null);
  const [storeCartAfterChangeBranch, setStoreCartAfterChangeBranch] = useState([]);
  const [groupColorConfig, setGroupColorConfig] = useState();
  const [currentOrderType, setCurrentOrderType] = useState(1);
  const isMaxWidth576 = useMediaQuery({ maxWidth: 576 });
  const { Toast } = useAppCtx();
  const translatedData = {
    deliveryTo: t("addUserLocation.deliveryTo", "Giao đến"),
    pickUp: t("addUserLocation.pickUp", "Tự lấy hàng"),
    pleaseSelectAddress: t("addUserLocation.pleaseSelectAddress", "Vui lòng cho chúng tôi biết địa chỉ của bạn"),
    selectStoreBranch: t("addUserLocation.selectStoreBranch", "Chọn chi nhánh"),
    autoCompletePlaceholder: t("addUserLocation.autoCompletePlaceholder", "Nhập địa chỉ giao hàng"),
    continue: t("button.continue", "Tiếp tục"),
    shoppingCartItemBelongToBranchWarning: t(
      "shoppingCartItemBelongToBranchWarning",
      `Giỏ hàng có những sản phẩm không thuộc chi nhánh <b>{{branchName}}</b>. <p>Những sản phẩm đó sẽ bị xóa khỏi giỏ hàng?</p>`,
    ),
    notification: t("storeWebPage.generalUse.notification"),
    selectAddressSuccessful: t("checkOutPage.selectAddressSuccessful", "Select address successful"),
  };

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    const group = colorGroup?.[0];
    setGroupColorConfig(group);
    if (deliveryAddress) {
      setCustomerAddress(deliveryAddress?.receiverAddress);
      setStoreBranchSelected(deliveryAddress?.branchAddress ?? null);
    } else {
      getDefaultStoreBranch(true);
    }
    const token = getStorage(localStorageKeys.TOKEN);
    const decoded_token = token && jwt_decode(token);
    if (decoded_token) {
      getCustomerAddresses(decoded_token?.ACCOUNT_ID);
      setHasCustomerAddresses(true);
    } else {
      setHasCustomerAddresses(false);
    }
  }, []);

  useEffect(() => {
    if (isClearCustomerAddressRef.current && !isVisibleModalDeliveryTo && customerAddress && deliveryAddress) {
      setCustomerAddress(null);
      const currentDeliveryAddress = {
        ...deliveryAddress,
        branchAddress: deliveryAddress?.branchAddress,
        receiverAddress: null,
      };
      dispatch(setDeliveryAddress(currentDeliveryAddress));
      isClearCustomerAddressRef.current = false;
    }
  }, [isVisibleModalDeliveryTo]);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (isMaxWidth576 && isVisibleModalDeliveryTo) {
      bodyClassList.toggle("locking-body-scroll", true);
    } else {
      bodyClassList.remove("locking-body-scroll");
    }
    return () => {
      bodyClassList.remove("locking-body-scroll");
    };
  }, [isVisibleModalDeliveryTo, isMaxWidth576]);

  useEffect(() => {
    if (customerAddress) {
      const currentDeliveryAddress = {
        ...deliveryAddress,
        branchAddress: deliveryAddress?.branchAddress,
        receiverAddress: customerAddress,
      };
      dispatch(setDeliveryAddress(currentDeliveryAddress));
    }
  }, [customerAddress]);

  useEffect(() => {
    if (deliveryAddress?.orderType && deliveryAddress?.orderType != currentOrderType) {
      setCurrentOrderType(deliveryAddress?.orderType);
    }
  }, [deliveryAddress?.orderType]);

  useEffect(() => {
    if (deliveryAddress) {
      if (deliveryAddress?.branchAddress?.id?.toLowerCase() !== storeBranchSelected?.id?.toLowerCase()) {
        setStoreBranchSelected(deliveryAddress?.branchAddress);
      }
    }
  }, [deliveryAddress?.branchAddress]);

  useEffect(() => {
    if (storeBranchSelected) {
      const currentDeliveryAddress = {
        ...deliveryAddress,
        receiverAddress: deliveryAddress?.receiverAddress,
        branchAddress: storeBranchSelected,
      };
      dispatch(setDeliveryAddress(currentDeliveryAddress));
      verifyStoreCart(storeBranchSelected);
    }
  }, [storeBranchSelected]);

  const getCustomerAddresses = async (accountId) => {
    const storeConfig = JSON.parse(getStorage(localStorageKeys.STORE_CONFIG));
    const res = await loginDataService.getAddressListByAccountIdAsync(accountId, storeConfig?.storeId);
    if (res) {
      const accountAddress = res?.data?.accountAddress;
      setAddressList(accountAddress);
    }
  };

  const getNearestStoreBranches = async (location, isAutoPickBranch) => {
    if (location) {
      const lat = location?.center?.lat;
      const lng = location?.center?.lng;
      const res = await getBranchesByCustomerAddress(lat, lng, false, nearestStoreBranches);
      if (res) {
        const branchesByCustomerAddress = res?.data?.branchesByCustomerAddress;
        dispatch(setNearestStoreBranches(branchesByCustomerAddress));
        if (isAutoPickBranch === true) {
          onAutoPickNearestBranchAddress(branchesByCustomerAddress);
        }
      }
    }
  };

  const getDefaultStoreBranch = async (isAutoPickBranch) => {
    const res = await branchDataService.getBranchesByCustomerAddressAsync(0, 0, true);
    if (res) {
      const branchesByCustomerAddress = res?.data?.branchesByCustomerAddress;
      dispatch(setNearestStoreBranches(branchesByCustomerAddress));
      // Add initital branch when the first time
      if (branchesByCustomerAddress) {
        const branchSelectedDefault = getInitialSelectedBranchInfo(branchesByCustomerAddress);
        setStoreBranchSelected(branchSelectedDefault);
      }
    }
  };

  const onSelectCustomerAddress = (customerAddress, isAutoPickBranch) => {
    if (customerAddress) {
      setIsVisibleModalDeliveryTo(false); // close modal
      autoCompleteRef?.current?.setAddress(customerAddress?.address); // set address search field value
      const location = {
        address: customerAddress?.address,
        center: {
          lat: customerAddress?.lat,
          lng: customerAddress?.lng,
        },
      };
      getNearestStoreBranches(location, isAutoPickBranch);
      setCustomerAddress(customerAddress);
      isClearCustomerAddressRef.current = false;
    }
  };

  const mappingLocationToCustomerAddress = (location) => {
    return {
      id: null,
      name: location?.address,
      address: location?.address,
      customerAddressTypeId: null,
      lat: location?.center?.lat,
      lng: location?.center?.lng,
      addressDetail: location?.address,
      note: null,
      isDefault: location?.isDefault,
    };
  };

  const onSelectLocation = (location) => {
    if (location) {
      setIsVisibleModalDeliveryTo(false);
      getNearestStoreBranches(location, true);

      const locationAddress = mappingLocationToCustomerAddress(location);
      setCustomerAddress(locationAddress);
      isClearCustomerAddressRef.current = false;
    }
  };

  const openDialogSelectReceiverAddress = (orderType) => {
    setCurrentOrderType(orderType ?? enumOrderType.DELIVERY);
    setIsVisibleModalDeliveryTo(true);
    if (deliveryAddress?.receiverAddress == null) {
      getNearestStoreBranchesFromApi(0, 0);
    } else {
      getNearestStoreBranchesFromApi(deliveryAddress?.receiverAddress?.lat, deliveryAddress?.receiverAddress?.lng);
    }
  };

  // fetch branch nearest from api
  const getNearestStoreBranchesFromApi = async (lat, lng) => {
    if (deliveryAddress) {
      if (deliveryAddress?.branchAddress) {
        const res = await getBranchesByCustomerAddress(lat, lng, true, nearestStoreBranches);
        if (res) {
          const branchesByCustomerAddress = res?.data?.branchesByCustomerAddress;
          dispatch(setNearestStoreBranches(branchesByCustomerAddress));
          const branchAddressDeliveryFromAPI = branchesByCustomerAddress?.find(
            (x) => x.branchId === deliveryAddress?.branchAddress?.id,
          );
          const branchSelectedDefault = getInitialSelectedBranchInfo([branchAddressDeliveryFromAPI]);
          if (
            branchSelectedDefault &&
            (branchSelectedDefault?.distance !== deliveryAddress?.branchAddress?.distance ||
              branchSelectedDefault?.lat !== deliveryAddress?.branchAddress?.lat ||
              branchSelectedDefault?.lng !== deliveryAddress?.branchAddress?.lng)
          ) {
            setStoreBranchSelected(branchSelectedDefault);
          }
        }
      }
    }
  };

  const onClearCustomerAddress = () => {
    if (customerAddress && deliveryAddress) {
      setCustomerAddress(null);
      const currentDeliveryAddress = {
        ...deliveryAddress,
        branchAddress: deliveryAddress?.branchAddress,
        receiverAddress: null,
      };
      dispatch(setDeliveryAddress(currentDeliveryAddress));
    }
    isClearCustomerAddressRef.current = true;
    getDefaultStoreBranch();
  };

  const handleShowToastSuccess = useCallback(() => {
    Toast.success({
      message: translatedData.selectAddressSuccessful,
      placement: "top",
    });
  }, []);

  const onSelectStoreBranchAddress = (storeBranch, isSelectNewBranch) => {
    setStoreBranchSelected(storeBranch);
    handleShowToastSuccess();
    if (isSelectNewBranch) {
      setTimeout(() => {
        setOpenStoreBranchAddressSelector(false);
      }, 500);
    }
  };

  const onAutoPickNearestBranchAddress = (branchesByCustomerAddress) => {
    storeBranchAddressSelectorRef?.current?.setAutoPickStoreBranchAddress(branchesByCustomerAddress);
  };

  const verifyStoreCart = (storeBranchSelected) => {
    /// call this function only one time after store branch has been changed
    if (window.verifyStoreCart) {
      clearTimeout(window.verifyStoreCart);
    }
    window.verifyStoreCart = setTimeout(async () => {
      const branchId = storeBranchSelected.id;
      const jsonConfig = getStorage(localStorageKeys.STORE_CONFIG);
      const storeConfig = JSON.parse(jsonConfig);
      const storeId = storeConfig.storeId;

      const response = await shoppingCartService.verifyProductInShoppingCartAsync(
        storeId,
        branchId,
        onDisplayItemWillRemoveFromCart,
      );
      setStoreCartAfterChangeBranch(response?.newStoreCart);
    }, 0);
  };

  const updateCartAfterChangeBranch = () => {
    shoppingCartService.setStoreCartLocalStorage(storeCartAfterChangeBranch);
    setItemsWillRemove(null);
  };

  const onDisplayItemWillRemoveFromCart = (itemsWillRemove) => {
    setItemsWillRemove(itemsWillRemove);

    //itemsWillRemove
  };

  const ShoppingCartWarningContent = () => {
    const text = t(translatedData.shoppingCartItemBelongToBranchWarning, {
      branchName: storeBranchSelected?.title,
    });

    return (
      <>
        <span className="text" dangerouslySetInnerHTML={{ __html: text }}></span>
      </>
    );
  };

  // sync shopping cart local storage and redux
  const onSyncShoppingCart = () => {
    const storeCart = getStorage(localStorageKeys.STORE_CART);
    let objectStoreCart = JSON.parse(storeCart);
    dispatch(setCartItems(objectStoreCart));
  };

  const ReceiverTitleText = (key) => {
    return (
      <div>
        {key == 1 ? (
          <div className="label-tabs">
            <DeliveryIcon />
            <span className="label-text">{translatedData.deliveryTo}</span>
          </div>
        ) : (
          <div className="label-tabs">
            <PickUpIcon />
            <span className="label-text">{translatedData.pickUp}</span>
          </div>
        )}
      </div>
    );
  };
  const DeliveryAddressBody = () => {
    return (
      <>
        <div className="body-container">
          <PlacesAutocompleteComponent
            ref={autoCompleteRef}
            groupColorConfig={groupColorConfig}
            placeholder={translatedData.autoCompletePlaceholder}
            onSelectLocation={(location) => onSelectLocation(location)}
            onEmptyLocation={onClearCustomerAddress}
            initAddress={customerAddress?.address}
            maxLength={100}
            hasCustomerAddresses={hasCustomerAddresses && addressList?.length > 0}
          />
        </div>
        {/* Customer address list */}
        {(customerAddress || hasCustomerAddresses) && (
          <CustomerAddresses
            addressList={addressList}
            setCustomerAddress={(address) => {
              onSelectCustomerAddress(address, true);
            }}
            colorGroup={colorGroup}
            config={config}
            groupColorConfig={groupColorConfig}
            storeBranchSelected={storeBranchSelected}
            customerAddress={customerAddress}
            hasCustomerAddresses={hasCustomerAddresses}
            storeBranchAddressSelectorRef={storeBranchAddressSelectorRef}
            openStoreBranchAddressSelector={openStoreBranchAddressSelector}
            onSelectPickUp={onSelectPickUp}
            setOpenStoreBranchAddressSelector={setOpenStoreBranchAddressSelector}
            getDefaultStoreBranch={getDefaultStoreBranch}
          />
        )}
      </>
    );
  };

  const onSelectPickUp = (branch, isSelectNewBranch, isCustomerAddressScreen) => {
    onSelectStoreBranchAddress(branch, isSelectNewBranch);
    if (!isCustomerAddressScreen) {
      setIsVisibleModalDeliveryTo(false);
    }
  };

  const PickUpBody = () => {
    return (
      <StoreBranchAddressSelector
        colorGroup={colorGroup}
        config={config}
        ref={storeBranchAddressSelectorRef}
        open={openStoreBranchAddressSelector}
        onSelected={(branch, isSelectNewBranch) => {
          onSelectPickUp(branch, isSelectNewBranch, false);
        }}
        onClose={() => setOpenStoreBranchAddressSelector(false)}
        initStoreBranchData={storeBranchSelected}
        getDefaultStoreBranch={() => getDefaultStoreBranch(true)}
        customerAddress={customerAddress}
      />
    );
  };
  const onChange = (key) => {
    const currentDeliveryAddress = {
      ...deliveryAddress,
      orderType: key,
    };
    dispatch(setDeliveryAddress(currentDeliveryAddress));
    setCurrentOrderType(key);
  };
  const itemsTab = [
    {
      key: enumOrderType.DELIVERY,
      label: ReceiverTitleText(1),
      children: DeliveryAddressBody(),
    },
    {
      key: enumOrderType.PICK_UP,
      label: ReceiverTitleText(4),
      children: PickUpBody(),
    },
  ];

  function getTitleTextDeliveryOrPickupType(type) {
    switch (type) {
      case enumOrderType.DELIVERY:
        return (
          <>
            {customerAddress?.address ? (
              <span>{customerAddress?.address}</span>
            ) : (
              <>
                <LocationIcon />
                <span>{translatedData.pleaseSelectAddress}</span>
              </>
            )}
          </>
        );
      case enumOrderType.PICK_UP:
        return (
          <span>{storeBranchSelected?.title ? storeBranchSelected?.title : translatedData.selectStoreBranch}</span>
        );

      default:
        return (
          <>
            <LocationIcon />
            <span>{translatedData.pleaseSelectAddress}</span>
          </>
        );
    }
  }

  function isShowDeliveryOrPickupIcon(type) {
    switch (type) {
      case enumOrderType.DELIVERY:
        if (customerAddress?.address) return true;
        return false;
      case enumOrderType.PICK_UP:
        if (storeBranchSelected?.title) return true;
        return false;
      default:
        return false;
    }
  }

  return (
    <div>
      <ConfirmationDialog
        className="shopping-cart-warning-dialog"
        title={translatedData.notification}
        open={itemsWillRemove && itemsWillRemove?.length > 0}
        content={
          <div className="shopping-cart-warning-content">
            <ShoppingCartWarningContent />
          </div>
        }
        footer={
          <p className="btn-shopping-cart-warning-continue" onClick={() => updateCartAfterChangeBranch()}>
            {translatedData.continue}
          </p>
        }
        afterClose={() => onSyncShoppingCart()}
      />
      <div
        id="deliveryAddressSelector"
        style={{ marginTop: isMaxWidth576 ? 0 : 0 }}
        className={`delivery-address-selector ${
          window.showDeliveryAddressSelector ?? "delivery-address-selector-default-hide"
        }`}
      >
        <div className="delivery-address-header-box">
          <div className="middle-box">
            {isShowDeliveryOrPickupIcon(currentOrderType) && (
              <div
                className="delivery-address-button store-branch-address-select-button cursor-pointer delivery-address-button-bg"
                onClick={() => {
                  openDialogSelectReceiverAddress(currentOrderType);
                }}
                style={{
                  background: colorGroup?.[0]?.buttonBackgroundColor,
                  color: colorGroup?.[0]?.buttonTextColor,
                }}
              >
                {currentOrderType === enumOrderType.DELIVERY ? (
                  <>
                    <DeliveryIcon />
                    <span>{translatedData.deliveryTo}</span>
                  </>
                ) : (
                  <>
                    <PickUpIcon />
                    <span>{translatedData.pickUp}</span>
                  </>
                )}
              </div>
            )}

            <div
              className="delivery-address-button receiver-address-select-button cursor-pointer"
              onClick={() => {
                openDialogSelectReceiverAddress(currentOrderType);
              }}
            >
              {getTitleTextDeliveryOrPickupType(currentOrderType)}
            </div>
          </div>
        </div>
      </div>

      <Modal
        style={{ top: isMaxWidth576 ? 120 : 200 }}
        className={`modal-delivery-address-selector ${hasCustomerAddresses === true && "login"}`}
        open={isVisibleModalDeliveryTo}
        onCancel={() => setIsVisibleModalDeliveryTo(false)}
        footer={(null, null)}
        forceRender={true}
        destroyOnClose={true}
        closeIcon
        title={<></>}
      >
        <StyledTab color={colorGroup[0]}>
          <Tabs
            activeKey={currentOrderType}
            items={itemsTab}
            onChange={onChange}
            className="tabs-control"
            animated={{ inkBar: false, tabPane: true }}
          />
        </StyledTab>
      </Modal>
    </div>
  );
});
