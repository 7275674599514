import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

const FilterPopover = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState("");
  const defaultValue = "";

  useEffect(() => {
    let countStatus = selectedStatus !== "" ? 1 : 0;

    const filterOptions = {
      status: selectedStatus,
      count: countStatus,
    };
    props.fetchData(filterOptions);
  }, [selectedStatus]);

  //#region PageData
  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      status: {
        all: t("material.filter.status.all"),
        title: t("material.filter.status.title"),
        active: t("promotion.status.active"),
        scheduled: t("promotion.status.scheduled"),
        finished: t("promotion.status.finished"),
        specialOptionKey: null,
      },
    },
  };
  //#endregion

  useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    resetFilter() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    setSelectedStatus(defaultValue);
  };

  const onFilterMaterial = (id) => {
    setSelectedStatus(id);
  };

  return (
    <Form layout="vertical">
      <Form.Item label={pageData.filter.status.title}>
        <Radio.Group
          value={selectedStatus}
          defaultValue={defaultValue}
          buttonStyle="solid"
          onChange={(e) => onFilterMaterial(e.target.value)}
        >
          <FnbRadioButton value={defaultValue}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={1}>{pageData.filter.status.active}</FnbRadioButton>
          <FnbRadioButton value={0}>{pageData.filter.status.scheduled}</FnbRadioButton>
          <FnbRadioButton value={2}>{pageData.filter.status.finished}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default FilterPopover;
