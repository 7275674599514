import { CheckOutlined } from "@ant-design/icons";
import { Card, Col, Form, Radio, Row } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { PurchaseOrderStatus } from "constants/purchase-order-status.constants";
import { actionType } from "constants/staff-activities.constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { branches, supplierFilter, staffs } = props;
  const [form] = Form.useForm();
  const DEFAULT_VALUE = "";

  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      status: {
        title: t("material.filter.status.title"),
        all: t("material.filter.status.all"),
        new: t("purchaseOrder.status.new"),
        approved: t("purchaseOrder.status.approved"),
        ordering: t("purchaseOrder.status.ordering"),
        canceled: t("purchaseOrder.status.canceled"),
        completed: t("purchaseOrder.status.completed"),
      },
      startDate: t("promotion.form.startDate"),
      endDate: t("promotion.form.endDate"),
    },
    action: {
      created: t("purchaseOrderHistory.actionType.created"),
      updateStatus: t("purchaseOrderHistory.actionType.updateStatus"),
      cancelled: t("purchaseOrderHistory.actionType.cancelled"),
      edited: t("purchaseOrderHistory.actionType.edited"),
    },
  };

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter(formData);
    },
  }));
  const [formData, setFormData] = useState({});

  useEffect(() => {
    props.fetchDataPurchaseOrder(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const clearFilter = (formData) => {
    setFormData({});
    props.fetchDataPurchaseOrder({});
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label={t("purchaseOrder.branchWarehouse")}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => {
            setFormData({
              ...formData,
              branchId: value,
            });
          }}
          value={formData.branchId || ""}
          defaultValue={DEFAULT_VALUE}
          option={branches}
        />
      </Form.Item>
      <Form.Item label={t("supplier.title")}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => {
            setFormData({
              ...formData,
              supplierId: value,
            });
          }}
          value={formData.supplierId || ""}
          defaultValue={DEFAULT_VALUE}
          option={supplierFilter}
        />
      </Form.Item>
      <Form.Item label={t("purchaseOrderHistory.updateBy")}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => {
            setFormData({
              ...formData,
              updateBy: value,
            });
          }}
          value={formData.updateBy || ""}
          defaultValue={DEFAULT_VALUE}
          option={staffs}
        />
      </Form.Item>
      <Form.Item label={t("table.status")}>
        <Radio.Group
          value={formData.statusId == undefined ? "" : formData.statusId}
          defaultValue={DEFAULT_VALUE}
          buttonStyle="solid"
          onChange={(e) => {
            setFormData({
              ...formData,
              statusId: e.target.value,
            });
          }}
        >
          <FnbRadioButton value={DEFAULT_VALUE}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.New}>{pageData.filter.status.new}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Approved}>{pageData.filter.status.approved}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Ordering}>{pageData.filter.status.ordering}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Completed}>{pageData.filter.status.completed}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Canceled}>{pageData.filter.status.canceled}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={t("table.action")}>
        <Radio.Group
          value={formData.actionId === undefined ? "" : formData.actionId}
          defaultValue={DEFAULT_VALUE}
          buttonStyle="solid"
          onChange={(e) => {
            setFormData({
              ...formData,
              actionId: e.target.value,
            });
          }}
        >
          <FnbRadioButton value={DEFAULT_VALUE}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={actionType.Created}>{pageData.action.created}</FnbRadioButton>
          <FnbRadioButton value={actionType.UpdateStatus}>{pageData.action.updateStatus}</FnbRadioButton>
          <FnbRadioButton value={actionType.Cancelled}>{pageData.action.cancelled}</FnbRadioButton>
          <FnbRadioButton value={actionType.Edited}>{pageData.action.edited}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
