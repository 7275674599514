import { Card, Col, message, Row, Typography } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { BarcodeType } from "constants/barcode-type.constants";
import { StampType, StampName } from "constants/stamp-type.constants";
import React, { useEffect, useState } from "react";
import { convertTextToBarcodeBase64, convertTextToQRCodeBase64Async, replaceParameter } from "utils/helpers";
import { FnbSelectSingleOptGroup } from "components/fnb-select-single-opt-group/fnb-select-single-opt-group";
import { Printer } from "components/Printer";
import "./index.scss";
import { PrinterSettingType } from "constants/store-setting.constants";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { PrinterOutlineIcon, CustomDownIcon } from "constants/icons.constants";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbButton } from "components/fnb-button/fnb-button";
import { getUserInfo } from "services/auth.service";
const { Text } = Typography;

export default function BarcodeConfig(props) {
  const { t, barcodeDataService, onChangeForm, activeTab } = props;
  const loggedUserInfo = getUserInfo();

  const pageData = {
    barcodeTemplate: t("barcode.barcodeTemplate"),
    barcode: t("barcode.barcode"),
    type: t("barcode.type"),
    model: t("barcode.model"),
    size: t("barcode.size"),
    showItemName: t("barcode.showItemName"),
    showPrice: t("barcode.showPrice"),
    showCode: t("barcode.showCode"),
    isUpdatedSuccessfully: t("messages.isUpdatedSuccessfully"),
    save: t("button.saveChanges"),
    preview: t("barcode.preview"),
    configuration: t("barcode.configuration"),
    oneStamp: t("barcode.oneStamp"),
    twoStamp: t("barcode.twoStamp"),
    btnPrintPreview: t("button.printPreview"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    btnCancel: t("button.cancel"),
    selectOptionDescription: t("barcode.selectOptionDescription"),
  };

  const printExampleData = {
    nameValue: "Mayonnaise",
    priceValue: "55,000",
    codeValue: "NVL10021001",
    currencyCodeValue: loggedUserInfo?.currencyCode,
  };

  const [barcodeTypeList, setBarcodeTypeList] = useState([]);
  const [barcodeType, setBarcodeType] = useState(BarcodeType.barcode);
  const [stampTypeList, setStampTypeList] = useState([]);
  const [stampType, setStampType] = useState(StampType.mm50x30);
  const [showName, setShowName] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [barcodeConfigs, setBarcodeConfigs] = useState([]);
  const [isDataInitialization, setIsDataInitialization] = useState(true);
  const [previewData, setPreviewData] = useState("");
  const printerRef = React.useRef(null);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (activeTab === PrinterSettingType.BARCODE) {
      getInitialData();
    } else {
      clearData();
      setIsChangeForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const clearData = () => {
    setShowName(false);
    setShowPrice(false);
    setShowCode(false);
  };

  useEffect(() => {
    changePreviewData();
  }, [isDataInitialization]);

  useEffect(() => {
    if (!isDataInitialization) {
      const indexEdit = barcodeConfigs.findIndex(
        (item) => item?.barcodeType === barcodeType && item?.stampType === stampType,
      );
      if (indexEdit !== -1) {
        let barcodeConfigsNew = [...barcodeConfigs];
        barcodeConfigsNew[indexEdit].isShowName = showName;
        barcodeConfigsNew[indexEdit].isShowPrice = showPrice;
        barcodeConfigsNew[indexEdit].isShowCode = showCode;
        setBarcodeConfigs(barcodeConfigsNew);
      }
      changePreviewData();
    }
  }, [showName, showPrice, showCode]);

  useEffect(() => {
    if (!isDataInitialization) {
      const indexExist = barcodeConfigs.findIndex(
        (item) => item?.barcodeType === barcodeType && item?.stampType === stampType,
      );
      if (indexExist === -1) {
        let barcodeConfigsNew = [...barcodeConfigs];
        barcodeConfigsNew.push({
          barcodeType: barcodeType,
          stampType: stampType,
          isShowName: true,
          isShowPrice: true,
          isShowCode: true,
        });
        setBarcodeConfigs(barcodeConfigsNew);

        setShowName(true);
        setShowPrice(true);
        setShowCode(true);
        handleSwapItem(indexExist, barcodeConfigsNew);
      } else {
        setShowName(barcodeConfigs[indexExist]?.isShowName);
        setShowPrice(barcodeConfigs[indexExist]?.isShowPrice);
        setShowCode(barcodeConfigs[indexExist]?.isShowCode);
        handleSwapItem(indexExist, barcodeConfigs);
      }

      changePreviewData();
    }
  }, [barcodeType, stampType]);

  const changePreviewData = async () => {
    let base64Image = "";
    if (barcodeType === BarcodeType.qrCode) {
      base64Image = await convertTextToQRCodeBase64Async(printExampleData.codeValue, { margin: 0 });
    } else {
      base64Image = convertTextToBarcodeBase64(printExampleData.codeValue, { displayValue: false });
    }
    const parameter = {
      productNameDisplay: getDisplayValue(showName),
      codeDisplay: getDisplayValue(showCode),
      priceDisplay: getDisplayValue(showPrice),
      productName: printExampleData.nameValue,
      productPriceName: "",
      code: printExampleData.codeValue,
      price: printExampleData.priceValue,
      currencyCode: printExampleData.currencyCodeValue,
      base64Image: base64Image,
    };
    let template = stampTypeList?.find((item) => item?.id === stampType)?.template ?? "";

    template = replaceParameter(template, parameter);
    if (stampType === StampType.mm35x22) {
      template += " " + template;
    }
    setPreviewData(template);
  };

  const getDisplayValue = (isShow) => {
    if (isShow) {
      return "block";
    } else {
      return "none!important";
    }
  };

  const getInitialData = () => {
    barcodeDataService.getBarcodeConfigByStoreIdAsync().then((res) => {
      setStampTypeList(res.stampTypeList);
      setBarcodeTypeList(res.barcodeTypeList);
      if (res?.barcodeConfigs?.length > 0) {
        setBarcodeConfigs(res.barcodeConfigs);
        const config = { ...res.barcodeConfigs?.[0] };
        setBarcodeType(config.barcodeType);
        setStampType(config.stampType);
        setShowName(config.isShowName);
        setShowPrice(config.isShowPrice);
        setShowCode(config.isShowCode);
      }
      setIsDataInitialization(false);
    });
  };

  const onBarcodeTypeChange = (value) => {
    setIsChangeForm(true);
    onChangeForm(true);
    setBarcodeType(value);
  };

  const onStampTypeChange = (value) => {
    setIsChangeForm(true);
    onChangeForm(true);
    setStampType(value);
  };

  const handleSetShowName = (event) => {
    setIsChangeForm(true);
    onChangeForm(true);
    setShowName(event.target.checked);
  };

  const handleSetShowPrice = (event) => {
    setIsChangeForm(true);
    onChangeForm(true);
    setShowPrice(event.target.checked);
  };

  const handleSetShowCode = (event) => {
    setIsChangeForm(true);
    onChangeForm(true);
    setShowCode(event.target.checked);
  };

  const onSave = () => {
    const request = {
      configs: barcodeConfigs,
    };
    barcodeDataService.updateBarcodeConfigByStoreIdAsync(request).then((res) => {
      if (res) {
        onChangeForm(false);
        message.success(`${pageData.barcode} ${pageData.isUpdatedSuccessfully}`);
        setIsChangeForm(false);
      }
    });
  };

  function StringToHTML({ htmlString }) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }

  const printStamp = () => {
    if (printerRef && printerRef.current) {
      printerRef.current.printTemplate();
    }
  };

  const handleSwapItem = (index, arr) => {
    if (arr.length > 1) {
      const temp = arr[0];
      if (index === -1) {
        arr[0] = arr[arr.length - 1];
        arr[arr.length - 1] = temp;
      } else {
        arr[0] = arr[index];
        arr[index] = temp;
      }
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onOK = () => {
    setIsChangeForm(false);
  };

  const cancelChange = () => {
    onChangeForm(false);
    setIsChangeForm(false);
    getInitialData();
  };

  return (
    <>
      <Card className="fnb-card-full barcode-setting item-card-printer-item">
        <Row className="barcode-config-card-header">
          <Col lg={10} md={12} xs={24}></Col>
          <Col lg={14} md={12} xs={24} style={{ width: "100%" }}>
            <div className="float-right group-button-item-printer" size={24}>
              <FnbButton
                iconHeader={<PrinterOutlineIcon />}
                onClick={printStamp}
                text={pageData.btnPrintPreview}
                variant="tertiary"
              />
              {isChangeForm && (
                <div className="action-button">
                  <FnbButton minWidth={100} text={pageData.btnCancel} variant="tertiary" onClick={cancelChange}/>
                  <FnbButton type="submit" minWidth={100} text={pageData.save} onClick={onSave}/>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="barcode-form">
          <Col xl={17} lg={12} md={12} sm={24} span={24} className="border-div">
            <Row>
              <Col xl={24} lg={24} md={24} xs={24} className="form-item-left-barcode">
                <h4 className="fnb-form-label">
                  {pageData.type} <span className="require-symbol">*</span>
                </h4>
                <FnbSelectSingle
                  suffixIcon={<CustomDownIcon></CustomDownIcon>}
                  className="item-input"
                  option={barcodeTypeList?.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }))}
                  onChange={onBarcodeTypeChange}
                  showSearch
                  value={barcodeType}
                />
              </Col>
              <Col xl={24} lg={24} md={24} xs={24} className="form-item-left-barcode">
                <h4 className="fnb-form-label">
                  {pageData.size} <span className="require-symbol">*</span>
                </h4>
                <FnbSelectSingleOptGroup
                  suffixIcon={<CustomDownIcon></CustomDownIcon>}
                  className="item-input"
                  optionGroup={stampTypeList?.map((item) => ({
                    id: item.id,
                    name:
                      item.name === StampName.mm35x22
                        ? pageData.twoStamp + " | " + item.name
                        : pageData.oneStamp + " | " + item.name,
                    group: item.name === StampName.mm35x22 ? pageData.twoStamp + ":" : pageData.oneStamp + ":",
                  }))}
                  onChange={onStampTypeChange}
                  showSearch
                  value={stampType}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography className="select-option-description">{pageData.selectOptionDescription}</Typography>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="pt-2">
                <FnbCheckBox noStyle onChange={handleSetShowName} checked={showName}>
                  <Text>{pageData.showItemName}</Text>
                </FnbCheckBox>
              </Col>
              <Col span={24} className="pt-2">
                <FnbCheckBox noStyle onChange={handleSetShowPrice} checked={showPrice}>
                  <Text>{pageData.showPrice}</Text>
                </FnbCheckBox>
              </Col>
              <Col span={24} className="pt-2">
                <FnbCheckBox noStyle onChange={handleSetShowCode} checked={showCode}>
                  <Text>{pageData.showCode}</Text>
                </FnbCheckBox>
              </Col>
            </Row>
          </Col>
          <Col xl={7} lg={12} md={12} sm={24} className="div-vertically">
            <p className="preview-text">{pageData.preview}</p>
            <div className="preview-barcode">
              <Printer ref={printerRef} htmlContent={previewData} paddingBottom={0} />
            </div>
          </Col>
        </Row>
        <DeleteConfirmComponent
          title={pageData.leaveDialog.confirmation}
          content={pageData.leaveDialog.content}
          visible={showConfirm}
          skipPermission={true}
          cancelText={pageData.discardBtn}
          okText={pageData.confirmLeaveBtn}
          onCancel={onDiscard}
          onOk={onOK}
          isChangeForm={isChangeForm}
        />
      </Card>
    </>
  );
}
