import { Form, message, Row } from "antd";
import { DELAYED_TIME } from "constants/default.constants";
import branchDataService from "data-services/branch/branch-data.service";
import materialCategoryDataService from "data-services/material-category/material-category-data.service";
import materialDataService from "data-services/material/material-data.service";
import unitDataService from "data-services/unit/unit-data.service";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { preventMultipleClick } from "utils/helpers";
import { GeneralInformationMaterial } from "./general-Information-material.page";
import { PriceAndConversionMaterial } from "./ingredient-price-unit-conversion.page";
import { ImageAndOtherSettingMaterial } from "./image-other-setting.page";
import { IngredientQuantityOfBranchMaterial } from "./ingredient-quantity-of-branch.page";
import i18n from "utils/i18n";
import { useModifiedDataLocalStorage } from "hooks/useModifiedDataLocalStorage";

export const CreateMaterialPage = forwardRef(({ fromName, history, onChangeForm }, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [units, setUnits] = useState([]);
  const [branches, setBranches] = useState([]);
  const [listMaterialCategory, setListMaterialCategory] = useState([]);
  const nameFromSearchMaterial = new URLSearchParams(window.location.search).get("name");
  const priceAndConversionMaterialRef = React.useRef(null);
  const [baseUnit, setBaseUnit] = useState(null);
  const [isUpdateIngredientQuantity, setIsUpdateIngredientQuantity] = useState(false);
  const { onModifiedIngredient } = useModifiedDataLocalStorage();

  const pageData = {
    addSuccess: t("messages.isCreatedSuccessfully"),
    material: t("material.material"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    btnUpload: t("button.upload"),
    addMaterial: t("material.addMaterial"),
    generalInformation: t("material.generalInformation"),
    name: t("material.name"),
    nameExist: t("material.nameExist"),
    pleaseEnterMaterialName: t("material.pleaseEnterMaterialName"),
    description: t("form.description"),
    maximum2000Characters: t("form.maximum2000Characters"),
    category: t("form.category"),
    selectMaterialCategory: t("form.selectMaterialCategory"),
    leaveForm: t("messages.leaveForm"),
    confirmation: t("messages.confirmation"),
    confirmLeave: t("button.confirmLeave"),
    discard: t("button.discard"),
    addedSuccessfully: t("material.addedSuccessfully"),
    unitNameValidateMessage: t("productManagement.unit.unitNameValidateMessage"),
    pricing: t("material.pricing"),
    baseUnit: t("material.baseUnit"),
    unitSelectPlaceholder: t("productManagement.unit.unitSelectPlaceholder"),
    unitNamePlaceholder: t("productManagement.unit.unitNamePlaceholder"),
    pleaseSelectUnit: t("productManagement.unit.pleaseSelectUnit"),
    pleaseSelectBaseUnit: t("productManagement.unit.pleaseSelectBaseUnit"),
    btnAddImportUnit: t("productManagement.unitConversion.btnAddImportUnit"),
    currentCostPerUnit: t("material.currentCostPerUnit"),
    tooltipCostPerUnit: t("material.tooltipCostPerUnit"),
    btnAddNewUnit: t("productManagement.unit.btnAddNewUnit"),
    inventory: {
      title: t("material.inventory.title"),
      sku: t("material.inventory.sku"),
      skuPlaceholder: t("material.inventory.skuPlaceholder"),
      minQuantity: t("material.inventory.minQuantity"),
      minQuantityPlaceholder: t("material.inventory.minQuantityPlaceholder"),
      minQuantityTooltip: t("material.inventory.minQuantityTooltip"),
      branch: t("material.inventory.branch"),
      branchSelectPlaceholder: t("material.inventory.branchSelectPlaceholder"),
      branchValidateMessage: t("material.inventory.branchValidateMessage"),
      quantityPlaceholder: t("material.inventory.quantityPlaceholder"),
      quantityValidateMessage: t("material.inventory.quantityValidateMessage"),
      productHasExpiryDate: t("material.ingredientHasExpiryDate"),
      quantityValidateMessage1min: t("material.inventory.quantityValidateMessage1min"),
      quantityValidateMessageMinimumOne: t("material.inventory.quantityValidateMessageMinimumOne"),
    },
    discardBtn: t("leaveDialog.ignore"),
    confirmLeaveBtn: t("leaveDialog.confirmLeave"),
    allBranch: t("productCategory.branch.all"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    goBack: t("material.goBack"),
    addNew: t("material.addNew"),
    upload: {
      addFromUrl: t("material.addFromUrl"),
      uploadImage: t("material.addFile"),
    },
    validateMinQtyMessage: t("productManagement.pricing.priceRange"),
    bestDisplayImage: t("messages.imageBestDisplay"),
    media: {
      title: t("media.title"),
    },
  };

  useEffect(() => {
    fetchUnits();
    setFormValues();
    fetchBranches();
    fetchMaterialCategory();
    if (nameFromSearchMaterial) {
      form.setFieldsValue({
        name: nameFromSearchMaterial,
      });
    }
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const fetchBranches = () => {
    branchDataService.getAllBranchsAsync(true).then((res) => {
      if (res && res?.branchs?.length > 0) {
        setBranches(res.branchs);
      }
    });
  };

  const fetchUnits = () => {
    unitDataService.getUnitsAsync().then((res) => {
      if (res.units) {
        setUnits([...res.units]);
      }
    });
  };

  const fetchMaterialCategory = (categoryId) => {
    materialCategoryDataService.getAllMaterialCategoriesAsync().then((res) => {
      if (res?.materialCategories) {
        setListMaterialCategory(res?.materialCategories);
        form.setFieldValue("materialCategoryId", categoryId);
      }
    });
  };

  useImperativeHandle(ref, () => ({
    onAddNewMaterial(e) {
      onAddNewMaterial(e);
    },
  }));

  /// Init form value
  const setFormValues = (material) => {
    form.setFieldsValue({
      name: material?.name,
      description: material?.description,
      category: material?.category,
      unitId: material?.unitId,
      hasExpiryDate: material?.hasExpiryDate ?? false,
    });
  };

  const onGoBack = () => {
    history.push("/inventory/material");
  };

  const onAddNewMaterial = (e) => {
    const event = () => {
      form
        .validateFields()
        .then(async (values) => {
          const quantities = form.getFieldValue("quantity");
          const req = {
            ...values,
            unitConversions: form.getFieldValue("listDataUnitConversion"),
            logo: form.getFieldValue("image")?.url,
            branches: quantities && isUpdateIngredientQuantity ? Object.keys(quantities)
              .filter((key) => quantities[key] > 0)
              .map((key) => ({
                quantity: quantities[key],
                position: branches?.findIndex((branch) => branch?.id === key),
                branchId: key,
              })) : [],
            listDataUnitConversion: null,
            quantity: null,
          };

          materialDataService.createMaterialManagementAsync(req).then((res) => {
            if (res) {
              onCompleted();
              message.success(`${pageData.material} ${pageData.addSuccess}`);
              onModifiedIngredient();
            }
          });
        })
        .catch((errors) => {
          if (!branches?.length > 0) {
            form.setFields([
              {
                name: ["tmpBranchIds"],
                errors: [pageData.inventory.branchValidateMessage],
              },
            ]);
          }
        });
    };
    preventMultipleClick(e, event);
  };

  const onCompleted = () => {
    onChangeForm(false);
    setTimeout(() => {
      onGoBack();
    }, DELAYED_TIME);
  };

  const onFieldsChange = (changedFields, allFields) => {
    onChangeForm(true);

    if (changedFields.some((field) => field.name[0] === "listDataUnitConversion")) {
      priceAndConversionMaterialRef.current.onUpdateListDataUnitConversion(
        form.getFieldValue("listDataUnitConversion"),
      );
      form.validateFields(changedFields);
    }
  };

  return (
    <>
      <Form
        form={form}
        onFieldsChange={onFieldsChange}
        autoComplete="off"
        className="custom-form material-form"
        layout="vertical"
        name={fromName}
      >
        <Row>
          <div className="left-component">
            <GeneralInformationMaterial
              t={t}
              form={form}
              listMaterialCategory={listMaterialCategory}
              onFetch={fetchMaterialCategory}
            />
            <PriceAndConversionMaterial
              t={t}
              form={form}
              units={units}
              fetchUnits={fetchUnits}
              ref={priceAndConversionMaterialRef}
              updateBaseUnit={setBaseUnit}
            />
            <IngredientQuantityOfBranchMaterial
              t={t}
              form={form}
              isCreatePage={true}
              branches={branches}
              baseUnitName={baseUnit?.name}
              unitConversions={[]}
              setIsUpdateIngredientQuantity={setIsUpdateIngredientQuantity}
            />
          </div>
          <div className="right-component">
            <ImageAndOtherSettingMaterial t={t} form={form} onFieldsChange={onFieldsChange} />
          </div>
        </Row>
      </Form>
    </>
  );
});
