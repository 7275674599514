import http from "utils/http-common";

const controller = "orderPackagePaymentTransaction";

const executePayPalPayment = async (request) => {
  const result = await http.get(
    `/${controller}/paypal/execute?paymentId=${request.paymentId}&token=${request.token}&payerId=${request.payerId}`,
  );
  return result;
};

const getPayPalPayment = async (paymentTransactionId) => {
  const result = await http.get(`/${controller}/paypal/payment-status?transactionId=${paymentTransactionId}`);
  return result;
};

const orderPackagePaymentTransactionService = {
  executePayPalPayment,
  getPayPalPayment,
};

export default orderPackagePaymentTransactionService;
