import { Image, Space, Typography, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import RejectDialogComponent from "components/fnb-reject-dialog/reject-dialog.component";
import { FnbSteps, Step } from "components/fnb-step/fnb-step";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { Thumbnail } from "components/thumbnail/thumbnail";
import {
  DeleteOutlined,
  EditOutlined,
  ImageMaterialDefault,
  PrinterOutlineIcon,
  RejectStep,
} from "constants/icons.constants";
import { images } from "constants/images.constants";
import {
  IngredientRemoveRequestHistoryLocalization,
  IngredientRemoveRequestHistoryType,
} from "constants/ingredient-remove-request.constants";
import { IngredientRemoveStatus } from "constants/ingredient-remove.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat, TrackingSteps } from "constants/string.constants";
import ingredientRemoveRequestDataService from "data-services/material-control/ingredient-remove-request-data.service";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { formatDate, formatterDecimalNumber, hasPermission } from "utils/helpers";
import { KEY_TAB_PANE } from "../table-inventory-control.component";
import "./index.scss";
import { Container, WrapperLeftColumn, WrapperRightColumn } from "./styled";

const { Text } = Typography;
const urlBack = `/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_INVENTORY_CONTROL}`;

export default function DetailIngredientRemoveRequest(props) {
  const { match, history } = props;
  const { t } = useTranslation();

  const [currencyStep, setCurrencyStep] = useState(0);
  const [isShowModalReject, setIsShowModalReject] = useState(false);
  const [ingredientRemoveRequest, setIngredientRemoveRequest] = useState(null);
  const [isShowModalApprove, setIsShowModalApprove] = useState(false);
  const [disabledBtnApprove, setDisabledBtnApprove] = useState(false);

  const pageData = {
    branchOrWareHouse: t("materialControl.createRemoveRequest.branchOrWarehouse"),
    reasonForRequest: t("materialControl.createRemoveRequest.reasonForRequest"),
    description: t("form.description"),
    back: t("button.back"),
    purchaseOrderManagement: t("purchaseOrder.purchaseOrderManagement"),
    edit: t("button.edit"),
    print: t("button.print"),
    approve: t("status.approved"),
    approveRequest: t("materialControl.detailRemoveRequest.approveRequest"),
    order: t("button.order"),
    complete: t("button.complete"),
    cancel: t("button.cancel"),
    option: t("button.option"),
    generalInfo: t("settings.createGroupPermission.titleGeneralInformation"),
    ingredientInformation: t("materialControl.createRemoveRequest.ingredientInfo"),
    requestHistory: t("materialControl.historyRemoveRequest.requestHistory"),
    approvePurchaseOrderSuccess: t("messages.approvePurchaseOrderSuccess"),
    orderPurchaseOrderSuccess: t("messages.orderPurchaseOrderSuccess"),
    cancelPurchaseOrderSuccess: t("messages.cancelPurchaseOrderSuccess"),
    completePurchaseOrderSuccess: t("messages.completePurchaseOrderSuccess"),
    tableIngredient: {
      no: t("table.no"),
      ingredients: t("menu.inventoryManagement.material"),
      totalQuantity: t("table.totalQuantity"),
      unavailable: t("materialControl.createRemoveRequest.unavailable"),
      reason: t("order.reason"),
      available: t("materialControl.createRemoveRequest.available"),
    },
    tableIngredientHistory: {
      timeline: t("materialControl.historyRemoveRequest.timeline"),
      activityEvent: t("materialControl.historyRemoveRequest.activity"),
    },
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("purchaseOrder.status.approved"),
      complete: t("purchaseOrder.status.completed"),
      cancel: t("purchaseOrder.status.canceled"),
      ordering: t("purchaseOrder.status.ordering"),
    },
    supplier: t("supplier.title"),
    destinationLabel: t("purchaseOrder.destinationLabel"),
    note: t("form.note"),
    materialInformation: t("purchaseOrder.materialInformation"),
    confirmCancel: t("leaveDialog.confirmCancel"),
    ignore: t("button.deletePageIgnore"),
    cancelPurchaseOrder: t("purchaseOrder.cancelPurchaseOrder"),
    viewHistory: t("button.viewHistory"),
    moreAction: t("button.moreAction"),
    confirmStopPromotion: t("messages.confirmStopPromotion"),
    reject: t("status.rejected"),
    rejectRequest: t("materialControl.detailRemoveRequest.rejectRequest"),
    rejectTitle: t("materialControl.detailRemoveRequest.rejectTitle"),
    rejectContent: t("materialControl.detailRemoveRequest.rejectContent"),
    waitingForApproval: t("status.waitingForApproval"),
    approveTitle: t("materialControl.detailRemoveRequest.approveTitle"),
    approveContent: t("materialControl.detailRemoveRequest.approveContent"),
    approveSuccess: t("materialControl.detailRemoveRequest.approveSuccess"),
    rejectSuccess: t("materialControl.detailRemoveRequest.rejectSuccess"),
    theRequest: t("materialControl.historyRemoveRequest.theRequest", "the request"),
    hoverTotal: t("materialControl.createRemoveRequest.hoverTotal"),
    hoverUnavailable: t("materialControl.createRemoveRequest.hoverUnavailable"),
    hoverAvailable: t("materialControl.createRemoveRequest.hoverAvailable"),
    reasonRejection: t("materialControl.detailRemoveRequest.reasonRejection"),
    idRequest: t("materialControl.detailRemoveRequest.idRequest"),
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await ingredientRemoveRequestDataService
      .getDetailIngredientRemoveRequestDataAsync(match?.params?.id)
      .then((res) => {
        if (res) {
          setIngredientRemoveRequest(res);
          let currencyStepByStatus = 0;
          if (
            res?.enumStatus === IngredientRemoveStatus.APPROVED ||
            res?.enumStatus === IngredientRemoveStatus.REJECTED
          ) {
            currencyStepByStatus = 1;
          }

          setCurrencyStep(currencyStepByStatus);
        }
      });
  };

  const columnsIngredientRemoveRequestDetails = () => {
    let columns = [
      {
        title: pageData.tableIngredient.no,
        align: "center",
        width: "5%",
        render: (_, record, index) => index + 1,
      },
      {
        title: pageData.tableIngredient.ingredients,
        align: "left",
        width: "27%",
        render: (_, record) => {
          return (
            <>
              <Container>
                <WrapperLeftColumn>
                  {record?.ingredientThumbnail ? (
                    <Thumbnail src={record?.ingredientThumbnail} width={56} height={56} />
                  ) : (
                    <ImageMaterialDefault width={56} height={56} />
                  )}
                  <div>
                    <FnbTooltip onlyShowWhenEllipsis title={record?.ingredientName} width={350} maxWidthContent={450}>
                      <FnbTypography variant={theme.typography["b1-bold"]} text={record?.ingredientName} />
                    </FnbTooltip>
                    <FnbTooltip onlyShowWhenEllipsis title={record?.ingredientSKU} width={350} maxWidthContent={450}>
                      <FnbTypography text={record?.ingredientSKU} color={theme.colors.gray[60]} />
                    </FnbTooltip>
                  </div>
                </WrapperLeftColumn>
                <WrapperRightColumn></WrapperRightColumn>
              </Container>
            </>
          );
        },
      },
      {
        title: pageData.tableIngredient.totalQuantity,
        dataIndex: "total",
        width: "15%",
        align: "center",
        tooltip: true,
        contentTooltip: parse(pageData.hoverTotal),
        render: (_, record) => {
          return <>{`${formatterDecimalNumber(record?.total)} ${record?.unit}`}</>;
        },
      },
      {
        title: pageData.tableIngredient.unavailable,
        dataIndex: "unavailable",
        width: "15%",
        align: "center",
        tooltip: true,
        contentTooltip: parse(pageData.hoverUnavailable),
        render: (_, record) => {
          return <>{`${formatterDecimalNumber(record?.unavailable)} ${record?.unit}`}</>;
        },
      },
      {
        title: pageData.tableIngredient.reason,
        dataIndex: "reason",
        align: "center",
        width: "23%",
      },
      {
        title: pageData.tableIngredient.available,
        dataIndex: "available",
        align: "center",
        width: "15%",
        tooltip: true,
        contentTooltip: parse(pageData.hoverAvailable),
        render: (_, record) => {
          return <>{`${formatterDecimalNumber(record?.available)} ${record?.unit}`}</>;
        },
      },
    ];
    return columns;
  };

  const columnsIngredientRemoveRequestHistories = () => {
    let columns = [
      {
        title: pageData.tableIngredientHistory.timeline,
        align: "left",
        width: "15%",
        render: (_, record) => {
          return <>{formatDate(record?.time, DateFormat.HH_mm_DD_MMM_YYYY)}</>;
        },
      },
      {
        title: pageData.tableIngredientHistory.activityEvent,
        dataIndex: "total",
        align: "left",
        width: "85%",
        render: (_, record) => {
          return (
            <Space size={6}>
              <Image
                src={record?.thumbnail ?? "error"}
                width={32}
                height={32}
                style={{ borderRadius: 4 }}
                fallback={images.imgDefault}
              />
              <FnbTooltip onlyShowWhenEllipsis maxWidthContent={280} title={record?.user}>
                <FnbTypography.Link text={record?.user} variant={theme.typography["b1-bold"]} />
              </FnbTooltip>
              <FnbTypography
                text={`${t(IngredientRemoveRequestHistoryLocalization[record?.type])} ${pageData.theRequest}`}
              />
            </Space>
          );
        },
      },
    ];
    return columns;
  };

  const renderActionCreate = () => {
    if (
      hasPermission(PermissionKeys.APPROVE_INGREDIENT_REMOVE_REQUEST) &&
      ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.WAITING_TO_APPROVAL
    ) {
      return (
        <FnbButton
          minWidth="fit-content"
          text={pageData.approveRequest}
          onClick={() => {
            setIsShowModalApprove(true);
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  const onCancel = () => {
    history.push(`/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_INVENTORY_CONTROL}`);
  };

  const onEdit = () => {
    history.push(`/inventory/material-control/edit-ingredient-remove-request/${match?.params?.id}`);
  };

  const handleBack = () => {
    history.push(urlBack);
  };

  const renderMoreAction = () => {
    return (
      <>
        <FnbButton text={pageData.cancel} variant="tertiary" onClick={handleBack} />
        {ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.WAITING_TO_APPROVAL && (
          <FnbButton
            style={{ justifyContent: "left" }}
            className="w-100 background-color-white"
            variant="tertiary"
            onClick={() => onEdit()}
            permission={PermissionKeys.EDIT_INGREDIENT_REMOVE_REQUEST}
            iconHeader={<EditOutlined />}
          />
        )}

        {ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.WAITING_TO_APPROVAL && (
          <FnbButton
            style={{ justifyContent: "left" }}
            className="w-100 background-color-white"
            text={pageData.rejectRequest}
            variant="tertiary"
            onClick={() => {
              setIsShowModalReject(true);
            }}
            permission={PermissionKeys.REJECT_INGREDIENT_REMOVE_REQUEST}
          />
        )}
      </>
    );
  };

  const onRejectIngredientRemoveRequest = (reason) => {
    let req = { id: match?.params?.id, reason: reason };

    ingredientRemoveRequestDataService
      .rejectIngredientRemoveRequestAsync(req)
      .then((res) => {
        if (res) {
          message.success(pageData.rejectSuccess);
          setIsShowModalReject(false);
          setCurrencyStep(1);
          fetchData();
        }
      })
      .catch((_ex) => {
        setIsShowModalReject(false);
      });
  };

  const onApproveIngredientRemoveRequest = () => {
    setDisabledBtnApprove(true);
    let req = { id: match?.params?.id };
    ingredientRemoveRequestDataService
      .approveIngredientRemoveRequestAsync(req)
      .then((res) => {
        if (res) {
          message.success(pageData.approveSuccess);
          setIsShowModalApprove(false);
          setCurrencyStep(1);
          fetchData();
          setDisabledBtnApprove(false);
        }
      })
      .catch((_ex) => {
        setIsShowModalApprove(false);
      });
  };

  return (
    <div className="detail-ingredient-remove-request">
      <FnbHeadingPage
        itemsBreadcrumb={[
          {
            href: urlBack,
            title: pageData.back,
          },
          {
            title: (
              <Text className="purchase-order-code">{`${pageData.idRequest}: ${ingredientRemoveRequest?.code}`}</Text>
            ),
          },
        ]}
        listButtons={[renderMoreAction(), renderActionCreate()]}
        isCompactButtons={false}
        approveStatus={
          ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.APPROVED && t(ingredientRemoveRequest?.status)
        }
        rejectStatus={
          ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.REJECTED && t(ingredientRemoveRequest?.status)
        }
        waitingForApproveStatus={
          ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.WAITING_TO_APPROVAL &&
          t(ingredientRemoveRequest?.status)
        }
      />

      <div className="card-parent">
        {ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.REJECTED && (
          <FnbCard className="card-general-info reject-reason">
            <div className="title">{pageData.reasonRejection}</div>
            <div className="content">
              {
                ingredientRemoveRequest?.ingredientRemoveHistories?.find(
                  (x) => x.type === IngredientRemoveRequestHistoryType.REJECTED,
                )?.description
              }
            </div>
          </FnbCard>
        )}
        <FnbCard className="card-general-info mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.generalInfo} />
            <hr className="title-hr" />
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.branchOrWareHouse}:`} />
              </div>
              <div className="content">
                <FnbTypography text={ingredientRemoveRequest?.branchOrHouseware} />
              </div>
            </div>
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.reasonForRequest}:`} />
              </div>
              <div className="content">
                <FnbTypography text={t(ingredientRemoveRequest?.reason)} />
              </div>
            </div>
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.description}:`} />
              </div>
              <div className="content text-area">
                <FnbTypography text={ingredientRemoveRequest?.description} />
              </div>
            </div>
          </div>
          <FnbSteps currentStep={currencyStep} className="steps-info">
            <Step isProcessing={TrackingSteps.process} title={pageData.waitingForApproval} />

            <Step
              isProcessing={TrackingSteps.process}
              className={
                ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.REJECTED ? "step-item-cancel-new" : ""
              }
              title={
                ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.REJECTED
                  ? pageData.reject
                  : pageData.approve
              }
              icon={ingredientRemoveRequest?.enumStatus === IngredientRemoveStatus.REJECTED && <RejectStep />}
            />
          </FnbSteps>
        </FnbCard>
        <FnbCard className="card-general-info card-table mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.ingredientInformation} />
            <hr className="title-hr" />
          </div>
          <FnbTable
            className="mt-4"
            dataSource={ingredientRemoveRequest?.ingredientRemoveRequestDetails}
            columns={columnsIngredientRemoveRequestDetails()}
          />
        </FnbCard>
        <FnbCard className="card-general-info card-table mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.requestHistory} />
            <hr className="title-hr" />
          </div>
          <FnbTable
            className="mt-4"
            dataSource={ingredientRemoveRequest?.ingredientRemoveHistories}
            columns={columnsIngredientRemoveRequestHistories()}
          />
        </FnbCard>
      </div>
      <ConfirmDialogComponent
        visible={isShowModalApprove}
        type={ConfirmStyle.STOP}
        onCancel={() => setIsShowModalApprove(false)}
        onOk={onApproveIngredientRemoveRequest}
        title={pageData.approveTitle}
        content={pageData.approveContent}
        okText={pageData.approve}
        cancelText={pageData.ignore}
        disabledBtnOk={disabledBtnApprove}
      />
      <RejectDialogComponent
        visible={isShowModalReject}
        type={ConfirmStyle.DELETE}
        onCancel={() => setIsShowModalReject(false)}
        onOk={onRejectIngredientRemoveRequest}
        title={pageData.rejectTitle}
        content={pageData.rejectContent}
        okText={t("materialControl.detailRemoveRequest.reject")}
        cancelText={t("store.ignore")}
      />
    </div>
  );
}
