import { ListPromotionType } from "constants/promotion.constants";
import branchDataService from "data-services/branch/branch-data.service";
import storeDataService from "data-services/store/store-data.service";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform as platform } from "constants/platform.constants";

export const PromotionProviderContext = createContext();

export const usePromotionContextProvider = () => {
  const context = useContext(PromotionProviderContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used with a DashboardContextProvider");
  }

  return context;
};

export const AppContextProvider = ({ children }) => {
  const [t] = useTranslation();
  const [branches, setBranches] = useState([]);
  const [promotionTypeOptions, setPromotionTypeOptions] = useState([]);
  const [listPlatforms, setListPlatforms] = useState([]);

  useEffect(() => {
    initFilter();
  }, []);

  const initPromotionType = () => {
    let allPromotionType = {
      id: "",
      name: t("promotion.allType"),
    };
    let listPromotionType = ListPromotionType?.map((item) => ({
      id: item.key,
      name: t(item.name),
    }));
    let promotionTypeOptions = [allPromotionType, ...listPromotionType];
    setPromotionTypeOptions(promotionTypeOptions);
  };

  const initPlatformFilter = () => {
    storeDataService.getAllPlatformActivatedAsync().then((data) => {
      if (data) {
        data.platforms = data.platforms?.filter((item) => item.id?.toLowerCase() !== platform?.AdminWebsite?.toLowerCase());
        setListPlatforms(data.platforms);
      }
    });
  };

  const initBranches = async () => {
    var resBranch = await branchDataService.getAllBranchsAsync();
    if (resBranch) {
      const allBranchOption = {
        id: "",
        name: t("productManagement.filter.branch.all"),
      };
      const branchOptions = [allBranchOption, ...resBranch.branchs];
      setBranches(branchOptions);
    }
  };

  const initFilter = () => {
    initPromotionType();
    initBranches();
    initPlatformFilter();
  };

  const context = { branches, promotionTypeOptions, listPlatforms };
  return <PromotionProviderContext.Provider value={context}>{children}</PromotionProviderContext.Provider>;
};
