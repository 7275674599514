import { Drawer, Image, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { EnumAddToCartType } from "../../../../constants/enums";
import { Platform } from "../../../../constants/platform.constants";
import comboDataService from "../../../../data-services/combo-data.service";
import productDataService from "../../../../data-services/product-data.service";
import { setToastMessageAddUpdateProductToCart } from "../../../../modules/toast-message/toast-message.actions";
import shoppingCartService from "../../../../services/shopping-cart/shopping-cart.service";
import { formatTextCurrency } from "../../../../utils/helpers";
import { getStorage, localStorageKeys } from "../../../../utils/localStorage.helpers";
import { AddToCartIcon } from "../../../assets/icons.constants";
import BackgroundFlashSale from "../../../assets/images/flash-sale-background.png";
import FlashSaleProductBottom from "../../../assets/images/flash-sale-product-card.png";
import ImgDefault from "../../../assets/images/product-default.png";
import { ComboType } from "../../../constants/combo.constants";
import { EditOrderProductDialogComponent } from "../../checkout/components/edit-order-product-dialog.component";
import "./product-card.component.scss";

const StyledProductCard = styled.div`
  @media (min-width: 1281px) {
    :hover {
      .product-card {
        &__content {
          background: ${(props) => props.styleProductList?.colorGroup?.buttonBackgroundColor || "defaultColor"};
        }
      }
    }
  }
  .product-card {
    &__title {
      color: ${(props) => props.styleProductList?.colorGroup?.textColor || "defaultColor"};
    }
    &__price-sell {
      color: ${(props) => props.styleProductList?.colorGroup?.titleColor || "defaultColor"};
    }
  }
`;

function ProductCard(props) {
  const history = useHistory();
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalKey, setModalKey] = useState(true);
  const [initProductData, setInitProductData] = useState(null);
  const [initCurrentIndex, setInitCurrentIndex] = useState(0);
  const [currentCartItems, setCurrentCartItems] = useState(JSON.parse(getStorage(localStorageKeys.STORE_CART)));
  const generalConfig = useSelector((state) => state.session?.themeConfig?.general);
  const colorConfig = generalConfig?.color?.colorGroups?.find((x) => x.id === generalConfig?.header?.colorGroupId);

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const translatedData = {
    outOfStock: t("storeWebPage.productDetailPage.outOfStock", "outOfStock"),
    addCartItemToastMessage: t("addCartItemToastMessage", "Sản phẩm đã được thêm vào giỏ hàng thành công"),
  };

  const { product, currency, handleAddToCart, styleProductList, tagDiscount, isOutOfStock = false, isGridView } = props;

  const navigateToProductDetail = () => {
    let url = "";
    if (product.isCombo) {
      if (product.comboTypeId === ComboType.SPECIFIC) {
        url = `/combo-detail/combo-product-price/${product.id}`;
      }
      if (product.comboTypeId === ComboType.FLEXIBLE) {
        url = `/combo-detail/combo-pricing/${product.id}`;
      }
    } else {
      url = `/product-detail/${product.id}`;
    }
    history.push(url);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const setStoreCart = (cartItems) => {
    if (window.setStoreCart) {
      clearTimeout(window.setStoreCart);
    }
    window.setStoreCart = setTimeout(() => {
      shoppingCartService?.setStoreCart(cartItems, true);
    }, 200);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalKey(!modalKey);
  };

  const onShowToastMessageAddCartItem = () => {
    dispatch(
      setToastMessageAddUpdateProductToCart({
        icon: null,
        message: translatedData.addCartItemToastMessage,
      }),
    );
  };

  const getComboProductPrice = async (comboId) => {
    const comboDetail = await comboDataService.getComboProductPriceByComboIdAsync(comboId);
    return comboDetail;
  };

  const getComboPricingByComboPricingId = async (comboId) => {
    const comboDetail = await comboDataService.getComboPricingByComboPricingIdAsync(comboId);
    return comboDetail;
  };

  const getProductDetail = async (productId, branchId) => {
    return await productDataService.getProductDetailByIdAsync(productId, Platform.StoreWebsite, branchId);
  };

  const onClickAddToCart = async (product) => {
    var productDetailData = [];
    if (product.isNotQuickAdd) {
      if (product.isCombo) {
        if (product.comboTypeId === ComboType.FLEXIBLE) {
          await getComboPricingByComboPricingId(product?.id).then((response) => {
            productDetailData = response?.data?.combo;
          });
        } else {
          await getComboProductPrice(product?.id).then((response) => {
            productDetailData = response?.data?.combo;
          });
        }
        productDetailData = productDataService.mappingComboProductSelect(productDetailData);
      } else {
        await getProductDetail(product?.id).then((response) => {
          productDetailData = response?.data;
        });
        productDetailData = productDataService.mappingProductSelect(productDetailData, product);
      }

      setInitProductData(productDetailData);
      setIsModalVisible(true);
    } else {
      let data = {};
      if (product.isCombo) {
        data = {
          id: product.id,
          isCombo: true,
          type:
            product.comboTypeId === ComboType.SPECIFIC
              ? EnumAddToCartType.ComboProductPrice
              : EnumAddToCartType.ComboPricing,
          data: product?.data,
        };
      } else {
        data = {
          flashSaleId: product?.flashSaleId,
          id: product.id,
          isCombo: false,
          isFlashSale: product.isFlashSale,
          productPriceId: product.productPriceId,
          type: EnumAddToCartType.Product,
        };
      }

      if (handleAddToCart) {
        handleAddToCart(data);
      }
    }
  };

  return (
    <StyledProductCard
      styleProductList={styleProductList}
      className={`product-card-theme1 ${isOutOfStock ? "product-card-theme1--is-out-of-stock" : ""} ${
        isGridView ? "" : "product-card-theme1-list-view"
      }`}
    >
      {product.isFlashSale && <img className="product-card__flashsale-img" src={BackgroundFlashSale} alt="" />}
      <div
        className={`product-card__img-box ${product.isFlashSale ? "product-card__img-box--is-flashsale" : ""}`}
        onClick={navigateToProductDetail}
      >
        {tagDiscount !== "" && (
          <div className="product-card__percent-discount">
            <svg xmlns="http://www.w3.org/2000/svg" width="159" height="85" viewBox="0 0 159 85" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M128.901 0L0.149452 83.612L0 83.3818V33.1145L50.9918 0H128.901Z"
                fill="#FF3448"
              />
            </svg>
            <span>{tagDiscount}</span>
          </div>
        )}
        <Image
          alt=""
          src={product.thumbnail ? product.thumbnail : ImgDefault}
          className="product-card__img"
          preview={false}
          fallback={product.thumbnailFallback}
        />
        {product.isFlashSale && <img className="product-card__flashsale-bottom" src={FlashSaleProductBottom} alt="" />}
        {/* Out of stock */}
        {isOutOfStock && <div className="out-of-stock-badge">{translatedData.outOfStock}</div>}
      </div>
      <div className="product-card__content">
        <div className="product-card__title" onClick={navigateToProductDetail}>
          {product?.priceName ? `${product?.name} (${product?.priceName})` : product?.name}
        </div>
        <div className="product-card__bottom">
          <div className="product-card__price-block">
            <span className="product-card__price-sell">{formatTextCurrency(product.sellingPrice)}</span>
            {product?.originalPrice > product?.sellingPrice && (
              <span className="product-card__price-discount">{`${formatTextCurrency(product.originalPrice)}`}</span>
            )}
          </div>
          <div className="product-card__btn-add-to-cart">
            <AddToCartIcon onClick={() => onClickAddToCart(product)} />
          </div>
        </div>
      </div>
      {isMaxWidth575
        ? isModalVisible && (
            <Drawer
              width={500}
              placement="bottom"
              closeIcon
              open={isModalVisible}
              onClose={handleCancel}
              forceRender={true}
              destroyOnClose={true}
              zIndex={1001}
            >
              <EditOrderProductDialogComponent
                isModalVisible={isModalVisible}
                initProductData={initProductData}
                initCurrentIndex={-1} /// -1 add new product
                onCancel={handleCancel}
                setCurrentCartItems={(cartItems) => {
                  setCurrentCartItems(cartItems);
                  onShowToastMessageAddCartItem();
                }}
                calculateShoppingCart={setStoreCart}
                platformId={Platform.StoreWebsite}
              />
            </Drawer>
          )
        : isModalVisible && (
            <Modal
              width={1400}
              className="modal-product-cart-detail"
              open={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={(null, null)}
              centered
              forceRender={true}
              destroyOnClose={true}
              zIndex={1001}
              key={modalKey}
            >
              <EditOrderProductDialogComponent
                isQuickAdd={true}
                isModalVisible={isModalVisible}
                initProductData={initProductData}
                initCurrentIndex={-1} /// -1 add new product
                onCancel={handleCancel}
                colorGroup={colorConfig}
                setCurrentCartItems={(cartItems) => {
                  setCurrentCartItems(cartItems);
                  onShowToastMessageAddCartItem();
                }}
                calculateShoppingCart={setStoreCart}
                platformId={Platform.StoreWebsite}
              />
            </Modal>
          )}
    </StyledProductCard>
  );
}

export default React.memo(ProductCard);
