import styled from "styled-components";
import theme from "theme";
import { FlexColumn } from "../styled";

export const CardPlatformContainer = styled(FlexColumn)`
  min-height: 244px;
  width: 100%;
  border-radius: 16px;
  background-color: ${theme.colors.default.white};
  padding: 16px;
  row-gap: 16px;
  @media (max-width: 1200px) {
    min-height: 376px;
  }
  @media (max-width: 575px) {
    min-height: 380px;
  }
`;

export const LineTitlePlatform = styled.hr`
  width: 40px;
  border: 4px solid ${theme.colors.primary[100]};
  border-radius: 5px;
`;

export const ContentPlatform = styled.div`
  display: flex;
  column-gap: 32px;
  row-gap: 16px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
