import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qrcode-logo";
import theme from "theme";
import { FlexColumn, FlexRow } from "../styled";
import { CardPlatformContainer, ContentPlatform, LineTitlePlatform } from "./styled-card-platform";

const CardPlatform = ({
  title = "",
  thumbnail = {
    src: "",
    width: 110,
    height: 82,
  },
  qrCode = {
    link: "",
    logo: "",
  },
  content,
  isComingSoon = false,
  className,
}) => {
  const [t] = useTranslation();
  return (
    <CardPlatformContainer className={className}>
      <FlexRow columnGap={16}>
        <FlexColumn rowGap={16}>
          <FnbTypography text={title} variant={theme.typography["b1-bold"]} />
          <LineTitlePlatform />
        </FlexColumn>
        {isComingSoon && (
          <FnbChip
            isDisableToolTip={true}
            styleChip={{ backgroundColor: theme.colors.primary[30] }}
            title={t("configuration.comingSoon")}
          />
        )}
      </FlexRow>
      <ContentPlatform>
        {thumbnail.src && <img src={thumbnail.src} width={thumbnail.width} height={thumbnail.height} alt="" />}
        {qrCode.link && (
          <QRCode
            removeQrCodeBehindLogo
            quietZone={0}
            value={qrCode.link}
            logoImage={qrCode.logo}
            fgColor={"#2B2162"}
            logoPadding={qrCode.logoPadding}
            logoHeight={qrCode.logoHeight}
            logoWidth={qrCode.logoWidth}
            logoPaddingStyle="square"
            size={110}
            qrStyle={"dots"}
          />
        )}
        {content}
      </ContentPlatform>
    </CardPlatformContainer>
  );
};

export default CardPlatform;
