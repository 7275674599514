import ComingSoonTag from "components/coming-soon-tag";
import FnbSwitch from "components/switch";
import React, { useEffect, useState } from "react";
import "./fnb-button-compound.scss";
import { StoreGeneralConfigInfoCircleIcon } from "constants/icons.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import classNames from "classnames";

const FnbButtonCompound = ({
  onClick,
  isActive,
  title,
  showComingSoon = false,
  showSwitch,
  disabled = false,
  valueSwitch = false,
  setValueSwitch,
  messageInfo = undefined, // string | JSX.Element
  iconHeader = undefined,
  className = "",
  onChangeSwitch = undefined,
}) => {
  const [valueSwitchBtn, setValueSwitchBtn] = useState(false);
  const onClickItemConfig = () => {
    onClick && onClick();
  };

  useEffect(() => {
    setValueSwitchBtn(valueSwitch);
  }, [valueSwitch]);

  const onChangeSwitchComponent = (e) => {
    setValueSwitchBtn(e);
    setValueSwitch && setValueSwitch(e);
    onChangeSwitch && onChangeSwitch(e)
  };

  const onClickFooter = (e) => {
    e.stopPropagation();
  };

  const classNameButton = classNames({
    "fnb-button-compound": true,
    "fnb-button-compound--active": isActive,
    "fnb-button-compound--default": !isActive,
    "fnb-button-compound--disabled": disabled,
    [`${className}`]: true
  });

  return (
    <div className={classNameButton} onClick={() => onClickItemConfig()}>
      <div className="fnb-button-compound__header">
        {iconHeader && (
          <div className="fnb-button-compound__wrapper-icon">
            {React.cloneElement(iconHeader, {
              className: "fnb-button-compound__icon",
            })}
          </div>
        )}
        <div className="fnb-button-compound__wrapper-title">
          <span className="fnb-button-compound__title">{title}</span>
          {messageInfo && (
            <FnbTooltip placement="top" title={messageInfo}>
              <StoreGeneralConfigInfoCircleIcon />
            </FnbTooltip>
          )}
        </div>
      </div>
      <div className="fnb-button-compound__footer" onClick={onClickFooter}>
        {showComingSoon && <ComingSoonTag />}
        {showSwitch && (
          <FnbSwitch
            className="fnb-button-compound__switch"
            checked={valueSwitchBtn}
            disabled={disabled}
            onChange={onChangeSwitchComponent}
          />
        )}
      </div>
    </div>
  );
};

export default FnbButtonCompound;
