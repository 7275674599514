import { Col, Form, message, Row } from "antd";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbSwitch from "components/switch";
import { inputNumberRange1To999999999 } from "constants/default.constants";
import {
  ArrowLeftOutlinedIcon,
  CustomInfoCircleIcon,
  CustomTrashIcon,
  LampOn,
  UploadLogoIcon,
} from "constants/icons.constants";
import { Platform } from "constants/platform.constants";
import { currency } from "constants/string.constants";
import productDataService from "data-services/product/product-data.service";
import storeDataService from "data-services/store/store-data.service";
import unitDataService from "data-services/unit/unit-data.service";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { formatNumberDecimalOrInteger, formatterDecimalNumber, isDecimalKey, parserDecimalNumber } from "utils/helpers";
import PlatformPreview from "./components/platform-preview.component";
import { WrapPlatform } from "./components/platform-preview.styled";
import StampPreview from "./components/stamp-preview.component";
import {
  ColPrintSticker,
  FnbModalAddNewToppingStyled,
  InfoTotalCostTable,
  TableListIngredients,
  TitleCustomModalAddTax,
  WrapInputQuantityIngredient,
  WraptTipRecipe,
} from "./fnb-modal-create-topping.styled";
import CreateNewTax from "pages/store/fee-tax/tax/components/create-tax.component";
import taxType from "constants/taxType.constants";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useMediaQuery } from "react-responsive";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";

const FORM_ITEM_NAMES = {
  NAME: "name",
  DESCRIPTION: "description",
  PLATFORM_IDS: "platformIds",
  PRICE: "price",
  TAX_ID: "taxId",
  UNIT_ID: "unitId",
  INGREDIENTS: "ingredients",
  IMAGE: "image",
  IS_PRINT_STAMP: "isPrintStamp",
  MATERIALS: "materials",
  MATERIAL_NAME: "materialName",
  MATERIAL_QUANTITY: "materialQuantity",
};

const DEFAULT_CHECKED_PLATFORMS = [
  Platform.POS.toLowerCase(),
  Platform.StoreWebsite.toLowerCase(),
  Platform.StoreMobileApp.toLowerCase(),
];

const FnbModalCreateTopping = ({
  visible,
  setVisible,
  defaultNameTopping,
  initPlatforms = undefined,
  initUnits = undefined,
  initMaterials = undefined,
  updateNewInitMaterials = undefined,
  setInitUnit = undefined,
  toppings,
  setToppings,
  currencyCode,
  stampConfigs,
  stampData,
  taxes,
  setTaxes,
  handleAddSuccess,
}) => {
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const uploadImageToppingRef = useRef(null);
  const [platforms, setPlatforms] = useState(initPlatforms);
  const [units, setUnits] = useState(initUnits);
  const [materials, setMaterials] = useState(initMaterials);
  const [searchValueUnit, setSearchValueUnit] = useState("");
  const [searchValueTax, setSearchValueTax] = useState("");
  const [dataSourceTableIngredient, setDataSourceTableIngredient] = useState([]);
  const [platformSelected, setPlatformSelected] = useState(DEFAULT_CHECKED_PLATFORMS);
  const [requestingAddTopping, setRequestingAddTopping] = useState(false);
  const [objectIngredientsSelected, setObjectIngredientDtoSelected] = useState({});
  const [showModalCreateTax, setShowModalCreateTax] = useState(false);
  const [totalCostTable, setToTalCostTable] = useState(0);
  const isMaxWidthScreenMedium = useMediaQuery({ maxWidth: 768 });
  const [image, setImage] = useState(null);

  const pageData = {
    modal: {
      title: t("productManagement.btnMenuCreateTopping"),
      createTopping: t("productManagement.btnCreateTopping"),
    },
    description: {
      title: t("form.description"),
      placeholder: t("productManagement.description.placeholder"),
    },
    topping: {
      title: t("productManagement.productInformation.toppingName"),
      placeholder: t("productManagement.productInformation.toppingNamePlaceholder"),
      validateMessage: t("productManagement.productInformation.toppingValidateMessage"),
      addSuccess: t("productManagement.toppingAddedSuccess"),
      addError: t("productManagement.toppingAddedFail"),
    },
    pricing: {
      price: t("productManagement.pricing.price"),
      placeholder: t("productManagement.pricing.pricePlaceholder"),
      validateMessage: t("productManagement.pricing.priceRange"),
    },
    tax: {
      title: t("table.tax"),
      placeholder: t("productManagement.tax.placeholder"),
    },
    unit: {
      unit: t("table.unit"),
      title: t("productManagement.unit.title"),
      placeholder: t("productManagement.unit.placeholder"),
      validateMessage: t("productManagement.unit.pleaseSelectUnit"),
      unitNameExisted: t("productManagement.unit.unitNameExisted"),
    },
    recipe: {
      title: t("productManagement.unit.recipe"),
      placeholder: t("productManagement.recipe.placeholder"),
      validateMessage: t("inventoryTracking.pleaseSetupRecipe"),
      ingredient: {
        title: t("material.material"),
        validateMessage: t("inventoryTracking.pleaseSelectMaterial"),
        quantityRange: t("material.inventory.quantityValidateMessage"),
      },
      table: {
        ingredients: t("productManagement.recipe.table.ingredients"),
        quantity: t("productManagement.recipe.table.quantity"),
        costPerUnit: t("productManagement.recipe.table.costPerUnit", { currency: currencyCode }),
        totalCost: t("productManagement.recipe.table.totalCost", { currency: currencyCode }),
        infoTotalCost: t("productManagement.productInformation.totalProductCost"),
      },
      tip: t("productManagement.recipe.recipeTip"),
    },
    uploadImage: {
      label: t("productManagement.productImage.title"),
      title: t("productManagement.generalInformation.uploadImages"),
    },
    otherSetting: {
      printSticker: t("productManagement.otherSetting.isPrintStampTitleSticker", "Print item label sticker (stamp)"),
    },
    modalAddTax: {
      backToAddTopping: t("productManagement.backToAddTopping", "Back to add topping"),
      titleHeader: t("productManagement.tax.addNewTax", "Add new tax"),
      createTax: t("permissions.createTax"),
    },
  };

  // region init data
  const getPlatforms = async () => {
    const response = await storeDataService.getAllPlatformActivatedAsync(true);
    if (response) {
      setPlatforms(response?.platforms);
    }
  };
  const getUnits = async () => {
    var resUnit = await unitDataService.getUnitsAsync();
    if (resUnit) {
      setUnits(resUnit.units);
    }
  };

  useEffect(() => {
    if (defaultNameTopping && form) {
      form.setFieldValue(FORM_ITEM_NAMES.MATERIAL_NAME, defaultNameTopping);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultNameTopping, form]);

  useEffect(() => {
    if (initPlatforms) {
      setPlatforms(initPlatforms);
    }
  }, [initPlatforms]);

  useEffect(() => {
    if (visible) {
      form.setFieldValue(FORM_ITEM_NAMES.NAME, defaultNameTopping);
      form.setFieldValue(FORM_ITEM_NAMES.PLATFORM_IDS, DEFAULT_CHECKED_PLATFORMS);
      form.setFieldValue(FORM_ITEM_NAMES.IS_PRINT_STAMP, true);
      if (platforms?.length === 0) {
        getPlatforms();
      }
      if (units?.length === 0) {
        getUnits();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (initUnits) setUnits(initUnits);
  }, [initUnits]);

  useEffect(() => {
    if (initMaterials) setMaterials(initMaterials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initMaterials]);

  const setDefaultDataModal = () => {
    setPlatformSelected(DEFAULT_CHECKED_PLATFORMS);
    setObjectIngredientDtoSelected({});
    form.resetFields();
  };

  // end region init data

  // region handle tax
  const addNewItemTax = () => {
    setShowModalCreateTax(true);
  };
  const handleCompletedTaxModal = (id, tax) => {
    const newTax = {
      id: id,
      name: tax?.name,
    };
    setTaxes([newTax, ...taxes]);
    form.setFieldValue(FORM_ITEM_NAMES.TAX_ID, id);
    setShowModalCreateTax(false);
  };

  const handleCancelTaxModal = () => {
    setShowModalCreateTax(false);
  };

  //region handle unit
  const addNewItemUnit = async () => {
    const response = await unitDataService.createUnitAsync({
      name: searchValueUnit,
    });
    if (response?.isSuccess) {
      const newItem = {
        id: response.id,
        name: response?.name,
      };
      setUnits([newItem, ...units]);
      setInitUnit && setInitUnit([newItem, ...units]);
      form.setFieldValue(FORM_ITEM_NAMES.UNIT_ID, response?.id);
      setSearchValueUnit("");
    } else {
      message.error(pageData.unit.unitNameExisted);
    }
  };

  //region ingredients handle
  useEffect(() => {
    let newDataSource = [];
    let sumTotalCost = 0;
    Object.keys(objectIngredientsSelected).forEach((key) => {
      newDataSource.push({
        id: key,
        ...objectIngredientsSelected[key],
      });
      sumTotalCost +=
        (objectIngredientsSelected[key]?.quantity ?? 0) * (objectIngredientsSelected[key]?.costPerUnit ?? 0);
    });
    setDataSourceTableIngredient(newDataSource);
    setToTalCostTable(sumTotalCost);
  }, [objectIngredientsSelected]);

  const onChangeQuantityIngredient = (id, quantity) => {
    let newObjectIngredients = { ...objectIngredientsSelected };
    newObjectIngredients[id].quantity = quantity;
    setObjectIngredientDtoSelected(newObjectIngredients);
  };

  const onClickRemoveIngredient = (id) => {
    let newObjectIngredientDto = { ...objectIngredientsSelected };
    delete newObjectIngredientDto[id];
    setObjectIngredientDtoSelected(newObjectIngredientDto);
    form.setFieldValue(FORM_ITEM_NAMES.INGREDIENTS, Object.keys(newObjectIngredientDto));
    form.setFieldValue([FORM_ITEM_NAMES.MATERIALS, id, FORM_ITEM_NAMES.MATERIAL_QUANTITY], 0);
  };

  const onChangeIngredients = (ids) => {
    let newIngredients = { ...objectIngredientsSelected };
    const currentIds = Object.keys(newIngredients);
    currentIds?.forEach((id) => {
      if (!ids?.includes(id)) {
        delete newIngredients[id];
        form.setFieldValue([FORM_ITEM_NAMES.MATERIALS, id, FORM_ITEM_NAMES.MATERIAL_QUANTITY], 0);
      }
    });
    if (ids?.length > 0) {
      ids?.forEach((id) => {
        const ingredient = materials?.find((item) => item.id === id);
        newIngredients[id] = {
          name: ingredient?.name,
          unitName: ingredient?.unitName,
          costPerUnit: ingredient?.costPerUnit,
          ...newIngredients[id],
        };
      });
      setObjectIngredientDtoSelected(newIngredients);
    } else {
      setObjectIngredientDtoSelected({});
    }
  };

  const onAddNewItemIngredient = (label) => {
    window.open(`/material/add-new?name=${label}`, "_blank");
  };

  const IngredientFormItem = () => {
    return (
      <>
        <Form.Item
          label={pageData.recipe.title}
          name={[FORM_ITEM_NAMES.INGREDIENTS]}
          required
          rules={[
            {
              required: true,
              message: <InputValidateMessage message={pageData.recipe.validateMessage} />,
              validator: (_, value) => {
                if (value?.length > 0) return Promise.resolve();
                return Promise.reject();
              },
            },
          ]}
        >
          <FnbSelectAddNewItem
            mode="multiple"
            options={materials?.map((item) => ({
              value: item.id,
              label: item?.name,
              description: `${item?.costPerUnit ?? 0}/${item?.unitName}`,
            }))}
            isHeaderCheckedIcon
            isShowPrefixDescription
            allowClear
            isShowSearchIcon={dataSourceTableIngredient?.length === 0}
            placeholder={pageData.recipe.placeholder}
            onChange={onChangeIngredients}
            fieldItemName={pageData.recipe.ingredient.title}
            onMouseDownAddNewItem={onAddNewItemIngredient}
            className={"fnb-select-add-new-ingredient"}
          />
        </Form.Item>
        <WraptTipRecipe>
          <LampOn />
          <FnbTypography text={pageData.recipe.tip} variant={theme.typography["b2-regular"]} />
        </WraptTipRecipe>
      </>
    );
  };

  const tableIngredientSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.recipe.table.ingredients,
        dataIndex: "name",
        render: (value) => (
          <FnbTooltip onlyShowWhenEllipsis maxWidthContent={300} title={value}>
            <FnbTypography text={value} />
          </FnbTooltip>
        ),
      },
      {
        title: pageData.recipe.table.quantity,
        render: (_, record) => {
          return (
            <WrapInputQuantityIngredient>
              <Form.Item
                name={[FORM_ITEM_NAMES.MATERIALS, record.id, FORM_ITEM_NAMES.MATERIAL_QUANTITY]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.recipe.ingredient.validateMessage}
                      />
                    ),
                  },
                  () => ({
                    validator(_, value) {
                      if (value > 999_999_999 || value < 0.01) {
                        return Promise.reject(
                          <InputValidateMessage message={pageData.recipe.ingredient.quantityRange} />,
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FnbInputNumber
                  style={{ width: "100px" }}
                  formatter={(value) => formatterDecimalNumber(value)}
                  parser={(value) => parserDecimalNumber(value)}
                  onKeyPress={(event) => {
                    if (!isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                  showWrap={true}
                  onChange={(e) => onChangeQuantityIngredient(record?.id, e)}
                />
              </Form.Item>
              <FnbTypography text={record?.unitName} />
            </WrapInputQuantityIngredient>
          );
        },
      },
      {
        title: pageData.recipe.table.costPerUnit,
        dataIndex: "costPerUnit",
        align: "right",
        render: (value) => {
          return <FnbTypography text={formatNumberDecimalOrInteger(value)} />;
        },
      },
      {
        title: pageData.recipe.table.totalCost,
        align: "right",
        render: (_, record) => {
          const ingredient = objectIngredientsSelected[record?.id];
          return (
            <FnbTypography
              text={
                ingredient?.quantity > 0
                  ? formatNumberDecimalOrInteger(ingredient?.quantity * ingredient?.costPerUnit)
                  : 0
              }
            />
          );
        },
      },
      {
        title: "",
        align: "center",
        render: (_, record) => {
          return (
            <FnbTooltip
              hideTooltip={isTouchDevice}
              disabledStopPropagation={isTouchDevice}
              placement="top"
              title={t("button.delete")}
              onClick={() => {
                onClickRemoveIngredient(record.id);
              }}
            >
              <CustomTrashIcon color={theme.colors.primary.main} />
            </FnbTooltip>
          );
        },
      },
    ],
  };

  //end region

  const onOkModalTopping = () => {
    form.submit();
  };

  const onCancelModalTopping = () => {
    setVisible(false);
    setDefaultDataModal();
    setImage(null);
  };

  const onChangePlatforms = (checked, id) => {
    const newPlatform = [...platformSelected];
    let index = newPlatform?.findIndex((item) => item === id);
    if (index >= 0) {
      if (!checked) {
        newPlatform.splice(index, 1);
      }
    } else if (checked) {
      newPlatform.push(id);
    }
    setPlatformSelected(newPlatform);
  };

  //region handle finish form add topping
  const onFinishForm = async (formValue) => {
    let payloadMaterials = [],
      image = "";

    Object.keys(objectIngredientsSelected).forEach((key) => {
      payloadMaterials.push({
        materialId: key,
        quantity: objectIngredientsSelected[key]?.quantity,
        unitCost: objectIngredientsSelected[key]?.costPerUnit,
      });
    });

    const listImage = uploadImageToppingRef?.current?.getImage();
    if (listImage && listImage?.length > 0) {
      image = listImage[0]?.data_url;
    }

    const payload = {
      name: formValue[FORM_ITEM_NAMES.NAME],
      description: formValue[FORM_ITEM_NAMES.DESCRIPTION],
      price: formValue[FORM_ITEM_NAMES.PRICE],
      taxId: formValue[FORM_ITEM_NAMES.TAX_ID],
      unitId: formValue[FORM_ITEM_NAMES.UNIT_ID],
      image: image,
      isPrintStamp: formValue[FORM_ITEM_NAMES.IS_PRINT_STAMP],
      materials: payloadMaterials,
      optionIds: [],
      isTopping: true,
      platformIds: platformSelected,
      productToppingIds: [],
      isIncludedAllToppings: false,
    };

    setRequestingAddTopping(true);
    try {
      const response = await productDataService.createProductAsync(payload);
      if (response?.succeeded) {
        message.success(pageData.topping.addSuccess);
        const newItemTopping = {
          name: formValue[FORM_ITEM_NAMES.NAME],
          id: response?.data?.id,
          thumbnail: image,
        };
        if (setToppings) {
          let newToppings = toppings ? [...toppings] : [];
          newToppings = [newItemTopping, ...newToppings];
          setToppings(newToppings);
        }

        handleAddSuccess && handleAddSuccess(newItemTopping);
        setDefaultDataModal();
        setVisible(false);
        setImage(null);
      } else {
        message.error(pageData.topping.addError);
      }
    } catch {}
    setRequestingAddTopping(false);
  };

  const onChangeImage = (file) => {
    setImage(file);
  };

  return (
    <FnbModalAddNewToppingStyled
      visible={visible}
      setVisible
      title={pageData.modal.title}
      onOk={onOkModalTopping}
      loadingOk={requestingAddTopping}
      disabledBtnOk={requestingAddTopping}
      handleCancel={onCancelModalTopping}
      okText={pageData.modal.createTopping}
      width={1200}
      displayNoneModal={showModalCreateTax}
      content={
        <>
          <Form form={form} className="custom-form" layout="vertical" onFinish={onFinishForm}>
            <Row gutter={[24, 16]}>
              {/* Left column */}
              <Col xs={24} md={17}>
                <Row gutter={[16, 28]}>
                  <Col span={24}>
                    <Form.Item
                      label={pageData.topping.title}
                      name={FORM_ITEM_NAMES.NAME}
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.topping.validateMessage}
                            />
                          ),
                        },
                      ]}
                    >
                      <FnbInput showCount placeholder={pageData.topping.placeholder} maxLength={100} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={pageData.description.title} name={FORM_ITEM_NAMES.DESCRIPTION}>
                      <FnbTextArea
                        placeholder={pageData.description.placeholder}
                        showCount
                        maxLength={255}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        rows={5}
                        style={{ minHeight: 128 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={pageData.pricing.price}
                      name={FORM_ITEM_NAMES.PRICE}
                      required
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.pricing.validateMessage}
                            />
                          ),
                        },
                        {
                          pattern: new RegExp(inputNumberRange1To999999999.range),
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.pricing.validateMessage}
                            />
                          ),
                        },
                      ]}
                    >
                      <FnbInputNumber
                        className="w-100"
                        placeholder={pageData.pricing.placeholder}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        addonAfter={currencyCode}
                        precision={currencyCode === currency.vnd ? 0 : 2}
                        id="product-price"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={pageData.tax.title} name={FORM_ITEM_NAMES.TAX_ID}>
                      <FnbSelectAddNewItem
                        showSearch
                        allowClear
                        placeholder={pageData.tax.placeholder}
                        options={taxes?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onSearch={(e) => setSearchValueTax(e)}
                        onAddNewItem={addNewItemTax}
                        fieldItemName={pageData.tax.title?.toLowerCase()}
                        isShowSearchIcon
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={pageData.unit.title}
                      name={FORM_ITEM_NAMES.UNIT_ID}
                      required
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.unit.validateMessage}
                            />
                          ),
                        },
                      ]}
                    >
                      <FnbSelectAddNewItem
                        showSearch
                        allowClear
                        placeholder={pageData.unit.placeholder}
                        options={units?.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                        onSearch={(e) => setSearchValueUnit(e)}
                        onAddNewItem={addNewItemUnit}
                        fieldItemName={pageData.unit.unit?.toLowerCase()}
                        isShowSearchIcon
                      />
                    </Form.Item>
                  </Col>
                  {!isMaxWidthScreenMedium && <Col span={24}>{<IngredientFormItem />}</Col>}
                </Row>
              </Col>
              {/* Right column */}
              <Col xs={24} md={7}>
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <h4 className="title-image">{pageData.uploadImage.label}</h4>
                    <Row className={`non-image ${image !== null ? "have-image" : ""}`}>
                      <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
                        <Form.Item name={FORM_ITEM_NAMES.IMAGE}>
                          <FnbCustomUploadImageComponent
                            ref={uploadImageToppingRef}
                            iconUpload={<UploadLogoIcon />}
                            buttonText={pageData.uploadImage.title}
                            onChange={onChangeImage}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={FORM_ITEM_NAMES.PLATFORM_IDS}
                      valuePropName="checked"
                      className="form-item-platforms-modal-add-new-topping"
                    >
                      {platforms?.map((platform) => {
                        return (
                          <div className="checkbox-platform-container">
                            <FnbCheckBox
                              value={platform.id}
                              checked={platformSelected.includes(platform.id)}
                              onChange={(e) => {
                                onChangePlatforms(e.target.checked, platform.id);
                              }}
                              className="checkbox-platform"
                            >
                              <WrapPlatform>
                                <FnbTypography variant={theme.typography["b2-regular"]} text={platform?.name} />
                              </WrapPlatform>
                            </FnbCheckBox>
                            <PlatformPreview className="checkbox-platform-preview" platformId={platform?.id} />
                          </div>
                        );
                      })}
                    </Form.Item>
                  </Col>
                  <ColPrintSticker span={24}>
                    <Form.Item name={FORM_ITEM_NAMES.IS_PRINT_STAMP}>
                      <FnbSwitch defaultChecked={true} />
                    </Form.Item>
                    <FnbTypography text={pageData.otherSetting.printSticker} />
                    <FnbTooltip
                      placement="bottomRight"
                      maxWidth="392px"
                      variant="secondary"
                      title={<StampPreview stampConfigs={stampConfigs} stampData={stampData} />}
                    >
                      <CustomInfoCircleIcon />
                    </FnbTooltip>
                  </ColPrintSticker>
                </Row>
              </Col>
              {isMaxWidthScreenMedium && <Col span={24}>{<IngredientFormItem />}</Col>}
              {dataSourceTableIngredient?.length > 0 && (
                <Col span={24}>
                  <TableListIngredients
                    dataSource={dataSourceTableIngredient}
                    columns={tableIngredientSettings.columns}
                    pageSize={tableIngredientSettings.pageSize}
                  />
                  <InfoTotalCostTable>
                    <FnbTypography
                      text={pageData.recipe.table.infoTotalCost + ":"}
                      variant={theme.typography["b1-regular"]}
                      color={theme.colors.gray[70]}
                    />
                    <FnbTypography text={`${totalCostTable} ${currencyCode}`} variant={theme.typography["b1-medium"]} />
                  </InfoTotalCostTable>
                </Col>
              )}
            </Row>
          </Form>
          <CreateNewTax
            t={t}
            isModalVisible={showModalCreateTax}
            name={searchValueTax}
            handleCompleted={handleCompletedTaxModal}
            handleCancel={handleCancelTaxModal}
            width={800}
            percentageField={{
              prefix: null,
              suffix: "%",
            }}
            typeField={{
              onlyShowPrefixType: true,
              typeId: taxType.SellingTax,
            }}
            buttonConfirm={{
              label: pageData.modalAddTax.createTax,
              width: "50%",
              textTransform: "capitalize",
            }}
            buttonCancel={{
              width: "50%",
            }}
            titleCustom={
              <TitleCustomModalAddTax>
                <FnbButton
                  iconHeader={<ArrowLeftOutlinedIcon />}
                  variant="tertiary"
                  text={pageData.modalAddTax.backToAddTopping}
                  onClick={() => setShowModalCreateTax(false)}
                />
                <FnbTypography
                  text={pageData.modalAddTax.titleHeader}
                  variant={theme.typography["h1-bold"]}
                  textTransform="capitalize"
                />
              </TitleCustomModalAddTax>
            }
          />
        </>
      }
    />
  );
};

export default FnbModalCreateTopping;
