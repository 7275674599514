export const IngredientRemoveReasonType = {
  OUT_OF_DATE: "0",
  DAMAGED: "1",
  OTHERS: "2",
};

export const IngredientRemoveStatus = {
  WAITING_TO_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
};
