import { Row } from "antd";
import { tableSettings } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import comboDataService from "data-services/combo/combo-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ListComboComponent from "./components/list-combo.component";
import "./index.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

export default function CompoPage(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [listCombo, setListCombo] = useState([]);
  const [totalCombos, setTotalCombos] = useState(0);
  const [comboParams, setComboParams] = useState({
    pageNumber: tableSettings.page,
    pageSize: tableSettings.pageSize,
  });

  const createNewPage = "/combo/create-new";
  const pageData = {
    btnAddNew: t("button.addNew"),
    title: t("combo.title"),
  };

  useEffect(() => {
    initDataTableCombos(comboParams);
  }, []);

  const initDataTableCombos = async (params) => {
    await comboDataService.getCombosAsync(params).then((res) => {
      let combos = mappingToDataTableCombos(res.combos);
      setTotalCombos(res.total);
      setListCombo(combos ?? []);
    });
  };

  const mappingToDataTableCombos = (combos) => {
    return combos?.map((i, index) => {
      return {
        id: i.id,
        no: index + 1,
        name: i.name,
        totalBranch: i.isShowAllBranches ? i.branch?.length : i.comboStoreBranches?.length,
        comboTypeId: i.comboTypeId,
        price: i.sellingPrice,
        product: i.comboProductPrices,
        comboPricings: i.comboPricings,
        startDate: i.startDate,
        endDate: i.endDate,
        statusId: i.statusId,
        isStopped: i.isStopped,
        thumbnail: i.thumbnail,
        comboPriceTypeId: i.comboPriceTypeId,
      };
    });
  };

  const onChangeParams = async (params) => {
    setComboParams(params);
    initDataTableCombos(params);
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.title}
        permissionCreate={PermissionKeys.CREATE_COMBO}
        onCreate={() => history.push(createNewPage)}
      ></FnbHeadingPage>
      <Row>
        <ListComboComponent
          listCombo={listCombo}
          totalCombos={totalCombos}
          comboParams={comboParams}
          onChangeParams={onChangeParams}
          comboDataService={comboDataService}
        />
      </Row>
    </>
  );
}
