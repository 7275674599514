export const OrderType = {
  INSTORE: 0,
  DELIVERY: 1,
  TAKE_AWAY: 2,
  ONLINE_DELIVERY: 3,
  PICK_UP: 4, 
};

export const OrderTypeSymbol = {
  INSTORE: "I",
  DELIVERY: "D",
  ONLINE_DELIVERY: "O",
  PICK_UP: "P",
  OFFLINE: "OFF"
};