import { message, Row } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { images } from "constants/images.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { Platform as platform } from "constants/platform.constants";
import { PreventReasonType } from "constants/preventReasonType.constants";
import branchDataService from "data-services/branch/branch-data.service";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import productDataService from "data-services/product/product-data.service";
import storeDataService from "data-services/store/store-data.service";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import theme from "theme";
import {
  executeAfter,
  formatNumberDecimalOrInteger,
  getCurrency,
  hasPermission,
  isJsonString
} from "utils/helpers";
import { getStorage, localStorageKeys, setStorage } from "utils/localStorage.helpers";
import DeleteProductComponent from "./delete-product.component";
import FilterProduct from "./filter-product.component";
import "./table-product.component.scss";

export default function TableProduct(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [productCategories, setProductCategories] = useState([]);
  const [branches, setBranches] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [preventDeleteProduct, setPreventDeleteProduct] = useState({});
  const [titleModal, setTitleModal] = useState();
  const [salesChannel, setSalesChannel] = useState([]);
  const [dataFilter, setDataFilter] = useState(null);
  const [keySearch, setKeySearch] = useState("");
  const filterPopoverRef = useRef();

  const pageData = {
    btnFilter: t("button.filter"),
    btnSort: t("button.sort"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    productDeleteSuccess: t("productManagement.productDeleteSuccess"),
    productDeleteFail: t("productManagement.productDeleteFail"),
    table: {
      searchPlaceholder: t("productManagement.table.searchPlaceholder"),
      productName: t("productManagement.table.productName"),
      variation: t("productManagement.table.variation"),
      price: t("productManagement.table.price"),
      displayPlatform: t("productManagement.table.displayPlatform"),
      status: t("productManagement.table.status"),
      action: t("productManagement.table.action"),
    },
    notificationTitle: t("form.notificationTitle"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    btnMenuCreateProduct: t("productManagement.btnMenuCreateProduct"),
    btnMenuCreateTopping: t("productManagement.btnMenuCreateTopping"),
    toCreateData: t("area.toCreateData"),
    or: t("area.or"),
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        key: "name",
        title: pageData.table.productName,
        dataIndex: "name",
        ellipsis: true,
        sorter: (pre, current) => pre?.name.localeCompare(current?.name),
        render: (value, record) => {
          return (
            <div className="column-product-name">
              <img src={record?.thumbnail || images.imgDefault} alt={value} />
              <FnbTooltip title={value} onlyShowWhenEllipsis maxWidthContent="95%">
                <span>{value}</span>
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        key: "price",
        title: `${pageData.table.price} (${getCurrency()}) - ${pageData.table.variation}`,
        dataIndex: "price",
        ellipsis: true,
        width: "45%",
        render: (value) => {
          return <MemoizedFnbListChips data={value?.map((variation) => ({ title: variation, detail: variation }))} />;
        },
      },
      {
        key: "status",
        title: pageData.table.status,
        dataIndex: "status",
        width: "9rem",
        align: "center",
        render: (value) => {
          const isActive = value?.id === 1;
          return (
            <div className="column-product-status">
              <FnbBadge
                variant={isActive ? "success" : "error"}
                text={isActive ? pageData.active : pageData.inactive}
              />
            </div>
          );
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await handleFilterProduct(dataFilter, page, pageSize, keySearch);
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        setKeySearch(keySearch);
        await handleFilterProduct(dataFilter, 1, tableSettings.pageSize, keySearch);
      });
    },
  };

  const onRowClick = (record) => {
    history.push(`/product/details/${record?.id}`);
  };

  const onDeleteItem = (_selectedRowKeys, selectedRows) => {
    const { id: productId, name: productName } = selectedRows?.[0];

    productDataService.getAllOrderNotCompletedByProductIdAsync(productId).then((res) => {
      const { preventDeleteProduct } = res;
      // Set property for object
      Object.assign(preventDeleteProduct, { productName: productName });

      setPreventDeleteProduct(preventDeleteProduct);
      if (!preventDeleteProduct?.isPreventDelete) {
        setTitleModal(pageData.confirmDelete);
      } else {
        setTitleModal(pageData.notificationTitle);
      }
      setIsModalVisible(true);
    });
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
    setPreventDeleteProduct({});
  };

  const handleDeleteItem = async (productId) => {
    const res = await productDataService.deleteProductByIdAsync(productId);
    if (res) {
      message.success(pageData.productDeleteSuccess);

      // Recount selected items after delete success
      setSelectedRowKeys([]);
      handleFilterProduct(dataFilter, 1, tableSettings.pageSize, keySearch);
    } else {
      message.error(pageData.productDeleteFail);
    }
    setIsModalVisible(false);
  };

  const onEditItem = (_selectedRowKeys, selectedRows) => {
    const { id: productId, name: productName } = selectedRows?.[0];

    productDataService.getAllOrderNotCompletedByProductIdAsync(productId).then((res) => {
      const { preventDeleteProduct } = res;

      // Set property for object
      Object.assign(preventDeleteProduct, {
        productName: productName,
        reasonType:
          preventDeleteProduct?.reasonType === PreventReasonType.DeleteProductComboRelate
            ? PreventReasonType.EditProductComboRelate
            : preventDeleteProduct?.reasonType,
      }); //reasonType 3 for edit

      setPreventDeleteProduct(preventDeleteProduct);
      if (!preventDeleteProduct?.isPreventDelete) {
        return history.push(`/product/edit/${productId}`);
      } else {
        setTitleModal(pageData.notificationTitle);
      }
      setIsModalVisible(true);
    });
  };

  useEffect(() => {
    const sessionProductFilter = getStorage(localStorageKeys.PRODUCT_FILTER);
    if (isJsonString(sessionProductFilter)) {
      const productFilter = JSON.parse(sessionProductFilter);
      if (productFilter && productFilter.count > 0) {
        const data = {
          branchId: productFilter.branchId,
          productCategoryId: productFilter.productCategoryId,
          statusId: productFilter.statusId,
          count: productFilter.count,
          salesChannel: productFilter.salesChannel,
        };
        setDataFilter(data);
        handleFilterProduct(data, currentPageNumber, tableSettings.pageSize, "");
      } else {
        handleFilterProduct(null, currentPageNumber, tableSettings.pageSize, "");
      }
    } else {
      handleFilterProduct(null, currentPageNumber, tableSettings.pageSize, "");
    }
    initialFilterData();
  }, []);

  const getProductVariation = (productPrices) => {
    return productPrices?.map((productPrice) => {
      const { priceValue, priceName } = productPrice;
      return priceName ? `${formatNumberDecimalOrInteger(priceValue)} - ${priceName}` : formatNumberDecimalOrInteger(priceValue);
    });
  };

  const mappingRecordToColumns = (item) => {
    return {
      key: item?.id,
      id: item?.id,
      name: item?.name,
      thumbnail: item?.thumbnail,
      prices: item?.prices,
      price: getProductVariation(item?.productPrices),
      channels: item?.channels,
      channel: item?.channels?.map((c) => (
        <>
          {c.name}
          <br></br>
        </>
      )),
      platforms: item?.platforms?.map((platform) => platform?.name),
      status: item?.status,
    };
  };

  const initialFilterData = async () => {
    const resCategory = await productCategoryDataService.getAllProductCategoriesAsync();
    if (resCategory) {
      const categoryOptions = resCategory.allProductCategories;
      setProductCategories(categoryOptions);
    }

    const resBranch = await branchDataService.getAllBranchsAsync();
    if (resBranch) {
      const branchOptions = resBranch.branchs;
      setBranches(branchOptions);
    }

    let resSalesChannel = await storeDataService.getAllPlatformActivatedAsync();
    resSalesChannel.platforms = resSalesChannel.platforms.filter(
      (item) => item.id.toLowerCase() !== platform.AdminWebsite.toLowerCase(),
    );
    if (resSalesChannel) {
      const salesChannels = resSalesChannel.platforms;
      setSalesChannel(salesChannels);
    }
  };

  const handleFilterProduct = async (data, pageNumber, pageSize, keySearch) => {
    const response = await productDataService.getProductsByFilterAsync(
      pageNumber,
      pageSize,
      keySearch,
      data?.branchId ?? "",
      data?.productCategoryId ?? "",
      data?.statusId ?? "",
      data?.salesChannel ?? "",
    );

    const products = response?.products.map((record) => mappingRecordToColumns(record));
    setDataSource(products);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
    setCountFilter(data?.count);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
    setStorage(localStorageKeys.PRODUCT_FILTER, null);
    setCountFilter(0);
    handleFilterProduct(null, 1, tableSettings.pageSize, keySearch);
    setDataFilter(null);
  };

  const filterComponent = () => {
    return (
      <FilterProduct
        ref={filterPopoverRef}
        fetchDataProducts={handleFilterProduct}
        categories={productCategories}
        branches={branches}
        salesChannel={salesChannel}
        pageSize={tableSettings.pageSize}
        keySearch={keySearch}
        setDataFilter={setDataFilter}
      />
    );
  };

  return (
    <>
      <Row className="form-staff">
        <FnbTable
          rowKey="id"
          className="table-striped-rows table-product-management"
          columns={tableSettings.columns}
          pageSize={tableSettings.pageSize}
          dataSource={dataSource}
          currentPageNumber={currentPageNumber}
          total={totalRecords}
          onChangePage={tableSettings.onChangePage}
          editPermission={PermissionKeys.EDIT_PRODUCT}
          deletePermission={PermissionKeys.DELETE_PRODUCT}
          scrollX={1200}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditItem,
            onDelete: onDeleteItem,
          }}
          search={{
            maxLength: 100,
            placeholder: pageData.table.searchPlaceholder,
            onChange: tableSettings.onSearch,
          }}
          filter={{
            totalFilterSelected: countFilter,
            onClearFilter: onClearFilter,
            buttonTitle: pageData.btnFilter,
            component: filterComponent(),
          }}
          onRowClick={hasPermission(PermissionKeys.VIEW_PRODUCT) && onRowClick}
          emptyText={
            hasPermission(PermissionKeys.CREATE_PRODUCT) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.btnMenuCreateProduct}
                  onClick={() => history.push("/product/create-new")}
                />
                {pageData.or}
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.btnMenuCreateTopping}
                  onClick={() => history.push("/product/create-new", { isTopping: true })}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
        <DeleteProductComponent
          isModalVisible={isModalVisible}
          preventDeleteProduct={preventDeleteProduct}
          titleModal={titleModal}
          handleCancel={() => onCloseModal()}
          onDelete={handleDeleteItem}
        />
      </Row>
    </>
  );
}
