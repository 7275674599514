import "moment/locale/vi";
import "./mobile-tab.component.scss";

import { Card, Row, message } from "antd";
import { DeployOnAndroidIcon, DeployOnIosIcon } from "constants/icons.constants";
import { useTranslation } from "react-i18next";

import DownloadBox from "../../../../../components/download-box/download-box.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { useEffect, useState } from "react";
import ModalBuildApplication from "./modal-build-application";
import { devicePlatformType } from "constants/platform.constants";
import adminSocket from "sockets/admin-socket";
import { SignalRListenerConstants } from "constants/signalR-listener.constants";
import { getUserInfo } from "services/auth.service";
import onlineStoreDataService from "data-services/online-store/online-store-data.service";
import { buildStoreAppType } from "constants/build-store-app.constants";

export default function OnlineStoreMobileTab(props) {
  const [t] = useTranslation();
  const [statusBuildAndroid, setStatusBuildAndroid] = useState(buildStoreAppType.default);
  // eslint-disable-next-line no-unused-vars
  const [statusBuildIOS, setStatusBuildIOS] = useState(buildStoreAppType.default);
  const [showModalBuildAndroid, setShowModalBuildAndroid] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const [storeAppInfo, setStoreAppInfo] = useState(null);
  const [isAlreadyUpdatePackageId, setIsAlreadyUpdatePackageId] = useState(false);
  const [googleMapConfig, setGoogleMapConfig] = useState({
    androidAppAPIKey: null,
    iosAppAPIKey: null,
  });
  const loggedUserInfo = getUserInfo();

  const pageData = {
    title: t("onlineStore.title"),
    selectTheme: t("onlineStore.selectTheme"),
    notSelectTheme: t("onlineStore.notSelectTheme"),
    theme: t("onlineStore.theme"),
    themeStore: t("onlineStore.themeStore"),
    currentTheme: t("onlineStore.currentTheme"),
    themeDetail: t("onlineStore.themeDetail"),
    apply: t("onlineStore.apply"),
    preview: t("onlineStore.preview"),
    includesSupport: t("onlineStore.includesSupport"),
    add: t("button.apply"),
    addThemeSuccess: t("onlineStore.addThemeSuccess"),
    customize: t("onlineStore.myTheme.button.customize"),
    lastSave: t("onlineStore.myTheme.lastSave"),
    publishFailMessage: t("theme.publish.failMessage", "Publish website failed, please try again after few minutes!"),
    mobileAppTitle: t("onlineStore.mobileApp.title"),
    deployYourApplication: t("onlineStore.mobileApp.deployYourApplication"),
    downloadYourStoreMobileApplicationFile: t("onlineStore.mobileApp.downloadYourStoreMobileApplicationFile"),
    uploadTheIPAAABToYourGooglePlayAndAppStore: t("onlineStore.mobileApp.uploadTheIPAAABToYourGooglePlayAndAppStore"),
    loremIpsumIsJustADummyText: t("onlineStore.mobileApp.loremIpsumIsJustADummyText"),
    waitingForTheReview: t("onlineStore.mobileApp.waitingForTheReview"),
    enjoyYourAppOnGooglePlayAndAppStore: t("onlineStore.mobileApp.enjoyYourAppOnGooglePlayAndAppStore"),
    deployOnAndroid: t("onlineStore.mobileApp.deployOnAndroid"),
    deployOnIOS: t("onlineStore.mobileApp.deployOnIOS"),
    downloadtheAABFileOfYourAppAndUploadToGooglePlay: t(
      "onlineStore.mobileApp.downloadtheAABFileOfYourAppAndUploadToGooglePlay",
    ),
    downloadTheIPAFileOfYourAppAndUploadToAppStore: t(
      "onlineStore.mobileApp.downloadTheIPAFileOfYourAppAndUploadToAppStore",
    ),
    downloadAAB: t("onlineStore.mobileApp.downloadAAB"),
    downloadIPA: t("onlineStore.mobileApp.downloadIPA"),
    buildAAB: t("onlineStore.mobileApp.buildAAB", "Build AAB"),
    buildAABFile: t("onlineStore.mobileApp.buildAABFile"),
    buildIPA: t("onlineStore.mobileApp.buildIPA", "Build IPA"),
    commingSoon: t("onlineStore.mobileApp.commingSoon"),
    build: {
      success: t("onlineStore.mobileApp.build.buildSuccess"),
      failure: t("onlineStore.mobileApp.build.buildFailed"),
    },
  };

  const onClickBuildAndroid = () => {
    setShowModalBuildAndroid(true);
  };

  const onDownloadAndroid = () => {
    window.open(downloadLink?.androidFileUrl, "_blank");
  };

  // eslint-disable-next-line no-unused-vars
  const onClickButtonIOS = () => {
    /*
      IOS ComingSoon
      if (statusBuildIOS === STATUS_BUILD.IDLE) {
      setShowModalBuildIOS(true);
      }
      if (statusBuildIOS === STATUS_BUILD.SUCCESS) {
        window.open(downloadLink?.iosFileUrl, "_blank");
      }
    */
  };

  useEffect(() => {
    getLinkDownloadApp();
    getInfoStoreApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBuildAndroid = () => {
    setStatusBuildAndroid(buildStoreAppType.requesting);
    getInfoStoreApp();
  };

  // eslint-disable-next-line no-unused-vars
  const onBuildIOS = () => {
    setStatusBuildIOS(buildStoreAppType.requesting);
  };

  const getLinkDownloadApp = async () => {
    const response = await onlineStoreDataService.getAppDownloadLinkAsync();
    if (response) {
      setDownloadLink(response);
    }
  };

  const getInfoStoreApp = async () => {
    const response = await onlineStoreDataService.getStoreAppInfoAsync(loggedUserInfo?.storeId);
    if (response?.succeeded) {
      setStoreAppInfo(response?.data);
      setGoogleMapConfig(response?.data?.googleMapConfig);
      setStatusBuildAndroid(response?.data?.statusBuildAndroid);
      setIsAlreadyUpdatePackageId(response?.data?.packageId ? true : false);
    }
  };

  useEffect(() => {
    initSocket();
    return () => {
      cleanSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initSocket = async () => {
    try {
      if (loggedUserInfo) {
        const userInfo = {
          storeId: loggedUserInfo?.storeId,
        };
        adminSocket.on(SignalRListenerConstants.SIGNAL_UPDATE_STATUS_BUILD_STORE_APP, (response) => {
          const { devicePlatform, isSuccess } = response;
          if (isSuccess) {
            getLinkDownloadApp();
          }

          if (devicePlatform === devicePlatformType.Android) {
            if (isSuccess) {
              setStatusBuildAndroid(buildStoreAppType.success);
              getInfoStoreApp();
              message.success(pageData.build.success);
            } else {
              setStatusBuildAndroid(buildStoreAppType.failure);
              message.error(pageData.build.failure);
            }
          }
          if (devicePlatform === devicePlatformType.IOS) {
            if (isSuccess) {
              setStatusBuildIOS(buildStoreAppType.success);
              message.success(pageData.build.success);
            } else {
              setStatusBuildIOS(buildStoreAppType.failure);
              message.error(pageData.build.failure);
            }
          }
        });

        adminSocket.on(SignalRListenerConstants.SIGNAL_REFRESH_STEP_GOOGLE_MAP_KEY_BUILD_STORE_APP, (response) => {
          setGoogleMapConfig({
            ...googleMapConfig,
            androidAppAPIKey: response?.androidAppAPIKey,
          });
        });

        await adminSocket.start();
        await adminSocket.invoke("JoinRoom", userInfo);
      }
    } catch {}
  };

  const cleanSocket = () => {
    try {
      adminSocket.off(SignalRListenerConstants.SIGNAL_UPDATE_STATUS_BUILD_STORE_APP);
      adminSocket.off(SignalRListenerConstants.SIGNAL_REFRESH_STEP_GOOGLE_MAP_KEY_BUILD_STORE_APP);
    } catch (err) {
      message.error(err);
    }
  };

  const setStatusBuildApp = (devicePlatform, status /* = buildStoreAppType */) => {
    if (devicePlatform === devicePlatformType.Android) {
      setStatusBuildAndroid(status);
    }
  };

  return (
    <div className="online-store-management-mobile-tab">
      <Card className="mobile-tab-card">
        <Row style={{ marginBottom: "20px" }}>
          <FnbTypography
            variant={theme.typography["h2-bold"]}
            color={theme.colors.primary[100]}
            text={pageData.deployYourApplication}
            textTransform="capitalize"
          />
        </Row>
        <Row>
          <ol className="content">
            <li>{pageData.downloadYourStoreMobileApplicationFile}</li>
            <li>{pageData.uploadTheIPAAABToYourGooglePlayAndAppStore}</li>
            <li>{pageData.waitingForTheReview}</li>
            <li>{pageData.enjoyYourAppOnGooglePlayAndAppStore}</li>
          </ol>
        </Row>
        <div className="deployment-environment-row">
          <DownloadBox
            icon={<DeployOnAndroidIcon />}
            title={pageData.deployOnAndroid}
            content={pageData.downloadtheAABFileOfYourAppAndUploadToGooglePlay}
            button={{
              text: pageData.buildAAB,
              onClick: onClickBuildAndroid,
              loading: statusBuildAndroid === buildStoreAppType.requesting,
            }}
            button2nd={{
              text: downloadLink?.androidFileUrl?.length > 0 ? pageData.downloadAAB : "",
              onClick: onDownloadAndroid,
            }}
          />
          <DownloadBox
            icon={<DeployOnIosIcon />}
            title={pageData.deployOnIOS}
            content={pageData.downloadTheIPAFileOfYourAppAndUploadToAppStore}
            button={{
              text: pageData.commingSoon,
              disabled: true,
            }}
          />
        </div>
      </Card>
      <ModalBuildApplication
        buttonBuild={{
          text: pageData.buildAABFile,
        }}
        showModalBuild={showModalBuildAndroid}
        setShowModalBuild={setShowModalBuildAndroid}
        devicePlatform={devicePlatformType.Android}
        onBuild={onBuildAndroid}
        storeId={loggedUserInfo?.storeId}
        t={t}
        storeAppInfo={storeAppInfo}
        isBuildingApp={statusBuildAndroid === buildStoreAppType.requesting}
        setStatusBuildApp={setStatusBuildApp}
        isAlreadyUpdatePackageId={isAlreadyUpdatePackageId}
        googleMapConfig={googleMapConfig}
      />
      {/* IOS Coming soon 
        <ModalBuildApplication
          buttonBuild={{
            text: pageData.buildIPA,
          }}
          showModalBuild={showModalBuildIOS}
          setShowModalBuild={setShowModalBuildIOS}
          devicePlatform={devicePlatformType.IOS}
          onBuild={onBuildIOS}
          storeId={loggedUserInfo?.storeId}
          t={t}
      />
      */}
    </div>
  );
}
