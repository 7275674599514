import { Card } from "antd";
import "./SelectLanguage.scss";
import { ArrowRightIcon } from "../../assets/icons.constants";

/**
 * Converts the input string to lower case and then capitalizes the first character.
 *
 * @param {string} input - The string to be converted. Defaults to an empty string.
 * @returns {string} - The formatted string with the first character capitalized.
 */
const upperCaseString = (input = "") => {
  // Check if the input is non-empty
  if (input) {
    // Convert the entire string to lowercase
    const value = input.toLowerCase();
    // Capitalize the first character and append the rest of the string
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  // Return an empty string if the input is empty
  return "";
};

const SelectLanguage = (props) => {
  const { onClick, languages, currentLang, isMobileMode, className } = props;
  if (isMobileMode) {
    return (
      <Card onClick={onClick} className={`select-language ${className}`}>
        <span className="lang-flag">{currentLang?.flag}</span>
        <span className="lang-name">{upperCaseString(currentLang?.languageCode)}</span>
        <span className="change-icon">
          <ArrowRightIcon />
        </span>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default SelectLanguage;
