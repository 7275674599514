import { Checkbox } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    box-shadow: none;
    border-color: ${(props) => props.theme.colors.text.timberwolf};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d8dae1;
    border-radius: 12px;
    box-shadow: none !important;

    &:hover {
      border: 1px solid #50429b;
    }
  }

  .ant-select.ant-select-focused .ant-select-selector {
    border: 1px solid #50429b !important;
  }

  .ant-select-multiple .ant-select-selector {
    height: 44px;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: ${(props) => (props?.hiddenSelectAll ? props.theme.colors.text.placeholder : props.theme.colors.text.main)};
  }

  .ant-select-multiple .ant-select-selection-search-input,
  .ant-select-multiple .ant-select-selection-search-mirror {
    height: 38px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.colors.text.main};
  }

  .ant-select-multiple .ant-select-selection-item {
    margin: 0;
    padding: 0 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: 32px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;

    .ant-select-selection-item-content {
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: ${(props) => props.theme.colors.text.main};
    }

    .ant-select-selection-item-remove {
      display: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primary.light};
      border: 1px solid ${(props) => props.theme.colors.primary.light};

      .ant-select-selection-item-content {
        &::after {
          display: none;
        }
      }

      .ant-select-selection-item-remove {
        display: ${(props) => (props?.selectedAll ? "none" : "initial")};
        color: ${(props) => props.theme.colors.primary.main};

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .ant-select-selection-overflow {
    max-width: 100%;

    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-rest):not(
        .ant-select-selection-overflow-item-suffix
      ):not(:last-child) {
      max-width: 80%;

      &:hover {
        .ant-select-selection-item-content {
          &::after {
            display: none;
          }
        }
      }

      .ant-select-selection-item-content {
        &::after {
          content: ",";
          display: ${(props) => (props?.selectedAll ? "none" : "initial")};
        }
      }
    }
  }

  .ant-select-multiple {
    .ant-select-arrow {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      transform: translate(25%, -25%);
      color: ${(props) => props.theme.colors.primary.main};
    }

    .ant-select-clear {
      display: ${(props) => (props?.selectedAll ? "none" : "initial")};
    }
  }

  .ant-select-multiple.ant-select-allow-clear {
    &:hover {
      .ant-select-arrow {
        visibility: ${(props) => (props?.selectedAll ? "initial" : "hidden")};
      }
    }
  }
`;

export const Menus = styled.div`
  margin: 4px 8px;
  background-color: ${(props) => props.theme.colors.common.white};

  .ant-select-item-option:not(.ant-select-item-option-grouped) {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    margin: 0;
    border-radius: 12px;

    &:hover {
      background-color: ${(props) => props.theme.colors.background.fog} !important;
    }
  }

  .ant-select-item.ant-select-item-group {
    padding: 8px;
    margin: 0;
    border-radius: 12px;

    &:has(.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item) {
      padding: 0;

      &:hover {
        background-color: ${(props) => props.theme.colors.background.fog};
      }
    }
  }

  .ant-select-item-option.ant-select-item-option-grouped {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    margin-left: calc(40px + 16px);
    margin-right: 0;
    border-radius: 12px;
    border-bottom: 4px solid ${(props) => props.theme.colors.common.white};
    background-color: ${(props) => props.theme.colors.primary.light} !important;

    &:hover {
      background-color: ${(props) => props.theme.colors.background.fog} !important;
    }
  }

  .ant-select-item-option .ant-select-item-option-content {
    flex: 1;
    overflow: hidden;
  }

  .ant-select-item-option:not(.ant-select-item-option-selected) {
    &::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 6px;
      padding: 0 6px;
      background-color: ${(props) => props.theme.colors.common.white};
      border: 1px solid #8b8899;
    }
  }

  .ant-select-item-option:not(.ant-select-item-option-grouped):not(.ant-select-item-option-selected) {
    &::after {
      margin-right: 0;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${(props) => props.theme.colors.background.white};
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${(props) => props.theme.colors.background.white};

    .ant-select-item-option-content > div {
      font-weight: 500;
      color: #50429b;
    }

    .ant-select-item-option-state {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.text.white};
      background-color: #50429b;
      border: 1px solid #50429b;

      .anticon-check {
        font-size: 12px;
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-grouped):not(.ant-select-item-option-disabled) {
    .ant-select-item-option-content div {
      font-weight: 500;
      color: #50429b;
    }

    .ant-select-item-option-state {
      margin-right: 0;
    }
  }

  .ant-select-item {
    img {
      object-fit: contain;
    }
  }
`;

export const TooltipContent = styled.ul`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
`;

export const NoDataFounded = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
  }

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.text.placeholder};
  }
`;

export const GroupVariants = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #2f2d39;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 576px) {
    img {
      width: 30px;
      height: 30px;
      border-radius: 8px;
    }
  }
`;

export const ProductGroupOtions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .product-name {
    flex-grow: 1;
    width: 50%;
  }

  .product-unit {
    width: 50%;
    flex-grow: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 12px;

    > div {
      max-width: 50%;
    }
  }
`;

export const ProductOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }

  div {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #2F2D39;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .product-name {
    flex-grow: 1;
    width: 50%;
  }

  .product-unit {
    width: 50%;
    flex-grow: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 12px;

    > div {
      max-width: 50%;
    }
  }

  @media (max-width: 576px) {
    img {
      width: 30px;
      height: 30px;
      border-radius: 8px;
    }
  }
`;

export const AllOption = styled(Checkbox)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 16px 8px;
  border-radius: 12px;

  span.ant-checkbox {
    input.ant-checkbox-input {
      width: 20px;
      height: 20px;
    }

    span.ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors.common.white};
      border: 1px solid #8b8899;
    }

    + span {
      flex-grow: 1;
      padding-left: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #2f2d39;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  span.ant-checkbox.ant-checkbox-checked {
    &::after {
      border-color: transparent;
    }

    span.ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      background-color: #50429b;
      border: 1px solid #50429b;

      &::after {
        position: absolute;
        border: 1px solid #fff;
        width: 5px;
        height: 10px;
        border-top: 0;
        border-left: 0;
        transform: rotate(40deg) scale(1.1) translate(-35%, -65%);
      }
    }
  }
`;
