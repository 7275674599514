import { TickCircleIcon } from "constants/icons.constants";
import "./index.scss";
import { Radio } from "antd";

const FnbRadioButton = (props) => {
  const {
    value,
    isChecked,
    children,
    className = "",
    icon = <TickCircleIcon width={14} height={14} className="check-icon" />,
    onClick,
    defaultChecked = false,
    id,
    name,
  } = props;

  return (
    <Radio.Button
      id={id}
      onClick={onClick}
      value={value}
      name={name}
      className={`fnb-radio-button-admin ${className}`}
      checked={isChecked}
      defaultChecked={defaultChecked}
    >
      {icon}
      {children}
    </Radio.Button>
  );
};

export default FnbRadioButton;
