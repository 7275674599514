import { Col, Empty, Row } from "antd";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { useRef, useState } from "react";
import { AddCircleOutlined } from "constants/icons.constants";
import FormNewManagementCategoryComponent from "pages/inventory/material-category/components/form-new-material-category.component";
import materialDataService from "data-services/material/material-data.service";
import materialCategoryDataService from "data-services/material-category/material-category-data.service";
import "./general-Information-material.page.scss";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";

export const GeneralInformationMaterial = (props) => {
  const { listMaterialCategory, t, form, onChangeMaterialName, onFetch } = props;
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [showAddNewCategoryForm, setShowAddNewCategoryForm] = useState(false);
  const addComponentRef = useRef(null);

  const pageData = {
    generalInformation: t("material.generalInformation"),
    material: t("material.material"),
    name: t("material.name"),
    pleaseEnterMaterialName: t("material.pleaseEnterMaterialName"),
    ingredientCategoryNotFound: t("material.ingredientCategoryNotFound"),
    maxLengthMaterialName: 255,
    description: t("form.description"),
    maximum2000Characters: t("form.maximum2000Characters"),
    category: t("form.category"),
    selectMaterialCategory: t("form.selectMaterialCategory"),
    inventory: {
      title: t("material.inventory.title"),
      sku: t("material.inventory.sku"),
      skuPlaceholder: t("material.inventory.skuPlaceholder"),
      pleaseInputTextHere: t("material.inventory.pleaseInputTextHere"),
    },
  };

  const onCancelAddForm = () => {
    setOpen(false);
    setShowAddNewCategoryForm(false);
  };

  const onCompletedAddForm = (categoryId) => {
    setOpen(false);
    setShowAddNewCategoryForm(false);
    onFetch && onFetch(categoryId);
  };

  const handleAddCategory = () => {
    materialDataService.getAllMaterialManagementsAsync().then((res) => {
      if (res) {
        addComponentRef.current({
          listMaterial: res?.materials,
        });
        setShowAddNewCategoryForm(true);
      }
    });
  };

  const onSearch = (value) => {
    setKeyword(value);
  };

  const onFocus = () => {
    setOpen(true);
  };

  const onBlur = () => {
    if (!keyword || !showAddNewCategoryForm) {
      setOpen(false);
    }
  };

  return (
    <FnbCard title={pageData.generalInformation} underlineTitle>
      <Row gutter={[16, 0]}>
        <Col span={24} md={12}>
          <FnbFormItem
            label={pageData.name}
            name="name"
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseEnterMaterialName} />,
              },
              {
                type: "string",
                max: <InputValidateMessage message={pageData.maxLengthMaterialName} />,
              },
            ]}
            className="mb-28"
          >
            <FnbInput
              showCount
              maxLength={255}
              placeholder={pageData.pleaseEnterMaterialName}
              onChange={(e) => (onChangeMaterialName ? onChangeMaterialName(e.target.value) : {})}
            />
          </FnbFormItem>
        </Col>
        <Col span={24} md={12}>
          <FnbFormItem
            label={pageData.inventory.sku}
            name="sku"
            rules={[
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: <InputValidateMessage message={pageData.inventory.pleaseInputTextHere} />,
              },
            ]}
            className="mb-28"
          >
            <FnbInput maxLength={20} placeholder={pageData.inventory.skuPlaceholder} />
          </FnbFormItem>
        </Col>
        <Col span={24}>
          <FnbFormItem className="mb-28" name="materialCategoryId" label={pageData.category}>
            <FnbSelectSingle
              placeholder={pageData.selectMaterialCategory}
              option={listMaterialCategory?.map((item) => ({
                id: item?.id,
                name: item?.name,
              }))}
              noTranslateOptionName={true}
              notFoundContent={
                <>
                  {keyword ? (
                    <div className="keyword-suggestions">
                      <span>{pageData.ingredientCategoryNotFound}</span>
                      <div onClick={() => handleAddCategory(keyword)}>
                        <AddCircleOutlined />
                        <span>{t("material.addNewIngredientCategory", { categoryName: keyword })}</span>
                      </div>
                    </div>
                  ) : (
                    <FnbEmpty />
                  )}
                </>
              }
              open={open}
              onSearch={onSearch}
              onFocus={onFocus}
              onBlur={onBlur}
              showSearch
            />
          </FnbFormItem>
          <FnbFormItem name="description" label={pageData.description}>
            <FnbTextArea showCount maxLength={2000} rows={4} placeholder={pageData.maximum2000Characters} />
          </FnbFormItem>
        </Col>
      </Row>
      <FormNewManagementCategoryComponent
        t={t}
        materialDataService={materialDataService}
        materialCategoryDataService={materialCategoryDataService}
        showAddNewCategoryForm={showAddNewCategoryForm}
        onCancel={onCancelAddForm}
        onCompleted={onCompletedAddForm}
        func={addComponentRef}
        initialValues={{ name: keyword }}
      />
    </FnbCard>
  );
};
