export const EnumRegisterStep = {
  AccountInfo: 1,
  Verification: 2,
  StoreInfo: 3,
  Complete: 4,
};

export function getStepName(value) {
  for (let key in EnumRegisterStep) {
    if (EnumRegisterStep[key] === value) {
      return key;
    }
  }
  return null; // Return null if value is not found in the EnumRegisterStep object
}
