import { Card, Col, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { formatNumberDecimalOrInteger, getCurrency } from "utils/helpers";
import productImageDefault from "assets/images/product-img-default.png";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import "./top-selling-product.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { Link } from "react-router-dom";

export function TopSellingProductComponent(props) {
  const { dataSource, title, hideSeeMore, onScroll, handleOnRowClick } = props;
  const [t] = useTranslation();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const currency = getCurrency();
  const isMobileDevice = useMediaQuery({ maxWidth: 575 });

  const pageData = {
    noDataFound: t("table.noDataFound"),
    topSellingProductItems: t("dashboard.topSellingProduct.items"),
    topSellingProductSeemore: t("dashboard.topSellingProduct.seemore"),
  };

  const tableTopSellingProductSettings = {
    pageSize: 20,
    columns: [
      {
        align: "center",
        width: "2rem",
        className: "col-order",
        render: (value, record, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        align: "center",
        width: "6rem",
        className: "col-thumbnail",
        render: (value, { thumbnail }) => {
          return <Thumbnail src={thumbnail} imageDefault={productImageDefault} />;
        },
      },
      {
        align: "left",
        ellipsis: true,
        width: "70%",
        className: "col-name",
        render: (_, { priceName, productName, productId }) => {
          return (
            <Link to={`/product/details/${productId}`} target="_blank">
              <FnbTooltip title={productName} placement="top" onlyShowWhenEllipsis>
                <FnbTypography
                  text={productName}
                  color={theme.colors.default.black}
                  variant={theme.typography["b1-bold"]}
                />
              </FnbTooltip>
              <FnbTypography text={priceName} variant={theme.typography["b1-regular"]} color={theme.colors.gray[70]} />
            </Link>
          );
        },
      },
      {
        align: "right",
        width: "30%",
        className: "col-item",
        render: (value, { quantity, totalCost }) => {
          return (
            <div className="description">
              <span className="description__quantity">{`${quantity} ${pageData.topSellingProductItems}`}</span>
              <span className="description__price">{`${formatNumberDecimalOrInteger(totalCost)} ${currency}`}</span>
            </div>
          );
        },
      },
    ],
  };

  return (
    <Card
      className={
        isTabletOrMobile
          ? "fnb-box custom-box card-selling-product-thumbnail"
          : "fnb-box custom-box card-selling-product-thumbnail top-selling-product-card-component-width"
      }
    >
      <Row className="group-header-top-selling-product-box">
        <Col xs={18} sm={18} lg={18}>
          <p style={{ color: "#2B2162" }}>{title}</p>
        </Col>
        <Col xs={6} sm={6} lg={6} className="table-selling-product-see-more-text-align" hidden={hideSeeMore}>
          <p className="table-selling-product-see-more">{pageData.topSellingProductSeemore}</p>
        </Col>
      </Row>
      <div className="top-selling-product-scroll mt-3">
        <div className="cc-wrapper cc-wrapper--scroll top-product-modal-table">
          <div className="ccw-table ccw-table--full">
            <FnbTable
              className={`fnb-table form-table table-selling-product ${
                onScroll && onScroll.classNameTable ? onScroll.classNameTable : ""
              }`}
              columns={tableTopSellingProductSettings.columns}
              dataSource={dataSource}
              pagination={false}
              scrollY={332}
              scrollX={isMobileDevice ? "auto" : 0}
              onRow={handleOnRowClick}
              onScrollTable={onScroll}
              showHeader={false}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
