import SocialChannel from ".";
import { SocialChannelIcon } from "constants/icons.constants";
import i18n from "utils/i18n";
import { PermissionKeys } from "constants/permission-key.constants";
const { t } = i18n;

const route = [
  {
    key: "app.socialChannel",
    position: 10,
    path: "/social-channel",
    icon: <SocialChannelIcon />,
    name: t("menu.socialChannel"),
    isMenu: true,
    exact: true,
    auth: true,
    permission: PermissionKeys.ADMIN,
    component: SocialChannel,
    child: [],
  },
  {
    key: "app.socialChannel.hide",
    position: 10,
    path: "/social-channel",
    icon: <SocialChannelIcon />,
    name: t("menu.socialChannel"),
    isMenu: false,
    exact: true,
    auth: true,
    permission: PermissionKeys.ADMIN,
    component: SocialChannel,
    child: [],
  },
];
export default route;
