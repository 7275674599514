import { Form, Image, message, Row, Select, Space } from "antd";
import "antd/dist/antd.css";
import foodBeverageLogo from "assets/images/go-fnb-login-logo.png";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { accountStatusConstants } from "constants/account-status.constants";
import {
  ArrowDown,
  EarthIcon,
  EyeIcon,
  EyeOpenIcon,
  HeadphoneIcon,
  LockIcon,
  TabletIcon,
  UserNameIcon,
} from "constants/icons.constants";
import { languageCodeLocalStorageKey, listDefaultLanguage } from "constants/language.constants";
import permissionDataService from "data-services/permission/permission-data.service";
import storeDataService from "data-services/store/store-data.service";
import jwt_decode from "jwt-decode";
import { KindlyNotificationComponent } from "pages/login/components/kindly-notification/kindly-notification.component";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  resetSession,
  setAuth,
  setPermissionGroup,
  setPermissions,
  setPrepareAddressData,
  setStoreInformation,
  setStoreLogo,
  setToken,
} from "store/modules/session/session.actions";
import theme from "theme";
import { getParamsFromUrl, tokenExpired } from "utils/helpers";
import i18n from "utils/i18n";
import { getStorage, localStorageKeys } from "utils/localStorage.helpers";
import { claimTypesConstants } from "../../constants/claim-types.constants";
import loginDataService from "../../data-services/login/login-data.service";
import "../../stylesheets/authenticator.scss";
import { setLanguageSession } from "./../../store/modules/session/session.actions";
import ListStoreComponent from "./components/list-store.component";
import { env } from "env";
import { Region } from "constants/region.constants";
import HeadingFreeTrial from "./components/heading-free-trial/heading-free-trial.component";
import classNames from "classnames";

const { Option } = Select;
const LoginPage = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [languageList, setLanguageList] = useState(listDefaultLanguage);
  const [defaultLanguage, setDefaultLanguage] = useState(
    localStorage.getItem(languageCodeLocalStorageKey) ?? listDefaultLanguage[0]?.languageCode,
  );
  const [kindlyNotification, setKindlyNotification] = useState(false);
  const kindlyNotificationRef = useRef();
  const [userData, setUserData] = useState({});
  const [storeList, setStoreList] = useState([]);
  const [loginInfo, setLoginInfo] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const isInternationalRegion = env.REACT_APP_REGION === Region.International;
  const [showHeadingFreeTrial, setShowHeadingFreeTrial] = useState(true);
  const [isCallLogin, setIsCallLogin] = useState(false);

  useEffect(() => {
    const { state } = props.location;
    const params = getParamsFromUrl(state);
    const { username } = params;
    if (username) {
      form.setFieldsValue({
        userName: username,
      });
    }
    handleRedirectWithToken();
    getInitData();

    //set default scrollTop
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }, []);

  useEffect(() => {
    if (isInternationalRegion && storeList.length === 0) {
      setShowHeadingFreeTrial(true);
    } else {
      setShowHeadingFreeTrial(false);
    }
  }, [isInternationalRegion, storeList]);

  const setUserAuth = (auth, token, permissions) => {
    dispatch(setAuth(auth));
    dispatch(setToken(token));
    dispatch(setPermissions(permissions));

    storeDataService.getStoreInformationAsync().then((response) => {
      dispatch(setStoreInformation(response));
    });

    storeDataService.getPrepareAddressDataAsync().then((response) => {
      dispatch(setPrepareAddressData(response));
    });
  };

  const onFinish = (values) => {
    setIsCallLogin(true);
    let isShowPackageExpire = getStorage(localStorageKeys.IS_SHOW_AGAIN_PACKAGE_EXPIRE);
    loginDataService
      .authenticate(values)
      .then((res) => {
        const {
          token,
          accountStatus,
          thumbnail,
          storeLogoUrl,
          packageName,
          packageExpireDays,
          isBranchPackage,
          isExpired,
          trialPackageExpired,
        } = res;
        dispatch(setStoreLogo(storeLogoUrl));
        setIsCallLogin(false);
        var user = getUserInfo(token);
        var auth = {
          ...user,
          thumbnail,
          isExpired,
          trialPackageExpired,
        };
        switch (accountStatus) {
          case accountStatusConstants.firstLogin:
            var userInfo = getUserInfo(token);
            var authStaff = {
              ...userInfo,
              thumbnail: thumbnail,
            };
            setUserData({
              token: token,
              user: authStaff,
            });
            if (kindlyNotificationRef && kindlyNotificationRef.current) {
              kindlyNotificationRef.current.setKindlyNotifyType(accountStatusConstants.firstLogin);
              kindlyNotificationRef.current.setIsPackageInfo(false);
              kindlyNotificationRef.current.setIsBranchPackage(isBranchPackage);
            }
            break;
          case accountStatusConstants.waitingForApproval:
            setupWaitingForApproveWorkspace(token, auth);
            break;
          case accountStatusConstants.aPackageExpiredNext7Days:
            if (!isShowPackageExpire || isShowPackageExpire === false) {
              if (kindlyNotificationRef && kindlyNotificationRef.current) {
                kindlyNotificationRef.current.setKindlyNotifyType(accountStatusConstants.aPackageExpiredNext7Days);
                kindlyNotificationRef.current.setPackageName(packageName);
                kindlyNotificationRef.current.setExpireDays(packageExpireDays);
                kindlyNotificationRef.current.setIsPackageInfo(true);
                kindlyNotificationRef.current.setIsBranchPackage(isBranchPackage);
              }

              setupWorkspaceNotRedirect(token, auth);
            } else {
              setupWorkspace(token, auth);
            }

            break;
          case accountStatusConstants.aPackageExpired:
            if (!isShowPackageExpire || isShowPackageExpire === false) {
              if (kindlyNotificationRef && kindlyNotificationRef.current) {
                kindlyNotificationRef.current.setKindlyNotifyType(accountStatusConstants.aPackageExpired);
                kindlyNotificationRef.current.setPackageName(packageName);
                kindlyNotificationRef.current.setIsPackageInfo(true);
                kindlyNotificationRef.current.setIsBranchPackage(isBranchPackage);
              }
              setupWorkspaceNotRedirect(token, auth);
            } else {
              setupWorkspace(token, auth);
            }

            break;
          case accountStatusConstants.allPackageExpired:
            if (kindlyNotificationRef && kindlyNotificationRef.current) {
              kindlyNotificationRef.current.setKindlyNotifyType(accountStatusConstants.allPackageExpired);
              kindlyNotificationRef.current.setIsPackageInfo(true);
              kindlyNotificationRef.current.setIsBranchPackage(isBranchPackage);
              setupWorkspaceNotRedirect(token, auth);
            }

            break;
          case accountStatusConstants.trialPackageIsExpired:
            if (kindlyNotificationRef && kindlyNotificationRef.current) {
              kindlyNotificationRef.current.setKindlyNotifyType(accountStatusConstants.trialPackageIsExpired);
              kindlyNotificationRef.current.setIsPackageInfo(true);
              kindlyNotificationRef.current.setIsBranchPackage(isBranchPackage);
              setupWorkspaceNotRedirect(token, auth);
            }

            break;
          default:
            setupWorkspace(token, auth);
            break;
        }
      })
      .catch(() => {
        setIsCallLogin(false);
      });
  };

  const getUserInfo = (token) => {
    let claims = jwt_decode(token);
    let user = {
      userId: claims[claimTypesConstants.id],
      accountId: claims[claimTypesConstants.accountId],
      fullName: claims[claimTypesConstants.fullName],
      emailAddress: claims[claimTypesConstants.email] ?? claims[claimTypesConstants.phoneNumber],
      accountType: claims[claimTypesConstants.accountType],
      currencyCode: claims[claimTypesConstants.currencyCode],
      storeId: claims[claimTypesConstants.storeId],
      currencySymbol: claims[claimTypesConstants.currencySymbol],
    };

    return user;
  };

  const setupFirstLoginWorkspace = () => {
    const { token } = userData;
    setUserAuth(userData, token, []);
    props.history.push("/billing");
  };

  const setupWaitingForApproveWorkspace = (token, userInfo) => {
    let auth = { token: token, user: userInfo };
    setUserAuth(auth, token, []);
    props.history.push("/home");
    message.success(t("signIn.youHaveBeenLoggedInSuccessfully"));
  };

  const setupWorkspace = (token, userInfo) => {
    let auth = { token: token, user: userInfo };
    if (userInfo?.isExpired) {
      dispatch(setPermissionGroup([]));
      setUserAuth(auth, token, []);
      props.history.push("/my-account");
    } else {
      // Get permissions
      permissionDataService.getPermissionsAsync(token).then((res) => {
        const { permissions, permissionGroup } = res;
        if (permissions.length > 0 && permissionGroup.length > 0) {
          dispatch(setPermissionGroup(permissionGroup));
          setUserAuth(auth, token, permissions);
          props.history.push("/home");
          message.success(t("signIn.youHaveBeenLoggedInSuccessfully"));
        } else {
          message.error(t("signIn.youHaveNoPermissions"));
        }
      });
    }
  };

  const setupWorkspaceNotRedirect = (token, userInfo) => {
    let auth = { token: token, user: userInfo };
    if (userInfo?.isExpired) {
      dispatch(setPermissionGroup([]));
      setUserAuth(auth, token, []);
    } else {
      // Get permissions
      permissionDataService.getPermissionsAsync(token).then((res) => {
        const { permissions, permissionGroup } = res;
        if (permissions.length > 0 && permissionGroup.length > 0) {
          dispatch(setPermissionGroup(permissionGroup));
          setUserAuth(auth, token, permissions);
        } else {
          message.error(t("signIn.youHaveNoPermissions"));
        }
      });
    }
  };

  const redirectHomePage = () => {
    props.history.push("/home");
    message.success(t("signIn.youHaveBeenLoggedInSuccessfully"));
  };

  const getInitData = async () => {
    let languages = languageList;
    if (isInternationalRegion) {
      languages = languages?.filter((language) => language?.languageCode !== "vi");
    }
    setLanguageList(languages);
    onChangeLang(defaultLanguage);

    //setFieldsValue
    let formValue = form.getFieldsValue();
    formValue.region = isInternationalRegion ? 1 : 0;
    form.setFieldsValue(formValue);
  };

  const onChangeLang = (selectedLang) => {
    var lang = languageList?.find((item) => item?.languageCode === selectedLang);
    i18n.changeLanguage(selectedLang);
    setDefaultLanguage(selectedLang);
    dispatch(setLanguageSession({ default: lang, list: languageList }));
  };

  const checkAccountLogin = () => {
    setIsCallLogin(true);
    form.validateFields().then(async (values) => {
      setLoginInfo(values);

      loginDataService
        .checkAccountLoginAsync(values)
        .then((res) => {
          if (res.success) {
            setStoreList(res.stores);
            setIsCallLogin(false);
            if (res.stores.length <= 1) {
              let valueSubmitLogin = {
                ...values,
                storeId: res.stores[0]?.storeId,
                accountId: res.stores[0]?.accountId,
              };
              onFinish(valueSubmitLogin);
            }
          } else {
            setIsLogin(false);
            setIsCallLogin(false);
          }
        })
        .catch((error) => {
          setIsCallLogin(false);
        });
    });
  };

  const onSelectStore = (storeId, accountId) => {
    let loginValue = {
      ...loginInfo,
      storeId: storeId,
      accountId: accountId,
    };
    onFinish(loginValue);
  };

  const onBackLogin = () => {
    form.setFieldsValue({
      userName: loginInfo?.userName,
      password: loginInfo?.password,
    });
    setStoreList([]);
  };

  const checkTokenExpired = () => {
    let isTokenExpired = true;
    let token = getStorage(localStorageKeys.TOKEN);
    if (token || token !== null) {
      isTokenExpired = tokenExpired(token);
    }
    return isTokenExpired;
  };

  const handleRedirectWithToken = () => {
    let isTokenExpired = checkTokenExpired();
    if (isTokenExpired) {
      dispatch(resetSession());
    } else {
      props.history.push("/home");
    }
  };

  const loginFormClassName = classNames({
    "div-form": true,
    "login-contain": true,
    "login-contain__right": true,
    "login-contain__right--international": isInternationalRegion,
    "login-form-admin": true,
  });
  return (
    <div className="c-authenticator login-bg">
      {showHeadingFreeTrial && <HeadingFreeTrial />}
      <div className="form-logo">
        <div>
          <Image preview={false} src={foodBeverageLogo} width={300} />
        </div>
      </div>
      {storeList.length < 2 && (
        <div className={loginFormClassName}>
          <Form
            className="login-form-admin login-inner login-inner__spacing custom-form"
            name="basic"
            autoComplete="off"
            onFinish={checkAccountLogin}
            form={form}
            layout="vertical"
          >
            <div className="frm-content">
              <Row className="form-lang">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  suffixIcon={<ArrowDown />}
                  dropdownClassName="select-language-dropdown"
                  value={t(defaultLanguage)}
                  onChange={(languageCode) => onChangeLang(languageCode)}
                >
                  {languageList?.map((item) => {
                    return (
                      <Option key={item?.languageCode}>
                        <span className="flag">{item?.flag}</span>
                        <span>{t(item?.name)}</span>
                      </Option>
                    );
                  })}
                </Select>
              </Row>
              {!isLogin && (
                <div className="error-field">
                  <p>{t("signIn.errorLogin")} </p>
                </div>
              )}
              <Space direction="vertical" size={24} className="w-100 login-field-item">
                <FnbTypography variant={theme.typography["d1-bold"]} text={t("signIn.text")} />
                <Form.Item name="region" hidden="true"></Form.Item>
                <Form.Item
                  name="userName"
                  label={t("signIn.username.label")}
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={t("signIn.username.validateMessage")}
                        />
                      ),
                    },
                    {
                      type: "email",
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={t("messages.pleaseEnterValidEmailAddress")}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    prefix={<UserNameIcon width={16} height={16} />}
                    placeholder={t("signIn.username.placeholder")}
                  />
                </Form.Item>
                <div>
                  <Form.Item
                    name="password"
                    label={t("signIn.password.placeholder")}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={t("signIn.password.validateMessage")}
                          />
                        ),
                      },
                    ]}
                  >
                    <FnbInput
                      type="password"
                      prefix={<LockIcon />}
                      iconRender={(visible) => (visible ? <EyeOpenIcon /> : <EyeIcon />)}
                      placeholder={t("signIn.password.placeholder")}
                    />
                  </Form.Item>
                  <div className="login-form-forgot">
                    <span onClick={() => props.history.push("/forgot-password")}>{t("signIn.forgotPassword")}</span>
                  </div>
                </div>
                <Form.Item>
                  <FnbButton
                    type="submit"
                    className="login-form-button"
                    text={t("signIn.buttonLogin")}
                    width="100%"
                    loading={isCallLogin}
                    disabled={isCallLogin}
                  />
                </Form.Item>
                <Row className="w-100" justify="center" align="middle" style={{ columnGap: "4px" }}>
                  <FnbTypography
                    variant={theme.typography["b1-regular"]}
                    text={t("signIn.dontHaveAccount")}
                    color="#5a5a5a"
                  />
                  <Link to={"/register-account?step=1"}>
                    <FnbTypography
                      variant={theme.typography["b1-bold"]}
                      text={t("signIn.register")}
                      color={theme.colors.primary[100]}
                    />
                  </Link>
                  <FnbTypography variant={theme.typography["b1-regular"]} color="#5a5a5a" text={t("signIn.here")} />
                </Row>
              </Space>
              <div className="content-bottom">
                <span className="icon-content-bottom">
                  <EarthIcon />
                </span>
                <span className="icon-content-bottom">
                  <HeadphoneIcon />
                </span>
                <span className="icon-content-bottom">
                  <TabletIcon />
                </span>
              </div>
            </div>
          </Form>
        </div>
      )}

      {storeList.length >= 2 && (
        <ListStoreComponent storeList={storeList} onSelectStore={onSelectStore} onBackLogin={onBackLogin} />
      )}

      <KindlyNotificationComponent
        visible={kindlyNotification}
        onActive={setupFirstLoginWorkspace}
        onClick={() => setKindlyNotification(false)}
        ref={kindlyNotificationRef}
        redirectHomePage={redirectHomePage}
      />
    </div>
  );
};

export default LoginPage;
