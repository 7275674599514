import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { RemoveIcon } from "constants/icons.constants";
import React from "react";
import "./fnb-chip.scss";

export function FnbChip(props) {
  const {
    id = "",
    className = "",
    title = "",
    variant = "primary",
    iconHeader = undefined,
    iconFooter = undefined,
    width = undefined,
    minWidth = undefined,
    maxWidth = undefined,
    detail = undefined,
    style,
    isVisible = true,
    isDisplay = true,
    isDisableToolTip = false,
    placement = "bottom",
    isDefault = false,
    isRemoveChip = false,
    onRemoveChip = undefined,
    onClick,
    styleChip = {},
    isBorder = true,
  } = props;
  return (
    <FnbTooltip
      {...props}
      variant={variant}
      placement={placement}
      title={detail ?? title}
      style={style}
      onClick={(e) => {
        onClick && onClick(e);
        e.stopPropagation();
      }}
      hideTooltip={!isVisible || isDisableToolTip}
    >
      <div
        className={`fnb-chip fnb-chip--${variant} ${className} ${isDefault ? "fnb-chip__option--default" : ""}`}
        style={{
          width: width ? width : "fit-content",
          minWidth: minWidth ? minWidth : "fit-content",
          maxWidth: maxWidth ? maxWidth : "100%",
          visibility: isVisible ? "visible" : "hidden",
          display: isDisplay ? "" : "none",
          ...styleChip,
          border: isBorder ? "" : "none",
        }}
        id={id}
      >
        {iconHeader &&
          React.cloneElement(iconHeader, {
            className: "fnb-chip__icon",
          })}
        <span className="fnb-chip__label" style={{ maxWidth: `calc(${maxWidth} - 20px)` ?? "100%" }}>
          {title}
        </span>
        {iconFooter &&
          React.cloneElement(iconFooter, {
            className: "fnb-chip__icon",
          })}
        {isRemoveChip && (
          <div className="fnb-chip__remove" onClick={() => onRemoveChip(id)}>
            <RemoveIcon></RemoveIcon>
          </div>
        )}
      </div>
    </FnbTooltip>
  );
}
