import styled from "styled-components";
import { ListPackage, PackageSectionContainer } from "./package-section.styled";

export const PageStepSelectPackage = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 22px;
  width: 100%;
  display: flex;
  column-gap: 32px;
  width: 100%;
  @media (max-width: 1200px) {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    row-gap: 32px;
  }
  @media (max-width: 1525px) {
    flex-direction: column;
  }
`;

export const MyAccountWrapperRightContent = styled.div`
  ${PackageSectionContainer} {
    padding: 0;
  }
  ${ListPackage} {
    padding: 16px 0 0 0;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${(p) => (p.rowGap ? `${p.rowGap}px` : "32px")};
  width: 100%;
`;
