import { Select } from "antd";
import { ArrowDown, CheckedIcon } from "constants/icons.constants";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import "./fnb-select-multiple-opt-group.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import "../fnb-select-multiple/fnb-select-multiple.scss";

/**
 * SelectSingle component custom from antd select
 * @param {option, value, onChange, className, disabled, allowClear, showSearch, placeholder, dropdownRender, style, defaultValue } props
 * option: data select option [], map data [{id: "key", name: "value"}] first
 * other param are used as same as antd select, visit link https://ant.design/components/select/
 * @returns
 */

export function FnbSelectMultipleOptGroup({
  value,
  onChange,
  className,
  popupClassName,
  option,
  disabled,
  allowClear,
  showSearch = true,
  placeholder,
  dropdownRender,
  style,
  defaultValue,
  onSelect,
  fixed,
  controlId,
  noTranslateOptionName,
  onPopupScroll,
  onFocus,
  onBlur,
  onSearch,
  onClear,
  optionGroup,
  suffixIcon,
  maxLength = 50,
}) {
  const isScreenXL = useMediaQuery({ maxWidth: 1200 });
  const [t] = useTranslation();
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  const optionGroups = optionGroup.map((option) => {
    return (
      <Select.OptGroup label={option?.name} key={option?.id}>
        {option?.group.map((item) => {
          const name = option?.name + " - " + item?.name;
          const nameTranslate = t(option?.name) + " - " + t(item?.name);
          return (
            <Select.Option key={item?.id} value={item?.id} name={item?.name}>
              {!isScreenXL ? (
                <FnbTooltip
                  title={noTranslateOptionName ? name : nameTranslate}
                  onlyShowWhenEllipsis
                  disabledStopPropagation={isTouchDevice}
                  hideTooltip={isTouchDevice}
                  maxWidth={"440px"}
                >
                  {noTranslateOptionName ? name : nameTranslate}
                </FnbTooltip>
              ) : noTranslateOptionName ? (
                name
              ) : (
                nameTranslate
              )}
            </Select.Option>
          );
        })}
      </Select.OptGroup>
    );
  });

  return (
    <>
      <Select
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        mode="multiple"
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        style={style}
        className={`fnb-select-multiple-opt-group fnb-select-multiple-admin ${className}`}
        popupClassName={`fnb-select-multiple-opt-group-dropdown fnb-select-multiple-dropdown ${popupClassName}`}
        suffixIcon={suffixIcon || <ArrowDown />}
        menuItemSelectedIcon={<CheckedIcon />}
        disabled={disabled}
        showSearch={showSearch}
        allowClear={allowClear}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        optionFilterProp="children"
        showArrow
        filterOption={(input, option) => {
          const newOption = t(option?.name);
          const inputStr = input.removeVietnamese();
          const name = newOption?.removeVietnamese();
          return name?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
        }}
        onPopupScroll={onPopupScroll}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        onClear={onClear}
      >
        {optionGroups}
      </Select>
    </>
  );
}
