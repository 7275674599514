import { createHttp } from "../utils/http-common";

const controller = "branch";

const getBranchesByCustomerAddressAsync = (lat, lng, isNotSelectCustomerAddress = false, extraData) => {
  const http = createHttp();
  return http.get(
    `/${controller}/get-branches-by-customer-address?lat=${lat}&lng=${lng}&isNotSelectCustomerAddress=${isNotSelectCustomerAddress}&extraData=${extraData}`,
  );
};

const getWorkingHourByBranchIdAsync = (branchId) => {
  const http = createHttp();
  if (branchId) {
    return http.get(`/${controller}/get-working-hour-by-branch-id?branchId=${branchId}`);
  }
  return Promise.resolve(null);
};

const getBranchesInStoreAsync = (storeId) => {
  const http = createHttp();
  return http.get(`/${controller}/get-branches-by-account-id?storeId=${storeId}`);
};

const branchDataService = {
  getBranchesByCustomerAddressAsync,
  getWorkingHourByBranchIdAsync,
  getBranchesInStoreAsync,
};

export default branchDataService;
