import "./store-branch-address-selector.component.scss";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Col, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getInitialSelectedBranchInfo } from "../../../utils/helpers";
import { enumOrderType } from "../../constants/enums";
import { ReactComponent as CheckedIcon } from "./checked-icon.svg";
import { ReactComponent as ShopAddressIcon } from "./shop-address-icon.svg";

export const StoreBranchAddressSelector = forwardRef((props, ref) => {
  const {
    onSelected,
    onClose,
    initStoreBranchData,
    colorGroup,
    getDefaultStoreBranch,
    customerAddress,
    isCustomerAddressScreen,
  } = props;
  const nearestStoreBranches = useSelector((state) => state?.session?.nearestStoreBranches);
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const [storeBranchAddresses, setStoreBranchAddresses] = useState([]);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [groupColorConfig, setGroupColorConfig] = useState();
  const [currentOrderType, setCurrentOrderType] = useState(deliveryAddress?.orderType ?? enumOrderType.DELIVERY);
  const isScrollable = storeBranchAddresses.length > 3;

  const translatedData = {
    deliveryTo: t("addUserLocation.deliveryTo", "Giao đến"),
    pickUp: t("addUserLocation.pickUp", "Tự lấy hàng"),
    nearestBranch: t("addUserLocation.nearestBranch", "Cửa hàng gần bạn nhất"),
    defaultBranch: t("addUserLocation.defaultBranch", "Chi nhánh mặc định"),
    otherBranch: t("addUserLocation.otherBranch", "Chi nhánh khác"),
  };

  useEffect(() => {
    const group = colorGroup?.[0];
    setGroupColorConfig(group);
  }, []);

  useEffect(() => {
    setCurrentOrderType(deliveryAddress?.orderType ?? enumOrderType.DELIVERY);
  }, [deliveryAddress?.orderType]);

  useEffect(() => {
    if (nearestStoreBranches) {
      getStoreBranchAddresses();
    }
  }, [nearestStoreBranches]);

  useImperativeHandle(ref, () => ({
    setAutoPickStoreBranchAddress(branches) {
      autoPickStoreBranchAddress(branches);
    },
  }));

  const autoPickStoreBranchAddress = (nearestStoreBranches) => {
    if (!nearestStoreBranches || nearestStoreBranches?.length === 0) {
      return;
    }

    const defaultStoreBranchAddressInfo = getInitialSelectedBranchInfo(nearestStoreBranches);
    onSelectBranchAddress(defaultStoreBranchAddressInfo);
  };

  const onSelectBranchAddress = (storeBranch, isSelectNewBranch) => {
    if (onSelected) onSelected(storeBranch, isSelectNewBranch);
  };

  const getStoreBranchAddresses = () => {
    // TODO: mapping nearestStoreBranches => storeBranchAddresses and update storeBranchAddresses
    const storeBranchAddresses = nearestStoreBranches?.map((item) => {
      const convertDistanceToKm = item?.distance / 1000;
      const distance = convertDistanceToKm?.toFixed(1)?.toString()?.replace(".", ",") + " km";

      return {
        id: item?.branchId,
        title: item?.branchName,
        addressDetail: item?.branchAddress,
        distance: distance,
        lat: item?.lat,
        lng: item?.lng,
        isDefault: item?.isDefault,
      };
    });
    setStoreBranchAddresses(storeBranchAddresses);

    ///If delete selected branch in admin, auto select first branch
    const selectedBranch = storeBranchAddresses?.find((x) => x.id === initStoreBranchData?.id);
    if (!selectedBranch && storeBranchAddresses?.[0]) {
      onSelectBranchAddress(storeBranchAddresses?.[0] ?? null);
    }
  };

  const StoreBranchDetail = (props) => {
    const { storeBranchName, distance, addressDetail, groupColorConfig, customClassName } = props;
    return (
      <Row className={`store-branch-address-item ${customClassName}`}>
        <Col span={24} className="d-flex">
          <div className="address-icon">
            <ShopAddressIcon fill={groupColorConfig?.buttonBackgroundColor} />
          </div>
          <div className="address-detail">
            <div className="address-label">
              <span>{storeBranchName}</span>
              <div className="selected">
                <CheckedIcon fill={groupColorConfig?.buttonBackgroundColor} />
              </div>
            </div>
            <p className="address">
              <span className="location">{addressDetail}</span>
              <span className="distance">({distance})</span>
            </p>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <div className={`store-branch-address-selector ${currentOrderType === enumOrderType.PICK_UP ? "pick-up" : ""}`}>
        <div className="store-branch-addresses">
          <div className="text-branch-info" style={{ marginBottom: 16 }}>
            {!customerAddress && storeBranchAddresses[0]?.isDefault
              ? translatedData.defaultBranch
              : translatedData.nearestBranch}
          </div>
          <div onClick={() => onSelectBranchAddress(storeBranchAddresses[0], true)}>
            <StoreBranchDetail
              groupColorConfig={groupColorConfig}
              storeBranchName={storeBranchAddresses[0]?.title}
              distance={storeBranchAddresses[0]?.distance}
              addressDetail={storeBranchAddresses[0]?.addressDetail}
              customClassName={
                storeBranchAddresses[0]?.id == initStoreBranchData?.id
                  ? "ant-radio-wrapper-checked default-element"
                  : "ant-radio-wrapper"
              }
            />
          </div>
          {storeBranchAddresses?.filter((st, index) => index !== 0)?.length > 0 && (
            <div className="text-branch-info" style={{ marginTop: 20 }}>
              {translatedData.otherBranch}
            </div>
          )}
          <div style={{ height: isScrollable ? 284 : "auto", overflow: isScrollable ? "scroll" : "visible" }}>
            <Radio.Group
              value={initStoreBranchData?.id}
              defaultValue={initStoreBranchData?.id}
              className="store-branch-addresses"
            >
              {storeBranchAddresses
                ?.filter((st, index) => index !== 0)
                ?.map((storeBranch, index) => {
                  return (
                    <Radio
                      className="store-radio-item"
                      key={index}
                      value={storeBranch?.id}
                      onClick={() => onSelectBranchAddress(storeBranch, true)}
                    >
                      <StoreBranchDetail
                        groupColorConfig={groupColorConfig}
                        position={index}
                        storeBranchName={storeBranch?.title}
                        distance={storeBranch?.distance}
                        addressDetail={storeBranch?.addressDetail}
                      />
                    </Radio>
                  );
                })}
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
});
