import "../BlogComponent.scss";

import { useEffect, useState } from "react";

import { Button, Image } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";
import { monthLocalize } from "../../../../../../constants/string.constants";
import { EyeBlogIcon, ProfileBlogIcon } from "../../../../../assets/icons.constants";
import BlogDefault from "../../../../../assets/images/blog-default.png";

const BlogCardComponent = (props) => {
  const { blogInfo } = props;

  const { t } = useTranslation();
  const [date, setDate] = useState();
  const [month, setMonth] = useState();
  const languageSession = useSelector((state) => state.session?.languageSession);
  const pageData = {
    btnSeeMore: t("blog.blogDetail.readMore", "Đọc thêm"),
    month: t("blog.blogDetail.month", "tháng"),
    by: t("blog.blogDetail.by", "Bởi")[0].toUpperCase() + t("blog.blogDetail.by", "Bởi").slice(1),
  };

  useEffect(() => {
    handleDate(blogInfo?.createdTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSession]);

  const handleDate = (date) => {
    const day = moment.utc(date).local().format("D");
    const month = moment.utc(date).local().format("M");

    setDate(day);
    setMonth(t(monthLocalize[month]));
  };

  const isViewOnStoreApp = window?.isStoreAppWebView ?? false;

  return (
    <Link to={`/blog/${blogInfo?.urlEncode}`} className="image-card">
      <div className="post-blog-date">
        <div className="date">{date}</div>
        <div className="month">{month}</div>
      </div>
      <div className="card">
        <div className="title text-btn text-line-clamp-1">{blogInfo?.title}</div>
        <div className="body">
          <div className="view-author">
            <div className="view">
              <EyeBlogIcon />
              <span>{blogInfo?.totalView}</span>
            </div>
            <hr />
            <div className="author">
              <ProfileBlogIcon />
              <span className="text-btn text-line-clamp-1">{pageData.by + " " + blogInfo?.creator}</span>
            </div>
          </div>
          <div className="description text-btn text-line-clamp-2">{blogInfo?.description}</div>
        </div>
        {isViewOnStoreApp === false && <Button className="button-see-more">{pageData.btnSeeMore}</Button>}
      </div>
      <div className="thumbnail">
        <Image preview={false} className="imageBlog" src={blogInfo.bannerImageUrl ?? BlogDefault} />
      </div>
    </Link>
  );
};

export default BlogCardComponent;
