import { InputNumber, Slider, Form } from "antd";
import { useTranslation } from "react-i18next";
import MultipleSelect from "../MultipleSelect";
import {
  getAllGroupPermissions,
  getAllStaff,
  groupPermissionsOptionSelector,
  staffOptionSelector,
} from "store/modules/common/common.reducers";
import { useLayoutEffect, forwardRef, useState, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShortValue, getSuffixShortValue, formatNumber, executeAfter } from "utils/helpers";
import { staffRevenueActions, staffRevenueSelector } from "store/modules/staff-revenue/staff-revenue.reducer";
import { Profile2UserIcon } from "constants/icons.constants";
import { THREE_DIGITS_REGEX } from "constants/default.constants";
import "./index.scss";

const FilterStaffRevenue = forwardRef((props, ref) => {
  const { minRevenue, maxRevenue, onChange, setIsAlreadyOpenModalFilter } = props;
  const [t] = useTranslation();
  const groupPermissionOption = useSelector(groupPermissionsOptionSelector);
  const allStaffOption = useSelector(staffOptionSelector);
  const [staffOption, setStaffOption] = useState([]);
  const dispatch = useDispatch();
  const defaultValueSlider = [minRevenue, maxRevenue];
  const [selectedValueSlider, setSelectedValueSlider] = useState(defaultValueSlider);
  const defaultFilter = {
    groupPermissionIds: [],
    staffIds: [],
    totalOrderFrom: null,
    totalOrderTo: null,
  };
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const staffRevenue = useSelector(staffRevenueSelector);
  const { filter } = staffRevenue;

  const pageData = {
    position: t("report.staff.position"),
    staff: t("report.shift.staff"),
    allStaff: t("report.staff.allStaff"),
    orderQuantity: t("report.staff.orderQuantity"),
    revenue: t("report.staff.revenue"),
    from: t("report.staff.from"),
    to: t("report.staff.to"),
    allPosition: t("report.staff.allPosition"),
    resetData: t("report.staff.resetData"),
    filtersApplied: t("report.staff.filtersApplied"),
  };

  const marks = {
    [minRevenue]: `${getShortValue(minRevenue)} ${getSuffixShortValue(minRevenue)}`,
    [maxRevenue]: `${getShortValue(maxRevenue)} ${getSuffixShortValue(maxRevenue)}`,
  };

  useEffect(() => {
    setIsAlreadyOpenModalFilter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onCleanAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minRevenue, maxRevenue]);

  useEffect(() => {
    if (selectedValueSlider !== defaultValueSlider || selectedFilter !== defaultFilter) {
      onChange({ selectedFilter, selectedValueSlider });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, selectedValueSlider]);

  useLayoutEffect(() => {
    dispatch(getAllStaff());
    dispatch(getAllGroupPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filterStaffByGroupPermission([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStaffOption]);

  useEffect(() => {
    if (selectedValueSlider[0] !== filter.startRevenue || selectedValueSlider[1] !== filter.endRevenue) {
      setSelectedValueSlider([filter.startRevenue, filter.endRevenue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.startRevenue, filter.endRevenue]);

  const handleChangeSlider = (value) => {
    setSelectedValueSlider(value);
  };

  const handleChangeInput = (value, columnName, minValue = null, maxValue = null, isDebounce = false) => {
    const updatedRangeValues = [...selectedValueSlider];
    if (columnName === "revenue.from") {
      if (!value) {
        updatedRangeValues[0] = minRevenue;
      } else {
        updatedRangeValues[0] = value;
      }
    } else if (columnName === "revenue.to") {
      if (!value) {
        updatedRangeValues[1] = maxRevenue;
      } else {
        updatedRangeValues[1] = value;
      }
    } else if (columnName) {
      const filterData = selectedFilter;
      if (
        ((columnName === "totalOrderTo" || columnName === "totalOrderFrom") && value === null) ||
        ((minValue === null || value > minValue) && (maxValue === null || value < maxValue))
      ) {
        filterData[columnName] = value;
      }
      setSelectedFilter(filterData);
    }
    executeAfter(isDebounce ? 500 : 0, () => {
      setSelectedValueSlider(updatedRangeValues);
      dispatch(staffRevenueActions.setSortTableWhenStaffSummaryClose(false));
    });
  };

  const filterStaffByGroupPermission = (value) => {
    let result = allStaffOption;
    if (value && value.length > 0) {
      result = allStaffOption.filter((staff) =>
        value.some((valueId) => staff.groupPermissions.some((groupPermission) => groupPermission.id === valueId)),
      );
    }
    setStaffOption(result);
  };

  const cleanSelect = (columnName) => {
    handleChangeInput([], columnName);
    const elements = document.getElementsByClassName("ant-select-selection-search-input");
    for (let i = 0; i < elements.length; i++) {
      elements[i].blur();
    }
  };

  const onCleanAll = () => {
    setSelectedFilter(defaultFilter);
    setSelectedValueSlider(defaultValueSlider);
  };

  const onKeyDownClearZero = (e) => {
    if (e.target.value === "0") {
      e.target.value = "";
    }
  };

  const formatter = (value) => formatNumber(value);

  useImperativeHandle(ref, () => ({
    resetFilter() {
      onCleanAll();
    },
  }));

  return (
    <Form layout="vertical">
      <Form.Item label={pageData.position}>
        <MultipleSelect
          options={groupPermissionOption}
          placeholder={pageData.allPosition}
          onChange={(value) => {
            filterStaffByGroupPermission(value);
            handleChangeInput(value, "groupPermissionIds");
          }}
          value={selectedFilter.groupPermissionIds}
          onClean={() => cleanSelect("groupPermissionIds")}
          cleanTitle={pageData.allPosition}
        />
      </Form.Item>
      <Form.Item label={pageData.staff}>
        <MultipleSelect
          options={staffOption}
          placeholder={pageData.allStaff}
          onChange={(value) => {
            handleChangeInput(value, "staffIds");
          }}
          value={selectedFilter.staffIds}
          onClean={() => cleanSelect("staffIds")}
          cleanTitle={pageData.allStaff}
          cleanIcon={<Profile2UserIcon width={24} height={24} />}
        />
      </Form.Item>
      <Form.Item label={pageData.orderQuantity} className="form-item-control-inline">
        <Form.Item>
          <InputNumber
            value={selectedFilter.totalOrderFrom}
            className="w-100 fnb-input-number"
            placeholder={pageData.from}
            formatter={(value) => `${value}`.replace(THREE_DIGITS_REGEX, ",")}
            onChange={(value) =>
              handleChangeInput(
                value,
                "totalOrderFrom",
                0,
                selectedFilter.totalOrderTo > 0 ? selectedFilter.totalOrderTo : null,
                true,
              )
            }
          />
        </Form.Item>
        <span className="middle-character">-</span>
        <Form.Item>
          <InputNumber
            value={selectedFilter.totalOrderTo}
            className="w-100 fnb-input-number"
            placeholder={pageData.to}
            formatter={(value) => `${value}`.replace(THREE_DIGITS_REGEX, ",")}
            onChange={(value) =>
              handleChangeInput(
                value,
                "totalOrderTo",
                selectedFilter.totalOrderFrom > 0 ? selectedFilter.totalOrderFrom : 0,
                null,
                true,
              )
            }
          />
        </Form.Item>
      </Form.Item>
      <Form.Item label={pageData.revenue} className="form-item-control-inline">
        <Form.Item>
          <InputNumber
            min={minRevenue}
            max={selectedValueSlider[1]}
            className="w-100 fnb-input-number"
            placeholder={pageData.from}
            value={selectedValueSlider[0]}
            formatter={(value) => `${value}`.replace(THREE_DIGITS_REGEX, ",")}
            onChange={(value) => handleChangeInput(value, "revenue.from", null, null, true)}
            onKeyDown={(e) => onKeyDownClearZero(e)}
          />
        </Form.Item>
        <span className="middle-character">-</span>
        <Form.Item>
          <InputNumber
            min={selectedValueSlider[0]}
            max={maxRevenue}
            className="w-100 fnb-input-number"
            placeholder={pageData.to}
            value={selectedValueSlider[1]}
            formatter={(value) => `${value}`.replace(THREE_DIGITS_REGEX, ",")}
            onChange={(value) => handleChangeInput(value, "revenue.to", null, null, true)}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item>
        <Slider
          className="fnb-input-slider"
          min={minRevenue}
          max={maxRevenue}
          marks={marks}
          value={selectedValueSlider}
          tooltip={{ formatter }}
          onChange={handleChangeSlider}
          range
        />
      </Form.Item>
    </Form>
  );
});

export default FilterStaffRevenue;
