import React from "react";
import { ArrowDown, CheckboxCheckedIcon } from "constants/icons.constants";
import { Select, Spin } from "antd";
import "./fnb-select-multiple.scss";
import { useState } from "react";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import theme from "theme";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

/**
 * SelectMultiple component custom from antd select
 * @param {option, onChange, className, value, disabled, allowClear, showSearch, placeholder, dropdownRender, style, defaultValue } props
 * option: data select option [], map data [{id: "key", name: "value"}] first
 * other param are used as same as antd select, visit link https://ant.design/components/select/
 * @returns
 */
export function FnbSelectMultiple(props) {
  const [keywordFilter, setKeywordFilter] = useState();

  const {
    value,
    onChange,
    className = "",
    option,
    disabled,
    allowClear,
    showSearch = true,
    placeholder,
    dropdownRender,
    style,
    defaultValue,
    onSelect,
    fixed,
    placement,
    onFocus,
    onBlur,
    loading = false,
    suffixIcon = <ArrowDown />,
    hideItemSelected = false,
    ...rest
  } = props;
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
  const compareStringsByKeyword = (strA, strB, keyword) => {
    const lowerStrA = strA.toLowerCase();
    const lowerStrB = strB.toLowerCase();
    const lowerKeyword = keyword?.toLowerCase();

    const indexA = lowerStrA.indexOf(lowerKeyword);
    const indexB = lowerStrB.indexOf(lowerKeyword);

    if (indexA === -1 && indexB !== -1) return 1;
    if (indexB === -1 && indexA !== -1) return -1;

    return indexA - indexB;
  };

  const classNameSelect = classNames({
    "fnb-select-multiple-admin": true,
    [`${className}`]: true,
    "fnb-select-multiple-admin--disabled": disabled,
    "fnb-select-multiple-admin--hide-items": loading || hideItemSelected,
  });
  return (
    <>
      <Select
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        mode="multiple"
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        style={style}
        className={classNameSelect}
        dropdownClassName="fnb-select-multiple-dropdown"
        suffixIcon={
          loading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
              style={{ color: theme.colors.gray[50], marginTop: 3 }}
            />
          ) : (
            suffixIcon
          )
        }
        menuItemSelectedIcon={<CheckboxCheckedIcon />}
        disabled={disabled || loading}
        showSearch={showSearch}
        allowClear={allowClear}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        optionFilterProp="children"
        showArrow
        filterOption={(input, option) => {
          setKeywordFilter(input);
          const inputValue = input.toLowerCase().removeVietnamese();
          let optionValue = option.children.props.children.toLowerCase().removeVietnamese();  
          return optionValue.includes(inputValue);
        }}
        filterSort={(obA, obB) => {
          if (keywordFilter) {
            return compareStringsByKeyword(obA.children.props.children, obB.children.props.children, keywordFilter);
          }
        }}
        placement={placement}
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      >
        {option?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            <FnbTooltip
              title={item.name}
              onlyShowWhenEllipsis
              disabledStopPropagation={isTouchDevice}
              hideTooltip={isTouchDevice}
              maxWidth={"440px"}
            >
              {item.name}
            </FnbTooltip>
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
