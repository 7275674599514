import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { IngredientRemoveReasonType, IngredientRemoveStatus } from "constants/ingredient-remove.constants";
import React from "react";
import { useTranslation } from "react-i18next";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { branches } = props;

  const [form] = Form.useForm();
  const DEFAULT_VALUE = "";

  const allBranchOption = {
    id: "",
    name: t("material.filter.branch.all"),
  };

  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      status: {
        title: t("material.filter.status.title"),
        all: t("material.filter.status.all"),
      },
      createdTime: t("menu.inventoryManagement.createdTime"),
    },
  };

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter();
    },
  }));

  const ingredientRemoveReasonOptions = [
    { name: t("menu.inventoryManagement.allReasons"), id: DEFAULT_VALUE },
    { name: t("menu.inventoryManagement.ingredientsAreOutOfDate"), id: IngredientRemoveReasonType.OUT_OF_DATE },
    { name: t("menu.inventoryManagement.ingredientsAreDamaged"), id: IngredientRemoveReasonType.DAMAGED },
    { name: t("menu.inventoryManagement.others"), id: IngredientRemoveReasonType.OTHERS },
  ];

  const clearFilter = () => {
    form.resetFields();
    props.fetchDataPopover({});
  };

  const handleOnValueChange = (changedValues, allValues) => {
    props.fetchDataPopover(allValues);
  };

  return (
    <Form layout="vertical" onValuesChange={handleOnValueChange} form={form}>
      <Form.Item label={t("purchaseOrder.branch")} name="branchId">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={[allBranchOption, ...branches]}
        />
      </Form.Item>
      <Form.Item label={t("order.reason")} name="reasonType">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={ingredientRemoveReasonOptions}
        />
      </Form.Item>
      <Form.Item label={t("table.status")} name="statusId">
        <Radio.Group defaultValue={DEFAULT_VALUE} buttonStyle="solid">
          <FnbRadioButton value={DEFAULT_VALUE}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={IngredientRemoveStatus.WAITING_TO_APPROVAL}>
            {t("menu.inventoryManagement.waitingForApproval")}
          </FnbRadioButton>
          <FnbRadioButton value={IngredientRemoveStatus.APPROVED}>{t("status.approved")}</FnbRadioButton>
          <FnbRadioButton value={IngredientRemoveStatus.REJECTED}>{t("status.rejected")}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
