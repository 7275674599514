import ReactApexChart from "react-apexcharts";
import "./chart-staff-revenute.style.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { staffRevenueActions, staffRevenueSelector } from "store/modules/staff-revenue/staff-revenue.reducer";
import { renderToString } from "react-dom/server";
import { TipIcon } from "constants/icons.constants";
import { COLORS_COLUMN_CHART } from "../data/chart-staff-revenue.data";
import { useTranslation } from "react-i18next";
import { NumberUnitLocalization } from "constants/number-unit-type.constants";
import { executeAfter, getCurrency } from "utils/helpers";

function StaffRevenueChart() {
  const { t } = useTranslation();
  const showStaffNumber = useSelector(staffRevenueSelector).chart.showStaffNumber;
  const id = useSelector(staffRevenueSelector).chart.id;
  const colorId = useSelector(staffRevenueSelector).chart.colorId;
  const COLUMN_WIDTH = 44;
  const COLUMN_GAP = 11;
  const filter = useSelector(staffRevenueSelector).filter;
  const dispatch = useDispatch();
  const listRevenue = useSelector(staffRevenueSelector).chart.listRevenue;
  const [isFirstReloadColor, setIsFirstReloadColor] = useState(false);
  const isShowStaffSummary = useSelector(staffRevenueSelector).isShowStaffSummary;
  const listStaffNumber = useSelector(staffRevenueSelector).chart.listStaffNumber;
  const columns = useSelector(staffRevenueSelector).chart.columns;
  const isInitialDone = useSelector(staffRevenueSelector).chart.isInitialDone;
  const numberUnitType = useSelector(staffRevenueSelector).chart.numberUnitType;
  const maxRevenue = useSelector(staffRevenueSelector).chart.maxRevenue;
  const [currency, setCurrency] = useState("");
  const [minHeightChart, setMinHeightChart] = useState(0.8);
  const [heightChartValue0, setHeightChartValue0] = useState(0.2);

  const setIndex = (startIndex, endIndex) => {
    const startRevenue = columns[startIndex].fromRevenue;
    const endRevenue = columns[endIndex].toRevenue;
    dispatch(
      staffRevenueActions.setStartAndEndRevenue({
        startRevenue,
        endRevenue,
        startIndex,
        endIndex,
      }),
    );
    dispatch(staffRevenueActions.setSortTableWhenStaffSummaryClose(false));
  };

  const reloadHeightChart = () => {
    if (listStaffNumber?.length > 0) {
      const max = listStaffNumber.reduce((a, b) => {
        return Math.max(a, b);
      });
      setMinHeightChart(max / 14);
      setHeightChartValue0(max / 27);
    }
  };
  useEffect(() => {
    reloadHeightChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listStaffNumber]);

  useEffect(() => {
    if (isInitialDone) {
      dispatch(staffRevenueActions.reloadChartId());
      setCurrency(getCurrency());
      setTimeout(() => {
        reloadColorChart();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialDone]);

  useEffect(() => {
    reloadColorChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorId]);

  useEffect(() => {
    setTimeout(() => {
      reloadColorChart();
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reloadColorChart = () => {
    setTimeout(() => {
      const columns = document.getElementsByClassName("apexcharts-bar-area");
      if (columns?.length > 0) {
        reloadHeightChart();
        if (filter.index.start === null && filter.index.end === null) {
          for (let index = 0; index < columns.length; index++) {
            const column = columns[index];
            column.style.fill = COLORS_COLUMN_CHART.SELECTED;
            column.style.cursor = "pointer";
          }
        } else {
          // 1 column is selected
          if ((filter.index.start !== null && filter.index.end === null) || filter.index.start === filter.index.end) {
            for (let index = 0; index < columns.length; index++) {
              const column = columns[index];

              if (index === filter.index.start) {
                column.style.fill = COLORS_COLUMN_CHART.SELECTED;
                column.style.cursor = "col-resize";
              } else {
                column.style.fill = COLORS_COLUMN_CHART.DEFAULT;
                column.style.cursor = "pointer";
              }
            }
          }
          // multiple columns are selected
          else {
            for (let index = 0; index < columns.length; index++) {
              const column = columns[index];
              column.style.cursor = "pointer";
              if (index >= filter.index.start && index <= filter.index.end) {
                column.style.fill = COLORS_COLUMN_CHART.SELECTED;
              } else {
                column.style.fill = COLORS_COLUMN_CHART.DEFAULT;
              }
            }
          }
        }
      }
    }, 200);
  };

  const options = {
    chart: {
      height: 205,
      type: "bar",
      animations: {
        enabled: false,
      },

      events: {
        dataPointSelection: (_event, _chartContext, config) => {
          const index = config.dataPointIndex;
          setIndex(index, index);
          if (!isFirstReloadColor) {
            executeAfter(1000, () => {
              dispatch(staffRevenueActions.reloadColorChart());
              setIsFirstReloadColor(true);
            });
          } else {
            dispatch(staffRevenueActions.reloadColorChart());
          }
          if (window.innerWidth > 1200) {
            dispatch(
              staffRevenueActions.setShowSelectedRange({
                isShow: false,
              }),
            );
          }
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return renderToString(
          <div
            className="tooltip-chart-staff-revenue"
            style={
              w?.globals?.clientX + COLUMN_WIDTH / 2 < w?.globals?.gridWidth + COLUMN_WIDTH * 2 + COLUMN_GAP
                ? {
                    marginLeft: "25px",
                  }
                : { marginRight: "25px" }
            }
          >
            <div className="line-info-tooltip">
              <span className="line-info-tooltip__title">{`${t("report.staff.numberOfStaff")}: `}</span>
              <span className="line-info-tooltip__value">{`${listStaffNumber[dataPointIndex]} ${
                listStaffNumber[dataPointIndex] > 1
                  ? t("report.staff.employees").toLowerCase()
                  : t("report.staff.employee").toLowerCase()
              }`}</span>
            </div>
            <div className="line-info-tooltip">
              <span className="line-info-tooltip__title">{`${t("report.staff.revenue")}: `}</span>
              <span className="line-info-tooltip__value">
                {maxRevenue > 0 ? (
                  <>
                    {`${columns[dataPointIndex]?.fromRevenueFormatUnit}-${
                      columns[dataPointIndex]?.toRevenueFormatUnit
                    } ${t(`${NumberUnitLocalization[numberUnitType]}`).toLowerCase()} ${currency}`}
                  </>
                ) : (
                  <>{`0 ${currency}`}</>
                )}
              </span>
            </div>
            <hr className="divide-info-tooltip" />
            <div className="tip-chart-staff">
              <TipIcon className="tip-chart-staff__icon" />
              <span className="tip-chart-staff__description">{t("report.staff.tipOnTooltip")}</span>
            </div>
          </div>,
        );
      },
      style: {
        marginLeft: "30px",
      },
      inverseOrder: false,
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 12,
        dataLabels: {
          position: "top",
        },
        columnWidth: "80%",
      },
    },

    grid: { show: false },
    dataLabels: {
      enabled: showStaffNumber,
      formatter: function (_, e) {
        return listStaffNumber[e.dataPointIndex];
      },
      offsetY: -20,
      style: {
        fontSize: "16px",
        colors: ["#304758"],
        fontWeight: 400,
      },
    },

    xaxis: {
      categories: [...listRevenue.map((item) => item.toString())],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      labels: {
        show: listRevenue?.length > 0,
        rotateAlways: false,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          fontWeight: 400,
          marginTop: "20px",
          cssClass: "apexcharts-xaxis-label wrapper-chart-staff-revenue__xaxis-label",
        },
        offsetX: 3,
        offsetY: 0,
        format: undefined,
        formatter: undefined,
        datetimeUTC: true,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "HH:mm",
        },
      },
      title: {
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "25px",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 575,
        options: {
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft",
              offsetY: -150,
              offsetX: -22,
            },
          },
          xaxis: {
            labels: {
              rotateAlways: false,
              offsetX: 0,
            },
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Staff-Revenue",
      data: listStaffNumber.map((item) =>
        item > minHeightChart ? item : item >= 1 ? minHeightChart : heightChartValue0,
      ),
    },
  ];

  return <ReactApexChart key={id} options={options} series={series} type="bar" height={250} />;
}

export default React.memo(StaffRevenueChart);
