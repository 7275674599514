import { Card, Col, Form, Row, Select, Skeleton, Space, Typography, message } from "antd";
import { Printer } from "components/Printer";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { CustomDownIcon, PrinterOutlineIcon, SelectCheckedIcon } from "constants/icons.constants";
import { PrinterSettingType } from "constants/store-setting.constants";
import useBuildBillTemplate from "hooks/useBuildBillTemplate";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { storeInfoSelector } from "store/modules/session/session.reducers";
import { executeAfter } from "utils/helpers";
import i18n from "utils/i18n";
import { defaultBillConfigData, dynamicData, sampleItemList } from "./default.data";
import "./index.scss";

export default function OrderSlipPage(props) {
  const { t, orderSlipDataService, onChangeForm, activeTab } = props;
  const [form] = Form.useForm();
  const orderSlipTemplateRef = React.useRef(null);
  const { Option } = Select;
  const storeInfo = useSelector(storeInfoSelector);
  const [allOrderSlipConfiguration, setAllOrderSlipConfiguration] = useState();
  const [buildTemplate, setBuildTemplate] = useBuildBillTemplate();
  const [currentTemplateConfig, setCurrentTemplateConfig] = useState(null);
  const [widthOrderSlipTemplate, setWidthOrderSlipTemplate] = useState(0);
  const [sampleTemplate, setSampleTemplate] = useState(null);
  const [orderSlipTemplateWithConfig, setOrderSlipTemplateWithConfig] = useState("");
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const pageData = {
    orderSlipTemplate: t("orderSlip.orderSlipTemplate"),
    btnPrintPreview: t("button.printPreview"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.saveChanges"),
    smallSizeOption: t("orderSlip.smallSizeOption"),
    mediumSizeOption: t("orderSlip.mediumSizeOption"),
    configuration: t("orderSlip.configuration"),
    showOrderSessionNo: t("orderSlip.showOrderSessionNo"),
    showAreaAndTable: t("orderSlip.showAreaAndTable"),
    showCreatedSessionTime: t("orderSlip.showCreatedSessionTime"),
    showCompletedSessionTme: t("orderSlip.showCompletedSessionTme"),
    showItemPrices: t("orderSlip.showItemPrices"),
    showTopping: t("orderSlip.showTopping"),
    showOption: t("orderSlip.showOption"),
    configureSuccessfully: t("orderSlip.configureSuccessfully"),
    frameSize: t("orderSlip.frameSize"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    selectOptionDescription: t("orderSlip.selectOptionDescription"),
    preview: t("orderSlip.preview"),
    itemNote: t("orderSlip.itemNote"),
  };

  const staticLabel = {
    storeName: storeInfo?.storeName,
  };

  const labelTranslated = {
    title: t("orderSlip.titleLabel"),
    sessionOrderNoLabel: t("orderSlip.sessionOrderNoLabel"),
    locationLabel: t("orderSlip.locationLabel"),
    createdSessionTimeLabel: t("orderSlip.createdSessionTimeLabel"),
    completedSessionTimeLabel: t("orderSlip.completedSessionTimeLabel"),
    itemNameColumnLabel: t("orderSlip.itemNameColumnLabel"),
    quantityColumnLabel: t("orderSlip.quantityColumnLabel"),
    unitPriceColumnLabel: t("orderSlip.unitPriceColumnLabel"),
    smallUnitPriceColumnLabel: t("orderSlip.smallUnitPriceColumnLabel"),
    totalAmountColumnLabel: t("orderSlip.totalAmountColumnLabel"),
    totalItemLabel: t("orderSlip.totalItemLabel"),
    priorityItemsLabel: t("orderSlip.priorityItemsLabel"),
    normalItemsLabel: t("orderSlip.normalItemsLabel"),
    noteLabel: t("orderSlip.note"),
    kitchenLabel: t("orderSlip.kitchen"),
  };

  const contentTranslated = {
    ...dynamicData,
    kitchenName: t("mockupData.orderSlip.kitchenName", dynamicData.kitchenName),
    locationValue: t("mockupData.orderSlip.locationValue", dynamicData.locationValue),
  };

  const sampleItemListTranslated = sampleItemList?.map((item, index) => ({
    ...item,
    itemName: t(`mockupData.orderSlip.orderItem${index}.productName`, item?.itemName),
    toppings: item?.toppings?.map((topping, indexTopping) => ({
      ...topping,
      itemName: t(`mockupData.orderSlip.orderItem${index}.topping${indexTopping}.name`, topping?.itemName),
      toppings: topping?.toppings?.map((topping, indexTopping) => ({
        ...topping,
        itemName: t(
          `mockupData.orderSlip.orderItem${index}.topping${indexTopping}.topping${indexTopping}.name`,
          topping?.itemName,
        ),
      })),
    })),
    options: item?.options?.map((option, indexOption) => ({
      ...option,
      itemName: t(`mockupData.orderSlip.orderItem${index}.option${indexOption}`, option?.itemName),
    })),
    notes: t(`mockupData.orderSlip.orderItem${index}.notes`, item?.notes),
  }));

  // Static data for bill
  const [currentOrderSlipConfigData, setCurrentOrderSlipConfigData] = useState({
    ...defaultBillConfigData,
    //...staticData,
    ...staticLabel,
    //...labelTranslated,
  });

  useEffect(() => {
    if (activeTab === PrinterSettingType.ORDER_SLIP) {
      initDefaultTemplateAndConfig();
    } else {
      setSampleTemplate(null);
      form.resetFields();
      setIsChangeForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (buildTemplate && currentTemplateConfig) {
      const { template } = currentTemplateConfig;
      getPreviewTemplateWithSampleData(template);
      getTemplateToSave(template);
    }
  }, [i18n.language]);

  /// render template
  useEffect(() => {
    executeAfter(500, () => {
      if (buildTemplate && currentTemplateConfig) {
        const { template } = currentTemplateConfig;
        getPreviewTemplateWithSampleData(template);
        getTemplateToSave(template);
      } else {
        setSampleTemplate(null);
        setOrderSlipTemplateWithConfig(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildTemplate, currentTemplateConfig, currentOrderSlipConfigData]);

  const getPreviewTemplateWithSampleData = (template) => {
    const params = {
      ...contentTranslated, // Mockup dynamic data
      ...labelTranslated,
      ...currentOrderSlipConfigData,
    };
    const myTemplate = buildTemplate(template, sampleItemListTranslated, params);
    setSampleTemplate(myTemplate);
  };

  const getTemplateToSave = (template) => {
    const configParams = {
      ...currentOrderSlipConfigData,
    };
    const myTemplateWithConfig = buildTemplate(template, [], configParams);
    setOrderSlipTemplateWithConfig(myTemplateWithConfig);
  };

  const initDefaultTemplateAndConfig = async () => {
    const res = await orderSlipDataService.getOrderSlipConfigurationAsync();
    const { orderSlipConfigs } = res;
    setAllOrderSlipConfiguration(orderSlipConfigs);
    const defaultTemplateConfig = orderSlipConfigs?.find((i) => i.config.isDefault === true);
    setBuildTemplate(defaultTemplateConfig.template.buildTemplate);
    const defaultConfig = defaultTemplateConfig.config;
    setCurrentTemplateConfig(defaultTemplateConfig);
    setWidthOrderSlipTemplate(defaultConfig?.billFrameWidthPage);

    if (defaultConfig) {
      form.setFieldsValue({
        orderSlipConfiguration: defaultConfig,
      });

      updateOrderSlipConfigData(defaultConfig.config);
    }
  };

  const printTemplate = () => {
    if (orderSlipTemplateRef && orderSlipTemplateRef.current) {
      orderSlipTemplateRef.current.printTemplate();
    }
  };

  const updateOrderSlipConfigData = (configValues) => {
    if (!configValues) {
      const formValues = form.getFieldsValue();
      const { orderSlipConfiguration } = formValues;
      configValues = orderSlipConfiguration;
    }

    const {
      isShowOrderSessionNo,
      isShowAreaTable,
      isShowCreatedSessionTime,
      isShowCompletedSessionTime,
      isShowItemPrices,
      isShowTopping,
      isShowOption,
      isShowItemNote,
      isShowOrderNote,
    } = configValues;
    const newOrderSlipConfigData = {
      ...currentOrderSlipConfigData,
      sessionOrderNoVisible: isShowOrderSessionNo ? "inline-flex" : "none",
      locationVisible: isShowAreaTable ? "inline-flex" : "none",
      createdSessionTimeVisible: isShowCreatedSessionTime ? "inline-flex" : "none",
      completedSessionTimeVisible: isShowCompletedSessionTime ? "inline-flex" : "none",
      toppingVisible: isShowTopping ? "block" : "none",
      optionVisible: isShowOption ? "block" : "none",
      itemPriceVisible: isShowItemPrices ? "inline-flex" : "none",
      itemPriceInVisible: !isShowItemPrices ? "inline-flex" : "none",
      itemNoteInVisible: isShowItemNote ? "inline-flex" : "none",
      orderNoteInVisible: isShowOrderNote ? "inline-flex" : "none",
    };

    setCurrentOrderSlipConfigData(newOrderSlipConfigData);
  };

  const onChangeFrameSize = (frameSizeKey) => {
    const templateConfig = allOrderSlipConfiguration.find((x) => x.config.billFrameSize === frameSizeKey);
    setWidthOrderSlipTemplate(templateConfig?.config?.billFrameWidthPage);

    form.setFieldsValue({
      orderSlipConfiguration: templateConfig.config,
    });

    // Update template and config selected
    if (templateConfig.template.buildTemplate) {
      setBuildTemplate(templateConfig.template.buildTemplate);
    }
    setCurrentTemplateConfig(templateConfig);
    updateOrderSlipConfigData(templateConfig.config);
  };

  const onFinish = async (values) => {
    let submitData = { ...values.orderSlipConfiguration };
    if (submitData.passwordData === undefined) {
      submitData.passwordData = null;
    }
    if (orderSlipTemplateWithConfig) {
      submitData.template = orderSlipTemplateWithConfig;
    }

    const res = await orderSlipDataService.updateOrderSlipConfigurationAsync({
      orderSlipConfiguration: submitData,
    });

    if (res) {
      onChangeForm(false);
      initDefaultTemplateAndConfig(values?.billConfiguration?.billFrameSize);
      message.success(pageData.configureSuccessfully);
    }

    setIsChangeForm(false);
  };

  const onFieldsChange = () => {
    onChangeForm(true);
    setIsChangeForm(true);
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onOK = () => {
    setIsChangeForm(false);
  };

  const cancelChange = () => {
    onChangeForm(false);
    setIsChangeForm(false);
    initDefaultTemplateAndConfig();
  };

  return (
    <Card bordered={false} className="fnb-card-full receipt-card item-card-printer-item">
      <Form
        autoComplete="off"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        form={form}
        onChange={(e) => updateOrderSlipConfigData()}
        onValuesChange={onFieldsChange}
      >
        <Row gutter={[24, 24]} className="receipt-config-card-header">
          <Col lg={10} md={12} sm={24}></Col>
          <Col lg={14} md={12} sm={24} className="card-button text-right" style={{ width: "100%" }}>
            <Space className="group-button-item-printer">
              <FnbButton
                iconHeader={<PrinterOutlineIcon />}
                onClick={printTemplate}
                variant="tertiary"
                text={pageData.btnPrintPreview}
              />
              {isChangeForm && (
                <div className="action-button">
                  <FnbButton minWidth={100} onClick={cancelChange} variant="tertiary" text={pageData.btnCancel} />
                  <FnbButton minWidth={100} type="submit" text={pageData.btnSave} />
                </div>
              )}
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 0]} className="receipt-config-card-body">
          <Col xl={16} lg={12} md={12} sm={24} className="receipt-form">
            <Row>
              <Col span={24}>
                <h4 className="fnb-form-label">
                  {pageData.frameSize} <span className="require-symbol">*</span>
                </h4>
                <Form.Item className="select-size" name={["orderSlipConfiguration", "billFrameSize"]}>
                  <Select
                    suffixIcon={<CustomDownIcon></CustomDownIcon>}
                    onChange={onChangeFrameSize}
                    size="large"
                    placeholder="Select frame size"
                    autoComplete="none"
                    className="select-single"
                    popupClassName="fnb-select-single-dropdown"
                    menuItemSelectedIcon={<SelectCheckedIcon style={{ padding: "5px" }} />}
                  >
                    <Option key={0} value={0}>
                      <p className="option-frame-size">{pageData.smallSizeOption}</p>
                    </Option>
                    <Option key={1} value={1}>
                      <p className="option-frame-size">{pageData.mediumSizeOption}</p>
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Typography className="select-option-description">{pageData.selectOptionDescription}</Typography>
              </Col>
            </Row>

            <Row>
              <Col span={24} className="configuration-form">
                <Row className="order-session-no-container">
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowOrderSessionNo"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showOrderSessionNo}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowAreaTable"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showAreaAndTable}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowCreatedSessionTime"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showCreatedSessionTime}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowCompletedSessionTime"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showCompletedSessionTme}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowItemPrices"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showItemPrices}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowTopping"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showTopping}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowItemNote"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.itemNote}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["orderSlipConfiguration", "isShowOption"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showOption}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xl={8} lg={12} md={12} sm={24} className="template-preview">
            <p className="preview-text">{pageData.preview}</p>
            {!sampleTemplate && <Skeleton active paragraph={{ rows: 30 }} style={{ paddingTop: "24px" }} />}
            <Row className="template-preview-container">
              {sampleTemplate && (
                <Printer ref={orderSlipTemplateRef} htmlContent={sampleTemplate} widthBill={widthOrderSlipTemplate} />
              )}
            </Row>
          </Col>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onOK}
        isChangeForm={isChangeForm}
      />
    </Card>
  );
}
