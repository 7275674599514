import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbButton } from "components/fnb-button/fnb-button";
import { Fragment } from "react";
import { Image } from "antd";
import { images } from "constants/images.constants";
import languageService from "services/language/language.service";
import theme from "theme";
import "./index.scss";

export const DialogHowToSetupProductVariants = (props) => {
  const { isShowGuideProductVariants, handleHiddenGuideProductVariants } = props;
  const [t] = useTranslation();

  const pageData = {
    button: {
      close: t("button.close", "Close"),
    },
    howToSetUpProductVariants: t("productManagement.hint.howToSetUpProductVariants"),
    description: t("productManagement.hint.productVariantsDialog.description"),
    example: t("productManagement.hint.productVariantsDialog.example"),
  };

  const styleImage = { border: `1px solid ${theme.colors.primary[100]}`, borderRadius: "12px" };

  const getImageByLanguageCode = () => {
    switch (languageService.getLang()) {
      case "vi":
        return images.howToSetUpProductVariantVI;
      default:
        return images.howToSetUpProductVariantEN;
    }
  };

  const content = () => {
    return (
      <Fragment>
        <div className="guide__dv-bg guide__dv-bg--rear">
          <div className="guide__dv-bg guide__dv-bg--fore">
            <p className="guide__dv-sec">{parse(pageData.description)}</p>
            <div className="guide__dv-sec">
              {parse(pageData.example)}
              <div className="guide__dv-img">
                <Image style={styleImage} src={getImageByLanguageCode()} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <FnbModal
      width={1200}
      className="guide"
      title={pageData.howToSetUpProductVariants}
      visible={isShowGuideProductVariants}
      handleCancel={handleHiddenGuideProductVariants}
      content={content()}
      footer={<FnbButton text={pageData.button.close} onClick={handleHiddenGuideProductVariants} minWidth={"120px"} />}
      bodyStyle={{ height: `calc(100vh - 241px)` }}
    ></FnbModal>
  );
};
