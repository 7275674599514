import { Button, Col, InputNumber, Radio, Row, Table, message } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbSteps, Step } from "components/fnb-step/fnb-step";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import {
  CalendarNewIcon,
  CardTickIcon,
  ChoosePackageStepIcon,
  CompletedIcon,
  CompletedStepIcon,
  InfoOutLineIcon,
  PaymentStepIcon,
  PaymentSuccessIcon,
} from "constants/icons.constants";
import {
  enumPackagePaymentMethod,
  orderPackagePaymentMethod,
  orderPackagePaymentMethods,
} from "constants/payment-method.constants";
import { Region } from "constants/region.constants";
import { DateFormat, blank, blankWindowDimensions, paymentStatus } from "constants/string.constants";
import orderPackagePaymentTransactionService from "data-services/order-package-payment-transaction/order-package-payment-transaction.service";
import paymentDataService from "data-services/payment/payment-data.service";
import storeDataService from "data-services/store/store-data.service";
import { env } from "env";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatCurrency, formatCurrencyUSD, formatCurrencyWithSymbol, formatNumber } from "utils/helpers";
import { BankTransferInfoComponent } from "../../billing/components/bank-transfer-info.component";
import "./branch-purchase-package.scss";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { localStorageKeys } from "utils/localStorage.helpers";
import paypalService from "services/paypal.service";

export function BranchPurchasePackage(props) {
  const [t] = useTranslation();
  const history = useHistory();

  const packagePricingStep = {
    selectPackage: 0,
    payment: 1,
    complete: 2,
    completeByBankTransfer: 3,
    completeByPayPal: 4,
  };

  const dateTimeRange = 100;
  const isInternationalRegion = env.REACT_APP_REGION === Region.International;
  const [bankTransferData, setBankTransferData] = useState(null);
  const [currentStep, setCurrentStep] = useState(packagePricingStep.payment);
  const [paymentMethod, setPaymentMethod] = useState(
    isInternationalRegion ? orderPackagePaymentMethod.paypal : orderPackagePaymentMethod.transfer,
  );
  const [stepStatus, setStepStatus] = useState(false);

  /// The package data bought has been updated after the user create payment.
  const [packageBought, setPackageBought] = useState(null); /// The current package info
  const [lastPackageBought, setLastPackageBought] = useState(null); /// The current package info
  const [packagePaymentResult, setPackagePaymentResult] = useState(null);
  const [expiryDateSelected, setExpiryDateSelected] = useState();
  const [branchPackagePricings, setBranchPackagePricings] = useState([]);
  const [packageName, setPackageName] = useState("");
  const [processPaymentLoading, setProcessPaymentLoading] = useState(false);
  const [dataAmountCal, setDataAmountCal] = useState(null);

  const pageData = {
    paymentDuration: t("package.paymentDuration"),
    comingSoon: t("package.comingSoon"),
    titleImportData: t("package.titleImportData"),
    selectPackage: t("package.selectPackage"),
    payment: t("package.paymentPackage"),
    complete: t("package.completePackage"),
    month: t("package.payment.month"),
    months: t("package.payment.months"),
    vat: "VAT",
    percent: "%",
    summary: t("package.payment.summary"),
    package: t("package.payment.package"),
    duration: t("package.payment.duration"),
    packagePrice: t("package.payment.packagePrice"),
    total: t("package.payment.total"),
    choosePaymentMethod: t("package.payment.choosePaymentMethod"),
    visa: t("package.payment.visa"),
    atm: t("package.payment.atm"),
    bankTransfer: t("package.payment.bankTransfer"),
    paymentMethod: t("package.payment.paymentMethod"),
    pleaseCheckPayment: t("package.text.pleaseCheckPayment"),
    accountOwner: t("package.text.accountOwner"),
    accountNumber: t("package.text.accountNumber"),
    bankName: t("package.text.bankName"),
    branch: t("package.text.branch"),
    content: t("package.text.content"),
    backToDashboard: t("package.backDashBoard"),
    signInSuccess: t("signIn.youHaveBeenLoggedInSuccessfully"),
    signInHasPermissions: t("signIn.youHaveNoPermissions"),
    buySuccess: t("package.text.buySuccess"),
    registerSuccess: t("package.text.registerSuccess"),
    processPayment: t("package.text.processPayment"),
    backToPackageDetail: t("package.text.backToPackageDetail"),
    orderCode: t("package.text.orderCode"),
    contactInfo: t("package.text.contactInfo"),
    remainingAmount: t("store.branchPurchase.remainingAmount"),
    packageName: t("store.branchPurchase.packageName"),
    quantity: t("store.branchPurchase.quantity"),
    extendQuantity: t("store.branchPurchase.extendQuantity"),
    unitPrice: t("store.branchPurchase.unitPrice"),
    expiryDate: t("store.branchPurchase.expiryDate"),
    totalPrice: t("store.branchPurchase.totalPrice"),
    unitPriceTooltip: t("store.branchPurchase.unitPriceTooltip"),
    totalBranch: t("package.totalBranch"),
    additionBranchQuantity: t("package.additionBranchQuantity"),
    paypalFee: t("store.branchPurchase.paypalFee"),
    validPaypalMessage: t("package.payment.validPaypalMessage"),
    registerPackageByPaypalSuccess: t("package.text.registerPackageByPaypalSuccess"),
    registerPackageByPaypalFail: t("package.text.registerPackageByPaypalFail"),
  };

  const packagePricingStatus = [
    {
      key: packagePricingStep.selectPackage,
      title: pageData.selectPackage,
      icon: <ChoosePackageStepIcon />,
    },
    {
      key: packagePricingStep.payment,
      title: pageData.payment,
      icon: <PaymentStepIcon />,
    },
    {
      key: packagePricingStep.complete,
      title: pageData.complete,
      icon: <CompletedStepIcon />,
    },
  ];

  const packageBoughtRef = useRef(packageBought);

  useEffect(() => {
    paypalService.removePayPalPaymentTransactionToLocalStorage();
    
    getCurrentPackageInfo();
  }, []);

  useEffect(() => {
    paypalService.removePayPalPaymentTransactionToLocalStorage();
  }, [currentStep, paymentMethod]);

  useEffect(() => {
    packageBoughtRef.current = packageBought;
  }, [packageBought]);

  function getCurrentPackageInfo() {
    storeDataService.getCurrentOrderPackageInfoAsync().then((res) => {
      const { orderPackageInfo, lastOrderPackage, branchPackagePricings } = res;
      const expiryDate = moment.utc(lastOrderPackage?.expiredDate).local(); /// Convert utc time to local time
      const activatedDate = moment.utc(orderPackageInfo?.activatedDate).local(); /// Convert utc time to local time
      const packageInfo = {
        ...orderPackageInfo,
        activatedDate: activatedDate,
        packageName: t(orderPackageInfo?.packageName),
        quantity: 1,
        unitPrice: orderPackageInfo?.costPerMonth,
        expiredDate: expiryDate,
        newExpiredDate: expiryDate,
        totalBranch: lastOrderPackage?.totalQuantity ?? 0,
      };

      /// Set default expiry date
      const expiryDateSelected = {
        id: 0,
        name: expiryDate.year(),
        expiryDate: expiryDate,
        expiryDateLabel: expiryDate.format(DateFormat.DD_MM_YYYY),
      };
      setExpiryDateSelected(expiryDateSelected);

      const { remainAmount, totalPrice, taxAmount, unitPrice } = calculateBranchPackagePrice(
        packageInfo,
        lastOrderPackage,
        expiryDateSelected,
        branchPackagePricings,
      );

      /// Get branch package pricing
      setBranchPackagePricings(branchPackagePricings);

      /// Set package info and package table detail
      setLastPackageBought({ ...lastOrderPackage });
      setPackageBought({
        ...packageInfo,
        unitPrice: unitPrice,
        remainAmount: remainAmount,
        totalPrice: totalPrice,
        taxAmount: taxAmount,
      });

      if (Boolean(paymentMethod) && paymentMethod === orderPackagePaymentMethod.paypal) {
        calPaypalFee(paymentMethod, totalPrice, remainAmount);
      }
    });
  }

  const calculateTaxAmount = (amount, tax) => {
    if (amount && tax) {
      const taxAmount = (amount * tax) / 100;
      return taxAmount;
    }

    return amount;
  };

  const calPaypalFee = (paymentMethod, totalPrice, remainAmount) => {
    const initialResponseCalculation = {
      payPalFee: 0,
      subTotal: 0,
      total: 0,
      vat: 0,
    };
    if (isNaN(totalPrice)) {
      setDataAmountCal(initialResponseCalculation);
      return;
    }

    paymentDataService.calcOrderPackageFeeAsync(paymentMethod, totalPrice, remainAmount).then((data) => {
      setDataAmountCal(data);
    });
  };

  const createBankTransferPayment = () => {
    setPaymentMethod(orderPackagePaymentMethod.transfer);
    const { orderPackageId, unitPrice, totalPrice, taxAmount, remainAmount, packageName, newExpiredDate, quantity } =
      packageBought;
    const createBankTransferModel = {
      paymentMethod: enumPackagePaymentMethod.bankTransfer,
      activateStorePackageId: orderPackageId,
      packageName: packageName,
      quantity: quantity,
      unitPrice: unitPrice,
      branchExpiredDate: newExpiredDate,
      branchPurchaseTotalPrice: totalPrice,
      remainAmount: remainAmount,
      taxAmount: taxAmount,
    };

    storeDataService.createBranchPurchaseOrderPackageAsync(createBankTransferModel).then((response) => {
      if (response) {
        const { accountBankTransfer, packageInfo } = response;
        const { packageCode, packageName, duration, totalAmount, packagePaymentMethod, paymentMethodName } =
          packageInfo;
        const { accountNumber, accountOwner, bankName, branch, content } = accountBankTransfer;
        const bankTransferResponseData = {
          packageCode: packageCode,
          packageName: packageName,
          duration: duration,
          totalAmount: totalAmount,
          packagePaymentMethodId: packagePaymentMethod,
          paymentMethodName: paymentMethodName,
        };
        const bankTransferInfo = {
          bankName: bankName,
          bankBranchName: branch,
          accountHolderName: accountOwner,
          accountNumber: accountNumber,
          content: content,
        };

        setPackagePaymentResult(bankTransferResponseData);
        setBankTransferData(bankTransferInfo);
        setCurrentStep(packagePricingStep.completeByBankTransfer);
        setStepStatus(true);
      }
    });
  };

  const onProcessPayment = async () => {
    setProcessPaymentLoading(true);
    switch (paymentMethod) {
      case orderPackagePaymentMethod.vnPay:
        break;
      case orderPackagePaymentMethod.atm:
        break;
      case orderPackagePaymentMethod.transfer:
        createBankTransferPayment();
        break;
      default:
    }
  };

  const getYearOptions = () => {
    let yearOptions = [];
    if (packageBought?.expiredDate) {
      for (var i = 0; i <= dateTimeRange; i++) {
        const expiryDate = moment(packageBought?.expiredDate); /// Convert utc time to local time
        const newExpiryDate = expiryDate.add(i, "Y"); /// Next year
        const newExpiryDateLabel = newExpiryDate.format(DateFormat.DD_MM_YYYY); /// Display date format DD/MM/YYYY
        const yearOption = {
          id: i,
          name: newExpiryDate.year(),
          expiryDate: newExpiryDate,
          expiryDateLabel: newExpiryDateLabel,
        };

        yearOptions.push(yearOption);
      }
    }

    return yearOptions;
  };

  const onChangeExpiryDate = (e) => {
    const yearOptions = getYearOptions();
    const expiryDateSelected = yearOptions?.find((item) => item.id === e);
    setExpiryDateSelected(expiryDateSelected);

    if (packageBought) {
      const { remainAmount, totalPrice, taxAmount, unitPrice } = calculateBranchPackagePrice(
        packageBought,
        lastPackageBought,
        expiryDateSelected,
        branchPackagePricings,
      );
      setPackageBought({
        ...packageBought,
        unitPrice: unitPrice,
        newExpiredDate: expiryDateSelected?.expiryDate,
        remainAmount: remainAmount,
        totalPrice: totalPrice,
        taxAmount: taxAmount,
      });
      calPaypalFee(paymentMethod, totalPrice, remainAmount);
    }
  };

  const onPayPalCreatePayment = useCallback(async () => {
    let partnerTransactionIdInLocal = localStorage.getItem(localStorageKeys.PAYPAL_PAYMENT_TRANSACTION_ID) ?? "";
    if (partnerTransactionIdInLocal) {
      return JSON.parse(partnerTransactionIdInLocal);
    }
    let { orderPackageId, unitPrice, totalPrice, taxAmount, remainAmount, packageName, newExpiredDate, quantity } =
      packageBoughtRef.current;
    let createPayPalRequestModel = {
      paymentMethod: enumPackagePaymentMethod.paypal,
      activateStorePackageId: orderPackageId,
      packageName: packageName,
      quantity: quantity,
      unitPrice: unitPrice,
      branchExpiredDate: newExpiredDate,
      branchPurchaseTotalPrice: totalPrice,
      remainAmount: remainAmount,
      taxAmount: taxAmount,
    };

    const response = await storeDataService.createBranchPurchaseOrderPackageAsync(createPayPalRequestModel);
    if (response?.partnerTransactionId) {
      setPackageName(response?.packageInfo?.packageName);
      paypalService.savePayPalPaymentTransactionInLocalStorage(JSON.stringify(response?.partnerTransactionId ?? ""));
      return response?.partnerTransactionId;
    } else {
      //todo Error
      return null;
    }
  }, []);

  const onPaypalApprove = useCallback(async (data) => {
    try {
      const request = {
        token: data?.orderID ?? "",
        payerId: data?.payerID ?? "",
      };
      const response = await orderPackagePaymentTransactionService.executePayPalPayment(request);
      if (response?.success) {
        setCurrentStep(packagePricingStep.completeByPayPal);
      } else {
        //todo update after
        message.error(pageData.registerPackageByPaypalFail);
      }
    } catch (error) {
      message.error(pageData.registerPackageByPaypalFail);
    }
  }, []);

  const onPaypalCancel = useCallback(async (data) => {
    //use after
    // message.error(pageData.registerPackageByPaypalFail);
  }, []);
  console.log("bị render 2222");
  const renderPayPalButton = useMemo(() => {
    console.log("bị render");
    let isDisable = true;
    if (isInternationalRegion) {
      isDisable = dataAmountCal?.total > 0 ? false : true;
    } else {
      isDisable = packageBought?.totalPrice - packageBought?.remainAmount + packageBought?.taxAmount > 0 ? false : true;
    }

    if (dataAmountCal?.payPalClientId) {
      return (
        <PayPalScriptProvider
          options={{
            clientId: dataAmountCal?.payPalClientId,
            locale: "en_US",
          }}
        >
          <PayPalButtons
            style={{ shape: "rect", layout: "vertical" }}
            className="w-100"
            disabled={isDisable}
            createOrder={onPayPalCreatePayment}
            onApprove={onPaypalApprove}
            onCancel={onPaypalCancel}
          />
        </PayPalScriptProvider>
      );
    }

    return <></>;
  }, [dataAmountCal]);

  const onChangeBranchQuantity = (quantity) => {
    const newPackageInfo = {
      ...packageBought,
      quantity: quantity ?? 0,
    };

    const { remainAmount, totalPrice, taxAmount, unitPrice } = calculateBranchPackagePrice(
      newPackageInfo,
      lastPackageBought,
      expiryDateSelected,
      branchPackagePricings,
    );
    setPackageBought({
      ...newPackageInfo,
      unitPrice: unitPrice,
      remainAmount: remainAmount,
      totalPrice: totalPrice,
      taxAmount: taxAmount,
    });
    calPaypalFee(paymentMethod, totalPrice, remainAmount);
  };

  const calculateBranchPackagePrice = (packageBought, lastPackageBought, expiryDateSelected, branchPackagePricings) => {
    const today = moment();
    if (packageBought && lastPackageBought) {
      let remainAmount = lastPackageBought?.remainAmount;

      /// Calculate total price of package from today to expiry date selected
      const { quantity } = packageBought;

      const currentBranchPackagePricing = branchPackagePricings?.find(
        (p) => p.minimumBranch <= quantity && quantity <= p.maximumBranch,
      );
      const unitPrice = currentBranchPackagePricing?.price / currentBranchPackagePricing?.period;
      const numberMonths = expiryDateSelected.expiryDate.startOf("day").diff(today.startOf("day"), "months");
      const totalPrice = unitPrice * numberMonths * quantity;
      const taxAmount = calculateTaxAmount(totalPrice, packageBought.tax);
      if (remainAmount > totalPrice) {
        remainAmount = totalPrice;
      }

      return {
        remainAmount: remainAmount,
        totalPrice,
        taxAmount,
        unitPrice,
      };
    }

    return {
      remainAmount: 0,
      totalPrice: 0,
      taxAmount: 0,
      unitPrice: 0,
    };
  };
  const renderPackagePayment = () => {
    const renderPaymentMethods = orderPackagePaymentMethods
      .filter((item) => item.disable === false && item.isInternationalPayment === isInternationalRegion)
      .map((method) => {
        const { name, icon, disable } = method;
        const singleIcon = method.icon && method.icon?.length > 0;
        const renderMethod = (
          <Row
            className={`payment-method-wrapper${singleIcon === true ? " multiple" : ""}${
              disable === true ? " disabled" : ""
            }`}
          >
            <Col span={24}>
              <div>
                <Radio value={method.code}>
                  {singleIcon === true ? (
                    <div className="payment-method-icons">
                      <div className="name">{t(name)}</div>
                      <div className="icons">
                        {method.icon?.map((icon, index) => {
                          return <div key={index}>{icon}</div>;
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="payment-method">
                      <div className="icon">{icon}</div>
                      <div className="name">{t(name)}</div>
                    </div>
                  )}
                </Radio>
              </div>
            </Col>
          </Row>
        );

        return renderMethod;
      });

    const columns = [
      {
        title: pageData.packageName,
        dataIndex: "packageName",
        width: "20%",
        render: (value) => {
          return <>{t(value)}</>;
        },
      },
      {
        title: pageData.totalBranch,
        dataIndex: "totalBranch",
        width: "14%",
        render: () => {
          return formatNumber(packageBought?.totalBranch);
        },
      },
      {
        title: pageData.additionBranchQuantity,
        dataIndex: "quantity",
        width: "14%",
        render: () => {
          return (
            <InputNumber
              className="fnb-input-number w-100"
              min={1}
              defaultValue={1}
              onChange={onChangeBranchQuantity}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        title: () => {
          return (
            <div className="unit-price-column-title">
              <span>{pageData.unitPrice}</span>
              <FnbTooltip placement="top" title={pageData.unitPriceTooltip}>
                <InfoOutLineIcon className="pointer" />
              </FnbTooltip>
            </div>
          );
        },
        dataIndex: "unitPrice",
        width: "15%",
        align: "center",
        render: (value) => {
          return <>{isInternationalRegion ? formatCurrencyUSD(value) : formatCurrency(value)}</>;
        },
      },
      {
        title: pageData.expiryDate,
        dataIndex: "expiredDate",
        width: "20%",
        align: "left",
        render: (value) => {
          const yearOptions = getYearOptions();
          return (
            <div className="date-year-picker-wrapper">
              <CalendarNewIcon className="calendar-icon" />
              <FnbSelectSingle
                className="date-year-picker"
                value={expiryDateSelected?.expiryDateLabel}
                option={yearOptions?.map((item) => ({
                  id: item.id,
                  name: item.name,
                }))}
                onChange={onChangeExpiryDate}
              />
            </div>
          );
        },
      },
      {
        title: pageData.totalPrice,
        dataIndex: "totalPrice",
        align: "right",
        render: (value) => {
          return <>{isInternationalRegion ? formatCurrencyUSD(value) : formatCurrency(value)}</>;
        },
      },
    ];

    return (
      <>
        <div className="mt-3">
          <Table className="branch-package-table" columns={columns} dataSource={[packageBought]} pagination={false} />
        </div>
        <Row className="payment-step mt-5">
          <Col xs={24} sm={24} md={24} lg={12} className="payment-method-options">
            <div>
              <p className="payment-method-area-label">{pageData.choosePaymentMethod}</p>
            </div>
            <Radio.Group
              className="payment-group"
              onChange={(e) => {
                const selectedValue = e.target.value;
                setPaymentMethod(selectedValue);
                if (selectedValue === orderPackagePaymentMethod.paypal) {
                  calPaypalFee(selectedValue, packageBought?.totalPrice, packageBought?.remainAmount);
                }
              }}
              value={paymentMethod}
              defaultChecked={paymentMethod}
            >
              {renderPaymentMethods}
            </Radio.Group>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="summary">
            <div className="header">
              <h2 className="title">{pageData.summary}</h2>
              <div className="package-name">
                <div className="tab">
                  <div>
                    <p>{pageData.package}</p>
                  </div>
                  <div>
                    <p>{packageBought?.packageName}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="label">
                <p className="material-view-text">{pageData.remainingAmount}</p>

                {/* VAT label */}
                {!isInternationalRegion && <p>{pageData.vat + "(" + packageBought?.tax + pageData.percent + ")"}</p>}
                {/* end VAT label */}

                <h3 className="total">{pageData.total}</h3>
              </div>
              <div className="info">
                <p className="material-view-text">
                  {isInternationalRegion
                    ? formatCurrencyUSD(packageBought?.remainAmount)
                    : formatCurrency(packageBought?.remainAmount)}
                </p>

                {/* VAT value */}
                {!isInternationalRegion && (
                  <p className="material-view-text">{formatCurrency(packageBought?.taxAmount)}</p>
                )}
                {/* end VAT value */}

                <h3 className="total">
                  {isInternationalRegion
                    ? formatCurrencyUSD(dataAmountCal?.total)
                    : formatCurrency(
                        packageBought?.totalPrice - packageBought?.remainAmount + packageBought?.taxAmount,
                      )}
                </h3>
              </div>
            </div>
            <div className="footer">
              {paymentMethod === orderPackagePaymentMethod.paypal ? (
                renderPayPalButton
              ) : (
                <Button
                  loading={processPaymentLoading}
                  icon={<CardTickIcon />}
                  className="process-payment-btn"
                  onClick={onProcessPayment}
                  disabled={paymentMethod === "" || !packageBought?.quantity}
                >
                  {pageData.processPayment}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const renderCompleteStep = () => {
    return (
      <div className="complete-step">
        <div className="image">
          <CompletedIcon />
        </div>
        <div className="message">
          <p
            dangerouslySetInnerHTML={{
              __html: <p>{t(pageData.buySuccess, { packageName: packageBought?.packageName })}</p>,
            }}
          ></p>
        </div>

        <div className="footer">
          <Button
            onClick={() => {
              history.push("/");
            }}
            className="back-btn"
          >
            {pageData.backToDashboard}
          </Button>
        </div>
      </div>
    );
  };

  const renderCompleteBankTransferStep = () => {
    return (
      <div className="bank-transfer-complete-step">
        <div className="image">
          <PaymentSuccessIcon />
        </div>
        <div className="message">
          <p>{t(pageData.registerSuccess, { packageName: packagePaymentResult?.packageName })}</p>
        </div>
        <div className="order-package-info">
          <div className="order-package-info-card">
            <ul className="border-right">
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.orderCode}:</p>
                </div>
                <div>
                  <p className="value">{packagePaymentResult?.packageCode}</p>
                </div>
              </li>
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.package}:</p>
                </div>
                <div>
                  <p className="value">{packagePaymentResult?.packageName}</p>
                </div>
              </li>
            </ul>
            <ul>
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.duration}:</p>
                </div>
                <div>
                  <p className="value">
                    {packagePaymentResult?.duration} {pageData.months}
                  </p>
                </div>
              </li>
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.paymentMethod}:</p>
                </div>
                <div>
                  <p className="value">{packagePaymentResult?.paymentMethodName}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="body">
          <div>
            <p className="total-price">
              <span>{pageData.total}: </span>
              <span className="price">{formatCurrencyWithSymbol(packagePaymentResult?.totalAmount)}</span>
            </p>
          </div>
          <div className="message detail">
            <p>{pageData.pleaseCheckPayment}:</p>
          </div>

          <div className="bank-info">
            <BankTransferInfoComponent data={bankTransferData} />
          </div>
          <div
            className="contact"
            dangerouslySetInnerHTML={{
              __html: pageData.contactInfo,
            }}
          ></div>
        </div>

        <div className="footer">
          <Button
            onClick={() => {
              history.push("/");
            }}
            className="back-btn"
          >
            {pageData.backToDashboard}
          </Button>
        </div>
      </div>
    );
  };

  const renderCompletePaypalStep = () => {
    return (
      <div className="bank-transfer-complete-step">
        <div className="image">
          <CompletedIcon />
        </div>
        <div className="message">
          <p>{t(pageData.registerPackageByPaypalSuccess, { packageName: packageName })}</p>
        </div>
        <div className="footer">
          <Button
            onClick={() => {
              history.push("/");
            }}
            className="back-btn"
          >
            {pageData.backToDashboard}
          </Button>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    switch (currentStep) {
      case packagePricingStep.selectPackage:
      default:
      case packagePricingStep.payment:
        return renderPackagePayment();
      case packagePricingStep.complete:
        return renderCompleteStep();
      case packagePricingStep.completeByBankTransfer:
        return renderCompleteBankTransferStep();
      case packagePricingStep.completeByPayPal:
        return renderCompletePaypalStep();
    }
  };

  /// Main render
  return (
    <>
      <Row className="buy-package-steps">
        <Col span={24}>
          <FnbSteps currentStep={currentStep}>
            {packagePricingStatus.map((item) => (
              <Step isProcessing={stepStatus} key={item.title} title={item.title} icon={item.icon} />
            ))}
          </FnbSteps>
        </Col>
      </Row>
      <Row className="package-info">
        <Col span={24}>{renderBody()}</Col>
      </Row>
    </>
  );
}
