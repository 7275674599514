import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: undefined,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    resetNotifications: (state) => {
      state.notifications = undefined;
    },
  },
});

export const notificationActions = notificationSlice.actions;

export const notificationSelector = (state) => state.notification;

export const notificationDetailSelector = (state) => state.notification?.notificationDetail;

const notificationReducer = notificationSlice.reducer;

export default notificationReducer;
