import { Card, message, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { CloneIcon, DeleteOutlined, EditOutlined, MinusCircleOutlineIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { QRCodeStatus } from "constants/qr-code.constants";
import qrCodeDataService from "data-services/qr-code/qr-code-data.service";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setQrCodeData } from "store/modules/qr-code/qr-code.actions";
import { formatDate, hasPermission, Sort, tryJsonString } from "utils/helpers";
import { getStorage, localStorageKeys, setStorage } from "utils/localStorage.helpers";
import FilterQRCode from "./filter-qr-order.component";
import "../qr-order.page.scss";
import "./table-qr-order.component.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { DateFormat } from "constants/string.constants";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import theme from "theme";

export default function TableQROrder(props) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [countFilter, setCountFilter] = useState(0);
  const [branches, setBranches] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [targets, setTargets] = useState([]);
  const [status, setStatus] = useState([]);
  const [pageNumberFilter, setPageNumberFilter] = useState(null);
  const filterPopoverRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isShowStopDialog, setIsShowStopDialog] = useState(false);
  const [stopData, setStopData] = useState();

  const pageData = {
    no: t("table.no"),
    eventName: t("marketing.qrCode.eventName"),
    serviceType: t("reportRevenue.serviceType"),
    target: t("marketing.qrCode.target"),
    discount: t("promotion.table.discount"),
    time: t("promotion.table.time"),
    status: t("promotion.table.status"),
    action: t("promotion.table.action"),
    amount: t("promotion.form.amount"),
    maximum: t("promotion.form.maximum"),
    start: t("marketing.qrCode.start"),
    end: t("marketing.qrCode.end"),
    search: t("promotion.search"),
    btnFilter: t("button.filter"),
    allBranches: t("material.filter.branch.all"),
    allTypes: t("order.allTypes"),
    allTargets: t("marketing.qrCode.allTargets"),
    all: t("order.all"),
    confirmStop: t("leaveDialog.confirmStop"),
    confirmStopQrCode: t("marketing.qrCode.confirmStop"),
    button: {
      btnIgnore: t("button.ignore"),
      btnStop: t("button.stop"),
      btnDelete: t("button.delete"),
      btnIgnoreDelete: t("marketing.qrCode.ignoreText"),
      editButton: t("button.edit"),
      btnClone: t("button.clone"),
    },
    stop: t("button.stop"),
    stopQrCodeSuccess: t("marketing.qrCode.stopQrCodeSuccess"),
    stopQrCodeFail: t("marketing.qrCode.stopQrCodeFail"),
    confirmDeleteMessage: t("marketing.qrCode.confirmDeleteMessage"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    deleteQrCodeSuccess: t("marketing.qrCode.deleteQrCodeSuccess"),
    deleteQrCodeFail: t("marketing.qrCode.deleteQrCodeFail"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.no,
        dataIndex: "index",
        key: "no",
        align: "left",
        width: "5rem",
        render: (value) => value + 1,
      },
      {
        title: pageData.eventName,
        dataIndex: "eventName",
        key: "eventName",
        align: "left",
        width: "30%",
        sorter: (pre, current) => Sort(pre?.eventName, current?.eventName),
        ellipsis: true,
        render: (_, record) => {
          return (
            <div className="text-line-clamp-2">
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.eventName}>
                <FnbTypography text={record?.eventName}></FnbTypography>
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.serviceType,
        dataIndex: "serviceType",
        key: "serviceType",
        width: "20%",
        sorter: (pre, current) => Sort(pre?.serviceType, current?.serviceType),
        ellipsis: true,
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.serviceTypeName}>
              {record?.serviceTypeName}
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.target,
        dataIndex: "target",
        key: "target",
        width: "24%",
        sorter: (pre, current) => Sort(pre?.target, current?.target),
        ellipsis: true,
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={t(record?.targetName)}>
              <FnbTypography text={t(record?.targetName)} />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.time,
        dataIndex: "time",
        key: "time",
        className: "grid-time-column",
        width: "18%",
        align: "right",
        render: (_, record) => {
          return (
            <div className="date-container">
              <FnbTypography text={`${pageData.start}: ${formatDate(record?.startDate, DateFormat.DD_MMM_YYYY)}`} />
              {record?.endDate && (
                <FnbTypography text={`${pageData.end}: ${formatDate(record?.endDate, DateFormat.DD_MMM_YYYY)}`} />
              )}
            </div>
          );
        },
      },
      {
        title: pageData.status,
        dataIndex: "status",
        key: "status",
        className: "grid-status-column",
        align: "center",
        width: "9rem",
        render: (_, record) => {
          switch (record?.statusId) {
            case QRCodeStatus.Schedule:
              return (
                <div className="grid-status-cell">
                  <FnbBadge variant="warning" text={t("promotion.status.scheduled")}></FnbBadge>
                </div>
              );
            case QRCodeStatus.Active:
              return (
                <div className="grid-status-cell">
                  <FnbBadge variant="success" text={t("promotion.status.active")}></FnbBadge>
                </div>
              );
            default:
              return (
                <div className="grid-status-cell">
                  <FnbBadge variant="error" text={t("promotion.status.finished")}></FnbBadge>
                </div>
              );
          }
        },
      },
    ];
    return columns;
  };

  useEffect(() => {
    setStorage(localStorageKeys.QR_ORDER_FILTER, null);
    setPageNumberFilter(currentPageNumber);
    var sessionQRCodeFilter = getStorage(localStorageKeys.QR_ORDER_FILTER);
    const qrCodeFilter = tryJsonString(sessionQRCodeFilter);
    if (qrCodeFilter !== null && qrCodeFilter.count > 0) {
      const data = {
        branchId: qrCodeFilter?.branchId ?? "",
        serviceTypeId: qrCodeFilter?.serviceTypeId ?? "",
        targetId: qrCodeFilter?.targetId ?? "",
        statusId: qrCodeFilter?.statusId ?? "",
        count: qrCodeFilter?.count ?? "",
      };
      handleFilterQRCode(data);
    } else {
      fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
    }
    getFilterData();
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch) => {
    const sessionQRCodeFilters = getStorage(localStorageKeys.QR_ORDER_FILTER);
    const qrCodeFilters = tryJsonString(sessionQRCodeFilters);

    const response = await qrCodeDataService.getAllQrCodeAsync(
      pageNumber,
      pageSize,
      keySearch,
      qrCodeFilters?.branchId ?? "",
      qrCodeFilters?.serviceTypeId ?? "",
      qrCodeFilters?.targetId ?? "",
      qrCodeFilters?.statusId ?? "",
    );

    const data = response?.qrCodes.map((s, index) => mappingRecordToColumns(s, index));

    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);

    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
    //Get initial data filter
    setCountFilter(qrCodeFilters?.count);
  };

  const mappingRecordToColumns = (qrCode, index) => {
    return {
      ...qrCode,
      no: index + 1,
      id: qrCode?.id,
      eventName: qrCode?.name,
      serviceType: qrCode?.serviceTypeName,
      target: t(qrCode?.targetName),
    };
  };

  const handleSearchByName = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        fetchDatableAsync(1, tableSettings.pageSize, keySearch);
      }, 500),
    );
  };

  const onChangePage = async (pageNumber, pageSize) => {
    fetchDatableAsync(pageNumber, pageSize, keySearch);
  };

  const getFilterData = async (event) => {
    var initialFilter = await qrCodeDataService.getPrepareDataFilterQrCodeAsync();
    if (initialFilter.qrCodeFilters.branches) {
      const allBranchOption = {
        id: "",
        name: pageData.allBranches,
      };
      const branchOptions = [allBranchOption, ...initialFilter.qrCodeFilters.branches];
      setBranches(branchOptions);
    }

    if (initialFilter.qrCodeFilters.serviceTypes) {
      const allServiceType = {
        id: "",
        name: pageData.allTypes,
      };
      const serviceTypes = [allServiceType, ...initialFilter.qrCodeFilters.serviceTypes];
      setServiceTypes(serviceTypes);
    }

    if (initialFilter.qrCodeFilters.targets) {
      const allTarget = {
        id: "",
        name: pageData.allTargets,
      };
      const targets = [allTarget, ...initialFilter.qrCodeFilters.targets];
      setTargets(targets);
    }

    if (initialFilter.qrCodeFilters.status) {
      const status = [...initialFilter.qrCodeFilters.status];
      setStatus(status);
    }
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
    setStorage(localStorageKeys.QR_ORDER_FILTER, null);
    setCountFilter(0);
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  };

  const filterComponent = () => {
    return (
      <FilterQRCode
        ref={filterPopoverRef}
        handleFilterQRCode={handleFilterQRCode}
        branches={branches}
        serviceTypes={serviceTypes}
        targets={targets}
        status={status}
      />
    );
  };

  const handleFilterQRCode = async (data) => {
    let req = {
      pageNumber: pageNumberFilter == null ? 1 : pageNumberFilter,
      pageSize: tableSettings.pageSize,
      keySearch: keySearch,
      branchId: data?.branchId ?? "",
      serviceTypeId: data?.serviceTypeId ?? "",
      targetId: data?.targetId ?? "",
      statusId: data?.statusId ?? "",
    };
    const response = await qrCodeDataService.getAllQrCodeAsync(
      req.pageNumber,
      req.pageSize,
      req.keySearch,
      req.branchId,
      req.serviceTypeId,
      req.targetId,
      req.statusId,
    );
    const result = response?.qrCodes.map((s, index) => mappingRecordToColumns(s, index));
    setDataSource(result);
    setTotalRecords(response.total);
    setCountFilter(data.count);
    setCurrentPageNumber(response.pageNumber);
  };

  const onCloneQrCode = async (qrCodeId) => {
    const response = await qrCodeDataService.getQrCodeByIdAsync(qrCodeId);
    const { qrCodeDetail } = response;
    dispatch(setQrCodeData(qrCodeDetail));

    history.push("/qr-order/clone");
  };

  const onStopQrCode = async (id) => {
    setIsShowStopDialog(false);
    await qrCodeDataService.stopQrCodeByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.stopQrCodeSuccess);
      } else {
        message.error(pageData.stopQrCodeFail);
      }
      fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
    });
    setSelectedRowKeys([]);
  };

  const onDeleteQrCode = async (id) => {
    setIsShowDeleteDialog(false);
    await qrCodeDataService.deleteQrCodeByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.deleteQrCodeSuccess);
      } else {
        message.error(pageData.deleteQrCodeFail);
      }
      fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
    });
    setSelectedRowKeys([]);
  };

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };
  const formatStopMessage = (name) => {
    let mess = t(pageData.confirmStopQrCode, { name: name });
    return mess;
  };

  const onRowClick = (record) => {
    history.push(`/marketing/qr-order/detail/${record?.id}`);
  };

  return (
    <Card className="fnb-card-custom w-100">
      <Row className="form-staff">
        <FnbTable
          className="table-striped-rows qr-code-table"
          columns={getColumns()}
          pageSize={tableSettings.pageSize}
          dataSource={dataSource}
          currentPageNumber={currentPageNumber}
          total={totalRecords}
          onChangePage={onChangePage}
          editPermission={PermissionKeys.EDIT_QR_CODE}
          deletePermission={PermissionKeys.DELETE_QR_CODE}
          scrollX={1300}
          search={{
            placeholder: pageData.search,
            onChange: handleSearchByName,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            renderActions: (selectedRowKeys, selectedRows) => (
              <>
                {selectedRowKeys.length <= 1 && selectedRows[0]?.statusId === QRCodeStatus.Schedule && (
                  <Fragment>
                    <FnbTooltip placement="top" title={pageData.button.editButton} zIndex={10}>
                      <FnbButton
                        permission={PermissionKeys.EDIT_QR_CODE}
                        variant="text"
                        className="row-edit-button"
                        text={<EditOutlined />}
                        onClick={() => {
                          history.push(`/qr-order/edit/${selectedRows[0]?.id}`);
                        }}
                      />
                    </FnbTooltip>
                    <FnbTooltip placement="top" title={pageData.button.btnDelete} zIndex={10}>
                      <FnbButton
                        permission={PermissionKeys.DELETE_QR_CODE}
                        variant="text"
                        className="row-delete-button"
                        text={<DeleteOutlined />}
                        onClick={() => {
                          setIsShowDeleteDialog(true);
                          setDeleteData(selectedRows[0]);
                        }}
                      ></FnbButton>
                    </FnbTooltip>
                  </Fragment>
                )}
                {selectedRowKeys.length <= 1 && selectedRows[0]?.statusId === QRCodeStatus.Active && (
                  <FnbTooltip placement="top" title={pageData.button.btnStop} zIndex={10}>
                    <FnbButton
                      permission={PermissionKeys.STOP_QR_CODE}
                      variant="text"
                      className="row-edit-button"
                      text={<MinusCircleOutlineIcon />}
                      onClick={() => {
                        setIsShowStopDialog(true);
                        setStopData(selectedRows[0]);
                      }}
                    />
                  </FnbTooltip>
                )}
                {selectedRowKeys.length <= 1 && (
                  <FnbTooltip placement="top" title={pageData.button.btnClone} zIndex={10}>
                    <FnbButton
                      permission={PermissionKeys.CREATE_QR_CODE}
                      variant="text"
                      className="row-edit-button"
                      text={<CloneIcon />}
                      onClick={() => onCloneQrCode(selectedRows[0]?.id)}
                    />
                  </FnbTooltip>
                )}
              </>
            ),
          }}
          filter={{
            totalFilterSelected: countFilter,
            onClearFilter: onClearFilter,
            buttonTitle: pageData.btnFilter,
            component: filterComponent(),
          }}
          onRowClick={hasPermission(PermissionKeys.VIEW_QR_CODE) && onRowClick}
          emptyText={
            hasPermission(PermissionKeys.CREATE_QR_CODE) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={() => history.push("/qr-order/create-new")}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
      </Row>
      <ConfirmDialogComponent
        visible={isShowDeleteDialog}
        title={pageData.confirmDelete}
        content={formatDeleteMessage(deleteData?.name)}
        okText={pageData.btnDelete}
        cancelText={pageData.btnIgnore}
        onOk={() => onDeleteQrCode(deleteData?.id)}
        onCancel={() => setIsShowDeleteDialog(false)}
        type={ConfirmStyle.DELETE}
      />
      <ConfirmDialogComponent
        visible={isShowStopDialog}
        title={pageData.confirmStop}
        content={formatStopMessage(stopData?.name)}
        okText={pageData.button.btnStop}
        cancelText={pageData.button.btnIgnore}
        onOk={() => onStopQrCode(stopData?.id)}
        tooltipTitle={pageData.stop}
        onCancel={() => setIsShowStopDialog(false)}
        type={ConfirmStyle.DELETE}
      />
    </Card>
  );
}
