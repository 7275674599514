import { Form, message, Row } from "antd";
import {
  DELAYED_TIME
} from "constants/default.constants";
import materialDataService from "data-services/material/material-data.service";
import materialCategoryDataService from "data-services/material-category/material-category-data.service";
import unitDataService from "data-services/unit/unit-data.service";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./edit-material.page.scss";
import { GeneralInformationMaterial } from "./general-Information-material.page";
import { PriceAndConversionMaterial } from "./ingredient-price-unit-conversion.page";
import { IngredientQuantityOfBranchMaterial } from "./ingredient-quantity-of-branch.page";
import { ImageAndOtherSettingMaterial } from "./image-other-setting.page";
import i18n from "utils/i18n";

export default function EditMaterialPage({ history, fromName, t, initData, onChangeForm, onChangeMaterialName }) {
  const fnbImageSelectRef = React.useRef();
  const param = useParams();
  const [form] = Form.useForm();
  const [units, setUnits] = useState([]);
  const [branches, setBranches] = useState([]);
  const [listMaterialCategory, setListMaterialCategory] = useState([]);
  const selectNewItemFuncs = React.useRef(null);
  const priceAndConversionMaterialRef = React.useRef(null);
  const [baseUnit, setBaseUnit] = useState(null);
  const [currentData, setCurrentData] = useState({});
  const [unitConversions, setUnitConversions] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [oldBranchIds, setOldBranchIds] = useState([]);

  const pageData = {
    generalInformation: t("material.generalInformation"),
    material: t("material.material"),
    name: t("material.name"),
    pleaseEnterMaterialName: t("material.pleaseEnterMaterialName"),
    maxLengthMaterialName: 255,
    description: t("form.description"),
    category: t("form.category"),
    selectCategory: t("form.selectCategory"),
    unitNameValidateMessage: t("productManagement.unit.unitNameValidateMessage"),
    pricing: t("material.pricing"),
    baseUnit: t("material.baseUnit"),
    tooltipCostPerUnit: t("material.tooltipCostPerUnit"),
    unitSelectPlaceholder: t("productManagement.unit.unitSelectPlaceholder"),
    unitNamePlaceholder: t("productManagement.unit.unitNamePlaceholder"),
    pleaseSelectUnit: t("productManagement.unit.pleaseSelectUnit"),
    btnAddImportUnit: t("productManagement.unitConversion.btnAddImportUnit"),
    currentCostPerUnit: t("material.currentCostPerUnit"),
    btnAddNewUnit: t("productManagement.unit.btnAddNewUnit"),
    isUpdatedSuccessfully: t("messages.isUpdatedSuccessfully"),
    allBranch: t("staffManagement.permission.allBranch"),
    media: {
      title: t("media.title"),
    },
    inventory: {
      title: t("material.inventory.title"),
      sku: t("material.inventory.sku"),
      skuPlaceholder: t("material.inventory.skuPlaceholder"),
      minQuantity: t("material.inventory.minQuantity"),
      minQuantityPlaceholder: t("material.inventory.minQuantityPlaceholder"),
      minQuantityTooltip: t("material.inventory.minQuantityTooltip"),
      branch: t("material.inventory.branch"),
      branchSelectPlaceholder: t("material.inventory.branchSelectPlaceholder"),
      branchValidateMessage: t("material.inventory.branchValidateMessage"),
      quantityPlaceholder: t("material.inventory.quantityPlaceholder"),
      quantityValidateMessage: t("material.inventory.quantityValidateMessage"),
      productHasExpiryDate: t("material.inventory.productHasExpiryDate"),
      quantityValidateMessage1min: t("material.inventory.quantityValidateMessage1min"),
      quantityValidateMessageMinimumOne: t("material.inventory.quantityValidateMessageMinimumOne"),
    },
  };

  useEffect(() => {
    fetchInitData();
    fetchMaterialCategory();
  }, [initData]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const fetchInitData = () => {
    if (initData) {
      const { units, materialCategories, branches, material, unitConversions } = initData;
      const { materialInventoryBranches, unit } = material;

      setUnits(units);
      setBranches(branches); // all branches used for dropdown list
      setCurrentData(material);
      setUnitConversions(unitConversions);
      setOldBranchIds(materialInventoryBranches?.map((materialBranch) => materialBranch?.branch?.id));
      const listMaterialInventoryBranches = materialInventoryBranches?.map((materialBranch) => {
        return {
          id: materialBranch?.id,
          name: materialBranch?.branch?.name,
          position: materialBranch?.position,
          quantity: materialBranch?.quantity,
          branchId: materialBranch?.branch?.id,
        };
      });

      const branchIds = materialInventoryBranches?.map((materialInventoryBranch) => {
        return materialInventoryBranch?.branch?.id;
      });

      let formValue = {
        id: param?.id,
        name: material?.name,
        description: material?.description,
        materialCategoryId: material?.materialCategory?.id,
        unitId: material?.unit?.id,
        sku: material?.sku,
        minQuantity: material?.minQuantity === 0 ? "" : material?.minQuantity,
        minQuantityUnitConversionId: material?.minQuantityUnitConversion?.id,
        hasExpiryDate: material?.hasExpiryDate,
        branchIds: branchIds,
        costPerUnit: material?.costPerUnit,
        quantity: listMaterialInventoryBranches.reduce((acc, obj) => {
          acc[obj?.branchId] = obj?.quantity;
          return acc;
        }, {}),
        ListDataUnitConversion: unitConversions,
        image: { url: material?.thumbnail },
      };
      setThumbnail(material?.thumbnail);

      /// Set value into select new item dropdown list component
      if (selectNewItemFuncs.current) {
        selectNewItemFuncs.current(unit?.id);
      }
      form.setFieldsValue(formValue);
      priceAndConversionMaterialRef.current.onUpdateListDataUnitConversion(unitConversions);
    }
  };

  const fetchMaterialCategory = (categoryId) => {
    materialCategoryDataService.getAllMaterialCategoriesAsync().then((res) => {
      if (res?.materialCategories) {
        setListMaterialCategory(res?.materialCategories);
        categoryId && form.setFieldValue("materialCategoryId", categoryId);
      }
    });
  };

  const fetchUnits = () => {
    unitDataService.getUnitsAsync().then((res) => {
      if (res.units) {
        setUnits([...res.units]);
      }
    });
  };

  const onEditMaterial = (values) => {
    form?.validateFields().then(() => {
      const quantities = form.getFieldValue('quantity');
      
      let req = {
        ...values,
        id: param?.id,
        logo: form.getFieldValue("image")?.url,
        unitConversions: values?.listDataUnitConversion,
        branches: quantities ? Object.keys(quantities)
        .filter((key) => quantities[key] > 0)
        .map((key) => ({
          quantity: quantities[key],
          position: branches?.findIndex((branch) => branch?.id === key),
          branchId: key,
        })) : [],
        listDataUnitConversion: null,
        quantity: null,
      };
      materialDataService.updateMaterialManagementAsync(req).then((res) => {
        if (res) {
          message.success(`${pageData.material} ${req.name} ${pageData.isUpdatedSuccessfully}`);
          onCancelEditMaterialForm();
        }
      });
    });
  };

  const onCancelEditMaterialForm = () => {
    onChangeForm(false);
    setTimeout(() => {
      return history.push("/inventory/material");
    }, DELAYED_TIME);
  };

  const onFieldsChange = (changedFields, allFields) => {
    onChangeForm(true);

    if (changedFields.some(field => field.name[0] === 'listDataUnitConversion')) {
      priceAndConversionMaterialRef.current.onUpdateListDataUnitConversion(form.getFieldValue('listDataUnitConversion'));
      form.validateFields(changedFields);
    }
  };

  return (
    <>
      <Form
        form={form}
        name={fromName}
        onFinish={onEditMaterial}
        onFieldsChange={onFieldsChange}
        autoComplete="off"
        className="custom-form material-form edit-material"
        layout="vertical"
      >
        <Row>
          <div className="left-component">
            <GeneralInformationMaterial
              t={t}
              form={form}
              listMaterialCategory={listMaterialCategory}
              onChangeMaterialName={onChangeMaterialName}
              onFetch={fetchMaterialCategory}
            />
            <PriceAndConversionMaterial
              t={t}
              form={form}
              units={units}
              fetchUnits={fetchUnits}
              ref={priceAndConversionMaterialRef}
              updateBaseUnit={setBaseUnit}
              unitConversions={unitConversions}
            />
            <IngredientQuantityOfBranchMaterial
              t={t}
              form={form}
              isCreatePage={false}
              branches={branches}
              baseUnitName={baseUnit?.name}
              oldBranchIds={oldBranchIds}
            />
          </div>
          <div className="right-component">
            <ImageAndOtherSettingMaterial
              t={t}
              form={form}
              hasExpiryDate={currentData?.hasExpiryDate}
              imageUrl={thumbnail}
            />
          </div>
        </Row>
      </Form>
    </>
  );
}
