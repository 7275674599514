import { useTranslation } from "react-i18next";
import { Empty, FnbEmptyCustom } from "./fnb-empty.styled";
import FnbTypography from "components/fnb-typography/fnb-typography";
import NoItems from "assets/images/no-item.png";
import theme from "theme";

const FnbEmpty = ({
  type = 1, // default = 1, customFnb = 2
}) => {
  const { t } = useTranslation();
  const pageData= {
    noDataFound: t("table.noDataFound")[0] + t("table.noDataFound").slice(1)?.toLowerCase()
  }
  return (
    <>
      {type === 1 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={pageData.noDataFound} />
      ) : (
        <FnbEmptyCustom>
          <img src={NoItems} alt="" />
          <FnbTypography
            text={pageData.noDataFound}
            color={theme.colors.gray[60]}
            variant={theme.typography["b2-regular"]}
          />
        </FnbEmptyCustom>
      )}
    </>
  );
};

export default FnbEmpty;
