import { Form, Radio } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { isJsonString } from "utils/helpers";
import { getStorage, localStorageKeys, setStorage } from "utils/localStorage.helpers";
import FnbRadioButton from "components/fnb-radio-button";
import "../product.scss";

const DEFAULT_VALUE = "";

const FilterProduct = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { categories, branches, onShowFilter, salesChannel, pageSize, keySearch, setDataFilter, fetchDataProducts } =
    props;

  const pageData = {
    filter: {
      button: t("button.clear"),
      branch: {
        title: t("productManagement.filter.branch.title"),
        all: t("productManagement.filter.branch.all"),
        placeholder: t("productManagement.filter.branch.placeholder"),
      },
      category: {
        title: t("productManagement.filter.category.title"),
        all: t("productManagement.filter.category.all"),
        placeholder: t("productManagement.filter.category.placeholder"),
      },
      salesChannel: {
        title: t("platform.title"),
      },
      status: {
        title: t("productManagement.filter.status.title"),
        all: t("productManagement.filter.status.all"),
        active: t("productManagement.filter.status.active"),
        inactive: t("productManagement.filter.status.inactive"),
      },
      resetallfilters: t("productManagement.filter.resetallfilters"),
    },
  };

  const branchOptions = [{
    id: "",
    name: pageData.filter.branch.all,
  }, ...branches];

  const categoryOptions = [{
    id: "",
    name: pageData.filter.category.all,
  }, ...categories];

  const salesChannelRadios = [{
    id: "",
    name: pageData.filter.status.all,
  }, ...salesChannel];

  useEffect(() => {
    var sessionProductFilter = getStorage(localStorageKeys.PRODUCT_FILTER);
    if (isJsonString(sessionProductFilter)) {
      var productFilter = JSON.parse(sessionProductFilter);
      if (productFilter && productFilter.count > 0) {
        form.setFieldsValue(productFilter);
      }
    }
  }, []);

  const onApplyFilter = () => {
    let formValue = form.getFieldsValue();
    formValue.count = countFilterControl(formValue);
    setStorage(localStorageKeys.PRODUCT_FILTER, JSON.stringify(formValue));
    fetchDataProducts(formValue, 1, pageSize, keySearch);
    setDataFilter(formValue);
  };

  const countFilterControl = (formValue) => {
    let countBranch = formValue.branchId === "" || formValue.branchId === undefined ? 0 : 1;

    let countCategory = formValue.productCategoryId === "" || formValue.productCategoryId === undefined ? 0 : 1;
    let countStatus = formValue.statusId === "" || formValue.statusId === undefined ? 0 : 1;

    let salesChannel = formValue.salesChannel === "" || formValue.salesChannel === undefined ? 0 : 1;

    return countBranch + countCategory + countStatus + salesChannel;
  };

  useImperativeHandle(ref, () => ({
    resetFilter() {
      onResetForm();
    },
  }));

  const onResetForm = () => {
    form?.resetFields();
    onApplyFilter();
    if (onShowFilter) {
      onShowFilter(false);
    }
  };

  return (
    <Form layout="vertical" form={form} onFieldsChange={onApplyFilter}>
      <Form.Item name="branchId" label={pageData.filter.branch.title}>
        <FnbSelectSingle
          placeholder={pageData.filter.branch.placeholder}
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={branchOptions}
        />
      </Form.Item>
      <Form.Item name="productCategoryId" label={pageData.filter.category.title}>
        <FnbSelectSingle
          placeholder={pageData.filter.category.placeholder}
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={categoryOptions}
        />
      </Form.Item>
      <Form.Item name="salesChannel" label={pageData.filter.salesChannel.title}>
        <Radio.Group defaultValue={DEFAULT_VALUE} buttonStyle="solid">
          {salesChannelRadios?.map((item) => (
            <FnbRadioButton value={item?.id}>{item?.name}</FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name="statusId" label={pageData.filter.status.title}>
        <Radio.Group defaultValue={DEFAULT_VALUE} buttonStyle="solid">
          <FnbRadioButton value={DEFAULT_VALUE}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={1}>{pageData.filter.status.active}</FnbRadioButton>
          <FnbRadioButton value={0}>{pageData.filter.status.inactive}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default FilterProduct;
