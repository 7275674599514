import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";
import {
  TriangleIncreaseIcon,
  TriangleReduceIcon,
} from "constants/icons.constants";
import { formatNumberDecimalOrInteger, getSuffixShortValue, getShortValue } from "utils/helpers";

export default function CardSummaryShift(props) {
  const [t] = useTranslation();
  const { summaryItem, title, icon, suffixData } = props;

  return (
    <div className="summary-card">
      <Row>
        <Col>
          <div className="sumnary-icon">{icon}</div>
        </Col>
        <Col>
          <div className="summary-title">
            <p className="title">{title}</p>
          </div>
        </Col>
        <Col>
          <div className={`acceleration-card ${summaryItem?.percent > 0 ? "increase" : "decrease"}`}>
            <Row>
              {summaryItem?.percent > 0 ? (
                <TriangleIncreaseIcon className="icon-increase-triangle" />
              ) : (
                <TriangleReduceIcon className="icon-increase-triangle" />
              )}
              <p className="percent">{Math.abs(summaryItem?.percent)}%</p>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="summary-short-value">
            <p>{getShortValue(summaryItem?.total)}</p>
            <p className="suffix">
              {getSuffixShortValue(summaryItem?.total)}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="summary-suffix">
            <p className="data">{formatNumberDecimalOrInteger(summaryItem?.total)}</p>
            <p className="suffix-text">{suffixData}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
