import { Modal, Space } from "antd";
import { useTranslation } from "react-i18next";

import "./fnb-notify-dialog.component.scss";
import { FnbButton } from "components/fnb-button/fnb-button";

export function FnbNotifyDialog({
  className,
  title,
  content: Content,
  open,
  cancelText,
  okText,
  onCancel,
  onOk,
  hideCancelButton,
  width = 578,
}) {
  const [t] = useTranslation();

  return (
    <Modal
      width={width}
      className={`fnb-notify-dialog ${className}`}
      title={title}
      open={open}
      closable={true}
      onOk={onOk}
      onCancel={onCancel}
      centered={true}
      maskClosable={false}
      cancelButtonProps={hideCancelButton ? { style: { display: "none" } } : ""}
      footer={<Space size={12}>
        <FnbButton minWidth="150px" variant="secondary" text={cancelText ?? "Cancel"} onClick={onCancel}/>
        <FnbButton minWidth="150px" text={okText ?? "OK"}  onClick={onOk}/>
      </Space>}
    >
      <Content />
    </Modal>
  );
}
