import FnbTypography from "components/fnb-typography/fnb-typography";
import { HeadingFreeTrialContent, HeadingFreeTrialStyled } from "./heading-free-trial.styled";
import theme from "theme";
import { FnbButton } from "components/fnb-button/fnb-button";
import { Link } from "react-router-dom";

const HeadingFreeTrial = () => {
  return (
    <HeadingFreeTrialStyled>
      <HeadingFreeTrialContent>
        <FnbTypography variant={theme.typography["b1-regular"]} color={theme.colors.default.white}>
          {"Get started with a "}
          <FnbTypography color={theme.colors.default.white} text="free trial!" variant={theme.typography["b1-bold"]} />
        </FnbTypography>
        <Link to="/register-account?step=1">
          <FnbButton text="Register now!" variant="secondary" style={{ height: 40 }} />
        </Link>
      </HeadingFreeTrialContent>
    </HeadingFreeTrialStyled>
  );
};

export default HeadingFreeTrial;
