import { Radio, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import paymentMethodDataService from "../../../../../data-services/paymentMethod-data.service";
import { isNonEmptyArray } from "../../../../../utils/helpers";
import { InfoDuoToneLineIcon } from "../../../../assets/icons.constants";
import BankTransferPayment from "../../../../components/BankTransferPayment/BankTransferPayment";
import { PaymentMethodType } from "../../../../constants/payment-method.constants";
import DepositMethods from "./DepositMethods";
import "./ReserveTableDepositMethod.scss";

const ReserveTableDepositMethod = (props) => {
  const { storeId, branchAddressId, onChangeDepositMethodEnumId, onEmptyDepositMethod } = props;
  const [t] = useTranslation();
  const [depositMethodEnumId, setDepositMethodEnumId] = useState(PaymentMethodType.Momo);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositBankTransfer, setDepositBankTransfer] = useState({});
  const storeConfig = useSelector((state) => state?.session?.storeConfig);

  const pageData = {
    depositAmount: t("deposit.depositAmount", "Deposit amount"),
    depositMethod: t("deposit.depositMethod", "Deposit method"),
    needToDeposit: t("deposit.needToDeposit", "You need to deposit to keep this place"),
    momo: t("deposit.momo", "Momo"),
    bankTransfer: t("deposit.bankTransfer", "Bank Transfer"),
    received: t("deposit.received", "Received"),
    notReceived: t("deposit.notReceived", "Not received"),
    payment: t("deposit.payment", "Payment for Reservation #"),
    noSuiTableDepositMethod: t("deposit.noSuiTableDepositMethod", "Currently there is no suitable payment method."),
  };

  useEffect(() => {
    fetchDataDepositMethod(storeId, branchAddressId);
  }, [storeId, branchAddressId]);

  useEffect(() => {
    onChangeDepositMethodEnumId && onChangeDepositMethodEnumId(depositMethodEnumId);
  }, [depositMethodEnumId]);

  useEffect(() => {
    const isEmptyDepositMethod = !isNonEmptyArray(depositMethods);
    onEmptyDepositMethod && onEmptyDepositMethod(isEmptyDepositMethod);
  }, [depositMethods]);

  const fetchDataDepositMethod = async (storeId, branchAddressId) => {
    if (!storeId) return;
    const response = await paymentMethodDataService.getPaymentMethods(storeId, branchAddressId);
    const depositData = response?.data;
    if (!depositData) return;

    const depositMethods = depositData
      ?.filter((method) => {
        const isDepositMomoOrBankTransfer =
          method?.paymentMethodEnumId === PaymentMethodType.Momo ||
          method?.paymentMethodEnumId === PaymentMethodType.BankTransfer;
        if (isDepositMomoOrBankTransfer) return method;
      })
      ?.sort((a, b) => (a?.paymentMethodEnumId > b?.paymentMethodEnumId ? 1 : -1));
    setDepositMethods(depositMethods);

    // Initial depositMethodEnumId
    const depositMethodEnumIdMomo = depositData?.filter(
      (method) => method?.paymentMethodEnumId === PaymentMethodType.Momo,
    );
    if (!isNonEmptyArray(depositMethodEnumIdMomo)) {
      setDepositMethodEnumId(depositData[0]?.paymentMethodEnumId);
    }

    // Handle changed the Bank transfer info when changed branchId
    if (depositMethodEnumId === PaymentMethodType.BankTransfer) {
      const depositMethodBankTransfer = depositMethods?.find(
        (p) => p?.paymentMethodEnumId === PaymentMethodType.BankTransfer,
      );
      depositMethodBankTransfer && setDepositBankTransfer(depositMethodBankTransfer);
    }
  };

  function onChangeDepositMethod(event) {
    setDepositMethodEnumId(event?.target?.value);
    const depositMethodBankTransfer = depositMethods?.find(
      (p) =>
        event?.target?.value === PaymentMethodType.BankTransfer &&
        p?.paymentMethodEnumId === PaymentMethodType.BankTransfer,
    );
    setDepositBankTransfer(depositMethodBankTransfer);
  }

  const hasShowDepositBankTransferInfo = useMemo(() => {
    const bankAccountInfoDeposit = depositBankTransfer?.bankAccountInfo;
    return bankAccountInfoDeposit?.isVietnameseBank && bankAccountInfoDeposit?.qrCodeUrl;
  }, [depositBankTransfer]);

  return (
    <div className="reserve-table-deposit-method">
      <div className="box_payment_method card-wrapper card-pacing-bottom">
        <div className="payment_method card-header">
          <p className="deposit-method">
            {pageData.depositMethod}
            <Tooltip
              placement="top"
              title={pageData.needToDeposit}
              color="#FEFEFE"
              overlayClassName="tooltip-need-to-deposit"
            >
              <InfoDuoToneLineIcon />
            </Tooltip>
          </p>
        </div>
        {storeConfig?.isDeposit && isNonEmptyArray(depositMethods) ? (
          <div className="card-body">
            <Radio.Group
              className="shipping_radio_payment_method radio-style"
              onChange={onChangeDepositMethod}
              value={depositMethodEnumId}
              defaultValue={depositMethodEnumId}
            >
              <DepositMethods
                depositMethods={depositMethods}
                depositAmount={storeConfig?.depositAmount}
                value={depositMethodEnumId}
              />
            </Radio.Group>

            {hasShowDepositBankTransferInfo && (
              <BankTransferPayment
                bankAccountInfo={depositBankTransfer?.bankAccountInfo}
                className="bank-transfer-deposit-reserve-table"
                content={t(pageData.payment)}
              />
            )}
          </div>
        ) : (
          <div className="no-suitable-deposit-method">
            <span>{t(pageData.noSuiTableDepositMethod)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReserveTableDepositMethod;
