import qs from "query-string";
import http, { downloadAsync } from "../../utils/http-common";

const controller = "ingredientRemoveRequest";

const getAllIngredientByBranchId = (branchId) => {
  return http.get(`/${controller}/get-ingredients-by-branchId?branchId=${branchId}`);
};

const getHistoryIngredientRequestAsync = (params) => {
  // Params: {KeySearch, BranchId, StartDate, EndDate, BusinessSummaryWidgetFilter, Type, PageNumber, PageSize}
  return http.get(`/${controller}/history`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const createIngredientRemoveRequest = (data) => {
  return http.post(`/${controller}/create-ingredient-remove-request`, data);
};

const getAllIngredientRemoveRequestAsync = (
  pageNumber,
  pageSize,
  keySearch,
  startDate,
  endDate,
  businessSummaryWidgetFilter,
  reasonType,
  branchId,
  status,
) => {
  let url = `/${controller}/get-all-ingredient-remove-request?pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}`;
  if (startDate) url += `&startDate=${startDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  if (businessSummaryWidgetFilter) url += `&businessSummaryWidgetFilter=${businessSummaryWidgetFilter}`;
  if (branchId) url += `&branchId=${branchId}`;
  if (reasonType) url += `&reasonType=${reasonType}`;
  if (status !== undefined) url += `&status=${status}`;

  return http.get(url);
};

const importIngredientRemoval = (data) => {
  return http.post(`/${controller}/import`, data);
};

const downloadTemplate = (languageCode) => {
  return downloadAsync(`/${controller}/template?languageCode=${languageCode}`);
};

const getIngredientRemoveRequestById = (id) => {
  return http.get(`${controller}/get-ingredient-remove-request-by-id?id=${id}`);
}

const updateIngredientRemoveRequest = (data) => {
  return http.post(`${controller}/update`, data);
}
const downloadFileErrAsync = (data) => {
  return http.post(`/${controller}/template-error`, data, { observe: "response", responseType: "blob" });
};

const getDetailIngredientRemoveRequestDataAsync = (id) => {
  return http.get(`/${controller}/${id}`);
};

const rejectIngredientRemoveRequestAsync = (data) => {
  return http.put(`/${controller}/reject`, data);
};

const approveIngredientRemoveRequestAsync = (data) => {
  return http.put(`/${controller}/approve`, data);
};

const ingredientRemoveRequestDataService = {
  getAllIngredientByBranchId,
  getHistoryIngredientRequestAsync,
  createIngredientRemoveRequest,
  getAllIngredientRemoveRequestAsync,
  importIngredientRemoval,
  downloadTemplate,
  downloadFileErrAsync,
  getIngredientRemoveRequestById,
  updateIngredientRemoveRequest,
  getDetailIngredientRemoveRequestDataAsync,
  rejectIngredientRemoveRequestAsync,
  approveIngredientRemoveRequestAsync,
};
export default ingredientRemoveRequestDataService;
