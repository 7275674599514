import { ArrowUpOutlinedIcon } from "constants/icons.constants";
import "./styles.scss";
import { useState } from "react";
import FnbButtonCompound from "components/fnb-button-compound/fnb-button-compound";
import kitchenDataService from "data-services/kitchen/kitchen-data.service";

export const SCREEN_OPERATION = {
  CASHIER: "cashier",
  CUSTOMER: "customer",
  POS_KITCHEN: "pos_kitchen",
};

const LeftMenuConfiguration = (props) => {
  const {
    t,
    screenActive,
    setScreenActive,
    isStoreHasKitchen,
    updateStoreConfig,
    isChangeForm,
    setShowConfirm,
    setScreenSelectPending,
  } = props;
  const [activePosCashRegister, setActivePosCashRegister] = useState(true);
  const [activeOtherPOSDevice, setActiveOtherPOSDevice] = useState(true);
  const onClickItem = (screen) => {
    if (isChangeForm) {
      setShowConfirm(true);
      setScreenSelectPending(screen);
    } else {
      setScreenActive(screen);
    }
  };

  const onChangeEnablePOSKitchen = async (e) => {
    const payload = {
      isStoreHasKitchen: e,
    };
    if(Boolean(e)) {
      //59928 - Create the default kitchen if any branch doesn't have one
      await kitchenDataService.createNewDefaultKitchenByStoreIdAsync();
    }
    updateStoreConfig(payload);
  };

  const pageData = {
    posCashRegister: t("configuration.posCashRegister"),
    cashierScreen: t("configuration.cashierScreen.title"),
    customerScreen: t("configuration.customerScreen.title"),
    otherPosDevices: t("configuration.otherPosDevices"),
    kitchenSystem: t("configuration.kitchenSystem.title"),
    tableOrderingSystem: t("configuration.tableOrderingSystem.title"),
  };

  return (
    <div className="container-list-configuration-card">
      <div
        className={`left-configuration-card ${
          activePosCashRegister ? "left-configuration-card--active" : "left-configuration-card--inactive"
        }`}
      >
        <div
          className="heading-left-configuration-card"
          id="heading-left-configuration-card__pos-cash-register"
          onClick={() => {
            setActivePosCashRegister(!activePosCashRegister);
          }}
        >
          <span className="heading-left-configuration-card__title">{pageData.posCashRegister}</span>
          <ArrowUpOutlinedIcon
            className={`${activePosCashRegister ? "icon-arrow-config--active" : "icon-arrow-config--inactive"} `}
          />
        </div>
        <hr className="left-configuration-card__divider" />
        <FnbButtonCompound
          isActive={screenActive === SCREEN_OPERATION.CASHIER}
          title={pageData.cashierScreen}
          onClick={() => onClickItem(SCREEN_OPERATION.CASHIER)}
        />
        <FnbButtonCompound
          isActive={screenActive === SCREEN_OPERATION.CUSTOMER}
          title={pageData.customerScreen}
          onClick={() => onClickItem(SCREEN_OPERATION.CUSTOMER)}
        />
      </div>
      <div
        className={`left-configuration-card ${
          activeOtherPOSDevice ? "left-configuration-card--active" : "left-configuration-card--inactive"
        }`}
      >
        <div
          className="heading-left-configuration-card"
          id="heading-left-configuration-card__other_pos_devices"
          onClick={() => {
            setActiveOtherPOSDevice(!activeOtherPOSDevice);
          }}
        >
          <span className="heading-left-configuration-card__title">{pageData.otherPosDevices}</span>
          <ArrowUpOutlinedIcon
            className={`${activeOtherPOSDevice ? "icon-arrow-config--active" : "icon-arrow-config--inactive"} `}
          />
        </div>
        <hr className="left-configuration-card__divider" />
        <FnbButtonCompound
          isActive={screenActive === SCREEN_OPERATION.POS_KITCHEN}
          title={pageData.kitchenSystem}
          onClick={() => onClickItem(SCREEN_OPERATION.POS_KITCHEN)}
          showSwitch
          valueSwitch={isStoreHasKitchen}
          setValueSwitch={onChangeEnablePOSKitchen}
        />
        <FnbButtonCompound title={pageData.tableOrderingSystem} showSwitch showComingSoon disabled />
      </div>
    </div>
  );
};

export default LeftMenuConfiguration;
