import { Card, Col, Form, Input, Row, Select, Skeleton, Typography, message } from "antd";
import { Printer } from "components/Printer";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { CustomDownIcon, PrinterOutlineIcon, SelectCheckedIcon } from "constants/icons.constants";
import { EnumReceiptType } from "constants/receipt-type.constants";
import { PrinterSettingType } from "constants/store-setting.constants";
import { DateFormat } from "constants/string.constants";
import fileDataService from "data-services/file/file-data.service";
import useBuildBillTemplate from "hooks/useBuildBillTemplate";
import i18n from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import { useSelector } from "react-redux";
import { informationPublishStoreSelector, storeInfoSelector } from "store/modules/session/session.reducers";
import { fileNameNormalize, jsonToFormData } from "utils/helpers";
import { defaultBillConfigData, dynamicData, sampleItemList } from "./default.data";
import "./index.scss";
const { Option } = Select;

export default function ReceiptPage(props) {
  const { t, billDataService, onChangeForm, activeTab } = props;
  const [form] = Form.useForm();
  const billTemplateRef = React.useRef(null);
  const storeInfo = useSelector(storeInfoSelector);
  const infoPublishStore = useSelector(informationPublishStoreSelector);
  const [allBillConfiguration, setAllBillConfiguration] = useState();
  const [isDisableShowWifi, setIsDisableShowWifi] = useState(false);
  const [isDisableShowThanks, setIsDisableShowThanks] = useState(false);
  const [isDisableShowQR, setIsDisableShowQR] = useState(false);
  const [qrCodeThumbnail, setQrCodeThumbnail] = useState(null);
  const [imageRequired, setImageRequired] = useState(true);
  const [currentTemplateConfig, setCurrentTemplateConfig] = useState(null);
  const [buildTemplate, setBuildTemplate] = useBuildBillTemplate();
  const [sampleTemplate, setSampleTemplate] = useState(null);
  const [storeLogoBase64Image, setStoreLogoBase64Image] = useState("");
  const [billTemplateWithConfig, setBillTemplateWithConfig] = useState("");
  const [widthBillTemplate, setWidthBillTemplate] = useState(0);
  const [address, setAddress] = useState("");
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const pageData = {
    btnSave: t("button.saveChanges"),
    upload: t("button.upload"),
    btnPrintPreview: t("button.printPreview"),
    btnCancel: t("button.cancel"),
    receiptTemplate: t("receipt.receiptTemplate"),
    frameSize: t("receipt.frameSize"),
    smallSizeOption: t("receipt.smallSizeOption"),
    mediumSizeOption: t("receipt.mediumSizeOption"),
    showLogo: t("receipt.showLogo"),
    showAddress: t("receipt.showAddress"),
    showServiceType: t("receipt.showServiceType"),
    showOrderTime: t("receipt.showOrderTime"),
    showCashierName: t("receipt.showCashierName"),
    showCustomerName: t("receipt.showCustomerName"),
    showTopping: t("receipt.showTopping"),
    showOption: t("receipt.showOption"),
    showThanksMessage: t("receipt.showThanksMessage"),
    enterThanksMessage: t("receipt.enterThanksMessage"),
    showWifi: t("receipt.showWifi"),
    enterWifi: t("receipt.enterWifi"),
    pleaseEnterWifi: t("receipt.pleaseEnterWifi"),
    showPassword: t("receipt.showPassword"),
    enterPassword: t("receipt.enterPassword"),
    pleaseEnterPassword: t("receipt.pleaseEnterPassword"),
    showQRCode: t("receipt.showQRCode"),
    enterQRCode: t("receipt.enterQRCode"),
    enterQRThumbnail: t("receipt.enterQRThumbnail"),
    qrThumbnailButton: t("receipt.qrThumbnailButton"),
    configureSuccessfully: t("receipt.configureSuccessfully"),
    showBranchHotline: t("receipt.showBranchHotline"),
    showCustomerPhone: t("receipt.showCustomerPhone"),
    showCustomerAddress: t("receipt.showCustomerAddress"),
    customerAreaAndTable: t("receipt.customerAreaAndTable"),
    showSubTotal: t("receipt.showSubTotal"),
    showDiscount: t("receipt.showDiscount"),
    showFee: t("receipt.showFee"),
    showTax: t("receipt.showTax"),
    showShippingFee: t("receipt.showShippingFee"),
    showDeposit: t("receipt.showDeposit"),
    configuration: t("receipt.configuration"),
    no: t("invoice.no"),
    feeAndTax: t("invoice.feeAndTax"),
    paymentMethod: t("invoice.paymentMethod"),
    cash: t("invoice.cash"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    selectOptionDescription: t("receipt.selectOptionDescription"),
    branchAndStaffGroup: t("receipt.branchAndStaffGroup"),
    orderGroup: t("receipt.orderGroup"),
    customerGroup: t("receipt.customerGroup"),
    preview: t("receipt.preview"),
    wifi: t("receipt.wifi"),
    password: t("receipt.password"),
    pleaseTryAgain: t("receipt.messages.pleaseTryAgain"),
    showOrderNote: t("receipt.showOrderNote"),
    showItemNote: t("receipt.showItemNote"),
  };

  const labelTranslated = {
    title: t("invoice.paymentInvoice"),
    orderCodeLabel: t("invoice.orderCode"),
    situationLabel: t("invoice.areaTable"),
    serviceTypeLabel: t("invoice.serviceType"),
    serviceTypeValue: t("invoice.instore"),
    timeLabel: t("invoice.orderTime"),
    cashierLabel: t("invoice.cashierName"),
    customerLabel: t("invoice.customerName"),
    customerPhoneLabel: t("invoice.customerPhoneLabel"),
    customerAddressLabel: t("invoice.customerAddressLabel"),
    itemNameColumnLabel: t("invoice.product"),
    itemQuantityColumnLabel: t("invoice.billQuantity"),
    itemPriceColumnLabel: t("invoice.billPrice"),
    totalAmountColumnLabel: t("invoice.billTotal"),
    subTotalAmountLabel: t("invoice.tempTotal"),
    discountAmountLabel: t("invoice.discount"),
    cashLabel: t("invoice.receivedAmount"),
    refundsLabel: t("invoice.change"),
    passwordWifiLabel: t("invoice.password"),
    feeAmountLabel: t("invoice.feeAmountLabel"),
    taxAmountLabel: t("invoice.taxAmountLabel"),
    shippingFeeLabel: t("invoice.shippingFeeLabel"),
    totalAmountLabel: t("invoice.totalAmountLabel"),
    paymentMethodLabel: t("invoice.paymentMethodLabel"),
    paymentMethodName: t("invoice.cash"),
    orderNoteLabel: t("invoice.orderNoteLabel"),
    noteLabel: t("orderSlip.note"),
    depositedLabel: t("invoice.depositedLabel"),
    depositedPaymentMethod: t("invoice.depositedPaymentMethod"),
  };

  const contentTranslated = {
    orderCodeValue: dynamicData.orderCodeValue,
    situationValue: `<p style='margin-bottom: 0px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1;'>  ${t(
      "mockupData.bill.areaTable1",
      "Bàn 1, Bàn 2, Bàn 3 > Tầng 1",
    )} </p> <p style='margin-bottom: 0px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1;'>  ${t(
      "mockupData.bill.areaTable2",
      "Bàn 1, Bàn 2, Bàn 3 > Tầng 2",
    )} </p>`,
    timeValue: "12/01/2023 12:00:00",
    cashierName: t("mockupData.bill.cashierName", dynamicData.cashierName),
    customerName: t("mockupData.bill.customerName", dynamicData.customerName),
    customerPhoneValue: t("mockupData.bill.customerPhoneValue", dynamicData.customerPhoneValue),
    customerAddressValue: t("mockupData.bill.customerAddressValue", dynamicData.customerAddressValue),
    subTotalAmountValue: t("mockupData.bill.subTotalAmountValue", dynamicData.subTotalAmountValue),
    discountAmountValue: t("mockupData.bill.discountAmountValue", dynamicData.discountAmountValue),
    feeAmountValue: t("mockupData.bill.feeAmountValue", dynamicData.feeAmountValue),
    taxAmountValue: t("mockupData.bill.taxAmountValue", dynamicData.taxAmountValue),
    shippingFeeValue: t("mockupData.bill.shippingFeeValue", dynamicData.shippingFeeValue),
    totalAmountValue: t("mockupData.bill.totalAmountValue", dynamicData.totalAmountValue),
    cashValue: t("mockupData.bill.cashValue", dynamicData.cashValue),
    refundsValue: t("mockupData.bill.refundsValue", dynamicData.refundsValue),
    depositedValue: t("mockupData.bill.depositedValue", dynamicData.depositedValue),
    hotline: t("mockupData.bill.hotline", dynamicData.hotline),
    passwordWifiValue: t("mockupData.bill.passwordWifiValue", dynamicData.passwordWifiValue),
    wifi: t("mockupData.bill.wifi", dynamicData.wifi),
    thanksMessage: t("mockupData.bill.thanksMessage", dynamicData.thanksMessage),
    storeLogo: t("mockupData.bill.storeLogo", dynamicData.storeLogo),
    storeName: t("mockupData.bill.storeName", dynamicData.storeName),
    qrCode: t("mockupData.bill.qrCode", dynamicData.qrCode),
    messageQRCode: t("mockupData.bill.messageQRCode", dynamicData.messageQRCode),
    notes: t("mockupData.bill.notes", dynamicData.notes),
  };

  const sampleItemListTranslated = sampleItemList?.map((item, index) => ({
    ...item,
    itemName: t(`mockupData.bill.orderItem${index}.productName`, item?.itemName),
    toppings: item?.toppings?.map((topping, indexTopping) => ({
      ...topping,
      itemName: t(`mockupData.bill.orderItem${index}.topping${indexTopping}`, topping?.itemName),
    })),
    options: item?.options?.map((option, indexOption) => ({
      ...option,
      itemName: t(`mockupData.bill.orderItem${index}.option${indexOption}`, option?.itemName),
    })),
    notes: t(`mockupData.bill.orderItem${index}.notes`, item?.notes),
  }));

  // Static data for bill
  const [currentBillConfigData, setCurrentBillConfigData] = useState({
    ...defaultBillConfigData,
  });

  useEffect(() => {
    if (activeTab === PrinterSettingType.RECEIPT) {
      initDefaultTemplateAndConfig();
    } else {
      setSampleTemplate(null);
      form.resetFields();
      setIsChangeForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, infoPublishStore?.isDeposit]);

  useEffect(() => {
    if (buildTemplate && currentTemplateConfig) {
      const { template } = currentTemplateConfig;
      getPreviewTemplateWithSampleData(template);
      getTemplateToSave(template);
    }
    //Re-validate to translate error messages when change language
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

    form.validateFields(errorFields);
  }, [i18n.language]);

  const configPreviewTemplate = () => {
    if (buildTemplate && currentTemplateConfig) {
      const { template } = currentTemplateConfig;
      getPreviewTemplateWithSampleData(template);
      getTemplateToSave(template);
    } else {
      setSampleTemplate(null);
      setBillTemplateWithConfig(null);
    }
  };

  /// render template
  useEffect(() => {
    if (storeInfo && currentBillConfigData && labelTranslated && contentTranslated) {
      configPreviewTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBillConfigData,
    storeInfo,
    storeInfo?.storeName,
    storeLogoBase64Image,
    buildTemplate,
    currentTemplateConfig,
    qrCodeThumbnail,
    contentTranslated,
    labelTranslated,
    address,
  ]);

  const getPreviewTemplateWithSampleData = (template) => {
    const params = {
      ...contentTranslated, // Mockup dynamic data
      ...labelTranslated,
      ...currentBillConfigData,
      storeName: storeInfo?.storeName,
      storeLogo: storeLogoBase64Image,
      qrCode: qrCodeThumbnail,
      address: address,
    };
    const myTemplate = buildTemplate(template, sampleItemListTranslated, params);
    setSampleTemplate(myTemplate);
  };

  const getTemplateToSave = (template) => {
    const configParams = {
      ...currentBillConfigData,
      storeLogo: storeLogoBase64Image,
      qrCode: qrCodeThumbnail,
      storeName: storeInfo?.storeName,
    };
    const myTemplateWithConfig = buildTemplate(template, [], configParams);
    setBillTemplateWithConfig(myTemplateWithConfig);
  };

  const initDefaultTemplateAndConfig = async () => {
    let receiptType = EnumReceiptType.BILL;
    if (activeTab === PrinterSettingType.ORDER_SLIP) {
      receiptType = EnumReceiptType.ORDER_SLIP;
    }
    const res = await billDataService.getBillConfigurationAsync(receiptType);
    const { billConfigs, addressInfo } = res;
    let newBillConfigs = billConfigs;
    if (!infoPublishStore?.isDeposit) {
      const updateBillConfigs = billConfigs.map((billConfig) => ({
        ...billConfig,
        config: {
          ...billConfig.config,
          isShowDeposit: false,
        },
      }));
      newBillConfigs = updateBillConfigs;
    }
    setAllBillConfiguration(newBillConfigs);
    setAddress(addressInfo);
    const defaultTemplateConfig = newBillConfigs?.find((i) => i.config.isDefault === true);
    setBuildTemplate(defaultTemplateConfig.template.buildTemplate);
    const defaultConfig = defaultTemplateConfig.config;
    setCurrentTemplateConfig(defaultTemplateConfig);
    setWidthBillTemplate(defaultConfig?.billFrameWidthPage);

    if (defaultConfig) {
      form.setFieldsValue({
        billConfiguration: defaultConfig,
      });
      setIsDisableShowWifi(defaultConfig.isShowWifiAndPassword);
      setIsDisableShowThanks(defaultConfig.isShowThanksMessage);
      setIsDisableShowQR(defaultConfig.isShowQRCode);
      setQrCodeThumbnail(defaultConfig.qrCodeThumbnail);

      if (defaultConfig.logo) {
        setStoreLogoBase64Image(defaultConfig.logo);
      }

      if (defaultConfig.qrCodeThumbnail) {
        setImageRequired(false);
      }

      updateBillConfigData(defaultConfig.config);
    }
  };

  const updateBillConfigData = (configValues) => {
    if (!configValues) {
      const formValues = form.getFieldsValue();
      const { billConfiguration } = formValues;
      configValues = billConfiguration;
    }

    const {
      isShowLogo,
      isShowAddress,
      isShowBranchHotLine,
      isShowServiceType,
      isShowOrderTime,
      isShowCashierName,
      isShowCustomerName,
      isShowCustomerPhone,
      isShowCustomerAddress,
      isShowAreaTable,
      isShowTopping,
      isShowOption,
      isShowSubTotal,
      isShowDiscount,
      isShowQRCode,
      isShowFee,
      isShowTax,
      isShowShippingFee,
      isShowThanksMessage,
      isShowWifiAndPassword,
      wifiData,
      passwordData,
      thanksMessageData,
      qrCodeData,
      isShowOrderNote,
      isShowItemNote,
      isShowDeposit,
    } = configValues;
    const newBillConfigData = {
      ...currentBillConfigData,
      logoVisible: isShowLogo ? "inline-flex" : "none",
      addressVisible: isShowAddress ? "inline-flex" : "none",
      branchHotlineVisible: isShowBranchHotLine ? "block" : "none",
      serviceTypeVisible: isShowServiceType ? "inline-flex" : "none",
      timeVisible: isShowOrderTime ? "inline-flex" : "none",
      cashierNameVisible: isShowCashierName ? "inline-flex" : "none",
      customerNameVisible: isShowCustomerName ? "inline-flex" : "none",
      customerPhoneVisible: isShowCustomerPhone ? "inline-flex" : "none",
      customerAddressVisible: isShowCustomerAddress ? "inline-flex" : "none",
      situationVisible: isShowAreaTable ? "inline-flex" : "none",
      subtotalVisible: isShowSubTotal ? "inline-flex" : "none",
      toppingVisible: isShowTopping ? "block" : "none",
      optionVisible: isShowOption ? "block" : "none",
      discountVisible: isShowDiscount ? "inline-flex" : "none",
      feeVisible: isShowFee ? "inline-flex" : "none",
      taxVisible: isShowTax ? "inline-flex" : "none",
      shippingVisible: isShowShippingFee ? "inline-flex" : "none",
      thanksMessageVisible: isShowThanksMessage ? "block" : "none",
      wifiPasswordVisible: isShowWifiAndPassword ? "inline-flex" : "none",
      barcodeVisible: isShowQRCode ? "inline-flex" : "none",
      lastBreakLine: isShowSubTotal || isShowDiscount || isShowFee || isShowShippingFee || isShowTax ? "block" : "none",
      wifi: wifiData,
      passwordWifiValue: passwordData,
      thanksMessage: thanksMessageData,
      messageQRCode: qrCodeData,
      orderNoteInVisible: isShowOrderNote ? "inline-flex" : "none",
      itemNoteInVisible: isShowItemNote ? "inline-flex" : "none",
      depositVisible: isShowDeposit ? "inline-flex" : "none",
    };
    setCurrentBillConfigData(newBillConfigData);
  };

  const onChangeFrameSize = (frameSizeKey) => {
    const templateConfig = allBillConfiguration.find((x) => x.config.billFrameSize === frameSizeKey);
    setWidthBillTemplate(templateConfig?.config?.billFrameWidthPage);

    form.setFieldsValue({
      billConfiguration: templateConfig.config,
    });

    setIsDisableShowWifi(templateConfig.config.isShowWifiAndPassword);
    setIsDisableShowThanks(templateConfig.config.isShowThanksMessage);
    setIsDisableShowQR(templateConfig.config.isShowQRCode);
    setQrCodeThumbnail(templateConfig.config.qrCodeThumbnail);

    if (templateConfig.config.qrCodeThumbnail) {
      setImageRequired(false);
    }

    // Update template and config selected
    if (templateConfig.template.buildTemplate) {
      setBuildTemplate(templateConfig.template.buildTemplate);
    }
    setCurrentTemplateConfig(templateConfig);
    updateBillConfigData(templateConfig.config);
  };

  const printTemplate = () => {
    if (billTemplateRef && billTemplateRef.current) {
      billTemplateRef.current.printTemplate();
    }
  };

  const onUploadImage = (imageList) => {
    let buildFileName = moment(new Date()).format(DateFormat.DDMMYYYYHHmmss);
    if (imageList[0]) {
      const requestData = {
        file: imageList[0],
        fileName: fileNameNormalize(buildFileName),
      };
      const requestFormData = jsonToFormData(requestData);
      fileDataService.uploadFileAsync(requestFormData).then((res) => {
        if (res.success === true) {
          imageList[0].data_url = res.data;
          setQrCodeThumbnail(imageList[0].data_url);
          setImageRequired(false);
        } else {
          message.error(pageData.pleaseTryAgain);
        }
      });
    } else {
      return;
    }
  };

  const onFinish = async (values) => {
    const qrCodeImage = {
      qrCodeThumbnail: qrCodeThumbnail,
    };
    const fromData = values?.billConfiguration;
    if (fromData?.qrCodeThumbnail !== null) {
      delete fromData?.qrCodeThumbnail;
    }
    let submitData = { ...qrCodeImage, ...values.billConfiguration };
    if (submitData.passwordData === undefined) {
      submitData.passwordData = null;
    }
    if (billTemplateWithConfig) {
      submitData.template = billTemplateWithConfig;
    }

    const res = await billDataService.updateBillConfigurationAsync({
      billConfiguration: submitData,
    });

    if (res) {
      onChangeForm(false);
      initDefaultTemplateAndConfig(values?.billConfiguration?.billFrameSize);
      message.success(pageData.configureSuccessfully);
    }
    setIsChangeForm(false);
  };

  const onFieldsChange = () => {
    setIsChangeForm(true);
    onChangeForm(true);
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onOK = () => {
    setIsChangeForm(false);
  };

  const cancelChange = () => {
    onChangeForm(false);
    setIsChangeForm(false);
    initDefaultTemplateAndConfig();
  };

  return (
    <Card bordered={false} className="fnb-card-full receipt-card item-card-printer-item">
      <Form
        autoComplete="off"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        form={form}
        onChange={(e) => {
          updateBillConfigData();
        }}
        onValuesChange={onFieldsChange}
      >
        <Row gutter={[24, 24]} className="receipt-config-card-header">
          <Col lg={8} md={8} sm={24}></Col>
          <Col lg={16} md={16} sm={24} className="card-button text-right" style={{ width: "100%" }}>
            <div className="group-button-item-printer">
              <FnbButton
                iconHeader={<PrinterOutlineIcon />}
                onClick={printTemplate}
                text={pageData.btnPrintPreview}
                variant="tertiary"
              />
              {isChangeForm && (
                <div className="action-button">
                  <FnbButton minWidth={100} text={pageData.btnCancel} variant="tertiary" onClick={cancelChange} />
                  <FnbButton minWidth={100} text={pageData.btnSave} type="submit" />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="receipt-config-card-body">
          <Col xl={16} lg={12} md={12} sm={24} className="receipt-form">
            <Row>
              <Col span={24}>
                <h4 className="fnb-form-label">
                  {pageData.frameSize} <span className="require-symbol">*</span>
                </h4>
                <Form.Item className="select-size" name={["billConfiguration", "billFrameSize"]}>
                  <Select
                    suffixIcon={<CustomDownIcon width={14} height={7} color="#B8B6C1"></CustomDownIcon>}
                    onChange={onChangeFrameSize}
                    size="large"
                    placeholder="Select frame size"
                    autoComplete="none"
                    className="select-single"
                    dropdownClassName="fnb-select-single-dropdown"
                    menuItemSelectedIcon={<SelectCheckedIcon style={{ padding: "5px" }} />}
                  >
                    <Option key={0} value={0}>
                      <p className="option-frame-size">{pageData.smallSizeOption}</p>
                    </Option>
                    <Option key={1} value={1}>
                      <p className="option-frame-size">{pageData.mediumSizeOption}</p>
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Typography className="select-option-description">{pageData.selectOptionDescription}</Typography>
              </Col>
            </Row>

            <Row gutter={12} className="list-option">
              <Col xl={8} lg={24} md={24} sm={24}>
                <Row>
                  <Col span={24}>
                    <Typography className="option-group">{pageData.branchAndStaffGroup}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowLogo"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showLogo}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowBranchHotLine"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showBranchHotline}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowAddress"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showAddress}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowServiceType"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showServiceType}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowOrderTime"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showOrderTime}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowCashierName"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showCashierName}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={["billConfiguration", "isShowWifiAndPassword"]}
                      valuePropName="checked"
                      onChange={(e) => {
                        setIsDisableShowWifi(e?.target?.checked);
                      }}
                    >
                      <FnbCheckBox>{pageData.showWifi}</FnbCheckBox>
                    </Form.Item>
                  </Col>

                  <Col span={24} className={isDisableShowWifi === true ? "d-block" : "d-none"}>
                    <Row>
                      <Col lg={24} md={24} sm={24} className="wifi-container">
                        <h4 className="fnb-form-label wifi-password-label">{pageData.wifi}</h4>
                        <Form.Item
                          name={["billConfiguration", "wifiData"]}
                          rules={[
                            {
                              required: isDisableShowWifi,
                              message: pageData.pleaseEnterWifi,
                            },
                          ]}
                        >
                          <Input className="fnb-input" min={100} placeholder={pageData.enterWifi} />
                        </Form.Item>
                      </Col>
                      <Col lg={24} md={24} sm={24} className="password-container">
                        <h4 className="fnb-form-label wifi-password-label password-label">{pageData.password}</h4>
                        <Form.Item
                          name={["billConfiguration", "passwordData"]}
                          rules={[
                            {
                              required: isDisableShowWifi,
                              message: pageData.pleaseEnterPassword,
                            },
                          ]}
                        >
                          <Input className="fnb-input" min={100} placeholder={pageData.enterPassword} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="thanks-message-container">
                  <Col span={24}>
                    <Form.Item
                      name={["billConfiguration", "isShowThanksMessage"]}
                      valuePropName="checked"
                      onChange={(e) => {
                        setIsDisableShowThanks(e?.target?.checked);
                      }}
                    >
                      <FnbCheckBox>{pageData.showThanksMessage}</FnbCheckBox>
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    className={
                      isDisableShowThanks === true
                        ? "d-block thanks-message-container"
                        : "d-none thanks-message-container"
                    }
                  >
                    <Form.Item
                      name={["billConfiguration", "thanksMessageData"]}
                      rules={[
                        {
                          required: isDisableShowThanks,
                          message: pageData.enterThanksMessage,
                        },
                      ]}
                    >
                      <Input className="fnb-input" min={100} placeholder={pageData.enterThanksMessage} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="qrCode-container">
                  <Col span={24}>
                    <Form.Item
                      name={["billConfiguration", "isShowQRCode"]}
                      valuePropName="checked"
                      onChange={(e) => {
                        setIsDisableShowQR(e?.target?.checked);
                      }}
                    >
                      <FnbCheckBox>{pageData.showQRCode}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={isDisableShowQR === true ? "d-block" : "d-none"}>
                    <Row>
                      <Col lg={24} md={24} sm={24} className="qrCodeData-container">
                        <Form.Item
                          name={["billConfiguration", "qrCodeData"]}
                          rules={[
                            {
                              required: isDisableShowQR,
                              message: pageData.enterQRCode,
                            },
                          ]}
                        >
                          <Input className="fnb-input qrCode-input" min={100} placeholder={pageData.enterQRCode} />
                        </Form.Item>
                      </Col>
                      <Col lg={24} md={24} sm={24} className="button-upload-image">
                        <Form.Item
                          name={["billConfiguration", "qrCodeThumbnail"]}
                          rules={[
                            {
                              required: isDisableShowQR ? imageRequired : false,
                              message: pageData.enterQRThumbnail,
                            },
                          ]}
                          className="form-item-upload-qr-code"
                        >
                          <ImageUploader
                            buttonStyles={{ width: "100%" }}
                            withIcon={false}
                            label=""
                            singleImage={true}
                            buttonText={pageData.qrThumbnailButton}
                            onChange={(value) => onUploadImage(value)}
                            imgExtension={[".jpg", ".png", ".jpeg"]}
                            maxFileSize={5242880}
                            className="btn-upload-image"
                            errorStyle={{
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: "21x",
                              color: "#DB1B1B",
                            }}
                            fileSizeError=" file size is too big"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xl={8} lg={24} md={24} sm={24}>
                <Row>
                  <Col span={24}>
                    <Typography className="option-group">{pageData.orderGroup}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowTopping"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showTopping}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowItemNote"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showItemNote}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowOrderNote"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showOrderNote}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowOption"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showOption}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowSubTotal"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showSubTotal}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowDiscount"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showDiscount}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowFee"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showFee}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowTax"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showTax}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowShippingFee"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showShippingFee}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                {infoPublishStore?.isDeposit && (
                  <Row>
                    <Col span={24}>
                      <Form.Item name={["billConfiguration", "isShowDeposit"]} valuePropName="checked">
                        <FnbCheckBox>{pageData.showDeposit}</FnbCheckBox>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xl={8} lg={24} md={24} sm={24}>
                <Row>
                  <Col span={24}>
                    <Typography className="option-group">{pageData.customerGroup}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowCustomerName"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showCustomerName}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowCustomerPhone"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showCustomerPhone}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowCustomerAddress"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.showCustomerAddress}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={["billConfiguration", "isShowAreaTable"]} valuePropName="checked">
                      <FnbCheckBox>{pageData.customerAreaAndTable}</FnbCheckBox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xl={8} lg={12} md={12} sm={24} className="template-preview">
            <p className="preview-text">{pageData.preview}</p>
            {!sampleTemplate && <Skeleton active paragraph={{ rows: 30 }} />}
            <Row className="template-preview-container">
              {sampleTemplate && (
                <Printer ref={billTemplateRef} htmlContent={sampleTemplate} widthBill={widthBillTemplate} />
              )}
            </Row>
          </Col>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onOK}
        isChangeForm={isChangeForm}
      />
    </Card>
  );
}
