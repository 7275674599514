export const SignalRListenerConstants = {
  KITCHEN_RECEIVER: "Kitchen",
  CASHIER_RECEIVER: "Cashier",
  POS_PAYMENT_STATUS: "POS_PAYMENT_STATUS",
  HAD_END_SHIFT: "HAD_END_SHIFT",
  PUBLISH_STORE_THEME: "PUBLISH_STORE_THEME",
  SIGNAL_CHECK_NAME_SERVERS: "SIGNAL_CHECK_NAME_SERVERS",
  SIGNAL_CHECK_MAPPING_STATUS: "SIGNAL_CHECK_MAPPING_STATUS",
  SIGNAL_UPDATE_CUSTOM_DOMAIN: "SIGNAL_UPDATE_CUSTOM_DOMAIN",
  SIGNAL_UPDATE_STATUS_BUILD_STORE_APP: "SIGNAL_UPDATE_STATUS_BUILD_STORE_APP",
  SIGNAL_REFRESH_STEP_GOOGLE_MAP_KEY_BUILD_STORE_APP: "SIGNAL_REFRESH_STEP_GOOGLE_MAP_KEY_BUILD_STORE_APP"
};
