import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, Image, message, Radio, Row } from "antd";
import adminBroadCast from "broadcast-channels/admin-broadcast-channel";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbSteps, Step } from "components/fnb-step/fnb-step";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import TabFeaturePackage from "components/subscription/tab-feature-package.component";
import { claimTypesConstants } from "constants/claim-types.constants";
import { CURRENCY_SYMBOL_CONSTANT } from "constants/currency-symbol.constants";
import {
  CardTickIcon,
  ChoosePackageStepIcon,
  CompletedIcon,
  CompletedStepIcon,
  ExclamationIcon,
  LeftArrowOutlined,
  PackageContinueIcon,
  PaymentStepIcon,
  PaymentSuccessIcon,
} from "constants/icons.constants";
import { images } from "constants/images.constants";
import { Package } from "constants/package.constants";
import { orderPackagePaymentMethod, orderPackagePaymentMethods } from "constants/payment-method.constants";
import { Region } from "constants/region.constants";
import { blank, blankWindowDimensions, paymentStatus } from "constants/string.constants";
import { BroadcastActions, VnPayResponseCodes } from "constants/vnpay-response-codes.constants";
import loginDataService from "data-services/login/login-data.service";
import orderPackagePaymentTransactionService from "data-services/order-package-payment-transaction/order-package-payment-transaction.service";
import packageDataService from "data-services/package/package-data.service";
import paymentDataService from "data-services/payment/payment-data.service";
import permissionDataService from "data-services/permission/permission-data.service";
import storeDataService from "data-services/store/store-data.service";
import { env } from "env";
import useDragScroll from "hooks/useDragScroll";
import jwt_decode from "jwt-decode";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setAuth,
  setPermissionGroup,
  setPermissions,
  setPrepareAddressData,
  setStoreInformation,
  setToken,
  setWorkspace,
} from "store/modules/session/session.actions";
import { authSelector } from "store/modules/session/session.reducers";
import { setUserInfo } from "themes/modules/session/session.actions";
import { removeStorage } from "themes/utils/localStorage.helpers";
import { formatCurrencyWithCode, formatNumber } from "utils/helpers";
import { localStorageKeys } from "utils/localStorage.helpers";
import { BankTransferInfoComponent } from "./bank-transfer-info.component";
import "./billing-component.scss";
import PackageSection from "./step-select-package/package-section.component";
import { DivideHorizontalLine } from "./step-select-package/package-section.styled";
import { MyAccountWrapperRightContent, RightContent } from "./step-select-package/styled";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import paypalService from "services/paypal.service";

export const packagePricingStep = {
  selectPackage: 0,
  payment: 1,
  complete: 2,
  completeByBankTransfer: 3,
  completeByPayPal: 4,
};

const BillingComponent = forwardRef((props, ref) => {
  const {
    userInfo,
    isSocialChannel,
    isExploreNow,
    setIsExploreNow,
    currentStep,
    onChangeStep,
    resetDefaultPackage,
    periodPackageDurations,
    packages,
    mainGroupFunctions,
    defaultHoverPackageId,
    setDefaultHoverPackageId,
    onClickBuyPackage,
    periodSelected,
    setPeriodSelected,
    minWidthTableFunctions,
  } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  useDragScroll(".billing-package .package");
  useDragScroll(".account-subscription .package");

  const [paymentDurations, setPaymentDurations] = useState([]);
  const [valueCheckPackage, setValueCheckPackage] = useState(null);
  const [selectedPackageId, setSelectedPackageId] = useState(Package.POS);
  const [selectedPackageDurationId, setSelectedPackageDurationId] = useState(null);
  const [allFunctionGroups, setAllFunctionGroups] = useState([]);
  const [bankTransferData, setBankTransferData] = useState(null);
  const [currentOrder, setCurrentOrder] = useState();
  const [packageOrderCode, setPackageOrderCode] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedPackagePrice, setSelectedPackagePrice] = useState(null);
  const auth = useSelector(authSelector);
  const [stepStatus, setStepStatus] = useState(false);
  /// The package data bought has been updated after the user create payment.
  const [packageBought, setPackageBought] = useState({});
  const [disableBankTransfer, setDisableBankTransfer] = useState(false);
  const [paymentTransactionId, setPaymentTransactionId] = useState("");
  const [paymentWindow, setPaymentWindow] = useState(null);
  const [processPaymentLoading, setProcessPaymentLoading] = useState(false);
  const [dataAmountCal, setDataAmountCal] = useState(null);
  const isInternationalRegion = env.REACT_APP_REGION === Region.International;
  useImperativeHandle(ref, () => ({
    selectPricingPackage: (id, packagePricingInfo, currencyCode, tax, indexDuration) => {
      handleSelectPackage(id, packagePricingInfo, currencyCode, tax, indexDuration);
      setSelectedPackageDurationId(indexDuration);

      if (currentStep === packagePricingStep.selectPackage) {
        const totalAmount = packagePricingInfo?.price;
        calPaypalFee(paymentMethod, totalAmount);
      }
    },
  }));

  const pageData = {
    paymentDuration: t("package.paymentDuration"),
    comingSoon: t("package.comingSoon"),
    titleImportData: t("package.titleImportData"),
    selectPackage: t("package.selectPackage"),
    payment: t("package.paymentPackage"),
    complete: t("package.completePackage"),
    month: t("package.payment.month"),
    months: t("package.payment.months"),
    percent: "%",
    summary: t("package.payment.summary"),
    package: t("package.payment.package"),
    duration: t("package.payment.duration"),
    packagePrice: t("package.payment.packagePrice"),
    total: t("package.payment.total"),
    choosePaymentMethod: t("package.payment.choosePaymentMethod"),
    visa: t("package.payment.visa"),
    atm: t("package.payment.atm"),
    bankTransfer: t("package.payment.bankTransfer"),
    paymentMethod: t("package.payment.paymentMethod"),
    pleaseCheckPayment: t("package.text.pleaseCheckPayment"),
    accountOwner: t("package.text.accountOwner"),
    accountNumber: t("package.text.accountNumber"),
    bankName: t("package.text.bankName"),
    branch: t("package.text.branch"),
    content: t("package.text.content"),
    backToDashboard: t("package.backDashBoard"),
    signInSuccess: t("signIn.youHaveBeenLoggedInSuccessfully"),
    signInHasPermissions: t("signIn.youHaveNoPermissions"),
    buySuccess: t("package.text.buySuccess"),
    registerSuccess: t("package.text.registerSuccess"),
    processPayment: t("package.text.processPayment"),
    backToPackageDetail: t("package.text.backToPackageDetail"),
    orderCode: t("package.text.orderCode"),
    contactInfo: t("package.text.contactInfo"),
    vat: t("package.vat"),
    continue: t("button.continue"),
    year: t("package.year"),
    years: t("package.years"),
    paypalFee: t("store.branchPurchase.paypalFee"),
    validPaypalMessage: t("package.payment.validPaypalMessage"),
    registerPackageByPaypalSuccess: t("package.text.registerPackageByPaypalSuccess"),
    registerPackageByPaypalFail: t("package.text.registerPackageByPaypalFail"),
    exploreNow: t("socialChannel.exploreNow", "Explore Now"),
    priceDoesNotIncludeVAT: t("package.priceDoesNotIncludeVAT", "*Giá trên chưa bao gồm 10% thuế GTGT"),
    wantToManagement: t("package.wantToManagement", "Want to start F&B management?"),
    notifyCompletedPaypalSubscription1: t("package.text.notifyCompletedPaypalSubscription1"),
    notifyCompletedPaypalSubscription2: t("package.text.notifyCompletedPaypalSubscription2"),
    createSubscriptionViaPaypalFail: t("package.text.createSubscriptionViaPaypalFail"),
  };

  const packagePricingStatus = [
    {
      key: packagePricingStep.selectPackage,
      title: pageData.selectPackage,
      icon: <ChoosePackageStepIcon />,
    },
    {
      key: packagePricingStep.payment,
      title: pageData.payment,
      icon: <PaymentStepIcon />,
    },
    {
      key: packagePricingStep.complete,
      title: pageData.complete,
      icon: <CompletedStepIcon />,
    },
  ];

  useEffect(() => {
    if (paymentTransactionId === "" || paymentWindow === null) {
      return;
    }
    const interval = setInterval(async () => {
      try {
        if (paymentWindow.closed) {
          clearInterval(interval);
          setPaymentWindow(null);
          setPaymentTransactionId("");
          updatePayPalPaymentResult();
          setProcessPaymentLoading(false);
        }
      } catch (error) {
        message.error(pageData.registerPackageByPaypalFail);
      }
    }, 2000);
  }, [paymentTransactionId, paymentWindow]);

  useEffect(() => {
    paypalService.removePayPalPaymentTransactionToLocalStorage();

    if (isInternationalRegion) {
      setPaymentMethod(orderPackagePaymentMethod.paypal);
      setDisableBankTransfer(true);
    } else {
      setPaymentMethod(orderPackagePaymentMethod.transfer);
    }
  }, []);

  useEffect(() => {
    getAllOrderPackages();
  }, [paymentMethod]);

  useEffect(() => {
    paypalService.removePayPalPaymentTransactionToLocalStorage();
  }, [currentStep, paymentMethod]);

  const updatePayPalPaymentResult = async () => {
    try {
      const result = await orderPackagePaymentTransactionService.getPayPalPayment(paymentTransactionId);
      switch (result) {
        case paymentStatus.successPayment:
          onChangeStep(packagePricingStep.completeByPayPal);
          break;
        case paymentStatus.failedPayment:
        case paymentStatus.newPayment:
          message.error(pageData.registerPackageByPaypalFail);
          break;
        default:
          break;
      }
    } catch (error) {
      message.error(pageData.registerPackageByPaypalFail);
    }
  };

  adminBroadCast.onmessage = async (event) => {
    let { action, data } = event.data;
    switch (action) {
      case BroadcastActions:
        let flag = data.vnp_ResponseCode === VnPayResponseCodes.paymentWasSuccessful;
        if (flag) {
          let formData = {
            orderInfo: data.vnp_OrderInfo,
            txnRef: data.vnp_TxnRef,
            vnPayCreateDate: currentOrder.vnPayCreateDate,
            code: packageOrderCode,
          };

          // Call the server to check this order.
          const response = await packageDataService.updateVNPayAsync(formData);
          if (response) {
            if (response.isSuccess) {
              // show step 3 (Completed)
              onChangeStep(currentStep + 1);
            } else {
              message.warn(t(response.message));
            }
          }
        }
        break;
      default:
        break;
    }
  };
  const getAllOrderPackages = () => {
    packageDataService.getPackagesPricingAsync().then((data) => {
      const { periodPackageDurations, allFunctionGroups } = data;
      setPaymentDurations(periodPackageDurations);
      setAllFunctionGroups(allFunctionGroups);
    });
  };

  const calPaypalFee = (paymentMethod, amount) => {
    const packageTax = selectedPackagePrice?.tax;
    const remainAmount = 0;
    paymentDataService.calcOrderPackageFeeAsync(paymentMethod, amount, remainAmount, packageTax).then((data) => {
      setDataAmountCal(data);
    });
  };

  const handleChangePackageDuration = (e) => {
    const currentPackage = packages?.find(
      (p) => p.id?.toString()?.toLowerCase() === selectedPackageId?.toString()?.toLowerCase(),
    );
    const selectedPackagePrice = currentPackage?.packagePricings?.find((_, i) => i === e.target.value);
    setSelectedPackagePrice(selectedPackagePrice);
    onCheckBoxPackageChange(e);
  };

  const onCheckBoxPackageChange = (e, value) => {
    const selected = value ?? e.target.value;
    setValueCheckPackage(selected);
    setSelectedPackageDurationId(selected);
  };

  const handleTransferPayment = async (method) => {
    setPaymentMethod(orderPackagePaymentMethod.vnPay);
    const createBankTransferModel = {
      packageId: selectedPackageId,
      packageDurationByMonth: paymentDurations[selectedPackageDurationId],
      PaymentMethod: method,
      RedirectUrl: `${env.REACT_APP_URL}/${env.API_VERSION ?? ""}transfer-payment`,
      Amount: calPackagePayment() + calPackagePercentPayment(),
      packagePriceId: selectedPackagePrice?.id,
    };
    const response = await packageDataService.createVNPayRequestAsync(createBankTransferModel);
    if (response) {
      setPackageOrderCode(response.code);
      var win = window.open(response.paymentUrl, "_blank");
      setCurrentOrder(response.orderInfo);
      win.focus();
    }
  };

  const calPackagePercentPayment = () => {
    let packageSelect = packages?.find(
      (item) => item?.id?.toString().toUpperCase() === selectedPackageId?.toString().toUpperCase(),
    );
    if (packageSelect) {
      return (selectedPackagePrice?.price * packageSelect?.tax) / 100;
    }
    return 0;
  };

  const calPackagePayment = () => {
    return selectedPackagePrice?.price;
  };

  const createBankTransferPayment = () => {
    setPaymentMethod(orderPackagePaymentMethod.transfer);

    const createBankTransferModel = {
      packagePricingId: selectedPackagePrice?.id,
      packageId: selectedPackageId,
      packageDurationByMonth: paymentDurations[selectedPackageDurationId],
      accountId: auth?.accountId ?? userInfo?.accountId ?? auth?.user?.accountId,
      storeId: auth?.storeId ?? userInfo?.storeId ?? auth?.user?.storeId,
    };

    packageDataService.createBankTransferPaymentAsync(createBankTransferModel).then((response) => {
      if (response) {
        const { packageBankTransfer } = response;
        const {
          packageCode,
          packageName,
          duration,
          totalAmount,
          packagePaymentMethodId,
          accountTransfer,
          paymentMethod,
        } = packageBankTransfer;
        const { accountNumber, accountOwner, bankName, branch, content } = accountTransfer;
        const bankTransferResponseData = {
          packageCode: packageCode,
          packageName: packageName,
          duration: duration,
          paymentMethod: paymentMethod,
          totalAmount: totalAmount,
          packagePaymentMethodId: packagePaymentMethodId,
        };
        const bankTransferInfo = {
          bankName: bankName,
          bankBranchName: branch,
          accountHolderName: accountOwner,
          accountNumber: accountNumber,
          content: content,
        };

        setBankTransferData(bankTransferInfo);
        setPackageBought(bankTransferResponseData);
        onChangeStep(packagePricingStep.completeByBankTransfer);
        setStepStatus(true);
      }
    });
  };

  const getUserInfo = (token) => {
    let claims = jwt_decode(token);
    let user = {
      userId: claims[claimTypesConstants.id],
      accountId: claims[claimTypesConstants.accountId],
      fullName: claims[claimTypesConstants.fullName],
      emailAddress: claims[claimTypesConstants.email],
      accountType: claims[claimTypesConstants.accountType],
      currencyCode: claims[claimTypesConstants.currencyCode],
      storeId: claims[claimTypesConstants.storeId],
    };

    return user;
  };

  const gotoDashBoard = () => {
    loginDataService.refreshTokenAndPermissionsAsync().then((res) => {
      const { token, permissions } = res;
      const userInfo = getUserInfo(token);
      let auth = { token: token, user: userInfo };
      if (permissions.length > 0) {
        const workspaceData = {
          auth: auth,
          token: token,
          permissions: permissions,
        };
        dispatch(setWorkspace(workspaceData));
        message.success(pageData.signInSuccess);
        history.push("/home");
      } else {
        message.error(pageData.signInHasPermissions);
      }
    });
  };

  const nextStep = () => {
    if (selectedPackagePrice) {
      const _nextStep = currentStep + 1;
      if (_nextStep === packagePricingStep.payment) {
        var totalAmount = calPackagePayment();
        calPaypalFee(paymentMethod, totalAmount);
      }

      onChangeStep(_nextStep);
    }
  };

  const prevStep = () => {
    onChangeStep(currentStep - 1);
  };

  const onProcessPayment = async () => {
    setProcessPaymentLoading(true);
    switch (paymentMethod) {
      case orderPackagePaymentMethod.vnPay:
        await handleTransferPayment(orderPackagePaymentMethod.vnPay);
        break;
      case orderPackagePaymentMethod.atm:
        await handleTransferPayment(orderPackagePaymentMethod.atm);
        break;
      case orderPackagePaymentMethod.transfer:
        createBankTransferPayment();
        break;
      default:
    }
  };

  const onPayPalCreatePayment = useCallback(async () => {
    let partnerTransactionIdInLocal = localStorage.getItem(localStorageKeys.PAYPAL_PAYMENT_TRANSACTION_ID) ?? "";
    if (partnerTransactionIdInLocal) {
      return JSON.parse(partnerTransactionIdInLocal);
    }

    const requestModel = {
      packagePricingId: selectedPackagePrice?.id,
      packageId: selectedPackageId,
      packageDurationByMonth: paymentDurations[selectedPackageDurationId],
      accountId: auth?.accountId ?? userInfo?.accountId ?? auth?.user?.accountId,
      storeId: auth?.storeId ?? userInfo?.storeId ?? auth?.user?.storeId,
      autoRenew: true
    };
    const response = await packageDataService.createStorePackagePayPalPayment(requestModel);
    if (response?.data?.partnerTransactionId) {
      paypalService.savePayPalPaymentTransactionInLocalStorage(JSON.stringify(response?.data?.partnerTransactionId ?? ""));
      return response?.data?.partnerTransactionId;
    } else {
      return null;
    }
  }, [auth, paymentDurations, selectedPackageDurationId, selectedPackageId, selectedPackagePrice?.id, userInfo]);

  const onPaypalApprove = async (data) => {
    paypalService.removePayPalPaymentTransactionToLocalStorage();
    onChangeStep(packagePricingStep.completeByPayPal);
  };

  const onPaypalCancel = async (data) => {
    //use after
    // message.error(pageData.registerPackageByPaypalFail);
  };

  const onPaypalError = async (data) => {
    paypalService.removePayPalPaymentTransactionToLocalStorage();
    message.error(pageData.createSubscriptionViaPaypalFail);
  };

  const renderPayPalButton = useMemo(() => {
    if (dataAmountCal?.payPalClientId) {
      return (
        <PayPalScriptProvider
          options={{
            clientId: dataAmountCal?.payPalClientId,
            intent: "subscription",
            vault: true,
            locale: "en_US",
            components: "buttons"
          }}
        >
          <PayPalButtons
            style={{ shape: "rect", layout: "vertical", borderRadius: 10 }}
            className="paypal-button"
            createSubscription={onPayPalCreatePayment}
            onApprove={onPaypalApprove}
            onCancel={onPaypalCancel}
            onError={onPaypalError}
          />
        </PayPalScriptProvider>
      );
    }

    return <></>;
  }, [dataAmountCal, onPayPalCreatePayment]);

  const renderPackagePayment = () => {
    const renderPaymentMethods = orderPackagePaymentMethods
      .filter((item) => {
        if (isInternationalRegion) {
          return item.code === orderPackagePaymentMethod.paypal && !item.disable;
        } else {
          return item.code !== orderPackagePaymentMethod.paypal && !item.disable;
        }
      })
      .map((method) => {
        const { name, icon, disable } = method;
        const singleIcon = method.icon && method.icon?.length > 0;
        const renderMethod = (
          <Row
            className={`payment-method-wrapper${singleIcon === true ? " multiple" : ""}${
              disable === true ? " disabled" : ""
            }`}
          >
            <Col span={24}>
              <div>
                <Radio
                  value={method.code}
                  disabled={method.code === orderPackagePaymentMethod.transfer && disableBankTransfer}
                >
                  {singleIcon === true ? (
                    <div className="payment-method-icons">
                      <div className="name">{t(name)}</div>
                      <div className="icons">
                        {method.icon?.map((icon, index) => {
                          return <div key={index}>{icon}</div>;
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="payment-method">
                      <div className="icon">{icon}</div>
                      <div className="name">
                        {t(name)}{" "}
                        {method.code === orderPackagePaymentMethod.transfer && disableBankTransfer && (
                          <span className="valid-paypal-message">{` - ${pageData.validPaypalMessage}`}</span>
                        )}
                      </div>
                    </div>
                  )}
                </Radio>
              </div>
            </Col>
          </Row>
        );

        return renderMethod;
      });

    var selectedPackage = packages?.find(
      (item) => item?.id?.toString().toUpperCase() === selectedPackageId?.toString().toUpperCase(),
    );
    var selectedPackageName = selectedPackage?.name;
    var selectedPackageNameArray = selectedPackageName?.split("+");
    var numberOfSelectedPackages = selectedPackageNameArray.length;
    const titleWidth = () => {
      switch (numberOfSelectedPackages) {
        case 2:
          return { width: "40%" };
        case 3:
          return { width: "30%" };
        case 1:
        default:
          return { width: "50%" };
      }
    };
    const fullWidthPercentage = 100;
    const titleWidthPercentage = Number(titleWidth().width.replace("%", ""));
    const remainingWidthPercentage = fullWidthPercentage - titleWidthPercentage;
    const packageWidth = { width: `${remainingWidthPercentage}%` };
    return (
      <Row className="payment-step">
        <Col xs={24} sm={24} md={24} lg={12} className="payment-method-options">
          <div>
            <p className="payment-method-area-label">{pageData.choosePaymentMethod}</p>
          </div>
          <Radio.Group
            className="payment-group"
            onChange={(e) => {
              const selectedValue = e.target.value;
              setPaymentMethod(selectedValue);
              calPaypalFee(selectedValue, calPackagePayment());
            }}
            value={paymentMethod}
            defaultChecked={paymentMethod}
          >
            {renderPaymentMethods}
          </Radio.Group>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} className="summary">
          <div className="header">
            <h2 className="title" style={titleWidth()}>
              {pageData.summary}
            </h2>
            <div className="package-name" style={packageWidth}>
              <div className="tab">
                <div>
                  <p>{pageData.package}</p>
                </div>
                <div>
                  <p>{selectedPackageName}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="label">
              <p>{pageData.duration}</p>
              <p className="material-view-text">{pageData.packagePrice}</p>

              {/* The VAT will be 0$ and hide the field VAT in International Region*/}
              <p className={isInternationalRegion ? "d-none" : ""}>
                {pageData.vat +
                  "(" +
                  packages?.find(
                    (item) => item?.id?.toString().toUpperCase() === selectedPackageId?.toString().toUpperCase(),
                  )?.tax +
                  pageData.percent +
                  ")"}
              </p>

              {/* The Fee will be 0$ and hide the field Fee in International Region*/}
              {paymentMethod === orderPackagePaymentMethod.paypal && (
                <p className={isInternationalRegion ? "d-none" : "material-view-text"}>{pageData.paypalFee}</p>
              )}

              <h3 className="total">{pageData.total}</h3>
            </div>
            <div className="info">
              <p>
                {paymentDurations[selectedPackageDurationId] / 12}{" "}
                {paymentDurations[selectedPackageDurationId] / 12 <= 1 ? pageData.year : pageData.years}
              </p>
              <p className="material-view-text">
                {formatNumber(dataAmountCal?.subTotal, 2, isInternationalRegion)} {selectedPackagePrice?.currencyCode}
              </p>

              {/* The VAT will be 0$ and hide the field VAT in International Region*/}
              <p className={isInternationalRegion ? "d-none" : "material-view-text"}>
                {formatNumber(dataAmountCal?.vat, 2, isInternationalRegion)} {selectedPackagePrice?.currencyCode}
              </p>

              {/* The Fee will be 0$ and hide the field Fee in International Region*/}
              {paymentMethod === orderPackagePaymentMethod.paypal && (
                <p className="material-view-text d-none">
                  {formatNumber(dataAmountCal?.payPalFee, 2, isInternationalRegion)}{" "}
                  {selectedPackagePrice?.currencyCode}
                </p>
              )}

              <h3 className="total">
                {formatNumber(dataAmountCal?.total, 2, isInternationalRegion)} {selectedPackagePrice?.currencyCode}
              </h3>
            </div>
          </div>
          <div className="footer">
            {paymentMethod === orderPackagePaymentMethod.paypal ? (
              renderPayPalButton
            ) : (
              <Button
                loading={processPaymentLoading}
                icon={<CardTickIcon />}
                className="process-payment-btn"
                onClick={onProcessPayment}
                disabled={paymentMethod === ""}
              >
                {pageData.processPayment}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  const renderTaxAndContinueButton = () => {
    return (
      <Row justify="space-between" className="float-right row-vat">
        {isExploreNow == false && (
          <Col span={12} className="social-explore">
            <>
              {pageData.wantToManagement}
              <span
                className="button-explore"
                onClick={() => {
                  setIsExploreNow(true);
                }}
              >
                {pageData.exploreNow}
              </span>
            </>
          </Col>
        )}
        <Col span={isSocialChannel && isExploreNow == false ? 12 : 24} className="continue-area">
          <div>
            <span className="label-vat">{pageData.priceDoesNotIncludeVAT}</span>
          </div>
          <div className="mt-16">
            <Button
              type="primary"
              className="button-continue d-flex-align-center"
              onClick={nextStep}
              disabled={!selectedPackagePrice}
            >
              {pageData.continue}
              <span className="continue-icon">
                <PackageContinueIcon />
              </span>
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  const renderPermissionTable = () => {
    return (
      <>
        {/* Will implement the international region this feature in version 3.5 */}
        {!isInternationalRegion && isExploreNow == true && (
          <div className="package-permission-wrapper">
            {allFunctionGroups?.map((functionGroup) => {
              const { id, name, functions } = functionGroup;
              return (
                <div className="package-list">
                  <div className="package-function">
                    <p>{name}</p>
                  </div>
                  <div className="package-permission">
                    {functions?.map((f) => {
                      const { id, name } = f;
                      const isCheckedPOS = packages
                        .find((p) => p.id === Package.POS?.toLowerCase())
                        ?.functions?.find((item) => item?.id === id);
                      const isCheckedWEB = packages
                        .find((p) => p.id === Package.WEB?.toLowerCase())
                        ?.functions?.find((item) => item?.id === id);
                      const isCheckedAPP = packages
                        .find((p) => p.id === Package.APP?.toLowerCase())
                        ?.functions?.find((item) => item?.id === id);
                      return (
                        <Row className="row-permission row-permission-border">
                          <Col span={7} className="permission-name">
                            {name}
                            {id === 2 && (
                              <FnbTooltip placement="topLeft" title={pageData.titleImportData}>
                                <span className="ml-18 mt-22">
                                  <ExclamationIcon />
                                </span>
                              </FnbTooltip>
                            )}
                          </Col>
                          <Col span={5} className="permission-status">
                            <Row className="justify-content-center">
                              {isCheckedPOS ? <CheckOutlined style={{ color: "#32CD32" }} /> : <></>}
                            </Row>
                          </Col>
                          <Col span={7} className="permission-status">
                            <Row className="justify-content-center">
                              {isCheckedWEB ? <CheckOutlined style={{ color: "#32CD32" }} /> : <></>}
                            </Row>
                          </Col>
                          <Col span={5} className="permission-status">
                            <Row className="justify-content-center">
                              {isCheckedAPP ? <CheckOutlined style={{ color: "#32CD32" }} /> : <></>}
                            </Row>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const renderPackageDetailInFourCard = () => {
    // fetch package info by payment method

    return (
      <div className="package-detail four-cards">
        <Row>
          <Col span={4}>
            <div className="duration-time">
              <h3 className="label label-color">
                <strong>{pageData.paymentDuration}</strong>
              </h3>
              <div className="duration-wrapper">
                {paymentDurations?.map((paymentDuration, index) => {
                  const durationMonthToYear = paymentDuration / 12;
                  const timeOptionLabel = `${durationMonthToYear} ${
                    durationMonthToYear <= 1 ? pageData.year : pageData.years
                  }`;
                  return (
                    <div className="duration-option">
                      <Radio.Group onChange={(e) => handleChangePackageDuration(e)} value={valueCheckPackage}>
                        <Radio value={index}>
                          <p className="time-option-label">{timeOptionLabel}</p>
                        </Radio>
                      </Radio.Group>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col span={20} className="package">
            <div className="package-option-wrapper">
              <div className="package-option-container package-four-cards">
                {packages?.map((p) => {
                  const { id, name, tax, isActive, isChoose, packagePricings, currencyCode } = p;
                  if (isExploreNow == false && id.toUpperCase() != Package.SOCIAL_CHANNEL) {
                    return <></>;
                  } else {
                    // TODO update currency symbol
                    const packageSelectedStyle = isChoose === true ? "package-selected" : "";
                    return (
                      <div className={`package-option-card-4-cards ${packageSelectedStyle}`}>
                        <div className="package-option">
                          <p className="platform-name text-center label-color">
                            <strong>{name}</strong>
                          </p>
                          {isActive === true ? (
                            paymentDurations?.map((duration, indexDuration) => {
                              const durationMonthToYear = duration / 12;
                              const packagePricingInfo = packagePricings?.find((p) => p.period === duration);
                              const costPerYear = ((packagePricingInfo?.price ?? 0) / durationMonthToYear).toFixed(2);
                              const end = ` ${currencyCode}/${pageData.year}`;
                              const pricingSelectedStyle =
                                selectedPackagePrice?.period === duration && selectedPackagePrice?.packageId === id
                                  ? "pricing-selected"
                                  : "";

                              const packageSameDurationStyle =
                                selectedPackagePrice?.period === duration && selectedPackagePrice?.packageId !== id
                                  ? "pricing-hover"
                                  : "";

                              return (
                                <div
                                  className={`pricing-detail w-100 ${packageSameDurationStyle} ${pricingSelectedStyle}`}
                                  onClick={() =>
                                    handleSelectPackage(id, packagePricingInfo, currencyCode, tax, indexDuration)
                                  }
                                >
                                  <p>
                                    <span className="price">{formatNumber(costPerYear, 2, isInternationalRegion)}</span>
                                    <span className="end-price">{end}</span>
                                    {packagePricingInfo?.discountPercent > 0 && (
                                      <span className="discount-tag">-{packagePricingInfo?.discountPercent}%</span>
                                    )}
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <Image className="coming-soon" preview={false} src={images.comingSoon} />
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Col>
        </Row>
        {renderTaxAndContinueButton()}
        {renderPermissionTable()}
      </div>
    );
  };

  const handleSelectPackage = (id, packagePricingInfo, currencyCode, tax, indexDuration) => {
    setSelectedPackageId(id);
    setSelectedPackagePrice({
      ...packagePricingInfo,
      currencyCode: currencyCode,
      tax: tax,
    });
    onCheckBoxPackageChange(null, indexDuration);
  };

  const handleBackToDashboard = () => {
    if (isSocialChannel) {
      removeStorage(localStorageKeys.LOGIN);
      removeStorage(localStorageKeys.TOKEN);
      removeStorage(localStorageKeys.CUSTOMER_INFO);
      dispatch(setUserInfo(null));
      window.location.href = `${env.REACT_APP_SOCIAL_DOMAIN}/login`;
    } else {
      handleSetPermissionAndRedirectToHome();
    }
  };

  const handleSetPermissionAndRedirectToHome = () => {
    let authDetail = { token: auth.token, user: auth.user };

    permissionDataService.getPermissionsAsync(auth.token).then((res) => {
      const { permissions, permissionGroup } = res;
      if (permissions.length > 0 && permissionGroup.length > 0) {
        dispatch(setPermissionGroup(permissionGroup));
        handleSetUserAuth(authDetail, authDetail.token, permissions);
        history.push("/");
      } else {
        message.error(t("signIn.youHaveNoPermissions"));
      }
    });
  };

  const handleSetUserAuth = (auth, token, permissions) => {
    dispatch(setAuth(auth));
    dispatch(setToken(token));
    dispatch(setPermissions(permissions));

    storeDataService.getStoreInformationAsync().then((response) => {
      dispatch(setStoreInformation(response));
    });

    storeDataService.getPrepareAddressDataAsync().then((response) => {
      dispatch(setPrepareAddressData(response));
    });
  };

  const renderCompleteStep = () => {
    return (
      <div className="complete-step">
        <div className="image">
          <CompletedIcon />
        </div>
        <div className="message">
          <p
            dangerouslySetInnerHTML={{
              __html: <p>{t(pageData.buySuccess, { packageName: packageBought?.packageName })}</p>,
            }}
          ></p>
        </div>

        <div className="footer">
          <Button onClick={() => history.push("/")} className="back-btn">
            {pageData.backToDashboard}
          </Button>
        </div>
      </div>
    );
  };

  const renderCompleteBankTransferStep = () => {
    return (
      <div className="bank-transfer-complete-step">
        <div className="image">
          <PaymentSuccessIcon />
        </div>
        <div className="message">
          <p>{t(pageData.registerSuccess, { packageName: packageBought?.packageName })}</p>
        </div>
        <div className="order-package-info">
          <div className="order-package-info-card">
            <ul className="border-right">
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.orderCode}:</p>
                </div>
                <div>
                  <p className="value">{packageBought?.packageCode}</p>
                </div>
              </li>
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.package}:</p>
                </div>
                <div>
                  <p className="value">{packageBought?.packageName}</p>
                </div>
              </li>
            </ul>
            <ul>
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.duration}:</p>
                </div>
                <div>
                  <p className="value">
                    {packageBought?.duration} {pageData.months}
                  </p>
                </div>
              </li>
              <li className="order-package-detail">
                <div className="w-60">
                  <p className="label">{pageData.paymentMethod}:</p>
                </div>
                <div>
                  <p className="value">{packageBought?.paymentMethod}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="body">
          <div>
            <p className="total-price">
              <span>{pageData.total}: </span>
              <span className="price">{formatCurrencyWithCode(packageBought?.totalAmount)}</span>
            </p>
          </div>
          <div className="message detail">
            <p>{pageData.pleaseCheckPayment}:</p>
          </div>

          <div className="bank-info">
            <BankTransferInfoComponent data={bankTransferData} />
          </div>
          <div
            className="contact"
            dangerouslySetInnerHTML={{
              __html: pageData.contactInfo,
            }}
          ></div>
        </div>

        <div className="footer">
          <Button
            onClick={() => {
              if (isSocialChannel) {
                removeStorage(localStorageKeys.LOGIN);
                removeStorage(localStorageKeys.TOKEN);
                removeStorage(localStorageKeys.CUSTOMER_INFO);
                dispatch(setUserInfo(null));
                window.location.href = `${env.REACT_APP_SOCIAL_DOMAIN}/login`;
              } else {
                history.push("/");
              }
            }}
            className="back-btn"
          >
            {pageData.backToDashboard}
          </Button>
        </div>
      </div>
    );
  };

  const renderCompletePaypalStep = () => {
    let packageName = packages?.find(
      (item) => item?.id?.toString().toUpperCase() === selectedPackageId?.toString().toUpperCase(),
    )?.name;
    return (
      <div className="paypal-complete-step">
        <div className="image">
          <CompletedIcon />
        </div>
        <div className="message">
          <p>{t(pageData.notifyCompletedPaypalSubscription1)}</p>
          <p>{t(pageData.notifyCompletedPaypalSubscription2)}</p>
        </div>
        <div className="footer">
          <Button onClick={() => handleBackToDashboard()} className="back-btn">
            {pageData.backToDashboard}
          </Button>
        </div>
      </div>
    );
  };

  const packageSection = () => {
    return (
      <MyAccountWrapperRightContent>
        <RightContent rowGap={24} className="package-section-page-my-account">
          <DivideHorizontalLine />
          <PackageSection
            packages={packages}
            periodPackageDurations={periodPackageDurations}
            mainGroupFunctions={mainGroupFunctions}
            periodSelected={periodSelected}
            setPeriodSelected={setPeriodSelected}
            defaultHoverPackageId={defaultHoverPackageId}
            setDefaultHoverPackageId={setDefaultHoverPackageId}
            onClickBuyPackage={onClickBuyPackage}
            backgroundColor="#fff"
            backgroundColorDivide="#F7F5FF"
          />
          <DivideHorizontalLine />
          <TabFeaturePackage
            t={t}
            mainGroupFunctions={mainGroupFunctions}
            minWidthTableFunctions={minWidthTableFunctions}
          />
        </RightContent>
      </MyAccountWrapperRightContent>
    );
  };
  const renderBody = () => {
    switch (currentStep) {
      case packagePricingStep.selectPackage:
        return packageSection();
      case packagePricingStep.payment:
        return renderPackagePayment();
      case packagePricingStep.complete:
        return renderCompleteStep();
      case packagePricingStep.completeByBankTransfer:
        return renderCompleteBankTransferStep();
      case packagePricingStep.completeByPayPal:
        return renderCompletePaypalStep();
      default:
        break;
    }
  };

  /// Main render
  return (
    <div className="billing-class">
      {currentStep === packagePricingStep.payment && (
        <FnbButton
          iconHeader={<LeftArrowOutlined />}
          onClick={() => {
            resetDefaultPackage && resetDefaultPackage();
            prevStep();
          }}
          text={pageData.backToPackageDetail}
          variant="tertiary"
          className="package-container__btn-go-back"
        />
      )}
      <Row className="buy-package-steps buy-package-billing-steps">
        <Col span={24} className="steps-component">
          <FnbSteps currentStep={currentStep}>
            {packagePricingStatus.map((item) => (
              <Step isProcessing={stepStatus} key={item.title} title={item.title} icon={item.icon} />
            ))}
          </FnbSteps>
        </Col>
      </Row>
      <Row className="package-info">
        <Col span={24}>{renderBody()}</Col>
      </Row>
    </div>
  );
});

export default BillingComponent;
