import { getInternationalRegion } from "../../../utils/helpers";
import ahamoveIcon from "../../assets/icons/ahamove.svg";
import cashIcon from "../../assets/icons/cash.svg";
import codIcon from "../../assets/icons/cod.svg";
import grabExpressIcon from "../../assets/icons/icon-grab-express.svg";
import momoIcon from "../../assets/icons/momo.svg";
import shippingIcon from "../../assets/icons/shipping.svg";
import visaIcon from "../../assets/icons/visa-master-card.svg";
import bankIcon from "../../assets/images/payment-default-logo.png";
import paypalIcon from "../../assets/images/paypal-logo.png";
import vnPayIcon from "../../assets/images/vnpay-logo.png";
import zaloIcon from "../../assets/images/zalo-pay-logo.png";

const isInternationalRegion = getInternationalRegion();

export const shippingIcons = [
  { enumId: 1, icon: shippingIcon },
  { enumId: 2, icon: ahamoveIcon },
  { enumId: 4, icon: grabExpressIcon },
];

export const paymentIcons = [
  { enumId: 0, icon: momoIcon },
  { enumId: 1, icon: zaloIcon },
  { enumId: 2, icon: visaIcon },
  { enumId: 3, icon: cashIcon },
  { enumId: 4, icon: vnPayIcon },
  { enumId: 5, icon: codIcon },
  { enumId: 6, icon: bankIcon },
  { enumId: 9, icon: paypalIcon },
];

//Use mockup data for customize & preview checkout only
export const mockupTaxes = [
  { name: "VAT 5%", value: 9000 },
  { name: "VAT 8%", value: 22000 },
  { name: "VAT 10%", value: 39000 },
];

//Use mockup data for customize & preview checkout only
export const mockupDiscountsLocal = [
  { promotionName: "Giảm giá trong ngày", totalDiscountAmount: 9000 },
  { promotionName: "Khuyến mãi đặc biệt", totalDiscountAmount: 22000 },
];

export const mockupCheckoutLocal = {
  name: "Nguyễn Văn A",
  phone: "0901234567",
  deliveryAddress: {
    receiverAddress: {
      id: "5372b8a6-8cda-4912-b656-d16742c86d24",
      name: "Home",
      address: "123 Đường Trần Quang Khải, Tân Định, Quận 1, Thành phố Hồ Chí Minh",
      customerAddressTypeId: 0,
      lat: 10.7918789,
      lng: 106.6921262,
      addressDetail: "Nhà",
      note: "Nhà",
    },
    branchAddress: {
      id: "c755f3b6-0807-48bc-bbcd-20eaff469dad",
      title: "Quang Trung",
      addressDetail: "656 Quang Trung, 11, Gò Vấp, Hồ Chí Minh",
      distance: "15,0 km",
      lat: 10.836209,
      lng: 106.6598915,
    },
  },
  deliveryMethods: [
    {
      deliveryMethodId: "4eeb36bd-d0e6-4ef2-a89e-a0057e8540cb",
      enumDeliveryMethod: 1,
      deliveryMethodName: "Delivery by shop",
      pricing: 10000,
    },
    {
      deliveryMethodId: "be8dac49-96a5-482e-a49b-6bf1a223156f",
      enumDeliveryMethod: 2,
      deliveryMethodName: "AhaMove",
      pricing: 42000,
    },
  ],
  paymentMethods: [
    {
      paymentMethodEnumId: 3,
      paymentMethodName: "Cash",
      icon: "assets/images/payment-cash-logo.png",
      paymentMethodId: "37ea8d80-d9b2-4208-85bb-8473cf771422",
      isDeleted: false,
    },
    {
      paymentMethodEnumId: 0,
      paymentMethodName: "MoMo",
      icon: "assets/images/logo-momo.png",
      paymentMethodId: "b26d46a4-c3ab-4182-be94-81bf5c7554e5",
      isDeleted: false,
    },
  ],
};

const mockupCheckoutInternational = {
  name: "Nguyen Van A",
  phone: "0901234567",
  deliveryAddress: {
    receiverAddress: {
      id: "5372b8a6-8cda-4912-b656-d16742c86d24",
      name: "Home",
      address: "Address",
      customerAddressTypeId: 0,
      lat: 10.7918789,
      lng: 106.6921262,
      addressDetail: "Home",
      note: "Home",
    },
    branchAddress: {
      id: "c755f3b6-0807-48bc-bbcd-20eaff469dad",
      title: "Title",
      addressDetail: "Address Detail",
      distance: "15,0 km",
      lat: 10.836209,
      lng: 106.6598915,
    },
  },
  deliveryMethods: [
    {
      deliveryMethodId: "4eeb36bd-d0e6-4ef2-a89e-a0057e8540cb",
      enumDeliveryMethod: 1,
      deliveryMethodName: "Delivery by shop",
      pricing: 10000,
    },
    {
      deliveryMethodId: "be8dac49-96a5-482e-a49b-6bf1a223156f",
      enumDeliveryMethod: 2,
      deliveryMethodName: "AhaMove",
      pricing: 42000,
    },
  ],
  paymentMethods: [
    {
      paymentMethodEnumId: 3,
      paymentMethodName: "Cash",
      icon: "assets/images/payment-cash-logo.png",
      paymentMethodId: "37ea8d80-d9b2-4208-85bb-8473cf771422",
      isDeleted: false,
    },
    {
      paymentMethodEnumId: 0,
      paymentMethodName: "MoMo",
      icon: "assets/images/logo-momo.png",
      paymentMethodId: "b26d46a4-c3ab-4182-be94-81bf5c7554e5",
      isDeleted: false,
    },
  ],
};

const mockupDiscountsInternational = [
  { promotionName: "Discount during the day", totalDiscountAmount: 9000 },
  { promotionName: "Special offer", totalDiscountAmount: 22000 },
];

export const mockupCheckout = isInternationalRegion ? mockupCheckoutInternational : mockupCheckoutLocal;
export const mockupDiscounts = isInternationalRegion ? mockupDiscountsInternational : mockupDiscountsLocal;
