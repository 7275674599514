import { useState, useEffect } from "react";
import { Col, Row, message } from "antd";
import TableSellingProduct from "./components/table-selling-product.component";
import TableShiftOrderDetail from "./components/table-order.component";
import ShiftInfoComponent from "./components/shift-info.component";
import PageTitle from "components/page-title";
import { FnbButton } from "components/fnb-button/fnb-button";
import { ArrowLeftBannerIcon, ExportShiftIcon } from "constants/icons.constants";
import { useHistory } from "react-router";
import { TRANSACTION_TABPANE_KEY } from "constants/report.constants";
import reportDataService from "data-services/report/report-data.service";
import { handleDownloadFile } from "utils/helpers";
import languageService from "services/language/language.service";
import "./detail-shift.page.scss";
import { hasPermission } from "utils/helpers";
import { PermissionKeys } from "constants/permission-key.constants";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import { useLocation } from "react-router-dom";

/**
 * Page Shift Detail
 * description: page Shift Detail primary template
 */
const KEY_TAB_PANE = {
  ORDER: "order",
  SOLD_PRODUCT: "sold-product",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.ORDER]: 0,
  [KEY_TAB_PANE.SOLD_PRODUCT]: 1,
};

const DEFAULT_SCREEN = KEY_TAB_PANE.ORDER;

export default function ShiftDetail(props) {
  const { t, shiftDataService, match } = props;
  const history = useHistory();
  const { search } = useLocation();
  const startDate = new URLSearchParams(search).get("startDate");
  const endDate = new URLSearchParams(search).get("endDate");
  const typeOptionDate = new URLSearchParams(search).get("typeOptionDate");

  const pageData = {
    title: t("report.shiftDetail.title"),
    orderTabTitle: t("report.shift.order"),
    sellingProductTabTitle: t("report.shiftDetail.sellingProduct.title"),
    export: t("button.export"),
    back: t("button.back"),
    exportSuccessful: t("messages.exportSuccessful"),
  };

  const [shiftDetail, setShiftDetail] = useState({});
  const [activeScreen, setActiveScreen] = useState(DEFAULT_SCREEN);

  useEffect(() => {
    let fetchData = () => {
      initGetShiftInfo();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    history.push(
      `/report/transaction/${TRANSACTION_TABPANE_KEY.SHIFT}?startDate=${startDate}&endDate=${endDate}&typeOptionDate=${typeOptionDate}`,
    );
  };

  window.onpopstate = () => {
    handleBack();
  };

  const initGetShiftInfo = () => {
    shiftDataService.getInfoShiftByIdRequesAsync(match?.params?.shiftId).then((res) => {
      setShiftDetail(res);
    });
  };

  const handleExportShiftDetail = () => {
    let languageCode = languageService.getLang();
    var timeZone = new Date().getTimezoneOffset() / 60;
    var param = {
      languageCode: languageCode,
      shiftId: shiftDetail?.shiftInfo?.shiftId,
      timeZone: timeZone,
    };
    reportDataService
      .exportShiftDetailAsync(param, (progress) => {
        if (progress === 100) message.success(pageData.exportSuccessful);
      })
      .then(async (response) => {
        try {
          handleDownloadFile(response);
        } catch (error) {
          const { statusText } = error;
          message.error(statusText);
        }
      });
  };

  const screens = [
    {
      name: pageData.orderTabTitle,
      key: KEY_TAB_PANE.ORDER,
      component: <TableShiftOrderDetail t={t} />,
    },
    {
      name: pageData.sellingProductTabTitle,
      key: KEY_TAB_PANE.SOLD_PRODUCT,
      component: <TableSellingProduct t={t} />,
    },
  ];
  const handleChangeScreen = (activeKey) => {
    setActiveScreen(activeKey);
  };
  return (
    <div className="shift-detail">
      <Row className="title-shift-detail">
        <Col span={24}>
          <div className="back-button" onClick={handleBack}>
            <ArrowLeftBannerIcon />
            <span>{pageData.back}</span>
          </div>
        </Col>
        <Col span={12}>
          <PageTitle content={pageData.title} />
        </Col>
        <Col span={12}>
          {hasPermission(PermissionKeys.EXPORT_SHIFT_REPORT) && (
            <FnbButton
              className="float-right btn-export-shift"
              onClick={handleExportShiftDetail}
              text={pageData.export}
              permission={PermissionKeys.EXPORT_SHIFT_REPORT}
              iconHeader={<ExportShiftIcon />}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ShiftInfoComponent t={t} shiftDetail={shiftDetail} />
        </Col>
      </Row>
      <Row style={{ marginTop: "24px" }}>
        <Col span={24}>
          {/* <Tabs defaultActiveKey="1" className="area-table-tabs" id="shift-detail-tabs-id">
            <TabPane tab={pageData.orderTabTitle} key="1">
              <div className="clearfix"></div>
              <Card>
                <TableShiftOrderDetail t={t} />
              </Card>
            </TabPane>
            <TabPane tab={pageData.sellingProductTabTitle} key="2">
              <div className="clearfix"></div>
              <Card>
                <TableSellingProduct t={t}/>
              </Card>
            </TabPane>
          </Tabs> */}

          <FnbTabPane
            screens={screens}
            activeScreen={activeScreen}
            indexKeyTabPane={INDEX_KEY_TAB_PANE}
            onChange={handleChangeScreen}
            defaultScreen={DEFAULT_SCREEN}
            firstKeyTabPane={KEY_TAB_PANE.ORDER}
            lastKeyTabPane={KEY_TAB_PANE.SOLD_PRODUCT}
          />
        </Col>
      </Row>
    </div>
  );
}
