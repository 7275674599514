import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { PackagePriceHeading } from "constants/icons.constants";
import { useEffect, useRef, useState } from "react";
import theme from "theme";
import { formatNumberDecimalOrInteger } from "utils/helpers";
import {
  BackgroundPriceContainerLeft,
  BackgroundPriceContainerRight,
  ButtonContainer,
  HeadingPriceContainer,
  PackagePriceContainer,
  Platform,
  PriceContainer,
  PriceText,
} from "./item-package-price.styled";

const ItemPackagePrice = ({
  packageItem,
  isOdd = false,
  isInternational,
  periodSelected,
  defaultHoverPackageId,
  setDefaultHoverPackageId,
  onClickBuyPackage,
  t,
}) => {
  const [price, setPrice] = useState(packageItem?.pricings[0].price);
  const [packagePriceSelected, setPackagePriceSelected] = useState(packageItem?.pricings[0].price);
  const [isDefaultHover, setIsDefaultHover] = useState(false);
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  useEffect(() => {
    const packagePrice = packageItem?.pricings?.find((price) => price.period === periodSelected);
    const pricePackage = packagePrice?.price;
    const pricePerYear = pricePackage / (periodSelected / 12);
    if (!isInternational) {
      setPrice(Math.round(pricePerYear));
    } else {
      setPrice(pricePerYear.toFixed(2));
    }

    setPackagePriceSelected(packagePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodSelected]);

  useEffect(() => {
    setIsDefaultHover(packageItem?.id === defaultHoverPackageId && !isTouchDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultHoverPackageId]);

  const refHover = useRef(null);
  const onHoverPackage = () => {
    if (refHover.current) {
      clearTimeout(refHover.current);
    }
    refHover.current = setTimeout(() => {
      if (defaultHoverPackageId !== null) {
        setDefaultHoverPackageId(null);
      }
    }, 100);
  };

  const pageData = {
    buyNow: t("package.buyNow"),
    perYear: t("package.perYear")?.toLowerCase(),
  };

  if (!Boolean(packagePriceSelected)) {
    return <></>;
  }

  return (
    <PackagePriceContainer defaultHover={isDefaultHover} onMouseOver={onHoverPackage}>
      <HeadingPriceContainer defaultHover={isDefaultHover}>
        <PackagePriceHeading />
      </HeadingPriceContainer>
      <Platform defaultHover={isDefaultHover}>
        <FnbTypography text={packageItem?.name} variant={theme.typography["h1-medium"]} />
      </Platform>
      {isOdd ? (
        <BackgroundPriceContainerLeft defaultHover={isDefaultHover} />
      ) : (
        <BackgroundPriceContainerRight defaultHover={isDefaultHover} />
      )}
      <PriceContainer defaultHover={isDefaultHover}>
        <PriceText>{`${formatNumberDecimalOrInteger(price)} ${packagePriceSelected?.currencyCode}`}</PriceText>
        <FnbTypography
          variant={theme.typography["b1-regular"]}
          text={pageData.perYear}
          color={theme.colors.primary.main}
        />
      </PriceContainer>
      <ButtonContainer>
        <FnbButton
          variant="secondary"
          text={pageData.buyNow}
          width={264}
          onClick={() => onClickBuyPackage(periodSelected, packageItem?.id)}
        />
      </ButtonContainer>
    </PackagePriceContainer>
  );
};

export default ItemPackagePrice;
