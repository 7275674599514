/**
 * FnbTextArea with count component custom from antd text area
 * @param {value, maxLength, rows, defaultValue, allowClear, placeholder, name } props
 * @returns
 */
export function FnbTextAreaWithCount(props) {
  const { maxLength = 255, rows, placeholder, onChange, textAreaCount, value } = props;

  return (
    <div
      class="ant-input-textarea ant-input-textarea-show-count ant-input-textarea-in-form-item ant-input-textarea-status-success fnb-text-area-with-count note undefined"
      data-count={`${textAreaCount} / ${maxLength}`}
    >
      <textarea
        maxLength={maxLength}
        rows={rows}
        placeholder={placeholder}
        class="ant-input ant-input-status-success"
        onChange={(event) => {
          onChange(event);
        }}
        value={value}
      ></textarea>
    </div>
  );
}
