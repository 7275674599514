import { Card } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableQROrder from "./components/table-qr-order.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

export function QROrder(props) {
  const [t] = useTranslation();
  const history = useHistory();

  const pageData = {
    title: t("marketing.qrCode.title"),
    addNew: t("button.addNew"),
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.title}
        permissionCreate={PermissionKeys.CREATE_QR_CODE}
        onCreate={() => history.push("/qr-order/create-new")}
      ></FnbHeadingPage>
      <Card className="fnb-card-custom">
        <TableQROrder />
      </Card>
    </>
  );
}
