const AddressLeftArrowIcon = ({ fillColor = "#BBAC5E", stroke = "#C1B36E" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 4.5L7.5 12L15 19.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default AddressLeftArrowIcon;
