export const CLASSNAME_CHART_STAFF = {
  COLUMN: "apexcharts-bar-area",
  TOOLTIP: "apexcharts-tooltip",
  GRID: "apexcharts-grid",
  BAR_SERIES: "apexcharts-bar-series"
};

export const ID_ELEMENT_CHART_STAFF = {
  CENTER_RANGE: "drag-range-chart__center-id",
  WRAPPER_CHART: "wrapper-chart-staff-revenue-id",
};

export const COLORS_COLUMN_CHART = {
  DEFAULT: "#E9E5FF",
  NOT_SELECTED: "#FFEDDB",
  SELECTED: "#50429B",
};

export const SPACING_CHART = {
  lg: {
    PADDING_CHART: 24,
  },
};
