import { Row } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTypography from "components/fnb-typography/fnb-typography";
import promotionConfigDataService from "data-services/promotion-config/promotion-config-data.service";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PromotionConfig from "../PromotionConfig";
import { FnbGuideline } from "components/fnb-guideline/fnb-guideline.component";
import { useMediaQuery } from "react-responsive";

export const HeaderPromotion = (props) => {
  const { guideline } = props;
  const [t] = useTranslation();
  const [isVisiblePromotionConfigModal, setIsVisiblePromotionConfigModal] = useState(false);
  const [promotionConfig, setPromotionConfig] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    promotionConfigDataService.getPromotionConfigAsync().then((res) => {
      setPromotionConfig(res);
    });
  }, []);
  const pageData = {
    promotionManagement: t("promotion.promotionManagement", "Promotion Management"),
    setting: t("topBar.setting"),
    promotionSetting: t("promotion.promotionSetting.title", "Promotion Setting"),
  };

  const handleShowPromotionConfig = () => setIsVisiblePromotionConfigModal(true);
  const handleHiddenPromotionConfig = () => setIsVisiblePromotionConfigModal(false);

  return (
    <Fragment>
      <Row className="promotion__row-header">
        <div className="promotion__dv-title">
          <FnbTypography text={pageData.promotionManagement} variant={"h1-bold"} />
          <FnbGuideline
            nameTooltip={"promotion"}
            title={guideline.title}
            content={guideline.content}
            placement={isMobile ? "bottomRight" : "rightTop"}
          />
        </div>
        <FnbButton
          textStyle={{ textTransform: "capitalize" }}
          onClick={handleShowPromotionConfig}
          text={pageData.promotionSetting}
          variant="secondary"
        />
      </Row>
      <PromotionConfig
        open={isVisiblePromotionConfigModal}
        config={promotionConfig}
        onCancel={handleHiddenPromotionConfig}
      />
    </Fragment>
  );
};
