import { Input } from "antd";
import classNames from "classnames";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { checkOnKeyPressValidation, formatterDecimalNumber, isDecimalKey, parserDecimalNumber } from "utils/helpers";
import "./fnb-input-group.scss";

/* EXAMPLE 
  <FnbInputGroup
    className="custom-input-group"
    firstInput={{
      type: "input",
      placeholder: "Input something",
      suffix: "VND",
    }}
    secondInput={{
      type: "select-single",
      prefix: "UOM",
      prefixColor: theme.colors.primary[80],
      option: [
        {
          name: "First selection",
          id: 1,
        },
        {
          name: "Second selection",
          id: 2,
        },
      ],
    }}
  />
*/

export const FnbInputGroupType = {
  INPUT: "input",
  NUMBER_INT: "number-int",
  NUMBER_DECIMAL: "number-decimal",
  SELECT_SINGLE: "select-single",
  LABEL: "label",
  BUTTON: "button",
};

const PositionInputType = {
  LEFT: 0,
  RIGHT: 1,
};

const InputItem = ({ type, position, props, prefix, prefixColor, formProps, suffix }) => {
  const classNameInput = classNames({
    "fnb-input-group__input": true,
    "fnb-input-group__input--left": position === PositionInputType.LEFT,
    "fnb-input-group__input--right": position === PositionInputType.RIGHT,
  });

  const classNamesSelectSingle = classNames({
    "fnb-input-group__select-single": true,
    "fnb-input-group__select-single--left": position === PositionInputType.LEFT,
    "fnb-input-group__select-single--right": position === PositionInputType.RIGHT,
  });

  const classNameLabel = classNames({
    "fnb-input-group__label": true,
    "fnb-input-group__label--left": position === PositionInputType.LEFT,
    "fnb-input-group__label--right": position === PositionInputType.RIGHT,
  });

  const classNameButton = classNames({
    "fnb-input-group__button": true,
    "fnb-input-group__button--left": position === PositionInputType.LEFT,
    "fnb-input-group__button--right": position === PositionInputType.RIGHT,
  });

  const renderInput = (type, isHaveFormItem) => {
    const width = isHaveFormItem ? "100%" : props?.width ?? "50%";
    switch (type) {
      case FnbInputGroupType.LABEL:
        return <FnbTooltip widthLabel={props?.width ?? "50%"} className={classNameLabel} maxWidthContent={props?.width ?? "50%"} onlyShowWhenEllipsis title={<FnbTypography {...props} color={theme.colors.default.white} text={props?.value} />}><FnbTypography {...props} text={props?.value} /></FnbTooltip>;
      case FnbInputGroupType.INPUT:
        return <FnbInput {...props} className={classNameInput} style={{ width: props?.width ?? "50%", ...props?.style }} />;
      case FnbInputGroupType.NUMBER_DECIMAL:
        return (
          <FnbInputNumber
            formatter={(value) => formatterDecimalNumber(value)}
            parser={(value) => parserDecimalNumber(value)}
            onKeyPress={(event) => {
              if (!isDecimalKey(event)) {
                event.preventDefault();
              }
            }}
            {...props}
            className={classNameInput}
            style={{ width: width }}
            suffix={suffix}
          />
        );
      case FnbInputGroupType.NUMBER_INT:
        return (
          <FnbInputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onKeyPress={(event) => {
              const checkValidKey = checkOnKeyPressValidation(event, "minQuantity", 0, 999999999, 0);
              if (!checkValidKey) event.preventDefault();
            }}
            {...props}
            className={classNameInput}
            style={{ width: width }}
            suffix={suffix}
          />
        );
      case FnbInputGroupType.SELECT_SINGLE:
        return (
          <FnbSelectSingle
            {...props}
            className={classNamesSelectSingle}
            style={{ width: width }}
            prefix={
              prefix ? (
                <FnbTypography
                  text={prefix}
                  variant={theme.typography["b2-medium"]}
                  color={prefixColor ?? theme.colors.gray[50]}
                />
              ) : undefined
            }
          />
        );
      case FnbInputGroupType.BUTTON:
        return (
          <FnbButton
            {...props}
            className={classNameButton}
            style={{ width: width }}
            prefix={
              prefix ? (
                <FnbTypography
                  text={prefix}
                  variant={theme.typography["b2-medium"]}
                  color={prefixColor ?? theme.colors.gray[50]}
                />
              ) : undefined
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (formProps && formProps?.name) ? (
    <FnbFormItem name={formProps?.name} rules={formProps?.rules} {...formProps}
      style={{ width: props?.width ?? "50%" }}>
      {renderInput(type, true)}
    </FnbFormItem>
  ) : (
    renderInput(type, false)
  );
};

const FnbInputGroup = ({
  width = "100%",
  className = "",
  firstInput = {
    type: FnbInputGroupType.INPUT,
    width: "50%",
    form: {
      name: null,
      rules: [],
    },
  },
  secondInput = {
    type: FnbInputGroupType.SELECT_SINGLE,
    width: "50%",
    prefix: undefined,
    prefixColor: undefined,
    form: {
      name: null,
      rules: [],
    },
  },
  minWidth = 200,
}) => {
  const classNamesInputGroup = classNames({
    "fnb-input-group": true,
    [`${className}`]: true,
  });

  return (
    <Input.Group style={{ width: width, minWidth: minWidth }} compact className={classNamesInputGroup}>
      <InputItem type={firstInput.type} position={PositionInputType.LEFT} props={{ ...firstInput }} formProps={firstInput?.form} suffix={firstInput?.suffix} />
      <InputItem
        type={secondInput.type}
        position={PositionInputType.RIGHT}
        props={{ ...secondInput }}
        prefix={secondInput.prefix}
        prefixColor={secondInput.prefixColor}
        formProps={secondInput?.form}
      />
    </Input.Group>
  );
};

export default FnbInputGroup;
