import { message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { Role } from "constants/default.constants";
import branchDataService from "data-services/branch/branch-data.service";
import permissionDataService from "data-services/permission/permission-data.service";
import staffDataService from "data-services/staff/staff-data.service";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { storeInfoSelector } from "store/modules/session/session.reducers";
import theme from "theme";
import { executeAfter, formatPhoneNumber } from "utils/helpers";
import FilterStaff from "./filter-staff.component";
import "./table-staff.component.scss";

export default function TableStaff(props) {
  const { onEditItem, screenKey } = props;
  const [t] = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [branches, setBranches] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedPermissionId, setSelectedPermissionId] = useState(null);
  const [keySearch, setKeySearch] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const filterPopoverRef = useRef();
  const phoneCode = useSelector(storeInfoSelector)?.phoneCode;
  const history = useHistory();

  const pageData = {
    searchPlaceholder: t("staffManagement.searchPlaceholder"),
    btnFilter: t("button.filter"),
    table: {
      no: t("table.no"),
      name: t("staffManagement.table.name"),
      phone: t("staffManagement.table.phone"),
      branch: t("staffManagement.table.branch"),
      group: t("staffManagement.table.group"),
      action: t("staffManagement.table.action"),
    },
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    staffDeleteSuccess: t("staffManagement.staffDeleteSuccess"),
    staffDeleteFail: t("staffManagement.staffDeleteFail"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };
  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.no,
        dataIndex: "index",
        key: "index",
        width: "5rem",
        render: (value) => value + 1,
      },
      {
        title: pageData.table.name,
        dataIndex: "name",
        key: "name",
        render: (_, { name }) => {
          return (
            <FnbTooltip title={name} onlyShowWhenEllipsis>
              <FnbTypography text={name} />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.phone,
        dataIndex: "phone",
        key: "phone",
        width: "15%",
        ellipsis: true,
        render: (_, { phone }) => {
          return (
            <>
              {phone && (
                <FnbTooltip title={formatPhoneNumber(phoneCode, phone)} onlyShowWhenEllipsis>
                  <FnbTypography text={formatPhoneNumber(phoneCode, phone)} />
                </FnbTooltip>
              )}
            </>
          );
        },
      },
      {
        title: pageData.table.branch,
        dataIndex: "branches",
        key: "branches",
        width: "30%",
        ellipsis: true,
        render: (value) => {
          const data = value?.map((branch) => ({ title: branch?.name, detail: branch?.name }));
          return <MemoizedFnbListChips data={data} />;
        },
      },
      {
        title: pageData.table.group,
        dataIndex: "groups",
        key: "groups",
        width: "25%",
        ellipsis: true,
        render: (value) => {
          const data = value?.map((group) => ({
            title: <FnbTypography.Link to={group?.link} text={group?.name} />,
            detail: <FnbTypography.Link to={group?.link} text={group?.name} className="tooltip-staff-group" />,
          }));
          return <MemoizedFnbListChips data={data} />;
        },
      },
    ],

    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize, "");
    },
    onSearch: async (serchKey) => {
      setKeySearch(serchKey);
      executeAfter(500, async () => {
        await fetchDatableAsync(1, tableSettings.pageSize, serchKey);
      });
    },
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
    const getBranch = async () => {
      var resBranch = await branchDataService.getAllBranchsAsync();
      if (resBranch) {
        const allBranchOption = {
          id: "",
          name: t("productManagement.filter.branch.all"),
        };
        const branchOptions = [allBranchOption, ...resBranch.branchs];
        setBranches(branchOptions);
      }
    };
    const getPermission = async () => {
      var resPermission = await permissionDataService.getGroupPermissionManagementAsync();
      if (resPermission) {
        const allPermissionOption = {
          id: "",
          name: t("staffManagement.permission.filter.all"),
        };
        const PermissionOptions = [allPermissionOption, ...resPermission.groupPermissions];
        setPermissions(PermissionOptions);
      }
    };

    getPermission();
    getBranch();
  }, []);

  const onDeleteItem = (_selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const handleDeleteItem = async (id) => {
    let res = await staffDataService.deleteStaffByIdAsync(id);
    if (res) {
      message.success(pageData.staffDeleteSuccess);

      // Recount selected items after delete
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setShowConfirmDeleteModal(false);
    } else {
      message.error(pageData.staffDeleteFail);
    }
    await fetchDatableAsync(1, tableSettings.pageSize, keySearch);
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch, branchId = null, permissionId = null) => {
    if (!pageNumber || !pageSize) {
      return;
    }

    let dataRequest = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      keySearch: keySearch,
      screenKey: screenKey,
    };

    if (branchId) {
      dataRequest = {
        ...dataRequest,
        branchId: branchId,
      };
    }

    if (permissionId) {
      dataRequest = {
        ...dataRequest,
        groupPermissionId: permissionId,
      };
    }

    const response = await staffDataService.getDataStaffManagementAsync(dataRequest);
    const data = response?.staffs.map((s) => mappingRecordToColumns(s));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
  };

  const mappingRecordToColumns = (staff) => {
    return {
      key: staff?.id,
      index: staff?.no,
      id: staff?.id,
      name: staff?.fullName,
      phone: staff?.phoneNumber,
      branches: staff?.storeBranches,
      branchesName: staff?.storeBranches?.map((b) => b.name),
      groups: mappingGroups(staff?.groupPermissions),
      groupsName: staff?.groupPermissions?.map((g) => g.name || ""),
      isInitialStoreAccount: staff?.isInitialStoreAccount,
      disabled: staff?.role === Role.SUPER_ADMINISTRATOR, // the row of staff is super admin will be disabled
    };
  };

  const handleFilterProduct = async (data) => {
    setSelectedBranchId(data?.branchId);
    setSelectedPermissionId(data?.permissionId);
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "", data?.branchId, data?.permissionId);
    setCountFilter(data?.count);
  };

  const onSelectItemFilter = (key) => {
    if (countFilter > 0 && key === "") {
      setCountFilter(countFilter - 1);
    } else {
      setCountFilter(countFilter + 1);
    }
  };

  const onResetFilter = async () => {
    setCountFilter(0);
    setSelectedBranchId("");
    setSelectedPermissionId("");
    await fetchDatableAsync(1, tableSettings.pageSize, keySearch);
  };
  const mappingGroups = (groups) => {
    let listGroup = [];
    groups?.map((item) => {
      let group = {
        id: item?.id,
        name: item?.name,
        link: `/settings/permission-group/${item?.id}/detail`,
      };
      listGroup.push(group);
    });

    return listGroup;
  };

  const filterComponent = () => {
    return (
      <FilterStaff
        ref={filterPopoverRef}
        fetchDataProducts={handleFilterProduct}
        groupPermissions={permissions}
        branches={branches}
        onSelectItemFilter={onSelectItemFilter}
        onResetFilter={onResetFilter}
        selectedBranchId={selectedBranchId}
        selectedPermissionId={selectedPermissionId}
      />
    );
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    // only select the row enabled
    const rows = _selectedRows.filter((i) => !i.disabled).map(i => i.index);
    setSelectedRowKeys(rows);
  };

  return (
    <Fragment>
      <FnbTable
        columns={tableSettings.columns}
        pageSize={tableSettings.pageSize}
        dataSource={dataSource}
        currentPageNumber={currentPageNumber}
        total={totalRecords}
        onChangePage={tableSettings.onChangePage}
        search={{
          placeholder: pageData.searchPlaceholder,
          onChange: tableSettings.onSearch,
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectedRowKeysChange,
          onEdit: onEditItem,
          onDelete: onDeleteItem,
        }}
        filter={{
          totalFilterSelected: countFilter,
          onClearFilter: onClearFilter,
          buttonTitle: pageData.btnFilter,
          component: filterComponent(),
        }}
        emptyText={
          <div className="content-collapse-empty-text">
            <FnbTypography.Link
              variant={theme.typography["b1-bold-underlined"]}
              underline={true}
              text={pageData.addNew}
              onClick={() => history.push("/staff/create-new")}
            />
            {pageData.toCreateData}
          </div>
        }
      />
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={() => handleDeleteItem(selectedRows[0]?.id)}
      />
    </Fragment>
  );
}
