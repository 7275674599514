import http from "../../utils/http-common";

const controller = "storeAdvancedSettings";

const getAdvanceSettingsAsync = () => {
  return http.get(`/${controller}`);
};

const createAdvanceSettingsAsync = (data) => {
  return http.post(`/${controller}`, data);
};

const storeAdvancedSettingsDataService = {
  getAdvanceSettingsAsync,
  createAdvanceSettingsAsync,
};
export default storeAdvancedSettingsDataService;
