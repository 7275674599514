import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import AddressCheckIcon from "../../assets/icons/address-check-icon";
import AddressHomeIcon from "../../assets/icons/address-home-icon";
import AddressLeftArrowIcon from "../../assets/icons/address-left-arrow-icon";
import AddressHomeMappingIcon from "../../assets/icons/address-list";
import AddressRightArrowIcon from "../../assets/icons/address-right-arrow-icon";
import AddressStoreIcon from "../../assets/icons/address-store-icon";
import AddressWorkIcon from "../../assets/icons/address-work-icon";
import { EnumCustomerAddressType } from "../../constants/enums";
import { StoreBranchAddressSelector } from "../store-branch-address-selector/store-branch-address-selector.component";
import "./customer-address.style.scss";

export const CustomerAddresses = forwardRef((props, ref) => {
  const {
    addressList,
    setCustomerAddress,
    groupColorConfig,
    storeBranchSelected,
    customerAddress,
    hasCustomerAddresses,
    config,
    storeBranchAddressSelectorRef,
    openStoreBranchAddressSelector,
    onSelectPickUp,
    setOpenStoreBranchAddressSelector,
    getDefaultStoreBranch,
    colorGroup,
  } = props;
  const [t] = useTranslation();
  const translatedData = {
    default: t("addUserLocation.default", "Mặc định"),
    deliveredFrom: t("addUserLocation.deliveredFrom"),
    addressSaved: t("addUserLocation.addressSaved"),
    selectOtherBranch: t("addUserLocation.selectOtherBranch"),
  };
  const [isSelectBranch, setIsSelectBranch] = useState(false);
  useEffect(() => {}, []);
  useImperativeHandle(ref, () => ({}));

  const handleClickChangeBranch = () => {
    setIsSelectBranch(!isSelectBranch);
  };

  const renderIcon = (addressType) => {
    switch (addressType) {
      case EnumCustomerAddressType.Home:
        return (
          <div className="icon-prefix">
            <AddressHomeIcon fillColor={groupColorConfig?.buttonBackgroundColor} />
          </div>
        );
      case EnumCustomerAddressType.Work:
        return (
          <div className="icon-prefix">
            <AddressWorkIcon fillColor={groupColorConfig?.buttonBackgroundColor} />
          </div>
        );
      default:
        return (
          <div className="icon-prefix">
            <AddressHomeMappingIcon fillColor={groupColorConfig?.buttonBackgroundColor} />
          </div>
        );
    }
  };

  return (
    <div className="customer-delivery-address-wrapper">
      <div className={`select-store-branch ${isSelectBranch ? "show" : ""}`}>
        <div
          className="header-row"
          onClick={() => {
            handleClickChangeBranch();
          }}
        >
          <AddressLeftArrowIcon stroke={groupColorConfig?.buttonBackgroundColor} />
          <span className="header">{translatedData.selectOtherBranch}</span>
        </div>
        <StoreBranchAddressSelector
          colorGroup={colorGroup}
          config={config}
          ref={storeBranchAddressSelectorRef}
          open={openStoreBranchAddressSelector}
          onSelected={(branch, isSelectNewBranch) => {
            onSelectPickUp(branch, isSelectNewBranch, true);
            if (isSelectBranch) {
              setIsSelectBranch(!isSelectBranch);
            }
          }}
          onClose={() => setOpenStoreBranchAddressSelector(false)}
          initStoreBranchData={storeBranchSelected}
          getDefaultStoreBranch={() => getDefaultStoreBranch(true)}
          customerAddress={customerAddress}
        />
      </div>
      <div className={`address-saved ${isSelectBranch ? "" : "show"}`}>
        {hasCustomerAddresses && addressList?.length > 0 && (
          <>
            <span className="header">{translatedData.addressSaved}</span>
            <div className="my-address-container">
              <div className="address-list-container">
                {addressList?.map((item, index) => {
                  return (
                    <div className="address-detail-box" key={index} onClick={() => setCustomerAddress(item)}>
                      <div className="address-content">
                        {renderIcon(item?.customerAddressTypeId)}
                        <div className="address-detail-text">
                          <span>{item.address}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <>
          <div
            className="header-row"
            onClick={() => {
              handleClickChangeBranch();
            }}
          >
            <span className="header">{translatedData.deliveredFrom}</span>
            <AddressRightArrowIcon stroke={groupColorConfig?.buttonBackgroundColor} />
          </div>
          <div className="address-store-picked">
            <AddressStoreIcon fillColor={groupColorConfig?.buttonBackgroundColor} />
            <div className="store-infomation">
              <div className="store-title">
                <span>{storeBranchSelected?.title} </span>
                <AddressCheckIcon fillColor={groupColorConfig?.buttonBackgroundColor} />
              </div>
              <div className="store-content">
                <span className="location">{storeBranchSelected?.addressDetail}</span>
                <span className="distance">({storeBranchSelected?.distance})</span>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
});
