import { Col, Form, Image, message, Row, Space } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { images } from "constants/images.constants";
import { OtherFoodyPlatformString } from "constants/other-foody-platform.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { EnDash } from "constants/string.constants";
import customerSegmentDataService from "data-services/customer-segment/customer-segment-data.service";
import customerDataService from "data-services/customer/customer-data.service";
import membershipDataService from "data-services/membership/membership-data.service";
import storeDataService from "data-services/store/store-data.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { executeAfter, formatNumber, formatPhoneNumber, hasPermission } from "utils/helpers";
import { platformNames } from "../../../../../constants/platform.constants";
import FilterCustomer from "./filter-customer.component";
import "./index.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { MinusSingleOutlinedIcon } from "constants/icons.constants";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

export default function TableCustomer(props) {
  const [t] = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showPopover, setShowPopover] = useState(false);
  const [countFilter, setCountFilter] = useState(0);
  const [dataFilter, setDataFilter] = useState(null);
  const [keySearch, setKeySearch] = useState("");
  const filterCustomerRef = React.useRef(null);
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [listPlatforms, setListPlatforms] = useState([]);
  const [listCustomerMembershipLevels, setListCustomerMembershipLevels] = useState([]);
  const [listCustomerSegments, setListCustomerSegments] = useState([]);
  const [listTags, setListTags] = useState([]);
  const DEFAULT_PAGESIZE = 20;
  const DEFAULT_PAGE_NUMBER = 1;
  const DEFAULT_KEY_SEARCH = "";
  const DEFAULT_ID_ALL = "";

  const pageData = {
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    searchPlaceholder: t("customer.searchByCustomerPhone", "Search by customer, phone"),
    no: t("customer.no"),
    customer: t("table.customer"),
    phone: t("customer.phone"),
    rank: t("customer.rank"),
    action: t("customer.action"),
    point: t("customer.point"),
    points: t("customer.points"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteCustomerMessage: t("customer.confirmDeleteCustomerMessage"),
    customerDeleteSuccess: t("customer.customerDeleteSuccess"),
    customerDeleteFail: t("customer.customerDeleteFail"),
    platform: t("platform.title"),
    filter: {
      title: t("customerManagement.filter.title"),
      all: t("customerManagement.filter.all"),
    },
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };
  const [all, setAll] = useState(pageData.filter.all);

  const tableSettings = {
    pageSize: DEFAULT_PAGESIZE,
    columns: [
      {
        title: pageData.no,
        key: "index",
        width: "5rem",
        align: "left",
        dataIndex: "index",
        render: (val) => val + 1,
      },
      {
        title: pageData.customer,
        dataIndex: "name",
        key: "name",
        width: "25%",
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => {
          return (
            <FnbTooltip title={record.name} onlyShowWhenEllipsis>
              <FnbTypography text={record.name} />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.phone,
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "20%",
        render: (_, record) => {
          return formatPhoneNumber(record?.phoneCode, record?.phoneNumber);
        },
      },
      {
        title: pageData.platform,
        dataIndex: "platformId",
        key: "platformId",
        width: "20%",
        render: (_, record) => {
          return <div className="platform-detail">{renderCustomerPlatform(record)}</div>;
        },
      },
      {
        title: pageData.rank,
        dataIndex: "rank",
        key: "rank",
        width: "30%",
        render: (_, record) => {
          if (!record?.rank && record?.point === EnDash) {
            return (
              <>
                <Row>
                  <Col span={24}>{EnDash}</Col>
                </Row>
              </>
            );
          } else {
            return (
              <>
                <Space wrap size={8}>
                  <FnbChip title={record?.rank} maxWidth={window.innerWidth > 575 ? "350px" : "200px"} />
                  {record?.point !== EnDash && (
                    <>
                      <div style={{ display: "flex" }}>
                        <MinusSingleOutlinedIcon />
                      </div>
                      <Space wrap size={3}>
                        <FnbTypography text={record?.point} />
                        <FnbTypography
                          text={
                            record?.point?.props?.value > 1
                              ? pageData.points.toLowerCase()
                              : pageData.point.toLowerCase()
                          }
                        />
                      </Space>
                    </>
                  )}
                </Space>
              </>
            );
          }
        },
      },
    ],

    onSearch: async (value) => {
      executeAfter(500, async () => {
        setKeySearch(value);
        await fetchDatableAsync(1, tableSettings.pageSize, value, dataFilter);
      });
    },

    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize, keySearch, dataFilter);
    },
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteCustomerMessage, { name: name });
    return mess;
  };

  const handleDeleteItem = async () => {
    const id = selectedRows[0].id;
    await customerDataService.deleteCustomerByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.customerDeleteSuccess);
      } else {
        message.error(pageData.customerDeleteFail);
      }
    });

    await fetchDatableAsync(DEFAULT_PAGE_NUMBER, DEFAULT_PAGESIZE, keySearch, dataFilter).then(() => {
      setShowConfirmDeleteModal(false);
      setSelectedRows([]);
      setSelectedRowKeys([]);
    });
  };

  useEffect(() => {}, []);
  useEffect(() => {
    fetchDatableAsync(currentPageNumber, DEFAULT_PAGESIZE, DEFAULT_KEY_SEARCH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch, dataFilter = null) => {
    const response = await customerDataService.getCustomersAsync(
      keySearch,
      pageNumber,
      pageSize,
      dataFilter?.platformId ?? "",
      dataFilter?.customerMembershipId ?? "",
      dataFilter?.customerSegmentId ?? "",
      dataFilter?.tagIds ?? "",
    );
    const data = response?.customers?.map((s) => mappingRecordToColumns(s));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(pageNumber);
    setCountFilter(dataFilter?.count);
  };

  const mappingRecordToColumns = (item) => {
    return {
      index: item?.no,
      id: item?.id,
      name: [item?.firstName, item?.lastName].join(" ").trim(),
      phoneNumber: item?.phoneNumber,
      phoneCode: item?.phoneCode,
      point: item?.availablePoint  === 0 ? EnDash : formatNumber(item?.availablePoint ),
      rank: item?.rank,
      color: item?.color ?? "#efbb00",
      platformId: item?.platformId,
      otherCustomerPlatform: {
        id: item?.otherCustomerPlatform?.id,
        name: item?.otherCustomerPlatform?.name,
        logo: item?.otherCustomerPlatform?.logo,
        foodyPlatformId: item?.otherCustomerPlatform?.foodyPlatformId,
      },
    };
  };

  const getOtherCustomerPlatformIcon = (foodyPlatformId, foodyPlatformLogo) => {
    switch (foodyPlatformId) {
      case OtherFoodyPlatformString.GrabFood:
        return <Image preview={false} src={images.grabFoodLogo} style={{ width: 24, height: 24 }} />;
      case OtherFoodyPlatformString.GoFood:
        return <Image preview={false} src={images.goFoodLogo} style={{ width: 24, height: 24 }} />;
      case OtherFoodyPlatformString.BeFood:
        return <Image preview={false} src={images.beFoodLogo} style={{ width: 24, height: 24 }} />;
      case OtherFoodyPlatformString.Baemin:
        return <Image preview={false} src={images.baeminLogo} style={{ width: 24, height: 24 }} />;
      case OtherFoodyPlatformString.ShopeeFood:
        return <Image preview={false} src={images.shopeeFoodLogo} style={{ width: 24, height: 24 }} />;
      default:
        if (foodyPlatformLogo) {
          return (
            <Image
              preview={false}
              src={foodyPlatformLogo ?? images.defaultFoodyPlatformLogo}
              style={{ width: 24, height: 24 }}
            />
          );
        }
        return <Image preview={false} src={images.defaultFoodyPlatformLogo} style={{ width: 24, height: 24 }} />;
    }
  };

  const renderCustomerPlatform = (data) => {
    if (data?.platformId) {
      var platform = platformNames.find((obj) => obj.id?.toLowerCase() === data.platformId?.toLowerCase());
      return (
        <>
          {platform?.icon}
          <span>{platform?.name}</span>
        </>
      );
    } else {
      if (data?.otherCustomerPlatform) {
        return (
          <>
            {getOtherCustomerPlatformIcon(
              data?.otherCustomerPlatform?.foodyPlatformId?.toLowerCase(),
              data?.otherCustomerPlatform?.logo,
            )}
            <span>{data?.otherCustomerPlatform?.name}</span>
          </>
        );
      }
      return null;
    }
  };

  /* Filter Region */
  const [requestingCustomerMembership, setRequestingCustomerMembership] = useState(false);
  const [paginationCustomerMembership, setPaginationCustomerMembership] = useState({
    pageSize: DEFAULT_PAGESIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [isLoadDoneMembership, setIsLoadDoneMembership] = useState(false);

  const [requestingCustomerSegment, setRequestingCustomerSegment] = useState(false);
  const [paginationCustomerSegment, setPaginationCustomerSegment] = useState({
    pageSize: DEFAULT_PAGESIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    keySearch: DEFAULT_KEY_SEARCH,
  });
  const [isLoadDoneCustomerSegment, setIsLoadDoneCustomerSegment] = useState(false);

  const getListCustomerSegment = async (pagination, isLoadMore = false) => {
    setRequestingCustomerSegment(true);
    setPaginationCustomerSegment(pagination);
    const resSegments = await customerSegmentDataService.getCustomerSegmentsAsync(
      pagination.pageNumber,
      pagination.pageSize,
      pagination.keySearch,
    );
    if (resSegments) {
      if (isLoadMore) {
        setListCustomerSegments([...listCustomerSegments, ...resSegments.customerSegments]);
      } else {
        setListCustomerSegments(resSegments.customerSegments);
      }
      if (resSegments.customerSegments.length < DEFAULT_PAGESIZE) {
        setIsLoadDoneCustomerSegment(true);
      } else {
        setIsLoadDoneCustomerSegment(false);
      }
    }
    setRequestingCustomerSegment(false);
  };

  const getDataForFilter = async () => {
    /* Platform */
    const resPlatforms = await storeDataService.getAllPlatformActivatedAsync();
    if (resPlatforms) {
      setListPlatforms([
        {
          id: DEFAULT_ID_ALL,
          name: pageData.filter.all,
        },
        ...resPlatforms.platforms,
      ]);
    }

    /* CustomerMembership */
    setPaginationCustomerMembership({
      ...paginationCustomerMembership,
      pageNumber: DEFAULT_PAGE_NUMBER,
    });
    const resMemberShipLevels = await membershipDataService.getMembershipsAsync(DEFAULT_PAGE_NUMBER, DEFAULT_PAGESIZE);
    if (resMemberShipLevels) {
      setListCustomerMembershipLevels([
        {
          id: DEFAULT_ID_ALL,
          name: pageData.filter.all,
        },
        ...resMemberShipLevels.customerMemberships,
      ]);

      if (resMemberShipLevels.customerMemberships.length < DEFAULT_PAGESIZE) {
        setIsLoadDoneMembership(true);
      }
    }

    /* CustomerMembership */
    await getListCustomerSegment({
      pageSize: DEFAULT_PAGESIZE,
      pageNumber: DEFAULT_PAGE_NUMBER,
      keySearch: DEFAULT_KEY_SEARCH,
    });

    const resTags = await customerDataService.getCustomerTagAsync();
    if (resTags) {
      setListTags(resTags.tags);
    }
  };

  useEffect(() => {
    getDataForFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Reload language when component render
    if (pageData.filter.all !== all) {
      setAll(pageData.filter.all);
      if (listCustomerMembershipLevels.length > 0) {
        setListCustomerMembershipLevels((prev) =>
          prev.map((item) => {
            if (item.id === DEFAULT_ID_ALL) return { ...item, name: pageData.filter.all };
            return item;
          }),
        );
      }
      if (listPlatforms.length > 0) {
        setListPlatforms((prev) =>
          prev.map((item) => {
            if (item.id === DEFAULT_ID_ALL) return { ...item, name: pageData.filter.all };
            return item;
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const onClickFilterButton = async (event) => {
    if (!event?.defaultPrevented) {
      setShowPopover(true);
    }
  };

  const onClearFilter = (_e) => {
    filterCustomerRef.current.resetFilter();
    setCountFilter(0);
    setDataFilter(null);
    fetchDatableAsync(DEFAULT_PAGE_NUMBER, DEFAULT_PAGESIZE, keySearch, null);
  };

  const handleScrollDownCustomerMembership = async (event) => {
    var target = event.target;
    if (
      !requestingCustomerMembership &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isLoadDoneMembership
    ) {
      setRequestingCustomerMembership(true);
      const newPagination = {
        ...paginationCustomerMembership,
        pageNumber: paginationCustomerMembership.pageNumber + 1,
      };
      setPaginationCustomerMembership(newPagination);

      const resMemberShipLevels = await membershipDataService.getMembershipsAsync(
        newPagination.pageNumber,
        newPagination.pageSize,
      );
      if (resMemberShipLevels) {
        setListCustomerMembershipLevels([...listCustomerMembershipLevels, ...resMemberShipLevels.customerMemberships]);
        if (resMemberShipLevels.customerMemberships.length < DEFAULT_PAGESIZE) {
          setIsLoadDoneMembership(true);
        }
      }

      setRequestingCustomerMembership(false);
    }
  };

  const handleScrollDownCustomerSegment = async (event) => {
    var target = event.target;
    if (
      !requestingCustomerSegment &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isLoadDoneCustomerSegment
    ) {
      await getListCustomerSegment(
        {
          ...paginationCustomerSegment,
          pageNumber: paginationCustomerSegment.pageNumber + 1,
        },
        true,
      );
    }
  };

  const typingSegmentTimeoutRef = React.useRef(null);

  const onSearchCustomerSegment = async (val) => {
    if (typingSegmentTimeoutRef.current) {
      clearTimeout(typingSegmentTimeoutRef.current);
    }
    typingSegmentTimeoutRef.current = setTimeout(async () => {
      await getListCustomerSegment({
        pageSize: DEFAULT_PAGESIZE,
        pageNumber: DEFAULT_PAGE_NUMBER,
        keySearch: val,
      });
    }, 600);
  };

  const onClearCustomerSegment = async () => {
    await getListCustomerSegment({
      pageSize: DEFAULT_PAGESIZE,
      pageNumber: DEFAULT_PAGE_NUMBER,
      keySearch: DEFAULT_KEY_SEARCH,
    });
  };

  const filterComponent = () => {
    return (
      showPopover && (
        <FilterCustomer
          ref={filterCustomerRef}
          dataFilter={dataFilter}
          setDataFilter={setDataFilter}
          handleFilterCustomer={fetchDatableAsync}
          keySearch={keySearch}
          pageSize={tableSettings.pageSize}
          listPlatforms={listPlatforms}
          listCustomerMembershipLevels={listCustomerMembershipLevels}
          setListCustomerMembershipLevels={setListCustomerMembershipLevels}
          listCustomerSegments={listCustomerSegments}
          listTags={listTags}
          handleScrollDownCustomerMembership={handleScrollDownCustomerMembership}
          handleScrollDownCustomerSegment={handleScrollDownCustomerSegment}
          onSearchCustomerSegment={onSearchCustomerSegment}
          onClearCustomerSegment={onClearCustomerSegment}
        />
      )
    );
  };
  /* End Filter Region */
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const onSelectedRowKeysChange = (keySelected) => {
    setSelectedRowKeys(keySelected);
  };

  const onEditRowKeys = (_, selectedRows) => {
    setSelectedRowKeys([]);
    history.push(`/customer/edit/${selectedRows[0]?.id}`);
  };

  const onDeleteRowKeys = (_, selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const onRowClick = (record, _index) => {
    history.push(`/customer/detail/${record?.id}`);
  };

  return (
    <>
      <Form className="form-staff form-filter-customer-manager">
        <Row>
          <FnbTable
            columns={tableSettings.columns}
            pageSize={tableSettings.pageSize}
            dataSource={dataSource}
            currentPageNumber={currentPageNumber}
            total={totalRecords}
            onChangePage={tableSettings.onChangePage}
            search={{
              placeholder: pageData.searchPlaceholder,
              onChange: tableSettings.onSearch,
              isSearchWithEnterKey: true,
            }}
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedRowKeysChange,
              onEdit: onEditRowKeys,
              onDelete: onDeleteRowKeys,
            }}
            editPermission={PermissionKeys.EDIT_CUSTOMER}
            deletePermission={PermissionKeys.DELETE_CUSTOMER}
            filter={{
              onClickFilterButton: onClickFilterButton,
              totalFilterSelected: countFilter,
              onClearFilter: onClearFilter,
              buttonTitle: pageData.filter.title,
              component: filterComponent(),
              filterClassName: "popover-filter-customer-manager",
              showPopover: isMobile && showPopover,
            }}
            scrollX="1200px"
            onRowClick={onRowClick}
            emptyText={
              hasPermission(PermissionKeys.CREATE_CUSTOMER) && (
                <div className="content-collapse-empty-text">
                  <FnbTypography.Link
                    variant={theme.typography["b1-bold-underlined"]}
                    underline={true}
                    text={pageData.addNew}
                    onClick={() => history.push("/customer/create-new")}
                  />
                  {pageData.toCreateData}
                </div>
              )
            }
          />
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={handleDeleteItem}
      />
    </>
  );
}
