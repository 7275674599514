import { Card } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableMenuManagement from "./components/table-menu-management.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

export function MenuManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();

  const pageData = {
    title: t("onlineStore.menuManagement"),
    addNew: t("button.addNew"),
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.title}
        permissionCreate={PermissionKeys.CREATE_MENU_MANAGEMENT}
        onCreate={() => history.push("/menu-management/create-new")}
      />
      <div className="clearfix"></div>
      <Card className="fnb-card-custom">
        <TableMenuManagement />
      </Card>
    </>
  );
}
