import React from "react";
import TableUnavailableIngredientComponent from "./components/table-unavailable-ingredient.component";
import "./material-control.page.scss";

export default function MaterialControlManagement(props) {
  const tableUnavailableIngredientRef = React.useRef();

  return (
      <TableUnavailableIngredientComponent ref={tableUnavailableIngredientRef} />
  );
}
