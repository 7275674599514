import { Col, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmStyle, ConfirmViewActiveItemStyle } from "constants/level-menu.constants";
import { FnbButton } from "components/fnb-button/fnb-button";
import "./confirm-dialog.component.scss";
import { Link } from "react-router-dom";

export default function ConfirmDialogComponent({
  className,
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
  okType,
  closable = true,
  visible,
  okButtonProps,
  cancelButtonProps,
  centered,
  type = ConfirmStyle.LEAVER,
  width = 410,
  contentElement,
  Component,
  variantOkButton = "primary",
  ItemNameInContent = "",
  activeItems, // [{label: '', link: ''}]
  dangerOkButton = false,
  typeViewActiveItems = ConfirmViewActiveItemStyle.GRID,
  minWidthBtnCancel = "144px",
  minWidthBtnOk = "166px",
  disabledBtnOk = false,
  idBtnOk,
  onAfterClose
}) {
  const [t] = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const pageData = {
    productCategoryRelatedMessage: t("productCategory.productCategoryRelatedMessage"),
    no: t("table.no"),
    comboName: t("combo.generalInformation.comboName"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirm: t("leaveDialog.confirmLeave"),
    },
    deleteDialog: {
      btnConfirm: t("button.delete"),
      discardBtn: t("button.ignore"),
      confirmation: t("leaveDialog.confirmDelete"),
      content: t("messages.confirmDeleteMessage"),
    },
    notificationDialog: {
      confirmation: t("form.notificationTitle"),
      btnConfirm: t("form.buttonIGotIt"),
    },
    stopDialog: {
      content: t("marketing.notificationCampaign.confirmStopNotificationCampaign"),
      confirmation: t("leaveDialog.confirmStop"),
      btnConfirm: t("button.stop"),
    },
  };

  if (type === ConfirmStyle.LEAVER) {
    title = title ?? pageData.leaveDialog.confirmation;
    content = content ?? pageData.leaveDialog.content;
    cancelText = cancelText ?? pageData.leaveDialog.discardBtn;
    okText = okText ?? pageData.leaveDialog.btnConfirm;
  } else if (type === ConfirmStyle.DELETE) {
    title = title ?? pageData.deleteDialog.confirmation;
    content = content ?? t(pageData.deleteDialog.content, { name: ItemNameInContent });
    cancelText = cancelText ?? pageData.deleteDialog.discardBtn;
    okText = okText ?? pageData.deleteDialog.btnConfirm;
    closable = false;
    dangerOkButton = true;
  } else if (type === ConfirmStyle.NOTIFICATION) {
    title = title ?? pageData.notificationDialog.confirmation;
    okText = okText ?? pageData.notificationDialog.btnConfirm;
    closable = false;
  } else if (type === ConfirmStyle.STOP) {
    title = title ?? pageData.stopDialog.confirmation;
    content = content ?? t(pageData.stopDialog.content, { name: ItemNameInContent });
    okText = okText ?? pageData.stopDialog.btnConfirm;
    cancelText = cancelText ?? pageData.deleteDialog.discardBtn;
    closable = false;
  }

  const handleOk = () => {
    setIsModalVisible(false);
    if (onOk) {
      onOk();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (onCancel) {
      onCancel();
    }
  };

  const renderListItemActive = () => {
    return activeItems ? (
      <Col span={24}>
        <div className="list-notification">{renderRowByIndex(activeItems)}</div>
      </Col>
    ) : (
      <></>
    );
  };

  const renderRowByIndex = (activeItems) => {
    let elements = [];
    for (
      var item = 0;
      item < activeItems?.length;
      typeViewActiveItems === ConfirmViewActiveItemStyle.GRID ? (item += 2) : item++
    ) {
      let element = <></>;
      if (typeViewActiveItems === ConfirmViewActiveItemStyle.LIST) {
        element = (
          <div className="name-row">
            {activeItems[item]?.link ? (
              <Link to={`${activeItems[item]?.link}`} target="_blank" rel="noopener noreferrer">
                {item + 1}. {activeItems[item]?.label}
              </Link>
            ) : (
              <span>
                {item + 1}. {activeItems[item]?.label}
              </span>
            )}
          </div>
        );
      } else if (typeViewActiveItems === ConfirmViewActiveItemStyle.GRID) {
        element = (
          <div className="code-row">
            <div className="code-row-item">
              <span>{item + 1}.</span>
              {activeItems[item]?.link ? (
                <Link to={`${activeItems[item]?.link}`} target="_blank">
                  {activeItems[item]?.label}
                </Link>
              ) : (
                <span>{activeItems[item]?.label}</span>
              )}
            </div>
            {activeItems[item + 1]?.label && (
              <div className="code-row-item">
                <span>{item + 2}.</span>
                {activeItems[item]?.link ? (
                  <Link to={`${activeItems[item + 1]?.link}`} target="_blank">
                    {activeItems[item + 1]?.label}
                  </Link>
                ) : (
                  <span>{activeItems[item + 1]?.label}</span>
                )}
              </div>
            )}
          </div>
        );
      }
      elements.push(element);
    }
    return elements;
  };

  return (
    <Modal
      className={`confirm-dialog-modal ${className}`}
      title={title}
      open={isModalVisible || visible}
      okText={okText}
      okType={okType ? okType : "danger"}
      closable={closable}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={okButtonProps}
      centered={centered}
      cancelButtonProps={cancelButtonProps}
      footer={[
        cancelText && (
          <FnbButton minWidth={minWidthBtnCancel} text={cancelText} variant="tertiary" onClick={handleCancel} />
        ),
        <FnbButton
          textStyle={{ "text-wrap": "nowrap" }}
          minWidth={minWidthBtnOk}
          text={okText}
          variant={variantOkButton}
          danger={dangerOkButton}
          onClick={handleOk}
          disabled={disabledBtnOk}
          id={idBtnOk}
        />,
      ]}
      width={width}
      afterClose={onAfterClose}
    >
      {contentElement ? contentElement : <span dangerouslySetInnerHTML={{ __html: `${content}` }}></span>}
      {renderListItemActive()}
      {Component && <Component />}
    </Modal>
  );
}
