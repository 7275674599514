import { Button, Col, Form, Row, Typography, message } from "antd";
import { env } from "env";
import { InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import otherFoodyPlatformConfigDataService from "data-services/other-foody-platform-config/other-foody-platform-config-data.service";
import { TrashIcon, UploadLogoIcon } from "constants/icons.constants";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbSwitch from "components/switch";
import FnbAutoComplete from "components/auto-complete";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import i18next from "i18next";
import FnbInputNumber from "components/fnb-input/fnb-input-number";

export function OtherFoodyPlatformConfigComponent(props) {
  const otherFoodyPlatformDefaultLogoPath = `assets/images/default-foody-platform-logo.png`;
  const otherFoodyPlatformDefaultLogo = `${env.REACT_APP_ROOT_DOMAIN}/${otherFoodyPlatformDefaultLogoPath}`;
  const { onCompleted, initData, options, handleCancelAddFoodyPlatform, onChangeForm } = props;
  const [activeButton, setActiveButton] = useState(true);
  const [activeDeleteButton, setActiveDeleteButton] = useState(true);
  const [foodyPlatformConfig, setFoodyPlatformConfig] = useState();
  const [image, setImage] = useState(null);
  const [isAllowInputDiscountFromPromotion, setIsAllowInputDiscountFromPromotion] = useState(false);
  const [isAllowInputDiscountFromPartner, setIsAllowInputDiscountFromPartner] = useState(false);
  const [isDefaultImageUsed, setIsDefaultImageUsed] = useState(false);
  const [displayDeleteFoodyPlatformConfirmation, setDisplayDeleteFoodyPlatformConfirmation] = useState(false);
  const [deleteFoodyPlatformContent, setDeleteFoodyPlatformContent] = useState("");
  const [enableEditing, setEnableEditing] = useState(false);
  const [isFieldsModified, setIsFieldsModified] = useState(false);
  const [isRevalidateInput, setIsRevalidateInput] = useState(false);
  const { t } = useTranslation();
  const fnbImageSelectRef = React.useRef();
  const [form] = Form.useForm();
  const [partnerCommissionLength, setPartnerCommissionLength] = useState(0);
  const [isAddForm, setIsAddForm] = useState(false);
  const pageData = {
    platformName: t("otherFoodyPlatform.platformName"),
    platformNamePlaceholder: t("otherFoodyPlatform.platformNamePlaceholder"),
    partnerCommission: t("otherFoodyPlatform.partnerCommission"),
    platformNameValidationMessage: t("otherFoodyPlatform.platformNameValidationMessage"),
    allowInputDiscountFromPromotion: t("otherFoodyPlatform.allowInputDiscountFromPromotion"),
    allowInputDiscountFromPromotionTooltip: t("otherFoodyPlatform.allowInputDiscountFromPromotionTooltip"),
    allowInputDiscountFromPartner: t("otherFoodyPlatform.allowInputDiscountFromPartner"),
    allowInputDiscountFromPartnerTooltip: t("otherFoodyPlatform.allowInputDiscountFromPartnerTooltip"),
    updateFailed: t("messages.updatePlatformFailed"),
    deleteFailed: t("messages.deletePlatformFailed"),
    generalInformation: {
      title: t("otherFoodyPlatform.generalInformation.title"),
      uploadImage: t("otherFoodyPlatform.generalInformation.addFile"),
    },
    upload: {
      addFromUrl: t("upload.addFromUrl"),
    },
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    bestDisplayImage: t("messages.imageBestDisplay"),
    btnUpdate: t("otherFoodyPlatform.update"),
    btnCancel: t("otherFoodyPlatform.cancel"),
    btnDelete: t("otherFoodyPlatform.delete"),
    btnAdd: t("otherFoodyPlatform.add"),
    deleteFoodyPlatformConfirmationTitle: t("otherFoodyPlatform.deleteFoodyPlatformConfirmationTitle"),
    deleteFoodyPlatformConfirmationCancellation: t("otherFoodyPlatform.deleteFoodyPlatformConfirmationCancellation"),
    deleteFoodyPlatformConfirmationOk: t("otherFoodyPlatform.deleteFoodyPlatformConfirmationOk"),
    partnerCommissionValidationMessage: t("otherFoodyPlatform.partnerCommissionValidationMessage"),
    partnerCommissionPlaceholder: t("otherFoodyPlatform.partnerCommissionPlaceholder"),
    logo: {
      title: t("otherFoodyPlatform.logo"),
      subTitle: t("registerAccount.logo.subTitle"),
      subTitle1: t("registerAccount.logo.subTitle1"),
      subTitle2: t("registerAccount.logo.subTitle2"),
      subTitle3: t("registerAccount.logo.subTitle3"),
      uploadImage: t("registerAccount.logo.uploadImage"),
      textNonImage: t("registerAccount.logo.textNonImage"),
      bestDisplayImage: t("registerAccount.logo.bestDisplayImage"),
    },
    validateRangeMessage: t("configuration.validateRangeMessage", "Allowed values range from $from$ to $to$"),
  };

  useEffect(() => {
    form.resetFields(["partnerCommission", "name"]);
    setIsAddForm(initData?.isAddPlatform);
    if (initData) {
      setInitData(initData);
    }
    if (initData?.isAddPlatform) {
      setActiveDeleteButton(false);
    } else if (initData?.isActive) {
      form.validateFields();
    }
  }, [initData]);

  useEffect(() => {
    setEnableEditing(initData?.isActive);
  }, [initData?.isActive]);

  useEffect(() => {
    onChangeForm(isFieldsModified);
  }, [isFieldsModified]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  const setInitData = (data) => {
    form.setFieldValue("id", data?.id);
    form.setFieldValue("name", data?.name);
    form.setFieldValue("partnerCommission", data?.partnerCommission);
    form.setFieldValue("logo", data?.logo);
    setImage(initData?.logo);
    setIsDefaultImageUsed(false);
    setIsAllowInputDiscountFromPromotion(data?.isAllowInputDiscountFromPromotion);
    setIsAllowInputDiscountFromPartner(data?.isAllowInputDiscountFromPartner);
    setFoodyPlatformConfig(data);
    setEnableEditing(initData?.isActive);
    setActiveButton(initData?.isActive);
    setActiveDeleteButton(Boolean(!initData?.foodyPlatformId));
    setIsFieldsModified(false);
    setIsRevalidateInput(false);

    fnbImageSelectRef.current.setImage(initData?.logo);

    setPartnerCommissionLength(
      initData?.partnerCommission?.toString() ? initData.partnerCommission.toString().length + 3 : 19,
    );
  };

  const onClickUpdate = () => {
    form.validateFields().then(async (values) => {
      let updatedFoodyPlatform = {
        id: values?.id,
        name: values?.name,
        logo: initData?.foodyPlatformId || isDefaultImageUsed ? null : image ?? otherFoodyPlatformDefaultLogo,
        partnerCommission: values.partnerCommission,
        isAllowInputDiscountFromPromotion: isAllowInputDiscountFromPromotion,
        isAllowInputDiscountFromPartner: isAllowInputDiscountFromPartner,
      };

      otherFoodyPlatformConfigDataService
        .updateFoodyPlatformConfigAsync(updatedFoodyPlatform)
        .then((response) => {
          if (response?.isSuccess === true) {
            message.success(t(response.message));
            onCompleted();
          } else {
            message.error(t(response.message));
            setIsRevalidateInput(true);
          }
        })
        .catch((response) => {
          message.error(t(pageData.updateFailed));
          setIsRevalidateInput(true);
        });
    });
  };

  const onValidForm = () => {
    if (isRevalidateInput) {
      form.validateFields();
    }
  };

  const handleDisplayDeleteConfirmationDialog = () => {
    setDeleteFoodyPlatformContent(
      t("otherFoodyPlatform.deleteFoodyPlatformConfirmationContent").replace(
        "{{name}}",
        `
        <span class="alert-text">
          ${foodyPlatformConfig?.name}
        </span>
      `,
      ),
    );
    setDisplayDeleteFoodyPlatformConfirmation(true);
  };

  const handleDeleteFoodyPLatform = () => {
    if (foodyPlatformConfig?.id) {
      otherFoodyPlatformConfigDataService
        .deleteFoodyPlatformConfigAsync(foodyPlatformConfig?.id)
        .then((response) => {
          if (response?.isSuccess === true) {
            message.success(t(response.message));
            onCompleted();
          } else {
            message.error(t(response.message));
          }
        })
        .catch((response) => {
          message.error(t(pageData.deleteFailed));
        });
    }
    setDisplayDeleteFoodyPlatformConfirmation(false);
  };

  const handleCancelDeleteFoodyPLatform = () => {
    setDisplayDeleteFoodyPlatformConfirmation(false);
  };

  const handleCancelEditingFoodyPlatform = () => {
    form.resetFields(["name", "partnerCommission"]);
    setInitData(initData);
  };

  const handleChangePlatformName = (value) => {
    setIsFieldsModified(true);
    const option = options.find((option) => option.value === value);
    if (option && !isDefaultImageUsed) {
      fnbImageSelectRef.current.setImage(option.logo);
      setImage(option.logo);
    }
  };

  const handleChangePartnerCommission = (value) => {
    setPartnerCommissionLength(value?.toString().length + 3);
    form.setFieldValue("partnerCommission", value);
    setIsFieldsModified(true);
  };

  const handleChangeIsAllowInputDiscountFromPromotion = (checked) => {
    setIsAllowInputDiscountFromPromotion(checked);
    setIsFieldsModified(true);
  };

  const handleChangeIsAllowInputDiscountFromPartner = (checked) => {
    setIsAllowInputDiscountFromPartner(checked);
    setIsFieldsModified(true);
  };

  const onChangeImage = (file) => {
    setImage(file?.url);
    setIsFieldsModified(true);
  };

  const onClickAdd = () => {
    form.validateFields().then(async (values) => {
      let newFoodyPlatform = {
        name: values.name,
        logo: image ?? otherFoodyPlatformDefaultLogo,
        partnerCommission: values.partnerCommission,
        isAllowInputDiscountFromPromotion: isAllowInputDiscountFromPromotion,
        isAllowInputDiscountFromPartner: isAllowInputDiscountFromPartner,
      };
      otherFoodyPlatformConfigDataService
        .createFoodyPlatformConfigAsync(newFoodyPlatform)
        .then((response) => {
          if (response?.isSuccess === true) {
            message.success(t(response.message));
            onCompleted();
          } else {
            message.error(t(response.message));
            setIsRevalidateInput(true);
          }
        })
        .catch((response) => {
          message.error(t(pageData.addFailed));
          setIsRevalidateInput(true);
        });
    });
  };

  return (
    <>
      <Form form={form} layout="vertical" autoComplete="off" onChange={onValidForm} className="custom-form">
        <Row span={24} style={{ height: 44 }}>
          {!isAddForm && (activeDeleteButton || (activeButton && isFieldsModified)) && (
            <Form.Item className="list-button mt-0">
              {activeButton && isFieldsModified && (
                <FnbButton text={pageData.btnCancel} variant="tertiary" onClick={handleCancelEditingFoodyPlatform} />
              )}
              {activeButton && isFieldsModified && (
                <FnbButton minWidth="140px" text={pageData.btnUpdate} onClick={onClickUpdate} />
              )}
              {activeDeleteButton && (
                <Button
                  htmlType="button"
                  className="delete-button"
                  type="primary"
                  onClick={handleDisplayDeleteConfirmationDialog}
                >
                  <TrashIcon width={24} height={24} />
                </Button>
              )}
            </Form.Item>
          )}
          {isAddForm && (
            <Form.Item className="list-button mt-0">
              {<FnbButton text={pageData.btnCancel} variant="tertiary" onClick={handleCancelAddFoodyPlatform} />}
              {<FnbButton minWidth="140px" text={pageData.btnAdd} htmlType="submit" onClick={onClickAdd} />}
            </Form.Item>
          )}
        </Row>
        <Row span={24} gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="id" style={{ display: "none" }}></Form.Item>
            <Form.Item
              name="name"
              label={pageData.platformName}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={pageData.platformNameValidationMessage}
                    />
                  ),
                },
              ]}
            >
              <FnbAutoComplete
                value={form.getFieldValue("name")}
                maxLength={100}
                disabled={!enableEditing}
                options={options}
                placeholder={pageData.platformNamePlaceholder}
                onChange={handleChangePlatformName}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24}>
          <Col span={24}>
            <Form.Item name="logo" label={pageData.logo.title}>
              <Row className={image ? "have-image" : "non-image"}>
                <Col className="content-image-product" span={24}>
                  <div className={`image-product ${image ? "justify-left" : ""}`}>
                    <Form.Item name={["image", "media"]}>
                      <div style={foodyPlatformConfig?.isActive ? {} : { filter: "grayscale(100%)" }}>
                        <FnbUploadImageComponent
                          ref={fnbImageSelectRef}
                          buttonText={pageData.generalInformation.uploadImage}
                          iconUpload={<UploadLogoIcon />}
                          onChange={onChangeImage}
                        />
                      </div>
                    </Form.Item>
                  </div>
                </Col>
                <Col className="text-non-image" hidden={image ? true : false}>
                  <Typography disabled className="sub-title-logo">
                    {image && (
                      <ul>
                        <li>{pageData.logo.subTitle1}</li>
                        <li>{pageData.logo.subTitle2}</li>
                      </ul>
                    )}
                    {!image && pageData.logo.subTitle1}
                    {!image && <br />}
                    {!image && pageData.logo.subTitle2}
                  </Typography>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row span={24} gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="partnerCommission"
              label={pageData.partnerCommission}
              rules={[
                {
                  required: enableEditing,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={pageData.partnerCommissionValidationMessage}
                    />
                  ),
                },
                {
                  type: "number",
                  min: 0,
                  max: 100,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={pageData.validateRangeMessage.replace("$from$", 0).replace("$to$", 100)}
                    />
                  ),
                },
              ]}
            >
              <FnbInputNumber
                type="number"
                className="w-100"
                addonAfter={"%"}
                maxLength={100}
                min={0}
                placeholder={pageData.partnerCommissionPlaceholder}
                onChange={handleChangePartnerCommission}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} gutter={[0, 16]}>
          <Row span={24} gutter={[4, 4]} wrap={true}>
            <Col span={24} className="promotion-switch">
              <div className="discount-switch-button col-switch">
                <FnbSwitch
                  checked={isAllowInputDiscountFromPromotion}
                  disabled={!enableEditing}
                  onChange={handleChangeIsAllowInputDiscountFromPromotion}
                />
              </div>
              <span className="discount-switch-title">{pageData.allowInputDiscountFromPromotion}</span>
            </Col>
            <Col span={24}>
              <span className="discount-switch-description">{pageData.allowInputDiscountFromPromotionTooltip}</span>
            </Col>
          </Row>
          <Row span={24} gutter={[4, 4]}>
            <Col span={24} className="discount-switch">
              <div className="partner-switch-button col-switch">
                <FnbSwitch
                  checked={isAllowInputDiscountFromPartner}
                  disabled={!enableEditing}
                  onChange={handleChangeIsAllowInputDiscountFromPartner}
                />
              </div>
              <span className="partner-switch-title">{pageData.allowInputDiscountFromPartner}</span>
            </Col>
            <Col span={24}>
              <span className="partner-switch-description">{pageData.allowInputDiscountFromPartnerTooltip}</span>
            </Col>
          </Row>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.deleteFoodyPlatformConfirmationTitle}
        content={deleteFoodyPlatformContent}
        visible={displayDeleteFoodyPlatformConfirmation}
        skipPermission={true}
        cancelText={pageData.deleteFoodyPlatformConfirmationCancellation}
        okText={pageData.deleteFoodyPlatformConfirmationOk}
        onCancel={handleCancelDeleteFoodyPLatform}
        onOk={handleDeleteFoodyPLatform}
      />
    </>
  );
}
