import { Col, Row, Typography } from "antd";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { NoDataFoundComponent } from "components/no-data-found/no-data-found.component";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { ImageMaterialDefault, SearchIcon } from "constants/icons.constants";
import ingredientRemoveRequestDataService from "data-services/material-control/ingredient-remove-request-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils/helpers";
import useDebounce from "hooks/useDebounce";
import "./ingredient-search-modal.scss";

export function IngredientSearchModal({
  visible,
  className,
  handleCancel,
  branchId,
  listSelectedMaterials,
  setListSelectedMaterials,
}) {
  const { t } = useTranslation();
  const pageData = {
    searchByNameSku: t("materialControl.createRemoveRequest.searchByNameSku"),
    text1: t("materialControl.createRemoveRequest.addIngredientsText1"),
    text2: t("materialControl.createRemoveRequest.addIngredientsText2"),
    ingredientSearch: t("materialControl.createRemoveRequest.ingredientSearch"),
  };

  const [materials, setMaterials] = useState([]);
  const [searchMaterials, setSearchMaterials] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState(listSelectedMaterials ?? []);
  const [keyword, setKeyword] = useState("");

  const renderListIngredients = () => {
    return searchMaterials.map((material, index) => {
      return (
        <Row
          id={`content-view-${index}`}
          style={{
            display: "flex",
            gap: 16,
            flexDirection: "row",
            padding: 12,
            borderBottomWidth: index === searchMaterials?.length - 1 ? 0 : 1,
            borderBottomColor: "#E1DBFF",
            borderBottomStyle: "solid",
          }}
        >
          <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <FnbCheckBox
              defaultChecked={selectedMaterials?.findIndex((x) => x.materialId === material.materialId) !== -1}
              checked={selectedMaterials?.findIndex((x) => x.materialId === material.materialId) !== -1}
              value={material.materialId}
              onChange={(e) => handleCheckMaterial(e, material)}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Row style={{ gap: 12 }} align={"middle"}>
              <Col>
                {material?.thumbnail ? (
                  <Thumbnail width={64} height={64} src={material?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={64} height={64} />
                )}
              </Col>
              <Col style={{ alignSelf: "center", flex: 1, gap: 6 }}>
                <Row>
                  <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>{material?.name}</Typography>
                </Row>
                {material?.sku && (
                  <Row>
                    <Typography style={{ fontWeight: 400, fontSize: 14, color: "#8B8899" }}>
                      SKU: {material?.sku}
                    </Typography>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>
              {formatCurrency(material?.price)}/{material?.unitName}
            </Typography>
          </Col>
        </Row>
      );
    });
  };

  const handleGetIngredients = async () => {
    let data = await ingredientRemoveRequestDataService.getAllIngredientByBranchId(branchId);
    if (data) {
      setMaterials(data?.ingredients);
      setSearchMaterials(data?.ingredients);
    }
  };

  const handleCheckMaterial = (e, material) => {
    if (e.target.checked) {
      let newListMaterials = [...selectedMaterials, material];
      setSelectedMaterials(newListMaterials);
    } else {
      let newListMaterials = selectedMaterials?.filter((x) => x.materialId !== material.materialId);
      setSelectedMaterials(newListMaterials);
    }
  };

  useDebounce(
    () => {
      if (keyword?.length === 0) {
        setSearchMaterials(materials);
      } else {
        let newMaterials = materials.filter((x) => {
          return (
            x?.name?.removeVietnamese().toLowerCase().includes(keyword?.removeVietnamese().toLowerCase()) ||
            x?.sku?.removeVietnamese().toLowerCase().includes(keyword?.removeVietnamese().toLowerCase())
          );
        });
        setSearchMaterials(newMaterials);
      }
    },
    [keyword],
    500,
  );

  const handleSubmitFilter = () => {
    setListSelectedMaterials(selectedMaterials);
    handleCancel();
  };

  useEffect(() => {
    if (branchId && visible) {
      handleGetIngredients();
      setSelectedMaterials([...listSelectedMaterials]);
    }
    setKeyword("");
  }, [visible]);

  useEffect(() => {
    setListSelectedMaterials([]);
    setSelectedMaterials([]);
    setMaterials([]);
    setSearchMaterials([]);
  }, [branchId]);

  useEffect(() => {
    if (searchMaterials?.length > 0) document.getElementById("content-view-0")?.scrollIntoView();
  }, [searchMaterials]);

  return (
    <FnbModal
      visible={visible}
      className={`ingredient-search-modal ${className}`}
      handleCancel={handleCancel}
      onOk={handleSubmitFilter}
      title={pageData.ingredientSearch}
      width={800}
      closeIcon
      okText={`${pageData.text1} ${selectedMaterials?.length > 0 ? `(${selectedMaterials?.length})` : ""} ${
        pageData.text2
      }`}
      content={
        <div className="content-inner">
          <FnbInput
            value={keyword}
            prefix={
              <>
                {selectedMaterials?.length === 0 && <SearchIcon />}
                {selectedMaterials?.map((material) => {
                  let selectedMaterial = materials.find((x) => x.materialId === material.materialId);
                  return (
                    <FnbChip
                      className={"ingredient-chip"}
                      id={selectedMaterial?.id}
                      isDisableToolTip={true}
                      title={selectedMaterial?.name}
                      isRemoveChip={true}
                      onRemoveChip={() => {
                        let newListMaterials = selectedMaterials?.filter((x) => x.materialId !== material.materialId);
                        setSelectedMaterials(newListMaterials);
                      }}
                    />
                  );
                })}
              </>
            }
            placeholder={selectedMaterials?.length === 0 ? pageData.searchByNameSku : undefined}
            onChange={(e) => setKeyword(e.target.value)}
            className="ingredient-input"
            maxLength={100}
            allowClear
          />
          <div className="ingredient-modal-content">
            <div className="ingredient-modal-content-list">
              {searchMaterials?.length > 0 ? (
                renderListIngredients()
              ) : (
                <div className="ingredient-search-no-data">
                  <NoDataFoundComponent />
                </div>
              )}
            </div>
          </div>
        </div>
      }
      disabledBtnOk={selectedMaterials?.length === 0}
    />
  );
}
