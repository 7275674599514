import { Form, Radio } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { tryJsonString } from "utils/helpers";
import { getStorage, localStorageKeys, setStorage } from "utils/localStorage.helpers";
import "../qr-order.page.scss";
import FnbRadioButton from "components/fnb-radio-button";

const FilterQrOrder = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { branches, serviceTypes, targets, status, onShowFilter } = props;
  const [initFilter, setInitFilter] = useState(true);
  const defaultValue = "";

  const pageData = {
    branch: t("material.filter.branch.title"),
    serviceType: t("reportRevenue.serviceType"),
    target: t("marketing.qrCode.target"),
    status: t("table.status"),
    filter: {
      resetAllFilters: t("productManagement.filter.resetallfilters"),
    },
    allBranches: t("material.filter.branch.all"),
    allTypes: t("order.allTypes"),
    allTargets: t("marketing.qrCode.allTargets"),
    all: t("order.all"),
  };

  useEffect(() => {
    var sessionQRCodeFilter = getStorage(localStorageKeys.QR_ORDER_FILTER);
    const qrCodeFilter = tryJsonString(sessionQRCodeFilter);
    if (qrCodeFilter != null && qrCodeFilter.count > 0) {
      form.setFieldsValue(qrCodeFilter);
    }
  }, []);

  const onApplyFilter = () => {
    let formValue = form.getFieldsValue();
    formValue.count = countFilterControl(formValue);
    setStorage(localStorageKeys.QR_ORDER_FILTER, JSON.stringify(formValue));
    props.handleFilterQRCode(formValue);
  };

  const countFilterControl = (formValue) => {
    let countBranch = formValue.branchId === "" || formValue.branchId === undefined ? 0 : 1;
    let countServiceType = formValue.serviceTypeId === "" || formValue.serviceTypeId === undefined ? 0 : 1;
    let countTarget = formValue.targetId === "" || formValue.targetId === undefined ? 0 : 1;
    let countStatus = formValue.statusId === "" || formValue.statusId === undefined ? 0 : 1;

    return countBranch + countServiceType + countTarget + countStatus;
  };

  const onResetForm = () => {
    form?.resetFields();
    onApplyFilter();
    if (onShowFilter) {
      onShowFilter(false);
    }
    setInitFilter(true);
  };

  const updateInitFilter = () => {
    let formValue = form?.getFieldsValue();
    let countFilter = countFilterControl(formValue);
    setInitFilter(countFilter === 0 ? true : false);
  };

  useImperativeHandle(ref, () => ({
    resetFilter() {
      onResetForm();
    },
  }));

  return (
    <Form layout="vertical" form={form} onFieldsChange={onApplyFilter} className="qr-code-filter">
      <Form.Item name="branchId" label={pageData.branch}>
        <FnbSelectSingle
          placeholder={pageData.allBranches}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={branches}
          onChange={updateInitFilter}
        />
      </Form.Item>
      <Form.Item name="serviceTypeId" label={pageData.serviceType}>
        <FnbSelectSingle
          placeholder={pageData.allTypes}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={serviceTypes}
          onChange={updateInitFilter}
        />
      </Form.Item>
      <Form.Item name="targetId" label={pageData.target}>
        <FnbSelectSingle
          placeholder={pageData.allTargets}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={targets}
          onChange={updateInitFilter}
        />
      </Form.Item>
      <Form.Item name="statusId" label={pageData.status}>
        <Radio.Group defaultValue={defaultValue} buttonStyle="solid" onChange={updateInitFilter}>
          <FnbRadioButton value={defaultValue}>{pageData.all}</FnbRadioButton>
          {status?.map((item) => (
            <FnbRadioButton value={item.id}>{t(item.name)}</FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default FilterQrOrder;
