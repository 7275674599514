import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoreSettingConstants } from "constants/store-setting.constants";
import storeDataService from "data-services/store/store-data.service";

const PREFIX = "settings";
const initialState = {
  bankAccountInfo: null,
};

export const getBankInfoAsync = createAsyncThunk(`${PREFIX}/getBankInfoAsync`, async () => {
  const response = await storeDataService.getStoreBankAccountByStoreIdAsync();
  return response?.storeBankAccount;
});

export const updateBankInfoAsync = createAsyncThunk(`${PREFIX}/updateBankInfoAsync`, async (data) => {
  const payload = {
    storeBankAccount: data,
    storeSetting: StoreSettingConstants.BANK_ACCOUNT_CONFIG,
  };
  const response = await storeDataService.updateStoreManagementAsync(payload);
  return response;
});

const settingsSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    unMountComponent: (state) => {
      state.bankInfo = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBankInfoAsync.fulfilled, (state, action) => {
      state.bankAccountInfo = action.payload;
    });
    builder.addCase(updateBankInfoAsync.fulfilled, (state, action) => {
      state.bankAccountInfo = { ...state.bankAccountInfo, ...action.meta.arg };
    });
  },
});

export const settingsActions = settingsSlice.actions;
export const settingsSelector = (state) => state.settings;

const settingsReducer = settingsSlice.reducer;
export default settingsReducer;
