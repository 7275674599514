import { useEffect } from "react";
import storeDataService from "data-services/store/store-data.service";

export default function TawkToWidget(props) {
  const { showWidget } = props;

  useEffect(() => {
    const loadTawkConfig = async () => {
      try {
        const tawkConfig = await storeDataService.getTawkConfig();
        loadTawkToScript(tawkConfig);
      } catch (error) {
        console.error("Failed to load Tawk configuration:", error);
      }
    };
    const loadTawkToScript = (tawkConfig) => {
      if (!tawkConfig) return;

      const script = document.createElement("script");
      script.async = true;
      script.src = `https://embed.tawk.to/${tawkConfig?.propertyId}/${tawkConfig?.widgetId}`;
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      document.head.appendChild(script);
    };
    loadTawkConfig();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.Tawk_API) {
        showWidget ? window.Tawk_API.showWidget() : window.Tawk_API.hideWidget();
        clearInterval(interval);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [showWidget]);

  return null;
}
