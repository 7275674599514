import { isNonEmptyArray } from "../../utils/helpers";

const mapperProducts2ProductVariantsFromCategories = (productCategories) => {
  if (!isNonEmptyArray(productCategories)) return [];

  let productPricesData = [];
  let index = 0;
  let currentComboId = null;

  productCategories?.forEach((item) => {
    item?.products?.forEach((product) => {
      if (product?.isCombo) {
        const comboProductPrices = product?.data?.comboProductPrices;
        const comboPricingProducts = product?.data?.comboPricingProducts;
        const comboProductPricesData = isNonEmptyArray(comboProductPrices) ? comboProductPrices : comboPricingProducts;

        comboProductPricesData?.forEach((comboProduct) => {
          if (currentComboId !== product?.id) {
            index += 1;
          }
          const productPrice = {
            productPriceId: comboProduct?.productPriceId,
            comboId: product?.id,
            index: index,
          };
          productPricesData.push(productPrice);

          currentComboId = product?.id;
        });
      } else {
        index += 1;

        const productPrice = { productPriceId: product?.productPriceId, index: index };
        productPricesData.push(productPrice);
      }
    });
  });

  return productPricesData;
};

const mapperProducts2Variants = (products) => {
  if (!isNonEmptyArray(products)) return [];
  const productPrices = products?.map((product, index) => ({
    productPriceId: product?.productPriceId,
    index: index,
  }));

  return productPrices;
};

// Mapper for store web the Product list theme2
const mapperProducts2ProductVariantsFromCategoriesObject = (obj) => {
  if (!obj || !isNonEmptyArray(Object.keys(obj))) return [];

  let productPricesData = [];
  let index = 0;
  let currentComboId = null;

  Object.keys(obj)?.forEach((categoryId) => {
    const productOrComboData = obj[categoryId];
    if (productOrComboData?.isCombo) {
      const comboProductPrices = productOrComboData?.comboProductPrices;
      const comboPricings = productOrComboData?.comboPricings;
      const isComboProductPrices = isNonEmptyArray(comboProductPrices);

      if (isComboProductPrices) {
        // Specific
        comboProductPrices?.forEach((comboSpecifix) => {
          if (currentComboId !== productOrComboData?.id) index += 1;
          const productPrice = {
            comboId: productOrComboData?.id,
            index: index,
            productPriceId: comboSpecifix?.productPriceId,
          };
          productPricesData.push(productPrice);
          currentComboId = productOrComboData?.id;
        });
      } else {
        // Flexible
        comboPricings?.forEach((comboPricing) => {
          comboPricing?.comboPricingProducts?.forEach((comboPricingProduct) => {
            if (currentComboId !== productOrComboData?.id) index += 1;
            const productPrice = {
              comboId: productOrComboData?.id,
              index: index,
              productPriceId: comboPricingProduct?.productPriceId,
            };
            productPricesData.push(productPrice);
            currentComboId = productOrComboData?.id;
          });
        });
      }
    } else {
      productOrComboData?.data?.forEach((product) => {
        index += 1;
        const productPriceId = product?.productPrices[product?.defaultProductPriceIndex ?? 0]?.id;
        const productPrice = { productPriceId: productPriceId, index: index };
        productPricesData.push(productPrice);
      });
    }
  });

  return productPricesData;
};

const mapperProducts2VariantsObject = (obj) => {
  if (!obj || !isNonEmptyArray(Object.keys(obj))) return [];
  let productPricesData = [];
  let index = 0;

  Object.keys(obj)?.forEach((categoryId) => {
    const productData = obj[categoryId];
    productData?.forEach((product) => {
      index += 1;
      const productPriceId = product?.productPrices[product?.defaultProductPriceIndex ?? 0]?.id;
      const productPrice = {
        productPriceId: productPriceId,
        index: index,
      };
      productPricesData.push(productPrice);
    });
  });
  return productPricesData;
};

const mapperOutOfStockServices = {
  mapperProducts2ProductVariantsFromCategories,
  mapperProducts2Variants,
  mapperProducts2ProductVariantsFromCategoriesObject,
  mapperProducts2VariantsObject,
};

export default mapperOutOfStockServices;
