const taxType = {
  /// <summary>
  /// Selling Tax
  /// </summary>
  SellingTax: 0,

  /// <summary>
  /// Imported Tax
  /// </summary>
  ImportedTax: 1,
};

export default taxType;
