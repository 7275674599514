import LoyaltyPointCardComponent from "../../pages/my-profile/components/LoyaltyPointCard/LoyaltyPointCard";
import { LoyaltyPointCardDefaultData } from "../../pages/my-profile/components/LoyaltyPointCard/DefaultData";

export default function LoyaltyPointCardContainer(props) {
  const { customerInfo, isCustomize, loyaltyPoint, isActivated } = props;
  const firstName = isCustomize ? LoyaltyPointCardDefaultData?.firstName : customerInfo?.firstName;
  const lastName = isCustomize ? LoyaltyPointCardDefaultData?.lastName : customerInfo?.lastName;
  const customerBarcode = isCustomize ? LoyaltyPointCardDefaultData?.customerBarcode : customerInfo?.customerBarcode;
  
  return (
    (isActivated || isCustomize) && (
      <LoyaltyPointCardComponent
        customerBarcode={customerBarcode}
        loyaltyPoint={isCustomize ? LoyaltyPointCardDefaultData?.loyaltyPoint : loyaltyPoint}
        firstName={firstName}
        lastName={lastName}
        isCustomize={isCustomize}
      />
    )
  );
}
