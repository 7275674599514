import { Badge, List, Popover, Skeleton } from "antd";
import Color from "color";
import parse from "html-react-parser";
import moment from "moment";
import VirtualList from "rc-virtual-list";
import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Locale } from "../../../constants/region.constants";
import notificationDataService from "../../../data-services/notification-data.service";
import { notificationActions, notificationDetailSelector } from "../../../modules/notification/notification.reducer";
import { languageCodeSelector, userInfoSelector } from "../../../modules/session/session.reducers";
import { BellIcon, CheckTitleCartIcon, LampIcon } from "../../assets/icons.constants";
import EmptyIcon from "../../assets/images/illustrations.png";
import { EnumNotification } from "../../constants/signalR-listener.constants";
import { noNotificationHomePageEn } from "../../pages/home/customize-default-data/en";
import { noNotificationHomePageVi } from "../../pages/home/customize-default-data/vi";
import "./fnb-notification.scss";

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_ITEM_HEIGHT = 105;
const DEFAULT_LIST_HEIGHT = 367;

const FnbNotification = (props) => {
  const { groupColorConfig, isCustomize } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const notificationDetail = useSelector(notificationDetailSelector);
  const languageCode = useSelector(languageCodeSelector);
  const [loading, setLoading] = useState(false);

  const translateData = {
    markAllAsRead: t("storeWebPage.generalUse.markAllAsRead"),
    noNotifications: t(
      isCustomize
        ? languageCode === Locale.Vi
          ? noNotificationHomePageVi.noNotifications
          : noNotificationHomePageEn.noNotifications
        : "storeWebPage.generalUse.noNotifications",
    ),
    noNotificationsRightNow: t(
      isCustomize
        ? languageCode === Locale.Vi
          ? noNotificationHomePageVi.noNotificationsRightNow
          : noNotificationHomePageEn.noNotificationsRightNow
        : "storeWebPage.generalUse.noNotificationsRightNow",
    ),
    comeBackLater: t(
      isCustomize
        ? languageCode === Locale.Vi
          ? noNotificationHomePageVi.comeBackLater
          : noNotificationHomePageEn.comeBackLater
        : "storeWebPage.generalUse.comeBackLater",
    ),
    pleaseRateYourOrder: t("storeWebPage.generalUse.pleaseRateYourOrder"),
    justNow: t("storeWebPage.generalUse.justNow"),
  };

  useLayoutEffect(() => {
    initNotifications();
  }, []);

  const initNotifications = async () => {
    if (
      !loading &&
      userInfo?.customerId &&
      (!notificationDetail || notificationDetail?.notifications?.length !== notificationDetail?.total)
    ) {
      const params = {
        customerId: userInfo?.customerId,
        pageNumber: notificationDetail?.currentPage ? notificationDetail?.currentPage + 1 : DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
      };

      setLoading(true);
      await notificationDataService
        .getNotificationByCustomerIdAsync(params)
        .then((res) => {
          if (res?.data?.notifications) {
            dispatch(
              notificationActions.setNotification({
                ...res?.data,
                notifications: (notificationDetail?.notifications || [])?.concat(res?.data?.notifications),
              }),
            );
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onScroll = (e) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - DEFAULT_LIST_HEIGHT) <= 1) {
      initNotifications();
    }
  };

  const markAsRead = async (item) => {
    if (!item?.isRead) {
      // Mark as read
      await notificationDataService.markAsReadAsync(item?.id);

      // Update read state for notification list
      const newNotification = {
        ...notificationDetail,
        totalUnread: notificationDetail?.totalUnread - 1,
        notifications: notificationDetail?.notifications?.map((notification) =>
          notification?.id === item?.id ? { ...notification, isRead: true } : notification,
        ),
      };
      dispatch(notificationActions.setNotification(newNotification));
    }

    // Redirect order detail
    if (item?.type === EnumNotification.Order && item?.referenceId) {
      history.push(`/my-profile/2/${item?.referenceId}`);
    }
  };

  const markAllAsRead = async (customerId) => {
    // Mark all as read
    await notificationDataService.markAllAsReadAsync(customerId);

    // Update read state for notification list
    const newNotification = {
      ...notificationDetail,
      totalUnread: 0,
      notifications: notificationDetail?.notifications?.map((notification) => ({ ...notification, isRead: true })),
    };
    dispatch(notificationActions.setNotification(newNotification));
  };

  const formatDate = (value) => {
    const currentTime = moment();
    const notificationTime = moment.utc(value).local();
    const isSame = notificationTime.isSame(currentTime, "days");
    const locale = languageCode === Locale.Vi ? Locale.Vi : Locale.En;

    if (isSame) {
      const diff = currentTime.diff(notificationTime, "seconds");
      return diff > 15 ? notificationTime.format("HH:mm") : translateData.justNow;
    } else {
      return notificationTime.locale(locale).format("DD MMM YYYY - HH:mm");
    }
  };

  const renderListItem = (item) => {
    if (item?.type === EnumNotification.NotificationCampaign) {
      return (
        <List.Item.Meta
          avatar={
            <div className="custom-avatar">
              <LampIcon />
            </div>
          }
          title={<span>{item?.title}</span>}
          description={
            <div>
              <div className="notification-description"></div>
              <span className="notification-timespan">{formatDate(item?.notificationTime)}</span>
            </div>
          }
        />
      );
    }

    if (item?.type === EnumNotification.Order) {
      return (
        <List.Item.Meta
          avatar={
            <div className="custom-avatar">
              <LampIcon />
            </div>
          }
          title={<span>{parse(t("order.completedOrder", { stringCode: item?.title }))}</span>}
          description={
            <div>
              <div className="notification-description">{translateData.pleaseRateYourOrder}</div>
              <span className="notification-timespan">{formatDate(item?.notificationTime)}</span>
            </div>
          }
        />
      );
    }

    return <></>;
  };

  const Container = styled.div`
    .unread-notification {
      &:hover {
        background: ${(props) => props?.blurColor};
      }
      .ant-list-item-meta {
        .ant-list-item-meta-content .ant-list-item-meta-title {
          color: ${(props) => props?.mappingColor};
        }
        .ant-list-item-meta-avatar .custom-avatar {
          color: ${(props) => props?.mappingColor};
          background: ${(props) => props?.blurColor};
        }
      }
      .dot-unread {
        background: ${(props) => props?.mappingColor};
      }
    }
  `;

  const notificationList = () => {
    const mappingColor = groupColorConfig?.buttonBackgroundColor || "#026F30";
    const blurColor = Color(mappingColor).fade(0.8);
    return (
      <Container className="notification-list" mappingColor={mappingColor} blurColor={blurColor}>
        <List
          itemLayout="horizontal"
          header={
            <>
              {notificationDetail?.notifications?.length > 0 && (
                <div className="notification-header" onClick={() => markAllAsRead(notificationDetail?.customerId)}>
                  <CheckTitleCartIcon />
                  {translateData.markAllAsRead}
                </div>
              )}
            </>
          }
          loadMore={
            <Skeleton className="notification-loading" active avatar paragraph={{ rows: 1 }} loading={loading} />
          }
        >
          {notificationDetail?.notifications?.length > 0 ? (
            <>
              <VirtualList
                itemKey="id"
                data={notificationDetail?.notifications || []}
                height={DEFAULT_LIST_HEIGHT}
                itemHeight={DEFAULT_ITEM_HEIGHT}
                onScroll={onScroll}
              >
                {(item) => (
                  <List.Item
                    key={item?.id}
                    className={item?.isRead ? "read-notification" : "unread-notification"}
                    onClick={() => markAsRead(item)}
                  >
                    {renderListItem(item)}
                    {!item?.isRead && <div className="dot-unread" />}
                  </List.Item>
                )}
              </VirtualList>
            </>
          ) : (
            <div className="notification-empty" style={{ height: DEFAULT_LIST_HEIGHT }}>
              <img src={EmptyIcon} alt="no-notifications" />
              <div className="empty-text">
                <span>{translateData.noNotifications}</span>
                <span>{translateData.noNotificationsRightNow}</span>
                <span>{translateData.comeBackLater}</span>
              </div>
            </div>
          )}
        </List>
      </Container>
    );
  };

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      showArrow={false}
      content={notificationList}
      align={{ offset: [0, 8] }}
      getPopupContainer={(trigger) => trigger.parentNode}
      overlayClassName="overlay-notification-list"
    >
      <Badge className="notification-badge" count={notificationDetail?.totalUnread || 0} overflowCount={99}>
        <span className="notification-icon" style={{ color: groupColorConfig?.titleColor }}>
          <BellIcon />
        </span>
      </Badge>
    </Popover>
  );
};

export default FnbNotification;
