import styled from "styled-components";


export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const WrapperLeftColumn = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

export const WrapperRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

