import { Form } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbSwitch from "components/switch";
import { InfoCircleFlashSaleIcon } from "constants/icons.constants";
import { RoutePathConstants } from "constants/route-key-path.constants";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./styles.scss";

const POSKitchenScreen = (props) => {
  const { t, isChangeForm, isStoreHasKitchen, onChangeForm, defaultStoreConfig, updateStoreConfig } = props;
  const history = useHistory();

  const [form] = Form.useForm();
  const pageData = {
    annotate: t("configuration.kitchenSystem.annotate"),
    button: {
      cancel: t("button.cancel"),
      saveChanges: t("button.saveChanges"),
    },
    autoPrintStamp: t("configuration.kitchenSystem.autoPrintStamp"),
    averageTimeCooking: t("configuration.kitchenSystem.averageTimeCooking"),
    minutes: t("configuration.minutes"),
    validateRangeMessage: t("configuration.validateRangeMessage"),
    manageKitchen: t("kitchen.manageKitchen"),
  };

  useEffect(() => {
    form.setFieldsValue({
      cookingTime: defaultStoreConfig?.cookingTime ?? 15,
      isAutoPrintStamp: defaultStoreConfig?.isAutoPrintStamp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultStoreConfig]);

  const onCancel = () => {
    form.setFieldsValue({
      cookingTime: defaultStoreConfig?.cookingTime ?? 15,
      isAutoPrintStamp: defaultStoreConfig?.isAutoPrintStamp,
    });
    onChangeForm(false);
  };

  const onSave = () => {
    form.submit();
  };

  const onSubmitForm = (e) => {
    const payload = {
      isAutoPrintStamp: e?.isAutoPrintStamp,
      cookingTime: e?.cookingTime,
    };
    updateStoreConfig(payload);
    onChangeForm(false);
  };

  const onClickButtonManageKitchen = () => {
    history.push(RoutePathConstants.kitchenManagement);
  };

  return (
    <div className="pos-kitchen-screen-operation">
      <div className="heading-screen-operation">
        <div className="contain-description">
          <InfoCircleFlashSaleIcon height={16} width={16} />
          <span className="body-operation">{pageData.annotate}</span>
        </div>
        {isChangeForm && (
          <div className="list-group-button">
            <FnbButton text={pageData.button.cancel} variant="tertiary" onClick={onCancel} />
            <FnbButton text={pageData.button.saveChanges} width="120px" onClick={onSave} />
          </div>
        )}
      </div>
      <Form form={form} className="custom-form" onFinish={onSubmitForm}>
        <div className="pos-kitchen-screen-operation__container">
          <div className="auto-print-stamp-container">
            <Form.Item name="isAutoPrintStamp" valuePropName="checked">
              <FnbSwitch
                onChange={() => {
                  onChangeForm(true);
                }}
                disabled={!isStoreHasKitchen}
              />
            </Form.Item>
            <span className="body-operation">{pageData.autoPrintStamp}</span>
          </div>
          <div className="average-time-container">
            <span className="body-medium-operation">{pageData.averageTimeCooking}</span>
            <div className="wrapper-input-average-time">
              <Form.Item
                name="cookingTime"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 1,
                    max: 999,
                    message: (
                      <InputValidateMessage
                        message={pageData.validateRangeMessage.replace("$from$", 1).replace("$to$", 999)}
                      />
                    ),
                  },
                ]}
              >
                <FnbInputNumber
                  onChange={() => {
                    onChangeForm(true);
                  }}
                  className="w-100"
                  addonAfter={pageData.minutes}
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={!isStoreHasKitchen}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <div className="button-manage-kitchen">
        <FnbButton
          variant="primary"
          className="row-edit-button"
          text={pageData.manageKitchen}
          onClick={onClickButtonManageKitchen}
          disabled={!isStoreHasKitchen}
        />
      </div>
    </div>
  );
};

export default POSKitchenScreen;
