import { Card, Col, Form, Row, message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import FnbFroalaEditor from "components/fnb-froala-editor";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import SelectBlogTagComponent from "components/select-tag-blog/select-tag-blog.components";
import TextDanger from "components/text-danger";
import { DELAYED_TIME } from "constants/default.constants";
import { ExclamationIcon, UploadLogoIcon, WarningIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import blogDataService from "data-services/blog/blog-data.service";
import { error } from "jquery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { convertSeoUrl, formatDate, formatNumber, formatShortDate } from "utils/helpers";
import { enumBlogResponse } from "constants/blog.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import "./edit-blog.page.scss";

export default function EditBlogPage(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [blockNavigation, setBlockNavigation] = useState(false);
  const fnbImageSelectRef = React.useRef();

  const [categories, setCategories] = useState([]);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [tagDataTemp, setTagDataTemp] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagError, setTagError] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState(null);
  const [showCategoryNameValidateMessage, setShowCategoryNameValidateMessage] = useState(false);
  const [isCategoryNameExisted, setIsCategoryNameExisted] = useState(false);
  const [urlLink, setUrlSEO] = useState("");
  const [titleSEO, setTitleSEO] = useState("");
  const [descriptionSEO, setDescriptionSEO] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [showBlogContentValidateMessage, setShowBlogContentValidateMessage] = useState(false);
  const [blogName, setBlogName] = useState("");
  const reduxState = useSelector((state) => state);
  const [blog, setBlog] = useState({});
  const [isShowWarningSEOTitle, setIsShowWarningSEOTitle] = useState(false);
  const [isShowWarningSEODescription, setIsShowWarningSEODescription] = useState(false);
  const [checkBlogContentLoaded, setCheckBlogContentLoaded] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    getInitData();
  }, []);

  const [form] = Form.useForm();
  const pageData = {
    btnCancel: t("button.cancel"),
    btnUpdate: t("button.update"),
    btnAddNew: t("button.addNew"),
    btnSaveChanges: t("button.saveChanges"),
    generalInformation: {
      title: t("productManagement.generalInformation.title"),
      name: {
        label: t("blog.create.blogTitle"),
        placeholder: t("blog.create.blogTitlePlaceholder"),
        required: true,
        maxLength: 255,
        validateMessage: t("blog.create.blogTitleValidateMessage"),
      },
      category: {
        label: t("blog.create.blogCategory"),
        placeholder: t("blog.create.blogCategoryPlaceholder"),
        required: true,
        blogCategoryValidateMessage: t("blog.create.blogCategoryValidateMessage"),
      },
      uploadImage: t("productManagement.generalInformation.uploadImages"),
      blogContent: {
        label: t("blog.create.blogContent"),
        required: true,
        validateMessage: t("blog.create.blogContentValidateMessage"),
      },
    },
    SEO: {
      title: t("blog.create.SEOConfiguration"),
      SEOUrlLink: t("blog.create.SEOUrlLink"),
      SEOTitle: t("blog.create.SEOTitle"),
      SEODescription: t("blog.create.SEODescription"),
      SEOKeywords: t("blog.create.SEOKeywords"),
      SEOUrlLinkPlaceholder: t("blog.create.SEOUrlLinkPlaceholder"),
      SEOTitlePlaceholder: t("blog.create.SEOTitlePlaceholder"),
      SEODescriptionPlaceholder: t("blog.create.SEODescriptionPlaceholder"),
      SEOKeywordsPlaceholder: t("blog.create.SEOKeywordsPlaceholder"),
      SEOPreview: t("blog.preview"),
      SEOOverviewTooltip: t("blog.create.SEOOverviewTooltip"),
      SEOUrlLinkTooltip: t("blog.create.SEOUrlLinkTooltip"),
      SEOTitleTooltip: t("blog.create.SEOTitleTooltip"),
      SEODescriptionTooltip: t("blog.create.SEODescriptionTooltip"),
      SEOKeywordsTooltip: t("blog.create.SEOKeywordsTooltip"),
    },
    media: {
      title: t("blog.create.media"),
      textNonImage: t("media.textNonImage"),
      bannerTitle: t("blog.create.bannerTitle"),
    },
    upload: {
      addFromUrl: t("upload.addFromUrl"),
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    createdBy: t("onlineStore.pageManagement.createdBy"),
    createdTime: t("onlineStore.pageManagement.createdTime"),
    updateTime: t("onlineStore.pageManagement.updatedTime"),
    tooltipCostPerCategory: t("material.tooltipCostPerCategory"),
    limitTagMessage: t("blog.limitTagMessage"),
    blogCategory: {
      blogCategoryPlaceholder: t("blog.create.blogCategoryPlaceholder"),
      blogCategoryNameValidateMessage: t("blog.create.blogCategoryNameValidateMessage"),
      blogCategoryExisted: t("blog.create.blogCategoryExisted"),
      recipe: t("productManagement.unit.recipe"),
    },
    updateBlogSuccess: t("blog.create.updateBlogSuccess"),
    updateBlogFailed: t("blog.create.updateBlogFailed"),
    cannotFindBlog: t("blog.create.cannotFindBlog"),
    urlBlogHasExisted: t("blog.create.urlBlogHasExisted"),
    bestDisplayImage: t("blog.bestDisplayImage"),
    view: t("blog.view"),
    messages: {
      imageSizeTooBig: t("messages.imageSizeTooBig"),
    },
    messageMatchSuggestSEOTitle: t("blog.messageMatchSuggestSEOTitle"),
    messageMatchSuggestSEODescription: t("blog.messageMatchSuggestSEODescription"),
  };

  useEffect(() => {
    getInitData();
    getBlogTags();
  }, []);

  // validate form again if clicked submit form and change language

  const getInitData = async () => {
    const { id } = props?.match?.params;
    await getCategories();
    await blogDataService
      .getBlogByIdAsync(id)
      .then((res) => {
        setBlog(res?.blogDetail);
        mappingData(res?.blogDetail);
      })
      .catch(error);
  };

  const mappingData = (data) => {
    //mapping banner
    if (fnbImageSelectRef && fnbImageSelectRef?.current) {
      fnbImageSelectRef.current.setImage(data?.bannerImageUrl);
    }
    if (data?.bannerImageUrl) {
      setImage(data?.bannerImageUrl);
    }
    setTags(data?.blogTags);
    setBlogName(data?.title);
    setBlogContent(data?.content);
    setUrlSEO(data?.urlSEO);
    setTitleSEO(data?.titleSEO);
    setDescriptionSEO(data?.descriptionSEO);
    setCheckBlogContentLoaded(true);
    //mapping general
    form.setFieldsValue({
      title: data?.title,
      content: data?.content,
      blogCategoryId: data?.blogCategoryId,
      blogContent: data?.content,
      UrlSEO: data?.urlSEO,
      TitleSEO: data?.titleSEO,
      DescriptionSEO: data?.descriptionSEO,
    });
  };

  const getCategories = async () => {
    var resCategory = await blogDataService.getBlogCategoryAsync();
    if (resCategory) {
      setCategories(resCategory.blogCategories);
    }
  };

  const getBlogTags = async () => {
    var resBlogTag = await blogDataService.getBlogTagAsync();
    if (resBlogTag) {
      setTagDataTemp(resBlogTag.blogTags);
    }
  };

  const onSubmitForm = () => {
    if (blogContent.length === 0) {
      setShowBlogContentValidateMessage(true);
      return;
    }
    var imageUrl = "";
    if (fnbImageSelectRef && fnbImageSelectRef.current) {
      imageUrl = fnbImageSelectRef?.current?.getImage();
    }
    form
      .validateFields()
      .then(async (values) => {
        let request = {
          blogDetailModel: {
            ...values,
            id: props?.match?.params?.id,
            content: blogContent,
            bannerImageUrl: imageUrl[0]?.data_url,
            blogTags: tags,
            titleSEO: titleSEO,
            descriptionSEO: descriptionSEO,
            description: blogContent.replace(/<.*?>/gm, "").slice(0, 200),
          },
        };

        const res = await blogDataService.editBlogAsync(request);
        if (res?.isSuccess) {
          message.success(pageData.updateBlogSuccess);
          onCompleted();
        } else {
          switch (res?.responseCode) {
            case enumBlogResponse.NotFound:
              message.error(pageData.cannotFindBlog);
              break;
            case enumBlogResponse.IsNotUniqueUrlEncode:
              message.error(pageData.urlBlogHasExisted);
              break;
            default:
              message.error(pageData.updateBlogFailed);
              break;
          }
        }
      })
      .catch((errors) => {});
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      onCompleted();
      return history.push("/online-store/blog-management");
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onCompleted = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/online-store/blog-management");
    }, DELAYED_TIME);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
    setDisableCreateButton(false);
  };

  const onAddNewCategory = async () => {
    if (!newCategoryName) {
      setShowCategoryNameValidateMessage(true);
      return;
    }

    let res = await blogDataService.createBlogCategoryAsync({
      name: newCategoryName,
    });
    if (res.isSuccess) {
      /// Handle add new unit
      let newItem = {
        id: res.id,
        name: res.name,
      };
      setCategories([newItem, ...categories]);
      form.setFieldsValue({
        blogCategoryId: res.id,
      });
      setNewCategoryName(null);
    } else {
      setIsCategoryNameExisted(true);
    }
  };

  //Enter Category name and check existed
  const onNameChange = (event) => {
    if (categories.filter((u) => u.name.trim() === event.target.value.trim()).length > 0) {
      setShowCategoryNameValidateMessage(true);
    } else {
      setShowCategoryNameValidateMessage(false);
    }
    setShowCategoryNameValidateMessage(false);
    setNewCategoryName(event.target.value);
  };

  const onChangeOption = (id) => {
    let formValue = form.getFieldsValue();

    formValue.blogCategoryId = id;
    form.setFieldsValue(formValue);
  };

  const onChangeImage = (file) => {
    setImage(file);
  };

  return (
    <>
      <FnbHeadingPage
        title={blogName}
        isCompactButtons={false}
        listButtons={[
          <FnbButton text={pageData.btnCancel} variant="tertiary" onClick={onCancel} />,
          <FnbAddNewButton
            text={pageData.btnSaveChanges}
            onClick={onSubmitForm}
            permission={PermissionKeys.EDIT_BLOG}
          />,
        ]}
      />
      <Form
        form={form}
        name="basic"
        className="edit-blog"
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        onChange={() => {
          if (!blockNavigation) setBlockNavigation(true);
        }}
      >
        <div className="col-input-full-width">
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={16} lg={16} xl={15} xxl={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card className="w-100 fnb-card h-auto">
                  <Row>
                    <Col span={24}>
                      <h4 className="title-group">{pageData.generalInformation.title}</h4>

                      <h4 className="fnb-form-label">
                        {pageData.generalInformation.name.label}
                        <span className="text-danger">*</span>
                      </h4>
                      <Form.Item
                        name={"title"}
                        rules={[
                          {
                            required: pageData.generalInformation.name.required,
                            message: pageData.generalInformation.name.validateMessage,
                          },
                        ]}
                        validateFirst={true}
                      >
                        <FnbInput
                          className="fnb-input"
                          placeholder={pageData.generalInformation.name.placeholder}
                          maxLength={pageData.generalInformation.name.maxLength}
                          id="product-name"
                          onChange={(e) => {
                            if (e.target.value.length <= 255) {
                              setBlogName(e.target.value);
                            }
                          }}
                          allowClear
                          value={blogName}
                          showCount
                        />
                      </Form.Item>

                      <h4 className="fnb-form-label">
                        {pageData.generalInformation.category.label} <span className="text-danger">*</span>
                      </h4>
                      <Form.Item
                        name={"blogCategoryId"}
                        rules={[
                          {
                            required: pageData.generalInformation.category.required,
                            message: pageData.generalInformation.category.blogCategoryValidateMessage,
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          onChange={onChangeOption}
                          className="unit-selector"
                          placeholder={pageData.blogCategory.blogCategoryPlaceholder}
                          allowClear
                          noTranslateOptionName
                          dropdownRender={(menu) => (
                            <>
                              <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={14}>
                                  <FnbInput
                                    className="fnb-input unit-dropdown-input"
                                    allowClear="true"
                                    maxLength={100}
                                    onChange={(e) => {
                                      onNameChange(e);
                                    }}
                                  />
                                  <TextDanger
                                    className="text-error-add-unit"
                                    visible={showCategoryNameValidateMessage}
                                    text={pageData.blogCategory.blogCategoryNameValidateMessage}
                                  />
                                  <TextDanger
                                    className="text-error-add-unit"
                                    visible={isCategoryNameExisted}
                                    text={pageData.blogCategory.blogCategoryExisted}
                                  />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={10}>
                                  <FnbAddNewButton
                                    onClick={() => onAddNewCategory()}
                                    className="mt-16 ml-24 mw-0"
                                    type="primary"
                                    text={pageData.btnAddNew}
                                  ></FnbAddNewButton>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <div className={showCategoryNameValidateMessage ? "mt-10" : "mt-32"}>{menu}</div>
                                </Col>
                              </Row>
                            </>
                          )}
                          option={categories?.map((item) => ({
                            id: item.id,
                            name: item.name,
                          }))}
                        ></FnbSelectSingle>
                      </Form.Item>

                      <h4 className="fnb-form-label">
                        {pageData.generalInformation.blogContent.label} <span className="text-danger">*</span>
                      </h4>
                      <Form.Item
                        name={"blogContent"}
                        rules={[
                          {
                            required: showBlogContentValidateMessage,
                            message: pageData.generalInformation.blogContent.validateMessage,
                          },
                        ]}
                        validateFirst={true}
                      >
                        <FnbFroalaEditor
                          value={blogContent}
                          onChange={(value) => {
                            if (checkBlogContentLoaded) setIsChangeForm(true);
                            setBlogContent(value);
                            if (value.length > 0) {
                              setShowBlogContentValidateMessage(false);
                            } else {
                              setShowBlogContentValidateMessage(true);
                            }
                          }}
                          charCounterMax={-1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <br />
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card className="w-100 mt-1 fnb-card h-auto">
                  <Row>
                    <Col span={24}>
                      <h4 className="title-group">{pageData.SEO.title}</h4>
                      <h4 className="fnb-form-label-seo mt-3">
                        {pageData.SEO.SEOPreview}
                        <FnbTooltip
                          placement="topLeft"
                          title={() => {
                            return (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: pageData.SEO.SEOOverviewTooltip,
                                }}
                              ></span>
                            );
                          }}
                          className=" material-edit-cost-per-unit-tool-tip"
                        >
                          <span>
                            <ExclamationIcon width={20} height={20} />
                          </span>
                        </FnbTooltip>
                      </h4>
                      <div className="edit-blog-overview">
                        <a
                          style={{ fontSize: "16px" }}
                          href={`${reduxState?.session?.informationPublishStore?.domainName}/blog/${
                            !urlLink ? convertSeoUrl(blogName) : convertSeoUrl(urlLink)
                          }`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${reduxState?.session?.informationPublishStore?.domainName}/blog/${
                            !urlLink ? convertSeoUrl(blogName) : convertSeoUrl(urlLink)
                          }`}
                        </a>
                        <br />
                        <FnbTypography
                          variant={theme.typography["b1-regular"]}
                          text={`<meta name="title" property="title" content="${
                            !titleSEO ? "SEO on Title" : titleSEO
                          }">`}
                        />
                        <br />
                        <FnbTypography
                          variant={theme.typography["b1-regular"]}
                          text={`<meta name="description" property="description" content="${
                            !descriptionSEO ? "SEO on Description" : descriptionSEO
                          }">`}
                        />
                        <br />
                        <FnbTypography
                          variant={theme.typography["b1-regular"]}
                          text={`<meta name="keywords" property="keywords" content="${
                            tags.length > 0 ? tags.map((x) => x.name).join(",") : "SEO on Keywords"
                          }">`}
                        />
                      </div>
                      <h4 className="fnb-form-label-seo mt-3">
                        {pageData.SEO.SEOUrlLink}
                        <FnbTooltip
                          placement="topLeft"
                          title={() => {
                            return (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: pageData.SEO.SEOUrlLinkTooltip,
                                }}
                              ></span>
                            );
                          }}
                          className=" material-edit-cost-per-unit-tool-tip"
                        >
                          <span>
                            <ExclamationIcon width={20} height={20} />
                          </span>
                        </FnbTooltip>
                      </h4>
                      <Form.Item name={"UrlSEO"}>
                        <FnbInput
                          className="fnb-input-with-count"
                          placeholder={pageData.SEO.SEOUrlLinkPlaceholder}
                          maxLength={2048}
                          onChange={(e) => setUrlSEO(e.target.value)}
                          showCount
                        />
                      </Form.Item>

                      <h4 className="fnb-form-label-seo">
                        {pageData.SEO.SEOTitle}
                        <FnbTooltip
                          placement="topLeft"
                          title={() => {
                            return (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: pageData.SEO.SEOTitleTooltip,
                                }}
                              ></span>
                            );
                          }}
                          className="material-edit-cost-per-unit-tool-tip"
                        >
                          <span>
                            <ExclamationIcon width={20} height={20} />
                          </span>
                        </FnbTooltip>
                      </h4>
                      <Form.Item name={"TitleSEO"}>
                        <FnbInput
                          className="fnb-input-with-count"
                          placeholder={pageData.SEO.SEOTitlePlaceholder}
                          maxLength={100}
                          onChange={(e) => {
                            setIsChangeForm(true);
                            e.target.value.length < 50 || e.target.value.length > 60
                              ? setIsShowWarningSEOTitle(true)
                              : setIsShowWarningSEOTitle(false);
                            setTitleSEO(e.target.value);
                          }}
                          value={titleSEO}
                          showCount
                        />
                        <div hidden={!isShowWarningSEOTitle} className="seo-warning-message">
                          <div className="icon-warning">
                            <WarningIcon width={16} height={16} />
                          </div>
                          <div className="text-warning">
                            <span>{pageData.messageMatchSuggestSEOTitle}</span>
                          </div>
                        </div>
                      </Form.Item>

                      <h4 className="fnb-form-label-seo">
                        {pageData.SEO.SEODescription}
                        <FnbTooltip
                          placement="topLeft"
                          title={() => {
                            return (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: pageData.SEO.SEODescriptionTooltip,
                                }}
                              ></span>
                            );
                          }}
                          className=" material-edit-cost-per-unit-tool-tip"
                        >
                          <span>
                            <ExclamationIcon width={20} height={20} />
                          </span>
                        </FnbTooltip>
                      </h4>
                      <Form.Item name={"DescriptionSEO"}>
                        <FnbInput
                          className="fnb-input-with-count"
                          placeholder={pageData.SEO.SEODescriptionPlaceholder}
                          maxLength={255}
                          onChange={(e) => {
                            setIsChangeForm(true);
                            e.target.value.length < 155 || e.target.value.length > 160
                              ? setIsShowWarningSEODescription(true)
                              : setIsShowWarningSEODescription(false);
                            setDescriptionSEO(e.target.value);
                          }}
                          value={descriptionSEO}
                          showCount
                        />
                        <div hidden={!isShowWarningSEODescription} className="seo-warning-message">
                          <div className="icon-warning">
                            <WarningIcon width={16} height={16} />
                          </div>
                          <div className="text-warning">
                            <span>{pageData.messageMatchSuggestSEODescription}</span>
                          </div>
                        </div>
                      </Form.Item>

                      <h4 className="fnb-form-label-seo">
                        {pageData.SEO.SEOKeywords}
                        <FnbTooltip
                          placement="topLeft"
                          title={() => {
                            return (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: pageData.SEO.SEOKeywordsTooltip,
                                }}
                              ></span>
                            );
                          }}
                          className=" material-edit-cost-per-unit-tool-tip"
                        >
                          <span>
                            <ExclamationIcon width={20} height={20} />
                          </span>
                        </FnbTooltip>
                      </h4>
                      <Form.Item>
                        <SelectBlogTagComponent
                          tagDataTemp={tagDataTemp}
                          tags={tags}
                          setTags={setTags}
                          setTagError={setTagError}
                          setIsChangeForm={setIsChangeForm}
                        />
                        <span hidden={!tagError} className="customer-tag-error-message">
                          {pageData.limitTagMessage}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <br />
              </Col>
            </Col>

            {/* <Col xs={24} sm={24} md={16} lg={16} xl={15} xxl={16}> */}

            <Col xs={24} sm={24} md={8} lg={8} xl={9} xxl={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Card className="w-100 fnb-card h-auto">
                      <h4 className="title-group">{pageData.media.title}</h4>
                      <h4 className="fnb-form-label">{pageData.media.bannerTitle}</h4>
                      <Row className={`non-image ${image !== null ? "have-image" : ""}`}>
                        <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
                          <Form.Item name={"banner"}>
                            <FnbCustomUploadImageComponent
                              ref={fnbImageSelectRef}
                              iconUpload={<UploadLogoIcon />}
                              buttonText={pageData.generalInformation.uploadImage}
                              onChange={onChangeImage}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <FnbCard className="mt-4">
                  <Row gutter={[24, 24]}>
                    <Col sm={24} lg={24} className="w-100">
                      <Row className="mb-1 mt-3 edit-blog-overview-odd">
                        <Col span={24}>
                          <div className="left-column">{pageData.createdBy}</div>
                          <div className="right-column">
                            <div className="fnb-form-label-right">
                              {blog?.createdUserName ? blog?.createdUserName : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="edit-blog-overview-even">
                        <Col span={24}>
                          <div className="left-column">{pageData.createdTime}</div>
                          <div className="right-column">
                            <div className="fnb-form-label-right">
                              {blog?.createdTime
                                ? formatShortDate(formatDate(blog?.createdTime, DateFormat.YYYY_MM_DD))
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-1 mt-3 edit-blog-overview-odd">
                        <Col span={24}>
                          <div className="left-column">{pageData.updateTime}</div>
                          <div className="right-column">
                            <div className="fnb-form-label-right">
                              {blog?.lastSavedTime
                                ? formatShortDate(formatDate(blog?.lastSavedTime, DateFormat.YYYY_MM_DD))
                                : "-"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="edit-blog-overview-even">
                        <Col span={24}>
                          <div className="left-column">{pageData.view}</div>
                          <div className="right-column">
                            <div className="fnb-form-label-right">{formatNumber(blog?.totalView)}</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </FnbCard>
              </Col>
            </Col>
          </Row>
        </div>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onCompleted}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
