import { Form, message, Radio, Space } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import enumTaxType from "constants/taxType.constants";
import taxDataService from "data-services/tax/tax-data.service";
import { useEffect } from "react";
import theme from "theme";
import { getValidationMessages } from "utils/helpers";
import { FooterButtonsCreateTax } from "./create-tax.styled";

export default function CreateNewTax({
  width = 520,
  t,
  titleCustom = null,
  handleCompleted,
  handleCancel,
  name,
  isModalVisible,
  percentageField = { prefix: "%", suffix: null },
  typeField = {
    onlyShowPrefixType: false, // not show radio buttons
    typeId: null,
  },
  buttonConfirm = {
    label: "button.addNew",
    width: "fit-content",
    textTransform: "",
  },
  buttonCancel = {
    width: "fit-content",
  },
  disabledImportedTax = false,
}) {
  const [form] = Form.useForm();
  const pageData = {
    addNew: t("button.addNew"),
    cancel: t("button.cancel"),
    addNewTax: t("feeAndTax.tax.addNewTax"),
    name: t("feeAndTax.tax.name"),
    value: t("feeAndTax.tax.value"),
    type: t("feeAndTax.tax.type"),
    description: t("feeAndTax.tax.description"),
    taxAddedSuccessfully: t("feeAndTax.tax.taxAddedSuccessfully"),
    pleaseEnterTaxName: t("feeAndTax.tax.pleaseEnterTaxName"),
    enterTaxName: t("feeAndTax.tax.enterTaxName"),
    pleaseEnterValue: t("feeAndTax.tax.pleaseEnterValue"),
    enterValue: t("feeAndTax.tax.enterValue"),
    sellingTax: t("feeAndTax.tax.sellingTax"),
    importTax: t("feeAndTax.tax.importTax"),
    percentValidation: t("feeAndTax.tax.percentValidation"),
    enterOnlyNumbers: t("feeAndTax.tax.pleaseEnterOnlyNumbers"),
    percentPattern: "^(100|[1-9][0-9]?)$",
  };

  useEffect(() => {
    form.setFieldsValue({
      tax: {
        taxTypeId: 0,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (name) {
      form.setFieldsValue({
        tax: {
          name: name,
          taxTypeId: 0,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!isModalVisible) {
      setTimeout(() => {
        form.resetFields();

        form.setFieldsValue({
          tax: {
            taxTypeId: 0,
          },
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const onCancel = async () => {
    await form.resetFields();
    handleCancel();
  };

  const onFinish = async (values) => {
    const payload = {
      ...values.tax,
      taxTypeId: typeField?.onlyShowPrefixType ? typeField.typeId : values.tax?.taxTypeId,
    };

    await taxDataService
      .createTaxAsync(payload)
      .then((res) => {
        if (res) {
          message.success(pageData.taxAddedSuccessfully);
          handleCompleted(res, values.tax);
        }
      })
      .catch((errs) => {
        const errors = getValidationMessages(errs)?.map((err) => ({
          ...err,
          name: ["tax", err.name],
        }));

        form.setFields(errors);
      });
  };

  const onSubmitForm = () => {
    form.submit();
  };
  const renderContentModal = () => {
    return (
      <Form autoComplete="off" form={form} name="basic" onFinish={onFinish} className="custom-form" layout="vertical">
        <Space direction="vertical" size={[0, 16]} className="w-100">
          <Form.Item
            name={["tax", "name"]}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseEnterTaxName} />,
              },
            ]}
            label={pageData.name}
          >
            <FnbInput placeholder={pageData.enterTaxName} autoComplete="none" />
          </Form.Item>
          <Form.Item
            name={["tax", "percentage"]}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseEnterValue} />,
              },
              {
                pattern: new RegExp(pageData.percentPattern),
                message: <InputValidateMessage message={pageData.percentValidation} />,
              },
            ]}
            label={pageData.value}
          >
            <FnbInput
              className="w-100"
              prefix={percentageField.prefix}
              suffix={percentageField.suffix}
              min={1}
              max={100}
              maxLength={3}
              placeholder={pageData.pleaseEnterValue}
              autoComplete="none"
            />
          </Form.Item>
          {typeField.onlyShowPrefixType ? (
            <Space size={8}>
              <FnbTypography
                text={pageData.type + ":"}
                variant={theme.typography["b1-medium"]}
                color={theme.colors.gray[70]}
              />
              <FnbTypography
                text={typeField.typeId === enumTaxType.SellingTax ? pageData.sellingTax : pageData.importTax}
                variant={theme.typography["b1-medium"]}
              />
            </Space>
          ) : (
            <Form.Item label={pageData.type} name={["tax", "taxTypeId"]}>
              <Radio.Group>
                <Space direction="horizontal">
                  <Radio value={enumTaxType.SellingTax}>{pageData.sellingTax}</Radio>
                  <Radio value={enumTaxType.ImportedTax} disabled={disabledImportedTax}>
                    {pageData.importTax}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name={["tax", "description"]} label={pageData.description}>
            <FnbTextArea rows={4} placeholder={pageData.description} maxLength={255} />
          </Form.Item>
        </Space>
      </Form>
    );
  };
  return (
    <FnbModal
      title={pageData.addNewTax}
      titleCustom={titleCustom}
      visible={isModalVisible}
      footer={
        <FooterButtonsCreateTax>
          <FnbButton
            variant="tertiary"
            text={pageData.cancel}
            onClick={onCancel}
            minWidth={"120px"}
            width={buttonCancel.width}
          />
          <FnbButton
            text={t(buttonConfirm.label)}
            onClick={onSubmitForm}
            minWidth={"120px"}
            width={buttonConfirm.width}
            textTransform={buttonConfirm.textTransform}
          />
        </FooterButtonsCreateTax>
      }
      content={renderContentModal()}
      closable={false}
      handleCancel={onCancel}
      width={width}
    />
  );
}
