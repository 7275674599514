import { Col, Row, Space } from "antd";
import POSDevice from "assets/images/pos-device.png";
import AppleLogo from "assets/images/apple-logo.png";
import AndroidLogo from "assets/images/android-logo.png";
import WindowPlatform from "assets/images/window-platform.png";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import theme from "theme";
import { isStoreInternational } from "utils/helpers";
import CardPlatform from "./components/card-platform";
import { FlexColumn, FlexRow, LIItem, LIItemLink, ULContent } from "./styled";
import { useEffect, useState } from "react";
import { EnvironmentConstants } from "constants/string.constants";
import "./style.scss";
import { Hr } from "pages/product/components/dialogHowToSetUpProductRecipe";

const emailAddress = "support@gofnb.biz";
const VERSION_REQUIRED_IOS = "12.4";
const VERSION_REQUIRED_ANDROID = "6.0";
const VERSION_REQUIRED_CHROME = "97";
const LINK_POS_WEB_VN = {
  [EnvironmentConstants.QA]: "https://qa-pos.beecowdeal.vn",
  [EnvironmentConstants.STAGING]: "https://stag-pos.beecowdeal.vn",
  [EnvironmentConstants.PRODUCTION]: "https://fnb.pos.gosell.vn",
};
const LINK_POS_WEB_INTERNATIONAL = {
  [EnvironmentConstants.QA]: "https://qa-pos.beecowdeal.com",
  [EnvironmentConstants.STAGING]: "https://stag-pos.beecowdeal.com",
  [EnvironmentConstants.PRODUCTION]: "https://pos.gofnb.biz",
};

const LINK_POS_APP_IOS = "https://apps.apple.com/vn/app/gof-b-pos-app/id6479501603";
const LINK_POS_APP_ANDROID = "https://play.google.com/store/apps/details?id=com.gofnb.posapplication";

const HowToUsePos = () => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isInternational = isStoreInternational();
  const [t] = useTranslation();
  const [environment, setEnvironment] = useState();

  useEffect(() => {
    const hostName = window.location.hostname;
    if (hostName?.startsWith("qa")) setEnvironment(EnvironmentConstants.QA);
    else if (hostName?.startsWith("stag")) setEnvironment(EnvironmentConstants.STAGING);
    else setEnvironment(EnvironmentConstants.PRODUCTION);
  }, []);

  const pageData = {
    weSupportFor: t("howToUsePos.weSupportFor"),
    title: t("howToUsePos.title"),
    posOnWebsite: {
      title: t("howToUsePos.posOnWebsite.title"),
      access: t("howToUsePos.posOnWebsite.access"),
      contents: t("howToUsePos.posOnWebsite.contents", "[]"),
    },
    posAppOnAndroid: {
      title: t("howToUsePos.posAppOnAndroid.title"),
      goToLinkOrScan: t("howToUsePos.posAppOnAndroid.goToLinkOrScan", "[]"),
      contents: t("howToUsePos.posAppOnAndroid.contents", "[]"),
    },
    posAppOnIos: {
      title: t("howToUsePos.posAppOnIos.title"),
      goToLinkOrScan: t("howToUsePos.posAppOnIos.goToLinkOrScan", "[]"),
      contents: t("howToUsePos.posAppOnIos.contents", "[]"),
    },
    windowApp: {
      title: t("howToUsePos.windowApp.title"),
      content: t("howToUsePos.windowApp.content"),
    },
    support: {
      title: t("howToUsePos.support.title"),
      content: t("howToUsePos.support.contents"),
      email: t("howToUsePos.support.email"),
    },
    learnAboutFeatures: t("howToUsePos.learnAboutFeatures", "Learn more about Features"),
  };
  const posWeb = {
    title: pageData.posOnWebsite.title,
    thumbnail: {
      src: POSDevice,
      width: 110,
      height: 82,
    },
    content: (
      <FlexColumn rowGap={8}>
        <FlexRow columnGap={6}>
          <FnbTypography color="#000" text={`${pageData.posOnWebsite.access}:`} />
          <FnbTypography.Link
            text="GoF&B POS"
            variant={theme.typography["b1-bold"]}
            underline
            target="_blank"
            to={isInternational ? LINK_POS_WEB_INTERNATIONAL[environment] : LINK_POS_WEB_VN[environment]}
          />
        </FlexRow>
        <FnbTypography color="#000" text={pageData.weSupportFor} />
        <ULContent>
          {JSON.parse(pageData.posOnWebsite.contents.replace("$version$", VERSION_REQUIRED_CHROME))?.map((item) => {
            return (
              <li>
                <FnbTypography color="#000" text={item} />
              </li>
            );
          })}
        </ULContent>
      </FlexColumn>
    ),
  };

  const posAppAndroid = {
    title: pageData.posAppOnAndroid.title,
    qrCode: {
      link: LINK_POS_APP_ANDROID,
      logo: AndroidLogo,
      logoHeight: 22,
      logoWidth: 22,
      logoPadding: 4,
    },
    content: (
      <FlexColumn rowGap={8}>
        <FlexRow columnGap={6} wrap={true}>
          {JSON.parse(pageData.posAppOnAndroid.goToLinkOrScan)?.map((item) => {
            return (
              <>
                {item === "$link$" ? (
                  <FnbTypography.Link
                    text="GoF&B"
                    variant={theme.typography["b1-bold"]}
                    underline
                    to={LINK_POS_APP_ANDROID}
                    target="_blank"
                  />
                ) : (
                  <FnbTypography color="#000" text={item} />
                )}
              </>
            );
          })}
        </FlexRow>
        <FnbTypography color="#000" text={pageData.weSupportFor} />
        <ULContent>
          <LIItem>
            {"Sunmi T2 ("}
            <LIItemLink href="https://www.sunmi.com/en-US/t2" target="_blank">
              {"https://www.sunmi.com/en-US/t2"}
            </LIItemLink>
            {")"}
          </LIItem>
          {JSON.parse(pageData.posAppOnAndroid.contents.replace("$version$", VERSION_REQUIRED_ANDROID))?.map((item) => {
            return (
              <li>
                <FnbTypography color="#000" text={item} />
              </li>
            );
          })}
        </ULContent>
      </FlexColumn>
    ),
  };
  const posAppIOS = {
    title: pageData.posAppOnIos.title,
    qrCode: {
      link: LINK_POS_APP_IOS,
      logo: AppleLogo,
      logoHeight: 22,
      logoWidth: 22,
      logoPadding: 4,
    },
    content: (
      <FlexColumn rowGap={8}>
        <FlexRow columnGap={6} wrap={true}>
          {JSON.parse(pageData.posAppOnIos.goToLinkOrScan)?.map((item) => {
            return (
              <>
                {item === "$link$" ? (
                  <FnbTypography.Link
                    text="GoF&B"
                    variant={theme.typography["b1-bold"]}
                    underline
                    to={LINK_POS_APP_IOS}
                    target="_blank"
                  />
                ) : (
                  <FnbTypography color="#000" text={item} />
                )}
              </>
            );
          })}
        </FlexRow>
        <FnbTypography color="#000" text={pageData.weSupportFor} />
        <ULContent>
          {JSON.parse(pageData.posAppOnIos.contents.replace("$version$", VERSION_REQUIRED_IOS))?.map((item) => {
            return (
              <li>
                <FnbTypography color="#000" text={item} />
              </li>
            );
          })}
        </ULContent>
      </FlexColumn>
    ),
  };

  const windowApp = {
    title: pageData.windowApp.title,
    thumbnail: {
      src: WindowPlatform,
      width: 110,
      height: 110,
    },
    content: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <FnbTypography color="#000" text={pageData.windowApp.content} />
      </div>
    ),
    isComingSoon: true,
  };

  const support = {
    title: pageData.support.title,
    content: (
      <FlexColumn className="support-description">
        <FnbTypography color="#000" text={pageData.support.content} />
        <span className="email">
          <FnbTypography variant={theme.typography["b1-bold"]} text={"Email"} textDecoration={"underline"} />
          <FnbTypography variant={theme.typography["b1-bold"]} text={": "} />
          <FnbTypography.Link to={`mailto:${emailAddress}`} target={"_blank"} color={"#2886ca"} text={emailAddress} />
        </span>
      </FlexColumn>
    ),
  };

  return (
    <div className="how-to-use-pos">
      <FnbHeadingPage
        title={pageData.title}
        listButtons={[
          <FnbTypography.Link
            underline
            target="_blank"
            to={isInternational ? "https://gofnb.biz/features" : "https://gofnb.vn/tinh-nang"}
            text={pageData.learnAboutFeatures}
            variant={theme.typography["b1-medium"]}
            style={{ marginRight: 8 }}
          />,
        ]}
      />
      <Space direction="vertical" size={20} style={{ display: "flex" }}>
        <Row gutter={[16, 16]} type="flex">
          <Col xs={24} sm={12} style={{ display: "flex", width: "100%" }}>
            <CardPlatform {...posWeb} />
          </Col>
          <Col xs={24} sm={12} style={{ display: "flex", width: "100%" }}>
            {isMobile ? <CardPlatform {...windowApp} /> : <CardPlatform {...posAppAndroid} />}
          </Col>
          <Col xs={24} sm={12} style={{ display: "flex", width: "100%" }}>
            {isMobile ? <CardPlatform {...posAppAndroid} /> : <CardPlatform {...windowApp} />}
          </Col>
          <Col xs={24} sm={12} style={{ display: "flex", width: "100%" }}>
            <CardPlatform {...posAppIOS} />
          </Col>
        </Row>
        <Hr margin={"0 0"} />
        <Row gutter={[16, 16]} type="flex">
          <Col xs={24} sm={12} style={{ display: "flex", width: "100%" }}>
            <CardPlatform {...support} className={"card-support"} />
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default HowToUsePos;
