import { FnbButton } from "components/fnb-button/fnb-button";
import TabFeaturePackage from "components/subscription/tab-feature-package.component";
import { LeftArrowOutlined } from "constants/icons.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PackageSection from "./package-section.component";
import { PageStepSelectPackage, RightContent } from "./styled";

const StepSelectPackage = ({
  packages,
  periodPackageDurations,
  mainGroupFunctions,
  periodSelected,
  setPeriodSelected,
  defaultHoverPackageId,
  setDefaultHoverPackageId,
  onClickBuyPackage,
  className,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <PageStepSelectPackage className={className}>
      <FnbButton
        text={t("button.back")}
        iconHeader={<LeftArrowOutlined />}
        onClick={() => {
          history.goBack();
        }}
        variant="tertiary"
      />
      <RightContent>
        <PackageSection
          packages={packages}
          periodPackageDurations={periodPackageDurations}
          mainGroupFunctions={mainGroupFunctions}
          periodSelected={periodSelected}
          setPeriodSelected={setPeriodSelected}
          defaultHoverPackageId={defaultHoverPackageId}
          setDefaultHoverPackageId={setDefaultHoverPackageId}
          onClickBuyPackage={onClickBuyPackage}
        />
        <TabFeaturePackage t={t} mainGroupFunctions={mainGroupFunctions} />
      </RightContent>
    </PageStepSelectPackage>
  );
};

export default StepSelectPackage;
