import { Card, Col, Collapse, Form, Input, Layout, message, Row, Space } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { GroupPermissionKeys } from "constants/group-permission-key.constatns";
import { ArrowActivePanelIcon, ArrowDeActivePanelIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import permissionDataService from "data-services/permission/permission-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { capitalize, sentenceToCamelCase, sentenceToCamelCaseGroup } from "utils/index";
const { Content } = Layout;
const { TextArea } = Input;
const { Panel } = Collapse;

export default function EditGroupPermission(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const pageData = {
    title: t("settings.createGroupPermission.edit"),
    btnCancel: t("button.cancel"),
    btnUpdate: t("button.update"),
    titlePermission: t("settings.createGroupPermission.titlePermission"),
    titleGeneralInformation: t("settings.createGroupPermission.titleGeneralInformation"),
    titleCheckboxAllPermissionGoFB: t("settings.createGroupPermission.titleCheckboxAllPermissionGoFB"),
    lblInputName: t("settings.createGroupPermission.lblInputName"),
    placeholderInputName: t("settings.createGroupPermission.placeholderInputName"),
    lblInputDescription: t("settings.createGroupPermission.lblInputDescription"),
    textFullPermission: t("settings.createGroupPermission.textFullPermission"),
    nameGroupPermissionValidate: t("settings.createGroupPermission.nameGroupPermissionValidate"),
    btnConfirmLeave: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    updateGroupPermissionSuccess: t("messages.updateGroupPermissionSuccess"),
  };

  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  // const [permissionGroupData, setPermissionGroupData] = useState([]);
  // const [isEdit, setIsEdit] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [groupPermissionId, setGroupPermissionId] = useState(false);
  const allowedPermissionIdsOnlineStore = [
    PermissionKeys.VIEW_THEME_STORE.toLowerCase(),
    PermissionKeys.EDIT_THEME.toLowerCase(),
    PermissionKeys.REMOVE_THEME.toLowerCase(),
    PermissionKeys.VIEW_BLOG.toLowerCase(),
    PermissionKeys.CREATE_BLOG.toLowerCase(),
    PermissionKeys.EDIT_BLOG.toLowerCase(),
    PermissionKeys.DELETE_BLOG.toLowerCase(),
    PermissionKeys.VIEW_MENU_MANAGEMENT.toLowerCase(),
    PermissionKeys.CREATE_MENU_MANAGEMENT.toLowerCase(),
    PermissionKeys.EDIT_MENU_MANAGEMENT.toLowerCase(),
    PermissionKeys.DELETE_MENU_MANAGEMENT.toLowerCase(),
    PermissionKeys.VIEW_PAGE.toLowerCase(),
    PermissionKeys.CREATE_PAGE.toLowerCase(),
    PermissionKeys.EDIT_PAGE.toLowerCase(),
    PermissionKeys.DELETE_PAGE.toLowerCase(),
  ];

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    //get list permission group
    const permissionGroupsResponse = await permissionDataService.getPermissionGroupsAsync();
    if (permissionGroupsResponse) {
      getGroupPermissionDetail(permissionGroupsResponse.permissionGroups);
    }
  }

  const getGroupPermissionDetail = async (listPermissionGroup) => {
    const { id } = props?.match?.params || {};
    setGroupPermissionId(id);
    const res = await permissionDataService.getGroupPermissionByIdAsync(id);
    const groupPermissionPermissions = res?.groupPermission?.groupPermissionPermissions;
    if (listPermissionGroup?.length > 0 && groupPermissionPermissions) {
      const permissionsDataRender = mappingPermissionGroups(listPermissionGroup, groupPermissionPermissions);
      let checkFullPermission = permissionsDataRender?.find((p) => p?.checked === false);
      if (checkFullPermission == null || checkFullPermission === undefined) {
        setCheckedAll(true);
      }

      let formValue = form.getFieldsValue();
      formValue.name = res?.groupPermission?.name;
      formValue.description = res?.groupPermission?.description;
      form.setFieldsValue(formValue);

      // setPermissionGroupData(groupPermissionPermissions);
      setPermissions([...permissionsDataRender]);
    }
  };

  const mappingPermissionGroups = (permissionGroups, permissionsDetail) => {
    return permissionGroups?.map((permission) => {
      let children = [];
      if (permission?.children) {
        children = permission?.children?.map((permission) => {
          const childPermissionName = capitalize(permission?.name);
          let permissionItem = permissionsDetail?.find((p) => p?.permissionId === permission?.id);
          let listPermissions = [];
          if (permission?.permissions) {
            listPermissions = permission?.permissions?.map((permissionLv2) => {
              const childPermissionNameLv2 = capitalize(permissionLv2?.name);
              const permissionItemLv2 = permissionsDetail?.find((p) => p?.permissionId === permissionLv2?.id);
              return {
                id: permissionLv2?.id,
                name: childPermissionNameLv2,
                checked: permissionItemLv2 ? true : false,
              };
            });
            permissionItem = listPermissions?.filter((child) => child.checked === false)?.length === 0 ? true : false;
          }
          return {
            id: permission?.id,
            name: childPermissionName,
            checked: permissionItem ? true : false,
            children: listPermissions,
          };
        });
      } else {
        children = permission?.permissions
          ?.filter(
            (x) =>
              x?.permissionGroupId?.toLowerCase() !== PermissionKeys.EDIT_COMBO.toLowerCase() ||
              allowedPermissionIdsOnlineStore?.includes(x.id),
          )
          .map((permission) => {
            const childPermissionName = capitalize(permission?.name);
            const permissionItem = permissionsDetail?.find((p) => p?.permissionId === permission?.id);
            return {
              id: permission?.id,
              name: childPermissionName,
              checked: permissionItem ? true : false,
            };
          });
      }

      const permissionName = capitalize(permission?.name);
      const checked = children?.filter((child) => child.checked === false)?.length === 0 ? true : false;
      return {
        id: permission?.id,
        name: permissionName,
        checkboxName: `${pageData.textFullPermission} ${permissionName}`,
        checked: checked,
        children: children,
      };
    });
  };

  const onClickSavePermission = () => {
    form.validateFields().then((values) => {
      const updatePermissionGroup = {
        permissionName: values?.name,
        description: values?.description,
        permissions: permissions,
      };
      onUpdateGroupPermission(updatePermissionGroup);
    });
  };

  const onUpdateGroupPermission = async (values) => {
    let permissions = [];
    values.permissions.forEach((permission) => {
      let childPermissions = permission.children.filter((p) => p.checked);
      permission?.children?.forEach((child) => {
        if (child?.children) {
          childPermissions = child?.children?.filter((p) => p?.checked);
          if (childPermissions) permissions = [...permissions, ...childPermissions];
        }
      });
      if (childPermissions) permissions = [...permissions, ...childPermissions];
    });

    var langCode = localStorage.getItem("i18nextLng");
    const createGroupPermissionRequestModel = {
      groupPermissionId: groupPermissionId,
      groupPermissionName: values.permissionName,
      description: values.description,
      permissionIds: permissions.map((p) => p.id),
      langCode: langCode,
    };

    const res = await permissionDataService.updateGroupPermissionByIdAsync(createGroupPermissionRequestModel);

    if (res) {
      message.success(pageData.updateGroupPermissionSuccess);
      onCompleted();
    }
  };

  /// Check all permission gof&b
  const isCheckedAll = () => {
    let anyUnchecked = permissions.some(
      (permission) => permission.checked === false || permission.children.some((child) => child.checked === false),
    );

    return anyUnchecked ? false : true;
  };

  const handleShowHideReview = () => {
    const tempPermissions = [...permissions];
    const reviewGroupPermissionIndex = tempPermissions?.findIndex(
      (tempPermission) => tempPermission.id === GroupPermissionKeys.REVIEW.toLocaleLowerCase(),
    );
    const reviewGroupPermission = tempPermissions[reviewGroupPermissionIndex];
    if (reviewGroupPermission) {
      reviewGroupPermission.children = reviewGroupPermission.children.map((child) => {
        if (child.id === PermissionKeys.VIEW_REVIEW.toLowerCase()) {
          return { ...child, checked: true };
        }
        return child;
      });
    }
  };

  /// Permission checkbox
  const onChangeCheckbox = (e, child, parent) => {
    let parentPermission = permissions?.find((permission) => permission?.id === parent?.id);
    let childPermission = parentPermission?.children?.find((permission) => permission?.id === child?.id);

    if (childPermission.id == PermissionKeys.SHOW_HIDE_REVIEW.toLowerCase()) {
      handleShowHideReview();
    }

    if (childPermission) {
      let checked = e?.target?.checked;
      childPermission.checked = checked;
    }

    /// Check parent permission
    let countChecked = parentPermission?.children?.filter((child) => child?.checked === false)?.length;
    if (countChecked > 0) {
      parentPermission.checked = false;
    }
    if (countChecked === 0) {
      parentPermission.checked = true;
    }

    isCheckedAll() ? setCheckedAll(true) : setCheckedAll(false);
    setPermissions([...permissions]);
  };

  /// Permission checkbox Children
  const onChangeCheckboxChildren = (e, child, parent, childrenId) => {
    let parentPermission = permissions?.find((permission) => permission?.id === parent?.id);
    let childPermission = parentPermission?.children?.find((permission) => permission?.id === childrenId);

    let childPermissionLv2 = childPermission?.children?.find((permission) => permission?.id === child?.id);
    if (childPermissionLv2) {
      let checked = e?.target?.checked;
      childPermissionLv2.checked = checked;
    }

    /// Check child permission
    let countChildChecked = childPermission?.children?.filter((child) => child?.checked === false)?.length;
    if (countChildChecked > 0) {
      childPermission.checked = false;
    }
    if (countChildChecked === 0) {
      childPermission.checked = true;
    }
    /// Check parent permission
    let countChecked = parentPermission?.children?.filter((child) => child?.checked === false)?.length;
    if (countChecked > 0) {
      parentPermission.checked = false;
    }
    if (countChecked === 0) {
      parentPermission.checked = true;
    }

    isCheckedAll() ? setCheckedAll(true) : setCheckedAll(false);
    setPermissions([...permissions]);
  };

  /// Full permissions
  const onChangeCheckboxFullPermissions = (e, id) => {
    const checked = e.target.checked;
    let permission = permissions.find((permission) => permission.id === id);
    if (permission) {
      permission.checked = checked;
      let children = permission.children;
      children.forEach((element) => {
        element.checked = checked;
        if (element?.children) {
          element?.children.forEach((child) => {
            child.checked = checked;
          });
        }
      });
    }

    isCheckedAll() ? setCheckedAll(true) : setCheckedAll(false);
    setPermissions([...permissions]);
  };

  /// Full permission on GoF&B
  const onChangeCheckAllPermissions = (e) => {
    let checked = e.target.checked;
    permissions.forEach((permission) => {
      permission.checked = checked;
      permission.children.forEach((child) => {
        child.checked = checked;
        if (child?.children) {
          child?.children.forEach((childLv2) => {
            childLv2.checked = checked;
          });
        }
      });
    });

    isCheckedAll() ? setCheckedAll(true) : setCheckedAll(false);
    setPermissions([...permissions]);
  };

  const renderPermissionCardHeader = (title, checkboxTitle, checked, onChangeCheckboxFullPermissions) => {
    const permissionGroupTranslateKey = sentenceToCamelCase(title);
    const fullPermissionGroupTranslateKey = sentenceToCamelCaseGroup(title);
    return (
      <Row className="permission-header" gutter={[12, 4]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="float-left">
            <div className="body-1">{t(`permissionsGroup.${permissionGroupTranslateKey}`)}</div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="permission-header__full-permission">
          <div className="float-right">
            <FnbCheckBox checked={checked} onChange={onChangeCheckboxFullPermissions}>
              <div className="body-2">{t(`permissionsGroup.fullPermission${fullPermissionGroupTranslateKey}`)}</div>
            </FnbCheckBox>
          </div>
        </Col>
      </Row>
    );
  };

  const renderPermissionCard = (permission, key) => {
    // const countPermissionItem = permission.children.length;
    // let countPermissionEdit = 0;
    const renderCardBody = permission.children.map((child, index) => {
      // const countItem = permissionGroupData?.find((p) => p.permissionId === child.id);
      const permissionTranslateKey = sentenceToCamelCase(child.name);
      // if (countItem) {
      //   countPermissionEdit = countPermissionEdit + 1;
      // }
      if (child?.children) {
        return (
          <>
            <Row className="group-permission-name">{t(`permissionsGroup.${permissionTranslateKey}`)}</Row>
            <Row className="w-100">
              {child?.children?.map((childLv2, i) => {
                const permissionChildrenTranslateKey = sentenceToCamelCase(childLv2.name);
                return (
                  <Col key={index} xs={24} sm={24} md={12} lg={6} className="mt-2 mb-2">
                    <FnbCheckBox
                      key={i}
                      checked={childLv2.checked || false}
                      onChange={(e) => onChangeCheckboxChildren(e, childLv2, permission, child?.id)}
                    >
                      <div className="body-2">{t(`permissions.${permissionChildrenTranslateKey}`)}</div>
                    </FnbCheckBox>
                  </Col>
                );
              })}
            </Row>
          </>
        );
      }

      return (
        <Col key={index} xs={24} sm={24} md={12} lg={6} className="mt-2 mb-2">
          <FnbCheckBox key={index} checked={child.checked} onChange={(e) => onChangeCheckbox(e, child, permission)}>
            <div className="body-2">{t(`permissions.${permissionTranslateKey}`)}</div>
          </FnbCheckBox>
        </Col>
      );
    });
    // if (!isEdit) {
    //   if (countPermissionItem === countPermissionEdit) {
    //     permission.checked = true;
    //   } else {
    //     permission.checked = false;
    //   }
    //   setIsEdit(true);
    // }

    return (
      <Collapse
        key={key}
        collapsible="header"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (isActive ? <ArrowActivePanelIcon /> : <ArrowDeActivePanelIcon />)}
        className="permission-collapse-header"
      >
        <Panel
          key={permission.id}
          className="card-collapse-header card-collapse-header-staff-text"
          header={renderPermissionCardHeader(permission.name, permission.checkboxName, permission.checked, (e) =>
            onChangeCheckboxFullPermissions(e, permission.id),
          )}
        >
          <Row>{renderCardBody}</Row>
        </Panel>
      </Collapse>
    );
  };

  const onLeavePage = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      onCompleted();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const onCompleted = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/staff", { tabPermissionGroup: 2 });
    }, 100);
  };

  return (
    <>
      <div className="clearfix"></div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        className="new-permission-group-format"
        onFieldsChange={(e) => changeForm(e)}
      >
        <FnbHeadingPage
          title={pageData.title}
          listButtons={[
            <FnbButton variant="tertiary" text={pageData.btnCancel} onClick={() => onLeavePage()} />,
            <FnbButton
              type="submit"
              text={pageData.btnUpdate}
              onClick={() => onClickSavePermission()}
              permission={PermissionKeys.ADMIN}
              minWidth={100}
            />,
          ]}
        />
        <Content>
          <Card className="w-100 card-body-bottom-0" style={{ background: "#FFFFFF" }}>
            <h2 style={{ marginBottom: "32px", marginTop: "9px" }}>{pageData.titleGeneralInformation}</h2>
            <Row style={{ marginBottom: "8px" }}>
              <Col span={24}>
                <h4 className="fnb-form-label" style={{ color: "#000000" }}>
                  {pageData.lblInputName}
                  <span className="text-danger">*</span>
                </h4>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: pageData.nameGroupPermissionValidate },
                    { type: "string", warningOnly: true },
                    { type: "string", max: 50, min: 3, message: pageData.nameGroupPermissionValidate },
                  ]}
                >
                  <Input
                    className="fnb-input-with-count"
                    showCount
                    maxLength={50}
                    placeholder={pageData.placeholderInputName}
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: "16px" }}>
                <h4 className="fnb-form-label" style={{ color: "#000000" }}>
                  {pageData.lblInputDescription}
                </h4>
                <Form.Item name="description">
                  <TextArea className="fnb-text-area-with-count no-resize" showCount maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Content>

        <Content style={{ marginTop: "32px" }}>
          <Row>
            <Card className="w-100" style={{ background: "#FFFFFF" }}>
              <h2 style={{ marginTop: "9px", marginBottom: "44px" }}>{pageData.titlePermission}</h2>
              <Row>
                <Col span={24}>
                  <Card>
                    <FnbCheckBox checked={checkedAll} onChange={(e) => onChangeCheckAllPermissions(e)}>
                      <div className="body-1 staff-all-permission-text">{pageData.titleCheckboxAllPermissionGoFB}</div>
                    </FnbCheckBox>
                  </Card>
                </Col>
              </Row>

              <Row style={{ marginBottom: "8px" }}>
                <Col span={24}>
                  <Space direction="vertical" className="w-100">
                    {permissions?.map((permission, index) => {
                      return renderPermissionCard(permission, index);
                    })}
                  </Space>
                </Col>
              </Row>
            </Card>
          </Row>
        </Content>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={onCompleted}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
