import styled from "styled-components";
import { BaseFormInput } from "./forgot-password.styled";

export const InputOTPForm = styled(BaseFormInput)`
  padding: 24px 32px 32px 32px;
  row-gap: 56px;
  margin-top: 100px;

  @media (max-width: 576px) {
    padding: 24px 24px 32px 24px;
    width: 100vw;
    row-gap: 0;
  }
`;

export const Heading = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 4px 0;
  span {
    font-size: 24px;
    font-weight: 400;
    line-height: 30.24px;
    text-align: left;
  }
  @media (max-width: 576px) {
    padding: 0;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 22.68px;
    }
  }
`;

export const InputOTPContainer = styled.div`
  padding: 0 23px;
  .input-otp {
    gap: 18px;
    input {
      border: 1px solid #6d6d6d;
      border-radius: 12px;
      font-size: 18px;
      font-weight: 700;
      line-height: 22.68px;
    }
    .ant-input-status-error {
      border-color: #ff0000 !important;
    }
  }
  .ant-form-item-explain {
    .ant-form-item-explain-error {
      color: #ff0000;
    }
  }
  @media (max-width: 576px) {
    padding: 0;
    margin-top: 56px;
    margin-bottom: 36px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    .input-otp {
      gap: 12px;
      input {
        width: 40px;
        font-size: 12.43px;
        font-weight: 700;
        line-height: 15.66px;
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterNote = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 22.68px;

  @media (max-width: 576px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
  }
`;

export const ResendText = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  color: #5e8dec;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  @media (max-width: 576px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.16px;
  }
`;

export const ResendTextCountDown = styled.span`
  color: #bebebe;
  font-size: 14px;
`;
