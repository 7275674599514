import styled from "styled-components";
import { BaseFormInput } from "./forgot-password.styled";

export const InputNewpasswordForm = styled(BaseFormInput)`
  width: 560px;
  padding: 24px 56px 32px 56px;
  row-gap: 24px;
  @media (max-width: 576px) {
    padding: 16px 16px 20px 16px;
    row-gap: 16px;
  }
`;

export const ListFormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .ant-input-affix-wrapper {
    background: #f8f8f8;
    height: 60px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 22.68px;
    input {
      background: #f8f8f8;
    }
    .ant-input-suffix {
      .anticon {
        transform: rotate(180deg);
      }
    }
  }
  .ant-form-item-explain {
    .ant-form-item-explain-error {
      color: #ff0000;
      font-size: 15px;
      font-weight: 400;
      line-height: 18.9px;
    }
  }
  @media (max-width: 576px) {
    .ant-input-affix-wrapper {
      height: 56px;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.9px;
    }
    .ant-form-item-explain {
      .ant-form-item-explain-error {
        font-size: 13px;
        font-weight: 400;
        line-height: 16.38px;
      }
    }
  }
`;

export const Heading = styled.div`
  padding-bottom: 2px;
  display: flex;
  column-gap: 12px;
  align-items: center;
`;
