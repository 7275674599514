import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";
import { CloseNote } from "../../../../assets/icons.constants";
import NoteIcon from "../../../../assets/icons/note.svg";
import userIcon from "../../../../assets/icons/user.svg";
import userPhoneIcon from "../../../../assets/icons/user_phone.svg";
import { PHONE_NUMBER_REGEX } from "../../../../constants/string.constants";

export default function RecipientInformation(props) {
  const {
    pageData,
    onChangeCustomerName,
    defaultCustomerName,
    onChangePhone,
    readPhoneFromStorage,
    checkOnKeyPressValidation,
    onChangeNote,
    clearNote,
    note,
  } = props;
  const [t] = useTranslation();
  const translateData = {
    recipientInformation: t("checkOutPage.recipientInformation", "Recipient information"),
    receiver: t("myProfile.myOrders.receiver", "Receiver"),
    phoneNumber: t("myProfile.myOrders.phoneNumber", "Phone number"),
    note: t("myProfile.myOrders.note", "Note"),
  };

  return (
    <div className="box-recipient-info">
      <div className="header-recipient-info">
        <span className="header-text">{translateData.recipientInformation}</span>
      </div>
      <Form autoComplete="off" className="recipient-info">
        <Row gutter={16}>
          <Col span={12} className="recipient-info-name">
            <div className="recipient-info-title">
              <img className="recipient-info-icon" src={userIcon} alt="" />
              <span>{translateData.receiver}</span>
            </div>
            <Form.Item
              name="receiverName"
              rules={[
                {
                  required: true,
                  message: pageData.missingCustomerNameMessage,
                },
              ]}
            >
              <Input
                id="txtName"
                className="check_out_name"
                maxLength={200}
                autoFocus={true}
                allowClear={false}
                placeholder={pageData.placeHolderName}
                onChange={onChangeCustomerName}
                defaultValue={defaultCustomerName()}
              />
            </Form.Item>
          </Col>
          <Col span={12} className="recipient-info-phone">
            <div className="recipient-info-title">
              <img className="recipient-info-icon" src={userPhoneIcon} alt="" />
              <span>{translateData.phoneNumber}</span>
            </div>
            <Form.Item
              name="receiverPhone"
              rules={[
                {
                  required: true,
                  message: pageData.missingPhoneMessage,
                },
                {
                  pattern: PHONE_NUMBER_REGEX,
                  message: pageData.invalidPhoneNumber,
                },
              ]}
            >
              <Input
                id="txtPhone"
                className="check_out_phone"
                maxLength={15}
                allowClear={false}
                placeholder={pageData.placeHolderPhone}
                onChange={onChangePhone}
                defaultValue={readPhoneFromStorage()}
                onKeyPress={(event) => {
                  const checkValidKey = checkOnKeyPressValidation(event, "txtPhone", 0, 999999999, 0);
                  if (!checkValidKey) event.preventDefault();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="recipient-info-note">
            <div className="recipient-info-title">
              <img className="recipient-info-icon" src={NoteIcon} alt="" />
              <span>{translateData.note}</span>
            </div>
            <Form.Item name="receiverNote">
              <Input
                id="txtName"
                className="text-note"
                maxLength={255}
                autoFocus={true}
                allowClear={false}
                placeholder={pageData.notePlaceHolder}
                onChange={onChangeNote}
                value={note}
              />
              <CloseNote onClick={clearNote} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
