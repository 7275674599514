export const StoreSettingConstants = {
  GENERAL_CONFIG: "GENERAL_CONFIG",
  BANK_ACCOUNT_CONFIG: "BANK_ACCOUNT_CONFIG",
  OPERATION_CONFIG: "OPERATION_CONFIG",
};

export const CustomerScreenType = {
  FULL_SCREEN: 0,
  LEFT_SIDE: 1,
};

export const ReservationType = {
  STAFF_ARRANGE: 1,
  LOCK_PERIOD_TIME: 2,
  FIXED_TIME: 3,
};

export const PrinterSettingType = {
  RECEIPT: 1,
  ORDER_SLIP: 2,
  STAMP: 3,
  BARCODE: 4
};
