import { DepositStatus } from "constants/deposit-status.contants";
import { ReservationStatus } from "constants/reservation-constant";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => props.theme.colors.text.violentViolet};
`;

export const WrapperTable = styled.div`
  .fnb-table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.mainMenu.background};
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.colors.text.main};
`;

export const Guest = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.colors.text.main};
`;

export const Phone = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.colors.text.main};
`;

export const Name = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.colors.text.main};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

export const Id = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.colors.text.blueOrchid};
  text-decoration-line: underline;
  cursor: pointer;
`;

export const Branch = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.colors.text.main};
`;

export const ArrivalTime = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.text.blueberry};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.colors.text.blueberry};
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 160px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  ${(props) => {
    switch (props.statusId) {
      case ReservationStatus.WaitToConfirm:
        return css`
          color: ${(props) => props.theme.colors.text.curiousBlue};
          background-color: ${(props) => props.theme.colors.background.iceBerg};
        `;
      case ReservationStatus.Serving:
        return css`
          color: ${(props) => props.theme.colors.text.carrotOrange};
          background-color: ${(props) => props.theme.colors.background.sandyBeach};
        `;
      case ReservationStatus.Completed:
        return css`
          color: ${(props) => props.theme.colors.text.limeGreen};
          background-color: ${(props) => props.theme.colors.background.offGreen};
        `;
      case ReservationStatus.Cancelled:
        return css`
          color: ${(props) => props.theme.colors.text.ferrariRed};
          background-color: ${(props) => props.theme.colors.background.vistaWhite};
        `;
      case ReservationStatus.Confirmed:
        return css`
          color: ${(props) => props.theme.colors.text.violentViolet};
          background-color: ${(props) => props.theme.colors.background.fog};
        `;
      default:
        return css``;
    }
  }}
`;

export const DepositStatusStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 160px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  ${(props) => {
    switch (props.DepositStatusId) {
      case DepositStatus.NotReceived:
        return css`
          color: ${(props) => props.theme.colors.text.tomatoRed};
          background-color: ${(props) => props.theme.colors.background.lightRed};
        `;
      case DepositStatus.Received:
        return css`
          color: ${(props) => props.theme.colors.text.translucentGreen};
          background-color: ${(props) => props.theme.colors.background.mintGreen};
        `;
      case DepositStatus.WaitToRefund:
        return css`
          color: ${(props) => props.theme.colors.text.translucentOrange};
          background-color: ${(props) => props.theme.colors.background.lightOrange};
        `;
      case DepositStatus.Refunded:
        return css`
          color: ${(props) => props.theme.colors.text.neonBlue};
          background-color: ${(props) => props.theme.colors.background.lightNeonBlue};
        `;
      case DepositStatus.NoRefund:
        return css`
          color: ${(props) => props.theme.colors.text.neutralBlack};
          background-color: ${(props) => props.theme.colors.background.greyish};
        `;
      default:
        return css``;
    }
  }}
`;

export const ActionReservation = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 3.667px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  ${(props) => {
    switch (props.statusId) {
      case ReservationStatus.Cancelled:
        return css`
          background-color: #fff7f7;
        `;
      case ReservationStatus.Confirmed:
        return css`
          background-color: #f4fbf8;
        `;
      default:
        return css`
          background-color: transparent;
        `;
    }
  }}
`;
