import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Image, Popover, Row, Typography } from "antd";
import defaultUserIcon from "assets/images/default-user.png";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { ThreeDotsIcon } from "constants/icons.constants";
import { useRedirect } from "hooks/useRedirect";
import { t } from "i18next";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import languageService from "services/language/language.service";
import {
  getStaffRevenueTable,
  staffRevenueActions,
  staffRevenueSelector,
} from "store/modules/staff-revenue/staff-revenue.reducer";
import { useDebounce } from "themes/theme-1-new/hooks";
import {
  formatCurrency,
  formatNumberDecimalOrInteger,
  getCurrency,
  getShortValue,
  getSuffixShortValue,
} from "utils/helpers";
import RevenueDetail from "./RevenueDetail";
import FilterStaffRevenue from "./filter-staff-revenue";
import "./staff-revenue-table.style.scss";
const { Text } = Typography;

export const StaffRevenueTable = forwardRef((props, ref) => {
  const { titleCondition, totalDate } = props;
  const [dataSource, setDataSource] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { handleRedirect } = useRedirect();
  const staffRevenueSelectorData = useSelector(staffRevenueSelector);
  const minRevenue = staffRevenueSelectorData.filter.minRevenue;
  const maxRevenue = staffRevenueSelectorData.chart.maxRevenue;
  const stepRevenue = staffRevenueSelectorData.chart.stepRevenue;
  const isSortTable = staffRevenueSelectorData.isSortTable;
  const { chart } = staffRevenueSelectorData;
  const [pageNumber, setPageNumber] = useState(1);
  const DEFAULT_PAGESIZE = 20;
  const DEFAULT_PAGENUMBER = 1;
  const dispatch = useDispatch();
  const staffRevenue = useSelector(staffRevenueSelector);
  const { filter, staffSummaryParams } = staffRevenue;
  const [selectedFilterData, setSelectedFilterData] = useState({});
  const [openRevenueDetail, setOpenRevenueDetail] = useState(false);
  const [currentStaff, setCurrentStaff] = useState({});
  const [countFilter, setCountFilter] = useState(0);
  const filterPopoverRef = useRef();
  const [openModalViewDetail, setOpenModalViewDetail] = useState(false);
  const [staffDetail, setStaffDetail] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isAlreadyOpenModalFilter, setIsAlreadyOpenModalFilter] = useState(false);
  const pageData = {
    no: t("table.no"),
    code: t("table.code"),
    name: t("table.name"),
    position: t("report.staff.position"),
    branch: t("report.staff.branch"),
    totalOrder: t("report.staff.totalOrder"),
    revenue: t("report.staff.revenue"),
    showMoreItem: t("report.staff.showMoreItem"),
    description: t("report.staff.description"),
    allBranch: t("dashboard.allBranch"),
    orders: t("report.staff.orders"),
    viewProfileTitle: t("report.staff.viewProfileTitle"),
    viewKPIDetailTitle: t("report.staff.viewKPIDetailTitle"),
  };

  useEffect(() => {
    if (chart.columns?.length > 0 && !isSortTable && !isAlreadyOpenModalFilter) {
      let filterData = {};
      const selectedValueSlider = calculateFilterConditions();
      filterData = { ...selectedFilterData, ...selectedValueSlider };
      getDataTable(DEFAULT_PAGENUMBER, filterData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.columns, filter.index, isMobile]);

  useEffect(() => {
    fetchDatableAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffData]);

  const onChangePage = async (pageNumber) => {
    let filterData = {};
    if (chart.columns?.length > 0) {
      const selectedValueSlider = calculateFilterConditions();
      filterData = { ...selectedFilterData, ...selectedValueSlider };
    }
    if (pageNumber > 0) {
      getDataTable(pageNumber, filterData);
    }
  };

  const getDataTable = async (pageNumber, filterData) => {
    const params = {
      languageCode: languageService.getLang(),
      branchId: staffSummaryParams.branchId ?? "",
      startDate: staffSummaryParams.startDate,
      endDate: staffSummaryParams.endDate,
      typeOptionDate: staffSummaryParams.businessSummaryWidgetFilter ?? 0,
      timeZone: new Date().getTimezoneOffset() / 60,
      pageSize: isMobile ? null : DEFAULT_PAGESIZE,
      pageNumber: isMobile ? null : pageNumber,
      groupPermissionIds: filterData?.selectedFilter?.groupPermissionIds ?? [],
      orderQuantityRange: {
        from: filterData?.selectedFilter?.totalOrderFrom ?? null,
        to: filterData?.selectedFilter?.totalOrderTo ?? null,
      },
      revenueRange: {
        from: null,
        to: null,
      },
      staffIds: filterData?.selectedFilter?.staffIds ?? [],
    };
    if (filterData?.selectedValueSlider) {
      params.revenueRange.from = filterData?.selectedValueSlider[0];
      params.revenueRange.to = filterData?.selectedValueSlider[1];
    }
    const response = unwrapResult(await dispatch(getStaffRevenueTable(params)));
    if (response) {
      setStaffData(response.staffRevenueModels);
      setTotalRecords(response.total);
      setPageNumber(pageNumber);
    }
  };

  const fetchDatableAsync = (pageNumber, pageSize) => {
    if (staffData) {
      const data = staffData?.map((s, index) => mappingRecordToColumns(s, index, pageNumber, pageSize));
      setDataSource(data);
    }
  };

  const tableSettings = {
    pageSize: DEFAULT_PAGESIZE,
    columns: [
      {
        title: pageData.no,
        dataIndex: "index",
        key: "index",
        width: "5rem",
        render: (value) => value + 1,
      },
      {
        title: pageData.code,
        dataIndex: "staffCode",
        key: "staffCode",
        align: "left",
        width: "9%",
        render: (_, { staffCode }) => {
          return <span>{staffCode}</span>;
        },
      },
      {
        title: pageData.name,
        dataIndex: "staffName",
        key: "staffName",
        align: "left",
        width: "27%",
        sorter: (pre, cur) => pre?.staffName.localeCompare(cur?.staffName),
        render: (_, record) => {
          return (
            <div className="staff-name">
              <Image preview={false} width={40} height={40} src={record?.thumbnail ?? defaultUserIcon} />
              <div>{record?.staffName}</div>
            </div>
          );
        },
      },
      {
        title: pageData.position,
        dataIndex: "position",
        key: "position",
        align: "left",
        width: "13.8%",
        render: (_, record, index) => {
          return renderListName(
            record?.permissions?.length > 0 ? record?.permissions : [{ name: "Admin" }],
            `position-${index}`,
          );
        },
      },
      {
        title: pageData.branch,
        dataIndex: "branch",
        key: "branch",
        align: "left",
        width: "17.2%",
        render: (_, record, index) => {
          return renderListName(
            record?.branches?.length > 0 ? record?.branches : [{ name: pageData.allBranch }],
            `branch-${index}`,
          );
        },
      },
      {
        title: pageData.totalOrder,
        dataIndex: "totalOrder",
        key: "totalOrder",
        align: "right",
        width: "13.8%",
        sorter: (pre, current) => pre?.totalOrder - current?.totalOrder,
        render: (_, record) => {
          return (
            <FnbTypography.Link
              onClick={() => handleVisibleRevenueDetail(true, record)}
              text={formatNumberDecimalOrInteger(record?.totalOrder)}
              boxShadow={"0 1px"}
            />
          );
        },
      },
      {
        title: pageData.revenue,
        dataIndex: "revenue",
        key: "revenue",
        align: "right",
        width: "13.8%",
        sorter: (pre, current) => pre?.revenue - current?.revenue,
        render: (_, record) => {
          return formatNumberDecimalOrInteger(record?.revenue);
        },
      },
    ],
  };

  // Only show popover in case 'Long text...'
  const PopoverText = ({ children, ...props }) => {
    const [isEllipsised, setIsEllipsised] = useState(false);
    const content = isEllipsised ? (
      <Col className="popover-show-more">
        <Row span={24}>{children}</Row>
      </Col>
    ) : null;

    return (
      <Popover placement="bottom" content={content} showArrow={isEllipsised}>
        <Text {...props} ellipsis={{ onEllipsis: setIsEllipsised }} style={{ width: "100%" }}>
          {children}
        </Text>
      </Popover>
    );
  };

  const renderListName = (listData, cellKey) => {
    return (
      <Col>
        {listData.slice(0, 3).map((item, index) => (
          <>
            {index >= 1 && <Row className="empty-space"></Row>}
            <Row span={24}>
              <FnbTooltip maxWidth={500} title={item?.name} maxWidthContent={"250px"} onlyShowWhenEllipsis={true}>
                <FnbTypography text={item?.name} />
              </FnbTooltip>
            </Row>
          </>
        ))}
        {listData.length > 3 && (
          <>
            <Row className="empty-space"></Row>
            <Row>
              <FnbTooltip
                overlayClassName="tooltip-show-more-branch-table-staff-revenue"
                title={
                  <ul>
                    {listData.slice(3, listData.length).map((item) => (
                      <li>
                        <FnbTypography text={item?.name} />
                      </li>
                    ))}
                  </ul>
                }
                placement="bottom"
                variant="secondary"
              >
                <FnbTypography.Link text={t(pageData.showMoreItem, { length: listData.length - 3 })} />
              </FnbTooltip>
            </Row>
          </>
        )}
      </Col>
    );
  };

  const renderDescription = () => {
    return (
      <div id="staff-revenue-table-header" className="header">
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: t(pageData.description, {
              staff_count: totalRecords,
              min_revenue:
                getShortValue(filter.startRevenue === 0 ? minRevenue : filter.startRevenue) +
                " " +
                getSuffixShortValue(filter.startRevenue === 0 ? minRevenue : filter.startRevenue),
              max_revenue:
                getShortValue(filter.endRevenue === 0 ? maxRevenue : filter.endRevenue) +
                " " +
                getSuffixShortValue(filter.endRevenue === 0 ? maxRevenue : filter.endRevenue),
              money_unit: getCurrency(),
              time: t(titleCondition, { dayOfNumber: totalDate }),
            }),
          }}
        />
      </div>
    );
  };

  const mappingRecordToColumns = (item) => {
    return {
      staffId: item?.staff?.id,
      staffCode: item?.staff?.code,
      staffName: item?.staff?.fullName?.trim(),
      permissions: item?.staff?.permissions,
      branches: item?.staff?.branches,
      totalOrder: item?.totalOrder,
      revenue: item?.revenueAmount,
      accountId: item?.staff?.accountId,
      thumbnail: item?.staff?.thumbnail,
    };
  };

  useDebounce(
    () => {
      const startFilter = selectedFilterData?.selectedValueSlider
        ? selectedFilterData?.selectedValueSlider[0]
        : minRevenue;
      const endFilter = selectedFilterData?.selectedValueSlider
        ? selectedFilterData?.selectedValueSlider[1]
        : maxRevenue;
      let beginIndex = null,
        endIndex = null;
      for (let index = 0; index < chart?.columns?.length; index++) {
        if (startFilter >= chart?.columns[index]?.fromRevenue) {
          beginIndex = index;
        }
        if (endIndex === null && endFilter <= chart?.columns[index]?.toRevenue) {
          endIndex = index;
        }
      }
      if (beginIndex > endIndex) {
        beginIndex = null;
        endIndex = null;
      }

      const setRevenueAndShowRange = () => {
        dispatch(
          staffRevenueActions.setStartAndEndRevenue({
            startRevenue: selectedFilterData?.selectedValueSlider[0],
            endRevenue: selectedFilterData?.selectedValueSlider[1],
            startIndex: beginIndex,
            endIndex: endIndex,
          }),
        );
        dispatch(
          staffRevenueActions.setShowSelectedRange({
            isShow: beginIndex !== endIndex,
          }),
        );
      };

      if (filter.isDefault) {
        if (beginIndex !== 0 || endIndex !== (isMobile ? 9 : 19)) {
          setRevenueAndShowRange();
        }
      } else {
        setRevenueAndShowRange();
      }
      dispatch(staffRevenueActions.reloadColorChart());
    },
    [selectedFilterData],
    500,
  );

  const onClearFilter = () => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
    setCountFilter(0);
    setSelectedFilterData(null);
  };

  function handleChangeFilter(data, disableCallApi = false) {
    const isSelectedStaff = data.selectedFilter.staffIds.length > 0;
    const isSelectedPosition = data.selectedFilter.groupPermissionIds.length > 0;
    const isSelectedOrder = data.selectedFilter.totalOrderFrom != null || data.selectedFilter.totalOrderTo != null;
    const isSelectedRevenue = data.selectedValueSlider[0] !== minRevenue || data.selectedValueSlider[1] !== maxRevenue;
    setCountFilter(isSelectedStaff + isSelectedPosition + isSelectedOrder + isSelectedRevenue);
    setSelectedFilterData(data);
    if (!disableCallApi) {
      getDataTable(DEFAULT_PAGENUMBER, data);
    }
  }

  const filterComponent = () => {
    return (
      <FilterStaffRevenue
        ref={filterPopoverRef}
        minRevenue={minRevenue}
        maxRevenue={maxRevenue}
        stepRevenue={stepRevenue}
        onChange={handleChangeFilter}
        setIsAlreadyOpenModalFilter={setIsAlreadyOpenModalFilter}
      />
    );
  };

  const handleVisibleRevenueDetail = (visible, currentStaff) => {
    setCurrentStaff(currentStaff);
    setOpenRevenueDetail(visible);
  };

  const calculateFilterConditions = useCallback(() => {
    const endIndex = window.innerWidth > 575 ? 19 : 9;
    const startRevenue = chart.columns[filter.index.start ?? 0]?.fromRevenue;
    const endRevenue = chart.columns[filter.index.end ?? endIndex]?.toRevenue;
    const filterData = {
      selectedValueSlider: [startRevenue, endRevenue],
    };
    return filterData;
  }, [chart.columns, filter.index]);

  const handleVisibleMoreRevenueDetail = (visible, currentStaff) => {
    setOpenModalViewDetail(visible);
    setStaffDetail(currentStaff);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleEditStaff = (_, [{ staffId }]) => {
    handleRedirect(`/staff/edit/${staffId}`);
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className="list-staff">
            {dataSource?.map((staff) => (
              <div
                className="list-staff-item staff-item"
                onClick={() => {
                  handleVisibleRevenueDetail(true, staff);
                }}
              >
                <Image className="staff-item__avatar" preview={false} src={staff?.thumbnail ?? defaultUserIcon} />
                <div className="staff-info">
                  <span className="staff-item__name">{staff.staffName}</span>
                  <span className="staff-item__code">{staff.staffCode}</span>
                  <span className="staff-item__code">{staff.position}</span>
                </div>
                <div className="staff-revenue">
                  <span className="staff-item__revenue">{formatCurrency(staff?.revenue)}</span>
                  <span className="staff-item__order">
                    {staff.totalOrder} {pageData.orders}
                  </span>
                </div>
                <ThreeDotsIcon
                  className="staff-item__more-detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleVisibleMoreRevenueDetail(true, staff);
                  }}
                />
              </div>
            ))}
          </div>
          {openModalViewDetail && staffDetail?.staffId && (
            <div className="modal-view-staff-order-detail" onClick={() => handleVisibleMoreRevenueDetail(false)}>
              <div className="view-staff-order-detail">
                <Link to={`/staff/edit/${staffDetail.staffId}`} target="_blank">
                  {t(pageData.viewProfileTitle, {
                    staff_name: staffDetail?.staffName || "",
                  })}
                </Link>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleVisibleRevenueDetail(true, staffDetail);
                  }}
                >
                  {t(pageData.viewKPIDetailTitle, {
                    staff_name: staffDetail?.staffName || "",
                  })}
                </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="staff-revenue-table-wrapper">
          <FnbTable
            className="table-staff-revenue"
            columns={tableSettings.columns}
            pageSize={tableSettings.pageSize}
            dataSource={dataSource}
            currentPageNumber={pageNumber}
            total={totalRecords}
            onChangePage={onChangePage}
            description={renderDescription()}
            filter={{
              totalFilterSelected: countFilter,
              onClearFilter: onClearFilter,
              component: filterComponent(),
            }}
            scrollX={1200}
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedRowKeysChange,
              onEdit: handleEditStaff,
            }}
            //TO DO: Comment this code because it impact to scroll X for table detail
            // scrollY={"70dvh"}
          />
        </div>
      )}
      <RevenueDetail
        key={currentStaff?.staffId}
        open={openRevenueDetail}
        currentStaff={currentStaff}
        onCancel={() => handleVisibleRevenueDetail(false)}
      />
    </>
  );
});
