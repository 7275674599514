import { StoreWebBannerIcon } from "../../assets/icons.constants";
import SelectBackgroundComponent from "../../components/select-background.component";
import SelectColorGroupComponent from "../../components/select-color-group.component";
import { theme1ElementCustomize } from "../../constants/store-web-page.constants";

export const ProductDetailCustomizes = [
  {
    icon: <StoreWebBannerIcon />,
    title: "General customization",
    isNormal: true,
    defaultActiveKey: 1,
    iconRight: <></>,
    collapsible: false,
    name: "productDetail",
    customizeKey: theme1ElementCustomize.ProductDetail,
    content: (props) => {
      return (
        <>
          <SelectBackgroundComponent
            {...props}
            isRequired={false}
            formItemPreName={["config"]}
            nameComponents={theme1ElementCustomize.ProductDetail}
            backgroundCustomize={props?.pageConfig.config}
          />
          <SelectColorGroupComponent {...props} formItemPreName={["config"]} />
        </>
      );
    },
  },
];
