import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTypography from "components/fnb-typography/fnb-typography";
import ItemPackagePrice from "components/subscription/item-package-price.component";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { isStoreInternational } from "utils/helpers";
import {
  DivideLine,
  ListPackage,
  ListPackageWrapper,
  PackageDurationContainer,
  PackageDurationListButton,
  PackageSectionContainer,
} from "./package-section.styled";
import { useMediaQuery } from "react-responsive";

const PackageSection = ({
  packages,
  periodPackageDurations,
  periodSelected,
  setPeriodSelected,
  defaultHoverPackageId,
  setDefaultHoverPackageId,
  onClickBuyPackage,
  backgroundColor = "#f7f5ff",
  backgroundColorDivide = theme.colors.default.white,
}) => {
  const [t] = useTranslation();

  const pageData = {
    year: t("package.year"),
    years: t("package.years"),
    packageDuration: t("package.packageDuration"),
    notIncludeVAT: t("package.notIncludeVAT"),
  };

  const onClickDuration = (index) => {
    setPeriodSelected(index);
  };
  const isInternational = isStoreInternational();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  return (
    <PackageSectionContainer backgroundColor={backgroundColor}>
      <PackageDurationContainer>
        <div>
          <FnbTypography variant={theme.typography["h2-medium"]} text={pageData.packageDuration} />
          {!isInternational && (
            <>
              <FnbTypography variant={theme.typography["h2-medium"]} text=" (" />
              <FnbTypography
                variant={theme.typography["h2-medium"]}
                text={pageData.notIncludeVAT}
                color={theme.colors.secondary[70]}
              />
              <FnbTypography variant={theme.typography["h2-medium"]} text=")" />
            </>
          )}
        </div>
        <PackageDurationListButton>
          {periodPackageDurations?.map((item) => {
            return (
              <FnbButton
                variant={periodSelected === item ? "primary" : "default"}
                text={`${item / 12} ${item / 12 > 1 ? pageData.years : pageData.year}`}
                width={isMobile ? "calc(50% - 4px)" : 246}
                onClick={() => onClickDuration(item)}
              />
            );
          })}
        </PackageDurationListButton>
      </PackageDurationContainer>
      <DivideLine backgroundColor={backgroundColorDivide} />
      <ListPackageWrapper>
        <ListPackage>
          {packages?.map((item, index) => {
            return (
              <ItemPackagePrice
                packageItem={item}
                isOdd={index % 2 === 1}
                isInternational={isInternational}
                periodSelected={periodSelected}
                onClickBuyPackage={onClickBuyPackage}
                defaultHoverPackageId={defaultHoverPackageId}
                setDefaultHoverPackageId={setDefaultHoverPackageId}
                t={t}
              />
            );
          })}
        </ListPackage>
      </ListPackageWrapper>
    </PackageSectionContainer>
  );
};

export default PackageSection;
