import { Switch } from "antd";
import "./index.scss";

const FnbSwitch = ({ onChange, checked, disabled = false, className = "", defaultChecked }) => {
  return (
    <Switch
      defaultChecked={defaultChecked}
      className={`custom-switch switch ${className}`}
      size="default"
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default FnbSwitch;
