import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TableBlog } from "../components/table-blog.component";
import "./blog-management-list.page.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

export function BlogPageManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    addNew: t("button.addNew"),
    blog_management: t("blog.blog_management"),
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.blog_management}
        permissionCreate={PermissionKeys.CREATE_BLOG}
        onCreate={() => history.push("/online-store/blog-management/create-blog")}
      />
      <TableBlog />
    </>
  );
}
