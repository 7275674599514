import { Button, Card, Col, DatePicker, Form, InputNumber, message, Radio, Row, Space, TimePicker } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectCustomer } from "components/fnb-select-customer/fnb-select-customer";
import { FnbSelectMultipleOptGroup } from "components/fnb-select-multiple-opt-group/fnb-select-multiple-opt-group";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import PageTitle from "components/page-title";
import { AreaTableStatus } from "constants/areaTable.constants";
import { DepositStatus } from "constants/deposit-status.contants";
import { ArrowLeftReservationDetail } from "constants/icons.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { paymentMethod } from "constants/payment-method.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { ReservationStatus } from "constants/reservation-constant";
import { enumReservation } from "constants/reservation-status.constrants";
import { DateFormat } from "constants/string.constants";
import areaDataService from "data-services/area/area-data.service";
import branchDataService from "data-services/branch/branch-data.service";
import customerDataService from "data-services/customer/customer-data.service";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import reserveTableDataService from "data-services/reserveTable/reserve-table-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { enumReserveTable } from "themes/theme-2-new/constants/enums";
import { formatNumberDecimalOrInteger, formatTextNumber, getCurrency } from "utils/helpers";
import { BadgeReservationStatus } from "../view/components/badge/BadgeReservationStatus";
import DepositStatusComponent from "./components/deposit-status.component";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import Display from "pages/settings/general/components/display/display.component";

export default function ReservationEdit(props) {
  const { t } = useTranslation();
  const param = useParams();
  const [data, setData] = useState({});
  const history = useHistory();
  const [form] = Form.useForm();
  const [formConfirm] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [titleDialogConfirm, setTitleDialogConfirm] = useState("");
  const [actionDialogConfirm, setActionDialogConfirm] = useState("");
  const [reservationId, setReservationId] = useState();
  const [statusReservation, setStatusReservation] = useState();
  const [statusReservationOld, setStatusReservationOld] = useState(null);
  const [isDialogConfirm, setIsDialogConfirm] = useState(false);
  const [depositStatusId, setDepositStatusId] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);
  const [branches, setBranches] = useState([]);
  const [areaTables, setAreaTables] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [listReservationTable, setListReservationTable] = useState([]);
  const [listAreaTableId, setListAreaTableId] = useState([]);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [arrivalTime, setArrivalTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [currentCustomerName, setCurrentCustomerName] = useState("");
  const [currentCustomerPhone, setCurrentCustomerPhone] = useState("");

  const [activePaymentMethods, setActivePaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});
  const [activeAllPaymentMethods, setActiveAllPaymentMethods] = useState([]);
  const [isCheckedRefundMethod, setIsCheckedRefundMethod] = useState(true);
  const [textConfirm, setTextConfirm] = useState("");
  const [status, setStatus] = useState(ReservationStatus.Cancelled);
  const [reservationStatus, setReservationStatus] = useState(ReservationStatus.Cancelled);
  const [isReservationDialogRefund, setIsReservationDialogRefund] = useState(true);
  const isShowTableInfo = !(
    data?.status === enumReservation.Completed ||
    data?.status === enumReservation.Serving ||
    data?.status === enumReservation.Cancelled
  );
  const pageData = {
    reservationDetail: t("reservation.reservationDetail", "Reservation detail"),
    guestInformation: t("reservation.guestInformation", "Guest information"),
    numberOfGuests: t("reservation.numberOfGuests", "Number of Guests"),
    guestArrivalTime: t("reservation.guestArrivalTime", "Guest arrival time"),
    tableInformation: t("reservation.tableInformation", "Table information"),
    assignedTables: t("reservation.assignedTables", "Assigned Table(s)"),
    name: t("reserve.name", "Name"),
    phoneNumber: t("reservation.phone", "Phone Number"),
    email: t("form.email", "Email"),
    note: t("form.note", "Note"),
    branch: t("reservation.branch", "Branch"),
    orderId: t("order.order", "Order Id"),
    btnCancel: t("button.cancel"),
    btnSaveChanges: t("button.saveChanges"),
    reject: t("reservation.reject"),
    confirm: t("reservation.confirm"),
    update: t("reservation.update"),
    cancel: t("reservation.cancel"),
    namePlaceHolder: t("reserve.namePlaceHolder"),
    pleaseEnterTheReservationName: t("reserve.pleaseEnterTheReservationName"),
    numberOfGuestsPlaceHolder: t("reserve.numberOfGuestsPlaceHolder"),
    pleaseEnterTheNumberOfGuests: t("reserve.pleaseEnterTheNumberOfGuests"),
    phoneNumberPlaceHolder: t("reserve.phoneNumberPlaceHolder"),
    pleaseEnterTheReservationPhone: t("reserve.pleaseEnterTheReservationPhone"),
    allowNumberOnly: t("form.allowNumberOnly"),
    mustBeBetweenThreeAndFifteenCharacters: t("form.mustBeBetweenThreeAndFifteenCharacters"),
    guestArrivalTimePlaceHolder: t("reserve.guestArrivalTimePlaceHolder"),
    guestArrivalTimePleaseSelect: t("reserve.guestArrivalTimePleaseSelect"),
    emailPlaceHolder: t("reserve.emailPlaceHolder"),
    confirmReservation: t("reservation.confirmReservation"),
    cancelReservation: t("reservation.cancelReservation"),
    rejectReservation: t("reservation.rejectReservation"),
    confirmReceived: t("reservation.confirmReceived"),
    doYouReallyWantTo: t("reservation.doYouReallyWantTo"),
    discard: t("reservation.discard"),
    confirmSuccess: t("reservation.confirmSuccess"),
    cancelSuccess: t("reservation.cancelSuccess"),
    rejectSuccess: t("reservation.rejectSuccess"),
    hasNotYetReceivedDeposit: t("reservation.hasNotYetReceivedDeposit"),
    reason: t("reservation.reason"),
    validateReason: t("reservation.validateReason"),
    placeholderReason: t("reservation.placeholderReason"),
    rejectDepositReservation: {
      refundNow: t("reservation.rejectReservationDeposit.refundNow"),
      refundLater: t("reservation.rejectReservationDeposit.refundLater"),
      noRefundReservation: t("reservation.rejectReservationDeposit.noRefundReservation"),
      rejectReservationContent: t("reservation.rejectReservationDeposit.rejectReservationContent"),
    },
    cancelDepositReservation: {
      refundNow: t("reservation.cancelReservationDeposit.refundNow"),
      refundLater: t("reservation.cancelReservationDeposit.refundLater"),
      noRefundReservation: t("reservation.cancelReservationDeposit.noRefundReservation"),
      cancelReservationContent: t("reservation.cancelReservationDeposit.cancelReservationContent"),
    },
    cash: t("payment.cash"),
    bankTransfer: t("payment.bankTransfer"),
    depositMethod: t("reservation.depositMethod"),
    depositAmount: t("reservation.depositAmount"),
    depositStatus: t("reservation.depositStatus"),
    branchPlaceHolder: t("reserve.branchPlaceHolder"),
    pleaseSelectTheBranch: t("reserve.pleaseSelectTheBranch"),
    assignedPlaceHolder: t("reserve.assignedPlaceHolder"),
    assignedTablesPleaseSelect: t("reserve.assignedTablesPleaseSelect"),
    updateFailed: t("messages.updateFailed"),
    updateSuccess: t("messages.updateSuccess"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
    ignore: t("reserve.ignore"),
    pleaseSelectRefundMethod: t("reservation.pleaseSelectRefundMethod"),
    depositStatusLabel: {
      notReceived: t("reservation.notReceived"),
      received: t("reservation.received"),
      waitToRefund: t("reservation.waitToRefund"),
      refunded: t("reservation.refunded"),
      noRefund: t("reservation.noRefund"),
    },
    depositBy: t("reservation.depositBy"),
    pleaseTurnOnDeposit: t("reservation.pleaseTurnOnDeposit"),
    customerReservation: t("reserveTable.customerReservation"),
    customer: t("reserve.customer"),
  };

  const dataPaymentMethod = [
    {
      id: paymentMethod.Cash,
      name: pageData.cash,
    },
    {
      id: paymentMethod.BankTransfer,
      name: pageData.bankTransfer,
    },
  ];

  const filterPaymentMethod = dataPaymentMethod.filter((dataPaymentMethod) =>
    activePaymentMethods.some(
      (activePaymentMethod) => activePaymentMethod && Object.values(activePaymentMethod).includes(dataPaymentMethod.id),
    ),
  );

  const initData = async () => {
    const { id } = param;
    if (id)
      await reserveTableDataService.getReserveTableAsync(id).then((result) => {
        if (result) {
          setReservationId(result?.id);
          setListAreaTableId(result?.listAreaTableId);
          const arrivalTimeUTC = moment.utc(result?.guestArrivalTime).local();
          setSelectedDate(arrivalTimeUTC);
          setArrivalDate(arrivalTimeUTC);
          setArrivalTime(arrivalTimeUTC);
          setCustomerId(result?.customerId);
          getAreaTables(result?.branchId);
          result.guestArrivalDate = arrivalTimeUTC;
          result.guestArrivalHour = arrivalTimeUTC;
          result.phoneNumber = result?.customerId;
          setDepositStatusId(result?.depositStatusId);
          setCurrentCustomerName(result?.customerName);
          setCurrentCustomerPhone(result?.customerPhone);
          setData(result);
          form.setFieldsValue(result);
        }
      });
  };

  useEffect(() => {
    getCustomers();
    getBranches();
    paymentConfigDataService.getAllStorePaymentConfigAsync().then((resData) => {
      if (resData) {
        if (resData.paymentMethods?.length > 0) {
          const activePaymentMethods = dataPaymentMethod.filter((dataPaymentMethod) =>
            resData.paymentMethods.find(
              (paymentMethod) =>
                paymentMethod.enumId === dataPaymentMethod.id && paymentMethod.paymentConfigs?.[0]?.isActivated,
            ),
          );
          setActivePaymentMethods(activePaymentMethods);
          setDefaultPaymentMethod(activePaymentMethods?.[0]);
          const paymentActives = resData.paymentMethods.filter(
            (paymentMethod) => paymentMethod.paymentConfigs?.[0]?.isActivated === true,
          );
          if (paymentActives?.length > 0) setActiveAllPaymentMethods(paymentActives);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (branches?.length > 0 && customers?.length > 0) {
      initData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, customers]);

  useEffect(() => {
    if (reservationId && arrivalTime && listAreaTableId?.length > 0 && areaTables?.length > 0) {
      getListReservationTable();
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [reservationId, arrivalTime, listAreaTableId, areaTables]);

  const handleCancel = (id) => {
    if (isChangeForm) {
      setShowLeaveDialog(true);
    } else history.push(`/report/reservation-detail/${id}`);
  };

  const handleDiscardDialog = () => {
    setShowLeaveDialog(false);
  };

  const onChangeForm = () => {
    if (!isChangeForm) {
      setIsChangeForm(true);
    }
  };

  const getBranches = () => {
    branchDataService.getAllBranchsAsync().then((res) => {
      if (res) {
        setBranches(res.branchs);
      }
    });
  };

  const getAreaTables = (storeBranchId) => {
    areaDataService.getListAreaTableAsync(storeBranchId).then((res) => {
      if (res) {
        setAreaTables(res?.areas);
      }
    });
  };

  const getCustomers = async () => {
    const response = await customerDataService.getAllCustomersAsync();
    const data = response?.customers?.filter((item) => item.id !== undefined || item.id !== null);
    setCustomers(data);
  };

  const getListReservationTable = () => {
    const reserveTableRequest = {
      reservationTableId: reservationId,
      arrivalTime: arrivalTime,
      areas: [],
    };
    areaTables?.map((itemGroup) => {
      const data = {
        areaId: itemGroup?.id,
        areaTableIds: [],
      };
      itemGroup?.areaTables?.map((item) => {
        listAreaTableId?.map((itemSelect) => {
          if (itemSelect === item?.id) {
            data.areaTableIds.push(item?.id);
          }
        });
      });
      if (data.areaTableIds.length > 0) {
        reserveTableRequest.areas.push(data);
      }
    });
    reserveTableDataService.getListReservationTablesAsync(reserveTableRequest).then((res) => {
      if (res) {
        const listReservation = res;
        setListReservationTable(listReservation);
      }
    });
  };

  const getAmountCurrency = (amount) => {
    return formatNumberDecimalOrInteger(amount) + " " + getCurrency();
  };

  const onConfirmItem = (reservationId, depositStatusId, depositAmount) => {
    setDepositAmount(depositAmount);
    setTitleDialogConfirm(pageData.confirmReservation);
    if (!data?.depositStatusId) {
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.confirm }));
      setIsReservationDialogRefund(false);
    } else {
      if (depositStatusId === DepositStatus.NotReceived) {
        setIsReservationDialogRefund(true);
        setTextConfirm(pageData.hasNotYetReceivedDeposit);
        setStatus(ReservationStatus.Confirmed);
        setReservationStatus(reservationStatus);
        renderContent(pageData.hasNotYetReceivedDeposit);
        setTitleDialogConfirm(pageData.confirmReceived);
      } else {
        renderContent(t("reservation.doYouReallyWantTo", { action: pageData.confirm }));
        setIsReservationDialogRefund(false);
      }
    }

    setReservationId(reservationId);
    setStatusReservation(ReservationStatus.Confirmed);
    setIsDialogConfirm(true);
  };

  const onRejectItem = (reservationId, statusId, depositStatusId, depositAmount) => {
    setDepositAmount(depositAmount);
    if (!data?.depositStatusId) {
      onRejectDefault(statusId);
      setIsReservationDialogRefund(false);
    } else {
      setIsReservationDialogRefund(true);
      if (depositStatusId === DepositStatus.Received) {
        setStatus(ReservationStatus.Cancelled);
        setReservationStatus(statusId);
        if (statusId === ReservationStatus.Confirmed) {
          setTextConfirm(
            t("reservation.cancelReservationDeposit.cancelReservationContent", {
              amount: getAmountCurrency(depositAmount),
              action: pageData.cancel,
            }),
          );
          setTitleDialogConfirm(pageData.cancelReservation);
        } else {
          setTextConfirm(
            t("reservation.rejectReservationDeposit.rejectReservationContent", {
              amount: getAmountCurrency(depositAmount),
              action: pageData.reject,
            }),
          );
          setTitleDialogConfirm(pageData.rejectReservation);
        }
      } else {
        setIsReservationDialogRefund(false);
        onRejectDefault(statusId);
      }
    }

    setReservationId(reservationId);
    setStatusReservationOld(statusId);
    setStatusReservation(ReservationStatus.Cancelled);
    setIsDialogConfirm(true);
  };

  const onRejectDefault = (statusId) => {
    setIsReservationDialogRefund(false);
    if (statusId === ReservationStatus.Confirmed) {
      setTitleDialogConfirm(pageData.cancelReservation);
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.cancel }));
    } else {
      setTitleDialogConfirm(pageData.rejectReservation);
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.reject }));
    }
  };

  const handleHiddenDialog = () => {
    setIsDialogConfirm(false);
  };

  const updateReservationStatus = async (reservationId, status) => {
    formConfirm.validateFields().then(async (values) => {
      let formValue = values;
      let refundPaymentMethodId = null;
      let paymentMethodId = null;
      let reason = formValue.reason || null;
      let depositStatus = formValue.depositStatus || depositStatusId;
      if (status === enumReserveTable.Confirmed) {
        paymentMethodId = formValue.depositMethod;
        refundPaymentMethodId = null;
        reason = null;
      } else if (formValue.depositStatus === DepositStatus.Refunded) {
        refundPaymentMethodId = formValue.depositMethod;
        paymentMethodId = null;
      }
      if (reservationId && status !== undefined) {
        const data = {
          id: reservationId,
          statusId: status,
          depositStatusId: depositStatus,
          refundPaymentMethodId: refundPaymentMethodId,
          paymentMethodId: paymentMethodId,
          refundAmount: depositAmount,
          Reason: reason,
        };
        await reserveTableDataService.updateReserveTableStatusAsync(data).then((response) => {
          if (response) {
            initData();
            if (statusReservationOld === ReservationStatus.Confirmed) {
              message.success(pageData.cancelSuccess);
            } else if (status === ReservationStatus.Confirmed) {
              message.success(pageData.confirmSuccess);
            } else message.success(pageData.rejectSuccess);
          }
        });

        setIsDialogConfirm(false);
        setReservationId();
        setStatusReservation();
      }
    });
  };

  const renderContent = (textConfirm) => {
    setActionDialogConfirm(textConfirm);
  };

  const handleFieldsChange = () => {
    const isFormValid = () =>
      formConfirm.getFieldsError().some((item) => {
        return item.errors.length > 0;
      });
    if (!isFormValid()) {
      document.getElementById("btn-submit").classList.remove("fnb-button--disabled--fulfill");
      document.getElementById("btn-submit").removeAttribute("disabled");
    } else {
      document.getElementById("btn-submit").classList.add("fnb-button--disabled--fulfill");
      document.getElementById("btn-submit").setAttribute("disabled", "");
    }
  };

  const handleChangeRadioGroup = ({ target }) => {
    if (target) {
      if (target.value !== DepositStatus.Refunded) {
        setIsCheckedRefundMethod(false);
      } else {
        setIsCheckedRefundMethod(true);
      }
      formConfirm.validateFields();
    }
  };

  const renderContentElement = () => {
    return status === ReservationStatus.Cancelled ? (
      <div className="confirm-reservation-page">
        <Form form={formConfirm} onFieldsChange={handleFieldsChange}>
          <Row gutter={[28, 32]}>
            <Col span={24}>
              <div className="text-confirm">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${textConfirm}`,
                  }}
                ></span>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                className="form-confirm-reservation"
                name={"depositStatus"}
                initialValue={DepositStatus.Refunded}
              >
                <Radio.Group
                  defaultValue={DepositStatus.Refunded}
                  value={DepositStatus.Refunded}
                  className="list-reservation-settings"
                  onChange={handleChangeRadioGroup}
                >
                  <Radio value={DepositStatus.Refunded}>
                    <span
                      className="label-radio-reservation"
                      dangerouslySetInnerHTML={{
                        __html: `${
                          data.status === ReservationStatus.WaitToConfirm
                            ? pageData.rejectDepositReservation.refundNow
                            : pageData.cancelDepositReservation.refundNow
                        }`,
                      }}
                    ></span>
                  </Radio>
                  <Space direction="vertical" size={12} className="w-100" style={{ paddingLeft: "32px" }}>
                    <Space className="w-100" direction="vertical" size={8}>
                      <Form.Item
                        name={"depositMethod"}
                        initialValue={defaultPaymentMethod?.id}
                        className="form-confirm-reservation"
                        rules={[
                          {
                            required: isCheckedRefundMethod,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.pleaseSelectRefundMethod}
                              />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          disabled={!isCheckedRefundMethod}
                          className="deposit-paymentmethod"
                          option={filterPaymentMethod?.map((item, index) => ({
                            id: item.id,
                            name: item.name,
                            key: index,
                          }))}
                        />
                      </Form.Item>
                    </Space>
                  </Space>
                  <Radio value={DepositStatus.WaitToRefund}>
                    <div className="reservation-coming-soon">
                      <span
                        className="label-radio-reservation"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            data.status === ReservationStatus.WaitToConfirm
                              ? pageData.rejectDepositReservation.refundLater
                              : pageData.cancelDepositReservation.refundLater
                          }`,
                        }}
                      ></span>
                    </div>
                  </Radio>
                  <Radio value={DepositStatus.NoRefund}>
                    <div className="reservation-coming-soon">
                      <span
                        className="label-radio-reservation"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            data.status === ReservationStatus.WaitToConfirm
                              ? pageData.rejectDepositReservation.noRefundReservation
                              : pageData.cancelDepositReservation.noRefundReservation
                          }`,
                        }}
                      ></span>
                    </div>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <h3 className="fnb-form-label">
                {pageData.reason} <span className="required-field">*</span>
              </h3>
              <Form.Item
                name={"reason"}
                className="form-confirm-reservation"
                initialValue={""}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validateReason} />
                    ),
                  },
                ]}
              >
                <FnbTextArea rows={4} maxLength={255} placeholder={pageData.placeholderReason}></FnbTextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    ) : (
      <div className="confirm-reservation-page">
        <Form form={formConfirm} onFieldsChange={handleFieldsChange}>
          <Row gutter={[28, 32]}>
            <Col span={24}>
              <div className="text-confirm">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${textConfirm}`,
                  }}
                ></span>
              </div>
            </Col>
            <Col lg={12} span={24}>
              <h3 className="fnb-form-label">{pageData.depositMethod}</h3>
              <Form.Item
                name={"depositMethod"}
                className="form-confirm-reservation"
                initialValue={defaultPaymentMethod?.id}
                rules={[
                  {
                    required: isCheckedRefundMethod,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.pleaseSelectRefundMethod}
                      />
                    ),
                  },
                ]}
              >
                <FnbSelectSingle
                  className="deposit-paymentmethod"
                  option={filterPaymentMethod?.map((item, index) => ({
                    id: item.id,
                    name: item.name,
                    key: index,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h3 className="fnb-form-label">{pageData.depositAmount}</h3>
              <p className="text-detail">
                {formatTextNumber(depositAmount) ?? "-"} <span className="text-currency">{getCurrency()}</span>
              </p>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const handleResetForm = () => {
    formConfirm.resetFields();
    setIsCheckedRefundMethod(true);
    const elBtnSubmit = document.getElementById("btn-submit");
    if (elBtnSubmit) {
      elBtnSubmit.classList.remove("fnb-button--disabled--fulfill");
      elBtnSubmit.removeAttribute("disabled");
    }
  };

  const onChangeBranch = (value) => {
    onChangeForm();
    getAreaTables(value);
    let formValue = form.getFieldsValue();
    formValue.listAreaTableId = [];
    form.setFieldsValue(formValue);
  };

  const onChangeAssignedTables = (value) => {
    onChangeForm();
    setListAreaTableId(value);
  };

  const handleUpdateReservationTable = () => {
    form.validateFields().then(async (values) => {
      const currentDate = new Date();
      const isBefore = selectedDate ? currentDate > selectedDate : true;

      let formValue = form.getFieldsValue();
      formValue.guestArrivalTime = isBefore
        ? moment.utc(currentDate.setSeconds(0))
        : moment.utc(selectedDate) ?? undefined;
      let request = {
        ...formValue,
        refundAmount: formValue.refundPaymentMethodId !== undefined ? data?.depositAmount : null,
      };
      if (data?.status === enumReservation.Cancelled) request = { ...request, listAreaTableId: data?.listAreaTableId };
      reserveTableDataService.updateReservationTablesAsync(request).then((res) => {
        if (res) {
          setCurrentCustomerName(request?.customerName);
          setCurrentCustomerPhone(request?.customerPhone);
          const listReservation = res;
          setListReservationTable(listReservation);
          message.success(pageData.updateSuccess);
          setIsChangeForm(false);
        } else {
          message.error(pageData.updateFailed);
        }
      });
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const renderTableReservationList = (item) => {
    return (
      <>
        <div>
          <div className="content-title">
            <div className="select-table-reservation-title">
              <div className="reservation-title">
                <FnbTooltip title={item.areaAreaTableName} onlyShowWhenEllipsis>
                  {item.areaAreaTableName}
                </FnbTooltip>
              </div>
            </div>
            {item?.status === AreaTableStatus.INUSED && <div className="in-use">{t("reservation.inUse")}</div>}
          </div>

          {item.reserveTables.map((item) => {
            return (
              <div className="select-table-reservation-detail">
                <div
                  className="select-table-reservation-code"
                  onClick={() => {
                    handleViewReserveTable(item?.id);
                  }}
                >
                  {item.stringCode}
                </div>
                <div className="select-table-reservation-time">
                  {moment.utc(item.arrivalTime).local().format("HH:mm")}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex-stretch"></div>
      </>
    );
  };

  const handleViewReserveTable = async (id) => {
    window.open(`/report/reservation-detail/${id}`, "_blank");
  };

  const defaultDateTimeValue = moment(new Date()).add(30, "m");
  if (defaultDateTimeValue.minute() > 30) {
    defaultDateTimeValue.add(1, "hour");
    defaultDateTimeValue.set("minute", 0);
  } else {
    defaultDateTimeValue.set("minute", 30);
  }

  // Function to handle date change
  const handleDateChange = (date) => {
    onChangeForm();
    setArrivalDate(date);
    if (arrivalTime) {
      const newDate = date;
      if (newDate.isAfter(defaultDateTimeValue)) {
        setSelectedDate(newDate);
      } else {
        setSelectedDate(defaultDateTimeValue);
      }
    }
  };

  const getDisabledHoursStartTime = () => {
    const currentHour = moment().hour();
    const disabledHours = [];
    if (selectedDate.isSame(moment(new Date()), "date")) {
      for (let i = 0; i < currentHour; i++) {
        disabledHours.push(i);
      }
    } else {
      if (selectedDate.isBefore(moment(new Date()), "date")) {
        for (let i = 0; i < 24; i++) {
          disabledHours.push(i);
        }
      }
    }
    return disabledHours;
  };

  const getDisabledMinutesStartTime = (selectedHour) => {
    var minutes = [];
    if (selectedDate.isSame(moment(new Date()), "date")) {
      if (selectedHour === moment().hour()) {
        for (var i = 0 - 1; i < moment().minute() + 30; i++) {
          minutes.push(i);
        }
      }
    } else {
      if (selectedDate.isBefore(moment(new Date()), "date")) {
        for (let i = 0 - 1; i < 60; i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  // Function to handle time change
  const handleTimeChange = (date) => {
    onChangeForm();
    setArrivalTime(date);
    form.setFieldValue('guestArrivalHour', date);
    if (arrivalDate) {
      const hours = date?.hours();
      const minutes = date?.minutes();
      const newDate = arrivalDate?.clone();
      newDate?.set({ hour: hours, minute: minutes });
      if (newDate.isAfter(defaultDateTimeValue)) {
        setSelectedDate(newDate);
      } else {
        setSelectedDate(defaultDateTimeValue);
      }
    }
  };

  const onOkLeaveDialog = () => {
    setIsChangeForm(false);
    setShowLeaveDialog(false);
    setTimeout(() => {
      history.push(`/report/reservation-detail/${reservationId}`);
    }, 500);
  };

  const onChangeCustomer = (value) => {
    setIsChangeForm(true);
    const customer = customers?.filter((item) => item.id === value);
    if (customer) {
      let formValue = form.getFieldsValue();
      formValue.phoneNumber = customer[0]?.id;
      formValue.customerName = customer[0]?.name || "";
      formValue.customerPhone = customer[0]?.phone || "";
      formValue.customerEmail = customer[0]?.email || "";
      form.setFieldsValue(formValue);
      setCustomerId(customer[0]?.id);
    }
  };

  const onChangePhoneNumber = (value) => {
    setIsChangeForm(true);
    const customer = customers?.filter((item) => item.id === value);
    if (customer) {
      let formValue = form.getFieldsValue();
      formValue.customerId = customer[0]?.id;
      formValue.customerName = customer[0]?.name || "";
      formValue.customerPhone = customer[0]?.phone || "";
      formValue.customerEmail = customer[0]?.email || "";
      form.setFieldsValue(formValue);
      setCustomerId(customer[0]?.id);
    }
  };

  return (
    <div className="edit-detail-reservation">
      <Form form={form} autoComplete="off" onChange={onChangeForm} className="custom-form" layout="vertical">
        <Row className="fnb-row-page-header items-center">
          <Row className="w-100">
            <Col sm={12} span={24}>
              <Row>
                <Col className="mr-3 items-center">
                  <Button
                    icon={<ArrowLeftReservationDetail />}
                    className="btn-back"
                    onClick={() => {
                      handleCancel(data?.id);
                    }}
                  />
                </Col>
                <Col className="mr-3 items-center">
                  <PageTitle className="items-center" content={data?.stringCode} />
                </Col>
                <Col className="items-center">
                  <BadgeReservationStatus status={data?.status} />
                </Col>
              </Row>
            </Col>
            <Col sm={12} span={24}>
              <Col className="content-list-button">
                <div className="list-group-button">
                  {(data?.status === ReservationStatus.WaitToConfirm ||
                    data?.status === ReservationStatus.Confirmed) && (
                    <FnbButton
                      variant="tertiary"
                      danger={true}
                      minWidth="100px"
                      text={data?.status === ReservationStatus.WaitToConfirm ? pageData.reject : pageData.cancel}
                      disabled={
                        data?.status !== ReservationStatus.WaitToConfirm && data?.status !== ReservationStatus.Confirmed
                      }
                      onClick={() => onRejectItem(data?.id, data?.status, data?.depositStatusId, data?.depositAmount)}
                      permission={PermissionKeys.CANCEL_RESERVATION}
                    />
                  )}
                  {data?.status === ReservationStatus.WaitToConfirm && (
                    <FnbButton
                      minWidth="100px"
                      text={pageData.confirm}
                      disabled={
                        data?.status !== ReservationStatus.WaitToConfirm && data?.status !== ReservationStatus.Confirmed
                      }
                      onClick={() => onConfirmItem(data?.id, data?.depositStatusId, data?.depositAmount)}
                      permission={PermissionKeys.EDIT_RESERVATION}
                    />
                  )}
                  <FnbButton
                    variant="secondary"
                    minWidth="100px"
                    text={pageData.btnSaveChanges}
                    disabled={!isChangeForm}
                    onClick={handleUpdateReservationTable}
                    permission={PermissionKeys.EDIT_RESERVATION}
                  />
                </div>
              </Col>
            </Col>
          </Row>
        </Row>
        <div className="clearfix"></div>

        <Row gutter={[0, 24]}>
          <Card className="fnb-card w-100 reservation-detail-card">
            <Row className="title-session">
              <span>{pageData.guestInformation}</span>
              <span className="border-bottom-title-card" />
            </Row>
            <Row className="w-100">
              <Row gutter={[28, 28]} className="min-width-100">
                <Col lg={12} span={24}>
                  <div className="customerInfo">
                    <span>{pageData.customerReservation}</span>:
                    <FnbTypography text={currentCustomerName + " - " + currentCustomerPhone}></FnbTypography>
                  </div>
                  <Form.Item label={pageData.customer} name={"customerId"} className="mb-0">
                    <FnbSelectCustomer
                      showSearch
                      placeholder={pageData.namePlaceHolder}
                      className="w-100"
                      option={customers?.map((item) => ({
                        id: item?.id,
                        name: item?.name,
                        phone: item?.phone,
                        membership: item?.membership,
                        discount: item?.discount,
                      }))}
                      onChange={(value) => onChangeCustomer(value)}
                      customerId={customerId}
                      onClear
                      showPhone={true}
                    />
                  </Form.Item>
                  <Form.Item name={"id"} hidden={true}>
                    <FnbInput />
                  </Form.Item>
                  <Form.Item name={"customerName"} hidden={true}>
                    <FnbInput />
                  </Form.Item>
                  <Form.Item name={"customerPhone"} hidden={true}>
                    <FnbInput />
                  </Form.Item>
                </Col>
                <Col lg={12} span={24}>
                  <div className="customerInfo-space" style={{ visibility: "hidden" }}>
                    .
                  </div>
                  <Form.Item
                    label={pageData.numberOfGuests}
                    name={"numberOfGuests"}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pleaseEnterTheNumberOfGuests}
                          />
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={pageData.numberOfGuestsPlaceHolder}
                      className={`fnb-input-number w-100 `}
                      min={1}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      max={999}
                      onKeyDown={(e) => {
                        if (e.target.value.length >= 3 && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ display: "none" }} lg={12} span={24}>
                  <Form.Item label={pageData.phoneNumber} name={"phoneNumber"}>
                    <FnbSelectCustomer
                      showSearch
                      placeholder={pageData.phoneNumberPlaceHolder}
                      className="w-100"
                      option={customers?.map((item) => ({
                        id: item?.id,
                        name: item?.phone,
                      }))}
                      onChange={(value) => onChangePhoneNumber(value)}
                      customerId={customerId}
                      onClear
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} span={24}>
                  <Form.Item label={pageData.email} name={"customerEmail"} className="mb-0">
                    <FnbInput placeholder={pageData.emailPlaceHolder} maxLength={100} showCount />
                  </Form.Item>
                </Col>
                <Col lg={12} span={24}>
                  <Row gutter={[28, 28]}>
                    <Col lg={12} span={24}>
                      <Form.Item
                        label={pageData.guestArrivalTime}
                        name={"guestArrivalDate"}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.guestArrivalTimePleaseSelect}
                              />
                            ),
                          },
                        ]}
                        className="mb-0"
                      >
                        <DatePicker
                          placeholder={pageData.guestArrivalTimePlaceHolder}
                          className="fnb-date-picker w-100"
                          disabledDate={disabledDate}
                          onChange={handleDateChange}
                          onSelect={handleDateChange}
                          format={DateFormat.DD_MM_YYYY}
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} span={24}>
                      <Form.Item
                        name={"guestArrivalHour"}
                        label={"."}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.guestArrivalTimePleaseSelect}
                              />
                            ),
                          },
                        ]}
                        className="mb-0 hidden-label"
                      >
                        <TimePicker
                          format={DateFormat.HH_MM}
                          minuteStep={30}
                          autoComplete="off"
                          className="fnb-date-picker working-hour-time-picker w-100"
                          popupClassName="fnb-date-time-picker-dropdown fnb-date-time-picker-dropdown-style"
                          placeholder={pageData.guestArrivalTimePlaceHolder}
                          disabledHours={getDisabledHoursStartTime}
                          disabledMinutes={getDisabledMinutesStartTime}
                          showNow={false}
                          allowClear={false}
                          onSelect={(time) => {
                            handleTimeChange(time);
                          }}
                          onChange={(time) => {
                            handleTimeChange(time);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <DepositStatusComponent
                  depositStatusId={depositStatusId}
                  setDepositStatusId={setDepositStatusId}
                  data={data}
                  onChangeForm={onChangeForm}
                  activeAllPaymentMethods={activeAllPaymentMethods}
                />
                {data?.status === ReservationStatus.Cancelled && (
                  <Col lg={12} span={24}>
                    <Form.Item name={"reason"} initialValue={""} label={pageData.reason}>
                      <FnbTextArea
                        rows={3}
                        maxLength={255}
                        placeholder={pageData.placeholderReason}
                        value={data?.reason}
                      ></FnbTextArea>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Row>
          </Card>
          {isShowTableInfo && (
            <Card className="fnb-card w-100 reservation-detail-card">
              <Row className="title-session">
                <span>{pageData.tableInformation}</span>
                <span className="border-bottom-title-card" />
              </Row>
              <Row className="w-100">
                <Row gutter={[28, 28]} className="min-width-100">
                  <Col lg={12} span={24}>
                    <Form.Item
                      label={pageData.branch}
                      name="branchId"
                      className="last-item"
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.pleaseSelectTheBranch}
                            />
                          ),
                        },
                      ]}
                    >
                      <FnbSelectSingle
                        placeholder={pageData.branchPlaceHolder}
                        className="w-100"
                        allowClear
                        option={branches?.map((item) => ({
                          id: item.id,
                          name: item.name,
                        }))}
                        onChange={(value) => onChangeBranch(value)}
                      ></FnbSelectSingle>
                    </Form.Item>
                  </Col>
                  <Col lg={12} span={24}>
                    <Form.Item label={pageData.note} name={"notes"} className="mb-0">
                      <FnbInput placeholder={pageData.enterBusinessName} maxLength={255} showCount />
                    </Form.Item>
                  </Col>
                  <Col lg={12} span={24}>
                    <Form.Item
                      label={pageData.assignedTables}
                      name={"listAreaTableId"}
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.assignedTablesPleaseSelect}
                            />
                          ),
                        },
                      ]}
                      className="mb-0"
                    >
                      <FnbSelectMultipleOptGroup
                        className="item-input"
                        placeholder={pageData.assignedPlaceHolder}
                        optionGroup={areaTables?.map((item) => ({
                          id: item?.id,
                          name: item?.name,
                          group: item?.areaTables,
                        }))}
                        showSearch
                        allowClear
                        onChange={(value) => onChangeAssignedTables(value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>

              <Row className="w-100">
                <Col lg={12} span={24}>
                  <div className="assign-tables">
                    {listReservationTable?.length > 0 && (
                      <div className="select-table-reservation-list">
                        {listReservationTable.map((item) => renderTableReservationList(item))}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Card>
          )}
        </Row>
      </Form>

      <ConfirmDialogComponent
        title={titleDialogConfirm}
        visible={isDialogConfirm}
        skipPermission={true}
        cancelText={pageData.ignore}
        okText={titleDialogConfirm}
        minWidthBtnOk="180px"
        onCancel={handleHiddenDialog}
        onOk={() => updateReservationStatus(reservationId, statusReservation)}
        contentElement={
          isReservationDialogRefund ? (
            renderContentElement()
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: `${actionDialogConfirm}`,
              }}
            ></span>
          )
        }
        type={ConfirmStyle.NOTIFICATION}
        width={446}
        idBtnOk={"btn-submit"}
        onAfterClose={handleResetForm}
      />

      <ConfirmDialogComponent
        visible={showLeaveDialog}
        skipPermission={true}
        cancelText={pageData.leaveDialog.discardBtn}
        onCancel={handleDiscardDialog}
        onOk={onOkLeaveDialog}
        isChangeForm={isChangeForm}
      />
    </div>
  );
}
