export const IngredientRemoveRequestHistoryType = {
  CREATED: 0,
  EDITED: 1,
  REJECTED: -5,
  APPROVED: 3,
};

export const IngredientRemoveRequestHistoryLocalization = {
  [IngredientRemoveRequestHistoryType.CREATED]: 'materialControl.historyRemoveRequest.actionType.created',
  [IngredientRemoveRequestHistoryType.EDITED]: 'materialControl.historyRemoveRequest.actionType.edited',
  [IngredientRemoveRequestHistoryType.REJECTED]: 'materialControl.historyRemoveRequest.actionType.rejected',
  [IngredientRemoveRequestHistoryType.APPROVED]: 'materialControl.historyRemoveRequest.actionType.approved'
};
