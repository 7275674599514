import { Card, Col, Form, message } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbTable } from "components/fnb-table/fnb-table";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { tableSettings } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import branchDataService from "data-services/branch/branch-data.service";
import materialCategoryDataService from "data-services/material-category/material-category-data.service";
import materialDataService from "data-services/material/material-data.service";
import purchaseOrderDataService from "data-services/purchase-order/purchase-order-data.service";
import unitDataService from "data-services/unit/unit-data.service";
import { env } from "env";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import languageService from "services/language/language.service";
import {
  formatNumberDecimalOrInteger,
  getCurrency,
  getShortValue,
  getSuffixShortValue,
  getThumbnailUrl,
  hasPermission,
  Sort,
} from "utils/helpers";
import "../index.scss";
import CheckDeleteMaterial from "./check-delete-material.component";
import { FilterPopover } from "./filter-popover.component";
import { useDebounce } from "themes/theme-1-new/hooks";
import "./table-material.component.scss";
import { WalletIcon } from "constants/icons.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { EnumMaterialDependencyType } from "constants/level-menu.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

const { forwardRef, useImperativeHandle } = React;
export const TableMaterial = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    exportFilter(data) {
      exportMaterial(data);
    },
  }));
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    materialManagement: t("material.materialManagement"),
    searchPlaceholder: t("material.searchPlaceholder"),
    addNew: t("button.addNew"),
    import: t("button.import"),
    export: t("button.export"),
    filter: t("button.filter"),
    //#region table header
    sku: t("table.sku"),
    ingredientName: t("material.table.ingredientName"),
    totalQuantity: t("table.totalQuantity"),
    costPerUnit: t("material.table.costPerUnit"),
    status: t("table.status"),
    //#endregion
    //#region table body
    statusType: {
      active: t("status.active"),
      inactive: t("status.inactive"),
    },
    //#endregion
    action: t("table.action"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteContent: t("messages.confirmDeleteContent"),
    notificationTitle: t("form.notificationTitle"),
    isDeletedSuccessfully: t("messages.isDeletedSuccessfully"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    tableShowingRecordMessage: t("material.tableShowingRecordMessage"),
    total: t("material.total"),
    toCreateData: t("area.toCreateData"),
  };

  const filterPopoverRef = React.useRef();
  const [totalMaterial, setTotalMaterial] = useState(0);
  const [listMaterial, setListMaterial] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [materialCategories, setMaterialCategories] = useState([]);
  const [branches, setBranches] = useState([]);
  const [units, setUnits] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPurchaseOrderVisible, setIsModalPurchaseOrderVisible] = useState(false);
  const [exportFilter, setExportFilter] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listObjectLockMaterial, setListObjectLockMaterial] = useState({});
  const [filterData, setFilterData] = useState(true);
  const [totalCostMaterials, setTotalCostMaterials] = useState(0);
  const [materialDependencyType, setMaterialDependencyType] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    initDataTableMaterials(tableSettings.page, tableSettings.pageSize, keySearch);
    materialCategoryDataService.getAllMaterialCategoriesAsync().then((resCategory) => {
      if (resCategory) {
        const allCategoryOption = {
          id: "",
          name: t("material.filter.category.all"),
        };
        const categoryOptions = [allCategoryOption, ...resCategory.materialCategories];
        setMaterialCategories(categoryOptions);
      }
    });
    branchDataService.getAllBranchsAsync().then((resBranch) => {
      if (resBranch) {
        const allBranchOption = {
          id: "",
          name: t("material.filter.branch.all"),
        };
        const branchOptions = [allBranchOption, ...resBranch.branchs];
        setBranches(branchOptions);
      }
    });
    unitDataService.getUnitsAsync().then((resUnit) => {
      if (resUnit) {
        const allUnitOption = {
          id: "",
          name: t("material.filter.unit.all"),
        };
        const unitOptions = [allUnitOption, ...resUnit.units];
        setUnits(unitOptions);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebounce(
    () => {
      let filter = {
        unitId: filterData?.unitId,
        branchId: filterData?.branchId,
        materialCategoryId: filterData?.materialCategoryId,
        isActive: filterData?.isActive,
        count: filterData?.count,
      };
      setExportFilter(filter);

      materialDataService
        .getMaterialsByFilterAsync(
          tableSettings.page,
          tableSettings.pageSize,
          keySearch,
          filterData?.unitId ?? "",
          filterData?.branchId ?? "",
          filterData?.materialCategoryId ?? "",
          filterData?.isActive ?? "",
        )
        .then((res) => {
          let materials = mappingToDataTableMaterials(res.materials);
          setListMaterial(materials);
          setTotalMaterial(res.total);
          setCountFilter(filterData?.count);
          setSelectedRowKeys([]);
        });
    },
    [filterData],
    500,
  );

  const initDataTableMaterials = (pageNumber, pageSize, keySearch) => {
    materialDataService
      .getMaterialsByFilterAsync(
        pageNumber,
        pageSize,
        keySearch,
        filterData?.unitId ?? "",
        filterData?.branchId ?? "",
        filterData?.materialCategoryId ?? "",
        filterData?.isActive ?? "",
      )
      .then((res) => {
        let materials = mappingToDataTableMaterials(res.materials);
        setListMaterial(materials);
        setTotalMaterial(res.total);
        setCurrentPageNumber(pageNumber);
        setTotalCostMaterials(res.totalCostAllMaterial);
      });
  };

  const onChangePage = (page, pageSize) => {
    initDataTableMaterials(page, pageSize, keySearch);
  };

  const mappingToDataTableMaterials = (materials) => {
    return materials?.map((i, index) => {
      return {
        ...i,
        id: i.id,
        key: i.id,
        name: i.name,
        description: i.description,
        sku: i.sku,
        quantity: i.quantity ? i.quantity : 0,
        unit: i.unitName,
        cost: i.costPerUnit ? i.costPerUnit : 0,
        isActive: i.isActive,
      };
    });
  };

  const onEditRowKeys = (selectedRowKeys, selectedRows) => {
    history.push(`/inventory/material/edit-material/${selectedRows[0]?.id}`);
    setSelectedRowKeys([]);
  };

  const onDeleteRowKeys = async (selectedRowKeys, selectedRows) => {
    var response = await materialDataService.checkDeleteMaterialByIdAsync(selectedRows[0]?.id);
    if (response?.isInUse) {
      setListObjectLockMaterial(response);
      setMaterialDependencyType(response?.type);
      if (response?.type === EnumMaterialDependencyType.PURCHASE_ORDER) {
        setIsModalPurchaseOrderVisible(true);
      } else {
        setIsModalVisible(true);
      }
    } else {
      await purchaseOrderDataService.getPurchaseOrderByMaterialIdAsync(selectedRows[0]?.id).then((res) => {
        setListObjectLockMaterial([]);
        setSelectedItem(selectedRows[0]);
        setIsModalVisible(true);
      });
    }
    setSelectedRowKeys([]);
  };

  const onDelete = async () => {
    await materialDataService.deleteMaterialManagementAsync(selectedItem?.id).then((res) => {
      if (res) {
        setIsModalVisible(false);
        initDataTableMaterials(tableSettings.page, tableSettings.pageSize, keySearch);
        message.success(`${selectedItem?.name} ${pageData.isDeletedSuccessfully}`);
        setSelectedItem({});
      }
    });
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.sku,
        width: 200,
        dataIndex: "sku",
      },
      {
        title: pageData.ingredientName,
        dataIndex: "name",
        className: "col-name",
        sorter: (a, b) => Sort(a?.name, b?.name),
        render: (_, record) => {
          let href = `/inventory/material/detail/${record.id}`;
          return (
            <div className="table-img-box">
              <Thumbnail width={32} height={32} src={getThumbnailUrl(record?.thumbnail, "mobile")} />
              <FnbTooltip onlyShowWhenEllipsis={true} maxWidthContent={"calc(100% - 40px)"}>
                {record.name}
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.totalQuantity,
        dataIndex: "quantity",
        width: 150,
        align: "right",
        sorter: (a, b) => Sort(a?.quantity, b?.quantity),
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} maxWidthContent={300} className="col-quantity">
              {`${formatNumberDecimalOrInteger(record?.quantity)} ${record?.unit}`}
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.costPerUnit,
        dataIndex: "cost",
        align: "right",
        width: 150,
        sorter: (a, b) => Sort(a?.cost, b?.cost),
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} maxWidthContent={300} className="col-cost">
              {`${formatNumberDecimalOrInteger(record?.cost)} ${getCurrency()}/${record?.unit}`}
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.status,
        dataIndex: "isActive",
        key: "isActive",
        width: "9rem",
        align: "center",
        render: (_, record) => {
          return (
            <FnbBadge
              variant={record?.isActive ? "success" : "error"}
              text={record?.isActive ? pageData.statusType.active : pageData.statusType.inactive}
            />
          );
        },
      },
    ].filter((item) => !item.hidden);

    return columns;
  };

  const onSearch = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        searchKeyAndFilterMaterials(tableSettings.page, tableSettings.pageSize, keySearch, exportFilter);
      }, 500),
    );
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const searchKeyAndFilterMaterials = (pageNumber, pageSize, keySearch, filter) => {
    materialDataService
      .getMaterialsByFilterAsync(
        pageNumber,
        pageSize,
        keySearch,
        Object.keys(filter).length !== 0 ? filter.unitId ?? "" : "",
        Object.keys(filter).length !== 0 ? filter.branchId ?? "" : "",
        Object.keys(filter).length !== 0 ? filter.materialCategoryId ?? "" : "",
        Object.keys(filter).length !== 0 ? filter.isActive ?? "" : "",
      )
      .then((res) => {
        let materials = mappingToDataTableMaterials(res.materials);
        setListMaterial(materials);
        setTotalMaterial(res.total);
        setCountFilter(filter?.count);
        setTotalCostMaterials(res.totalCostAllMaterial);
        setSelectedRowKeys([]);
      });
  };

  const exportMaterial = (storeId) => {
    let languageCode = languageService.getLang();
    const link = document.createElement("a");
    link.href = `${env.REACT_APP_ROOT_DOMAIN}/api${
      env.API_VERSION ?? ""
    }/material/export-material?languageCode=${languageCode}&&storeId=${storeId}&keySearch=${keySearch}
    &unitId=${exportFilter?.unitId ? exportFilter?.unitId : ""}&branchId=${
      exportFilter?.branchId ? exportFilter?.branchId : ""
    }&materialCategoryId=${exportFilter?.materialCategoryId ? exportFilter?.materialCategoryId : ""}&isActive=${
      exportFilter?.isActive === true || exportFilter?.isActive === false ? exportFilter?.isActive : ""
    }`;
    link.click();
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
    setCountFilter(0);
  };

  const filterComponent = () => {
    return (
      <FilterPopover
        ref={filterPopoverRef}
        fetchDataMaterials={setFilterData}
        categories={materialCategories}
        branches={branches}
        units={units}
      />
    );
  };

  const onRowClick = (record, _index) => {
    history.push(`/inventory/material/detail/${record.id}`);
  };

  return (
    <>
      <Form className="table-material-management">
        <Card className="w-100 fnb-card-custom total-cost">
          <div className="label">
            <WalletIcon width={32} height={32} className="total-cost-icon" />
            <span>{pageData.total}</span>
          </div>
          <div className="short-title">
            <span className="value">{getShortValue(totalCostMaterials)}</span>
            <span className="suffix">{getSuffixShortValue(totalCostMaterials)}</span>
          </div>
          <div className="long-title">
            <span>{formatNumberDecimalOrInteger(totalCostMaterials)}</span>
            <span className="suffix">{getCurrency()}</span>
          </div>
        </Card>
        <Card className="w-100 fnb-card-custom">
          <Col span={24}>
            <FnbTable
              columns={getColumns()}
              pageSize={tableSettings.pageSize}
              dataSource={listMaterial}
              currentPageNumber={currentPageNumber}
              total={totalMaterial}
              onChangePage={onChangePage}
              editPermission={PermissionKeys.EDIT_MATERIAL}
              deletePermission={PermissionKeys.DELETE_MATERIAL}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                onEdit: onEditRowKeys,
                onDelete: onDeleteRowKeys,
              }}
              search={{
                placeholder: pageData.searchPlaceholder,
                onChange: onSearch,
              }}
              filter={{
                totalFilterSelected: countFilter,
                onClearFilter: onClearFilter,
                buttonTitle: pageData.filter,
                component: filterComponent(),
              }}
              footerMessage={pageData.tableShowingRecordMessage}
              onRowClick={onRowClick}
              emptyText={
                hasPermission(PermissionKeys.CREATE_MATERIAL) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.addNew}
                      onClick={() => history.push("/material/add-new")}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Col>
        </Card>
      </Form>
      <CheckDeleteMaterial
        materialDependencyType={materialDependencyType}
        isModalVisible={isModalVisible}
        isModalPurchaseOrderVisible={isModalPurchaseOrderVisible}
        listObjectLockMaterial={listObjectLockMaterial}
        handleCancel={() => {
          setIsModalVisible(false);
          setIsModalPurchaseOrderVisible(false);
        }}
        onDelete={onDelete}
      />
    </>
  );
});
