/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Row, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { tableSettings } from "constants/default.constants";
import { CrownIcon, DeleteOutlined } from "constants/icons.constants";
import { languageCode } from "constants/language.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { RoutePathConstants } from "constants/route-key-path.constants";
import kitchenDataService from "data-services/kitchen/kitchen-data.service";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import theme from "theme";
import { executeAfter, hasPermission } from "utils/helpers";
import "./table-kitchen.component.scss";

export default function TableKitchen(props) {
  const { selectedBranchId } = props;
  const [t] = useTranslation();
  const history = useHistory();

  const linkToOrderDetail = "/report/order/detail/";

  const pageData = {
    kitchenManagement: t("kitchen.kitchenManagement"),
    kitchenName: t("kitchen.kitchenName"),
    no: t("table.no"),
    productCategory: t("kitchen.productCategory"),
    btnDelete: t("button.yesDelete"),
    textConfirmDelete: t("kitchen.yesDelete"),
    btnIGotIt: t("button.iGotIt"),
    btnIgnore: t("kitchen.noStayOnPage"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteKitchenContent: t("kitchen.confirmDeleteKitchenContent"),
    kitchenDeletedSuccess: t("kitchen.kitchenDeletedSuccess"),
    kitchenDeletedFail: t("kitchen.kitchenDeletedFail"),
    searchByName: t("kitchen.searchByName"),
    default: t("kitchen.default"),
    defaultKitchenContent1: t("kitchen.defaultKitchenContent1"),
    defaultKitchenContent2: t("kitchen.defaultKitchenContent2"),
    defaultKitchenContent3: t("kitchen.defaultKitchenContent3"),
    isStillProcessingOrdersBelow: t("kitchen.isStillProcessingOrdersBelow"),
    soTheKitchenCannotBeDeleted: t("kitchen.soTheKitchenCannotBeDeleted"),
    notificationTitle: t("form.notificationTitle"),
  };

  const [kitchens, setKitchens] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [pageNumber, setPageNumber] = useState(tableSettings.page);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [orderProcessingInKitchenProductCategories, setOrderProcessingInKitchenProductCategories] = useState([]);

  useEffect(() => {
    if (selectedBranchId) {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      void getKitchensByBranchWithPaging(tableSettings.page, tableSettings.pageSize, keySearch);
    }
  }, [selectedBranchId]);

  const getKitchensByBranchWithPaging = async (pageNumber, pageSize, keySearch) => {
    setPageNumber(pageNumber);
    const request = {
      branchId: selectedBranchId,
      pageSize: pageSize,
      pageNumber: pageNumber,
      keySearch: keySearch,
    };
    const res = await kitchenDataService.getKitchensByBranchIdWithPagingAsync(request);
    if (res) {
      const kitchens = mappingKitchens(res?.kitchens);
      setKitchens(kitchens);
      setTotalRecord(res?.total);
    }
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteKitchenContent, { kitchenName: name });
    return mess;
  };

  const onChangePage = async (pageNumber, pageSize) => {
    getKitchensByBranchWithPaging(pageNumber, pageSize, keySearch);
  };

  const mappingKitchens = (kitchens) => {
    return kitchens?.map((item, index) => {
      return {
        key: item?.id,
        id: item?.id,
        name: item?.name,
        description: item?.description,
        isDefault: item?.isDefault,
        kitchenProductCategories: item?.kitchenProductCategories,
      };
    });
  };

  const getTextLocalize = (localizationKey, text) => {
    const localizations = [t(localizationKey, { lng: languageCode.vi }), t(localizationKey, { lng: languageCode.en })];
    return localizations?.includes(text.replace(/<[^>]*>/g, "").replace("&nbsp;", "")) ? t(localizationKey) : text;
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.kitchenName,
        dataIndex: "name",
        ellipsis: true,
        render: (_, record) => (
          <div className="container-column-kitchen-name">
            <div className="row-data-kitchen-name">
              <FnbTooltip title={record?.isDefault ? getTextLocalize("kitchen.defaultKitchen", record?.name) : record?.name} onlyShowWhenEllipsis maxWidthContent={800} maxWidth={500}>
                <FnbTypography
                  text={record?.isDefault ? getTextLocalize("kitchen.defaultKitchen", record?.name) : record?.name}
                />
              </FnbTooltip>
              {record?.isDefault && (
                <div className="div-default-content">
                  <FnbTooltip title={renderTooltipContent()} placement="bottomLeft" variant="secondary" maxWidth={400}>
                    <div className="div-row-default-kitchen-content">
                      <div>
                        <CrownIcon width={20} height={20} />
                      </div>
                      <FnbTypography
                        variant={theme.typography["b1-medium"]}
                        text={pageData.default}
                        color={theme.colors.secondary[70]}
                      />
                    </div>
                  </FnbTooltip>
                </div>
              )}
            </div>
            {record?.description && (
              <FnbTooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: record?.isDefault
                        ? getTextLocalize("kitchen.defaultKitchenDescription", record?.description)
                        : record?.description?.replace(/<.*?>/gm, ""),
                    }}
                  />
                }
                maxWidth={500}
              >
                <div
                  className="kitchen-item-description"
                  dangerouslySetInnerHTML={{
                    __html: record?.isDefault
                      ? getTextLocalize("kitchen.defaultKitchenDescription", record?.description)
                      : record?.description?.replace(/<.*?>/gm, ""),
                  }}
                />
              </FnbTooltip>
            )}
          </div>
        ),
      },
      {
        title: pageData.productCategory,
        dataIndex: "kitchenProductCategories",
        ellipsis: true,
        render: (_, record) => {
          const data = [];
          record?.kitchenProductCategories?.forEach((kitchenProductCategory) => {
            let text = kitchenProductCategory?.name;
            const item = {
              title: text,
              detail: text,
            };
            data.push(item);
          });
          return <MemoizedFnbListChips data={data} isOptionChip={false} />;
        },
      },
    ];

    return columns;
  };

  const onSearch = (keySearch) => {
    executeAfter(500, async () => {
      setKeySearch(keySearch);
      getKitchensByBranchWithPaging(tableSettings.page, tableSettings.pageSize, keySearch);
    });
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const handleShowModalDelete = async () => {
    // TODO: Verify status of order/orderItem later
    // const resVerified = await verifyKitchenBeforeDelete();
    setOrderProcessingInKitchenProductCategories([]);
    setIsShowDeleteModal(true);
  };

  const verifyKitchenBeforeDelete = async () => {
    let result = {
      isValid: false,
      orderProcessingInKitchenProductCategories: [],
    };
    const res = await kitchenDataService.verifyKitchenByIdAsync({
      id: selectedRows[0]?.id,
      branchId: selectedBranchId,
    });
    if (res) {
      if (!res?.isValid) {
        if (res?.orderProcessingInKitchenProductCategories) {
          const resOrderList = res?.orderProcessingInKitchenProductCategories?.map((item) => {
            const label = item?.stringCode;
            const link = `${linkToOrderDetail}${item?.id}`;
            return { label, link };
          });
          result.orderProcessingInKitchenProductCategories = resOrderList;
        }
      } else {
        result.isValid = true;
      }
    }
    return result;
  };

  const onEditItem = (selectedRowKeys, selectedRows) => {
    history.push(RoutePathConstants.editKitchen.replace(":id", selectedRows?.[0]?.id));
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const onDeleteItem = async () => {
    if (selectedRows && selectedRows?.length > 0) {
      const res = await kitchenDataService.deleteKitchenByIdAsync(selectedRows[0]?.id);
      if (res) {
        message.success(pageData.kitchenDeletedSuccess);
        // Recount selected items after delete
        if (selectedRowKeys) {
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }
      } else {
        message.error(pageData.kitchenDeletedFail);
      }
      handleHideModalDelete();
      await getKitchensByBranchWithPaging(tableSettings.page, tableSettings.pageSize, keySearch);
    }
  };

  const handleHideModalDelete = () => {
    setIsShowDeleteModal(false);
  };

  const renderTooltipContent = () => {
    return (
      <div className="div-tooltip-default-kitchen-content">
        <div className="">{parse(pageData.defaultKitchenContent1)}</div>
        <div className="spacing-tooltip-content">{parse(pageData.defaultKitchenContent2)}</div>
        <div className="spacing-tooltip-content">{parse(pageData.defaultKitchenContent3)}</div>
      </div>
    );
  };

  const renderMessageNotification = () => {
    return (
      <div className="div-message-cannot-delete-kitchen">
        <div className="message-cannot-delete-kitchen">
          {parse(t(pageData.isStillProcessingOrdersBelow, { kitchenName: selectedRows[0]?.name }))}
        </div>
        <div className="message-cannot-delete-kitchen">{parse(pageData.soTheKitchenCannotBeDeleted)}</div>
      </div>
    );
  };

  const renderBodyNotificationModal = () => {
    return (
      <div>
        {renderMessageNotification()}
        {renderOrderIdList()}
        {renderButtonIGotIt()}
      </div>
    );
  };

  const renderOrderIdList = () => {
    return (
      <div className="div-order-list-processing">
        <Row className="row-order-list-processing">
          {orderProcessingInKitchenProductCategories?.map((item, index) => {
            return (
              <Col span={12} className="col-order-list-processing">
                <Link to={item?.link} target="_blank" className="order-item-id">
                  {item?.label}
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  const renderButtonIGotIt = () => {
    return (
      <div className="div-button-i-got-it">
        <div className="button-i-got-it" onClick={handleHideModalDelete}>
          {pageData.btnIGotIt}
        </div>
      </div>
    );
  };

  return (
    <>
      <Card className="fnb-card-custom card-table-kitchen-management">
        <FnbTable
          className={"kitchen-table-management"}
          columns={getColumns()}
          pageSize={tableSettings.pageSize}
          dataSource={kitchens}
          currentPageNumber={pageNumber}
          total={totalRecord}
          onChangePage={onChangePage}
          editPermission={PermissionKeys.EDIT_KITCHEN}
          deletePermission={PermissionKeys.DELETE_KITCHEN}
          search={{
            placeholder: pageData.searchByName,
            onChange: onSearch,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditItem,
            renderActions: (_, selectedRows) => {
              return (
                <>
                  {hasPermission(PermissionKeys.DELETE_KITCHEN) && (
                    <FnbTooltip placement="top" title={pageData.btnDelete} zIndex={10}>
                      <FnbButton
                        variant="text"
                        className="row-delete-button"
                        iconHeader={<DeleteOutlined />}
                        onClick={handleShowModalDelete}
                        disabled={selectedRows?.length > 1 || selectedRows?.some((item) => item?.isDefault)}
                      />
                    </FnbTooltip>
                  )}
                </>
              );
            },
          }}
        />
      </Card>

      <ConfirmDialogComponent
        visible={isShowDeleteModal && orderProcessingInKitchenProductCategories?.length === 0}
        onOk={onDeleteItem}
        okText={pageData.textConfirmDelete}
        onCancel={handleHideModalDelete}
        cancelText={pageData.btnIgnore}
        type={ConfirmStyle.DELETE}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        centered={true}
      />

      <FnbModal
        className="modal-notification-cannot-delete-kitchen"
        width={440}
        title={pageData.notificationTitle}
        visible={isShowDeleteModal && orderProcessingInKitchenProductCategories?.length !== 0}
        handleCancel={handleHideModalDelete}
        hideFooter={true}
        content={renderBodyNotificationModal()}
      />
    </>
  );
}
