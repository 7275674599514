import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { handleHyperlinkValue } from "../../../../../utils/helpers";

const SignatureProductComponent = (props) => {
  const { index, title, description, buttonText, imageUrl, hyperlinkValue, hyperlinkType, fontFamily, isCustomize } =
    props;
  const { t } = useTranslation();
  const translateData = {
    signatureProduct: t("signature.signatureProduct", "Signature product"),
  };
  const handleHyperlink = (hyperlinkType, hyperlinkValue) => {
    if (hyperlinkType) {
      window.location.href = handleHyperlinkValue(hyperlinkType, hyperlinkValue);
    }
  };
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });

  return (
    <Row className="signature-product-container main-session" style={fontFamily ? { fontFamily: fontFamily } : {}}>
      {isMaxWidth575 ? (
        <Row className="signature-product-mobile">
          <p className="signature-title">{translateData.translateData}</p>
        </Row>
      ) : (
        <Col className="signature-product-image">
          <img src={imageUrl} alt="Signature" />
        </Col>
      )}

      <Col className="signature-product-info">
        {isMaxWidth575 ? (
          <Col className="signature-product-image">
            <img src={imageUrl} alt="Signature" />
          </Col>
        ) : (
          <Row className="signature-product">
            <p className="signature-title">{translateData.signatureProduct}</p>
          </Row>
        )}
        <Row className="signature-product-title">{title}</Row>
        <Row id={`signature-product-description-${index}`} className="signature-product-description">
          {description}
        </Row>
        <Row className="try-now-btn">
          <button
            className="signature-product-btn"
            style={fontFamily ? { fontFamily: fontFamily } : {}}
            onClick={() => !isCustomize && handleHyperlink(hyperlinkType, hyperlinkValue)}
          >
            <div className="box-content">
              <span className="text-line-clamp-1">{buttonText}</span>
            </div>
          </button>
        </Row>
      </Col>
    </Row>
  );
};

export default SignatureProductComponent;
