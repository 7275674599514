import { createAsyncThunk, createDraftSafeSelector, createSlice } from '@reduxjs/toolkit/dist';
import commonDataService from 'data-services/common/common-data.service';
import defaultUserIcon from 'assets/images/default-user.png';
import { Col, Image, Popover, Row } from 'antd';

const initialState = {
  loadingBranch: false,
  branches: undefined,
  loadingArea: false,
  areas: undefined,
  loadingStaffs: false,
  staffs: undefined,
  loadingGroupPermissions: false,
  groupPermissions: undefined,
};

export const getAllBranchManagement = createAsyncThunk(
  '/branch/get-all-branch-management',
  async () => {
    const response = await commonDataService.getAllBranchManagementAsync();
    return response;
  }
);

export const getAreasAreaTablesByBranchId = createAsyncThunk(
  '/area/branch',
  async (params) => {
    const response = await commonDataService.getAreasAreaTablesByBranchIdAsync(params);
    return response;
  }
);

export const getAllStaff = createAsyncThunk(
  '/staff',
  async (params) => {
    const response = await commonDataService.getAllStaffAsync(params);
    return response;
  }
);

export const getAllGroupPermissions = createAsyncThunk(
  '/groupPermissions',
  async (params) => {
    const response = await commonDataService.getAllGroupPermissionsAsync(params);
    return response;
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBranchManagement.pending, (state) => {
        state.loadingBranch = true;
      })
      .addCase(getAllBranchManagement.fulfilled, (state, action) => {
        state.loadingBranch = false;
        state.branches = action.payload?.branchs;
      })
      .addCase(getAllBranchManagement.rejected, (state) => {
        state.loadingBranch = false;
        state.branches = undefined;
      })
      .addCase(getAreasAreaTablesByBranchId.pending, (state) => {
        state.loadingArea = true;
      })
      .addCase(getAreasAreaTablesByBranchId.fulfilled, (state, action) => {
        state.loadingArea = false;
        state.areas = action.payload;
      })
      .addCase(getAreasAreaTablesByBranchId.rejected, (state) => {
        state.loadingArea = false;
        state.areas = undefined;
      })
      .addCase(getAllStaff.pending, (state) => {
        state.loadingStaffs = true;
      })
      .addCase(getAllStaff.fulfilled, (state, action) => {
        state.loadingStaffs = false;
        state.staffs = action.payload?.staffs;
      })
      .addCase(getAllStaff.rejected, (state) => {
        state.loadingStaffs = false;
        state.staffs = undefined;
      })
      .addCase(getAllGroupPermissions.pending, (state) => {
        state.loadingGroupPermissions = true;
      })
      .addCase(getAllGroupPermissions.fulfilled, (state, action) => {
        state.loadingGroupPermissions = false;
        state.groupPermissions = action.payload?.groupPermissions;
      })
      .addCase(getAllGroupPermissions.rejected, (state) => {
        state.loadingGroupPermissions = false;
        state.groupPermissions = undefined;
      });
  },
});

export const commonActions = commonSlice.actions;

export const commonSelector = (state) => state.common;

export const branchesSelector = (state) => state.common?.branches;

export const branchOptionSelector = createDraftSafeSelector(branchesSelector, (branches) =>
  branches?.map((branch) => {
        return { label: branch?.name, value: branch?.id };
    })
);

export const areasSelector = (state) => state.common?.areas;

export const areaOptionSelector = createDraftSafeSelector(areasSelector, (areas) =>
  areas?.map((area) => {
        return { label: area?.name, value: area?.id };
    })
);

export const staffsSelector = (state) => state.common?.staffs;

export const staffOptionSelector = createDraftSafeSelector(staffsSelector, (staffs) =>
  staffs?.map((staff) => {
    return {
      label:
        <div className="staff-name">
          <Image preview={false} width={24} height={24} src={staff?.thumbnail ?? defaultUserIcon} />
          <div>{staff?.fullName}</div>
        </div>, value: staff?.id, groupPermissions: staff?.groupPermissions,
      labelSearch: staff?.fullName,
    };
  })
);

export const groupPermissionsSelector = (state) => state.common?.groupPermissions;

export const groupPermissionsOptionSelector = createDraftSafeSelector(groupPermissionsSelector, (groupPermissions) =>
  groupPermissions?.map((groupPermission) => {
    return {
      label: <div className="staff-name">{groupPermission.name}</div>, value: groupPermission?.id,
      labelSearch: groupPermission.name
    };
  })
);

const commonReducer = commonSlice.reducer;

export default commonReducer;