import { InfoCircleOutlined, PercentageOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Typography, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";
import { ComboType } from "constants/combo.constants";
import { DELAYED_TIME, MaximumNumber } from "constants/default.constants";
import { CalendarNewIconBold, DiscountIcon, TrashFill, UploadLogoIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat, currency } from "constants/string.constants";
import comboDataService from "data-services/combo/combo-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  checkOnKeyPressValidation,
  combinationPossible,
  formatCurrency,
  getCurrency,
  roundNumber,
  formatNumberDecimalOrInteger
} from "utils/helpers";
import "./form-create-combo.scss";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbCancelButton } from "components/cancel-button";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import FnbSelectProduct from "components/fnb-select-product";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import branchDataService from "data-services/branch/branch-data.service";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";

export default function FormCreateComboComponent(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();

  const initProductGroup = {
    categoryId: null,
    quantity: 1,
    productIds: [],
  };
  const combos = {
    flexibleCombo: {
      name: t("combo.flexibleCombo"),
      value: 0,
    },
    specificCombo: {
      name: t("combo.specificCombo"),
      value: 1,
    },
  };
  const prices = {
    fixed: {
      name: t("combo.price.fixed"),
      value: 0,
    },
    specific: {
      name: t("combo.price.specific"),
      value: 1,
    },
  };
  const [form] = Form.useForm();
  const [formChanged, setFormChanged] = useState(false);
  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProductPriceOptions, setAllProductPriceOptions] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategoryIds, setSelectedProductCategoryIds] = useState([]);
  const [selectedProductPriceIds, setSelectedProductPriceIds] = useState([]); /// group combo by product price id
  const [productCombos, setProductCombos] = useState([]);
  const [sellingFixedPrice, setSellingFixedPrice] = useState(0);
  const [productGroups, setProductGroups] = useState([initProductGroup]);
  const [selectedComboType, setSelectedComboType] = useState(combos.flexibleCombo.value);
  const [selectedPriceType, setSelectedPriceType] = useState(prices.fixed.value);
  const [selectedSpecificProducts, setSelectedSpecificProducts] = useState([]);
  const [totalOriginalPriceOfSpecificCombo, setTotalOriginalPriceOfSpecificCombo] = useState(0);
  const [discountPercentAmountOfSpecificCombo, setDiscountPercentAmountOfSpecificCombo] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [disableAllBranches, setDisableAllBranches] = useState(false);
  const [isShowAllBranches, setIsShowAllBranches] = useState(false);
  const [allproductsWithCategory, setAllproductsWithCategory] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [maxPrice, setMaxPrice] = useState(MaximumNumber);
  const [comboParentName, setComboName] = useState(null);
  const productGroupsWatch = Form.useWatch("productGroups", form);

  const pageData = {
    title: t("combo.createCombo"),
    btnCancel: t("button.cancel"),
    btnCreate: t("button.create"),
    btnIgnore: t("button.ignore"),
    btnConfirmLeave: t("button.confirmLeave"),
    leaveWarningMessage: t("messages.leaveForm"),
    messages: {
      createdSuccess: t("combo.messages.createdSuccess"),
    },
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    selectDate: t("promotion.selectDate"),
    generalInformation: {
      title: t("combo.generalInformation.title"),
      name: t("combo.generalInformation.name"),
      namePlaceholder: t("combo.generalInformation.namePlaceholder"),
      nameValidateMessage: t("combo.generalInformation.nameValidateMessage"),
      description: t("combo.generalInformation.description"),
      thumbnailValidateMessage: t("combo.generalInformation.thumbnailValidateMessage"),
      branch: t("combo.generalInformation.branch"),
      branchPlaceholder: t("combo.generalInformation.branchPlaceholder"),
      branchValidateMessage: t("combo.generalInformation.branchValidateMessage"),
      allBranches: t("combo.generalInformation.allBranches"),
      maximum2000Characters: t("form.maximum2000Characters"),
      startDate: t("promotion.form.startDate"),
      PleaseStartDate: t("promotion.form.pleaseStartDate"),
      endDate: t("promotion.form.endDate"),
      PlaceholderDateTime: t("promotion.form.placeholderDateTime"),
      uploadImage: t("productManagement.generalInformation.uploadImages"),
    },
    product: {
      title: t("combo.product.title"),
      comboType: t("combo.comboType"),
      productPlaceholder: t("combo.product.productPlaceholder"),
      productValidateMessage: t("combo.product.productValidateMessage"),
      tooltipMessage: t("combo.product.tooltipMessage"),
      categoryValidateMessage: t("combo.product.categoryValidateMessage"),
      categoryPlaceholder: t("combo.product.categoryPlaceholder"),
      groups: t("combo.product.groups"),
      group: t("combo.product.group"),
      category: t("combo.product.category"),
      itemQuantity: t("combo.product.itemQuantity"),
      itemQuantityPlaceholder: t("combo.product.itemQuantityPlaceholder"),
      itemQuantityValidateMessage: t("combo.product.itemQuantityValidateMessage"),
      addGroup: t("combo.product.addGroup"),
    },
    price: {
      title: t("combo.price.title"),
      combo: t("combo.price.combo"),
      originalPrice: t("combo.price.originalPrice"),
      sellingPrice: t("combo.price.sellingPrice"),
      sellingPricePlaceholder: t("combo.price.sellingPricePlaceholder"),
      sellingPriceValidateMessage: t("combo.price.sellingPriceValidateMessage"),
      product: t("combo.productTitle"),
      comboNameTitle: t("combo.comboNameTitle"),
      comboItemNameRequiredMessage: t("combo.comboItemNameRequiredMessage"),
      comboItemNameMaxLengthMessage: t("combo.comboItemNameMaxLengthMessage"),
      comboItemNamePlaceholder: t("combo.comboItemNamePlaceholder"),
    },
    upload: {
      addFromUrl: t("material.addFromUrl"),
      uploadImage: t("material.addFile"),
    },
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
  };

  const tableSettings = {
    columns: [
      {
        title: pageData.price.comboNameTitle,
        dataIndex: "comboName",
        width: "35%",
        render: (comboName, record) => {
          return (
            <Form.Item
              name={["productCombos", record?.index, "customName"]}
              rules={[
                {
                  required: true,
                  message: pageData.price.comboItemNameRequiredMessage,
                },
                {
                  type: "string",
                  max: 100,
                  message: pageData.price.comboItemNameMaxLengthMessage,
                },
              ]}
            >
              <Input
                className="fnb-input"
                placeholder={pageData.price.comboItemNamePlaceholder}
                maxLength={100}
                id="product-name"
                value={comboName}
                onChange={(e) => {
                  onChangeCustomName(e.target.value, record.index);
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: pageData.price.product,
        dataIndex: "comboName",
        width: "25%",
        render: (comboName) => {
          return (
            <Row align="middle">
              <Paragraph
                placement="top"
                ellipsis={{
                  row: 1,
                  tooltip: (
                    <Typography className={`combo-list-name-tooltip ${isMobile ? "is-tooltip-mobile" : ""}`}>
                      {comboName}
                    </Typography>
                  ),
                }}
                color="#50429B"
              >
                {comboName}
              </Paragraph>
            </Row>
          );
        },
      },
      {
        title: `${pageData.price.originalPrice} (${getCurrency()})`,
        dataIndex: "originalPrice",
        width: "20%",
        render: (value) => {
          return <span>{formatNumberDecimalOrInteger(value)}</span>;
        },
      },
      {
        title: `${pageData.price.sellingPrice} (${getCurrency()})`,
        dataIndex: "sellingPrice",
        width: "20%",
        render: (sellingPrice, record) => {
          const originalPrice = record?.originalPrice ?? 1;
          return (
            <>
              <Form.Item
                initialValue={formatNumberDecimalOrInteger(sellingPrice)}
                name={["productCombos", record?.index, "sellingPrice"]}
                rules={[
                  {
                    required: true,
                    message: pageData.price.sellingPriceValidateMessage + originalPrice,
                  },
                  {
                    validator: (rule, value, callback) =>
                      maxComboSellingPriceValidatorForSpecificPrice(rule, value, callback, originalPrice),
                  },
                ]}
              >
                <InputNumber
                  onChange={(value) => onChangeComboSellingPrice(value, record.index)}
                  disabled={selectedPriceType === prices.fixed.value}
                  addonAfter={getCurrency()}
                  placeholder={pageData.product.quantityPlaceholder}
                  className="w-100 fnb-input-number"
                  min={0}
                  // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  precision={getCurrency() === currency.vnd ? 0 : 2}
                  id={`productCombos_${record?.index}_sellingPrice`}
                  onKeyPress={(event) => {
                    const checkValidKey = checkOnKeyPressValidation(
                      event,
                      `productCombos_${record?.index}_sellingPrice`, // Id compiled from form item name followed antd format
                      0,
                      originalPrice,
                      getCurrency() === currency.vnd ? 0 : 2,
                    );

                    if (!checkValidKey) event.preventDefault();
                  }}
                />
              </Form.Item>

              <div className="float-right color-primary discount-products-wrapper">
                <Row>
                  <Col className="discount-icon-col">
                    <DiscountIcon />
                  </Col>
                  <Col className="discount-percent-col">
                    <span className="ml-2">{originalPrice === 0 ? 0 : roundNumber(((originalPrice - sellingPrice) / originalPrice) * 100, 1)}</span>
                    <PercentageOutlined />
                  </Col>
                </Row>
              </div>
            </>
          );
        },
      },
    ],
  };

  useEffect(() => {
    getPrepareData();
    setInitFormValue();
    setSelectedComboType(combos.flexibleCombo.value);
    calculateMinPrice();
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxComboSellingPriceValidatorForSpecificPrice = (rule, value, callback, originalPrice) => {
    if (value && value >= originalPrice) {
      const validateMessage = pageData.price.sellingPriceValidateMessage + originalPrice;
      callback(validateMessage);
    }
    callback();
  };
  
  const calculateMinPrice = (value) => {
    const type = value === undefined ? selectedPriceType : value;
    if (type === undefined || type === null || type === 0) {
      let tempMinPrice = MaximumNumber;
      if (productCombos === undefined || productCombos === null) {
        setMaxPrice(MaximumNumber);
        return;
      }
      productCombos.forEach((c) => {
        let total = c.comboData.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.productPrice;
        }, 0);
        if (tempMinPrice > total) tempMinPrice = total;
      });
      setMaxPrice(tempMinPrice);
    } else setMaxPrice(MaximumNumber);
  };

  //#region Actions
  const setInitFormValue = () => {
    form.setFieldsValue({
      name: "",
      description: "",
      thumbnail: "",
      isShowAllBranches: true,
      comboTypeId: selectedComboType,
      priceTypeId: selectedPriceType,
    });
  };

  const getPrepareData = () => {
    comboDataService.getPrepareCreateProductComboDataAsync().then((data) => {
      const { products, productsWithCategory, productCategories } = data;
      setProducts(products);
      setAllproductsWithCategory(productsWithCategory);
      const productDataOptions = getProductDataOptions(products);
      setAllProductPriceOptions(productDataOptions);
      setProductCategories(productCategories);
    });
  };

  const getBranches = async () => {
    const res = await branchDataService.getAllBranchsAsync();
    if (res) {
      setBranches(res.branchs);
      updateLastTimeGetBranches();
    }
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const getProductDataOptions = (products) => {
    let productOptions = [];
    products?.map((product) => {
      if (product?.productPrices?.length > 0) {
        product?.productPrices.map((price) => {
          const text = price?.priceName ? `${product?.name} (${price?.priceName})` : product?.name;
          const option = {
            key: price?.id,
            productId: product?.id,
            productName: product?.name,
            text: text,
            productPriceId: price?.id,
            productPriceName: price?.name,
            productPrice: price?.priceValue,
            isSinglePrice: product?.productPrices?.length <= 1,
            thumbnail: product?.thumbnail,
            unitName: product?.unit?.name,
            isTopping: product?.isTopping,
          };

          productOptions.push(option);
        });
      }
    });
    return productOptions;
  };

  const getDataTable = () => {
    const dataSource = [];
    productCombos.forEach((combo, index) => {
      const originalPrice = combo?.comboData?.map((p) => p?.productPrice)?.reduce((a, b) => a + b, 0);
      let sellingPrice =
        combo?.sellingPrice == null
          ? 0
          : combo?.sellingPrice && combo?.sellingPrice > 0
          ? combo?.sellingPrice
          : originalPrice;
      if (selectedPriceType === prices.fixed.value && sellingFixedPrice > 0) {
        sellingPrice = sellingFixedPrice;
      }

      const record = {
        index: index,
        productIds: combo?.comboData?.map((p) => p?.productId),
        comboName: combo?.comboData?.map((p) => p?.text)?.join(" | "),
        originalPrice: originalPrice,
        sellingPrice: sellingPrice,
      };

      dataSource.push(record);
    });

    return dataSource;
  };

  const onSubmitForm = () => {
    form.validateFields().then((values) => {
      const { comboTypeId } = values;

      let requestModel = {
        ...values,
        startDate: startDate.format(DateFormat.MM_DD_YYYY),
        endDate: values.endDate?.format(DateFormat.MM_DD_YYYY),
        thumbnail: selectedImage?.url,
      };

      /// Mapping selected product price name to productPriceIds
      if (comboTypeId === ComboType.Specific) {
        const comboProductNames = allProductPriceOptions
          ?.filter((p) => values?.productPriceIds?.includes(p?.productPriceId))
          ?.map((p) => p?.text);

        requestModel = {
          ...requestModel,
          comboProductNames: comboProductNames,
          isShowAllBranches: isShowAllBranches,
        };
      }

      /// Mapping selected product price name to productPriceIds
      if (comboTypeId === ComboType.Fixed) {
        const comboPricings = productCombos?.map((combo, index) => {
          return {
            comboProductName: combo?.comboData?.map((p) => p.text)?.join(" | "),
            sellingPrice: combo?.sellingPrice,
            customName: values?.productCombos?.[index]?.customName,
            comboPricingProducts: combo?.comboData?.map((item) => {
              return {
                productPriceId: item?.productPriceId,
                productPrice: item?.productPrice,
              };
            }),
          };
        });

        requestModel = {
          ...requestModel,
          isShowAllBranches: isShowAllBranches,
          comboPricings: comboPricings,
        };
      }

      comboDataService.createComboAsync(requestModel).then((success) => {
        if (success) {
          setFormChanged(false);
          navigateToManagementPage();
          message.success(pageData.messages.createdSuccess);
        }
      });
    });
  };

  const onClickUploadImage = (file) => {
    setSelectedImage(file);
  };

  const onAddNewProductGroup = () => {
    const formValues = form.getFieldsValue();
    const { productGroups } = formValues;
    const newProductGroup = [...productGroups, initProductGroup];
    setProductGroups(newProductGroup);
    form.setFieldsValue({ productGroups: newProductGroup });
    calculateMinPrice();
  };

  const onRemoveProductGroup = (index) => {
    const formValues = form.getFieldsValue();
    const { productGroups } = formValues;
    const newProductGroup = productGroups.filter((_, i) => i !== index);
    setProductGroups(newProductGroup);
    form.setFieldsValue({ productGroups: newProductGroup });
    /// Update selectedProductPriceIds
    selectedProductPriceIds[index] = [];
    refreshOptionsAndCalculateCombos(selectedProductPriceIds);
  };

  const onChangeComboSellingPrice = (value, index) => {
    productCombos[index].sellingPrice = value;
    setProductCombos([...productCombos]);
    calculateMinPrice();

    const formValues = form.getFieldsValue();
    let formProductCombos = formValues.productCombos;
    formProductCombos[index].sellingPrice = value;
    form.setFieldsValue({
      productCombos: formProductCombos,
    });
  };

  const onChangeProductCategory = (index) => {
    const formValues = form.getFieldsValue();
    const { productGroups } = formValues;
    const productCategoryIds = productGroups?.map((group) => group.categoryId) ?? [];
    setSelectedProductCategoryIds(productCategoryIds);
    productGroups?.map((productGroup, i) => {
      if (i === index) {
        productGroup.productPriceIds = [];
      }
    });

    form.setFieldsValue({ productGroups });
    calculateMinPrice();
  };

  /// Selecting product and find product combos
  const onChangeProduct = (value, index) => {
    /// update group combos by product price id
    selectedProductPriceIds[index] = value;
    refreshOptionsAndCalculateCombos(selectedProductPriceIds);
    calculateMinPrice();
  };

  const refreshOptionsAndCalculateCombos = (selectedProductPriceIds) => {
    setSelectedProductPriceIds(selectedProductPriceIds);
    /// Find combos
    calculateCombinationPossible(selectedProductPriceIds);
  };

  const calculateCombinationPossible = (selectedProductPriceIds) => {
    const combos = combinationPossible(selectedProductPriceIds);
    const formValue = form.getFieldsValue();
    const formProductCombos = [];
    if (combos.length > 0) {
      /// Mapping product options to product combos
      const productCombosData = combos.map((combo, index) => {
        const data = combo.map((productPriceId) => {
          const productOption = allProductPriceOptions.find((p) => p.key === productPriceId);
          if (productOption) {
            return productOption;
          }
        });
        const customName = `${comboParentName ? comboParentName + ": " : ""} ${data.map((a) => a.text).join(" | ")}`;
        const originalPrice = data?.map((p) => p?.productPrice)?.reduce((a, b) => a + b, 0);
        let sellingPrice =
          sellingFixedPrice == null
            ? 0
            : sellingFixedPrice && sellingFixedPrice > 0
            ? sellingFixedPrice
            : originalPrice;

        formProductCombos.push({
          customName: customName.length > 100 ? customName.slice(0, 100) : customName,
          sellingPrice: sellingPrice,
        });

        return {
          comboData: data,
          sellingPrice: sellingFixedPrice,
        };
      });

      form.setFieldsValue({
        ...formValue,
        productCombos: formProductCombos,
      });
      setProductCombos(productCombosData);
      calculateMinPrice();
    }
  };

  const onSelectSpecificProducts = (option) => {
    const listProductSelected =
      option?.map((productPrice) => ({
        productId: productPrice?.productId,
        productPriceId: productPrice?.productPriceId,
        name: productPrice?.label,
        price: productPrice?.priceValue,
      })) ?? [];
    setSelectedSpecificProducts(listProductSelected);
    const totalOriginalPrice = listProductSelected?.map((p) => p.price)?.reduce((a, b) => a + b, 0);
    setTotalOriginalPriceOfSpecificCombo(totalOriginalPrice);
    updateTotalOriginalPriceOfSpecificCombo(sellingFixedPrice, listProductSelected);
  };

  const updateTotalOriginalPriceOfSpecificCombo = (sellingFixedPrice, listProductSelected) => {
    if (!listProductSelected) {
      listProductSelected = selectedSpecificProducts;
    }
    const totalOriginalPrice = listProductSelected?.map((p) => p.price)?.reduce((a, b) => a + b, 0);
    var discountPercentAmount = calculatingDiscountPercent(sellingFixedPrice, totalOriginalPrice);
    setDiscountPercentAmountOfSpecificCombo(discountPercentAmount);
  };

  const calculatingDiscountPercent = (sellingFixedPrice, totalOriginalPrice) => {
    if (totalOriginalPrice === 0) return 0;
    return ((totalOriginalPrice - sellingFixedPrice) / totalOriginalPrice) * 100;
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const disabledDateByStartDate = (current) => {
    // Can not select days before today and today
    return current && current < startDate;
  };
  //#endregion

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  //#region Render components
  const renderGeneralInformationComponent = () => {
    return (
      <>
        <h4 className="fnb-form-label mt-32">
          {pageData.generalInformation.name}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: pageData.generalInformation.nameValidateMessage,
            },
            {
              type: "string",
              max: 100,
            },
          ]}
        >
          <Input
            className="fnb-input-with-count"
            placeholder={pageData.generalInformation.namePlaceholder}
            showCount
            maxLength={100}
            onChange={(e) => {
              onChangeComboName(e?.target?.value);
            }}
          />
        </Form.Item>

        <Row gutter={[32, 16]}>
          <Col xs={24} lg={12}>
            <h4 className="fnb-form-label">{pageData.generalInformation.startDate}<span className="text-danger">*</span></h4>
            <Form.Item
              name={["startDate"]}
              rules={[
                {
                  required: true,
                  message: pageData.generalInformation.PleaseStartDate,
                },
              ]}
            >
              <DatePicker
                suffixIcon={<CalendarNewIconBold />}
                placeholder={pageData.selectDate}
                className="fnb-date-picker w-100"
                disabledDate={disabledDate}
                format={DateFormat.DD_MM_YYYY}
                onChange={(date) => setStartDate(date)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <h4 className="fnb-form-label">{pageData.generalInformation.endDate}</h4>
            <Form.Item name={["endDate"]}>
              <DatePicker
                suffixIcon={<CalendarNewIconBold />}
                placeholder={pageData.selectDate}
                className="fnb-date-picker w-100"
                disabledDate={disabledDateByStartDate}
                format={DateFormat.DD_MM_YYYY}
                disabled={startDate ? false : true}
              />
            </Form.Item>
          </Col>
        </Row>

        <h4 className="fnb-form-label">{pageData.generalInformation.description}</h4>
        <Form.Item className="fnb-form-description" name="description">
          <TextArea
            showCount
            className="fnb-text-area-with-count no-resize combo-description-box"
            placeholder={pageData.generalInformation.maximum2000Characters}
            maxLength={2000}
          />
        </Form.Item>
        <h4 className="fnb-form-label">{pageData.generalInformation.branch}<span className="text-danger">*</span></h4>
        <div className="combo-check-box-select-all-branch">
          <FnbCheckBox onChange={(event) => onSelectAllBranches(event)} checked={isShowAllBranches}>
            {pageData.generalInformation.allBranches}
          </FnbCheckBox>
        </div>

        <Form.Item
          hidden={disableAllBranches}
          name={["branchIds"]}
          rules={[
            {
              required: !disableAllBranches,
              message: pageData.generalInformation.branchValidateMessage,
            },
          ]}
        >
          <FnbSelectAddNewItem
            mode="multiple"
            placeholder={pageData.generalInformation.branchPlaceholder}
            options={branches?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            showSearch
            allowClear
            fieldItemName={pageData.generalInformation.branch.toLowerCase()}
            onAddNewItem={onAddNewBranch}
          />
        </Form.Item>
        <Form.Item hidden={!disableAllBranches}>
          <FnbSelectMultiple disabled={true}></FnbSelectMultiple>
        </Form.Item>
      </>
    );
  };

  const onSelectAllBranches = (event) => {
    const isChecked = event.target.checked;
    setIsShowAllBranches(isChecked);
    setDisableAllBranches(isChecked);
  };

  const renderProductComponent = () => {
    return (
      <>
        <h3 className="label-information mt-10">{pageData.product.title}</h3>
        <h4 className="combo-type-name mt-3">{pageData.product.comboType}</h4>
        <Form.Item className="select-product-radio-wrapper" name={["comboTypeId"]}>
          <Radio.Group
            onChange={(e) => {
              const value = e.target.value;
              setSelectedComboType(value);
              setSelectedProductCategoryIds([]);
              calculateMinPrice();
            }}
            className="product-component-radio"
          >
            <Radio value={combos.flexibleCombo.value}>
              <p>{combos.flexibleCombo.name}</p>
            </Radio>
            <Radio value={combos.specificCombo.value}>
              <p>{combos.specificCombo.name}</p>
            </Radio>
          </Radio.Group>
        </Form.Item>

        {selectedComboType === combos.specificCombo.value ? renderSpecificCombo() : renderFlexibleCombo()}
      </>
    );
  };

  const renderPriceComponent = () => {
    return (
      <>
        <h3 className="label-information mt-10">{pageData.price.title}</h3>
        {selectedComboType === combos.specificCombo.value ? (
          renderSpecificComboSellingPrice()
        ) : (
          <>
            <Form.Item name={["priceTypeId"]}>
              <Radio.Group
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedPriceType(value);
                  calculateMinPrice(value);
                }}
              >
                <Radio value={prices.fixed.value}>
                  <p>{prices.fixed.name}</p>
                </Radio>
                <Radio value={prices.specific.value}>
                  <p>{prices.specific.name}</p>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {selectedPriceType === prices.fixed.value && renderSellingPrice()}
            <FnbTable
              className="table-product-combo-prices"
              columns={tableSettings.columns}
              dataSource={getDataTable()}
            />
          </>
        )}
      </>
    );
  };

  const renderFlexibleCombo = () => {
    return (
      <>
        <div className="groups">
          <h4 className="float-left combo-type-name">
            <span>{pageData.product.groups}</span>
            <FnbTooltip placement="topLeft" title={pageData.product.tooltipMessage}>
              <span className="ml-2 pointer">
                <InfoCircleOutlined />
              </span>
            </FnbTooltip>
          </h4>
          <FnbAddNewButton className="float-right" onClick={onAddNewProductGroup} text={pageData.product.addGroup} />
        </div>
        <div className="clearfix"></div>
        <div className="mt-3">{renderProductGroups()}</div>
      </>
    );
  };

  const renderSpecificCombo = () => {
    return (
      <div className="specific-combo">
        <h4 className="fnb-form-label">
          {pageData.product.title}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name="productPriceIds"
          rules={[
            {
              required: true,
              message: pageData.product.productValidateMessage,
            },
          ]}
          className="product-flexible-panel"
        >
          <FnbSelectProduct
            options={renderProductSpecificOptions()}
            placeholder={pageData.product.productPlaceholder}
            onChange={(_, option) => onSelectSpecificProducts(option)}
            listHeight={500}
            hiddenSelectAll
          />
        </Form.Item>
      </div>
    );
  };

  const renderProductSpecificOptions = () => {
    let allProducts = products;
    allProducts?.sort((a, b) => a?.name.localeCompare(b?.name));
    return allProducts;
  };

  const renderProductFlexibleOptions = (index) => {
    let allProducts = [];
    if (index !== null) {
      if (productGroupsWatch !== undefined) {
        const productCategory = productGroupsWatch[index];
        const productsByCategory = allproductsWithCategory?.filter(
          (item) => item?.productCategoryId === productCategory?.categoryId,
        );
        if (productsByCategory?.length > 0) {
          allProducts = productsByCategory;
        }
      }
    }
    allProducts?.sort((a, b) => a?.name.localeCompare(b?.name));
    return allProducts;
  };

  const renderProductGroups = () => {
    return productGroups?.map((_, index) => {
      return (
        <>
          {index >= 1 && <div className="group-clearfix"></div>}
          <div className="product-group-container">
            <div className="product-group-header">
              <Row>
                <Col span={20}>
                  <h4 className="product-group-name label-information">
                    {pageData.product.group} {productGroups.length > 1 && index + 1}
                  </h4>
                </Col>
                {index > 0 && (
                  <Col span={4}>
                    <div className="product-group-delete-action float-right">
                      <a onClick={() => onRemoveProductGroup(index)}>
                        <TrashFill className="icon-svg-hover" />
                      </a>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="product-group-body">
              <Row gutter={[24, 24]} className="mt-1">
                <Col xs={24} sm={24} md={24} lg={24} span={24}>
                  <h4 className="fnb-form-label">
                    {pageData.product.category}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["productGroups", index, "categoryId"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.product.categoryValidateMessage,
                      },
                    ]}
                  >
                    <FnbSelect
                      placeholder={pageData.product.categoryPlaceholder}
                      onChange={() => onChangeProductCategory(index)}
                      options={productCategories?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} sm={0} md={0} lg={0} span={0} className="d-none">
                  <h4 className="fnb-form-label d-none">
                    {pageData.product.itemQuantity}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    className="d-none"
                    name={["productGroups", index, "quantity"]}
                    rules={[
                      {
                        required: false,
                        message: pageData.product.itemQuantityValidateMessage,
                      },
                    ]}
                  >
                    <InputNumber
                      type="hidden"
                      defaultValue={3}
                      placeholder={pageData.product.quantityPlaceholder}
                      className="w-100 fnb-input input-quantity"
                      min={1}
                      max={MaximumNumber}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onKeyPress={(event) => {
                        const checkValidKey = checkOnKeyPressValidation(event, "discountPercent", 1, MaximumNumber, 0);
                        if (!checkValidKey) event.preventDefault();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} span={24}>
                  <h4 className="fnb-form-label">
                    {pageData.product.title}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["productGroups", index, "productPriceIds"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.product.productValidateMessage,
                      },
                    ]}
                    className="product-flexible-panel"
                  >
                    <FnbSelectProduct
                      options={renderProductFlexibleOptions(index)}
                      placeholder={pageData.product.productPlaceholder}
                      onChange={(value) => onChangeProduct(value, index)}
                      listHeight={500}
                      hiddenSelectAll
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </>
      );
    });
  };

  const renderSellingPrice = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.price.sellingPrice}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name="sellingPrice"
          rules={[
            {
              required: true,
              message: pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(maxPrice),
            },
          ]}
        >
          <InputNumber
            onChange={(value) => {
              onChangeSellingFixedPrice(value);
            }}
            addonAfter={getCurrency()}
            placeholder={pageData.price.sellingPricePlaceholder}
            className="w-100 fnb-input-number"
            min={getCurrency() === currency.vnd ? 1 : 0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            precision={getCurrency() === currency.vnd ? 0 : 2}
            id="sellingPrice"
            onKeyPress={(event) => {
              const checkValidKey = checkOnKeyPressValidation(
                event,
                "sellingPrice",
                0,
                maxPrice,
                getCurrency() === currency.vnd ? 0 : 2,
              );
              if (!checkValidKey) event.preventDefault();
            }}
          />
        </Form.Item>
      </>
    );
  };

  
  /// Render selling price field if product combo type is specific
  const maxComboSellingPriceValidatorForSpecificItem = (rule, value, callback) => {
    if (value && value > totalOriginalPriceOfSpecificCombo) {
      const validateMessage =
        pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(totalOriginalPriceOfSpecificCombo);
      callback(validateMessage);
    }
    callback();
  };

  const renderSpecificComboSellingPrice = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.price.sellingPrice}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name="sellingPrice"
          rules={[
            {
              required: true,
              message: `${pageData.price.sellingPriceValidateMessage} ${formatNumberDecimalOrInteger(totalOriginalPriceOfSpecificCombo)}`,
            },
            { validator: maxComboSellingPriceValidatorForSpecificItem },
          ]}
        >
          <FnbInputNumber
            onChange={(value) => {
              setSellingFixedPrice(value);
              updateTotalOriginalPriceOfSpecificCombo(value);
            }}
            addonAfter={getCurrency()}
            placeholder={pageData.price.sellingPricePlaceholder}
            className="w-100"
            min={getCurrency() === currency.vnd ? 1 : 0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            precision={getCurrency() === currency.vnd ? 0 : 2}
            id="comboSpecific-sellingPrice"
            onKeyPress={(event) => {
              const checkValidKey = checkOnKeyPressValidation(
                event,
                "comboSpecific-sellingPrice",
                0,
                totalOriginalPriceOfSpecificCombo,
                getCurrency() === currency.vnd ? 0 : 2,
              );
              if (!checkValidKey) event.preventDefault();
            }}
          />
        </Form.Item>

        <Row gutter={[16, 16]} className="float-right flexible-combo-by-group-of-items">
          <Col className="flexible-combo-discount-icon">
            <b>
              <span>{pageData.price.originalPrice}</span>:
              <span className="ml-2">{formatCurrency(totalOriginalPriceOfSpecificCombo)}</span>
            </b>
          </Col>
          <Col className="color-primary ml-2 flexible-combo-discount-percent">
            <DiscountIcon />
            <span className="ml-2">{roundNumber(discountPercentAmountOfSpecificCombo, 1)}</span>
            <PercentageOutlined />
          </Col>
        </Row>
      </>
    );
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      navigateToManagementPage();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const navigateToManagementPage = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/combo");
    }, DELAYED_TIME);
  };
  //#endregion

  const onChangeCustomName = (value, index) => {
    const formValues = form.getFieldsValue();
    let formProductCombos = formValues.productCombos;
    formProductCombos[index].customName = value;
    form.setFieldsValue({
      productCombos: formProductCombos,
    });
  };

  const onChangeSellingFixedPrice = (value) => {
    setSellingFixedPrice(value);
    const formValue = form.getFieldsValue();
    productCombos.forEach((combo) => {
      combo.sellingPrice = value;
    });
    formValue.productCombos.map((item) => {
      item.sellingPrice = value;
    });

    form.setFieldsValue({
      ...formValue,
    });

    setProductCombos([...productCombos]);
    calculateMinPrice();
  };

  const onChangeComboName = (value) => {
    const formValue = form.getFieldsValue();
    if (formValue.productCombos) {
      formValue.productCombos.map((item) => {
        let customName = item.customName.split(":");
        item.customName = `${value ? value + ":" : ""} ${customName[customName.length - 1].trim()}`;
      });

      form.setFieldsValue({
        ...formValue,
      });
    }
    setComboName(value);
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.title}
        listButtons={[
          <FnbCancelButton onOk={() => onCancel()} />,
          <FnbAddNewButton
            onClick={() => onSubmitForm()}
            className="float-right"
            type="primary"
            text={pageData.btnCreate}
            permission={PermissionKeys.CREATE_COMBO}
          />,
        ]}
      />
      <Form
        className="combo-form"
        form={form}
        layout="vertical"
        autoComplete="off"
        onChange={() => {
          if (!formChanged) setFormChanged(true);
        }}
        onFieldsChange={(e) => changeForm(e)}
      >
        <div className="w-100">
          <Content>
            <Card className="w-100 mb-4 fnb-card h-auto">
              <h3 className="label-information mt-10">{pageData.generalInformation.title}</h3>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={17} span={17}>
                  {renderGeneralInformationComponent()}
                </Col>
                <Col xs={24} sm={24} md={24} lg={7} span={7} className="w-100 upload-product-image">
                  <h3 className="fnb-form-label mt-32">{pageData.media.title}</h3>
                  <Row className={`non-image ${selectedImage !== null && selectedImage !== undefined ? "have-image" : ""}`}>
                      <Col span={24} className={`image-product ${selectedImage !== null && selectedImage !== undefined ? "justify-left" : ""}`}>
                        <Form.Item name={["combo", "media"]}>
                          <FnbCustomUploadImageComponent
                            iconUpload={<UploadLogoIcon />}
                            buttonText={pageData.generalInformation.uploadImage}
                            onChange={onClickUploadImage}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </Card>
            <Card className="fnb-card mb-4">
              <Row gutter={[16, 16]} className="mb-4">
                <Col span={24}>{renderProductComponent()}</Col>
              </Row>
            </Card>
            <Card className="fnb-card mb-4">
              <Row gutter={[16, 16]}>
                <Col span={24}>{renderPriceComponent()}</Col>
              </Row>
            </Card>
          </Content>
        </div>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={navigateToManagementPage}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
