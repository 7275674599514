import { Col, Form, Image, Input, Row, message } from "antd";
import ComingSoonTag from "components/coming-soon-tag";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbGuideline } from "components/fnb-guideline/fnb-guideline.component";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbSwitch from "components/switch";
import { paymentMethod } from "constants/payment-method.constants";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import MomoStoreCodeGuidelinePNG from "../../../../assets/images/momo-store-code-guideline.png";
import "./momo-payment-config.component.scss";

export function MoMoPaymentConfigComponent(props) {
  const {
    onCompleted,
    initData,
    isCheckMomoBusiness,
    onChangeMomoBusiness,
    onChangeForm,
    isCheckCreditCard,
    onChangeCreditCard,
    initDataCreditCard,
  } = props;
  const [activeAuthenticateButton, setActiveAuthenticateButton] = useState(true);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formCreditCard] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isShowMomoBusiness, setIsShowMomoBusiness] = useState(isCheckMomoBusiness);
  //credit card
  const [isShowCreditCard, setIsShowCreditCard] = useState(isCheckCreditCard);
  const [isChangeFormCreditCard, setIsChangeFormCreditCard] = useState(false);
  const [activeAuthenticateButtonCreditCard, setActiveAuthenticateButtonCreditCard] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const pageData = {
    storeCode: t("payment.storeCode", "Store Code"),
    partnerStoreCodePlaceholder: t("payment.partnerStoreCodePlaceholder"),
    partnerStoreCodeMaxLengthMessage: t("payment.partnerStoreCodeMaxLengthMessage"),
    partnerStoreCodeGuideline: t("payment.partnerStoreCodeGuideline"),
    storeName: t("payment.storeName", "Store Name"),
    partnerStoreNamePlaceholder: t("payment.partnerStoreNamePlaceholder"),
    partnerCode: t("payment.partnerCode"),
    partnerCodePlaceholder: t("payment.partnerCodePlaceholder"),
    partnerCodeValidationMessage: t("payment.partnerCodeValidationMessage"),
    accessKey: t("payment.accessKey"),
    accessKeyPlaceholder: t("payment.accessKeyPlaceholder"),
    accessKeyValidationMessage: t("payment.accessKeyValidationMessage"),
    publicKey: t("payment.publicKey", "Public key"), //new
    publicKeyPlaceholder: t("payment.publicKeyPlaceholder", "Enter public key"), //new
    publicKeyValidationMessage: t("payment.publicKeyValidationMessage", "Please enter public key"), //new
    secretKey: t("payment.secretKey"),
    secretKeyPlaceholder: t("payment.secretKeyPlaceholder"),
    secretKeyValidationMessage: t("payment.secretKeyValidationMessage"),
    updateSuccess: t("payment.updateSuccess"),
    logo: t("payment.logo"),
    momoBusiness: t("payment.momoBusiness"),
    momoBusinessDescription: t("payment.momoBusinessDescription"),
    momoCreditCard: t("payment.momoCreditCard"),
    momoCreditCardDescription: t("payment.momoCreditCardDescription"),
    momoPersonal: t("payment.momoPersonal"),
    momoPersonalDescription: t("payment.momoPersonalDescription"),
    button: {
      cancel: t("button.cancel"),
      authenticate: t("payment.authenticate"),
    },
  };

  useEffect(() => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    setInitData(initData);
    setInitDataCreditCard(initDataCreditCard);
  }, []);

  useEffect(() => {
    onChangeForm(isChangeForm);
  }, [isChangeForm]);

  useEffect(() => {
    onChangeForm(isChangeFormCreditCard);
  }, [isChangeFormCreditCard]);

  useEffect(() => {
    setIsShowMomoBusiness(isCheckMomoBusiness);
  }, [isCheckMomoBusiness]);

  useEffect(() => {
    setIsShowCreditCard(isShowCreditCard);
  }, [isShowCreditCard]);

  const setInitData = (paymentMethod) => {
    const { paymentConfigs } = paymentMethod;
    if (paymentConfigs && paymentConfigs[0]) {
      const config = {
        paymentMethodId: paymentMethod?.id,
        ...paymentConfigs[0],
      };
      if (paymentConfigs && config) {
        form.setFieldsValue(config);
        setActiveAuthenticateButton(false);
      }
    }
  };

  const setInitDataCreditCard = (paymentMethod) => {
    const { paymentConfigs } = paymentMethod;
    if (paymentConfigs && paymentConfigs[0]) {
      const config = {
        paymentMethodId: paymentMethod?.id,
        ...paymentConfigs[0],
      };
      if (paymentConfigs && config) {
        formCreditCard.setFieldsValue(config);
        setActiveAuthenticateButtonCreditCard(false);
      }
    }
  };

  const onClickAuthenticate = () => {
    form.validateFields().then((_) => {
      const valuesForm = form.getFieldsValue();
      paymentConfigDataService.updatePaymentConfigAsync(valuesForm).then((response) => {
        const { success } = response;
        if (success === true) {
          message.success(pageData.updateSuccess);
          setIsChangeForm(false);
          onCompleted();
        }
      });
    });
  };

  const onClickAuthenticateCreditCard = () => {
    formCreditCard.validateFields().then((_) => {
      const valuesForm = formCreditCard.getFieldsValue();
      paymentConfigDataService.updatePaymentConfigAsync(valuesForm).then((response) => {
        const { success } = response;
        if (success === true) {
          message.success(pageData.updateSuccess);
          setIsChangeFormCreditCard(false);
          onCompleted();
        }
      });
    });
  };

  const onValidForm = () => {
    form
      .validateFields()
      .then((_) => {
        setActiveAuthenticateButton(false);
      })
      .catch((_) => {
        setActiveAuthenticateButton(true);
      });
  };

  const onValidFormCreditCard = () => {
    formCreditCard
      .validateFields()
      .then((_) => {
        setActiveAuthenticateButtonCreditCard(false);
      })
      .catch((_) => {
        setActiveAuthenticateButtonCreditCard(true);
      });
  };

  const onCancel = () => {
    setInitData(initData);
    setIsChangeForm(false);
  };

  const onCancelCreditCard = () => {
    setInitDataCreditCard(initData);
    setIsChangeFormCreditCard(false);
  };

  const onFormChanged = () => {
    setIsChangeForm(true);
  };

  const onFormChangedCreditCard = () => {
    setIsChangeFormCreditCard(true);
  };

  const onChangeSwitchMomoBusiness = (value) => {
    onChangeMomoBusiness(value);
    setIsShowMomoBusiness(value);
  };

  const onChangeSwitchCreditCard = (value) => {
    onChangeCreditCard(value);
    setIsShowCreditCard(value);
  };

  function MomoStoreCodeGuideline() {
    return <Image preview={true} src={MomoStoreCodeGuidelinePNG} width={800} />;
  }

  return (
    <Col className="enterprise-payment-config momo-component">
      <Col className="sub-tab">
        <div className="momo-header">
          <div>
            <FnbSwitch checked={isShowMomoBusiness} onChange={(value) => onChangeSwitchMomoBusiness(value)} />
          </div>
          <div className="switch-title">
            <span className="name">{pageData.momoBusiness}</span>
            <span className="description">{pageData.momoBusinessDescription}</span>
          </div>
          {isChangeForm && (
            <Row className="list-button">
              <FnbButton text={pageData.button.cancel} variant="tertiary" onClick={onCancel} />
              <FnbButton
                text={pageData.button.authenticate}
                disabled={activeAuthenticateButton}
                htmlType="submit"
                onClick={() => onClickAuthenticate()}
              />
            </Row>
          )}
        </div>

        <Form
          form={form}
          className="form-edit custom-form"
          layout="vertical"
          autoComplete="off"
          onFinish={onClickAuthenticate}
          onChange={onValidForm}
          onValuesChange={() => {
            onFormChanged();
          }}
        >
          <Row gutter={[16, 28]}>
            <Form.Item name="enumId" initialValue={paymentMethod.MoMo}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="paymentMethodId">
              <Input type="hidden" />
            </Form.Item>
            <Col md={12} sm={24}>
              <Form.Item
                label={pageData.storeCode}
                name="merchantStoreId"
                rules={[
                  {
                    max: 255,
                    message: pageData.partnerStoreCodeMaxLengthMessage,
                  },
                ]}
              >
                <FnbInput placeholder={pageData.partnerStoreCodePlaceholder} />
              </Form.Item>
              <FnbGuideline
                iconClassName="store-code-guideline-icon"
                title={t(pageData.partnerStoreCodeGuideline)}
                content={<MomoStoreCodeGuideline />}
                placement={isMobile ? "bottom" : "rightTop"}
              />
            </Col>
            <Col md={12} sm={24}></Col>
            <Col md={12} sm={24}>
              <Form.Item
                label={pageData.partnerCode}
                name="partnerCode"
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.partnerCodeValidationMessage}
                      />
                    ),
                  },
                ]}
              >
                <FnbInput placeholder={pageData.partnerCodePlaceholder} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item
                label={pageData.accessKey}
                name="accessKey"
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.accessKeyValidationMessage}
                      />
                    ),
                  },
                ]}
              >
                <FnbInput placeholder={pageData.accessKeyPlaceholder} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item
                label={pageData.publicKey}
                name="publicKey"
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.publicKeyValidationMessage}
                      />
                    ),
                  },
                ]}
              >
                <FnbInput autoComplete="new-username" placeholder={pageData.publicKeyPlaceholder} />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <Form.Item
                label={pageData.secretKey}
                name="secretKey"
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.secretKeyValidationMessage}
                      />
                    ),
                  },
                ]}
              >
                <FnbInput
                  type={TYPE_INPUT.PASSWORD}
                  autoComplete="new-password"
                  placeholder={pageData.accessKeyPlaceholder}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col className="sub-tab">
        <div className="momo-header">
          <div>
            <FnbSwitch checked={isShowCreditCard} onChange={(value) => onChangeSwitchCreditCard(value)} />
          </div>
          <div className="switch-title">
            <span className="name">{pageData.momoCreditCard}</span>
            <span className="description">{pageData.momoCreditCardDescription}</span>
          </div>
          {isChangeFormCreditCard && isShowCreditCard && (
            <Row className="list-button">
              <FnbButton text={pageData.button.cancel} variant="tertiary" onClick={onCancelCreditCard} />
              <FnbButton
                text={pageData.button.authenticate}
                disabled={activeAuthenticateButtonCreditCard}
                htmlType="submit"
                onClick={() => onClickAuthenticateCreditCard()}
              />
            </Row>
          )}
        </div>
        {isShowCreditCard && (
          <Form
            form={formCreditCard}
            className="form-edit custom-form"
            layout="vertical"
            autoComplete="off"
            onFinish={onClickAuthenticateCreditCard}
            onChange={onValidFormCreditCard}
            onValuesChange={() => {
              onFormChangedCreditCard();
            }}
          >
            <Row gutter={[16, 28]}>
              <Form.Item name="enumId" initialValue={paymentMethod.CreditDebitCard}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="paymentMethodId">
                <Input type="hidden" />
              </Form.Item>
              <Col md={12} sm={24}>
                <Form.Item
                  label={pageData.partnerCode}
                  name="partnerCode"
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.partnerCodeValidationMessage}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput placeholder={pageData.partnerCodePlaceholder} />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label={pageData.accessKey}
                  name="accessKey"
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.accessKeyValidationMessage}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput placeholder={pageData.accessKeyPlaceholder} />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label={pageData.publicKey}
                  name="publicKey"
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.publicKeyValidationMessage}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput autoComplete="new-username" placeholder={pageData.publicKeyPlaceholder} />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label={pageData.secretKey}
                  name="secretKey"
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.secretKeyValidationMessage}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type={TYPE_INPUT.PASSWORD}
                    autoComplete="new-password"
                    placeholder={pageData.accessKeyPlaceholder}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Col>
      <Col className="sub-tab">
        <div className="momo-header">
          <div className="col-switch">
            <FnbSwitch checked={false} />
          </div>
          <Col className="switch-title momo-personal">
            <Row span={24}>
              <span className="name">{pageData.momoPersonal}</span>
              <ComingSoonTag />
            </Row>
            <Row span={24} className="description">
              {pageData.momoPersonalDescription}
            </Row>
          </Col>
        </div>
      </Col>
    </Col>
  );
}
