import styled from "styled-components";
import { Button as AntButton } from "antd";

export const ForgetPasswordTheme1 = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 832px;
  @media (max-width: 576px) {
    justify-content: center;
    height: 616px;
  }
`;

export const IconBackForgotPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  svg {
    cursor: ${(p) => (p.loading ? "not-allowed" : "pointer")};
    width: 24px;
    height: 16px;
  }
  @media (max-width: 576px) {
    width: 24px;
    height: 24px;
    svg {
      width: 18px;
      height: 12px;
    }
  }
`;

export const BaseFormInput = styled.div`
  height: fit-content;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border-radius: 20px;
  margin-right: 100px;
  margin-top: 136px;
  margin-bottom: 136px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
  @media (max-width: 576px) {
    margin: auto 16px !important;
  }
`;

export const ButtonConfirm = styled(AntButton)`
  height: 60px;
  border: none;
  box-shadow: none;
  background: ${(p) => `rgb(from ${p.backgroundColor} r g b / 50%)`};
  span {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    color: ${(p) => p.textColor};
  }
  @media (max-width: 576px) {
    height: 48px;
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 20.16px;
    }
  }
`;

export const TitleHeading = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 30.24px;
  @media (max-width: 576px) {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
  }
`;
