export const MaximumNumber = 999999999;
export const MinimumNumber = 0;
export const EmptyId = "00000000-0000-0000-0000-000000000000";
export const DELAYED_TIME = 0;

export const tableSettings = {
  page: 1,
  pageSize: 20,
};

export const localeDateFormat = {
  enUS: "en-US",
};

export const COLOR = {
  PRIMARY: "#50429B",
  SECONDARY: "#EBE8FE",
};

export const sortConstant = {
  ASC: "ascend",
  DESC: "descent",
};

/**
 * Range number 0-999999999
 * https://regexr.com/
 */
export const inputNumberRange1To999999999 = {
  range: "^([0-9]|[1-9][0-9]{1,8})$",
};

/**
 * Range number 1-999999999
 * https://regexr.com/
 */
export const inputNumberRangeOneTo999999999 = {
  range: "^([1-9]|[1-9][0-9]{1,8})$",
};

/**
 * Range number 0-100
 * https://regexr.com/
 */
export const inputNumberRange0To100 = {
  range: "^([0-9]|[1-9][0-9]|100)$",
};

/**
 * Range number 0-300
 * https://regexr.com/
 */
export const inputNumberRange0To300 = {
  range: "^([0-9]|[1-9][0-9]|[1-2][0-9][0-9]|300)$",
};

/**
 * Range number 0-10000
 * https://regexr.com/
 */
export const inputNumberRange0To10000 = {
  range: "^(0|[1-9]\\d{0,3}|10000)(\\.\\d+)?$",
};

/**
 * Range number 0-999999999
 * https://regexr.com/
 */
export const inputNumberRange0To999999999 = {
  range: "^(0|[1-9]\\d{0,8})$",
};

export const inputNumberRange0To999999999DotAllow = {
  range: "^(0|([1-9]\\d{0,8}))(\\.\\d+)?$",
};

export const inputNumberRange1To999999999DotAllow = {
  range: "^([0-9]|[1-9][0-9]{1,8})(\\.\\d+)?$",
};

export const INPUT_PHONE_NUMBER_REGEX = /^[0-9]{9,12}\s*?$/;

export const POSTAL_CODE_REGEX = /^[a-zA-Z0-9- ]{3,50}\s*?$/;

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;

export const MAXIMUM_FILE_SIZE = 20 * 1024 * 1024; // 20 MB => bytes

export const MAXIMUM_FILE_SIZE_DISPLAY = 20; // 20 MB

export const EXCHANGE_RATE_USD_TO_VND = 24000;

/**
 * 3 digits in a number string.
 * for adding commas as thousand separators in a string of numbers
 */
export const THREE_DIGITS_REGEX = /\B(?=(\d{3})+(?!\d))/g;

export const SCROLL_DIRECTION = {
  HORIZONTAL: "HORIZONTAL ",
  VERTICAL: "VERTICAL",
  BOTH: "BOTH",
};

export const EnumStoreRegion = {
  VIETNAM: 0,
  INTERNATIONAL: 1,
};

export const Role = {
  STAFF: 0,
  ADMINISTRATOR: 1,
  SUPER_ADMINISTRATOR: 2,
};
