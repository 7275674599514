import React, { useLayoutEffect } from "react";
import { Row, Col } from "antd";
import { formatCurrencyWithoutSuffix, formatDate, getColorForChart, getCurrency } from "utils/helpers";
import { DateFormat } from "constants/string.constants";
import RevenuePieChart from "components/revenue-pie-chart";
import "./shift-info.component.scss";
import { EnumServiceType } from "constants/service-type.constants";

const ShiftInfoComponent = (props) => {
  const { t, shiftDetail } = props;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const pageData = {
    succeedOrder: t("report.shift.succeedOrder"),
    incompletedOrder: t("report.shift.incompletedOrder"),
    canceledOrder: t("report.shift.cancelOrder"),
    totalRevenue: t("report.shift.totalRevenue"),
    totalReceived: t("report.shift.totalReceived"),
    unpaid: t("report.shift.unpaid"),
    initialAmount: t("report.shift.initialAmount"),
    grossRevenue: t("report.shift.grossRevenue"),
    discount: t("report.shift.discount"),
    depositAmount: t("report.shift.depositAmount"),
    withdrawalAmount: t("report.shift.withdrawalAmount"),
    remainAmount: t("report.shift.remainAmount"),
    revenueByPaymentMethod: t("report.shift.revenueByPaymentMethod"),
    revenueByServiceType: t("report.shift.revenueByServiceType"),
    revenueByPlatform: t("report.shift.revenueByPlatform"),
    paymentMethod: t("report.shift.paymentMethod"),
    serviceType: t("report.shift.serviceType"),
    platform: t("report.shift.platform"),
  };

  const serviceType = {
    [EnumServiceType.InStore] : "serviceType.inStore",
    [EnumServiceType.Delivery]: "serviceType.delivery",
    [EnumServiceType.TakeAway]: "serviceType.takeAway",
    [EnumServiceType.Online]: "serviceType.online",
    [EnumServiceType.PickUp]: "serviceType.pickup",
  };
  

  const getConfigForPieChart = (chartData) => {
    const platformColor = [
      "rgba(67, 54, 134, 1)",
      "rgba(80, 66, 155, 1)",
      "rgba(111, 95, 191, 1)",
      "rgba(144, 131, 206, 1)",
      "rgba(192, 182, 241, 1)",
    ];

    const colorPalette = chartData?.length > platformColor?.length
      ? getColorForChart(249, 100, 24, chartData?.length)
      : platformColor;

    return chartData?.map((item, index) => {
      return {
        label: serviceType?.[item?.id] || item?.name,
        value: item?.totalAmount,
        color: colorPalette[index],
        totalAmount: item?.totalAmount,
      };
    });
  };

  const mapServiceTypeNameToLocale = (revenueByServiceType) => revenueByServiceType?.map(st => ({
    ...st,
    name: serviceType?.[st.id] || st.name
  }));

  return (
    <div className="revenue-summary-container">
      <div className="revenue-summary">
        <div className="shift-summary-detail">
          <div className="shift-info">
            <div className="brand-container">
              <span>{shiftDetail?.shiftInfo?.storeBranchName}</span>
              <div>{shiftDetail?.shiftInfo?.storeBranchAddress}</div>
            </div>
            {shiftDetail?.shiftInfo?.shiftCode && <div className="shift-code">#{shiftDetail?.shiftInfo?.shiftCode}</div>}
            <div className="total-revenue">
              <span>{pageData.totalRevenue}</span>
              <div>{formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.totalRevenue)} {getCurrency()}</div>
            </div>
            <div className="total-payment-status">
              <div className="received">
                <span>{pageData.totalReceived}</span>
                <div>{formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.totalReceived)}</div>
              </div>
              <div className="unpaid">
                <span>{pageData.unpaid}</span>
                <div>{formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.unpaid)}</div>
              </div>
            </div>
            <div className="shift-description-item">
              <Row gutter={[12, 12]}>
                <Col span={12} className="label-item">
                  {pageData.initialAmount}
                </Col>
                <Col span={12} className="field-item">
                  {formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.initialAmount)}
                </Col>
                <Col span={12} className="label-item">
                  {pageData.grossRevenue}
                </Col>
                <Col span={12} className="field-item">
                  {formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.grossRevenue)}
                </Col>
                <Col span={12} className="label-item">
                  {pageData.discount}
                </Col>
                <Col span={12} className="field-item">
                  {formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.discount)}
                </Col>
                <Col span={12} className="label-item">
                  {pageData.depositAmount}
                </Col>
                <Col span={12} className="field-item">
                  {formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.depositAmount)}
                </Col>
                <Col span={12} className="label-item">
                  {pageData.withdrawalAmount}
                </Col>
                <Col span={12} className="field-item">
                  {formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.withdrawalAmount)}
                </Col>
                <Col span={12} className="label-item">
                  {pageData.remainAmount}
                </Col>
                <Col span={12} className="field-item">
                  {formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.remain)}
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="chart-summary-detail">
          <div className="shift-summary">
            <div className="flex-column">
              <span className="checkin-username">{shiftDetail?.shiftInfo?.checkInUserName || "-"}</span>
              <span className="checkin-datetime">
                {shiftDetail?.shiftInfo?.checkInDateTime
                  ? formatDate(shiftDetail?.shiftInfo?.checkInDateTime, DateFormat.HH_MM_DD_MM_YYYY_)
                  : "-"}
              </span>
            </div>
            <div className="flex-column divider">
              <span className="checkout-username">{shiftDetail?.shiftInfo?.checkOutUserName || "-"}</span>
              <span className="checkout-datetime">
                {shiftDetail?.shiftInfo?.checkOutDateTime
                  ? formatDate(shiftDetail?.shiftInfo?.checkOutDateTime, DateFormat.HH_MM_DD_MM_YYYY_)
                  : "-"}
              </span>
            </div>
            <div className="flex-column">
              <span className="total-order">{pageData.succeedOrder}</span>
              <span className="succeed">
                {shiftDetail?.shiftInfo?.totalSucceedOrder ? formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.totalSucceedOrder) : 0}
              </span>
            </div>
            <div className="flex-column">
              <span className="total-order">{pageData.canceledOrder}</span>
              <span className="cancelled">
                {shiftDetail?.shiftInfo?.totalCanceledOrder ? formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.totalCanceledOrder) : 0}
              </span>
            </div>
            <div className="flex-column">
              <span className="total-order">{pageData.incompletedOrder}</span>
              <span className="incompleted">
                {shiftDetail?.shiftInfo?.totalIncompletedOrder ? formatCurrencyWithoutSuffix(shiftDetail?.shiftInfo?.totalIncompletedOrder) : 0}
              </span>
            </div>
          </div>
          <div className="revenue-chart-container">
            <div className="revenue-chart-item">
              <div className="custom-chart-pie">
                <RevenuePieChart
                  dataSourceRevenue={shiftDetail?.revenueByPaymentMethod}
                  getDataForPieChart={getConfigForPieChart(shiftDetail?.revenueByPaymentMethod)}
                  titleBack={pageData.revenueByPaymentMethod}
                  chartName={pageData.paymentMethod}
                  minHeight={200}
                  maxHeight={200}
                  maxHeightScroll={170}
                  donutSize={"40%"}
                  totalLabelInner={false}
                  maxContentDescripton
                  maxContentPieChart
                  hiddenRevenue
                  titlePlacementLeft
                  viewAllText="reportRevenue.seeMore"
                  hideText="reportRevenue.hide"
                />
              </div>
            </div>
            <div className="revenue-chart-item">
              <div className="custom-chart-pie">
                <RevenuePieChart
                  dataSourceRevenue={mapServiceTypeNameToLocale(shiftDetail?.revenueByServiceType)}
                  getDataForPieChart={getConfigForPieChart(shiftDetail?.revenueByServiceType)}
                  titleBack={pageData.revenueByServiceType}
                  chartName={pageData.serviceType}
                  minHeight={200}
                  maxHeight={200}
                  maxHeightScroll={170}
                  donutSize={"40%"}
                  totalLabelInner={false}
                  maxContentDescripton
                  maxContentPieChart
                  hiddenRevenue
                  titlePlacementLeft
                  viewAllText="reportRevenue.seeMore"
                  hideText="reportRevenue.hide"
                />
              </div>
            </div>
            <div className="revenue-chart-item">
              <div className="custom-chart-pie">
                <RevenuePieChart
                  dataSourceRevenue={shiftDetail?.revenueByPlatform}
                  getDataForPieChart={getConfigForPieChart(shiftDetail?.revenueByPlatform)}
                  titleBack={pageData.revenueByPlatform}
                  chartName={pageData.platform}
                  minHeight={200}
                  maxHeight={200}
                  maxHeightScroll={170}
                  donutSize={"40%"}
                  totalLabelInner={false}
                  maxContentDescripton
                  maxContentPieChart
                  hiddenRevenue
                  titlePlacementLeft
                  viewAllText="reportRevenue.seeMore"
                  hideText="reportRevenue.hide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftInfoComponent;
