import { Form, message, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { PermissionKeys } from "constants/permission-key.constants";
import { ClassicMember, Percent } from "constants/string.constants";
import membershipDataService from "data-services/membership/membership-data.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatCurrency, formatNumberDecimalOrInteger } from "utils/helpers";
import TableMembershipLevelByAccumulatedPointComponent from "./table-membership-level-name-by-accumulatedPoint.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { CloseModalIcon, DeleteOutlined, EditOutlined } from "constants/icons.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";

export default function TableCustomerMemberShip(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const tableMembershipLevelFuncs = React.useRef(null);
  const [showMembershipLevel, setShowMembershipLevelModel] = useState(false);
  const [accumulatedPoint, setAccumulatedPoint] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const DEFAULT_PAGESIZE = 20;
  const pageData = {
    title: t("membership.title"),
    btnDelete: t("button.delete"),
    btnEdit: t("button.edit"),
    btnIgnore: t("button.ignore"),
    accumulatedPoint: t("membership.accumulatedPoint"),
    membershipDiscount: t("membership.membershipDiscount"),
    numberOfMembers: t("membership.numberOfMembers", "Number of members"),
    no: t("membership.no"),
    action: t("membership.action"),
    name: t("membership.Name"),
    phone: t("membership.Phone"),
    titleModal: t("membership.titleModal"),
    membershipLevel: t("membership.membershipLevel"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    membershipDeleteSuccess: t("membership.membershipDeleteSuccess"),
    membershipDeleteFail: t("membership.membershipDeleteFail"),
    confirmDeleteContent: t("membership.membershipDeleteContent"),
    ignore: t("leaveDialog.ignore"),
  };

  const tableSettings = {
    pageSize: DEFAULT_PAGESIZE,
    columns: [
      {
        title: pageData.membershipLevel,
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: "35%",
        render: (val) => {
          return (
            <FnbTooltip title={val} onlyShowWhenEllipsis>
              <FnbTypography variant={theme.typography["b1-regular"]} text={val} />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.accumulatedPoint,
        dataIndex: "accumulatedPoint",
        key: "accumulatedPoint",
        width: "25%",
        align: "right",
        render: (val) => {
          return <>{formatNumberDecimalOrInteger(val)}</>;
        },
      },
      {
        title: pageData.membershipDiscount,
        dataIndex: "discount",
        key: "discount",
        width: "25%",
        align: "right",
      },
      {
        title: pageData.numberOfMembers,
        dataIndex: "member",
        key: "member",
        width: "25%",
        align: "right",
        render: (value, record) => {
          return <FnbTypography text={formatNumberDecimalOrInteger(value)} />;
        },
      },
    ],

    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize);
    },
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteContent, { name: name });
    return mess;
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize) => {
    const response = await membershipDataService.getMembershipsAsync(pageNumber, pageSize);
    const data = response?.customerMemberships?.map((s) => mappingRecordToColumns(s));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(pageNumber);
  };

  const mappingRecordToColumns = (item) => {
    return {
      index: item?.no,
      id: item?.id,
      member: item?.member,
      name: item?.accumulatedPoint === 0 ? ClassicMember : item?.name,
      accumulatedPoint: item?.accumulatedPoint,
      discount: item?.maximumDiscount ? formatCurrency(item?.maximumDiscount) : item.discount + Percent,
    };
  };

  const renderMembershipLevelLevelModal = () => {
    return (
      <FnbModal
        title={pageData.titleModal}
        closeIcon={<CloseModalIcon />}
        width={"1280px"}
        visible={showMembershipLevel}
        handleCancel={() => setShowMembershipLevelModel(false)}
        hideFooter={true}
        content={
          <TableMembershipLevelByAccumulatedPointComponent
            t={t}
            tableFuncs={tableMembershipLevelFuncs}
            membershipAccumulatedPoint={accumulatedPoint}
          />
        }
      />
    );
  };

  const onSelectedRowKeysChange = (keySelected, selectedRows) => {
    setSelectedRowKeys(keySelected);
    setSelectedRows(selectedRows);
  };

  const onEditRowKeys = () => {
    history.push(`/membership/edit/${selectedRows[0]?.id}`);
  };

  const onDeleteRowKeys = () => {
    setShowConfirmDeleteModal(true);
  };

  const confirmDeleteMembership = async () => {
    var res = await membershipDataService.deleteMembershipByIdAsync(selectedRows[0]?.id);
    if (res) {
      message.success(pageData.membershipDeleteSuccess);
    } else {
      message.error(pageData.membershipDeleteFail);
    }
    await fetchDatableAsync(1, tableSettings.pageSize);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowConfirmDeleteModal(false);
  };

  const onRowClick = (record, _index) => {
    setAccumulatedPoint(record?.accumulatedPoint);
    setShowMembershipLevelModel(true);
    if (tableMembershipLevelFuncs.current) {
      tableMembershipLevelFuncs.current(record?.accumulatedPoint);
    }
  };

  return (
    <>
      {renderMembershipLevelLevelModal()}
      <Form className="form-staff">
        <Row>
          <FnbTable
            columns={tableSettings.columns}
            pageSize={tableSettings.pageSize}
            dataSource={dataSource}
            currentPageNumber={currentPageNumber}
            total={totalRecords}
            onChangePage={tableSettings.onChangePage}
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedRowKeysChange,
              renderActions: (_, selectedRows) => {
                return (
                  <>
                    {selectedRows?.length === 1 && (
                      <FnbTooltip placement="top" title={pageData.btnEdit} zIndex={10}>
                        <FnbButton
                          variant="text"
                          className="row-edit-button"
                          iconHeader={<EditOutlined />}
                          onClick={onEditRowKeys}
                          disabled={selectedRows?.some((item) => item.accumulatedPoint === 0)}
                        />
                      </FnbTooltip>
                    )}
                    <FnbTooltip placement="top" title={pageData.btnDelete} zIndex={10}>
                      <FnbButton
                        variant="text"
                        className="row-delete-button"
                        iconHeader={<DeleteOutlined />}
                        onClick={onDeleteRowKeys}
                        disabled={selectedRows?.length > 1 || selectedRows?.some((item) => item.accumulatedPoint === 0)}
                      />
                    </FnbTooltip>
                  </>
                );
              },
            }}
            editPermission={PermissionKeys.EDIT_MEMBERSHIP_LEVEL}
            deletePermission={PermissionKeys.DELETE_MEMBERSHIP_LEVEL}
            scrollX="1200px"
            onRowClick={onRowClick}
          />
        </Row>
      </Form>
      <ConfirmDialogComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.ignore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={confirmDeleteMembership}
        type={ConfirmStyle.DELETE}
      />
    </>
  );
}
