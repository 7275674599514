export const RouteKeyConstants = {
  kitchenManagement: "app.store.kitchen",
  createNewKitchen: "app.store.kitchen.create-new-kitchen",
  editKitchen: "app.store.kitchen.edit-kitchen",
};


export const RoutePathConstants = { 
  kitchenManagement: "/store/kitchen-management",
  createNewKitchen: "/store/kitchen/create-new",
  editKitchen: "/store/kitchen/edit/:id",
}