import { Popover } from "antd";
import { ArrowDownOutlined } from "constants/icons.constants";
import Flags from "country-flag-icons/react/1x1";
import languageDataService from "data-services/language/language-data.service";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import languageService from "services/language/language.service";
import { isStoreInternational } from "utils/helpers";
import i18n from "utils/i18n";
import { setLanguageSession } from "./../../store/modules/session/session.actions";
import "./change-language.scss";

function ChangeLanguage(props) {
  const { t } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [languageList, setLanguageList] = useState([]);
  const dispatch = useDispatch();
  const languageSession = useSelector((state) => state.session?.languageSession);
  const isInternational = isStoreInternational();
  const isIpad = useMediaQuery({ maxWidth: 991 });

  useEffect(() => {
    if (!languageSession && !props.localSession) {
      loadLanguage();
      window.reloadLang = loadLanguage;
    } else {
      let languages = languageSession?.list ?? props.localSession?.list;
      let defaultLanguage = languageSession?.default ?? props.localSession?.default;
      if (isInternational && languages) {
        languages = languages?.filter((language) => language?.languageCode !== "vi");
        let defaultLang = languages?.filter((language) => language?.languageCode === "en");
        if (!languageSession?.default && defaultLanguage?.length > 0) {
          defaultLanguage = defaultLang[0];
        }
      }
      setDefaultLanguage(defaultLanguage);
      setLanguageList(languages);
      dispatch(setLanguageSession({ default: defaultLanguage, list: languages }));
    }
  }, []);

  const loadLanguage = () => {
    languageDataService.getListLanguageByStoreIdAndIsPublishAsync().then((jsonObject) => {
      let languages = jsonObject?.languages;
      if (isInternational) {
        languages = languages?.filter((language) => language?.languageCode !== "vi");
      }
      setLanguageList(languages);

      let defaultLanguageCode = languageService.getLang();
      let language = languages?.find((lang) => lang?.languageCode === defaultLanguageCode);
      setDefaultLanguage(language);
      dispatch(setLanguageSession({ default: language, list: languages }));
    });
  };

  const onChangeLang = (selectedLang) => {
    languageService.setLang(selectedLang);
    i18n.changeLanguage(selectedLang);

    let language = languageList?.find((lang) => lang.languageCode === selectedLang);
    setDefaultLanguage(language);
    dispatch(setLanguageSession({ default: language, list: languageList }));
    props.showAndHideLanguageBox(false);
  };

  const getFlag = (languageCode, title) => {
    var Flag = Flags[languageCode];
    return <Flag title={t(title)} className="country-flag" />;
  };

  const contentLanguage = useMemo(() => {
    return (
      <>
        {languageList?.map((item, index) => (
          <div key={index} className="change-language-option" onClick={() => onChangeLang(item.languageCode)}>
            {getFlag(item.emoji, item.name)}
            <span>{t(item.name)}</span>
          </div>
        ))}
      </>
    );
  }, [languageList, t]);

  const getDefaultFlag = useMemo(() => {
    return (languageCode, title) => {
      var Flag = Flags[languageCode];
      return (
        <>
          <Flag title={t(title)} className="country-flag" />
          <span>{t(title)}</span>
          <div className="arrow-down">
            <ArrowDownOutlined />
          </div>
        </>
      );
    };
  }, [t]);

  return (
    <Popover
      trigger={isIpad ? "click" : "hover"}
      placement="bottom"
      overlayClassName="change-language-popover"
      content={contentLanguage}
      visible={props.visible}
      onVisibleChange={props.showAndHideLanguageBox}
      align={{ offset: [0, -6] }}
    >
      <div className="admin-link-language">
        {defaultLanguage && getDefaultFlag(defaultLanguage.emoji, defaultLanguage.name)}
      </div>
    </Popover>
  );
}

export default ChangeLanguage;
