export const UpdateStockRequestType = {
  UPDATE_STOCK: 0,
  REMOVE_INGREDIENT: 1,
};

export const UpdateStockHistoryActionType = {
  CREATED: 0,
  EDITED: 1,
  REJECTED: -5,
  APPROVED: 3,
};

export const IngredientUpdateReasonType = {
  SHRINKAGE: 0,
  MISPLACEMENT: 1,
  BACKFLUSHING: 2,
  OTHERS: 3,
};

export const UpdateStockHistoryActionTypeLocalization = {
  [UpdateStockHistoryActionType.CREATED]: 'materialControl.historyRemoveRequest.actionType.created',
  [UpdateStockHistoryActionType.EDITED]: 'materialControl.historyRemoveRequest.actionType.edited',
  [UpdateStockHistoryActionType.REJECTED]: 'materialControl.historyRemoveRequest.actionType.rejected',
  [UpdateStockHistoryActionType.APPROVED]: 'materialControl.historyRemoveRequest.actionType.approved'
};

export const EnumUpdateStockReasonType = {
  SHRINKAGE: 0,
  MISPLACEMENT: 1,
  BACKFLUSHING: 2,
  OTHERS: 3,
};

export const EnumUpdateStockStatus = {
  WAITING_TO_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
};

export const EnumUpdateStockAction = {
  VIEW_DETAIL: 1,
  EDIT: 2,
};