import styled from "styled-components";
import theme from "theme";

const WIDTH_HEADING_PLATFORMS = {
  VN: 820,
  INTERNATIONAL: 520,
};
const WIDTH_HEADING_PLATFORMS_TABLET = {
  VN: 440,
  INTERNATIONAL: 290,
};

export const TabFeaturePackageContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    padding-top: 5px !important;
    background: ${theme.colors.primary[100]} !important;
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.primary[80]} !important;
  }
`;

export const TabFeaturePackageContainerScrollX = styled.div`
  min-width: ${(p) => p.minWidth}px;
  overflow-x: scroll;
`;
export const WrapperHeadingTab = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperTitledHeader = styled.div`
  padding-top: 24px;
  padding-left: 24px;
`;

export const HeaderTab = styled.div`
  background: #9183d8;
  height: 72px;
  border-radius: 24px 24px 0 0;
  position: relative;
  padding: 24px;
  &::before {
    content: "";
    background: #fff;
    position: absolute;
    width: 40px;
    height: 72px;
    left: -40px;
    border-radius: 24px;
    z-index: 1;
    top: 0;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -20px;
    height: 20px;
    width: 30px;
    background-color: #9183d8;
  }
`;

export const TableTab = styled.div`
  min-height: 400px;
  background: linear-gradient(192.95deg, #9183d8 0%, #50429b 87.73%);
  width: 100%;
  border-radius: 24px 0 24px 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const ListButtons = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const ButtonTab = styled.div`
  background-color: ${theme.colors.primary[80]};
  width: 266px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-blend-mode: screen;
  mix-blend-mode: screen;
  @media (max-width: 1200px) {
    width: 150px;
  }
`;

export const MainFunctionGroup = styled.div`
  background: linear-gradient(180deg, #a498e9 0%, #7e70cc 100%);
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-items: center;
  overflow: hidden;
`;

export const MainFunctionGroupTitle = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;

export const ListFunctionGroups = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

export const FunctionGroup = styled.div`
  background: #e1dbff;
  width: 100%;
`;

export const FunctionGroupTitle = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export const FunctionItem = styled.div`
  background-color: ${theme.colors.default.white};
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  border-bottom: ${(p) => (p.isLast ? "unset" : `1px solid ${theme.colors.primary[30]}`)};
`;

export const FunctionItemTitle = styled.div`
  width: calc(
    100% - ${(p) => (p.isInternational ? WIDTH_HEADING_PLATFORMS.INTERNATIONAL : WIDTH_HEADING_PLATFORMS.VN)}px
  );
  @media (max-width: 1200px) {
    width: calc(
      100% -
        ${(p) =>
          p.isInternational ? WIDTH_HEADING_PLATFORMS_TABLET.INTERNATIONAL : WIDTH_HEADING_PLATFORMS_TABLET.VN}px
    );
  }
`;

export const ListTicks = styled.div`
  width: ${(p) => (p.isInternational ? WIDTH_HEADING_PLATFORMS.INTERNATIONAL : WIDTH_HEADING_PLATFORMS.VN)}px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 1200px) {
    width: ${(p) =>
      p.isInternational ? WIDTH_HEADING_PLATFORMS_TABLET.INTERNATIONAL : WIDTH_HEADING_PLATFORMS_TABLET.VN}pxpx;
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${theme.colors.primary.main};
    }
  }
`;
