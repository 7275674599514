import ahaMoveLogo from "assets/icons/ahamove_logo.png";
import selfDelivery from "assets/icons/car-self-delivery.png";
import completeGif from "assets/icons/complete.gif";
import defaultImageBlog from "assets/icons/default-image-blog.svg";
import imgDefault from "assets/icons/img_default.png";
import baeminLogo from "assets/images/baemin-logo.png";
import beFoodLogo from "assets/images/be-food-logo.png";
import comboDefault from "assets/images/combo-default-img.jpg";
import comingSoon from "assets/images/coming-soon.png";
import defaultFoodyPlatformLogo from "assets/images/default-foody-platform-logo.png";
import goFoodLogo from "assets/images/go-food-logo.png";
import goFnbLogo from "assets/images/gofnb.png";
import grabFoodLogo from "assets/images/grab-food-logo.png";
import onlineStoreBusinessLicense from "assets/images/online-store-business-license.png";
import shopeeFoodLogo from "assets/images/shopee-food-logo.png";
import smsLogoNoColor from "assets/images/sms-no-color.png";
import smsLogo from "assets/images/sms.png";
import thumbnailComboDefault from "assets/images/thumbnail-combo-default-img.png";
import thumbnailDefault from "assets/images/thumbnail-default.png";
import zaloZnsNoColor from "assets/images/zalo-zns-no-color.png";
import zaloZns from "assets/images/zalo-zns.png";
import imagePreviewLeftBanner from "assets/images/preview-left-banner.png";
import howToSetUpFirstVI from "assets/images/how-to-set-up-unit-first_vi.png";
import howToSetUpFirstEN from "assets/images/how-to-set-up-unit-first_eng.png";
import howToSetUpSecondVI from "assets/images/how-to-set-up-unit-second_vi.png";
import howToSetUpSecondEN from "assets/images/how-to-set-up-unit-second_eng.png";
import howToSetUpProductVariantEN from "assets/images/variants_guideline_en.png";
import howToSetUpProductVariantVI from "assets/images/variants_guideline_vi.png";
import recipeLGuidelineEN from "assets/images/recipe_l_guideline_en.png";
import recipeLGuidelineVI from "assets/images/recipe_l_guideline_vi.png";
import recipeMGuidelineEN from "assets/images/recipe_m_guideline_en.png";
import recipeMGuidelineVI from "assets/images/recipe_m_guideline_vi.png";
import recipeSGuidelineEN from "assets/images/recipe_s_guideline_en.png";
import recipeSGuidelineVI from "assets/images/recipe_s_guideline_vi.png";
import productCustomizeEN from "assets/images/product-customization-image-en.png";
import productCustomizeVI from "assets/images/product-customization-image-vi.png";
import storeWebGuide from "assets/images/store-web-guide-image.png";
import storeAppGuide from "assets/images/store-app-guide-image.png";
import registerComplete from "assets/images/register-complete.png";

export const images = {
  selfDelivery: selfDelivery,
  completeGif: completeGif,
  ahaMoveLogo: ahaMoveLogo,
  imgDefault: imgDefault,
  goFnbLogo: goFnbLogo,
  comingSoon: comingSoon,
  comboDefault: comboDefault,
  thumbnailComboDefault: thumbnailComboDefault,
  onlineStoreBusinessLicense: onlineStoreBusinessLicense,
  smsLogo: smsLogo,
  smsLogoNoColor: smsLogoNoColor,
  zaloZns: zaloZns,
  zaloZnsNoColor: zaloZnsNoColor,
  defaultImageBlog: defaultImageBlog,
  baeminLogo: baeminLogo,
  beFoodLogo: beFoodLogo,
  goFoodLogo: goFoodLogo,
  grabFoodLogo: grabFoodLogo,
  shopeeFoodLogo: shopeeFoodLogo,
  defaultFoodyPlatformLogo: defaultFoodyPlatformLogo,
  thumbnailDefault: thumbnailDefault,
  imagePreviewLeftBanner: imagePreviewLeftBanner,
  howToSetUpFirstVI: howToSetUpFirstVI,
  howToSetUpFirstEN: howToSetUpFirstEN,
  howToSetUpSecondVI: howToSetUpSecondVI,
  howToSetUpSecondEN: howToSetUpSecondEN,
  howToSetUpProductVariantEN: howToSetUpProductVariantEN,
  howToSetUpProductVariantVI: howToSetUpProductVariantVI,
  recipeLGuidelineEN: recipeLGuidelineEN,
  recipeLGuidelineVI: recipeLGuidelineVI,
  recipeMGuidelineEN: recipeMGuidelineEN,
  recipeMGuidelineVI: recipeMGuidelineVI,
  recipeSGuidelineEN: recipeSGuidelineEN,
  recipeSGuidelineVI: recipeSGuidelineVI,
  productCustomizeEN: productCustomizeEN,
  productCustomizeVI: productCustomizeVI,
  storeWebGuide: storeWebGuide,
  storeAppGuide: storeAppGuide,
  registerComplete: registerComplete,
};
