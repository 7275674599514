import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTooltip, { FnbTooltipVariant } from "components/fnb-tooltip/fnb-tooltip";
import { EnumStoreRegion } from "constants/default.constants";
import { GuidanceLinkType } from "constants/guidance-link.constants";
import { BookSavedIcon } from "constants/icons.constants";
import guidanceLinkDataService from "data-services/guidance-link/guidance-link-data.service";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import theme from "theme";
import { isStoreInternational } from "utils/helpers";
import youtubeLogo from "../../assets/images/youtube-play.png";
import "./fnb-suffix-heading-guideline.style.scss";
import {
  AbsoluteContainIframe,
  ContentHeadingGuideline,
  IFramePlayVideo,
  IFramePlayVideoMobile,
  ModalFullScreenPlayVideo,
  ModalGuidanceMobile,
  RelativeContainIframe,
  YoutubeLogoStyle,
} from "./fnb-suffix-heading-guideline.styled";
import { useTranslation } from "react-i18next";

const FnbSuffixHeadingGuideline = ({ purposeType = undefined, visible = true }) => {
  const [t] = useTranslation();
  const [videoId, setVideoId] = useState("");
  const [wikipediaLink, setWikipediaLink] = useState("");
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [showModalFullScreenPlayVideo, setShowModalFullScreenPlayVideo] = useState(false);
  const [showModalMobileMode, setShowModalMobileMode] = useState(false);
  const [preventPlayVideo, setPreventPlayVideo] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screen.sm}) or (max-height: ${theme.screen.sm})`,
  });
  const isMobileVertical = useMediaQuery({ maxWidth: "576px", orientation: "portrait" });
  const pageData = {
    viewMoreWiki: t("guidanceLink.viewMoreWiki"),
  };

  const extractYouTubeVideoId = (url) => {
    const regex = /(?:youtube\.com\/.*[?&]v=|youtu\.be\/)([^&?/]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const getGuidanceLink = async (purposeType) => {
    try {
      const region = isStoreInternational() ? EnumStoreRegion.INTERNATIONAL : EnumStoreRegion.VIETNAM;
      const response = await guidanceLinkDataService.getGuidanceLinkRequest({ purposeType, region });
      if (response?.succeeded && response?.data?.length > 0) {
        const guidanceLinks = response.data;

        // Extract Wikipedia link
        const wikipediaLink = guidanceLinks.find((link) => link.linkType === GuidanceLinkType.Wikipedia)?.linkUrl;
        setWikipediaLink(wikipediaLink || null);

        // Extract YouTube video ID
        const youtubeLink = guidanceLinks.find((link) => link.linkType === GuidanceLinkType.YouTube)?.linkUrl;
        if (youtubeLink) {
          const videoId = extractYouTubeVideoId(youtubeLink);
          setVideoId(videoId);
        }
      }
    } catch {}
  };

  useEffect(() => {
    if (purposeType !== undefined) {
      getGuidanceLink(purposeType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purposeType]);

  const onVisibleChangeTooltip = (isShow) => {
    setVisibleTooltip(isShow);
  };

  const handleVideoPreviewClick = () => {
    setPreventPlayVideo(false);
    setShowModalFullScreenPlayVideo(true);
    setVisibleTooltip(false);
  };

  const handleCloseModal = () => {
    setPreventPlayVideo(true);
    setTimeout(() => {
      setShowModalFullScreenPlayVideo(false);
    }, 50);
  };

  const onCloseModalMobile = () => {
    setPreventPlayVideo(true);
    setTimeout(() => {
      setShowModalMobileMode(false);
    }, 50);
  };

  const handleVideoPreviewClickMobile = () => {
    setPreventPlayVideo(false);
    setShowModalMobileMode(true);
  };

  const onClickViewMoreWiki = () => {
    window.open(wikipediaLink, "_blank");
  };

  return (
    <>
      {(videoId || wikipediaLink) && visible && (
        <>
          {!isMobile ? (
            <>
              <FnbTooltip
                title={
                  <ContentHeadingGuideline>
                    {videoId && (
                      <RelativeContainIframe>
                        <iframe
                          id={`youtube-player-${videoId}`}
                          width="658"
                          height="292"
                          src={`https://www.youtube.com/embed/${videoId}`} // enable JS API
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="YouTube video player"
                        />
                        <AbsoluteContainIframe onClick={handleVideoPreviewClick} />
                      </RelativeContainIframe>
                    )}
                    {wikipediaLink && (
                      <FnbButton
                        text={pageData.viewMoreWiki}
                        variant="secondary-purple"
                        iconHeader={<BookSavedIcon />}
                        onClick={onClickViewMoreWiki}
                      />
                    )}
                  </ContentHeadingGuideline>
                }
                placement="bottom"
                variant={FnbTooltipVariant.SECONDARY}
                trigger="click"
                visible={visibleTooltip}
                onVisibleChange={onVisibleChangeTooltip}
                overlayClassName="fnb-tooltip-guideline-content-overlay"
              >
                <YoutubeLogoStyle src={youtubeLogo} alt="" />
              </FnbTooltip>
              <ModalFullScreenPlayVideo
                open={showModalFullScreenPlayVideo}
                onCancel={handleCloseModal}
                centered
                footer={<></>}
              >
                {!preventPlayVideo && (
                  <IFramePlayVideo
                    id={`youtube-player-${videoId}`}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video player"
                  />
                )}
              </ModalFullScreenPlayVideo>
            </>
          ) : (
            <>
              <YoutubeLogoStyle src={youtubeLogo} alt="" onClick={handleVideoPreviewClickMobile} />
              <ModalGuidanceMobile
                open={showModalMobileMode}
                onCancel={onCloseModalMobile}
                centered
                footer={<></>}
                isMobileHorizontalMode={!isMobileVertical}
              >
                {!preventPlayVideo && videoId && (
                  <IFramePlayVideoMobile
                    id={`youtube-player-${videoId}`}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video player"
                    isMobileHorizontalMode={!isMobileVertical}
                  />
                )}
                {wikipediaLink && (
                  <FnbButton
                    text={pageData.viewMoreWiki}
                    variant="secondary-purple"
                    iconHeader={<BookSavedIcon />}
                    onClick={onClickViewMoreWiki}
                    className="fnb-button-view-more-wiki"
                  />
                )}
              </ModalGuidanceMobile>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FnbSuffixHeadingGuideline;
