import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Form, InputNumber, Radio, Row, message } from "antd";
import { AddCircleOutlined, InfoCircleFillOutlined, RecycleOutlined } from "constants/icons.constants";
import { MaximumNumber } from "constants/default.constants";
import { useSelector } from "react-redux";
import { sessionSelector } from "store/modules/session/session.reducers";
import { CURRENCY_CODE } from "themes/constants/string.constants";
import { randomGuid, checkOnKeyPressValidation, getCurrency } from "utils/helpers";
import { EnumDeliveryMethod } from "constants/delivery-method.constants";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import deliveryConfigService from "data-services/delivery-config/delivery-config.service";
import i18next from "i18next";
import "./index.scss";

const newFeeByDistance = {
  id: randomGuid(),
  fromDistance: 0,
  toDistance: 0,
  feeValue: 1,
};

const SelfDeliveryConfig = (props) => {
  const { t, id, deliveryConfig, onChangeForm, reLoadFormData } = props;
  const [form] = Form.useForm();
  const session = useSelector(sessionSelector);
  const { currentUser } = session;
  const [listFeeByDistance, setListFeeByDistance] = useState([newFeeByDistance]);
  const [isFixedFee, setIsFixedFee] = useState(true);
  const [isValuesChange, setIsValuesChange] = useState(false);
  const pageData = {
    saveChanges: t("button.saveChanges"),
    cancel: t("button.cancel"),
    btnAddNewFee: t("deliveryMethod.byDistance.btnAddNewFee"),
    titleByYourBusinessNote: t("deliveryMethod.titleByYourBusinessNote"),
    typeOfDeliveryFee: t("deliveryMethod.typeOfDeliveryFee"),
    titleFixedFee: t("deliveryMethod.titleFixedFee"),
    titleFeeDyDistance: t("deliveryMethod.titleFeeDyDistance"),
    feeDistance: t("deliveryMethod.byDistance.feeDistance"),
    feeValueValidation: t("deliveryMethod.feeValueValidation"),
    isFixed: {
      validFee: t("deliveryMethod.isFixed.validFee"),
    },
    byDistance: {
      fromDistance: t("deliveryMethod.byDistance.fromDistance"),
      toDistance: t("deliveryMethod.byDistance.toDistance"),
      validToDistance: t("deliveryMethod.byDistance.validToDistance"),
      feeDistance: t("deliveryMethod.byDistance.feeDistance"),
      validFeeDistance: t("deliveryMethod.byDistance.validFeeDistance"),
    },
    updateSelfDeliveryConfigSuccess: t("deliveryMethod.updateSelfDeliveryConfigSuccess"),
  };

  useLayoutEffect(() => {
    getInitFormData();
  }, [deliveryConfig]);

  useEffect(() => {
    onChangeForm && onChangeForm(isValuesChange);
  }, [isValuesChange]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
  }, [i18next.language]);

  const getInitFormData = async () => {
    let formValues = form.getFieldsValue();
    const { selfDeliveryConfig } = formValues;
    setIsValuesChange(false);
    if (deliveryConfig && typeof deliveryConfig?.isFixedFee === "boolean") {
      const { isFixedFee, deliveryConfigPricings, feeValue } = deliveryConfig;

      const deliveryConfigPricingsOrder =
        (deliveryConfigPricings?.length || 0) > 0
          ? deliveryConfigPricings.sort((pre, current) => {
              return pre?.position - current?.position;
            })
          : [newFeeByDistance];
      selfDeliveryConfig.isFixedFee = isFixedFee;
      selfDeliveryConfig.feeValue = feeValue;
      selfDeliveryConfig.deliveryConfigPricings = deliveryConfigPricingsOrder;

      setIsFixedFee(selfDeliveryConfig.isFixedFee);
      setListFeeByDistance(selfDeliveryConfig.deliveryConfigPricings);

      form.setFieldsValue(formValues);
    } else {
      selfDeliveryConfig.isFixedFee = true;
      selfDeliveryConfig.deliveryConfigPricings = [newFeeByDistance];
      form.setFieldsValue(formValues);
    }
  };

  const resetInitFormData = async () => {
    setIsValuesChange(false);
    form.resetFields();
    await getInitFormData();
  };

  const onChangeValue = (index, value, key) => {
    const newData = listFeeByDistance?.map((item, i) => {
      if (i === index) {
        if (key === 1) {
          return {
            ...item,
            toDistance: value,
          };
        } else {
          return {
            ...item,
            feeValue: value,
          };
        }
      }
      return { ...item };
    });
    setListFeeByDistance(newData);
  };

  const handleAddFeeByDistance = () => {
    let formValues = form.getFieldsValue();
    const { selfDeliveryConfig } = formValues;
    form.validateFields().then(() => {
      let listNumber = selfDeliveryConfig?.deliveryConfigPricings.map((config) => config?.toDistance);
      let numberKmNext = Math.max(...listNumber);
      const newFeeByDistance = {
        id: randomGuid(),
        fromDistance: numberKmNext,
        toDistance: numberKmNext,
        feeValue: 1,
      };
      setListFeeByDistance([...listFeeByDistance, newFeeByDistance]);
      selfDeliveryConfig.deliveryConfigPricings = [...listFeeByDistance, newFeeByDistance];
      form.setFieldsValue(formValues);
      setIsValuesChange(true);
    });
  };

  const handleDeleteFeeByDistance = (deleteIndex, fromDistance) => {
    let formValues = form.getFieldsValue();
    const { selfDeliveryConfig } = formValues;
    listFeeByDistance?.forEach((item, index) => {
      if (deleteIndex === 0 && deleteIndex === index - 1) {
        item.fromDistance = 0;
      }
      if (deleteIndex !== 0 && deleteIndex !== listFeeByDistance.length - 1 && deleteIndex === index - 1) {
        item.fromDistance = fromDistance;
      }
    });
    let feeByDistances = [...listFeeByDistance];
    feeByDistances.splice(deleteIndex, 1);
    setListFeeByDistance(feeByDistances);
    selfDeliveryConfig.deliveryConfigPricings = feeByDistances;
    form.setFieldsValue(formValues);
    setIsValuesChange(true);
  };

  const handleSubmit = async (values) => {
    let { selfDeliveryConfig } = values;
    selfDeliveryConfig.deliveryMethodId = id;
      const response = await deliveryConfigService.updateDeliveryConfigAsync({
        deliveryConfig: { ...selfDeliveryConfig },
      });
      if (response) {
        message.success(pageData.updateSelfDeliveryConfigSuccess);
        reLoadFormData(EnumDeliveryMethod.SelfDelivery);
      }
    setIsValuesChange(false);
  };

  const handleChangeTypeOfDeliveryFee = (event) => {
    setIsFixedFee(event.target.value);
  };

  const handleValuesChange = () => {
    setIsValuesChange(true);
  };

  const renderFixedFee = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item
          label={
            <>
              {pageData.feeDistance}
              <p>*</p>
            </>
          }
          name={["selfDeliveryConfig", "feeValue"]}
          rules={[
            () => ({
              validator(_, value) {
                if (value && (value <= 0 || value >= 1e9)) {
                  return Promise.reject(
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={pageData.feeValueValidation}
                    />,
                  );
                }
                return Promise.resolve();
              },
            }),
            { required: true, message: pageData.isFixed.validFee },
          ]}
        >
          <InputNumber
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            precision={getCurrency() === CURRENCY_CODE.VND ? 0 : 2}
            id="selfDeliveryConfig-feeValue"
            onKeyPress={(event) => {
              const checkStatus = checkOnKeyPressValidation(
                event,
                "selfDeliveryConfig-feeValue",
                0,
                null,
                getCurrency() === CURRENCY_CODE.VND ? 0 : 2,
              );
              if (!checkStatus) event.preventDefault();
            }}
            addonAfter={currentUser?.currencyCode || CURRENCY_CODE.VND}
            disabled={!deliveryConfig?.isActivated}
            controls={false}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderFeeByDistance = () => {
    return (
      <div className="self-delivery-fee-settings-by-distance">
        {listFeeByDistance?.map((config, index) => (
          <Row
            key={index}
            gutter={[16, 28]}
            align="middle"
            wrap={false}
            className="self-delivery-fee-by-distance-wrapper"
          >
            <Col flex="auto">
              <Row gutter={[16, 28]}>
                <Form.Item hidden={true} name={["selfDeliveryConfig", "deliveryConfigPricings", index, "id"]}>
                  <InputNumber disabled defaultValue={config?.id} controls={false} />
                </Form.Item>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label={pageData.byDistance.fromDistance}
                    name={["selfDeliveryConfig", "deliveryConfigPricings", index, "fromDistance"]}
                  >
                    <InputNumber
                      disabled
                      defaultValue={config?.fromDistance}
                      addonAfter="km"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label={pageData.byDistance.toDistance}
                    name={["selfDeliveryConfig", "deliveryConfigPricings", index, "toDistance"]}
                    rules={[{ required: true, message: pageData.byDistance.validToDistance }]}
                  >
                    <InputNumber
                      defaultValue={config?.toDistance}
                      min={config?.fromDistance}
                      onChange={(value) => onChangeValue(index, value, 1)}
                      addonAfter="km"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      disabled={!deliveryConfig?.isActivated}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label={pageData.byDistance.feeDistance}
                    name={["selfDeliveryConfig", "deliveryConfigPricings", index, "feeValue"]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && (value <= 0 || value >= 1e9)) {
                            return Promise.reject(
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.feeValueValidation}
                              />,
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                      { required: true, message: pageData.byDistance.validFeeDistance },
                    ]}
                  >
                    <InputNumber
                      defaultValue={config?.feeValue}
                      addonAfter={currentUser?.currencyCode || CURRENCY_CODE.VND}
                      max={getCurrency() === CURRENCY_CODE.VND ? MaximumNumber : null}
                      onChange={(value) => onChangeValue(index, value, 2)}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      disabled={!deliveryConfig?.isActivated}
                      controls={false}
                      id={`selfDeliveryConfig-deliveryConfigPricings-${index}-feeValue`}
                      precision={getCurrency() === CURRENCY_CODE.VND ? 0 : 2}
                      onKeyPress={(event) => {
                        const checkStatus = checkOnKeyPressValidation(
                          event,
                          `selfDeliveryConfig-deliveryConfigPricings-${index}-feeValue`,
                          0,
                          null,
                          getCurrency() === CURRENCY_CODE.VND ? 0 : 2,
                        );
                        if (!checkStatus) event.preventDefault();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {listFeeByDistance && listFeeByDistance?.length > 1 && (
              <Col flex="none">
                <div
                  className={`self-delivery-fee-by-distance-delete-row ${
                    deliveryConfig?.isActivated ? "" : "self-delivery-fee-by-distance-delete-row-disabled"
                  }`}
                  onClick={() => handleDeleteFeeByDistance(index, config?.fromDistance)}
                >
                  <RecycleOutlined />
                </div>
              </Col>
            )}
          </Row>
        ))}
        <Row>
          <div
            className={`self-delivery-add-distance-fee ${
              deliveryConfig?.isActivated ? "" : "self-delivery-add-distance-fee-disabled"
            }`}
            onClick={handleAddFeeByDistance}
          >
            <AddCircleOutlined /> <span>{pageData.btnAddNewFee}</span>
          </div>
        </Row>
      </div>
    );
  };

  const renderTypeOfDeliveryFee = () => {
    if (isFixedFee) {
      return renderFixedFee();
    }
    return renderFeeByDistance();
  };

  return (
    <div className="self-delivery-container">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="self-delivery-title">
              <div className="self-delivery-note">
                <InfoCircleFillOutlined />
                <span>{pageData.titleByYourBusinessNote}</span>
              </div>
              {isValuesChange && (
                <div className="self-delivery-group-button">
                  <Button type="text" onClick={resetInitFormData}>
                    <span>{pageData.cancel}</span>
                  </Button>
                  <Button htmlType="submit" type="primary">
                    <span>{pageData.saveChanges}</span>
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="self-delivery-type-of-fee-wrapper">
              <span className="self-delivery-type-of-fee">{pageData.typeOfDeliveryFee}</span>
              <Form.Item name={["selfDeliveryConfig", "isFixedFee"]}>
                <Radio.Group
                  onChange={handleChangeTypeOfDeliveryFee}
                  defaultValue={isFixedFee}
                  disabled={!deliveryConfig?.isActivated}
                >
                  <Radio value={true}>{pageData.titleFixedFee}</Radio>
                  <Radio value={false}>{pageData.titleFeeDyDistance}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
        </Row>
        {renderTypeOfDeliveryFee()}
      </Form>
    </div>
  );
};

export default SelfDeliveryConfig;
