import { Button, Col, Form, Input, Row, message } from "antd";
import { EnumDeliveryMethod } from "constants/delivery-method.constants";
import { CallOutlined, ChromeOutlined, InfoCircleFillOutlined, MessagesOutlined } from "constants/icons.constants";
import deliveryConfigService from "data-services/delivery-config/delivery-config.service";
import parse from "html-react-parser";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

const GrabExpressConfig = (props) => {
  const { id, deliveryConfig, onChangeForm, reLoadFormData } = props;
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isValuesChange, setIsValuesChange] = useState(false);
  const pageData = {
    grabConfigText: t("deliveryMethod.grabConfigText"),
    linkWebsite:
      "https://help.grab.com/passenger/vi-vn/4403551977369-Bao-cao-chuyen-xe-giao-hang-GrabExpress-su-dung-thiet-bi-POS-can-ho-tro",
    website: "www.help.grab.com",
    email: "express.vn.support@grabtaxi.com",
    hotline: "090 2341 795",
    hotlineText: "090-234-1795",
    titleGrabExpressNote: t("deliveryMethod.titleGrabExpressNote"),
    saveChanges: t("button.saveChanges"),
    cancel: t("button.cancel"),
    updateSuccess: t("messages.updateSuccess"),
    updateFailed: t("messages.updateFailed"),
    promotionCode: {
      title: t("deliveryMethod.promotionCode.title"),
      placeholder: t("deliveryMethod.promotionCode.placeholder"),
    },
  };

  useLayoutEffect(() => {
    getInitFormData();
  }, [deliveryConfig]);

  useEffect(() => {
    onChangeForm && onChangeForm(isValuesChange);
  }, [isValuesChange]);

  const getInitFormData = async () => {
    setIsValuesChange(false);
    let formValues = form.getFieldsValue();
    const { grabConfig } = formValues;
    if (deliveryConfig) {
      grabConfig.promotionCode = deliveryConfig?.promotionCode;
      grabConfig.deliveryMethodId = id;
      form.setFieldsValue(formValues);
      const requestData = {
        grabConfig: {
          deliveryMethodId: id,
          isUpdateConfig: true,
        },
      };
      await deliveryConfigService.updateGrabConfigAsync(requestData);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      deliveryConfigService.updateGrabConfigAsync(values).then((res) => {
        if (res) {
          message.success(pageData.updateSuccess);
          reLoadFormData(EnumDeliveryMethod.GrabExpress);
        } else {
          message.error(pageData.updateFailed);
        }
      });
    });
    setIsValuesChange(false);
  };

  const handleValuesChange = () => {
    setIsValuesChange(true);
  };

  const resetInitFormData = async () => {
    setIsValuesChange(false);
    form.resetFields();
    await getInitFormData();
  };

  return (
    <div className="ahamove-express-container">
      <div className="ahamove-express-config">
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          scrollToFirstError
          autoComplete="off"
          onFinish={handleSubmit}
          onValuesChange={handleValuesChange}
        >
          <Form.Item hidden="true" name={["grabConfig", "deliveryMethodId"]}></Form.Item>
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div className="ahamove-express-title">
                <div className="ahamove-express-note">
                  <InfoCircleFillOutlined />
                  <span>{parse(pageData.titleGrabExpressNote)}</span>
                </div>
                {isValuesChange && (
                  <div className="ahamove-express-group-button">
                    <Button type="text" onClick={resetInitFormData}>
                      <span>{pageData.cancel}</span>
                    </Button>
                    <Button htmlType="submit" type="primary">
                      <span>{pageData.saveChanges}</span>
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item label={pageData.promotionCode.title} name={["grabConfig", "promotionCode"]}>
                <Input
                  placeholder={pageData.promotionCode.placeholder}
                  disabled={!deliveryConfig?.isActivated}
                  showCount
                  maxLength={100}
                  className="grab-express-promotion-code"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row gutter={[16, 16]} className="ahamove-express-contact">
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="ahamove-express-contact-item">
            <ChromeOutlined />
            <a href={pageData.linkWebsite} target="_blank" rel="noreferrer">
              {pageData.website}
            </a>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="ahamove-express-contact-item">
            <MessagesOutlined />
            <a href={`mailto:${pageData.email}`}>{pageData.email}</a>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="ahamove-express-contact-item">
            <CallOutlined />
            <a href={`tel:${pageData.hotline}`}>{pageData.hotlineText}</a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GrabExpressConfig;
