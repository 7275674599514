import { Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { EmailCampaignStatus } from "constants/email-campaign.constants";
import { DeleteOutlined, EditOutlined } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import emailCampaignDataService from "data-services/email-campaign/email-campaign-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatDate, hasPermission } from "utils/helpers";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbButton } from "components/fnb-button/fnb-button";
import theme from "theme";

export default function TableEmailCampaign(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const translateData = {
    no: t("table.no", "No"),
    campaignName: t("marketing.emailCampaign.campaignName", "Campaign Name"),
    time: t("promotion.table.time", "Time"),
    action: t("promotion.table.action", "Action"),
    search: t("promotion.search", "Search by name"),

    status: {
      title: t("promotion.table.status", "Status"),
      scheduled: t("marketing.emailCampaign.status.scheduled"),
      finished: t("marketing.emailCampaign.status.finished"),
      active: t("marketing.emailCampaign.status.active"),
    },

    button: {
      delete: t("button.delete"),
      edit: t("button.edit"),
      addNew: t("button.addNew"),
    },
    toCreateData: t("area.toCreateData"),
  };

  const getColumns = () => {
    const columns = [
      {
        title: translateData.no,
        dataIndex: "no",
        key: "no",
        align: "left",
        width: "5rem",
      },
      {
        title: translateData.campaignName,
        dataIndex: "campaignName",
        key: "campaignName",
        align: "left",
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true}>
              <FnbTypography text={record.campaignName} />
            </FnbTooltip>
          );
        },
      },
      {
        title: translateData.time,
        dataIndex: "sendingTime",
        key: "sendingTime",
        className: "col-time",
        width: "20%",
        render: (_, record) => {
          return (
            <>
              <span className="hh-mm-format">{formatDate(record?.sendingTime, DateFormat.HH_MM)}</span>{" "}
              {formatDate(record?.sendingTime, DateFormat.DD_MMM_YYYY)}
            </>
          );
        },
      },
      {
        title: translateData.status.title,
        dataIndex: "status",
        key: "status",
        align: "center",
        width: "9rem",
        className: "col-status",
        render: (_, record) => {
          switch (record?.status) {
            case EmailCampaignStatus.Scheduled:
              return <FnbBadge variant="warning" text={translateData.status.scheduled} />;
            case EmailCampaignStatus.Finished:
              return <FnbBadge variant="error" text={translateData.status.finished} />;
            default:
              return <FnbBadge variant="success" text={translateData.status.active} />;
          }
        },
      },
    ];

    return columns;
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch) => {
    const response = await emailCampaignDataService.getAllEmailCampaignAsync(pageNumber, pageSize, keySearch);
    const data = response?.emailCampaigns.map((s, index) => mappingRecordToColumns(s, index));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);

    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
  };

  const mappingRecordToColumns = (emailCampaign, index) => {
    return {
      ...emailCampaign,
      no: index + 1,
      id: emailCampaign?.id,
      campaignName: emailCampaign?.name,
    };
  };

  const handleSearchByName = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
      }, 500),
    );
  };

  const onChangePage = async (pageNumber, pageSize) => {
    fetchDatableAsync(pageNumber, pageSize, keySearch);
  };

  const onEditItem = (selectedRowKeys, selectedRows) => {
    history.push(`/email-campaign/edit/${selectedRows[0]?.id}`);
  };

  const onDeleteItem = (selectedRowKeys, selectedRows) => {
    //TODO: Handle delete email campaign
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onRowClick = (record) => {
    history.push(`/marketing/email-campaign/detail/${record?.id}`);
  };

  return (
    <Row className="form-staff">
      <FnbTable
        className="table-email-campaign"
        columns={getColumns()}
        pageSize={tableSettings.pageSize}
        dataSource={dataSource}
        currentPageNumber={currentPageNumber}
        total={totalRecords}
        onChangePage={onChangePage}
        search={{
          placeholder: translateData.search,
          onChange: handleSearchByName,
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectedRowKeysChange,
          renderActions: (selectedRowKeys, selectedRows) => (
            <>
              {selectedRowKeys?.length >= 1 && selectedRows[0]?.status === EmailCampaignStatus.Scheduled && (
                <>
                  <FnbTooltip placement="top" title={translateData.button.edit} zIndex={10}>
                    <FnbButton
                      permission={PermissionKeys.EDIT_EMAIL_CAMPAIGN}
                      variant="text"
                      className="row-edit-button"
                      text={<EditOutlined />}
                      onClick={() => onEditItem(selectedRowKeys, selectedRows)}
                      disabled={selectedRowKeys.length > 1}
                    />
                  </FnbTooltip>
                  <FnbTooltip placement="top" title={translateData.button.delete} zIndex={10}>
                    <FnbButton
                      permission={PermissionKeys.DELETE_EMAIL_CAMPAIGN}
                      variant="text"
                      className="row-delete-button"
                      text={<DeleteOutlined />}
                      onClick={() => onDeleteItem(selectedRowKeys, selectedRows)}
                      disabled={selectedRowKeys.length > 1}
                      hidden={true} // TODO Hidden Button until US delete Email Campaign
                    />
                  </FnbTooltip>
                </>
              )}
            </>
          ),
        }}
        onRowClick={hasPermission(PermissionKeys.VIEW_EMAIL_CAMPAIGN) && onRowClick}
        emptyText={
          hasPermission(PermissionKeys.CREATE_EMAIL_CAMPAIGN) && (
            <div className="content-collapse-empty-text">
              <FnbTypography.Link
                variant={theme.typography["b1-bold-underlined"]}
                underline={true}
                text={translateData.button.addNew}
                onClick={() => history.push("/email-campaign/create-new")}
              />
              {translateData.toCreateData}
            </div>
          )
        }
      />
    </Row>
  );
}
