import { Form, Image, Input, message, Row, Space } from "antd";
import {
  ArrowLeftBackIcon,
  DollarCircleIcon,
  EyeIcon,
  EyeOpenIcon,
  InfoCircleGrayIcon,
  KeyIcon,
  MessageIcon,
  RegisterLogoIcon,
  ShopBranchStoreLine,
  TickCircle1Icon,
  TickCircle2FinishIcon,
  TickCircleIcon,
} from "constants/icons.constants";
import { images } from "constants/images.constants";
import { languageCode, languageCodeLocalStorageKey, listDefaultLanguage } from "constants/language.constants";
import { EnumRegisterStep } from "constants/register-step.constants";
import { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import languageService from "services/language/language.service";
import "../../stylesheets/authenticator.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbButton } from "components/fnb-button/fnb-button";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { env } from "env";
import { Region } from "constants/region.constants";
import classNames from "classnames";
import { FnbSteps, Step } from "components/fnb-step/fnb-step";
import "./register.page.scss";
import { useMediaQuery } from "react-responsive";
import i18n from "utils/i18n";
import { useDispatch } from "react-redux";
import { setAuth, setLanguageSession, setPermissionGroup, setPermissions, setPrepareAddressData, setStoreInformation, setStoreLogo, setToken } from "store/modules/session/session.actions";
import ChangeLanguage from "components/change-language/change-language.component";
import { FnbSelectSingleIcon } from "components/fnb-select-single-icon/fnb-select-single-icon";
import { CircleFlag } from "react-circle-flags";
import "react-puzzle-captcha/dist/react-puzzle-captcha.css";
import PuzzleCaptcha from "components/puzzle-captcha/puzzle-captcha.component";
import loginDataService from "data-services/login/login-data.service";
import permissionDataService from "data-services/permission/permission-data.service";
import jwt_decode from "jwt-decode";
import { claimTypesConstants } from "constants/claim-types.constants";

const RegisterPage = (props) => {
  const { storeDataService, trialRegister, t, history } = props;
  const location = useLocation();
  const languageCodeLocalStorage = languageService.getLang() || languageCode.en;
  const isInternationalRegion = env.REACT_APP_REGION === Region.International;
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const dispatch = useDispatch();
  const [isCallRegister, setIsCallRegister] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState(
    localStorage.getItem(languageCodeLocalStorageKey) ?? listDefaultLanguage[0]?.languageCode,
  );
  const [countryOption, setCountryOption] = useState([]);
  const [registerEmailValue, setRegisterEmailValue] = useState("");
  const [isVerifyCodeSuccess, setIsVerifyCodeSuccess] = useState(true);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [isCallVerifyCode, setIsCallVerifyCode] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [newStoreInfo, setNewStoreInfo] = useState(null);

  const pageData = {
    registerAccount: t("registerAccount.register"),
    storeName: t("registerAccount.storeName"),
    inputStoreName: t("registerAccount.inputStoreName"),
    country: t("form.country"),
    phone: t("form.phone"),
    email: t("form.email"),
    validEmail: t("form.validEmail"),
    inputEmail: t("form.inputEmail"),
    validEmailPattern: t("form.validEmailPattern"),
    currency: t("form.currency"),
    selectCurrency: t("form.selectCurrency"),
    verificationCode: t("form.verificationCode"),
    inputVerificationCode: t("form.inputVerificationCode"),

    setUpStore: t("form.setUpStore"),
    yourAddress: t("form.yourAddress"),
    otpVerification: t("form.otpVerification"),
    justSendCode: t("form.justSendCode"),
    didNotReceive: t("form.didNotReceive"),
    resendCode: t("form.resendCode"),
    awesome: t("form.awesome"),
    proceed: t("form.proceed"),
    checkEmail: t("form.checkEmail"),
    checkEmailBold: t("form.checkEmailBold"),
    select: t("form.select"),
    button: {
      login: t("form.button.login"),
      back: t("form.button.back"),
      next: t("form.button.next"),
      complete: t("form.button.complete"),
      start: t("form.button.start"),
      backToLogin: t("registerAccount.backToLogin"),
      verify: t("form.button.verify"),
      resend: t("button.resend"),
    },

    accountInformation: t("form.accountInformation"),
    enterAccountInfo: t("form.enterAccountInfo"),
    setUpStoreDescription: t("form.setUpStoreDescription"),
    storeNameValid: t("registerAccount.storeNameValid"),
    welComeRegister1: t("registerAccount.welComeRegister1"),
    welComeRegister2: t("registerAccount.welComeRegister2"),
    password: t("form.password"),
    passwordPlaceholder: t("form.passwordPlaceholder"),
    passwordRequired: t("form.passwordRequired"),
    passwordLimit: t("form.passwordLimit"),
    passwordWhitespace: t("form.passwordWhitespace"),
    storeNameDescription: t("registerAccount.storeNameDescription"),
    verification: t("registerAccount.verification"),
    verificationInfo: t("registerAccount.verificationInfo"),
    notReceiveCode: t("registerAccount.notReceiveCode"),
    invalidCode: t("registerAccount.invalidCode"),
    pleaseEnterVerifyCode: t("registerAccount.pleaseEnterVerifyCode"),
    somethingWentWrong: t("registerAccount.somethingWentWrong"),
    codeSentSuccessfully: t("registerAccount.codeSentSuccessfully"),
  };

  const [form] = Form.useForm();
  const [currencies, setCurrencies] = useState(null);
  const [languageList, setLanguageList] = useState(listDefaultLanguage);

  //Address Info
  const [currentStep, setCurrentStep] = useState(EnumRegisterStep.AccountInfo);
  const isRegisterCompleteRef = useRef(false);
  useEffect(() => {
    async function fetchData() {
      await getInitDataAsync();
    }
    fetchData();
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isRegisterCompleteRef.current) {
        start();
      } else if (currentStep === EnumRegisterStep.AccountInfo) {
        setCurrentStep(EnumRegisterStep.AccountInfo);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // Prevent access from international stores to trial register page
    const { pathname } = history.location;
    if (pathname && pathname === "/register-trial-account" && isInternationalRegion) {
      history.push("/register-account");
    }
  }, [history.location]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

    form.validateFields(errorFields);
  }, [i18n.language]);

  const getInitDataAsync = async () => {
    let languages = languageList;
    if (isInternationalRegion) {
      languages = languages?.filter((language) => language?.languageCode !== "vi");
    }
    setLanguageList(languages);
    onChangeLang(defaultLanguage);

    const currentCountry = await fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        return data?.country;
      })
      .catch((error) => console.log("ipinfo error: ", error));

    var initData = await storeDataService.getPrepareRegisterNewStoreDataAsync({ region: isInternationalRegion });
    let defaultCountry = initData.countries?.filter((country) =>
      currentCountry
        ? currentCountry === "VN" && isInternationalRegion
          ? country?.iso === "US"
          : country?.iso === currentCountry
        : country?.iso === "US",
    );
    if (
      defaultCountry?.length > 0 &&
      initData?.defaultCountry?.id &&
      initData?.defaultCountry?.currencycode &&
      initData?.defaultCountry?.phonecode
    ) {
      initData.defaultCountry.id = defaultCountry[0]?.id;
      initData.defaultCountry.currencycode = defaultCountry[0]?.currencycode;
      initData.defaultCountry.phonecode = defaultCountry[0]?.phonecode;
      initData.defaultCountry.iso = defaultCountry[0]?.iso;
    }

    setCurrencies(initData?.currencies);
    countryOptionSelector(initData?.countries);

    const currency = initData?.currencies?.filter((item) => item?.code === initData?.defaultCountry?.currencycode);

    //setFieldsValue
    let formValue = form.getFieldsValue();
    formValue.countryId = initData?.defaultCountry?.id;
    formValue.currencyId = currency[0]?.id;
    formValue.langCode = languageCodeLocalStorage;
    formValue.region = isInternationalRegion ? 1 : 0;
    formValue.isTrial = isInternationalRegion || (!isInternationalRegion && trialRegister);
    form.setFieldsValue(formValue);
  };

  useEffect(() => {
    goToStep(currentStep);
  }, [currentStep]);

  const onCountryChange = (countryId) => {
    let country = countryOption?.find((item) => item?.value === countryId);
    const currency = currencies?.filter((currency) => currency?.code === country?.currencycode);
    let formValue = form.getFieldsValue();
    formValue.currencyId = currency[0]?.id;
    formValue.stateId = null;
    form.setFieldsValue(formValue);
  };

  const onFinish = async (values) => {
    setIsCallRegister(true);
    const payload = {
      ...values,
      fullName: values.email.trim(),
      address1: countryOption?.find((item) => item?.value === values?.countryId)?.nicename,
      isInternational: isInternationalRegion,
    };
    const res = await storeDataService.registerNewStoreAccountAsync(payload);
    if (res) {
      setNewStoreInfo(res);
      setCurrentStep(EnumRegisterStep.Complete);
      isRegisterCompleteRef.current = true;
      window.scrollTo({ top: 0 });
    }
    setIsCallRegister(false);
  };

  const resendVerifyCode = () => {
    setIsCaptchaVisible(true);
  };

  const next = () => {
    switch (currentStep) {
      case EnumRegisterStep.AccountInfo:
        form.validateFields(["email", "password"]).then(async (value) => {
          //Navigate to next step
          setCurrentStep(EnumRegisterStep.Verification);
          form.setFieldValue("code", "");
          setIsCaptchaVisible(false);

          //Check and sent verify code email
          if (value?.email !== registerEmailValue) {
            setRegisterEmailValue(value?.email);

            const payload = {
              email: value?.email,
              region: isInternationalRegion,
            };
            var isSent = await storeDataService.sentVerifyCodeRegisterEmailAsync(payload);
            if (isSent) {
              messageApi.open({
                content: pageData.codeSentSuccessfully,
                type: "success",
              });
            }
          }
        });
        break;
      case EnumRegisterStep.Verification:
        form.validateFields(["code"]).then(async (value) => {
          const payload = {
            email: registerEmailValue,
            verifyCode: value?.code,
          };
          setIsCallVerifyCode(true);
          await storeDataService.verifyCodeRegisterEmailAsync(payload).then((value) => {
            if (value?.isSuccess) {
              //Navigate to next step
              setCurrentStep(EnumRegisterStep.StoreInfo);
              setIsVerifyCodeSuccess(value?.isSuccess);
            } else {
              setIsVerifyCodeSuccess(false, form.validateFields(["code"]));
              if (value?.isError) {
                messageApi.open({
                  content: pageData.somethingWentWrong,
                  type: "error",
                });
              }
            }
            setIsCallVerifyCode(false);
          });
        });
        break;
      case EnumRegisterStep.StoreInfo:
        form
          .validateFields([
            "password",
            "email",
            "storeName",
            "countryId",
            "currencyId",
            "region",
            "langCode",
            "isTrial",
          ])
          .then((values) => {
            onFinish(values);
          });
        break;
      default:
        break;
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const getUserInfo = (token) => {
    let claims = jwt_decode(token);
    let user = {
      userId: claims[claimTypesConstants.id],
      accountId: claims[claimTypesConstants.accountId],
      fullName: claims[claimTypesConstants.fullName],
      emailAddress: claims[claimTypesConstants.email] ?? claims[claimTypesConstants.phoneNumber],
      accountType: claims[claimTypesConstants.accountType],
      currencyCode: claims[claimTypesConstants.currencyCode],
      storeId: claims[claimTypesConstants.storeId],
      currencySymbol: claims[claimTypesConstants.currencySymbol],
    };

    return user;
  };

  const setUserAuth = (auth, token, permissions) => {
    dispatch(setToken(token));
    dispatch(setAuth(auth));
    dispatch(setPermissions(permissions));

    storeDataService.getStoreInformationAsync().then((response) => {
      dispatch(setStoreInformation(response));
    });

    storeDataService.getPrepareAddressDataAsync().then((response) => {
      dispatch(setPrepareAddressData(response));
    });
  };

  const setupWorkspace = (token, userInfo) => {
    let auth = { token: token, user: userInfo };
    permissionDataService.getPermissionsAsync(token).then((res) => {
      const { permissions, permissionGroup } = res;
      if (permissions.length > 0 && permissionGroup.length > 0) {
        dispatch(setPermissionGroup(permissionGroup));
        setUserAuth(auth, token, permissions);
        props.history.push("/home");
        message.success(t("signIn.youHaveBeenLoggedInSuccessfully"));
      } else {
        message.error(t("signIn.youHaveNoPermissions"));
      }
    });
  }

  const start = () => {
    const userName = form.getFieldValue("email");
    const password = isInternationalRegion ? form.getFieldValue("password") : null;
    if (!isInternationalRegion) {
      history.push("/login", {
        username: userName,
      });
    } else {
      const loginReq = {
        userName: userName,
        password: password,
        accountId: newStoreInfo?.accountId,
        storeId: newStoreInfo?.storeId,
        region: isInternationalRegion,
      }
      loginDataService
      .authenticate(loginReq)
      .then((res) => {
        const {
          token,
          thumbnail,
          storeLogoUrl,
          isExpired,
          trialPackageExpired,
        } = res;
        dispatch(setStoreLogo(storeLogoUrl));
        var user = getUserInfo(token);
        var auth = {
          ...user,
          thumbnail,
          isExpired,
          trialPackageExpired,
        };
        setupWorkspace(token, auth);
      })
    }
  };

  const goToStep = (step) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("step", step);
    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  const containerRegisterClassName = classNames({
    "container-register": true,
    "c-authenticator": true,
    "register-bg": true,
    "c-authenticator--step-completed":
      currentStep === EnumRegisterStep.CompleteRegisterLocal ||
      currentStep === EnumRegisterStep.CompleteRegisterOtherCountry,
  });

  const showAndHideLanguageBox = (value) => {
    setIsVisiblePopover(value);
  };

  const onChangeLang = (selectedLang) => {
    var lang = languageList?.find((item) => item?.languageCode === selectedLang);
    i18n.changeLanguage(selectedLang);
    setDefaultLanguage(selectedLang);
    dispatch(setLanguageSession({ default: lang, list: languageList }));
  };

  const countryOptionSelector = (countries) => {
    let result = countries?.map((country) => {
      return {
        label: (
          <Row className="col-country">
            <CircleFlag className="circle-icon" countryCode={country?.iso?.toLowerCase()} height={20} />
            <div className="country-name">{t(country?.nicename)}</div>
          </Row>
        ),
        value: country?.id,
        labelSearch: country?.nicename,
        currencycode: country?.currencycode,
      };
    });
    setCountryOption(result);
  };

  const onCaptchaSuccess = async () => {
    setIsCaptchaVisible(false);
    //sent verify code email
    var registerEmail = form.getFieldValue("email");
    const payload = {
      email: registerEmail,
      region: isInternationalRegion,
    };
    var isSent = await storeDataService.sentVerifyCodeRegisterEmailAsync(payload);
    if (isSent) {
      messageApi.open({
        content: pageData.codeSentSuccessfully,
        type: "success",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className={containerRegisterClassName}>
        {props?.isAuthenticated ? (
          <Redirect to="home" />
        ) : (
          <>
            {isMobile ? (
              <div className="header">
                <RegisterLogoIcon width={42} height={40} />
                <div className="actions" style={{ visibility: isInternationalRegion ? "hidden" : "visible" }}>
                  <div className="header-change-language">
                    <ChangeLanguage
                      showAndHideLanguageBox={showAndHideLanguageBox}
                      visible={isVisiblePopover}
                      localSession={{
                        default: listDefaultLanguage.find((item) => item.languageCode === languageService.getLang()),
                        list: listDefaultLanguage,
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="logo-desktop">
                  <RegisterLogoIcon width={131} height={124} />
                </div>
              </>
            )}
            <div className="login-contain login-contain__right">
              <div className="login-inner">
                <Form
                  form={form}
                  className="form-register form-register__spacing custom-form"
                  layout="vertical"
                  autoComplete="off"
                >
                  {!isMobile && (
                    <div
                      className="actions-desktop"
                      style={{ visibility: isInternationalRegion ? "hidden" : "visible" }}
                    >
                      <div className="header-change-language">
                        <ChangeLanguage
                          showAndHideLanguageBox={showAndHideLanguageBox}
                          visible={isVisiblePopover}
                          localSession={{
                            default: listDefaultLanguage.find(
                              (item) => item.languageCode === languageService.getLang(),
                            ),
                            list: listDefaultLanguage,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {isInternationalRegion && currentStep === EnumRegisterStep.AccountInfo && (
                    <div className="welcome-register-title">
                      <FnbTypography variant={theme.typography["h1-bold"]} text={pageData.welComeRegister1} />
                      <FnbTypography variant={theme.typography["h1-bold"]} text={pageData.welComeRegister2} />
                    </div>
                  )}
                  {/* Step line icon */}
                  {currentStep !== EnumRegisterStep.Complete && (
                    <FnbSteps currentStep={currentStep} className={"register-step-component"}>
                      <Step
                        index={EnumRegisterStep.AccountInfo}
                        title={pageData.accountInformation}
                        typography={{ color: theme.colors.default.black }}
                        icon={<TickCircleIcon width={32} height={32} />}
                        iconFinish={<TickCircle2FinishIcon width={32} height={32} />}
                        className={currentStep === 1 ? "custom-line" : ""}
                      />
                      <Step
                        index={EnumRegisterStep.Verification}
                        title={pageData.verification}
                        typography={{
                          color:
                            currentStep === EnumRegisterStep.Verification
                              ? theme.colors.default.black
                              : theme.colors.gray[70],
                          variant: theme.typography["b1-regular"],
                        }}
                        icon={<TickCircle1Icon width={32} height={32} />}
                        iconFinish={<TickCircle2FinishIcon width={32} height={32} />}
                        className={currentStep === 2 ? "custom-line-verify" : ""}
                      />
                      <Step
                        index={EnumRegisterStep.StoreInfo}
                        title={pageData.setUpStore}
                        typography={{
                          color:
                            currentStep === EnumRegisterStep.StoreInfo
                              ? theme.colors.default.black
                              : theme.colors.gray[70],
                          variant: theme.typography["b1-regular"],
                        }}
                        icon={<TickCircle1Icon width={32} height={32} />}
                        iconFinish={<TickCircle2FinishIcon width={32} height={32} />}
                      />
                    </FnbSteps>
                  )}
                  <div className="form-container">
                    {/* Form account information */}
                    {currentStep === EnumRegisterStep.AccountInfo && (
                      <Space direction="vertical" size={24} className="account-info">
                        <div className="account-info-header">
                          <FnbTypography
                            variant={theme.typography[isMobile ? "h1-bold" : "d1-bold"]}
                            text={pageData.accountInformation}
                          />
                          <FnbTypography
                            variant={theme.typography["b2-regular"]}
                            color={theme.colors.gray[70]}
                            text={pageData.enterAccountInfo}
                          />
                        </div>
                        <Form.Item
                          name="email"
                          label={pageData.email}
                          rules={[
                            {
                              type: "email",
                              message: (
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.validEmailPattern}
                                />
                              ),
                            },
                            {
                              required: true,
                              message: (
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.validEmail}
                                />
                              ),
                            },
                          ]}
                          normalize={(value, prevVal, prevVals) => value.trim()}
                        >
                          <FnbInput prefix={<MessageIcon />} maxLength={100} placeholder={pageData.inputEmail} />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label={pageData.password}
                          rules={[
                            {
                              required: true,
                              message: <InputValidateMessage message={pageData.passwordRequired} />,
                            },
                            {
                              validator: (_, value) => {
                                if (value && value.length < 6) {
                                  return Promise.reject(<InputValidateMessage message={pageData.passwordLimit} />);
                                } else if (value && value.includes(" ")) {
                                  return Promise.reject(<InputValidateMessage message={pageData.passwordWhitespace} />);
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                          normalize={(value, prevVal, prevVals) => value.trim()}
                        >
                          <FnbInput
                            type={TYPE_INPUT.PASSWORD}
                            autoComplete="new-password"
                            prefix={<KeyIcon />}
                            iconRender={(visible) => (visible ? <EyeOpenIcon /> : <EyeIcon />)}
                            placeholder={pageData.passwordPlaceholder}
                          />
                        </Form.Item>
                      </Space>
                    )}

                    {/* Form email verification */}
                    {currentStep === EnumRegisterStep.Verification && (
                      <Space direction="vertical" size={24} className="store-info">
                        <div className="account-info-header">
                          <FnbTypography
                            variant={theme.typography[isMobile ? "h1-bold" : "d1-bold"]}
                            text={pageData.verification}
                          />
                          <span>
                            <FnbTypography
                              variant={theme.typography["b2-regular"]}
                              color={theme.colors.gray[70]}
                              text={pageData.verificationInfo}
                            />{" "}
                            <FnbTypography
                              variant={theme.typography["b2-regular"]}
                              color={theme.colors.default.black}
                              text={form.getFieldValue("email")}
                            />
                          </span>
                        </div>
                        <Form.Item
                          name="code"
                          label={pageData.verificationCode}
                          rules={[
                            {
                              required: true,
                              message: (
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.pleaseEnterVerifyCode}
                                />
                              ),
                            },
                            () => ({
                              validator(_, value) {
                                if (isVerifyCodeSuccess) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(<InputValidateMessage message={pageData.invalidCode} />);
                              },
                            }),
                          ]}
                        >
                          <FnbInput
                            style={{ width: "100%" }}
                            prefix={<KeyIcon />}
                            maxLength={6}
                            placeholder={pageData.inputVerificationCode}
                            onChange={() => setIsVerifyCodeSuccess(true, form.validateFields(["code"]))}
                            allowClear={true}
                            autoFocus={true}
                          />
                        </Form.Item>
                        {!isCaptchaVisible && (
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Space size={4}>
                              <FnbTypography
                                variant={theme.typography["b1-regular"]}
                                color={theme.colors.default.black}
                                text={pageData.notReceiveCode}
                              />
                              <FnbTypography.Link
                                underline
                                onClick={() => resendVerifyCode()}
                                text={pageData.button.resend}
                              />
                            </Space>
                          </div>
                        )}
                        <div className="re-captcha">
                          <PuzzleCaptcha
                            visible={isCaptchaVisible}
                            onSuccess={() => onCaptchaSuccess()}
                          ></PuzzleCaptcha>
                        </div>
                      </Space>
                    )}

                    {/* Form store information */}
                    {currentStep === EnumRegisterStep.StoreInfo && (
                      <Space direction="vertical" size={24} className="store-info">
                        <div className="account-info-header">
                          <FnbTypography variant={theme.typography["d1-bold"]} text={pageData.setUpStore} />
                          <FnbTypography
                            variant={theme.typography["b2-regular"]}
                            color={theme.colors.gray[70]}
                            text={pageData.setUpStoreDescription}
                          />
                        </div>
                        <div className="store-name">
                          <Form.Item
                            name="storeName"
                            label={pageData.storeName}
                            rules={[
                              {
                                required: true,
                                message: <InputValidateMessage message={pageData.storeNameValid} />,
                              },
                            ]}
                          >
                            <FnbInput
                              prefix={<ShopBranchStoreLine width={20} height={20} />}
                              maxLength={100}
                              placeholder={pageData.inputStoreName}
                            />
                          </Form.Item>
                          <FnbTypography
                            className="store-name-description"
                            iconHeader={<InfoCircleGrayIcon color={theme.colors.gray[70]} width={16} height={16} />}
                            color={theme.colors.gray[70]}
                            text={pageData.storeNameDescription}
                          />
                        </div>
                        <Form.Item name="countryId" rules={[{ required: true }]} label={pageData.country}>
                          <FnbSelectSingleIcon
                            size="large"
                            showSearch
                            autoComplete="none"
                            onChange={(e) => {
                              onCountryChange(e);
                            }}
                            option={countryOption}
                            virtual={false}
                          />
                        </Form.Item>
                        <Form.Item
                          name="currencyId"
                          label={pageData.currency}
                          rules={[
                            {
                              required: true,
                              message: (
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.selectCurrency}
                                />
                              ),
                            },
                          ]}
                        >
                          <FnbSelectSingle
                            showSearch
                            placeholder={pageData.select}
                            labelOption={<DollarCircleIcon className="icon-select-dropdown" />}
                            option={currencies?.map((item) => ({
                              id: item.id,
                              name: item.code,
                            }))}
                            prefixIcon={<DollarCircleIcon />}
                            virtual={false}
                          />
                        </Form.Item>
                      </Space>
                    )}

                    {/* Form register completed */}
                    {currentStep === EnumRegisterStep.Complete && (
                      <Space className="w-100 complete-info" direction="vertical" size={32}>
                        <div className="text-center wrap-img-awesome">
                          <Image src={images.registerComplete} preview={false} />
                        </div>
                        <Space className="w-100" style={{ flexDirection: "column" }} size={isMobile ? 16 : 32}>
                          <FnbTypography
                            variant={theme.typography[isMobile ? "h1-bold" : "d1-bold"]}
                            text={pageData.awesome}
                            color={theme.colors.default.black}
                            style={{ fontWeight: 600 }}
                          />
                          <Space className="w-100" direction="vertical" style={{ textAlign: "center" }}>
                            <FnbTypography
                              text={pageData.proceed}
                              variant={theme.typography[isMobile ? "b1-regular" : "h1-regular"]}
                              color={theme.colors.default.black}
                            />
                          </Space>
                        </Space>
                      </Space>
                    )}
                  </div>
                  <div className="bottom-content">
                    {currentStep === EnumRegisterStep.AccountInfo && (
                      <Row style={{ marginTop: "24px", justifyContent: "center", gap: "20px" }}>
                        <FnbButton text={pageData.button.next} width="100%" onClick={() => next()} />
                        <FnbTypography.Link
                          to="/login"
                          text={pageData.button.backToLogin}
                          underline
                          iconHeader={<ArrowLeftBackIcon width={16} height={16} />}
                        />
                      </Row>
                    )}
                    {currentStep === EnumRegisterStep.Verification && (
                      <Row style={{ marginTop: "24px", justifyContent: "center", gap: "20px" }}>
                        <FnbButton
                          text={pageData.button.verify}
                          width="100%"
                          onClick={() => next()}
                          loading={isCallVerifyCode}
                          disabled={isCallVerifyCode}
                        />
                        <FnbTypography.Link
                          onClick={prev}
                          text={pageData.button.back}
                          underline
                          iconHeader={<ArrowLeftBackIcon width={16} height={16} />}
                        />
                      </Row>
                    )}
                    {currentStep === EnumRegisterStep.StoreInfo && (
                      <Row style={{ marginTop: "24px", justifyContent: "center", gap: "20px" }}>
                        <FnbButton
                          text={pageData.button.complete}
                          width="100%"
                          onClick={() => next()}
                          loading={isCallRegister}
                          disabled={isCallRegister}
                        />
                        <FnbTypography.Link
                          onClick={prev}
                          text={pageData.button.back}
                          underline
                          iconHeader={<ArrowLeftBackIcon width={16} height={16} />}
                        />
                      </Row>
                    )}
                    {currentStep === EnumRegisterStep.Complete && (
                      <Row className="submit-button">
                        <FnbButton text={pageData.button.start} width="100%" onClick={() => start()} />
                      </Row>
                    )}
                  </div>
                  {/* Hidden form item */}
                  <div>
                    <Form.Item name="region" hidden="true" />
                    <Form.Item name="isTrial" hidden="true" />
                    <Form.Item name="langCode" className="d-none">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="countryId" hidden="true" rules={[{ required: true }]}></Form.Item>
                    <Form.Item
                      name="email"
                      hidden="true"
                      rules={[
                        {
                          type: "email",
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.validEmailPattern}
                            />
                          ),
                        },
                        {
                          required: true,
                          message: (
                            <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validEmail} />
                          ),
                        },
                      ]}
                    ></Form.Item>
                    <Form.Item name="currencyId" hidden="true" rules={[{ required: true }]}></Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RegisterPage;
