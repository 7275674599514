import { Card, Space, message } from "antd";
import defaultUserIcon from "assets/images/default-user.png";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { DateFormat } from "constants/string.constants";
import { UpdateStockHistoryActionTypeLocalization, UpdateStockRequestType } from "constants/update-stock-request.constants";
import branchDataService from "data-services/branch/branch-data.service";
import ingredientUpdateStockRequestDataService from "data-services/material-control/ingredient-update-stock-request.service";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { executeAfter, formatDate } from "utils/helpers";
import i18n from "utils/i18n";
import { KEY_TAB_PANE } from "../../table-inventory-control.component";
import FilterHistoryUpdateStockRequest from "./filter-history-update-stock-request";
import "./styles.scss";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";

const DEFAULT_PAGESIZE = 20;
const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_BRANCH = "";
const DEFAULT_ACTION_TYPE = null;

const HistoryUpdateStockRequest = () => {
  const [t] = useTranslation();
  const DEFAULT_DATA_FILTER = {
    branchId: DEFAULT_BRANCH,
    startDate: moment().startOf("date").format(DateFormat.YYYY_MM_DD_HH_MM_SS_2),
    endDate: moment().endOf("date").format(DateFormat.YYYY_MM_DD_HH_MM_SS_2),
    actionType: DEFAULT_ACTION_TYPE,
  };

  const pageData = {
    title: t("materialControl.updateStockRequest.history.title", "History of Update stock request"),
    back: t("button.back", "Back"),
    table: {
      timeline: t("materialControl.historyRemoveRequest.timeline", "Timeline"),
      activity: t("materialControl.historyRemoveRequest.activity", "Activity"),
      branch: t("store.branch", "Branch"),
      numberOfIngredients: t("materialControl.historyRemoveRequest.numberOfIngredients", "Number of ingredients"),
    },
    theRequest: t("materialControl.historyRemoveRequest.theRequest", "the request"),
    allBranch: t("materialControl.historyRemoveRequest.all"),
    searchPlaceholder: t("materialControl.historyRemoveRequest.searchPlaceholder", "Search by request ID or branch"),
  };

  const [histories, setHistories] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [dataFilter, setDataFilter] = useState(DEFAULT_DATA_FILTER);
  const [branches, setBranches] = useState([]);
  const filterRef = useRef(null);
  const [all, setAll] = useState(pageData.allBranch);

  const getBranches = async () => {
    const branchResponse = await branchDataService.getAllBranchsAsync();
    if (branchResponse?.branchs) {
      setBranches([{ id: "", name: pageData.allBranch }, ...branchResponse?.branchs]);
    }
  };

  useEffect(() => {
    getBranches();
    getRequestHistories(DEFAULT_PAGE_NUMBER, "", DEFAULT_DATA_FILTER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageData.allBranch !== all) {
      setAll(pageData.allBranch);
      if (branches.length > 0) {
        setBranches((prev) =>
          prev.map((item) => {
            if (item.id === DEFAULT_BRANCH) return { ...item, name: pageData.allBranch };
            return item;
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const tableSettings = {
    pageSize: DEFAULT_PAGESIZE,
    columns: [
      {
        title: pageData.table.timeline,
        key: "timeline",
        align: "left",
        width: "15%",
        dataIndex: "createdTime",
        render: (val) => formatDate(val, DateFormat.HH_mm_DD_MMM_YYYY),
      },
      {
        title: pageData.table.activity,
        key: "type",
        align: "left",
        width: "40%",
        render: (_, record) => {
          return (
            <Space size={4}>
              <img
                src={record?.thumbnail ? record?.thumbnail : defaultUserIcon}
                width={32}
                height={32}
                style={{ borderRadius: 4 }}
                alt=""
              />
              <FnbTooltip onlyShowWhenEllipsis maxWidthContent={280} title={record?.createdFullName}>
                <FnbTypography text={record?.createdFullName} variant={theme.typography["b1-regular"]} />
              </FnbTooltip>
              <FnbTypography
                text={`${t(UpdateStockHistoryActionTypeLocalization[record?.actionType])} ${pageData.theRequest}`}
              />
              <FnbTypography.Link
                text={`#${record?.updateStockRequestCode}`}
                variant={theme.typography["b1-bold"]}
                target="_blank"
                to={`/inventory/material-control/update-stock/${record?.updateStockRequestId}`}
              />
            </Space>
          );
        },
      },
      {
        title: pageData.table.numberOfIngredients,
        key: "numberOfIngredients",
        align: "center",
        width: "20%",
        render: (_, record) => (
          <FnbTooltip
            variant="secondary"
            title={
              <ul className="tooltip-number-of-ingredients-history-update-stock">
                {record?.ingredientNames?.map((item) => {
                  return (
                    <li>
                      <FnbTypography variant={theme.typography["b2-regular"]} text={item} />
                    </li>
                  );
                })}
              </ul>
            }
            maxWidth={400}
            placement="bottom"
          >
            <FnbTypography.Link text={record?.numberOfIngredients} variant={theme.typography["b1-bold"]} />
          </FnbTooltip>
        ),
      },
      {
        title: pageData.table.branch,
        key: "branchName",
        dataIndex: "branchName",
        align: "left",
        width: "25%",
        render: (val) => (
          <FnbTooltip title={val} onlyShowWhenEllipsis maxWidthContent={350}>
            <FnbTypography text={val} />
          </FnbTooltip>
        ),
      },
    ],
    onSearch: async (value) => {
      executeAfter(500, async () => {
        setKeySearch(value);
        setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
        await getRequestHistories(DEFAULT_PAGE_NUMBER, value, dataFilter);
      });
    },
    onChangePage: async (pageNumber) => {
      setCurrentPageNumber(pageNumber);
      await getRequestHistories(pageNumber, keySearch, dataFilter);
    },
  };

  const getRequestHistories = async (
    pageNumber = DEFAULT_PAGE_NUMBER,
    keySearch = "",
    dataFilter = DEFAULT_DATA_FILTER,
  ) => {
    const params = {
      pageNumber,
      pageSize: DEFAULT_PAGESIZE,
      keySearch,
      branchId: dataFilter?.branchId,
      startDate: dataFilter?.startDate,
      endDate: dataFilter?.endDate,
      actionType: dataFilter?.actionType,
      requestType: UpdateStockRequestType.UPDATE_STOCK,
    };

    const response = await ingredientUpdateStockRequestDataService.getHistoryAsync(params);
    if (response?.succeeded) {
      setHistories(response?.data?.result);
      setTotalRecords(response?.data?.total);
    } else {
      message.error(response?.message);
    }
  };

  const onClearFilter = async () => {
    if (filterRef && filterRef.current) {
      filterRef.current.resetFilter();
    }
    setCountFilter(0);
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
    const { branchId, type, ...rest } = dataFilter;
    const newDataFilter = {
      ...rest,
      branchId: DEFAULT_BRANCH,
      actionType: DEFAULT_ACTION_TYPE,
    };
    setDataFilter(newDataFilter);
    await getRequestHistories(DEFAULT_PAGE_NUMBER, keySearch, newDataFilter);
  };

  const onChangeFilter = async (itemChange, value) => {
    let newDataFilter = { ...dataFilter };
    let changes = 0;
    if (itemChange === "actionType") {
      newDataFilter = { ...newDataFilter, actionType: value };
    }
    if (itemChange === "branchId") {
      newDataFilter = { ...newDataFilter, branchId: value };
    }

    if (newDataFilter.actionType !== null) {
      changes++;
    }

    if (newDataFilter.branchId !== "" || newDataFilter.branchId !== null) {
      changes++;
    }

    setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
    setDataFilter(newDataFilter);
    setCountFilter(changes);
    await getRequestHistories(DEFAULT_PAGE_NUMBER, keySearch, newDataFilter);
  };

  const onSelectedDatePicker = async (date) => {
    const newDataFilter = {
      ...dataFilter,
      startDate: date?.startDate,
      endDate: date?.endDate,
    };
    setDataFilter(newDataFilter);
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
    await getRequestHistories(DEFAULT_PAGE_NUMBER, keySearch, newDataFilter);
  };
  
  const filterComponent = () => {
    return (
      <FilterHistoryUpdateStockRequest
        branches={branches}
        t={t}
        actionType={dataFilter.actionType}
        branchSelected={dataFilter.branchId}
        onChangeFilter={onChangeFilter}
        ref={filterRef}
      />
    );
  };

  return (
    <div className="page-history-update-stock-request">
      <FnbHeadingPage
        className="page-history-update-stock-request__fnb-heading-page"
        itemsBreadcrumb={[
          {
            href: `/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_UPDATE_STOCK}`,
            title: pageData.back,
          },
          {
            title: (
              <FnbTypography
                variant={theme.typography["h1-bold"]}
                text={pageData.title}
                color={theme.colors.default.black}
                style={{ lineHeight: "normal" }}
              />
            ),
          },
        ]}
      />
      <Card className="fnb-card-custom">
        <FnbTable
          columns={tableSettings.columns}
          pageSize={tableSettings.pageSize}
          dataSource={histories}
          currentPageNumber={currentPageNumber}
          onChangePage={tableSettings.onChangePage}
          total={totalRecords}
          search={{
            placeholder: pageData.searchPlaceholder,
            onChange: tableSettings.onSearch,
          }}
          filter={{
            totalFilterSelected: countFilter,
            onClearFilter: onClearFilter,
            component: filterComponent(),
          }}
          listButtons={[
            <FnbDatePicker
              selectedDate={dataFilter}
              setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
            />,
          ]}
        />
      </Card>
    </div>
  );
};

export default HistoryUpdateStockRequest;
