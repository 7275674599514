import BlogDefault from "../../../assets/images/blog-default.png";
import flashSaleProductDefault1Img from "../../../assets/images/flash-sale-product-default-1.png";
import flashSaleProductDefault2Img from "../../../assets/images/flash-sale-product-default-2.png";
import flashSaleProductDefault3Img from "../../../assets/images/flash-sale-product-default-3.png";
import flashSaleProductDefault4Img from "../../../assets/images/flash-sale-product-default-4.png";
import coffeeDefaultImg from "../../../assets/images/coffee-default.png";
import cocktailDefaultImg from "../../../assets/images/cocktail-default.png";
import yogurtDefaultImg from "../../../assets/images/yogurt-default.png";
import teaDefaultImg from "../../../assets/images/tea-default.png";
import creamTeaDefaultImg from "../../../assets/images/cream-tea-default.png";
import supDefaultImg from "../../../assets/images/sup-default.png";
import productDefault1 from "../../../assets/images/hibiscus-vanilla.png";
import productDefault2 from "../../../assets/images/pho_mai_tuyet_hoa_hong.png";
import productDefault4 from "../../../assets/images/tra_atiso_do_hat_boba.png";
import productDefault5 from "../../../assets/images/tra_dua_nhiet_doi.png";
import productDefault3 from "../../../assets/images/tra_vai.png";

export const categoryListDefaultInternational = [
  {
    thumbnail: coffeeDefaultImg,
    title: "Coffee",
    description: "Enjoy the aromatic aroma of coffee",
    buttonText: "Order now",
    hyperlink: "#",
  },
  {
    thumbnail: cocktailDefaultImg,
    title: "Cocktail",
    description: "Enjoy the aromatic aroma of coffee",
    buttonText: "Order now",
    hyperlink: "#",
  },
  {
    thumbnail: yogurtDefaultImg,
    title: "Yogurt",
    description: "Enjoy the aromatic aroma of coffee",
    buttonText: "Order now",
    hyperlink: "#",
  },
  {
    thumbnail: teaDefaultImg,
    title: "Type of tea",
    description: "The flavor of herbal tea comes from the Northwest mountains and forests",
    buttonText: "Order now",
    hyperlink: "#",
  },
  {
    thumbnail: creamTeaDefaultImg,
    title: "Special dish",
    description: "The elegant and solemn taste of the dish comes from simple ingredients",
    buttonText: "Order now",
    hyperlink: "#",
  },
  {
    thumbnail: supDefaultImg,
    title: "Premium taste",
    description: "Super premium flavors from famous chefs",
    buttonText: "Order now",
    hyperlink: "#",
  },
];

export const bestSellingProductDefaultInternational = [
  {
    id: "f7aaa991-2dc4-420d-b2e0-192f86fbc388",
    name: "Raspberry soda",
    thumbnail: productDefault1,
    productPrices: [{ priceValue: 25000.0 }, { priceValue: 30000.0 }, { priceValue: 40000.0 }],
  },
  {
    id: "510fdfb9-e19b-4d01-abd8-3b41fb4ec80a",
    thumbnail: productDefault2,
    name: "Cheese yogurt",
    productPrices: [{ priceValue: 60000.0 }, { priceValue: 230000.0 }, { priceValue: 120000.0 }],
  },
  {
    id: "c2001596-b809-4cd2-bcec-490fe3301701",
    thumbnail: productDefault3,
    name: "Lychee tea",
    productPrices: [{ priceValue: 33000.0 }, { priceValue: 25000.0 }, { priceValue: 30000.0 }],
  },
  {
    id: "56473499-582a-4c15-99c8-4d4b245b1b52",
    thumbnail: productDefault4,
    name: "Mulberry tea",
    productPrices: [{ priceValue: 20000.0 }],
  },
  {
    id: "1b7822b0-b996-4d34-90bb-4df93fbf932e",
    thumbnail: productDefault5,
    name: "Peach tea",
    productPrices: [{ priceValue: 25000.0 }],
  },
  {
    id: "085566e2-5bb7-468c-b35a-52a95167769b",
    thumbnail: productDefault2,
    name: "Oolong tea",
    productPrices: [{ priceValue: 19000.0 }, { priceValue: 29000.0 }, { priceValue: 25000.0 }],
  },
  {
    id: "38ba4ae9-1d91-4339-af1a-5aaa55815a5b",
    thumbnail: productDefault4,
    name: "Brown sugar milk tea",
    productPrices: [{ priceValue: 30000.0 }, { priceValue: 25000.0 }, { priceValue: 20000.0 }],
  },
];

export const storeDiscountDefaultInternational = [
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Reduced 100k product",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Reduced 100k product",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Reduced 100k product",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Reduced 100k product",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Reduced 100k product",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Reduced 100k product",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
];

export const discountCodeDefaultInternational = [
  {
    code: "H1MAB4",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Discount 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "AB26AX",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Discount 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "0KP1MA",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Discount 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "ZM6M1A",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Discount 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "MH78BF",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Discount 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
];

export const flashSaleDefaultInternational = {
  flashSaleHasEnded: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2023-03-18T03:36:00",
    endDate: "2023-03-18T03:36:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Strawberry Cocktail",
            thumbnail: flashSaleProductDefault1Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault2Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail cherry",
            thumbnail: flashSaleProductDefault3Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault4Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Strawberry Cocktail",
            thumbnail: flashSaleProductDefault1Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault2Img,
            description: "",
            productOptions: [],
          },
        },
      },
    ],
  },
  flashSaleIsHappening: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2023-04-18T03:36:00",
    endDate: "2099-04-18T16:00:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Strawberry Cocktail",
            thumbnail: flashSaleProductDefault1Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault2Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail cherry",
            thumbnail: flashSaleProductDefault3Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault4Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Strawberry Cocktail",
            thumbnail: flashSaleProductDefault1Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault2Img,
            description: "",
            productOptions: [],
          },
        },
      },
    ],
  },
  flashSaleIsComing: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2099-04-18T16:00:00",
    endDate: "2999-04-18T16:00:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Strawberry Cocktail",
            thumbnail: flashSaleProductDefault1Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault2Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail cherry",
            thumbnail: flashSaleProductDefault3Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault4Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Strawberry Cocktail",
            thumbnail: flashSaleProductDefault1Img,
            description: "",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Mixed Lemon Soda",
            thumbnail: flashSaleProductDefault2Img,
            description: "",
            productOptions: [],
          },
        },
      },
    ],
  },
};

export const blogDefaultInternational = [
  {
    blogId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bannerImageUrl: BlogDefault,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdTime: "2023-08-05T03:27:42.3237068",
    creator: "Admin",
    isMain: true,
    channel: "News",
    totalView: 500,
  },
  {
    blogId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bannerImageUrl: BlogDefault,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdTime: "2023-08-04T03:27:42.3237068",
    creator: "Admin",
    isMain: false,
    channel: "News",
    totalView: 400,
  },
  {
    blogId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bannerImageUrl: BlogDefault,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdTime: "2023-08-02T03:27:42.3237068",
    creator: "Admin",
    isMain: false,
    channel: "CUISINE",
    totalView: 300,
  },
];

export const signatureProductsInternational = [
  {
    imageUrl: require("../../../assets/images/signature-product.png"),
    title: " Strawbery smoothie with cheese",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    buttonText: "Try now",
  },
  {
    imageUrl: require("../../../assets/images/signature-product.png"),
    title: "Coffee",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    buttonText: "Try now",
  },
];

export const noNotificationHomePageEn = {
  noNotifications: "No Notifications Yet",
  noNotificationsRightNow: "You have no notifications right now.",
  comeBackLater: "Come back later.",
};
