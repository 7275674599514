import { Button, Col, Form, InputNumber, message, Row } from "antd";
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import ActionButtonGroup from "components/action-button-group/action-button-group.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbCard from "components/fnb-card/fnb-card.component";
import { inputNumberRange1To999999999, inputNumberRange1To999999999DotAllow } from "constants/default.constants";
import "moment/locale/vi";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  formatterDecimalNumber,
  formatNumberDecimalOrInteger,
  getCurrency,
  isDecimalKey,
  parserDecimalNumber,
} from "utils/helpers";
import "./referral-point-configuration.page.style.scss";
import storeDataService from "data-services/store/store-data.service";
import debounce from "lodash/debounce";
import { InfoCircleFlashSaleIcon } from "constants/icons.constants";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
const ReferralPointConfiguration = forwardRef((props, ref) => {
  const { setIsDataSave, res, fetchInitData, switcherReferralPointConfig, onChangeForm } = props;
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [earningExchangeNumber, setEarningExchangeNumber] = useState(0);
  const [currencyCode, setCurrencyCode] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const isVietnamese = getCurrency() === "VND";
  const [isReferralPointConfigActive, setIsReferralPointConfigActive] = useState(!switcherReferralPointConfig);

  const pageData = {
    btnSave: t("button.saveChanges"),
    btnCancel: t("button.cancel"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    title: t("referralPoint.title"),
    enableTitle: t("referralPoint.enable"),
    pointProgram: t("referralPoint.pointProgram"),
    earningPoint: t("loyaltyPoint.earningPoint"),
    summary: t("referralPoint.summary"),
    earningMessage: t("referralPoint.earningMessage"),
    description: t("referralPoint.description"),
    earningPointRequired: t("referralPoint.earningPointRequired"),
    earningOnePoint: t("loyaltyPoint.earningOnePoint"),
    modifySuccessMessage: t("referralPoint.modifySuccessMessage"),
    exchangeRate: t("loyaltyPoint.exchangeRate"),
    enable: t("status.enable"),
    disable: t("status.disable"),
    point: {
      min: 0,
      max: 999999999,
    },
    btnConfirmLeave: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    selectMembershipDate: t("referralPoint.selectMembershipDate"),
    enableMembershipDate: t("referralPoint.enableMembershipDate"),
    titleMembershipDate: t("referralPoint.titleMembershipDate"),
    formatDateTime: "dd/mm",
    endTimeUTC: "23:59:59 (UTC + 7)",
    theReferrerWillReceivePointWhen: t("referralPoint.theReferrerWillReceivePointWhen"),
    meaning: t("referralPoint.meaning"),
  };

  useImperativeHandle(ref, () => ({
    reload() {
      isChangeForm && reloadPage();
    },
  }));

  useEffect(() => {
    setCurrencyCode(getCurrency());
    form.validateFields();
  }, [i18n.language]);

  useEffect(() => {
    setIsReferralPointConfigActive(!switcherReferralPointConfig);
  }, [switcherReferralPointConfig]);

  useEffect(() => {
    setInitialFormValue(res);
  }, [res]);

  useEffect(() => {
    setIsDataSave(!isChangeForm);
  }, [isChangeForm]);

  const setInitialFormValue = async (res) => {
    if (res.hasData) {
      const { configuration } = res;
      const referralPointConfig = {
        earningPointExchangeValue: configuration.earningPointExchangeValue,
      };
      setEarningExchangeNumber(formatNumberDecimalOrInteger(configuration.earningPointExchangeValue));
      form.setFieldsValue({ referralPointConfig });
      setOriginalData(referralPointConfig);
    } else {
      const referralPointConfig = {
        earningPointExchangeValue: 1,
      };
      setEarningExchangeNumber(formatNumberDecimalOrInteger(referralPointConfig.earningPointExchangeValue));
      form.setFieldsValue({ referralPointConfig });
    }
  };

  const onFinish = async (value) => {
    storeDataService.modifyReferralPointAsync(value.referralPointConfig).then((res) => {
      if (res) {
        message.success(pageData.modifySuccessMessage);
        reloadPage();
      }
    });
  };

  const onChangeEarningPoint = (value) => {
    setEarningExchangeNumber(formatNumberDecimalOrInteger(value));
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      reloadPage();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = () => {
    const currenctData = form.getFieldValue("referralPointConfig");
    onChangeForm(true);
    setIsChangeForm(
      !originalData ||
        currenctData.isActivated !== originalData.isActivated ||
        currenctData.earningPointExchangeValue !== originalData.earningPointExchangeValue,
    );
  };

  // Redirect to home page
  const reloadPage = () => {
    setIsChangeForm(false);
    setShowConfirm(false);
    fetchInitData();
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={debounce(onFinish, 5000, { leading: true })}
        autoComplete="off"
        onValuesChange={(e) => changeForm(e)}
        className="referral-point"
      >
        <Row className="fnb-row-page-header">
          <Col xxl={18} span={24} className="referral-point__col--info">
            <div className="referral-point__dv--flex referral-point__dv--margin">
              <div className="referral-point__dv-icon">
                <InfoCircleFlashSaleIcon />
              </div>
              <span>{pageData.theReferrerWillReceivePointWhen}</span>
            </div>
          </Col>
          <Col xxl={6} span={24}>
            {isChangeForm && (
              <ActionButtonGroup
                gap={24}
                arrayButton={[
                  {
                    action: (
                      <Button htmlType="submit" type="primary" className="btn-save-data">
                        {pageData.btnSave}
                      </Button>
                    ),
                    permission: null,
                  },
                  {
                    action: (
                      <a onClick={() => onCancel()} className="action-cancel btn-cancel-data">
                        {pageData.btnCancel}
                      </a>
                    ),
                    permission: null,
                  },
                ]}
              />
            )}
          </Col>
        </Row>

        <FnbCard className="referral-point__card">
          <Row className="referral-point__row--title-exchange">
            <span className="referral-point__span--title-exchange">{pageData.exchangeRate}</span>
          </Row>
          <Row className="referral-point__row-exchange">
            <Col className="referral-point__col--flex-1">
              <Form.Item
                name={["referralPointConfig", "earningPointExchangeValue"]}
                rules={[
                  {
                    required: true,
                    message: pageData.earningPointRequired,
                  },
                  {
                    pattern: new RegExp(
                      isVietnamese ? inputNumberRange1To999999999.range : inputNumberRange1To999999999DotAllow.range,
                    ),
                    message: pageData.earningPointRequired,
                  },
                ]}
                className="earning-point-change referral-point__form"
              >
                <FnbInputNumber
                  placeholder="0"
                  min={1}
                  max={999999999}
                  formatter={(value) =>
                    isVietnamese
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : formatterDecimalNumber(value, true)
                  }
                  parser={(value) =>
                    isVietnamese ? value.replace(/\$\s?|(,*)/g, "") : parserDecimalNumber(value, true)
                  }
                  onChange={(value) => onChangeEarningPoint(value)}
                  onKeyPress={(event) => {
                    if ((isVietnamese && !/[0-9]/.test(event.key)) || !isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                  addonAfter={getCurrency()}
                  disabled={isReferralPointConfigActive}
                />
              </Form.Item>
            </Col>
            <Col className="referral-point__col--flex-1">
              <p>{t(pageData.titleAWork)}</p>
              <div className="earn-point">
                <span>{pageData.earningOnePoint}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span className="referral-point__span-spending">
                {`${pageData.meaning}: ${t(pageData.earningMessage, {
                  number: earningExchangeNumber,
                  currency: currencyCode,
                })}`}
              </span>
            </Col>
          </Row>
        </FnbCard>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={reloadPage}
        isChangeForm={isChangeForm}
      />
    </div>
  );
});

export default ReferralPointConfiguration;
