import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { IngredientRemoveStatus } from "constants/ingredient-remove.constants";
import { IngredientUpdateReasonType } from "constants/update-stock-request.constants";
import React from "react";
import { useTranslation } from "react-i18next";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { branches, defaultStatus, defaultBranchId, defaultReasonType } = props;
  const [form] = Form.useForm();

  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      status: {
        title: t("material.filter.status.title"),
        all: t("material.filter.status.all"),
      },
      createdTime: t("menu.inventoryManagement.createdTime"),
    },
  };

  const allBranchOption = {
    id: defaultBranchId,
    name: t("productManagement.filter.branch.all"),
  };

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    form.resetFields();
    props.fetchDataPopover({});
  };

  const ingredientUpdateReasonOptions = [
    { name: t("menu.inventoryManagement.allReasons"), id: defaultReasonType },
    { name: t("materialControl.updateStockRequest.shrinkage"), id: IngredientUpdateReasonType.SHRINKAGE.toString() },
    {
      name: t("materialControl.updateStockRequest.misplacement"),
      id: IngredientUpdateReasonType.MISPLACEMENT.toString(),
    },
    {
      name: t("materialControl.updateStockRequest.backflushing"),
      id: IngredientUpdateReasonType.BACKFLUSHING.toString(),
    },
    { name: t("materialControl.updateStockRequest.others"), id: IngredientUpdateReasonType.OTHERS.toString() },
  ];
  const handleOnValueChange = (changedValues, allValues) => {
    props.fetchDataPopover(allValues);
  };

  return (
    <Form layout="vertical" onValuesChange={handleOnValueChange} form={form}>
      <Form.Item label={t("purchaseOrder.branch")} name="branchId">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={defaultBranchId}
          option={[allBranchOption, ...branches]}
        />
      </Form.Item>
      <Form.Item label={t("order.reason")} name="reasonType">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={defaultReasonType}
          option={ingredientUpdateReasonOptions}
        />
      </Form.Item>
      <Form.Item label={t("table.status")} name="statusId">
        <Radio.Group defaultValue={defaultStatus} buttonStyle="solid">
          <FnbRadioButton value={defaultStatus}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={IngredientRemoveStatus.WAITING_TO_APPROVAL}>
            {t("menu.inventoryManagement.waitingForApproval")}
          </FnbRadioButton>
          <FnbRadioButton value={IngredientRemoveStatus.APPROVED}>
            {t("materialControl.updateStockRequest.status.approve")}
          </FnbRadioButton>
          <FnbRadioButton value={IngredientRemoveStatus.REJECTED}>{t("status.rejected")}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
