import http from "../../utils/http-common";

const controller = "purchaseOrderHistory";

const getAllPurchaseOrderHistoryAsync = (pageNumber, pageSize, keySearch, fromDate, toDate, branchId, supplierId, status, action, updateBy, purchaseOrderId) => {
  let url = `/${controller}?pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}`;
  if(fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`;
  if(branchId) 
    url += `&branchId=${branchId}`;
  if(supplierId) 
    url += `&supplierId=${supplierId}`;
  if(status !== undefined) 
    url += `&status=${status}`;
  if(action !== undefined) 
    url += `&action=${action}`;
  if(purchaseOrderId !== undefined) 
    url += `&purchaseOrderId=${purchaseOrderId}`;
  if(updateBy !== undefined) 
    url += `&updateBy=${updateBy}`;
  return http.get(url);
};

const getDetailPurchaseOrderHistoryAsync = (id) => {
  let url = `/${controller}/${id}`;
  return http.get(url);
};


const purchaseOrderHistoryDataService = {
  getAllPurchaseOrderHistoryAsync,
  getDetailPurchaseOrderHistoryAsync
};
export default purchaseOrderHistoryDataService;
