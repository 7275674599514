import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useState, useLayoutEffect, forwardRef, useImperativeHandle } from "react";
import RadioButtonGroup from "../RadioButtonGroup";
import { ComboStatus, ComboType } from "constants/combo.constants";
import comboDataService from "data-services/combo/combo-data.service";
import commonDataService from "data-services/common/common-data.service";
import moment from "moment";
import { tableSettings } from "constants/default.constants";
import FnbSelectProduct from "components/fnb-select-product";
import "./popover-filter.component.scss";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbSingleDatePicker from "components/fnb-single-date-picker/fnb-single-date-picker";

const DEFAULT_CHECK_ALL = "";
const DEFAULT_FORMAT_DATE = "DD/MM/YYYY";
const DEFAULT_FORMAT_UTC_DATE = "MM/D/YYYY, hh:mm:ss A";

const PopoverFilter = forwardRef((props, ref) => {
  const { comboParams, onChange } = props;
  const [t] = useTranslation();
  const [branches, setBranches] = useState([]);
  const [productInCombos, setProductInCombos] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(DEFAULT_CHECK_ALL);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [type, setType] = useState(DEFAULT_CHECK_ALL);
  const [status, setStatus] = useState(DEFAULT_CHECK_ALL);
  const defaultValue = "";

  const pageData = {
    resetAllFilters: t("combo.resetAllFilters"),
    branch: t("combo.branch"),
    allBranches: t("combo.allBranches"),
    fromDate: t("combo.fromDate"),
    toDate: t("combo.toDate"),
    all: t("combo.all"),
    productTitle: t("combo.product.title"),
    typeTitle: t("combo.type.title"),
    typeFlexible: t("combo.type.flexible"),
    typeSpecific: t("combo.type.specific"),
    statusTitle: t("combo.status.title"),
    statusActive: t("combo.status.active"),
    statusScheduled: t("combo.status.scheduled"),
    statusFinished: t("combo.status.finished"),
  };

  useLayoutEffect(() => {
    getAllBranchManagementAsync();
    getAllProductInComboAsync();
  }, []);

  useImperativeHandle(ref, () => ({
    resetFilter() {
      handleResetFilter();
    },
  }));

  const getAllBranchManagementAsync = async () => {
    await commonDataService
      .getAllBranchManagementAsync()
      .then((res) => {
        const allBranchOption = {
          id: "",
          name: t("material.filter.branch.all"),
        };
        const resBranch = res?.branchs?.map((branch) => ({ name: branch?.name, id: branch?.id }));
        const branchOptions = [allBranchOption, ...resBranch];

        setBranches(branchOptions);
      })
      .catch((_ex) => {
        setBranches([]);
      });
  };

  const getAllProductInComboAsync = async (params) => {
    await comboDataService
      .getAllProductInComboAsync(params)
      .then((res) => {
        setProductInCombos(res?.products);
      })
      .catch((_ex) => {
        setProductInCombos([]);
      });
  };

  const typeOptions = [
    {
      label: t(pageData.typeFlexible),
      value: ComboType.Fixed,
    },
    {
      label: t(pageData.typeSpecific),
      value: ComboType.Specific,
    },
  ];

  const statusOptions = [
    {
      label: t(pageData.statusActive),
      value: ComboStatus.Active,
    },
    {
      label: t(pageData.statusScheduled),
      value: ComboStatus.Schedule,
    },
    {
      label: t(pageData.statusFinished),
      value: ComboStatus.Finish,
    },
  ];

  const handleChangeBranch = (branchId) => {
    setSelectedBrand(branchId);
    setSelectedProducts([]);

    if (branchId) {
      getAllProductInComboAsync({ branchId });
    } else {
      getAllProductInComboAsync();
    }
    onChange &&
      onChange({
        ...comboParams,
        pageNumber: tableSettings.page,
        branchId,
        productIds: [],
      });
  };

  const handleChangeProducts = (productIds) => {
    setSelectedProducts(productIds);
    onChange &&
      onChange({
        ...comboParams,
        pageNumber: tableSettings.page,
        productIds,
      });
  };

  const handleChangeFromDate = (_date, dateString) => {
    setFromDate(dateString);
    setToDate("");
    onChange &&
      onChange({
        ...comboParams,
        pageNumber: tableSettings.page,
        fromDate: moment(dateString, DEFAULT_FORMAT_DATE).isValid()
          ? moment(dateString, DEFAULT_FORMAT_DATE).startOf("day").locale("en-US").format(DEFAULT_FORMAT_UTC_DATE)
          : "",
        toDate: "",
      });
  };

  const disabledToDate = (current) => {
    return current && current < moment(fromDate, DEFAULT_FORMAT_DATE).startOf("day");
  };

  const handleChangeToDate = (_date, dateString) => {
    setToDate(dateString);
    onChange &&
      onChange({
        ...comboParams,
        pageNumber: tableSettings.page,
        toDate: moment(dateString, DEFAULT_FORMAT_DATE).isValid()
          ? moment(dateString, DEFAULT_FORMAT_DATE).endOf("day").locale("en-US").format(DEFAULT_FORMAT_UTC_DATE)
          : "",
      });
  };

  const handleChangeType = (typeId) => {
    setType(typeId);
    onChange && onChange({ ...comboParams, pageNumber: tableSettings.page, typeId });
  };

  const handleChangeStatus = (statusId) => {
    setStatus(statusId);
    onChange && onChange({ ...comboParams, pageNumber: tableSettings.page, statusId });
  };

  const handleResetFilter = () => {
    const { branchId, productIds, fromDate, toDate, typeId, statusId, ...rest } = comboParams;
    setSelectedBrand(DEFAULT_CHECK_ALL);
    setSelectedProducts([]);
    setFromDate();
    setToDate();
    setType(DEFAULT_CHECK_ALL);
    setStatus(DEFAULT_CHECK_ALL);
    onChange && onChange({ ...rest, pageNumber: tableSettings.page });
  };

  return (
    <Form layout="vertical">
      <Form.Item label={pageData.branch}>
        <FnbSelect
          showSearch
          onChange={handleChangeBranch}
          value={selectedBrand}
          defaultValue={defaultValue}
          options={branches?.map((b) => ({
            value: b.id,
            label: b?.name,
          }))}
          placeholder={pageData.allBranches}
        />
      </Form.Item>
      <Form.Item label={pageData.productTitle}>
        <FnbSelectProduct options={productInCombos} onChange={handleChangeProducts} value={selectedProducts} />
      </Form.Item>
      <Form.Item label={pageData.fromDate}>
        <FnbSingleDatePicker onChange={handleChangeFromDate} value={fromDate} />
      </Form.Item>
      <Form.Item label={pageData.toDate}>
        <FnbSingleDatePicker
          disabledDate={disabledToDate}
          onChange={handleChangeToDate}
          value={toDate}
          disabled={!Boolean(fromDate)}
        />
      </Form.Item>
      <Form.Item label={pageData.typeTitle}>
        <RadioButtonGroup options={typeOptions} onChange={handleChangeType} value={type} />
      </Form.Item>
      <Form.Item label={pageData.statusTitle}>
        <RadioButtonGroup options={statusOptions} onChange={handleChangeStatus} value={status} />
      </Form.Item>
    </Form>
  );
});

export default PopoverFilter;
