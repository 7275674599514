export const BarcodeType = {
    /// <summary>
    /// Barcode
    /// </summary>
    barcode: 0,

    /// <summary>
    /// QR Code
    /// </summary>
    qrCode: 1,
};