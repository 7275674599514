import {
  AmericanExpressCardIcon,
  AtmCardIcon,
  BankTransferIcon,
  DiscoverCardIcon,
  JcbCardIcon,
  MasterCardIcon,
  PayPalIcon,
  VisaCardIcon,
} from "./icons.constants";

export const paymentMethod = {
  MoMo: 0,
  ZaloPay: 1,
  CreditDebitCard: 2,
  Cash: 3,
  VNPay: 4,
  COD: 5,
  BankTransfer: 6,
  Personal: 7,
  mPos: 8,
  PayPal: 9,
};

export const paymentMethodType = {
  All: -1,
  Enterprise: 0,
  Personal: 1,
};

/**
 * REF to EnumPackagePaymentMethod Code
 */
export const orderPackagePaymentMethod = {
  vnPay: "VNPAY",
  atm: "ATM",
  transfer: "BANK_TRANSFER",
  paypal: "PAYPAL",
};

export const enumPackagePaymentMethod = {
  visa: 0,
  atm: 1,
  bankTransfer: 2,
  paypal: 3,
};

export const orderPackagePaymentMethods = [
  {
    code: orderPackagePaymentMethod.vnPay,
    icon: [<MasterCardIcon />, <JcbCardIcon />, <VisaCardIcon />, <AmericanExpressCardIcon />, <DiscoverCardIcon />],
    name: "package.payment.creditCard",
    disable: true,
    isInternationalPayment: false,
  },
  {
    code: orderPackagePaymentMethod.atm,
    icon: <AtmCardIcon />,
    name: "package.payment.atm",
    disable: true,
    isInternationalPayment: false,
  },
  {
    code: orderPackagePaymentMethod.paypal,
    icon: <PayPalIcon />,
    name: "package.payment.paypal",
    disable: false,
    isInternationalPayment: true,
  },
  {
    code: orderPackagePaymentMethod.transfer,
    icon: <BankTransferIcon />,
    name: "package.payment.bankTransfer",
    disable: false,
    isInternationalPayment: false,
  },
];

export const orderPaymentMethodLocalization = {
  [paymentMethod.MoMo]: "payment.momo",
  [paymentMethod.ZaloPay]: "Zalo Pay",
  [paymentMethod.CreditDebitCard]: "payment.creditDebitCard",
  [paymentMethod.Cash]: "payment.cash",
  [paymentMethod.VNPay]: "VNPay",
  [paymentMethod.COD]: "payment.cash",
  [paymentMethod.BankTransfer]: "payment.bankTransfer",
  [paymentMethod.mPos]: "payment.mPOS",
  [paymentMethod.PayPal]: "payment.payPal",
};
