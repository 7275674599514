export const DefaultActiveKeyBlockCollapse = {
  Color: 1,
  Header: 2,
  Footer: 3,
  Banner: 4,
  ProductList: 5,
  BestSellingProduct: 6,
  GeneralBackground: 7,
  Favicon: 8,
  AppLogo: 9,
};
