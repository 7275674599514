import { createHttp } from "../utils/http-common";

const controller = "Payment";

const updateStoreWebOrderMomoPayment = (requestId, orderId, amount) => {
  const http = createHttp();
  return http.put(
    `/${controller}/update-store-web-order-momo-payment?requestId=${requestId}&orderId=${orderId}&amount=${amount}`,
  );
};

const updateReserveTableMomoPayment = (requestId, reserveTableId, amount) => {
  const http = createHttp();
  return http.put(
    `/${controller}/momo/reserve-table-status?requestId=${requestId}&reserveTableId=${reserveTableId}&amount=${amount}`,
  );
};

const updateStoreWebOrderMomoPaymentWithPoint = (
  requestId,
  orderId,
  amount,
  isUsePoint,
  usedPoint,
  redeemPointExchangeValue,
) => {
  const http = createHttp();
  return http.put(
    `/${controller}/update-store-web-order-momo-payment?requestId=${requestId}&orderId=${orderId}&amount=${amount}&isActiveUsedPoint=${
      isUsePoint ?? ""
    }&usedPoint=${usedPoint ?? ""}&redeemPointExchangeValue=${redeemPointExchangeValue ?? ""}`,
  );
};

const createPaymentByOrder = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/create-payment-by-order`, data);
};

const paymentDataService = {
  updateStoreWebOrderMomoPayment,
  updateStoreWebOrderMomoPaymentWithPoint,
  updateReserveTableMomoPayment,
  createPaymentByOrder,
};

export default paymentDataService;
