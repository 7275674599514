import React from "react";
import { Card } from "antd";

export default function PermissionGroupDetail(props) {
  return (
    <Card>
      <p>Permission group detail page</p>
    </Card>
  );
}
