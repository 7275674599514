import { Space } from "antd";
import { FnbTabsV2 } from "components/fnb-tabs/fnb-tabs.component.v2";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { PermissionKeys } from "constants/permission-key.constants";
import { PromotionTabLabel } from "constants/promotion.constants";
import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utils/helpers";
import { HeaderPromotion } from "./components/HeaderPromotion";
import "./flashsale-discount.page.scss";
import ListBuyXGetYPage from "./list-buy-x-get-y/ListBuyXGetYPage";
import DiscountCodeManagement from "./list-discount-code/list-discount-code.page";
import FlashSaleManagement from "./list-flashsale/list-flashsale.page";
import PromotionManagement from "./list-promotion/list-promotion.page";
import { AppContextProvider } from "./promotion-context";
const KEY_TAB_PANE = {
  DISCOUNT_CAMPAIGN: PromotionTabLabel.Discount,
  FLASH_SALE_CAMPAIGN: PromotionTabLabel.FlashSale,
  DISCOUNT_CODE: PromotionTabLabel.DiscountCode,
  BUY_X_GET_Y: PromotionTabLabel.BuyXGetY,
};

export default function FlashSaleDiscount(props) {
  const [t] = useTranslation();
  const [activeScreen, setActiveScreen] = useState(KEY_TAB_PANE.DISCOUNT_CAMPAIGN);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [guideline, setGuideline] = useState({});
  const pageData = {
    flashSaleTitle: t("promotion.flashSale.tabTitle"),
    discountTitle: t("promotion.discount.tabTitle"),
    discountCodeTitle: t("discountCode.title"),
    guidelinePromotion: {
      title: t("promotion.guideline.title"),
      content: t("promotion.guideline.content"),
    },
    guidelineFlashSale: {
      title: t("promotion.flashSale.guideline.title"),
      content: t("promotion.flashSale.guideline.content"),
    },
    guidelineDiscountCode: {
      title: t("discountCode.guideline.title"),
      content: t("discountCode.guideline.content"),
    },
    buyXGetYTitle: t("promotionCampaign.buyXGetY"),
    promotionCampaign: {
      linkAddNew: "/store/buy-x-get-y/create-new",
      createBuyXgetYCampaign: t("promotionCampaign.createBuyXgetYCampaign"),
      createCampaign: t("promotionCampaign.createCampaign"),
    },
  };

  useLayoutEffect(() => {
    const key = getDefaultActiveKey(props?.match?.params?.tabName);
    handleSetGuideline(key);
    setActiveScreen(key);
  }, []);

  const getDefaultActiveKey = (activeKey) => {
    switch (activeKey?.toLowerCase()) {
      case PromotionTabLabel.FlashSale:
        return KEY_TAB_PANE.FLASH_SALE_CAMPAIGN;
      case PromotionTabLabel.DiscountCode:
        return KEY_TAB_PANE.DISCOUNT_CODE;
      case PromotionTabLabel.BuyXGetY:
        return KEY_TAB_PANE.BUY_X_GET_Y;
      default:
        return KEY_TAB_PANE.DISCOUNT_CAMPAIGN;
    }
  };

  const handleSetGuideline = (activeKey) => {
    switch (activeKey) {
      case KEY_TAB_PANE.DISCOUNT_CAMPAIGN:
        setGuideline({
          title: pageData.guidelinePromotion.title,
          content: pageData.guidelinePromotion.content,
        });
        break;
      case KEY_TAB_PANE.FLASH_SALE_CAMPAIGN:
        setGuideline({
          title: pageData.guidelineFlashSale.title,
          content: pageData.guidelineFlashSale.content,
        });
        break;
      case KEY_TAB_PANE.DISCOUNT_CODE:
        setGuideline({
          title: pageData.guidelineDiscountCode.title,
          content: pageData.guidelineDiscountCode.content,
        });
        break;
      default:
        setGuideline({
          title: pageData.guidelinePromotion.title,
          content: pageData.guidelinePromotion.content,
        });
        break;
    }
  };

  const changeTab = (activeKey) => {
    handleSetGuideline(activeKey);
    setActiveScreen(activeKey);
  };

  const tabs = [
    {
      screen: KEY_TAB_PANE.DISCOUNT_CAMPAIGN,
      title: <FnbTypography className="fnb-tab__txt-tab" text={pageData.discountTitle} />,
      component: hasPermission(PermissionKeys.VIEW_PROMOTION) && <PromotionManagement />,
    },
    {
      screen: KEY_TAB_PANE.FLASH_SALE_CAMPAIGN,
      title: <FnbTypography className="fnb-tab__txt-tab" text={pageData.flashSaleTitle} />,
      component: hasPermission(PermissionKeys.VIEW_FLASH_SALE) && (
        <FlashSaleManagement isVisible={isModalNotificationVisible} onChangeVisible={setIsModalNotificationVisible} />
      ),
    },
    {
      screen: KEY_TAB_PANE.DISCOUNT_CODE,
      title: <FnbTypography className="fnb-tab__txt-tab" text={pageData.discountCodeTitle} />,
      component: hasPermission(PermissionKeys.VIEW_DISCOUNT_CODE) && <DiscountCodeManagement />,
    },
    {
      title: <FnbTypography className="fnb-tab__txt-tab" text={pageData.buyXGetYTitle} />,
      screen: KEY_TAB_PANE.BUY_X_GET_Y,
      component: hasPermission(PermissionKeys.VIEW_BUY_X_GET_Y) && <ListBuyXGetYPage />,
    },
  ];

  return (
    <AppContextProvider>
      <Space direction="vertical" className="promotion-management">
        <HeaderPromotion guideline={guideline} />
        <FnbTabsV2 defaultScreen={activeScreen} onChangeTab={changeTab} tabs={tabs} paddingContentTab={0} />
      </Space>
    </AppContextProvider>
  );
}
