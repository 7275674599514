import { EllipsisOutlined } from "@ant-design/icons";
import { Card, Col, Modal, Popover, Row, Typography, message } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { ThumbnailCombo } from "components/thumbnail-combo/thumbnail-combo";
import { ComboStatus, ComboType } from "constants/combo.constants";
import { tableSettings } from "constants/default.constants";
import { DeleteOutlined, EditOutlined, MinusCircleOutlineIcon } from "constants/icons.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import theme from "theme";
import {
  Sort,
  formatDate,
  formatNumberDecimalOrInteger,
  getCurrency,
  getThumbnailUrl,
  hasPermission
} from "utils/helpers";
import PopoverFilter from "./PopoverFilter";
import "./list-combo.scss";
import BranchComboDetail from "./table-combo.component";

const KEY_PARAMS = ["branchId", "productIds", "fromDate", "toDate", "typeId", "statusId"];

export default function ListComboComponent(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const { listCombo, totalCombos, comboParams, onChangeParams, comboDataService } = props;
  const [showBranchModel, setShowBranchModel] = useState(false);
  const [selectedComboBranch, setSelectedComboBranch] = useState(null);
  const [totalFilterSelected, setTotalFilterSelected] = useState(0);
  const popoverRef = useRef();
  const timeOutSearch = useRef(null);
  const timeOutFilter = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isShowStopDialog, setIsShowStopDialog] = useState(false);
  const [stopData, setStopData] = useState();

  const pageData = {
    no: t("combo.no"),
    name: t("combo.generalInformation.name"),
    product: t("combo.product.title"),
    includedProducts: t("combo.generalInformation.includedProducts"),
    branchDetail: t("combo.generalInformation.branch"),
    time: t("combo.generalInformation.validTime"),
    status: t("promotion.table.status"),
    start: t("combo.generalInformation.start"),
    end: t("combo.generalInformation.end"),
    price: t("combo.price.title"),
    action: t("table.action"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    comboDeleteSuccess: t("combo.comboDeleteSuccess"),
    comboDeleteFail: t("combo.comboDeleteFail"),
    table: {
      status: t("promotion.table.status"),
    },
    confirmStop: t("leaveDialog.confirmStop"),
    confirmStopCombo: t("combo.confirmStop"),
    button: {
      btnIgnore: t("button.ignore"),
      btnStop: t("button.stop"),
      editButton: t("button.edit"),
      deleteButton: t("button.delete"),
    },
    stop: t("button.stop"),
    stopComboSuccess: t("combo.stopComboSuccess"),
    stopComboFail: t("combo.stopComboFail"),
    placeholderSearch: t("combo.placeholderSearch"),
    filter: t("combo.filter"),
    scheduledStatus: t("combo.status.scheduled"),
    activeStatus: t("combo.status.active"),
    finishedStatus: t("combo.status.finished"),
    addNew: t("button.addNew"),
    toCreateData: t("area.toCreateData"),
  };

  const onEditCombo = (index, data) => {
    const comboData = data[0];

    history.push(`/combo/edit/${comboData?.id}`);
    setSelectedRowKeys([]);
  };

  const handleDeleteItem = async (id) => {
    setIsShowDeleteDialog(false);
    var res = await comboDataService.deleteComboByIdAsync(id);
    if (res) {
      message.success(pageData.comboDeleteSuccess);
      onChangeParams({ ...comboParams, pageNumber: tableSettings.page });
    } else {
      message.error(pageData.comboDeleteFail);
    }
    setSelectedRowKeys([]);
  };

  const onStopCombo = async (id) => {
    setIsShowStopDialog(false);
    await comboDataService.stopComboByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.stopComboSuccess);
      } else {
        message.error(pageData.stopComboFail);
      }
      onChangeParams({ ...comboParams, pageNumber: tableSettings.page });
    });
    setSelectedRowKeys([]);
  };

  const PopoverContentComponent = (props) => {
    return (
      <div className="popover-container-custom">
        <div className="popover-container-custom-header">
          <span className="popover-container-custom-header-title">{props?.title}</span>
        </div>
        <div className="popover-container-custom-body">{props?.children}</div>
      </div>
    );
  };

  const initDataBranch = async (record) => {
    var res = await comboDataService.getComboByIdAsync(record?.id);
    if (res.isSuccess) {
      setSelectedComboBranch(res.combo.comboStoreBranches);
    }
  };

  const renderBranchModal = () => {
    return (
      <Modal
        width={"800px"}
        className="modal-detail-material-category"
        title={pageData.branchDetail}
        visible={showBranchModel}
        onCancel={() => setShowBranchModel(false)}
        footer={(null, null)}
      >
        <BranchComboDetail t={t} branchDetail={selectedComboBranch} />
      </Modal>
    );
  };

  const renderSpecificCombo = (data) => {
    const specComboProduct = data?.product;
    let comboDetail = "";
    let comboData = [];
    specComboProduct.forEach((item, index) => {
      const priceName = item?.productPrice?.priceName;
      const productName = item?.productPrice?.product?.name;
      if (index > 0) {
        comboDetail = comboDetail + " + " + productName + (priceName ? ` (${priceName})` : "");
      } else {
        comboDetail = productName + (priceName ? ` (${priceName})` : "");
      }
    });
    comboData.push({ title: comboDetail, detail: comboDetail });
    return <MemoizedFnbListChips data={comboData}></MemoizedFnbListChips>;
  };

  const renderFlexibleCombo = (data) => {
    const flexComboProduct = data?.comboPricings;
    let comboDetail = "";
    let comboData = [];
    flexComboProduct.map((comboPrice, index) => {
      const comboPricingProducts = comboPrice.comboPricingProducts;
      comboPricingProducts.forEach((item, index) => {
        const priceName = item?.productPrice?.priceName;
        const productName = item?.productPrice?.product?.name;
        if (index > 0) {
          comboDetail = comboDetail + " + " + productName + (priceName ? ` (${priceName})` : "");
        } else {
          comboDetail = productName + (priceName ? ` (${priceName})` : "");
        }
      });
      comboData.push({ title: comboDetail, detail: comboDetail });
    });

    return <MemoizedFnbListChips data={comboData}></MemoizedFnbListChips>;
  };

  const getMinMaxComboPrice = (data) => {
    const comboPricingData = data?.comboPricings;
    let minPrice = comboPricingData[0]?.sellingPrice;
    let maxPrice = comboPricingData[0]?.sellingPrice;
    for (let i = 1; i < comboPricingData.length; i++) {
      if (comboPricingData[i]?.sellingPrice > maxPrice) {
        maxPrice = comboPricingData[i]?.sellingPrice;
      }
      if (comboPricingData[i]?.sellingPrice < minPrice) {
        minPrice = comboPricingData[i]?.sellingPrice;
      }
    }
    if (minPrice === maxPrice) {
      return <Typography>{formatNumberDecimalOrInteger(maxPrice)}</Typography>;
    } else {
      return (
        <Typography>
          {formatNumberDecimalOrInteger(minPrice)} - {formatNumberDecimalOrInteger(maxPrice)}
        </Typography>
      );
    }
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.no,
        dataIndex: "index",
        width: "5rem",
        align: "left",
        className: "grid-product-no-column",
        render: (value) => value + 1,
      },
      {
        title: pageData.name,
        dataIndex: "name",
        width: "30%",
        ellipsis: true,
        className: "grid-product-combo-name-column",
        sorter: (pre, current) => Sort(pre?.name, current?.name),
        render: (_, record) => {
          return (
            <div className="combo-name">
              <div className="thumbnail-container">
                <ThumbnailCombo width={32} height={32} src={getThumbnailUrl(record?.thumbnail, "mobile")} />
              </div>
              <FnbTooltip maxWidthContent={"90%"} onlyShowWhenEllipsis={true} title={record?.name}>
                <FnbTypography.Link to={`/combo/detail/${record?.id}`} text={record?.name} />
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.includedProducts,
        dataIndex: "product",
        ellipsis: "true",
        width: "34%",
        align: "left",
        className: "grid-product-product-name-column",
        render: (_, record) => {
          if (record?.comboTypeId === ComboType.Specific) {
            return <>{renderSpecificCombo(record)}</>;
          } else {
            return <>{renderFlexibleCombo(record)}</>;
          }
        },
      },
      {
        title: `${pageData.price} (${getCurrency()})`,
        dataIndex: "price",
        align: "right",
        width: "18%",
        className: "grid-product-price-column",
        sorter: (pre, current) => parseInt(pre?.price) - parseInt(current?.price),
        render: (_, record) => {
          if (record?.comboPriceTypeId === ComboType.Fixed) {
            return <Typography strong>{formatNumberDecimalOrInteger(record.price)}</Typography>;
          } else {
            return <>{getMinMaxComboPrice(record)}</>;
          }
        },
      },
      {
        title: pageData.time,
        dataIndex: "time",
        align: "right",
        className: "grid-time-column",
        width: "18%",
        render: (_, record) => {
          return (
            <div className="date-container">
              <Typography className="start-date">
                <span className="start-text">{pageData.start}: </span>
                {formatDate(record.startDate, DateFormat.DD_MMM_YYYY)}
              </Typography>
              {record.endDate && (
                <Typography className="end-date">
                  <span className="end-text">{pageData.end}: </span>
                  {formatDate(record.endDate, DateFormat.DD_MMM_YYYY)}
                </Typography>
              )}
            </div>
          );
        },
      },
      {
        title: pageData.table.status,
        dataIndex: "status",
        align: "center",
        width: "9rem",
        className: "grid-status-column",
        render: (_, record) => {
          switch (record?.statusId) {
            case ComboStatus.Schedule:
              return (
                <div className="status-container">
                  <FnbBadge variant="warning" text={pageData.scheduledStatus} />
                </div>
              );
            case ComboStatus.Active:
              return (
                <div className="status-container">
                  <FnbBadge variant="success" text={pageData.activeStatus} />
                </div>
              );
            default:
              return (
                <div className="status-container">
                  <FnbBadge variant="error" text={pageData.finishedStatus} />
                </div>
              );
          }
        },
      },
    ];

    return columns;
  };

  const flexiblePopoverColumn = [
    {
      title: pageData.no,
      dataIndex: "index",
      key: "index",
      ellipsis: "true",
      width: "10%",
      render: (_, record) => {
        let comboNameArray = record?.name.split(" | ");
        return {
          children: (
            <div className="popover-flexible-wrapper">
              {comboNameArray.map((item) => (
                <div className="product-price-item combo-price-item">{item}</div>
              ))}
            </div>
          ),
          props: {
            colSpan: 2,
          },
        };
      },
    },
    {
      title: `${pageData.product} & ${pageData.price} (${getCurrency()})`,
      dataIndex: "price",
      key: "price",
      width: "65%",
      align: "left",
      render: (_, record) => {
        const productPrice = record.price;
        return {
          children: productPrice,
          props: {
            className: "popover-product-price",
          },
        };
      },
      colSpan: 2,
    },
  ];

  const specificPopoverColumn = [
    {
      title: pageData.no,
      dataIndex: "index",
      key: "index",
      width: "10%",
    },
    {
      title: pageData.product,
      dataIndex: "name",
      key: "name",
      ellipsis: "true",
      width: "70%",
      render: (_, record) => {
        if (record?.comboTypeId === ComboType.Specific) {
          return <div className="product-price-item">{record?.name}</div>;
        } else {
          let comboNameArray = record?.name.split(" | ");
          return (
            <div className="popover-flexible-wrapper">
              {comboNameArray.map((item) => (
                <div className="product-price-item combo-price-item">{item}</div>
              ))}
            </div>
          );
        }
      },
    },
    {
      title: `${pageData.price} (${getCurrency()})`,
      dataIndex: "price",
      key: "price",
      width: "20%",
      align: "right",
    },
  ];

  const showPopoverProducts = (recordId, data, comboTypeId) => {
    return (
      <Row>
        <Col>
          <Popover
            content={
              <PopoverContentComponent title={`${pageData.product} (${data?.length})`}>
                {renderContentPopver(data, comboTypeId)}
              </PopoverContentComponent>
            }
            trigger="click"
          >
            <button id={`btn-show-more-${recordId}`} className="btn-show-more">
              <EllipsisOutlined />
            </button>
          </Popover>
        </Col>
      </Row>
    );
  };

  const renderContentPopver = (data, comboTypeId) => {
    let dataSource = [];
    if (comboTypeId === ComboType.Specific) {
      data?.map((item, index) => {
        let data = {
          index: index + 1,
          name: item?.productPrice?.product?.name,
          price: formatNumberDecimalOrInteger(item?.priceValue),
          comboTypeId: comboTypeId,
        };
        dataSource.push(data);
      });
      return (
        <FnbTable
          className="popover-table"
          dataSource={dataSource}
          columns={specificPopoverColumn}
          pagination={false}
        />
      );
    } else {
      data?.map((item, index) => {
        let data = {
          index: index + 1,
          name: item?.comboName,
          price: formatNumberDecimalOrInteger(item?.sellingPrice),
          comboTypeId: comboTypeId,
        };
        dataSource.push(data);
      });
      return (
        <FnbTable
          scrollX={800}
          className="popover-table"
          dataSource={dataSource}
          columns={flexiblePopoverColumn}
          pagination={false}
        />
      );
    }
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const handleChangePage = (pageNumber, pageSize) => {
    onChangeParams({ ...comboParams, pageNumber, pageSize });
  };

  const onSearch = (keysearch) => {
    if (timeOutSearch.current) {
      clearTimeout(timeOutSearch.current);
    }
    timeOutSearch.current = setTimeout(() => {
      handleSearch(keysearch);
    }, 300);
  };

  const handleSearch = (keySearch) => {
    onChangeParams({ ...comboParams, pageNumber: tableSettings.page, keySearch });
  };

  const handleChangeParams = (newParams) => {
    const totalSelected = Object.keys(newParams)?.filter(
      (key) =>
        KEY_PARAMS?.includes(key) &&
        (Array.isArray(newParams[key]) ? newParams[key]?.length > 0 : newParams[key] !== ""),
    )?.length;
    setTotalFilterSelected(totalSelected || 0);

    if (timeOutFilter.current) {
      clearTimeout(timeOutFilter.current);
    }
    timeOutFilter.current = setTimeout(() => {
      onChangeParams(newParams);
    }, 200);
  };

  const onClickFilterButton = () => {};

  const onClearFilter = () => {
    popoverRef.current.resetFilter();
  };

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onRowClick = (record) => {
    history.push(`/combo/detail/${record?.id}`);
  };

  return (
    <>
      {renderBranchModal()}
      <Card className="fnb-card-custom w-100">
        <FnbTable
          className="table-striped-rows product-in-combo-table"
          columns={getColumns()}
          pageSize={comboParams?.pageSize}
          dataSource={listCombo}
          currentPageNumber={comboParams?.pageNumber}
          total={totalCombos}
          onChangePage={handleChangePage}
          editPermission={PermissionKeys.EDIT_COMBO}
          deletePermission={PermissionKeys.DELETE_COMBO}
          search={{
            placeholder: pageData.placeholderSearch,
            onChange: onSearch,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            renderActions: (selectedRowKeys, selectedRows) => (
              <>
                {selectedRowKeys?.length <= 1 &&
                  selectedRows[0]?.statusId !== ComboStatus.Active &&
                  selectedRows[0]?.statusId !== ComboStatus.Finish && (
                    <>
                      <FnbTooltip placement="top" title={pageData.button.editButton} zIndex={10}>
                        <FnbButton
                          permission={PermissionKeys.EDIT_COMBO}
                          variant="text"
                          className="row-edit-button"
                          text={<EditOutlined />}
                          onClick={() => onEditCombo(selectedRowKeys, selectedRows)}
                          disabled={
                            selectedRowKeys.length > 1 ||
                            selectedRows[0]?.statusId === ComboStatus.Active ||
                            selectedRows[0]?.statusId === ComboStatus.Finish
                          }
                        />
                      </FnbTooltip>
                      <FnbTooltip placement="top" title={pageData.button.deleteButton} zIndex={10}>
                        <FnbButton
                          permission={PermissionKeys.DELETE_COMBO}
                          variant="text"
                          className="row-delete-button"
                          text={<DeleteOutlined />}
                          onClick={() => {
                            setIsShowDeleteDialog(true);
                            setDeleteData(selectedRows[0]);
                          }}
                          disabled={
                            selectedRowKeys.length > 1 ||
                            selectedRows[0]?.statusId === ComboStatus.Active ||
                            selectedRows[0]?.statusId === ComboStatus.Finish
                          }
                        />
                      </FnbTooltip>
                    </>
                  )}

                {selectedRowKeys.length <= 1 &&
                  selectedRows[0]?.statusId !== ComboStatus.Schedule &&
                  selectedRows[0]?.statusId !== ComboStatus.Finish && (
                    <FnbTooltip placement="top" title={pageData.button.btnStop} zIndex={10}>
                      <FnbButton
                        permission={PermissionKeys.STOP_COMBO}
                        variant="text"
                        className="row-stop-button"
                        iconHeader={<MinusCircleOutlineIcon />}
                        onClick={() => {
                          setIsShowStopDialog(true);
                          setStopData(selectedRows[0]);
                        }}
                        disabled={
                          selectedRowKeys.length > 1 ||
                          selectedRows[0]?.statusId === ComboStatus.Schedule ||
                          selectedRows[0]?.statusId === ComboStatus.Finish
                        }
                      />
                    </FnbTooltip>
                  )}
              </>
            ),
          }}
          filter={{
            buttonTitle: pageData.filter,
            onClickFilterButton: onClickFilterButton,
            onClearFilter: onClearFilter,
            totalFilterSelected: totalFilterSelected,
            component: <PopoverFilter ref={popoverRef} comboParams={comboParams} onChange={handleChangeParams} />,
          }}
          onRowClick={hasPermission(PermissionKeys.VIEW_COMBO) && onRowClick}
          emptyText={
            hasPermission(PermissionKeys.CREATE_COMBO) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={() => history.push("/combo/create-new")}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
        <ConfirmDialogComponent
          visible={isShowDeleteDialog}
          title={pageData.confirmDelete}
          content={formatDeleteMessage(deleteData?.name)}
          okText={pageData.btnDelete}
          cancelText={pageData.btnIgnore}
          onOk={() => handleDeleteItem(deleteData.id)}
          onCancel={() => setIsShowDeleteDialog(false)}
          type={ConfirmStyle.DELETE}
        />
        <ConfirmDialogComponent
          visible={isShowStopDialog}
          title={pageData.confirmStop}
          content={t(pageData.confirmStopCombo, { name: stopData?.name })}
          okText={pageData.button.btnStop}
          cancelText={pageData.button.btnIgnore}
          onOk={() => onStopCombo(stopData?.id)}
          tooltipTitle={pageData.stop}
          onCancel={() => setIsShowStopDialog(false)}
          type={ConfirmStyle.DELETE}
        />
      </Card>
    </>
  );
}
