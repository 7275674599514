import { Col, Form, Radio, Row } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { DepositStatus } from "constants/deposit-status.contants";
import { enumReservation } from "constants/reservation-status.constrants";
import { useTranslation } from "react-i18next";
import { formatCurrencyWithoutSuffix, getCurrency } from "utils/helpers";
import { BadgeDepositStatus } from "../../view/components/deposit/BadgeDepositStatus";

export default function DepositStatusComponent(props) {
  const [t] = useTranslation();
  const {
    depositStatusId,
    setDepositStatusId,
    data,
    onChangeForm,
    activeAllPaymentMethods
  } = props;
  const pageData = {
    depositStatus: t("reservation.depositStatus"),
    depositStatusLabel: {
      notReceived: t("reservation.notReceived"),
      received: t("reservation.received"),
      waitToRefund: t("reservation.waitToRefund"),
      refunded: t("reservation.refunded"),
      noRefund: t("reservation.noRefund"),
    },
    depositBy: t("reservation.depositBy"),
    pleaseTurnOnDeposit: t("reservation.pleaseTurnOnDeposit"),
    pleaseSelectAtLeastOneValue: t("reservation.pleaseSelectAtLeastOneValue")
  };

  return (
    <Col lg={12} span={24}>
      {data?.isShowDeposit ? (
        <Row gutter={[28, 28]}>
          <Col lg={12} span={24}>
            <Form.Item
              className="radio-deposit-status"
              name={"depositStatusId"}
              initialValue={null}
              label={pageData.depositStatus}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.pleaseSelectAtLeastOneValue} />
                  ),
                },
              ]}
            >
              {data?.depositStatusId === DepositStatus.NoRefund ? <BadgeDepositStatus status={data?.depositStatusId} /> :
                <Radio.Group
                  onChange={(e) => setDepositStatusId(e.target.value)}
                >
                  {data?.status === enumReservation.Cancelled ? (
                    <>
                      <Radio value={DepositStatus.WaitToRefund} className={`${depositStatusId === DepositStatus.WaitToRefund && 'ant-radio-checked-wait-to-refund'}`}>
                        <span className="label-radio-status-deposit">{pageData.depositStatusLabel.waitToRefund}</span>
                      </Radio>
                      <Radio value={DepositStatus.Refunded} className={`${depositStatusId === DepositStatus.Refunded && 'ant-radio-checked-refunded'}`}>
                        <span className="label-radio-status-deposit">{pageData.depositStatusLabel.refunded}</span>
                      </Radio>
                    </>
                  ) : (
                    <>
                      <Radio value={DepositStatus.NotReceived} className={`${depositStatusId === DepositStatus.NotReceived && 'ant-radio-checked-not-receive'}`}>
                        <span className="label-radio-status-deposit">{pageData.depositStatusLabel.notReceived}</span>
                      </Radio>
                      <Radio value={DepositStatus.Received} className={`${depositStatusId === DepositStatus.Received && 'ant-radio-checked-receive'}`}>
                        <span className="label-radio-status-deposit">{pageData.depositStatusLabel.received}</span>
                      </Radio>
                    </>
                  )}
                </Radio.Group>
              }
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Form.Item
              label={
                <>
                  <span style={{ color: '#ff8c24' }}>{formatCurrencyWithoutSuffix(data?.depositAmount)} &nbsp;</span>
                  <span>{getCurrency()} {pageData.depositBy}</span>
                </>
              }
              name={data?.status === enumReservation.Cancelled ? "refundPaymentMethodId" : "paymentMethodId"}
              className="last-item"
              rules={[
                {
                  required: depositStatusId === DepositStatus.Received,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={"Translate"}
                    />
                  ),
                },
              ]}
            >
              <FnbSelectSingle
                className="w-100"
                option={activeAllPaymentMethods?.map((item, index) => ({
                  id: item.enumId,
                  name: item.name,
                  key: index,
                }))}
                disabled={!(depositStatusId === DepositStatus.Received || depositStatusId === DepositStatus.Refunded)}
                onChange={onChangeForm}
              ></FnbSelectSingle>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={[28, 28]}>
          <Col lg={24} span={24}>
            <div className="label-warning-turn-off-deposit">{pageData.depositStatus}</div>
            <div className="content-warning-turn-off-deposit">
              <span className="label-content-warning"
                dangerouslySetInnerHTML={{
                  __html: `${pageData.pleaseTurnOnDeposit}`,
                }}
              ></span>
            </div>
          </Col>
        </Row>
      )}
    </Col>
  );
}
