import { Col, Empty, Row, Select } from "antd";
import { ArrowDown, BoldAddCircleIcon, CheckedIcon, SearchIcon } from "constants/icons.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./select-edit-new-item.scss";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";

const { Option } = Select;

export default function SelectEditComponent(props) {
  const {
    placeholder,
    listOption,
    onChangeOption,
    addNewItem,
    isNameExisted,
    name,
    isEditProduct,
    className,
    allowClear,
    showSearch,
    onSearch,
    onSelect,
    fixed,
    value,
    category, //Localization category ex: category, unit, area...
    isShowSearchIcon = false,
    id,
    minWidth = "fit-content",
    form,
    isLowerCase = true,
  } = props;

  const [t] = useTranslation();
  const [setValue] = useState(null);

  const pageData = {
    add: t("button.add"),
    thisCategoryNotFound: t("form.thisCategoryNotFound", { category: (category || t("form.category"))?.toLowerCase() }),
    addNewAsNewCategory: t("form.addNewAsNewCategory"),
  };

  useEffect(() => {
    if (isEditProduct) {
      props.functions.current = setValue;
    }
  }, []);

  const orderListByKey = (data, key, order) => {
    if (data) {
      const compareValues =
        (key, order = "asc") =>
        (elemA, elemB) => {
          if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key)) return 0;
          const comparison = elemA[key].localeCompare(elemB[key]);
          return order === "desc" ? comparison * -1 : comparison;
        };
      return data?.sort(compareValues(key, order));
    }
    return data;
  };

  const renderSelect = () => {
    return (
      <Select
        style={{ minWidth: minWidth }}
        id={id}
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        value={value}
        autoBlur={false}
        showSearch={showSearch}
        allowClear={allowClear}
        suffixIcon={<ArrowDown />}
        menuItemSelectedIcon={<CheckedIcon />}
        className={`w-100 fnb-select-single-admin ${className} ${isShowSearchIcon ? "show-search-icon" : ""}`}
        onChange={onChangeOption}
        onSearch={onSearch}
        onSelect={onSelect}
        placeholder={placeholder}
        popupClassName="fnb-select-single-dropdown-admin"
        dropdownClassName="fnb-select-single-dropdown-admin"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().removeVietnamese().indexOf(input.toLowerCase().removeVietnamese()) >= 0
        }
        dropdownRender={(menu) => (
          <>
            <Row align="middle" justify="center" className="dropdown-option-add">
              {name && !isNameExisted ? (
                <>
                  <Col span={24}>
                    <div className="empty-text">{pageData.thisCategoryNotFound}</div>
                    <Row align="middle" justify="center">
                      <div className="add-new-select w-100" onClick={addNewItem}>
                        <BoldAddCircleIcon className="icon-btn-add-new-select" />
                        <div className="keyword-add">
                          {pageData.addNewAsNewCategory
                            .replace("{{name}}", isLowerCase ? name?.toLowerCase() : name)
                            .replace("{{category}}", isLowerCase ? (category || t("form.category"))?.toLowerCase() : (category || t("form.category")))}
                        </div>
                      </div>
                    </Row>
                  </Col>
                </>
              ) : (
                (!listOption || listOption?.length === 0) && <FnbEmpty />
              )}

              <Col span={24}>{menu}</Col>
            </Row>
          </>
        )}
      >
        {orderListByKey(listOption, "name")?.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    );
  };

  return (
    <>
      {form ? (
        <FnbFormItem name={form?.name} rules={form?.rules}>
          {renderSelect()}
        </FnbFormItem>
      ) : (
        renderSelect()
      )}
      {isShowSearchIcon && <SearchIcon className={`fnb-select-search__icon-prefix`} />}
    </>
  );
}
