import HowToUsePos from "./how-to-use-pos/how-to-use-pos.page";

const route = {
  key: "app.how-to-use-pos",
  position: 0,
  path: "/how-to-use-pos",
  name: "HowToUsePos",
  isMenu: false,
  exact: true,
  auth: true,
  permission: "public",
  component: HowToUsePos,
  child: [],
};

export default route;
