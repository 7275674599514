import { Select } from "antd";
import { ArrowDown, CheckedIcon } from "constants/icons.constants";
import { useTranslation } from "react-i18next";

import "./fnb-select-customer.scss";
import { useEffect, useState } from "react";

/**
 * SelectSingle component custom from antd select
 * @param {option, value, onChange, className, disabled, allowClear, showSearch, placeholder, dropdownRender, style, defaultValue } props
 * option: data select option [], map data [{id: "key", name: "value"}] first
 * other param are used as same as antd select, visit link https://ant.design/components/select/
 * @returns
 */

export function FnbSelectCustomer({
  value,
  onChange,
  className,
  option,
  disabled,
  allowClear,
  showSearch = true,
  placeholder,
  dropdownRender,
  style,
  defaultValue,
  onSelect,
  fixed,
  controlId,
  noTranslateOptionName,
  onFocus,
  onClear,
  suffixIcon,
  customerId,
  pageSize = 20,
  showPhone = false,
}) {
  const [t] = useTranslation();
  const [customerByFilter, setCustomerByFilter] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    if (customerId && option.length > 0) {
      const customer = option?.filter((item) => item.id === customerId);
      let customerOther = option?.filter((item) => item.id !== customerId);
      customerOther = customerOther.slice(0, pageSize - 1);
      if (customer.length > 0) customerOther = [customer[0], ...customerOther];
      setCustomerByFilter(customerOther);
    } else if (option.length > 0) {
      setCustomerByFilter(option);
    }
  }, [customerId, option, pageSize]);

  const onBlur = () => {
    setTextSearch();
  };

  const onSearch = (value) => {
    setPageNumber(1);
    setTextSearch(value);
    if (value && option.length > 0) {
      let customerOther = option?.filter((item) => {
        const inputStr = value.removeVietnamese();
        const name = item?.name.removeVietnamese();
        return name?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
      });
      customerOther = customerOther.slice(0, pageSize);
      setCustomerByFilter(customerOther);
    } else if (customerId && option.length > 0) {
      const customer = option?.filter((item) => item.id === customerId);
      let customerOther = option?.filter((item) => item.id !== customerId);
      customerOther = customerOther.slice(0, pageSize - 1);
      if (customer.length > 0) customerOther = [customer[0], ...customerOther];
      setCustomerByFilter(customerOther);
    }
  };

  const onPopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight + 200 > target.scrollHeight) {
      let currentNumber = pageNumber * pageSize;
      let nextNumber = (pageNumber + 1) * pageSize;
      setPageNumber(pageNumber + 1);
      if (textSearch) {
        let customerOther = option?.filter((item) => {
          const inputStr = textSearch.removeVietnamese();
          const name = item?.name.removeVietnamese();
          return name?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
        });
        if (customerOther.length > currentNumber) {
          customerOther = customerOther.slice(currentNumber, nextNumber);
          customerOther = customerByFilter.concat(customerOther);
          setCustomerByFilter(customerOther);
        }
      } else {
        let customerOther = option?.filter((item) => item.id !== customerId);
        if (customerOther.length > currentNumber) {
          customerOther = customerOther.slice(currentNumber, nextNumber);
          customerOther = customerByFilter.concat(customerOther);
          setCustomerByFilter(customerOther);
        }
      }
    }
  };

  return (
    <>
      <Select
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        style={style}
        className={`fnb-select-single-admin ${className}`}
        popupClassName="fnb-select-single-dropdown-admin"
        suffixIcon={suffixIcon || <ArrowDown />}
        menuItemSelectedIcon={<CheckedIcon />}
        disabled={disabled}
        showSearch={showSearch}
        allowClear={allowClear}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        optionFilterProp="children"
        showArrow
        filterOption={(input, option) => {
          const newOption = t(option?.name);
          const inputStr = input.removeVietnamese();
          const name = newOption?.removeVietnamese();
          return name?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
        }}
        onPopupScroll={onPopupScroll}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
        onClear={onClear}
      >
        {customerByFilter?.map((item) => (
          <Select.Option key={item?.id} value={item?.id} name={item?.name}>
            {showPhone ? (
              item?.membership && item?.discount >= 0 ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${item?.name} <span style='color: #9592a5; font-weight: 400;'>- ${item?.phone}</span> - <span style='color: #FF8C24; font-weight: 400;'>${item?.membership} (${item?.discount}%)</span>`,
                  }}
                ></span>
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${item?.name} <span style='color: #9592a5; font-weight: 400;'>- ${item?.phone}</span>`,
                  }}
                ></span>
              )
            ) : noTranslateOptionName ? (
              item?.name
            ) : (
              t(item?.name)
            )}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
