import { Form } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import "./filter-popover.scss";

const FilterBlogPopover = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { dataFilter, onChangeFilter } = props;
  const { blogCatetories, blogAuthors } = dataFilter;

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedAuthorId, setSelectedAuthorId] = useState("");
  const [filterData, setFilterData] = useState({});
  const defaultValue = "";

  //#region PageData
  const pageData = {
    filter: {
      buttonResetFilter: t("blog.reset_all_filters"),
      all: t("blog.all"),
      category: t("blog.category"),
      creator: t("blog.creator"),
    },
  };
  //#endregion

  const clearFilter = () => {
    setSelectedCategoryId(defaultValue);
    handleChangeFilterData({});
    setSelectedCategoryId("");
    setSelectedAuthorId("");
  };

  const handleChangeFilterData = (data) => {
    setFilterData(data);
    onChangeFilter && onChangeFilter(data);
  };

  useImperativeHandle(ref, () => ({
    resetFilter() {
      clearFilter();
    },
  }));

  return (
    <Form layout="vertical">
      {/* CATEGORY */}
      <Form.Item label={pageData.filter.category}>
        <FnbSelectSingle
          className="form-select "
          showSearch
          onChange={(value) => {
            setSelectedCategoryId(value);
            handleChangeFilterData({ ...filterData, categoryId: value });
          }}
          value={selectedCategoryId}
          defaultValue={defaultValue}
          option={[{ id: "", name: pageData.filter.all }, ...blogCatetories]}
        />
      </Form.Item>
      {/* Author */}
      <Form.Item label={pageData.filter.creator}>
        <FnbSelectSingle
          className="form-select "
          showSearch
          onChange={(value) => {
            setSelectedAuthorId(value);
            handleChangeFilterData({ ...filterData, creatorId: value });
          }}
          value={selectedAuthorId}
          defaultValue={defaultValue}
          option={[{ id: "", name: pageData.filter.all }, ...blogAuthors]}
        />
      </Form.Item>
    </Form>
  );
});

export default FilterBlogPopover;
