import { Image, Select, Typography } from "antd";
import {
  ArrowDownOutlined,
  BranchOutlined,
  CheckedIcon,
  ImageMaterialDefault,
  SearchIcon,
} from "constants/icons.constants";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./fnb-select.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";

export function FnbSelect({
  className = "",
  allowClear,
  value,
  options,
  mode = undefined, // 'multiple' | 'tags'
  defaultValue,
  disabled,
  dropdownRender,
  placeholder,
  style,
  dropdownStyle,
  showSearch = true,
  onChange,
  onSelect,
  onFocus,
  onBlur,
  onSearch,
  searchValue,
  onClear,
  suffixIcon,
  prefixIcon = undefined,
  listHeight,
  multiLine = false,
  showThumbnail,
  fixed,
  noTranslateOptionName = false,
  placement,
  isShowSearchIcon = false,
  isHeaderCheckedIcon = false,
  isShowPrefixDescription = false,
  noTranslateOptionDescription = false,
  defaultOption,
  hiddenDefaultItemOnSearch = false,
  popupClassName = "",
  notFoundContent = undefined,
  isShowPrefixHomeIcon = false,
  variant = "b2-regular",
  removeIcon,
  ...rest
}) {
  const [t] = useTranslation();
  const classNameSelect = classNames({
    "fnb-select-admin": true,
    [`${className}`]: true,
    "fnb-select-admin--show-prefix-icon": prefixIcon !== undefined,
    "fnb-select-admin--header-checked-icon": isHeaderCheckedIcon,
    "fnb-select-admin__default-item--hidden-on-search": hiddenDefaultItemOnSearch,
  });

  const popupClassNameSelect = classNames({
    "fnb-select-dropdown-admin": true,
    [`${popupClassName}`]: true,
  });

  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  const filterOption = (input, option) => {
    const newOption = t(option?.label);
    const inputStr = input.removeVietnamese();
    const label = newOption?.removeVietnamese();
    return label?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
  };

  return (
    <div className="fnb-select">
      <Select
        {...rest}
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        className={`${classNameSelect} ${isShowSearchIcon || isShowPrefixHomeIcon ? "show-search-icon" : ""}`}
        placeholder={placeholder}
        allowClear={allowClear}
        value={value}
        mode={mode}
        defaultValue={defaultValue}
        disabled={disabled}
        dropdownRender={dropdownRender}
        style={style}
        dropdownStyle={dropdownStyle}
        showSearch={showSearch}
        onChange={onChange}
        onSelect={onSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        onClear={onClear}
        onSearch={onSearch}
        searchValue={searchValue}
        filterOption={filterOption}
        suffixIcon={
          suffixIcon || (
            <div className="arrow-down">
              <ArrowDownOutlined />
            </div>
          )
        }
        menuItemSelectedIcon={<CheckedIcon width={"1em"} height={"1em"} />}
        popupClassName={popupClassNameSelect}
        maxTagCount={multiLine ? "responsive" : ""}
        placement={placement}
        showArrow
        optionFilterProp="children"
        listItemHeight={0}
        listHeight={listHeight || 300}
        optionLabelProp={showThumbnail ? "label" : ""}
        notFoundContent={notFoundContent ? notFoundContent : <FnbEmpty />}
        removeIcon={removeIcon}
      >
        {defaultOption && (
          <Select.Option
            key={defaultOption.value}
            id={defaultOption.value}
            name={defaultOption?.label}
            value={defaultOption.value}
            label={defaultOption.label}
          >
            <FnbTooltip
              widthLabel={"fit-content"}
              title={noTranslateOptionName ? defaultOption?.label : t(defaultOption?.label)}
              onlyShowWhenEllipsis
              disabledStopPropagation={isTouchDevice}
              hideTooltip={isTouchDevice}
            >
              <div className="ant-select-item-option-default_content">
                {defaultOption?.prefixIcon}
                <FnbTypography
                  color={theme.colors.primary[100]}
                  text={noTranslateOptionName ? defaultOption?.label : t(defaultOption?.label)}
                />
              </div>
            </FnbTooltip>
          </Select.Option>
        )}
        {showThumbnail
          ? options?.map((item) => (
              <Select.Option
                key={item?.value}
                id={item?.value}
                name={item?.label}
                value={item?.value}
                label={item?.label}
                disabled={item?.disabled}
              >
                <div className="product-option-box">
                  <div className="img-box">
                    {item?.thumbnail ? <Image preview={false} src={item?.thumbnail} /> : <ImageMaterialDefault />}
                  </div>
                  <Typography ellipsis={true} className="product-name">
                    {item?.label}
                    {item?.isTopping && (
                      <div className="fnb-chip fnb-chip__option--default" style={{ marginLeft: 12 }}>
                        <Typography style={{ color: "#ff7d07" }}>Topping</Typography>
                      </div>
                    )}
                  </Typography>
                </div>
              </Select.Option>
            ))
          : options?.map((item) => (
              <Select.Option
                key={item.value}
                id={item.value}
                name={item?.label}
                value={item.value}
                label={item?.label}
                disabled={item?.disabled}
              >
                <FnbTooltip
                  title={noTranslateOptionName ? item?.label : t(item?.label)}
                  maxWidth={isShowPrefixDescription ? "280px" : "440px"}
                  onlyShowWhenEllipsis
                  disabledStopPropagation={isTouchDevice}
                  hideTooltip={isTouchDevice}
                  className="ant-select-item-option__content"
                >
                  <FnbTypography variant={variant} text={noTranslateOptionName ? item?.label : t(item?.label)} />
                </FnbTooltip>
                {isShowPrefixDescription && (
                  <FnbTooltip
                    title={noTranslateOptionDescription ? item?.description : t(item?.description)}
                    maxWidth={"140px"}
                    onlyShowWhenEllipsis
                    disabledStopPropagation={isTouchDevice}
                    hideTooltip={isTouchDevice}
                    className="ant-select-item-option__description"
                  >
                    <FnbTypography
                      variant={variant}
                      color={theme.colors.gray[70]}
                      text={noTranslateOptionDescription ? item?.description : t(item?.description)}
                    />
                  </FnbTooltip>
                )}
              </Select.Option>
            ))}
      </Select>
      {isShowPrefixHomeIcon && <BranchOutlined className="fnb-select-branch__prefix-icon" />}
      {isShowSearchIcon && <SearchIcon className={`fnb-select-search__icon-prefix`} />}
    </div>
  );
}
