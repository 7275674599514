import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const target = {
  blank: "blank",
  self: "self",
  parent: "parent",
  top: "top",
};

const GetTarget = (value) => {
  const targetObj = {
    blank: "_blank",
    self: "_self",
    parent: "_parent",
    top: "_top",
  };
  
  return targetObj[value] ?? "NOT FOUND";
};

export const useRedirect = () => {
  const history = useHistory();

  const handleRedirect = (route, target = "") => {
    if (target) {
      window.open(route, GetTarget(target));
      return;
    }

    history.push(route);
    window.scroll(0, 0);
  };

  return {
    handleRedirect,
    target,
  };
};
