import { Col, Form, Input, message, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { CustomTrashIcon, ImageMaterialDefault, SearchIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import branchDataService from "data-services/branch/branch-data.service";
import ingredientRemoveRequestDataService from "data-services/material-control/ingredient-remove-request-data.service";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatNumberDecimalOrInteger, isDecimalKey, parserDecimalNumber, roundNumber } from "utils/helpers";
import i18n from "utils/i18n";
import { IngredientSearchModal } from "../components/ingredient-search-modal";
import { KEY_TAB_PANE } from "../table-inventory-control.component";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { MinimumNumber } from "constants/default.constants";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbCancelButton } from "components/cancel-button";
import "./new-ingredient-remove-request.scss";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";

const { TextArea } = Input;

export default function AddNewIngredientRemoveRequest(props) {
  const { t } = useTranslation();
  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    createTransferMaterialSuccess: t("messages.createTransferMaterialSuccess"),
    updateTransferMaterialSuccess: t("messages.updateTransferMaterialSuccess"),
    createRemoveRequest: t("materialControl.createRemoveRequest.requestUpdate"),
    searchByNameSku: t("materialControl.createRemoveRequest.searchByNameSku"),
    branchOrWarehouse: t("materialControl.createRemoveRequest.branchOrWarehouse"),
    placeholderBranch: t("materialControl.createRemoveRequest.placeholderBranch"),
    reasonForRequest: t("materialControl.createRemoveRequest.reasonForRequest"),
    ingredientInformation: t("materialControl.createRemoveRequest.ingredientInfo"),
    selectApplicableReason: t("materialControl.createRemoveRequest.selectApplicableReason"),
    pleaseSelectReason: t("materialControl.createRemoveRequest.pleaseSelectReason"),
    placeholderDescription: t("materialControl.createRemoveRequest.placeholderDescription"),
    description: t("materialControl.createRemoveRequest.description"),
    selectBranch: t("materialControl.createRemoveRequest.selectBranch"),
    pleaseSelectBranch: t("materialControl.createRemoveRequest.pleaseSelectBranch"),
    supplier: {
      title: t("supplier.title"),
      selectSupplier: t("supplier.selectSupplier"),
      pleaseSelectSupplier: t("supplier.pleaseSelectSupplier"),
    },
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      totalQuantity: t("materialControl.createRemoveRequest.totalQuantity"),
      ingredients: t("materialControl.createRemoveRequest.ingredients"),
      unavailable: t("materialControl.createRemoveRequest.unavailable"),
      reason: t("materialControl.createRemoveRequest.reason"),
      available: t("materialControl.createRemoveRequest.available"),
    },
    btnCreate: t("button.create"),
    btnConfirmLeave: t("button.confirmLeave"),
    leaveWarningMessage: t("messages.leaveForm"),
    messages: {
      createdSuccess: t("combo.messages.createdSuccess"),
    },
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    generalInformation: t("purchaseOrder.generalInformation"),
    createdSuccessfully: t("materialControl.createRemoveRequest.createdSuccessfully"),
    errQuantity: t("materialControl.createRemoveRequest.errQuantity"),
    errIngredient: t("materialControl.createRemoveRequest.errIngredient"),
    hoverTotal: t("materialControl.createRemoveRequest.hoverTotal"),
    hoverUnavailable: t("materialControl.createRemoveRequest.hoverUnavailable"),
    hoverAvailable: t("materialControl.createRemoveRequest.hoverAvailable"),
    createRequest: t("materialControl.createRemoveRequest.createRequest"),
  };

  const optionsReasonForRequest = [
    {
      id: 0,
      name: t("materialControl.createRemoveRequest.outOfDate"),
    },
    {
      id: 1,
      name: t("materialControl.createRemoveRequest.damaged"),
    },
    {
      id: 2,
      name: t("materialControl.createRemoveRequest.others"),
    },
  ];

  const [form] = useForm();
  const history = useHistory();
  const [visibleModalLeaveConfirm, setVisibleModelLeaveConfirm] = useState(false);
  const [branches, setBranches] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listSelectedMaterials, setListSelectedMaterials] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();

  const getBranches = async () => {
    const branchesResponse = await branchDataService.getAllBranchsAsync();
    if (branchesResponse) {
      setBranches(branchesResponse.branchs);
      updateLastTimeGetBranches();
    }
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  const leaveOnCancel = () => {
    setVisibleModelLeaveConfirm(false);
  };

  const leaveOnOk = () => {};

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const handleChangeUnavailable = (value, index, max) => {
    listSelectedMaterials[index].unavailableAmount = (value > max ? max : value) ?? "";
    setListSelectedMaterials([...listSelectedMaterials]);

    let formValue = form.getFieldValue();
    let selectedMaterials = [];
    listSelectedMaterials?.forEach((material) => {
      selectedMaterials.push({ unavailable: material?.unavailableAmount });
    });
    formValue.removeRequest.selectedMaterials = selectedMaterials;
    form.validateFields([["removeRequest", "selectedMaterials", index, "unavailable"]]);
  };

  const handleChangeBranch = (value) => {
    form.setFieldValue("branchId", value);
    setListSelectedMaterials([]);
  };

  const handleChangeUnit = (unitId, material, listUnit, index) => {
    if (unitId === material?.unitId) {
      listSelectedMaterials[index].currentUnitName = null;
      listSelectedMaterials[index].currentUnitId = unitId;
      listSelectedMaterials[index].quantity = material?.inventoryQuantity ?? 0;
    } else {
      let unitConversion = listUnit?.find((u) => u?.id === unitId);
      listSelectedMaterials[index].currentUnitName = unitConversion?.name;
      listSelectedMaterials[index].currentUnitId = unitId;
      if (unitConversion?.capacity && unitConversion?.capacity !== 0) {
        listSelectedMaterials[index].quantity = (material?.inventoryQuantity ?? 0) / unitConversion?.capacity;
      } else {
        listSelectedMaterials[index].quantity = 0;
      }
    }
    setListSelectedMaterials([...listSelectedMaterials]);
  };

  const onRemoveMaterial = (material) => {
    let newListSelected = listSelectedMaterials.filter((x) => x.materialId !== material.materialId);
    setListSelectedMaterials([...newListSelected]);
  };

  const handleChangeReasonType = (option) => {
    form.setFieldValue("reasonType", option);
  };

  const handleChangeReason = (e, index) => {
    listSelectedMaterials[index].reason = e.target.value;
    setListSelectedMaterials([...listSelectedMaterials]);
  };

  const onCancel = () => {
    navigateToManagementPage();
  };

  const changeForm = (e) => {
    if (e?.length > 0) {
      setIsChangeForm(true);
      setIsDisabled(false);
    }
  };

  const navigateToManagementPage = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push({
        pathname: "/inventory/transfer-control",
        search: `?tabActive=${KEY_TAB_PANE.VIEW_BY_INVENTORY_CONTROL}`,
      });
    }, 100);
  };

  const onFinish = async () => {
    form.validateFields().then(async () => {
      if (isSubmit) return;
      setIsSubmit(true);
      setIsChangeForm(true);
      setIsDisabled(true);
      if (listSelectedMaterials?.length === 0) return;
      let listIngredients = listSelectedMaterials.map((material) => {
        return {
          materialId: material?.materialId,
          unitId: material?.currentUnitId ?? material?.unitId,
          unavailableQuantity: roundNumber(material?.unavailableAmount, 2),
          availableQuantity: roundNumber(material?.quantity - material?.unavailableAmount, 2),
          reason: material?.reason,
        };
      });
      let request = {
        branchId: form.getFieldValue("branchId"),
        reasonType: +form.getFieldValue("reasonType"),
        description: form.getFieldValue("description"),
        ingredients: listIngredients,
      };
      await ingredientRemoveRequestDataService
        .createIngredientRemoveRequest(request)
        .then((response) => {
          if (response?.isSuccess) {
            message.success(pageData.createdSuccessfully);
            navigateToManagementPage();
          }
        })
        .catch((ex) => {
          setIsChangeForm(false);
          setIsSubmit(false);
        });
    });
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "center",
        width: "7%",
        ellipsis: true,
        render: (_, record, index) => <Typography style={{ textAlign: "center" }}>{index + 1}</Typography>,
      },
      {
        title: pageData.table.ingredients,
        dataIndex: "ingredients",
        width: "21%",
        align: "left",
        editable: true,
        render: (_, record, index) => {
          return (
            <Col style={{ flex: 1 }}>
              <Row style={{ gap: 12 }}>
                {record?.thumbnail ? (
                  <Thumbnail width={52} height={52} src={record?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={52} height={52} />
                )}
                <Col style={{ alignSelf: "center", flex: 1, gap: 6, maxWidth: "50%" }}>
                  <FnbTooltip title={record?.name} onlyShowWhenEllipsis></FnbTooltip>
                  {record?.sku && (
                    <Row>
                      <Typography style={{ fontWeight: 400, fontSize: 14, color: "#8B8899" }}>
                        SKU: {record?.sku}
                      </Typography>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          );
        },
      },
      {
        title: pageData.table.totalQuantity,
        dataIndex: "totalQuantity",
        align: "left",
        width: "13%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col>{`${formatNumberDecimalOrInteger(record?.quantity)} ${
                record?.currentUnitName ? record?.currentUnitName : record?.unitName
              }`}</Col>
            </Row>
          );
        },
        tooltip: true,
        contentTooltip: parse(pageData.hoverTotal),
      },
      {
        title: pageData.table.unavailable,
        dataIndex: "unavailable",
        align: "left",
        width: "20%",
        render: (_, record, index) => {
          let mapUnitConversions = record?.unitConversion?.map((unit) => {
            return {
              id: unit.unitId,
              name: unit.unitName,
              capacity: unit.capacity,
            };
          });

          let unitConversion = [{ id: record?.unitId, name: record?.unitName }, ...mapUnitConversions];

          return (
            <div className="error-select-quantity">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage id={"please-select-input"} message={pageData.errQuantity} />,
                  },
                ]}
                name={["removeRequest", "selectedMaterials", index, "unavailable"]}
              >
                <Row>
                  <Col span={12} className="pr-1">
                    <FnbInputNumber
                      min={MinimumNumber}
                      max={record?.quantity}
                      value={record?.unavailableAmount}
                      onChange={(value) => {
                        handleChangeUnavailable(value, index, record?.quantity);
                      }}
                      showWrap={true}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => parserDecimalNumber(value, true)}
                      onKeyPress={(event) => {
                        if (!isDecimalKey(event)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  <Col span={12} className="pl-1">
                    <FnbSelectSingle
                      value={record?.currentUnitId ?? unitConversion[0].id}
                      option={unitConversion}
                      onChange={(unitId) => {
                        handleChangeUnit(unitId, record, unitConversion, index);
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </div>
          );
        },
        tooltip: true,
        contentTooltip: parse(pageData.hoverUnavailable),
      },
      {
        title: pageData.table.reason,
        dataIndex: "reason",
        align: "left",
        width: "23%",
        render: (_, record, index) => {
          return (
            <FnbInput
              maxLength={1000}
              showCount
              onChange={(value) => {
                handleChangeReason(value, index);
              }}
            />
          );
        },
      },
      {
        title: pageData.table.available,
        dataIndex: "available",
        align: "left",
        width: "13%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col>{`${formatNumberDecimalOrInteger(record?.quantity - (record?.unavailableAmount ?? 0))} ${
                record?.currentUnitName ? record?.currentUnitName : record?.unitName
              }`}</Col>
            </Row>
          );
        },
        tooltip: true,
        contentTooltip: parse(pageData.hoverAvailable),
      },
      {
        title: "",
        dataIndex: "action",
        align: "left",
        width: "5%",
        render: (_, record, index) => (
          <div
            className="icon-delete-price pt-3"
            onClick={() => {
              onRemoveMaterial(record);
            }}
          >
            <CustomTrashIcon color="#50429B" />
          </div>
        ),
      },
    ];
    return columns;
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 22,
        }}
        className="create-ingredient-remove-request-form custom-form"
        layout="vertical"
      >
        <FnbHeadingPage
          title={pageData.createRemoveRequest}
          isCompactButtons={false}
          listButtons={[
            <FnbCancelButton showWarning={isChangeForm} onOk={onCancel} />,
            <FnbAddNewButton
              onClick={(e) => onFinish(e)}
              className="float-right"
              type="primary"
              text={pageData.createRequest}
              hideIcon={true}
              permission={PermissionKeys.CREATE_INGREDIENT_REMOVE_REQUEST}
              disabled={isDisabled}
            />,
          ]}
        />
        <div className="col-input-full-width">
          <Row align="middle">
            <FnbCard className="w-100" title={pageData.generalInformation} underlineTitle>
              <Row gutter={16} align="middle">
                <Col className="gutter-row mb-28" span={24}>
                  <Form.Item
                    name={["removeRequest", "branchId"]}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage id={"please-select-brach"} message={pageData.pleaseSelectBranch} />
                        ),
                      },
                    ]}
                    label={pageData.branchOrWarehouse}
                  > 
                    <FnbSelectAddNewItem
                      isShowSearchIcon
                      mode="single"
                      placeholder={pageData.placeholderBranch}
                      options={branches?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      showSearch
                      allowClear
                      fieldItemName={pageData.branchOrWarehouse.toLowerCase()}
                      onAddNewItem={onAddNewBranch}
                      onChange={handleChangeBranch}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} align="middle">
                <Col className="gutter-row mb-28" span={24}>
                  <Form.Item
                    name={["removeRequest", "reasonType"]}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            id={"please-select-transfer-material-from"}
                            message={pageData.pleaseSelectReason}
                          />
                        ),
                      },
                    ]}
                    label={pageData.reasonForRequest}
                  >
                    <FnbSelectSingle
                      placeholder={pageData.selectApplicableReason}
                      option={optionsReasonForRequest}
                      onChange={(option) => {
                        handleChangeReasonType(option);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col span={24}>
                  <Form.Item name={["removeRequest", "description"]} label={pageData.description}>
                    <TextArea
                      className="fnb-text-area-with-count no-resize combo-description-box mb-28"
                      showCount
                      maxLength={1000}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      placeholder={pageData.placeholderDescription}
                      onChange={(e) => {
                        form.setFieldValue("description", e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FnbCard>
          </Row>
          <Row className="display-contents">
            <FnbCard className="mt-3" title={pageData.ingredientInformation} underlineTitle>
              <Row>
                <Col span={24} className="button-show-search-modal">
                  <div
                    onClick={() => {
                      if (form.getFieldValue("branchId")) {
                        setIsOpenModal(true);
                      } else {
                        form.validateFields([["removeRequest", "branchId"]]);
                      }
                    }}
                  >
                    <Form.Item
                      className="w-100"
                      name={["removeRequest", listSelectedMaterials]}
                      rules={[
                        {
                          required: listSelectedMaterials?.length <= 0,
                          message: (
                            <InputValidateMessage id={"please-select-materials"} message={pageData.errIngredient} />
                          ),
                        },
                      ]}
                    >
                      <FnbInput
                        id={"btn-search-ingredient"}
                        className="fnb-input"
                        size="large"
                        placeholder={pageData.searchByNameSku}
                        prefix={<SearchIcon />}
                        type={TYPE_INPUT.BUTTON}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                {listSelectedMaterials?.length > 0 && (
                  <Col span={24}>
                    <FnbTable
                      columns={columnsMaterial()}
                      dataSource={listSelectedMaterials}
                      total={listSelectedMaterials?.length}
                      numberRecordCurrent={listSelectedMaterials?.length}
                      showPaging={false}
                      scroll={listSelectedMaterials?.length > 5 ? { x: 1200, y: 86 * 5 } : { x: 1200 }}
                    />
                  </Col>
                )}
              </Row>
            </FnbCard>
          </Row>
        </div>
      </Form>
      <ConfirmDialogComponent visible={visibleModalLeaveConfirm} onCancel={leaveOnCancel} onOk={leaveOnOk} />
      <IngredientSearchModal
        visible={isOpenModal}
        handleCancel={() => setIsOpenModal(false)}
        listSelectedMaterials={listSelectedMaterials}
        setListSelectedMaterials={setListSelectedMaterials}
        branchId={form.getFieldValue("branchId")}
      />
    </>
  );
}
