import { message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { BankTransferLogoIcon, CloseModalIcon, PayPalLogoIcon } from "constants/icons.constants";
import { PackagePaymentStatus } from "constants/order-package-payment-transaction.contants";
import { enumPackagePaymentMethod } from "constants/payment-method.constants";
import { DateFormat } from "constants/string.constants";
import orderPackageService from "data-services/order-package/order-package.service";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addEventClickAndStretchToScrollY, formatDate } from "utils/helpers";
import "./order-package.component.scss";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { AutoRenewStatus, OrderPackageStatus } from "constants/order-package.contants";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { useMediaQuery } from "react-responsive";

function OrderPackageComponent(props) {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [dataList, setDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageNumberOfPaymentHistory, setPageNumberOfPaymentHistory] = useState(1);

  const dataOrderPackageDetailDefault = {
    visible: false,
    data: [],
    totalQuantity: 0,
  };
  const [dataOrderPackageDetail, setDataOrderPackageDetail] = useState(dataOrderPackageDetailDefault);
  const [dataToCancelRenew, setDataToCancelRenew] = useState(null);
  const [t] = useTranslation();
  const orderPackageComponentContentId = "orderPackageComponentHeaderId";
  const orderPackageComponentBodyId = "orderPackageComponentBodyId";

  useEffect(() => {
    getInitData();
    addEventClickAndStretchToScrollY(orderPackageComponentContentId);
  }, []);

  const pageData = {
    title: {
      orderId: t("orderPackage.title.orderId"),
      package: t("orderPackage.title.package"),
      subscriptionDate: t("orderPackage.title.subscriptionDate"),
      duration: t("orderPackage.title.duration"),
      status: t("orderPackage.title.status"),
      action: t("orderPackage.title.action"),
    },
    status: {
      created: t("orderPackage.status.created"),
      actived: t("orderPackage.status.actived"),
      canceled: t("orderPackage.status.canceled"),
      retrying: t("orderPackage.status.retrying"),
      suppended: t("orderPackage.status.suppended"),
      waitingForActivation: t("orderPackage.status.waitingForActivation"),
      waitingForPayment: t("orderPackage.status.waitingForPayment"),
      completed: t("orderPackage.status.completed"),
      failed: t("orderPackage.status.failed"),
    },
    nextBillingCycle: t("orderPackage.nextBillingCycle"),
    unsubscribe: t("orderPackage.unsubscribe"),
    discard: t("messages.leaveDialog.discard"),
    year: t("package.year"),
    years: t("package.years"),
    days: t("orderPackage.days"),
    paymentHistory: t("orderPackage.paymentHistory"),
    transactionID: t("orderPackage.transactionID"),
    subscriptionID: t("orderPackage.subscriptionID"),
    tokenID: t("orderPackage.tokenID"),
    amount: t("orderPackage.amount"),
    paymentMethod: t("orderPackage.paymentMethod"),
    payment: t("orderPackage.payment"),
    description: t("orderPackage.description"),
    paymentStatus: t("orderPackage.paymentStatus"),
    paymentTime: t("orderPackage.paymentTime"),
    confirmStop: t("leaveDialog.confirmStop"),
    button: {
      btnIgnore: t("button.ignore"),
      btnStop: t("button.stop"),
    },
    cancelSubscription: {
      title: t("orderPackage.cancelSubscription.title"),
      content1: t("orderPackage.cancelSubscription.content1"),
      content2: t("orderPackage.cancelSubscription.content2"),
      successMessage: t("orderPackage.cancelSubscription.successMessage"),
      errorMessage: t("orderPackage.cancelSubscription.errorMessage"),
    },
  };

  async function getInitData() {
    setLoading(true);
    const request = {
      page: isMobile ? 0 : 1,
    };
    const orderPackagesResponse = await orderPackageService.getListAsync(request);
    if (orderPackagesResponse?.succeeded) {
      setDataList(orderPackagesResponse?.data?.orderPackages);
      setLoading(false);
    } else {
      message.warning(t(orderPackagesResponse?.messageCode, orderPackagesResponse?.message));
    }
  }

  const loadingFromBottomHeight = 10;
  const pageSize = 20;
  const handleScroll = useCallback(
    async (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (!loading && scrollTop + clientHeight >= scrollHeight - loadingFromBottomHeight) {
        if (!hasMore) {
          return;
        }
        setLoading(true);
        const newPageNumber = pageNumber + 1;
        const request = {
          page: isMobile ? 0 : newPageNumber,
          size: pageSize,
        };
        const orderPackagesResponse = await orderPackageService.getListAsync(request);
        if (orderPackagesResponse?.succeeded) {
          setDataList([...dataList, ...orderPackagesResponse?.data?.orderPackages]);
        } else {
          message.warning(t(orderPackagesResponse?.messageCode, orderPackagesResponse?.message));
        }

        setHasMore(orderPackagesResponse?.data?.orderPackages?.length === pageSize);
        setPageNumber(newPageNumber);
        setLoading(false);
      }
    },
    [dataList, hasMore, loading, pageNumber, t],
  );

  useEffect(() => {
    const element = document.getElementById(orderPackageComponentBodyId);
    if (handleScroll && element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (handleScroll && element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, handleScroll]);

  const onShowViewDetail = async (id) => {
    const request = {
      id: id,
      page: 1,
      size: pageSize,
    };
    const response = await orderPackageService.getPaymentTransactionsAsync(request);
    if (response?.succeeded) {
      const _dataOrderPackageDetail = {
        id: id,
        visible: true,
        data: response?.data?.orderPackagePaymentTransactions ?? [],
        totalQuantity: response?.data?.totalQuantity ?? 0,
      };
      setDataOrderPackageDetail(_dataOrderPackageDetail);
    } else {
      message.warning(t(response?.messageCode, response?.message));
    }
  };

  function onHideViewDetail() {
    setPageNumberOfPaymentHistory(1);
    setDataOrderPackageDetail(dataOrderPackageDetailDefault);
  }

  const onCancelRenew = async (data) => {
    const { id } = data;
    const response = await orderPackageService.cancelRenewAsync(id);
    if (response?.succeeded) {
      message.success(pageData.cancelSubscription.successMessage);
    } else {
      if (response?.messageCode) {
        message.error(t(response?.messageCode, response?.message));
      } else {
        message.error(pageData.cancelSubscription.errorMessage);
      }
    }
    await getInitData();

    setDataToCancelRenew(null);
  };

  const handleCancelRenew = async (item) => {
    setDataToCancelRenew(item);
  };

  function renderPaymentMethodCell(paymentMethod, email = "", isShowDash = false) {
    if (isShowDash) {
      return <>-</>;
    }

    let name = "";
    switch (paymentMethod) {
      case enumPackagePaymentMethod.visa:
        name = t("package.payment.creditCard");
        break;
      case enumPackagePaymentMethod.atm:
        name = t("package.payment.atm");
        break;
      case enumPackagePaymentMethod.bankTransfer:
        name = (
          <div className="payment-method-content">
            <BankTransferLogoIcon />
            {t("package.payment.bankTransfer")}
            {email && (
              <FnbTooltip title={email} onlyShowWhenEllipsis maxWidthContent="95%">
                <span>{email}</span>
              </FnbTooltip>
            )}
          </div>
        );
        break;
      case enumPackagePaymentMethod.paypal:
        name = (
          <div className="payment-method-content">
            <PayPalLogoIcon />
            {t("package.payment.paypal")}
            {email && (
              <FnbTooltip title={email} onlyShowWhenEllipsis maxWidthContent="95%">
                <span>{email}</span>
              </FnbTooltip>
            )}
          </div>
        );
        break;
      default:
        break;
    }

    return name;
  }

  function getAutoRenewStatusTag(value) {
    let result = "";
    switch (value) {
      case AutoRenewStatus.CREATED:
        result = <FnbBadge variant="warning" text={pageData.status.created} />;
        break;
      case AutoRenewStatus.ACTIVED:
        result = <FnbBadge variant="success" text={pageData.status.actived} />;
        break;
      case AutoRenewStatus.CANCELED:
        result = <FnbBadge variant="error" text={pageData.status.canceled} />;
        break;
      case AutoRenewStatus.RETRYING:
        result = <FnbBadge variant="primary" text={pageData.status.retrying} />;
        break;
      case AutoRenewStatus.SUPPENDED:
        result = <FnbBadge variant="error" text={pageData.status.suppended} />;
        break;
      default:
        break;
    }

    return result;
  }

  function getStatusTag(value) {
    let result = "";
    switch (value) {
      case OrderPackageStatus.PENDING:
        result = <FnbBadge variant="primary" text={pageData.status.waitingForActivation} />;
        break;
      case OrderPackageStatus.APPROVED:
        result = <FnbBadge variant="success" text={pageData.status.completed} />;
        break;
      case OrderPackageStatus.CANCELED:
        result = <FnbBadge variant="error" text={pageData.status.canceled} />;
        break;
      default:
        break;
    }

    return result;
  }

  function getPaymentStatusTag(value) {
    let result = "";
    switch (value) {
      case PackagePaymentStatus.WAITING_FOR_PAYMENT:
        result = <FnbBadge className="payment-status" variant="primary" text={pageData.status.waitingForPayment} />;
        break;
      case PackagePaymentStatus.SUCCESSFUL:
        result = <FnbBadge className="payment-status" variant="success" text={pageData.status.completed} />;
        break;
      case PackagePaymentStatus.FAILED:
        result = <FnbBadge className="payment-status" variant="error" text={pageData.status.failed} />;
        break;
      default:
        break;
    }

    return result;
  }

  function renderStatus(item) {
    if (item?.paymentMethod?.enum === enumPackagePaymentMethod.paypal) {
      return getAutoRenewStatusTag(item?.autoRenewStatus?.code);
    }

    return getStatusTag(item?.status);
  }

  function renderAmount(amount, currencyCode) {
    return <span>{`${amount} ${currencyCode}`}</span>;
  }

  function renderSubContent(item) {
    if (item?.autoRenewStatus?.code === AutoRenewStatus.CANCELED) {
      return (
        <div className="sub-content">
          <div className="subscription-info">{item?.note}</div>
        </div>
      );
    } else if (item?.nextBillingCycle) {
      // In BE checked AutoRenewStatus.Code == AutoRenewStatusConstants.ACTIVED

      return (
        <div className="sub-content">
          <div className="subscription-info">
            {`${pageData.nextBillingCycle}: ${formatDate(item?.nextBillingCycle, DateFormat.DD_MM_YYYY)}`}
            <span>-</span>
            <span
              className="unsubscribe"
              onClick={() => handleCancelRenew(item)}
            >{` ${pageData.unsubscribe.toLocaleUpperCase()}`}</span>
          </div>
        </div>
      );
    }
  }

  const handleChangePage = async (pageNumber, pageSize) => {
    if (dataOrderPackageDetail?.id) {
      setPageNumberOfPaymentHistory(pageNumber);
      const request = {
        id: dataOrderPackageDetail?.id,
        page: pageNumber,
        size: pageSize,
      };
      const response = await orderPackageService.getPaymentTransactionsAsync(request);
      if (response?.succeeded) {
        const _dataOrderPackageDetail = {
          ...dataOrderPackageDetail,
          data: response?.data?.orderPackagePaymentTransactions ?? [],
          totalQuantity: response?.data?.totalQuantity ?? 0,
        };
        setDataOrderPackageDetail(_dataOrderPackageDetail);
      } else {
        message.warning(t(response?.messageCode, response?.message));
      }
    }
  };

  const columns = [
    {
      title: pageData.transactionID,
      width: 200,
      dataIndex: "tokenId",
      key: "tokenId",
      render: (value) => (
        <FnbTooltip title={value} onlyShowWhenEllipsis maxWidthContent="95%">
          <span>{value}</span>
        </FnbTooltip>
      ),
    },
    {
      title: pageData.subscriptionID,
      width: 200,
      ellipsis: true,
      dataIndex: "paymentId",
      key: "paymentId",
      render: (value) => (
        <FnbTooltip title={value} onlyShowWhenEllipsis maxWidthContent="95%">
          <span>{value}</span>
        </FnbTooltip>
      ),
    },
    {
      title: pageData.amount,
      width: 100,
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (value, record) => renderAmount(value, record?.currencyCode),
    },
    {
      title: pageData.paymentMethod,
      width: 200,
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      className: "payment-method-cell",
      render: (value) => renderPaymentMethodCell(value),
    },
    {
      title: pageData.paymentTime,
      width: 220,
      dataIndex: "createdTime",
      key: "createdTime",
      render: (value) => formatDate(value, DateFormat.HH_MM_DD_MMMM_YYYY_),
    },
    {
      title: pageData.description,
      width: 200,
      dataIndex: "description",
      key: "description",
      render: (value) => (
        <FnbTooltip title={value} onlyShowWhenEllipsis maxWidthContent="95%">
          <span>{value ?? "-"}</span>
        </FnbTooltip>
      ),
    },
    {
      title: pageData.paymentStatus,
      width: 200,
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value) => getPaymentStatusTag(value),
    },
  ];

  function renderDuration(item) {
    if (item?.isTrial) {
      return <>{`7 ${pageData.days}`}</>;
    } else {
      if (item?.durationByYear > 1) {
        return <>{`${item?.durationByYear} ${pageData.years}`}</>;
      } else {
        return <>{`${item?.durationByYear} ${pageData.year}`}</>;
      }
    }
  }

  return (
    <div className="order-package-component">
      <div className="order-package-component-content" id={orderPackageComponentContentId}>
        <div className="order-package-component-header">
          <div className="col-custom">{pageData.title.orderId}</div>
          <div className="col-custom">{pageData.title.package}</div>
          <div className="col-custom align-center">{pageData.title.subscriptionDate}</div>
          <div className="col-custom align-center">{pageData.title.duration}</div>
          <div className="col-custom col-payment-method">{pageData.payment}</div>
          <div className="col-custom align-center">{pageData.title.status}</div>
          <div className="col-custom align-right">{pageData.title.action}</div>
        </div>
        <div className="order-package-component-body" id={orderPackageComponentBodyId}>
          {dataList?.map((item) => (
            <div className="order-package-item" key={`order-package-item_${item?.code}`}>
              <div className="content">
                <div className="col-custom">{`#${item?.code}`}</div>
                <div className="col-custom">{item?.packageName}</div>
                <div className="col-custom align-center">{formatDate(item?.startDate, DateFormat.DD_MM_YYYY)}</div>
                <div className="col-custom align-center">{renderDuration(item)}</div>
                <div className="col-custom col-payment-method">
                  {renderPaymentMethodCell(item?.paymentMethod?.enum, item?.subscriberEmail, item?.isTrial)}
                </div>
                <div className="col-custom align-center col-status">{renderStatus(item)}</div>
                <div className="col-custom align-right">
                  {item?.paymentMethod?.enum !== enumPackagePaymentMethod.bankTransfer && !item?.isTrial && (
                    <span className="action-link" onClick={() => onShowViewDetail(item?.id)}>
                      {pageData.paymentHistory}
                    </span>
                  )}
                </div>
              </div>
              {renderSubContent(item)}
            </div>
          ))}
        </div>
      </div>
      <FnbModal
        className="transaction-history"
        centered={false}
        closeIcon={<CloseModalIcon />}
        title={pageData.paymentHistory}
        visible={dataOrderPackageDetail?.visible}
        handleCancel={onHideViewDetail}
        closable={true}
        content={
          <FnbTable
            className="transaction-history-table"
            width="auto"
            scrollY={"50vh"}
            columns={columns}
            onChangePage={handleChangePage}
            total={dataOrderPackageDetail?.totalQuantity}
            pageSize={pageSize}
            currentPageNumber={pageNumberOfPaymentHistory}
            dataSource={dataOrderPackageDetail?.data ?? []}
          />
        }
        hideFooter={true}
      />

      <DeleteConfirmComponent
        title={pageData.cancelSubscription.title}
        contentElement={
          <div className="cancel-content">
            <div>{pageData.cancelSubscription.content1}</div>
            <div>{pageData.cancelSubscription.content2}</div>
          </div>
        }
        visible={dataToCancelRenew}
        skipPermission={true}
        cancelText={pageData.cancelSubscription.discardButton}
        okText={pageData.unsubscribe}
        onCancel={() => setDataToCancelRenew(null)}
        onOk={() => onCancelRenew(dataToCancelRenew)}
      />
    </div>
  );
}

export default OrderPackageComponent;
