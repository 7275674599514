import { StarFilled } from "@ant-design/icons";
import { Form } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { TickCircleIcon } from "constants/icons.constants";
import { REVIEW_STATUS } from "constants/review-status.constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import "./filter-popover.component.scss";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isCheckAllScore, setIsCheckAllScore] = useState(true);
  const [isCheckOneScore, setIsCheckOneScore] = useState(false);
  const [isCheckTwoScore, setIsCheckTwoScore] = useState(false);
  const [isCheckThreeScore, setIsCheckThreeScore] = useState(false);
  const [isCheckFourScore, setIsCheckFourScore] = useState(false);
  const [isCheckFiveScore, setIsCheckFiveScore] = useState(false);

  const { setStatusReview, setRatings, setPageNumber } = props;
  const pageData = {
    filter: {
      byScore: t("review.filter.byScore"),
      hiddenShow: t("review.filter.showHide"),
      all: t("material.filter.status.all"),
    },
  };

  React.useImperativeHandle(ref, () => ({
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    form.resetFields();
    setRatings([]);
    setStatusReview("");
    setPageNumber(1);
  };

  const reviewStatusOptions = [
    { name: t("review.status.all"), id: "" },
    { name: t("review.status.hiddenReview"), id: REVIEW_STATUS.Hidden },
    { name: t("review.status.shownReview"), id: REVIEW_STATUS.Shown },
  ];

  const handleSetRatings = () => {
    let ratings = [];
    const formValues = form.getFieldsValue();
    Object.keys(formValues).forEach((value, index) => {
      if (value.includes("score") && formValues[value] === true) {
        const arrayValue = value.split("-");
        switch (arrayValue[0]) {
          case "one":
            ratings.push(1);
            break;
          case "two":
            ratings.push(2);
            break;
          case "three":
            ratings.push(3);
            break;
          case "four":
            ratings.push(4);
            break;
          case "five":
            ratings.push(5);
            break;
          default:
            break;
        }
      }
    });
    setRatings(ratings);
  };

  const onClickScore = ({ target }) => {
    switch (target.name) {
      case "all-score":
        //Prevent for unchecking All score when 1, 2, 3, 4, 5 scores are unchecked as well.
        if (isCheckAllScore === true) {
          return;
        }

        setIsCheckAllScore(!isCheckAllScore);
        form.setFieldValue(target.name, !isCheckAllScore);
        form.resetFields(["one-score", "two-score", "three-score", "four-score", "five-score"]);
        setIsCheckOneScore(false);
        setIsCheckTwoScore(false);
        setIsCheckThreeScore(false);
        setIsCheckFourScore(false);
        setIsCheckFiveScore(false);
        break;
      case "one-score":
        setIsCheckOneScore(!isCheckOneScore);
        form.setFieldValue(target.name, !isCheckOneScore);
        break;
      case "two-score":
        setIsCheckTwoScore(!isCheckTwoScore);
        form.setFieldValue(target.name, !isCheckTwoScore);
        break;
      case "three-score":
        setIsCheckThreeScore(!isCheckThreeScore);
        form.setFieldValue(target.name, !isCheckThreeScore);
        break;
      case "four-score":
        setIsCheckFourScore(!isCheckFourScore);
        form.setFieldValue(target.name, !isCheckFourScore);
        break;
      case "five-score":
        setIsCheckFiveScore(!isCheckFiveScore);
        form.setFieldValue(target.name, !isCheckFiveScore);
        break;
      default:
        break;
    }
    if (target.name !== "all-score" && form.getFieldValue("all-score") === true) {
      form.setFieldValue("all-score", !isCheckAllScore);
      setIsCheckAllScore(!isCheckAllScore);
    }

    handleSetRatings();
    setPageNumber(1);
  };

  const onChangeStatus = (value) => {
    setStatusReview(value);
  };

  return (
    <Form layout="vertical" className="filter" form={form}>
      <div className="score-label">
        <FnbTypography text={pageData.filter.byScore} variant={theme.typography["b1-medium"]}></FnbTypography>
      </div>
      <div className="score-item">
        <Form.Item name="all-score" initialValue={true}>
          <FnbRadioButton
            name={"all-score"}
            icon={<TickCircleIcon width={16} height={16} className="check-icon" />}
            value={isCheckAllScore}
            isChecked={isCheckAllScore}
            onClick={onClickScore}
          >
            <FnbTypography variant={theme.typography["b1-regular"]} text="All" />
          </FnbRadioButton>
        </Form.Item>
        <Form.Item name="one-score" initialValue={false}>
          <FnbRadioButton
            name={"one-score"}
            icon={<TickCircleIcon width={16} height={16} className="check-icon" />}
            value={isCheckOneScore}
            isChecked={isCheckOneScore}
            onClick={onClickScore}
          >
            <FnbTypography variant={theme.typography["b1-regular"]} text="1" /> <StarFilled />
          </FnbRadioButton>
        </Form.Item>
        <Form.Item name="two-score" initialValue={false}>
          <FnbRadioButton
            icon={<TickCircleIcon width={16} height={16} className="check-icon" />}
            name={"two-score"}
            value={isCheckTwoScore}
            isChecked={isCheckTwoScore}
            onClick={onClickScore}
          >
            <FnbTypography variant={theme.typography["b1-regular"]} text="2" /> <StarFilled />
          </FnbRadioButton>
        </Form.Item>
        <Form.Item name="three-score" initialValue={false}>
          <FnbRadioButton
            name={"three-score"}
            icon={<TickCircleIcon width={16} height={16} className="check-icon" />}
            value={isCheckThreeScore}
            isChecked={isCheckThreeScore}
            onClick={onClickScore}
          >
            <FnbTypography variant={theme.typography["b1-regular"]} text="3" /> <StarFilled />
          </FnbRadioButton>
        </Form.Item>
        <Form.Item name="four-score" initialValue={false}>
          <FnbRadioButton
            name={"four-score"}
            icon={<TickCircleIcon width={16} height={16} className="check-icon" />}
            value={isCheckFourScore}
            isChecked={isCheckFourScore}
            onClick={onClickScore}
          >
            <FnbTypography variant={theme.typography["b1-regular"]} text="4" /> <StarFilled />
          </FnbRadioButton>
        </Form.Item>
        <Form.Item name="five-score" initialValue={false}>
          <FnbRadioButton
            name={"five-score"}
            icon={<TickCircleIcon width={16} height={16} className="check-icon" />}
            value={isCheckFiveScore}
            isChecked={isCheckFiveScore}
            onClick={onClickScore}
          >
            <FnbTypography variant={theme.typography["b1-regular"]} text="5" /> <StarFilled />
          </FnbRadioButton>
        </Form.Item>
      </div>

      <Form.Item label={pageData.filter.hiddenShow} name="review-status" initialValue={""}>
        <FnbSelectSingle
          onChange={onChangeStatus}
          className="form-select"
          showSearch={false}
          option={reviewStatusOptions}
        />
      </Form.Item>
    </Form>
  );
});
