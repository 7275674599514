/* eslint-disable jsx-a11y/anchor-is-valid */
import { PercentageOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Form, message, Row, TimePicker, Typography } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { DELAYED_TIME, tableSettings } from "constants/default.constants";
import { CalendarNewIconBold, FlashSalePercentIcon, InfoCircleFlashSaleIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { currency, DateFormat } from "constants/string.constants";
import comboDataService from "data-services/combo/combo-data.service";
import flashSaleDataService from "data-services/flash-sale/flash-sale-data.service";
import debounce from "lodash/debounce";
import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatNumber, getCurrency, getValidationMessagesWithParentField, roundNumber, checkOnKeyPressValidation } from "utils/helpers";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbSelectProduct from "components/fnb-select-product";
import "./index.scss";
import i18n from "utils/i18n";

const { Text } = Typography;

export default function CreateNewFlashSale(props) {
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  const [isMinimumPurchaseAmount, setIsMinimumPurchaseAmount] = useState(false);
  const [isSpecificBranch, setIsSpecificBranche] = useState(false);
  const [isIncludedTopping, setIsIncludedTopping] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [disableAllBranches, setDisableAllBranches] = useState(false);
  const [dataSelectedProduct, setDataSelectedProduct] = useState([]);
  const [selectedProductPriceIds, setSelectedProductPriceIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsMapping, setProductsMapping] = useState([]);
  const [hiddenPercent, setHiddenPercent] = useState(false);

  const pageData = {
    leaveForm: t("messages.leaveForm"),
    create: t("promotion.create"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    createPromotionSuccess: t("promotion.createPromotionSuccess"),
    selectDate: t("promotion.selectDate"),
    allBranch: t("productCategory.branch.all"),
    branch: t("material.inventory.branch"),
    form: {
      general: t("promotion.form.general"),
      name: t("promotion.flashSale.name"),
      PlaceholderName: t("promotion.form.PlaceholderName"),
      maxLengthName: 100,
      pleaseEnterPromotionName: t("promotion.form.pleaseEnterPromotionName"),
      promotionType: t("promotion.form.promotionType"),
      selectPromotionType: t("promotion.form.selectPromotionType"),
      pleaseSelectPromotionType: t("promotion.form.pleaseSelectPromotionType"),
      product: t("promotion.form.product"),
      selectProduct: t("promotion.form.selectProduct"),
      pleaseSelectProduct: t("promotion.form.pleaseSelectProduct"),
      productCategory: t("promotion.form.productCategory"),
      selectProductCategory: t("promotion.form.selectProductCategory"),
      pleaseSelectProductCategory: t("promotion.form.pleaseSelectProductCategory"),
      percent: "%",
      discountValue: t("promotion.form.discountValue"),
      pleaseEnterPrecent: t("promotion.form.pleaseEnterPrecent"),
      maxDiscount: t("promotion.form.maxDiscount"),
      pleaseEnterMaxDiscount: t("promotion.form.pleaseEnterMaxDiscount"),
      startDate: t("promotion.form.startDate"),
      PleaseStartDate: t("promotion.form.pleaseStartDate"),
      endDate: t("promotion.form.endDate"),
      PlaceholderDateTime: t("promotion.form.placeholderDateTime"),
      termsAndConditions: t("promotion.form.termsAndConditions"),
      maxLengthTermsAndConditions: 2000,
      maximum: 999999999,
      condition: {
        title: t("promotion.form.condition.title"),
        checkboxPurchaseAmount: t("promotion.form.condition.checkboxPurchaseAmount"),
        pleaseEnterMinimum: t("promotion.form.condition.pleaseEnterMinimum"),
        checkboxSpecificBranches: t("promotion.form.condition.checkboxSpecificBranches"),
        pleaseSelectSpecificBranches: t("promotion.flashSale.pleaseSelectSpecificBranches"),
        selectBranchPlaceholder: t("promotion.form.condition.selectBranchPlaceholder"),
        specificBranchesTooltip: t("promotion.form.condition.specificBranchesTooltip"),
        includedTopping: t("promotion.form.condition.includedTopping"),
        minimumPurchaseAmountTooltip: t("promotion.flashSale.minimumPurchaseAmountTooltip"),
      },
      startTime: t("promotion.flashSale.startTime"),
      endTime: t("promotion.flashSale.endTime"),
      no: t("table.no"),
      nameColumn: t("promotion.table.name"),
      price: t("combo.price.title"),
      flashSalePrice: t("promotion.flashSale.flashSalePrice"),
      flashSaleQuantity: t("promotion.flashSale.flashSaleQuantity"),
      flashSaleDiscount: t("promotion.flashSale.flashSaleDiscount"),
      maximumLimit: t("promotion.flashSale.maximumLimit"),
      createTitle: t("promotion.flashSale.createTitle"),
      enterCampaignName: t("promotion.flashSale.enterCampaignName"),
      nameValidateMessage: t("promotion.flashSale.nameValidateMessage"),
      enterTermsAndCondition: t("promotion.flashSale.enterTermsAndCondition"),
      pleaseSelectStartDate: t("promotion.flashSale.pleaseSelectStartDate"),
      pleaseSelectStartTime: t("promotion.flashSale.pleaseSelectStartTime"),
      pleaseStartEndTime: t("promotion.flashSale.pleaseStartEndTime"),
      pleaseInputPrice: t("promotion.flashSale.pleaseInputPrice"),
      pleaseInputQuantity: t("promotion.flashSale.pleaseInputQuantity"),
      maximumLimitValidate: t("promotion.flashSale.maximumLimitValidate"),
      tooltipFlashSaleQuantity: t("promotion.flashSale.tooltipFlashSaleQuantity"),
      tooltipMaximumLimit: t("promotion.flashSale.tooltipMaximumLimit"),
      productValidate: t("promotion.flashSale.productValidate"),
      flashSaleQuantityValidate: t("promotion.flashSale.flashSaleQuantityValidate"),
      flashSalePriceValidate: t("promotion.flashSale.flashSalePriceValidate"),
      createFlashSaleSuccess: t("promotion.flashSale.createFlashSaleSuccess"),
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    guideline: {
      title: t("promotion.flashSale.guideline.title"),
      content: t("promotion.flashSale.guideline.content"),
    },
    product: {
      title: t("combo.product.title"),
      comboType: t("combo.comboType"),
      productPlaceholder: t("productManagement.table.searchPlaceholder"),
      productValidateMessage: t("combo.product.productValidateMessage"),
      tooltipMessage: t("combo.product.tooltipMessage"),
      categoryValidateMessage: t("combo.product.categoryValidateMessage"),
      categoryPlaceholder: t("combo.product.categoryPlaceholder"),
      groups: t("combo.product.groups"),
      group: t("combo.product.group"),
      category: t("combo.product.category"),
      itemQuantity: t("combo.product.itemQuantity"),
      itemQuantityPlaceholder: t("combo.product.itemQuantityPlaceholder"),
      itemQuantityValidateMessage: t("combo.product.itemQuantityValidateMessage"),
      addGroup: t("combo.product.addGroup"),
    },
    inventoryTracking: {
      pleaseEnterQuantity: t("inventoryTracking.pleaseEnterQuantity"),
      quantityMoreThanZero: t("inventoryTracking.quantityGreaterThanZero"),
    },
  };

  useLayoutEffect(() => {
    getPrepareData();
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

    form.validateFields(errorFields);
  }, [i18n.language]);

  const getPrepareData = () => {
    comboDataService.getPrepareCreateProductComboDataAsync().then((data) => {
      const { branches, products } = data;
      const productOptions =
        products?.sort((pre, current) => {
          return pre?.name?.localeCompare(current?.name);
        }) || [];
      setProducts(productOptions);
      setBranches(branches);
      const mapping = getMappingData(products);
      setProductsMapping(mapping);
    });
  };

  const getMappingData = (products) => {
    let options = [];
    products?.forEach((product) => {
      const oneSizeOnly = product?.productPrices?.length === 1 && !product?.productPrices?.[0]?.priceName;
      Object.assign(
        options,
        product?.productPrices?.reduce((map, option) => {
          map[option.id] = {
            id: option?.id,
            name: product?.name,
            priceName: option?.priceName,
            priceValue: option?.priceValue,
            thumbnail: product?.thumbnail,
            oneSizeOnly,
          };
          return map;
        }, {}),
      );
    });
    return options;
  };

  const getStateMapping = () => {
    return dataSelectedProduct?.reduce((map, product) => {
      map[product.id] = product;
      return map;
    }, {});
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const columnsProduct = (indexPriceName) => {
    let columns = [
      {
        title: pageData.form.no,
        width: "5rem",
        dataIndex: "index",
        render: (value) => value + 1,
      },
      {
        title: pageData.form.nameColumn,
        dataIndex: "name",
        ellipsis: true,
        render: (_, record, index) => (
          <div className="column-flash-sale-name">
            <div className="table-selling-product-thumbnail">
              <Thumbnail width={32} height={32} src={record?.thumbnail} />
            </div>
            <FnbTooltip
              title={record?.productPriceName ? `${record?.name} - ${record?.productPriceName}` : record?.name}
              onlyShowWhenEllipsis
              maxWidthContent={"90%"}
            >
              <div>
                <strong>{record?.name}</strong>
                {record?.productPriceName && <span> - {record?.productPriceName}</span>}
              </div>
            </FnbTooltip>
          </div>
        ),
      },
      {
        title: `${pageData.form.price} (${getCurrency()})`,
        dataIndex: "priceFormat",
        width: "10%",
        render: (_, record) => <span>{record?.priceFormat}</span>,
      },
      {
        title: `${pageData.form.flashSalePrice} (${getCurrency()})`,
        dataIndex: "flashSalePrice",
        editable: true,
        render: (_, record, index) => {
          return (
            <>
              <Form.Item
                name={["flashSale", "products", index, "flashSalePrice"]}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.form.pleaseInputPrice} />,
                  },
                  () => ({
                    validator(_, value) {
                      if (value >= dataSelectedProduct[index]?.price) {
                        setHiddenPercent(true);
                        return Promise.reject(<InputValidateMessage message={pageData.form.flashSalePriceValidate} />);
                      } else if (value) {
                        setHiddenPercent(false);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                className="form-item-quantity"
              >
                <FnbInputNumber
                  className="input-quantity"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={() => {
                    let { flashSale } = form.getFieldValue();
                    var productSelect = [...dataSelectedProduct];
                    productSelect[index].flashSalePrice = flashSale?.products[index]?.flashSalePrice;
                    setDataSelectedProduct(productSelect);
                    if (!flashSale?.products[index]?.flashSalePrice) {
                      setHiddenPercent(true);
                    }
                  }}
                  precision={2}
                />
              </Form.Item>
            </>
          );
        },
      },
      {
        title: pageData.form.flashSaleDiscount,
        width: "8rem",
        align: "center",
        render: (_, record, index) => (
          <div className="color-primary" hidden={hiddenPercent}>
            <Row
              className={
                form.getFieldValue(["flashSale", "products", index, "flashSalePrice"]) ? "" : "has-error-message"
              }
            >
              <Col>
                <FlashSalePercentIcon />
              </Col>
              <Col className="ml-1 column-discount-percentage">
                <span>
                  {record?.price === 0
                    ? 0
                    : roundNumber(
                        ((record?.price - dataSelectedProduct[index]?.flashSalePrice) / record?.price) * 100,
                        2,
                      )}
                </span>
                <PercentageOutlined />
              </Col>
            </Row>
          </div>
        ),
      },
      {
        title: (
          <div className="title-flash-sale-quantity">
            {pageData.form.flashSaleQuantity}
            <FnbTooltip placement="topLeft" title={pageData.form.tooltipFlashSaleQuantity}>
              <InfoCircleFlashSaleIcon className="flash-sale-product-tooltip-icon" size={24} />
            </FnbTooltip>
          </div>
        ),
        dataIndex: "flashSaleQuantity",
        editable: true,
        render: (_, record, index) => (
          <Form.Item
            name={["flashSale", "products", index, "flashSaleQuantity"]}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.form.pleaseInputQuantity} />,
              },
              {
                type: "number",
                min: 1,
                message: <InputValidateMessage message={pageData.inventoryTracking.quantityMoreThanZero} />,
              },
              {
                type: "number",
                max: 999999,
                message: <InputValidateMessage message={pageData.form.flashSaleQuantityValidate} />,
              },
            ]}
            className="form-item-quantity"
          >
            <FnbInputNumber
              className="input-quantity"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={() => {
                let { flashSale } = form.getFieldValue();
                var productSelect = [...dataSelectedProduct];
                productSelect[index].flashSaleQuantity = flashSale?.products[index]?.flashSaleQuantity;
                setDataSelectedProduct(productSelect);
              }}
              precision={0}
            />
          </Form.Item>
        ),
      },
      {
        title: (
          <div className="title-flash-sale-maximum-limit">
            {pageData.form.maximumLimit}
            <FnbTooltip placement="topLeft" title={pageData.form.tooltipMaximumLimit}>
              <InfoCircleFlashSaleIcon className="flash-sale-product-tooltip-icon" size={24} />
            </FnbTooltip>
          </div>
        ),
        dataIndex: "maximumLimit",
        width: "15%",
        editable: true,
        ellipsis: true,
        render: (_, record, index) => (
          <Form.Item
            name={["flashSale", "products", index, "maximumLimit"]}
            rules={[
              {
                type: "number",
                min: 1,
                message: <InputValidateMessage message={pageData.inventoryTracking.quantityMoreThanZero} />,
              },
              () => ({
                validator(_, value) {
                  let formValue = form.getFieldsValue();
                  let { flashSale } = formValue;
                  if (value > flashSale?.products[index]?.flashSaleQuantity) {
                    return Promise.reject(<InputValidateMessage message={pageData.form.maximumLimitValidate} />);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="form-item-quantity"
          >
            <FnbInputNumber
              className="input-quantity"
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              id={`product-materials-priceName-${indexPriceName}-material-${index}-quantity`}
              onChange={() => {
                let { flashSale } = form.getFieldValue();
                var productSelect = [...dataSelectedProduct];
                productSelect[index].maximumLimit = flashSale?.products[index]?.maximumLimit;
                setDataSelectedProduct(productSelect);
              }}
              precision={0}
            />
          </Form.Item>
        ),
      },
    ];
    return columns;
  };

  const onSelectProduct = (options) => {
    let newDataSelectedProduct = [];

    if (options?.length > 0) {
      const stateMapping = getStateMapping();

      options?.forEach((option) => {
        const productPrice = productsMapping[option];
        const discount = stateMapping[option];
        const newRow = {
          id: productPrice?.id,
          name: productPrice?.name,
          price: productPrice?.priceValue,
          priceFormat: formatNumber(productPrice?.priceValue),
          isSinglePrice: productPrice?.oneSizeOnly,
          productPriceName: productPrice?.priceName,
          thumbnail: productPrice?.thumbnail,
          flashSalePrice: discount?.flashSalePrice || null,
          flashSaleQuantity: discount?.flashSaleQuantity || null,
          maximumLimit: discount?.maximumLimit || null,
        };
        newDataSelectedProduct = [...newDataSelectedProduct, newRow];
      });
    }
    setDataSelectedProduct(newDataSelectedProduct);
    setSelectedProductPriceIds(options);
    
    newDataSelectedProduct?.forEach((product, index) => {
      form.setFieldValue(["flashSale", "products", index, "flashSalePrice"], product?.flashSalePrice);
      form.setFieldValue(["flashSale", "products", index, "flashSaleQuantity"], product?.flashSaleQuantity);
      form.setFieldValue(["flashSale", "products", index, "maximumLimit"], product?.maximumLimit);
    });
  };

  const onDeSelectProduct = (_selectedRowKeys, selectedRows) => {
    const { id } = selectedRows?.[0];
    setSelectedRowKeys([]);

    const newDataSelectedProduct = dataSelectedProduct?.filter((product) => product?.id !== id);
    setDataSelectedProduct(newDataSelectedProduct);

    const newSelectedProductPriceIds = selectedProductPriceIds?.filter((productId) => productId !== id);
    setSelectedProductPriceIds(newSelectedProductPriceIds);

    newDataSelectedProduct?.forEach((product, index) => {
      form.setFieldValue(["flashSale", "products", index, "flashSalePrice"], product?.flashSalePrice);
      form.setFieldValue(["flashSale", "products", index, "flashSaleQuantity"], product?.flashSaleQuantity);
      form.setFieldValue(["flashSale", "products", index, "maximumLimit"], product?.maximumLimit);
    });
  };

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const getFormSelectedMaterials = () => {
    return (
      <>
        <Row className="w-100">
          <Col span={24}>
            <FnbTable
              rowKey="id"
              dataSource={dataSelectedProduct}
              columns={columnsProduct(0)}
              pageSize={tableSettings.pageSize}
              pageNumber={tableSettings.page}
              total={dataSelectedProduct.length}
              showPaging={false}
              scrollY={96 * 5}
              scrollX={1300}
              className="table-flash-sale-campaign"
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                onDelete: onDeSelectProduct,
              }}
            />
          </Col>
        </Row>
      </>
    );
  };

  const clickCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      onCancel();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onCancel = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/store/promotion/flashSale");
    }, DELAYED_TIME);
  };

  const onSelectBranches = (values) => {
    const listBranch = branches.filter((b) => values.find((v) => v === b.id));
    if (values && values.length > 0) {
      let formValues = form.getFieldsValue();
      let branchIds = [];
      branchIds = listBranch?.map((item) => item?.id);

      form.setFieldsValue({
        ...formValues,
        flashSale: { branchIds: branchIds },
      });
    }
  };

  const onSelectAllBranches = (event) => {
    let isChecked = event.target.checked;
    setDisableAllBranches(isChecked);
    let branchIds = [];
    if (isChecked) {
      branchIds = branches?.map((item) => item?.id);
      onSelectBranches(branchIds);
      let formValue = {
        flashSale: {
          branchIds: branchIds,
        },
      };
      form.setFieldsValue(formValue);
    }
  };

  const setStartTime = (value) => {
    let formValue = form.getFieldsValue();
    let { flashSale } = formValue;
    flashSale.startTime = value;
    form.setFieldsValue(formValue);
  };

  const setEndTime = (value) => {
    let formValue = form.getFieldsValue();
    let { flashSale } = formValue;
    flashSale.endTime = value;
    form.setFieldsValue(formValue);
  };

  const onCheckSpecificBranches = (e) => {
    setIsSpecificBranche(e.target.checked);
  };

  const onCreateNewFlashSale = (values) => {
    let { flashSale } = values;
    if (!flashSale?.products || flashSale?.products?.length < 1) {
      message.error(pageData.form.productValidate);
    } else {
      // eslint-disable-next-line array-callback-return
      flashSale?.products?.map((product, index) => {
        var productSelect = dataSelectedProduct[index];
        productSelect.flashSalePrice = product.flashSalePrice;
        productSelect.flashSaleQuantity = product.flashSaleQuantity;
        productSelect.maximumLimit = product.maximumLimit;
      });
      var flashStartDate = new Date(
        moment(flashSale?.startDate).year(),
        moment(flashSale?.startDate).month(),
        moment(flashSale?.startDate).date(),
        moment(flashSale?.startTime).hours(),
        moment(flashSale?.startTime).minutes(),
        moment(flashSale?.startTime).seconds(),
      );
      var flashEndDate = new Date(
        moment(flashSale?.startDate).year(),
        moment(flashSale?.startDate).month(),
        moment(flashSale?.startDate).date(),
        moment(flashSale?.endTime).hours(),
        moment(flashSale?.endTime).minutes(),
        moment(flashSale?.endTime).seconds(),
      );

      var saveObj = {
        flashSale: {
          ...flashSale,
          startDate: moment.utc(flashStartDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2),
          endDate: moment.utc(flashEndDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2),
          isMinimumPurchaseAmount: isMinimumPurchaseAmount,
          isIncludedTopping: isIncludedTopping,
          flashSaleProducts: dataSelectedProduct,
        },
      };
      flashSaleDataService
        .createFlashSaleAsync(saveObj)
        .then((response) => {
          if (response === true) {
            onCancel();
            message.success(pageData.form.createFlashSaleSuccess);
          }
        })
        .catch((errs) => {
          form.setFields(getValidationMessagesWithParentField(errs, "flashSale"));
        });
    }
  };

  /**
   * Disable Hour From Start Time
   */

  const getDisabledHoursStartTime = () => {
    var hours = [];
    let formValue = form.getFieldsValue();
    let { flashSale } = formValue;
    var flashSaleDate = new Date(
      moment(flashSale?.startDate).year(),
      moment(flashSale?.startDate).month(),
      moment(flashSale?.startDate).date(),
    );
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (flashSaleDate.toDateString() === currentDate.toDateString()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  /**
   * Disable Hour Minute From Start Time
   * @param {*} selectedHour
   */

  const getDisabledMinutesStartTime = (selectedHour) => {
    var minutes = [];
    let formValue = form.getFieldsValue();
    let { flashSale } = formValue;
    var flashSaleDate = new Date(
      moment(flashSale?.startDate).year(),
      moment(flashSale?.startDate).month(),
      moment(flashSale?.startDate).date(),
    );
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (flashSaleDate.toDateString() === currentDate.toDateString()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  /**
   * Disable Hour From End Time
   */

  const getDisabledHoursEndTime = () => {
    var hours = [];
    let formValue = form.getFieldsValue();
    let { flashSale } = formValue;
    for (var i = 0; i < moment(flashSale.startTime).hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  /**
   * Disable Hour Minute From End Time
   * @param {*} selectedHour
   */
  const getDisabledMinutesEndTime = (selectedHour) => {
    var minutes = [];
    let formValue = form.getFieldsValue();
    let { flashSale } = formValue;
    if (selectedHour === moment(flashSale.startTime).hour()) {
      for (var i = 0; i <= moment(flashSale.startTime).minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  return (
    <>
      <Form
        onFinish={debounce(onCreateNewFlashSale, 1000)}
        form={form}
        onFieldsChange={() => setIsChangeForm(true)}
        layout="vertical"
        autoComplete="off"
      >
        <FnbHeadingPage
          title={pageData.form.createTitle}
          guideline={{
            title: pageData.guideline.title,
            content: pageData.guideline.content,
          }}
          isCompactButtons={false}
          listButtons={[
            <FnbButton variant="tertiary" text={pageData.btnCancel} onClick={clickCancel} />,
            <FnbButton
              minWidth={100}
              type="submit"
              text={pageData.btnSave}
              permission={PermissionKeys.CREATE_FLASH_SALE}
              onClick={() => setIsSubmitting(true)}
            />,
          ]}
        />
        <Row>
          <Card className="fnb-card w-100">
            <Row>
              <h3 className="label-information">{pageData.form.general}</h3>
            </Row>
            <Row>
              <h4 className="fnb-form-label mt-32">
                {pageData.form.name}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item
                name={["flashSale", "name"]}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.form.nameValidateMessage} />,
                  },
                  {
                    type: "string",
                    max: 100,
                  },
                ]}
                className="w-100"
              >
                <FnbInput
                  className="fnb-input-with-count"
                  showCount
                  maxLength={pageData.form.maxLengthName}
                  placeholder={pageData.form.enterCampaignName}
                />
              </Form.Item>
            </Row>
            <Row>
              <h4 className="fnb-form-label">{pageData.form.termsAndConditions}</h4>
              <Form.Item name={["flashSale", "termsAndCondition"]} className="w-100">
                <FnbTextArea
                  placeholder={pageData.form.enterTermsAndCondition}
                  showCount
                  maxLength={pageData.form.maxLengthTermsAndConditions}
                  rows={4}
                ></FnbTextArea>
              </Form.Item>
            </Row>
          </Card>
        </Row>
        <Row className="mt-3">
          <Card className="fnb-card w-100">
            <Row gutter={[32, 16]}>
              <Col xs={24} lg={8}>
                <h4 className="fnb-form-label">
                  {pageData.form.startDate} <span className="text-danger">*</span>
                </h4>

                <Form.Item
                  name={["flashSale", "startDate"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.pleaseSelectStartDate} />,
                    },
                  ]}
                >
                  <DatePicker
                    suffixIcon={<CalendarNewIconBold />}
                    placeholder={pageData.selectDate}
                    className="fnb-date-picker w-100"
                    disabledDate={disabledDate}
                    format={DateFormat.DD_MM_YYYY}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <h4 className="fnb-form-label">
                  {pageData.form.startTime}
                  <span className="text-danger">*</span>
                </h4>

                <Form.Item
                  name={["flashSale", "startTime"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.pleaseSelectStartTime} />,
                    },
                  ]}
                >
                  <TimePicker
                    className="fnb-date-picker   w-100"
                    dropdownClassName="fnb-date-time-picker-dropdown fnb-date-time-picker-dropdown-style"
                    format={"HH:mm"}
                    onSelect={(time) => {
                      setStartTime(moment(time, "HH:mm"));
                    }}
                    showNow={false}
                    showConfirm={false}
                    disabledHours={getDisabledHoursStartTime}
                    disabledMinutes={getDisabledMinutesStartTime}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <h4 className="fnb-form-label">
                  {pageData.form.endTime} <span className="text-danger">*</span>
                </h4>

                <Form.Item
                  name={["flashSale", "endTime"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.pleaseStartEndTime} />,
                    },
                  ]}
                >
                  <TimePicker
                    className="fnb-date-picker   w-100"
                    dropdownClassName="fnb-date-time-picker-dropdown fnb-date-time-picker-dropdown-style"
                    format={"HH:mm"}
                    onSelect={(time) => {
                      setEndTime(moment(time, "HH:mm"));
                    }}
                    showNow={false}
                    showConfirm={false}
                    disabledHours={getDisabledHoursEndTime}
                    disabledMinutes={getDisabledMinutesEndTime}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row className="mt-3 fnb-create-flash-sale">
          <Card className="fnb-card w-100">
            <Row>
              <h4 className="fnb-form-label">
                {pageData.form.product}
                <span className="text-danger">*</span>
              </h4>
              <Col span={24}>
                <FnbSelectProduct
                  options={products}
                  placeholder={pageData.product.productPlaceholder}
                  listHeight={500}
                  onChange={onSelectProduct}
                  value={selectedProductPriceIds}
                  hiddenSelectAll
                />
              </Col>
            </Row>
            {isSubmitting && selectedProductPriceIds.length < 1 && (
              <InputValidateMessage message={pageData.form.productValidate} />
            )}
            <Row className="mt-3">
              <Col span={24}>{getFormSelectedMaterials()}</Col>
            </Row>
          </Card>
        </Row>
        <Row className="mt-3">
          <Card className="fnb-card w-100">
            <Row>
              <h5 className="title-group">{pageData.form.condition.title}</h5>
            </Row>
            <Row className="mb-2">
              <FnbCheckBox
                valuePropName="checked"
                noStyle
                onChange={(e) => setIsMinimumPurchaseAmount(e.target.checked)}
              >
                <Text>{pageData.form.condition.checkboxPurchaseAmount}</Text>
              </FnbCheckBox>
              <FnbTooltip placement="topLeft" title={pageData.form.condition.minimumPurchaseAmountTooltip}>
                <InfoCircleFlashSaleIcon size={24} />
              </FnbTooltip>
            </Row>
            {isMinimumPurchaseAmount && (
              <Row>
                <Form.Item
                  name={["flashSale", "minimumPurchaseAmount"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.condition.pleaseEnterMinimum} />,
                    },
                    () => ({
                      validator(_, value) {
                        if (value && (value <= 0 || value >= 1e9)) {
                          return Promise.reject(
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.form.pleaseEnterMaxDiscount}
                            />,
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  className="w-100"
                >
                  <FnbInputNumber
                    className="w-100 fnb-input-number"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={getCurrency() === currency.vnd ? 1 : 0}
                    precision={getCurrency() === currency.vnd ? 0 : 2}
                    id= "flashSale-minimumPurchaseAmount"
                    onKeyPress={(event) => {
                      const checkValidKey = checkOnKeyPressValidation(
                        event,
                        "flashSale-minimumPurchaseAmount",
                        0,
                        null,
                        getCurrency() === currency.vnd ? 0 : 2,
                      );
                      if (!checkValidKey) event.preventDefault();
                    }}
                  />
                </Form.Item>
              </Row>
            )}
            <Row className="mb-2">
              <FnbCheckBox valuePropName="checked" onChange={onCheckSpecificBranches}>
                <Text>{pageData.form.condition.checkboxSpecificBranches}</Text>
              </FnbCheckBox>
              <FnbTooltip placement="topLeft" title={pageData.form.condition.specificBranchesTooltip}>
                <InfoCircleFlashSaleIcon size={24} />
              </FnbTooltip>
            </Row>
            {isSpecificBranch && (
              <>
                <h3 className="fnb-form-label mt-16">{pageData.branch}</h3>
                <div className="material-check-box-select-all-branch">
                  <FnbCheckBox onChange={(event) => onSelectAllBranches(event)}>{pageData.allBranch}</FnbCheckBox>
                </div>
                <Row>
                  <Col span={24} hidden={disableAllBranches}>
                    <Form.Item
                      name={["flashSale", "branchIds"]}
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage message={pageData.form.condition.pleaseSelectSpecificBranches} />
                          ),
                        },
                      ]}
                      className="w-100"
                    >
                      <FnbSelect
                        mode="multiple"
                        onChange={(values) => onSelectBranches(values)}
                        placeholder={pageData.form.condition.selectBranchPlaceholder}
                        className="w-100"
                        allowClear
                        options={branches?.map((item) => ({
                          value: item?.id,
                          label: t(item?.name),
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} hidden={!disableAllBranches}>
                    <Form.Item name="tmpBranchIds">
                      <FnbSelect mode="multiple" disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <FnbCheckBox onChange={(e) => setIsIncludedTopping(e.target.checked)}>
                <Text>{pageData.form.condition.includedTopping}</Text>
              </FnbCheckBox>
            </Row>
          </Card>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onCancel}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
