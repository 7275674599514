import React, { useState, useEffect } from "react";
import { Card, Row, Col, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./translated-language.style.scss";
import AddLanguage from "./components/add-language.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import languageDataService from "data-services/language/language-data.service";
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useMediaQuery } from "react-responsive";

export default function TranslatedLanguage(props) {
  const [t] = useTranslation();
  const [languageList, setLanguageList] = useState([]);
  const [showAddLanguage, setShowAddLanguage] = useState(false);
  const [listLanguageStore, setListLanguageStore] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobileDevice = useMediaQuery({ maxWidth: 575 });
  const pageData = {
    btnAddLanguage: t("languageConfig.addLanguage"),
    translatedLanguages: t("languageConfig.translatedLanguages"),
    translatedContent: t("languageConfig.translatedContent"),
    languages: t("languageConfig.languages"),
    action: t("languageConfig.action"),
    publish: t("languageConfig.publish"),
    unpublish: t("languageConfig.unpublish"),
    updateSuccess: t("messages.updateSuccess"),
  };

  useEffect(() => {
    initDataTable();
  }, []);

  const showLanguage = async (value) => {
    setShowAddLanguage(value);
    await loadLanguageList();
  };

  const loadLanguageList = async () => {
    let res = await languageDataService.getListLanguageNotContainStoreIdAsync();
    if (res) {
      setLanguageList(res.languages);
    }
  };

  const initDataTable = () => {
    languageDataService.getListLanguageStoreByStoreIdAsync().then((res) => {
      let languageStore = mappingToDataTable(res.languageStore);
      setListLanguageStore(languageStore);
    });
  };

  const mappingToDataTable = (languageStore) => {
    return languageStore?.map((i, index) => {
      return {
        id: i.id,
        name: i.name,
        emoji: i.emoji,
        isDefault: i.isDefault,
        isPublish: i.isPublish?.id,
        isPublishName: i.isPublish?.name,
      };
    });
  };

  const onCancel = () => {
    setShowAddLanguage(false);
  };

  const onPublishItem = (record) => {
    setLoading(true);
    languageDataService.updateIsPublishByIdAsync(record).then((res) => {
      if (res) {
        message.success(pageData.updateSuccess);
        initDataTable();
      }
      if (window.reloadLang) {
        window.reloadLang();
      }
      setLoading(false);
    });
  };

  const tableConfigs = [
    {
      title: pageData.languages.toUpperCase(),
      dataIndex: "emoji",
      width: isMobileDevice ? "50%" : "unset",
      render: (_, record) => {
        return (
          <Row className="col-language">
            <CircleFlag className="circle-icon" countryCode={record.emoji.toLowerCase()} height={24} />
            <span>{t(record.name)}</span>
          </Row>
        );
      },
    },
    {
      title: pageData.action.toUpperCase(),
      width: isMobileDevice ? "50%" : 200,
      align: "center",
      render: (_, record) => {
        if (!record.isDefault) {
          return (
            <FnbButton
              className="btn-publish"
              variant="tertiary"
              disabled={loading}
              onClick={() => onPublishItem(record)}
              text={t(record.isPublishName)}
            >
            </FnbButton>
          );
        }
      },
    },
  ];

  return (
    <div className="content-tab">
      <Card className="fnb-card-full">
        <Row>
          <FnbButton
            className="float-right"
            text={pageData.btnAddLanguage}
            variant="primary"
            iconHeader={<PlusOutlined style={{color: '#fff', fontSize: 18}} />}
            onClick={() => showLanguage(true)}
          ></FnbButton>
        </Row>
        <Row>
          <Col span={24}>
            <FnbTable
              className="form-table language-table"
              isRenderTableController={false}
              columns={tableConfigs}
              dataSource={listLanguageStore}
              scrollX={"max-content"}
            />
          </Col>
        </Row>
      </Card>
      <AddLanguage
        languageList={languageList}
        isModalVisible={showAddLanguage}
        handleCancel={onCancel}
        t={t}
        onLoadLanguage={loadLanguageList}
        initDataTable={initDataTable}
      />
    </div>
  );
}
