import { PermissionKeys } from "constants/permission-key.constants";
import React, { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import { useState } from "react";
import moment from "moment";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { executeAfter, formatDate, formatNumberDecimalOrInteger, formatShortDate, getCurrency } from "utils/helpers";
import { Card, Form, Row, Typography } from "antd";
import { PurchaseOrderStatus } from "constants/purchase-order-status.constants";
import { DateFormat, guidIdEmptyValue } from "constants/string.constants";
import purchaseOrderDataService from "data-services/purchase-order/purchase-order-data.service";
import { FilterPopover } from "./components/filter-popover.component";
import branchDataService from "data-services/branch/branch-data.service";
import supplierDataService from "data-services/supplier/supplier-data.service";
import "./purchase-order-history.page.scss";
import purchaseOrderHistoryDataService from "data-services/purchase-order-history/purchase-order-history-data.service";
import { actionType } from "constants/staff-activities.constants";
import DetailPurchaseOrderHistory from "./components/detail-purchase-order-history.page";
import FnbTypography from "components/fnb-typography/fnb-typography";
import staffDataService from "data-services/staff/staff-data.service";
import { getAllStaff, staffsSelector } from "store/modules/common/common.reducers";
import { useDispatch, useSelector } from "react-redux";
import { KEY_TAB_PANE } from "../material-control/table-inventory-control.component";
const { Text } = Typography;

export default function PurchaseOrderHistoryManagement(props) {
  const { match } = props;
  const staffs = useSelector(staffsSelector);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();
  const [countFilter, setCountFilter] = useState(0);
  const filterPopoverRef = React.useRef();
  const [branches, setBranches] = useState([]);
  const [supplierFilter, setSupplierFilter] = useState([]);
  const [staffsFilter, setStaffsFilter] = useState([]);
  const [dataFilterAndSearch, setDataFilterAndSearch] = useState({
    keySearch: undefined,
    startDate: undefined,
    endDate: undefined,
    branchId: undefined,
    supplierId: undefined,
    statusId: undefined,
    actionId: undefined,
  });
  const pageData = {
    addNew: t("purchaseOrder.addNew"),
    purchaseOrderManagement: t("purchaseOrder.purchaseOrderManagement"),
    shortHistory: t("purchaseOrderHistory.shortHistory"),
    history: t("purchaseOrderHistory.history"),
    export: t("button.export"),
    import: t("button.import"),
    btnFilter: t("button.filter"),
    viewHistory: t("button.viewHistory"),
    table: {
      searchPlaceholder: t("purchaseOrderHistory.searchPlaceholder"),
      code: t("table.code"),
      supplier: t("supplier.title"),
      branchWarehouse: t("purchaseOrder.branchWarehouse"),
      action: t("purchaseOrderHistory.action"),
      status: t("table.status"),
      lastUpdateBy: t("purchaseOrderHistory.lastUpdateBy"),
      createdDate: t("purchaseOrder.createdDate"),
      changeDetail: t("purchaseOrderHistory.changeDetail"),
    },
    today: t("optionDatetime.today"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    yesterday: "dashboard.compareDate.yesterday",
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("purchaseOrder.status.approved"),
      ordering: t("purchaseOrder.status.ordering"),
      canceled: t("purchaseOrder.status.canceled"),
      completed: t("purchaseOrder.status.completed"),
    },
    action: {
      created: t("purchaseOrderHistory.actionType.created"),
      updateStatus: t("purchaseOrderHistory.actionType.updateStatus"),
      cancelled: t("purchaseOrderHistory.actionType.cancelled"),
      edited: t("purchaseOrderHistory.actionType.edited"),
    },
    viewChange: t("purchaseOrderHistory.viewChange"),
  };
  const [selectedId, setSelectedId] = useState();
  const [isShowModalDetail, setIsShowModalDetail] = useState(false);
  const purchaseOrderId = match?.params?.id;
  const code = match?.params?.code;

  useEffect(() => {
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: "",
    });
  }, []);

  useEffect(() => {
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...purchaseOrderId });
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      fromDate: dataFilterAndSearch.startDate,
      toDate: dataFilterAndSearch.endDate,
      keySearch: dataFilterAndSearch.keySearch || "",
      branchId: dataFilterAndSearch.branchId,
      supplierId: dataFilterAndSearch.supplierId,
      status: dataFilterAndSearch.statusId,
      actionId: dataFilterAndSearch.actionId,
      updateBy: dataFilterAndSearch.updateBy,
    });
  }, [purchaseOrderId]);

  useEffect(() => {
    onSelectedDatePicker(selectedDate);
  }, [selectedDate]);

  useLayoutEffect(() => {
    dispatch(getAllStaff());
  }, []);

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...date });
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      fromDate: date.startDate,
      toDate: date.endDate,
      keySearch: dataFilterAndSearch.keySearch || "",
      branchId: dataFilterAndSearch.branchId,
      supplierId: dataFilterAndSearch.supplierId,
      status: dataFilterAndSearch.statusId,
      action: dataFilterAndSearch.actionId,
      updateBy: dataFilterAndSearch.updateBy,
    });
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.createdDate,
        dataIndex: "createdDate",
        key: "index",
        render: (_, record) => {
          return formatDate(record?.createdDate, DateFormat.HH_mm_DD_MMM_YYYY);
        },
      },
      {
        title: pageData.table.code,
        dataIndex: "id",
        key: "index",
        width: "9rem",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record?.code}</FnbTooltip>;
        },
      },
      {
        title: pageData.table.supplier,
        dataIndex: "supplierName",
        key: "supplier",
        ellipsis: true,
        render: (_, record) => {
          return (
            <>
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.name}>
                <span>{record?.supplierName}</span>
              </FnbTooltip>
            </>
          );
        },
      },
      {
        title: pageData.table.branchWarehouse,
        dataIndex: "storeBranchName",
        key: "index",
        ellipsis: true,
        render: (_, record) => {
          return (
            <>
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.name}>
                <span>{record?.storeBranchName}</span>
              </FnbTooltip>
            </>
          );
        },
      },
      {
        title: pageData.table.lastUpdateBy,
        dataIndex: "createdBy",
        key: "index",
        ellipsis: true,
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.createdBy}>
              <span>{record?.createdBy}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.action,
        dataIndex: "actionId",
        key: "index",
        render: (_, record) => {
          switch (record?.actionId) {
            case actionType.Created:
              return <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.action.created} />;
            case actionType.UpdateStatus:
              return <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.action.updateStatus} />;
            case actionType.Cancelled:
              return <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.action.cancelled} />;
            case actionType.Edited:
              return <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.action.edited} />;
            default:
              return <></>;
          }
        },
      },
      {
        title: pageData.table.status,
        dataIndex: "status",
        key: "index",
        width: "9rem",
        align: "center",
        render: (_, record) => {
          switch (record?.statusId) {
            case PurchaseOrderStatus.Approved:
              return <FnbBadge variant="primary" text={pageData.status.approved} />;
            case PurchaseOrderStatus.Canceled:
              return <FnbBadge variant="error" text={pageData.status.canceled} />;
            case PurchaseOrderStatus.Completed:
              return <FnbBadge variant="success" text={pageData.status.completed} />;
            case PurchaseOrderStatus.Ordering:
              return <FnbBadge variant="warning" text={pageData.status.ordering} />;
            default:
              return <FnbBadge variant="new" text={pageData.status.new} />;
          }
        },
      },
      {
        title: pageData.table.changeDetail,
        align: "center",
        render: (_, record) => {
          return <FnbTypography.Link onClick={(e) => { e.stopPropagation(); onViewDetail(record); }} text={pageData.viewChange} />
        },
      }
    ],

    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync({
        pageNumber: page,
        pageSize: pageSize,
        keySearch: dataFilterAndSearch.keySearch ?? "",
        fromDate: dataFilterAndSearch.startDate,
        toDate: dataFilterAndSearch.endDate,
        branchId: dataFilterAndSearch.branchId,
        supplierId: dataFilterAndSearch.supplierId,
        status: dataFilterAndSearch.statusId,
        actionId: dataFilterAndSearch.actionId,
        updateBy: dataFilterAndSearch.updateBy
      });
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        setDataFilterAndSearch({ ...dataFilterAndSearch, keySearch: keySearch });
        await fetchDatableAsync({
          pageNumber: 1,
          pageSize: tableSettings.pageSize,
          keySearch: keySearch,
          fromDate: dataFilterAndSearch.startDate,
          toDate: dataFilterAndSearch.endDate,
          branchId: dataFilterAndSearch.branchId,
          supplierId: dataFilterAndSearch.supplierId,
          status: dataFilterAndSearch.statusId,
          actionId: dataFilterAndSearch.actionId,
          updateBy: dataFilterAndSearch.updateBy
        });
      });
    },
  };

  const fetchDatableAsync = async ({
    pageNumber,
    pageSize,
    keySearch,
    fromDate,
    toDate,
    branchId,
    supplierId,
    status,
    action,
    updateBy
  }) => {
    const response = await purchaseOrderHistoryDataService.getAllPurchaseOrderHistoryAsync(
      pageNumber,
      pageSize,
      keySearch,
      fromDate,
      toDate,
      branchId,
      supplierId,
      status,
      action,
      updateBy,
      purchaseOrderId,
    );
    const data = response?.purchaseOrderHistories.map((s) => mappingRecordToColumns(s));
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
    setDataSource(data);

    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
    setNumberRecordCurrent(numberRecordCurrent);
  };

  const mappingRecordToColumns = (item) => {
    return {
      id: item?.id === guidIdEmptyValue ? item?.purchaseOrderId : item?.id,
      purchaseOrderId: item?.purchaseOrderId,
      code: item?.code,
      supplierName: item?.supplierName,
      storeBranchName: item?.storeBranchName,
      actionId: item?.actionId,
      statusId: item?.statusId,
      createdDate: item?.createdTime,
      createdBy: item?.createdBy,
    };
  };

  const getTableColumns = () => {
    return purchaseOrderId ? tableSettings.columns.filter(column => column.dataIndex !== "id") : tableSettings.columns;
  };
  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
    setCountFilter(0);
    setDataFilterAndSearch({
      ...dataFilterAndSearch,
      branchId: "",
      supplierId: "",
      statusId: "",
      actionId: "",

    });
  };
  const handleFilter = (data) => {
    setCurrentPageNumber(1);
    const countFilterData = (data?.branchId && data?.branchId !== "" ? 1 : 0) + (data?.supplierId && data?.supplierId !== "" ? 1 : 0) + 
    (data?.statusId !== undefined && data?.statusId !== null && data?.statusId !== "" ? 1 : 0) + (data?.actionId !== undefined && data?.actionId !== null && data?.actionId !== "" ? 1 : 0); 

    setCountFilter(countFilterData);
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...data });
    fetchDatableAsync({
      pageNumber: 1,
      pageSize: tableSettings.pageSize,
      keySearch: dataFilterAndSearch.keySearch || "",
      fromDate: dataFilterAndSearch.startDate,
      toDate: dataFilterAndSearch.endDate,
      branchId: data.branchId,
      supplierId: data.supplierId,
      status: data.statusId,
      action: data.actionId,
      updateBy: data.updateBy
    });
  };

  const filterComponent = () => {
    return (
      <FilterPopover
        fetchDataPurchaseOrder={handleFilter}
        ref={filterPopoverRef}
        branches={branches}
        supplierFilter={supplierFilter}
        staffs={staffsFilter}
      />
    );
  };
  const onClickFilterButton = async (event) => {
    const resBranch = await branchDataService.getAllBranchsAsync();
    if (resBranch) {
      const allBranchOption = {
        id: "",
        name: t("productManagement.filter.branch.all"),
      };
      const branchOptions = [allBranchOption, ...resBranch.branchs];
      setBranches(branchOptions);
    }

    const supplierData = await supplierDataService.getAllSupplierAsync();
    if (supplierData) {
      const allSuppliers = {
        id: "",
        name: t("purchaseOrder.allSupplier"),
      };
      var supplierFilterData = supplierData.suppliers.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      const suppliersData = [allSuppliers, ...supplierFilterData];
      setSupplierFilter(suppliersData);
    }

    if (staffs) {
      const allStaff = {
        id: "",
        name: t("purchaseOrderHistory.allStaff"),
      };
      const staffsOption = staffs.map((item) => {
        return {
          id: item?.account?.id,
          name: item?.fullName,
        }
      })
      const staffsData = [allStaff, ...staffsOption];
      setStaffsFilter(staffsData);
    }
  };

  const onRowClick = (record, _index) => {
    history.push(`/inventory/detail-purchase-order/${record?.purchaseOrderId}`);
  };

  const onViewDetail = (record) => {
    setIsShowModalDetail(true);
    setSelectedId(record?.id);
  }

  const handleCancelDetailModel = () => {
    setIsShowModalDetail(false);
    setSelectedId(null);
  }

  const renderItemsBreadcrumb = () => {
    if(purchaseOrderId){
      return [
        {
          title: pageData.history,
          href: "/inventory/purchase-order-history",
        },
        {
          href: `/inventory/detail-purchase-order/${purchaseOrderId}`,
          title: `# ${code}`,
        },
      ]
    }
    else {
      return [
        {
          href: `/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_PURCHASE_ORDER}`,
          title: pageData.purchaseOrderManagement,
        },
        {
          title: <Text className="purchase-order-code">{pageData.shortHistory}</Text>,
        },
      ]
    }
  }

  return (
    <div className="fnb-purchase-order-history">
      <FnbHeadingPage
        itemsBreadcrumb={renderItemsBreadcrumb()}
        listButtons={[
          <FnbDatePicker
            selectedDate={selectedDate}
            setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
            className="date-range-picker-filter"
          />,
        ]}
      ></FnbHeadingPage>
      <Form className="form-staff">
        <Card className="fnb-card-custom w-100">
          <Row className="form-staff">
            <FnbTable
              className="table-striped-rows table-purchase-order-management"
              dataSource={dataSource}
              columns={getTableColumns()}
              pageSize={tableSettings.pageSize}
              currentPageNumber={currentPageNumber}
              total={totalRecords}
              onChangePage={tableSettings.onChangePage}
              numberRecordCurrent={numberRecordCurrent}
              scrollX={1500}
              search={{
                placeholder: pageData.table.searchPlaceholder,
                onChange: tableSettings.onSearch,
                maxLength: 100,
              }}
              filter={{
                onClickFilterButton: onClickFilterButton,
                totalFilterSelected: countFilter,
                onClearFilter: onClearFilter,
                buttonTitle: pageData.btnFilter,
                component: filterComponent(),
              }}
              onRowClick={onRowClick}
            />
          </Row>
        </Card>
      </Form>

      <DetailPurchaseOrderHistory
        id={selectedId}
        t={t}
        isShowModal={isShowModalDetail}
        handleCancel={handleCancelDetailModel}
      />
    </div>
  );
}
