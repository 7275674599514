import React, { useEffect, useState } from "react";
import { executeAfter, formatNumber, formatPhoneNumber, hasPermission } from "utils/helpers";
import { useTranslation } from "react-i18next";
import customerDataService from "data-services/customer/customer-data.service";
import { FnbTable } from "components/fnb-table/fnb-table";
import { PermissionKeys } from "constants/permission-key.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { useHistory } from "react-router-dom";

export default function TableCustomerBySegmentComponent(props) {
  const [t] = useTranslation();
  const { segment } = props;
  const [segmentId, setSegmentId] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const history = useHistory();

  const pageData = {
    addNew: t("button.addNew"),
    table: {
      searchPlaceholder: t("customer.searchbyNamePhone"),
      no: t("customer.no"),
      name: t("customer.name"),
      phone: t("customer.phone"),
      rank: t("customer.rank"),
      point: t("customer.point"),
    },
  };

  const tableSettings = {
    page: currentPageNumber,
    pageSize: 10,
    columns: [
      {
        title: pageData.table.no,
        dataIndex: "index",
        key: "index",
        width: "5rem",
        render: (index) => index + 1,
      },
      {
        title: pageData.table.name,
        dataIndex: "name",
        key: "name",
        width: "30%",
        ellipsis: true,
        render: (val) => (
          <FnbTooltip onlyShowWhenEllipsis title={val}>
            <FnbTypography text={val} />
          </FnbTooltip>
        ),
      },
      {
        title: pageData.table.phone,
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "25%",
        render: (_, record) => {
          return <FnbTypography text={record?.phoneNumber ? formatPhoneNumber(record?.phoneCode, record?.phoneNumber) : "-"} />;
        },
      },
      {
        title: pageData.table.rank,
        dataIndex: "rank",
        key: "rank",
        width: "25%",
        ellipsis: true,
        render: (val) => {
          return <FnbTooltip onlyShowWhenEllipsis title={val}>
            <FnbTypography text={val} />
          </FnbTooltip>
        }
      },
      {
        title: pageData.table.point,
        dataIndex: "point",
        key: "point",
        width: "20%",
        render: (_, record) => <span>{record?.point}</span>,
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDataTableAsync(page, pageSize, "", segmentId);
    },
    onSearch: async (e) => {
      const keySearch = e.target.value;
      executeAfter(500, async () => {
        await fetchDataTableAsync(1, tableSettings.pageSize, keySearch, segmentId);
      });
    },
  };

  useEffect(() => {
    props.tableFuncs.current = onInitCustomerData;
    onInitCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInitCustomerData = async (data) => {
    setSegmentId(data ? data.id : segment.id);
    fetchDataTableAsync(currentPageNumber, tableSettings.pageSize, "", data ? data.id : segment.id);
  };

  const fetchDataTableAsync = async (pageNumber, pageSize, keySearch, segmentId) => {
    let responseData = await customerDataService.GetCustomersBySegmentAsync(pageNumber, pageSize, keySearch, segmentId);
    if (responseData) {
      const { customers, total, pageNumber } = responseData;
      const records = customers?.map((item) => mappingRecordToColumns(item));
      setDataSource(records);
      setTotalRecords(total);
      setCurrentPageNumber(pageNumber);
    }
  };

  const mappingRecordToColumns = (item) => {
    return {
      index: item?.no,
      id: item?.id,
      name: item?.fullName,
      phoneNumber: item?.phoneNumber,
      phoneCode: item?.phoneCode,
      rank: item?.rank ?? "-",
      point: item?.point ? formatNumber(item?.point) : "-",
    };
  };

  const onRowClick = (record, _index) => {
    if (hasPermission(PermissionKeys.VIEW_CUSTOMER)) {
      history.push(`/customer/detail/${record?.id}`);
    }
  };

  return (
    <FnbTable
      columns={tableSettings.columns}
      dataSource={dataSource}
      onChangePage={tableSettings.onChangePage}
      pageSize={tableSettings.pageSize}
      currentPageNumber={currentPageNumber}
      total={totalRecords}
      onRowClick={onRowClick}
    />
  );
}
