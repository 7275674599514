import { PermissionKeys } from "constants/permission-key.constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TableMaterial } from "./components/table-material.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import { ExportOutlinedIcon, ImportOutlinedIcon } from "constants/icons.constants";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import debounce from "lodash/debounce";
import FnbSuffixHeadingGuideline from "components/fnb-heading-guidline/fnb-suffix-heading-guideline.component";
import { GuidancePurposeType } from "constants/guidance-link.constants";

export function MaterialManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const { storeId } = props;
  const tableMaterialRef = React.useRef();
  const pageData = {
    materialManagement: t("material.materialManagement"),
    addNew: t("button.addNew"),
    import: t("button.import"),
    export: t("button.export"),
    filter: t("button.filter"),
    nameMaterial: t("material.name"),
    quantity: t("table.quantity"),
    unit: t("table.unit"),
    cost: t("table.cost"),
    sku: t("table.sku"),
    action: t("table.action"),
  };

  const handleExportMaterial = () => {
    if (tableMaterialRef && tableMaterialRef.current) {
      tableMaterialRef.current.exportFilter(storeId);
    };
  };

  return (
    <>
      <>
        <FnbHeadingPage
          title={pageData.materialManagement}
          permissionCreate={PermissionKeys.CREATE_MATERIAL}
          onCreate={() => history.push("/material/add-new")}
          listButtons={[
            <FnbButton
              permission={PermissionKeys.IMPORT_MATERIAL}
              onClick={() => history.push("/inventory/material/import")}
              text={pageData.import}
              iconHeader={<ImportOutlinedIcon width={20} height={20} />}
              variant='tertiary'
              className="bg-white"
            />,
            <FnbButton
              permission={PermissionKeys.EXPORT_MATERIAL}
              onClick={debounce(handleExportMaterial, 3000, { leading: true })}
              text={pageData.export}
              iconHeader={<ExportOutlinedIcon width={20} height={20} />}
              variant='tertiary'
              className="bg-white"
            />,
          ]}
          suffix={<FnbSuffixHeadingGuideline purposeType={GuidancePurposeType.Ingredient} />}
          isCompactButtons={false}
          onlyShowIconButtonInMobileMode={true}
        />
        <TableMaterial ref={tableMaterialRef} />
      </>
    </>
  );
}
