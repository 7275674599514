/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, Col, Form, message, Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbFroalaEditor from "components/fnb-froala-editor";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { DELAYED_TIME } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { currency } from "constants/string.constants";
import storeDataService from "data-services/store/store-data.service";
import React, { useEffect, useState } from "react";
import theme from "theme";
import {
  formatterDecimalNumber,
  formatNumberDecimalOrInteger,
  getCurrency,
  getValidationMessages,
  isDecimalKey,
  parserDecimalNumber,
} from "utils/helpers";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import { UploadLogoIcon } from "constants/icons.constants";
import "./update-membership.page.scss";

export default function EditCustomerMembershipPage(props) {
  const { t, membershipDataService, history } = props;
  const fnbImageSelectRef = React.useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const isVietnamese = getCurrency() === currency.vnd;

  const pageData = {
    title: t("membership.addNewForm.titleUpdate"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    leaveForm: t("messages.leaveForm"),
    confirmation: t("leaveDialog.confirmation"),
    confirmLeave: t("button.confirmLeave"),
    discard: t("button.discard"),
    name: t("membership.addNewForm.name"),
    accumulatedPoint: t("membership.addNewForm.accumulatedPoint"),
    description: t("membership.addNewForm.description"),
    discount: t("membership.addNewForm.discount"),
    maximumDiscount: t("membership.addNewForm.maximumDiscount"),
    nameValidation: t("membership.addNewForm.nameValidation"),
    accumulatedPointValidation: t("membership.addNewForm.accumulatedPointValidation"),
    discountValidation: t("membership.addNewForm.discountValidation"),
    maximumDiscountValidation: t("membership.addNewForm.maximumDiscountValidation"),
    maximumNameValidation: t("membership.addNewForm.maximumNameValidation"),
    uploadImage: t("productManagement.generalInformation.uploadImage"),
    percent: "%",
    membershipAddSuccess: t("membership.addNewForm.membershipAddSuccess"),
    membershipUpdateSuccess: t("membership.addNewForm.membershipUpdateSuccess"),
    descriptionMaximum: t("membership.addNewForm.descriptionMaximum"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      confirmLeave: t("leaveDialog.confirmLeave"),
    },
    validateRangeMessage: t("configuration.validateRangeMessage"),
    upload: {
      addFromUrl: t("material.addFromUrl"),
      uploadImage: t("material.addFile"),
    },
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    bestDisplayImage: t("messages.imageBestDisplay"),
  };

  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    getInitDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(selectedImage,'selectedImage')
  }, [selectedImage])
  const getInitDataAsync = async () => {
    const { membershipId } = props?.match?.params;
    if (membershipId) {
      membershipDataService.getMembershipByIdAsync(membershipId).then((response) => {
        if (response) {
          const { customerMembership } = response;

          form.setFieldsValue({
            ...customerMembership,
          });
          console.log("customerMembership?.thumbnail", customerMembership?.thumbnail);
          if (fnbImageSelectRef && fnbImageSelectRef.current && customerMembership?.thumbnail !== undefined) {
            fnbImageSelectRef.current.setImage(customerMembership?.thumbnail);
            setSelectedImage(customerMembership?.thumbnail);
          }
        }
      });
    }
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      let request = {
        ...values,
      };

      if (selectedImage) {
        request = {
          ...request,
          thumbnail: selectedImage.url,
        };
      }

      membershipDataService
        .updateMembershipAsync(request)
        .then((res) => {
          if (res) {
            navigateToManagementPage();
            message.success(pageData.membershipUpdateSuccess);
          }
        })
        .catch((errs) => {
          form.setFields(getValidationMessages(errs));
        });
    });
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      navigateToManagementPage();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const navigateToManagementPage = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/membership/management");
    }, DELAYED_TIME);
  };
  const onChangeImage = (file) => {
    setSelectedImage(file);
  };
  return (
    <>
      <FnbHeadingPage
        title={pageData.title.toLowerCase()}
        listButtons={[
          <FnbButton variant="tertiary" text={pageData.btnCancel} onClick={onCancel} />,
          <FnbButton
            text={pageData.btnSave}
            permission={PermissionKeys.EDIT_MEMBERSHIP_LEVEL}
            onClick={onFinish}
            minWidth={"120px"}
          />,
        ]}
      />
      <div className="clearfix"></div>
      <Card className="fnb-card-custom" style={{ padding: "12px 12px 24px 12px" }}>
        <Form
          className="custom-form"
          autoComplete="off"
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          onFieldsChange={() => {
            if (!isChangeForm) setIsChangeForm(true);
          }}
          form={form}
          layout="vertical"
        >
          <Content>
            <Card>
              <Row style={{ display: "grid" }}>
                <Row gutter={[24, 24]}>
                  <Col span={17}>
                    <Space direction="vertical" size={24} className="w-100">
                      <Form.Item
                        name={"name"}
                        rules={[
                          { required: true, message: pageData.nameValidation },
                          { max: 100, message: pageData.maximumNameValidation },
                        ]}
                        label={pageData.name}
                      >
                        <FnbInput maxLength={100} placeholder={pageData.namePlaceholder} />
                      </Form.Item>
                      <Form.Item
                        name={"accumulatedPoint"}
                        rules={[
                          {
                            required: true,
                            message: pageData.accumulatedPointValidation,
                          },
                          {
                            type: "number",
                            max: 1e12 - 1,
                            message: <InputValidateMessage message={pageData.accumulatedPointValidation} />,
                          },
                        ]}
                        label={pageData.accumulatedPoint}
                      >
                        <FnbInputNumber
                          formatter={(value) =>
                            isVietnamese
                              ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : formatterDecimalNumber(value, true)
                          }
                          parser={(value) =>
                            isVietnamese ? value.replace(/\$\s?|(,*)/g, "") : parserDecimalNumber(value, true)
                          }
                          onKeyPress={(event) => {
                            const checkKey = isVietnamese ? !/[0-9]/.test(event.key) : !isDecimalKey(event);
                            if (checkKey) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={7} className="w-100 edit-membership upload-product-image">
                    <Row className={`non-image ${selectedImage !== null && selectedImage !== undefined ? "have-image" : ""}`}>
                      <Col span={24} className={`image-product ${selectedImage !== null && selectedImage !== undefined ? "justify-left" : ""}`}>
                        <Form.Item name={"image"}>
                          <FnbCustomUploadImageComponent
                            ref={fnbImageSelectRef}
                            iconUpload={<UploadLogoIcon />}
                            buttonText={pageData.uploadImage}
                            onChange={onChangeImage}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="description" label={pageData.description}>
                      <FnbFroalaEditor
                        value={form.getFieldValue("description")}
                        onChange={(value) => {
                          form.setFieldValue("description", value);
                        }}
                        charCounterMax={-1}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 12]} style={{ marginTop: "24px" }}>
                  <Col span={12}>
                    <Form.Item
                      name={"discount"}
                      rules={[
                        {
                          type: "number",
                          min: 0,
                          max: 100,
                          message: <InputValidateMessage message={pageData.discountValidation} />,
                        },
                      ]}
                      label={pageData.discount}
                    >
                      <FnbInputNumber
                        className="w-100"
                        addonAfter={pageData.percent}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={"maximumDiscount"}
                      label={pageData.maximumDiscount}
                      rules={[
                        {
                          type: "number",
                          min: 1,
                          max: 1e12,
                          message: (
                            <InputValidateMessage
                              message={pageData.validateRangeMessage
                                .replace("$from$", 0)
                                .replace("$to$", formatNumberDecimalOrInteger(1e12))}
                            />
                          ),
                        },
                      ]}
                    >
                      <FnbInputNumber
                        className="w-100"
                        addonAfter={getCurrency()}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Content>
          <Form.Item name="id" hidden="true"></Form.Item>
        </Form>
      </Card>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discard}
        okText={pageData.leaveDialog.confirmLeave}
        onCancel={onDiscard}
        onOk={navigateToManagementPage}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
