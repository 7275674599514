import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Form, Input, Radio, Row, Space, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbSwitch from "components/switch";
import { MaximumNumber } from "constants/default.constants";
import { CalendarNewIconBold } from "constants/icons.constants";
import { OrderTypeConstantTranslate, OrderTypeConstants } from "constants/order-type-status.constants";
import { DateFormat, MaxCharacterForLongText, currency } from "constants/string.constants";
import feeDataService from "data-services/fee/fee-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { formatNumberDecimalOrInteger, formatTextRemoveComma, getCurrency, momentFormatDateTime, checkOnKeyPressValidation } from "utils/helpers";
import "./create-new-fee.component.scss";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";

export default function AddNewFeeComponent(props) {
  const [t] = useTranslation();
  const { isModalVisible, listBranch, onCancel, listFee } = props;
  const [form] = Form.useForm();
  const [isAllBranches, setIsAllBranches] = useState(false);
  const [isPercent, setIsPercent] = useState(true);
  const [startDate, setStartDate] = useState(moment(new Date(), DateFormat));
  const [endDate, setEndDate] = useState(null);
  const [isAutoApplied, setIsAutoApplied] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const pageData = {
    titleAddNewFee: t("feeAndTax.createFee.titleAddNewFee"),
    feeName: t("feeAndTax.createFee.feeName"),
    maxLengthFeeName: 255,
    pleaseEnterFeeName: t("feeAndTax.createFee.pleaseEnterFeeName"),
    enterFeeName: t("feeAndTax.createFee.enterFeeName"),
    percent: "%",
    min: 1,
    maxPercent: 100,
    max: MaximumNumber,
    enterPercentFeeValue: t("feeAndTax.createFee.enterPrecentFeeValue"),
    enterAmountFeeValue: t("feeAndTax.createFee.enterAmountFeeValue"),
    pleaseEnterAmount: t("feeAndTax.createFee.pleaseEnterAmount"),
    pleaseSelectServingTypes: t("feeAndTax.createFee.pleaseSelectServingType"),
    branchName: t("feeAndTax.createFee.branchName"),
    allBranches: t("feeAndTax.createFee.allBranches"),
    selectBranch: t("feeAndTax.createFee.selectBranch"),
    pleaseSelectBranch: t("feeAndTax.createFee.pleaseSelectBranch"),
    description: t("feeAndTax.createFee.description"),
    descriptionMaximum: 1000,
    cancel: t("button.cancel"),
    addNew: t("button.addNew"),
    startDate: t("feeAndTax.createFee.startDate"),
    endDate: t("feeAndTax.createFee.endDate"),
    percentValidation: t("feeAndTax.createFee.percentValidation"),
    enterOnlyNumbers: t("feeAndTax.createFee.pleaseEnterOnlyNumbers"),
    titleFeeValue: t("feeAndTax.table.value"),
    valueValidation: t("feeAndTax.createFee.valueValidation"),
    pleaseStartDate: t("feeAndTax.createFee.pleaseStartDate"),
    orderTypeNames: Object.keys(OrderTypeConstants),
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    autoApplied: t("feeAndTax.autoApplied"),
    servingTypes: t("feeAndTax.createFee.servingTypes"),
    createFeeSuccess: t("feeAndTax.createFee.createFeeSuccess"),
    feeNameExisted: t("feeAndTax.createFee.feeNameExisted"),
    tooltipAutoApplied: t(
      "feeAndTax.createFee.tooltipAutoApplied",
      "<span>This fee will be applied automatically when created the order.</span><br/><span>Otherwise, staff must select manually</span>",
    ),
  };

  useEffect(() => {
    setInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const servingTypeOptions = pageData.orderTypeNames
    .filter((key) => OrderTypeConstants[key] !== OrderTypeConstants.Online)
    .map((name) => {
      return {
        label: <FnbTypography variant={theme.typography["b1-regular"]} text={t(OrderTypeConstantTranslate[name])} />,
        value: OrderTypeConstants[name],
      };
    });

  const setInitData = () => {
    form.setFieldsValue({
      fee: {
        name: "",
        isPercentage: true,
        isShowAllBranches: false,
        isAutoApplied: false,
        StartDate: moment(new Date(), DateFormat),
        servingTypes: [OrderTypeConstants.InStore],
      },
    });
    setIsChangeForm(false);
  };

  const onFinish = () => {
    if (isAdding) return;
    let formValues = form.getFieldsValue();
    formValues.fee.isAutoApplied = isAutoApplied;
    if (validFormBeforeSubmit(formValues)) {
      setIsAdding(true);
      // save data
      if (formValues?.fee?.StartDate) {
        formValues.fee.StartDate = momentFormatDateTime(formValues.fee.StartDate);
      }

      if (formValues?.fee?.EndDate) {
        formValues.fee.EndDate = momentFormatDateTime(formValues.fee.EndDate);
      }

      feeDataService
        .createFeeManagementAsync(formValues.fee)
        .then((res) => {
          if (res) {
            message.success(pageData.createFeeSuccess);
            form.resetFields();
            setIsAllBranches(false);
            setIsAutoApplied(false);
            onCancel();
            setIsPercent(true);
            setIsAdding(false);
          }
        })
        .catch((err) => {
          setIsAdding(false);
        });
    } else {
      form.validateFields();
    }
  };

  const onChangeCheckBoxAllBranches = (e) => {
    setIsAllBranches(e.target.checked);
    form.validateFields();
  };

  const onSelectBranches = (branchesSelected) => {
    if (branchesSelected.length === listBranch.length) {
      form.setFieldsValue({
        fee: {
          isShowAllBranches: true,
          feeBranchIds: [],
        },
      });
      setIsAllBranches(true);
    }
  };

  const handleCancel = async () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      await form.resetFields();
      onCancel();
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const disabledDateByStartDate = (current) => {
    // Can not select days before today and today
    return current && current < startDate;
  };

  /** This function is used to check the string is numeric.
   * @param  {string} value The value for example, '1234'
   */
  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  /** This function is fired when the group is changed, the fee can be a percent or a price number.
   * @param  {event} e The event will be passed when the input is changed.
   */
  const onChangeRadioGroup = (e) => {
    setIsPercent(e.target.value);

    form.setFields([
      {
        name: ["fee", "value"],
        value: null,
        errors: [],
      },
    ]);
  };

  const handleInputChange = (e) => {
    if (!isNaN(e)) {
      form.setFieldsValue({
        fee: {
          value: e,
        },
      });
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
    setIsPercent(true);
    onCancel();
    form.resetFields();
  };

  const onChangeStartDate = (date) => {
    // if clear start date => clear end date
    if (date === null || date === undefined) {
      form.setFieldsValue({
        fee: {
          EndDate: null,
        },
      });
      setEndDate(null);
      setStartDate(null);
      return;
    }

    // if start date > end date => start date = end date
    if (date !== null && date !== undefined) {
      if (endDate !== null && endDate !== undefined) {
        if (date.toDate() > endDate.toDate()) {
          form.setFieldsValue({
            fee: {
              StartDate: endDate,
            },
          });
          setStartDate(endDate);
          return;
        }
      }
    }

    setStartDate(date);
  };

  // #region Validate From Submit

  const validFormBeforeSubmit = (formValues) => {
    return (
      isValidateName(formValues) &&
      isValidateValue(formValues) &&
      isValidateStartEndDate(formValues) &&
      isValidateServingType(formValues) &&
      isValidateBranch(formValues)
    );
  };

  const isValidateName = (formValues) => {
    return formValues.fee.name !== undefined && formValues.fee.name !== "";
  };

  const isValidateValue = (formValues) => {
    if (formValues.fee.value === "" || formValues.fee.value === undefined || formValues.fee.value === null) {
      return false;
    }

    if (formValues.fee.isPercentage && (formValues.fee.value > 100 || formValues.fee.value < 0)) {
      return false;
    }

    return true;
  };

  const isValidateStartEndDate = (formValues) => {
    let startDate = formValues.fee.StartDate;
    if (startDate === null || startDate === undefined) {
      return false;
    }
    return true;
  };

  const isValidateServingType = (formValues) => {
    let servingTypes = formValues.fee.servingTypes;
    return servingTypes !== undefined && servingTypes !== null && servingTypes.length > 0;
  };

  const isValidateBranch = (formValues) => {
    let isShowAllBranches = formValues.fee.isShowAllBranches;
    let feeBranchIds = formValues.fee.feeBranchIds;
    return !(isShowAllBranches === false && (feeBranchIds === undefined || feeBranchIds.length === 0));
  };

  // #endregion Validate From Submit

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  const renderContent = () => {
    return (
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        onFieldsChange={() => {
          if (!isChangeForm) setIsChangeForm(true);
        }}
        className="custom-form form-create-new-fee"
        layout="vertical"
      >
        <Space direction="vertical" size={24} className="w-100">
          {/* NAME */}
          <Row>
            <Col span={24}>
              <Form.Item
                name={["fee", "name"]}
                rules={[
                  {
                    required: true,
                    message: pageData.pleaseEnterFeeName,
                  },
                  { type: "string", max: pageData.maxLengthFeeName },
                  {
                    validator: (_, value) => {
                      if (!listFee.find((item) => item.name === value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error(pageData.feeNameExisted));
                      }
                    },
                  },
                ]}
                label={pageData.feeName}
              >
                <FnbInput showCount maxLength={pageData.maxLengthFeeName} placeholder={pageData.enterFeeName} />
              </Form.Item>
            </Col>
          </Row>

          {/* VALUE */}
          <div>
            <Row>
              <Col span={24}>
                <Input.Group size="large">
                  {/* % / CURRENCY (Ex: VND) */}
                  {isPercent ? (
                    <Form.Item
                      name={["fee", "value"]}
                      rules={[
                        { required: true, message: pageData.percentValidation },
                        {
                          min: 0,
                          max: 100,
                          type: "integer",
                          message: pageData.percentValidation,
                        },
                        {
                          validator: (_, value) => {
                            if (value === undefined || (value !== undefined && value <= 100)) {
                              return Promise.resolve();
                            } else {
                              if (value?.length > 0 && !isNumeric(value)) {
                                return Promise.reject(new Error(pageData.enterOnlyNumbers));
                              } else {
                                return Promise.reject(new Error(pageData.percentValidation));
                              }
                            }
                          },
                        },
                      ]}
                      label={pageData.titleFeeValue}
                    >
                      <FnbInputNumber
                        className="w-100 discount-amount fnb-input-number-with-suffix-currency"
                        formatter={(value) => value}
                        parser={(value) => formatTextRemoveComma(value)}
                        placeholder={pageData.enterAmountFeeValue}
                        addonAfter={
                          <Form.Item name={["fee", "isPercentage"]} style={{ display: "contents" }}>
                            <Radio.Group
                              className="radio-group-discount"
                              initialValues={isPercent}
                              onChange={(e) => onChangeRadioGroup(e)}
                            >
                              <Radio.Button value={true} className="percent-option">
                                {pageData.percent}
                              </Radio.Button>
                              <Radio.Button value={false} className="currency-option">
                                {getCurrency()}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        }
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name={["fee", "value"]}
                      rules={[
                        {
                          required: true,
                          message: pageData.valueValidation,
                        },
                        () => ({
                          validator(_, value) {
                            if (value && (value <= 0 || value >= 1e9)) {
                              return Promise.reject(
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.valueValidation}
                                />,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      label={pageData.titleFeeValue}
                    >
                      <FnbInputNumber
                        id={"fee.value"}
                        className="w-100 discount-amount fnb-input-number-with-suffix-currency"
                        formatter={(value) => value}
                        parser={(value) => formatTextRemoveComma(value)}
                        min={getCurrency() === currency.vnd ? pageData.min : 0}
                        max={getCurrency() === currency.vnd ? pageData.max : null}
                        onKeyPress={(event) => {
                          const checkStatus = checkOnKeyPressValidation(
                            event,
                            "fee.value",
                            0,
                            null,
                            getCurrency() === currency.vnd ? 0 : 2,
                          );
                          if (!checkStatus) event.preventDefault();
                        }}
                        onChange={handleInputChange}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                        placeholder={pageData.enterAmountFeeValue}
                        addonAfter={
                          <Form.Item name={["fee", "isPercentage"]} style={{ display: "contents" }}>
                            <Radio.Group
                              className="radio-group-discount"
                              initialValues={isPercent}
                              onChange={(e) => onChangeRadioGroup(e)}
                            >
                              <Radio.Button value={true} className="percent-option">
                                {pageData.percent}
                              </Radio.Button>
                              <Radio.Button value={false} className="currency-option">
                                {getCurrency()}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        }
                      />
                    </Form.Item>
                  )}
                </Input.Group>
              </Col>
            </Row>
          </div>

          {/* START/END DATE */}
          <Row gutter={[32, 16]}>
            {/* START DATE */}
            <Col xs={24} lg={12}>
              <Form.Item
                name={["fee", "StartDate"]}
                rules={[
                  {
                    required: true,
                    message: pageData.pleaseStartDate,
                  },
                ]}
                label={pageData.startDate}
              >
                <DatePicker
                  required={true}
                  suffixIcon={<CalendarNewIconBold />}
                  placeholder={pageData.selectDate}
                  className="fnb-date-picker w-100 dtp-start-date"
                  disabledDate={disabledDate}
                  format={DateFormat.DD_MM_YYYY}
                  onChange={(date) => onChangeStartDate(date)}
                  initialValues={moment(new Date(), DateFormat)}
                />
              </Form.Item>
            </Col>

            {/* END DATE */}
            <Col xs={24} lg={12}>
              <Form.Item name={["fee", "EndDate"]} label={pageData.endDate}>
                <DatePicker
                  suffixIcon={<CalendarNewIconBold />}
                  placeholder={pageData.selectDate}
                  className="fnb-date-picker w-100"
                  disabledDate={disabledDateByStartDate}
                  format={DateFormat.DD_MM_YYYY}
                  disabled={startDate ? false : true}
                  onChange={(date) => setEndDate(date)}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* AUTO APPLIED */}
          <Space wrap>
            <Form.Item name={["fee", "IsAutoApplied"]}>
              <FnbSwitch initialValues={isAutoApplied} onChange={(checked) => setIsAutoApplied(checked)} />
            </Form.Item>
            <Space>
              <FnbTypography text={pageData.autoApplied} />
              <FnbTooltip
                placement="topLeft"
                title={
                  <FnbTypography
                    color={theme.colors.default.white}
                    variant={theme.typography["b2-regular"]}
                    text={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: pageData.tooltipAutoApplied,
                        }}
                      ></span>
                    }
                  />
                }
              >
                <FnbTypography variant={theme.typography["h3-regular"]} text={<ExclamationCircleOutlined />} />
              </FnbTooltip>
            </Space>
          </Space>

          {/* SERVING TYPE */}
          <Row style={{ marginTop: "-8px" }}>
            <Col className="w-100">
              <div className="servingTypes__container">
                <Form.Item
                  name={["fee", "servingTypes"]}
                  rules={[{ required: true, message: pageData.pleaseSelectServingTypes }]}
                  label={pageData.servingTypes}
                >
                  <FnbCheckBox.Group options={servingTypeOptions} direction="horizontal" rowGap={8} />
                </Form.Item>
                {/* {renderServingType()} */}
              </div>
            </Col>
          </Row>

          {/* BRANCH */}
          <Space direction="vertical" className="w-100" size={8}>
            <Row className="flex-space-between">
              <Col>
                <FnbTypography variant={theme.typography["b1-medium"]} text={pageData.branchName} />
                <FnbTypography variant={theme.typography["b1-medium"]} color={theme.colors.critical[80]} text="*" />
              </Col>
              <Col>
                <Form.Item name={["fee", "isShowAllBranches"]} valuePropName="checked" noStyle>
                  <FnbCheckBox className="w-100" onChange={onChangeCheckBoxAllBranches}>
                    {pageData.allBranches}
                  </FnbCheckBox>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Form.Item
                className="w-100"
                name={["fee", "feeBranchIds"]}
                rules={[
                  {
                    required: !isAllBranches,
                    message: pageData.pleaseSelectBranch,
                  },
                ]}
              >
                <FnbSelectAddNewItem
                  mode="multiple"
                  placeholder={pageData.selectBranch}
                  allowClear
                  disabled={isAllBranches}
                  onChange={(value) => onSelectBranches(value)}
                  options={listBranch?.map((item) => ({
                    value: item.id,
                    label:
                      item.name.length > MaxCharacterForLongText
                        ? item.name.substring(0, MaxCharacterForLongText) + "..."
                        : item.name,
                  }))}
                  fieldItemName={pageData.branchName.toLowerCase()}
                  onAddNewItem={onAddNewBranch}
                />
              </Form.Item>
            </Row>
          </Space>

          {/* DESCRIPTION */}
          <Row>
            <Col span={24}>
              <h4 className="fnb-form-label">{pageData.description}</h4>
              <Form.Item name={["fee", "description"]}>
                <FnbTextArea size="large" rows={1} showCount maxLength={pageData.descriptionMaximum} />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
    );
  };

  return (
    <>
      <FnbModal
        width={"800px"}
        visible={isModalVisible}
        title={pageData.titleAddNewFee}
        handleCancel={handleCancel}
        content={renderContent()}
        closable={false}
        footer={[
          <Space>
            <FnbButton variant="tertiary" text={pageData.cancel} onClick={handleCancel} minWidth={"120px"} />
            <FnbButton text={pageData.addNew} onClick={onFinish} minWidth={"120px"} />
          </Space>,
        ]}
      />
      <ConfirmDialogComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={() => setShowConfirm(false)}
        onOk={onDiscard}
      />
    </>
  );
}
