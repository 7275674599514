import { Form, Row, Typography, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingleIcon } from "components/fnb-select-single-icon/fnb-select-single-icon";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbSwitch from "components/switch";
import { InfoIconV2 } from "constants/icons.constants";
import { paymentMethod } from "constants/payment-method.constants";
import { payPalStoreType } from "constants/paypal-store-type.constants";
import { currency } from "constants/string.constants";
import commonDataService from "data-services/common/common-data.service";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import { useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { useTranslation } from "react-i18next";
import { getCurrency } from "utils/helpers";
import i18n from "utils/i18n";
import "./paypal-payment-config.component.scss";

const PayPalPaymentConfig = (props) => {
  const { onCompleted, initData, onChangeForm, IsUnActivated, setIsValidPayPal } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [countryOption, setCountryOption] = useState([]);
  const [payPalPaymentData, setPayPalPaymentData] = useState();
  const [isBusinessStoreType, setIsBusinessStoreType] = useState(true);
  const storeCurrency = getCurrency();
  const [isNotValidData, setIsNotValidData] = useState(false);

  const pageData = {
    saveSuccessfully: t("payPalSettings.saveSuccessfully"),
    btnCancel: t("button.cancel"),
    btnUpdate: t("button.update"),
    exchangeRate: t("payPalSettings.exchangeRate"),
    exchangeRateDescription: t("payPalSettings.exchangeRateDescription"),
    pleaseEnterExchangeRate: t("payPalSettings.pleaseEnterExchangeRate"),
    enterExchangeRate: t("payPalSettings.enterExchangeRate"),
    businessAreaDescription: t("payPalSettings.businessAreaDescription"),
    country: t("payPalSettings.country"),
    countrySelectDescription: t("payPalSettings.countrySelectDescription"),
    pleaseSelectCountry: t("payPalSettings.pleaseSelectCountry"),
    selectCountry: t("payPalSettings.selectCountry"),
    pleaseEnterClientId: t("payPalSettings.pleaseEnterClientId"),
    enterClientID: t("payPalSettings.enterClientID"),
    pleaseEnterSecretKey: t("payPalSettings.pleaseEnterSecretKey"),
    enterSecretKey: t("payPalSettings.enterSecretKey"),
    comingSoon: t("payPalSettings.comingSoon"),
    personalAreaDescription: t("payPalSettings.personalAreaDescription"),
  };

  useEffect(() => {
    const fetchData = async () => {
      await initDataGeneralInfo();
    };
    handleSetFormData();
    fetchData();
  }, []);

  useEffect(() => {
    if (!IsUnActivated) form.validateFields();
  }, [t]);

  useEffect(() => {
    onChangeForm(isChangeForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeForm]);

  useEffect(() => {
    if (!IsUnActivated && isNotValidData) {
      setIsChangeForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsUnActivated]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const initDataGeneralInfo = async () => {
    const response = await commonDataService.getCountryCodesAsync();
    countryOptionSelector(response?.countries);
  };

  const handleSetFormData = () => {
    var defaultData = initData?.paymentConfigs[0];
    if (defaultData) {
      setPayPalPaymentData(defaultData);
      form.setFieldsValue({
        exchangeRate: defaultData.exchangeRate,
        fromCurrency: defaultData.fromCurrency,
        targetCurrency: defaultData.targetCurrency,
        clientId: defaultData.clientId,
        secretKey: defaultData.secretKey,
        countryId: defaultData.countryId,
      });
      if (!(defaultData.exchangeRate && defaultData.countryId && defaultData.clientId && defaultData.secretKey)) {
        if (defaultData.isActivated) {
          setIsChangeForm(true);
          setIsValidPayPal(false);
        }
        setIsNotValidData(true);
        setIsValidPayPal(false);
      }
    }
  };

  const onValidForm = (isValid = true) => {
    if (isValid) {
      form
        .validateFields()
        .then((_) => {
          setDisableSaveButton(false);
        })
        .catch((_) => {
          setDisableSaveButton(true);
        });
    }
  };

  const onCancel = () => {
    setIsChangeForm(false);
    handleSetFormData();
  };

  const countryOptionSelector = (countries) => {
    let result = countries?.map((country) => {
      return {
        label: (
          <Row className="col-country">
            <CircleFlag className="circle-icon" countryCode={country?.iso?.toLowerCase()} height={20} />
            <div className="country-name">{t(country?.nicename)}</div>
          </Row>
        ),
        value: country?.id,
        labelSearch: country?.nicename,
      };
    });
    setCountryOption(result);
  };

  const updatePaymentDetail = () => {
    form
      .validateFields()
      .then((_) => {
        let valuesForm = form.getFieldsValue();
        valuesForm = {
          ...valuesForm,
          storeType: isBusinessStoreType ? payPalStoreType.business : payPalStoreType.personal,
          EnumId: paymentMethod.PayPal,
          paymentMethodId: payPalPaymentData?.paymentMethodId,
        };
        paymentConfigDataService.updatePaymentConfigAsync(valuesForm).then((response) => {
          const { success, errorMessage } = response;
          if (success === true) {
            message.success(pageData.saveSuccessfully);
            setIsChangeForm(false);
            onCompleted();
          } else {
            message.error(t(errorMessage));
          }
        });
      })
      .catch((_) => {
        setDisableSaveButton(true);
      });
  };

  const onFormChanged = (changedValues, value) => {
    setIsChangeForm(true);
  };

  return (
    <Form className="paypal-payment-config-form" form={form} onValuesChange={onFormChanged}>
      <div className="btn-action-area">
        {isChangeForm && (
          <>
            <FnbButton
              disabled={IsUnActivated}
              minWidth={120}
              text={pageData.btnCancel}
              variant="tertiary"
              onClick={onCancel}
            />
            <FnbButton
              minWidth={120}
              disabled={disableSaveButton || IsUnActivated}
              text={pageData.btnUpdate}
              onClick={updatePaymentDetail}
            />
          </>
        )}
      </div>
      <div className="exchange-rate-area">
        <div className="title">
          <Typography>
            {pageData.exchangeRate}
            <span className="highlight">*</span>
          </Typography>
          <FnbTooltip placement="top" title={pageData.exchangeRateDescription}>
            <InfoIconV2 />
          </FnbTooltip>
        </div>
        <div className="exchange-rate-input">
          <Typography>1 {payPalPaymentData?.fromCurrency ?? currency.usd}</Typography>
          <Typography>=</Typography>
          <Form.Item
            className="exchange-rate-input-container"
            name={"exchangeRate"}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseEnterExchangeRate} />,
              },
            ]}
          >
            <FnbInputNumber
              placeholder={pageData.enterExchangeRate}
              onChange={onValidForm}
              disabled={IsUnActivated}
              type={"number"}
              style={{ width: "100%" }}
              precision={2}
            />
          </Form.Item>
          <Typography>{storeCurrency ?? currency.vnd}</Typography>
        </div>
      </div>
      <div className="paypal-business-area">
        <div className="paypal-business-area-header">
          <div>
            <FnbSwitch checked={isBusinessStoreType} onChange={() => setIsBusinessStoreType(true)} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <Typography className="paypal-business-area-title">PayPal Business</Typography>
            <Typography className="paypal-business-area-description">{pageData.businessAreaDescription}</Typography>
          </div>
        </div>
        <div className="paypal-business-area-body">
          <div className="body-item">
            <Form.Item
              label={
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography>{pageData.country}</Typography>
                  <span className="highlight">*</span>
                  <FnbTooltip placement="top" title={pageData.countrySelectDescription}>
                    <InfoIconV2 />
                  </FnbTooltip>
                </div>
              }
              name={"countryId"}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage message={pageData.pleaseSelectCountry} />,
                },
              ]}
            >
              <FnbSelectSingleIcon
                placeholder={pageData.selectCountry}
                onChange={onValidForm}
                disabled={IsUnActivated}
                option={countryOption}
              />
            </Form.Item>
          </div>
          <div className="body-item">
            <Form.Item
              label={
                <Typography>
                  Client ID<span className="highlight">*</span>
                </Typography>
              }
              name={"clientId"}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage message={pageData.pleaseEnterClientId} />,
                },
              ]}
            >
              <FnbInput
                placeholder={pageData.enterClientID}
                onChange={onValidForm}
                disabled={IsUnActivated}
                maxLength={100}
              />
            </Form.Item>
          </div>
          <div className="body-item">
            <Form.Item
              label={
                <Typography>
                  Secret Key<span className="highlight">*</span>
                </Typography>
              }
              name={"secretKey"}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage message={pageData.pleaseEnterSecretKey} />,
                },
              ]}
            >
              <FnbInput
                placeholder={pageData.enterSecretKey}
                onChange={onValidForm}
                disabled={IsUnActivated}
                maxLength={100}
                type="password"
                autoComplete="new-password"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="paypal-personal-area">
        <div className="paypal-personal-area-header">
          <div>
            <FnbSwitch checked={false} />
          </div>
          <div className="paypal-personal-area-body">
            <div className="paypal-personal-area-body-container">
              <Typography className="paypal-personal-area-title">PayPal Personal</Typography>
              <div className="coming-soon-text-area">
                <Typography className="coming-soon-text">{pageData.comingSoon}</Typography>
              </div>
            </div>
            <Typography className="paypal-personal-area-description">{pageData.personalAreaDescription}</Typography>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PayPalPaymentConfig;
