import { Card, Col, Form, message, Row, Typography } from "antd";
import { FnbSelectSingleOptGroup } from "components/fnb-select-single-opt-group/fnb-select-single-opt-group";
import { StampType, StampName } from "constants/stamp-type.constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StampTemplateComponent } from "./components/stamp-template.component";
import "./index.scss";
import { PrinterSettingType } from "constants/store-setting.constants";
import { CustomDownIcon, PrinterOutlineIcon } from "constants/icons.constants";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbButton } from "components/fnb-button/fnb-button";

const { Text } = Typography;

export default function StampConfig(props) {
  const { t, stampDataService, onChangeForm, activeTab } = props;

  const pageData = {
    stamp: t("stamp.stamp"),
    stampTemplate: t("stamp.stampTemplate"),
    stampType: t("stamp.stampType"),
    showLogo: t("stamp.showLogo"),
    showTime: t("stamp.showTime"),
    showNumberOfItem: t("stamp.showNumberOfItem"),
    showItemNote: t("stamp.showItemNote"),
    isUpdatedSuccessfully: t("messages.isUpdatedSuccessfully"),
    save: t("button.saveChanges"),
    btnPrintPreview: t("button.printPreview"),
    oneStamp: t("stamp.oneStamp"),
    twoStamp: t("stamp.twoStamp"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    btnCancel: t("button.cancel"),
    preview: t("stamp.preview"),
    selectOptionDescription: t("stamp.selectOptionDescription"),
    showArea: t("stamp.showArea"),
    showAreaTable: t("stamp.showAreaTable"),
  };

  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [stampTypeList, setStampTypeList] = useState([]);
  const stampReviewRef = React.useRef(null);
  const [stampConfig, setStampConfig] = useState({});
  const [stampData, setStampData] = useState({});
  const storeLogoUrl = useSelector((state) => state?.session?.storeLogo);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (activeTab === PrinterSettingType.STAMP) {
      getInitialData();
    } else {
      form.resetFields();
      setIsChangeForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const createStampMockupData = () => {
    const stampData = {
      code: "#I1003",
      logo: storeLogoUrl,
      createdTime: "2022-07-06 10:03:41.6983432",
      areaName: "VIP",
      areaTableName: "V01",
      itemList: [
        {
          no: "1",
          name: "Coffee",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
            {
              name: "Ice",
              value: "50%",
            },
            {
              name: "Pudding",
              value: "x122",
            },
          ],
          current: true,
        },
        {
          no: "2",
          name: "Milk tea",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
          ],
          current: false,
        },
      ],
    };

    return stampData;
  };

  const getInitialData = async () => {
    const stampData = createStampMockupData();
    setStampData(stampData);
    let res = await stampDataService.getStampConfigByStoreIdAsync();
    if (res) {
      setStampTypeList(res.stampTypeList);
      let formValue = undefined;
      if (res.stampConfig !== undefined) {
        let data = res?.stampConfig;
        formValue = {
          stampType: data?.stampType,
          isShowLogo: data?.isShowLogo,
          isShowTime: data?.isShowTime,
          isShowNumberOfItem: data?.isShowNumberOfItem,
          isShowNote: data?.isShowNote,
          isShowArea: data?.isShowArea,
          isShowAreaTable: data?.isShowAreaTable,
        };

        setStampConfig(formValue);
        form.setFieldsValue(formValue);
      } else {
        formValue = {
          stampType: StampType.mm50x30,
          isShowLogo: true,
          isShowTime: true,
          isShowNumberOfItem: true,
          isShowNote: true,
          isShowArea: true,
          isShowAreaTable: true
        };

        setStampConfig(formValue);
        form.setFieldsValue(formValue);
      }
      stampData.logo = storeLogoUrl;

      if (stampReviewRef && stampReviewRef.current) {
        stampReviewRef.current.render(formValue, stampData);
      }
    }
  };

  const onStampTypeChange = (value) => {
    setStampConfig({ ...stampConfig, stampType: value });
  };

  const onChangeStampConfig = () => {
    if (!isChangeForm) setIsChangeForm(true);

    const newStampConfig = form.getFieldsValue();
    setStampConfig({ ...stampConfig, ...newStampConfig });
    if (stampReviewRef && stampReviewRef.current) {
      stampReviewRef.current.render(newStampConfig, stampData);
    }
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      stampDataService.updateStampConfigByStoreIdAsync(values).then((res) => {
        if (res) {
          onChangeForm(false);
          message.success(`${pageData.stamp} ${pageData.isUpdatedSuccessfully}`);
          setIsChangeForm(false);
        }
      });
    });
  };

  const printTemplate = () => {
    if (stampReviewRef && stampReviewRef.current) {
      stampReviewRef.current.print();
    }
  };

  const onFieldsChange = () => {
    onChangeForm(true);
    onChangeStampConfig();
    setIsChangeForm(true);
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onOK = () => {
    setIsChangeForm(false);
  };

  const cancelChange = () => {
    onChangeForm(false);
    setIsChangeForm(false);
    getInitialData();
  };

  return (
    <>
      <Card
        className="stamp-card fnb-card-full item-card-printer-item"
        extra={
          <div className="float-right group-button-item-printer">
            <FnbButton
              iconHeader={<PrinterOutlineIcon />}
              onClick={printTemplate}
              text={pageData.btnPrintPreview}
              variant="tertiary"
            />
            {isChangeForm && (
              <div className="action-button">
                <FnbButton minWidth={100} onClick={cancelChange} variant="tertiary" text={pageData.btnCancel} />
                <FnbButton minWidth={100} onClick={() => onFinish()} text={pageData.save} />
              </div>
            )}
          </div>
        }
      >
        <Form
          form={form}
          name="basic"
          onValuesChange={onFieldsChange}
          autoComplete="off"
          gutter={12}
          className="stamp-form"
        >
          <Row>
            <Col xl={17} lg={12} md={12} sm={24} span={24} className="border-div">
              <h4 className="fnb-form-label">
                {pageData.stampType} <span className="require-symbol">*</span>
              </h4>
              <Form.Item name="stampType">
                <FnbSelectSingleOptGroup
                  suffixIcon={<CustomDownIcon></CustomDownIcon>}
                  className="item-input"
                  optionGroup={stampTypeList?.map((item) => ({
                    id: item.id,
                    name:
                      item.name === StampName.mm35x22
                        ? pageData.twoStamp + " | " + item.name
                        : pageData.oneStamp + " | " + item.name,
                    group: item.name === StampName.mm35x22 ? pageData.twoStamp + ":" : pageData.oneStamp + ":",
                  }))}
                  onChange={onStampTypeChange}
                  showSearch
                />
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Typography className="select-option-description">{pageData.selectOptionDescription}</Typography>
                </Col>
              </Row>
              <Form.Item name="isShowLogo" valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.showLogo}</Text>
                </FnbCheckBox>
              </Form.Item>
              <Form.Item name="isShowTime" valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.showTime} (HH:MM)</Text>
                </FnbCheckBox>
              </Form.Item>
              <Form.Item name="isShowNumberOfItem" valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.showNumberOfItem}</Text>
                </FnbCheckBox>
              </Form.Item>
              <Form.Item name="isShowNote" valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.showItemNote}</Text>
                </FnbCheckBox>
              </Form.Item>
              <Form.Item name="isShowArea" valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.showArea}</Text>
                </FnbCheckBox>
              </Form.Item>
              <Form.Item name="isShowAreaTable" valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.showAreaTable}</Text>
                </FnbCheckBox>
              </Form.Item>
            </Col>
            <Col xl={7} lg={12} md={12} sm={24} span={24} className="div-vertically">
              <p className="preview-text">{pageData.preview}</p>
              <StampTemplateComponent ref={stampReviewRef} />
            </Col>
          </Row>
        </Form>
        <DeleteConfirmComponent
          title={pageData.leaveDialog.confirmation}
          content={pageData.leaveDialog.content}
          visible={showConfirm}
          skipPermission={true}
          cancelText={pageData.discardBtn}
          okText={pageData.confirmLeaveBtn}
          onCancel={onDiscard}
          onOk={onOK}
          isChangeForm={isChangeForm}
        />
      </Card>
    </>
  );
}
