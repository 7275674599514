import styled from "styled-components";

// All property
// styles={{
//   paidAndUnPaidColor: "#FFF",
//   paidAndUnPaidCurrencyColor: "#FFF",
//   paidAndUnPaidColor: "#FFF",
//   backgroundTotalColor: "#FFFFFF",
//   sectionTotalTextColor: "#50429B",
//   sectionTotalTextCurrencyColor: "#7E66FF",
//   sectionAvgBackgroundColor: "#E1DBFF",
//   sectionAvgTextColor: "#50429B",
//   sectionAvgCurrencyTextColor: "#7E66FF",
// }}
const FnbWidgetStyle = styled.div`
  .summary-card-average {
    background: ${(props) => props?.styles?.backgroundTotalColor || "#50429B"};

    //Total
    .section-total,
    .section-average {
      .ant-row:nth-child(1):not(.acceleration-card .ant-row) {
        .ant-col:nth-child(3) {
          .acceleration-card {
            .ant-row {
              .decrease {
                color: #db1b1b;
              }

              .increase {
                color: #33b530;
              }
            }
          }

          .acceleration-card.increase {
            background-color: #e7f9e7;
            color: #33b530;
          }

          .acceleration-card.decrease {
            background-color: #fff7f7;
            color: #db1b1b;
          }
        }
      }

      .ant-row:nth-child(3) {
        .summary-suffix {
        }
      }
    }

    .section-total {
      .summary-paid {
        .ant-row {
          .ant-col {
            &:first-child {
              p {
                color: ${(props) => props?.styles?.paidAndUnPaidColor || "#fff"};
              }
            }

            &:last-child {
              p:first-child {
                color: ${(props) => props?.styles?.paidAndUnPaidColor || "#fff"};
              }
              p:last-child {
                color: ${(props) => props?.styles?.paidAndUnPaidCurrencyColor || "#AFA1FF"};
              }
            }
          }
        }
      }
      .title {
        color: ${(props) => props?.styles?.sectionTotalTextColor || "#fff"};
      }
      .ant-row:nth-child(2) {
        .summary-short-value {
          color: ${(props) => props?.styles?.sectionTotalTextColor || "#fff"};
        }
      }
      .ant-row:nth-child(3) {
        .summary-suffix {
          color: ${(props) => props?.styles?.sectionTotalTextColor || "#fff"};
          p.data {
            color: ${(props) => props?.styles?.sectionTotalTextColor || "#fff"};
          }
          .suffix-text {
            color: ${(props) => props?.styles?.sectionTotalTextCurrencyColor || "#AFA1FF"};
          }
        }
      }
    }

    .section-average {
      background: ${(props) => props?.styles?.sectionAvgBackgroundColor || "#fff"};

      .row-average {
        .f-none {
        }

        .ant-col:first-child {
          .section-average-title {
            color: ${(props) => props?.styles?.sectionAvgTextColor || "#50429B"};
          }
        }

        .ant-col:last-child {
          .ant-row {
            .section-average-amount {
              color: ${(props) => props?.styles?.sectionAvgTextColor || "#50429B"};
            }

            .section-average-currency {
              color: ${(props) => props?.styles?.sectionAvgCurrencyTextColor || "#7E66FF"};
            }
          }
        }
      }

      .title {
        color: ${(props) => props?.styles?.sectionAvgTextColor || "#50429B"};
      }

      .ant-row:nth-child(2) {
        .summary-short-value {
          color: ${(props) => props?.styles?.sectionAvgTextColor || "#50429B"};
        }
      }

      .ant-row:nth-child(3) {
        .summary-suffix {
          color: ${(props) => props?.styles?.sectionAvgTextColor || "#50429B"};
          p {
            color: ${(props) => props?.styles?.sectionAvgTextColor || "#50429B"};
          }
        }
      }
    }
  }
`;

export default FnbWidgetStyle;
