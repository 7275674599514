import { Radio, Switch } from "antd";
import AhaMoveLogo from "assets/images/aha-move-method.png";
import GrapExpressLogo from "assets/images/grap-express-method.png";
import ShopDeliveryLogo from "assets/images/shop-delivery-method.png";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { EnumDeliveryMethod } from "constants/delivery-method.constants";
import deliveryMethodService from "data-services/delivery-method/delivery-method.service";
import { useLayoutEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AhaMoveConfig, GrabExpressConfig, SelfDeliveryConfig } from "./components";
import "./index.scss";
import FnbSwitch from "components/switch";

const DeliveryPage = (props) => {
  const { t, countryCodes, onChangeForm, isChangeForm } = props;
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selfDelivery, setSelfDelivery] = useState(undefined);
  const [ahaMove, setAhaMove] = useState(undefined);
  const [grabExpress, setGrabExpress] = useState(undefined);
  const [activedKey, setActivedKey] = useState(EnumDeliveryMethod.SelfDelivery);
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const [showConfirm, setShowConfirm] = useState(false);
  const [screenPending, setScreenPending] = useState();

  const pageData = {
    titleSelfDelivery: t("deliveryMethod.titleSelfDelivery"),
    titleAhaMove: t("deliveryMethod.titleAhaMove"),
    titleGrabExpress: t("deliveryMethod.titleGrabExpress"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };

  useLayoutEffect(() => {
    getDeliveryMethodsAsync();
  }, []);

  const getDeliveryMethodsAsync = async () => {
    await deliveryMethodService
      .getDeliveryMethodsAsync()
      .then((res) => {
        const { deliveryMethods } = res;
        if (deliveryMethods && deliveryMethods?.length > 0) {
          setDeliveryMethods(
            deliveryMethods?.map((method) => ({
              enumId: method?.enumId,
              name: method?.name,
              thumbnail: method?.thumbnail,
              checked: method?.deliveryConfig?.isActivated || false,
            })),
          );
          setSelfDelivery(deliveryMethods?.find((method) => method?.enumId === EnumDeliveryMethod.SelfDelivery));
          setAhaMove(deliveryMethods?.find((method) => method?.enumId === EnumDeliveryMethod.AhaMove));
          setGrabExpress(deliveryMethods?.find((method) => method?.enumId === EnumDeliveryMethod.GrabExpress));
        }
      })
      .catch(() => {
        setDeliveryMethods([]);
        setSelfDelivery(undefined);
        setAhaMove(undefined);
        setGrabExpress(undefined);
      });
  };

  const handleSelectMethod = (event) => {
    if (isChangeForm) {
      setScreenPending(event?.target?.value);
      setShowConfirm(true);
    } else {
      setActivedKey(event?.target?.value);
    }
  };

  const handleChangeActiveMethod = async (isActivated, id) => {
    await deliveryMethodService
      .updateStatusDeliveryMethodByIdAsync({
        id,
        isActivated,
      })
      .then((res) => {
        if (res) {
          if (id === EnumDeliveryMethod.SelfDelivery) {
            setSelfDelivery((state) => ({ ...state, deliveryConfig: { ...state?.deliveryConfig, isActivated } }));
          }

          if (id === EnumDeliveryMethod.AhaMove) {
            setAhaMove((state) => ({ ...state, deliveryConfig: { ...state?.deliveryConfig, isActivated } }));
          }

          if (id === EnumDeliveryMethod.GrabExpress) {
            setGrabExpress((state) => ({ ...state, deliveryConfig: { ...state?.deliveryConfig, isActivated } }));
          }
        }
      });
  };

  const handleChangeValues = (value) => {
    onChangeForm && onChangeForm(value);
  };

  const renderDeliveryLogo = (deliveryMethod) => {
    switch (deliveryMethod?.enumId) {
      case EnumDeliveryMethod.SelfDelivery:
        return <img src={deliveryMethod?.thumbnail || ShopDeliveryLogo} alt={deliveryMethod?.name || ""} />;
      case EnumDeliveryMethod.AhaMove:
        return <img src={AhaMoveLogo} alt={deliveryMethod?.name || ""} />;
      case EnumDeliveryMethod.GrabExpress:
        return <img src={GrapExpressLogo} alt={deliveryMethod?.name || ""} />;
      default:
        return <></>;
    }
  };

  const renderDeliveryName = (enumId) => {
    switch (enumId) {
      case EnumDeliveryMethod.SelfDelivery:
        return pageData.titleSelfDelivery;
      case EnumDeliveryMethod.AhaMove:
        return pageData.titleAhaMove;
      case EnumDeliveryMethod.GrabExpress:
        return pageData.titleGrabExpress;
      default:
        return "";
    }
  };

  const renderDeliveryMethod = (deliveryMethod) => {
    return (
      <div className="delivery-method-option">
        <div className="delivery-method-wrapper-name">
          <div className="delivery-method-logo">{renderDeliveryLogo(deliveryMethod)}</div>
          <div className="delivery-method-name">{renderDeliveryName(deliveryMethod?.enumId)}</div>
        </div>
        <FnbSwitch
            defaultChecked={deliveryMethod?.checked}
            onChange={(checked) => handleChangeActiveMethod(checked, deliveryMethod?.enumId)}
          />
      </div>
    );
  };

  const renderDeliveryConfig = () => {
    switch (activedKey) {
      case EnumDeliveryMethod.SelfDelivery:
        return (
          <SelfDeliveryConfig
            t={t}
            {...selfDelivery}
            onChangeForm={handleChangeValues}
            reLoadFormData={getDeliveryMethodsAsync}
          />
        );
      case EnumDeliveryMethod.AhaMove:
        return (
          <AhaMoveConfig
            t={t}
            {...ahaMove}
            countryCodes={countryCodes}
            onChangeForm={handleChangeValues}
            reLoadFormData={getDeliveryMethodsAsync}
          />
        );
      case EnumDeliveryMethod.GrabExpress:
        return (
          <GrabExpressConfig
            {...grabExpress}
            reLoadFormData={getDeliveryMethodsAsync}
            onChangeForm={handleChangeValues}
          />
        );
      default:
        return <></>;
    }
  };

  const onConfirm = () => {
    setShowConfirm(false);
    setActivedKey(screenPending);
    onChangeForm(false);
    getDeliveryMethodsAsync();
  };

  const onCancel = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <div className="delivery-container">
        <div className="delivery-method-container">
          {deliveryMethods && (
            <Radio.Group
              className="delivery-method-group-options"
              defaultValue={activedKey}
              onChange={handleSelectMethod}
              value={activedKey}
            >
              {deliveryMethods?.map((method, index) => (
                <Radio.Button key={index} value={method?.enumId}>
                  {renderDeliveryMethod(method)}
                  {isTablet && activedKey === method?.enumId && (
                    <div className="delivery-config-container">{renderDeliveryConfig()}</div>
                  )}
                </Radio.Button>
              ))}
            </Radio.Group>
          )}
          <DeleteConfirmComponent
            title={pageData.leaveDialog.confirmation}
            content={pageData.leaveDialog.content}
            visible={showConfirm}
            skipPermission={true}
            cancelText={pageData.leaveDialog.discardBtn}
            okText={pageData.leaveDialog.btnConfirmLeave}
            onCancel={onCancel}
            onOk={onConfirm}
            isChangeForm={isChangeForm}
          />
        </div>
        {!isTablet && <div className="delivery-config-container">{renderDeliveryConfig()}</div>}
      </div>
    </>
  );
};

export default DeliveryPage;
