import { InputNumber } from "antd";
import classNames from "classnames";
import { getUniqueId } from "utils/helpers";
import "./fnb-input.component.scss";
import React from "react";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

const FnbInputNumber = ({
  disabled = false,
  placeholder = "",
  className = "",
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  maxLength = Number.MAX_SAFE_INTEGER,
  addonAfter = "",
  showWrap = false,
  suffix,
  suffixColor,
  ...rest
}) => {
  const classNameInput = classNames({
    "fnb-input-admin": true,
    "fnb-input-number": true,
    "fnb-input-admin--disabled": disabled,
    "fnb-input-number-admin--disabled": disabled,
    "show-wrap": showWrap,
    [`${className}`]: true,
  });

  const [id] = getUniqueId();

  return (
    <>
      <InputNumber
        id={id}
        className={classNameInput}
        placeholder={placeholder}
        addonAfter={addonAfter}
        disabled={disabled}
        min={min}
        max={max}
        maxLength={maxLength}
        {...rest}
      />
       {suffix &&
        <FnbTypography
        className="fnb-input-admin__suffix"
        text={suffix}
        variant={theme.typography["b2-medium"]}
        color={suffixColor ?? theme.colors.gray[50]}
      />}
    </>
  );
};

export default FnbInputNumber;
