import { ArrowDownIcon, ArrowUpIcon, MoneysIcon, ReceiptItem } from "constants/icons.constants";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { formatNumberDecimalOrInteger } from "utils/helpers";
import "./summary-staff-revenue.style.scss";

const DEFAULT_MILLION = 1000000;
const DEFAULT_BILLION = 1000000000;
const DEFAULT_TRIILLION = 1000000000000;

const SummaryStaffRevenue = (props) => {
  const { t, isShow, setIsShow, summaryInfo, currencyCode } = props;
  const isMobile = useMediaQuery({ maxWidth: 575 });

  const pageData = {
    totalStaff: t("report.staff.totalStaff"),
    totalOrder: t("report.staff.totalOrder"),
    orders: t("report.staff.orders"),
    staff: t("report.staff.staff"),
    totalRevenue: t("report.staff.totalRevenue"),
    millions: t("report.staff.millions"),
    billions: t("report.staff.billions"),
    trillions: t("report.staff.trillions"),
    avgOrder: t("report.staff.avgOrder"),
    avgRevenue: t("report.staff.avgRevenue"),
    close: t("report.staff.close"),
  };

  useEffect(() => {
    if (isMobile) {
      setIsShow && setIsShow(true);
    }
  }, [isMobile]);

  const handleCloseSummary = () => {
    setIsShow && setIsShow(false);
  };

  const convertShortValue = (numberValue) => {
    if (!numberValue) return <>0</>;
    if (numberValue < DEFAULT_MILLION) {
      return <>{formatNumberDecimalOrInteger(numberValue)}</>;
    }
    if (numberValue < DEFAULT_BILLION) {
      return (
        <>
          {formatNumberDecimalOrInteger((numberValue / DEFAULT_MILLION).toFixed(2))} <span>{pageData.millions}</span>
        </>
      );
    }
    if (numberValue < DEFAULT_TRIILLION) {
      return (
        <>
          {formatNumberDecimalOrInteger((numberValue / DEFAULT_BILLION).toFixed(2))} <span>{pageData.billions}</span>
        </>
      );
    }
    return (
      <>
        {formatNumberDecimalOrInteger((numberValue / DEFAULT_TRIILLION).toFixed(2))} <span>{pageData.trillions}</span>
      </>
    );
  };

  return (
    <div className={`staff-summary-wrapper ${!isShow && "hidden-summary"}`}>
      <div className="staff-summary-group">
        <div className="close-summary">
          <span onClick={handleCloseSummary}>{pageData.close}</span>
        </div>
        <div className="total-staff">
          <span>{pageData.totalStaff}</span>
          <div>{formatNumberDecimalOrInteger(summaryInfo?.totalStaff)}</div>
        </div>
        <div className="staff-summary-container">
          <div className="total-summary-container">
            <div className="total-summary-item">
              <div className="header-summary">
                <div className="summary-icon">
                  <ReceiptItem />
                </div>
                <div className="summary-title">{pageData.totalOrder}</div>
                <div className={summaryInfo?.totalOrders?.isDecrease ? "summary-decrease" : "summary-increase"}>
                  {summaryInfo?.totalOrders?.isDecrease ? <ArrowDownIcon /> : <ArrowUpIcon />}
                  <span>{Math.abs(summaryInfo?.totalOrders?.percent || 0)}%</span>
                </div>
              </div>
              <div className="total-summary-highlight">{convertShortValue(summaryInfo?.totalOrders?.total)}</div>
              <div className="total-summary-detail">
                {formatNumberDecimalOrInteger(summaryInfo?.totalOrders?.total)} <span>{pageData.orders}</span>
              </div>
            </div>
          </div>
          <div className="avg-summary">
            <div className="avg-summary-container">
              <div className="avg-summary-item">
                <div className="header-summary">
                  <div className="summary-icon">
                    <ReceiptItem />
                  </div>
                  <div className="summary-title">{pageData.avgOrder}</div>
                  <div className={summaryInfo?.averageOrders?.isDecrease ? "summary-decrease" : "summary-increase"}>
                    {summaryInfo?.averageOrders?.isDecrease ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    <span>{Math.abs(summaryInfo?.averageOrders?.percent || 0)}%</span>
                  </div>
                </div>
                <div className="avg-summary-highlight">{convertShortValue(summaryInfo?.averageOrders?.total)}</div>
                <div className="avg-summary-detail">
                  {formatNumberDecimalOrInteger(summaryInfo?.averageOrders?.total)}{" "}
                  <span>
                    {pageData.orders}/{pageData.staff}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="staff-summary-container">
          <div className="total-summary-container">
            <div className="total-summary-item">
              <div className="header-summary">
                <div className="summary-icon">
                  <MoneysIcon />
                </div>
                <div className="summary-title">{pageData.totalRevenue}</div>
                <div className={summaryInfo?.totalRevenue?.isDecrease ? "summary-decrease" : "summary-increase"}>
                  {summaryInfo?.totalRevenue?.isDecrease ? <ArrowDownIcon /> : <ArrowUpIcon />}
                  <span>{Math.abs(summaryInfo?.totalRevenue?.percent || 0)}%</span>
                </div>
              </div>
              <div className="total-summary-highlight">{convertShortValue(summaryInfo?.totalRevenue?.total)}</div>
              <div className="total-summary-detail">
                {formatNumberDecimalOrInteger(summaryInfo?.totalRevenue?.total)} <span>{currencyCode}</span>
              </div>
            </div>
          </div>
          <div className="avg-summary">
            <div className="avg-summary-container">
              <div className="avg-summary-item">
                <div className="header-summary">
                  <div className="summary-icon">
                    <MoneysIcon />
                  </div>
                  <div className="summary-title">{pageData.avgRevenue}</div>
                  <div className={summaryInfo?.averageRevenue?.isDecrease ? "summary-decrease" : "summary-increase"}>
                    {summaryInfo?.averageRevenue?.isDecrease ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    <span>{Math.abs(summaryInfo?.averageRevenue?.percent || 0)}%</span>
                  </div>
                </div>
                <div className="avg-summary-highlight">{convertShortValue(summaryInfo?.averageRevenue?.total)}</div>
                <div className="avg-summary-detail">
                  {formatNumberDecimalOrInteger(summaryInfo?.averageRevenue?.total)}{" "}
                  <span>
                    {currencyCode}/{pageData.staff}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryStaffRevenue;
