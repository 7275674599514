import { Button, Col, Image, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { formatDateTimeByLanguageCode, limitCharacter } from "../../../../../utils/helpers";
import {
  CalendarDateLinearIcon,
  MakkiArrowIcon,
  UserLineDueToneIcon,
  ViewEyesIcon,
} from "../../../../assets/icons.constants";
import defaultImageBlog from "../../../../assets/images/default-image-blog.png";
import { SizeScreen } from "../../../../constants/size-screen.constants";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import "./blog-card-content.component.scss";

export function BlogCardContentComponent(props) {
  const {
    key,
    bannerImageUrl,
    blogCategory,
    blogCategoryId,
    content,
    createdTime,
    createdUser,
    creator,
    id,
    lastSavedTime,
    title,
    totalView,
    isCustomize,
    code,
    urlEncode,
    description,
  } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    viewMore: t("button.viewMore"),
    by: t("blog.blogDetail.by"),
  };
  const checkScreen = useWindowDimensions();

  const getWindowDimensions = () => {
    if (checkScreen === SizeScreen.NORMAL) {
      return 170;
    } else if (checkScreen === SizeScreen.IS_MOBILE) {
      return 70;
    } else {
      return 100;
    }
  };

  const handleClickViewMore = (urlEncode) => {
    if (isCustomize == false || isCustomize == null || isCustomize == undefined) {
      const urlParams = `blog/${urlEncode}`;
      history.replace("");
      history.push(urlParams);
    }
  };
  
  return (
    <Col className={isCustomize ? "blog-card-content-customize" : "blog-card-content"}>
      <Link to={urlEncode && `/blog/${urlEncode}`}>
        <Row className="row-image">
          <Image width={"100%"} src={Boolean(bannerImageUrl) ? bannerImageUrl : defaultImageBlog} preview={false} />
        </Row>
      </Link>
      <div className="row-created-information">
        <div className="created-time">
          <CalendarDateLinearIcon />
          <span>{formatDateTimeByLanguageCode(new Date(createdTime), localStorage.getItem("i18nextLng"))}</span>
        </div>
        <div className="tag">
          <ViewEyesIcon />
          <span>{totalView}</span>
        </div>
        <div className="created-by">
          <UserLineDueToneIcon />
          <span>{pageData.by + " " + creator}</span>
        </div>
      </div>
      <Link to={urlEncode && `/blog/${urlEncode}`}>
        <Row className="row-title">
          <span className="title">{title}</span>
        </Row>
      </Link>
      <Row className="row-content">
        <span className="content">{limitCharacter(description, getWindowDimensions(), "...")}</span>
      </Row>
      <Row>
        <Button
          className="row-button"
          onClick={(e) => {
            handleClickViewMore(urlEncode);
          }}
        >
          <span>{pageData?.viewMore?.toUpperCase()}</span>
          <MakkiArrowIcon className="icon-arrow"></MakkiArrowIcon>
        </Button>
      </Row>
    </Col>
  );
}
