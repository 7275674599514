import { Button, Input, Row, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ArrowUpIcon, SearchICon } from "../../../../assets/icons.constants";
import "./blog-card-categories.component.scss";
import { BlogCardRecentPostComponent } from "./blog-card-recent-post.component";

export function BlogCardCategoriesComponent(props) {
  const {
    blogCategories,
    blogRecentPost,
    onClickCategories,
    isCustomize,
    searchFunction,
    keySearch = "",
    fontFamily,
    colorGroupArticle
  } = props;
  const isMinWidth1200 = useMediaQuery({ minWidth: 1200 });
  const [t] = useTranslation();
  const DEFAULT_SEARCH = "FIRST";
  const pageData = {
    viewMore: t("button.viewMore"),
    searchText: t("blogList.searchText"),
    searching: t("blogList.searching"),
    categories: t("blogList.categories"),
    all: t("blogList.all"),
  };
  const colorGroup = props?.general?.color?.colorGroups?.find((c) => c.id === props?.config?.header?.colorGroupId);
  const [searchTerm, setSearchTerm] = useState(DEFAULT_SEARCH);
  const [isOnChange, setIsOnChange] = useState(false);
  const totalCategories = blogCategories?.reduce((total, item) => {
    if (item?.total && item.total > 0) {
      return total + item.total;
    }
    return total;
  }, 0);
  const [showTooltip, setShowTooltip] = useState([]);

  const checkOverflow = () => {
    const newShowTooltip = blogCategories?.map((tag, index) => {
      const element = document.getElementById(`tag-name-${tag.id}`);
      return element && element.scrollWidth > element.clientWidth;
    });
    setShowTooltip(newShowTooltip);
  };

  useEffect(() => {
    const newShowTooltip = blogCategories?.filter(tag => tag?.total > 0)?.map(() => false);
    setShowTooltip(newShowTooltip);
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [blogCategories]);

  return (
    <>
      <div
        className={isCustomize ? "blog-card-categories-search-customize" : "blog-card-categories-search"}
        id="blog-card-categories-search-blog-theme1"
      >
        <Row className="categories">
          <span className="title-categories">{pageData.searching}</span>
          <div className="search-bar">
            <Input
              autoFocus={false}
              maxLength={100}
              className="search-component"
              allowClear
              size="large"
              placeholder={pageData.searchText}
              prefix={<SearchICon onClick={() => searchFunction(searchTerm)} />}
              onChange={(e) => {
                setIsOnChange(true);
                setSearchTerm(e.target.value);
              }}
              onPressEnter={(e) => searchFunction(e.target.value)}
              value={isOnChange ? searchTerm : keySearch}
            />
          </div>
        </Row>
      </div>
      <div
        className={
          isCustomize ? "blog-card-categories-and-recent-post-customize" : "blog-card-categories-and-recent-post"
        }
      >
        {blogCategories?.length > 0 && (
          <div className="categories">
            <span className="title-categories">{pageData.categories}</span>
            {isMinWidth1200 ? (
              <Button
                className="tag-component tag-component-category-blog-theme1"
                onClick={(e) => onClickCategories()}
              >
                <div className="tag-name" data-tooltip={pageData.all}>
                  <ArrowUpIcon />
                  <span>{pageData.all}</span>
                </div>
                  {!isCustomize && <span className="tag-number">{totalCategories}</span>} 
              </Button>
            ) : (
              <Button className="tag-component tag-component-category-blog-theme1" onClick={(e) => onClickCategories()}>
                <div className="tag-name" data-tooltip={pageData.all}>
                  <ArrowUpIcon />
                  <span>{pageData.all}</span>
                </div>
                  {!isCustomize && <span className="tag-number">{totalCategories}</span>} 
              </Button>
            )}

            {blogCategories?.map((tag, index) => {
              if (tag?.total > 0 && isMinWidth1200)
                return (
                  <>
                    {showTooltip?.[index] && <Tooltip
                      key={tag?.id}
                      color={colorGroup?.buttonBackgroundColor}
                      placement="topLeft"
                      title={<span style={{ fontFamily: fontFamily }}>{tag.name}</span>}
                    >
                      <Button
                        className="tag-component tag-component-category-blog-theme1"
                        onClick={(e) => onClickCategories(tag?.id)}
                      >
                        <div className="tag-name">
                          <ArrowUpIcon />
                          <span id={`tag-name-${tag?.id}`}>{tag?.name}</span>
                        </div>
                        <span className="tag-number">{tag?.total}</span>
                      </Button>
                    </Tooltip>}
                    {!showTooltip?.[index] && <Button
                      className="tag-component tag-component-category-blog-theme1"
                      onClick={(e) => onClickCategories(tag?.id)}
                    >
                      <div>
                        <div className="tag-name">
                          <ArrowUpIcon />
                          <span id={`tag-name-${tag?.id}`}>{tag?.name}</span>
                        </div>
                      </div>
                      <span className="tag-number">{tag?.total}</span>
                    </Button>}
                  </>
                );
              if (tag?.total > 0 && !isMinWidth1200)
                return (
                  <Button
                    className="tag-component tag-component-category-blog-theme1"
                    onClick={(e) => onClickCategories(tag.id)}
                  >
                    <div className="tag-name">
                      <ArrowUpIcon />
                      <span>{tag.name}</span>
                    </div>
                    <span className="tag-number">{tag?.total}</span>
                  </Button>
                );
            })}
          </div>
        )}
      </div>
      <div
        className={
          isCustomize ? "blog-card-categories-and-recent-post-customize" : "blog-card-categories-and-recent-post"
        }
        style={{ position: "sticky", top: "100px" }}
      >
        {blogRecentPost?.length > 0 && (
          <BlogCardRecentPostComponent
            blogRecentPost={blogRecentPost}
            isCustomize={isCustomize}
            colorGroupArticle={colorGroupArticle}
          />
        )}
      </div>
    </>
  );
}
