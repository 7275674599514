import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import {
  IngredientRemoveRequestHistoryLocalization,
  IngredientRemoveRequestHistoryType,
} from "constants/ingredient-remove-request.constants";
import { forwardRef, useImperativeHandle } from "react";

const FilterHistoryRemoveIngredient = forwardRef((props, ref) => {
  const { t, branches, onChangeFilter } = props;
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      form.setFieldsValue({
        type: -1,
        branchId: "",
      });
    },
  }));
  const pageData = {
    branch: t("store.branch"),
    activity: t("materialControl.historyRemoveRequest.activity", "Activity"),
    actionType: {
      all: t("materialControl.historyRemoveRequest.all"),
    },
  };

  const defaultValueBranch = "";

  const onChangeBranch = (value) => {
    onChangeFilter("branchId", value);
  };

  const onChangeType = (e) => {
    onChangeFilter("type", e.target.value);
  };

  const listType = [
    {
      id: -1,
      name: pageData.actionType.all,
    },
    {
      id: IngredientRemoveRequestHistoryType.CREATED,
      name: t(IngredientRemoveRequestHistoryLocalization[IngredientRemoveRequestHistoryType.CREATED]),
    },
    {
      id: IngredientRemoveRequestHistoryType.APPROVED,
      name: t(IngredientRemoveRequestHistoryLocalization[IngredientRemoveRequestHistoryType.APPROVED]),
    },
    {
      id: IngredientRemoveRequestHistoryType.EDITED,
      name: t(IngredientRemoveRequestHistoryLocalization[IngredientRemoveRequestHistoryType.EDITED]),
    },
    {
      id: IngredientRemoveRequestHistoryType.REJECTED,
      name: t(IngredientRemoveRequestHistoryLocalization[IngredientRemoveRequestHistoryType.REJECTED]),
    },
  ];
  return (
    <Form className="custom-form" form={form} layout="vertical">
      <Form.Item label={pageData.branch} name="branchId">
        <FnbSelectSingle showSearch defaultValue={defaultValueBranch} option={branches} onChange={onChangeBranch} />
      </Form.Item>
      <Form.Item label={pageData.activity} name="type">
        <Radio.Group defaultValue={-1} buttonStyle="solid" onChange={onChangeType}>
          {listType?.map((item, index) => (
            <FnbRadioButton value={item?.id} key={index}>
              {item?.name[0]?.toUpperCase() + item?.name?.slice(1)}
            </FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default FilterHistoryRemoveIngredient;
