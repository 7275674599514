export const PaymentMethodType = {
  /// <summary>
  /// Momo
  /// </summary>
  Momo: 0,

  /// <summary>
  /// ZaloPay
  /// </summary>
  ZaloPay: 1,

  /// <summary>
  /// CreditDebitCard
  /// </summary>
  CreditDebitCard: 2,

  /// <summary>
  /// Cash
  /// </summary>
  Cash: 3,

  /// <summary>
  /// VNPay
  /// </summary>
  VNPay: 4,

  /// <summary>
  /// COD
  /// </summary>
  COD: 5,

  /// <summary>
  /// COD
  /// </summary>
  COD: 5,

  /// <summary>
  /// BankTransfer
  /// </summary>
  BankTransfer: 6,

  /// <summary>
  /// Personal
  /// </summary>
  Personal: 7,

  /// <summary>
  /// mPos
  /// </summary>
  mPOS: 8,
  
  /// <summary>
  /// PayPal
  /// </summary>
  PayPal: 9,
};
