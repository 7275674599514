import RegisterNewStoreAccount from "pages/register-new-store-account";

// Define the route
const route = [
  {
    key: "app.register-account",
    path: "/register-account",
    icon: "fa fa-plus-square",
    name: "RegisterNewStoreAccount",
    isMenu: false,
    exact: true,
    auth: false,
    component: () => <RegisterNewStoreAccount trialRegister={false} />,
    child: [],
  },
  {
    key: "app.register-trial-account",
    path: "/register-trial-account",
    icon: "fa fa-plus-square",
    name: "RegisterTrialAccount",
    isMenu: false,
    exact: true,
    auth: false,
    component: () => <RegisterNewStoreAccount trialRegister={true} />,
    child: [],
  },
];
export default route;
