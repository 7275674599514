import { Card, Form, Row, message } from "antd";
import defaultUserIcon from "assets/images/default-user.png";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import RejectDialogComponent from "components/fnb-reject-dialog/reject-dialog.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { AddCircleIcon, EditOutlined, ImportOutlinedIcon, Note2Icon } from "constants/icons.constants";
import { IngredientRemoveReasonType, IngredientRemoveStatus } from "constants/ingredient-remove.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import branchDataService from "data-services/branch/branch-data.service";
import ingredientRemoveRequestDataService from "data-services/material-control/ingredient-remove-request-data.service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import theme from "theme";
import { executeAfter, formatShortDate, hasPermission } from "utils/helpers";
import { formatDate } from "../../../../utils/helpers";
import { FilterPopover } from "./filter-popover.component";
import "./index.scss";

const { forwardRef, useImperativeHandle } = React;
const TableUnavailableIngredientComponent = forwardRef((props, ref) => {
  const history = useHistory();

  const [t] = useTranslation();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();
  const [countFilter, setCountFilter] = useState(0);
  const filterPopoverRef = React.useRef();
  const [branches, setBranches] = useState([]);
  const [dataFilterAndSearch, setDataFilterAndSearch] = useState({
    keySearch: "",
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
    businessSummaryWidgetFilter: 0,
    reasonType: "",
    branchId: "",
    statusId: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShowModalReject, setIsShowModalReject] = useState(false);
  const [isShowModalApprove, setIsShowModalApprove] = useState(false);
  const [disabledBtnApprove, setDisabledBtnApprove] = useState(false);

  const pageData = {
    btnFilter: t("button.filter"),
    btnAddNew: t("button.addNew"),
    btnEdit: t("button.edit"),
    btnReject: t("button.reject"),
    btnApprove: t("button.approve"),
    viewHistory: t("button.viewHistory"),
    table: {
      code: t("table.code"),
      requestId: t("menu.inventoryManagement.requestId"),
      branch: t("purchaseOrder.branch"),
      numberOfIngredient: t("menu.inventoryManagement.numberOfIngredients"),
      reason: t("order.reason"),
      status: t("table.status"),
      createdBy: t("table.createdBy"),
      createdDate: t("menu.inventoryManagement.createdTime"),
    },
    today: t("optionDatetime.today"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    yesterday: "dashboard.compareDate.yesterday",
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("purchaseOrder.status.approved"),
      waitingToApproval: t("menu.inventoryManagement.waitingForApproval"),
      rejected: t("status.rejected"),
    },
    reasonType: {
      ingredientsAreOutOfDate: t("menu.inventoryManagement.ingredientsAreOutOfDate"),
      ingredientsAreDamaged: t("menu.inventoryManagement.ingredientsAreDamaged"),
      other: t("menu.inventoryManagement.others"),
    },
    import: t("button.import"),
    searchPlaceholder: t("materialControl.historyRemoveRequest.searchPlaceholder"),
    reject: t("reservation.reject"),
    rejectTitle: t("materialControl.detailRemoveRequest.rejectTitle"),
    rejectContent: t("materialControl.detailRemoveRequest.rejectContent"),
    approveTitle: t("materialControl.detailRemoveRequest.approveTitle"),
    approveContent: t("materialControl.detailRemoveRequest.approveContent"),
    approveSuccess: t("materialControl.detailRemoveRequest.approveSuccess"),
    rejectSuccess: t("materialControl.detailRemoveRequest.rejectSuccess"),
    approve: t("button.approve"),
    ignore: t("button.deletePageIgnore"),
    toCreateData: t("area.toCreateData"),
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.requestId,
        key: "id",
        width: "9rem",
        align: "center",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record?.code}</FnbTooltip>;
        },
      },
      {
        title: pageData.table.branch,
        key: "branch",
        width: "24%",
        className: "table-text-branch-overflow",
        ellipsis: true,
        align: "left",
        sorter: (pre, current) => pre?.branch.localeCompare(current?.branch),
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.branch}>
              <span>{record?.branch}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.numberOfIngredient,
        key: "numberOfIngredient",
        width: "16%",
        align: "center",
        render: (_, record) => {
          return (
            <FnbTooltip
              variant="secondary"
              title={
                <ul style={{ paddingLeft: 14, margin: "auto" }}>
                  {record?.ingredientNames?.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              }
              maxWidth={400}
            >
              <FnbTypography
                color={theme.colors.primary[100]}
                text={record?.numberOfIngredient}
                variant={theme.typography["b1-bold"]}
              />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.reason,
        key: "reasonType",
        width: "12%",
        align: "left",
        render: (_, record) => {
          switch (record?.reasonType.toString()) {
            case IngredientRemoveReasonType.OUT_OF_DATE:
              return (
                <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.reasonType.ingredientsAreOutOfDate}>
                  <span>{pageData.reasonType.ingredientsAreOutOfDate}</span>
                </FnbTooltip>
              );
            case IngredientRemoveReasonType.DAMAGED:
              return (
                <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.reasonType.ingredientsAreDamaged}>
                  <span>{pageData.reasonType.ingredientsAreDamaged}</span>
                </FnbTooltip>
              );
            case IngredientRemoveReasonType.OTHERS:
              return (
                <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.reasonType.other}>
                  <span>{pageData.reasonType.other}</span>
                </FnbTooltip>
              );
            default:
              <FnbTooltip onlyShowWhenEllipsis={true} title={pageData.reasonType.other}>
                <span>{pageData.reasonType.other}</span>
              </FnbTooltip>;
          }
        },
      },
      {
        title: pageData.table.createdBy,
        width: "16%",
        ellipsis: true,
        align: "left",
        className: "table-text-createdBy-overflow",
        render: (_, record) => {
          return (
            <div className="column-product-name">
              <img src={record?.thumbnail || defaultUserIcon} alt={record?.createBy} />
              <FnbTooltip title={record?.createBy} onlyShowWhenEllipsis maxWidthContent="95%">
                <span>{record?.createBy}</span>
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.table.createdDate,
        key: "createdDate",
        width: "10%",
        align: "left",
        render: (_, record) => {
          return formatShortDate(formatDate(record?.createdDate, DateFormat.YYYY_MM_DD));
        },
      },
      {
        title: pageData.table.status,
        key: "status",
        align: "left",
        width: "12rem",
        ellipsis: true,
        render: (_, record) => {
          switch (record?.status) {
            case IngredientRemoveStatus.WAITING_TO_APPROVAL:
              return <FnbBadge variant="primary" text={pageData.status.waitingToApproval} />;
            case IngredientRemoveStatus.REJECTED:
              return <FnbBadge variant="error" text={pageData.status.rejected} />;
            case IngredientRemoveStatus.APPROVED:
              return <FnbBadge variant="success" text={pageData.status.approved} />;
            default:
              return <FnbBadge variant="new" text={pageData.status.new} />;
          }
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync({
        pageNumber: page,
        pageSize: pageSize,
        keySearch: dataFilterAndSearch.keySearch ?? "",
        startDate: dataFilterAndSearch.startDate,
        endDate: dataFilterAndSearch.endDate,
        businessSummaryWidgetFilter: dataFilterAndSearch.businessSummaryWidgetFilter,
        reasonType: dataFilterAndSearch.reasonType,
        branchId: dataFilterAndSearch.branchId,
        status: dataFilterAndSearch.statusId,
      });
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        setDataFilterAndSearch({ ...dataFilterAndSearch, keySearch: keySearch });
        await fetchDatableAsync({
          pageNumber: 1,
          pageSize: tableSettings.pageSize,
          keySearch: keySearch,
          startDate: dataFilterAndSearch.startDate,
          endDate: dataFilterAndSearch.endDate,
          businessSummaryWidgetFilter: dataFilterAndSearch.businessSummaryWidgetFilter,
          reasonType: dataFilterAndSearch.reasonType,
          branchId: dataFilterAndSearch.branchId,
          status: dataFilterAndSearch.statusId,
        });
      });
    },
  };

  useEffect(() => {
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: "",
      startDate: moment().toDate().toLocaleDateString("en-US"),
      endDate: moment().toDate().toLocaleDateString("en-US"),
      businessSummaryWidgetFilter: 0,
    });
    initBranchDataFilter();
  }, []);

  const initBranchDataFilter = async () => {
    var resBranch = await branchDataService.getAllBranchsAsync();
    if (resBranch) {
      setBranches(resBranch.branchs);
    }
  };

  const fetchDatableAsync = async ({
    pageNumber,
    pageSize,
    keySearch,
    startDate,
    endDate,
    businessSummaryWidgetFilter,
    reasonType,
    branchId,
    status,
  }) => {
    const response = await ingredientRemoveRequestDataService.getAllIngredientRemoveRequestAsync(
      pageNumber,
      pageSize,
      keySearch,
      startDate,
      endDate,
      businessSummaryWidgetFilter,
      reasonType,
      branchId,
      status,
    );
    if (response) {
      const data = response?.ingredientRemoveRequests.map((s) => mappingRecordToColumns(s));
      setTotalRecords(response.total);
      setCurrentPageNumber(response.pageNumber);
      setDataSource(data);

      let numberRecordCurrent = pageNumber * pageSize;
      if (numberRecordCurrent > response.total) {
        numberRecordCurrent = response.total;
      }
      setNumberRecordCurrent(numberRecordCurrent);
    }
  };

  const mappingRecordToColumns = (item) => {
    return {
      id: item?.id,
      code: item?.code,
      branch: item?.branchName,
      numberOfIngredient: item?.numberOfIngredient,
      status: item?.status,
      createdDate: item?.createdTime,
      createBy: item?.createBy,
      thumbnail: item?.thumbnail,
      reasonType: item?.reasonType,
      ingredientNames: item?.ingredientNames,
    };
  };

  const getTableColumns = () => {
    return tableSettings.columns;
  };
  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
    setCountFilter(0);
    setDataFilterAndSearch({
      ...dataFilterAndSearch,
      businessSummaryWidgetFilter: 0,
      reasonType: "",
      branchId: "",
      statusId: "",
    });
  };
  const handleFilter = (data, isReloadCountFilter = true) => {
    if (!data) return;

    setCurrentPageNumber(1);
    if (isReloadCountFilter) {
      const countFilter = Object.values(data).reduce((accumulator, currentValue) => {
        // Count except for string undefined, '' or status = 0
        if (currentValue || currentValue === 0) return ++accumulator;
        return accumulator;
      }, 0);
      setCountFilter(countFilter);
    }
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...data });
    fetchDatableAsync({
      pageNumber: 1,
      pageSize: tableSettings.pageSize,
      keySearch: dataFilterAndSearch.keySearch || "",
      startDate: dataFilterAndSearch.startDate,
      endDate: dataFilterAndSearch.endDate,
      businessSummaryWidgetFilter: dataFilterAndSearch.businessSummaryWidgetFilter,
      reasonType: data.reasonType,
      branchId: data.branchId,
      status: data.statusId,
    });
  };
  //#region
  const filterComponent = () => {
    return <FilterPopover fetchDataPopover={handleFilter} ref={filterPopoverRef} branches={branches} />;
  };

  //#endregion

  const onRowClick = (record, _index) => {
    history.push(`/inventory/material-control/detail/${record?.id}`);
  };

  const onClickViewHistory = () => {
    history.push("/inventory/material-control/history-ingredient-remove-request");
  };

  const onCreate = () => {
    history.push("/inventory/material-control/create-new-ingredient-remove-request");
  };

  const onEditItem = (selectedRows) => {
    history.push(`/inventory/material-control/edit-ingredient-remove-request/${selectedRows?.[0]?.id}`);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onShowRejectModal = (selectedRows) => {
    setIsShowModalReject(true);
    setSelectedRows(selectedRows);
  };

  const onShowApproveModal = (selectedRows) => {
    setIsShowModalApprove(true);
    setSelectedRows(selectedRows);
  };

  const onSelectedDatePicker = async (date, typeOptionDate) => {
    const newDataFilter = {
      ...dataFilterAndSearch,
      startDate: date?.startDate,
      endDate: date?.endDate,
      businessSummaryWidgetFilter: typeOptionDate,
    };
    setDataFilterAndSearch(newDataFilter);
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: dataFilterAndSearch.keySearch || "",
      startDate: date?.startDate,
      endDate: date?.endDate,
      businessSummaryWidgetFilter: typeOptionDate,
      reasonType: dataFilterAndSearch.reasonType,
      branchId: dataFilterAndSearch.branchId,
      status: dataFilterAndSearch.statusId,
    });
  };

  const onRejectIngredientRemoveRequest = (reason) => {
    let req = { id: selectedRows?.[0]?.id, reason: reason };
    ingredientRemoveRequestDataService
      .rejectIngredientRemoveRequestAsync(req)
      .then((res) => {
        if (res) {
          message.success(pageData.rejectSuccess);
          setIsShowModalReject(false);
          setSelectedRows([]);
          setSelectedRowKeys([]);
          handleFilter(dataFilterAndSearch, false);
        }
      })
      .catch((_ex) => {
        setIsShowModalReject(false);
      });
  };

  const onApproveIngredientRemoveRequest = () => {
    setDisabledBtnApprove(true);
    let req = { id: selectedRows?.[0]?.id };
    ingredientRemoveRequestDataService
      .approveIngredientRemoveRequestAsync(req)
      .then((res) => {
        if (res) {
          message.success(pageData.approveSuccess);
          setIsShowModalApprove(false);
          setSelectedRows([]);
          setSelectedRowKeys([]);
          handleFilter(dataFilterAndSearch, false);
          setDisabledBtnApprove(false);
        }
      })
      .catch((_ex) => {
        setIsShowModalApprove(false);
      });
  };

  return (
    <>
      <Form className="form-staff">
        <Card className="fnb-card-custom w-100">
          <Row className="form-staff">
            <FnbTable
              className="table-striped-rows table-unavailable-ingredient"
              dataSource={dataSource}
              columns={getTableColumns()}
              pageSize={tableSettings.pageSize}
              currentPageNumber={currentPageNumber}
              total={totalRecords}
              onChangePage={tableSettings.onChangePage}
              numberRecordCurrent={numberRecordCurrent}
              scrollX={1500}
              search={{
                placeholder: pageData.searchPlaceholder,
                onChange: tableSettings.onSearch,
                maxLength: 100,
              }}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                renderActions: (_selectedRowKeys, selectedRows) => {
                  const multipleSelectedRows = selectedRows?.length > 1;
                  const isWaitingToApprovalStatus = selectedRows?.every(
                    (reccord) => reccord?.status === IngredientRemoveStatus.WAITING_TO_APPROVAL,
                  );
                  return (
                    <>
                      {hasPermission(PermissionKeys.EDIT_INGREDIENT_REMOVE_REQUEST) &&
                        !multipleSelectedRows &&
                        isWaitingToApprovalStatus && (
                          <FnbTooltip placement="top" title={pageData.btnEdit} zIndex={100}>
                            <FnbButton
                              variant="text"
                              className="row-edit-button"
                              iconHeader={<EditOutlined />}
                              onClick={() => onEditItem(selectedRows)}
                              disabled={multipleSelectedRows}
                            />
                          </FnbTooltip>
                        )}
                      {hasPermission(PermissionKeys.REJECT_INGREDIENT_REMOVE_REQUEST) &&
                        !multipleSelectedRows &&
                        isWaitingToApprovalStatus && (
                          <FnbTooltip placement="top" title={pageData.btnReject} zIndex={100}>
                            <FnbButton
                              text={pageData.btnReject}
                              variant="tertiary"
                              className="w-100 background-color-white"
                              onClick={() => onShowRejectModal(selectedRows)}
                              disabled={multipleSelectedRows}
                            />
                          </FnbTooltip>
                        )}
                      {hasPermission(PermissionKeys.APPROVE_INGREDIENT_REMOVE_REQUEST) &&
                        !multipleSelectedRows &&
                        isWaitingToApprovalStatus && (
                          <FnbTooltip placement="top" title={pageData.btnApprove} zIndex={100}>
                            <FnbButton
                              text={pageData.btnApprove}
                              variant="tertiary"
                              className="w-100 background-color-white"
                              onClick={() => onShowApproveModal(selectedRows)}
                              disabled={multipleSelectedRows}
                            />
                          </FnbTooltip>
                        )}
                    </>
                  );
                },
              }}
              filter={{
                totalFilterSelected: countFilter,
                onClearFilter: onClearFilter,
                buttonTitle: pageData.btnFilter,
                component: filterComponent(),
              }}
              onRowClick={onRowClick}
              listButtons={[
                <FnbButton
                  permission={PermissionKeys.CREATE_INGREDIENT_REMOVE_REQUEST}
                  onClick={onCreate}
                  text={pageData.btnAddNew}
                  iconHeader={<AddCircleIcon />}
                  className="fnb-heading-page__button-create"
                />,
                <FnbButton
                  permission={PermissionKeys.IMPORT_INGREDIENT_REMOVE_REQUEST}
                  variant="secondary"
                  text={pageData.import}
                  onClick={() => history.push("/inventory/remove-ingredient-request/import")}
                  iconHeader={<ImportOutlinedIcon></ImportOutlinedIcon>}
                />,
                <FnbButton
                  variant="secondary-purple"
                  text={pageData.viewHistory}
                  onClick={() => onClickViewHistory()}
                  iconHeader={<Note2Icon />}
                ></FnbButton>,
                <FnbDatePicker
                  selectedDate={dataFilterAndSearch}
                  setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
                />,
              ]}
              emptyText={
                hasPermission(PermissionKeys.CREATE_INGREDIENT_REMOVE_REQUEST) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.btnAddNew}
                      onClick={onCreate}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Row>
        </Card>
      </Form>
      <ConfirmDialogComponent
        visible={isShowModalApprove}
        type={ConfirmStyle.STOP}
        onCancel={() => setIsShowModalApprove(false)}
        onOk={onApproveIngredientRemoveRequest}
        title={pageData.approveTitle}
        content={pageData.approveContent}
        okText={pageData.approve}
        cancelText={pageData.ignore}
        disabledBtnOk={disabledBtnApprove}
      />
      <RejectDialogComponent
        visible={isShowModalReject}
        type={ConfirmStyle.DELETE}
        onCancel={() => setIsShowModalReject(false)}
        onOk={onRejectIngredientRemoveRequest}
        title={pageData.rejectTitle}
        content={pageData.rejectContent}
        okText={t("materialControl.detailRemoveRequest.reject")}
        cancelText={t("store.ignore")}
      />
    </>
  );
});

export default TableUnavailableIngredientComponent;
