import { Card, message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { PermissionKeys } from "constants/permission-key.constants";
import materialCategoryDataService from "data-services/material-category/material-category-data.service";
import materialDataService from "data-services/material/material-data.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { executeAfter, hasPermission } from "utils/helpers";
import "../index.scss";
import TableMaterialByCategoryComponent from "./table-material-by-category.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function TableMaterialCategoryComponent(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const { onShowNewCategoryManagementComponent, onEditMaterialCategory } = props;
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showMaterialModel, setShowMaterialModel] = useState(false);
  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState(null);
  const tableMaterialsFuncs = React.useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);

  const pageData = {
    addNew: t("button.addNew"),
    title: t("materialCategory.title"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessageCategory: t("messages.confirmDeleteMessageCategory"),
    materialCategoryDeleteSuccess: t("materialCategory.materialCategoryDeleteSuccess"),
    materialCategoryDeleteFail: t("materialCategory.materialCategoryDeleteFail"),
    addCategory: t("materialCategory.addCategory"),
    table: {
      searchPlaceholder: t("materialCategory.table.searchPlaceholder"),
      no: t("table.no"),
      name: t("materialCategory.table.name"),
      description: t("materialCategory.table.description"),
      material: t("materialCategory.table.material"),
      numberOfProducts: t("materialCategory.table.numberOfProducts"),
      action: t("materialCategory.table.action"),
    },
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    toCreateData: t("area.toCreateData"),
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.no,
        dataIndex: "index",
        key: "index",
        width: "5rem",
        align: "left",
        render: (value) => value + 1,
      },
      {
        title: pageData.table.name,
        dataIndex: "name",
        key: "name",
        width: "50%",
        align: "left",
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => {
          return (
            <FnbTooltip title={record?.name} onlyShowWhenEllipsis={true}>
              <FnbTypography text={record.name} variant={theme.typography["b1-regular"]}></FnbTypography>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.numberOfProducts,
        dataIndex: "totalMaterial",
        className: "grid-total-column",
        key: "totalMaterial",
        width: "50%",
        align: "right",
        render: (value, record) => <span>{value}</span>,
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize, "");
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        await fetchDatableAsync(1, tableSettings.pageSize, keySearch);
      });
    },
  };

  useEffect(() => {
    props.tableFuncs.current = onRefreshTable;
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  }, []);

  const handleDeleteItem = async (id) => {
    var res = await materialCategoryDataService.deleteMaterialCategoryByIdAsync(id);
    if (res) {
      message.success(pageData.materialCategoryDeleteSuccess);
    } else {
      message.error(pageData.materialCategoryDeleteFail);
    }
    setIsShowDeleteDialog(false);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    await fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const onRefreshTable = async () => {
    await fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  };

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch) => {
    const response = await materialCategoryDataService.getMaterialCategoriesAsync(pageNumber, pageSize, keySearch);
    const data = response?.materialCategories.map((s) => mappingRecordToColumns(s));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
  };

  const mappingRecordToColumns = (item) => {
    return {
      index: item?.no,
      id: item?.id,
      name: item?.name,
      totalMaterial: item?.totalMaterial,
    };
  };

  const initDataMaterial = async (record) => {
    let dataMaterials = await materialDataService.getMaterialsByFilterAsync(1, 10, "", "", "", record?.id, "");
    if (tableMaterialsFuncs.current) {
      tableMaterialsFuncs.current({
        dataMaterials: dataMaterials,
        materialCategoryId: record?.id,
      });
    }
  };

  const renderMaterialModal = () => {
    const materialCategory = dataSource?.find((s) => s.id === selectedMaterialCategory?.id);
    return (
      <FnbModal
        width={"800px"}
        className="modal-detail-material-category"
        title={materialCategory?.name}
        visible={showMaterialModel}
        handleCancel={() => setShowMaterialModel(false)}
        hideFooter={true}
        isDestroyOnClose={true}
        content={
          <TableMaterialByCategoryComponent materialCategory={materialCategory} tableFuncs={tableMaterialsFuncs} />
        }
      />
    );
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const onRowClick = (record, _index) => {
    if (record?.totalMaterial > 0) {
      setShowMaterialModel(true);
      setSelectedMaterialCategory(record);
    }
    initDataMaterial(record);
  };

  return (
    <>
      {renderMaterialModal()}
      <FnbHeadingPage
        title={pageData.title}
        onCreate={onShowNewCategoryManagementComponent}
        permissionCreate={PermissionKeys.CREATE_MATERIAL_CATEGORY}
      ></FnbHeadingPage>
      <Card className="fnb-card-custom">
        <FnbTable
          columns={tableSettings.columns}
          pageSize={tableSettings.pageSize}
          dataSource={dataSource}
          currentPageNumber={currentPageNumber}
          total={totalRecords}
          onChangePage={tableSettings.onChangePage}
          editPermission={PermissionKeys.EDIT_MATERIAL_CATEGORY}
          deletePermission={PermissionKeys.DELETE_MATERIAL_CATEGORY}
          search={{
            placeholder: pageData.table.searchPlaceholder,
            onChange: tableSettings.onSearch,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: () => {
              onEditMaterialCategory(selectedRows[0]?.id);
              setSelectedRowKeys([]);
            },
            onDelete: () => {
              setIsShowDeleteDialog(true);
            },
          }}
          onRowClick={onRowClick}
          emptyText={
            hasPermission(PermissionKeys.CREATE_PRODUCT_CATEGORY) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={onShowNewCategoryManagementComponent}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
      </Card>
      <DeleteConfirmComponent
        visible={isShowDeleteDialog}
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        okText={pageData.btnDelete}
        cancelText={pageData.btnIgnore}
        permission={PermissionKeys.DELETE_MATERIAL_CATEGORY}
        onOk={() => handleDeleteItem(selectedRows[0]?.id)}
        onCancel={() => setIsShowDeleteDialog(false)}
        buttonType={"NONE"}
      />
    </>
  );
}
