import { REVIEW_STATUS } from "constants/review-status.constants";
import http from "../../utils/http-common";
import QueryString from "qs";

const controller = "review";

const getFilterReviewAsync = ({
  status,
  endDate,
  pageNumber,
  pageSize,
  startDate,
  keySearchOrderId,
  branchIds,
  ratings,
}) => {
  const params = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    status: status,
    keySearchOrderId: keySearchOrderId,
    startDate: startDate,
    endDate: endDate,
    branchIds: branchIds,
    ratings: ratings,
  };
  return http.get(`/${controller}/filter`, {
    params: params,
    paramsSerializer: (params) => {
      return QueryString.stringify(params);
    },
  });
};

const updateStatusReviewAsync = (data) => {
  return http.put(`/${controller}/update-status`, data);
};
const reviewDataService = {
  getFilterReviewAsync,
  updateStatusReviewAsync,
};
export default reviewDataService;
