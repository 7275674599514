import { Card, Form, Row, Typography } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { AddCircleIcon, ExportOutlinedIcon, ImportOutlinedIcon, Note2Icon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { PurchaseOrderStatus } from "constants/purchase-order-status.constants";
import { DateFormat } from "constants/string.constants";
import branchDataService from "data-services/branch/branch-data.service";
import purchaseOrderDataService from "data-services/purchase-order/purchase-order-data.service";
import supplierDataService from "data-services/supplier/supplier-data.service";
import { env } from "env";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import languageService from "services/language/language.service";
import { executeAfter, formatNumberDecimalOrInteger, formatShortDate, hasPermission } from "utils/helpers";
import { formatDate, getCurrency } from "../../../../utils/helpers";
import { FilterPopover } from "./filter-popover.component";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

const { Text } = Typography;
const { forwardRef } = React;
const TablePurchaseOrderComponent = forwardRef((props, ref) => {
  const storeId = useSelector((state) => state.session?.auth?.user?.storeId);
  const history = useHistory();
  const [t] = useTranslation();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();
  const [countFilter, setCountFilter] = useState(0);
  const filterPopoverRef = React.useRef();
  const [branches, setBranches] = useState([]);
  const [supplierFilter, setSupplierFilter] = useState([]);
  const [dataFilterAndSearch, setDataFilterAndSearch] = useState({
    keySearch: undefined,
    startDate: undefined,
    endDate: undefined,
    branchId: undefined,
    supplierId: undefined,
    statusId: undefined,
  });
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });

  const initDataFilter = async () => {
    var resBranch = await branchDataService.getAllBranchsAsync();
    if (resBranch) {
      setBranches(resBranch.branchs);
    }

    var supplierData = await supplierDataService.getAllSupplierAsync();
    if (supplierData) {
      var supplierFilterData = supplierData.suppliers.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setSupplierFilter(supplierFilterData);
    }
  };

  const pageData = {
    btnFilter: t("button.filter"),
    viewHistory: t("button.viewHistory"),
    table: {
      searchPlaceholder: t("purchaseOrder.searchBySupplierName"),
      code: t("table.code"),
      supplier: t("supplier.title"),
      branch: t("purchaseOrder.branch"),
      amount: t("purchaseOrder.amount"),
      status: t("table.status"),
      createdBy: t("table.createdBy"),
      createdDate: t("purchaseOrder.createdDate"),
    },
    today: t("optionDatetime.today"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    yesterday: "dashboard.compareDate.yesterday",
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("purchaseOrder.status.approved"),
      ordering: t("purchaseOrder.status.ordering"),
      canceled: t("purchaseOrder.status.canceled"),
      completed: t("purchaseOrder.status.completed"),
    },
    btnAddNew: t("button.addNew"),
    export: t("button.export"),
    import: t("button.import"),
    toCreateData: t("area.toCreateData"),
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.code,
        dataIndex: "id",
        key: "index",
        width: "10%",
        align: "center",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record?.code}</FnbTooltip>;
        },
      },
      {
        title: pageData.table.supplier,
        dataIndex: "supplier",
        key: "supplier",
        width: "28%",
        className: "table-text-supplier-overflow",
        sorter: (pre, current) => pre?.supplier.localeCompare(current?.supplier),
        ellipsis: true,
        render: (_, record) => {
          return (
            <>
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.name}>
                <span>{record?.supplier}</span>
              </FnbTooltip>
            </>
          );
        },
      },
      {
        title: pageData.table.branch,
        dataIndex: "branch",
        key: "index",
        width: "20%",
        className: "table-text-branch-overflow",
        ellipsis: true,
        render: (_, record) => {
          return (
            <>
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.name}>
                <span>{record?.branch}</span>
              </FnbTooltip>
            </>
          );
        },
      },
      {
        title: `${pageData.table.amount} (${getCurrency()})`,
        dataIndex: "amount",
        key: "index",
        align: "right",
        width: "16%",
        render: (_, record) => {
          return <Text>{formatNumberDecimalOrInteger(record.amount)}</Text>;
        },
      },
      {
        title: pageData.table.status,
        dataIndex: "status",
        key: "index",
        width: "9rem",
        align: "center",
        render: (_, record) => {
          switch (record?.status?.statusId) {
            case PurchaseOrderStatus.Approved:
              return <FnbBadge variant="primary" text={pageData.status.approved} />;
            case PurchaseOrderStatus.Canceled:
              return <FnbBadge variant="error" text={pageData.status.canceled} />;
            case PurchaseOrderStatus.Completed:
              return <FnbBadge variant="success" text={pageData.status.completed} />;
            case PurchaseOrderStatus.Ordering:
              return <FnbBadge variant="warning" text={pageData.status.ordering} />;
            default:
              return <FnbBadge variant="new" text={pageData.status.new} />;
          }
        },
      },
      {
        title: pageData.table.createdBy,
        dataIndex: "createdBy",
        key: "index",
        width: "13%",
        ellipsis: true,
        className: "table-text-createdBy-overflow",
        render: (_, record) => {
          return (
            <>
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.createdBy}>
                <span>{record?.createdBy}</span>
              </FnbTooltip>
            </>
          );
        },
      },
      {
        title: pageData.table.createdDate,
        dataIndex: "createdDate",
        key: "index",
        width: "13%",
        render: (_, record) => {
          return formatShortDate(formatDate(record?.createdDate, DateFormat.YYYY_MM_DD));
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync({
        pageNumber: page,
        pageSize: pageSize,
        keySearch: dataFilterAndSearch.keySearch ?? "",
        fromDate: dataFilterAndSearch.startDate,
        toDate: dataFilterAndSearch.endDate,
        branchId: dataFilterAndSearch.branchId,
        supplierId: dataFilterAndSearch.supplierId,
        status: dataFilterAndSearch.statusId,
      });
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        setDataFilterAndSearch({ ...dataFilterAndSearch, keySearch: keySearch });
        await fetchDatableAsync({
          pageNumber: 1,
          pageSize: tableSettings.pageSize,
          keySearch: keySearch,
          fromDate: dataFilterAndSearch.startDate,
          toDate: dataFilterAndSearch.endDate,
          branchId: dataFilterAndSearch.branchId,
          supplierId: dataFilterAndSearch.supplierId,
          status: dataFilterAndSearch.statusId,
        });
      });
    },
  };

  useEffect(() => {
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: "",
    });
    initDataFilter();
  }, []);

  const fetchDatableAsync = async ({
    pageNumber,
    pageSize,
    keySearch,
    fromDate,
    toDate,
    branchId,
    supplierId,
    status,
  }) => {
    const response = await purchaseOrderDataService.getAllPurchaseOrderAsync(
      pageNumber,
      pageSize,
      keySearch,
      fromDate,
      toDate,
      branchId,
      supplierId,
      status,
    );
    const data = response?.purchaseOrders.map((s) => mappingRecordToColumns(s));
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
    setDataSource(data);

    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
    setNumberRecordCurrent(numberRecordCurrent);
  };

  const mappingRecordToColumns = (item) => {
    return {
      id: item?.id,
      code: item?.code,
      supplier: item?.supplier?.name,
      branch: item?.storeBranch?.name,
      amount: calculateTotalOfMaterialPrice(item?.purchaseOrderMaterials),
      status: item?.status,
      createdDate: item?.createdTime,
      createdBy: item?.createdBy,
    };
  };

  /**
   * Calculate the total of material price
   * @param {*} orderMaterial
   * @returns total price
   */
  const calculateTotalOfMaterialPrice = (orderMaterial) => {
    let total = 0;
    orderMaterial.forEach((item) => {
      total += item?.total;
    });
    return total;
  };

  const getTableColumns = () => {
    return tableSettings.columns;
  };
  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
    setCountFilter(0);
    setDataFilterAndSearch({
      ...dataFilterAndSearch,
      branchId: "",
      supplierId: "",
      statusId: "",
    });
  };
  const handleFilter = (data) => {
    if (!data) return;
    const countFilter = Object.values(data).reduce((accumulator, currentValue) => {
      // Count except for string undefined, '' or status = 0
      if (currentValue || currentValue === 0) return ++accumulator;
      return accumulator;
    }, 0);

    setCurrentPageNumber(1);
    setCountFilter(countFilter);
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...data });
    fetchDatableAsync({
      pageNumber: 1,
      pageSize: tableSettings.pageSize,
      keySearch: dataFilterAndSearch.keySearch || "",
      fromDate: dataFilterAndSearch.startDate,
      toDate: dataFilterAndSearch.endDate,
      branchId: data.branchId,
      supplierId: data.supplierId,
      status: data.statusId,
    });
  };
  //#region
  const filterComponent = () => {
    return (
      <FilterPopover
        fetchDataPurchaseOrder={handleFilter}
        ref={filterPopoverRef}
        branches={branches}
        supplierFilter={supplierFilter}
      />
    );
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...date });
    fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      fromDate: date.startDate,
      toDate: date.endDate,
      keySearch: dataFilterAndSearch.keySearch || "",
      branchId: dataFilterAndSearch.branchId,
      supplierId: dataFilterAndSearch.supplierId,
      status: dataFilterAndSearch.statusId,
    });
  };

  const exportPurchaseOrder = () => {
    let languageCode = languageService.getLang();
    const link = document.createElement("a");
    let url = `${env.REACT_APP_ROOT_DOMAIN}/api${
      env.API_VERSIONS ?? ""
    }/purchaseorder/export?languageCode=${languageCode}&storeId=${storeId}&keySearch=${
      dataFilterAndSearch.keySearch ?? ""
    }`;
    if (dataFilterAndSearch.startDate && dataFilterAndSearch.endDate)
      url += `&fromDate=${dataFilterAndSearch.startDate}&toDate=${dataFilterAndSearch.endDate}`;
    if (dataFilterAndSearch.branchId) url += `&branchId=${dataFilterAndSearch.branchId}`;
    if (dataFilterAndSearch.supplierId) url += `&supplierId=${dataFilterAndSearch.supplierId}`;
    if (dataFilterAndSearch.statusId != undefined) url += `&status=${dataFilterAndSearch.statusId}`;
    var timeZone = new Date().getTimezoneOffset() / 60;
    url += `&timeZone=${timeZone}`;

    link.href = url;
    link.click();
  };

  //#endregion

  const onRowClick = (record, _index) => {
    history.push(`/inventory/detail-purchase-order/${record?.id}`);
  };

  const onClickViewHistory = () => {
    history.push("/inventory/purchase-order-history");
  };

  const onCreate = () => {
    history.push(`/inventory/purchase-order/create-new`);
  };

  const handleImport = () => {
    history.push("/inventory/purchase-orders/import");
  };

  return (
    <>
      <Form className="form-staff">
        <Card className="fnb-card-custom w-100">
          <Row className="form-staff">
            <FnbTable
              className="table-striped-rows table-purchase-order-management"
              dataSource={dataSource}
              columns={getTableColumns()}
              pageSize={tableSettings.pageSize}
              currentPageNumber={currentPageNumber}
              total={totalRecords}
              onChangePage={tableSettings.onChangePage}
              numberRecordCurrent={numberRecordCurrent}
              scrollX={1500}
              search={{
                placeholder: pageData.table.searchPlaceholder,
                onChange: tableSettings.onSearch,
                maxLength: 100,
              }}
              filter={{
                totalFilterSelected: countFilter,
                onClearFilter: onClearFilter,
                buttonTitle: pageData.btnFilter,
                component: filterComponent(),
              }}
              onRowClick={onRowClick}
              listButtons={[
                <FnbButton
                  permission={PermissionKeys.CREATE_PURCHASE}
                  onClick={onCreate}
                  text={pageData.btnAddNew}
                  iconHeader={<AddCircleIcon />}
                  className="fnb-heading-page__button-create"
                />,
                <FnbButton
                  variant="secondary-purple"
                  text={pageData.viewHistory}
                  onClick={onClickViewHistory}
                  iconHeader={<Note2Icon />}
                ></FnbButton>,
                <FnbButton
                  permission={PermissionKeys.CREATE_PURCHASE}
                  variant="secondary"
                  text={pageData.import}
                  onClick={handleImport}
                  iconHeader={<ImportOutlinedIcon></ImportOutlinedIcon>}
                ></FnbButton>,
                <FnbButton
                  permission={PermissionKeys.VIEW_PURCHASE}
                  variant="secondary"
                  text={pageData.export}
                  onClick={exportPurchaseOrder}
                  iconHeader={<ExportOutlinedIcon></ExportOutlinedIcon>}
                ></FnbButton>,
                <FnbDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
                  className="date-range-picker-filter"
                />,
              ]}
              emptyText={
                hasPermission(PermissionKeys.CREATE_PURCHASE) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.btnAddNew}
                      onClick={onCreate}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Row>
        </Card>
      </Form>
    </>
  );
});

export default TablePurchaseOrderComponent;
