import http from "../../utils/http-common";

const controller = "store";

const getPrepareRegisterNewStoreDataAsync = (data) => {
  return http.post(`/${controller}/get-prepare-register-new-store-data`, data);
};

const getStoreLogo = () => {
  return http.get(`/${controller}/get-store-logo`);
};

const registerNewStoreAccountAsync = (data) => {
  return http.post(`/${controller}/register-new-store-account`, data);
};

const getCurrencyByStoreId = () => {
  return http.get(`/${controller}/get-currency-by-store-id`);
};

const getPrepareAddressDataAsync = () => {
  return http.get(`/${controller}/get-prepare-address-data`);
};

const getStoreByIdAsync = () => {
  return http.get(`/${controller}/get-store-by-id`);
};

const updateStoreManagementAsync = (data) => {
  return http.put(`/${controller}/update-store`, data);
};

const getStoreBankAccountByStoreIdAsync = () => {
  return http.get(`/${controller}/get-store-bank-account-by-store-id`);
};

const getAllPlatformActivatedAsync = (isGetFromProduct = false, isGetFromOrderReport = false) => {
  return http.get(
    `/${controller}/get-all-platform-activated?isGetFromProduct=${isGetFromProduct}&isGetFromOrderReport=${isGetFromOrderReport}`,
  );
};

const activateAccountStoreAsync = (data) => {
  return http.put(`/${controller}/activate-account-store`, data);
};

const createSliderAsync = (data) => {
  return http.post(`/${controller}/create-slider`, data);
};

const getAvailableBranchQuantityAsync = () => {
  return http.get(`/${controller}/get-available-branch-quantity`);
};

const getCurrentOrderPackageInfoAsync = () => {
  return http.get(`/${controller}/get-current-package-info`);
};

const createBranchPurchaseOrderPackageAsync = (data) => {
  return http.post(`/${controller}/create-branch-purchase-order-package`, data);
};

const getStoreBannersAsync = (bannerType) => {
  return http.get(`/${controller}/get-store-banners/${bannerType}`);
};

const updateStoreBannersAsync = (data) => {
  return http.post(`/${controller}/update-store-banners`, data);
};

const updateStoreLogoAsync = (data) => {
  return http.post(`/${controller}/update-store-logo`, data);
};

const getThemesAsync = () => {
  return http.get(`/${controller}/get-themes`);
};

const getStoreInformationAsync = () => {
  return http.get(`/${controller}/information`);
};

const getStoreGoogleConfigAsync = () => {
  return http.get(`/${controller}/get-store-google-config`);
};

const getESMSConfig = () => {
  return http.get(`${controller}/get-esms-config`);
};

const updateESMSConfig = (data) => {
  return http.put(`${controller}/update-esms-config`, data);
};

const updateStoreGoogleConfigForPlatformAsync = (data) => {
  return http.post(`/${controller}/update-store-google-config-for-platform`, data);
};

const getStoreGoogleConfigForPlatformAsync = () => {
  return http.get(`${controller}/get-store-google-config-for-platform`);
};

const getReferralPointByStoreIdAsync = () => {
  return http.get(`/${controller}/referral-point-by-store-id`);
};

const modifyReferralPointAsync = (data) => {
  return http.put(`/${controller}/referral-point`, data);
};

const getStoreConfig = (storeId) => {
  return http.get(`/${controller}/get-store-config-data?storeId=${storeId}`);
};

const updateCustomDomainAsync = (data) => {
  return http.put(`/${controller}/custom-domain`, data);
};

const getStatusSystemGuidelineAsync = () => {
  return http.get(`/${controller}/get-status-system-guideline`);
};

const sentVerifyCodeRegisterEmailAsync = (data) => {
  return http.post(`/${controller}/register/send-code-to-email`, data);
};

const verifyCodeRegisterEmailAsync = (data) => {
  return http.put(`/${controller}/register/verify-code`, data);
};

const getTawkConfig = () => {
  return http.get(`${controller}/tawk-config`);
};

const getStoreSocialsSettings =()=> {
  return http.get(`/${controller}/settings`);
};

const storeDataService = {
  getPrepareRegisterNewStoreDataAsync,
  getStoreLogo,
  registerNewStoreAccountAsync,
  getCurrencyByStoreId,
  getPrepareAddressDataAsync,
  getStoreByIdAsync,
  updateStoreManagementAsync,
  getStoreBankAccountByStoreIdAsync,
  getAllPlatformActivatedAsync,
  activateAccountStoreAsync,
  createSliderAsync,
  getAvailableBranchQuantityAsync,
  getCurrentOrderPackageInfoAsync,
  createBranchPurchaseOrderPackageAsync,
  getStoreBannersAsync,
  updateStoreBannersAsync,
  updateStoreLogoAsync,
  getThemesAsync,
  getStoreInformationAsync,
  getStoreGoogleConfigAsync,
  getESMSConfig,
  updateESMSConfig,
  updateStoreGoogleConfigForPlatformAsync,
  getStoreGoogleConfigForPlatformAsync,
  getReferralPointByStoreIdAsync,
  modifyReferralPointAsync,
  getStoreConfig,
  updateCustomDomainAsync,
  getStatusSystemGuidelineAsync,
  sentVerifyCodeRegisterEmailAsync,
  verifyCodeRegisterEmailAsync,
  getTawkConfig,
  getStoreSocialsSettings
};

export default storeDataService;
