export const ReserveStatus = {
  Cancelled: 0,
  WaitToConfirm: 1,
  Confirmed: 2,
  Serving: 3,
  Completed: 4,
};

export const STATUS_ALL_RESERVE = -1;

export const MAX_SHORT_DESCRIPTION = 199;

export const ReserveDepositStatus = {
  NotReceived: 1,
  Received: 2,
  WaitToRefund: 3,
  Refunded: 4,
  NoRefund: 5,
};
