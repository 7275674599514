import { DocumentOutlined } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import CreateCustomerSegment from "pages/CRM/customer-segment/create-customer-segment";
import ListCustomerSegment from "pages/CRM/customer-segment/list-customer-segment/list-customer-segment.page";
import UpdateCustomerSegment from "pages/CRM/customer-segment/update-customer-segment";
import ViewCustomerSegment from "pages/CRM/customer-segment/view-customer-segment";
import CreateCustomerPage from "pages/CRM/customer/create-customer/create-customer.page";
import CustomerManagement from "pages/CRM/customer/customer-management/customer.page";
import DetailCustomerPage from "pages/CRM/customer/detail-customer";
import UpdateCustomerPage from "pages/CRM/customer/edit-customer";
import CreateCustomerMembershipPage from "pages/CRM/membership/create-membership";
import CustomerMembershipManagement from "pages/CRM/membership/membership-management/customer-membership.page";
import EditCustomerMembershipPage from "pages/CRM/membership/update-membership";
import i18n from "utils/i18n";
import ImportCustomer from "./customer/import-customer/import-customer.page";
import PointConfigurationPage from "pages/CRM/point-configuration/point-configuration.page";
import ReviewManagement from "./review";
const { t } = i18n;

const route = {
  key: "app.crm",
  position: 3,
  path: "#",
  icon: <DocumentOutlined />,
  name: t("menu.crm"),
  isMenu: true,
  exact: true,
  auth: true,
  child: [
    {
      key: "app.customer.management",
      position: 3,
      path: "/customer/management",
      name: t("menu.crmManagement.customer"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_CUSTOMER,
      component: CustomerManagement,
      child: [],
    },
    {
      key: "app.customer.segment",
      position: 3,
      path: "/customer/segment",
      name: t("menu.crmManagement.customerSegment"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_SEGMENT,
      component: ListCustomerSegment,
      child: [],
    },
    {
      key: "app.customer.segment.create",
      focus: "app.customer.segment",
      position: 3,
      path: "/customer/segment/create-new",
      name: "Create New Customer Segment",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_SEGMENT,
      component: CreateCustomerSegment,
      child: [],
    },
    {
      key: "app.customer.segment.edit",
      focus: "app.customer.segment",
      position: 3,
      path: "/customer/segment/edit/:customerSegmentId",
      name: "Edit Customer Segment",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_SEGMENT,
      component: UpdateCustomerSegment,
      child: [],
    },
    {
      key: "app.customer.segment.view",
      focus: "app.customer.segment",
      position: 3,
      path: "/customer/segment/view/:customerSegmentId",
      name: "View Customer Segment",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_SEGMENT,
      component: ViewCustomerSegment,
      child: [],
    },
    {
      key: "app.customer.management.create-customer",
      focus: "app.customer.management",
      position: 3,
      path: "/customer/create-new",
      name: "CustomerCreate",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_CUSTOMER,
      component: CreateCustomerPage,
      child: [],
    },
    {
      key: "app.customer.management.edit-customer",
      focus: "app.customer.management",
      position: 3,
      path: "/customer/edit/:customerId",
      name: "CustomerEdit",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_CUSTOMER,
      component: UpdateCustomerPage,
      child: [],
    },
    {
      key: "app.customer.management.detail-customer",
      focus: "app.customer.management",
      position: 3,
      path: "/customer/detail/:customerId",
      name: "CustomerDetail",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_CUSTOMER,
      component: DetailCustomerPage,
      child: [],
    },
    {
      key: "app.customer.membership",
      position: 3,
      path: "/membership/management",
      name: t("menu.crmManagement.membership"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_MEMBERSHIP_LEVEL,
      component: CustomerMembershipManagement,
      child: [],
    },
    {
      key: "app.customer.membership.create-membership",
      focus: "app.customer.membership",
      position: 3,
      path: "/membership/create",
      name: "MembershipCreate",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_MEMBERSHIP_LEVEL,
      component: CreateCustomerMembershipPage,
      child: [],
    },
    {
      key: "app.customer.membership.edit-membership",
      focus: "app.customer.membership",
      position: 3,
      path: "/membership/edit/:membershipId",
      name: "MembershipEdit",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_MEMBERSHIP_LEVEL,
      component: EditCustomerMembershipPage,
      child: [],
    },
    {
      key: "app.point.configuration",
      position: 3,
      path: "/customer/point/configuration",
      name: t("menu.crmManagement.pointConfiguration"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_LOYALTY_POINT,
      component: PointConfigurationPage,
      child: [],
    },
    {
      key: "app.customer.import-template",
      focus: "app.customer.management",
      position: 3,
      path: "/customer/import",
      name: "CustomerImport",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_CUSTOMER,
      component: ImportCustomer,
      child: [],
    },
    {
      key: "app.review.page",
      focus: "app.review.page",
      position: 4,
      path: "/review",
      name: t("permissionsGroup.review"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_REVIEW,
      component: ReviewManagement,
      child: [],
    },
  ],
};
export default route;
