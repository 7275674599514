import { Input, message, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import loginDataService from "../../../../../data-services/login-data.service";
import resetPasswordService from "../../../../../data-services/reset-password-data.service";
import {
  authActions,
  METHOD_SEND_OTP,
  resetPasswordSelector,
  STATUS_RESET_PASSWORD,
} from "../../../../../modules/auth/auth.reducer";
import { signInWithPhoneNumber, writeFirebaseLog } from "../../../../../services/auth/auth.services";
import { executeAfter } from "../../../../../utils/helpers";
import { localStorageKeys } from "../../../../../utils/localStorage.helpers";
import { ArrowLeftForgotPassword } from "../../../../assets/icons.constants";
import { IconBackForgotPassword, TitleHeading } from "./forgot-password.styled";
import {
  ButtonConfirmPhoneNumber,
  DescriptionInput,
  ErrorMessage,
  ForgotPasswordForm,
  Heading,
  InputPhoneNumberContainer,
  RegisterMessage,
  RowInputPhoneNumber,
  WrapperTextHeading,
} from "./input-phone-number.styled";
import { EnumInternationalRegion } from "../../../../constants/store-web-page.constants";
import { storeConfigSelector } from "../../../../../modules/session/session.reducers";
import { PhoneCodeConstants } from "../../../../constants/phone-number.constants";

const _3_MINUTES = 180;
const ERROR_TYPE_PASSWORD = {
  IDLE: 0,
  PLEASE_INPUT: 1,
  NOT_VALID: 2,
  NOT_EXIST: 3,
};
const ErrorMessagePassword = ({ errorType, t, colorConfig, handleRedirectToRegisterForm }) => {
  switch (errorType) {
    case ERROR_TYPE_PASSWORD.PLEASE_INPUT:
      return <ErrorMessage>{t("loginPage.pleaseEnterPhoneNumber")}</ErrorMessage>;
    case ERROR_TYPE_PASSWORD.NOT_VALID:
      return <ErrorMessage>{t("loginPage.invalidPhoneNumber")}</ErrorMessage>;
    case ERROR_TYPE_PASSWORD.NOT_EXIST:
      return (
        <>
          <ErrorMessage>
            {t("resetPassword.phoneNumberNotExistMessageHeading") + " "}
            <RegisterMessage onClick={handleRedirectToRegisterForm} textColor={colorConfig?.titleColor}>
              {t("loginPage.register")}
            </RegisterMessage>
            {" " + t("resetPassword.phoneNumberNotExistMessageFooter")}
          </ErrorMessage>
        </>
      );
    default:
      return <></>;
  }
};

const InputPhoneNumber = ({
  themePageConfig,
  countries,
  defaultIso,
  countryOptions,
  t,
  handleRedirectToRegisterForm,
}) => {
  const history = useHistory();
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE_PASSWORD.IDLE);
  const [requestingConfirm, setRequestingConfirm] = useState(false);
  const dispatch = useDispatch();
  const { currentMethodSendOTP, iso, phoneNumber, phoneCode } = useSelector(resetPasswordSelector);
  const storeType = useSelector(storeConfigSelector)?.type ?? EnumInternationalRegion.VIETNAM;

  const pageData = {
    forgotPassword: t("resetPassword.forgotPassword"),
    inputPhoneNumberToReset: t("resetPassword.inputPhoneNumberToReset"),
    phoneNumber: t("myProfile.accountInfo.phoneNumber"),
    confirm: t("myProfile.accountInfo.confirm"),
    cannotSendOTP: t("resetPassword.cannotSendOTP"),
  };

  useEffect(() => {
    if (!iso || !phoneCode) {
      if (defaultIso) {
        const country = countries.find((c) => c.iso === defaultIso);
        if (country) {
          dispatch(
            authActions.setResetPasswordState({
              phoneCode: country.phonecode,
              iso: country.iso,
            }),
          );
        }
      } else {
        fetch("https://ipinfo.io/json")
          .then((response) => response.json())
          .then((ipinfo) => {
            const { country } = ipinfo;
            if (!ipinfo || ipinfo?.length === 0) {
              getCountryCodeDefault();
            } else {
              dispatch(
                authActions.setResetPasswordState({
                  iso: country,
                  phoneCode: PhoneCodeConstants[country],
                }),
              );
            }
          })
          .catch((_error) => {
            getCountryCodeDefault();
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryCodeDefault = () => {
    let iso = "";
    if (storeType === EnumInternationalRegion.VIETNAM) {
      iso = "VN";
    } else {
      iso = "US";
    }
    dispatch({
      iso: iso,
      phoneCode: PhoneCodeConstants[iso],
    });
  };
  const onChangeCountry = (iso) => {
    const country = countries.find((c) => c.iso === iso);
    if (country) {
      dispatch(
        authActions.setResetPasswordState({
          iso: country.iso,
          phoneCode: country.phonecode,
        }),
      );
    }
    if (firstSubmit) {
      setErrorType(validatePhoneNumber(phoneNumber));
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return ERROR_TYPE_PASSWORD.PLEASE_INPUT;
    const isValid = isValidPhoneNumber(phoneNumber, iso);
    if (!isValid) return ERROR_TYPE_PASSWORD.NOT_VALID;
    return ERROR_TYPE_PASSWORD.IDLE;
  };

  const onChangePhoneNumber = (event) => {
    executeAfter(500, () => {
      const phoneNumber = event.target.value;
      dispatch(
        authActions.setResetPasswordState({
          phoneNumber: phoneNumber,
        }),
      );
      if (firstSubmit) {
        setErrorType(validatePhoneNumber(phoneNumber));
      }
    });
  };
  const onConfirmForgetPassword = async () => {
    const errorType = validatePhoneNumber(phoneNumber);
    setErrorType(errorType);
    setFirstSubmit(true);
    if (errorType !== ERROR_TYPE_PASSWORD.IDLE) return;
    const storeId = JSON.parse(localStorage.getItem(localStorageKeys.STORE_CONFIG))?.storeId;
    const payload = {
      phoneNumber: parsePhoneNumber(phoneNumber, iso).nationalNumber,
      storeId,
    };
    setRequestingConfirm(true);
    const response = await resetPasswordService.generateTokenAsync(payload);
    if (response.data?.succeeded) {
      dispatch(
        authActions.setResetPasswordState({
          token: response.data?.data,
          currentMethodSendOTP: response.data?.data?.currentMethodSendOTP,
        }),
      );
      const expireTime = moment(response.data?.data?.expiredTime).add(-7, "minutes"); // Time expire of token is 10 minutes -7 minutes to get 3 minutes resend
      const duration = moment.duration(expireTime.diff(moment.utc()));
      const seconds = parseInt(duration.asSeconds());
      if (seconds < _3_MINUTES - 5) {
        if (response.data?.data?.currentMethodSendOTP === METHOD_SEND_OTP.FIREBASE && !window.confirmationResult) {
          sendRequestOTP();
        } else {
          dispatch(authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_OTP }));
          setRequestingConfirm(false);
        }
      } else {
        sendRequestOTP();
      }
    } else {
      if (!response.data?.data?.isPhoneNumberExist) {
        setErrorType(ERROR_TYPE_PASSWORD.NOT_EXIST);
        setRequestingConfirm(false);
      }
    }
  };

  const sendRequestOTP = async () => {
    const phoneNumberDto = parsePhoneNumber(phoneNumber, iso);
    //Send OTP with firebase
    if (currentMethodSendOTP === METHOD_SEND_OTP.FIREBASE) {
      signInWithPhoneNumber(phoneNumberDto.number, ({ success, data, message }) => {
        if (success) {
          dispatch(authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_OTP }));
          setRequestingConfirm(false);
        } else {
          handleOTPGoSell();
          writeFirebaseLog(message);
        }
      });
    }
    if (currentMethodSendOTP === METHOD_SEND_OTP.GO_SELL) {
      handleOTPGoSell();
    }
  };

  const handleOTPGoSell = async () => {
    dispatch(authActions.setResetPasswordState({ currentMethodSendOTP: METHOD_SEND_OTP.GO_SELL }));
    const phoneNumberDto = parsePhoneNumber(phoneNumber, iso);
    const payload = { countryCode: iso, phone: phoneNumberDto.nationalNumber, phoneCode: phoneCode };
    const response = await loginDataService.sendOTPGoSellAsync(payload);
    if (response?.data?.data) {
      dispatch(authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_OTP }));
    } else {
      message.error(pageData.cannotSendOTP);
    }
    setRequestingConfirm(false);
  };

  return (
    <ForgotPasswordForm>
      <Heading>
        <IconBackForgotPassword loading={requestingConfirm}>
          <ArrowLeftForgotPassword
            onClick={() => {
              if (!requestingConfirm) {
                dispatch(authActions.resetState());
                history.push("/login");
              }
            }}
          />
        </IconBackForgotPassword>

        <WrapperTextHeading>
          <TitleHeading style={{ color: themePageConfig?.colorGroup?.titleColor }}>
            {pageData.forgotPassword}
          </TitleHeading>
          <DescriptionInput>
            {pageData.inputPhoneNumberToReset}
          </DescriptionInput>
        </WrapperTextHeading>
      </Heading>
      <InputPhoneNumberContainer>
        <RowInputPhoneNumber>
          <Select
            className="select-phone-code"
            onChange={onChangeCountry}
            value={`+${phoneCode}`}
            loading={!phoneCode}
            popupClassName="select-phone-code-forgot-password-pop-up"
          >
            {countryOptions}
          </Select>
          <Input
            placeholder={pageData.phoneNumber}
            allowClear={true}
            defaultValue={phoneNumber}
            onChange={onChangePhoneNumber}
          />
        </RowInputPhoneNumber>
        <ErrorMessagePassword
          errorType={errorType}
          t={t}
          colorConfig={themePageConfig?.colorGroup}
          handleRedirectToRegisterForm={handleRedirectToRegisterForm}
        />
      </InputPhoneNumberContainer>
      <ButtonConfirmPhoneNumber
        loading={requestingConfirm}
        backgroundColor={themePageConfig?.colorGroup?.buttonBackgroundColor}
        textColor={themePageConfig?.colorGroup?.buttonTextColor}
        onClick={onConfirmForgetPassword}
      >
        {pageData.confirm}
      </ButtonConfirmPhoneNumber>
    </ForgotPasswordForm>
  );
};

export default InputPhoneNumber;
