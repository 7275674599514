import http from "../../utils/http-common";

const controller = "resetPassword";

const getStoreByEmailAsync = (data) => {
  return http.post(`/${controller}/store-by-email`, data);
};

const resetPasswordAsync = (data) => {
  return http.post(`/${controller}`, data);
};

const sendMailResetPasswordAsync = (params) => {
  return http.get(`/${controller}/send-mail-reset-password`, {
    params,
  });
};

const resetPasswordDataService = {
  getStoreByEmailAsync,
  resetPasswordAsync,
  sendMailResetPasswordAsync,
};
export default resetPasswordDataService;
