const actionTypes = {
  SET_STORE_INFO: "SET_STORE_INFO",
  SET_PREPARE_ADDRESS_DATA: "SET_PREPARE_ADDRESS_DATA",
  SET_AUTH: "SET_AUTH",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_WORKSPACE: "SET_WORKSPACE",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  RESET_SESSION: "RESET_SESSION",
  LANGUAGE_SESSION: "LANGUAGE_SESSION",
  SET_THUMBNAIL: "SET_THUMBNAIL",
  SET_PERMISSION_GROUP: "SET_PERMISSION_GROUP",
  SET_FULL_NAME: "SET_FULL_NAME",
  STORE_LOGO: "STORE_LOGO",
  SET_THEME_CUSTOMIZE_CONFIG: "SET_THEME_CUSTOMIZE_CONFIG",
  SET_THEME_CUSTOMIZE_CONFIG_DEFAULT: "SET_THEME_CUSTOMIZE_CONFIG_DEFAULT",
  SET_THEME_CUSTOMIZE_MENU: "SET_THEME_CUSTOMIZE_MENU",
  SET_PREPARE_DATA_FOR_BANNER: "SET_PREPARE_DATA_FOR_BANNER",
  SET_CART_ITEMS: "SET_CART_ITEMS",
  SET_INFORMATION_PUBLISH_STORE: "SET_INFORMATION_PUBLISH_STORE",
  SET_REQUEST_RENDER_THEME_CUSTOMIZE: "SET_REQUEST_RENDER_THEME_CUSTOMIZE",
  SET_SELECTED_SUB_MENU_ID: "SET_SELECTED_SUB_MENU_ID",
  SET_POS_CART_ITEMS: "SET_POS_CART_ITEMS",
  SET_THEME_FONT: "SET_THEME_FONT",
};

export default actionTypes;
