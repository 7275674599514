import { Row, Image } from "antd";
import { useTranslation } from "react-i18next";
import { FnbTable } from "components/fnb-table/fnb-table";
import storeDataService from "data-services/store/store-data.service";
import { useEffect, useState } from "react";
import "./banktransfer-payment-config.component.scss";
import { CrownIcon } from "constants/icons.constants";
import { BankAccountDialogComponent } from "./bank-account-dialog.component";
import { BANK_DATA } from "constants/bank.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import { useSelector } from "react-redux";
import { settingsSelector } from "store/modules/settings/settings.reducer";

const BankTransferPaymentConfigComponent = (props) => {
  const { onChangeForm, IsUnActivated } = props;
  const { t } = useTranslation();
  const [dataTable, setDataTable] = useState([]);
  const [showEditBankTranferForm, setShowEditBankTranferForm] = useState(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const bankAccountInfo = useSelector(settingsSelector)?.bankAccountInfo;

  const dataBankInfo = BANK_DATA;
  const pageData = {
    no: t("table.no"),
    accountHolder: t("storeBankAccount.accountHolder"),
    bankQRCode: t("storeBankAccount.bankQr"),
    action: t("table.action"),
    mainAccount: t("storeBankAccount.mainAccount"),
    titleBank: t("storeBankAccount.title"),
    accountNumber: t("storeBankAccount.accountNumber"),
    bank: t("storeBankAccount.bankName"),
    notificationEditMessage: t("storeBankAccount.notificationEditMessage"),
    notificationTitle: t("form.notificationTitle"),
    button: {
      cancel: t("button.cancel"),
      ignore: t("button.ignoreEdit"),
    },
  };
  const [addressData, setAddressData] = useState({});
  const tableSettings = {
    columns: [
      {
        title: pageData.no,
        width: "5rem",
        render: (_, record, index) => {
          return index + 1;
        },
      },
      {
        title: pageData.accountHolder,
        width: "26.26%",
        render: (_, record) => {
          return (
            <div className="row-accountHolder">
              <FnbTooltip onlyShowWhenEllipsis={true}>{record.accountHolder}</FnbTooltip>
              {record.isMainAccount && (
                <span className="tag-highlight">
                  <CrownIcon width={20} height={20}></CrownIcon>
                  {pageData.mainAccount}
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: pageData.bank,
        render: (_, record) => {
          return (
            <Row className="row-bank">
              {record?.thumbnail && <Image width={32} height={32} preview={false} src={record?.thumbnail} />}
              <FnbTooltip onlyShowWhenEllipsis={true}>
                {dataBankInfo.find((bank) => bank.bin === record?.acpId)?.short_name}
              </FnbTooltip>
            </Row>
          );
        },
      },
      {
        title: pageData.accountNumber,
        width: "19.23%",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record.accountNumber}</FnbTooltip>;
        },
      },
      {
        title: pageData.bankQRCode,
        width: "19.23%",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record.bankQR}</FnbTooltip>;
        },
      },
    ],
  };

  useEffect(() => {
    getInitDataAsync();
  }, []);

  const getInitDataAsync = async () => {
    let promises = [];
    promises.push(storeDataService.getPrepareAddressDataAsync());
    promises.push(storeDataService.getStoreByIdAsync());

    let [prepareAddressDataResponse, storeDataResponse] = await Promise.all(promises);
    if (storeDataResponse) {
      setAddressData(prepareAddressDataResponse);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (bankAccountInfo) {
      setDataTable([mapStoreBankAccountToTableItem(bankAccountInfo)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountInfo]);

  const mapStoreBankAccountToTableItem = (data) => {
    return {
      ...data,
      bankQR: "",
      isMainAccount: true,
      thumbnail: dataBankInfo?.find((bankInfo) => bankInfo?.bin === data?.acpId)?.logo,
    };
  };

  const renderDialog = () => {
    if (showEditBankTranferForm) {
      return (
        <BankAccountDialogComponent
          onChangeForm={onChangeForm}
          isModalVisible={showEditBankTranferForm}
          storeBankAccount={dataTable[0]}
          prepareAddressDataResponse={addressData}
          t={t}
          handleCancel={() => handleCancelDialog()}
          handleSuccess={() => handleSuccessDialog()}
        />
      );
    } else {
      return <></>;
    }
  };

  const handleCancelDialog = () => {
    setShowEditBankTranferForm(false);
  };

  const handleSuccessDialog = () => {
    handleCancelDialog();
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onEditRowKeys = (selectedRowKeys, selectedRows) => {
    setShowEditBankTranferForm(true);
  };

  return (
    <div className="BankTransferPayment">
      <Row className="component-body">
        <FnbTable
          columns={tableSettings.columns}
          pageSize={tableSettings.pageSize}
          dataSource={dataTable.filter((item) => item !== null && item !== undefined)}
          scrollX={1144}
          editPermission={IsUnActivated}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditRowKeys,
          }}
        ></FnbTable>
      </Row>
      {renderDialog()}
      <ConfirmDialogComponent
        width={578}
        visible={isModalNotificationVisible}
        onOk={() => {
          setShowEditBankTranferForm(true);
          setIsModalNotificationVisible(false);
        }}
        onCancel={() => {
          setIsModalNotificationVisible(false);
        }}
        content={pageData.notificationEditMessage}
        cancelText={pageData.button.cancel}
        okText={pageData.button.ignore}
        type={ConfirmStyle.NOTIFICATION}
      />
    </div>
  );
};

export default BankTransferPaymentConfigComponent;
