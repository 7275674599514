import React from "react";
import "./info-card.scss";
import packageWeb from "../../assets/images/package-web.png";
import packageApp from "../../assets/images/package-app.png";
import packagePos from "../../assets/images/package-pos.png";
import packageBranch from "../../assets/images/package-branch.png";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useTranslation } from "react-i18next";
import { isStoreInternational } from "utils/helpers";
import { PackageName } from "constants/package.constants";

function InfoCardComponent({ data = {}, className = "", showBillingComponent }) {
  const { title = "", childCards = [], isDataExpiry, isDataDefault, expiryDate } = data;
  const [t] = useTranslation();
  const isInternational = isStoreInternational();

  const mappings = {
    WEB: {
      title: t("packageTable.webTitle"),
      description: t("packageTable.webDescription"),
      thumbnail: packageWeb,
    },
    Branch: {
      title: t("packageTable.branchesTitle"),
      description: t("packageTable.branchesDescription"),
      thumbnail: packageBranch,
    },
    APP: {
      title: t("packageTable.appTitle"),
      description: t("packageTable.appDescription"),
      thumbnail: packageApp,
    },
    POS: {
      title: t("packageTable.posTitle"),
      description: t("packageTable.posDescription"),
      thumbnail: packagePos,
    },
  };
  const { title: displayTitle, description, thumbnail } = mappings[title] || {};

  const handleChangeScreen = (isBranchTitle) => {
    if (isBranchTitle) {
      window.location.href = "/branch/branch-purchase";
    } else showBillingComponent();
  };

  return (
    <div className={`info-card-component ${className}`}>
      <img src={thumbnail} alt={displayTitle} height={152} width={172} />
      <div className="card-right">
        <div className="title">{displayTitle}</div>
        {isDataExpiry ? (
          <div className="expired-on">
            {t("packageTable.expiredOn")} {expiryDate}
          </div>
        ) : (
          <div className="description">{description}</div>
        )}
        {isInternational && title === PackageName.APP ? (
          <>
            <span className="coming-soon-btn">
              {t("configuration.comingSoon")}
            </span>
          </>
        ) : (
          <>
            {isDataDefault && (
              <FnbButton onClick={() => handleChangeScreen(childCards[0]?.isBranchTitle)} text={t("package.buyNow")} />
            )}
            {isDataExpiry && (
              <FnbButton
                onClick={() => handleChangeScreen(childCards[0]?.isBranchTitle)}
                text={t("packageTable.renewPlan")}
              />
            )}
            {!isDataExpiry && !isDataDefault && (
              <div className="label-card">
                {childCards.length > 0 &&
                  childCards.map((item, index) => (
                    <div key={index} className="child-card">
                      <div className="title">{t(item.title)}:</div>
                      <div>
                        <span className="value">{item.value}</span>
                        <span className="value-extra">
                          {item?.isBranchTitle && " (" + t("packageTable.include1free") + ")"}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(InfoCardComponent, (prevProps, nextProps) => prevProps.data === nextProps.data);
