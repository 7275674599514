import { ArrowDownOutlined, BranchOutlined } from "constants/icons.constants";
import branchDataService from "data-services/branch/branch-data.service";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./fnb-list-branches.scss";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import i18next from "i18next";

export const FnbListBranches = forwardRef((props, ref) => {
  const { onChangeEvent, showAllBranch = true, initSelectedBranchId = null, className = "", isSelectFirstBranch = false } = props;
  const [t] = useTranslation();
  const [branches, setBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState(null);
  const pageData = {
    allBranch: t("dashboard.allBranch"),
  };

  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(branches?.length > 0 && isSelectFirstBranch) {
      setBranchSelected(branches?.[0]?.id);
      onChangeEvent && onChangeEvent(branches?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  useEffect(() => {
    if (initSelectedBranchId) {
      setBranchSelected(initSelectedBranchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSelectedBranchId]);

  useImperativeHandle(ref, () => ({
    setSelectedAllBranch: () => {
      handleChangeBranch(null);
    },
  }));

  const getBranches = async () => {
    const branchesResponse = await branchDataService.getAllBranchsAsync();
    if (branchesResponse) {
      setBranches(branchesResponse.branchs);
    }
  };

  const handleChangeBranch = (e) => {
    let branchIdSelected = e;
    if (branchIdSelected !== null) {
      setBranchSelected(branchIdSelected);
    } else {
      branchIdSelected = "";
      setBranchSelected(null);
    }

    if (onChangeEvent) {
      onChangeEvent(branchIdSelected);
    }
  };

  const listOptions = useMemo(() => {
    let options = branches?.map((item, index) => ({
      id: item.id,
      name: item.name,
      key: index + item.id,
    }));
    if (showAllBranch) {
      return [
        {
          name: pageData.allBranch,
          id: null,
          key: null,
        },
        ...options,
      ];
    }
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, i18next.language]);

  return (
    <div style={{ position: "relative" }}>
      <FnbSelectSingle
        prefixIcon={null}
        value={branchSelected}
        className={`fnb-select-branch ${className}`}
        option={listOptions}
        popupClassName="fnb-select-branch-drop-down"
        onChange={handleChangeBranch}
        suffixIcon={<ArrowDownOutlined className="fnb-select-branch__suffix-icon" width={14} height={14} />}
      />
      <BranchOutlined className="fnb-select-branch__prefix-icon" />
    </div>
  );
});
