import { Card } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TablePageManagement from "./components/table-page-management.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

export function PageManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const pageCreateLink = "/online-store/page-management/create-page-management";

  const pageData = {
    title: t("onlineStore.pageManagement.title"),
    addNew: t("button.addNew"),
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.title}
        permissionCreate={PermissionKeys.CREATE_PAGE}
        onCreate={() => history.push(pageCreateLink)}
      />
      <Card className="fnb-card-custom">
        <TablePageManagement />
      </Card>
    </>
  );
}
