import { Row, Typography, Space } from "antd";
import { useTranslation } from "react-i18next";
import FnbSwitch from "components/switch";
import "./point-configuration.page.style.scss";
import React, { useEffect, useRef, useState } from "react";
import ReferralPointConfiguration from "../referral-point/referral-point-configuration.page";
import LoyaltyPointConfiguration from "../loyalty-point/loyalty-point-configuration.page";
import loyaltyPointConfigDataService from "data-services/loyalty-point-config/loyalty-point.service";
import referralPointConfigDataService from "data-services/referral-point-config/referral-point.service";
import customerDataService from "data-services/customer/customer-data.service";
import storeDataService from "data-services/store/store-data.service";
import { message } from "antd";
// import { FnbTabsV2 } from "components/fnb-tabs/fnb-tabs.component.v2";
import { FnbTabsV2 } from "components/fnb-tabs/fnb-tabs.component.v2";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import ComingSoonTag from "components/coming-soon-tag";

const { Text, Title } = Typography;

const screens = {
  LoyaltyPointConfiguration: 1,
  refer: 2,
};

export default function PointConfiguration(props) {
  const [t] = useTranslation();
  const [isReferralDataNotSave, setIsReferralDataNotSave] = useState(true);
  const [loyaltyPointData, setLoyaltyPoint] = useState({});
  const [referralPointData, setReferralPointData] = useState({});
  const [switcherLoyaltyPointConfig, setSwitcherLoyaltyPointConfig] = useState(false);
  const [switcherReferralPointConfig, setSwitcherReferralPointConfig] = useState(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [defaultScreen, setDefaultScreen] = useState(screens.LoyaltyPointConfiguration);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const screenRef = useRef();
  const loyaltyPointConfigRef = useRef();
  const referralPointConfigRef = useRef();

  const pageData = {
    loyaltyPointDetail: t("loyaltyPoint.title"),
    referralPoint: t("referralPoint.title"),
    pointConfiguration: t("menu.crmManagement.pointConfiguration"),
    message: {
      success: t("messages.updateSuccess"),
      failed: t("messages.updateFailed"),
    },
  };

  useEffect(async () => {
    fetchInitDataLoyaltyConfig();
    fetchInitDataReferralConfig();
  }, []);

  const fetchInitDataLoyaltyConfig = async () => {
    let resLoyalty = await customerDataService.getLoyaltyPointByStoreIdAsync();
    if (resLoyalty) {
      if (resLoyalty.hasData) {
        setLoyaltyPoint(resLoyalty);
        setSwitcherLoyaltyPointConfig(resLoyalty.configuration?.isActivated);
      }
    }
  };

  const fetchInitDataReferralConfig = async () => {
    let resReferral = await storeDataService.getReferralPointByStoreIdAsync();
    if (resReferral) {
      if (resReferral.hasData) {
        setReferralPointData(resReferral);
        setSwitcherReferralPointConfig(resReferral.configuration?.isActivated);
      }
    }
  };

  const onSwitchLoyaltyPointConfig = async (value) => {
    const data = {
      active: value,
    };
    setSwitcherLoyaltyPointConfig(value);
    //Use setTimout for prevent delaying switcher with api
    setTimeout(async () => {
      var response = await loyaltyPointConfigDataService.activeLoyaltyPointAsync(data);
      if (response.success) {
        message.success(pageData.message.success);
      } else {
        message.error(pageData.message.failed);
        setSwitcherLoyaltyPointConfig(!value);
      }
    }, 200);
  };

  const onSwitchReferralPointConfig = (value) => {
    const data = {
      active: value,
    };
    setSwitcherReferralPointConfig(value);
    //Use setTimout for prevent delaying switcher with api
    setTimeout(async () => {
      var response = await referralPointConfigDataService.activeReferralPointAsync(data);
      if (response.success) {
        message.success(pageData.message.success);
      } else {
        message.error(pageData.message.failed);
        setSwitcherReferralPointConfig(!value);
      }
    }, 200);
  };

  const handleChangeForm = (value) => setIsChangeForm(value);

  const tabs = [
    {
      screen: screens.LoyaltyPointConfiguration,
      title: (
        <>
          <Text className="fnb-tab__txt-tab">{pageData.loyaltyPointDetail}</Text>
          <FnbSwitch
            className="fnb-tab__switcher-tab"
            onChange={(value) => onSwitchLoyaltyPointConfig(value)}
            checked={switcherLoyaltyPointConfig}
          />
        </>
      ),
      component: (
        <LoyaltyPointConfiguration
          ref={loyaltyPointConfigRef}
          onChangeForm={handleChangeForm}
          switcherLoyaltyPointConfig={switcherLoyaltyPointConfig}
          fetchInitData={fetchInitDataLoyaltyConfig}
          res={loyaltyPointData}
        />
      ),
    },
    {
      screen: screens.refer,
      title: (
        <div className="fnb-tab__wrapper">
          <Text className="fnb-tab__txt-tab">
            {pageData.referralPoint}
            <ComingSoonTag />
          </Text>
          <FnbSwitch
            className="fnb-tab__switcher-tab"
            onChange={(value) => onSwitchReferralPointConfig(value)}
            checked={switcherReferralPointConfig}
            disabled
          />
        </div>
      ),
      component: (
        <ReferralPointConfiguration
          ref={referralPointConfigRef}
          onChangeForm={handleChangeForm}
          fetchInitData={fetchInitDataReferralConfig}
          res={referralPointData}
          setIsDataSave={(isDataSave) => setIsReferralDataNotSave(isDataSave)}
          switcherReferralPointConfig={switcherReferralPointConfig}
        />
      ),
    },
  ];

  const handleOnChangeTab = (screen) => {
    if (isChangeForm) {
      setIsModalNotificationVisible(true);
      screenRef.current = screen;
    } else {
      setDefaultScreen(screen);
      setIsModalNotificationVisible(false);
    }
  };

  const handleHiddenConfirmModal = () => setIsModalNotificationVisible(false);
  const handleConfirmModal = () => {
    loyaltyPointConfigRef && loyaltyPointConfigRef.current.reload();
    referralPointConfigRef && referralPointConfigRef.current.reload();
    setIsModalNotificationVisible(false);
    setIsChangeForm(false);
    setDefaultScreen(screenRef.current);
  };

  return (
    <div className="point-config">
      <Space class="point-config__space">
        <Row className="point-config__row">
          <Title className="point-config__title" level={3}>
            {pageData.pointConfiguration}
          </Title>
        </Row>
        <FnbTabsV2 defaultScreen={defaultScreen} onChangeTab={handleOnChangeTab} offsetHeight={158} tabs={tabs} />
      </Space>

      <ConfirmDialogComponent
        visible={isModalNotificationVisible}
        onCancel={handleHiddenConfirmModal}
        onOk={handleConfirmModal}
        type={ConfirmStyle.LEAVER}
      />
    </div>
  );
}
