import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import { images } from "constants/images.constants";
import { FnbButton } from "components/fnb-button/fnb-button";
import storeDataService from "data-services/store/store-data.service";
import { CustomerScreenType } from "constants/store-setting.constants";
import { message } from "antd";
import FnbUploadMultipleImages, {
  POSITION_PREVIEW_IMAGE,
} from "components/fnb-upload-multiple-images/fnb-upload-multiple-images";
import { InfoCircleFlashSaleIcon } from "constants/icons.constants";
import { useSelector } from "react-redux";

const CustomerScreen = (props) => {
  const { t, isChangeForm, onChangeForm } = props;
  const listFullScreenUploadRef = useRef(null);
  const listLeftSideUploadRef = useRef(null);
  const [listDefaultFullScreen, setListDefaultFullScreen] = useState([]);
  const [listDefaultLeftSide, setListDefaultLeftSide] = useState([]);
  const storeId = useSelector((state) => state?.session?.currentUser?.storeId);

  const pageData = {
    annotate: t("configuration.customerScreen.annotate"),
    button: {
      cancel: t("button.cancel"),
      saveChanges: t("button.saveChanges"),
    },
    fullScreen: t("storeBanner.fullscreenTitle"),
    leftSideTitle: t("storeBanner.leftSideTitle"),
    displayWhenNoOrder: t("configuration.customerScreen.displayWhenNoOrder"),
    displayWhenOrdering: t("configuration.customerScreen.displayWhenOrdering"),
  };

  const initData = async () => {
    const responseFullScreen = await storeDataService.getStoreBannersAsync(CustomerScreenType.FULL_SCREEN);
    if (responseFullScreen) {
      let newListThumbnailFullScreen = responseFullScreen?.thumbnails.map((item) => {
        return {
          url: item,
        };
      });
      setListDefaultFullScreen([...newListThumbnailFullScreen]);
      listFullScreenUploadRef.current.setImages(newListThumbnailFullScreen);
    }

    const responseLeftSide = await storeDataService.getStoreBannersAsync(CustomerScreenType.LEFT_SIDE);
    if (responseLeftSide) {
      let newListThumbnailLeftSide = responseLeftSide?.thumbnails.map((item) => {
        return {
          url: item,
        };
      });
      setListDefaultLeftSide([...newListThumbnailLeftSide]);
      listLeftSideUploadRef.current.setImages(newListThumbnailLeftSide);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const onCancel = () => {
    listFullScreenUploadRef.current.resetData(listDefaultFullScreen);
    listLeftSideUploadRef.current.resetData(listDefaultLeftSide);
    onChangeForm(false);
  };

  const onSave = async () => {
    let thumbnailsFullScreen = [];
    let thumbnailsLeftSide = [];

    const listImageFullScreen = listFullScreenUploadRef.current.getImages();
    const listImageLeftSide = listLeftSideUploadRef.current.getImages();
    listImageFullScreen?.forEach((item) => {
      thumbnailsFullScreen.push(item?.url);
    });
    listImageLeftSide?.forEach((item) => {
      thumbnailsLeftSide.push(item?.url);
    });

    const payloadFullscreen = {
      storeId: storeId,
      thumbnails: thumbnailsFullScreen,
      type: CustomerScreenType.FULL_SCREEN,
    };
    const payloadLeftSide = {
      storeId: storeId,
      thumbnails: thumbnailsLeftSide,
      type: CustomerScreenType.LEFT_SIDE,
    };

    const responseFullScreen = await storeDataService.updateStoreBannersAsync(payloadFullscreen);
    const responseLeftSide = await storeDataService.updateStoreBannersAsync(payloadLeftSide);
    if (responseFullScreen.success && responseLeftSide.success) {
      message.success("Update customer screen successfully");
      onChangeForm(false);
    } else {
      message.error("Error while updating customer screen");
    }
  };

  return (
    <div className="customer-screen-configuration">
      <div className="heading-screen-operation">
        <div className="contain-description">
          <InfoCircleFlashSaleIcon height={16} width={16} />
          <span className="body-operation">{pageData.annotate}</span>
        </div>
        {isChangeForm && (
          <div className="list-group-button">
            <FnbButton text={pageData.button.cancel} variant="tertiary" onClick={onCancel} />
            <FnbButton text={pageData.button.saveChanges} width="120px" onClick={onSave} />
          </div>
        )}
      </div>

      <div className="section-upload-image">
        <FnbUploadMultipleImages
          positionPreview={POSITION_PREVIEW_IMAGE.CENTER}
          title={pageData.fullScreen}
          maxItems={5}
          ref={listFullScreenUploadRef}
          setIsChangeData={onChangeForm}
          parentId={CustomerScreenType.FULL_SCREEN.toString()}
          descriptionForDisplay={pageData.displayWhenNoOrder}
        />
        <FnbUploadMultipleImages
          positionPreview={POSITION_PREVIEW_IMAGE.LEFT}
          title={pageData.leftSideTitle}
          imageOverlayFront={images.imagePreviewLeftBanner}
          maxItems={5}
          ref={listLeftSideUploadRef}
          setIsChangeData={onChangeForm}
          parentId={CustomerScreenType.LEFT_SIDE.toString()}
          descriptionForDisplay={pageData.displayWhenOrdering}
        />
      </div>
    </div>
  );
};

export default CustomerScreen;
