/* eslint-disable jsx-a11y/anchor-is-valid */
import { message, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { DeleteOutlined } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import menuManagementDataService from "data-services/menu-management/menu-management-data.service";
import multilevelMenuDataService from "data-services/multilevel-menu/multilevel-menu-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { hasPermission } from "utils/helpers";
import "../menu-management.page.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";

export default function TableMenuManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [showModalCanDelete, setShowModalCanDelete] = useState(false);
  const [contentModalCanDelete, setContentModalCanDelete] = useState("");
  const [showModalCanNotDelete, setShowModalCanNotDelete] = useState(false);
  const [contentModalCanNotDelete, setContentModalCanNotDelete] = useState();
  const [menuIdCanDelete, setMenuIdCanDelete] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const pageData = {
    no: t("table.no"),
    menuName: t("onlineStore.menuName"),
    menuItem: t("onlineStore.menuItem"),
    action: t("onlineStore.action"),
    button: {
      btnIgnore: t("button.ignore"),
      btnStop: t("button.stop"),
      btnDelete: t("button.delete"),
      btnIgnoreDelete: t("marketing.qrCode.ignoreText"),
      iGotIt: t("button.iGotIt"),
    },
    confirmDeleteMessage: t("onlineStore.confirmDeleteMenuMessage"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    deleteMenuSuccess: t("onlineStore.deleteMenuSuccess"),
    deleteMenuFail: t("onlineStore.deleteMenuFail"),
    confirmCannotDeleted: t("onlineStore.confirmCannotDeleted"),
    search: t("onlineStore.search"),
    menuBeingUseOnStoreWebsite: t("onlineStore.menuBeingUseOnStoreWebsite"),
    menuIsBeingLinked: t("onlineStore.menuIsBeingLinked"),
    pleaseUnlinkFirst: t("onlineStore.pleaseUnlinkFirst"),
  };

  const showConfirmDelete = async (id, name) => {
    const response = await menuManagementDataService.checkMenuManagementUseOnStore(id);
    const { isSubMenuBeingUsed, isUseOnStoreWeb, onlineStoreMenus } = response;

    if (isUseOnStoreWeb) {
      let messageContent = <span dangerouslySetInnerHTML={{ __html: pageData.menuBeingUseOnStoreWebsite }}></span>;
      setContentModalCanNotDelete(messageContent);
      setShowModalCanNotDelete(true);
    } else if (isSubMenuBeingUsed) {
      if (onlineStoreMenus.length === 1) {
        let messageHtml = (
          <>
            {pageData.menuIsBeingLinked}
            <br />
            <a
              style={{ color: "#F5832B" }}
              onClick={() => {
                moveToEditPage(onlineStoreMenus[0].id);
              }}
            >
              <span style={{ wordBreak: "break-word" }}>{onlineStoreMenus[0].name}</span>
            </a>
            <br />
            {pageData.pleaseUnlinkFirst}
          </>
        );

        setContentModalCanNotDelete(messageHtml);
      } else {
        let listItems = (
          <div className="table-notification-confirm-delete">
            <ul>
              {onlineStoreMenus.map((item) => {
                return (
                  <li>
                    <a onClick={() => moveToEditPage(item.id)}>{item.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        );

        let contentMessage = (
          <>
            <span
              dangerouslySetInnerHTML={{ __html: `${pageData.menuIsBeingLinked} <br/> ${pageData.pleaseUnlinkFirst}` }}
            ></span>
            {listItems}
          </>
        );
        setContentModalCanNotDelete(contentMessage);
      }

      setShowModalCanNotDelete(true);
    } else {
      setMenuIdCanDelete(id);
      setContentModalCanDelete(t(pageData.confirmDeleteMessage, { name: name }));
      setShowModalCanDelete(true);
    }
  };

  const moveToEditPage = (id) => {
    history.push(`/menu-management/edit/${id}`);
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.no,
        key: "index",
        align: "left",
        width: "5rem",
        dataIndex: "index",
        render: (val) => val + 1,
      },
      {
        title: pageData.menuName,
        dataIndex: "menuName",
        key: "menuName",
        align: "left",
        width: "43%",
        ellipsis: true,
        render: (value) => {
          return (
            <FnbTooltip placement="topLeft" title={value} onlyShowWhenEllipsis>
              <FnbTypography text={value} />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.menuItem,
        dataIndex: "menuItem",
        key: "menuItem",
        align: "left",
        width: "50%",
        ellipsis: true,
        render: (values) => {
          return (
            <FnbTooltip placement="topLeft" title={values} onlyShowWhenEllipsis>
              <FnbTypography text={values} />
            </FnbTooltip>
          );
        },
      },
    ];
    return columns;
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch) => {
    const response = await multilevelMenuDataService.getListMenuAsync(pageNumber, pageSize, keySearch);
    const data = response?.onlineStoreMenus.map((menu, index) => mappingRecordToColumns(menu, index));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(pageNumber);
    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > response.total) {
      numberRecordCurrent = response.total;
    }
  };

  const mappingRecordToColumns = (menu, index) => {
    return {
      ...menu,
      no: index + 1,
      menuName: menu?.name,
      menuItem: menu?.onlineStoreMultiLevelNames?.join(", "),
    };
  };

  const handleSearchByName = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
      }, 500),
    );
  };

  const onChangePage = async (pageNumber, pageSize) => {
    fetchDatableAsync(pageNumber, pageSize, keySearch);
  };

  const onDeleteMenuManagement = async (id) => {
    await menuManagementDataService.deleteMenuManagementByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.deleteMenuSuccess);
        setSelectedRowKeys([]);
        setSelectedRows([]);
      } else {
        message.error(pageData.deleteMenuFail);
      }
      setShowModalCanDelete(false);
      fetchDatableAsync(currentPageNumber, tableSettings.pageSize, keySearch);
    });
  };

  const onSelectedRowKeysChange = (keySelected, selectedRows) => {
    setSelectedRowKeys(keySelected);
    setSelectedRows(selectedRows);
  };

  const onEditRowKeys = () => {
    history.push(`/menu-management/edit/${selectedRows[0]?.id}`);
  };

  const onDeleteRowKeys = () => {
    showConfirmDelete(selectedRows[0]?.id, selectedRows[0]?.name);
  };
  return (
    <Row className="form-staff">
      <FnbTable
        className="table-striped-rows menu-management-table"
        columns={getColumns()}
        pageSize={tableSettings.pageSize}
        dataSource={dataSource}
        currentPageNumber={currentPageNumber}
        total={totalRecords}
        onChangePage={onChangePage}
        search={{
          placeholder: pageData.search,
          onChange: handleSearchByName,
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectedRowKeysChange,
          onEdit: onEditRowKeys,
          renderActions: (_, selectedRows) => {
            return (
              <>
                {hasPermission(PermissionKeys.DELETE_MENU_MANAGEMENT) && (
                  <FnbTooltip placement="top" title={pageData.button.btnDelete} zIndex={10}>
                    <FnbButton
                      variant="text"
                      className="row-delete-button"
                      iconHeader={<DeleteOutlined />}
                      onClick={onDeleteRowKeys}
                      disabled={selectedRows?.length > 1 || selectedRows?.some((item) => item.isDefault)}
                    />
                  </FnbTooltip>
                )}
              </>
            );
          },
        }}
        editPermission={PermissionKeys.EDIT_MENU_MANAGEMENT}
      />

      <>
        <ConfirmDialogComponent
          title={pageData.confirmDelete}
          visible={showModalCanNotDelete}
          closable={false}
          onOk={() => setShowModalCanNotDelete(false)}
          onCancel={() => setShowModalCanNotDelete(false)}
          type={ConfirmStyle.NOTIFICATION}
          contentElement={<span>{contentModalCanNotDelete}</span>}
          okText={pageData.button.iGotIt}
        />
        <ConfirmDialogComponent
          title={pageData.confirmDelete}
          visible={showModalCanDelete}
          okText={pageData.button.btnDelete}
          cancelText={pageData.button.btnIgnore}
          onCancel={() => setShowModalCanDelete(false)}
          type={ConfirmStyle.DELETE}
          onOk={() => onDeleteMenuManagement(menuIdCanDelete)}
          contentElement={<span dangerouslySetInnerHTML={{ __html: `${contentModalCanDelete}` }}></span>}
        />
      </>
    </Row>
  );
}
