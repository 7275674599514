import { Select, Typography } from "antd";
import { ArrowDown, CheckboxCheckedIcon } from "../../assets/icons.constants";
import "./fnb-select-multiple.scss";

/**
 * SelectMultiple component custom from antd select
 * @param {option, onChange, className, value, disabled, allowClear, showSearch, placeholder, dropdownRender, style, defaultValue } props
 * option: data select option [], map data [{id: "key", name: "value"}] first
 * other param are used as same as antd select, visit link https://ant.design/components/select/
 * @returns
 */
export function FnbSelectMultiple(props) {
  const {
    value,
    onChange,
    className,
    option,
    disabled,
    allowClear,
    showSearch,
    placeholder,
    dropdownRender,
    style,
    defaultValue,
    onSelect,
  } = props;

  return (
    <>
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        mode="multiple"
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        style={style}
        className={`fnb-select-multiple ${className}`}
        dropdownClassName="fnb-select-multiple-dropdown"
        suffixIcon={<ArrowDown />}
        menuItemSelectedIcon={<CheckboxCheckedIcon />}
        disabled={disabled}
        showSearch={showSearch}
        allowClear={allowClear}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        optionFilterProp="children"
        showArrow
        filterOption={(input, option) => {
          return option?.name?.trim()?.toLowerCase().indexOf(input?.trim()?.toLowerCase()) >= 0;
        }}
      >
        {option?.map((item) => (
          <Select.Option key={item?.id} value={item?.id} name={item?.name}>
            {item.name}
            {item?.isTopping && !value?.includes(item.id) && (
              <div className="fnb-chip fnb-chip__option--default" style={{ marginLeft: 12, maxHeight: 24 }}>
                <Typography style={{ color: "#ff7d07" }}>Topping</Typography>
              </div>
            )}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
