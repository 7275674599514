import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ZaloIcon, MessengerIcon } from "./icons/index";
import TawkToWidget from "components/tawkto-widget";
import "./index.scss";

// mapping to EnumAdvancedSetting code
const modules = {
  meta_messenger: {
    icon: <MessengerIcon />,
    name: "Messenger",
    style: {
      fillShadow: "0 0 0 0 #c31d1d",
      fillBgColor: "rgb(235 81 141 / 70%)",
      width: "100px",
      height: "100px"
    },
  },
  zalo_oa_chat: {
    icon: <ZaloIcon />,
    name: "Zalo",
    style: {
      fillShadow: "0 0 0 0 #2196F3",
      fillBgColor: "rgba(33,150,243,0.7)",
    },
  },
};

const SocialContact = (props) => {
  const { configs, extra = [] } = props;
  const [settingCongfigs, setSettingConfigs] = useState(configs);

  useEffect(() => {
    if (configs && configs.length > 0) {
      setSettingConfigs(configs);
    }
  }, [configs]);

  const RenderMessengerChat = () => {
    return (
      <div className="messenger-chat-container">
        {extra?.map((element, index) => {
          return (
            <div index={index} className="button-contact">
              {element}
            </div>
          );
        })}
        {settingCongfigs?.map((i, index) => {
          const { code, url } = i;
          if (!Boolean(modules[code])) {
            return <></>;
          }
          const { icon, style } = modules[code];
          return (
            <a index={"c-" + index} href={url} target="_blank">
              <div className="button-contact">
                <div className="phone-vr">
                  <div
                    className="phone-vr-circle-fill"
                    style={{ boxShadow: style.fillShadow, backgroundColor: style.fillBgColor }}
                  ></div>
                  <div className="phone-vr-img-circle">{icon}</div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    )
  };

  return (
    <div class="social-container">
      <RenderMessengerChat />
      <TawkToWidget showWidget={true} />
    </div>
  );
};

SocialContact.propTypes = {
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  extra: PropTypes.arrayOf(PropTypes.element),
};

export default SocialContact;
