export const PromotionDialogDefaultData = {
    discounts: [
        {
            isIncludedTopping: false,
            isMinimumPurchaseAmount: false,
            isPercentDiscount: true,
            maximumDiscountAmount: 0,
            name: "Discount default",
            percentNumber: 30,
            promotionName: "Discount on total bill",
            promotionProductCategories: [],
            promotionType: 0,
            promotionTypeId: 0,
            remainingDiscountAmount: 0,
            totalDiscountAmount: 15000,
        }
    ],
    discountCodes: [
        {
            no: 0,
            name: "Discount code default",
            discountCodeTypeId: 0,
            startDate: "2023-06-29T08:21:00",
            endDate: "2023-07-31T16:00:00",
            statusId: 0,
            createTime: "2023-06-29T08:20:40.6923404",
            isStopped: false,
            percentNumber: 20.00,
            maximumDiscountAmount: 20000.00,
            isPercentDiscount: true,
            code: "DEFAULT"
        }
    ]
}