import React, { useCallback } from "react";
import styled from "styled-components";
import { formatTextNumber, roundNumber } from "../../../../utils/helpers";
import ProductCard from "../components/product-card.component";
import "./product-list-card.component.scss";

const StyledProductListCard = styled.div`
  .product-list {
    &__title {
      .title-name {
        color: ${(props) => props.styleProductList?.colorGroup?.textColor || "defaultTextColor"};
      }
      .quantity-products {
        color: ${(props) => props.styleProductList?.colorGroup?.titleColor || "defaultTitleColor"};
      }
    }
  }

  .quantity-products {
    color: ${(props) => props.styleProductList?.colorGroup?.titleColor || "defaultTitleColor"};
  }
`;

function ProductListCard(props) {
  const { id, name, listProducts, currency, totalItems, handleAddToCart, styleProductList, productPrices, isGridView } =
    props;

  const getTagDiscountProductItem = useCallback((product) => {
    let tagDiscount = "";
    if (product?.originalPrice > product?.sellingPrice) {
      if (product?.isCombo) {
        tagDiscount = `-${formatTextNumber(
          roundNumber(100 - (product?.sellingPrice / product?.originalPrice) * 100, 2),
        )}%`;
      } else {
        if (product?.isFlashSale) {
          tagDiscount = `-${formatTextNumber(
            roundNumber(100 - (product?.sellingPrice / product?.originalPrice) * 100, 2),
          )}%`;
        } else {
          tagDiscount = `${product?.promotionTag}`;
        }
      }
    }
    return tagDiscount;
  }, []);

  return (
    <StyledProductListCard
      styleProductList={styleProductList}
      id={`list-products-section-id-${id}`}
      className={`product-list-card-theme1 ${listProducts?.length > 0 ? "" : "product-list-card-theme1--no-item"}`}
    >
      <div className="product-list__title">
        <span className="title-name">{name}</span>
        <span className="quantity-products">{`(${totalItems})`}</span>
      </div>
      <div className="product-list__container">
        {listProducts?.map((item) => {
          const isOutOfStock = productPrices?.find((i) => {
            const checkOutOfStockOfItem =
              ((i?.comboId === item?.id && item?.isCombo === true) ||
                (i.productPriceId === item.productPriceId && item.isCombo === false)) &&
              i.outOfStock === true;

            return checkOutOfStockOfItem;
          });

          return (
            <ProductCard
              product={item}
              key={item.id}
              currency={currency}
              handleAddToCart={handleAddToCart}
              styleProductList={styleProductList}
              tagDiscount={getTagDiscountProductItem(item)}
              isOutOfStock={isOutOfStock !== undefined}
              isGridView={isGridView}
            />
          );
        })}
      </div>
    </StyledProductListCard>
  );
}

export default ProductListCard;
