export const GOOGLE_API_KEY = "AIzaSyDJBwzrHdHae_2zYXPCAaWtQjFFc7x_3Cc";

export const FORMAT_GOOGLE_ADDRESS = {
  defaultLength: 4,
};

export const GOOGLE_CONFIG_TYPE = {
  googleApi: 1,
  firebaseCredential: 2,
  sms_Zalo: 3,
};

export const DEFAULT_LOCATION = {
  StepMedia: {
    Lat: 10.7944384,
    Lng: 106.67183,
  },
};

export const RESPONSE_STATUS = {
  Status: "OK",
};

export const EnumGoogleConfigType = {
  /// <summary>
  /// Android App
  /// </summary>
  AndroidAppAPIKey: 1,

  /// <summary>
  /// IOS App
  /// </summary>
  IOSAppAPIKey: 2,

  /// <summary>
  /// Store Web
  /// </summary>
  StoreWebAPIKey: 3,

  /// <summary>
  /// Pos Web
  /// </summary>
  PosWebAPIKey: 4,

  /// <summary>
  /// Notification Config
  /// </summary>
  FirebaseCredentials: 5,
};



