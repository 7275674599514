import { Modal } from "antd";
import styled from "styled-components";
import { ReactComponent as FinishAllStepBackgroundSvg } from "assets/images/system-guideline-finish-all-step-backgroud.svg";

export const ModalFinishSystemGuidelineStyle = styled(Modal)`
  width: 365px !important;
  .ant-modal-content {
    height: 472px !important;
    border-radius: 32px !important;
    width: 365px;
    overflow: hidden;
    position: relative;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      height: 100%;
    }
  }
`;

export const HeadingModalBackground = styled(FinishAllStepBackgroundSvg)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ContentBottom = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 32px 30px 32px;
  text-align: center;
`;
