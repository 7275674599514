import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { AutoComplete, Image, Input } from "antd";
import { AddCircleIcon, ArrowDown, icons } from "constants/icons.constants";
import { t } from "i18next";
import { env } from "env";
const { Option } = AutoComplete;
const FnbAutoComplete = (props) => {
  const { 
    options, 
    disabled, 
    onChange, 
    maxLength, 
    value = "", 
    optionClass = "option-autocomplete-item-default", 
    optionAddNewCustom, 
    placeholder = "", 
    isShowLogo = true, 
    defaultIcon = `${env.REACT_APP_ROOT_DOMAIN}/assets/images/default-autocomplete-item-logo.png` } = props;
  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const pageData = {
    optionAddNew: t("otherFoodyPlatform.optionAddNew"),
    add: t("otherFoodyPlatform.add")
  }
  useEffect(() => {
    setDataSource(options);
    handleSearch(value);
  }, [options]);

  const handleSearch = (value) => {
    setSearchValue(value);
    let filteredOptions = [];
    if (value) {
      // eslint-disable-next-line no-const-assign
      filteredOptions = [...options.filter((option) => option.value.toLowerCase().includes(value.toLowerCase()))];
    } else {
      filteredOptions = [...options];
    }
    filteredOptions.push({logo: '', value: value});
    setDataSource(filteredOptions);
  };

  const renderOptionAddNew = (value) => {
    return optionAddNewCustom ? optionAddNewCustom(value) : <span className="option-add-new-item"><AddCircleIcon width={20} height={20}/>{t(pageData.optionAddNew, {optionValue: value})}</span>;
  };

  const suffixArrow = (
    <div
      ref={arrowRef}
      tabIndex="-1"
      onClick={() => setIsOpen(prevState => !prevState)}
    >
      <ArrowDown />
    </div>
  );

  return (
    <AutoComplete
      open={isOpen}
      value={value}
      className="custom-auto-complete"
      dataSource={dataSource.map((option, index) => (
        <Option key={index} value={option.value}>
          {dataSource.length === 1 ? (
            renderOptionAddNew(searchValue)
          ) : (
            <>
            { isShowLogo && <Image style={{marginRight: 8}} preview={false} width={24} height={24} src={option?.logo} fallback={defaultIcon} /> }
            <span
              className={optionClass}
              dangerouslySetInnerHTML={{
                __html: option?.value?.replace(
                  new RegExp(searchValue, "gi"),
                  (match) =>
                    `${
                      index === dataSource?.length - 1 && dataSource.length !== options.length ? `<span class='highlight-action'>${pageData.add} “</span>` : ""
                    }<span class="highlight-character">${match}</span>${
                      index === dataSource?.length - 1 && dataSource.length !== options.length ? "<span class='highlight-action'>”</span>" : ""
                    }`,
                ),
              }}
            />
            </>
          )}
        </Option>
      ))}
      onSearch={handleSearch}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
      onSelect={() => setIsOpen(false)}
    >
      <Input placeholder={placeholder} suffix={suffixArrow}
        onClick={() => setIsOpen(true)}
        onBlur={(e) => e.relatedTarget !== arrowRef.current && setIsOpen(false)}
      />
    </AutoComplete>
  );
};

export default FnbAutoComplete;
