import { Col, Row } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { ImageMaterialDefault, SearchIcon } from "constants/icons.constants";
import useDebounce from "hooks/useDebounce";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { executeAfter } from "utils/helpers";
import "./modal-search-material.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

export default function ModalSearchMaterial(props) {
  const { materialList, position, dataSelectedMaterial, onAddSelected, onCancelModal } = props;
  const [t] = useTranslation();
  const [keySearch, setKeySearch] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const ourRef = useRef(null);

  const pageData = {
    table: {
      searchPlaceholder: t("productManagement.table.searchPlaceholder"),
      productName: t("productManagement.table.productName"),
      variation: t("productManagement.table.variation"),
      price: t("productManagement.table.price"),
      displayPlatform: t("productManagement.table.displayPlatform"),
      status: t("productManagement.table.status"),
      action: t("productManagement.table.action"),
      numberOfProducts: t("materialCategory.table.numberOfProducts"),
    },
    searchMaterial: t("productManagement.recipe.placeholder"),
    sku: t("materialControl.createRemoveRequest.sku"),
  };

  useEffect(() => {
    const element = ourRef.current;
    if (element) {
      let position = { top: 0, left: 0, x: 0, y: 0 };

      const mouseMoveHandler = function (e) {
        const dx = e.clientX - position.x;
        const dy = e.clientY - position.y;
        element.scrollTop = position.top - dy;
        element.scrollLeft = position.left - dx;
      };

      const mouseUpHandler = function (e) {
        element.removeEventListener("mousemove", mouseMoveHandler);
        element.removeEventListener("mouseup", mouseUpHandler);
        element.style.removeProperty("user-select");
        element.style.removeProperty("cursor");
      };

      const mouseDownHandler = function (e) {
        position = {
          left: element.scrollLeft,
          top: element.scrollTop,
          x: e.clientX,
          y: e.clientY,
        };

        element.style.userSelect = "none";
        if (element.scrollWidth > element.clientWidth) {
          element.style.cursor = "grab";
        }
        element.addEventListener("mousemove", mouseMoveHandler);
        element.addEventListener("mouseup", mouseUpHandler);
        element.addEventListener("mouseleave", mouseUpHandler);
      };
      element.removeEventListener("mousedown", mouseDownHandler);
      element.addEventListener("mousedown", mouseDownHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSelectedMaterial) {
      const newList = dataSelectedMaterial?.map((item) => item.key);
      setSelectedKeys(newList);
      setDataSelected(dataSelectedMaterial);
      handleFilterProduct();
    }
    setKeySearch();
  }, [materialList]);

  const onSelect = (record, selected, selectedRows) => {
    if (record?.id) {
      let newSelected = dataSelected;
      if (selected) {
        selectedRows?.forEach((item) => {
          if (!newSelected?.some((existingItem) => existingItem?.key === item?.key)) {
            if (item) {
              newSelected.push(item);
            }
          }
        });
        setDataSelected(newSelected);
      } else {
        const newSelectedMaterial = newSelected?.filter((pps) => !record?.key?.includes(pps?.key));
        setDataSelected(newSelectedMaterial);
      }
    }
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        key: "material",
        title: "",
        dataIndex: "material",
        ellipsis: true,
        tooltip: false,
        width: "80%",
        render: (value, record) => {
          return (
            <div className="column-material-name">
              {record?.thumbnail ? (
                <Thumbnail src={record?.thumbnail} width={64} height={64} />
              ) : (
                <ImageMaterialDefault width={64} height={64} />
              )}
              <div className="title-material">
                <FnbTooltip title={value} onlyShowWhenEllipsis={true}>
                  <FnbTypography className="text-line-description-clamp-1" text={value} />
                </FnbTooltip>
                <span className="sku">{record?.sku ? `${pageData.sku}: ${record.sku}` : ""}</span>
              </div>
            </div>
          );
        },
      },
      {
        key: "unitCost",
        title: "",
        dataIndex: "unitCost",
        ellipsis: true,
        tooltip: false,
        width: "20%",
        render: (value, record) => {
          return (
            <FnbTooltip title={`${record?.unitCost ?? 0}/${record.unit}`} onlyShowWhenEllipsis={true}>
              <FnbTypography className="column-material-cost" text={`${record?.unitCost ?? 0}/${record.unit}`} />
            </FnbTooltip>
          );
        },
      },
    ],
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        setKeySearch(keySearch);
        await handleFilterProduct(keySearch);
      });
    },
  };

  const mappingRecordToColumns = (item) => {
    let units = [];
    let baseUnit = {
      id: item?.unit?.id,
      name: item?.unit?.name,
      isBase: true,
      quantity: 1,
    };
    units.push(baseUnit);
    if (item?.unitConversion) {
      item?.unitConversion?.map((item) => {
        let unit = {
          id: item?.unitId,
          name: item?.unitName,
          quantity: item?.quantity,
          isBase: false,
        };
        units.push(unit);
      });
    }
    return {
      key: item?.id,
      material: item?.name,
      quantity: null,
      unit: item?.unitName,
      unitCost: item?.costPerUnit,
      cost: 0,
      unitId: item?.unit?.id ?? null,
      units: units,
      id: item?.id,
      thumbnail: item?.thumbnail,
      sku: item?.sku,
      isActive: item?.isActive,
    };
  };

  const handleFilterProduct = async (key) => {
    const products = materialList?.map((record) => mappingRecordToColumns(record));
    if (key !== "" && key !== undefined) {
      if (products) {
        const filteredResults = products?.filter(
          (item) =>
            item?.material?.removeVietnamese().trim().toLowerCase().includes(key?.toLowerCase()) ||
            item?.material?.trim().toLowerCase().includes(key?.toLowerCase()) ||
            item?.sku?.removeVietnamese().trim().toLowerCase().includes(key?.toLowerCase()) ||
            item?.sku?.trim().toLowerCase().includes(key?.toLowerCase()),
        );
        setDataSource(filteredResults);
      }
    } else {
      setDataSource(products);
    }
  };

  useDebounce(
    () => {
      handleFilterProduct(keySearch);
    },
    [keySearch],
    500,
  );

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedKeys(selectedRowKeys);
  };

  function onSearchInput(value) {
    tableSettings.onSearch(value);
  }

  function onClear() {
    setDataSelected([]);
    setSelectedKeys([]);
  }

  function onDeselect(key) {
    const newListMaterial = dataSelected?.filter((pps) => !key?.includes(pps?.key));
    setDataSelected(newListMaterial);
    const newSelectedKeys = selectedKeys?.filter((k) => !key?.includes(k));
    setSelectedKeys(newSelectedKeys);
  }

  function onOk() {
    onAddSelected && onAddSelected(dataSelected, position);
    onCancelModal();
  }

  function redirectToCreateMaterial() {
    onCancelModal();
    setTimeout(() => {
      let baseUrl = "/material/add-new";
      if (keySearch) {
        const data = {
          name: keySearch,
        };
        const queryString = new URLSearchParams(data).toString();
        baseUrl = `${baseUrl}?` + queryString;
      }
      window.open(baseUrl, "_blank");
    }, 500);
  }

  return (
    <>
      <Col className="form-staff material-search">
        <Row className="material-search-multiple w-100" gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col className="w-100">
            <FnbInput
              value={keySearch}
              prefix={
                <div ref={ourRef} className="wrapper-selected-chip">
                  {dataSelected?.length === 0 && <SearchIcon />}
                  {dataSelected?.map((material) => {
                    let selectedMaterial = materialList?.find((x) => x.id === material.key);
                    return (
                      <FnbChip
                        className={"ingredient-chip"}
                        id={selectedMaterial?.key}
                        isDisableToolTip={true}
                        title={selectedMaterial?.material ?? selectedMaterial?.name}
                        isRemoveChip={true}
                        onRemoveChip={() => onDeselect(material.key)}
                      />
                    );
                  })}
                </div>
              }
              placeholder={dataSelected?.length === 0 ? pageData.searchMaterial : undefined}
              onChange={(e) => setKeySearch(e.target.value)}
              className="ingredient-input container-selected-material"
              maxLength={100}
              allowClear
            />
          </Col>
        </Row>
        <Row
          className={`${
            dataSource?.length === 0 ? "modal-material-search-table-not-found " : ""
          }modal-material-search-table modal-product-topping-table`}
        >
          <FnbTable
            rowKey="id"
            className="table-striped-rows table-product-management"
            columns={tableSettings.columns}
            pageSize={tableSettings.pageSize}
            dataSource={dataSource}
            onChangePage={tableSettings.onChangePage}
            scrollX={"100%"}
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onChange: onSelectedRowKeysChange,
              onSelect: onSelect,
            }}
            search={{
              maxLength: 100,
              placeholder: pageData.table.searchPlaceholder,
              onChange: tableSettings.onSearch,
            }}
            showDetailDataFound={true}
            itemDataFound={keySearch}
            redirectUrl={redirectToCreateMaterial}
          />
        </Row>
      </Col>
      <Row
        style={{ display: "flex", justifyContent: "center", gap: 8, marginTop: 12 }}
        className="footer-promotion-campaign-buy-x-get-y"
      >
        <FnbCancelButton text={t("button.cancel")} minWidth={"120px"} onOk={onCancelModal} />
        <FnbButton
          text={t("material.addSelected", {
            number: dataSelected?.length > 0 ? dataSelected?.length : "",
          })}
          onClick={onOk}
          minWidth={"120px"}
          disabled={dataSelected?.length > 0 ? false : true}
        />
      </Row>
    </>
  );
}
