import { Space } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";

const DownloadBox = ({
  icon,
  title,
  content,
  button = {
    onClick: undefined,
    text: "",
    disabled: false,
    loading: false,
  },
  button2nd = {
    onClick: undefined,
    text: "",
    disabled: false,
    loading: false,
  },
}) => {
  return (
    <div className="deploy-enviroment-box">
      <div className="group-icon-content">
        <div className="icon">{icon}</div>
        <div className="group-deploy">
          <div className="group-deploy-content">
            <b className="title">{title}</b>
            <p className="content">{content}</p>
          </div>
          <Space wrap size={12}>
            <FnbButton
              text={button.text}
              disabled={button.disabled}
              onClick={() => {
                button.onClick && button.onClick();
              }}
              loading={button.loading}
            />
            {button2nd?.text && (
              <FnbButton text={button2nd.text} onClick={button2nd.onClick} disabled={button2nd.disabled} />
            )}
          </Space>
        </div>
      </div>
    </div>
  );
};

export default DownloadBox;
