import React, { useEffect, useState } from "react";
import { he } from "react-date-range/dist/locale";
import { Verify } from "react-puzzle-captcha";
import "react-puzzle-captcha/dist/react-puzzle-captcha.css";
import "./puzzle-captcha.component.scss";
import { ArrowRight3StrokeIcon, ReloadIcon } from "constants/icons.constants";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import i18n from "utils/i18n";

export default function PuzzleCaptcha(props) {
  const { visible, width = 320, height = 160, onSuccess } = props;
  const [t] = useTranslation();
  const pageData = {
    puzzleHint: t("registerAccount.puzzleHint"),
  };

  useEffect(() => {
    var sliderText = document.querySelector(".sliderText");
    ReactDOM.render(<span>{pageData.puzzleHint}</span>, sliderText);
  }, [i18n.language]);

  useEffect(() => {
    var sliderIcon = document.querySelector(".sliderIcon");
    ReactDOM.render(<ArrowRight3StrokeIcon></ArrowRight3StrokeIcon>, sliderIcon);

    var reloadIcon = document.querySelector(".refreshIcon");
    ReactDOM.render(
      <div className="reloadIcon">
        <ReloadIcon />
      </div>,
      reloadIcon,
    );
  }, []);
  return (
    <div className="captcha-container">
      <Verify width={width} height={height} visible={visible} onSuccess={() => onSuccess()} />
    </div>
  );
}
