import { Input } from "antd";
import "./fnb-input.component.scss";
import classNames from "classnames";

export const TYPE_INPUT = {
  DEFAULT: "default",
  PASSWORD: "password",
  BUTTON: "button",
}

export function FnbInput({
  id,
  defaultValue,
  value,
  allowClear,
  showCount,
  className = "",
  placeholder,
  maxLength,
  onChange,
  autoFocus,
  type = TYPE_INPUT.DEFAULT,
  disabled = false,
  suffix,
  prefix,
  ...rest
}) {
  
  const classNameInput = classNames({
    "fnb-input-admin": true,
    "fnb-input-admin--disabled": disabled,
    [`${className}`]: true
  })

  switch (type) {
    case TYPE_INPUT.PASSWORD:
      return (
        <Input.Password
          id={id}
          value={value}
          allowClear={allowClear}
          onChange={onChange}
          className={classNameInput}
          showCount={showCount}
          placeholder={placeholder}
          maxLength={maxLength}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          disabled={disabled}
          prefix={prefix}
          {...rest}
        />
      );
    case TYPE_INPUT.BUTTON:
      return (
        <Input
          type="button"
          id={id}
          value={placeholder}
          autoFocus={autoFocus}
          disabled={disabled}
          suffix={suffix}
          prefix={prefix}
          {...rest}
        />
      );  
    default:
      return (
        <Input
          id={id}
          value={value}
          allowClear={allowClear}
          onChange={onChange}
          className={classNameInput}
          showCount={showCount}
          placeholder={placeholder}
          maxLength={maxLength}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          disabled={disabled}
          suffix={suffix}
          prefix={prefix}
          {...rest}
        />
      );
  }
}
