import { Card, Col, message, Row } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FeeStatus } from "constants/fee.constants";
import { DeleteOutlined, MinusCircleOutlineIcon } from "constants/icons.constants";
import { OrderTypeConstants, OrderTypeConstantTranslate } from "constants/order-type-status.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import branchDataService from "data-services/branch/branch-data.service";
import feeDataService from "data-services/fee/fee-data.service";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { formatDate, formatNumberDecimalOrInteger, getCurrency, hasPermission } from "utils/helpers";
import AddNewFeeComponent from "./create-new-fee.component";
import FeeDetailComponent from "./detail-fee.component";
import "./list-fee.component.scss";

const feeDetailActions = {
  none: 0,
  delete: 1,
  stop: 2,
};

export default function ListFeeComponent(props) {
  const { visibleAddFee, onChangeVisibleAddFee } = props;
  const [t] = useTranslation();
  const [listFee, setListFee] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [listBranch, setListBranch] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [showFeeDetailModal, setShowFeeDetailModal] = useState(false);
  const [feeDetail, setFeeDetail] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmTitle, setConfirmTittle] = useState("");
  const [confirmContent, setConfirmContent] = useState("");
  const [confirmActionButton, setConfirmActionButton] = useState("");
  const [currentFeeDetailAction, setCurrentFeeDetailAction] = useState(feeDetailActions.none);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmStopModal, setShowConfirmStopModal] = useState(false);
  const orderTypeNames = Object.keys(OrderTypeConstants);
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();

  const pageData = {
    addNew: t("button.addNew"),
    percent: "%",
    table: {
      no: t("table.no"),
      feeName: t("feeAndTax.table.feeName"),
      description: t("feeAndTax.table.description"),
      value: t("feeAndTax.table.value"),
      action: t("feeAndTax.table.action"),
      time: t("feeAndTax.table.time"),
      start: t("feeAndTax.table.start"),
      end: t("feeAndTax.table.end"),
      status: t("promotion.table.status"),
      type: t("table.type"),
    },
    btnStop: t("button.stop"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("feeAndTax.confirmDeleteMessage"),
    confirmStop: t("leaveDialog.confirmStop"),
    confirmStopFeeMessage: t("messages.confirmStopFeeMessage"),
    feeDeleteSuccess: t("feeAndTax.feeDeleteSuccess"),
    feeDeleteFail: t("feeAndTax.feeDeleteFail"),
    stopFeeSuccess: t("feeAndTax.stopFeeSuccess"),
    feeStopFail: t("feeAndTax.feeStopFail"),
    feeManagement: t("feeAndTax.feeManagement"),
    autoApplied: t("feeAndTax.autoApplied"),
    manual: t("feeAndTax.manual"),
    toCreateData: t("area.toCreateData"),
  };

  const tableSettings = {
    page: 1,
    pageSize: 20,
  };

  useLayoutEffect(() => {
    getListBranch();
    getInitDataTableFee(tableSettings.page, tableSettings.pageSize);
  }, []);

  const getInitDataTableFee = (pageNumber, pageSize) => {
    feeDataService.getAllFeeInStoreAsync(pageNumber, pageSize).then((res) => {
      let fees = mappingToDataTableFees(res.fees);
      setTotalFee(res.total);
      setListFee(fees);
    });
  };

  const mappingToDataTableFees = (data) => {
    let fees = [];
    data.map((item, index) => {
      let fee = {
        no: index + 1,
        id: item?.id,
        name: item?.name,
        description: item?.description,
        value: `${formatNumberDecimalOrInteger(item?.value)} ${item?.isPercentage ? pageData.percent : getCurrency()}`,
        startDate: item?.startDate,
        endDate: item?.endDate,
        status: item?.statusId,
        isAutoApplied: item?.isAutoApplied,
        feeServingTypes: item?.feeServingTypes,
      };
      fees.push(fee);
    });
    return fees;
  };

  const getColumnsFee = () => {
    const columnFees = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        key: "no",
        width: "5rem",
      },
      {
        title: pageData.table.feeName,
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        render: (value) => (
          <FnbTooltip title={value} onlyShowWhenEllipsis>
            <span>{value}</span>
          </FnbTooltip>
        ),
      },
      {
        title: pageData.table.description,
        dataIndex: "description",
        key: "description",
        width: "20%",
        ellipsis: true,
        render: (_, record) => (
          <FnbTooltip title={record?.description} onlyShowWhenEllipsis>
            <FnbTypography text={record?.description} />
          </FnbTooltip>
        ),
      },
      {
        title: pageData.table.value,
        dataIndex: "value",
        key: "value",
      },
      {
        title: pageData.table.type,
        dataIndex: "feeServingTypes",
        key: "feeServingTypes",
        ellipsis: true,
        render: (value) => {
          return (
            <MemoizedFnbListChips
              data={value?.map((type) => ({
                title: t(OrderTypeConstantTranslate[orderTypeNames[type.orderServingType]]),
                detail: t(OrderTypeConstantTranslate[orderTypeNames[type.orderServingType]]),
              }))}
            ></MemoizedFnbListChips>
          );
        },
      },
      {
        title: pageData.table.time,
        dataIndex: "time",
        align: "right",
        render: (_, record) => {
          return (
            <div className="column-effective-time">
              {record?.startDate && (
                <span>
                  <span>{pageData.table.start}: </span>
                  <span>{formatDate(record?.startDate, DateFormat.DD_MMM_YYYY)}</span>
                </span>
              )}
              {record?.endDate && (
                <span>
                  <span>{pageData.table.end}: </span>
                  <span>{formatDate(record?.endDate, DateFormat.DD_MMM_YYYY)}</span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: pageData.table.status,
        dataIndex: "status",
        width: "9rem",
        align: "center",
        render: (value) => <Row justify={"center"}>{renderFeeStatus(value)}</Row>,
      },
    ];
    return columnFees;
  };

  const onRowClick = (record) => {
    showDetailFeeModal(record?.id);
  };

  const renderFeeStatus = (statusId) => {
    switch (statusId) {
      case FeeStatus.Schedule:
        return <FnbBadge variant="warning" text={t("promotion.status.scheduled")} />;
      case FeeStatus.Active:
        return <FnbBadge variant="success" text={t("promotion.status.active")} />;
      default:
        return <FnbBadge variant="error" text={t("promotion.status.finished")} />;
    }
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  // Insert the name into the message
  const formatStopMessage = (name) => {
    let mess = t(pageData.confirmStopFeeMessage, { name: name });
    return mess;
  };

  const onDeleteItem = (selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const handleDeleteItem = (id) => {
    feeDataService.deleteFeeByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.feeDeleteSuccess);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setShowConfirmDeleteModal(false);
      } else {
        message.error(pageData.feeDeleteFail);
      }
      getInitDataTableFee(tableSettings.page, tableSettings.pageSize);
    });
  };

  const onChangePage = async (page, pageSize) => {
    setCurrentPageNumber(page);
    getInitDataTableFee(page, pageSize);
  };

  const getListBranch = () => {
    branchDataService.getAllBranchsAsync().then((res) => {
      setListBranch(res?.branchs);
      updateLastTimeGetBranches();
    });
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getListBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const loadListFee = () => {
    getInitDataTableFee(tableSettings.page, tableSettings.pageSize);
    onChangeVisibleAddFee && onChangeVisibleAddFee(false);
  };

  const showDetailFeeModal = async (id) => {
    let res = await feeDataService.getFeeDetailByIdAsync(id);
    let feeStatus = listFee.find((x) => x.id === res.feeDetail.id).status;
    let feeDetail = res.feeDetail;
    feeDetail.statusId = feeStatus;
    setFeeDetail(feeDetail);
    setShowFeeDetailModal(true);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onStopItem = (selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmStopModal(true);
  };

  const onStopFee = async (id) => {
    const res = await feeDataService.stopFeeByIdAsync(id);
    if (res) {
      message.success(pageData.stopFeeSuccess);
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setShowConfirmStopModal(false);
    } else {
      message.error(pageData.feeStopFail);
    }
    getInitDataTableFee(tableSettings.page, tableSettings.pageSize);
  };

  const onDeleteFeeDetail = () => {
    setConfirmTittle(pageData.confirmDelete);
    let mess = t(pageData.confirmDeleteMessage, { name: feeDetail?.name });
    setConfirmContent(mess);
    setConfirmActionButton(pageData.btnDelete);
    setShowConfirm(true);
    setCurrentFeeDetailAction(feeDetailActions.delete);
  };

  const onStopFeeDetail = () => {
    setConfirmTittle(pageData.confirmStop);
    let mess = t(pageData.confirmStopFeeMessage, { name: feeDetail?.name });
    setConfirmContent(mess);
    setConfirmActionButton(pageData.btnStop);
    setShowConfirm(true);
    setCurrentFeeDetailAction(feeDetailActions.stop);
  };

  const onConfirmFeeDetailAction = () => {
    if (currentFeeDetailAction === feeDetailActions.delete) {
      handleDeleteItem(feeDetail?.id);
    }

    if (currentFeeDetailAction === feeDetailActions.stop) {
      onStopFee(feeDetail?.id);
    }

    setShowConfirm(false);
    setShowFeeDetailModal(false);
  };

  const feeDetailActionButtons = (feeDetail) => {
    return {
      delete: {
        hasPermission: hasPermission(PermissionKeys.DELETE_FEE) && feeDetail?.statusId === FeeStatus.Schedule,
        onDeleteFee: () => onDeleteFeeDetail(),
      },
      stop: {
        hasPermission: hasPermission(PermissionKeys.STOP_FEE) && feeDetail?.statusId === FeeStatus.Active,
        onStopFee: () => onStopFeeDetail(),
      },
    };
  };

  return (
    <>
      <Card className="fnb-card-custom">
        <FeeDetailComponent
          isModalVisible={showFeeDetailModal}
          feeDetail={feeDetail}
          closeFeeDetailModal={() => setShowFeeDetailModal(false)}
          actionButtons={feeDetailActionButtons(feeDetail)}
        />
        <Row>
          <Col span={24}>
            <FnbTable
              className="fee-table"
              columns={getColumnsFee()}
              pageSize={tableSettings.pageSize}
              dataSource={listFee}
              currentPageNumber={currentPageNumber}
              total={totalFee}
              onChangePage={onChangePage}
              deletePermission={PermissionKeys.DELETE_FEE}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                renderActions: (_selectedRowKeys, selectedRows) => {
                  const multipleSelectedRows = selectedRows?.length > 1;
                  const isScheduleStatus = selectedRows?.every((reccord) => reccord?.status === FeeStatus.Schedule);
                  const isActiveStatus = selectedRows?.every((reccord) => reccord?.status === FeeStatus.Active);
                  return (
                    <>
                      {hasPermission(PermissionKeys.DELETE_FEE) && isScheduleStatus && (
                        <FnbTooltip placement="top" title={pageData.btnDelete} zIndex={10}>
                          <FnbButton
                            variant="text"
                            className="row-delete-button"
                            iconHeader={<DeleteOutlined />}
                            onClick={() => onDeleteItem(selectedRows)}
                            disabled={multipleSelectedRows}
                          />
                        </FnbTooltip>
                      )}
                      {hasPermission(PermissionKeys.STOP_FEE) && !multipleSelectedRows && isActiveStatus && (
                        <FnbTooltip placement="top" title={pageData.btnStop} zIndex={10}>
                          <FnbButton
                            variant="text"
                            className="row-stop-button"
                            iconHeader={<MinusCircleOutlineIcon />}
                            onClick={() => onStopItem(selectedRows)}
                          />
                        </FnbTooltip>
                      )}
                    </>
                  );
                },
              }}
              onRowClick={onRowClick}
              emptyText={
                hasPermission(PermissionKeys.CREATE_FEE) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.addNew}
                      onClick={() => onChangeVisibleAddFee(true)}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Col>
        </Row>
        <AddNewFeeComponent
          isModalVisible={visibleAddFee}
          listBranch={listBranch}
          listFee={listFee}
          onCancel={loadListFee}
        />
      </Card>
      <DeleteConfirmComponent
        title={confirmTitle}
        content={confirmContent}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={confirmActionButton}
        onCancel={() => setShowConfirm(false)}
        onOk={onConfirmFeeDetailAction}
        className="opTop"
      />
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={() => handleDeleteItem(selectedRows[0]?.id)}
      />
      <DeleteConfirmComponent
        title={pageData.confirmStop}
        content={formatStopMessage(selectedRows[0]?.name)}
        visible={showConfirmStopModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnStop}
        onCancel={() => {
          setShowConfirmStopModal(false);
        }}
        onOk={() => onStopFee(selectedRows[0]?.id)}
      />
    </>
  );
}
