export const LevelMenu = {
  Level1: 1,
  Level2: 2,
};

export const ACTION_MENU_ITEMS = {
  ADD_ABOVE: "addAbove",
  ADD_BELOW: "addBelow",
  DELETE: "delete",
};

export const ON_VIEW_MENU_MANAGEMENT = {
  CREATE: "createMenu",
  EDIT: "editMenu",
  DELETE: "deleteMenu",
};

export const ConfirmStyle = {
  LEAVER: 0,
  DELETE: 1,
  NOTIFICATION: 2,
  STOP: 4,
};

export const ConfirmViewActiveItemStyle = {
  GRID: 0,
  LIST: 1,
};

export const EnumMaterialDependencyType = {
  NONE: 0,
  OPTION: 1,
  ORDER: 2,
  PURCHASE_ORDER: 3,
};
