export const LoyaltyPointCardDefaultData = {
    loyaltyPoint: {
        name: "Gold",
        accumulatedPoint: 1000,
        availablePoint: 0,
        customerAccumulatedPoint: 1000,
        accumulatedPointNext: 1000000,
        rankNext: "Superman"
    },
    customerCode: "123456789",
    fullName: "Hello World",
    firstName: "Hello",
    lastName: "World"
}