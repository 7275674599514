import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { useTranslation } from "react-i18next";
import theme from "theme";
import {
  ContentBottom,
  HeadingModalBackground,
  ModalFinishSystemGuidelineStyle,
} from "./modal-finish-system-guideline.styled";

const ModalFinishSystemGuideline = ({ open, setOpen }) => {
  const [t] = useTranslation();
  const pageData = {
    title: t("theme.publish.wellDone"),
    content: t("systemGuideline.modalFinish.content"),
    buttonConfirm: t("systemGuideline.modalFinish.buttonConfirm"),
  };

  const onClickConfirm = () => {
    setOpen(false);
  };

  return (
    <ModalFinishSystemGuidelineStyle open={open} footer={null} centered>
      <HeadingModalBackground />
      <ContentBottom>
        <FnbTypography variant={theme.typography["h1-bold"]} text={pageData.title} />
        <FnbTypography variant={theme.typography["b1-regular"]}>
          <span
            dangerouslySetInnerHTML={{
              __html: pageData.content,
            }}
          />
        </FnbTypography>
        <FnbButton text={pageData.buttonConfirm} onClick={onClickConfirm} width={"100%"} />
      </ContentBottom>
    </ModalFinishSystemGuidelineStyle>
  );
};

export default ModalFinishSystemGuideline;
