import { Button, Col, Form, Row, message, Switch, Tooltip, Input, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import { StoreGeneralConfigInfoCircleIcon, UploadLogoIcon } from "constants/icons.constants";
import otherFoodyPlatformConfigDataService from "data-services/other-foody-platform-config/other-foody-platform-config-data.service";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";
import { InputNumber } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FnbAutoComplete from "components/auto-complete";
import FnbSwitch from "components/switch";

const { forwardRef, useImperativeHandle } = React;
export const AddNewOtherFoodyPlatformConfigComponent = forwardRef((props, ref) => {
  const { onCompleted, options } = props;
  const [activeFunctionButton, setActiveFunctionButton] = useState(true);
  const [image, setImage] = useState(null);
  const [isAllowInputDiscountFromPromotion, setIsAllowInputDiscountFromPromotion] = useState(true);
  const [isAllowInputDiscountFromPartner, setIsAllowInputDiscountFromPartner] = useState(true);
  const [isRevalidateInput, setIsRevalidateInput] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const pageData = {
    platformName: t("otherFoodyPlatform.platformName"),
    platformNamePlaceholder: t("otherFoodyPlatform.platformNamePlaceholder"),
    partnerCommission: t("otherFoodyPlatform.partnerCommission"),
    platformNameValidationMessage: t("otherFoodyPlatform.platformNameValidationMessage"),
    allowInputDiscountFromPromotion: t("otherFoodyPlatform.allowInputDiscountFromPromotion"),
    allowInputDiscountFromPromotionTooltip: t("otherFoodyPlatform.allowInputDiscountFromPromotionTooltip"),
    allowInputDiscountFromPartner: t("otherFoodyPlatform.allowInputDiscountFromPartner"),
    allowInputDiscountFromPartnerTooltip: t("otherFoodyPlatform.allowInputDiscountFromPartnerTooltip"),
    btnCancel: t("otherFoodyPlatform.cancel"),
    btnAdd: t("otherFoodyPlatform.add"),
    addSuccess: t("messages.createPlatformSuccessfully"),
    addFailed: t("messages.createPlatformFailed"),
    generalInformation: {
      title: t("otherFoodyPlatform.generalInformation.title"),
      uploadImage: t("otherFoodyPlatform.generalInformation.addFile"),
    },
    upload: {
      addFromUrl: t("upload.addFromUrl"),
    },
    media: {
      title: t("otherFoodyPlatform.logo"),
      textNonImage: t("media.textNonImage"),
    },
    bestDisplayImage: t("messages.imageBestDisplay"),
    partnerCommissionValidationMessage: t("otherFoodyPlatform.partnerCommissionValidationMessage"),
    partnerCommissionPlaceholder: t("otherFoodyPlatform.partnerCommissionPlaceholder"),

    logo: {
      title: t("otherFoodyPlatform.logo"),
      subTitle: t("registerAccount.logo.subTitle"),
      subTitle1: t("registerAccount.logo.subTitle1"),
      subTitle2: t("registerAccount.logo.subTitle2"),
      subTitle3: t("registerAccount.logo.subTitle3"),
      uploadImage: t("registerAccount.logo.uploadImage"),
      textNonImage: t("registerAccount.logo.textNonImage"),
      bestDisplayImage: t("registerAccount.logo.bestDisplayImage"),
    },
  };
  const [partnerCommissionLength, setPartnerCommissionLength] = useState(0);

  useImperativeHandle(ref, () => ({
    reset() {
      reset();
    },
  }));

  const reset = () => {
    form.resetFields();
    setIsAllowInputDiscountFromPromotion(true);
    setIsAllowInputDiscountFromPartner(true);
    setImage(null);
  };

  const onClickAdd = (values) => {
    let newFoodyPlatform = {
      name: values.platFormName,
      logo: values?.image?.media?.url,
      partnerCommission: values.partnerCommission,
      isAllowInputDiscountFromPromotion: isAllowInputDiscountFromPromotion,
      isAllowInputDiscountFromPartner: isAllowInputDiscountFromPartner,
    }
    form.validateFields();
    otherFoodyPlatformConfigDataService.createFoodyPlatformConfigAsync(newFoodyPlatform).then((response) => {
      if (response?.isSuccess === true) {
        message.success(t(response.message));
        onCompleted();
      }
      else {
        message.error(t(response.message));
        setIsRevalidateInput(true);
      }
    }).catch((response) => {
      message.error(t(pageData.addFailed));
      setIsRevalidateInput(true);
    });
  };

  const onValidForm = () => {
    if (isRevalidateInput) {
      form.validateFields();
    }
  };

  const onChangePartnerCommission = (value) => {
    setPartnerCommissionLength(value ? value.toString().length + 3 : 19);
    form.setFieldValue("partnerCommission", value);
  };

  const renderAddNewForm = () => {
    return <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onClickAdd}
      onChange={onValidForm}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            name="platFormName"
            label={pageData.platformName}
            rules={[
              {
                required: true,
                message: pageData.platformNameValidationMessage,
              },
            ]}
          >
            <FnbAutoComplete
              value={form.getFieldValue("name")}
              maxLength={100}
              options={options}
              placeholder={pageData.platformNamePlaceholder} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label={pageData.partnerCommission}
            name="partnerCommission"
            rules={[
              {
                required: true,
                message: pageData.partnerCommissionValidationMessage,
              },
            ]}
          >
            <InputNumber
              className="fnb-input-number w-100 discount-amount"
              precision={2}
              max={100}
              min={0}
              placeholder={pageData.partnerCommissionPlaceholder}
              onChange={(value) => onChangePartnerCommission(value)}
            />
            <span className="suffix-icon" style={{ left: `${8 + 11 * (partnerCommissionLength > 0 ? partnerCommissionLength : 19)}px` }}>%</span>
          </Form.Item>
        </Col>
      </Row>

      <Row span={24} gutter={[0, 16]} >
        <Row span={24} gutter={[4, 4]} wrap={true}>
          <Col span={24} className="promotion-switch">
            <div className="discount-switch-button col-switch">
              <FnbSwitch checked={isAllowInputDiscountFromPromotion} onChange={(checked) => setIsAllowInputDiscountFromPromotion(checked)} />
            </div>
            <span className="discount-switch-title" >
              {pageData.allowInputDiscountFromPromotion}
            </span>
          </Col>
          <Col span={24}>
            <span className="discount-switch-description">
              {pageData.allowInputDiscountFromPromotionTooltip}
            </span>
          </Col>
        </Row>
        <Row span={24} gutter={[4, 4]}>
          <Col span={24} className="discount-switch">
            <div className="partner-switch-button col-switch">
              <FnbSwitch checked={isAllowInputDiscountFromPartner} onChange={(checked) => setIsAllowInputDiscountFromPartner(checked)} />
            </div>
            <span className="partner-switch-title">
              {pageData.allowInputDiscountFromPartner}
            </span>
          </Col>
          <Col span={24}>
            <span className="partner-switch-description">
              {pageData.allowInputDiscountFromPartnerTooltip}
            </span>
          </Col>
        </Row></Row>

    </Form>
  }

  const onChangeImage = (file) => {
    setImage(file);
    setActiveFunctionButton(true);
  }

  const cancelAddNewFoodyPlatform = () => {
    onCompleted();
  }

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onClickAdd}
        onChange={onValidForm}
      >
        <Row gutter={[16, 16]}>
          <Col sm={24} xs={24} lg={16}>
            {renderAddNewForm()}
          </Col>
          <Col sm={24} xs={24} lg={8}>
            <span className="title-logo">{pageData.logo.title}</span>
            <Row  style={{marginTop: 8}} className={image ? "have-image" : "non-image"}>
              <Col className="content-image-product" span={24}>
                <div className={`image-product ${image !== null ? "justify-left" : ""}`}>
                  <Form.Item name={["image", "media"]}>
                    <FnbUploadImageComponent
                      buttonText={pageData.generalInformation.uploadImage}
                      iconUpload={<UploadLogoIcon />}
                      onChange={onChangeImage}
                    />
                  </Form.Item>
                </div>
              </Col>
              {/* TODO */}
              <Col className="text-non-image" hidden={image ? true : false}>
                <Typography disabled className="sub-title-logo">
                  {image && (
                    <ul>
                      <li>{pageData.logo.subTitle1}</li>
                      <li>{pageData.logo.subTitle2}</li>
                    </ul>
                  )}
                  {!image && pageData.logo.subTitle1}
                  {!image && <br />}
                  {!image && pageData.logo.subTitle2}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, { xs: 8, sm: 24, md: 24, lg: 32 }]}>
          <Col sm={0} xs={0} lg={9}></Col>
          <Col sm={9} xs={9} lg={3}>
            <div className="pt-1">
              <Form.Item>
                <Button type="primary" className="cancel-button w-100 mt-4" disabled={!activeFunctionButton} onClick={cancelAddNewFoodyPlatform}>
                  {pageData.btnCancel}
                </Button>
              </Form.Item>
            </div>
          </Col>
          <Col sm={9} xs={9} lg={3}>
            <div className="pt-1">
              <Form.Item>
                <Button htmlType="submit" type="primary" className="w-100 mt-4" disabled={!activeFunctionButton}>
                  {pageData.btnAdd}
                </Button>
              </Form.Item>
            </div>
          </Col>
          <Col sm={0} xs={0} lg={9}></Col>
        </Row>
      </Form>
    </>
  );
});