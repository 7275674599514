import React from "react";
import "./cancel-order-error-content.component.scss";
const CancelOrderErrorContent = ({ translateData, branchPhone }) => {
  const isMobile = navigator.userAgent.match(/iPhone|Android/i);
  return (
    <div>
      <p>{translateData.theOrderIsConfirmedAndBeingPrepared}</p>
      <br></br>
      <b>
        <a className="hotline-cancel-notification" href={isMobile ? `tel:${branchPhone}` : "javascript:void(0)"}>
          {translateData.hotline} {branchPhone}
        </a>
      </b>
    </div>
  );
};

export default CancelOrderErrorContent;
