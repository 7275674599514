import { Form, message } from "antd";
import { InputOTP as AntInputOTP } from "antd-input-otp";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import loginDataService from "../../../../../data-services/login-data.service";
import resetPasswordService from "../../../../../data-services/reset-password-data.service";
import {
  authActions,
  METHOD_SEND_OTP,
  resetPasswordSelector,
  STATUS_RESET_PASSWORD,
} from "../../../../../modules/auth/auth.reducer";
import {
  confirmSMSVerificationCode,
  signInWithPhoneNumber,
  writeFirebaseLog,
} from "../../../../../services/auth/auth.services";
import { localStorageKeys } from "../../../../../utils/localStorage.helpers";
import { ArrowLeftForgotPassword } from "../../../../assets/icons.constants";
import { PhoneCodeConstants } from "../../../../constants/phone-number.constants";
import { IconBackForgotPassword } from "./forgot-password.styled";
import {
  Footer,
  FooterNote,
  Heading,
  InputOTPContainer,
  InputOTPForm,
  ResendText,
  ResendTextCountDown,
} from "./input-otp.styled";

const FORM_ITEM_NAME = {
  OTP: "otp",
};

const InputOTP = ({ themePageConfig }) => {
  const [form] = Form.useForm();
  const pageData = {
    enterWrongCode: t("resetPassword.enterWrongCode"),
    cannotSendOTP: t("resetPassword.cannotSendOTP"),
    codeExpired: t("resetPassword.codeExpired"),
    pleaseEnterVerifyCode: t("resetPassword.pleaseEnterVerifyCode"),
    notReceiveCode: t("resetPassword.notReceiveCode"),
    reSend: t("loginPage.reSend"),
  };
  const [checkingOTP, setCheckingOTP] = useState(false);
  const [isShowResendButton, setIsShowResendButton] = useState(false);
  const [requestingResend, setRequestingResend] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();
  const { currentMethodSendOTP, token, iso, phoneNumber } = useSelector(resetPasswordSelector);

  const onChange = () => {
    let otp = "";
    const valueFormItemOtp = form.getFieldValue(FORM_ITEM_NAME.OTP);
    valueFormItemOtp?.forEach((item) => {
      otp += item;
    });
    if (otp?.length === 6) {
      verifyOTP(otp);
    }
  };
  const verifyOTP = async (otp) => {
    setCheckingOTP(true);
    if (currentMethodSendOTP === METHOD_SEND_OTP.FIREBASE) {
      confirmSMSVerificationCode(otp, ({ success, data, message, errorMessageCode }) => {
        setCheckingOTP(false);
        if (success) {
          dispatch(authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_NEW_PASSWORD }));
        } else {
          let errorMessage = pageData.enterWrongCode;
          if (errorMessageCode === "auth/code-expired") {
            errorMessage = pageData.codeExpired;
          }
          form.setFields([
            {
              name: FORM_ITEM_NAME.OTP,
              errors: [errorMessage],
            },
          ]);
        }
      });
    }
    if (currentMethodSendOTP === METHOD_SEND_OTP.GO_SELL) {
      const response = await loginDataService.verifyOTPGoSellByPhoneAsync(
        parsePhoneNumber(phoneNumber, iso).nationalNumber,
        otp,
      );
      setCheckingOTP(false);
      if (response?.data?.data) {
        dispatch(authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_NEW_PASSWORD }));
      } else {
        const expireTime = moment(token.expiredTime).add(-7, "minutes"); // Time expire of token is 10 minutes -7 minutes to get 3 minutes resend
        const duration = moment.duration(expireTime.diff(moment.utc()));
        const seconds = parseInt(duration.asSeconds());
        let messageError = pageData.enterWrongCode;
        if (seconds < 0) {
          messageError = pageData.codeExpired;
        }
        form.setFields([
          {
            name: FORM_ITEM_NAME.OTP,
            errors: [messageError],
          },
        ]);
      }
    }
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
      if (countdown === 0) {
        setIsShowResendButton(true);
      }
    }, 1e3);
    return () => {
      clearInterval(myInterval);
    };
  }, [countdown]);

  useEffect(() => {
    const expireTime = moment(token?.expiredTime).add(-7, "minutes"); // Time expire of token is 10 minutes -7 minutes to get 3 minutes resend
    const duration = moment.duration(expireTime.diff(moment.utc()));
    const seconds = parseInt(duration.asSeconds());
    if (seconds > 0) {
      setIsShowResendButton(false);
      setCountdown(seconds);
    } else {
      setIsShowResendButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const onClickResend = async () => {
    if (!requestingResend) {
      setRequestingResend(true);
      const storeId = JSON.parse(localStorage.getItem(localStorageKeys.STORE_CONFIG))?.storeId;
      const payload = {
        phoneNumber: parsePhoneNumber(phoneNumber, iso).nationalNumber,
        storeId,
      };
      const response = await resetPasswordService.generateTokenAsync(payload);
      if (response.data?.succeeded) {
        form.resetFields();
        dispatch(authActions.setResetPasswordState({ token: response.data?.data }));
        if (currentMethodSendOTP === METHOD_SEND_OTP.GO_SELL) {
          handleOTPGoSell();
        }
        if (currentMethodSendOTP === METHOD_SEND_OTP.FIREBASE) {
          const phoneNumberDto = parsePhoneNumber(phoneNumber, iso);
          signInWithPhoneNumber(phoneNumberDto.number, ({ success, data, message }) => {
            if (!success) {
              handleOTPGoSell();
              writeFirebaseLog(message);
            }
          });
        }
      } else {
        message.error(response.data?.message);
      }

      setRequestingResend(false);
    }
  };

  const handleOTPGoSell = async () => {
    dispatch(authActions.setResetPasswordState({ currentMethodSendOTP: METHOD_SEND_OTP.GO_SELL }));
    const phoneNumberDto = parsePhoneNumber(phoneNumber, iso);
    const payload = { countryCode: iso, phone: phoneNumberDto.nationalNumber, phoneCode: PhoneCodeConstants[iso] };
    const response = await loginDataService.sendOTPGoSellAsync(payload);
    if (!response?.data || !response?.data?.succeeded) {
      message.error(pageData.cannotSendOTP);
    }
  };

  return (
    <InputOTPForm>
      <Heading>
        <IconBackForgotPassword loading={checkingOTP}>
          <ArrowLeftForgotPassword
            onClick={() => {
              if (!checkingOTP) {
                dispatch(
                  authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_PHONE_NUMBER }),
                );
              }
            }}
          />
        </IconBackForgotPassword>
        <span>{pageData.pleaseEnterVerifyCode}</span>
      </Heading>
      <InputOTPContainer>
        <Form form={form} onChange={onChange}>
          <Form.Item name={FORM_ITEM_NAME.OTP}>
            <AntInputOTP disabled={checkingOTP} inputMode="numeric" />
          </Form.Item>
        </Form>
      </InputOTPContainer>
      <Footer>
        {isShowResendButton ? (
          <>
            <FooterNote style={{ color: themePageConfig?.colorGroup?.textColor }}>{pageData.notReceiveCode}</FooterNote>
            <ResendText disabled={requestingResend} onClick={onClickResend}>
              {pageData.reSend}
            </ResendText>
          </>
        ) : (
          <ResendTextCountDown>
            {pageData.reSend} {countdown}'s
          </ResendTextCountDown>
        )}
      </Footer>
    </InputOTPForm>
  );
};

export default InputOTP;
