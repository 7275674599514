import React from "react";
import "./input-validate-message.scss";
import { ForbiddenIcon, SuccessIcon, Warning2Icon } from "constants/icons.constants";

export const EnumInputValidateType = {
  ERROR: 0,
  WARRING: 1,
  SUCCESS: 2,
};

export function InputValidateMessage({ type = EnumInputValidateType.ERROR, message, id, ref, className }) {
  switch (type) {
    case EnumInputValidateType.ERROR:
      return (
        <div id={id} ref={ref} className={`validate-input-message ${className}`}>
          <span className="validate-input-icon">
            <ForbiddenIcon />
          </span>
          <span className="error-message">{message}</span>
        </div>
      );
    case EnumInputValidateType.WARRING:
      return (
        <div id={id} ref={ref} className={`validate-input-message ${className}`}>
          <span className="validate-input-icon">
            <Warning2Icon />
          </span>
          <span className="warring-message">{message}</span>
        </div>
      );
    default:
      return (
        <div id={id} ref={ref} className={`validate-input-message ${className}`}>
          <span className="validate-input-icon">
            <SuccessIcon />
          </span>
          <span className="success-message">{message}</span>
        </div>
      );
  }
}
