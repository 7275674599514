import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Tooltip } from "antd";
import { arrayMoveImmutable } from "array-move";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { DragDropIcon, SearchLightIcon, TrashFill } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { hasPermission } from "utils/helpers";
import "./product-in-category.modal.scss";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";

const DragHandle = SortableHandle(() => <DragDropIcon />);

const TableProduct = ({
  title,
  showProductsModal,
  dataModal,
  setDataModal,
  onRemoveItemModal,
  onSearchProductModal,
  products,
  onSelectedProduct,
  onCancel,
  onSubmitModal,
}) => {
  const [t] = useTranslation();
  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    addNew: t("button.addNew"),
    title: t("productCategory.title"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    productCategoryDeleteSuccess: t("productCategory.productCategoryDeleteSuccess"),
    table: {
      searchPlaceholder: t("productManagement.table.searchPlaceholder"),
      no: t("table.no"),
      name: t("productCategory.table.name"),
      unit: t("table.unit"),
      action: t("materialCategory.table.action"),
    },
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.table.no,
        dataIndex: "sort",
        width: "5rem",
        className: "drag-visible",
        render: (_, render, index) => {
          if (hasPermission(PermissionKeys.EDIT_PRODUCT_CATEGORY))
            return (
              <>
                <DragHandle />
                {index + 1}
              </>
            );
        },
      },
      {
        title: pageData.table.name,
        dataIndex: "thumbnail",
        render: (_, record) => {
          return (
            <Row className="table-img-box">
              <Thumbnail src={record?.thumbnail} />
              <FnbTooltip maxWidthContent={"calc(100% - 64px)"}>{record?.name}</FnbTooltip>
            </Row>
          );
        },
      },
      {
        title: pageData.table.unit,
        width: 150,
        dataIndex: "unitName",
      },
    ];

    if (hasPermission(PermissionKeys.EDIT_PRODUCT_CATEGORY)) {
      const actionColumn = {
        title: pageData.table.action,
        key: "action",
        width: 130,
        align: "center",
        className: "",
        render: (_, record) => {
          return (
            <>
              <a onClick={() => onHandleRemoveItem(record?.productId)}>
                <div className="fnb-table-action-icon">
                  <Tooltip placement="top" title={t("button.delete")} color="#50429B">
                    <TrashFill className="icon-svg-hover" />
                  </Tooltip>
                </div>
              </a>
            </>
          );
        },
      };
      columns.push(actionColumn);
    }

    return columns;
  };

  const onHandleRemoveItem = async (productId) => {
    onRemoveItemModal && onRemoveItemModal(productId);
  };

  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataModal, oldIndex+1, newIndex+1).filter((el) => !!el);
      setDataModal(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody useDragHandle disableAutoscroll className="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = dataModal.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem style={{ zIndex: 9999 }} index={index} {...restProps} />;
  };

  const renderHeaderModal = () => {
    if (hasPermission(PermissionKeys.EDIT_PRODUCT_CATEGORY)) {
      return (
        <>
          <FnbSelectSingle
            value={null}
            placeholder={pageData.table.searchPlaceholder}
            showSearch
            onChange={(value) => onSelectedProduct(value)}
            option={products?.map((item) => {
              return {
                key: item?.productId,
                id: item?.productId,
                name: item?.name,
                unit: item?.unitName,
                thumbnail: item?.thumbnail,
                isTopping: item?.isTopping,
              };
            })}
            showThumbnail={true}
            suffixIcon={<SearchLightIcon />}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </>
      );
    } else {
      return (
        <Input
          maxLength={100}
          allowClear
          size="large"
          placeholder={pageData.table.searchPlaceholder}
          className="modal-input-search"
          onChange={(e) => onSearchProductModal(e.target.value)}
          prefix={<SearchOutlined />}
        />
      );
    }
  };

  return (
    <FnbModal
      className={"modal-product-in-category"}
      closeIcon
      title={title}
      visible={showProductsModal}
      width={800}
      content={
        <Form>
          <div className="content-inner">
            <Row style={{ display: "contents" }}>
              <Row>
                <Col span={24}>
                  <Form.Item className="form-wrapper">{renderHeaderModal()}</Form.Item>
                </Col>
              </Row>
              <div className="fnb-table-wrapper product-modal-table">
                <Row>
                  <Col span={24} style={{ minHeight: "60vh" }}>
                    <FnbTable
                      pagination={false}
                      columns={getColumns()}
                      rowKey="index"
                      dataSource={dataModal.map((item, index) => {
                        item.index = index + 1;
                        return item;
                      })}
                      components={{
                        body: {
                          wrapper: DraggableContainer,
                          row: DraggableBodyRow,
                        },
                      }}
                      scrollY={"60vh"}
                      scrollX={"100%"}
                    />
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </Form>
      }
      permissionOk={PermissionKeys.EDIT_PRODUCT_CATEGORY}
      okText={pageData.btnSave}
      handleCancel={onCancel}
      onOk={onSubmitModal}
    />
  );
};
export default TableProduct;
