import React, { useEffect, useState } from "react";
import "./fnb-tabs.style.v2.scss";
import { Row, Space, Col } from "antd";
import { FnbTabItem } from "./components/fnb-tab-item";
import { FnbPanelItem } from "./components/fnb-panel-item";
import { memo } from "react";
export const FnbTabsV2 = memo((props) => {
  const {
    defaultScreen,
    tabs = [],
    offsetHeight = 0, //Important prop that you must calculate. Soon i will write a example about this prop
    classNameTabItem = "",
    classNamePanelItem = "",
    onChangeTab,
    paddingContentTab = 12,
  } = props;
  const [currentScreen, setCurrentScreen] = useState(defaultScreen);

  useEffect(() => {
    setCurrentScreen(defaultScreen);
  }, [defaultScreen]);

  /*
  Usage;
  const screens = {
    LoyaltyPointConfiguration: 1,
    refer: 2,
  };
  const tabs = [
    {
      screen: screens.LoyaltyPointConfiguration, //Must declare constant for each tab
      title: (  //Title could be a string or jsx
        <>
          <Text className="fnb-tab__txt-tab">{pageData.loyaltyPointDetail}</Text>
          <FnbSwitch
            className="fnb-tab__switcher-tab"
            onChange={(value) => onSwitchLoyaltyPointConfig(value)}
            checked={switcherLoyaltyPointConfig}
          />
        </>
      ),
      component: <LoyaltyPointConfiguration />,  //Component
    },
    {
      screen: screens.refer,
      title: (
        <>
          <Text className="fnb-tab__txt-tab">{pageData.referralPoint}</Text>
          <FnbSwitch
            className="fnb-tab__switcher-tab"
            onChange={(value) => onSwitchReferralPointConfig(value)}
            checked={switcherReferralPointConfig}
          />
        </>
      ),
      component: <ReferralPointConfiguration />,
    },
  ];

  <FnbTabsV2 defaultScreen={screens.LoyaltyPointConfiguration} tabs={tabs} />
  */

  const onChangeScreen = (screen) => {
    //If dev dont pass an onChangeTab function it will change screen on its own otherwise, it would need to change screen inside the onChangeTab function.
    onChangeTab ? onChangeTab(screen) : setCurrentScreen(screen);
  };

  return (
    <div className="fnb-tab">
      <Row className="fnb-tab__row">
        <Col className="fnb-tab__nav">
          <Space className="fnb-tab__space" size={12} align="left" direction="vertical" style={{ display: "flex" }}>
            {tabs.map((tab) => {
              return (
                <FnbTabItem
                  screen={tab.screen}
                  title={tab.title}
                  isCurrentScreen={tab.screen === currentScreen}
                  onClick={() => onChangeScreen(tab.screen)}
                  className={classNameTabItem}
                />
              );
            })}
          </Space>
        </Col>
        <Col
          className="fnb-tab__panel"
          style={{
            height: offsetHeight === 0 ? "auto" : `calc(100vh - ${offsetHeight}px)`,
            padding: paddingContentTab,
          }}
        >
          {tabs.map((tab) => {
            return (
              <FnbPanelItem
                screen={tab.screen}
                isCurrentScreen={tab.screen == currentScreen}
                className={classNamePanelItem}
                component={tab.component}
              />
            );
          })}
        </Col>
      </Row>
    </div>
  );
});
