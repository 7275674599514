import { Col, Form, message, Row } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbPageHeader } from "components/fnb-page-header/fnb-page-header";
import { DELAYED_TIME } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import pageDataService from "data-services/page/page-data.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/helpers";
import "./create-page-management.page.scss";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbFroalaEditor from "components/fnb-froala-editor";

export default function CreatePageManagement() {
  const [t] = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [pageContentEditor, setPageContentEditor] = useState("");
  const [isChangeForm, setIsChangeForm] = useState(false);
  const createdTimeDefault = formatDate(new Date(), DateFormat.DD_MMM_YYYY);
  const state = useSelector((state) => state);
  const userFullName = state?.session?.currentUser?.fullName;

  const pageData = {
    btnCancel: t("button.cancel"),
    btnAddNew: t("button.create"),

    createPage: t("onlineStore.pageManagement.createPage"),
    pageName: t("onlineStore.pageManagement.pageName"),
    createPageInfo: t("onlineStore.pageManagement.createPageInfo"),
    pageNamePlaceholder: t("onlineStore.pageManagement.pageNamePlaceholder"),
    pageNameValidation: t("onlineStore.pageManagement.pageNameValidation"),
    pageContent: t("onlineStore.pageManagement.pageContent"),
    pageContentValidation: t("onlineStore.pageManagement.pageContentValidation"),
    createPageSuccess: t("onlineStore.pageManagement.createPageSuccess"),
    createPageFailed: t("onlineStore.pageManagement.createPageFailed"),
    createdBy: t("onlineStore.pageManagement.createdBy"),
    createdTime: t("onlineStore.pageManagement.createdTime"),
    updatedTime: t("onlineStore.pageManagement.updatedTime"),
    generalInformation: t("title.generalInformation"),
    pageContentPlaceholder: t("onlineStore.pageManagement.pageContentPlaceholder"),
  };

  const onChangePageContentEditor = (value) => {
    if (value !== "" && value !== "<div></div>") setIsChangeForm(true);
    setPageContentEditor(value);
    form.setFieldValue("pageContent", value);
  };

  const onClickCreatePage = async () => {
    form.validateFields().then(async (values) => {
      let request = {
        page: {
          ...values,
          pageContent: pageContentEditor,
        },
      };
      let res = await pageDataService.createPageAsync(request);
      if (res) {
        message.success(pageData.createPageSuccess);
        redirectToPageManagement();
      } else {
        message.error(pageData.createPageFailed);
      }
    });
  };

  const redirectToPageManagement = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/online-store/page-management");
    }, DELAYED_TIME);
  };

  return (
    <>
      <FnbPageHeader
        actionDisabled={isChangeForm ? false : true}
        title={pageData.createPage}
        actionButtons={[
          {
            action: <FnbAddNewButton onClick={onClickCreatePage} text={pageData.btnAddNew} disabled={!isChangeForm} />,
            permission: PermissionKeys.CREATE_PAGE,
          },
          {
            action: <FnbCancelButton showWarning={isChangeForm} onOk={redirectToPageManagement} />,
          },
        ]}
      />
      <Form
        className="create-qr-code custom-form"
        form={form}
        layout="vertical"
        autoComplete="off"
        onFieldsChange={() => setIsChangeForm(true)}
      >
        <Row gutter={[24, 24]}>
          <Col span={16}>
            <FnbCard title={pageData.generalInformation}>
              <Row gutter={[16, 16]}>
                <Col sm={24} lg={24} className="w-100">
                  <Form.Item
                    name="pageName"
                    label={pageData.pageName}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pageNameValidation}
                          />
                        ),
                      },
                    ]}
                  >
                    <FnbInput showCount placeholder={pageData.pageNamePlaceholder} maxLength={100} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col sm={24} lg={24} className="w-100">
                  <Form.Item
                    name="pageContent"
                    label={pageData.pageContent}
                    value={pageContentEditor}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pageContentValidation}
                          />
                        ),
                      },
                    ]}
                  >
                    {/* Content */}
                    <div className="footer__content">
                      <div className="footer__content_editor">
                        <FnbFroalaEditor
                          value={pageContentEditor}
                          placeholder={pageData.pageContentPlaceholder}
                          onChange={(value) => onChangePageContentEditor(value)}
                          charCounterMax={-1}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FnbCard>
          </Col>
          <Col span={8}>
            <FnbCard title={pageData.createPageInfo}>
              <Row gutter={[24, 24]}>
                <Col sm={24} lg={24} className="w-100">
                  <Row className="mb-2">
                    <Col span={24}>
                      <div className="left-column">{pageData.createdBy}</div>
                      <div className="right-column">
                        <div className="fnb-form-label-right">{userFullName ? userFullName : "-"}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={24}>
                      <div className="left-column">{pageData.createdTime}</div>
                      <div className="right-column">
                        <div className="fnb-form-label-right">{createdTimeDefault ? createdTimeDefault : "-"}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="left-column">{pageData.updatedTime}</div>
                      <div className="right-column">
                        <div className="fnb-form-label-right">-</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FnbCard>
          </Col>
        </Row>
      </Form>
    </>
  );
}
