import "./staff.page.scss";
import { Card, message } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TableStaff from "./components/table-staff.component";

export default function Staff(props) {
  const { screenKey } = props;
  const history = useHistory();
  const location = useLocation();

  const openEditStaffPage = (_selectedRowKeys, selectedRows) => {
    const { id } = selectedRows?.[0];
    history.push(`/staff/edit/${id}`);
  };

  useEffect(() => {
    let state = location?.state;
    if (state?.savedSuccessfully) {
      message.success(state?.message);
      history.replace();
    }
  }, []);

  return (
    <div>
      <div className="clearfix"></div>
      <Card className="fnb-card-custom">
        <TableStaff screenKey={screenKey} onEditItem={openEditStaffPage} />
      </Card>
    </div>
  );
}
