import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import shiftDataService from "data-services/shift/shift-data.service";
import ShiftManagement from "./shift.page";
import reportDataService from "data-services/report/report-data.service";

const mapDispatchToProps = () => {
  return {
    shiftDataService: shiftDataService,
    reportDataService: reportDataService,
  };
};

export default compose(
  withTranslation("translations"),
  connect(null, mapDispatchToProps),
  withRouter
)(ShiftManagement);
