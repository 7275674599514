import { FnbButton } from "components/fnb-button/fnb-button";
import packageDataService from "data-services/package/package-data.service";
import BillingComponent from "pages/billing/components/billing.component";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LeftArrowOutlined,
  SubIcon,
  TickCircle2FinishIcon,
  TickCircleTabIcon,
} from "../../../constants/icons.constants";
import { useHistory, useLocation } from "react-router-dom";

import "./subscription.component.scss";
import orderPackageService from "data-services/order-package/order-package.service";
import OrderPackageComponent from "pages/billing/components/order-package/order-package.component";
import PackageList from "./package-list.component";
import { Col, Row } from "antd";

const STEP_PACKAGE = {
  IDLE: -1,
  SELECT_PACKAGE: 0,
  PAYMENT: 1,
  COMPLETED: 2,
};

const SECOND_TAB = {
  ACTIVE_PACKAGE: 0,
  SUBSCRIPTION: 1,
};

export const SubscriptionComponent = (props) => {
  const { userInformation, step } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(null);
  const [packages, setPackages] = useState([]);
  const [periodPackageDurations, setPeriodPackageDurations] = useState([]);
  const [defaultHoverPackageId, setDefaultHoverPackageId] = useState(null);
  const [mainGroupFunctions, setMainGroupFunctions] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(12);
  const [orderPackages, setOrderPackages] = useState([]);
  const [secondTabActive, setSecondTabActive] = useState(SECOND_TAB.ACTIVE_PACKAGE);

  const billingComponentRef = useRef(null);

  const initData = async () => {
    const responsePackages = await packageDataService.getPackagesAsync();
    if (responsePackages?.succeeded) {
      setPackages(responsePackages?.data?.packages);
      setPeriodPackageDurations(responsePackages?.data?.periods);
      const defaultPackageIdSelected =
        responsePackages?.data?.packages[responsePackages?.data?.packages?.length - 1].id;
      setDefaultHoverPackageId(defaultPackageIdSelected);
    }

    const responseFunctions = await packageDataService.getFunctionAsync();
    if (responseFunctions?.succeeded) {
      setMainGroupFunctions(responseFunctions?.data?.mainGroupFunctions);
    }

    const orderPackagesRequest = {};
    await getOrderPackages(orderPackagesRequest);
  };

  async function getOrderPackages(request) {
    const orderPackagesResponse = await orderPackageService.getListAsync(request);
    if (orderPackagesResponse?.succeeded) {
      setOrderPackages(orderPackagesResponse?.data?.orderPackages);
    }
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (step != null) {
      setCurrentStep(step);
    } else {
      setCurrentStep(STEP_PACKAGE.IDLE);
    }
  }, [step]);

  const goToStep = (step) => {
    if (step >= 0) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("step", step + 1);
      history.push(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  const onClickSelectPackage = (step) => {
    setCurrentStep(step);
    goToStep(step);
  };

  const onClickBuyPackage = (period, packageId) => {
    const packageItem = packages?.find((item) => item.id === packageId);
    const packagePricingInfo = packageItem?.pricings?.find((item) => item.period === period);
    const currencyCode = packagePricingInfo?.currencyCode;
    const indexDuration = periodPackageDurations?.findIndex((item) => item === period);
    if (billingComponentRef.current) {
      billingComponentRef.current?.selectPricingPackage(
        packageId,
        packagePricingInfo,
        currencyCode,
        packageItem?.tax,
        indexDuration,
      );
      setTimeout(() => {
        const step = STEP_PACKAGE.PAYMENT;
        setCurrentStep(step);
        goToStep(step);
      }, 100);
    }
  };
  const resetDefaultPackage = () => {
    const defaultPackageIdSelected = packages[packages?.length - 1].id;
    setPeriodSelected(12);
    setDefaultHoverPackageId(defaultPackageIdSelected);
  };

  const handleCallback = () => {
    setCurrentStep(STEP_PACKAGE.IDLE);
    history.push(`/my-account?tab=subscription`);
  };

  function onChangeSecondTab(value) {
    if (value !== secondTabActive) {
      setSecondTabActive(value);
    }
  }

  return (
    <div className="subscription-package-page">
      {currentStep === STEP_PACKAGE.IDLE ? (
        <div className="first-content">
          <div className="second-tabs">
            <div
              className={`second-tab ${secondTabActive === SECOND_TAB.ACTIVE_PACKAGE && "second-tab-active"}`}
              onClick={() => onChangeSecondTab(SECOND_TAB.ACTIVE_PACKAGE)}
            >
              <TickCircleTabIcon /> {t("packageTable.yourPlan")}
            </div>
            <div
              className={`second-tab ${secondTabActive === SECOND_TAB.SUBSCRIPTION && "second-tab-active"}`}
              onClick={() => onChangeSecondTab(SECOND_TAB.SUBSCRIPTION)}
            >
              <SubIcon />
              {t("myAccount.tabName.subscription")}
            </div>
          </div>

          {secondTabActive === SECOND_TAB.SUBSCRIPTION ? (
            <div className="second-content subscription-tab">
              <OrderPackageComponent orderPackages={orderPackages} getOrderPackages={getOrderPackages} />
            </div>
          ) : (
            <div className="second-content">
              <PackageList showBillingComponent={() => onClickSelectPackage(STEP_PACKAGE.SELECT_PACKAGE)} />
            </div>
          )}
        </div>
      ) : (
        <div className="subscription-package-tab">
          {currentStep === STEP_PACKAGE.SELECT_PACKAGE && (
            <FnbButton
              text={t("button.back")}
              iconHeader={<LeftArrowOutlined />}
              variant="tertiary"
              onClick={handleCallback}
              className="package-container__btn-go-back"
            />
          )}
          <BillingComponent
            ref={billingComponentRef}
            userInfo={userInformation}
            currentStep={currentStep}
            onChangeStep={setCurrentStep}
            packages={packages}
            mainGroupFunctions={mainGroupFunctions}
            periodPackageDurations={periodPackageDurations}
            defaultHoverPackageId={defaultHoverPackageId}
            setDefaultHoverPackageId={setDefaultHoverPackageId}
            onClickBuyPackage={onClickBuyPackage}
            resetDefaultPackage={resetDefaultPackage}
            periodSelected={periodSelected}
            setPeriodSelected={setPeriodSelected}
            minWidthTableFunctions={1120}
          />
        </div>
      )}
    </div>
  );
};
