export const OptionDateTime = {
  today: 0,
  yesterday: 1,
  thisWeek: 2,
  lastWeek: 3,
  thisMonth: 4,
  lastMonth: 5,
  thisYear: 6,
  customize: 7,
  allTime: 8,
};

export const weekDays = {};
