const AddressHomeMappingIcon = ({ fillColor = "#BBAC5E" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C9.61401 2.00243 7.32649 2.87939 5.63926 4.43852C3.95202 5.99765 3.00286 8.11161 3 10.3167C3 12.4583 4.79441 15.81 8.33363 20.2783C8.75497 20.8117 9.30663 21.2457 9.94373 21.5449C10.5808 21.8441 11.2854 22 12 22C12.7146 22 13.4192 21.8441 14.0563 21.5449C14.6934 21.2457 15.245 20.8117 15.6664 20.2783C19.2056 15.81 21 12.4583 21 10.3167C20.9971 8.11161 20.048 5.99765 18.3607 4.43852C16.6735 2.87939 14.386 2.00243 12 2ZM12 13.6316C11.2866 13.6316 10.5893 13.4362 9.99614 13.0699C9.403 12.7036 8.9407 12.183 8.6677 11.5739C8.39471 10.9648 8.32328 10.2946 8.46245 9.64802C8.60162 9.00142 8.94514 8.40748 9.44957 7.9413C9.954 7.47513 10.5967 7.15766 11.2963 7.02904C11.996 6.90042 12.7212 6.96644 13.3803 7.21873C14.0393 7.47102 14.6027 7.89826 14.999 8.44642C15.3953 8.99459 15.6069 9.63905 15.6069 10.2983C15.6069 11.1824 15.2268 12.0302 14.5504 12.6553C13.874 13.2805 12.9566 13.6316 12 13.6316Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default AddressHomeMappingIcon;
