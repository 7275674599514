import { SpeakerOutlined } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import i18n from "utils/i18n";
import CreateEmailCampaignPage from "./email-campaign/create-email-campaign/create-email-campaign.page";
import EditEmailCampaignPage from "./email-campaign/edit-email-campaign/edit-email-campaign.component";
import { EmailCampaign } from "./email-campaign/email-campaign.page";
import ViewEmailCampaignPage from "./email-campaign/view-email-campaign/view-email-campaign.component";
import CreateNotificationCampaign from "./notification-campaign/create-notification-campaign/create-notification-campaign.page";
import EditNotificationCampaign from "./notification-campaign/edit-notification-campaign/edit-notification-campaign.page";
import { NotificationCampaign } from "./notification-campaign/notification-campaign.page";
import ViewNotificationCampaignPage from "./notification-campaign/view-notification-campaign/view-notification-campaign.page";
import CloneQrOrderPage from "./qr-order/clone-qr-order/clone-qr-order.page";
import CreateQrOrderPage from "./qr-order/create-qr-order/create-qr-order.page";
import QrOrderDetailPage from "./qr-order/detail-qr-order/detail-qr-order.page";
import EditQrOrderPage from "./qr-order/edit-qr-order/edit-qr-order.page";
import { QROrder } from "./qr-order/qr-order.page";
const { t } = i18n;

const route = {
  key: "app.marketing",
  position: 6,
  path: "#",
  icon: <SpeakerOutlined />,
  name: t("menu.marketing"),
  isMenu: true,
  exact: true,
  auth: true,
  child: [
    {
      key: "app.marketing.qrOrder",
      position: 6,
      path: "/marketing/qr-order",
      name: t("menu.marketingManagement.qrCode"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_QR_CODE,
      component: QROrder,
      child: [],
    },
    {
      key: "app.marketing.createQrOrder",
      focus: "app.marketing.qrOrder",
      position: 6,
      path: "/qr-order/create-new",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_QR_CODE,
      component: CreateQrOrderPage,
      child: [],
    },
    {
      key: "app.marketing.qrOrder.detail",
      focus: "app.marketing.qrOrder",
      position: 3,
      path: "/marketing/qr-order/detail/:qrCodeId",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_QR_CODE,
      component: QrOrderDetailPage,
      child: [],
    },
    {
      key: "app.marketing.cloneQrOrder",
      focus: "app.marketing.qrOrder",
      position: 6,
      path: "/qr-order/clone",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_QR_CODE,
      component: CloneQrOrderPage,
      child: [],
    },
    {
      key: "app.marketing.editQrOrder",
      focus: "app.marketing.qrOrder",
      position: 6,
      path: "/qr-order/edit/:id",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_QR_CODE,
      component: EditQrOrderPage,
      child: [],
    },
    {
      key: "app.marketing.emailCampaign",
      position: 6,
      path: "/marketing/email-campaign",
      name: t("menu.marketingManagement.emailCampaign"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_EMAIL_CAMPAIGN,
      component: EmailCampaign,
      child: [],
    },
    {
      key: "app.marketing.emailCampaign",
      position: 7,
      path: "/email-campaign/create-new",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_EMAIL_CAMPAIGN,
      component: CreateEmailCampaignPage,
      child: [],
    },
    {
      key: "app.marketing.emailCampaign",
      focus: "app.marketing.emailCampaign",
      position: 8,
      path: "/email-campaign/edit/:emailCampaignId",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_EMAIL_CAMPAIGN,
      component: EditEmailCampaignPage,
      child: [],
    },
    {
      key: "app.marketing.emailCampaign",
      position: 9,
      path: "/marketing/email-campaign/detail/:emailCampaignId",
      name: "View Email Campaign",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_EMAIL_CAMPAIGN,
      component: ViewEmailCampaignPage,
      child: [],
    },
    {
      key: "app.marketing.notificationCampaign",
      position: 9,
      path: "/marketing/notification-campaign",
      name: t("menu.marketingManagement.notificationCampaign"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_NOTIFICATION_CAMPAIGN,
      component: NotificationCampaign,
      child: [],
    },
    {
      key: "app.marketing.create-notification-campaign",
      focus: "app.marketing.notificationCampaign",
      position: 9,
      path: "/marketing/notification-campaign/create-notification-campaign",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_NOTIFICATION_CAMPAIGN,
      component: CreateNotificationCampaign,
      child: [],
    },
    {
      key: "app.marketing.notificationCampaign",
      position: 9,
      path: "/marketing/notification-campaign/view/:notificationCampaignId",
      name: "View Notification Campaign",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_NOTIFICATION_CAMPAIGN,
      component: ViewNotificationCampaignPage,
      child: [],
    },
    {
      key: "app.marketing.notificationCampaign",
      position: 9,
      path: "/marketing/notification-campaign/edit/:notificationCampaignId",
      name: "Edit Notification Campaign",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_NOTIFICATION_CAMPAIGN,
      component: EditNotificationCampaign,
      child: [],
    },
  ],
};

export default route;
