import styled from "styled-components";
import theme from "theme";

export const CustomBranchStyled = styled.div`
  .ant-select-item.ant-select-item-option-selected {
    background: ${theme.colors.primary[30]} !important;
    flex-direction: row-reverse;
    gap: 12px;
    .ant-select-item-option-state {
      background: ${theme.colors.primary[100]};
      border-radius: 8px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
`;
