import { Form, Radio } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./filter-customer.style.scss";
import { useMediaQuery } from "react-responsive";
import React from "react";
import FnbRadioButton from "components/fnb-radio-button";

const FilterCustomer = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const {
    dataFilter,
    setDataFilter,
    handleFilterCustomer,
    pageSize,
    keySearch,
    listPlatforms,
    listCustomerMembershipLevels,
    listCustomerSegments,
    listTags,
    handleScrollDownCustomerMembership,
    handleScrollDownCustomerSegment,
    onSearchCustomerSegment,
    onClearCustomerSegment,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isMaxWidth790px = useMediaQuery({ minWidth: 577, maxWidth: 790 });
  const isMaxWidth1080px = useMediaQuery({ minWidth: 791, maxWidth: 1080 });
  const MAX_LENGTH_TAG = isMobile || isMaxWidth1080px ? 27 : isMaxWidth790px ? 24 : 45;
  const pageData = {
    filter: {
      platform: {
        title: t("platform.title"),
      },
      customerMemberShipLevels: {
        title: t("customerManagement.filter.rank.title"),
      },
      customerSegment: {
        title: t("customerManagement.filter.customerSegment.title"),
        noSegmentIsSelected: t("customerManagement.filter.customerSegment.noSegmentIsSelected"),
      },
      tag: {
        title: t("customerManagement.filter.tag.title"),
        noTagIsSelected: t("customerManagement.filter.tag.noTagIsSelected"),
      },
      title: t("customerManagement.filter.title"),
      cancel: t("customerManagement.filter.cancel"),
      apply: t("customerManagement.filter.apply"),
    },
  };

  const defaultPlatformId = "";
  const defaultValueCustomerMembershipLevel = "";

  const [isHaveTag, setIsHaveTag] = useState(false);

  const onApplyFilter = () => {
    var fieldsErrors = form.getFieldsError();
    let isFormValid = fieldsErrors.find((item) => item?.errors?.length > 0);
    if (isFormValid === undefined || !isFormValid) {
      let formValue = form.getFieldsValue();
      setIsHaveTag(formValue?.tagIds?.length > 0);
      if (!isMobile) {
        handleFilterCustomer(1, pageSize, keySearch, formValue);
        setDataFilter(formValue);
        formValue.count = countFilterControl(formValue);
      }
    }
  };

  // Update datafilter when using mobile
  useEffect(() => {
    if (isMobile && dataFilter) {
      form.setFieldsValue({
        platformId: dataFilter?.platformId ?? defaultPlatformId,
        customerMembershipId: dataFilter?.customerMembershipId ?? defaultValueCustomerMembershipLevel,
      });

      if (dataFilter?.customerSegmentId) {
        form.setFieldsValue({
          customerSegmentId: dataFilter?.customerSegmentId,
        });
      } else {
        form.resetFields(["customerSegmentId"]);
      }
      if (dataFilter?.tagIds) {
        form.setFieldsValue({
          tagIds: dataFilter?.tagIds,
        });
        setIsHaveTag(dataFilter?.tagIds?.length > 0);
      } else {
        form.resetFields(["tagIds"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-unused-vars
  const handleApplyFilter = () => {
    let formValue = form.getFieldsValue();
    formValue.count = countFilterControl(formValue);
    handleFilterCustomer(1, pageSize, keySearch, formValue);
    setDataFilter(formValue);
  };

  const countFilterControl = (formValue) => {
    let countPlatform = formValue.platformId === "" || formValue.platformId === undefined ? 0 : 1;
    let countcustomerMembership =
      formValue.customerMembershipId === "" || formValue.customerMembershipId === undefined ? 0 : 1;
    let countCustomerSegmentId =
      formValue.customerSegmentId === "" || formValue.customerSegmentId === undefined ? 0 : 1;
    let countTag = formValue.tagIds === "" || formValue.tagIds === undefined || formValue.tagIds?.length === 0 ? 0 : 1;
    return countPlatform + countcustomerMembership + countCustomerSegmentId + countTag;
  };

  React.useImperativeHandle(ref, () => ({
    resetFilter: () => {
      form?.resetFields();
      if (isMobile) {
        form.setFieldsValue({
          customerMembershipId: defaultValueCustomerMembershipLevel,
        });
      } else {
        onApplyFilter();
      }
    },
  }));

  return (
    <Form form={form} layout="vertical" onFieldsChange={onApplyFilter} className="filter-customer-management">
      <Form.Item label={pageData.filter.platform.title} name="platformId">
        <Radio.Group
          defaultValue={defaultPlatformId}
          buttonStyle="solid"
          className="filter-customer-management__options-sale-channels"
        >
          {listPlatforms?.map((item, index) => (
            <FnbRadioButton value={item?.id} key={index}>
              {item?.name}
            </FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label={pageData.filter.customerMemberShipLevels.title} name="customerMembershipId">
        <FnbSelectSingle
          className="form-select"
          fixed
          showSearch
          defaultValue={defaultValueCustomerMembershipLevel}
          option={listCustomerMembershipLevels}
          onPopupScroll={handleScrollDownCustomerMembership}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.customerSegment.title} name="customerSegmentId">
        <FnbSelectSingle
          placeholder={pageData.filter.customerSegment.noSegmentIsSelected}
          className="form-select"
          showSearch
          allowClear
          fixed={!isMobile}
          option={listCustomerSegments}
          onPopupScroll={handleScrollDownCustomerSegment}
          onSearch={onSearchCustomerSegment}
          onClear={onClearCustomerSegment}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.tag.title} name="tagIds">
        <FnbSelectMultiple
          fixed={!isMobile}
          placeholder={pageData.filter.tag.noTagIsSelected}
          className={`form-select select-muliple-tags-customer selection-item-customer-manager ${
            isHaveTag ? "" : "select-muliple-tags-customer--not-have-value"
          }`}
          allowClear
          option={listTags?.map((item) => ({
            id: item.id,
            name: item.name.length > MAX_LENGTH_TAG ? `${item.name.substring(0, MAX_LENGTH_TAG)}...` : item.name,
          }))}
          placement={`${isMobile ? "topRight" : ""}`}
        />
      </Form.Item>
    </Form>
  );
});

export default FilterCustomer;
