import { Col, Row } from "antd";
import { capitalize, formatCurrencyWithoutSuffix, formatPhoneNumber } from "utils/helpers";
import { FnbTable } from "components/fnb-table/fnb-table";
import { useState, useEffect } from "react";
import shiftDataService from "data-services/shift/shift-data.service";
import { useParams } from "react-router-dom";
import { orderStatusBadgeVariant, orderStatusLocalization } from "constants/order-status.constants";
import { OrderServiceTypeLocalization } from "constants/order-type-status.constants";
import { orderPaymentMethodLocalization, paymentMethod } from "constants/payment-method.constants";
import theme from "theme";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { useSelector } from "react-redux";
import "./table-order.component.scss"

export default function TableShiftOrderDetail(props) {
  const { t } = props;
  const params = useParams();
  const pageData = {
    id: t("order.orderId"),
    status: t("table.status"),
    serviceType: t("table.serviceType"),
    details: t("table.details"),
    customer: t("table.customer"),
    point: t("customer.point"),
    total: t("table.total"),
    paymentMethod: t("payment.paymentMethod"),
    discount: t("promotion.table.discount"),
    grossTotal: t("table.grossTotal"),
    subtotal: t("table.subtotal"),
    feeAndTax: t("order.feeAndTax"),
    name: t("customer.name"),
    phone: t("customer.phone"),
  };
  const [listOrders, setListOrders] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const PAGESIZE_DEFAULT = 20,
    PAGENUMBER_DEFAULT = 1;
  const getListOrder = async (pageNumber) => {
    const id = params?.shiftId;
    const resList = await shiftDataService.getShiftDetailOrderAsync(id, pageNumber, PAGESIZE_DEFAULT);
    if (resList) {
      setListOrders(resList?.orders);
      setTotalOrder(resList?.total);
    }
  };

  const onChangePage = (pageNumber) => {
    getListOrder(pageNumber);
    setCurrentPageNumber(pageNumber);
  };

  useEffect(() => {
    getListOrder(PAGENUMBER_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnOrder = [
    {
      title: pageData.id,
      key: "code",
      render: (_, record) => {
        return (
          <FnbTypography.Link to={`/report/order/detail/${record?.id}`} target="_self" text={record?.stringCode} />
        );
      },
    },
    {
      title: pageData.status,
      key: "status",
      dataIndex: "statusId",
      render: (val) => {
        return <FnbBadge variant={orderStatusBadgeVariant[val]} text={t(`${orderStatusLocalization[val]}`)} />;
      },
    },
    {
      title: pageData.serviceType,
      key: "orderTypeName",
      render: (_, record) => {
        return <FnbTypography text={t(`${OrderServiceTypeLocalization[record?.orderTypeId]}`)} />;
      },
    },
    {
      title: pageData.details,
      key: "detail",
      render: (_, record) => {
        return (
          <>
            <Row>
              <Col span={12}>
                <FnbTypography text={`${pageData.subtotal}:`} />
              </Col>
              <Col span={12} className="text-right">
                <FnbTypography
                  text={record?.originalPrice > 0 ? formatCurrencyWithoutSuffix(record?.originalPrice) : "-"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <FnbTypography text={`${pageData.discount}:`} />
              </Col>
              <Col span={12} className="text-right">
                <FnbTypography
                  text={record?.totalDiscountAmount ? formatCurrencyWithoutSuffix(record?.totalDiscountAmount) : "-"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>{capitalize(pageData.feeAndTax)}:</Col>
              <Col span={12} className="text-right">
                <FnbTypography
                  text={
                    record?.totalFee > 0 || record?.totalTax > 0 || record?.deliveryFee > 0
                      ? formatCurrencyWithoutSuffix(
                          (record?.totalFee ?? 0) + (record?.totalTax ?? 0) + (record?.deliveryFee ?? 0),
                        )
                      : "-"
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={14}>
                <FnbTypography text={`${capitalize(pageData.paymentMethod)}:`} />
              </Col>
              <Col span={10} className="text-right">
                <FnbTypography
                  text={
                    record?.paymentMethodId === paymentMethod.Personal
                      ? record?.personalPaymentMethodName
                      : t(`${orderPaymentMethodLocalization[record?.paymentMethodId]}`)
                  }
                />
              </Col>
            </Row>
            <hr className="divide-detail-column" />
            <Row>
              <Col span={8}>
                <FnbTypography
                  text={pageData.total}
                  variant={theme.typography["h3-bold"]}
                  color={theme.colors.primary[100]}
                />
              </Col>
              <Col span={16} className="text-right">
                <FnbTypography
                  text={record?.totalAmount > 0 ? formatCurrencyWithoutSuffix(record?.totalAmount) : "-"}
                  variant={theme.typography["h3-bold"]}
                  color={theme.colors.primary[100]}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: pageData.customer,
      dataIndex: "customer",
      key: "customer",
      render: (_, record) => {
        return (
          <>
            <div className="column-customer">
              <FnbTypography text={`${pageData.name}:`} />
              <FnbTypography.Link
                to={`/customer/detail/${record?.customer?.id}`}
                target="_blank"
                text={[record?.customer?.firstName, record?.customer?.lastName].join(" ").trim()}
              />
            </div>
            <div className="column-customer">
              <FnbTypography text={`${pageData.phone}:`} />
              {record?.customer?.phoneNumber && (
                <FnbTypography text={formatPhoneNumber(record?.customer?.phoneCode, record?.customer?.phoneNumber)} />
              )}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div style={{ background: theme.colors.default.white, borderRadius: "12px", paddingTop: "12px" }}>
      <FnbTable
        className="table-order-shift-detail"
        columns={getColumnOrder}
        dataSource={listOrders}
        pageSize={PAGESIZE_DEFAULT}
        total={totalOrder}
        currentPageNumber={currentPageNumber}
        onChangePage={onChangePage}
        tableId="table-shift-detail-id"
        scrollX={1200}
      />
    </div>
  );
}
