import { Form, Space, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";

import FnbSteps, { FnbStepItemStatus } from "components/fnb-steps";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { buildStoreAppType } from "constants/build-store-app.constants";
import { GOOGLE_CONFIG_TYPE } from "constants/google.constants";
import { ArrowRightIcon, CloseFill, Edit2Icon, ExportOutlinedIcon, NoteOutlinedIcon } from "constants/icons.constants";
import { devicePlatformType } from "constants/platform.constants";
import onlineStoreDataService from "data-services/online-store/online-store-data.service";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import theme from "theme";

const LINK_GUIDELINE_BUILD_APP = "https://storeapp.gofnb.biz/guideline/CreateFirebaseAndCredentials.pdf";
const ACCEPT_FILE_IMAGE = ["image/png", "image/gif", "image/jpeg"];
const ACCEPT_FILE_JSON = "application/json";

const DescriptionItemStep = ({
  t,
  title,
  required = false,
  content,
  showDivide = true,
  styleDescription = {},
  linkConfig = null,
  headingValidationText = null,
}) => {
  return (
    <Space direction="vertical" size={0} className="w-100">
      <Space direction="vertical" size={16} className="wrapper-description-item-build w-100" style={styleDescription}>
        <div className="wrapper-description-item-build__heading">
          <Space size={16}>
            <Space size={4}>
              <FnbTypography variant={theme.typography["b1-bold"]} text={title} />
              {required && (
                <FnbTypography variant={theme.typography["b1-bold"]} text={"*"} color={theme.colors.critical[80]} />
              )}
            </Space>
            {headingValidationText && (
              <FnbTypography
                color={theme.colors.critical[80]}
                variant={theme.typography["c-medium"]}
                text={headingValidationText}
              />
            )}
          </Space>
          {linkConfig && (
            <div className="wrapper-description-item-build__go-to-config">
              <FnbTypography.Link
                underline
                text={t("onlineStore.mobileApp.build.goToConfig", "Go to config")}
                target="_blank"
                to={linkConfig}
              />
              <ArrowRightIcon />
            </div>
          )}
        </div>
        {content}
      </Space>

      {showDivide && <hr className="line-horizontal-step-item" />}
    </Space>
  );
};

const ModalBuildApplication = ({
  storeId,
  showModalBuild,
  setShowModalBuild,
  devicePlatform,
  buttonBuild = { text: "" },
  onBuild = undefined,
  t,
  storeAppInfo = null,
  isBuildingApp,
  setStatusBuildApp,
  isAlreadyUpdatePackageId = false,
  isAlreadyUpdateBundleId = false,
  googleMapConfig,
}) => {
  const [form] = Form.useForm();
  const inputAppLogoRef = useRef(null);
  const inputGoogleFirebaseServiceRef = useRef(null);
  const inputFirebaseCredentialRef = useRef(null);
  const changeFormRef = useRef(null);
  const [fileAppLogo, setFileAppLogo] = useState(null);
  const [linkFileAppLogo, setLinkAppLogo] = useState(null);
  const [fileGoogleFirebaseService, setFileGoogleFirebaseService] = useState(null);
  const [linkFileGoogleFirebaseService, setLinkFileGoogleFirebaseService] = useState(null);
  const [fileFirebaseCredential, setFileFirebaseCredential] = useState(null);
  const [jsonFirebaseCredential, setJsonFirebaseCredential] = useState(null);
  const [bundleId, setBundleId] = useState();
  const [packageId, setPackageId] = useState();
  const [statusField, setStatusField] = useState({
    appName: FnbStepItemStatus.WAIT,
    bundleId: FnbStepItemStatus.WAIT,
    packageId: FnbStepItemStatus.WAIT,
    fileAppLogo: FnbStepItemStatus.WAIT,
    googleMapsApiKey: FnbStepItemStatus.WAIT,
    fileGoogleFirebaseService: FnbStepItemStatus.WAIT,
    fileFirebaseCredential: FnbStepItemStatus.WAIT,
  });
  const [validateMessageAppLogo, setValidateMessageAppLogo] = useState("");
  const [validateMessageGoogleFirebaseService, setValidateMessageGoogleFirebaseService] = useState("");
  const [validateMessageFirebaseCredential, setValidateMessageFirebaseCredential] = useState("");
  const isMaxWidth1280 = useMediaQuery({ maxWidth: 1280 });
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const pageData = {
    title: t("onlineStore.mobileApp.build.title"),
    guideline1: t("onlineStore.mobileApp.build.guideline1"),
    guideline2: t("onlineStore.mobileApp.build.guideline2"),
    validateUploadFile: `${t("onlineStore.mobileApp.build.validateUploadFile")} ${
      devicePlatform === devicePlatformType.Android ? "AAB" : "IBA"
    }`,
    validateConfigKey: `${t("onlineStore.mobileApp.build.validateConfigKey")} ${
      devicePlatform === devicePlatformType.Android ? "AAB" : "IBA"
    }`,
    acceptFileImageTypes: t("messages.acceptFileImageTypes"),
    acceptFileTypes: t("messages.acceptFileTypes"),
    button: {
      save: t("button.save"),
      uploadFile: t("button.uploadFile"),
      guideline: t("button.guideline"),
    },
    appName: {
      title: t("onlineStore.mobileApp.build.appName.title"),
      description: t("onlineStore.mobileApp.build.appName.description"),
      validate: t("onlineStore.mobileApp.build.appName.validate"),
      placeholder: t("onlineStore.mobileApp.build.appName.placeholder"),
    },
    bundleId: {
      title: t("onlineStore.mobileApp.build.bundleId.title"),
      description: t("onlineStore.mobileApp.build.bundleId.description"),
      validate: t("onlineStore.mobileApp.build.bundleId.validate"),
      help: t("onlineStore.mobileApp.build.bundleId.help"),
    },
    packageId: {
      title: t("onlineStore.mobileApp.build.packageId.title"),
      description: t("onlineStore.mobileApp.build.packageId.description"),
      validate: t("onlineStore.mobileApp.build.packageId.validate"),
    },
    appLogo: {
      title: t("onlineStore.mobileApp.build.appLogo.title"),
      description1: t("onlineStore.mobileApp.build.appLogo.description1"),
      description2: t("onlineStore.mobileApp.build.appLogo.description2"),
      description3: t("onlineStore.mobileApp.build.appLogo.description3"),
    },
    googleMapsApiKey: {
      title: t("onlineStore.mobileApp.build.googleMapsApiKey.title"),
      description1: t("onlineStore.mobileApp.build.googleMapsApiKey.description1"),
      description2: t("onlineStore.mobileApp.build.googleMapsApiKey.description2"),
    },
    googleFirebaseService: {
      title: t("onlineStore.mobileApp.build.googleFirebaseService.title"),
      description: t("onlineStore.mobileApp.build.googleFirebaseService.description"),
    },
    firebaseCredential: {
      title: t("onlineStore.mobileApp.build.firebaseCredential.title"),
      description1: t("onlineStore.mobileApp.build.firebaseCredential.description1"),
      description2: t("onlineStore.mobileApp.build.firebaseCredential.description2"),
    },
  };
  useEffect(() => {
    if (showModalBuild && storeAppInfo) {
      setTimeout(() => {
        const formValue = form.getFieldsValue();
        formValue.appName = storeAppInfo?.appName;
        formValue.packageId = storeAppInfo?.packageId;
        setPackageId(storeAppInfo?.packageId);
        setLinkAppLogo(storeAppInfo.appLogo);
        setLinkFileGoogleFirebaseService(
          devicePlatform === devicePlatformType.Android
            ? storeAppInfo.androidGoogleFirebaseService
            : storeAppInfo?.iosGoogleFirebaseService,
        );

        setJsonFirebaseCredential(storeAppInfo?.firebaseCredential);
        setStatusField({
          ...statusField,
          appName: storeAppInfo?.appName ? FnbStepItemStatus.FINISH : FnbStepItemStatus.WAIT,
          packageId: storeAppInfo?.packageId ? FnbStepItemStatus.FINISH : FnbStepItemStatus.WAIT,
          fileAppLogo: !checkValidLogoApp(fileAppLogo, storeAppInfo?.appLogo)
            ? FnbStepItemStatus.ERROR
            : storeAppInfo?.appLogo
            ? FnbStepItemStatus.FINISH
            : FnbStepItemStatus.WAIT,
          googleMapsApiKey: checkValidGoogleMapConfig() ? FnbStepItemStatus.FINISH : FnbStepItemStatus.WAIT,
          fileGoogleFirebaseService:
            devicePlatform === devicePlatformType.Android
              ? !checkValidGoogleFirebaseService(fileGoogleFirebaseService, storeAppInfo?.androidGoogleFirebaseService)
                ? FnbStepItemStatus.ERROR
                : storeAppInfo?.androidGoogleFirebaseService
                ? FnbStepItemStatus.FINISH
                : FnbStepItemStatus.WAIT
              : storeAppInfo?.iosGoogleFirebaseService
              ? FnbStepItemStatus.FINISH
              : FnbStepItemStatus.WAIT,
          fileFirebaseCredential: !checkValidFirebaseCredential(
            fileFirebaseCredential,
            storeAppInfo?.firebaseCredential,
          )
            ? FnbStepItemStatus.ERROR
            : storeAppInfo?.firebaseCredential
            ? FnbStepItemStatus.FINISH
            : FnbStepItemStatus.WAIT,
        });
        form.setFieldsValue(formValue);
      }, 100);
    }
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalBuild]);

  useEffect(() => {
    const currentStatusGoogleMap = statusField.googleMapsApiKey;
    const isValid = checkValidGoogleMapConfig();
    setStatusField({
      ...statusField,
      googleMapsApiKey: isValid ? FnbStepItemStatus.FINISH : currentStatusGoogleMap,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapConfig]);

  const onSubmit = async () => {
    if (
      statusField.appName === FnbStepItemStatus.FINISH &&
      (statusField.bundleId === FnbStepItemStatus.FINISH || statusField.packageId === FnbStepItemStatus.FINISH) &&
      statusField.fileAppLogo === FnbStepItemStatus.FINISH &&
      statusField.googleMapsApiKey === FnbStepItemStatus.FINISH &&
      statusField.fileGoogleFirebaseService === FnbStepItemStatus.FINISH &&
      statusField.fileFirebaseCredential === FnbStepItemStatus.FINISH
    ) {
      setStatusBuildApp(devicePlatformType.Android, buildStoreAppType.requesting);
      const formData = new FormData();
      formData.append("storeId", storeId);
      formData.append("appName", form.getFieldValue("appName"));
      formData.append("bundleId", bundleId);
      formData.append("packageId", packageId);
      formData.append("fileAppLogo", fileAppLogo);
      formData.append("fileGoogleFirebaseService", fileGoogleFirebaseService);
      formData.append("fileFirebaseCredential", fileFirebaseCredential);
      formData.append("devicePlatform", devicePlatform);
      const response = await onlineStoreDataService.buildStoreApp(formData);
      if (response?.succeeded) {
        message.success(t(response?.message));
        setShowModalBuild(false);
        onBuild && onBuild();
        setStatusBuildApp(devicePlatformType.Android, buildStoreAppType.default);
      } else {
        message.error(t(response?.message));
        setStatusBuildApp(devicePlatformType.Android, buildStoreAppType.default);
      }
    }
  };

  const onChangeForm = (fieldName) => {
    if (changeFormRef.current) {
      clearTimeout(changeFormRef.current);
    }

    changeFormRef.current = setTimeout(() => {
      if (fieldName === "appName") {
        setStatusField({
          ...statusField,
          appName: form.getFieldError("appName")?.length > 0 ? FnbStepItemStatus.ERROR : FnbStepItemStatus.FINISH,
        });
      }
      if (fieldName === "bundleId") {
        setStatusField({
          ...statusField,
          bundleId: form.getFieldError("bundleId")?.length > 0 ? FnbStepItemStatus.ERROR : FnbStepItemStatus.WAIT,
        });
      }
      if (fieldName === "packageId") {
        setStatusField({
          ...statusField,
          packageId: form.getFieldError("packageId")?.length > 0 ? FnbStepItemStatus.ERROR : FnbStepItemStatus.WAIT,
        });
      }
    }, 300);
  };

  const onClickButtonSave = (type) => {
    if (type === "bundleId") {
      const errorBundleId = form.getFieldError("bundleId");
      if (errorBundleId.length === 0) {
        setStatusField({
          ...statusField,
          bundleId: FnbStepItemStatus.FINISH,
        });
      }
    }
    if (type === "packageId") {
      const errorPackageId = form.getFieldError("packageId");
      if (errorPackageId.length === 0) {
        setStatusField({
          ...statusField,
          packageId: FnbStepItemStatus.FINISH,
        });
      }
    }
  };

  const onChangeInputAppLogo = (file) => {
    setFileAppLogo(file);
    setLinkAppLogo(null);
    const isValid = checkValidLogoApp(file, null);
    setStatusField({
      ...statusField,
      fileAppLogo: isValid ? FnbStepItemStatus.FINISH : FnbStepItemStatus.ERROR,
    });
  };

  const onChangeInputGoogleFirebaseService = (file) => {
    setFileGoogleFirebaseService(file);
    setLinkFileGoogleFirebaseService(null);
    const isValid = checkValidGoogleFirebaseService(file, null);

    setStatusField({
      ...statusField,
      fileGoogleFirebaseService: isValid ? FnbStepItemStatus.FINISH : FnbStepItemStatus.ERROR,
    });
  };

  const onChangeInputFirebaseCredential = (file) => {
    setFileFirebaseCredential(file);
    setJsonFirebaseCredential(null);
    const isValid = checkValidFirebaseCredential(file, null);
    setStatusField({
      ...statusField,
      fileFirebaseCredential: isValid ? FnbStepItemStatus.FINISH : FnbStepItemStatus.ERROR,
    });
  };

  const checkValidLogoApp = (file, linkFile) => {
    let isValid = true;
    if (file) {
      if (!ACCEPT_FILE_IMAGE.some((type) => type === file?.type)) {
        setValidateMessageAppLogo(pageData.acceptFileImageTypes);
        isValid = false;
      }
    } else {
      if (linkFile === null) {
        setValidateMessageAppLogo(pageData.validateUploadFile);
        isValid = false;
      }
    }
    return isValid;
  };

  const checkValidGoogleMapConfig = () => {
    if (devicePlatform === devicePlatformType.Android) {
      return googleMapConfig?.androidAppAPIKey ? true : false;
    }
  };

  const checkValidGoogleFirebaseService = (file, linkFile) => {
    let isValid = true;
    if (file) {
      if (file?.type !== ACCEPT_FILE_JSON && devicePlatform === devicePlatformType.Android) {
        setValidateMessageGoogleFirebaseService(pageData.acceptFileTypes.replace("{{value}}", ".json"));
        isValid = false;
      }
    } else {
      if (linkFile === null) {
        setValidateMessageGoogleFirebaseService(pageData.validateUploadFile);
        isValid = false;
      }
    }
    return isValid;
  };

  const checkValidFirebaseCredential = (file, json) => {
    let isValid = true;
    if (file) {
      if (file?.type !== ACCEPT_FILE_JSON && devicePlatform === devicePlatformType.Android) {
        setValidateMessageFirebaseCredential(pageData.acceptFileTypes.replace("{{value}}", ".json"));
        isValid = false;
      }
    } else {
      if (json === null) {
        setValidateMessageFirebaseCredential(pageData.validateUploadFile);
        isValid = false;
      }
    }
    return isValid;
  };

  const onClickBuild = async () => {
    if (!isBuildingApp) {
      const appName = form.getFieldValue("appName");
      const bundleId = form.getFieldValue("bundleId");
      setStatusField({
        ...statusField,
        appName: appName === "" || appName === undefined ? FnbStepItemStatus.ERROR : FnbStepItemStatus.FINISH,
        bundleId:
          bundleId === "" || bundleId === undefined || statusField.bundleId === FnbStepItemStatus.WAIT
            ? FnbStepItemStatus.ERROR
            : FnbStepItemStatus.FINISH,
        packageId:
          packageId === "" || packageId === undefined || statusField.packageId === FnbStepItemStatus.WAIT
            ? FnbStepItemStatus.ERROR
            : FnbStepItemStatus.FINISH,
        fileAppLogo: checkValidLogoApp(fileAppLogo, linkFileAppLogo)
          ? FnbStepItemStatus.FINISH
          : FnbStepItemStatus.ERROR,
        googleMapsApiKey: checkValidGoogleMapConfig() ? FnbStepItemStatus.FINISH : FnbStepItemStatus.ERROR,
        fileGoogleFirebaseService: checkValidGoogleFirebaseService(
          fileGoogleFirebaseService,
          linkFileGoogleFirebaseService,
        )
          ? FnbStepItemStatus.FINISH
          : FnbStepItemStatus.ERROR,
        fileFirebaseCredential: checkValidFirebaseCredential(fileFirebaseCredential, jsonFirebaseCredential)
          ? FnbStepItemStatus.FINISH
          : FnbStepItemStatus.ERROR,
      });

      form.submit();
    }
  };
  return (
    <FnbModal
      visible={showModalBuild}
      title={pageData.title}
      handleCancel={() => {
        setShowModalBuild(false);
      }}
      width={1080}
      hideFooter
      closable
      content={
        <>
          <FnbButton
            iconHeader={<NoteOutlinedIcon />}
            text={pageData.button.guideline}
            textStyle={{ textDecoration: "underline" }}
            variant="tertiary"
            onClick={() => {
              window.open(LINK_GUIDELINE_BUILD_APP, "_blank");
            }}
            className="button-guideline-build-app"
          />
          <Space size={12} direction="vertical" className="w-100 text-guideline-build-app">
            <Space direction="vertical" size={0}>
              <FnbTypography text={pageData.guideline1} variant={theme.typography["b1-italic"]} color="#FF8C21" />
              <FnbTypography text={pageData.guideline2} variant={theme.typography["b1-italic"]} color="#FF8C21" />
            </Space>
            <Form className="custom-form" form={form} onFinish={onSubmit}>
              <FnbSteps
                className="steps-build-application"
                items={[
                  {
                    status: statusField.appName,
                    description: (
                      <DescriptionItemStep
                        t={t}
                        title={`1. ${pageData.appName.title}`}
                        required
                        styleDescription={{ paddingBottom: 24 }}
                        content={
                          <>
                            <FnbTypography text={pageData.appName.description} />
                            <Form.Item
                              name="appName"
                              rules={[
                                {
                                  required: true,
                                  message: <InputValidateMessage message={pageData.appName.validate} />,
                                },
                              ]}
                            >
                              <FnbInput
                                className="steps-build-application__input"
                                placeholder={pageData.appName.placeholder}
                                showCount
                                maxLength={100}
                                onChange={() => onChangeForm("appName")}
                                disabled={isBuildingApp}
                              />
                            </Form.Item>
                          </>
                        }
                      />
                    ),
                  },
                  devicePlatform === devicePlatformType.Android
                    ? {
                        status: statusField.packageId,
                        description: (
                          <DescriptionItemStep
                            t={t}
                            title={`2. ${pageData.packageId.title}`}
                            required
                            content={
                              <>
                                <FnbTypography text={pageData.packageId.description} />
                                {statusField.packageId === FnbStepItemStatus.FINISH ? (
                                  <Space size={12}>
                                    <FnbTypography
                                      text={packageId}
                                      color={theme.colors.primary[100]}
                                      variant={theme.typography["b1-bold-underlined"]}
                                    />
                                    {!isAlreadyUpdatePackageId && (
                                      <FnbButton
                                        iconHeader={<Edit2Icon />}
                                        variant="secondary-purple"
                                        onClick={() =>
                                          setStatusField({
                                            ...statusField,
                                            packageId: FnbStepItemStatus.WAIT,
                                          })
                                        }
                                        disabled={isBuildingApp}
                                      />
                                    )}
                                  </Space>
                                ) : (
                                  <Space
                                    size={10}
                                    className="d-flex"
                                    style={{ alignItems: "flex-start", position: "relative", marginBottom: 12 }}
                                  >
                                    <Form.Item
                                      name="packageId"
                                      rules={[
                                        {
                                          required: true,
                                          message: <InputValidateMessage message={pageData.packageId.validate} />,
                                        },
                                      ]}
                                    >
                                      <FnbInput
                                        className="steps-build-application__input"
                                        placeholder="com."
                                        showCount
                                        maxLength={255}
                                        onChange={(e) => {
                                          setPackageId(e.target.value);
                                          onChangeForm("packageId");
                                        }}
                                      />
                                    </Form.Item>
                                    <FnbButton
                                      disabled={packageId === "" || packageId === undefined}
                                      text={pageData.button.save}
                                      minWidth={100}
                                      onClick={() => onClickButtonSave("packageId")}
                                    />
                                    {statusField.packageId !== FnbStepItemStatus.ERROR && (
                                      <FnbTypography
                                        variant={theme.typography["c-italic"]}
                                        color={theme.colors.gray[70]}
                                        text={pageData.bundleId.help}
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          bottom: -22,
                                        }}
                                      />
                                    )}
                                  </Space>
                                )}
                              </>
                            }
                          />
                        ),
                      }
                    : {
                        status: statusField.bundleId,
                        description: (
                          <DescriptionItemStep
                            t={t}
                            title={`2. ${pageData.bundleId.title}`}
                            required
                            content={
                              <>
                                <FnbTypography text={pageData.bundleId.description} />
                                {statusField.bundleId === FnbStepItemStatus.FINISH ? (
                                  <Space size={12}>
                                    <FnbTypography
                                      text={bundleId}
                                      color={theme.colors.primary[100]}
                                      variant={theme.typography["b1-bold-underlined"]}
                                    />
                                    {!isAlreadyUpdateBundleId && (
                                      <FnbButton
                                        iconHeader={<Edit2Icon />}
                                        variant="secondary-purple"
                                        onClick={() =>
                                          setStatusField({
                                            ...statusField,
                                            bundleId: FnbStepItemStatus.WAIT,
                                          })
                                        }
                                        disabled={isBuildingApp}
                                      />
                                    )}
                                  </Space>
                                ) : (
                                  <Space
                                    size={10}
                                    className="d-flex"
                                    style={{ alignItems: "flex-start", position: "relative", marginBottom: 12 }}
                                  >
                                    <Form.Item
                                      name="bundleId"
                                      rules={[
                                        {
                                          required: true,
                                          message: <InputValidateMessage message={pageData.bundleId.validate} />,
                                        },
                                      ]}
                                    >
                                      <FnbInput
                                        className="steps-build-application__input"
                                        placeholder="com."
                                        showCount
                                        maxLength={255}
                                        onChange={(e) => {
                                          setBundleId(e.target.value);
                                          onChangeForm("bundleId");
                                        }}
                                      />
                                    </Form.Item>
                                    <FnbButton
                                      disabled={bundleId === "" || bundleId === undefined}
                                      text={pageData.button.save}
                                      minWidth={100}
                                      onClick={() => onClickButtonSave("bundleId")}
                                    />
                                    {statusField.bundleId !== FnbStepItemStatus.ERROR && (
                                      <FnbTypography
                                        variant={theme.typography["c-italic"]}
                                        color={theme.colors.gray[70]}
                                        text={pageData.bundleId.help}
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          bottom: -22,
                                        }}
                                      />
                                    )}
                                  </Space>
                                )}
                              </>
                            }
                          />
                        ),
                      },
                  {
                    status: statusField.fileAppLogo,
                    description: (
                      <DescriptionItemStep
                        t={t}
                        title={`3. ${pageData.appLogo.title}`}
                        required
                        content={
                          <>
                            <ul style={{ marginLeft: 32, marginBottom: 0 }}>
                              <li>
                                <FnbTypography text={pageData.appLogo.description1} />
                              </li>
                              <li>
                                <Space size={4} style={{ alignItems: "baseline" }}>
                                  <FnbTypography text={pageData.appLogo.description2 + ":"} />
                                  <FnbTypography text="1024x1024." variant={theme.typography["b1-bold"]} />
                                </Space>
                              </li>
                              <li>
                                <FnbTypography text={pageData.appLogo.description3} />
                              </li>
                            </ul>
                            <Space size={12} className="w-100" wrap>
                              <FnbButton
                                iconHeader={<ExportOutlinedIcon />}
                                text={pageData.button.uploadFile}
                                variant="secondary-purple"
                                onClick={() => {
                                  inputAppLogoRef.current?.click();
                                }}
                                disabled={isBuildingApp}
                              />
                              {(fileAppLogo || linkFileAppLogo) && (
                                <div className="file-info-application-building">
                                  <FnbTypography
                                    text={fileAppLogo?.name ?? linkFileAppLogo?.split("/").pop()}
                                    color={theme.colors.primary[100]}
                                    style={{ cursor: fileAppLogo ? "default" : "pointer", wordBreak: "break-word" }}
                                    onClick={() => {
                                      if (fileAppLogo === null && linkFileAppLogo) {
                                        window.open(linkFileAppLogo, "_blank");
                                      }
                                    }}
                                  />
                                  <CloseFill
                                    onClick={() => {
                                      if (!isBuildingApp) {
                                        onChangeInputAppLogo(null);
                                      }
                                    }}
                                    style={{ cursor: isBuildingApp ? "not-allowed" : "pointer" }}
                                  />
                                </div>
                              )}
                              {statusField.fileAppLogo === FnbStepItemStatus.ERROR && (
                                <FnbTypography
                                  text={validateMessageAppLogo}
                                  color={theme.colors.critical[80]}
                                  variant={theme.typography["c-medium"]}
                                />
                              )}
                              <input
                                onChange={(e) => {
                                  onChangeInputAppLogo(e.target.files[0]);
                                }}
                                type="file"
                                ref={inputAppLogoRef}
                                style={{ visibility: "hidden", width: "5px" }}
                                accept={ACCEPT_FILE_IMAGE.join(", ")}
                              />
                            </Space>
                          </>
                        }
                      />
                    ),
                  },
                  {
                    status: statusField.googleMapsApiKey,
                    description: (
                      <DescriptionItemStep
                        t={t}
                        title={`4. ${pageData.googleMapsApiKey.title}`}
                        required
                        linkConfig={`/config?tabActive=communications&tabCommunication=${GOOGLE_CONFIG_TYPE.googleApi}`}
                        headingValidationText={
                          statusField.googleMapsApiKey === FnbStepItemStatus.ERROR ? pageData.validateConfigKey : null
                        }
                        content={
                          <ul style={{ marginLeft: 32, marginBottom: 0 }}>
                            <li>
                              <FnbTypography text={pageData.googleMapsApiKey.description1} />
                            </li>
                            <li>
                              <FnbTypography text={pageData.googleMapsApiKey.description2} />
                            </li>
                          </ul>
                        }
                      />
                    ),
                  },
                  {
                    status: statusField.fileGoogleFirebaseService,
                    description: (
                      <DescriptionItemStep
                        t={t}
                        title={`5. ${pageData.googleFirebaseService.title}`}
                        required
                        content={
                          <>
                            <FnbTypography text={pageData.googleFirebaseService.description} />
                            <Space size={12} className="w-100" wrap>
                              <FnbButton
                                iconHeader={<ExportOutlinedIcon />}
                                text={pageData.button.uploadFile}
                                variant="secondary-purple"
                                onClick={() => inputGoogleFirebaseServiceRef.current?.click()}
                                disabled={isBuildingApp}
                              />
                              {(fileGoogleFirebaseService || linkFileGoogleFirebaseService) && (
                                <div className="file-info-application-building">
                                  <FnbTypography
                                    text={
                                      fileGoogleFirebaseService?.name ??
                                      (linkFileGoogleFirebaseService &&
                                        linkFileGoogleFirebaseService?.split("/")?.pop())
                                    }
                                    color={theme.colors.primary[100]}
                                    style={{ cursor: fileAppLogo ? "default" : "pointer" }}
                                    onClick={() => {
                                      if (fileGoogleFirebaseService === null && linkFileGoogleFirebaseService) {
                                        window.open(linkFileGoogleFirebaseService, "_blank");
                                      }
                                    }}
                                  />
                                  <CloseFill
                                    onClick={() => {
                                      if (!isBuildingApp) {
                                        onChangeInputGoogleFirebaseService(null);
                                      }
                                    }}
                                    style={{ cursor: isBuildingApp ? "not-allowed" : "pointer" }}
                                  />
                                </div>
                              )}
                              {statusField.fileGoogleFirebaseService === FnbStepItemStatus.ERROR && (
                                <FnbTypography
                                  text={validateMessageGoogleFirebaseService}
                                  color={theme.colors.critical[80]}
                                  variant={theme.typography["c-medium"]}
                                />
                              )}
                              <input
                                onChange={(e) => {
                                  onChangeInputGoogleFirebaseService(e.target.files[0]);
                                }}
                                type="file"
                                ref={inputGoogleFirebaseServiceRef}
                                style={{ visibility: "hidden", width: "5px" }}
                                accept={devicePlatform === devicePlatformType.Android ? ACCEPT_FILE_JSON : ""}
                              />
                            </Space>
                          </>
                        }
                      />
                    ),
                  },
                  {
                    status: statusField.fileFirebaseCredential,
                    description: (
                      <DescriptionItemStep
                        t={t}
                        title={`6. ${pageData.firebaseCredential.title}`}
                        required
                        linkConfig={`/config?tabActive=communications&tabCommunication=${GOOGLE_CONFIG_TYPE.firebaseCredential}`}
                        content={
                          <>
                            <ul style={{ marginLeft: 32, marginBottom: 0 }}>
                              <li>
                                <FnbTypography text={pageData.firebaseCredential.description1} />
                              </li>
                              <li>
                                <FnbTypography text={pageData.firebaseCredential.description2} />
                              </li>
                            </ul>
                            <Space size={12} className="w-100" wrap>
                              <FnbButton
                                iconHeader={<ExportOutlinedIcon />}
                                text={pageData.button.uploadFile}
                                variant="secondary-purple"
                                onClick={() => inputFirebaseCredentialRef.current?.click()}
                                disabled={isBuildingApp}
                              />
                              {(fileFirebaseCredential || jsonFirebaseCredential) && (
                                <div className="file-info-application-building">
                                  {fileFirebaseCredential?.name ? (
                                    <FnbTypography
                                      text={fileFirebaseCredential?.name}
                                      color={theme.colors.primary[100]}
                                    />
                                  ) : (
                                    <FnbTooltip
                                      variant="secondary"
                                      title={
                                        jsonFirebaseCredential && (
                                          <pre style={{ whiteSpace: "pre-wrap" }}>{jsonFirebaseCredential}</pre>
                                        )
                                      }
                                      maxWidth={isMaxWidth1280 ? "80%" : 700}
                                      placement={isMaxWidth1280 ? "top" : "topLeft"}
                                      hideTooltip={
                                        jsonFirebaseCredential === null || jsonFirebaseCredential === undefined
                                      }
                                    >
                                      <FnbTypography
                                        text={
                                          jsonFirebaseCredential &&
                                          jsonFirebaseCredential?.slice(0, isMobile ? 30 : 45) + "..."
                                        }
                                        style={{ cursor: "pointer" }}
                                        color={theme.colors.primary[100]}
                                      />
                                    </FnbTooltip>
                                  )}
                                  <CloseFill
                                    onClick={() => {
                                      if (!isBuildingApp) {
                                        onChangeInputFirebaseCredential(null);
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              )}
                              {statusField.fileFirebaseCredential === FnbStepItemStatus.ERROR && (
                                <FnbTypography
                                  text={validateMessageFirebaseCredential}
                                  color={theme.colors.critical[80]}
                                  variant={theme.typography["c-medium"]}
                                />
                              )}
                              <input
                                onChange={(e) => {
                                  onChangeInputFirebaseCredential(e.target.files[0]);
                                }}
                                type="file"
                                ref={inputFirebaseCredentialRef}
                                style={{ visibility: "hidden", width: "5px" }}
                                accept={ACCEPT_FILE_JSON}
                              />
                            </Space>
                          </>
                        }
                        showDivide={false}
                      />
                    ),
                  },
                ]}
              />
              <div className="d-flex justify-content-center w-100" style={{ marginTop: 12 }}>
                <Form.Item>
                  <FnbButton
                    text={buttonBuild.text}
                    onClick={onClickBuild}
                    loading={isBuildingApp}
                    disabled={isBuildingApp}
                  />
                </Form.Item>
              </div>
            </Form>
          </Space>
        </>
      }
      className="application-building-step-modal"
    />
  );
};

export default ModalBuildApplication;
