import { Button, Col, Image, message, Row } from "antd";
import { icons } from "constants/icons.constants";
import otherFoodyPlatformConfigDataService from "data-services/other-foody-platform-config/other-foody-platform-config-data.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OtherFoodyPlatformConfigComponent } from "./components/other-foody-platform-config.component";
import { AddNewOtherFoodyPlatformConfigComponent } from "./components/add-new-other-foody-platform-config.component";
import ConfirmDialog from "./components/confirmation-dialog/confirm-dialog.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import "./other-foody-platform.scss";
import FnbSwitch from "components/switch";
import { env } from "env";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { NoInfomationComponent } from "components/no-infomation/no-infomation.component";

export default function OtherFoodyPlatformPage(props) {
  const { onChangeForm, isChangeForm } = props;
  const [selectedFoodyPlatform, setSelectedFoodyPlatform] = useState(null);
  const [foodyPlatforms, setFoodyPlatforms] = useState([]);
  const [showAddFoodyPlayformsModal, setAddFoodyPlayformsModal] = useState(false);
  const [stopFoodyPlatformConfirm, setStopFoodyPlatformConfirm] = useState({
    showConfirm: false,
    handleConfirm: undefined,
  });
  const [stopFoodyPlatformContent, setStopFoodyPlatformContent] = useState("");
  const [changePlatformConfirm, setChangePlatformConfirm] = useState({
    showConfirm: false,
    handleConfirm: undefined,
  });
  const { t } = useTranslation();
  const addNewFoodyPlatformRef = React.useRef();
  const options = [
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/shopee-food-logo.png`, value: "ShopeeFood" },
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/grab-food-logo.png`, value: "GrabFood" },
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/be-food-logo.png`, value: "beFood" },
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/go-food-logo.png`, value: "GoFood" },
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/doorDash-logo.png`, value: "DoorDash" },
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/deliveroo-logo.png`, value: "Deliveroo" },
    { logo: `${env.REACT_APP_ROOT_DOMAIN}/assets/images/dunzo-logo.png`, value: "Dunzo" },
  ];
  const pageData = {
    updateSuccess: t("messages.updateSuccess"),
    updateFailed: t("messages.updateFailed"),
    addNewPlatform: t("otherFoodyPlatform.addNewPlatform"),
    addPlatform: t("otherFoodyPlatform.addPlatform"),
    confirmCancel: t("leaveDialog.confirmCancel"),
    cancelTransferMaterial: t("transferMaterial.cancelTransferMaterial"),
    cancel: t("button.cancel"),
    ignore: t("button.ignore"),
    stopFoodyPlatformConfirmationTitle: t("otherFoodyPlatform.deleteFoodyPlatformConfirmationTitle"),
    stopFoodyPlatformConfirmationCancellation: t("otherFoodyPlatform.deleteFoodyPlatformConfirmationCancellation"),
    stopFoodyPlatformConfirmationOk: t("otherFoodyPlatform.stopFoodyPlatformConfirmationOk"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };

  const [isMobileSize, setIsMobileSize] = useState(false);

  const updateDimensions = () => {
    setIsMobileSize(window.innerWidth < 500);
  };

  useEffect(() => {
    getInitData();
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const getInitData = () => {
    otherFoodyPlatformConfigDataService.getAllFoodyPlatformConfigAsync().then((resData) => {
      if (resData) {
        const { foodPlatform } = resData;
        if (Array.isArray(foodPlatform)) {
          setFoodyPlatforms(foodPlatform);
          setSelectedFoodyPlatform(
            foodPlatform?.find((foodPlatform) => foodPlatform?.id === selectedFoodyPlatform?.id) || foodPlatform[0],
          );
        }
      }
    });
  };

  const handleCancelChangePlatformConfirm = () => {
    setChangePlatformConfirm({
      showConfirm: false,
      handleConfirm: undefined,
    });
  };

  const handleDisplayStopFoodyPlatform = (foodyPlatform, isActive, event) => {
    const handleStopConfirmCallback = () => {
      if (!isActive) {
        setStopFoodyPlatformContent(
          t("otherFoodyPlatform.stopFoodyPlatformConfirmationContent").replace(
            "{{name}}",
            `
            <span class="warning-text">
              ${foodyPlatform?.name}
            </span>
          `,
          ),
        );
        setStopFoodyPlatformConfirm({
          showConfirm: true,
          handleConfirm: () => onActiveFoodyPlatform(foodyPlatform, false),
        });
      } else {
        onActiveFoodyPlatform(foodyPlatform, isActive);
      }
    };
    event.stopPropagation();
    if (isChangeForm) {
      setChangePlatformConfirm({
        showConfirm: true,
        handleConfirm: handleStopConfirmCallback,
      });
      return;
    }
    handleStopConfirmCallback();
  };

  const handleCancelStopFoodyPlatform = () => {
    setStopFoodyPlatformConfirm(false);
  };

  function handleSelectedFoodyPlatform(foodyPlatform) {
    // ignore click on current tab
    if (selectedFoodyPlatform?.id === foodyPlatform?.id) return;
    if (isChangeForm) {
      setChangePlatformConfirm({
        showConfirm: true,
        handleConfirm: () => {
          setSelectedFoodyPlatform(foodyPlatform);
        },
      });
    } else setSelectedFoodyPlatform(foodyPlatform);
  }

  const onActiveFoodyPlatform = (foodyPlatform, isActive) => {
    const enablePaymentConfigRequest = {
      id: foodyPlatform?.id,
      isActive: isActive,
    };
    otherFoodyPlatformConfigDataService
      .setStatusFoodyPlatformConfigAsync(enablePaymentConfigRequest)
      .then((response) => {
        if (response?.isSuccess === true) {
          message.success(t(response.message));
        } else {
          message.error(t(response.message));
        }
        getInitData();
        setStopFoodyPlatformConfirm({
          showConfirm: false,
          handleConfirm: undefined,
        });
      });
  };

  const renderContent = () => {
    return (
      <AddNewOtherFoodyPlatformConfigComponent
        ref={addNewFoodyPlatformRef}
        onCompleted={() => {
          getInitData();
          addNewFoodyPlatformRef.current.reset();
          setAddFoodyPlayformsModal(false);
        }}
        options={options}
      />
    );
  };

  const renderTableAddNewPlatformModal = () => {
    return (
      <FnbModal
        className="modal-foody-platform"
        closable={false}
        width={"70%"}
        title={pageData.addNewPlatform}
        visible={showAddFoodyPlayformsModal}
        handleCancel={() => {
          addNewFoodyPlatformRef.current.reset();
          setAddFoodyPlayformsModal(false);
        }}
        content={renderContent()}
        hideFooter={true}
      />
    );
  };

  const renderOtherFoodyPlatform = () => {
    const renderMethods = foodyPlatforms?.map((foodyPlatform) => {
      const currentSelected = selectedFoodyPlatform?.id === foodyPlatform?.id ? "active" : "";
      const switchOn = foodyPlatform?.isActive;
      return (
        <Row
          className={`pointer ${currentSelected} payment-item`}
          onClick={() => {
            handleSelectedFoodyPlatform(foodyPlatform);
          }}
        >
          <Col span={21} className="col-title">
            <Image
              preview={false}
              width={32}
              height={32}
              src={foodyPlatform?.logo}
              fallback={icons.paymentDefaultIcon}
            />
            <FnbTooltip
              className="title-center"
              onlyShowWhenEllipsis={true}
              title={foodyPlatform?.name}
              maxWidthContent={"calc(100% - 50px)"}
            ></FnbTooltip>
          </Col>
          <Col span={3} className="m-auto col-switch">
            <FnbSwitch
              checked={switchOn}
              onChange={(value, event) => handleDisplayStopFoodyPlatform(foodyPlatform, value, event)}
            />
          </Col>
          {isMobileSize && selectedFoodyPlatform?.id === foodyPlatform?.id && (
            <Col span={24} className="foody-platform-config">
              {renderOtherFoodyPlatformConfig()}
            </Col>
          )}
        </Row>
      );
    });
    return <div className="foody-platform-config-container">{renderMethods}</div>;
  };

  const renderOtherFoodyPlatformConfig = () => {
    let otherFoodyPlatformConfigComponent = <></>;
    if (selectedFoodyPlatform) {
      otherFoodyPlatformConfigComponent = (
        <OtherFoodyPlatformConfigComponent
          onCompleted={() => {
            getInitData();
          }}
          initData={selectedFoodyPlatform}
          options={options}
          handleCancelAddFoodyPlatform={handleCancelAddFoodyPlatform}
          onChangeForm={onChangeForm}
        />
      );
    } else {
      otherFoodyPlatformConfigComponent = (
        <Row className="default-foody-platform-config">
          <div className="content">
            <NoInfomationComponent />
          </div>
        </Row>
      );
    }

    return <div className="component-PaymentConfig">{otherFoodyPlatformConfigComponent}</div>;
  };

  const clickAddPlatform = () => {
    // setAddFoodyPlayformsModal(true);
    setSelectedFoodyPlatform({
      isAddPlatform: true,
      isActive: true,
    });
  };

  const handleCancelAddFoodyPlatform = () => {
    setSelectedFoodyPlatform();
  };

  return (
    <div className="other-fooldy-component">
      {renderTableAddNewPlatformModal()}
      {isMobileSize ? (
        <>
          <Col sm={12} md={8} className="foody-platform-choice h-100">
            <Row className={`payment-item ${selectedFoodyPlatform?.isAddPlatform ? "payment-item-select" : ""}`}>
              <Button className="addNewButton" onClick={clickAddPlatform}>
                {pageData.addPlatform}
              </Button>
              {isMobileSize && selectedFoodyPlatform?.isAddPlatform && (
                <Col span={24} className="foody-platform-config">
                  {renderOtherFoodyPlatformConfig()}
                </Col>
              )}
            </Row>
            {renderOtherFoodyPlatform()}
          </Col>
        </>
      ) : (
        <Row span={24} gutter={12} className="foody-platform-form">
          <Col sm={12} md={6} className="foody-platform-choice">
            <Button className="addNewButton" onClick={clickAddPlatform}>
              {pageData.addPlatform}
            </Button>
            {renderOtherFoodyPlatform()}
          </Col>
          <Col sm={12} md={18} className="foody-platform-setting">
            <Col span={24} className="foody-platform-config">
              {renderOtherFoodyPlatformConfig()}
            </Col>
          </Col>
        </Row>
      )}
      <ConfirmDialog
        className="stop-confirmation-modal"
        title={pageData.stopFoodyPlatformConfirmationTitle}
        content={stopFoodyPlatformContent}
        visible={stopFoodyPlatformConfirm.showConfirm}
        skipPermission={true}
        cancelText={pageData.stopFoodyPlatformConfirmationCancellation}
        okText={pageData.stopFoodyPlatformConfirmationOk}
        onCancel={handleCancelStopFoodyPlatform}
        onOk={() => {
          stopFoodyPlatformConfirm.handleConfirm();
          setStopFoodyPlatformConfirm({
            showConfirm: false,
            handleConfirm: undefined,
          });
        }}
      />
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={changePlatformConfirm.showConfirm}
        skipPermission={true}
        cancelText={pageData.leaveDialog.discardBtn}
        okText={pageData.leaveDialog.btnConfirmLeave}
        onCancel={handleCancelChangePlatformConfirm}
        onOk={() => {
          changePlatformConfirm.handleConfirm();
          setChangePlatformConfirm({
            showConfirm: false,
            handleConfirm: undefined,
          });
        }}
        isChangeForm={isChangeForm}
      />
    </div>
  );
}
