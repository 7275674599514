import { StopOutlined } from "@ant-design/icons";
import { Col, message, Row } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbQrCode } from "components/fnb-qr-code/fnb-qr-code.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { StopFill } from "constants/icons.constants";
import { OrderTypeConstants } from "constants/order-type-status.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { QRCodeStatus } from "constants/qr-code.constants";
import { DateFormat } from "constants/string.constants";
import qrCodeDataService from "data-services/qr-code/qr-code-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { setQrCodeData } from "store/modules/qr-code/qr-code.actions";
import { formatDate, formatNumberDecimalOrInteger } from "utils/helpers";
import "./detail-qr-order.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTypography from "components/fnb-typography/fnb-typography";

export default function QrCodeDetailPage(props) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();
  const [qrCode, setQrCode] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmStop, setShowConfirmStop] = useState(false);

  const pageData = {
    noColumnTitle: t("detailQrCode.noColumnTitle", "No"),
    productNameColumnTitle: t("detailQrCode.productNameColumnTitle", "Product name"),
    quantityColumnTitle: t("detailQrCode.quantityColumnTitle", "Quantity"),
    unitNameColumnTitle: t("detailQrCode.unitNameColumnTitle", "Unit"),
    generalInformation: t("detailQrCode.generalInformation", "General information"),
    name: t("detailQrCode.name", "Name"),
    branch: t("detailQrCode.branch", "Branch"),
    serviceType: t("detailQrCode.serviceType", "Service Type"),
    area: t("detailQrCode.area", "Area"),
    table: t("detailQrCode.table", "Table"),
    validFrom: t("detailQrCode.validFrom", "Valid from"),
    validUntil: t("detailQrCode.validUntil", "Valid until"),
    target: t("detailQrCode.target", "Target"),
    discount: t("detailQrCode.discount", "Discount"),
    discountValue: t("detailQrCode.discountValue", "Discount Value"),
    maxDiscount: t("detailQrCode.maxDiscount", "Max Discount"),
    button: {
      leave: t("button.leave", "Leave"),
      edit: t("button.edit", "Edit"),
      clone: t("button.clone", "Clone"),
      stop: t("button.stop", "Stop"),
      delete: t("button.delete", "Delete"),
      btnStop: t("button.stop"),
      btnIgnore: t("button.ignore"),
      btnIgnoreDelete: t("marketing.qrCode.ignoreText"),
    },
    deleteQrCodeSuccess: t("marketing.qrCode.deleteQrCodeSuccess"),
    deleteQrCodeFail: t("marketing.qrCode.deleteQrCodeFail"),
    confirmDeleteMessage: t("marketing.qrCode.confirmDeleteMessage"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    stopQrCodeSuccess: t("marketing.qrCode.stopQrCodeSuccess"),
    stopQrCodeFail: t("marketing.qrCode.stopQrCodeFail"),
    confirmStop: t("leaveDialog.confirmStop"),
    confirmStopQrCode: t("marketing.qrCode.confirmStop"),
    stop: t("button.stop"),
  };

  useEffect(() => {
    getInitDataAsync(param.qrCodeId);
  }, []);

  const getInitDataAsync = async (id) => {
    var res = await qrCodeDataService.getQrCodeByIdAsync(id);
    if (res) {
      setQrCode(res.qrCodeDetail);
    } else {
      goBack();
    }
  };

  const getProductTableColumns = () => {
    let columns = [
      {
        title: pageData.noColumnTitle,
        width: "74px",
        className: "",
        render: (_, record, index) => <>{index + 1}</>,
      },
      {
        title: pageData.productNameColumnTitle,
        width: "707px",
        className: "",
        render: (_, record) => {
          const productDetailUrl = `/product/details/${record?.productId}`;
          return (
            <div className="product-name-box">
              <div>
                <Thumbnail src={record?.productThumbnail} width={60} height={60} />
              </div>
              <FnbTypography.Link target="_blank" to={productDetailUrl} text={record?.productName} />
            </div>
          );
        },
      },
      {
        title: pageData.quantityColumnTitle,
        width: "375px",
        className: "",
        render: (_, record) => <>{formatNumberDecimalOrInteger(record?.productQuantity)}</>,
      },
      {
        title: pageData.unitNameColumnTitle,
        width: "318px",
        className: "",
        render: (_, record) => <>{record?.unitName}</>,
      },
    ];
    return columns;
  };

  const goToEditQrCodePage = () => {
    history.push(`/qr-order/edit/${param.qrCodeId}`);
  };

  const goBack = () => {
    history.push("/marketing/qr-order");
  };

  const goToClonePage = async () => {
    const response = await qrCodeDataService.getQrCodeByIdAsync(qrCode.qrCodeId);
    if (response) {
      const { qrCodeDetail } = response;
      dispatch(setQrCodeData(qrCodeDetail));

      history.push("/qr-order/clone");
    }
  };

  const onDeleteQrCode = async (id) => {
    const res = await qrCodeDataService.deleteQrCodeByIdAsync(id);
    if (res) {
      message.success(pageData.deleteQrCodeSuccess);
    } else {
      message.error(pageData.deleteQrCodeFail);
    }
    goBack();
  };

  const onStopQrCode = async (id) => {
    await qrCodeDataService.stopQrCodeByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.stopQrCodeSuccess);
      } else {
        message.error(pageData.stopQrCodeFail);
      }
      goBack();
    });
  };

  const getActionButton = () => {
    const editButton = (
      <FnbButton onClick={goToEditQrCodePage} text={pageData.button.edit} permission={PermissionKeys.EDIT_QR_CODE} />
    );

    const cloneButton = (
      <FnbButton
        variant="secondary"
        onClick={goToClonePage}
        text={pageData.button.clone}
        permission={qrCode?.isStopped ? " " : PermissionKeys.CREATE_QR_CODE}
      />
    );

    const stopButton = (
      <FnbButton
        onClick={() => setShowConfirmStop(true)}
        text={pageData.button.stop}
        permission={qrCode?.isStopped ? " " : PermissionKeys.STOP_QR_CODE}
      />
    );

    const leaveButton = <FnbButton variant="tertiary" text={pageData.button.leave} onClick={goBack} />;

    const deleteButton = (
      <FnbButton
        variant="tertiary"
        danger={true}
        text={pageData.button.delete}
        onClick={() => setShowConfirmDelete(true)}
        permission={PermissionKeys.DELETE_QR_CODE}
      />
    );

    switch (qrCode?.statusId) {
      case QRCodeStatus.Active:
        return [leaveButton, cloneButton, stopButton];

      case QRCodeStatus.Finish:
        return [leaveButton, cloneButton];

      case QRCodeStatus.Schedule:
        return [leaveButton, deleteButton, cloneButton, editButton];

      default:
        return [leaveButton];
    }
  };

  return (
    <>
      <FnbHeadingPage
        title={qrCode?.name}
        permissionCreate={PermissionKeys.CREATE_QR_CODE}
        // isCompactButtons={false}
        listButtons={getActionButton()}
      ></FnbHeadingPage>

      <div className="qr-code-info-wrapper">
        <div className="qr-code-general-info-card">
          <div className="qr-code-general-info-container">
            <Row>
              <Col xs={24} sm={24} lg={16}>
                <div>
                  <p className="title-text">{pageData.generalInformation}</p>
                </div>
                <div>
                  <p className="label-text mt-20">{pageData.name}</p>
                  <p className="detail-text">{qrCode?.name}</p>
                </div>
                <div>
                  <p className="label-text">{pageData.branch}</p>
                  <p className="detail-text">{qrCode?.branchName}</p>
                </div>
                <div>
                  <p className="label-text">{pageData.serviceType}</p>
                  <p className="detail-text">{qrCode?.serviceTypeName}</p>
                </div>

                {qrCode?.serviceTypeId === OrderTypeConstants.InStore && (
                  <Row>
                    <Col xs={24} sm={24} lg={12}>
                      <div>
                        <p className="label-text">{pageData.area}</p>
                        <p className="detail-text">{qrCode?.areaName}</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <div>
                        <p className="label-text">{pageData.table}</p>
                        <p className="detail-text">{qrCode?.areaTableName}</p>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={24} sm={24} lg={12}>
                    <div>
                      <p className="label-text">{pageData.validFrom}</p>
                      <p className="detail-text">
                        {qrCode?.startDate ? formatDate(qrCode?.startDate, DateFormat.DD_MMM_YYYY) : "-"}
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <div>
                      <p className="label-text">{pageData.validUntil}</p>
                      <p className="detail-text">
                        {qrCode?.endDate ? formatDate(qrCode?.endDate, DateFormat.DD_MMM_YYYY) : "-"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <Row className="justify-content-center">
                  <FnbQrCode fileName={qrCode?.qrCodeId} value={qrCode?.url ?? ""} size={172} showDownloadButton />
                </Row>
              </Col>
            </Row>
          </div>
        </div>

        <div className="qr-code-target-info-card mt-24">
          <div className="qr-code-target-info-container">
            <div>
              <p className="title-text">{pageData.target}</p>
            </div>
            <div>
              <p className="label-text mt-20">{pageData.target}</p>
              <p className="detail-text">{t(qrCode?.targetName)}</p>
            </div>

            {qrCode && qrCode.products && qrCode.products.length > 0 && (
              <div className="mt-3">
                <FnbTable
                  className="table-qr-code-product p-0"
                  dataSource={qrCode?.products}
                  columns={getProductTableColumns()}
                  total={qrCode?.products?.length}
                  scrollY={116 * 5}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={t(pageData.confirmDeleteMessage, { name: qrCode?.name })}
        skipPermission={true}
        okText={pageData.button.delete}
        cancelText={pageData.button.btnIgnoreDelete}
        permission={PermissionKeys.DELETE_QR_CODE}
        onOk={() => onDeleteQrCode(param?.qrCodeId)}
        onCancel={() => setShowConfirmDelete(false)}
        visible={showConfirmDelete}
      />
      <DeleteConfirmComponent
        icon={<StopOutlined />}
        buttonIcon={<StopFill className="icon-svg-hover pointer" />}
        title={pageData.confirmStop}
        content={t(pageData.confirmStopQrCode, { name: qrCode?.name })}
        skipPermission={true}
        okText={pageData.button.btnStop}
        cancelText={pageData.button.btnIgnoreDelete}
        permission={PermissionKeys.STOP_QR_CODE}
        onCancel={() => setShowConfirmStop(false)}
        onOk={() => onStopQrCode(param?.qrCodeId)}
        tooltipTitle={pageData.stop}
        visible={showConfirmStop}
      />
    </>
  );
}
