import styled from "styled-components";
import { Empty as AntEmpty } from "antd";

export const FnbEmptyCustom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  img {
    width: 60px;
    height: 60px;
  }
`;

export const Empty = styled(AntEmpty)`
  margin: 8px 0px !important;
`