import { Form, Radio } from "antd";
import { useLayoutEffect, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  areaOptionSelector,
  areasSelector,
  branchOptionSelector,
  getAllBranchManagement,
  getAreasAreaTablesByBranchId,
} from "store/modules/common/common.reducers";
import { useTranslation } from "react-i18next";
import { ReservationStatus } from "constants/reservation-constant";
import { reserveTableSelector } from "store/modules/reservation/reservation.reducers";
import { tableSettings } from "constants/default.constants";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import FnbSingleDatePicker from "components/fnb-single-date-picker/fnb-single-date-picker";
import { ClockIcon } from "constants/icons.constants";
import moment from "moment";

const DEFAULT_CHECKED_ALL_VALUE = ReservationStatus.All;
const DEFAULT_FORMAT_DATE = "DD/MM/YYYY";
const DEFAULT_FORMAT_UTC_DATE = "MM/D/YYYY, hh:mm:ss A";

const PopoverFilter = forwardRef((props, ref) => {
  const { onChange, onClose } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const branchOption = useSelector(branchOptionSelector);
  const areaOption = useSelector(areaOptionSelector);
  const areas = useSelector(areasSelector);
  const { reserveTableParams } = useSelector(reserveTableSelector);
  const [areaTableOptions, setAreaTableOptions] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedAreaTable, setSelectedAreaTable] = useState([]);
  const [arrivalFromDate, setArrivalFromDate] = useState();
  const [arrivalToDate, setArrivalToDate] = useState();

  const pageData = {
    branch: t("reservation.branch"),
    allBranches: t("reservation.allBranches"),
    area: t("reservation.area"),
    allAreas: t("reservation.allAreas"),
    table: t("reservation.table"),
    allTables: t("reservation.allTables"),
    status: t("reservation.status"),
    resetAllFilters: t("reservation.resetAllFilters"),
    waitToConfirm: t("reservation.waitToConfirm"),
    confirmed: t("reservation.confirmed"),
    serving: t("reservation.serving"),
    cancelled: t("reservation.cancelled"),
    completed: t("reservation.completed"),
    all: t("reservation.all"),
    arrivalFromDate: t("reservation.arrivalFromDate"),
    arrivalToDate: t("reservation.arrivalToDate"),
  };

  const statusOptions = [
    {
      label: t(pageData.all),
      value: ReservationStatus.All,
    },
    {
      label: t(pageData.waitToConfirm),
      value: ReservationStatus.WaitToConfirm,
    },
    {
      label: t(pageData.confirmed),
      value: ReservationStatus.Confirmed,
    },
    {
      label: t(pageData.serving),
      value: ReservationStatus.Serving,
    },
    {
      label: t(pageData.cancelled),
      value: ReservationStatus.Cancelled,
    },
    {
      label: t(pageData.completed),
      value: ReservationStatus.Completed,
    },
  ];

  useLayoutEffect(() => {
    dispatch(getAllBranchManagement());
    dispatch(getAreasAreaTablesByBranchId());
  }, []);

  useEffect(() => {
    if (areas) {
      handleCreateAreaTableOptions(reserveTableParams?.areaIds, areas);
    }
  }, [reserveTableParams?.areaIds, areas]);

  useImperativeHandle(ref, () => ({
    resetFilter() {
      handleResetFilter();
    },
  }));

  const handleChangeBranches = (branchIds) => {
    const { areaIds, areaTableIds, ...rest } = reserveTableParams;
    setSelectedBrand(branchIds);
    setSelectedArea([]);
    setSelectedAreaTable([]);
    onChange &&
      onChange({
        ...rest,
        pageNumber: tableSettings.page,
        branchIds,
      });
    dispatch(getAreasAreaTablesByBranchId({ branchIds }));
  };

  const handleChangeAreas = (areaIds) => {
    const { areaTableIds, ...rest } = reserveTableParams;
    setSelectedArea(areaIds);
    setSelectedAreaTable([]);
    onChange &&
      onChange({
        ...rest,
        pageNumber: tableSettings.page,
        areaIds,
      });
  };

  const handleChangeAreaTables = (areaTableIds) => {
    setSelectedAreaTable(areaTableIds);
    onChange &&
      onChange({
        ...reserveTableParams,
        pageNumber: tableSettings.page,
        areaTableIds,
      });
  };

  const handleChangeStatus = (statusIds) => {
    const statusId = statusIds.target.value;
    onChange &&
      onChange({
        ...reserveTableParams,
        pageNumber: tableSettings.page,
        statusIds: statusId,
      });
  };

  const handleResetFilter = () => {
    const { branchIds, areaIds, areaTableIds, statusIds, ...rest } = reserveTableParams;
    setSelectedBrand([]);
    setSelectedArea([]);
    setSelectedAreaTable([]);
    setArrivalFromDate();
    setArrivalToDate();
    let data = rest;
    data.arrivalFromDate = null;
    data.arrivalToDate = null;
    onChange && onChange({ ...data, pageNumber: tableSettings.page });
  };

  const handleCreateAreaTableOptions = (areaIds, areaList) => {
    const areaTableOptions = [];
    if (areaIds && areaIds?.length > 0) {
      areaList
        ?.filter((area) => areaIds?.includes(area?.id) && area?.areaTables?.length > 0)
        ?.forEach((area) =>
          area?.areaTables?.forEach((areaTable) =>
            areaTableOptions?.push({ label: `${areaTable?.name} - ${area?.name}`, value: areaTable?.id }),
          ),
        );
    } else {
      areaList
        ?.filter((area) => area?.areaTables?.length > 0)
        ?.forEach((area) =>
          area?.areaTables?.forEach((areaTable) =>
            areaTableOptions?.push({ label: `${areaTable?.name} - ${area?.name}`, value: areaTable?.id }),
          ),
        );
    }
    setAreaTableOptions(areaTableOptions);
  };

  const handleChangeFromDate = (_date, dateString) => {
    setArrivalFromDate(dateString);
    setArrivalToDate("");
    
    onChange &&
      onChange({
        ...reserveTableParams,
        pageNumber: tableSettings.page,
        arrivalFromDate: moment(dateString, DEFAULT_FORMAT_DATE).isValid()
          ? moment(dateString, DEFAULT_FORMAT_DATE).startOf("day").locale("en-US").format(DEFAULT_FORMAT_UTC_DATE)
          : "",
        arrivalToDate: "",
      });
  };

  const disabledToDate = (current) => {
    return current && current < moment(arrivalFromDate, DEFAULT_FORMAT_DATE).startOf("day");
  };

  const handleChangeToDate = (_date, dateString) => {
    setArrivalToDate(dateString);
    onChange &&
      onChange({
        ...reserveTableParams,
        pageNumber: tableSettings.page,
        arrivalToDate: moment(dateString, DEFAULT_FORMAT_DATE).isValid()
          ? moment(dateString, DEFAULT_FORMAT_DATE).endOf("day").locale("en-US").format(DEFAULT_FORMAT_UTC_DATE)
          : "",
      });
  };

  return (
    <Form layout="vertical">
      <Form.Item label={pageData.branch}>
        <FnbSelectMultiple
          option={branchOption?.map((item) => ({
            id: item.value,
            name: item.label,
          }))}
          placeholder={pageData.allBranches}
          onChange={handleChangeBranches}
          value={selectedBrand}
        />
      </Form.Item>
      <Form.Item label={pageData.area}>
        <FnbSelectMultiple
          option={areaOption?.map((item) => ({
            id: item.value,
            name: item.label,
          }))}
          placeholder={pageData.allAreas}
          onChange={handleChangeAreas}
          value={selectedArea}
        />
      </Form.Item>
      <Form.Item label={pageData.table}>
        <FnbSelectMultiple
          option={areaTableOptions?.map((item) => ({
            id: item.value,
            name: item.label,
          }))}
          placeholder={pageData.allTables}
          onChange={handleChangeAreaTables}
          value={selectedAreaTable}
        />
      </Form.Item>
      <FnbFormItem label={pageData.arrivalFromDate}>
        <FnbSingleDatePicker onChange={handleChangeFromDate} value={arrivalFromDate} suffixIcon={<ClockIcon />} />
      </FnbFormItem>
      <FnbFormItem label={pageData.arrivalToDate}>
        <FnbSingleDatePicker
            disabledDate={disabledToDate}
            onChange={handleChangeToDate}
            value={arrivalToDate}
            disabled={!Boolean(arrivalFromDate)}
            suffixIcon={<ClockIcon />}
          />
      </FnbFormItem>
      <Form.Item label={pageData.status}>
        <Radio.Group defaultValue={DEFAULT_CHECKED_ALL_VALUE} buttonStyle="solid" onChange={handleChangeStatus}>
          {statusOptions?.map((item) => (
            <FnbRadioButton value={item?.value}>{item?.label}</FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default PopoverFilter;
