export const TOTAL_STEP_SYSTEM_GUIDELINE = 7;

export const StepsSystemGuidelineKeysConstants = {
  ADD_INGREDIENT: "add-ingredient",
  ADD_PRODUCT: "add-product",
  BUILD_WEBSITE: "build-website",
  OPERATION: "operation",
  PAYMENT_METHOD: "payment-method",
  DELIVERY_METHOD: "delivery-method",
  SET_UP_ADDRESS: "set-up-address",
};

export const StepsSystemGuidelineConstants = {
  [StepsSystemGuidelineKeysConstants.ADD_INGREDIENT]: {
    title: "systemGuideline.addFirstIngredient.title",
    content: "systemGuideline.addFirstIngredient.content",
    button: {
      label: "systemGuideline.addFirstIngredient.title",
    },
    navigateLink: "/material/add-new",
  },
  [StepsSystemGuidelineKeysConstants.ADD_PRODUCT]: {
    title: "systemGuideline.createFirstProduct.title",
    content: "systemGuideline.createFirstProduct.content",
    button: {
      label: "systemGuideline.createFirstProduct.title",
    },
    navigateLink: "/product/create-new",
  },
  [StepsSystemGuidelineKeysConstants.BUILD_WEBSITE]: {
    title: "systemGuideline.buildWebsite.title",
    content: "systemGuideline.buildWebsite.content",
    button: {
      label: "systemGuideline.buildWebsite.buttonLabel",
    },
    navigateLink: "/online-store/management",
  },
  [StepsSystemGuidelineKeysConstants.OPERATION]: {
    title: "systemGuideline.customizeOperation.title",
    content: "systemGuideline.customizeOperation.content",
    button: {
      label: "systemGuideline.customizeOperation.buttonLabel",
    },
    navigateLink: "/config?tabActive=operation",
  },
  [StepsSystemGuidelineKeysConstants.PAYMENT_METHOD]: {
    title: "systemGuideline.customizePaymentMethod.title",
    content: "systemGuideline.customizePaymentMethod.content",
    button: {
      label: "systemGuideline.customizePaymentMethod.buttonLabel",
    },
    navigateLink: "/config?tabActive=payment",
  },
  [StepsSystemGuidelineKeysConstants.DELIVERY_METHOD]: {
    title: "systemGuideline.configDeliveryMethod.title",
    content: "systemGuideline.configDeliveryMethod.content",
    button: {
      label: "systemGuideline.configDeliveryMethod.buttonLabel",
    },
    navigateLink: "/config?tabActive=delivery",
  },
  [StepsSystemGuidelineKeysConstants.SET_UP_ADDRESS]: {
    title: "systemGuideline.setUpAddress.title",
    content: "systemGuideline.setUpAddress.content",
    button: {
      label: "systemGuideline.setUpAddress.buttonLabel",
    },
    navigateLink: "/branch/edit/$branchId$",
  },
};
