import { Card, Col, Form, Row, Typography } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { tableSettings } from "constants/default.constants";
import { AddCircleIcon, BookIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import { EnumTransferMaterialStatus } from "constants/transfer-material-history.constant";
import transferMaterialDataService from "data-services/transfer-material/transfer-material-data.service";
import TableTransferMaterialHistory from "pages/inventory/transfer-material-history/components/table-transfer-material-history.component";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { formatDate, formatShortDate, hasPermission } from "utils/helpers";
import "../index.scss";
import { useMediaQuery } from "react-responsive";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

const { Title } = Typography;

export const TableTransferMaterial = (props) => {
  const [t] = useTranslation();
  const history = useHistory();
  const pageViewDetailLink = "/inventory/transfer-material/view";
  const pageData = {
    //#region Table header
    id: t("table.id"),
    ingredients: t("transferMaterial.ingredients"),
    title: t("transferMaterial.title"),
    notPermissionAdd: t("transferMaterial.notPermissionAdd"),
    from: t("table.from"),
    to: t("table.to"),
    createdBy: t("table.createdBy"),
    createdDate: t("table.time"),
    status: t("table.status"),
    //#endregion
    search: t("transferMaterial.search"),
    viewHistory: t("transferMaterial.viewHistory"),
    new: t("transferMaterial.statusTransfer.new"),
    delivering: t("transferMaterial.statusTransfer.delivering"),
    completed: t("transferMaterial.statusTransfer.completed"),
    canceled: t("transferMaterial.statusTransfer.canceled"),
    inProgress: t("transferMaterial.statusTransfer.inProgress"),
    transferIngredientHistory: t("transferMaterialHistory.transferIngredientHistory"),
    btnAddNew: t("button.addNew"),
    confirm: t("button.confirm"),
    toCreateData: t("area.toCreateData"),
  };

  const [totalMaterial, setTotalMaterial] = useState(0);
  const [listMaterial, setListMaterial] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isShowModalTransferIngredient, setIsShowModalTransferIngredient] = useState(false);
  const [isShowModalCreatePermission, setIsShowModalCreatePermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  useEffect(() => {
    initDataTableMaterials(tableSettings.page, tableSettings.pageSize, keySearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initDataTableMaterials = (pageNumber, pageSize, keySearch) => {
    /// get list material
    setIsLoading(true);
    transferMaterialDataService
      .getTransferMaterialManagementsAsync(pageNumber, pageSize, keySearch)
      .then((res) => {
        let materials = mappingToDataTableMaterials(res.transferMaterials);
        setListMaterial(materials);
        setTotalMaterial(res.total);
        setCurrentPageNumber(pageNumber);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onChangePage = (page, pageSize) => {
    initDataTableMaterials(page, pageSize, keySearch);
  };

  const mappingToDataTableMaterials = (materials) => {
    return materials?.map((i, index) => {
      return {
        ...i,
        id: i.id,
        from: i.from,
        to: i.to,
        status: i.status,
        createdDate: i?.createdDate,
        createdBy: i.createdBy,
      };
    });
  };
  const getColumns = () => {
    const columns = [
      {
        title: pageData.id,
        dataIndex: "code",
        width: "8%",
        className: "col-id",
        render: (value, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{value}</FnbTooltip>;
        },
      },
      {
        title: pageData.from,
        dataIndex: "from",
        width: "30%",
        render: (value) => {
          return (
            <FnbTooltip maxWidthContent={800} onlyShowWhenEllipsis={true}>
              {value}
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.to,
        dataIndex: "to",
        width: "30%",
        render: (value) => {
          return (
            <FnbTooltip maxWidthContent={800} onlyShowWhenEllipsis={true}>
              {value}
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.createdBy,
        dataIndex: "createdBy",
        className: "colCreatedBy",
        render: (value) => {
          return (
            <FnbTooltip maxWidthContent={800} onlyShowWhenEllipsis={true}>
              {value}
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.createdDate,
        dataIndex: "createdDate",
        render: (value, render) => {
          return <span className="createdDate">{formatShortDate(formatDate(value, DateFormat.YYYY_MM_DD))}</span>;
        },
      },
      {
        title: pageData.status,
        dataIndex: "status",
        width: "9rem",
        className: "colStatus",
        ellipsis: true,
        align: "center",
        render: (value) => {
          return renderTransferMaterialStatus(value);
        },
      },
    ];

    return columns;
  };

  const onSearch = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        searchKeyAndFilterMaterials(tableSettings.page, tableSettings.pageSize, keySearch);
      }, 500),
    );
  };

  const searchKeyAndFilterMaterials = (pageNumber, pageSize, keySearch) => {
    transferMaterialDataService.getTransferMaterialManagementsAsync(pageNumber, pageSize, keySearch).then((res) => {
      let materials = mappingToDataTableMaterials(res.transferMaterials);
      setListMaterial(materials);
      setTotalMaterial(res.total);
      setCurrentPageNumber(pageNumber);
    });
  };

  const renderTransferMaterialStatus = (statusId) => {
    switch (statusId) {
      case EnumTransferMaterialStatus.Draft:
        return <FnbBadge className="status-draft" text={pageData.new} />;
      case EnumTransferMaterialStatus.Inprogress:
        return <FnbBadge className="status-in-progress" text={pageData.inProgress} />;
      case EnumTransferMaterialStatus.Completed:
        return <FnbBadge className="status-completed" text={pageData.completed} />;
      case EnumTransferMaterialStatus.Delivering:
        return <FnbBadge className="status-delivering" text={pageData.delivering} />;
      default:
        return <FnbBadge className="status-canceled" text={pageData.canceled} />;
    }
  };
  const handleShowModalTransferIngredient = () => setIsShowModalTransferIngredient(true);
  const handleHiddenModalTransferIngredient = () => setIsShowModalTransferIngredient(false);

  const handleHiddenModalCreateMaterial = () => setIsShowModalCreatePermission(false);
  const handleShowModalCreateMaterial = () => setIsShowModalCreatePermission(true);

  const onRowClick = (record, _index) => {
    history.push(`${pageViewDetailLink}/${record?.id}`);
  };

  const onCreate = () => {
    if (hasPermission(PermissionKeys.CREATE_NEW_TRANSFER_MATERIAL)) {
      history.push("/inventory/transfer-material/create-new");
    } else {
      handleShowModalCreateMaterial();
    }
  };

  return (
    <Form>
      <Card className="w-100 fnb-card-custom">
        <Row className="total-cost-amount-row">
          <Col span={24}>
            <FnbTable
              loading={isLoading}
              className="table-transfer-material"
              columns={getColumns()}
              pageSize={tableSettings.pageSize}
              dataSource={listMaterial}
              currentPageNumber={currentPageNumber}
              total={totalMaterial}
              onChangePage={onChangePage}
              editPermission={PermissionKeys.EDIT_TRANSFER_MATERIAL}
              search={{
                placeholder: pageData.search,
                onChange: onSearch,
              }}
              isCompactButtons={false}
              listButtons={[
                <FnbButton
                  permission={PermissionKeys.CREATE_NEW_TRANSFER_MATERIAL}
                  onClick={onCreate}
                  text={pageData.btnAddNew}
                  iconHeader={<AddCircleIcon />}
                  className="fnb-heading-page__button-create"
                />,
                <FnbButton
                  text={pageData.viewHistory}
                  iconHeader={<BookIcon width={20} height={20} />}
                  variant="secondary"
                  onClick={handleShowModalTransferIngredient}
                />,
              ]}
              footerMessage={pageData.tableShowingRecordMessage}
              rowKey={"id"}
              onRowClick={onRowClick}
              scrollX={1500}
              emptyText={
                hasPermission(PermissionKeys.CREATE_NEW_TRANSFER_MATERIAL) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.btnAddNew}
                      onClick={onCreate}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Col>
        </Row>
      </Card>
      <FnbModal
        className={"ingredient-history-dialog"}
        width={1402}
        handleCancel={handleHiddenModalTransferIngredient}
        visible={isShowModalTransferIngredient}
        title={
          <Title className="ingredient-history__title" level={isMobile ? 5 : 3}>
            {pageData.transferIngredientHistory}
          </Title>
        }
        content={
          <div className="ingredient-history__body">
            <TableTransferMaterialHistory />
          </div>
        }
        hideFooter={true}
        closable={true}
      />
      <FnbModal
        className={"create-permission"}
        handleCancel={handleHiddenModalCreateMaterial}
        visible={isShowModalCreatePermission}
        title={t("transferMaterial.titleNotification")}
        content={<span>{t("transferMaterial.notPermissionAdd")}</span>}
        closable={true}
        cancelText={pageData.confirm}
      />
    </Form>
  );
};
