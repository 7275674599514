import { Button, Col, Form, Radio, Row, Space, message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import PageTitle from "components/page-title";
import { DepositStatus } from "constants/deposit-status.contants";
import { ArrowLeftReservationDetail } from "constants/icons.constants";
import { orderPaymentMethodLocalization, paymentMethod } from "constants/payment-method.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { TRANSACTION_TABPANE_KEY } from "constants/report.constants";
import { ReservationStatus } from "constants/reservation-constant";
import { enumReservation } from "constants/reservation-status.constrants";
import { DateFormat } from "constants/string.constants";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import reserveTableDataService from "data-services/reserveTable/reserve-table-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { enumReserveTable } from "themes/theme-2-new/constants/enums";
import {
  formatCurrencyWithoutSuffix,
  formatDate,
  formatNumberDecimalOrInteger,
  formatPhoneNumber,
  formatTextNumber,
  getCurrency,
} from "utils/helpers";
import { Id } from "../management/styled";
import { BadgeReservationStatus } from "./components/badge/BadgeReservationStatus";
import { BadgeDepositStatus } from "./components/deposit/BadgeDepositStatus";
import "./index.scss";

export default function ReservationDetail(props) {
  const { t } = useTranslation();
  const param = useParams();
  const [data, setData] = useState({});
  const history = useHistory();
  const [titleDialogConfirm, setTitleDialogConfirm] = useState("");
  const [actionDialogConfirm, setActionDialogConfirm] = useState("");
  const [reservationId, setReservationId] = useState();
  const [statusReservation, setStatusReservation] = useState();
  const [statusReservationOld, setStatusReservationOld] = useState(null);
  const [isDialogConfirm, setIsDialogConfirm] = useState(false);
  const [contentElement, setContentElement] = useState(null);
  const [depositStatusId, setDepositStatusId] = useState(null);
  const [form] = Form.useForm();
  const [widthConfirm, setWidthConfirm] = useState(400);
  const [depositAmount, setDepositAmount] = useState(0);
  const [activePaymentMethods, setActivePaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});
  const [isCheckedRefundMethod, setIsCheckedRefundMethod] = useState(true);
  const [textConfirm, setTextConfirm] = useState("");
  const [status, setStatus] = useState(ReservationStatus.Cancelled);
  const [reservationStatus, setReservationStatus] = useState(ReservationStatus.Cancelled);
  const [isReservationDialogRefund, setIsReservationDialogRefund] = useState(true);

  const pageData = {
    reservationDetail: t("reservation.reservationDetail", "Reservation detail"),
    guestInformation: t("reservation.guestInformation", "Guest information"),
    numberOfGuests: t("reservation.numberOfGuests", "Number of Guests"),
    guestArrivalTime: t("reservation.guestArrivalTime", "Guest arrival time"),
    tableInformation: t("reservation.tableInformation", "Table information"),
    assignedTables: t("reservation.assignedTables", "Assigned Table(s)"),
    name: t("reserve.name", "Name"),
    phoneNumber: t("reservation.phone", "Phone Number"),
    email: t("form.email", "Email"),
    note: t("form.note", "Note"),
    branch: t("reservation.branch", "Branch"),
    orderId: t("order.order", "Order Id"),
    BtnEdit: t("button.edit"),
    reject: t("reservation.reject"),
    confirm: t("reservation.confirm"),
    update: t("reservation.update"),
    cancel: t("reservation.cancel"),
    confirmReservation: t("reservation.confirmReservation"),
    cancelReservation: t("reservation.cancelReservation"),
    rejectReservation: t("reservation.rejectReservation"),
    confirmReceived: t("reservation.confirmReceived"),
    doYouReallyWantTo: t("reservation.doYouReallyWantTo"),
    discard: t("reservation.discard"),
    confirmSuccess: t("reservation.confirmSuccess"),
    cancelSuccess: t("reservation.cancelSuccess"),
    rejectSuccess: t("reservation.rejectSuccess"),
    hasNotYetReceivedDeposit: t("reservation.hasNotYetReceivedDeposit"),
    reason: t("reservation.reason"),
    validateReason: t("reservation.validateReason"),
    placeholderReason: t("reservation.placeholderReason"),
    rejectDepositReservation: {
      refundNow: t("reservation.rejectReservationDeposit.refundNow"),
      refundLater: t("reservation.rejectReservationDeposit.refundLater"),
      noRefundReservation: t("reservation.rejectReservationDeposit.noRefundReservation"),
      rejectReservationContent: t("reservation.rejectReservationDeposit.rejectReservationContent"),
    },
    cancelDepositReservation: {
      refundNow: t("reservation.cancelReservationDeposit.refundNow"),
      refundLater: t("reservation.cancelReservationDeposit.refundLater"),
      noRefundReservation: t("reservation.cancelReservationDeposit.noRefundReservation"),
      cancelReservationContent: t("reservation.cancelReservationDeposit.cancelReservationContent"),
    },
    cash: t("payment.cash"),
    bankTransfer: t("payment.bankTransfer"),
    rejectReservationContent: t("reservation.rejectReservationContent"),
    depositMethod: t("reservation.depositMethod"),
    depositAmount: t("reservation.depositAmount"),
    depositStatus: t("reservation.depositStatus"),
    ignore: t("reserve.ignore"),
    pleaseSelectRefundMethod: t("reservation.pleaseSelectRefundMethod"),
    depositBy: t("reservation.depositBy"),
    pleaseTurnOnDeposit: t("reservation.pleaseTurnOnDeposit"),
    customer: t("reserve.customer"),
  };

  const isShowTableInfo = !(
    data?.status === enumReservation.Completed ||
    data?.status === enumReservation.Serving ||
    data?.status === enumReservation.Cancelled
  );
  const dataPaymentMethod = [
    {
      id: paymentMethod.Cash,
      name: pageData.cash,
    },
    {
      id: paymentMethod.BankTransfer,
      name: pageData.bankTransfer,
    },
  ];

  const filterPaymentMethod = dataPaymentMethod.filter((dataPaymentMethod) =>
    activePaymentMethods.some(
      (activePaymentMethod) => activePaymentMethod && Object.values(activePaymentMethod).includes(dataPaymentMethod.id),
    ),
  );

  const initData = async () => {
    const { id } = param;
    await reserveTableDataService.getReserveTableAsync(id).then((result) => {
      setData(result);
    });

    await paymentConfigDataService.getAllStorePaymentConfigAsync().then((resData) => {
      if (resData) {
        if (resData.paymentMethods?.length > 0) {
          const activePaymentMethods = dataPaymentMethod.filter((dataPaymentMethod) =>
            resData.paymentMethods.find(
              (paymentMethod) =>
                paymentMethod.enumId === dataPaymentMethod.id && paymentMethod.paymentConfigs?.[0]?.isActivated,
            ),
          );
          setActivePaymentMethods(activePaymentMethods);
          setDefaultPaymentMethod(activePaymentMethods?.[0]);
        }
      }
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const handleViewOrderDetail = (id) => {
    window.open(`/report/order/detail/${id}`, "_blank");
  };

  const handleBackButton = () => {
    history.push(`/report/transaction/${TRANSACTION_TABPANE_KEY.RESERVATION}`);
  };

  const handleRedirectEdit = (id) => {
    history.push(`/report/reservation-edit/${id}`);
  };

  const getAmountCurrency = (amount) => {
    return formatNumberDecimalOrInteger(amount) + " " + getCurrency();
  };

  const onConfirmItem = (reservationId, depositStatusId, depositAmount) => {
    setDepositAmount(depositAmount);
    setTitleDialogConfirm(pageData.confirmReservation);
    if (!data?.depositStatusId) {
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.confirm }));
      setIsReservationDialogRefund(false);
    } else {
      setDepositStatusId(depositStatusId);
      if (depositStatusId === DepositStatus.NotReceived) {
        setIsReservationDialogRefund(true);
        setTextConfirm(pageData.hasNotYetReceivedDeposit);
        setStatus(ReservationStatus.Confirmed);
        setReservationStatus(reservationStatus);
        renderContent(pageData.hasNotYetReceivedDeposit);
        setTitleDialogConfirm(pageData.confirmReceived);
      } else {
        renderContent(t("reservation.doYouReallyWantTo", { action: pageData.confirm }));
        setIsReservationDialogRefund(false);
      }
    }
    setReservationId(reservationId);
    setStatusReservation(ReservationStatus.Confirmed);
    setIsDialogConfirm(true);
  };

  const onRejectItem = (reservationId, statusId, depositStatusId, depositAmount) => {
    setDepositAmount(depositAmount);
    if (!data?.depositStatusId) {
      onRejectDefault(statusId);
      setIsReservationDialogRefund(false);
    } else {
      setIsReservationDialogRefund(true);
      if (depositStatusId === DepositStatus.Received) {
        setStatus(ReservationStatus.Cancelled);
        setReservationStatus(statusId);
        if (statusId === ReservationStatus.Confirmed) {
          setTextConfirm(
            t("reservation.cancelReservationDeposit.cancelReservationContent", {
              amount: getAmountCurrency(depositAmount),
              action: pageData.cancel,
            }),
          );
          setTitleDialogConfirm(pageData.cancelReservation);
        } else {
          setTextConfirm(
            t("reservation.rejectReservationDeposit.rejectReservationContent", {
              amount: getAmountCurrency(depositAmount),
              action: pageData.reject,
            }),
          );
          setTitleDialogConfirm(pageData.rejectReservation);
        }
      } else {
        setIsReservationDialogRefund(false);
        onRejectDefault(statusId);
      }
    }
    setReservationId(reservationId);
    setStatusReservationOld(statusId);
    setStatusReservation(ReservationStatus.Cancelled);
    setIsDialogConfirm(true);
  };

  const onRejectDefault = (statusId) => {
    if (statusId === ReservationStatus.Confirmed) {
      setTitleDialogConfirm(pageData.cancelReservation);
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.cancel }));
    } else {
      setTitleDialogConfirm(pageData.rejectReservation);
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.reject }));
    }
  };

  const handleHiddenDialog = () => {
    setIsDialogConfirm(false);
  };

  const updateReservationStatus = async (reservationId, status) => {
    form.validateFields().then(async (values) => {
      let formValue = values;
      let refundPaymentMethodId = null;
      let paymentMethodId = null;
      let reason = formValue.reason || null;
      let depositStatus = formValue.depositStatus || depositStatusId;
      if (status === enumReserveTable.Confirmed) {
        paymentMethodId = formValue.depositMethod;
        refundPaymentMethodId = null;
        reason = null;
      } else if (formValue.depositStatus === DepositStatus.Refunded) {
        refundPaymentMethodId = formValue.depositMethod;
        paymentMethodId = null;
      }
      if (reservationId && status !== undefined) {
        const data = {
          id: reservationId,
          statusId: status,
          depositStatusId: depositStatus,
          refundPaymentMethodId: refundPaymentMethodId,
          paymentMethodId: paymentMethodId,
          refundAmount: depositAmount,
          Reason: reason,
        };
        await reserveTableDataService.updateReserveTableStatusAsync(data).then((response) => {
          if (response) {
            initData();
            if (statusReservationOld === ReservationStatus.Confirmed) {
              message.success(pageData.cancelSuccess);
            } else if (status === ReservationStatus.Confirmed) {
              message.success(pageData.confirmSuccess);
            } else message.success(pageData.rejectSuccess);
          }
        });

        setIsDialogConfirm(false);
        setReservationId();
        setStatusReservation();
      }
    });
  };

  const renderContent = (textConfirm) => {
    setActionDialogConfirm(textConfirm);
  };

  const handleChangeRadioGroup = ({ target }) => {
    if (target) {
      if (target.value !== DepositStatus.Refunded) {
        setIsCheckedRefundMethod(false);
      } else {
        setIsCheckedRefundMethod(true);
      }
      form.validateFields();
    }
  };

  const handleFieldsChange = () => {
    const isFormValid = () =>
      form.getFieldsError().some((item) => {
        return item.errors.length > 0;
      });
    if (!isFormValid()) {
      document.getElementById("btn-submit").classList.remove("fnb-button--disabled--fulfill");
      document.getElementById("btn-submit").removeAttribute("disabled");
    } else {
      document.getElementById("btn-submit").classList.add("fnb-button--disabled--fulfill");
      document.getElementById("btn-submit").setAttribute("disabled", "");
    }
  };

  const handleResetForm = () => {
    form.resetFields();
    setIsCheckedRefundMethod(true);
    const elBtnSubmit = document.getElementById("btn-submit");
    if (elBtnSubmit) {
      elBtnSubmit.classList.remove("fnb-button--disabled--fulfill");
      elBtnSubmit.removeAttribute("disabled");
    }
  };

  const renderContentElement = () => {
    return status === ReservationStatus.Cancelled ? (
      <div className="confirm-reservation-page">
        <Form form={form} onFieldsChange={handleFieldsChange}>
          <Row gutter={[28, 32]}>
            <Col span={24}>
              <div className="text-confirm">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${textConfirm}`,
                  }}
                ></span>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                className="form-confirm-reservation"
                name={"depositStatus"}
                initialValue={DepositStatus.Refunded}
              >
                <Radio.Group
                  defaultValue={DepositStatus.Refunded}
                  value={DepositStatus.Refunded}
                  className="list-reservation-settings"
                  onChange={handleChangeRadioGroup}
                >
                  <Radio value={DepositStatus.Refunded}>
                    <span
                      className="label-radio-reservation"
                      dangerouslySetInnerHTML={{
                        __html: `${
                          data.status === ReservationStatus.WaitToConfirm
                            ? pageData.rejectDepositReservation.refundNow
                            : pageData.cancelDepositReservation.refundNow
                        }`,
                      }}
                    ></span>
                  </Radio>
                  <Space direction="vertical" size={12} className="w-100" style={{ paddingLeft: "32px" }}>
                    <Space className="w-100" direction="vertical" size={8}>
                      <Form.Item
                        name={"depositMethod"}
                        initialValue={defaultPaymentMethod?.id}
                        className="form-confirm-reservation"
                        rules={[
                          {
                            required: isCheckedRefundMethod,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.pleaseSelectRefundMethod}
                              />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          option={filterPaymentMethod?.map((item, index) => ({
                            id: item.id,
                            name: item.name,
                            key: index,
                          }))}
                          disabled={!isCheckedRefundMethod}
                        />
                      </Form.Item>
                    </Space>
                  </Space>
                  <Radio value={DepositStatus.WaitToRefund}>
                    <div className="reservation-coming-soon">
                      <span
                        className="label-radio-reservation"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            data.status === ReservationStatus.WaitToConfirm
                              ? pageData.rejectDepositReservation.refundLater
                              : pageData.cancelDepositReservation.refundLater
                          }`,
                        }}
                      ></span>
                    </div>
                  </Radio>
                  <Radio value={DepositStatus.NoRefund}>
                    <div className="reservation-coming-soon">
                      <span
                        className="label-radio-reservation"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            data.status === ReservationStatus.WaitToConfirm
                              ? pageData.rejectDepositReservation.noRefundReservation
                              : pageData.cancelDepositReservation.noRefundReservation
                          }`,
                        }}
                      ></span>
                    </div>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <h3 className="fnb-form-label">
                {pageData.reason} <span className="required-field">*</span>
              </h3>
              <Form.Item
                name={"reason"}
                className="form-confirm-reservation"
                initialValue={""}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validateReason} />
                    ),
                  },
                ]}
              >
                <FnbTextArea rows={4} maxLength={255} placeholder={pageData.placeholderReason}></FnbTextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    ) : (
      <div className="confirm-reservation-page">
        <Form form={form} onFieldsChange={handleFieldsChange}>
          <Row gutter={[28, 32]}>
            <Col span={24}>
              <div className="text-confirm">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${textConfirm}`,
                  }}
                ></span>
              </div>
            </Col>
            <Col lg={12} span={24}>
              <h3 className="fnb-form-label">{pageData.depositMethod}</h3>
              <Form.Item
                name={"depositMethod"}
                initialValue={defaultPaymentMethod?.id}
                className="form-confirm-reservation"
                rules={[
                  {
                    required: isCheckedRefundMethod,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.pleaseSelectRefundMethod}
                      />
                    ),
                  },
                ]}
              >
                <FnbSelectSingle
                  className="deposit-paymentmethod"
                  option={filterPaymentMethod?.map((item, index) => ({
                    id: item.id,
                    name: item.name,
                    key: index,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h3 className="fnb-form-label">{pageData.depositAmount}</h3>
              <p className="text-detail">
                {formatTextNumber(depositAmount) ?? "-"} <span className="text-currency">{getCurrency()}</span>
              </p>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };
  const getPaymentMethod = () => {
    return data?.paymentMethodId ? t(orderPaymentMethodLocalization[data?.paymentMethodId]) : "-";
  };

  return (
    <div className="view-detail-reservation">
      <Row className="fnb-row-page-header items-center">
        <Row className="w-100">
          <Col sm={12} span={24}>
            <Row>
              <Col className="mr-3 items-center">
                <Button icon={<ArrowLeftReservationDetail />} className="btn-back" onClick={handleBackButton} />
              </Col>
              <Col className="mr-3 items-center">
                <PageTitle className="items-center" content={data?.stringCode} isCode={true} />
              </Col>
              <Col className="items-center">
                <BadgeReservationStatus status={data?.status} />
              </Col>
            </Row>
          </Col>
          <Col sm={12} span={24}>
            <Col className="content-list-button">
              <div className="list-group-button">
                {(data?.status === ReservationStatus.WaitToConfirm || data?.status === ReservationStatus.Confirmed) && (
                  <FnbButton
                    variant="tertiary"
                    danger={true}
                    minWidth="100px"
                    text={data?.status === ReservationStatus.WaitToConfirm ? pageData.reject : pageData.cancel}
                    disabled={
                      data?.status !== ReservationStatus.WaitToConfirm && data?.status !== ReservationStatus.Confirmed
                    }
                    onClick={() => onRejectItem(data?.id, data?.status, data?.depositStatusId, data?.depositAmount)}
                    permission={PermissionKeys.CANCEL_RESERVATION}
                  />
                )}
                {data?.status === ReservationStatus.WaitToConfirm && (
                  <FnbButton
                    minWidth="100px"
                    text={pageData.confirm}
                    disabled={
                      data?.status !== ReservationStatus.WaitToConfirm && data?.status !== ReservationStatus.Confirmed
                    }
                    onClick={() => onConfirmItem(data?.id, data?.depositStatusId, data?.depositAmount)}
                    permission={PermissionKeys.EDIT_RESERVATION}
                  />
                )}
                {!(data?.status === enumReservation.Serving || data?.status === enumReservation.Completed) && (
                  <FnbButton
                    minWidth="100px"
                    variant="secondary"
                    text={pageData.BtnEdit}
                    disabled={
                      data?.status !== ReservationStatus.WaitToConfirm &&
                      data?.status !== ReservationStatus.Confirmed &&
                      data?.status !== ReservationStatus.Cancelled
                    }
                    onClick={() => handleRedirectEdit(data?.id)}
                    permission={PermissionKeys.EDIT_RESERVATION}
                  />
                )}
              </div>
            </Col>
          </Col>
        </Row>
      </Row>
      <div className="clearfix"></div>

      <div className="reservation-detail-card">
        <div className="guest-infomation">
          <Row>
            <Col sm={24} span={24}>
              <div className="title-session">
                <span>{pageData.guestInformation}</span>
                <span className="border-bottom-title-card" />
              </div>
            </Col>
            <Col sm={12} span={24}></Col>
          </Row>
          <Row>
            <Col span={24} sm={12}>
              <Row>
                {(data?.status === enumReservation.Serving || data?.status === enumReservation.Completed) && (
                  <Col span={24}>
                    <div className="text-container order-id">
                      <p className="text-label">{pageData.orderId}</p>
                      <Id className="text-detail" onClick={() => handleViewOrderDetail(data?.orderId)}>
                        #{data?.orderCode || "-"}
                      </Id>
                    </div>
                  </Col>
                )}

                <Col span={24}>
                  <div className="text-container">
                    <p className="text-label">{pageData.customer}</p>
                    <p className="text-detail">
                      {data?.customerName || "-"}
                      <span>{data?.membership ? " - " : ""}</span>
                      <span className="text-customer-rank">
                        {data?.membership ? data?.membership + " (" + data?.discount + "%)" : ""}
                      </span>
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="text-container">
                    <p className="text-label">{pageData.phoneNumber}</p>
                    <p className="text-detail">
                      {formatPhoneNumber(data?.branchPhoneCode, data?.customerPhone) || "-"}
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="text-container">
                    <p className="text-label">{pageData.email}</p>
                    <p className="text-detail">{data?.customerEmail || "-"}</p>
                  </div>
                </Col>
                {data?.status === enumReservation.Cancelled && (
                  <Col span={24}>
                    <div className="text-container">
                      <p className="text-label">{pageData.reason}</p>
                      <p className="text-detail">{data?.reason || "-"}</p>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24} sm={12}>
              <Row>
                <Col span={24}>
                  <div className="text-container">
                    <p className="text-label">{pageData.numberOfGuests}</p>
                    <p className="text-detail">{data?.numberOfGuests || "-"}</p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="text-container">
                    <p className="text-label guest-arrival-label">{pageData.guestArrivalTime}</p>
                    <p className="text-detail guest-arrival-detail">
                      <BadgeReservationStatus
                        colorBackground={"#F7F5FF"}
                        colorText={"#282828"}
                        text={formatDate(data?.guestArrivalTime, DateFormat.HH_MM_dash_DD_MM_YYYY)}
                      />
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  {data?.isShowDeposit ? (
                    <div className="text-container">
                      <Row>
                        <Col span={12}>
                          <p className="text-label guest-arrival-label">{pageData.depositStatus}</p>
                          <p className="text-detail guest-arrival-detail">
                            {data?.depositStatusId ? <BadgeDepositStatus status={data?.depositStatusId} /> : "-"}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p>
                            <span className="text-deposit-amount">
                              {formatCurrencyWithoutSuffix(data?.depositAmount)}{" "}
                            </span>
                            <span className="text-label">
                              {getCurrency()} {pageData.depositBy}:
                            </span>
                          </p>
                          <p className="text-detail guest-arrival-detail">{getPaymentMethod()}</p>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="text-container">
                      <Row>
                        <Col span={24}>
                          <p className="text-label guest-arrival-label">{pageData.depositStatus}</p>
                          <div className="content-warning-turn-off-deposit">
                            <span
                              className="label-content-warning"
                              dangerouslySetInnerHTML={{
                                __html: `${pageData.pleaseTurnOnDeposit}`,
                              }}
                            ></span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      {isShowTableInfo && (
        <div className="reservation-detail-card">
          <div className="table-infomation">
            <div className="title-session">
              <span>{pageData.tableInformation}</span>
              <span className="border-bottom-title-card" />
            </div>
            <Row>
              <Col span={24} sm={12}>
                <Row>
                  <Col span={24}>
                    <div className="text-container">
                      <p className="text-label">{pageData.branch}</p>
                      <p className="text-detail branch-reservation">
                        <span>{data?.branchName + ","}</span> <span>{data?.branchAddress || "-"}</span>
                      </p>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="text-container assign-tables">
                      <p className="text-label">{pageData.assignedTables}</p>
                      {data?.assignedTables?.map((assignTable) => {
                        const assignedTableSplit = assignTable.split("-");
                        const areaAsString = assignedTableSplit[0];
                        const tableAsString = assignedTableSplit[1];
                        return (
                          <>
                            <Row>
                              <Col span={11} sm={8}>
                                <p className="text-detail">{areaAsString}</p>
                              </Col>
                              <Col span={2} sm={2}>
                                <p className="text-detail text-center"> - </p>
                              </Col>
                              <Col span={11} sm={14}>
                                <p className="text-detail">{tableAsString}</p>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24} sm={12}>
                <Row>
                  <Col span={24}>
                    <div className="text-container">
                      <p className="text-label">{pageData.note}</p>
                      <p className="text-detail">{data?.notes || "-"}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}

      <DeleteConfirmComponent
        title={titleDialogConfirm}
        visible={isDialogConfirm}
        skipPermission={true}
        cancelText={pageData.ignore}
        okText={titleDialogConfirm}
        minWidthBtnOk="180px"
        onCancel={handleHiddenDialog}
        onOk={() => updateReservationStatus(reservationId, statusReservation)}
        contentElement={
          isReservationDialogRefund ? (
            renderContentElement()
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: `${actionDialogConfirm}`,
              }}
            ></span>
          )
        }
        width={446}
        idBtnOk={"btn-submit"}
        onAfterClose={handleResetForm}
      />
    </div>
  );
}
