import { Card, message } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import customerDataService from "data-services/customer/customer-data.service";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import languageService from "services/language/language.service";
import { handleDownloadFile } from "utils/helpers";
import TableCustomer from "./components/table-customer.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import { ExportOutlinedIcon, ImportOutlinedIcon } from "constants/icons.constants";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import theme from "theme";

/**
 * Page Customer Management
 * description: page manage customer primary template
 */
export default function CustomerManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    customerManagement: t("customer.title"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    btnAddNew: t("button.addNew"),
    btnExport: t("button.export"),
    import: t("button.import"),
  };
  const createCustomerPage = "/customer/create-new";

  const gotoAddNewCustomerPage = () => {
    history.push(createCustomerPage);
  };

  const handleExportAllCustomer = async () => {
    try {
      const languageCode = languageService.getLang();
      const response = await customerDataService.exportAllCustomersAsync(languageCode);
      handleDownloadFile(response);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.customerManagement}
        permissionCreate={PermissionKeys.CREATE_CUSTOMER}
        onCreate={() => gotoAddNewCustomerPage(true)}
        listButtons={[
          <FnbButton
            variant="secondary"
            text={pageData.import}
            onClick={() => history.push("/customer/import")}
            iconHeader={<ImportOutlinedIcon />}
            permission={PermissionKeys.CREATE_CUSTOMER}
          />,
          <FnbButton
            variant="secondary"
            text={pageData.btnExport}
            onClick={handleExportAllCustomer}
            iconHeader={<ExportOutlinedIcon height={20} width={20} fill={theme.colors.primary[100]} />}
            permission={PermissionKeys.VIEW_CUSTOMER}
          />,
        ]}
      />
      <div className="clearfix"></div>
      <Card className="fnb-card-custom">
        <TableCustomer />
      </Card>
    </>
  );
}
