import { Space } from "antd";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SubscriptionComponent } from "./components/subscription.component";
import UserInformationComponent from "./components/user-information.component";
import { useHistory, useLocation } from "react-router-dom";

import "./my-account.scss";
import { hasPermission } from "utils/helpers";
import { PermissionKeys } from "constants/permission-key.constants";
import { DefaultConstants } from "constants/string.constants";

const KEY_TAB_PANE_MY_ACCOUNT = {
  ACCOUNT: "account",
  SUBSCRIPTION: "subscription",
};

const INDEX_KEY_TAB_PANE_MY_ACCOUNT = {
  [KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT]: 0,
  [KEY_TAB_PANE_MY_ACCOUNT.SUBSCRIPTION]: 1,
};

const DEFAULT_SCREEN = KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT;

export default function MyAccountPage(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const _searchParams = new URLSearchParams(location?.search);
  const state = useSelector((state) => state);
  const [userInformation, setUserInformation] = useState(null);
  const [step, setStep] = useState(null);
  const [activeScreen, setActiveScreen] = useState(KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT);
  const hasSubscriptionPermission =
    state.session?.auth?.user?.accountType === DefaultConstants.ADMIN_ACCOUNT ||
    hasPermission(PermissionKeys.SUPER_ADMIN) ||
    hasPermission(PermissionKeys.ADMIN);

  const pageData = {
    titlePage: t("myAccount.title"),
    tabNameAccount: t("myAccount.tabName.account"),
    subscription: t("myAccount.tabName.subscription"),
    billing: t("myAccount.tabName.billing"),
  };

  let screens = [
    {
      name: pageData.tabNameAccount,
      key: KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT,
      component: <UserInformationComponent />,
    },
  ];

  if (hasSubscriptionPermission) {
    screens.push({
      name: pageData.billing,
      key: KEY_TAB_PANE_MY_ACCOUNT.SUBSCRIPTION,
      component: <SubscriptionComponent userInformation={userInformation} t={t} step={step != null ? 0 : null} />,
    });
  }

  useEffect(() => {
    let userInfo = state.session?.auth?.user;
    setUserInformation(userInfo);
  }, []);

  useEffect(() => {
    const __tabValue = _searchParams.get("tab");
    const __stepValue = _searchParams.get("step");
    setStep(__stepValue);

    if (__tabValue === KEY_TAB_PANE_MY_ACCOUNT.SUBSCRIPTION && hasSubscriptionPermission) {
      setActiveScreen(KEY_TAB_PANE_MY_ACCOUNT.SUBSCRIPTION);
    } else {
      setStep(null);
      setActiveScreen(KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT);
    }
  }, [_searchParams]);

  const handleChangeScreen = (activeKey) => {
    if (activeKey === KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT) {
      setStep(null);
    }
    goToStep(activeKey);
  };

  const goToStep = (step) => {
    const searchParams = new URLSearchParams(location?.search);
    const stepValue = searchParams.get("step");
    setStep(stepValue);
    searchParams.set("tab", step);
    searchParams.delete("step");
    history.push(`/my-account?${searchParams.toString()}`);
  };

  return (
    <>
      <Space className="w-100" size={12} direction="vertical">
        <FnbHeadingPage title={pageData.titlePage} />
        <FnbTabPane
          screens={screens}
          activeScreen={activeScreen}
          indexKeyTabPane={INDEX_KEY_TAB_PANE_MY_ACCOUNT}
          onChange={handleChangeScreen}
          defaultScreen={DEFAULT_SCREEN}
          firstKeyTabPane={KEY_TAB_PANE_MY_ACCOUNT.ACCOUNT}
          lastKeyTabPane={KEY_TAB_PANE_MY_ACCOUNT.SUBSCRIPTION}
          setTabPaneDisplayFullWidth={true}
          maxWidthItemConfig={1200}
        />
      </Space>
    </>
  );
}
