import { Button, Col, Collapse, Image, Row, message } from "antd";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbNotifyDialog } from "components/fnb-notify-dialog/fnb-notify-dialog.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { NoDataFoundComponent } from "components/no-data-found/no-data-found.component";
import FnbSwitch from "components/switch";
import { NewDragIcon, PaymentVector, icons } from "constants/icons.constants";
import { orderPackagePaymentMethod, paymentMethod } from "constants/payment-method.constants";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import paymentDataService from "data-services/payment/payment-data.service";
import { useEffect, useLayoutEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import BankTransferPaymentConfigComponent from "./components/banktransfer-payment-config.component";
import { CashPaymentConfigComponent } from "./components/cash-payment-config.component";
import { MoMoPaymentConfigComponent } from "./components/momo-payment-config.component";
import { MPOSPaymentConfigComponent } from "./components/mpos-payment-config.component";
import { NewMethodDialogComponent } from "./components/new-method-dialog.component";
import PayPalPaymentConfig from "./components/paypal-payment-config.component";
import { PersonalPaymentConfigComponent } from "./components/personal-payment-method.component";
import { VisaMasterPaymentConfigComponent } from "./components/visa-master-payment-config.component";
import { VNPayPaymentConfigComponent } from "./components/vnpay-payment-config.component";
import "./payment-method.scss";
import { useDispatch, useSelector } from "react-redux";
import { getBankInfoAsync, settingsSelector } from "store/modules/settings/settings.reducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { NoInfomationComponent } from "components/no-infomation/no-infomation.component";

export default function PaymentMethodPage(props) {
  const { onChangeForm, isChangeForm, setIsValidPayPal } = props;
  const dispatch = useDispatch();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [nextPayment, setNextPayment] = useState(null);
  const [enterprisePaymentMethods, setEnterprisePaymentMethods] = useState([]);
  const [personalPaymentMethods, setPersonalPaymentMethods] = useState([]);
  const [namePaymentMethods, setNamePaymentMethods] = useState(false);
  const [showAddNewMethodForm, setShowAddNewMethodForm] = useState(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [isOpenLeaveDialog, setIsOpenLeaveDialog] = useState(false);
  const isMobileSize = useMediaQuery({ maxWidth: 575 });
  const { t } = useTranslation();
  const settings = useSelector(settingsSelector);

  const pageData = {
    paymentMethod: t("payment.paymentMethod"),
    enterprisePaymentMethod: t("payment.throughThirdParty"),
    personalPaymentMethod: t("payment.byOtherMeans"),
    tooltipEnterprise: t("payment.tooltipEnterprise"),
    tooltipPersonal: t("payment.tooltipPersonal"),
    addNewMethodButton: t("payment.addNewMethodButton"),
    noDataFound: t("payment.noDataFound"),
    settings: t("payment.settings"),
    activated: t("payment.activated"),
    notIntegrated: t("payment.notIntegrated"),
    updateSuccess: t("payment.updateSuccess"),
    updateFailed: t("payment.updateFailed"),
    personalUpdateSuccess: t("payment.personalUpdateSuccess"),
    notificationTitle: t("form.notificationTitle"),
    notificationMessage: t("payment.notificationMessage", { method_name: selectedPaymentMethod?.name }),
    personalPaymentMethodEmpty: t("payment.personalPaymentMethodEmpty"),
    tagEnterprise: t("payment.tagEnterprise"),
    button: {
      stop: t("button.stop"),
      ignore: t("button.ignore"),
    },
    paypal: t("package.payment.paypal"),
    BankTransferNotification: t("payment.bankTransferNotification"),

    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };

  const paymentMethodTypeEnum = {
    enterprise: 1,
    personal: 2,
  };

  useEffect(() => {
    getInitData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // Check exist bank transfer payment
    const bankTransferPayment = enterprisePaymentMethods?.find(
      (payment) => payment?.enumId === paymentMethod.BankTransfer,
    );
    if (settings?.bankAccountInfo && bankTransferPayment && bankTransferPayment?.isDisabled) {
      const newEnterprisePaymentMethods = enterprisePaymentMethods?.map((payment) =>
        payment?.enumId === paymentMethod.BankTransfer ? { ...payment, isDisabled: false } : payment,
      );
      setEnterprisePaymentMethods(newEnterprisePaymentMethods);
    }
  }, [settings?.bankAccountInfo]);

  const getInitData = async (isFistLoad = false, isReloadSelectedMethod = false) => {
    const storeBankAccountResponse = unwrapResult(await dispatch(getBankInfoAsync()));
    let isNotHaveStoreBankAccount = storeBankAccountResponse === null || storeBankAccountResponse === undefined;
    paymentConfigDataService.getAllStorePaymentConfigAsync().then((resData) => {
      if (resData) {
        let { paymentMethods, personalPaymentMethods } = resData;

        paymentMethods = paymentMethods.map((method) => {
          method.isDisabled = method.enumId === paymentMethod.BankTransfer && isNotHaveStoreBankAccount;
          return method;
        });
        setEnterprisePaymentMethods(paymentMethods);
        setPersonalPaymentMethods(personalPaymentMethods);
        setNamePaymentMethods(
          paymentMethods.map((method) => method.name).concat(personalPaymentMethods.map((method) => method.name)),
        );
        if (isFistLoad) {
          onChangeCollapse(paymentMethodTypeEnum.enterprise.toString(), paymentMethods);
        }

        if (isReloadSelectedMethod) {
          let newSelectedPaymentMethod = null;
          if (selectedPaymentMethod.enumId !== paymentMethod.Personal) {
            newSelectedPaymentMethod = enterprisePaymentMethods.find(
              (method) => method.id === selectedPaymentMethod.id,
            );
          } else {
            newSelectedPaymentMethod = personalPaymentMethods.find((method) => method.id === selectedPaymentMethod.id);
          }
          setSelectedPaymentMethod(newSelectedPaymentMethod);
        }
      }
    });
  };

  const onActivePaymentMethod = (paymentMethod, isActive) => {
    const enablePaymentConfigRequest = {
      enumId: paymentMethod.enumId,
      isActive: isActive,
    };
    paymentConfigDataService.enablePaymentConfigAsync(enablePaymentConfigRequest).then((success) => {
      if (success === true) {
        message.success(pageData.updateSuccess);
        if (paymentMethod.paymentConfigs[0]) {
          paymentMethod.paymentConfigs[0].isActivated = isActive;
          setSelectedPaymentMethod(paymentMethod);
        }
      } else {
        message.error(pageData.updateFailed);
      }
      getInitData();
    });
  };

  const activePaymentMethodWithoutSelect = (paymentMethod, isActive) => {
    const enablePaymentConfigRequest = {
      enumId: paymentMethod.enumId,
      isActive: isActive,
    };
    paymentConfigDataService.enablePaymentConfigAsync(enablePaymentConfigRequest).then((success) => {
      if (success === true) {
        message.success(pageData.updateSuccess);
        if (paymentMethod.paymentConfigs[0]) {
          paymentMethod.paymentConfigs[0].isActivated = isActive;
        }
      } else {
        message.error(pageData.updateFailed);
      }
      getInitData();
    });
  };

  const onActivePersonalPaymentMethod = (paymentMethod, isActive) => {
    const enablePersonalPaymentMethodRequest = {
      id: paymentMethod.id,
      isActive: isActive,
    };
    paymentDataService.enablePersonalPaymentMethodAsync(enablePersonalPaymentMethodRequest).then((success) => {
      if (success === true) {
        message.success(pageData.personalUpdateSuccess);
        let newSelectedPaymentMethod = selectedPaymentMethod;
        newSelectedPaymentMethod.isActive = isActive;
        setSelectedPaymentMethod(newSelectedPaymentMethod);
      } else {
        message.error(pageData.updateFailed);
      }
      getInitData();
    });
  };

  const onClickSwitchPersonal = (paymentMethod, isActive) => {
    if (isActive) {
      onActivePersonalPaymentMethod(paymentMethod, isActive);
    } else {
      setIsModalNotificationVisible(true);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => (item.position = index));
    return result;
  };

  const onDragEnd = (result, paymentMethodTypeEnumId) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (paymentMethodTypeEnumId === paymentMethodTypeEnum.enterprise) {
      let newPositionEnterprisePaymentMethods = reorder(
        enterprisePaymentMethods.filter(
          (payment) => payment.enumId !== paymentMethod.Cash && payment.enumId !== paymentMethod.CreditDebitCard,
        ),
        result.source.index,
        result.destination.index,
      );
      newPositionEnterprisePaymentMethods = [
        enterprisePaymentMethods.find((payment) => payment.enumId === paymentMethod.Cash),
        enterprisePaymentMethods.find((payment) => payment.enumId === paymentMethod.CreditDebitCard),
        ...newPositionEnterprisePaymentMethods,
      ];
      setEnterprisePaymentMethods(newPositionEnterprisePaymentMethods);
      paymentDataService
        .updatePositionEnterprisePaymentMethodAsync({
          positionEnterprisePaymentMethods: newPositionEnterprisePaymentMethods,
        })
        .then((success) => {
          if (!success) {
            setEnterprisePaymentMethods(enterprisePaymentMethods);
          }
        })
        .catch(() => {
          setEnterprisePaymentMethods(enterprisePaymentMethods);
        });
    }
    if (paymentMethodTypeEnumId === paymentMethodTypeEnum.personal) {
      const newPositionPersonalPaymentMethods = reorder(
        personalPaymentMethods,
        result.source.index,
        result.destination.index,
      );
      setPersonalPaymentMethods(newPositionPersonalPaymentMethods);
      paymentDataService
        .updatePositionPersonalPaymentMethodAsync({ positionPersonalPaymentMethods: newPositionPersonalPaymentMethods })
        .then((success) => {
          if (!success) {
            setPersonalPaymentMethods(personalPaymentMethods);
          }
        })
        .catch(() => {
          setPersonalPaymentMethods(personalPaymentMethods);
        });
    }
  };

  const onChangeCollapse = (key, localEnterprisePaymentMethods = enterprisePaymentMethods) => {
    switch (key) {
      case paymentMethodTypeEnum.enterprise.toString(): {
        setSelectedPaymentMethod();
        break;
      }
      case paymentMethodTypeEnum.personal.toString(): {
        setSelectedPaymentMethod();
        break;
      }
      default:
        break;
    }
  };

  const onChangeMethod = (value) => {
    if (isChangeForm) {
      setIsOpenLeaveDialog(true);
      setNextPayment(value);
    } else {
      setSelectedPaymentMethod(value);
    }
  };

  const renderPaymentMethods = () => {
    const renderEnterpriseMethods = () => {
      return (
        <>
          <DragDropContext onDragEnd={(list) => onDragEnd(list, paymentMethodTypeEnum.enterprise)}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="list-enterprise-method"
                  style={{
                    minHeight:
                      56 *
                      enterprisePaymentMethods?.filter(
                        (method) =>
                          method.enumId !== paymentMethod.Cash && method.enumId !== paymentMethod.CreditDebitCard,
                      ).length,
                  }}
                >
                  {enterprisePaymentMethods
                    ?.filter(
                      (method) =>
                        method.enumId !== paymentMethod.Cash && method.enumId !== paymentMethod.CreditDebitCard,
                    )
                    .map((method, index) => {
                      const config = method?.paymentConfigs[0];
                      const currentSelected = selectedPaymentMethod?.id === method?.id ? "active" : "";
                      const switchOn = config?.isActivated;
                      return (
                        <Draggable key={method.id} draggableId={method.id} index={index}>
                          {(provided) =>
                            method?.isDisabled ? (
                              <FnbTooltip
                                placement="bottomRight"
                                overlayStyle={{ maxWidth: "302px" }}
                                title={pageData.BankTransferNotification}
                              >
                                <Row
                                  className={"payment-disable drag-item"}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Row className="payment-item">
                                    <Col span={20} className="col-title">
                                      <Image
                                        preview={false}
                                        width={32}
                                        height={32}
                                        src={method?.icon}
                                        fallback={icons.paymentDefaultIcon}
                                      />
                                      <span className="title-center">{t(method?.name)}</span>
                                    </Col>
                                    <Col span={4} className="col-switch">
                                      {method.enumId !== paymentMethod.MoMo && <FnbSwitch checked={false} />}
                                    </Col>
                                  </Row>
                                </Row>
                              </FnbTooltip>
                            ) : (
                              <Row
                                className={`pointer ${currentSelected} drag-item`}
                                onClick={() => onChangeMethod(method)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Row className="payment-item">
                                  <Col span={24} className="col-title">
                                    <NewDragIcon className="drag-icon" width={20} height={20} />
                                    <Image
                                      className={method.enumId === paymentMethod.BankTransfer ? "white-if-select" : ""}
                                      preview={false}
                                      width={32}
                                      height={32}
                                      src={method?.icon}
                                      fallback={icons.paymentDefaultIcon}
                                    />
                                    <FnbTooltip
                                      className="title-center"
                                      onlyShowWhenEllipsis={true}
                                      maxWidthContent={"calc(100% - 40px - 32px - 28px)"}
                                    >
                                      {t(method?.name)}
                                    </FnbTooltip>
                                    {method.enumId !== paymentMethod.MoMo && (
                                      <FnbSwitch
                                        checked={switchOn}
                                        onChange={(value) => {
                                          onActivePaymentMethod(method, value);
                                        }}
                                      />
                                    )}
                                  </Col>
                                </Row>
                                {isMobileSize && selectedPaymentMethod?.id === method.id && (
                                  <Col sm={12} md={16} className="payment-method-setting">
                                    <Col span={24} className="p-0 payment-config">
                                      {renderPaymentConfig()}
                                    </Col>
                                  </Col>
                                )}
                              </Row>
                            )
                          }
                        </Draggable>
                      );
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      );
    };

    const renderPersonalMethods = () => {
      const cashMethod = enterprisePaymentMethods?.find((method) => method.enumId === paymentMethod.Cash);
      return (
        <div className="list-personal-method">
          <Row
            className={`pointer ${selectedPaymentMethod?.id === cashMethod?.id ? "active" : ""} un-drag-item`}
            onClick={() => onChangeMethod(cashMethod)}
          >
            <Row className="payment-item">
              <Col span={24} className="col-title">
                <Image
                  className="white-if-select"
                  preview={false}
                  width={32}
                  height={32}
                  src={cashMethod?.icon}
                  fallback={icons.paymentDefaultIcon}
                />
                <FnbTooltip
                  className="title-center"
                  onlyShowWhenEllipsis={true}
                  maxWidthContent={"calc(100% - 40px - 32px - 28px)"}
                >
                  {t(cashMethod?.name)}
                </FnbTooltip>
                <FnbSwitch
                  checked={cashMethod?.paymentConfigs[0]?.isActivated}
                  onChange={(value) => {
                    onActivePaymentMethod(cashMethod, value);
                  }}
                />
              </Col>
            </Row>
          </Row>
          <DragDropContext onDragEnd={(list) => onDragEnd(list, paymentMethodTypeEnum.personal)}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {personalPaymentMethods?.map((method, index) => {
                    method.enumId = paymentMethod.Personal;
                    const currentSelected = selectedPaymentMethod?.id === method?.id ? "active" : "";
                    const switchOn = method?.isActive;
                    return (
                      <Draggable key={method.id} draggableId={method.id} index={index}>
                        {(provided) => (
                          <Row
                            className={`pointer ${currentSelected} drag-item`}
                            onClick={() => onChangeMethod(method)}
                            draggable
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row className="payment-item">
                              <Col span={24} className="col-title">
                                <NewDragIcon className="drag-icon" width={20} height={20} />
                                <Image
                                  preview={false}
                                  width={32}
                                  height={32}
                                  src={method?.logo}
                                  fallback={icons.paymentDefaultIcon}
                                />
                                <FnbTooltip
                                  className="title-center"
                                  onlyShowWhenEllipsis={true}
                                  maxWidthContent={"calc(100% - 40px - 32px - 28px)"}
                                >
                                  {method?.name}
                                </FnbTooltip>
                                <FnbSwitch
                                  checked={switchOn}
                                  onChange={(value) => {
                                    onClickSwitchPersonal(method, value);
                                  }}
                                />
                              </Col>
                            </Row>
                            {isMobileSize && selectedPaymentMethod?.id === method.id && (
                              <Col sm={12} md={16} className="payment-method-setting">
                                <Col span={24} className="p-0 payment-config">
                                  {renderPaymentConfig()}
                                </Col>
                              </Col>
                            )}
                          </Row>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      );
    };

    return (
      <div className="payment-methods-config-container">
        {
          <>
            <div className="background-component">
              <Button className="addNewButton" onClick={() => setShowAddNewMethodForm(true)}>
                {pageData.addNewMethodButton}
              </Button>
            </div>
            <div className="background-component">
              <Collapse
                defaultActiveKey={[paymentMethodTypeEnum.enterprise.toString()]}
                className="fnb-collapse"
                accordion
                onChange={(key) => onChangeCollapse(key)}
                expandIcon={({ expanded }) => (expanded ? <PaymentVector /> : <PaymentVector />)}
              >
                <Collapse.Panel
                  key={paymentMethodTypeEnum.enterprise}
                  header={
                    <div className="collapse-dropdown">
                      <span>{pageData.enterprisePaymentMethod}</span>
                    </div>
                  }
                  forceRender={true}
                >
                  <div>{renderEnterpriseMethods()}</div>
                </Collapse.Panel>
              </Collapse>
            </div>
            <div className="background-component">
              <Collapse
                defaultActiveKey={[paymentMethodTypeEnum.personal.toString()]}
                className="fnb-collapse"
                accordion
                onChange={(key) => onChangeCollapse(key)}
                expandIcon={({ expanded }) => (expanded ? <PaymentVector /> : <PaymentVector />)}
              >
                <Collapse.Panel
                  key={paymentMethodTypeEnum.personal}
                  header={
                    <div className="collapse-dropdown">
                      <span>{pageData.personalPaymentMethod}</span>
                    </div>
                  }
                  forceRender={true}
                >
                  <div>{renderPersonalMethods()}</div>
                </Collapse.Panel>
              </Collapse>
            </div>
          </>
        }
      </div>
    );
  };

  const onChangeMomoBusiness = (value, method) => {
    if (method?.paymentConfigs[0]?.isAuthenticated) {
      onActivePaymentMethod(method, value);
    } else {
      setEnterprisePaymentMethods((prePayment) => {
        return prePayment.map((payment) => {
          if (payment.id === method.id) {
            return {
              ...payment,
              paymentConfigs: [
                {
                  ...payment.paymentConfigs[0],
                  isActivated: value,
                },
              ],
            };
          }
          return payment;
        });
      });
      if (method?.name?.toUpperCase() === orderPackagePaymentMethod.paypal) onActivePaymentMethod(method, value);
      onChangeForm(false);
    }
  };

  const onChangeMoMoCaptureWallet = (value, method) => {
    onActivePaymentMethod(method, value);
  };

  const onChangeCreditCard = (value) => {
    let creditCardMethod = enterprisePaymentMethods.find((payment) => payment.enumId === paymentMethod.CreditDebitCard);
    let momoMethod = enterprisePaymentMethods.find((payment) => payment.enumId === paymentMethod.MoMo);
    const enablePaymentConfigRequest = {
      enumId: creditCardMethod?.enumId,
      isActive: value,
    };
    paymentConfigDataService.enablePaymentConfigAsync(enablePaymentConfigRequest).then((success) => {
      if (success === true) {
        message.success(pageData.updateSuccess);
        if (paymentMethod.paymentConfigs[0]) {
          paymentMethod.paymentConfigs[0].isActivated = value;
          setSelectedPaymentMethod(momoMethod);
        }
      } else {
        message.error(pageData.updateFailed);
      }
      getInitData();
    });
  };

  const renderPaymentConfig = () => {
    let paymentConfigComponent = (
      <Row className="default-payment-config">
        <div className="content">
          <NoInfomationComponent />
        </div>
      </Row>
    );
    let creditCardMethod = enterprisePaymentMethods.find((payment) => payment.enumId === paymentMethod.CreditDebitCard);
    switch (selectedPaymentMethod?.enumId) {
      case paymentMethod.MoMo:
        paymentConfigComponent = (
          <MoMoPaymentConfigComponent
            onChangeForm={onChangeForm}
            isCheckMomoBusiness={
              enterprisePaymentMethods.find((payment) => payment.enumId === paymentMethod.MoMo)?.paymentConfigs[0]
                ?.isActivated
            }
            onChangeMomoBusiness={(value) => onChangeMoMoCaptureWallet(value, selectedPaymentMethod)}
            onCompleted={() => {
              let newSelectedPaymentMethod = selectedPaymentMethod;
              if (newSelectedPaymentMethod.paymentConfigs[0]) {
                newSelectedPaymentMethod.paymentConfigs[0].isActivated = true;
                setSelectedPaymentMethod(newSelectedPaymentMethod);
              }
              getInitData();
            }}
            initData={selectedPaymentMethod}
            isCheckCreditCard={creditCardMethod?.paymentConfigs[0]?.isActivated}
            onChangeCreditCard={(value) => onChangeCreditCard(value)}
            initDataCreditCard={creditCardMethod}
            IsUnActivated={!selectedPaymentMethod.paymentConfigs[0]?.isActivated}
          />
        );
        break;
      case paymentMethod.ZaloPay:
        /// TODO: Implement ZaloPay payment config component
        break;
      case paymentMethod.CreditDebitCard:
        paymentConfigComponent = (
          <VisaMasterPaymentConfigComponent
            onCompleted={() => {
              getInitData();
            }}
            initData={selectedPaymentMethod}
          />
        );
        break;
      case paymentMethod.Cash:
        /// TODO: Implement cash payment config component
        paymentConfigComponent = <CashPaymentConfigComponent initData={selectedPaymentMethod} />;
        break;
      case paymentMethod.VNPay:
        paymentConfigComponent = (
          <VNPayPaymentConfigComponent
            onCompleted={() => {
              getInitData();
            }}
            initData={selectedPaymentMethod}
          />
        );
        break;
      case paymentMethod.BankTransfer:
        paymentConfigComponent = (
          <BankTransferPaymentConfigComponent
            onChangeForm={onChangeForm}
            IsUnActivated={!selectedPaymentMethod.paymentConfigs[0]?.isActivated}
          />
        );
        break;
      case paymentMethod.Personal:
        paymentConfigComponent = (
          <PersonalPaymentConfigComponent
            onChangeForm={onChangeForm}
            onCompleted={() => {
              getInitData(false, true);
            }}
            namePaymentMethods={namePaymentMethods.filter((name) => name !== selectedPaymentMethod.name)}
            initData={selectedPaymentMethod}
          />
        );
        break;
      case paymentMethod.mPos:
        paymentConfigComponent = (
          <MPOSPaymentConfigComponent
            onChangeForm={onChangeForm}
            onCompleted={() => {
              getInitData(false, true);
            }}
            initData={selectedPaymentMethod}
            IsUnActivated={!selectedPaymentMethod.paymentConfigs[0]?.isActivated}
          />
        );
        break;
      case paymentMethod.PayPal:
        paymentConfigComponent = (
          <PayPalPaymentConfig
            onChangeForm={onChangeForm}
            onCompleted={() => {
              getInitData(false, true);
            }}
            initData={selectedPaymentMethod}
            IsUnActivated={!selectedPaymentMethod.paymentConfigs[0]?.isActivated}
            setIsValidPayPal={setIsValidPayPal}
          />
        );
        break;
      default:
        break;
    }

    return (
      <div className="component-payment-config">
        <div className="payment-method-content">{paymentConfigComponent}</div>
      </div>
    );
  };

  const renderDialog = () => {
    if (showAddNewMethodForm) {
      return (
        <NewMethodDialogComponent
          isModalVisible={showAddNewMethodForm}
          namePaymentMethods={namePaymentMethods}
          handleCancel={() => handleCancelDialog()}
          handleSuccess={() => handleSuccessDialog()}
        />
      );
    } else {
      return <></>;
    }
  };

  const handleCancelDialog = () => {
    setShowAddNewMethodForm(false);
  };

  const handleSuccessDialog = () => {
    getInitData();
    handleCancelDialog();
  };

  const onOkDeleteConfirm = () => {
    let isResetMomo =
      selectedPaymentMethod?.enumId === paymentMethod.MoMo && !selectedPaymentMethod.paymentConfigs[0]?.isAuthenticated;
    if (isResetMomo) {
      setEnterprisePaymentMethods((prePayment) => {
        return prePayment.map((payment) => {
          if (payment.id === selectedPaymentMethod.id) {
            return {
              ...payment,
              paymentConfigs: [
                {
                  ...payment.paymentConfigs[0],
                  isActivated: false,
                },
              ],
            };
          }
          return payment;
        });
      });
    }
    if (selectedPaymentMethod?.enumId === paymentMethod.PayPal) {
      activePaymentMethodWithoutSelect(selectedPaymentMethod, false);
    }
    setSelectedPaymentMethod(nextPayment);
    setIsOpenLeaveDialog(false);
  };

  return (
    <>
      <Row gutter={24} className="payment-method">
        <Col sm={12} md={6} className="payment-method-choice h-100">
          {renderPaymentMethods()}
        </Col>
        {!isMobileSize && (
          <Col sm={12} md={18} className="payment-method-setting">
            <Col span={24} className="p-0 payment-config">
              {renderPaymentConfig()}
            </Col>
          </Col>
        )}
      </Row>
      {renderDialog()}
      <ConfirmDialogComponent
        skipPermission={true}
        onCancel={() => setIsOpenLeaveDialog(false)}
        onOk={onOkDeleteConfirm}
        visible={isOpenLeaveDialog}
      />
      <FnbNotifyDialog
        className="notify-dialog"
        title={pageData.notificationTitle}
        open={isModalNotificationVisible}
        okText={pageData.button.stop}
        cancelText={pageData.button.ignore}
        onOk={() => {
          onActivePersonalPaymentMethod(selectedPaymentMethod, false);
          setIsModalNotificationVisible(false);
        }}
        onCancel={() => {
          setIsModalNotificationVisible(false);
        }}
        content={() => {
          return (
            <>
              <div
                className="text-content-notification-product-dependencies"
                dangerouslySetInnerHTML={{
                  __html: pageData.notificationMessage,
                }}
              />
            </>
          );
        }}
      />
    </>
  );
}
