import { Button, Card, Col, Form, message, Modal, Popover, Row } from "antd";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { ArrowDropDownIcon } from "constants/icons.constants";
import { InventoryHistoryAction, ListInventoryHistoryAction } from "constants/inventory-history-action.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import inventoryHistoryDataService from "data-services/inventory/inventory-data.service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import languageService from "services/language/language.service";
import {
  executeAfter,
  formatDate,
  formatNumberDecimalOrInteger,
  handleDownloadFile,
  hasPermission,
} from "utils/helpers";
import { FilterPopover } from "./filter-popover.component";
import "./index.scss";
import "./table-inventory-history.component.scss";

const TableInventoryHistoryDefaultComponent = (props) => {
  const [t] = useTranslation();
  const history = useHistory();
  const MAX_LENGTH_SEARCH = 100;
  const pageData = {
    btnFilter: t("button.filter"),
    table: {
      searchPlaceholder: t("inventoryHistory.searchPlaceHolder"),
      time: t("order.time"),
      material: t("material.material"),
      sku: t("material.inventory.sku"),
      category: t("form.category"),
      branch: t("purchaseOrder.branch"),
      unit: t("table.unit"),
      previousQuantity: t("inventoryHistory.previousQuantity"),
      change: t("inventoryHistory.change"),
      remain: t("inventoryHistory.remain"),
      action: t("inventoryHistory.action"),
      note: t("form.note"),
      reference: t("inventoryHistory.reference"),
      createdBy: t("table.createdBy"),
    },
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    today: t("optionDatetime.today"),
    yesterday: "dashboard.compareDate.yesterday",
    buttonIGotIt: t("form.buttonIGotIt"),
    notificationTitle: t("form.notificationTitle"),
    materialIsDeleted: t("inventoryHistory.materialIsDeleted"),
    btnExport: t("button.export"),
  };

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();
  const [countFilter, setCountFilter] = useState(0);
  const [branches, setBranches] = useState([]);
  const [materialCategories, setMaterialCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const [dataFilter, setDataFilter] = useState({});
  const [keySearchFilter, setKeySearchFilter] = useState("");
  const filterPopoverRef = React.useRef();
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [categoryFilterOptions, setCategoryFilterOptions] = useState([]);
  const [materialFilters, setMaterialFilters] = useState([]);

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.time,
        dataIndex: "time",
        align: "left",
        render: (_, record) => {
          return (
            <>
              <span>{formatDate(record?.time, DateFormat.HH_mm_DD_MMM_YYYY)}</span>
            </>
          );
        },
      },
      {
        title: pageData.table.material,
        dataIndex: "materialName",
        ellipsis: true,
        render: (_, record) => {
          if (record?.isDeleted) {
            return (
              <div
                onClick={() => {
                  setShowModalNotification(true);
                }}
              >
                <FnbTooltip onlyShowWhenEllipsis={true} title={record?.materialName}>
                  <span>{record.materialName}</span>
                </FnbTooltip>
              </div>
            );
          }
          return (
            <div>
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.materialName}>
                <FnbTypography.Link
                  to={`/inventory/material/detail/${record?.materialId}`}
                  target="_blank"
                  text={record.materialName}
                ></FnbTypography.Link>
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.table.sku,
        dataIndex: "sku",
      },
      {
        title: pageData.table.category,
        dataIndex: "category",
        ellipsis: true,
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.category}>
              <span>{record.category}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.branch,
        dataIndex: "branchName",
        ellipsis: true,
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.branchName}>
              <span>{record.branchName}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.unit,
        dataIndex: "baseUnitName",
      },
      {
        title: pageData.table.previousQuantity,
        dataIndex: "oldQuantity",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>
            {formatNumberDecimalOrInteger(record?.refund >= 0 ? record?.refund : record?.oldQuantity < 0 ? 0 : record?.oldQuantity)}
          </FnbTooltip>;  
        },
      },
      {
        title: pageData.table.change,
        render: (_, record) => {
          return record?.changeQuantity > 0
            ? "+" + formatNumberDecimalOrInteger(record?.changeQuantity)
            : formatNumberDecimalOrInteger(record?.changeQuantity);
        },
      },
      {
        title: pageData.table.remain,
        dataIndex: "newQuantity",
        width: "10%",
        render: (_, record) => {
          return (
            <>
              <Row>
                <Col
                  span={24}
                  onClick={(e) => {
                    record?.unitConversion?.length > 0 && e.stopPropagation();
                  }}
                >
                  {record?.refund < 0
                    ? 0
                    : record?.newQuantity < 0
                    ? 0
                    : formatNumberDecimalOrInteger(record?.newQuantity)}
                  <Popover
                    placement="bottomLeft"
                    content={record?.unitConversion?.map((unit, index) => {
                      return (
                        <>
                          <Row
                            className="inventory-history-tooltip"
                            style={index % 2 === 0 ? { background: "#F9F8FF" } : { background: "#FFFFFF" }}
                          >
                            <Col span={24}>
                              <div className="unit"> {unit?.unitName}</div>
                              <div className="quantity"> {formatNumberDecimalOrInteger(unit?.quantity)}</div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    overlayClassName="inventory-history-tooltip"
                    trigger={["click"]}
                  >
                    {record?.unitConversion.length > 0 && (
                      <ArrowDropDownIcon width={14} height={14} className="inventory-history-tooltip-arrow-icon" />
                    )}
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      },
      {
        title: pageData.table.action,
        dataIndex: "action",
        align: "center",
        render: (_, record) => {
          return (
            <div className="inventory-history-action-badge">
              <FnbBadge
                style={{ backgroundColor: record?.actionBackgroundColor }}
                textStyle={{ color: record?.actionColor }}
                variant="primary"
                text={t(record?.action)}
              ></FnbBadge>
            </div>
          );
        },
      },
      {
        title: pageData.table.note,
        dataIndex: "note",
        width: "15%",
        render: (_, record) => {
          return (
            <div className="text-overflow-content">
              <FnbTooltip onlyShowWhenEllipsis>{handleSetTranslateForNoteColumn(record)}</FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.table.reference,
        dataIndex: "reference",
        ellipsis: true,
        render: (_, record) => {
          return record.referenceCode && <FnbTooltip onlyShowWhenEllipsis={true}>#{record?.referenceCode}</FnbTooltip>;
        },
      },
      {
        title: pageData.table.createdBy,
        dataIndex: "createdBy",
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(
        page,
        pageSize,
        keySearchFilter,
        dataFilter?.branchId,
        dataFilter?.action,
        dataFilter?.materialId,
        dataFilter?.isActive,
        selectedDate?.startDate,
        selectedDate?.endDate,
        dataFilter?.categoryId,
      );
    },
    onSearch: async (keySearch) => {
      setKeySearchFilter(keySearch);
      executeAfter(500, async () => {
        await fetchDatableAsync(
          1,
          tableSettings.pageSize,
          keySearch,
          dataFilter?.branchId,
          dataFilter?.action,
          dataFilter?.materialId,
          dataFilter?.isActive,
          selectedDate?.startDate,
          selectedDate?.endDate,
          dataFilter?.categoryId,
        );
      });
    },
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  }, []);

  const fetchDatableAsync = async (
    pageNumber,
    pageSize,
    keySearch,
    branchId,
    materialCategoryId,
    unitId,
    isActive,
    startDate,
    endDate,
    categoryId,
  ) => {
    const response = await inventoryHistoryDataService.getInventoryHistoryManagementsAsync(
      pageNumber,
      pageSize,
      keySearch,
      branchId ?? "",
      materialCategoryId ?? "",
      unitId ?? "",
      isActive ?? "",
      startDate ?? "",
      endDate ?? "",
      categoryId ?? "",
    );
    const data = response?.materialInventoryHistories.map((s) => mappingRecordToColumns(s));
    setTotalRecords(response.total);
    setCurrentPageNumber(pageNumber);
    setDataSource(data);
  };

  const mappingRecordToColumns = (item) => {
    return {
      oldQuantity: item?.oldQuantity,
      newQuantity: item?.newQuantity,
      reference: item?.reference,
      referenceCode: item?.referenceCode,
      action: item?.action,
      note: item?.note,
      createdBy: item?.createdBy,
      time: moment.utc(item?.time).local(),
      materialName: item?.materialName,
      baseUnitName: item?.baseUnitName,
      branchName: item?.branchName,
      unitConversion: item?.unitConversion,
      materialId: item?.materialId,
      actionColor: item?.actionColor,
      actionBackgroundColor: item?.actionBackgroundColor,
      orderId: item?.orderId,
      purchaseOrderId: item?.purchaseOrderId,
      shiftId: item?.shiftId,
      transferMaterialId: item?.transferMaterialId,
      isDeleted: item?.isDeleted,
      isActive: item?.isActive,
      actionId: item?.actionId,
      refund: item?.refund,
      sku: item?.sku,
      category: item?.category,
      ingredientRemoveRequestId: item?.ingredientRemoveRequestId,
      updateStockRequestId: item?.updateStockRequestId,
      changeQuantity: item?.changeQuantity,
    };
  };

  const filterComponent = () => {
    return (
      <FilterPopover
        fetchDataMaterials={handleFilter}
        actions={materialCategories}
        branches={branches}
        materials={units}
        category={categoryFilterOptions}
        ref={filterPopoverRef}
        changeCategoryId={changeCategoryId}
      />
    );
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
    setCountFilter(0);

    setDataFilter({
      branchId: "",
      action: "",
      materialId: "",
      isActive: "",
      categoryId: "",
    });

    fetchDatableAsync(
      1,
      tableSettings.pageSize,
      keySearchFilter,
      "",
      "",
      "",
      "",
      selectedDate?.startDate,
      selectedDate?.endDate,
      "",
    );
  };

  const onClickFilterButton = async (event) => {
    var filterData = await inventoryHistoryDataService.getFilterInventoryHistory();
    const { branchFilters, categoryFilters, materialFilters } = filterData;

    if (branchFilters) {
      const allBranchOption = {
        id: "",
        name: t("material.filter.branch.all"),
      };
      const branchOptions = [allBranchOption, ...branchFilters];
      setBranches(branchOptions);
    }

    if (categoryFilters) {
      const allCategoryOption = {
        id: "",
        name: t("material.filter.category.all"),
      };
      const categoryOptions = [allCategoryOption, ...categoryFilters];
      setCategoryFilterOptions(categoryOptions);
    }

    if (materialFilters) {
      const allUnitOption = {
        id: "",
        name: t("inventoryHistory.allMaterials"),
      };
      const unitOptions = [allUnitOption, ...materialFilters];
      setMaterialFilters(unitOptions);
      if (dataFilter?.categoryId) {
        var newMaterials = unitOptions?.filter((item) => !item.id || item.categoryId === dataFilter?.categoryId);
        setUnits(newMaterials);
      } else {
        setUnits(unitOptions);
      }
    }

    const allAction = {
      id: "",
      name: t("inventoryHistory.allAction"),
    };
    const allActionOptions = [allAction, ...ListInventoryHistoryAction];
    setMaterialCategories(allActionOptions);
  };

  const changeCategoryId = (id) => {
    if (!id) {
      setUnits(materialFilters);
    } else {
      var newMaterials = materialFilters?.filter((item) => !item.id || item.categoryId === id);
      setUnits(newMaterials);
    }
  };

  const handleFilter = (data) => {
    setCountFilter(data?.count);
    setDataFilter(data);
    fetchDatableAsync(
      1,
      tableSettings.pageSize,
      keySearchFilter,
      data?.branchId,
      data?.action,
      data?.materialId,
      data?.isActive,
      selectedDate?.startDate,
      selectedDate?.endDate,
      data?.categoryId,
    );
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    fetchDatableAsync(
      1,
      tableSettings.pageSize,
      keySearchFilter,
      dataFilter?.branchId,
      dataFilter?.action,
      dataFilter?.materialId,
      dataFilter?.isActive,
      date?.startDate,
      date?.endDate,
      dataFilter?.categoryId,
    );
  };

  const handleSetTranslateForNoteColumn = (record) => {
    let dataResult = t(record?.note);
    if (record?.actionId === InventoryHistoryAction.TransferGoods) {
      dataResult = t(record?.note, { branchName: record?.branchName });
    }
    return dataResult;
  };

  const exportFile = async () => {
    let languageCode = languageService.getLang();
    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);

    try {
      const response = await inventoryHistoryDataService.exportInventoryHistory(
        keySearchFilter,
        dataFilter?.branchId ?? "",
        dataFilter?.action ?? "",
        dataFilter?.materialId ?? "",
        dataFilter?.isActive ?? "",
        startDate,
        endDate,
        languageCode,
        dataFilter?.categoryId ?? "",
      );
      handleDownloadFile(response);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };

  const onRowClick = (record) => {
    record?.orderId
      ? window.open(`/report/order/detail/${record?.orderId}`, "_blank")
      : record?.purchaseOrderId
      ? window.open(`/inventory/detail-purchase-order/${record?.purchaseOrderId}`, "_blank")
      : record?.shiftId
      ? window.open(`/shift/detail/${record?.shiftId}`, "_blank")
      : record?.transferMaterialId
      ? window.open(`/inventory/transfer-material/view/${record?.transferMaterialId}`, "_blank")
      : record?.ingredientRemoveRequestId
      ? window.open(`/inventory/material-control/detail/${record?.ingredientRemoveRequestId}`, "_blank")
      : record?.updateStockRequestId
      ? window.open(`/inventory/material-control/update-stock/${record?.updateStockRequestId}`, "_blank")
      : record.referenceCode && window.open(`/inventory/inventory-history/detail/${record.id}`, "_blank");
  };

  return (
    <>
      <Form className="form-staff">
        <Card className="fnb-card-custom">
          <Row className="form-staff inventory-history">
            <FnbTable
              className="table-striped-rows table-inventory-history"
              dataSource={dataSource}
              columns={tableSettings.columns}
              pageSize={tableSettings.pageSize}
              currentPageNumber={currentPageNumber}
              total={totalRecords}
              onChangePage={tableSettings.onChangePage}
              numberRecordCurrent={numberRecordCurrent}
              scrollX={2900}
              search={{
                maxLength: MAX_LENGTH_SEARCH,
                placeholder: pageData.table.searchPlaceholder,
                onChange: tableSettings.onSearch,
              }}
              filter={{
                onClickFilterButton: onClickFilterButton,
                totalFilterSelected: countFilter,
                onClearFilter: onClearFilter,
                buttonTitle: pageData.filter,
                component: filterComponent(),
              }}
              calendarComponent={{
                onSelectedDatePicker: onSelectedDatePicker,
                selectedDate: selectedDate
              }}
              exportExcel={
                hasPermission(PermissionKeys.VIEW_INVENTORY_HISTORY) && {
                  buttonTitle: pageData.btnExport,
                  onClick: exportFile,
                }
              }
              onRowClick={(record) => {
                onRowClick(record);
              }}
            />
          </Row>
        </Card>
      </Form>
      <Modal
        width={500}
        className="delete-confirm-modal"
        title={pageData.notificationTitle}
        closeIcon
        visible={showModalNotification}
        footer={(null, null)}
      >
        <Row>
          <div className="table-notification">{pageData.materialIsDeleted}</div>
        </Row>
        <Row className="btn-i-got-it">
          <Button type="primary" onClick={() => setShowModalNotification(false)}>
            {pageData.buttonIGotIt}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default TableInventoryHistoryDefaultComponent;
