import { useTranslation } from "react-i18next";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { ComingSoonTagContainer } from "./styled";

const ComingSoonTag = () => {
  const { t } = useTranslation();
  return (
    <ComingSoonTagContainer>
      <FnbTypography
        variant={theme.typography["c-medium"]}
        color={theme.colors.primary[100]}
        text={t("configuration.comingSoon")}
      />
    </ComingSoonTagContainer>
  );
};

export default ComingSoonTag;
