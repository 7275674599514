import { Image, Select, Typography } from "antd";
import { ArrowDown, CheckedIcon, ImageMaterialDefault } from "constants/icons.constants";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useState } from "react";
import "./fnb-select-single.scss";
import classNames from "classnames";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import "../fnb-select-multiple-product/fnb-select-multiple-product.scss";
import { getUniqueId } from "utils/helpers";
import { id } from "react-date-range/dist/locale";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";

const { Text } = Typography;
/**
 * SelectSingle component custom from antd select
 * @param {option, value, onChange, className, disabled, allowClear, showSearch, placeholder, dropdownRender, style, defaultValue } props
 * option: data select option [], map data [{id: "key", name: "value"}] first
 * other param are used as same as antd select, visit link https://ant.design/components/select/
 * @returns
 */

export function FnbSelectSingle({
  value,
  onChange,
  className,
  option,
  disabled,
  allowClear,
  showSearch = true,
  placeholder,
  dropdownRender,
  style,
  defaultValue,
  onSelect,
  fixed,
  controlId,
  noTranslateOptionName,
  onPopupScroll,
  onFocus,
  onBlur,
  onSearch,
  onClear,
  suffixIcon,
  prefixIcon = undefined,
  showThumbnail,
  popupClassName = "",
  notFoundContent = undefined,
  prefix = undefined, // <span>
  idPrefix = getUniqueId(),
  ...rest
}) {
  const [t] = useTranslation();
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
  const classNameSelect = classNames({
    "fnb-select-single-admin": true,
    [`${className}`]: true,
    "fnb-select-single-admin--show-prefix-icon": prefixIcon !== undefined,
    "fnb-select-single-admin--disabled": disabled,
  });

  const classNamePopup = classNames({
    "fnb-select-multiple-product-dropdown": showThumbnail,
    "fnb-select-single-dropdown-admin": !showThumbnail,
    [`${popupClassName}`]: true,
  });

  const [valueSearch, setValueSearch] = useState("");
  const [maxWidthSelect, setMaxWidthSelect] = useState("100%");

  useEffect(() => {
    if (idPrefix) {
      loadMaxWidthSelect();
    }
  }, [idPrefix]);

  const onSearchSelect = (e) => {
    setValueSearch(e);
    onSearch && onSearch(e);
  };

  const loadMaxWidthSelect = () => {
    let widthPreFix = document.getElementById(idPrefix)?.firstChild?.offsetWidth;
    if (!widthPreFix) {
      widthPreFix = 0;
    }
    return setMaxWidthSelect(`calc(100% - ${widthPreFix}px)`);
  };

  return (
    <>
      {prefix && (
        <div id={idPrefix}>
          {React.cloneElement(prefix, {
            ...prefix?.props,
            className: `fnb-select-single-admin__prefix ${prefix?.props?.className ? prefix?.props?.className : ""}`,
          })}
        </div>
      )}
      <Select
        getPopupContainer={fixed ? null : (trigger) => trigger.parentNode}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        style={style}
        className={classNameSelect}
        popupClassName={classNamePopup}
        notFoundContent={notFoundContent ? notFoundContent : <FnbEmpty />}
        suffixIcon={suffixIcon || <ArrowDown />}
        menuItemSelectedIcon={<CheckedIcon />}
        disabled={disabled}
        showSearch={showSearch}
        allowClear={allowClear}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        optionFilterProp="children"
        showArrow
        filterOption={(input, option) => {
          const newOption = t(option?.name || option?.label);
          const inputStr = input.removeVietnamese();
          const name = newOption?.removeVietnamese();
          return name?.trim().toLowerCase().indexOf(inputStr.trim().toLowerCase()) >= 0;
        }}
        onPopupScroll={onPopupScroll}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearchSelect}
        onClear={onClear}
        {...rest}
      >
        {showThumbnail
          ? option?.map((item) => (
              <Select.Option key={item?.id} value={item?.id} label={item?.name}>
                <div className="product-option-box">
                  <div className="img-box">
                    {item?.thumbnail ? <Image preview={false} src={item?.thumbnail} /> : <ImageMaterialDefault />}
                  </div>
                  <div className="content-product-name">
                    <Text ellipsis={true} className="product-name">
                      {item?.name}
                      {item?.isTopping && (
                        <div className="fnb-chip fnb-chip__option--default" style={{ marginLeft: 12 }}>
                          <Typography style={{ color: "#ff7d07" }}>Topping</Typography>
                        </div>
                      )}
                    </Text>
                    {item?.unit && <Text className="product-unit">{item?.unit}</Text>}
                  </div>
                </div>
              </Select.Option>
            ))
          : option?.map((item) => (
              <Select.Option key={item.id} value={item.id} name={item?.name} disabled={item?.disabled}>
                <FnbTooltip
                  title={noTranslateOptionName ? item?.name : t(item?.name)}
                  maxWidthContent={maxWidthSelect}
                  maxWidth="440px"
                  onlyShowWhenEllipsis
                  disabledStopPropagation={isTouchDevice}
                  hideTooltip={isTouchDevice}
                >
                  <FnbTypography text={noTranslateOptionName ? item?.name : t(item?.name)} />
                </FnbTooltip>
              </Select.Option>
            ))}
      </Select>
      {prefixIcon &&
        React.cloneElement(prefixIcon, {
          ...prefixIcon?.props,
          className: `fnb-select-single-admin__icon-prefix ${
            valueSearch ? "fnb-select-single-admin__icon-prefix--hide" : ""
          } ${prefixIcon?.props?.className ? prefixIcon?.props?.className : ""}`,
        })}
    </>
  );
}
