export const NumberUnitConstant = {
  DEFAULT: 0,
  THOUSAND: 1,
  MILLION: 2,
  BILLION: 3,
  TRILLION: 4,
};

export const NumberUnitLocalization = {
  [NumberUnitConstant.DEFAULT]: "",
  [NumberUnitConstant.THOUSAND]: "report.staff.thousand",
  [NumberUnitConstant.MILLION]: "report.staff.millions",
  [NumberUnitConstant.BILLION]: "report.staff.billions",
  [NumberUnitConstant.TRILLION]: "report.staff.trillions",
};

export const ValueNumberUnit = {
  [NumberUnitConstant.DEFAULT]: 1,
  [NumberUnitConstant.THOUSAND]: 1_000,
  [NumberUnitConstant.MILLION]: 1_000_000,
  [NumberUnitConstant.BILLION]: 1_000_000_000,
  [NumberUnitConstant.TRILLION]: 1_000_000_000_000,
};

export const FirstCharacterNumberUnit = {
  [NumberUnitConstant.DEFAULT]: "",
  [NumberUnitConstant.THOUSAND]: "K",
  [NumberUnitConstant.MILLION]: "M",
  [NumberUnitConstant.BILLION]: "B",
  [NumberUnitConstant.TRILLION]: "T",
};
