import { Col, Row } from "antd";
import InfoCardComponent from "components/info-card/info-card.component";
import { DateFormat } from "constants/string.constants";
import packageDataService from "data-services/package/package-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./package-list.scss";
import { OrderPackageType } from "constants/order-package.contants";
import { PackageStatus } from "constants/packageStatus.constants";
import { EnumOrderPackageStatus, PackageName } from "constants/package.constants";
import { FnbButton } from "components/fnb-button/fnb-button";
import { AddCircleIcon } from "constants/icons.constants";

export default function PackageList(props) {
  const { showBillingComponent } = props;
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [dataExpiry, setDataExpiry] = useState([]);
  const dataDefault = [
    {
      title: "WEB",
      isDataDefault: true,
    },
    {
      title: "Branch",
      childCards: [{ isBranchTitle: true }],
      isDataDefault: true,
    },
    {
      title: "APP",
      isDataDefault: true,
    },
    {
      title: "POS",
      isDataDefault: true,
    },
  ];
  const pageData = {
    no: t("table.no"),
    status: t("table.status"),
    package: t("packageTable.package"),
    subScriptionDate: t("packageTable.subScriptionDate"),
    subScriptionPackage: t("packageTable.subScriptionPackage"),
    expiryDate: "packageTable.expiryDate",
    renew: t("packageTable.renew"),
    viewServicePackage: t("packageTable.viewServicePackage"),
    activeSubscriptionPackage: t("packageTable.activeSubscriptionPackage"),
    branchQuantity: "packageTable.branchQuantity",
    activePlans: t("packageTable.activePlans"),
    morePlans: t("packageTable.morePlans"),
    expired: t("packageTable.expired"),
    newPlans: t("orderPackage.newPlans"),
  };

  useEffect(() => {
    packageDataService.getListPackageOrderAsync().then((res) => {
      if (res.packageOrders) {
        const _data = [];
        const _dataExpiry = [];

        res.packageOrders
          .filter(
            (item) =>
              item?.status !== EnumOrderPackageStatus.CANCELED &&
              item?.packageName !== PackageName.SOCIAL_CHANNEL
          )
          .forEach((item) => {
            const childCards = [];
            if (item?.orderPackageType === OrderPackageType.BRANCH_PURCHASE) {
              childCards.push({
                title: pageData.branchQuantity,
                value: item?.branchQuantity + 1,
                isBranchTitle: true,
              });
            }

            let expiryDate = "";
            if (item?.expiredDate) {
              expiryDate = moment.utc(item?.expiredDate).local().format(DateFormat.DD_MM_YYYY);
            }
            childCards.push({
              title: pageData.expiryDate,
              value: expiryDate,
            });

            const record = {
              title: item?.orderPackageType === OrderPackageType.BRANCH_PURCHASE ? "Branch" : item?.package?.name,
              childCards: childCards,
              isDataExpiry: item?.enumPackageStatusId === PackageStatus.EXPIRED,
              expiryDate: expiryDate,
            };

            if (item?.enumPackageStatusId !== PackageStatus.EXPIRED) {
              _data.push(record);
            } else {
              _dataExpiry.push(record);
            }
          });
        // Filter data by title, group by title and get the max Expiry Date for each unique title in _dataExpiry
        const filteredDataExpiry = Array.from(
          _dataExpiry
            .filter((expiryItem) => !_data.some((dataItem) => dataItem.title === expiryItem.title))
            .reduce((acc, expiryItem) => {
              const expiryDate = moment(
                expiryItem.childCards.find((card) => card.title === pageData.expiryDate)?.value,
                DateFormat.DD_MM_YYYY,
              ).toDate();

              const existingItem = acc.get(expiryItem.title);

              if (
                !existingItem ||
                expiryDate >
                  moment(
                    existingItem.childCards.find((card) => card.title === pageData.expiryDate)?.value,
                    DateFormat.DD_MM_YYYY,
                  ).toDate()
              ) {
                acc.set(expiryItem.title, expiryItem);
              }

              return acc;
            }, new Map()),
        ).map(([_, item]) => item);
        setData(_data);
        setDataExpiry(filteredDataExpiry);
      }
    });
  }, []);

  return (
    <>
      <FnbButton
        className="new-plans"
        type="button"
        variant="text"
        iconHeader={<AddCircleIcon/>}
        text={pageData.newPlans}
        onClick={showBillingComponent}
      />
      {data?.length > 0 && (
        <>
          <Row className="package-title">{pageData.activePlans}</Row>
          <Row gutter={[16, 16]}>
            {data?.map((item, index) => (
              <Col sm={24} xs={24} lg={12} key={`InfoCardComponent-${index}`}>
                <InfoCardComponent data={item} />
              </Col>
            ))}
          </Row>
        </>
      )}

      {dataDefault
        .filter((item) => !data.some((dataItem) => dataItem.title === item.title))
        .filter((item) => !dataExpiry.some((dataItem) => dataItem.title === item.title))?.length > 0 && (
        <>
          <Row className="package-title">{pageData.morePlans}</Row>
          <Row gutter={[16, 16]}>
            {dataDefault
              .filter((item) => !data.some((dataItem) => dataItem.title === item.title))
              .filter((item) => !dataExpiry.some((dataItem) => dataItem.title === item.title))
              ?.map((item, index) => (
                <Col sm={24} xs={24} lg={12} key={`InfoCardComponentMore-${index}`}>
                  <InfoCardComponent data={item} showBillingComponent={showBillingComponent} />
                </Col>
              ))}
          </Row>
        </>
      )}

      {dataExpiry?.length > 0 && (
        <>
          <Row className="package-title">{pageData.expired}</Row>
          <Row gutter={[16, 16]}>
            {dataExpiry?.map((item, index) => (
              <Col sm={24} xs={24} lg={12} key={`InfoCardComponentExpired-${index}`}>
                <InfoCardComponent data={item} showBillingComponent={showBillingComponent} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
}
