import { Row } from "antd";
import { theme1ElementCustomize } from "../../constants/store-web-page.constants";
import Index from "../../index";
import ContactFeedback from "./components/feedback/contact.feedback";
import ContactHeader from "./components/header/contact.header";
import ContactInformation from "./components/information/contact.info";
import "./contact.style.scss";

const ContactContentPage = (props) => {
  const { clickToFocusCustomize } = props;

  const theme1IdScrollViewId = {
    HeaderContact: "themeHeaderContact",
    InformationContact: "themeInformationContact",
    UsContact: "themeUsContact",
  };

  return (
    <div className="contact-content-page">
      <Row
        id={theme1IdScrollViewId.HeaderContact}
        onClick={() => {
          if (clickToFocusCustomize) clickToFocusCustomize(theme1ElementCustomize.HeaderContact);
        }}
      >
        <ContactHeader {...props} />
      </Row>
      <Row
        id={theme1IdScrollViewId.InformationContact}
        onClick={() => {
          if (clickToFocusCustomize) clickToFocusCustomize(theme1ElementCustomize.InformationContact);
        }}
      >
        <ContactInformation {...props} />
      </Row>
      <Row
        id={theme1IdScrollViewId.UsContact}
        onClick={() => {
          if (clickToFocusCustomize) clickToFocusCustomize(theme1ElementCustomize.UsContact);
        }}
      >
        <ContactFeedback {...props} />
      </Row>
    </div>
  );
};

const ContactPage = (props) => {
  return (
    <Index
      {...props}
      contentPage={(_props) => {
        return <ContactContentPage {..._props} />;
      }}
    />
  );
};

export default ContactPage;
