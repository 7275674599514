import BlogDefault from "../../../assets/images/blog-default.png";
import flashSaleProductDefault1Img from "../../../assets/images/flash-sale-product-default-1.png";
import flashSaleProductDefault2Img from "../../../assets/images/flash-sale-product-default-2.png";
import flashSaleProductDefault3Img from "../../../assets/images/flash-sale-product-default-3.png";
import flashSaleProductDefault4Img from "../../../assets/images/flash-sale-product-default-4.png";
import coffeeDefaultImg from "../../../assets/images/coffee-default.png";
import cocktailDefaultImg from "../../../assets/images/cocktail-default.png";
import yogurtDefaultImg from "../../../assets/images/yogurt-default.png";
import teaDefaultImg from "../../../assets/images/tea-default.png";
import creamTeaDefaultImg from "../../../assets/images/cream-tea-default.png";
import supDefaultImg from "../../../assets/images/sup-default.png";
import productDefault1 from "../../../assets/images/hibiscus-vanilla.png";
import productDefault2 from "../../../assets/images/pho_mai_tuyet_hoa_hong.png";
import productDefault4 from "../../../assets/images/tra_atiso_do_hat_boba.png";
import productDefault5 from "../../../assets/images/tra_dua_nhiet_doi.png";
import productDefault3 from "../../../assets/images/tra_vai.png";

export const categoryListDefaultLocal = [
  {
    thumbnail: coffeeDefaultImg,
    title: "Cà Phê",
    description: "Hương vị cà phê chồn thơm ngất ngây người thưởng thức",
    buttonText: "Đặt ngay",
    hyperlink: "#",
  },
  {
    thumbnail: cocktailDefaultImg,
    title: "Đá Xay",
    description: "Hương vị cà phê chồn thơm ngất ngây người thưởng thức",
    buttonText: "Đặt ngay",
    hyperlink: "#",
  },
  {
    thumbnail: yogurtDefaultImg,
    title: "Sữa Chua",
    description: "Hương vị cà phê chồn thơm ngất ngây người thưởng thức",
    buttonText: "Đặt ngay",
    hyperlink: "#",
  },
  {
    thumbnail: teaDefaultImg,
    title: "Các loại Trà",
    description: "Hương vị trà thảo mộc đến từ núi rừng Tây Bắc",
    buttonText: "Đặt ngay",
    hyperlink: "#",
  },
  {
    thumbnail: creamTeaDefaultImg,
    title: "Món Đặc Biệt",
    description: "Hương vị món ăn thanh tao, trang trọng bắt nguồn từ những nguyên liệu đơn giản",
    buttonText: "Đặt ngay",
    hyperlink: "#",
  },
  {
    thumbnail: supDefaultImg  ,
    title: "Hương vị cao cấp",
    description: "Các hương vị siêu hảo hạng đến từ các đầu bếp nổi tiếng",
    buttonText: "Đặt ngay",
    hyperlink: "#",
  },
];

export const bestSellingProductDefaultLocal = [
  {
    id: "f7aaa991-2dc4-420d-b2e0-192f86fbc388",
    name: "Soda phúc bồn tử",
    thumbnail: productDefault1,
    productPrices: [{ priceValue: 25000.0 }, { priceValue: 30000.0 }, { priceValue: 40000.0 }],
  },
  {
    id: "510fdfb9-e19b-4d01-abd8-3b41fb4ec80a",
    thumbnail: productDefault2,
    name: "Sữa chua phô mai",
    productPrices: [{ priceValue: 60000.0 }, { priceValue: 230000.0 }, { priceValue: 120000.0 }],
  },
  {
    id: "c2001596-b809-4cd2-bcec-490fe3301701",
    thumbnail: productDefault3,
    name: "Trà vải",
    productPrices: [{ priceValue: 33000.0 }, { priceValue: 25000.0 }, { priceValue: 30000.0 }],
  },
  {
    id: "56473499-582a-4c15-99c8-4d4b245b1b52",
    thumbnail: productDefault4,
    name: "Trà dâu tằm",
    productPrices: [{ priceValue: 20000.0 }],
  },
  {
    id: "1b7822b0-b996-4d34-90bb-4df93fbf932e",
    thumbnail: productDefault5,
    name: "Trà đào",
    productPrices: [{ priceValue: 25000.0 }],
  },
  {
    id: "085566e2-5bb7-468c-b35a-52a95167769b",
    thumbnail: productDefault2,
    name: "Trà Olong",
    productPrices: [{ priceValue: 19000.0 }, { priceValue: 29000.0 }, { priceValue: 25000.0 }],
  },
  {
    id: "38ba4ae9-1d91-4339-af1a-5aaa55815a5b",
    thumbnail: productDefault4,
    name: "Trà sữa đường nâu",
    productPrices: [{ priceValue: 30000.0 }, { priceValue: 25000.0 }, { priceValue: 20000.0 }],
  },
];

export const storeDiscountDefaultLocal = [
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Giảm 100k món ăn",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Giảm 100k món ăn",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Giảm 100k món ăn",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Giảm 100k món ăn",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Giảm 100k món ăn",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: false,
    maximumDiscountAmount: 100000,
    name: "Giảm 100k món ăn",
    percentNumber: null,
    startDate: "2022-07-31T17:00:00",
  },
];

export const discountCodeDefaultLocal = [
  {
    code: "H1MAB4",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Giảm 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "AB26AX",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Giảm 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "0KP1MA",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Giảm 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "ZM6M1A",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Giảm 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
  {
    code: "MH78BF",
    discountCodeTypeId: 0,
    endDate: "2022-08-31T17:00:00",
    id: "",
    isPercentDiscount: true,
    maximumDiscountAmount: 1000,
    name: "Giảm 10%",
    percentNumber: 10,
    startDate: "2022-07-31T17:00:00",
  },
];

export const flashSaleDefaultLocal = {
  flashSaleHasEnded: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2023-03-18T03:36:00",
    endDate: "2023-03-18T03:36:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail Dâu",
            thumbnail: flashSaleProductDefault1Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda chanh mix with với nước đường",
            thumbnail: flashSaleProductDefault2Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail cherry",
            thumbnail: flashSaleProductDefault3Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda cam mix with với nước đường",
            thumbnail: flashSaleProductDefault4Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail Dâu",
            thumbnail: flashSaleProductDefault1Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda chanh mix with với nước đường",
            thumbnail: flashSaleProductDefault2Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
    ],
  },
  flashSaleIsHappening: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2023-04-18T03:36:00",
    endDate: "2099-04-18T16:00:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail Dâu",
            thumbnail: flashSaleProductDefault1Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda chanh mix with với nước đường",
            thumbnail: flashSaleProductDefault2Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail cherry",
            thumbnail: flashSaleProductDefault3Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda cam mix with với nước đường",
            thumbnail: flashSaleProductDefault4Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail Dâu",
            thumbnail: flashSaleProductDefault1Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda chanh mix with với nước đường",
            thumbnail: flashSaleProductDefault2Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
    ],
  },
  flashSaleIsComing: {
    id: "be618c75-0216-4320-b09d-1817d5272887",
    name: "r4a",
    startDate: "2099-04-18T16:00:00",
    endDate: "2999-04-18T16:00:00",
    flashSaleProducts: [
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail Dâu",
            thumbnail: flashSaleProductDefault1Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda chanh mix with với nước đường",
            thumbnail: flashSaleProductDefault2Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail cherry",
            thumbnail: flashSaleProductDefault3Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda cam mix with với nước đường",
            thumbnail: flashSaleProductDefault4Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Cocktail Dâu",
            thumbnail: flashSaleProductDefault1Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
      {
        productPriceId: "19dfd426-487b-41f5-b3a5-d31270f4dc8f",
        flashSalePrice: 40000,
        flashSaleQuantity: 100,
        remainingQuantity: 100,
        maximumLimit: 100,
        productPrice: {
          productId: "af51c1fc-f1a3-474d-b602-33acaefdc1a0",
          priceValue: 80000,
          product: {
            productId: "00000000-0000-0000-0000-000000000000",
            name: "Soda chanh mix with với nước đường",
            thumbnail: flashSaleProductDefault2Img,
            description: "Phở đặc biệt thơm ngon, nước súp đạm đà, hương vị đặc trưng.",
            productOptions: [],
          },
        },
      },
    ],
  },
};

export const blogDefaultLocal = [
  {
    blogId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bannerImageUrl: BlogDefault,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdTime: "2023-08-05T03:27:42.3237068",
    creator: "Admin",
    isMain: true,
    channel: "TIN TỨC",
    totalView: 500,
  },
  {
    blogId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bannerImageUrl: BlogDefault,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdTime: "2023-08-04T03:27:42.3237068",
    creator: "Admin",
    isMain: false,
    channel: "TIN TỨC",
    totalView: 400,
  },
  {
    blogId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    bannerImageUrl: BlogDefault,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    createdTime: "2023-08-02T03:27:42.3237068",
    creator: "Admin",
    isMain: false,
    channel: "ẨM THỰC",
    totalView: 300,
  },
];

export const signatureProductsLocal = [
  {
    imageUrl: require("../../../assets/images/signature-product.png"),
    title: "Cà Phê",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    buttonText: "Thử ngay",
  },
  {
    imageUrl: require("../../../assets/images/signature-product.png"),
    title: "Cà Phê",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    buttonText: "Thử ngay",
  },
];

export const noNotificationHomePageVi = {
  noNotifications: "Chưa Có Thông Báo",
  noNotificationsRightNow: "Hiện tại bạn chưa có thông báo mới.",
  comeBackLater: "Vui lòng quay lại sau.",
};
