/* eslint-disable react-hooks/exhaustive-deps */
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import { PermissionKeys } from "constants/permission-key.constants";
import { RoutePathConstants } from "constants/route-key-path.constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TableKitchen from "./components/table-kitchen.component";
import "./kitchen.page.scss";

export default function KitchenManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    kitchenManagement: t("kitchen.kitchenManagement"),
  };

  const [branchSelected, setBranchSelected] = useState(history.location.state?.branchId);

  const handleChangeBranch = (branchId) => {
    setBranchSelected(branchId);
  };

  const handleNavigationToCreatePage = () => {
    history.push(RoutePathConstants.createNewKitchen, { selectedBranchId: branchSelected });
  };

  const listButton = [
    <FnbListBranches
      initSelectedBranchId={branchSelected}
      onChangeEvent={handleChangeBranch}
      showAllBranch={false}
      isSelectFirstBranch={!branchSelected}
    />,
  ];

  return (
    <>
      <FnbHeadingPage
        title={pageData.kitchenManagement}
        onCreate={handleNavigationToCreatePage}
        listButtons={listButton}
        permissionCreate={PermissionKeys.CREATE_KITCHEN}
        isCompactButtons={false}
        className="header-kitchen-management"
      />
      <TableKitchen selectedBranchId={branchSelected} />
    </>
  );
}
