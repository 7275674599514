import styled from "styled-components";
import theme from "theme";

export const PackageSectionContainer = styled.div`
  display: flex;
  background-color: ${(p) => p.backgroundColor};
  padding: 24px;
  border-radius: 24px;
  column-gap: 40px;
  width: 100%;
  @media (max-width: 1200px) {
    border-radius: 0;
  }
  @media (max-width: 576px) {
    border-radius: 0;
    flex-direction: column;
    row-gap: 12px;
  }
`;

export const PackageDurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 400px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const PackageDurationListButton = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  @media (max-width: 576px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

export const DivideHorizontalLine = styled.div`
  height: 1px;
  border-top: 1px solid #f7f5ff;
`;

export const DivideLine = styled.div`
  border-left: 2px solid ${(p) => p.backgroundColor};
  height: 281px;
  @media (max-width: 576px) {
    height: 1px;
    border-top: 1px solid ${(p) => p.backgroundColor};
  }
`;

export const ListPackageWrapper = styled.div`
  display: flex;
  width: 75%;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ListPackage = styled.div`
  display: flex;
  overflow-x: auto;
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.primary[100]};
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.primary[80]};
  }
`;
