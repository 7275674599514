import { message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FileUploadDragDropComponent } from "components/file-upload-drag-drop/file-upload-drag-drop.component";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbImportResultTable } from "components/fnb-import-result-table/fnb-import-result-table.component";
import { FnbPageHeader } from "components/fnb-page-header/fnb-page-header";
import { MAXIMUM_FILE_SIZE } from "constants/default.constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import languageService from "services/language/language.service";
import { handleDownloadFile, handleGetFileName } from "utils/helpers";
import "./import.page.scss";
import ingredientUpdateStockRequestDataService from "data-services/material-control/ingredient-update-stock-request.service";
import { TemplateExcelIcon } from "constants/icons.constants";
import { useMediaQuery } from "react-responsive";
import { KEY_TAB_PANE } from "../../table-inventory-control.component";

function ImportUpdateStockRequest(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [fileSelected, setFileSelected] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [isImportSuccess, setIsImportSuccess] = useState(null);
  const inputRef = React.useRef();
  const [blockNavigation, setBlockNavigation] = useState(false);
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [importRequestErrors, setImportRequestErrors] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const maximumFileSize = 20 * 1024 * 1024; // 20 MB => bytes
  const pageData = {
    btnCancel: t("button.cancel"),
    btnUpload: t("button.upload"),
    importIngredient: t("title.importUpdateStockRequest"),
    generalInformation: t("title.generalInformation"),
    labelDownload: t("title.labelDownload"),
    here: t("title.here"),
    maximumFileSize: t("material.import.maximumFileSize", { fileSizeLimit: MAXIMUM_FILE_SIZE }),
    importSuccess: t("messages.importSuccess"),
    rowColumnTitle: t("table.rowColumnTitle"),
    cellColumnTitle: t("table.cellColumnTitle"),
    errorColumnTitle: t("table.errorColumnTitle"),
    sheet: t("table.sheet"),
    importIngredientRequestSuccess: t("messages.importUpdateStockRequestSuccess"),
    importIngredientRequest: t("title.importIngredientRequest"),
    labelDownloadFileErr: t("materialControl.createRemoveRequest.labelDownloadFileErr"),
  };

  const tableColumns = [
    {
      title: pageData.rowColumnTitle,
      dataIndex: "row",
      key: "row",
      align: "left",
      width: "10%",
    },
    {
      title: pageData.cellColumnTitle,
      dataIndex: "cell",
      key: "cell",
      align: "left",
      width: "10%",
    },
    {
      title: pageData.errorColumnTitle,
      dataIndex: "message",
      key: "message",
      width: "70%",
      render: (_, record) => {
        let messsageArr = record?.message.split(";");
        return i18n.language === "vi" ? messsageArr[0] : messsageArr[1];
      },
    },
  ];

  const validateFileSize = (file, maxSize) => {
    const fileSize = file.size;
    if (fileSize <= maxSize) {
      return true;
    } else {
      message.warn(pageData.maximumFileSize);
    }
    return false;
  };

  const handleSelectFile = (file) => {
    if (Boolean(file)) {
      const isValid = validateFileSize(file, maximumFileSize);
      if (isValid === true) {
        setFileSelected(file);
      }
    } else {
      setFileSelected("");
    }
  };

  const handleUploadFileAsync = async () => {
    setIsUploading(true);
    setIsImportSuccess(false);
    setDataTable([]); // reset data table
    const formData = new FormData();
    formData.append("file", fileSelected);
    const response = await ingredientUpdateStockRequestDataService.importIngredientUpdateStock(formData);
    const { isSuccess, errors, errorMessage, importErrs } = response;
    setIsImportSuccess(isSuccess);
    setDataTable(errors);
    if (isSuccess) {
      setBlockNavigation(false);
      message.success(pageData.importIngredientRequestSuccess);
      // 👇️ reset input value
      if (inputRef && inputRef.current) {
        inputRef.current.reset();
      }
      history.push({
        pathname: "/inventory/transfer-control",
        search: `?tabActive=${KEY_TAB_PANE.VIEW_BY_UPDATE_STOCK}`,
      });
    } else {
      setImportRequestErrors(importErrs);
      errorMessage && message.error(t(errorMessage));
    }
    setIsUploading(false);
  };

  const handleDownloadTemplateUrlAsync = async () => {
    const languageCode = languageService.getLang();
    try {
      const response = await ingredientUpdateStockRequestDataService.downloadTemplate(languageCode);
      handleDownloadFile(response);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };

  const downloadFileErrAsync = async () => {
    let languageCode = languageService.getLang();
    try {
      var response = await ingredientUpdateStockRequestDataService.downloadFileErrAsync({
        languageCode,
        importErrs: importRequestErrors,
      });
      const { data } = response;
      const file = {
        fileName: handleGetFileName(response),
        data: data,
      };
      handleDownloadFile(file);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };

  useEffect(() => {
    if (Boolean(fileSelected)) {
      setBlockNavigation(true);
    } else {
      setBlockNavigation(false);
    }
  }, [fileSelected]);

  const renderError = () => {
    if (isImportSuccess) return <></>;
    return (
      <div className="link-download-box link-download-box-customer mt-4">
        <TemplateExcelIcon width={isMobile ? 24 : 32} height={isMobile ? 24 : 32} />
        <span> {pageData.labelDownloadFileErr}</span>
        <a onClick={() => downloadFileErrAsync()}>
          <span className="link-url-template-customer">{pageData.here}</span>
        </a>
      </div>
    );
  };

  return (
    <>
      {/* Show leave confirm dialog */}
      <DeleteConfirmComponent
        title={t("leaveDialog.confirmation")}
        content={t("messages.leaveForm")}
        skipPermission={true}
        cancelText={t("button.discard")}
        okText={t("button.confirmLeave")}
        onOk={() => {
          setBlockNavigation(false);
        }}
        isChangeForm={blockNavigation}
      />

      <FnbPageHeader
        showBackLink
        onBack={history.goBack}
        actionDisabled={fileSelected && !isUploading ? false : true}
        title={pageData.importIngredient}
        cancelText={pageData.btnCancel}
        onCancel={history.goBack}
        actionText={<span className="text-first-capitalize">{pageData.btnUpload}</span>}
        onAction={handleUploadFileAsync}
      />

      <FnbCard className="fnb-card-full import-product-card">
        <div className="fnb-card-wrapper">
          <div className="mb-2">
            <h2 className="card-title">{pageData.generalInformation}</h2>
            <div className="description">
              <span className="download-description">{pageData.labelDownload}</span>
              <Link className="ml-2 download-link" onClick={handleDownloadTemplateUrlAsync}>
                {pageData.here}
              </Link>
            </div>
          </div>

          <FileUploadDragDropComponent ref={inputRef} onChange={handleSelectFile} />
          {dataTable && dataTable?.length > 0 && (
            <FnbImportResultTable
              tableName={pageData.importIngredientRequest?.toLowerCase()}
              columns={tableColumns}
              dataSource={dataTable}
              isSuccess={isImportSuccess}
              renderError={renderError}
            />
          )}
        </div>
      </FnbCard>
    </>
  );
}

export default ImportUpdateStockRequest;
