import React from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "constants/icons.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

export function FnbDeleteIcon(props) {
  const { width = 24, height = 24, color } = props;
  const [t] = useTranslation();
  return (
    <FnbTooltip placement="top" title={t("button.delete")}>
      <TrashIcon width={width} height={height} color={color} />
    </FnbTooltip>
  );
}
