import { Col, Row, Typography, message, Popover } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { ArrowDownOutlined, Edit2Icon, EditIcon, Note2Icon, PrinterOutlineIcon, TrashIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { PurchaseOrderAction, PurchaseOrderStatus } from "constants/purchase-order-status.constants";
import { DateFormat, TrackingSteps } from "constants/string.constants";
import { useEffect, useState } from "react";
import { formatDate, formatNumberDecimalOrInteger, getCurrencyWithSymbol, hasPermission, getCurrency } from "utils/helpers";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbSteps, Step } from "components/fnb-step/fnb-step";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import { KEY_TAB_PANE } from "pages/inventory/material-control/table-inventory-control.component";
const { Text } = Typography;

export default function DetailPurchaseOrder(props) {
  const { t, match, history, purchaseOrderDataService } = props;
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState(false);
  const [purchaseOrderMaterials, setPurchaseOrderMaterials] = useState([]);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [isNewStatus, setIsNewStatus] = useState(false);
  const [currencyStep, setCurrencyStep] = useState(1);
  const [openPopOverMoreAction, setOpenPopOverMoreAction] = useState(false);
  const [isShowModalCancelPurchaseOrder, setIsShowModalCancelPurchaseOrder] = useState(false);
  const [purchaseOrderHistories, setPurchaseOrderHistories] = useState([]);
  const purchaseOrderId = match?.params?.id;

  const pageData = {
    backTo: t("button.backTo"),
    purchaseOrderManagement: t("purchaseOrder.purchaseOrderManagement"),
    edit: t("button.edit"),
    print: t("button.print"),
    approve: t("button.approve"),
    order: t("button.order"),
    complete: t("button.complete"),
    cancel: t("button.cancel"),
    option: t("button.option"),
    generalInfo: t("settings.createGroupPermission.titleGeneralInformation"),
    approvePurchaseOrderSuccess: t("messages.approvePurchaseOrderSuccess"),
    orderPurchaseOrderSuccess: t("messages.orderPurchaseOrderSuccess"),
    cancelPurchaseOrderSuccess: t("messages.cancelPurchaseOrderSuccess"),
    completePurchaseOrderSuccess: t("messages.completePurchaseOrderSuccess"),
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      materialName: t("table.materialName"),
      material: t("material.title"),
      quantity: t("table.quantity"),
      importUnit: t("table.importUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseEnterNameUnit"),
      importPrice: t("table.importPrice"),
      total: t("table.total"),
      totalCost: t("table.totalCost"),
      action: t("table.action"),
      inventory: t("table.inventory"),
      branchName: t("table.branchName"),
    },
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("purchaseOrder.status.approved"),
      complete: t("purchaseOrder.status.completed"),
      cancel: t("purchaseOrder.status.canceled"),
      ordering: t("purchaseOrder.status.ordering"),
    },
    supplier: t("supplier.title"),
    destinationLabel: t("purchaseOrder.destinationLabel"),
    note: t("form.note"),
    materialInformation: t("purchaseOrder.materialInformation"),
    confirmCancel: t("leaveDialog.confirmCancel"),
    ignore: t("button.ignore"),
    cancelPurchaseOrder: t("purchaseOrder.cancelPurchaseOrder"),
    viewHistory: t("button.viewHistory"),
    moreAction: t("button.moreAction"),
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await purchaseOrderDataService.getPurchaseOrderByIdAsync(purchaseOrderId).then((res) => {
      if (res) {
        const { purchaseOrder } = res;
        setPurchaseOrderDetail(purchaseOrder);
        let dataSource = mappingToDataTable(purchaseOrder.purchaseOrderMaterials);
        setPurchaseOrderMaterials(dataSource);
        if (purchaseOrder?.statusId === PurchaseOrderStatus.New) {
          setIsNewStatus(true);
        } else {
          setIsNewStatus(false);
        }
        if (
          purchaseOrder?.statusId === PurchaseOrderStatus.New ||
          purchaseOrder?.statusId === PurchaseOrderStatus.Approved ||
          purchaseOrder?.statusId === PurchaseOrderStatus.Ordering
        ) {
          setCancelStatus(true);
        } else {
          setCancelStatus(false);
        }

        setPurchaseOrderHistories(purchaseOrder.purchaseOrderHistories);

        //handle check step...
        if (purchaseOrder?.statusId === PurchaseOrderStatus.New) {
          setCurrencyStep(0);
        } else if (
          purchaseOrder?.statusId === PurchaseOrderStatus.Completed ||
          purchaseOrder?.statusId === PurchaseOrderStatus.Canceled
        ) {
          if (purchaseOrderHistories.some(poHistory => poHistory.statusId === PurchaseOrderStatus.Ordering)) {
            setCurrencyStep(3);
          }
          else {
            setCurrencyStep(4);
          }
        } else if (purchaseOrder?.statusId === PurchaseOrderStatus.Ordering) {
          setCurrencyStep(2);
        } else {
          setCurrencyStep(1);
        }
      }
    });
  };

  const onEditPurchaseOrder = () => {
    history.push(`/inventory/edit-purchase-order/${purchaseOrderId}`);
  };

  const onCancel = () => {
    setIsShowModalCancelPurchaseOrder(false);
    onUpdatePurchaseOrderStatus(PurchaseOrderAction.Cancel);
  };

  const onUpdatePurchaseOrderStatus = async (action) => {
    let req = {
      id: purchaseOrderId,
    };
    if (action === PurchaseOrderAction.Approve) {
      purchaseOrderDataService.approvePurchaseOrderStatusByIdAsync(req).then((res) => {
        if (res) {
          fetchData();
          message.success(pageData.approvePurchaseOrderSuccess);
        }
      });
    } else if (action === PurchaseOrderAction.Order) {
      purchaseOrderDataService.orderPurchaseOrderStatusByIdAsync(req).then((res) => {
        if (res) {
          fetchData();
          message.success(pageData.orderPurchaseOrderSuccess);
        }
      });
    } else if (action === PurchaseOrderAction.Complete) {
      purchaseOrderDataService.completePurchaseOrderStatusByIdAsync(req).then((res) => {
        if (res) {
          fetchData();
          message.success(pageData.completePurchaseOrderSuccess);
        }
      });
    } else {
      purchaseOrderDataService.cancelPurchaseOrderStatusByIdAsync(req).then((res) => {
        if (res) {
          fetchData();
          message.success(pageData.cancelPurchaseOrderSuccess);
        }
      });
    }
  };

  const mappingToDataTable = (data) => {
    let materials = [];
    data.map((item, index) => {
      let material = {
        no: index + 1,
        sku: item?.material?.sku,
        materialID: item?.material?.id,
        material: item?.material?.name,
        quantity: item?.quantity,
        unitId: item?.unit?.name,
        price: item?.price,
        total: item?.total,
      };
      materials.push(material);
    });
    return materials;
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "left",
        width: "5%",
      },
      {
        title: pageData.table.sku,
        dataIndex: "sku",
        align: "left",
        width: "10%",
      },
      {
        title: pageData.table.materialName,
        dataIndex: "material",
        align: "left",
        width: "20%",
        render: (_, record) => {
          return (
            <>
              <FnbTypography.Link
                to={`/inventory/material/detail/${record?.materialID}`}
                target="_blank"
                text={record?.material}
              />
            </>
          );
        },
      },
      {
        title: pageData.table.quantity,
        dataIndex: "quantity",
        width: "15%",
        align: "left",
      },
      {
        title: pageData.table.importUnit,
        dataIndex: "unitId",
        width: "15%",
        align: "left",
      },
      {
        title: `${pageData.table.importPrice} (${getCurrency()})`,
        dataIndex: "price",
        align: "left",
        width: "15%",
        render: (_, record) => {
          return <>{record?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>;
        },
      },
      {
        title: pageData.table.total,
        dataIndex: "total",
        align: "left",
        width: "15%",
        render: (_, record) => {
          return <>{record?.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>;
        },
      },
    ];
    return columns;
  };

  const onClickShowHistory = () => {
    history.push(`/inventory/purchase-order-history/${purchaseOrderId}/${purchaseOrderDetail?.code}`);
  };

  const onCancelPO = () => {
    setIsShowModalCancelPurchaseOrder(true);
  };

  const renderActionCreate = () => {
    if (hasPermission(PermissionKeys.APPROVE_PURCHASE) && isNewStatus) {
      return <FnbButton minWidth="150px" text={pageData.approve} onClick={() => onUpdatePurchaseOrderStatus(PurchaseOrderAction.Approve)} />;
    }
    else if (hasPermission(PermissionKeys.APPROVE_PURCHASE) && currencyStep === 1) {
      return <FnbButton minWidth="150px" text={pageData.order} onClick={() => onUpdatePurchaseOrderStatus(PurchaseOrderAction.Order)} />;
    }
    else if (hasPermission(PermissionKeys.IMPORT_GOODS) && currencyStep === 2) {
      return <FnbButton minWidth="150px" text={pageData.complete} onClick={() => onUpdatePurchaseOrderStatus(PurchaseOrderAction.Complete)} />;
    }
    else {
      return <></>
    }
  }

  const renderMoreAction = () => {
    return (
      <Popover
        showArrow={false}
        content={
          <div className="more-action-popover">
            <FnbButton text={pageData.print} variant="tertiary" iconHeader={<PrinterOutlineIcon width={20} height={20} />} className="w-100" style={{ justifyContent: "left" }} />
            {cancelStatus && (
              <FnbButton
                style={{ justifyContent: "left" }}
                className="w-100"
                text={pageData.edit}
                variant="tertiary"
                onClick={onEditPurchaseOrder}
                permission={PermissionKeys.EDIT_PURCHASE}
                iconHeader={<Edit2Icon width={20} height={20} />}
              />
            )}
            <FnbButton
              style={{ justifyContent: "left" }}
              className="w-100"
              iconHeader={<Note2Icon width={20} height={20} />}
              variant="tertiary"
              onClick={onClickShowHistory}
              text={pageData.viewHistory}
            />
            {cancelStatus && (
              <FnbButton
                style={{ justifyContent: "left" }}
                className="w-100"
                text={pageData.cancel}
                variant="tertiary"
                danger
                onClick={(onCancelPO)}
                permission={PermissionKeys.CANCEL_PURCHASE}
                iconHeader={<TrashIcon width={20} height={20} />}
              />
            )}
          </div>
        }
        trigger="click"
        placement="bottomRight"
        open={openPopOverMoreAction}
        onOpenChange={setOpenPopOverMoreAction}
        overlayClassName="fnb-heading-page__popover-overlay"
      >
        <FnbButton variant="secondary" iconFooter={<ArrowDownOutlined />} text={pageData.moreAction} />
      </Popover>
    );
  };

  const renderDescription = (createdTime) => {
    return createdTime ? formatDate(createdTime, DateFormat.HH_MM_DD_MM_YYYY_) : "";
  }

  return (
    <div className="detail-purchase-order-page">
      <FnbHeadingPage
        itemsBreadcrumb={[
          {
            href: `/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_PURCHASE_ORDER}`,
            title: pageData.purchaseOrderManagement,
          },
          {
            title: <Text className="purchase-order-code">#{purchaseOrderDetail?.code}</Text>,
          },
        ]}
        listButtons={[
          renderMoreAction(),
          renderActionCreate(),
        ]}
        isCompactButtons={false}
      />
      <div className="card-parent">
        <FnbCard className="card-general-info">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.generalInfo} />
            <hr className="title-hr" />
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.supplier}:`} />
              </div>
              <div className="content">
                <FnbTypography.Link text={purchaseOrderDetail?.supplier?.name} />
              </div>
            </div>
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.destinationLabel}:`} />
              </div>
              <div className="content">
                <FnbTypography text={purchaseOrderDetail?.storeBranch?.name} />
              </div>
            </div>
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.note}:`} />
              </div>
              <div className="content text-area">
                <FnbTypography text={purchaseOrderDetail?.note} />
              </div>
            </div>
          </div>
          <FnbSteps
            currentStep={currencyStep}
            status={TrackingSteps.process}
            className="steps-info"
          >
            <Step
              isProcessing={TrackingSteps.process}
              title={pageData.status.new}
              description={renderDescription(purchaseOrderHistories.find(poHistory => poHistory.statusId === PurchaseOrderStatus.New)?.createdTime ?? null, DateFormat.HH_MM_DD_MM_YYYY_)}
            />
            {!(purchaseOrderDetail?.statusId === PurchaseOrderStatus.Canceled &&
              !purchaseOrderHistories.some(poHistory => poHistory?.statusId === PurchaseOrderStatus.Approved))
              && <Step
                isProcessing={TrackingSteps.process}
                title={pageData.status.approved}
                description={renderDescription(purchaseOrderHistories.find(poHistory => poHistory.statusId === PurchaseOrderStatus.Approved)?.createdTime ?? null, DateFormat.HH_MM_DD_MM_YYYY_)}
              />}
            {!(purchaseOrderDetail?.statusId === PurchaseOrderStatus.Canceled &&
              !purchaseOrderHistories.some(poHistory => poHistory?.statusId === PurchaseOrderStatus.Ordering))
              && <Step
                isProcessing={TrackingSteps.process}
                title={pageData.status.ordering}
                description={
                  renderDescription(purchaseOrderHistories
                    .find(poHistory => poHistory.statusId === PurchaseOrderStatus.Ordering)?.createdTime ?? null, DateFormat.HH_MM_DD_MM_YYYY_)}
              />}
            <Step
              isProcessing={
                purchaseOrderDetail?.statusId === PurchaseOrderStatus.Canceled && currencyStep === 2
                  ? TrackingSteps.process
                  : ""
              }
              index={
                purchaseOrderDetail?.statusId === PurchaseOrderStatus.Canceled
                  ? PurchaseOrderStatus.Canceled
                  : PurchaseOrderStatus.Completed
              }
              title={
                purchaseOrderDetail?.statusId === PurchaseOrderStatus.Canceled
                  ? pageData.status.cancel
                  : pageData.status.complete
              }
              className={purchaseOrderDetail?.statusId === PurchaseOrderStatus.Canceled ? "step-item-cancel" : ""}
              description={renderDescription(purchaseOrderHistories.find(poHistory => poHistory.statusId === PurchaseOrderStatus.Completed || poHistory.statusId === PurchaseOrderStatus.Canceled)?.createdTime, DateFormat.HH_MM_DD_MM_YYYY_)}
            />
          </FnbSteps>
        </FnbCard>
        <FnbCard className="card-material-info fnb-card-custom">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.materialInformation} />
            <hr className="title-hr" />
          </div>
          <FnbTable className="material-table" dataSource={purchaseOrderMaterials} columns={columnsMaterial()} />
        </FnbCard>
        {purchaseOrderMaterials?.length > 0 && (
          <Row className="total">
            <Col className="total-width">
              <div className="label">
                <FnbTypography text={pageData.table.quantity + ":"} />
              </div>
              <div className="label">
                <FnbTypography text={pageData.table.totalCost + ":"} />
              </div>
            </Col>
            <Col className="total-width">
              <div className="content">
                <FnbTypography text={`${purchaseOrderMaterials?.length} ${pageData.table.material}`} />
              </div>
              <div className="content">
                <FnbTypography text={`${formatNumberDecimalOrInteger(
                  purchaseOrderMaterials?.reduce((n, { total }) => n + total, 0),
                )} ${getCurrency()}`} />
              </div>
            </Col>
          </Row>
        )}
      </div>
      <ConfirmDialogComponent
        visible={isShowModalCancelPurchaseOrder}
        type={ConfirmStyle.DELETE}
        onCancel={() => setIsShowModalCancelPurchaseOrder(false)}
        onOk={onCancel}
        title={pageData.confirmCancel}
        content={pageData.cancelPurchaseOrder}
        okText={pageData.cancel} />
    </div>
  );
}
