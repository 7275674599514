import { Card, Col, Form, Row, message } from "antd";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { GOOGLE_CONFIG_TYPE } from "constants/google.constants";
import { FirebaseIcon, SMSZaloIcon, GeneralConfigInfoCircle, GoogleMapIcon } from "constants/icons.constants";
import storeDataService from "data-services/store/store-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./google-config.scss";
import { PermissionKeys } from "constants/permission-key.constants";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import i18n from "i18next";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import _ from "lodash";
import FnbButtonCompound from "components/fnb-button-compound/fnb-button-compound";
import theme from "theme";
import { useHistory } from "react-router-dom";
import { isStoreInternational } from "utils/helpers";

const tab = {
  GOOGLE: GOOGLE_CONFIG_TYPE.googleApi,
  FIREBASE: GOOGLE_CONFIG_TYPE.firebaseCredential,
  SMS: GOOGLE_CONFIG_TYPE.sms_Zalo,
};
const EnumESMSTypes = {
  SMS: 0,
  ZaloZns: 1,
};

function GoogleConfig(props) {
  const { onChangeForm, isChangeFormData, setIsChangeForm } = props;
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [formSMS] = Form.useForm();
  const [isChangeFormGoogle, setIsChangeFormGoogle] = useState(false);
  const [isChangeFormFireBase, setIsChangeFormFireBase] = useState(false);
  const [isChangeFormSMS, setIsChangeFormSMS] = useState(false);
  const [initConfigData, setInitConfigData] = useState(null);
  const [storeConfigSMS, setStoreConfigSMS] = useState([]);
  const [smsActive, setSmsActive] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [nextTab, setNextTab] = useState(tab.GOOGLE);
  const [isChangeForm, setIsChangeFormData] = useState(false);
  const [regionInternational, setRegionInternational] = useState(null);
  const tabActive = new URLSearchParams(window.location.search).get("tabCommunication");
  const history = useHistory()

  const translateData = {
    btnCancel: t("button.cancel"),
    btnSaveChanges: t("button.saveChanges"),
    updateSuccess: t("text.smsZaloZns.updateSuccess"),
    updateFailed: t("text.smsZaloZns.updateFailed", "Update failed"),
    googleConfig: {
      title: t("googleConfig.title"),
      tooltip: t("googleConfig.tooltip"),
      key: t("googleConfig.key"),
      googleMap: t("googleConfig.googleMap"),
      keyPlaceholder: t("googleConfig.keyPlaceholder"),
      keyErrorMessage: t("googleConfig.keyErrorMessage"),
      updateSuccess: t("googleConfig.updateSuccess"),
      androidApp: t("googleConfig.androidApp"),
      iOSApp: t("googleConfig.iOSApp"),
      storeWeb: t("googleConfig.storeWeb"),
      pOS: t("googleConfig.pOS"),
      keyInvalid: t("googleConfig.keyInvalid"),
      tooltipFireBase: t("googleConfig.tooltipFireBase"),
      placeholderFirebase: t("googleConfig.placeholderFirebase"),
      validateFirebase: t("googleConfig.validateFirebase"),
      titleSMS: t("googleConfig.titleSMS"),
      credentialJSON: t("googleConfig.credentialJSON"),
      apiKey: t("googleConfig.apiKey"),
      apiKeyPlaceholder: t("googleConfig.apiKeyPlaceholder"),
      apiKeyErrorMessage: t("googleConfig.apiKeyErrorMessage"),
      secretKey: t("googleConfig.secretKey"),
      secretKeyPlaceholder: t("googleConfig.secretKeyPlaceholder"),
      secretKeyErrorMessage: t("googleConfig.secretKeyErrorMessage"),
    },
    firebaseCredential: {
      title: t("googleConfig.firebaseCredential"),
    },
    sms: {
      tooltip: t("text.smsZaloZns.tooltip"),
      apiKey: t("text.smsZaloZns.apiKey", "API Key"),
      enterApiKey: t("text.smsZaloZns.enterApiKey", "Enter API Key"),
      pleaseEnterApiKey: t("text.smsZaloZns.pleaseEnterApiKey", "Please enter API Key"),
      secretKey: t("text.smsZaloZns.secretKey", "Secret Key"),
      enterSecretKey: t("text.smsZaloZns.enterSecretKey", "Enter Secret Key"),
      pleaseEnterSecretKey: t("text.smsZaloZns.pleaseEnterSecretKey", "Please enter API Key"),
      max100Characters: t("text.smsZaloZns.maximum100Characters"),
      inValidApiKeySecretKey: t("text.smsZaloZns.inValidApiKeySecretKey", "Invalid Api key/ Secret Key"),
    },
    general: t("store.configTab.general"),
    bankAccount: t("store.configTab.bankAccount"),
    operation: t("store.configTab.operation"),
    qrCode: t("store.configTab.qrCode"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };

  const [currentTab, setCurrentTab] = useState(tabActive ? Number(tabActive) : tab.GOOGLE);

  useEffect(() => {
    initConfig();
    initConfigSMS();
  }, []);

  useEffect(() => {
    const validateForm = (form) => {
      const errorFields = form
        .getFieldsError()
        .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
      form.validateFields(errorFields);
    };

    validateForm(form);
    validateForm(formSMS);
  }, [i18n.language]);

  useEffect(() => {
    handleChangeForm(isChangeFormGoogle);
  }, [isChangeFormGoogle]);

  useEffect(() => {
    handleChangeForm(isChangeFormFireBase);
  }, [isChangeFormFireBase]);

  useEffect(() => {
    handleChangeForm(isChangeFormSMS);
  }, [isChangeFormSMS]);

  const handleChangeForm = (isChange) => {
    if (onChangeForm) onChangeForm(isChange);
  };

  const onSave = async () => {
    form.validateFields().then(async (values) => {
      const { androidAppAPIKey, iOSAppAPIKey, storeWebAPIKey, posWebAPIKey, firebaseCredentialAPIKey } = values;

      const config = {
        androidAppAPIKey: androidAppAPIKey,
        iOSAppAPIKey: iOSAppAPIKey,
        storeWebAPIKey: storeWebAPIKey,
        posWebAPIKey: posWebAPIKey,
        firebaseCredentialAPIKey: firebaseCredentialAPIKey,
      };

      var req = {
        googleConfig: config,
      };
      let res = await storeDataService.updateStoreGoogleConfigForPlatformAsync(req);
      if (res) {
        message.success(translateData.updateSuccess);
        onChangeFormGoogle(false);
        const configData = {
          androidAppAPIKey:
            config.androidAppAPIKey !== undefined ? config.androidAppAPIKey : initConfigData.androidAppAPIKey,
          iOSAppAPIKey: config.iOSAppAPIKey !== undefined ? config.iOSAppAPIKey : initConfigData.iOSAppAPIKey,
          storeWebAPIKey: config.storeWebAPIKey !== undefined ? config.storeWebAPIKey : initConfigData.storeWebAPIKey,
          posWebAPIKey: config.posWebAPIKey !== undefined ? config.posWebAPIKey : initConfigData.posWebAPIKey,
          firebaseCredentialAPIKey:
            config.firebaseCredentialAPIKey !== undefined
              ? config.firebaseCredentialAPIKey
              : initConfigData.firebaseCredentialAPIKey,
        };
        setInitConfigData(configData);
      }
    });
  };

  //#region Events SMS
  const onSaveSMS = async () => {
    formSMS.validateFields().then(async (values) => {
      message.destroy();
      var req = {
        storeESMSConfigId: values.storeESMSConfigId || null,
        apiKey: values.apiKey,
        secretKey: values.secretKey,
        type: EnumESMSTypes.SMS,
        isEnabled: true,
      };
      await storeDataService
        .updateESMSConfig(req)
        .then(async (res) => {
          if (res) {
            responseMessage(res);
          } else {
            message.error(translateData.updateFailed);
          }
        })
        .catch((err) => {
          message.error(translateData.updateFailed);
        });
    });
  };

  const responseMessage = async (res) => {
    if (res.isSuccess) {
      message.success(translateData.updateSuccess);
    } else if (!Boolean(res.isValidApiSecretKey)) {
      message.error(translateData.sms.inValidApiKeySecretKey);
    } else {
      message.error(translateData.updateFailed);
    }
  };

  const IsJsonString = (str) => {
    if (str === null || str === undefined || str === "") return false;
    try {
      var json = JSON.parse(str);
      return typeof json === "object";
    } catch (e) {
      return false;
    }
  };

  const initConfig = async () => {
    const res = await storeDataService.getStoreGoogleConfigForPlatformAsync();
    if (res) {
      const config = {
        androidAppAPIKey: res.androidAppAPIKey,
        iOSAppAPIKey: res.iosAppAPIKey,
        storeWebAPIKey: res.storeWebAPIKey,
        posWebAPIKey: res.posWebAPIKey,
        firebaseCredentialAPIKey: res.firebaseCredentialAPIKey,
      };
      form.resetFields();
      form.setFieldsValue(config);
      setInitConfigData(config);
    }
  };

  const initConfigSMS = async () => {
    const isInternational = isStoreInternational();
    setRegionInternational(isInternational);
    if (!isInternational) {
      const res = await storeDataService.getESMSConfig();
      if (res) {
        const esmsConfigs = res?.esmsConfigs;
        setStoreConfigSMS(esmsConfigs);
        setSmsActive(esmsConfigs?.find((x) => x.type === EnumESMSTypes.SMS)?.isEnable || false);
        handleInitFormSMS(esmsConfigs);
      }
    }
  };

  const handleInitFormSMS = (esmsConfigs = storeConfigSMS) => {
    formSMS.resetFields();
    let formValue = formSMS.getFieldsValue();
    formValue.storeESMSConfigId = null;
    formValue.apiKey = "";
    formValue.secretKey = "";

    const storeSMS = esmsConfigs?.find((x) => x.type === EnumESMSTypes.SMS);
    if (storeSMS) {
      formValue.storeESMSConfigId = storeSMS?.id || null;
      formValue.apiKey = storeSMS?.apiKey;
      formValue.secretKey = storeSMS?.secretKey;
    }
    formSMS.setFieldsValue(formValue);
  };

  const onCancelGoogle = () => {
    setIsChangeFormGoogle(false);
    form.resetFields();
    form.setFieldsValue(initConfigData);
  };

  const onCancelFireBase = () => {
    setIsChangeFormFireBase(false);
    form.resetFields();
    form.setFieldsValue(initConfigData);
  };

  const onCancelSMS = () => {
    setIsChangeFormSMS(false);
    handleInitFormSMS(storeConfigSMS);
  };

  const onChangeFormGoogle = (change = true) => {
    switch (currentTab) {
      case tab.GOOGLE:
        setIsChangeFormGoogle(change);
        break;
      case tab.FIREBASE:
        setIsChangeFormFireBase(change);
        break;
      case tab.SMS:
        setIsChangeFormSMS(change);
        break;
      default:
        setIsChangeFormGoogle(change);
    }
  };

  const onEnableProvider = async (type, value) => {
    const formValue = formSMS.getFieldsValue();
    message.destroy();
    let req = {};
    // handle case form empty when switch sms in tab not sms
    const storeSMS = storeConfigSMS.find((x) => x.type === EnumESMSTypes.SMS);
    if (_.isEmpty(formValue) && currentTab !== tab.SMS && storeSMS) {
      req = {
        storeESMSConfigId: storeSMS.id,
        apiKey: storeSMS.apiKey,
        secretKey: storeSMS.secretKey,
        type: type,
        isEnabled: value,
        isEnabledOnly: true,
      };
    } else {
      req = {
        storeESMSConfigId: formValue.storeESMSConfigId || null,
        apiKey: formValue.apiKey,
        secretKey: formValue.secretKey,
        type: type,
        isEnabled: value,
        isEnabledOnly: true,
      };
    }
    await storeDataService
      .updateESMSConfig(req)
      .then((res) => {
        if (res) {
          setIsChangeFormSMS(false);
          responseMessage(res);
          setSmsActive(value);
          disableInput();
        } else {
          message.error(translateData.updateFailed);
        }
      })
      .catch(() => {
        message.error(translateData.updateFailed);
      });
  };

  const disableInput = () => {
    return smsActive === false && currentTab === tab.SMS;
  };

  const onChangeTab = (tab) => {
    if (isChangeFormData) {
      setShowConfirm(true);
      setNextTab(tab);
    } else {
      setCurrentTab(tab);
      history.push({
        pathname: '/config',
        search: `?tabActive=communications&tabCommunication=${tab}`
      })
    }
  };

  const onOk = () => {
    setShowConfirm(false);
    setIsChangeFormData(false);
    setCurrentTab(nextTab);
    history.push({
      pathname: '/config',
      search: `?tabActive=communications&tabCommunication=${nextTab}`
    })
    setIsChangeForm(false);

    switch (currentTab) {
      case tab.GOOGLE:
        setIsChangeFormGoogle(false);
        form.resetFields();
        form.setFieldsValue(initConfigData);
        break;
      case tab.FIREBASE:
        setIsChangeFormFireBase(false);
        form.resetFields();
        form.setFieldsValue(initConfigData);
        break;
      default:
        setIsChangeFormSMS(false);
        handleInitFormSMS(storeConfigSMS);
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const renderProvider = () => {
    return (
      <div className="tab-config-container">
        <Card className="fnb-card w-100 card-general">
          <FnbButtonCompound
            title={translateData.googleConfig.googleMap}
            iconHeader={
              <GoogleMapIcon
                color={currentTab === tab.GOOGLE ?   theme.colors.default.white: theme.colors.primary[100]}
              />
            }
            onClick={() => onChangeTab(tab.GOOGLE)}
            isActive={currentTab === tab.GOOGLE}
            className="w-100"
          />
           <FnbButtonCompound
            title={translateData.firebaseCredential.title}
            iconHeader={
              <FirebaseIcon
                color={currentTab === tab.FIREBASE ?   theme.colors.default.white: theme.colors.primary[100]}
              />
            }
            onClick={() => onChangeTab(tab.FIREBASE)}
            isActive={currentTab === tab.FIREBASE}
            className="w-100"
          />
          {/* Hide for US 77562 */}
          {/* {!regionInternational && <FnbButtonCompound
            title={translateData.googleConfig.titleSMS}
            iconHeader={
              <SMSZaloIcon
                color={currentTab === tab.SMS ?   theme.colors.default.white: theme.colors.primary[100]}
              />
            }
            onClick={() => onChangeTab(tab.SMS)}
            isActive={currentTab === tab.SMS}
            className="w-100"
            showSwitch
            valueSwitch={smsActive}
            setValueSwitch={setSmsActive}
            onChangeSwitch={(value) => {
              onEnableProvider(EnumESMSTypes.SMS, value);
            }}
          />} */}
        </Card>
      </div>
    );
  };

  const renderProviderSetting = () => {
    return (
      <Col sm={24} md={24}>
        <Card className="google-config-card fnb-card">
          <Form autoComplete="off" onChange={onChangeFormGoogle} form={form}>
            <Row className="card-title-box">
              <Col span={17} className="d-flex-align-center content-help">
                <div className="icon-help">
                  <GeneralConfigInfoCircle />
                </div>
                <div className="auto-save-title">{translateData.googleConfig.tooltip}</div>
              </Col>
              <Col span={7} className="content-btn">
                {isChangeFormGoogle && (
                  <div className="list-group-button">
                    <FnbButton
                      minWidth="100px"
                      text={translateData.btnCancel}
                      variant="tertiary"
                      onClick={onCancelGoogle}
                    />
                    <FnbButton
                      minWidth="140px"
                      text={translateData.btnSaveChanges}
                      onClick={onSave}
                      permission={PermissionKeys.ADMIN}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row gutter={[28, 28]}>
              <Col lg={12} span={24}>
                <h3 className="fnb-form-label">{translateData.googleConfig.androidApp}</h3>
                <Form.Item
                  name={"androidAppAPIKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: initConfigData?.androidAppAPIKey ? true : false,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.googleConfig.keyInvalid}
                        />
                      ),
                    },
                    {
                      type: "string",
                      max: 100,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.max100Characters}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type="password"
                    autoComplete={false}
                    placeholder={translateData.googleConfig.keyPlaceholder}
                    maxLength={101}
                    className={"google-config-input"}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <h3 className="fnb-form-label">{translateData.googleConfig.iOSApp}</h3>
                <Form.Item
                  name={"iOSAppAPIKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: initConfigData?.iOSAppAPIKey ? true : false,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.googleConfig.keyInvalid}
                        />
                      ),
                    },
                    {
                      type: "string",
                      max: 100,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.max100Characters}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type="password"
                    autoComplete={false}
                    placeholder={translateData.googleConfig.keyPlaceholder}
                    maxLength={101}
                    className={"google-config-input"}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <h3 className="fnb-form-label">{translateData.googleConfig.storeWeb}</h3>
                <Form.Item
                  name={"storeWebAPIKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: initConfigData?.storeWebAPIKey ? true : false,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.googleConfig.keyInvalid}
                        />
                      ),
                    },
                    {
                      type: "string",
                      max: 100,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.max100Characters}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type="password"
                    autoComplete={false}
                    placeholder={translateData.googleConfig.keyPlaceholder}
                    maxLength={101}
                    className={"google-config-input"}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <h3 className="fnb-form-label">{translateData.googleConfig.pOS}</h3>
                <Form.Item
                  name={"posWebAPIKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: initConfigData?.posWebAPIKey ? true : false,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.googleConfig.keyInvalid}
                        />
                      ),
                    },
                    {
                      type: "string",
                      max: 100,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.max100Characters}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type="password"
                    autoComplete={false}
                    placeholder={translateData.googleConfig.keyPlaceholder}
                    maxLength={101}
                    className={"google-config-input"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    );
  };

  // firebase
  const renderProviderFireBaseSetting = () => {
    return (
      <Col span={24}>
        <Card className="firebase-config-card fnb-card">
          <Form autoComplete="off" onChange={onChangeFormGoogle} form={form}>
            <Row className="card-title-box">
              <Col span={17} className="d-flex-align-center content-help">
                <div className="icon-help">
                  <GeneralConfigInfoCircle />
                </div>
                <div className="auto-save-title">{translateData.googleConfig.tooltipFireBase}</div>
              </Col>
              <Col span={7} className="content-btn">
                {isChangeFormFireBase && (
                  <div className="list-group-button">
                    <FnbButton
                      minWidth="100px"
                      text={translateData.btnCancel}
                      variant="tertiary"
                      onClick={onCancelFireBase}
                    />
                    <FnbButton
                      minWidth="140px"
                      text={translateData.btnSaveChanges}
                      onClick={onSave}
                      permission={PermissionKeys.ADMIN}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row className="google-config-row-margin">
              <Col span={24}>
                <h3 className="fnb-form-label">{translateData.googleConfig.credentialJSON}</h3>
                <Form.Item
                  name={"firebaseCredentialAPIKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.googleConfig.validateFirebase}
                        />
                      ),
                    },
                    {
                      validator: (_, value) => {
                        if (IsJsonString(value) || value === "") {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error(translateData.googleConfig.validateFirebase));
                        }
                      },
                    },
                  ]}
                >
                  <FnbTextArea rows={29} placeholder={translateData.googleConfig.placeholderFirebase}></FnbTextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    );
  };

  // SMS | ZALO ZNS
  const renderProviderSMSSetting = () => {
    return (
      <Col span={24}>
        <Card className="google-config-card fnb-card">
          <Form autoComplete="off" onChange={onChangeFormGoogle} form={formSMS}>
            <Row className="card-title-box">
              <Col span={17} className="d-flex-align-center content-help">
                <div className="icon-help">
                  <GeneralConfigInfoCircle />
                </div>
                <div className="auto-save-title">{translateData.sms.tooltip}</div>
              </Col>
              <Col span={7} className="content-btn">
                {isChangeFormSMS && (
                  <div className="list-group-button">
                    <FnbButton
                      minWidth="100px"
                      text={translateData.btnCancel}
                      variant="tertiary"
                      onClick={onCancelSMS}
                    />
                    <FnbButton
                      minWidth="140px"
                      text={translateData.btnSaveChanges}
                      onClick={onSaveSMS}
                      permission={PermissionKeys.ADMIN}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row gutter={[28, 28]}>
              <Col span={24}>
                <h3 className="fnb-form-label">
                  {translateData.googleConfig.apiKey}
                  <span className="required-field">*</span>
                </h3>
                <Form.Item
                  name={"apiKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: true,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.pleaseEnterApiKey}
                        />
                      ),
                    },
                    {
                      type: "string",
                      max: 100,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.max100Characters}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type="password"
                    autoComplete={false}
                    placeholder={translateData.sms.enterApiKey}
                    maxLength={101}
                    className={"google-config-input"}
                    disabled={disableInput()}
                  />
                </Form.Item>
                <Form.Item name={"storeESMSConfigId"} hidden={true}>
                  <FnbInput />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h3 className="fnb-form-label">
                  {translateData.googleConfig.secretKey}
                  <span className="required-field">*</span>
                </h3>
                <Form.Item
                  name={"secretKey"}
                  className="form-google-item"
                  rules={[
                    {
                      required: initConfigData?.iOSAppAPIKey ? true : false,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.googleConfig.secretKeyErrorMessage}
                        />
                      ),
                    },
                    {
                      type: "string",
                      max: 100,
                      message: (
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={translateData.sms.max100Characters}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    type="password"
                    autoComplete={false}
                    placeholder={translateData.sms.enterSecretKey}
                    maxLength={101}
                    className={"google-config-input"}
                    disabled={disableInput()}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    );
  };

  return (
    <div className="google-config-page">
      <div className="left-content">{renderProvider()}</div>
      <div className="right-content">
        {tab.GOOGLE === currentTab && renderProviderSetting()}
        {tab.FIREBASE === currentTab && renderProviderFireBaseSetting()}
        {/* Hide for US 77562 */}
        {/* {tab.SMS === currentTab && renderProviderSMSSetting()} */}
      </div>
      <DeleteConfirmComponent
        title={translateData.leaveDialog.confirmation}
        content={translateData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={translateData.leaveDialog.discardBtn}
        okText={translateData.leaveDialog.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={onOk}
        isChangeForm={isChangeForm}
      />
    </div>
  );
}

export default GoogleConfig;
