import "./fnb-upload-multiple-images.scss";
import { FnbUploadImageComponent } from "components/fnb-upload-image/fnb-upload-image.component";
import { AddCircleIcon, UploadLogoIcon } from "constants/icons.constants";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Carousel } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const POSITION_PREVIEW_IMAGE = {
  CENTER: "center",
  LEFT: "left",
};

const STATUS_UPLOAD = {
  IDLE: "idle",
  DELETED: "deleted",
  UPLOADED: "uploaded",
};

const DEFAULT_IMAGE_ITEM = {
  url: "",
  status: STATUS_UPLOAD.IDLE,
  parentId: null,
};

/* 
Usage: 
  //Region if there is a default url image to display
  const listFullScreenUploadRef = useRef(null);
  const initData = async () => {
    ...
    listFullScreenUploadRef.current.setImages(newListThumbnailFullScreen);
  }
  // End region
 <UploadImage
    positionPreview={POSITION_PREVIEW_IMAGE.CENTER}
    title="Full screen banner (1600 x 1200)"
    maxItems={5}
    ref={listFullScreenUploadRef}
    setIsChangeData={setIsChangeData}
    parentId={CustomerScreenType.FULL_SCREEN.toString()}
  />
*/

const FnbUploadMultipleImages = forwardRef((props, ref) => {
  const {
    title,
    imageOverlayFront = "",
    positionPreview = POSITION_PREVIEW_IMAGE.CENTER,
    maxItems = 1,
    parentId = null, // if you use more than 1 component FnbUploadMultipleImages in 1 page
    // -> you should add a parentId to avoid mutual impact
    setIsChangeData, // check if uploaded data has changed 
    //-> use case when you need to show dialog "Data has changed, are you sure you want to exit?"
    isShowPreview = true,
    buttonText = "Upload image",
    descriptionForDisplay = undefined,
    isDisabled = false,
    minHeight = 296,
    hideCaptionWhenUploadDone = false,
  } = props;
  const [isInitial, setIsInitial] = useState(true);
  // listImageUpload type => [{url: 'string'}, {url: 'string'}]
  const [listImageUpload, setListImageUpload] = useState([{ ...DEFAULT_IMAGE_ITEM, parentId: parentId }]);
  const listImageRef = useRef([]);

  const { t } = useTranslation();

  const pageData = {
    displayDimension: t("registerAccount.logo.subTitle1"),
    acceptType: t("registerAccount.logo.textNonImage"),
    upToQuantityImages: t("registerAccount.logo.upToQuantityImages"),
    preview: t("onlineStore.preview"),
    defaultDescriptionForDisplay: t("configuration.customerScreen.displayWhenNoOrder"),
  };
  useImperativeHandle(ref, () => ({
    setImages(listImage) {
      if (listImage?.length > 0) {
        let newListImage = listImage?.map((item) => {
          return {
            url: item.url,
            status: STATUS_UPLOAD.UPLOADED,
            parentId: parentId,
          };
        });

        if (newListImage?.length < maxItems) {
          newListImage.push({ ...DEFAULT_IMAGE_ITEM, parentId: parentId });
        }

        listImageRef.current = listImageRef.current.slice(0, maxItems);
        setListImageUpload([...newListImage]);
        setTimeout(() => {
          for (let i = 0; i < listImage?.length; i++) {
            if (listImage[i].url !== "") {
              listImageRef.current[i].setImage(listImage[i].url);
            }
          }
        }, [50]);
        setIsInitial(false);
      }
    },
    getImages: () => {
      return listImageUpload.filter((item) => item.status === STATUS_UPLOAD.UPLOADED && item.parentId === parentId);
    },
    resetData: (listDefault) => {
      const listUrl = listDefault.map((item) => {
        return item.url;
      });

      let newListImage = [...listImageUpload];
      for (let i = 0; i < newListImage.length; i++) {
        if (listUrl.includes(newListImage[i].url)) {
          newListImage[i].status = STATUS_UPLOAD.UPLOADED;
          listImageRef.current[i].setImage(newListImage[i].url);
        } else {
          newListImage[i].status = STATUS_UPLOAD.DELETED;
        }
      }
      const numberImageUploaded = newListImage.filter((item) => item.status === STATUS_UPLOAD.UPLOADED).length;
      if (numberImageUploaded < maxItems) {
        newListImage.push({ ...DEFAULT_IMAGE_ITEM, parentId: parentId });
      }
      setListImageUpload([...newListImage]);

      if (numberImageUploaded > 0) {
        setIsInitial(false);
      } else {
        setIsInitial(true);
      }
    },
  }));

  const onChangeImage = (image, index) => {
    setIsChangeData && setIsChangeData(true);
    let newListImage = listImageUpload;
    if (image === null) {
      newListImage[index].status = STATUS_UPLOAD.DELETED;
    }

    if (image?.url) {
      newListImage[index].url = image.url;
      newListImage[index].status = STATUS_UPLOAD.UPLOADED;
      newListImage[index].parentId = parentId;
    }

    const isHaveButtonAddNew = newListImage.some((item) => item.status === STATUS_UPLOAD.IDLE);
    const numberUploaded = newListImage.filter((item) => item.status !== STATUS_UPLOAD.DELETED).length;
    if (!isHaveButtonAddNew && numberUploaded < maxItems) {
      newListImage.push({
        url: "",
        status: STATUS_UPLOAD.IDLE,
      });
    }
    const isHaveImageUploaded = newListImage.some((item) => item.status === STATUS_UPLOAD.UPLOADED);
    setIsInitial(!isHaveImageUploaded);
    setListImageUpload([...newListImage]);
  };

  const classNameSectionCaptionUpload = classNames({
    "section-caption-upload": !hideCaptionWhenUploadDone,
    'section-caption-upload-d-none': hideCaptionWhenUploadDone
  })

  return (
    <div className="upload-image-configuration">
      <span className="body-medium-operation">{title}</span>
      <div className="section-upload-configuration">
        <div
          className={`frame-upload-image-configuration ${
            !isInitial ? "frame-upload-image-configuration--uploaded" : "frame-upload-image-configuration--default"
          }`}
          style={{ minHeight: minHeight }}
        >
          <div className="list-button-upload" style={{ display: "flex" }}>
            {listImageUpload.map((item, index) => {
              return (
                <div
                  className={`${
                    item.status === STATUS_UPLOAD.DELETED ? "container-component-upload--display-none" : ""
                  }`}
                >
                  <FnbUploadImageComponent
                    isDisabled={isDisabled}
                    buttonText={`${!isInitial ? "" : buttonText}`}
                    iconUpload={!isInitial ? <AddCircleIcon /> : <UploadLogoIcon />}
                    onChange={(e) => onChangeImage(e, index)}
                    className={`${item.status !== STATUS_UPLOAD.IDLE ? "custom-upload-operation__hide-button" : ""} ${
                      !isInitial && item.status === STATUS_UPLOAD.IDLE ? "custom-upload-operation__add-circle" : ""
                    }`}
                    ref={(el) => (listImageRef.current[index] = el)}
                  />
                </div>
              );
            })}
          </div>

          <div className={classNameSectionCaptionUpload}>
            {!isInitial && (
              <span className="body-2-operation">{descriptionForDisplay ?? pageData.defaultDescriptionForDisplay}</span>
            )}
            <ul className="list-description-upload">
              <li className={`${!isInitial ? "caption-operation" : "caption-upload"}`}>{pageData.displayDimension}</li>
              <li className={`${!isInitial ? "caption-operation" : "caption-upload"}`}>{pageData.acceptType}</li>
              <li className={`${!isInitial ? "caption-operation" : "caption-upload"}`}>
                {pageData.upToQuantityImages.replace("{{quantity}}", maxItems)}
              </li>
            </ul>
          </div>
        </div>
        {isShowPreview && (
          <div className="preview-upload-image">
            <span className="body-medium-operation">{pageData.preview}</span>
            <div className="contain-emulator-preview-image">
              <div className="emulator-preview-image">
                <div className="frame-emulator-preview-image">
                  {!isInitial && (
                    <Carousel autoplay autoplaySpeed={2500} dots={false} className="carousel-preview-image-upload">
                      {listImageUpload
                        ?.filter((item) => item.status === STATUS_UPLOAD.UPLOADED)
                        .map((item) => {
                          return (
                            <img
                              className={`image-preview-simulator ${
                                positionPreview === POSITION_PREVIEW_IMAGE.CENTER
                                  ? "image-preview-simulator--center"
                                  : ""
                              } ${
                                positionPreview === POSITION_PREVIEW_IMAGE.LEFT ? "image-preview-simulator--left" : ""
                              }`}
                              src={item?.url}
                              alt=""
                            />
                          );
                        })}
                    </Carousel>
                  )}
                  {imageOverlayFront && !isInitial && (
                    <img className="image-preview-simulator-overlay__front" src={imageOverlayFront} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default FnbUploadMultipleImages;
