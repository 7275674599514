import { Card, Checkbox, Col, Form, Image, Input, message, Row, Tabs, Tooltip, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { FnbCancelButton } from "components/cancel-button";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbSelectOptions } from "components/fnb-select-options/fnb-select-options";
import { FnbSelect } from "components/fnb-select/fnb-select";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import SelectEditComponent from "components/select-edit-new-item/select-edit-new-item";
import FnbSwitch from "components/switch";

import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import FnbModalCreateTopping from "components/fnb-modal-create-topping/fnb-modal-create-topping.component";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { tableSettings } from "constants/default.constants";
import {
  AddCircleOutlined,
  CustomInfoCircleIcon,
  CustomTickCircleIcon,
  CustomTrashIcon,
  Ellipse,
  ExclamationIcon,
  ImageMaterialDefault,
  LampOn,
  MessageQuestionIcon,
  NewDragIcon,
  TrashFill,
  UploadLogoIcon,
} from "constants/icons.constants";
import { images } from "constants/images.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { Platform } from "constants/platform.constants";
import { StampType } from "constants/stamp-type.constants";
import { currency } from "constants/string.constants";
import taxType from "constants/taxType.constants";
import branchDataService from "data-services/branch/branch-data.service";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import productDataService from "data-services/product/product-data.service";
import stampDataService from "data-services/stamp/stamp-data.service";
import storeDataService from "data-services/store/store-data.service";
import taxDataService from "data-services/tax/tax-data.service";
import unitDataService from "data-services/unit/unit-data.service";
import { StampTemplatePreviewComponent } from "pages/settings/stamp/components/stamp-template-preview.component";
import CreateNewTax from "pages/store/fee-tax/tax/components/create-tax.component";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import languageService from "services/language/language.service";
import theme from "theme";
import {
  checkOnKeyPressValidation,
  executeAfter,
  formatCurrencyWithoutSuffix,
  formatNumberDecimalOrInteger,
  formatterDecimalNumber,
  getCurrency,
  getValidationMessages,
  getValidationMessagesWithParentField,
  hasPermission,
  isDecimalKey,
  parserDecimalNumber,
  randomGuid,
  roundNumber,
} from "utils/helpers";
import productDefaultImage from "../../../assets/images/combo-default-img.jpg";
import { DialogHowToSetupProductRecipes } from "../components/dialogHowToSetUpProductRecipe";
import { DialogHowToSetupProductVariants } from "../components/dialogHowToSetUpProductVariants";
import "../edit-product/edit-product.scss";
import CreateNewOptionManagement from "../option/components/create-new-option-management.component";
import ModalSearchMaterial from "../search-material/ModalSearchMaterial";
import { useModifiedDataLocalStorage } from "hooks/useModifiedDataLocalStorage";
import FnbSuffixHeadingGuideline from "components/fnb-heading-guidline/fnb-suffix-heading-guideline.component";
import { GuidancePurposeType } from "constants/guidance-link.constants";

const { Text } = Typography;
const { TabPane } = Tabs;

export default function CreateProductPage(props) {
  const [t, i18n] = useTranslation();
  const history = useHistory();

  const arrPlatformIdSelected = [
    Platform.POS?.toLowerCase(),
    Platform.StoreWebsite?.toLowerCase(),
    Platform.StoreMobileApp?.toLowerCase(),
  ];

  const [blockNavigation, setBlockNavigation] = useState(false);
  const [image, setImage] = useState(null);
  const [prices, setPrices] = useState([{}]);
  const [initDataOptions, setInitDataOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initDataMaterials, setInitDataMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [listPlatforms, setListPlatforms] = useState([]);
  const [valuePlatforms, setValuePlatforms] = useState(arrPlatformIdSelected);
  const [isPrintStampValue, setIsPrintStampValue] = useState(true);
  const [dataSelectedMaterial, setDataSelectedMaterial] = useState([]);
  const [isUnitNameExisted, setIsUnitNameExisted] = useState(false);
  const [showConfirmTouched, setShowConfirmTouched] = useState(false);
  const [listAllProductCategory, setListAllProductCategory] = useState([]);
  const [listSellingTax, setListSellingTax] = useState([]);
  const [units, setUnits] = useState([]);
  const [nameUnit, setNameUnit] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();
  const [showPaging, setShowPaging] = useState(false);
  const [pageNumber, setPageNumber] = useState(tableSettings.page);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isCreateCompleted, setIsCreateCompleted] = useState(false);
  const [newUnitName, setNewUnitName] = useState(null);
  const [showUnitNameValidateMessage, setShowUnitNameValidateMessage] = useState(false);
  const [newOptionName, setNewOptionName] = useState("");
  const [createOptionName, setCreateOptionName] = useState("");
  const [allproductToppings, setAllProductToppings] = useState([]);
  const [productToppings, setProductToppings] = useState([]);
  const [productToppingSelected, setProductToppingSelected] = useState([]);
  const [isVisibaleProductToppingModal, setIsVisibaleProductToppingModal] = useState(false);
  const [activeKeyInventoryTracking, setActiveKeyInventoryTracking] = useState("0");
  const [defaultActiveKeyInventoryTracking, setDefaultActiveKeyInventoryTracking] = useState("0");
  const [showRecipeMessage, setShowRecipeMessage] = useState(false);
  const [isClickSubmitForm, setIsClickSubmitForm] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth < 500);
  const selectNewItemFuncs = React.useRef(null);
  const storeLogoUrl = useSelector((state) => state?.session?.storeLogo);
  const [stampConfigs, setStampConfigs] = useState();
  const [stampDatas, setStampDatas] = useState();
  const [isOptionNameExisted, setIsOptionNameExisted] = useState(false);
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);
  const data = props.location.state;
  const isTopping = data?.isTopping;
  const [toppingOptionValue, setToppingOptionValue] = useState(false);
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
  const [nameCategory, setNameCategory] = useState("");
  const selectNewCategoryFuncs = React.useRef(null);
  const [isCategoryNameExisted, setIsCategoryNameExisted] = useState(false);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [branchOptionValue, setBranchOptionValue] = useState(false);
  const [disableAllBranches, setDisableAllBranches] = useState(false);
  const [branches, setBranches] = useState([]);
  const [isShowModalSearchMaterial, setIsShowModalSearchMaterial] = useState(false);
  const [isOptionNameSelected, setIsOptionNameSelected] = useState(false);
  const [isNameTaxExisted, setIsNameTaxExisted] = useState(false);
  const [isTaxModalVisible, setIsTaxModalVisible] = useState(false);
  const [nameTax, setNameTax] = useState("");

  const [isShowGuideProductVariants, setIsShowGuideProductVariants] = useState(false);
  const [isShowGuideProductRecipes, setIsShowGuideProductRecipes] = useState(false);
  const [isShowModalAddTopping, setIsShowModalAddTopping] = useState(false);
  const [searchToppingValue, setSearchToppingValue] = useState("");
  const [currencyCode, setCurrencyCode] = useState(false);

  const [storeInfo, setStoreInfo] = useState({});
  const { needToReloadIngredients, updateLastTimeGetIngredients } = useModifiedDataLocalStorage();

  useEffect(() => {
    getInitData();
    getInitialStampData();
    getBranches();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [form] = Form.useForm();
  const [formAddCategory] = Form.useForm();
  const pageData = {
    title: t("productManagement.createFoodOrDrink"),
    createToppingTitle: t("productManagement.createNewTopping"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.create"),
    btnAddNew: t("button.addNew"),
    btnCreateNewProduct: t("productManagement.btnCreateNewProduct"),
    btnCreateNewTopping: t("productManagement.btnCreateNewTopping"),
    btnIgnore: t("productManagement.btnIgnore"),
    btnContinueCreate: t("productManagement.btnContinueCreate"),
    productInformation: {
      title: t("productManagement.productInformation.title"),
      name: {
        label: t("productManagement.productInformation.name"),
        placeholder: t("productManagement.productInformation.namePlaceholder"),
        required: true,
        maxLength: 100,
        validateMessage: t("productManagement.productInformation.nameValidateMessage"),
        toppingName: t("productManagement.productInformation.toppingName"),
        toppingNamePlaceholder: t("productManagement.productInformation.toppingNamePlaceholder"),
        toppingValidateMessage: t("productManagement.productInformation.toppingValidateMessage"),
      },
      totalProductCost: t("productManagement.productInformation.totalProductCost"),
    },
    generalInformation: {
      title: t("productManagement.generalInformation.title"),
      name: {
        label: t("productManagement.generalInformation.name"),
        placeholder: t("productManagement.generalInformation.namePlaceholder"),
        required: true,
        maxLength: 100,
        validateMessage: t("productManagement.generalInformation.nameValidateMessage"),
      },
      description: {
        label: t("productManagement.generalInformation.description"),
        placeholder: t("productManagement.description.placeholder"),
        required: false,
        maxLength: 255,
      },
      uploadImage: t("productManagement.generalInformation.uploadImages"),
    },
    pricing: {
      pricesAndVariations: t("productManagement.pricing.pricesAndVariations"),
      productPrice: t("productManagement.pricing.productPrice"),
      title: t("productManagement.pricing.price"),
      addPrice: t("productManagement.pricing.addPrice"),
      price: {
        label: t("productManagement.pricing.price"),
        placeholder: t("productManagement.pricing.pricePlaceholder"),
        required: true,
        max: 999999999,
        min: 0,
        format: "^[0-9]*$",
        validateMessage: t("productManagement.pricing.priceRange"),
      },
      priceName: {
        label: t("productManagement.pricing.priceName"),
        placeholder: t("productManagement.pricing.priceNamePlaceholder"),
        required: true,
        maxLength: 100,
        validateMessage: t("productManagement.pricing.priceNameValidateMessage"),
      },
    },
    unit: {
      title: t("productManagement.unit.title"),
      unitPlaceholder: t("productManagement.unit.placeholder"),
      pleaseSelectUnit: t("productManagement.unit.pleaseSelectUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseEnterNameUnit"),
      unitNameExisted: t("productManagement.unit.unitNameExisted"),
      recipe: t("productManagement.unit.recipe"),
    },
    optionInformation: {
      title: t("productManagement.option.title"),
      selectOption: t("productManagement.option.placeholder"),
      pleaseSelectOption: t("option.pleaseSelectOption"),
      defaultOptionText: t("option.defaultOptionText"),
    },
    inventoryTracking: {
      title: t("inventoryTracking.title"),
      byMaterial: t("inventoryTracking.byMaterial"),
      totalCost: t("table.totalCost"),
      pleaseEnterQuantity: t("inventoryTracking.pleaseEnterQuantity"),
      selectMaterial: t("inventoryTracking.selectMaterial"),
      pleaseSelectMaterial: t("inventoryTracking.pleaseSelectMaterial"),
      quantityMoreThanZero: t("inventoryTracking.quantityGreaterThanZero"),
      pleaseSetupRecipe: t("inventoryTracking.pleaseSetupRecipe"),
      table: {
        materialName: t("productManagement.recipe.table.ingredients"),
        quantity: t("productManagement.recipe.table.quantity"),
        unit: t("table.unit"),
        cost: t("productManagement.recipe.table.costPerUnit", { currency: getCurrency() }),
        totalCost: t("productManagement.recipe.table.totalCost", { currency: getCurrency() }),
        action: t("table.action"),
      },
      recipeTip: t("productManagement.recipe.recipeTip"),
    },
    productCategory: {
      label: t("productManagement.category.title"),
      placeholder: t("productManagement.category.placeholderSearch"),
      pleaseEnterNameCategory: t("materialCategory.pleaseEnterNameCategory"),
      nameCategoryExisted: t("materialCategory.nameCategoryExisted"),
      pleaseSelectCategory: t("productManagement.category.pleaseSelectCategory"),
      enterNameCategory: t("productManagement.category.enterNameCategory"),
      nameCategoryPlaceholder: t("productManagement.category.nameCategoryPlaceholder"),
    },
    productNameExisted: t("productManagement.productNameExisted"),
    fileSizeLimit: t("productManagement.fileSizeLimit"),
    productAddedSuccess: t("productManagement.productAddedSuccess"),
    topping: t("productManagement.isTopping"),
    includeTopping: t("productManagement.includeTopping"),
    allToppings: t("productManagement.allToppings"),
    toppingPlaceholder: t("productManagement.toppingPlaceholder"),
    tax: t("table.tax"),
    pleaseSelectTax: t("productManagement.tax.placeholder"),
    productImage: {
      title: t("productManagement.productImage.title"),
    },
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    platform: {
      title: t("productManagement.platform.title"),
    },
    upload: {
      addFromUrl: t("upload.addFromUrl"),
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    btnAddNewUnit: t("productManagement.unit.btnAddNewUnit"),
    table: {
      name: t("productManagement.table.name"),
      action: t("productManagement.table.action"),
    },
    addTopping: t("productManagement.addTopping"),
    toppingSelected: t("productManagement.toppingSelected"),
    selectToppings: t("productManagement.selectToppings"),
    material: {
      minQuantity: 0.01,
      maxQuantity: 999999999,
    },
    bestDisplayImage: t("messages.imageBestDisplay"),
    validateMinQtyMessage: t("productManagement.recipe.quantityValidateMessage"),
    othersSetting: {
      title: t("productManagement.otherSetting.title"),
      isPrintStampTitle: t("productManagement.otherSetting.isPrintStampTitle"),
      stampPreviewDescription: t("productManagement.otherSetting.stampPreviewDescription"),
    },
    addProductCategory: t("productCategory.addProductCategory"),
    branch: {
      title: t("productCategory.branch.title"),
      all: t("productCategory.branch.all"),
      displayAll: t("productCategory.branch.displayAll"),
      displaySpecific: t("productCategory.branch.displaySpecific"),
      placeholder: t("payment.branchPlaceholder"),
      validateMessage: t("productCategory.branch.selectBranchValidateMessage"),
    },
    productCategoryAddedSuccess: t("productCategory.productCategoryAddedSuccess"),
    priority: {
      title: t("productCategory.priority.title"),
      placeholder: t("productCategory.priority.placeholder"),
      validateMessage: t("productCategory.priority.validateMessage"),
      tooltip: t("productCategory.priority.tooltip"),
    },
    cancel: t("button.cancel"),
    categoryName: t("productCategory.categoryName"),
    createCategory: t("productCategory.createCategory"),
    searchMaterial: t("productManagement.recipe.placeholder"),
    ingredientSearch: t("material.ingredientSearch"),
    taxLabel: t("table.tax"),
    hint: {
      howToSetUpProductVariants: t("productManagement.hint.howToSetUpProductVariants"),
      howToSetUpProductRecipes: t("productManagement.hint.howToSetUpProductRecipes"),
      howToSetUpRecipes: t("productManagement.hint.howToSetUpRecipes"),
      optionDescriptionTooltip: t("productManagement.hint.optionDescriptionTooltip"),
      POSCashRegister: t("productManagement.hint.platformGuide.POSCashRegister"),
      POSDevices: t("productManagement.hint.platformGuide.POSDevices"),
      POSServing: t("productManagement.hint.platformGuide.POSServing"),
      storeApp: t("productManagement.hint.platformGuide.storeApp"),
      storeWebIs: t("productManagement.hint.platformGuide.storeWebIs"),
      storeWebLink: t("productManagement.hint.platformGuide.storeWebLink"),
      storeWebExample: t("productManagement.hint.platformGuide.storeWebExample"),
    },
  };

  useEffect(() => {
    handleLanguageChange();
  }, [i18n.language]);

  const getInitialStampData = async () => {
    const stampData = createStampMockupData();
    let res = await stampDataService.getStampConfigByStoreIdAsync();
    if (res) {
      let formValue = undefined;
      if (res.stampConfig !== undefined) {
        let data = res?.stampConfig;
        formValue = {
          stampType: data?.stampType,
          isShowLogo: data?.isShowLogo,
          isShowTime: data?.isShowTime,
          isShowNumberOfItem: data?.isShowNumberOfItem,
          isShowNote: data?.isShowNote,
          isShowArea: data?.isShowArea,
          isShowAreaTable: data?.isShowAreaTable,
        };

        form.setFieldsValue(formValue);
      } else {
        formValue = {
          stampType: StampType.mm50x30,
          isShowLogo: true,
          isShowTime: true,
          isShowNumberOfItem: true,
          isShowNote: true,
          isShowArea: true,
          isShowAreaTable: true,
        };

        form.setFieldsValue(formValue);
      }
      setTimeout(() => {
        stampData.logo = storeLogoUrl;
      }, 300);
      setTimeout(() => {
        setStampConfigs(formValue);
        setStampDatas(stampData);
      }, 500);
    }
  };

  const createStampMockupData = () => {
    const stampData = {
      code: "#I1003",
      logo: storeLogoUrl,
      createdTime: "2022-07-06 10:03:41.6983432",
      areaName: "VIP",
      areaTableName: "V01",
      itemList: [
        {
          no: "1",
          name: "Coffee",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
            {
              name: "Ice",
              value: "50%",
            },
            {
              name: "Pudding",
              value: "x122",
            },
          ],
          current: true,
        },
        {
          no: "2",
          name: "Milk tea",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
          ],
          current: false,
        },
      ],
    };

    return stampData;
  };

  // validate form again if clicked submit form and change language
  const handleLanguageChange = () => {
    if (isClickSubmitForm) {
      setShowRecipeMessage(true);
      form.validateFields();
    }
  };

  const getInitData = async () => {
    let resPlatform = await storeDataService.getAllPlatformActivatedAsync(true);
    if (resPlatform) {
      setListPlatforms(resPlatform?.platforms);
    }

    await getUnits();
    await getDataInitialCreateProduct();
  };

  const getDataInitialCreateProduct = async () => {
    const resDataInitialCreateProduct = await productDataService.getInitialDataCreateProduct();
    if (resDataInitialCreateProduct) {
      const materials = resDataInitialCreateProduct?.materials;
      const allProductCategories = resDataInitialCreateProduct?.allProductCategories;
      const taxesByType = resDataInitialCreateProduct?.taxesByType;
      const options = resDataInitialCreateProduct?.options;
      const toppings = resDataInitialCreateProduct?.toppings;

      if (materials) {
        setMaterials(materials);
        setInitDataMaterials(materials);
      }

      if (allProductCategories) {
        setListAllProductCategory(allProductCategories);
      }

      if (taxesByType) {
        setListSellingTax(
          taxesByType.map((tax) => ({
            id: tax.id,
            name: tax?.formatName,
            originalName: tax?.name,
          })),
        );
      }

      if (options) {
        setOptions(options);
        setInitDataOptions(options);
      }

      if (toppings) {
        setAllProductToppings(toppings);
      }
    }
  };

  const getInitMaterial = async () => {
    const resDataInitialCreateProduct = await productDataService.getInitialDataCreateProduct();
    if (resDataInitialCreateProduct) {
      const materials = resDataInitialCreateProduct?.materials;
      if (materials) {
        setMaterials(materials);
        setInitDataMaterials(materials);
        updateLastTimeGetIngredients();
      }
    }
  };

  useEffect(() => {
    if (needToReloadIngredients) {
      getInitMaterial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadIngredients]);

  const getUnits = async () => {
    var resUnit = await unitDataService.getUnitsAsync();
    if (resUnit) {
      setUnits(resUnit.units);
    }
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const onSubmitForm = async () => {
    setIsClickSubmitForm(true);
    setShowRecipeMessage(true);
    await form
      .validateFields()
      .then(async () => {
        if (dataSelectedMaterial?.length < prices?.length) {
          let position = 0;
          prices?.find((price) => {
            const isExist = dataSelectedMaterial?.find((material) => material?.position === price?.position);
            if (typeof isExist !== "object") {
              position = price?.position;
              return true;
            }
          });
          getTotalCost(`${position}`, true);
          scrollToElement("error-recipe-message");
          return;
        }
        const name = form.getFieldValue(["product", "name"]);
        await productDataService.checkExistNameAsync({ name }).then((res) => {
          if (res) {
            setShowConfirmTouched(true);
          } else {
            createProduct();
          }
        });
      })
      .catch((errors) => {
        if (errors?.errorFields?.length > 0) {
          const elementId = `basic_${errors?.errorFields[0]?.name.join("_")}_help`;
          scrollToElement(elementId);
          let tabPanelMaterialErr = [];
          let otherErr = [];
          errors?.errorFields?.map((errorItem) => {
            let checkMaterialQuantityErr = errorItem?.name?.find((x) => x === "material");
            if (checkMaterialQuantityErr) {
              tabPanelMaterialErr.push(errorItem);
            } else {
              otherErr.push(errorItem);
            }
          });
          if (tabPanelMaterialErr?.length > 0 && otherErr?.length === 0) {
            message.error(
              tabPanelMaterialErr?.[0]?.errors?.[0]?.props?.message ?? tabPanelMaterialErr?.[0]?.errors?.[0],
            );
            setActiveKeyInventoryTracking(tabPanelMaterialErr[0]?.name[3]?.toString());
            let nameInputFirst = `basic_${tabPanelMaterialErr[0]?.name?.join("_")}`;
            scrollToElement(nameInputFirst);
          }
        }
      });
  };

  const createProduct = async () => {
    form.validateFields().then(async (values) => {
      let optionIds = [];
      selectedOptions.map((o) => {
        optionIds.push(o.id);
      });
      const createProductRequestModel = {
        units: units,
        ...values.product,
        optionIds: optionIds,
        image: image?.url,
        isTopping: isTopping,
        platformIds: valuePlatforms,
        productToppingIds: toppingOptionValue
          ? []
          : productToppings?.map(function (item) {
              return item["id"];
            }),
        isPrintStamp: isPrintStampValue,
        isIncludedAllToppings: toppingOptionValue,
      };

      if (
        createProductRequestModel.prices > 0 ||
        (createProductRequestModel.materials && createProductRequestModel.materials.priceName.length > 0)
      ) {
        if (createProductRequestModel.prices) {
          createProductRequestModel.prices.map((item, index) => {
            item.materials = createProductRequestModel.materials.priceName[index].material;
          });
          createProductRequestModel.materials = null;
        } else {
          createProductRequestModel.materials = createProductRequestModel.materials.priceName[0].material;
        }

        productDataService
          .createProductAsync(createProductRequestModel)
          .then((res) => {
            if (res?.succeeded) {
              message.success(pageData.productAddedSuccess);
              onCompleted();
            }
          })
          .catch((errs) => {
            form.setFields(getValidationMessagesWithParentField(errs, "product"));
          });
      } else {
        scrollToElement("receipe-message");
      }
    });
  };

  const onConfirmTouched = async () => {
    setShowConfirmTouched(false);
    createProduct();
  };

  const onCancelTouched = () => {
    setShowConfirmTouched(false);
  };

  const onOptionSearch = (value) => {
    const checkOptionSelected =
      selectedOptions
        ?.filter((o) => o !== undefined)
        ?.filter((u) => u?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())?.length > 0;
    if (checkOptionSelected) {
      setIsOptionNameSelected(true);
      setIsOptionNameExisted(true);
    } else {
      setIsOptionNameExisted(
        options
          ?.filter((o) => o !== undefined)
          ?.filter((u) => u?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())?.length > 0,
      );
      setIsOptionNameSelected(false);
    }

    setNewOptionName(value);
  };

  const onCancelAddNewFormOption = async () => {
    setShowAddNewOptionForm(false);
    setNewOptionName("");
    setCreateOptionName("");
  };

  const onSuccessAddNewFormOption = async (newOptionId, optionLevel) => {
    setShowAddNewOptionForm(false);
    let newOption = { id: newOptionId, name: createOptionName, optionLevel: optionLevel };
    let listNewOption = options;
    listNewOption?.push(newOption);

    if (listNewOption) {
      setOptions(listNewOption);
      setInitDataOptions(listNewOption);
      addSelectOption(newOptionId, listNewOption);
    }
    setNewOptionName("");
    setCreateOptionName("");
  };

  const addSelectOption = (value, newOptions) => {
    setIsChangeForm(true);
    let selectedOption = newOptions.find((o) => o.id === value);
    let restOptions = newOptions.filter((o) => o.id !== value);
    setOptions(restOptions);
    selectedOption && selectedOptions.push(selectedOption);
  };

  const onAddNewOption = () => {
    hasPermission(PermissionKeys.CREATE_OPTION) ? setShowAddNewOptionForm(true) : setShowAddNewOptionForm(false);
    setCreateOptionName(newOptionName);
  };

  const renderCreateDuplicateName = () => {
    const productName = form.getFieldValue(["product", "name"]);
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: t("productManagement.confirmCreateDuplicateName", { productName: `"${productName}"` }),
        }}
      />
    );
  };

  const onSelectOption = (key) => {
    setIsChangeForm(true);
    let selectedOption = options.filter((o) => o !== undefined)?.find((o) => o.id === key);
    let restOptions = options.filter((o) => o !== undefined)?.filter((o) => o.id !== key);
    setOptions(restOptions);
    selectedOptions.push(selectedOption);
  };

  const onDeleteSelectedOption = (key) => {
    let restOptions = selectedOptions.filter((o) => o.id !== key);
    setSelectedOptions(restOptions);

    let initDataOption = initDataOptions.find((o) => o.id === key);
    options.push(initDataOption);
  };

  const onChangeImage = (file) => {
    setImage(file);
  };

  const onClickAddPrice = () => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;

    const newPrice = {
      position: prices.length || 0,
      id: randomGuid(),
      isPriceBelongsCombo: false,
      name: "",
      price: "",
    };
    if (prices.length === 1) {
      const price = {
        position: 0,
        id: randomGuid(),
        isPriceBelongsCombo: false,
        name: "",
        price: product?.price || "",
      };
      prices[0] = price;
    }
    const listPrice = [...(product?.prices ?? prices), newPrice];
    product.prices = listPrice;
    setPrices(listPrice);
    form.setFieldsValue(formValue);
    setTimeout(() => {
      const dragDropPrices = document.getElementById("dragDropPrices");
      dragDropPrices.scrollTop = dragDropPrices.scrollHeight;
    }, 100);
  };

  const onDeletePrice = (index) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;

    if (product.prices.length > 0) {
      product.prices.splice(index, 1);
      product?.materials?.priceName?.splice(index, 1);
      product.prices.forEach((item, index) => (item.position = index));
    }

    setPrices(product.prices);
    if (product.prices.length === 1) {
      product.price = product.prices[0].price;
      product.prices[0].position = 0;
    }
    setActiveKeyInventoryTracking("0");
    form.setFieldsValue(formValue);

    // Update selected materials
    const newSelectedMaterial = dataSelectedMaterial
      ?.filter((variant) => variant?.position !== index)
      ?.map((variant, index) => ({ ...variant, position: index }));
    setDataSelectedMaterial(newSelectedMaterial);
  };

  //Enter Unit name and check existed
  const onNameChange = (value) => {
    if (units.filter((u) => u.name.trim() === value.trim()).length > 0) {
      setIsUnitNameExisted(true);
    } else {
      setIsUnitNameExisted(false);
    }
    setNameUnit(value);
  };

  const onChangeOption = (id) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.unitId = id;
    setUnitName(units?.find((u) => u.id === id)?.name);
    form.setFieldsValue(formValue);
    if (selectNewItemFuncs.current) {
      selectNewItemFuncs.current(id);
    }
  };

  const onChangePlatform = (values) => {
    setValuePlatforms(values);
  };

  //Handle add New Unit
  const addNewUnit = async (e) => {
    if (nameUnit) {
      e.preventDefault();
      let req = {
        name: nameUnit,
      };
      let res = await unitDataService.createUnitAsync(req);
      if (res.isSuccess) {
        let newItem = {
          id: res.id,
          name: res.name,
        };
        setUnits([newItem, ...units]);
        let formValue = form.getFieldsValue();
        let { product } = formValue;
        product.unitId = res.id;
        setUnitName(res.name);
        form.setFieldsValue(formValue);
        if (selectNewItemFuncs.current) {
          selectNewItemFuncs.current(res.id);
          setIsUnitNameExisted(true);
        }
      } else {
        message.error(pageData.unit.unitNameExisted);
      }
    }
  };

  //Enter category name and check existed
  const onCategoryNameChange = (value) => {
    if (listAllProductCategory.filter((u) => u.name.trim() === value.trim()).length > 0) {
      setIsCategoryNameExisted(true);
    } else {
      setIsCategoryNameExisted(false);
    }
    setNameCategory(value);
  };

  const onChangeCategory = (id) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.productCategoryId = id;
    setNameCategory(listAllProductCategory?.find((u) => u.id === id)?.name);
    form.setFieldsValue(formValue);
    if (selectNewCategoryFuncs.current) {
      selectNewCategoryFuncs.current(id);
    }
  };

  const showModalAddCategory = async (e) => {
    let productCategory = document.getElementById("productCategory");
    if (productCategory) {
      productCategory.blur();
    }
    formAddCategory.resetFields();
    setBranchOptionValue(false);
    setDisableAllBranches(false);
    formAddCategory.setFieldValue("name", nameCategory);
    setTimeout(function () {
      setIsCategoryModalVisible(true);
    }, 500);
  };
  const addNewCategory = async (e) => {
    e.preventDefault();
    formAddCategory.validateFields().then(async (values) => {
      const createProductCategoryRequestModel = {
        name: values.name,
        isDisplayAllBranches: branchOptionValue,
        products: [],
        storeBranchIds: values.storeBranchIds,
        priority: values.priority,
      };
      productCategoryDataService
        .createProductCategoryAsync(createProductCategoryRequestModel)
        .then((res) => {
          if (res) {
            message.success(pageData.productCategoryAddedSuccess);
            setIsCategoryModalVisible(false);
            setBranchOptionValue(false);
            setDisableAllBranches(false);
            let newItem = {
              id: res.id,
              name: res.name,
            };
            setListAllProductCategory([newItem, ...listAllProductCategory]);
            setNameCategory(res.name);
            let formValue = form.getFieldsValue();
            let { product } = formValue;
            product.productCategoryId = res.id;
            form.setFieldsValue(formValue);
            setIsCategoryNameExisted(true);
            if (selectNewCategoryFuncs.current) {
              selectNewCategoryFuncs.current(res.id);
            }
          }
        })
        .catch((errs) => {
          formAddCategory.setFields(getValidationMessages(errs));
        });
    });
  };

  const getBranches = async () => {
    var res = await branchDataService.getAllBranchsAsync();
    if (res) {
      setBranches(res.branchs);
    }
  };

  const onChangeBranchOption = (e) => {
    const isChecked = e.target.checked;
    setBranchOptionValue(isChecked);
    setDisableAllBranches(isChecked);
  };

  const renderSelectBranch = () => {
    return (
      <>
        <h3 className="fnb-form-label">
          {pageData.branch.title}
          <span className="text-danger">*</span>
        </h3>
        <div className="material-check-box-select-all-branch">
          <FnbCheckBox onChange={(event) => onChangeBranchOption(event)} checked={branchOptionValue}>
            {pageData.branch.all}
          </FnbCheckBox>
        </div>
        <Form.Item
          hidden={disableAllBranches}
          name="storeBranchIds"
          className="last-item"
          rules={[
            {
              required: !disableAllBranches,
              message: pageData.branch.validateMessage,
            },
          ]}
        >
          <FnbSelect
            mode="multiple"
            placeholder={pageData.branch.placeholder}
            allowClear
            options={branches?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          ></FnbSelect>
        </Form.Item>
        <Form.Item hidden={!disableAllBranches} className="last-item">
          <FnbSelect disabled={true}></FnbSelect>
        </Form.Item>
      </>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => (item.position = index));
    return result;
  };

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const listPrice = reorder(product.prices, sourceIndex, destinationIndex);
    product.prices = listPrice;
    setPrices(listPrice);

    const tempPriceName = product.materials.priceName[sourceIndex];
    product.materials.priceName[sourceIndex] = product.materials.priceName[destinationIndex];
    product.materials.priceName[destinationIndex] = tempPriceName;

    const listMaterials = dataSelectedMaterial?.map((material) => {
      if (material.position === sourceIndex) {
        return {
          ...material,
          position: destinationIndex,
        };
      }
      if (material.position === destinationIndex) {
        return {
          ...material,
          position: sourceIndex,
        };
      }
      return material;
    });
    setDataSelectedMaterial(listMaterials);

    form.setFieldsValue(formValue);
  };

  const onChangeOptionTax = (id) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.taxId = id;
    form.setFieldsValue(formValue);
  };

  const onNameTaxChange = (value) => {
    if (
      listSellingTax?.filter((u) => u?.originalName?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())?.length > 0
    ) {
      setIsNameTaxExisted(true);
    } else {
      setIsNameTaxExisted(false);
    }
    setNameTax(value);
  };

  const addNewTaxDialog = async (e) => {
    setTimeout(function () {
      setIsTaxModalVisible(true);
    }, 500);
  };

  const handleCompletedTaxModal = (value) => {
    onChangeOptionTax(value);
    taxDataService.getAllTaxByTaxTypeAsync(taxType.SellingTax).then((res) => {
      if (res) {
        setListSellingTax(
          res.taxesByType.map((tax) => ({
            id: tax.id,
            name: tax?.formatName,
            originalName: tax?.name,
          })),
        );
      }
    });
    setIsTaxModalVisible(false);
  };

  const handleCancelTaxModal = () => {
    setIsTaxModalVisible(false);
  };

  const renderPrices = () => {
    const addPriceButton = (
      <div style={{ display: "flex", gap: 4 }}>
        <AddCircleOutlined width={20} height={20} color="#50429B" />
        <FnbTypography.Link
          style={{ textDecoration: "underline" }}
          text={pageData.pricing.addPrice}
          onClick={onClickAddPrice}
        ></FnbTypography.Link>
      </div>
    );

    const singlePrice = (
      <>
        <Row>
          <Col span={24}>
            <h4 className="fnb-form-label">
              {pageData.pricing.price.label}
              <span className="text-danger">*</span>
            </h4>
            <Form.Item
              name={["product", "price"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={pageData.pricing.price.validateMessage}
                    />
                  ),
                },
                () => ({
                  validator(_, value) {
                    if (value && (value <= 0 || value >= 1e9)) {
                      return Promise.reject(
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.pricing.price.validateMessage}
                        />,
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <FnbInputNumber
                className="w-100 fnb-input-number"
                placeholder={pageData.pricing.price.placeholder}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                addonAfter={getCurrency()}
                precision={getCurrency() === currency.vnd ? 0 : 2}
                id="product-price"
                onKeyPress={(event) => {
                  const checkValidKey = checkOnKeyPressValidation(
                    event,
                    "product-price",
                    0,
                    null,
                    getCurrency() === currency.vnd ? 0 : 2,
                  );
                  if (!checkValidKey) event.preventDefault();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="mt-2" hidden={isTopping}>
              {addPriceButton}
            </div>
          </Col>
        </Row>
      </>
    );

    const multiplePrices = (
      <>
        <DragDropContext className="mt-4" onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="list-price">
                <div
                  id="dragDropPrices"
                  style={prices.length >= 6 ? { height: 500, overflowY: "scroll" } : { minHeight: prices.length * 70 }}
                >
                  <div style={{ minHeight: prices.length * 70 }}>
                    {prices.map((price, index) => {
                      return (
                        <Draggable key={price.id} draggableId={price.id} index={index}>
                          {(provided) => (
                            <Row
                              className={`mb-4 pointer price-item`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Col span={24} className="col-title">
                                <NewDragIcon className="title-center drag-icon" width={16} height={16} />
                                <Row className="mt-10 w-100">
                                  <Col span={isMobileSize ? 19 : 22}>
                                    <Row gutter={[16, 16]}>
                                      <Col xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item
                                          name={["product", "prices", price.position, "position"]}
                                          hidden={true}
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item name={["product", "prices", price.position, "id"]} hidden={true}>
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={["product", "prices", price.position, "isPriceBelongsCombo"]}
                                          hidden={true}
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={["product", "prices", price.position, "name"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: (
                                                <div className="mt-2">
                                                  <InputValidateMessage
                                                    type={EnumInputValidateType.ERROR}
                                                    message={pageData.pricing.priceName.validateMessage}
                                                  />
                                                </div>
                                              ),
                                            },
                                          ]}
                                        >
                                          <Input
                                            className="fnb-input"
                                            onBlur={(evt) => onInputPriceName(price.position, evt)}
                                            placeholder={pageData.pricing.priceName.placeholder}
                                            maxLength={pageData.pricing.priceName.maxLength}
                                            disabled={price?.isPriceBelongsCombo}
                                            id={`product-prices-${price.position}-name`}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item
                                          name={["product", "prices", price.position, "price"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: (
                                                <div className="mt-2">
                                                  <InputValidateMessage
                                                    type={EnumInputValidateType.ERROR}
                                                    message={pageData.pricing.price.validateMessage}
                                                  />
                                                </div>
                                              ),
                                            },
                                            () => ({
                                              validator(_, value) {
                                                if (value && (value <= 0 || value >= 1e9)) {
                                                  return Promise.reject(
                                                    <InputValidateMessage
                                                      type={EnumInputValidateType.ERROR}
                                                      message={pageData.pricing.price.validateMessage}
                                                    />,
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]}
                                        >
                                          <FnbInputNumber
                                            className="fnb-input-number w-100"
                                            placeholder={pageData.pricing.price.placeholder}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            addonAfter={getCurrency()}
                                            precision={getCurrency() === currency.vnd ? 0 : 2}
                                            onKeyPress={(event) => {
                                              const checkValidKey = checkOnKeyPressValidation(
                                                event,
                                                `product-prices-${price.position}-price`,
                                                0,
                                                null,
                                                getCurrency() === currency.vnd ? 0 : 2,
                                              );
                                              if (!checkValidKey) event.preventDefault();
                                            }}
                                            disabled={price?.isPriceBelongsCombo}
                                            id={`product-prices-${price.position}-price`}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={isMobileSize ? 5 : 2} className="icon-delete-price">
                                    <span
                                      className="m-2"
                                      hidden={price?.isPriceBelongsCombo}
                                      onClick={() => onDeletePrice(price.position)}
                                    >
                                      <FnbTooltip
                                        hideTooltip={isTouchDevice}
                                        disabledStopPropagation={isTouchDevice}
                                        placement="top"
                                        title={t("button.delete")}
                                      >
                                        <CustomTrashIcon color="#50429B" />
                                      </FnbTooltip>
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Col span={24}>
          <div className="mt-2" hidden={isTopping}>
            {addPriceButton}
          </div>
        </Col>
      </>
    );

    return (
      <>
        {prices.length === 1 && singlePrice}
        {prices.length > 1 && multiplePrices}

        <Row className="w-100 mt-3" gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <h4 className="fnb-form-label">{pageData.tax}</h4>
            <Form.Item name={["product", "taxId"]}>
              <SelectEditComponent
                showSearch
                allowClear
                placeholder={pageData.pleaseSelectTax}
                pleaseEnterName={pageData.tax.pleaseEnterNameTax}
                nameExisted={pageData.tax.taxNameExisted}
                btnAddNew={pageData.btnAddNew}
                listOption={listSellingTax}
                onChangeOption={onChangeOptionTax}
                onSearch={onNameTaxChange}
                addNewItem={addNewTaxDialog}
                isNameExisted={isNameTaxExisted}
                name={nameTax}
                category={pageData.taxLabel}
                isShowSearchIcon={true}
                isLowerCase={false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <h4 className="fnb-form-label">
              {pageData.unit.title}
              <span className="text-danger">*</span>
            </h4>
            <Form.Item
              name={["product", "unitId"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.unit.pleaseSelectUnit} />
                  ),
                },
              ]}
            >
              <SelectEditComponent
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                allowClear
                functions={selectNewItemFuncs}
                placeholder={pageData.unit.unitPlaceholder}
                pleaseEnterName={pageData.unit.pleaseEnterNameUnit}
                nameExisted={pageData.unit.unitNameExisted}
                btnAddNew={pageData.btnAddNew}
                listOption={units}
                onChangeOption={onChangeOption}
                onSearch={onNameChange}
                addNewItem={addNewUnit}
                isNameExisted={isUnitNameExisted}
                name={nameUnit}
                isEditProduct={true}
                category={pageData.unit.title}
                isShowSearchIcon={true}
              />
            </Form.Item>
            <Input name="product-unitId" style={{ display: "none" }} />
          </Col>
        </Row>
      </>
    );
  };

  //form item option
  const renderOptions = () => {
    return (
      <>
        <Row className="mt-3" gutter={[0, 16]}>
          {selectedOptions.map((option, index) => {
            option?.optionLevel?.sort((elementA, elementB) => elementB?.isSetDefault - elementA?.isSetDefault);
            return (
              <>
                <Col span={20}>
                  <Paragraph
                    className="option-name"
                    placement="top"
                    ellipsis={{ tooltip: option?.name }}
                    color="#50429B"
                    key={option?.id}
                  >
                    <Text className="option-name-text">{option?.name}</Text>
                  </Paragraph>
                </Col>
                <Col span={4}>
                  <div className="mr-3 float-right">
                    <a onClick={() => onDeleteSelectedOption(option?.id)}>
                      <FnbTooltip
                        hideTooltip={isTouchDevice}
                        disabledStopPropagation={isTouchDevice}
                        placement="top"
                        title={t("button.delete")}
                      >
                        <CustomTrashIcon color="#50429B" />
                      </FnbTooltip>
                    </a>
                  </div>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <div className="option-description">
                        <div className="option-description__dv--flex-horizon">
                          <div>
                            <Ellipse className="option-description__icon--eclipse" />
                          </div>
                          <div>
                            <span className="option-description__dv-default__txt">=</span>
                          </div>
                          <div>
                            <span className="option-description__dv-default__txt">
                              {pageData.optionInformation.defaultOptionText}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        id={"container-options-" + index}
                        className="w-100 container-options"
                        style={{ display: "flex", gap: 12 }}
                      >
                        {option?.optionLevel?.map((item) => {
                          return (
                            <>
                              <FnbChip isDefault={item.isSetDefault} title={item.name}></FnbChip>
                            </>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </>
            );
          })}
        </Row>
      </>
    );
  };

  function onAddSelectedMaterial(dataSelected, position) {
    const materialSelectList = dataSelected?.map((selected) => {
      return initDataMaterials?.find((material) => material.id === selected.key);
    });
    let listSelect = [];
    materialSelectList?.forEach((item, index) => {
      const selectedMaterials = initDataMaterials?.find((o) => o.id === item?.id);
      let units = [];
      let baseUnit = {
        id: selectedMaterials?.unit?.id,
        name: selectedMaterials?.unit?.name,
        isBase: true,
        quantity: 1,
      };
      units.push(baseUnit);
      if (selectedMaterials?.unitConversion) {
        selectedMaterials?.unitConversion.map((item) => {
          let unit = {
            id: item?.unitId,
            name: item?.unitName,
            quantity: item?.quantity,
            isBase: false,
          };
          units.push(unit);
        });
      }
      const newRow = {
        key: selectedMaterials.id,
        material: selectedMaterials.name,
        quantity: null,
        unit: selectedMaterials.unitName,
        unitCost: selectedMaterials.costPerUnit,
        cost: 0,
        unitId: selectedMaterials?.unit?.id ?? null,
        units: units,
        index: index,
      };
      listSelect.push(newRow);
    });
    const otherVariants = dataSelectedMaterial?.filter((variant) => variant?.position !== position);
    const newVariants = [...otherVariants, { position, materials: listSelect }]?.sort(
      (pre, current) => pre?.position - current?.position,
    );
    setDataSelectedMaterial(newVariants);
  }

  function onCancelModalSearchMaterial() {
    setIsShowModalSearchMaterial(false);
  }

  const modalSearchMaterial = () => {
    const position = parseInt(activeKeyInventoryTracking);
    const selectMaterials = dataSelectedMaterial?.find((variant) => variant?.position === position)?.materials || [];

    return (
      <div className="modal-product-topping">
        <ModalSearchMaterial
          materialList={initDataMaterials}
          position={position}
          dataSelectedMaterial={selectMaterials}
          onAddSelected={onAddSelectedMaterial}
          onCancelModal={onCancelModalSearchMaterial}
        />
      </div>
    );
  };

  const clickInventoryTrackings = () => {
    setIsShowModalSearchMaterial(true);
    getInitMaterial();
  };

  //form item Inventory Trackings
  const renderInventoryTrackings = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <FnbSelect
              onChange={(e) => {}}
              popupClassName="fnb-select-multiple-dropdown"
              placeholder={pageData.searchMaterial}
              onClick={() => clickInventoryTrackings()}
              fixed={true}
              multiLine={false}
              open={false}
              showSearch={false}
              isShowSearchIcon={true}
              suffixIcon={<></>}
            />
          </Col>
        </Row>
      </>
    );
  };

  const getFormSelectedMaterials = () => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    let productPrices = product?.prices ?? prices;
    let productPricesSort = [];
    prices.map((item) => {
      var productPrice = productPrices.find((x) => x.id === item.id);
      productPricesSort.push(productPrice);
    });

    return (
      <>
        {prices?.length > 1 && (
          <Row>
            <Tabs
              onChange={(index) => getTotalCost(index, true)}
              className="w-100 recipe-product-price-tab-container"
              id="tab-inventory"
              defaultActiveKey={defaultActiveKeyInventoryTracking}
              activeKey={activeKeyInventoryTracking}
            >
              {productPricesSort?.map((productPrice, index) => {
                const selectMaterials =
                  dataSelectedMaterial?.find((variant) => variant?.position === productPrice?.position)?.materials ||
                  [];
                return (
                  <TabPane
                    tab={
                      <div className="recipe-product-price-tab">
                        <div className="recipe-product-price-tab-icon">
                          {parseInt(activeKeyInventoryTracking) === productPrice?.position && <CustomTickCircleIcon />}
                        </div>
                        <FnbTypography className="recipe-product-price-text" text={productPrice?.name} />
                      </div>
                    }
                    forceRender
                    key={index}
                  >
                    <Row>
                      <Col span={24}>
                        <FnbTable
                          columns={columnsMaterial(index)}
                          dataSource={selectMaterials}
                          pageSize={tableSettings.pageSize}
                          pageNumber={pageNumber}
                          total={selectMaterials?.length}
                          numberRecordCurrent={numberRecordCurrent}
                          showPaging={!showPaging}
                          scrollY={96 * 5}
                          className="table-product-receipt custom-form"
                        />
                      </Col>
                    </Row>
                  </TabPane>
                );
              })}
            </Tabs>
          </Row>
        )}
        {prices?.length === 1 && dataSelectedMaterial.length > 0 && (
          <>
            <br />
            <Row className="w-100">
              <Col span={24}>
                <FnbTable
                  dataSource={dataSelectedMaterial?.[0]?.materials}
                  columns={columnsMaterial(0)}
                  pageSize={tableSettings.pageSize}
                  pageNumber={pageNumber}
                  total={dataSelectedMaterial?.[0]?.materials?.length}
                  numberRecordCurrent={numberRecordCurrent}
                  showPaging={!showPaging}
                  scrollY={96 * 5}
                  className="table-product-receipt custom-form"
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const getTotalCost = (index, isChangeTab = false) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    const totalCost = product?.materials?.priceName?.[index]?.material?.reduce(
      (n, { quantity, unitCost }) => n + (quantity ?? 0) * (unitCost ?? 0),
      0,
    );
    if (isChangeTab) {
      form.validateFields();
      setActiveKeyInventoryTracking(index);
    }
    setTotalCost(totalCost);
  };

  const columnsMaterial = (indexPriceName) => {
    let columns = [
      {
        title: pageData.inventoryTracking.table.materialName,
        dataIndex: "material",
        align: "left",
        width: "34%",
        ellipsis: true,
        render: (_, record, index) => (
          <Form.Item
            name={["product", "materials", "priceName", indexPriceName, "material", index, "materialId"]}
            className="form-item-material"
          >
            <p>
              <FnbTooltip onlyShowWhenEllipsis>{record.material}</FnbTooltip>
            </p>
          </Form.Item>
        ),
      },
      {
        title: pageData.inventoryTracking.table.quantity,
        dataIndex: "quantity",
        width: "22%",
        align: "left",
        editable: true,
        render: (_, record, index) => {
          return (
            <div style={{ display: "flex", gap: 8, justifyContent: "space-around" }}>
              <Form.Item
                className="form-item-quantity"
                name={["product", "materials", "priceName", indexPriceName, "material", index, "quantity"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.inventoryTracking.pleaseEnterQuantity}
                      />
                    ),
                  },
                  () => ({
                    validator(_, value) {
                      if (value > pageData.material.maxQuantity || value < pageData.material.minQuantity) {
                        return Promise.reject(pageData.validateMinQtyMessage);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{ marginBottom: "1em" }}
              >
                <FnbInputNumber
                  className="recipe-material-quantity"
                  onChange={(value) => onChangeQuantity(record, indexPriceName, value)}
                  defaultValue={record?.quantity}
                  formatter={(value) => formatterDecimalNumber(value)}
                  parser={(value) => parserDecimalNumber(value)}
                  onKeyPress={(event) => {
                    if (!isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                  showWrap={true}
                />
              </Form.Item>
              <Form.Item
                className="form-item-units"
                name={["product", "materials", "priceName", indexPriceName, "material", index, "unitId"]}
              >
                <FnbTypography className="form-item-units-text" text={record?.unit}></FnbTypography>
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: pageData.inventoryTracking.table.cost,
        dataIndex: "unitCost",
        align: "right",
        width: "22%",
        render: (_, record, index) => {
          let formValue = form.getFieldsValue();
          let { product } = formValue;
          const item = product.materials?.priceName[indexPriceName]?.material[index];
          return item && item?.unitCost ? (
            <Form.Item
              name={["product", "materials", "priceName", indexPriceName, "material", index, "unitCost"]}
              className="form-item-unit-cost"
            >
              <p>{formatNumberDecimalOrInteger(item?.unitCost)}</p>
            </Form.Item>
          ) : (
            <Form.Item
              name={["product", "materials", "priceName", indexPriceName, "material", index, "unitCost"]}
              className="form-item-unit-cost"
            >
              <p>{formatNumberDecimalOrInteger(record?.unitCost)}</p>
            </Form.Item>
          );
        },
      },
      {
        title: pageData.inventoryTracking.table.totalCost,
        dataIndex: "cost",
        align: "right",
        width: "17%",
        render: (_, record, index) => {
          let formValue = form.getFieldsValue();
          let { product } = formValue;
          const item = product.materials?.priceName[indexPriceName]?.material[index];
          return item && item?.quantity > 0 && item?.unitCost > 0 ? (
            <p>{formatNumberDecimalOrInteger(item?.quantity * item?.unitCost)}</p>
          ) : (
            <p>{formatNumberDecimalOrInteger(record?.quantity * record?.unitCost)}</p>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        align: "center",
        width: "5%",
        render: (_, record, index) => (
          <span
            className="icon-delete-price"
            onClick={() => {
              onRemoveItemMaterial(record, indexPriceName);
              getTotalCost(indexPriceName);
            }}
          >
            <FnbTooltip
              hideTooltip={isTouchDevice}
              disabledStopPropagation={isTouchDevice}
              placement="top"
              title={t("button.delete")}
            >
              <CustomTrashIcon color="#50429B" />
            </FnbTooltip>
          </span>
        ),
      },
    ];
    return columns;
  };

  // Hanlde change quantity and get total cost
  const onChangeQuantity = (record, position, value) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.materials.priceName[position].material[record?.index].materialId = record?.key;
    product.materials.priceName[position].material[record?.index].unitCost = record?.unitCost;
    product.materials.priceName[position].material[record?.index].quantity = value;
    form.setFieldsValue(formValue);
    getTotalCost(position);
  };

  const onRemoveItemMaterial = (record, position) => {
    const restMaterials = dataSelectedMaterial?.map((variant) =>
      variant?.position === position
        ? {
            position: variant?.position,
            materials: variant?.materials
              ?.filter((material) => material?.key !== record?.key)
              ?.map((material, index) => ({ ...material, index })),
          }
        : variant,
    );
    setDataSelectedMaterial(restMaterials);

    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.materials.priceName?.map((item, index) => {
      return index === position ? item.material.splice(record?.index, 1) : item.material;
    });
    form.setFieldsValue(formValue);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const formCreateProductSubmitCapture = () => {
    let tabControl = document.getElementById("tab-inventory");
    if (tabControl) {
      let tabItem = tabControl.querySelectorAll(".ant-tabs-tab");
      tabItem?.forEach((itemControl, index) => {
        let breakException = {};
        let tabInventoryContent = document.getElementById(`tab-inventory-panel-${index}`);
        if (tabInventoryContent) {
          let errorControl = tabInventoryContent.querySelectorAll(".ant-form-item-explain-error");
          if (errorControl.length > 0) {
            let activeTab = document.getElementById(`tab-inventory-tab-${index}`);
            activeTab.click();
            onSubmitForm();
            throw breakException;
          }
        }
      });
    }
  };

  const onAddNewUnit = async () => {
    if (!newUnitName) {
      setShowUnitNameValidateMessage(true);
      return;
    }

    let res = await unitDataService.createUnitAsync({ name: newUnitName });
    if (res.isSuccess) {
      /// Handle add new unit
      getUnits();

      /// Set unit selected is new unit
      form.setFieldsValue({
        product: {
          unitId: res.id,
        },
      });

      setNewUnitName(null);
    } else {
      setIsUnitNameExisted(true);
    }
  };

  useEffect(() => {
    setProductToppings(productToppingSelected);
  }, [productToppingSelected]);

  const onSelectProductTopping = (id) => {
    const selectedTopping = allproductToppings.find((item) => item?.id === id);
    setProductToppingSelected([...productToppingSelected, selectedTopping]);
    setAllProductToppings(allproductToppings.filter((item) => item?.id !== id));

    // setTimeout(setProductToppings(productToppingSelected), 300);
  };

  const onRemoveProductTopping = (id) => {
    const removedTopping = productToppingSelected.find((item) => item?.id === id);
    setProductToppingSelected(productToppingSelected.filter((item) => item?.id !== id));
    setAllProductToppings([...allproductToppings, removedTopping]);

    setTimeout(setProductToppings(productToppingSelected), 300);
  };

  const productToppingSelectedColumnTable = () => {
    const column = [
      {
        title: pageData.table.name,
        width: "15%",
        dataIndex: "thumbnail",
        render: (_, record) => {
          return <Image preview={false} src={record.thumbnail ?? "error"} fallback={productDefaultImage} />;
        },
      },
      {
        title: " ",
        width: "60%",
        dataIndex: "name",
        align: "left",
      },
      {
        title: pageData.table.action,
        key: "action",
        width: "20%",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <a onClick={() => onRemoveProductTopping(record?.id)}>
                <div className="fnb-table-action-icon">
                  <Tooltip placement="top" title={t("button.delete")} color="#50429B">
                    <TrashFill className="icon-svg-hover" />
                  </Tooltip>
                </div>
              </a>
            </>
          );
        },
      },
    ];

    return column;
  };

  const renderModalContent = () => {
    return (
      <Form>
        <div className="modal-product-topping">
          <Row className="modal-product-topping-search" style={{ display: "contents" }}>
            <Col span={24}>
              <FnbSelect
                value={null}
                placeholder={pageData.selectToppings}
                onChange={(key) => onSelectProductTopping(key)}
                listHeight={400}
                options={allproductToppings?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                  thumbnail: item?.thumbnail,
                }))}
                showThumbnail
              />
            </Col>
          </Row>
          <Row className="modal-product-topping-table">
            <FnbTable
              className="selected-product-topping-modal"
              dataSource={productToppingSelected}
              columns={productToppingSelectedColumnTable()}
            />
          </Row>
        </div>
      </Form>
    );
  };

  const handleCancel = () => {
    setIsVisibaleProductToppingModal(false);
    let toppings = allproductToppings.concat(productToppingSelected);
    setAllProductToppings(toppings?.filter((item) => !productToppings?.find((x) => x.id === item.id)));
    setProductToppingSelected([]);
  };

  const handleOK = () => {
    setIsVisibaleProductToppingModal(false);
    setProductToppings(productToppingSelected);
    setProductToppingSelected([]);

    if (!blockNavigation) setBlockNavigation(true);
  };

  const onCancelCreateProduct = () => {
    history.push("/product-management");
  };

  const onCompleted = () => {
    setIsChangeForm(false);
    setIsCreateCompleted(true);
  };

  useEffect(() => {
    if (!isChangeForm && isCreateCompleted) {
      history.push("/product-management");
    }
  }, [isChangeForm, isCreateCompleted]);
  
  const onInputPriceName = (index, evt) => {
    let valueInput = evt?.target?.value;
    if (valueInput.length > 0) {
      setActiveKeyInventoryTracking(index.toString());
      getTotalCost(index.toString());
    } else {
      setActiveKeyInventoryTracking("0");
      getTotalCost("0");
    }
    setDefaultActiveKeyInventoryTracking(valueInput);
  };

  const updateDimensions = () => {
    setIsMobileSize(window.innerWidth < 500);
  };

  //Handle for Other settings
  const renderStampPreview = () => {
    return (
      <div className="stamp-preview-setting">
        <div
          className="stamp-preview-setting-description"
          dangerouslySetInnerHTML={{ __html: pageData.othersSetting.stampPreviewDescription }}
        ></div>
        <div className="stamp-preview-setting-template">
          {stampConfigs && stampDatas && (
            <StampTemplatePreviewComponent stampConfig={stampConfigs} stampData={stampDatas} />
          )}
        </div>
      </div>
    );
  };

  const getOptionImageByLanguageCode = () => {
    switch (languageService.getLang()) {
      case "vi":
        return images.productCustomizeVI;
      default:
        return images.productCustomizeEN;
    }
  };

  const styleImage = { border: `1px solid ${theme.colors.primary[100]}`, borderRadius: "12px" };

  const renderOptionPreview = () => {
    return (
      <div className="option-preview-setting">
        <FnbTypography className="option-preview-setting-description" text={pageData.hint.optionDescriptionTooltip} />
        <div className="option-preview-image">
          <Image style={styleImage} src={getOptionImageByLanguageCode()} />
        </div>
      </div>
    );
  };

  const renderPlatformPreview = (id) => {
    switch (id.toString()) {
      case Platform.POS.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="topRight"
              maxWidth="524px"
              variant="secondary"
              title={() => (
                <div className="guide-text">
                  <FnbTypography className="pos-devices" text={pageData.hint.POSCashRegister + ": "} />
                  {<span dangerouslySetInnerHTML={{ __html: pageData.hint.POSDevices }}></span>}
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      case Platform.StoreWebsite.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="topRight"
              maxWidth="524px"
              variant="secondary"
              title={() => (
                <div className="store-web-guide-tooltip guide-text">
                  <FnbTypography text={pageData.hint.storeWebIs} />
                  <a className="store-web-link" href={storeInfo?.domainName} target={"_blank"} rel="noreferrer">
                    {pageData.hint.storeWebLink}
                  </a>
                  <FnbTypography text={pageData.hint.storeWebExample} />
                  <div className="store-web-preview-image">
                    <Image style={styleImage} src={images.storeWebGuide} />
                  </div>
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      case Platform.StoreMobileApp.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="top"
              maxWidth="384px"
              variant="secondary"
              title={() => (
                <div className="store-app-guide-tooltip guide-text">
                  {
                    <span
                      className="storeApp-preview-text"
                      dangerouslySetInnerHTML={{ __html: pageData.hint.storeApp }}
                    />
                  }
                  <div className="storeApp-preview-image">
                    <Image style={styleImage} src={images.storeAppGuide} />
                  </div>
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      case Platform.POSServing.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="topRight"
              maxWidth="524px"
              variant="secondary"
              title={() => (
                <div className="guide-text">
                  {<span dangerouslySetInnerHTML={{ __html: pageData.hint.POSServing }}></span>}
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      default:
        return <></>;
    }
  };

  const onChangePrintProduct = (value) => {
    setIsPrintStampValue(value);
  };

  const onRemoveToppingChip = (id) => {
    onRemoveProductTopping(id);
  };

  const onChangeToppingOption = (e) => {
    const isChecked = e.target.checked;
    setToppingOptionValue(isChecked);
  };

  const handleHiddenGuideProductVariants = () => setIsShowGuideProductVariants(false);
  const handleShowGuideProductVariants = () => setIsShowGuideProductVariants(true);

  const handleHiddenGuideProductRecipes = () => setIsShowGuideProductRecipes(false);
  const handleShowGuideProductRecipes = () => setIsShowGuideProductRecipes(true);

  const onAddNewItem = () => {
    setIsShowModalAddTopping(true);
  };

  const handleAddSuccessTopping = (topping) => {
    setProductToppingSelected([...productToppingSelected, topping]);
    executeAfter(500, () => {
      setAllProductToppings(allproductToppings.filter((item) => item?.id !== topping.id));
    });
  };

  return (
    <>
      <FnbHeadingPage
        title={isTopping ? pageData.createToppingTitle : pageData.title}
        listButtons={[
          <FnbCancelButton showWarning={isChangeForm} onOk={onCancelCreateProduct} />,
          <FnbAddNewButton
            text={isTopping ? pageData.btnCreateNewTopping : pageData.btnCreateNewProduct}
            onClick={() => onSubmitForm()}
            permission={PermissionKeys.CREATE_PRODUCT}
            hideIcon={true}
          />,
        ]}
        suffix={!isTopping ? <FnbSuffixHeadingGuideline purposeType={GuidancePurposeType.Product} /> : <></>}
      />
      <Form
        form={form}
        name="basic"
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        onChange={() => {
          if (!blockNavigation) setBlockNavigation(true);
        }}
        onSubmitCapture={() => formCreateProductSubmitCapture()}
      >
        <div className="col-input-full-width create-product-page">
          <Row className="grid-container-create-product">
            <Col className="left-create-product" xs={24} sm={24} md={24} lg={24}>
              <Card className="w-100 fnb-card h-auto">
                <Row>
                  <Col span={24}>
                    <h4 className="text-title-group">{pageData.productInformation.title}</h4>
                    <div className="text-title-group-underlined"></div>
                    <h4 className="fnb-form-label form-group-label">
                      {isTopping
                        ? pageData.productInformation.name.toppingName
                        : pageData.productInformation.name.label}
                      <span className="text-danger">*</span>
                    </h4>
                    <Form.Item
                      name={["product", "name"]}
                      rules={[
                        {
                          required: pageData.generalInformation.name.required,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={
                                isTopping
                                  ? pageData.productInformation.name.toppingValidateMessage
                                  : pageData.generalInformation.name.validateMessage
                              }
                            />
                          ),
                        },
                      ]}
                      validateFirst={true}
                    >
                      <Input
                        showCount
                        className="fnb-input-with-count"
                        placeholder={
                          isTopping
                            ? pageData.productInformation.name.toppingNamePlaceholder
                            : pageData.productInformation.name.placeholder
                        }
                        maxLength={pageData.generalInformation.name.maxLength}
                        id="product-name"
                      />
                    </Form.Item>

                    <div className={"w-100 mt-1 fnb-card h-auto"}>
                      <h4 className="fnb-form-label form-group-label">{pageData.productCategory.label}</h4>

                      <Form.Item name={["product", "productCategoryId"]}>
                        <SelectEditComponent
                          id="productCategory"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          showSearch
                          allowClear
                          functions={selectNewCategoryFuncs}
                          placeholder={pageData.productCategory.placeholder}
                          pleaseEnterName={pageData.productCategory.pleaseEnterNameCategory}
                          nameExisted={pageData.productCategory.nameCategoryExisted}
                          btnAddNew={pageData.btnAddNew}
                          listOption={listAllProductCategory}
                          onChangeOption={onChangeCategory}
                          onSearch={onCategoryNameChange}
                          addNewItem={showModalAddCategory}
                          isNameExisted={isCategoryNameExisted}
                          name={nameCategory}
                          isEditProduct={true}
                          category={pageData.productCategory.label}
                          isShowSearchIcon={true}
                        />
                      </Form.Item>
                    </div>

                    <h4 className="fnb-form-label form-group-label">{pageData.generalInformation.description.label}</h4>
                    <Form.Item name={["product", "description"]} rules={[]}>
                      <FnbTextArea
                        placeholder={pageData.generalInformation.description.placeholder}
                        showCount
                        maxLength={pageData.generalInformation.description.maxLength}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        rows={5}
                        id="product-description"
                      ></FnbTextArea>
                    </Form.Item>

                    <h4 className={`fnb-form-label form-group-label ${isTopping === true ? "d-none" : ""}`}>
                      {pageData.includeTopping}
                    </h4>
                    <div className={`product-check-box-select-all-topping ${isTopping === true ? "d-none" : ""}`}>
                      <FnbCheckBox onChange={(event) => onChangeToppingOption(event)} checked={toppingOptionValue}>
                        {pageData.allToppings}
                      </FnbCheckBox>
                    </div>
                    <Form.Item className={`select-topping ${isTopping === true ? "d-none" : ""}`}>
                      <Row>
                        <Col span={24}>
                          <FnbSelectAddNewItem
                            mode={"multiple"}
                            placeholder={pageData.toppingPlaceholder}
                            onSelect={(key) => onSelectProductTopping(key)}
                            onDeselect={(key) => onRemoveToppingChip(key)}
                            listHeight={400}
                            options={allproductToppings?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                              thumbnail: item?.thumbnail,
                            }))}
                            showThumbnail
                            isShowSearchIcon={true}
                            disabled={toppingOptionValue}
                            fieldItemName="Topping"
                            onAddNewItem={onAddNewItem}
                            onSearch={(e) => setSearchToppingValue(e)}
                            tagRender={() => null}
                            className="select-topping-product"
                            value={productToppingSelected?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                              thumbnail: item?.thumbnail,
                            }))}
                          />
                          {productToppingSelected.length > 0 && !toppingOptionValue && (
                            <Col className="topping-text">
                              <Row gutter={[12, 12]} className="list-topping-chip">
                                {productToppingSelected.map((item) => {
                                  return (
                                    <Col>
                                      <div className="w-100 container-toppings">
                                        <FnbChip
                                          id={item?.id}
                                          iconHeader={
                                            item?.thumbnail ? (
                                              <Image preview={false} src={item?.thumbnail} />
                                            ) : (
                                              <ImageMaterialDefault />
                                            )
                                          }
                                          isDisableToolTip={true}
                                          title={item.name}
                                          isRemoveChip={true}
                                          onRemoveChip={(id) => onRemoveToppingChip(id)}
                                        ></FnbChip>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <FnbCard
                title={isTopping ? pageData.pricing.productPrice : pageData.pricing.pricesAndVariations}
                underlineTitle
                className="w-100 mt-12 fnb-card h-auto"
                buttons={[
                  <FnbButton
                    onClick={handleShowGuideProductVariants}
                    text={pageData.hint.howToSetUpProductVariants}
                    variant="secondary-purple"
                    iconHeader={<MessageQuestionIcon />}
                    hiddenTextOnMobile
                    className={`${isTopping === true ? "hidden" : ""}`}
                  />,
                ]}
              >
                <Row>
                  <Col span={24}>{renderPrices()}</Col>
                </Row>
              </FnbCard>
              <FnbCard
                title={pageData.unit.recipe}
                underlineTitle
                className="w-100 mt-12 fnb-card fnb-card-recipe h-auto"
                buttons={[
                  <FnbButton
                    onClick={handleShowGuideProductRecipes}
                    text={isTopping ? pageData.hint.howToSetUpRecipes : pageData.hint.howToSetUpProductRecipes}
                    variant="secondary-purple"
                    iconHeader={<MessageQuestionIcon />}
                    hiddenTextOnMobile
                  />,
                ]}
              >
                <Row>
                  <Col span={24}>{renderInventoryTrackings()}</Col>
                  <Col span={24}>
                    {getFormSelectedMaterials()}
                    {showRecipeMessage && dataSelectedMaterial?.length < prices?.length && (
                      <div id="error-recipe-message" className="mt-1">
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.inventoryTracking.pleaseSetupRecipe}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="mt-20">
                  <Col className="tip-product-receipt" xs={24} sm={24} md={24} lg={24} flex={1}>
                    <LampOn />
                    {pageData.inventoryTracking.recipeTip}
                  </Col>
                  {prices?.length > 0 && dataSelectedMaterial.length > 0 && (
                    <>
                      <Col className="total-cost-product-receipt" xs={24} sm={24} md={24} lg={24} flex={1}>
                        <FnbTypography
                          className="total-cost-product-receipt-title"
                          text={`${pageData.productInformation.totalProductCost}:${" "}`}
                        ></FnbTypography>
                        <div className="total-cost-product-receipt-value">
                          <FnbTypography
                            text={totalCost ? formatCurrencyWithoutSuffix(roundNumber(totalCost, 2)) : 0}
                          />
                          <FnbTypography text={` ${getCurrency()}/ ${unitName}`} />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </FnbCard>
            </Col>

            <Col className="right-create-product" xs={24} sm={24} md={24} lg={24}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Card className="w-100 fnb-card h-auto upload-product-image">
                    <h4 className="title-image">{pageData.productImage.title}</h4>
                    <Row className={`non-image ${image !== null ? "have-image" : ""}`}>
                      <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
                        <Form.Item name={["product", "media"]}>
                          <FnbCustomUploadImageComponent
                            iconUpload={<UploadLogoIcon />}
                            buttonText={pageData.generalInformation.uploadImage}
                            onChange={onChangeImage}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Card className={`w-100 mt-12 fnb-card h-auto ${isTopping === true ? "hidden" : ""}`}>
                    <Row className="option-preview">
                      <Col className="option-title-container">
                        <Row className="option-title-text">
                          <h4 className="title-option">{pageData.optionInformation.title}</h4>
                          <FnbTooltip
                            autoAdjustOverflow
                            placement="bottomRight"
                            maxWidth="370px"
                            variant="secondary"
                            title={() => renderOptionPreview()}
                            arrowPointAtCenter={true}
                          >
                            <CustomInfoCircleIcon />
                          </FnbTooltip>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <FnbSelectOptions
                          placeholder={pageData.optionInformation.selectOption}
                          showSearch
                          options={options?.map((b) => ({
                            value: b?.id,
                            label: b?.name,
                          }))}
                          optionFilterProp="children"
                          value={null}
                          onChange={(value) => onSelectOption(value)}
                          name={newOptionName}
                          isEditProduct={true}
                          onChangeOption={onChangeOption}
                          onSearch={onOptionSearch}
                          addNewItem={onAddNewOption}
                          isNameExisted={isOptionNameExisted}
                          isNameSelected={isOptionNameSelected}
                          permission={PermissionKeys.CREATE_OPTION}
                        />
                      </Col>
                    </Row>
                    {renderOptions()}
                  </Card>
                </Col>
              </Row>

              <Row hidden={listPlatforms.length > 0 ? "" : "hidden"}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Card className="w-100 mt-12 fnb-card platform-card">
                    <h4 className="title-platforms">{pageData.platform.title}</h4>
                    <Form.Item name={["name", "platform"]}>
                      <div className="platform-group">
                        <Checkbox.Group defaultValue={valuePlatforms} onChange={onChangePlatform}>
                          {listPlatforms?.map((p, index) => {
                            return (
                              <div className={`platform-item ${index === 0 ? "mt-1" : "mt-10"}`}>
                                <FnbCheckBox defaultChecked={true} value={p.id}>
                                  {p.name === "POS" ? "POS devices" : p.name}
                                </FnbCheckBox>
                                {renderPlatformPreview(p.id)}
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </div>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Card className="w-100 mt-12 fnb-card other-setting-card">
                    <h4 className="title-other-setting">{pageData.othersSetting.title}</h4>
                    <Form.Item name={["product", "isPrintStamp"]}>
                      <Row className="isPrintStamp-container">
                        <Col>
                          <FnbSwitch
                            onChange={(value) => onChangePrintProduct(value)}
                            defaultChecked={isPrintStampValue}
                            className="isPrintStamp-switch"
                          />
                        </Col>
                        <Col className="isPrintStamp-title-container">
                          <Row className="isPrintStamp-title">
                            {pageData.othersSetting.isPrintStampTitle}
                            <FnbTooltip
                              placement="bottom"
                              maxWidth="392px"
                              variant="secondary"
                              title={() => renderStampPreview()}
                            >
                              <CustomInfoCircleIcon />
                            </FnbTooltip>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
      <FnbHeadingPage
        className="bottomPage"
        title={""}
        listButtons={[
          <FnbCancelButton showWarning={isChangeForm} onOk={onCancelCreateProduct} />,
          <FnbAddNewButton
            text={isTopping ? pageData.btnCreateNewTopping : pageData.btnCreateNewProduct}
            onClick={() => onSubmitForm()}
            permission={PermissionKeys.CREATE_PRODUCT}
            hideIcon={true}
          />,
        ]}
      />
      {isVisibaleProductToppingModal && (
        <FnbModal
          width={"800px"}
          title={pageData.ingredientSearch}
          handleCancel={handleCancel}
          onOk={handleOK}
          cancelText={pageData.btnCancel}
          okText={pageData.btnAddNew}
          visible={isVisibaleProductToppingModal}
          content={renderModalContent()}
        />
      )}
      {isShowModalSearchMaterial && (
        <FnbModal
          width={900}
          title={pageData.ingredientSearch}
          handleCancel={onCancelModalSearchMaterial}
          visible={isShowModalSearchMaterial}
          content={modalSearchMaterial()}
          hideFooter
          className="promotion-campaign-buy-x-get-y-modal"
        ></FnbModal>
      )}
      <ConfirmDialogComponent
        title={pageData.leaveDialog.confirmation}
        okText={pageData.btnContinueCreate}
        cancelText={pageData.btnIgnore}
        onOk={onConfirmTouched}
        onCancel={onCancelTouched}
        visible={showConfirmTouched}
        type={ConfirmStyle.LEAVER}
        contentElement={renderCreateDuplicateName()}
      />
      {isCategoryModalVisible && (
        <FnbModal
          title={pageData.addProductCategory}
          visible={isCategoryModalVisible}
          footer={(null, null)}
          width={"800px"}
          handleCancel={() => setIsCategoryModalVisible(false)}
          onOk={addNewCategory}
          cancelText={pageData.cancel}
          okText={pageData.createCategory}
          disabledBtnOk={false}
          content={
            <Form form={formAddCategory} name="basic" onFinish={addNewCategory} autoComplete="off">
              <Row>
                <div className="w-100">
                  <Card className="fnb-card">
                    <Row gutter={[24, 24]}>
                      <Col xs={24} sm={24} md={24} lg={12} span={12}>
                        <div className="d-flex">
                          <h3 className="fnb-form-label">
                            {pageData.categoryName}
                            <span className="text-danger">*</span>
                          </h3>
                        </div>
                        <Form.Item
                          name={["name"]}
                          rules={[
                            {
                              required: true,
                              message: pageData.productCategory.enterNameCategory,
                            },
                          ]}
                        >
                          <FnbInput
                            className="fnb-input-with-count"
                            showCount
                            placeholder={pageData.productCategory.nameCategoryPlaceholder}
                            maxLength={pageData.generalInformation.name.maxLength}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} span={12}>
                        <div className="d-flex">
                          <h3 className="fnb-form-label">
                            {pageData.priority.title}
                            <span className="text-danger">*</span>
                          </h3>
                          <div style={{ marginTop: "17px" }}>
                            <FnbTooltip placement="topLeft" title={pageData.priority.tooltip}>
                              <span className="ml-12">
                                <ExclamationIcon width={16} height={16} />
                              </span>
                            </FnbTooltip>
                          </div>
                        </div>
                        <Form.Item
                          name={["priority"]}
                          rules={[
                            {
                              required: true,
                              message: pageData.priority.validateMessage,
                            },
                          ]}
                        >
                          <FnbInputNumber
                            placeholder={pageData.priority.placeholder}
                            className="fnb-input-number w-100"
                            min={1}
                            max={1000000}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]} className="mt-16">
                      <Col xs={24} sm={24} md={24} lg={24} span={24}>
                        {renderSelectBranch()}
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Row>
            </Form>
          }
        />
      )}
      <CreateNewOptionManagement
        isModalVisible={showAddNewOptionForm}
        handleCancel={onCancelAddNewFormOption}
        onSuccess={onSuccessAddNewFormOption}
        nameOption={createOptionName}
      />
      <CreateNewTax
        t={t}
        isModalVisible={isTaxModalVisible}
        name={nameTax}
        handleCompleted={handleCompletedTaxModal}
        handleCancel={handleCancelTaxModal}
        disabledImportedTax={true}
      />
      <DialogHowToSetupProductVariants
        isShowGuideProductVariants={isShowGuideProductVariants}
        handleHiddenGuideProductVariants={handleHiddenGuideProductVariants}
      />
      <DialogHowToSetupProductRecipes
        isShowGuideProductRecipes={isShowGuideProductRecipes}
        handleHiddenGuideProductRecipes={handleHiddenGuideProductRecipes}
      />
      <FnbModalCreateTopping
        visible={isShowModalAddTopping}
        setVisible={setIsShowModalAddTopping}
        defaultNameTopping={searchToppingValue}
        initPlatforms={listPlatforms}
        initUnits={units}
        initMaterials={materials}
        toppings={allproductToppings}
        setToppings={setAllProductToppings}
        currencyCode={currencyCode}
        stampConfigs={stampConfigs}
        stampData={stampDatas}
        taxes={listSellingTax}
        setTaxes={setListSellingTax}
        handleAddSuccess={handleAddSuccessTopping}
      />
    </>
  );
}
