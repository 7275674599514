export const EnumDeliveryMethod = {
  /// <summary>
  /// Shop Delivery
  /// </summary>
  ShopDelivery: 1,

  /// <summary>
  /// AhaMove
  /// </summary>
  AhaMove: 2,

  /// <summary>
  /// Grab Express
  /// </summary>
  GrabExpress: 4,
};
