import { Image, Typography, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import RejectDialogComponent from "components/fnb-reject-dialog/reject-dialog.component";
import { FnbSteps, Step } from "components/fnb-step/fnb-step";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { EditOutlined, ImageMaterialDefault, RejectStep } from "constants/icons.constants";
import { images } from "constants/images.constants";
import { IngredientRemoveRequestHistoryLocalization } from "constants/ingredient-remove-request.constants";
import { IngredientRemoveStatus } from "constants/ingredient-remove.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat, TrackingSteps } from "constants/string.constants";
import { EnumUpdateStockAction, EnumUpdateStockStatus, UpdateStockHistoryActionType } from "constants/update-stock-request.constants";
import ingredientUpdateStockRequestDataService from "data-services/material-control/ingredient-update-stock-request.service";
import parse from "html-react-parser";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import {
  capitalizeFirstLetterEachWord,
  formatDate,
  formatterDecimalNumber,
  hasPermission,
  roundNumber,
} from "utils/helpers";
import { KEY_TAB_PANE } from "../../table-inventory-control.component";
import "./index.scss";
import { Container, FlexRowItem, NoWhiteSpace, WrapperLeftColumn, WrapperRightColumn } from "./styled";

const { Text } = Typography;

const urlBack = `/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_UPDATE_STOCK}`;

export default function DetailupdateStockRequestDetail(props) {
  const { match, history } = props;
  const { t } = useTranslation();

  const [currencyStep, setCurrencyStep] = useState(0);
  const [isShowModalReject, setIsShowModalReject] = useState(false);
  const [updateStockRequestDetail, setUpdateStockRequestDetail] = useState({});
  const [isShowModalApprove, setIsShowModalApprove] = useState(false);
  const [disabledBtnApprove, setDisabledBtnApprove] = useState(false);

  const pageData = {
    branchOrWareHouse: t("materialControl.createRemoveRequest.branchOrWarehouse"),
    reasonForRequest: t("materialControl.createRemoveRequest.reasonForRequest"),
    description: t("form.description"),
    back: t("button.back"),
    purchaseOrderManagement: t("purchaseOrder.purchaseOrderManagement"),
    edit: t("button.edit"),
    print: t("button.print"),
    approveRequest: t("materialControl.detailRemoveRequest.approveRequest"),
    order: t("button.order"),
    complete: t("button.complete"),
    cancel: t("button.cancel"),
    option: t("button.option"),
    generalInfo: t("settings.createGroupPermission.titleGeneralInformation"),
    ingredientInformation: t("materialControl.createRemoveRequest.ingredientInfo"),
    requestHistory: t("materialControl.historyRemoveRequest.requestHistory"),
    approvePurchaseOrderSuccess: t("messages.approvePurchaseOrderSuccess"),
    orderPurchaseOrderSuccess: t("messages.orderPurchaseOrderSuccess"),
    cancelPurchaseOrderSuccess: t("messages.cancelPurchaseOrderSuccess"),
    completePurchaseOrderSuccess: t("messages.completePurchaseOrderSuccess"),
    tableIngredient: {
      no: t("table.no"),
      sku: t("table.sku"),
      ingredients: t("materialControl.updateStockRequest.createRequest.ingredients"),
      inventoryQuantity: t("materialControl.updateStockRequest.createRequest.inventoryQuantity"),
      realityQuantity: t("materialControl.updateStockRequest.createRequest.realityQuantity"),
      reason: t("materialControl.updateStockRequest.createRequest.reason"),
      available: t("materialControl.updateStockRequest.createRequest.available"),
    },
    tableIngredientHistory: {
      timeline: t("materialControl.historyRemoveRequest.timeline"),
      activityEvent: t("materialControl.historyRemoveRequest.activity"),
    },
    status: {
      new: t("purchaseOrder.status.new"),
      approved: t("materialControl.updateStockRequest.status.approve"),
      complete: t("purchaseOrder.status.completed"),
      cancel: t("purchaseOrder.status.canceled"),
      ordering: t("purchaseOrder.status.ordering"),
    },
    supplier: t("supplier.title"),
    destinationLabel: t("purchaseOrder.destinationLabel"),
    note: t("form.note"),
    materialInformation: t("purchaseOrder.materialInformation"),
    confirmCancel: t("leaveDialog.confirmCancel"),
    ignore: t("button.deletePageIgnore"),
    cancelPurchaseOrder: t("purchaseOrder.cancelPurchaseOrder"),
    viewHistory: t("button.viewHistory"),
    moreAction: t("button.moreAction"),
    confirmStopPromotion: t("messages.confirmStopPromotion"),
    reject: t("status.rejected"),
    rejectRequest: t("materialControl.detailRemoveRequest.rejectRequest"),
    rejectTitle: t("materialControl.detailRemoveRequest.rejectTitle"),
    rejectContent: t("materialControl.updateStockRequest.rejectContent"),
    waitingForApproval: t("status.waitingForApproval"),
    approveTitle: t("materialControl.updateStockRequest.approveTitle"),
    approveContent: t("materialControl.updateStockRequest.approveContent"),
    approveSuccess: t("materialControl.detailRemoveRequest.approveSuccess"),
    rejectSuccess: t("materialControl.detailRemoveRequest.rejectSuccess"),
    confirmApprove: t("materialControl.updateStockRequest.confirmApprove"),
    confirmReject: t("materialControl.updateStockRequest.confirmReject"),
    theRequest: t("materialControl.historyRemoveRequest.theRequest", "the request"),
    hoverUnavailable: t("materialControl.createRemoveRequest.hoverUnavailable"),
    hoverAvailable: t("materialControl.createRemoveRequest.hoverAvailable"),
    reasonRejection: t("materialControl.detailRemoveRequest.reasonRejection"),
    idRequest: t("materialControl.detailUpdateRequest.idRequest"),
    hoverTotal: t("materialControl.updateStockRequest.createRequest.hoverTotal"),
    hoverRealityQuantity: t("materialControl.updateStockRequest.createRequest.hoverRealityQuantity"),
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await ingredientUpdateStockRequestDataService.getUpdateStockRequestById(match?.params?.id,EnumUpdateStockAction.VIEW_DETAIL).then((res) => {
      if (res) {
        const { data } = res;
        setUpdateStockRequestDetail(data);
        let currencyStepByStatus = 0;
        if (data.status === EnumUpdateStockStatus.APPROVED || data.status === EnumUpdateStockStatus.REJECTED) {
          currencyStepByStatus = 1;
        }

        setCurrencyStep(currencyStepByStatus);
      }
    });
  };

  const handleGetUnitName = (unitsConversion, unitId, defaultNameUnit) => {
    let unitName;
    const unitConversion = unitsConversion?.find((unitConversion) => unitConversion.unitId === unitId);
    if (!unitConversion) return defaultNameUnit;

    if (unitConversion) {
      unitName = unitConversion.unitName;
    }
    return unitName;
  };

  const handleConvertQuantityUnit = (unitsConversion, unitId, quantity) => {
    let quantityConvert;
    const unitConversion = unitsConversion?.find((unitConversion) => unitConversion.unitId === unitId);
    if (!unitConversion) return roundNumber(quantity, 2);

    if (unitConversion) {
      quantityConvert = quantity / unitConversion.capacity;
    }
    return roundNumber(quantityConvert, 2);
  };

  const columnsRequestDetails = () => {
    let columns = [
      {
        title: pageData.tableIngredient.no,
        align: "center",
        width: "5rem",
        render: (_, record, index) => index + 1,
      },
      {
        title: pageData.tableIngredient.ingredients,
        align: "left",
        width: "70%",
        render: (_, record) => {
          return (
            <Container>
              <WrapperLeftColumn>
                {record?.thumbnail ? (
                  <Thumbnail src={record?.thumbnail} width={56} height={56} />
                ) : (
                  <ImageMaterialDefault width={56} height={56} />
                )}
                <div>
                  <FnbTooltip onlyShowWhenEllipsis title={record?.name} width={350} maxWidthContent={450}>
                    <FnbTypography variant={theme.typography["b1-bold"]} text={record?.name} />
                  </FnbTooltip>
                  <FnbTooltip onlyShowWhenEllipsis title={record?.sku} width={350} maxWidthContent={450}>
                    <FnbTypography text={record?.sku} color={theme.colors.gray[60]} />
                  </FnbTooltip>
                </div>
              </WrapperLeftColumn>
              <WrapperRightColumn></WrapperRightColumn>
            </Container>
          );
        },
      },
      {
        title: capitalizeFirstLetterEachWord(pageData.tableIngredient.inventoryQuantity),
        dataIndex: "total",
        width: "15%",
        align: "left",
        tooltip: true,
        contentTooltip: parse(pageData.hoverTotal),
        render: (_, record) => {
          return (
            <Fragment>{`${formatterDecimalNumber(
              handleConvertQuantityUnit(
                record?.unitConversion,
                record?.unitId,
                updateStockRequestDetail?.status === EnumUpdateStockStatus.WAITING_TO_APPROVAL
                  ? record?.currentInStockQuantity
                  : record?.inventoryQuantity,
              ),
            )} ${handleGetUnitName(record?.unitConversion, record?.unitId, record?.defaultUnitName)}`}</Fragment>
          );
        },
      },
      {
        title: capitalizeFirstLetterEachWord(pageData.tableIngredient.realityQuantity),
        dataIndex: "available",
        align: "left",
        width: "20%",
        tooltip: true,
        contentTooltip: parse(pageData.hoverRealityQuantity),
        render: (_, record) => {
          return (
            <Fragment>{` ${formatterDecimalNumber(record?.quantity)} ${handleGetUnitName(
              record?.unitConversion,
              record?.unitId,
              record?.defaultUnitName,
            )}`}</Fragment>
          );
        },
      },
    ];
    return columns;
  };

  const columnsIngredientRemoveRequestHistories = () => {
    let columns = [
      {
        title: pageData.tableIngredientHistory.timeline,
        align: "left",
        width: "25%",
        render: (_, { time }) => {
          return <NoWhiteSpace>{formatDate(time, DateFormat.HH_mm_DD_MMM_YYYY)}</NoWhiteSpace>;
        },
      },
      {
        title: pageData.tableIngredientHistory.activityEvent,
        dataIndex: "total",
        align: "left",
        width: "75%",
        render: (_, record) => {
          return (
            <NoWhiteSpace>
              <FlexRowItem>
                <Image
                  src={record?.thumbnail ?? "error"}
                  width={32}
                  height={32}
                  style={{ borderRadius: 4 }}
                  fallback={images.imgDefault}
                />
                <FnbTooltip onlyShowWhenEllipsis maxWidthContent={280} title={record?.user}>
                  <FnbTypography.Link text={record?.user} variant={theme.typography["b1-bold"]} />
                </FnbTooltip>
                <FnbTypography
                  text={`${t(IngredientRemoveRequestHistoryLocalization[record?.type])} ${pageData.theRequest}`}
                />
              </FlexRowItem>
            </NoWhiteSpace>
          );
        },
      },
    ];
    return columns;
  };

  const renderActionCreate = () => {
    if (
      hasPermission(PermissionKeys.APPROVE_STOCK_UPDATE_REQUEST) &&
      updateStockRequestDetail?.status === EnumUpdateStockStatus.WAITING_TO_APPROVAL
    ) {
      return <FnbButton minWidth="fit-content" text={pageData.approveRequest} onClick={handleShowModalApprove} />;
    } else {
      return <></>;
    }
  };

  const onEdit = () => {
    history.push(`/inventory/material-control/edit-update-stock-request/${match?.params?.id}`);
  };

  const handleShowRejectModal = () => setIsShowModalReject(true);
  const handleHiddenRejectModal = () => setIsShowModalReject(false);

  const handleShowModalApprove = () => setIsShowModalApprove(true);
  const handleHiddenModalApprove = () => setIsShowModalApprove(false);

  const handleBack = () => {
    history.push(urlBack);
  };

  const renderMoreAction = () => {
    return (
      <Fragment>
        <FnbButton text={pageData.cancel} variant="tertiary" onClick={handleBack} />
        {updateStockRequestDetail?.status === EnumUpdateStockStatus.WAITING_TO_APPROVAL && (
          <FnbTooltip placement="top" title={pageData.edit}>
            <FnbButton
              variant="tertiary"
              onClick={onEdit}
              permission={PermissionKeys.EDIT_STOCK_UPDATE_REQUEST}
              iconHeader={<EditOutlined />}
            />
          </FnbTooltip>
        )}

        {updateStockRequestDetail?.status === EnumUpdateStockStatus.WAITING_TO_APPROVAL && (
          <FnbButton
            style={{ justifyContent: "left" }}
            className="background-color-white"
            text={pageData.rejectRequest}
            variant="tertiary"
            onClick={handleShowRejectModal}
            permission={PermissionKeys.REJECT_STOCK_UPDATE_REQUEST}
          />
        )}
      </Fragment>
    );
  };

  const onRejectIngredientRemoveRequest = (reason) => {
    handleHiddenRejectModal();
    let req = { id: match?.params?.id, reason: reason };

    ingredientUpdateStockRequestDataService
      .rejectUpdateStockRequestAsync(req)
      .then((res) => {
        if (res) {
          message.success(pageData.rejectSuccess);
          setCurrencyStep(1);
          fetchData();
        }
      })
      .catch((_ex) => {
        handleHiddenRejectModal();
      });
  };

  const onApproveIngredientRemoveRequest = () => {
    setDisabledBtnApprove(true);
    let req = { id: match?.params?.id };
    ingredientUpdateStockRequestDataService
      .approveUpdateStockRequestAsync(req)
      .then((res) => {
        if (res) {
          message.success(pageData.approveSuccess);
          handleHiddenModalApprove();
          setCurrencyStep(1);
          fetchData();
          setDisabledBtnApprove(false);
        }
      })
      .catch((_ex) => {
        handleHiddenModalApprove();
      });
  };

  return (
    <div className="detail-ingredient-remove-request">
      <FnbHeadingPage
        itemsBreadcrumb={[
          {
            href: urlBack,
            title: pageData.back,
          },
          {
            title: (
              <Text className="purchase-order-code">{`${pageData.idRequest}: ${updateStockRequestDetail?.code}`}</Text>
            ),
          },
        ]}
        listButtons={[renderMoreAction(), renderActionCreate()]}
        isCompactButtons={false}
        approveStatus={
          updateStockRequestDetail?.status === IngredientRemoveStatus.APPROVED &&
          t(updateStockRequestDetail?.statusAsString)
        }
        rejectStatus={
          updateStockRequestDetail?.status === IngredientRemoveStatus.REJECTED &&
          t(updateStockRequestDetail?.statusAsString)
        }
        waitingForApproveStatus={
          updateStockRequestDetail?.status === IngredientRemoveStatus.WAITING_TO_APPROVAL &&
          t(updateStockRequestDetail?.statusAsString)
        }
      />

      <div className="card-parent">
        {updateStockRequestDetail?.status === IngredientRemoveStatus.REJECTED && (
          <FnbCard className="card-general-info reject-reason">
            <div className="title">{pageData.reasonRejection}</div>
            <div className="content">
              {
                updateStockRequestDetail?.updateStockHistories?.find(
                  (x) => x.type === UpdateStockHistoryActionType.REJECTED,
                )?.description
              }
            </div>
          </FnbCard>
        )}
        <FnbCard className="card-general-info mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.generalInfo} />
            <hr className="title-hr" />
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.branchOrWareHouse}:`} />
              </div>
              <div className="content">
                <FnbTypography text={updateStockRequestDetail?.branchName} />
              </div>
            </div>
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.reasonForRequest}:`} />
              </div>
              <div className="content">
                <FnbTypography text={t(updateStockRequestDetail?.reasonAsString)} />
              </div>
            </div>
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.description}:`} />
              </div>
              <div className="content text-area">
                <FnbTypography text={updateStockRequestDetail?.description || "-"} />
              </div>
            </div>
          </div>
          <FnbSteps currentStep={currencyStep} className="steps-info">
            <Step isProcessing={TrackingSteps.process} title={pageData.waitingForApproval} />
            <Step
              isProcessing={TrackingSteps.process}
              className={
                updateStockRequestDetail?.status === EnumUpdateStockStatus.REJECTED ? "step-item-cancel-new" : ""
              }
              title={
                updateStockRequestDetail?.status === EnumUpdateStockStatus.REJECTED ? pageData.reject : pageData.status.approved
              }
              icon={updateStockRequestDetail?.status === EnumUpdateStockStatus.REJECTED && <RejectStep />}
            />
          </FnbSteps>
        </FnbCard>
        <FnbCard className="card-general-info card-table mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.ingredientInformation} />
            <hr className="title-hr" />
          </div>
          <FnbTable
            className="mt-4"
            dataSource={updateStockRequestDetail?.ingredients}
            columns={columnsRequestDetails()}
          />
        </FnbCard>
        <FnbCard className="card-general-info card-table mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.requestHistory} />
            <hr className="title-hr" />
          </div>
          <FnbTable
            className="mt-4"
            dataSource={updateStockRequestDetail?.updateStockHistories}
            columns={columnsIngredientRemoveRequestHistories()}
            scrollX={"auto"}
          />
        </FnbCard>
      </div>
      <ConfirmDialogComponent
        visible={isShowModalApprove}
        type={ConfirmStyle.STOP}
        onCancel={handleHiddenModalApprove}
        onOk={onApproveIngredientRemoveRequest}
        title={pageData.approveTitle}
        content={t(pageData.approveContent, { name: updateStockRequestDetail?.code })}
        okText={pageData.confirmApprove}
        cancelText={pageData.ignore}
        centered
        disabledBtnOk={disabledBtnApprove}
      />
      <RejectDialogComponent
        visible={isShowModalReject}
        type={ConfirmStyle.DELETE}
        onCancel={handleHiddenRejectModal}
        onOk={onRejectIngredientRemoveRequest}
        title={pageData.rejectTitle}
        content={t(pageData.rejectContent, { name: updateStockRequestDetail?.code })}
        okText={pageData.confirmReject}
        cancelText={t("store.ignore")}
        centered
      />
    </div>
  );
}
