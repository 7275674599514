import { Rate } from "antd";
import { StarIcon } from "../../assets/icons.constants";
import "./fnb-rate.scss";

const FnbRate = (props) => {
  const { defaultValue, onChange, disabled, className, value, ...rest } = props;

  return (
    <Rate
      character={<StarIcon />}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`wrapper-rated ${className}`}
      {...rest}
    />
  );
};

export default FnbRate;
