import { Col, Form, Image, Row, Table, Tabs, Typography, message } from "antd";
import productImageDefault from "assets/images/product-img-default.png";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbNotifyDialog } from "components/fnb-notify-dialog/fnb-notify-dialog.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { PermissionKeys } from "constants/permission-key.constants";
import { ProductStatus } from "constants/product-status.constants";
import productDataService from "data-services/product/product-data.service";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  formatCurrency,
  formatCurrencyWithoutSuffix,
  formatNumberDecimalOrInteger,
  getCurrency,
  hasPermission,
} from "utils/helpers";
import productDefaultImage from "../../../assets/images/combo-default-img.jpg";
import "../product-dependencies.scss";
import DeleteProductComponent from "../product-management/components/delete-product.component";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import {
  CustomInfoCircleIcon,
  CustomTickCircleIcon,
  CustomTrashIcon,
  Ellipse,
  ImageMaterialDefault,
  LampOn,
} from "constants/icons.constants";
import { StampType } from "constants/stamp-type.constants";
import stampDataService from "data-services/stamp/stamp-data.service";
import { StampTemplatePreviewComponent } from "pages/settings/stamp/components/stamp-template-preview.component";
import { useSelector } from "react-redux";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import CurrencyFormat from "react-currency-format";
import theme from "theme";
const { TabPane } = Tabs;
const { Text } = Typography;

export default function ProductDetailPage(props) {
  const { t, match } = props;
  const history = useHistory();

  const [product, setProduct] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [activate, setActivate] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState();
  const [isVisibaleProductToppingModal, setIsVisibaleProductToppingModal] = useState(false);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationDatasource, setNotificationDatasource] = useState(null);
  const [notificationTable, setNotificationTable] = useState(null);
  const [preventDeleteProduct, setPreventDeleteProduct] = useState({});

  const storeLogoUrl = useSelector((state) => state?.session?.storeLogo);
  const [stampConfigs, setStampConfigs] = useState();
  const [stampDatas, setStampDatas] = useState();

  const [activeKeyInventoryTracking, setActiveKeyInventoryTracking] = useState("0");
  const [totalCostInitial, setTotalCostInitial] = useState(0);

  const pageData = {
    btnDelete: t("button.delete"),
    btnEdit: t("button.edit"),
    btnLeave: t("button.leave"),
    action: t("button.action"),
    btnCancel: t("button.cancel"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    productDeleteSuccess: t("productManagement.productDeleteSuccess"),
    productDeleteFail: t("productManagement.productDeleteFail"),
    generalInformation: {
      title: t("productManagement.generalInformation.title"),
      productName: {
        label: t("productManagement.generalInformation.productName"),
      },
      toppingName: t("productManagement.productInformation.toppingName"),
      description: {
        label: t("productManagement.generalInformation.description"),
      },
    },
    pricing: {
      title: t("productManagement.pricing.title"),
      price: t("productManagement.pricing.price"),
      pricesAndVariations: t("productManagement.pricing.pricesAndVariations"),
      variations: t("productManagement.pricing.variations"),
      priceVNDCup: t("productManagement.pricing.priceVNDCup"),
    },
    unit: {
      title: t("productManagement.unit.title"),
      recipe: t("productManagement.unit.recipe"),
    },
    optionInformation: {
      title: t("productManagement.option.title"),
    },
    inventoryTracking: {
      title: t("inventoryTracking.title"),
      byMaterial: t("inventoryTracking.byMaterial"),
      totalCost: t("table.totalCost"),
      pleaseEnterQuantity: t("inventoryTracking.pleaseEnterQuantity"),
      table: {
        materialName: t("productManagement.recipe.table.ingredients"),
        quantity: t("productManagement.recipe.table.quantityUnit"),
        unit: t("table.unit"),
        cost: t("productManagement.recipe.table.costPerUnit", { currency: getCurrency() }),
        totalCost: t("productManagement.recipe.table.totalCost", { currency: getCurrency() }),
      },
    },
    productCategory: {
      label: t("productManagement.category.productCategory"),
    },
    tax: {
      title: t("productManagement.details.tax"),
    },
    topping: t("productManagement.topping"),
    media: "MEDIA",
    notificationTitle: t("form.notificationTitle"),
    platform: {
      title: t("productManagement.platform.title"),
    },
    addTopping: t("productManagement.addTopping"),
    allToppings: t("productManagement.allToppings"),
    toppingSelected: t("productManagement.toppingSelected"),
    table: {
      name: t("productManagement.table.name"),
    },
    selectedTopping: t("productManagement.selectedTopping"),
    productHasActiveCombos: t("messages.productHasActiveCombos"),
    productHasActiveCampaigns: t("messages.productHasActiveCampaigns"),
    buttonIGotIt: t("form.buttonIGotIt"),
    comboName: t("combo.generalInformation.comboName"),
    campaignName: t("promotion.flashSale.name"),
    no: t("table.no"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    productActivatedSuccess: t("productManagement.productActivatedSuccess"),
    productDeactivatedSuccess: t("productManagement.productDeactivatedSuccess"),
    othersSetting: {
      title: t("productManagement.otherSetting.title"),
      isPrintStampTitle: t("productManagement.otherSetting.isPrintStampTitle"),
      stampPreviewDescription: t("productManagement.otherSetting.stampPreviewDescription"),
      thisIsPrintingProduct: t("productManagement.otherSetting.thisIsPrintingProduct"),
      thisIsNonPrintingProduct: t("productManagement.otherSetting.thisIsNonPrintingProduct"),
    },
    includeTopping: t("productManagement.includeTopping"),
    defaultOptionText: t("option.defaultOptionText"),
    totalProductCost: t("productManagement.productInformation.totalProductCost"),
    recipeTip: t("productManagement.recipe.recipeTip"),
  };

  const tableComboSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.comboName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/combo/detail/${record?.id}`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableCombo403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.comboName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscountSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link
            to={`/store/discount/detail/${record?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="combo-name"
          >
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscount403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscountCodeSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link
            to={`/store/discountCode/view/${record?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="combo-name"
          >
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscountCode403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableFlashSaleSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link
            to={`/store/flashSale/view/${record?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="combo-name"
          >
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableFlashSale403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    getInitData();
    getInitialStampData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitData = async () => {
    let response = await productDataService.getProductDetailByIdAsync(match?.params?.id);

    setProduct(response.product);

    setTotalCostInitial(response?.product?.productInventoryData[0]?.totalCost);

    if (response?.product?.statusId === ProductStatus.Activate) {
      setActivate("productManagement.deactivate");
    } else {
      setActivate("productManagement.activate");
    }
  };

  const onChangeStatus = async () => {
    if (product?.statusId === ProductStatus.Activate) {
      const productID = match?.params?.id;
      var relatedObjects = await productDataService.getAllProductDependenciesByIdAsync(productID);
      if (relatedObjects?.isHasDependencies) {
        //Show notification dialog
        setIsModalNotificationVisible(true);
        let message = t(pageData.productHasActiveCombos, { name: product?.name });
        setNotificationMessage(message);

        if (relatedObjects.activeCombos.length > 0) {
          if (hasPermission(PermissionKeys.VIEW_COMBO)) setNotificationTable(tableComboSettings);
          else setNotificationTable(tableCombo403Settings);
          setNotificationDatasource(relatedObjects.activeCombos);
        } else if (relatedObjects.activeDiscounts.length > 0) {
          message = t(pageData.productHasActiveCampaigns, { name: product?.name });
          setNotificationMessage(message);
          if (hasPermission(PermissionKeys.VIEW_PROMOTION)) setNotificationTable(tableDiscountSettings);
          else setNotificationTable(tableDiscount403Settings);
          setNotificationDatasource(relatedObjects.activeDiscounts);
        } else if (relatedObjects.activeFlashSales.length > 0) {
          message = t(pageData.productHasActiveCampaigns, { name: product?.name });
          setNotificationMessage(message);
          if (hasPermission(PermissionKeys.VIEW_FLASH_SALE)) setNotificationTable(tableFlashSaleSettings);
          else setNotificationTable(tableFlashSale403Settings);
          setNotificationDatasource(relatedObjects.activeFlashSales);
        } else if (relatedObjects.activeDiscountCodes.length > 0) {
          message = t(pageData.productHasActiveCampaigns, { name: product?.name });
          setNotificationMessage(message);
          if (hasPermission(PermissionKeys.VIEW_DISCOUNT_CODE)) setNotificationTable(tableDiscountCodeSettings);
          else setNotificationTable(tableDiscountCode403Settings);
          setNotificationDatasource(relatedObjects.activeDiscountCodes);
        }

        return;
      } else setIsModalNotificationVisible(false);
    }

    var res = await productDataService.changeStatusAsync(match?.params?.id);
    if (res) {
      if (product?.statusId === ProductStatus.Deactivate) {
        message.success(pageData.productActivatedSuccess);
      } else {
        message.success(pageData.productDeactivatedSuccess);
      }
      getInitData();
    }
  };

  const getFormSelectedMaterials = () => {
    return (
      <>
        <Row className="mt-3 mb-3">
          {product?.productPrices?.length > 1 && product?.productInventoryData?.length > 0 && (
            <Tabs
              type="card"
              className="w-100 recipe-product-price-tab-container"
              onChange={(index) => getTotalCost(index, true)}
            >
              {product?.productInventoryData?.map((p, index) => {
                return (
                  <TabPane
                    tab={
                      <div className="recipe-product-price-tab">
                        <div className="recipe-product-price-tab-icon">
                          {activeKeyInventoryTracking == index ? <CustomTickCircleIcon /> : ""}
                        </div>
                        <FnbTypography className="recipe-product-price-tab-text" text={p?.priceName} />
                      </div>
                    }
                    key={index}
                  >
                    <Row className="w-100">
                      <Col span={24}>
                        <FnbTable dataSource={p.listProductPriceMaterial} columns={columnsMaterial(index)} />
                      </Col>
                    </Row>
                  </TabPane>
                );
              })}
            </Tabs>
          )}
          {product?.productPrices?.length === 1 && product?.productInventoryData?.length > 0 && (
            <>
              <Row className="w-100 mt-2">
                <Col span={24}>
                  <FnbTable
                    dataSource={product?.productInventoryData[0]?.listProductPriceMaterial}
                    columns={columnsMaterial(0)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Row>
        <Row className="mt-20">
          <Col className="tip-product-receipt" xs={24} sm={24} md={16} lg={16} flex={1}>
            <LampOn />
            {pageData.recipeTip}
          </Col>
          <Col className="total-cost-product-receipt" xs={24} sm={24} md={8} lg={8}>
            <FnbTypography
              className="total-cost-product-receipt-title"
              text={`${pageData.totalProductCost}:${" "}`}
            ></FnbTypography>
            <div className="total-cost-product-receipt-value">
              <FnbTypography
                text={
                  totalCost
                    ? formatNumberDecimalOrInteger(totalCost ?? 0)
                    : formatNumberDecimalOrInteger(totalCostInitial ?? 0)
                }
              />
              <FnbTypography text={` ${getCurrency()}/ ${product?.unit?.name}`} />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const getTotalCost = (index, isChangeTab = false) => {
    let sum = 0;
    product.productInventoryData[index]?.listProductPriceMaterial?.map((material) => {
      sum = sum + material.quantity * material.unitCost;
    });
    if (isChangeTab) {
      setActiveKeyInventoryTracking(index);
    }
    setTotalCost(sum);
  };

  const columnsMaterial = (indexPriceName) => {
    let columns = [
      {
        title: pageData.inventoryTracking.table.materialName,
        dataIndex: "material",
        width: "44%",
        render: (_, record, index) => (
          <Form.Item name={["product", "materials", "priceName", indexPriceName, "material", index, "materialId"]}>
            <FnbTooltip onlyShowWhenEllipsis>{record.material}</FnbTooltip>
          </Form.Item>
        ),
      },
      {
        title: pageData.inventoryTracking.table.quantity,
        dataIndex: "quantity",
        width: "16%",
        align: "left",
        editable: true,
        render: (_, record, index) => (
          <Form.Item name={["product", "materials", "priceName", indexPriceName, "material", index, "quantity"]}>
            <p>
              {formatCurrencyWithoutSuffix(record.quantity)} {record?.unit}
            </p>
          </Form.Item>
        ),
      },
      {
        title: `${pageData.inventoryTracking.table.cost}`,
        dataIndex: "unitCost",
        align: "right",
        width: "22%",
        render: (_, record, index) => (
          <Form.Item name={["product", "materials", "priceName", indexPriceName, "material", index, "unitCost"]}>
            <p>{formatNumberDecimalOrInteger(record.unitCost)}</p>
          </Form.Item>
        ),
      },
      {
        title: `${pageData.inventoryTracking.table.totalCost}`,
        dataIndex: "cost",
        align: "right",
        width: "18%",
        render: (_, record, index) => (
          <Form.Item name={["product", "materials", "priceName", indexPriceName, "material", index, "cost"]}>
            <p>{formatNumberDecimalOrInteger(record.cost)}</p>
          </Form.Item>
        ),
      },
    ];
    return columns;
  };

  const renderOptions = () => {
    return (
      <Row className="mt-3" gutter={[0, 12]}>
        {product?.options?.map((option, index) => {
          option?.optionLevel.sort((elementA, elementB) => elementB.isSetDefault - elementA.isSetDefault);
          return (
            <>
              <Col span={20}>
                <FnbTypography text={option.name} variant={theme.typography["b1-bold"]} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <div className="option-description">
                      <div className="option-description__dv--flex-horizon">
                        <div>
                          <Ellipse className="option-description__icon--eclipse" />
                        </div>
                        <div>
                          <span className="option-description__dv-default__txt">=</span>
                        </div>
                        <div>
                          <span className="option-description__dv-default__txt">{pageData.defaultOptionText}</span>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 container-options" style={{ display: "flex", gap: 12 }}>
                      {option?.optionLevel?.map((item) => {
                        return <FnbChip isDefault={item?.isSetDefault} title={item?.name}></FnbChip>;
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
            </>
          );
        })}
      </Row>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const onDeleteItem = () => {
    const { id } = product;
    productDataService.getAllOrderNotCompletedByProductIdAsync(id).then((res) => {
      const { preventDeleteProduct } = res;

      setPreventDeleteProduct(preventDeleteProduct);
      if (!preventDeleteProduct?.isPreventDelete) {
        setTitleModal(pageData.confirmDelete);
      } else {
        setTitleModal(pageData.notificationTitle);
      }
      setIsModalVisible(true);
    });
  };
  const handleDeleteItem = async (productId) => {
    var res = await productDataService.deleteProductByIdAsync(productId);
    if (res) {
      message.success(pageData.productDeleteSuccess);
    } else {
      message.error(pageData.productDeleteFail);
    }
    setIsModalVisible(false);
    history.push("/product-management");
  };

  const onEditItem = () => {
    productDataService.getAllOrderNotCompletedByProductIdAsync(product?.id).then((res) => {
      const { preventDeleteProduct } = res;

      setPreventDeleteProduct(preventDeleteProduct);
      if (!preventDeleteProduct?.isPreventDelete) {
        return history.push(`/product/edit/${product?.id}`);
      } else {
        setTitleModal(pageData.notificationTitle);
      }
      setIsModalVisible(true);
    });
  };

  const productToppingSelectedColumnTable = () => {
    const column = [
      {
        title: pageData.table.name,
        dataIndex: "thumbnail",
        render: (_, record) => {
          return <Image preview={false} src={record.thumbnail ?? "error"} fallback={productDefaultImage} />;
        },
      },
      {
        title: " ",
        width: "80%",
        dataIndex: "name",
        align: "left",
      },
    ];

    return column;
  };

  const columnPrices = () => {
    const column = [
      {
        title: pageData.pricing.variations,
        dataIndex: "priceName",
        width: "50%",
        render: (_, record) => {
          return record?.priceName;
        },
      },
      {
        title: `${pageData.pricing.price} (${getCurrency()}/${product?.unit?.name})`,
        width: "50%",
        dataIndex: "priceValue",
        align: "right",
        render: (_, record) => {
          return (
            <CurrencyFormat value={record?.priceValue} displayType={"text"} thousandSeparator={true} decimalScale={2} />
          );
        },
      },
    ];

    return column;
  };

  const renderModalContent = () => {
    return (
      <Form>
        <div className="modal-product-topping">
          <Row className="modal-product-topping-table-detail">
            <Col span={24}>
              <FnbTable
                className="selected-product-topping-modal"
                dataSource={product?.productToppings}
                columns={productToppingSelectedColumnTable()}
              />
            </Col>
          </Row>
        </div>
      </Form>
    );
  };

  const handleCancelToppingModal = () => {
    setIsVisibaleProductToppingModal(false);
  };

  const items = [
    {
      label: (
        <a
          className={activate === "productManagement.activate" ? "action-activate" : "action-deactivate"}
          onClick={() => onChangeStatus()}
        >
          {t(activate)}
        </a>
      ),
      permission: PermissionKeys.ACTIVATE_PRODUCT,
      key: "0",
    },
    {
      label: (
        <a
          className="action-delete"
          onClick={() => {
            onDeleteItem();
          }}
        >
          {pageData.btnDelete}
        </a>
      ),
      key: "1",
      permission: null,
    },
  ];

  //Handle for Other settings
  const renderStampPreview = () => {
    return (
      <div className="stamp-preview-setting">
        <div
          className="stamp-preview-setting-description"
          dangerouslySetInnerHTML={{ __html: pageData.othersSetting.stampPreviewDescription }}
        ></div>
        <div className="stamp-preview-setting-template">
          {stampConfigs && stampDatas && (
            <StampTemplatePreviewComponent stampConfig={stampConfigs} stampData={stampDatas} />
          )}
        </div>
      </div>
    );
  };

  const getInitialStampData = async () => {
    const stampData = createStampMockupData();
    let res = await stampDataService.getStampConfigByStoreIdAsync();
    if (res) {
      let formValue = undefined;
      if (res.stampConfig !== undefined) {
        let data = res?.stampConfig;
        formValue = {
          stampType: data?.stampType,
          isShowLogo: data?.isShowLogo,
          isShowTime: data?.isShowTime,
          isShowNumberOfItem: data?.isShowNumberOfItem,
          isShowNote: data?.isShowNote,
          isShowArea: data?.isShowArea,
          isShowAreaTable: data?.isShowAreaTable,
        };
      } else {
        formValue = {
          stampType: StampType.mm50x30,
          isShowLogo: true,
          isShowTime: true,
          isShowNumberOfItem: true,
          isShowNote: true,
          isShowArea: true,
          isShowAreaTable: true,
        };
      }
      setTimeout(() => {
        stampData.logo = storeLogoUrl;
      }, 300);
      setTimeout(() => {
        setStampConfigs(formValue);
        setStampDatas(stampData);
      }, 500);
    }
  };

  const createStampMockupData = () => {
    const stampData = {
      code: "#I1003",
      logo: storeLogoUrl,
      createdTime: "2022-07-06 10:03:41.6983432",
      areaName: "VIP",
      areaTableName: "V01",
      itemList: [
        {
          no: "1",
          name: "Coffee",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
            {
              name: "Ice",
              value: "50%",
            },
            {
              name: "Pudding",
              value: "x122",
            },
          ],
          current: true,
        },
        {
          no: "2",
          name: "Milk tea",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
          ],
          current: false,
        },
      ],
    };

    return stampData;
  };

  return (
    <>
      <Form layout="vertical" autoComplete="off" className="product-detail-form">
        <FnbHeadingPage
          title={product?.name}
          activeStatus={{
            content:
              product.statusId === ProductStatus.Activate ? (
                <FnbBadge variant="success" text={pageData.active}></FnbBadge>
              ) : (
                product.statusId === ProductStatus.Deactivate && (
                  <FnbBadge variant="error" text={pageData.inactive}></FnbBadge>
                )
              ),
          }}
          listButtons={[
            <FnbButton
              variant="tertiary"
              onClick={() => history.push(`/product-management`)}
              text={pageData.btnCancel}
            />,
            <FnbButton
              variant="tertiary"
              danger
              onClick={() => onDeleteItem()}
              permission={PermissionKeys.DELETE_PRODUCT}
              iconHeader={<CustomTrashIcon color="#DB1B1B" />}
            />,
            <FnbButton
              text={t(activate)}
              variant="secondary"
              permission={
                product.statusId === ProductStatus.Activate
                  ? PermissionKeys.DEACTIVATE_PRODUCT
                  : PermissionKeys.ACTIVATE_PRODUCT
              }
              onClick={() => onChangeStatus()}
            />,
            <FnbButton text={pageData.btnEdit} onClick={() => onEditItem()} permission={PermissionKeys.EDIT_PRODUCT} />,
          ]}
        />
        <Row className="product-container">
          <div className="product-form-left">
            <div className="card-genaral padding-t-l-b">
              <div className="div-title">
                <Text className="text-title">
                  {pageData.generalInformation.title}
                  <div className="text-title-underlined"></div>
                </Text>
              </div>
              <Row className="w-100">
                <Col xs={12} sm={24} md={24} lg={12}>
                  <div className="product-detail-div">
                    <Text className="text-item">
                      {product?.isTopping
                        ? pageData.generalInformation.toppingName
                        : pageData.generalInformation.productName.label}
                    </Text>
                  </div>
                  <div className="product-detail-div">
                    <Text className="text-name">{product?.name}</Text>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <div className="product-detail-div">
                    <Text className="text-item">{pageData.productCategory.label}</Text>
                  </div>
                  <div className="product-detail-div">
                    <Text className="text-name">{product?.productCategoryName ?? "-"}</Text>
                  </div>
                </Col>
              </Row>
              <div className="product-detail-div">
                <Text className="text-item">{pageData.generalInformation.description.label}</Text>
              </div>
              <div className="product-detail-div">
                <Text className="text-name">{product?.description || "-"}</Text>
              </div>
              {product?.isIncludedAllToppings ? (
                <>
                  <div className="product-detail-div">
                    <Text className="text-item">{pageData.includeTopping}</Text>
                  </div>
                  <FnbTypography className="all-toppings" text={pageData.allToppings} />
                </>
              ) : (
                product?.productToppings?.length > 0 && (
                  <>
                    <div className="product-detail-div">
                      <Text className="text-item">{pageData.includeTopping}</Text>
                    </div>
                    <Col>
                      <Row gutter={[12, 12]} className="list-topping-chip">
                        {product?.productToppings?.map((item) => {
                          return (
                            <Col>
                              <div className="w-100 container-toppings">
                                <FnbChip
                                  id={item?.id}
                                  iconHeader={
                                    item?.thumbnail ? (
                                      <Image preview={false} src={item?.thumbnail} />
                                    ) : (
                                      <ImageMaterialDefault />
                                    )
                                  }
                                  isDisableToolTip={true}
                                  title={item.name}
                                  isRemoveChip={false}
                                ></FnbChip>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </>
                )
              )}
            </div>
            <div className="card-genaral padding-t-l-b">
              <div className="div-title">
                <Text className="text-title">
                  {pageData.pricing.pricesAndVariations}
                  <div className="text-title-underlined"></div>
                </Text>
              </div>
              <div className="product-detail-div">
                {product?.productPrices?.length > 1 && (
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <FnbTable dataSource={product?.productPrices} columns={columnPrices()} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div className="div-text pt-3 text-align-right">
                        <Text className="text-item">{pageData.tax.title}: </Text>
                        <Text className="text-name-price">
                          {product?.tax?.name ? `${product?.tax?.name} (${product?.tax?.percentage} %)` : "-"}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                )}
                {product?.productPrices?.length === 1 && (
                  <Row className="w-100">
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <div className="product-detail-div">
                        <Text className="text-item">{pageData.pricing.price}: </Text>
                        <Text className="text-name-price">
                          {formatCurrency(product?.productPrices?.[0]?.priceValue)}/{product?.unit?.name}
                        </Text>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <div className="product-detail-div">
                        <Text className="text-item">{pageData.tax.title}: </Text>
                        <Text className="text-name-price">
                          {product?.tax?.name ? `${product?.tax?.name} (${product?.tax?.percentage} %)` : "-"}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <div className="card-genaral padding-t-l-b">
              <div className="div-title">
                <Text className="text-title">
                  {pageData.unit.recipe}
                  <div className="text-title-underlined"></div>
                </Text>
              </div>
              <div className="dev-form-material">{getFormSelectedMaterials()}</div>
            </div>
          </div>
          <div className="product-form-right">
            <div className="form-image padding-t-l-b">
              <div className="content-img">
                <Image width={"100%"} src={product?.thumbnail ?? "error"} fallback={productImageDefault} />
              </div>
            </div>
            {!product?.isTopping && (
              <>
                <div className="form-option padding-t-l-b">
                  <Text className="text-title-option">{pageData.optionInformation.title}</Text>
                  {renderOptions()}
                </div>
              </>
            )}
            <div className="form-option padding-t-l-b">
              <Text className="text-title-option">{pageData.platform.title}</Text>
              <div className="platform-group">
                {product?.platforms?.map((p, index) => {
                  return (
                    <FnbTypography
                      text={
                        (p.name === "POS" ? "POS devices" : p.name) +
                        (index === product?.platforms?.length - 1 ? "" : ", ")
                      }
                      variant={theme.typography["b1-medium"]}
                    />
                  );
                })}
              </div>
            </div>

            <FnbCard className="form-other-settings">
              <FnbTypography className="title-other-setting" text={pageData.othersSetting.title} />
              <Row className="isPrintStamp-container">
                <Col className="isPrintStamp-title-container">
                  <Row className="isPrintStamp-title">
                    {product?.isPrintStamp
                      ? pageData.othersSetting.thisIsPrintingProduct
                      : pageData.othersSetting.thisIsNonPrintingProduct}
                    <FnbTooltip
                      placement="bottom"
                      maxWidth="392px"
                      variant="secondary"
                      title={() => renderStampPreview()}
                    >
                      <CustomInfoCircleIcon />
                    </FnbTooltip>
                  </Row>
                </Col>
              </Row>
            </FnbCard>
          </div>
        </Row>
      </Form>
      <FnbModal
        width={"800px"}
        title={pageData.selectedTopping}
        visible={isVisibaleProductToppingModal}
        handleCancel={handleCancelToppingModal}
        hideFooter={true}
        content={renderModalContent()}
      ></FnbModal>
      <DeleteProductComponent
        isModalVisible={isModalVisible}
        preventDeleteProduct={preventDeleteProduct}
        titleModal={titleModal}
        handleCancel={() => handleCancel()}
        onDelete={handleDeleteItem}
      />
      <FnbNotifyDialog
        title={pageData.notificationTitle}
        open={isModalNotificationVisible}
        hideCancelButton={true}
        okText={pageData.buttonIGotIt}
        onOk={() => {
          setIsModalNotificationVisible(false);
        }}
        onCancel={() => {
          setIsModalNotificationVisible(false);
        }}
        className={"fnb-notify-dialog-product-dependencies"}
        content={() => {
          return (
            <>
              <div
                className="text-content-notification-product-dependencies"
                dangerouslySetInnerHTML={{
                  __html: notificationMessage,
                }}
              />
              <Table
                className="table-product-dependencies"
                columns={notificationTable}
                dataSource={notificationDatasource}
                pagination={false}
              />
            </>
          );
        }}
      />
    </>
  );
}
