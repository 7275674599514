import http from "../../utils/http-common";

const controller = "loyaltypoint";

const activeLoyaltyPointAsync = (data) => {
  return http.put(`/${controller}/active`, data);
};

const loyaltyPointConfigDataService = {
  activeLoyaltyPointAsync,
};
export default loyaltyPointConfigDataService;
