import { Modal } from "antd";
import "./fnb-modal-component.scss";
import { FnbCancelButton } from "components/cancel-button";
import { FnbButton } from "components/fnb-button/fnb-button";
import { CloseOutlined } from "constants/icons.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import classNames from "classnames";

export function FnbModal({
  width,
  visible,
  title,
  cancelText,
  handleCancel,
  handleClose,
  okText,
  onOk,
  content,
  footer,
  hideFooter = false,
  closeIcon = <CloseOutlined />,
  className = "",
  okButtonProps,
  cancelButtonProps,
  centered = true,
  closable = false,
  top,
  style,
  permissionOk,
  disabledBtnOk = false,
  loadingOk = false,
  typeButtonSubmit = "button",
  form,
  isDestroyOnClose = false,
  bodyStyle,
  titleCustom = null,
  isAllowScrollContent = false,
  zIndex,
}) {
  const titleElement = (
    <FnbTooltip title={title} onlyShowWhenEllipsis>
      {title}
    </FnbTooltip>
  );

  const allowContentScrollCss = isAllowScrollContent && {
    overflowY: "auto",
    maxHeight: "calc(100vh - 200px)",
  };

  const classNameModal = classNames({
    "fnb-modal-component": true,
    [`${className}`]: true,
    "fnb-modal-component--title-custom": titleCustom
  })

  return (
    <>
      <Modal
        closeIcon={closeIcon}
        width={width}
        className={classNameModal}
        open={visible}
        title={titleCustom ?? titleElement}
        cancelText={cancelText}
        onCancel={handleCancel ?? handleClose}
        okText={okText}
        onOk={onOk}
        footer={
          footer ??
          (hideFooter ? null : (
            <>
              {handleCancel && <FnbCancelButton buttonText={cancelText} onOk={handleCancel} minWidth={"120px"} />}
              {(onOk || typeButtonSubmit === "submit") && (
                <FnbButton
                  type={typeButtonSubmit}
                  text={okText}
                  onClick={onOk}
                  permission={permissionOk}
                  minWidth={"120px"}
                  disabled={disabledBtnOk}
                  form={form}
                  loading={loadingOk}
                />
              )}
            </>
          ))
        }
        okButtonProps={okButtonProps}
        cancelButtonProps={cancelButtonProps}
        centered={centered}
        closable={closable}
        style={{ ...style, top: top }}
        destroyOnClose={isDestroyOnClose}
        bodyStyle={{ ...bodyStyle, ...allowContentScrollCss }}
        zIndex={zIndex}
      >
        {content}
      </Modal>
    </>
  );
}
