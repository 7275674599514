import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import orderDataService from "../../../data-services/order-data.service";
import { setToastMessageAddUpdateProductToCart } from "../../../modules/toast-message/toast-message.actions";
import { HttpStatusCode } from "../../../utils/http-common";
import { EnumOrderMessageCode } from "../../constants/enums";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog.component";
import "./cancel-order-button.component.scss";
const CancelOrderButton = (props) => {
  const { orderId, buttonText, callBack, className, cancelOrderErrorContent } = props;
  const [open, setOpen] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const translateData = {
    confirmCancel: t("confirmCancel", "Xác nhận hủy"),
    ignore: t("ignore", "Từ chối"),
    contentCancelOrder: t("contentCancelOrder", "Bạn có thực sự muốn hủy đơn hàng này?"),
    notification: t("loginPage.notification", "Thông báo"),
    hotline: t("myProfile.myOrders.hotline", "Thông báo"),
    theOrderIsConfirmedAndBeingPrepared: t("myProfile.myOrders.theOrderIsConfirmedAndBeingPrepared", "Thông báo"),
    okay: t("order.okay", "Okay"),
  };

  const onCancelOrder = async () => {
    // Call api cancel order
    setIsLoading(true);
    const dataRequest = {
      orderId: orderId,
    };
    const response = await orderDataService.cancelOrderAsync(dataRequest);
    // if response success -> callback
    if (response.status == HttpStatusCode.Ok) {
      if (response?.data?.messageCode == EnumOrderMessageCode.success) {
        if (callBack) {
          callBack();
        }
        dispatch(
          setToastMessageAddUpdateProductToCart({
            icon: null,
            message: t("toastMessageCancelOrder", "Hủy đơn hàng thành công"),
          }),
        );
        setOpen(false);
      } else if (response?.data?.messageCode == EnumOrderMessageCode.CancelOrderError) {
        setOpen(false);
        setCancelStatus(true);
      }
    } else {
      setOpen(false);
    }
    setIsLoading(false);
  };

  const onConfirmCannotCancelOrder = () => {
    setCancelStatus(false);
  };

  return (
    <>
      <Button
        className={className}
        onClick={() => {
          setOpen(true);
          setIsLoading(false);
        }}
      >
        {buttonText ?? "Cancel order"}
      </Button>
      <ConfirmationDialog
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={onCancelOrder}
        confirmLoading={isLoading}
        okText={translateData.confirmCancel ?? "Confirm cancel"}
        cancelText={translateData.ignore ?? "Ignore"}
        content={translateData.contentCancelOrder}
        title={translateData.notification}
      />
      <ConfirmationDialog
        open={cancelStatus}
        confirmLoading={isLoading}
        className="verify-cancel-order-notification-dialog-content"
        content={cancelOrderErrorContent}
        title={translateData.notification}
        footer={[<Button onClick={onConfirmCannotCancelOrder}>{translateData.okay}</Button>]}
      />
    </>
  );
};

export default CancelOrderButton;
