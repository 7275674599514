/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, DatePicker, Form, InputNumber, message, Radio, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import {
  ageConditionOptions,
  customerDataOptions,
  genderConditionOptions,
  monthsInYearOptions,
  objectiveOptions,
  orderConditionOptions,
  orderDataOptions,
  registrationDateConditionOptions,
} from "constants/customer-segment-condition-option.constants";
import {
  ageDataEnum,
  customerDataEnum,
  genderEnum,
  objectiveEnum,
  orderConditionEnum,
  orderDataEnum,
  registrationDateConditionEnum,
} from "constants/customer-segment-condition.constants";
import {
  DELAYED_TIME,
  inputNumberRange0To100,
  inputNumberRange0To10000,
  inputNumberRange0To999999999,
  inputNumberRange0To999999999DotAllow,
} from "constants/default.constants";
import { PlusCircleIcon, TrashFill } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { currency, DateFormat } from "constants/string.constants";
import customerDataService from "data-services/customer/customer-data.service";
import storeDataService from "data-services/store/store-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getCurrency, getValidationMessages } from "utils/helpers";
import "./create-customer-segment.page.scss";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbButton } from "components/fnb-button/fnb-button";

export default function CreateCustomerSegment(props) {
  const { t, history, customerSegmentDataService } = props;
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: "",
    isAllMatch: false,
    conditions: [],
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const isMobileMode = useMediaQuery({ maxWidth: 575 });
  const [listPlatforms, setListPlatforms] = useState([]);
  const [listCustomerTag, setListCustomerTag] = useState([]);

  const pageData = {
    title: t("customerSegment.createCustomerSegment"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    btnAddNew: t("button.addNew"),
    name: {
      label: t("customerSegment.name"),
      placeholder: t("customerSegment.namePlaceholder"),
      required: true,
      maxLength: 100,
      validateMessage: t("customerSegment.nameValidateMessage"),
    },
    namePlaceholder: t("customerSegment.namePlaceholder"),
    condition: {
      days: t("customerSegment.condition.days"),
      daysTillNow: t("customerSegment.condition.daysTillNow"),
      title: t("customerSegment.condition.title"),
      objective: t("customerSegment.condition.objective"),
      condition: t("customerSegment.condition.condition"),
      customerData: t("customerSegment.condition.customerData"),
      orderData: t("customerSegment.condition.orderData"),
      registrationDate: t("customerSegment.condition.registrationDate"),
      birthday: {
        label: t("customerSegment.condition.birthday"),
        placeholder: t("customerSegment.condition.birthdayPlaceholder"),
        required: true,
        validateMessage: t("customerSegment.condition.birthdayValidateMessage"),
      },
      gender: t("customerSegment.condition.gender"),
      tag: t("customerSegment.condition.tag"),
      tags: {
        label: t("customerSegment.condition.tags"),
        placeholder: t("customerSegment.condition.tagsPlaceholder"),
        required: true,
        validateMessage: t("customerSegment.condition.tagsValidateMessage"),
      },
      on: t("customerSegment.condition.on"),
      before: t("customerSegment.condition.before"),
      after: t("customerSegment.condition.after"),
      time: {
        label: t("customerSegment.condition.time"),
        placeholder: t("customerSegment.condition.timePlaceholder"),
        required: true,
        validateMessage: t("customerSegment.condition.timeValidateMessage"),
      },
      male: t("customerSegment.condition.male"),
      is: t("customerSegment.condition.is"),
      are: t("customerSegment.condition.are"),
      totalOrders: t("customerSegment.condition.totalOrders"),
      totalPurchaseAmount: {
        label: t("customerSegment.condition.totalPurchaseAmount"),
        placeholder: t("customerSegment.condition.totalPurchaseAmountPlaceholder"),
        required: true,
        validateMessage: t("customerSegment.condition.totalPurchaseAmountValidateMessage"),
        max: 999999999,
        min: 0,
        format: "^[0-9]*$",
      },
      isEqual: t("customerSegment.condition.isEqual"),
      isLargerThan: t("customerSegment.condition.isLargerThan"),
      isLessThan: t("customerSegment.condition.isLessThan"),
      orderNumber: {
        label: t("customerSegment.condition.orderNumber"),
        placeholder: t("customerSegment.condition.orderNumberPlaceholder"),
        required: true,
        validateMessage: t("customerSegment.condition.orderNumberValidateMessage"),
      },
      allTime: t("customerSegment.condition.allTime"),
      thisWeek: t("customerSegment.condition.thisWeek"),
      thisMonth: t("customerSegment.condition.thisMonth"),
      thisYear: t("customerSegment.condition.thisYear"),
      amount: t("customerSegment.condition.amount"),
      add: t("customerSegment.condition.addCondition"),
      ifAnyMatch: t("customerSegment.condition.ifAnyMatch"),
      allMatch: t("customerSegment.condition.allMatch"),
      age: t("customerSegment.condition.age"),
      platformValidateMessage: t("customerSegment.condition.platformValidateMessage"),
      tagValidateMessage: t("customerSegment.condition.tagValidateMessage"),
      ageValidateMessage: t("customerSegment.condition.ageValidateMessage"),
      platformPlaceholder: t("customerSegment.condition.platformPlaceholder"),
      tagPlaceholder: t("customerSegment.condition.tagPlaceholder"),
      agePlaceholder: t("customerSegment.condition.agePlaceholder"),
      ageRankValidateMessage: t("customerSegment.condition.ageRankValidateMessage"),
    },
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    leaveWarningMessage: t("messages.leaveWarningMessage"),
    customerSegmentAddSuccess: t("customerSegment.customerSegmentAddSuccess"),
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    generalInformation: t("customer.generalInformation"),
    valueShouldBeFrom0to999999999: t("customerSegment.condition.valueShouldBeFrom0to999999999"),
    valueShouldBeFrom0to10000: t("customerSegment.condition.valueShouldBeFrom0to10000"),
  };

  useEffect(() => {
    setInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initConditionData = {
    objectiveId: objectiveEnum.customerData,
    customerDataId: customerDataEnum.registrationDate,
    registrationDateConditionId: registrationDateConditionEnum.on,
    orderDataId: orderDataEnum.totalCompletedOrders,
    orderConditionId: orderConditionEnum.isEqual,
    days: 0,
    orderStartTime: moment(),
    orderEndTime: moment(),
    orderTypeFilterTime: OptionDateTime.allTime,
    orderNumber: 0,
  };
  const setInitData = async () => {
    let resPlatform = await storeDataService.getAllPlatformActivatedAsync();
    if (resPlatform) {
      setListPlatforms(resPlatform.platforms);
    }

    let resCustomerTag = await customerDataService.getCustomerTagAsync();
    if (resCustomerTag) {
      setListCustomerTag(resCustomerTag.tags);
    }

    let initFormData = {
      ...formData,
      conditions: [initConditionData],
    };

    form.setFieldsValue(initFormData);
    setFormData(initFormData);
  };

  /// update form data
  const updateFormData = (formData) => {
    const formValues = form.getFieldsValue();
    const { name, isAllMatch } = formValues;

    let initFormData = {
      name: name,
      isAllMatch: isAllMatch,
      conditions: formData.conditions,
    };

    form.setFieldsValue(initFormData);
    setFormData(initFormData);
  };

  /// Submit form
  const onSubmitForm = () => {
    form.validateFields().then(async (values) => {
      customerSegmentDataService
        .createCustomerSegmentAsync(values)
        .then((res) => {
          if (res) {
            message.success(pageData.customerSegmentAddSuccess);
            onCompleted();
          }
        })
        .catch((errs) => {
          form.setFields(getValidationMessages(errs));
        });
    });
  };

  /// Add condition
  const onAddCondition = () => {
    const formValues = form.getFieldsValue();
    const { name, isAllMatch } = formValues;

    let initFormData = {
      name: name,
      isAllMatch: isAllMatch,
      conditions: [...formData.conditions, initConditionData],
    };

    form.setFieldsValue({ ...formValues, ...initFormData });
    setFormData(initFormData);
  };

  /// Delete condition
  const deleteCondition = (index) => {
    const { name, isAllMatch, conditions } = formData;
    const newConditions = conditions.filter((_, i) => i !== index);
    let initFormData = {
      name: name,
      isAllMatch: isAllMatch,
      conditions: newConditions,
    };
    updateFormData(initFormData);
  };
  /// Change objectiveId
  const onChangeObjective = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].objectiveId = value;
    }
    updateFormData(formData);
  };

  /// Change customerDataId
  const onChangeCustomerData = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].customerDataId = value;
      if (value === customerDataEnum.gender) {
        conditions[index].gender = genderEnum.female;
      }
      if (value === customerDataEnum.age) {
        conditions[index].age = 25;
        conditions[index].ageConditionId = ageDataEnum.isEqual;
      }
    }
    updateFormData(formData);
  };

  /// Change registrationDateConditionId
  const onChangeRegistrationDateCondition = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].registrationDateConditionId = value;
    }
    updateFormData(formData);
  };
  /// Change orderDataId
  const onChangeOrderData = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].orderDataId = value;
      conditions[index].orderConditionId = orderConditionEnum.isEqual;
      conditions[index].orderTypeFilterTime = OptionDateTime.allTime;
      conditions[index].orderNumber = 0;
      conditions[index].days = 0;
      conditions[index].purchasedAmount = 0;
      conditions[index].orderStartTime = "";
      conditions[index].orderEndTime = "";
      conditions[index].orderTime = {
        startDate: "",
        endDate: "",
      };
    }
    updateFormData(formData);
  };

  const onChangeOrderCondition = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].orderConditionId = value;
    }
    updateFormData(formData);
  };

  const onChangeOrderNumber = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].orderNumber = value;
    }
    updateFormData(formData);
  };

  const onChangePurchasedAmount = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].purchasedAmount = value;
    }
    updateFormData(formData);
  };

  const onChangeDays = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].days = value;
    }
    updateFormData(formData);
  };

  /// Change registrationTime
  const onChangeRegistrationTime = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].registrationTime = value;
    }
    updateFormData(formData);
  };

  /// Change birthdayTime
  const onChangeBirthday = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].birthday = value;
    }
    updateFormData(formData);
  };

  /// Change gender
  const onChangeGender = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].gender = value;
    }
    updateFormData(formData);
  };

  /// Change ageCondition
  const onChangeAgeCondition = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].ageConditionId = value;
    }
    updateFormData(formData);
  };

  /// Change ageCondition
  const onChangeAge = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].age = value;
    }
    updateFormData(formData);
  };

  /// Change Platform
  const onChangePlatform = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].platformId = value;
    }
    updateFormData(formData);
  };

  /// Change Tag
  const onChangeTag = (value, index) => {
    let { conditions } = formData;
    if (conditions) {
      conditions[index].tagId = value;
    }
    updateFormData(formData);
  };

  /// render multiple conditions
  const renderConditions = () => {
    const { conditions } = formData;
    return conditions?.map((condition, index) => {
      return renderCondition(condition, index);
    });
  };

  const onSelectedDatePicker = (date, typeOptionDate, index) => {
    let { conditions } = formData;

    if (conditions) {
      conditions[index].orderStartTime = date.startDate;
      conditions[index].orderEndTime = date.endDate;
      conditions[index].orderTime = date;
      conditions[index].orderTypeFilterTime = typeOptionDate;
    }

    updateFormData(formData);
  };

  /// Render single condition
  const renderCondition = (condition, index) => {
    const deleteIcon = formData.conditions?.length > 1 ? <TrashFill className="icon-del" /> : <></>;
    return (
      <>
        <Row key={index} style={{ marginTop: isMobileMode ? "12px" : "24px", marginLeft: "40px" }} gutter={[0, 24]}>
          <Col sm={24} xs={24} lg={5}>
            {index !== 0 && isMobileMode && (
              <hr style={{ border: "1px solid #F1EEFF", width: "100%", margin: "24px 0" }} />
            )}
            <Form.Item name={["conditions", index, "objectiveId"]} label={pageData.condition.objective}>
              <FnbSelectSingle
                getPopupContainer={(trigger) => trigger.parentNode}
                defaultValue={objectiveEnum.customerData}
                onChange={(value) => onChangeObjective(value, index)}
                option={objectiveOptions?.map((item, index) => ({
                  id: item.id,
                  name: t(item.name),
                  key: item.id,
                }))}
              />
              {isMobileMode && index !== 0 && (
                <a className="customer-segment-trash-icon" onClick={() => deleteCondition(index)}>
                  {deleteIcon}
                </a>
              )}
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} lg={18} className="customer-segment-data">
            {condition?.objectiveId === objectiveEnum.customerData && renderOptionCustomerData(condition, index)}
            {condition?.objectiveId === objectiveEnum.orderData && renderOptionOrderData(condition, index)}
          </Col>
          <Col sm={24} xs={24} lg={1}>
            <div
              style={
                isMobileMode ? { marginTop: "-20px", marginLeft: "40px", marginBottom: "20px" } : { marginTop: "8px" }
              }
            >
              <h4>&nbsp;</h4>
              {!isMobileMode && index !== 0 && <a onClick={() => deleteCondition(index)}>{deleteIcon}</a>}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  /// Render Customer data
  const renderOptionCustomerData = (condition, index) => {
    return (
      <>
        <Row key={index} gutter={[0, 24]} className="row-option-customer-data">
          <Col
            sm={24}
            xs={24}
            lg={8}
            style={
              isMobileMode
                ? { maxWidth: "266px", marginLeft: "40px" }
                : { paddingRight: "16px", paddingLeft: "16px", marginLeft: "0px" }
            }
          >
            <div className={`${!isMobileMode && "ml-2"}`}>
              <Form.Item name={["conditions", index, "customerDataId"]} label={pageData.condition.customerData}>
                <FnbSelectSingle
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultValue={customerDataEnum.registrationDate}
                  onChange={(value) => onChangeCustomerData(value, index)}
                  option={customerDataOptions?.map((item, index) => ({
                    id: item.id,
                    name: t(item.name),
                    key: item.id,
                  }))}
                />
              </Form.Item>
            </div>
          </Col>
          <Col sm={24} xs={24} lg={16}>
            {condition?.customerDataId === customerDataEnum.registrationDate && (
              <Row gutter={[0, 24]}>
                <Col
                  sm={24}
                  xs={24}
                  lg={12}
                  style={
                    isMobileMode
                      ? { maxWidth: "266px", marginLeft: "40px" }
                      : { paddingRight: "16px", marginLeft: "0px" }
                  }
                >
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item
                      name={["conditions", index, "registrationDateConditionId"]}
                      label={pageData.condition.condition}
                    >
                      <FnbSelectSingle
                        getPopupContainer={(trigger) => trigger.parentNode}
                        defaultValue={registrationDateConditionEnum.on}
                        onChange={(value) => onChangeRegistrationDateCondition(value, index)}
                        option={registrationDateConditionOptions?.map((item) => ({
                          id: item.id,
                          name: t(item.name),
                          key: item.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  sm={24}
                  xs={24}
                  lg={12}
                  style={
                    isMobileMode
                      ? { maxWidth: "266px", marginLeft: "40px" }
                      : { paddingRight: "16px", marginLeft: "0px" }
                  }
                >
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item
                      name={["conditions", index, "registrationTime"]}
                      rules={[
                        {
                          required: pageData.condition.time.required,
                          message: pageData.condition.time.validateMessage,
                        },
                      ]}
                      label={pageData.condition.time.label}
                    >
                      <DatePicker
                        className="fnb-date-picker w-100"
                        format={DateFormat.DD_MM_YYYY}
                        placeholder={pageData.condition.time.placeholder}
                        onChange={(value) => onChangeRegistrationTime(value, index)}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            )}
            {condition?.customerDataId === customerDataEnum.birthday && (
              <Row gutter={[0, 24]}>
                <Col
                  sm={24}
                  xs={24}
                  lg={12}
                  style={
                    isMobileMode
                      ? { maxWidth: "266px", marginLeft: "40px", paddingBottom: "10px" }
                      : { textAlign: "center", paddingTop: "32px" }
                  }
                >
                  <div className="customer-data-condition-text">
                    <h4 className="fnb-form-label">{pageData.condition.on}</h4>
                  </div>
                </Col>
                <Col
                  sm={24}
                  xs={24}
                  lg={12}
                  style={isMobileMode ? { maxWidth: "266px", marginLeft: "40px" } : { paddingRight: "16px" }}
                >
                  <Form.Item
                    name={["conditions", index, "birthday"]}
                    rules={[
                      {
                        required: pageData.condition.birthday.required,
                        message: pageData.condition.birthday.validateMessage,
                      },
                    ]}
                    label={pageData.condition.time.label}
                  >
                    <FnbSelectSingle
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={pageData.condition.birthday.placeholder}
                      onChange={(value) => onChangeBirthday(value, index)}
                      option={monthsInYearOptions?.map((item, index) => ({
                        id: item.id,
                        name: t(item.name),
                        key: item.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {condition?.customerDataId === customerDataEnum.gender && (
              <>
                <Row>
                  <Col
                    sm={24}
                    xs={24}
                    lg={10}
                    style={
                      isMobileMode
                        ? { maxWidth: "266px", marginLeft: "40px", paddingBottom: "10px" }
                        : { textAlign: "center", paddingTop: "32px" }
                    }
                  >
                    <div className="customer-data-condition-text">
                      <h4 className="fnb-form-label">{pageData.condition.is}</h4>
                    </div>
                  </Col>
                  <Col
                    sm={24}
                    xs={24}
                    lg={14}
                    style={
                      isMobileMode
                        ? { maxWidth: "266px", marginLeft: "40px", paddingBottom: "-20px", marginTop: "0px" }
                        : { paddingTop: "8px", marginTop: "32px" }
                    }
                  >
                    <Form.Item name={["conditions", index, "gender"]}>
                      <Radio.Group onChange={(e) => onChangeGender(e.target.value, index)}>
                        {genderConditionOptions.map((item) => {
                          return <Radio value={item.id}>{t(item.name)}</Radio>;
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {condition?.customerDataId === customerDataEnum.tag && (
              <>
                <Row>
                  <Col
                    sm={24}
                    xs={24}
                    lg={12}
                    style={
                      isMobileMode
                        ? { maxWidth: "266px", marginLeft: "40px", paddingBottom: "10px" }
                        : { textAlign: "center", paddingTop: "32px" }
                    }
                  >
                    <div className="customer-data-condition-text">
                      <h4 className="fnb-form-label">{pageData.condition.is}</h4>
                    </div>
                  </Col>
                  <Col
                    sm={24}
                    xs={24}
                    lg={12}
                    style={
                      isMobileMode
                        ? { maxWidth: "266px", marginLeft: "40px" }
                        : { paddingRight: "16px", marginLeft: "0px" }
                    }
                  >
                    <Form.Item
                      name={["conditions", index, "tagId"]}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(pageData.condition.tagValidateMessage);
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      label={pageData.condition.tags.label}
                    >
                      <FnbSelectSingle
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value) => onChangeTag(value, index)}
                        placeholder={pageData.condition.tagPlaceholder}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option?.name
                            .toLowerCase()
                            .removeVietnamese()
                            .indexOf(input.toLowerCase().removeVietnamese()) >= 0
                        }
                        option={listCustomerTag?.map((item) => ({
                          id: item.id,
                          name: item.name,
                          key: item.id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {condition?.customerDataId === customerDataEnum.age && (
              <Row gutter={[0, 24]}>
                <Col
                  sm={24}
                  xs={24}
                  lg={12}
                  style={
                    isMobileMode
                      ? { maxWidth: "266px", marginLeft: "40px" }
                      : { paddingRight: "16px", marginLeft: "0px" }
                  }
                >
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item name={["conditions", index, "ageConditionId"]} label={pageData.condition.condition}>
                      <FnbSelectSingle
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value) => onChangeAgeCondition(value, index)}
                        option={ageConditionOptions?.map((item, index) => ({
                          id: item.id,
                          name: t(item.name),
                          key: item.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  sm={24}
                  xs={24}
                  lg={12}
                  style={
                    isMobileMode
                      ? { maxWidth: "266px", marginLeft: "40px" }
                      : { paddingRight: "16px", marginLeft: "0px" }
                  }
                >
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item
                      name={["conditions", index, "age"]}
                      rules={[
                        {
                          pattern: new RegExp(inputNumberRange0To100.range),
                          message: pageData.condition.ageRankValidateMessage,
                        },
                        {
                          required: pageData.condition.age,
                          message: pageData.condition.ageValidateMessage,
                        },
                      ]}
                      label={pageData.condition.age}
                    >
                      <FnbInput
                        maxLength={pageData.name.maxLength}
                        placeholder={pageData.condition.agePlaceholder}
                        onChange={(e) => onChangeAge(e.target.value, index)}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            )}
            {condition?.customerDataId === customerDataEnum.platform && (
              <>
                <Row>
                  <Col
                    sm={24}
                    xs={24}
                    lg={12}
                    style={
                      isMobileMode
                        ? { maxWidth: "266px", marginLeft: "40px", paddingBottom: "10px" }
                        : { textAlign: "center", paddingTop: "32px" }
                    }
                  >
                    <div className="customer-data-condition-text">
                      <h4 className="fnb-form-label">{pageData.condition.are}</h4>
                    </div>
                  </Col>
                  <Col
                    sm={24}
                    xs={24}
                    lg={12}
                    style={
                      isMobileMode
                        ? { maxWidth: "266px", marginLeft: "40px" }
                        : { paddingRight: "16px", marginLeft: "0px" }
                    }
                  >
                    <Form.Item
                      name={["conditions", index, "platformId"]}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject(pageData.condition.platformValidateMessage);
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      label={t("platform.title")}
                    >
                      <FnbSelectSingle
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value) => onChangePlatform(value, index)}
                        placeholder={pageData.condition.platformPlaceholder}
                        option={listPlatforms?.map((item) => ({
                          id: item.id,
                          name: item.name,
                          key: item.id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };

  /// Render Order data
  const renderOptionOrderData = (condition, index) => {
    return (
      <>
        <Row key={index} gutter={[0, 24]}>
          <Col
            sm={24}
            xs={24}
            lg={6}
            style={
              isMobileMode
                ? { maxWidth: "266px", marginLeft: "40px" }
                : { paddingRight: "16px", paddingLeft: "16px", marginLeft: "0px" }
            }
          >
            <div className={`${!isMobileMode && "ml-2"}`}>
              <Form.Item name={["conditions", index, "orderDataId"]} label={pageData.condition.orderData}>
                <FnbSelectSingle
                  getPopupContainer={(trigger) => trigger.parentNode}
                  defaultValue={orderDataEnum.totalCompletedOrders}
                  onChange={(value) => onChangeOrderData(value, index)}
                  option={orderDataOptions?.map((item, index) => ({
                    id: item.id,
                    name: t(item.name),
                    key: item.id,
                  }))}
                />
              </Form.Item>
            </div>
          </Col>
          <Col sm={24} xs={24} lg={18}>
            <Row gutter={[0, 24]}>
              <Col
                sm={24}
                xs={24}
                lg={8}
                style={
                  isMobileMode ? { maxWidth: "266px", marginLeft: "40px" } : { paddingRight: "16px", marginLeft: "0px" }
                }
              >
                <div className={`${!isMobileMode && "ml-2"}`}>
                  <Form.Item name={["conditions", index, "orderConditionId"]} label={pageData.condition.condition}>
                    <FnbSelectSingle
                      getPopupContainer={(trigger) => trigger.parentNode}
                      defaultValue={orderConditionEnum.isEqual}
                      onChange={(value) => onChangeOrderCondition(value, index)}
                      option={orderConditionOptions?.map((item) => ({
                        id: item.id,
                        name: t(item.name),
                        key: item.id,
                      }))}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col
                sm={24}
                xs={24}
                lg={
                  condition?.orderDataId === orderDataEnum.totalPurchasedAmount
                    ? 9
                    : condition?.orderDataId === orderDataEnum.totalCompletedOrders
                    ? window.innerWidth < 1900
                      ? 6
                      : 9
                    : 8
                }
                style={
                  isMobileMode ? { maxWidth: "266px", marginLeft: "40px" } : { paddingRight: "16px", marginLeft: "0px" }
                }
              >
                {condition?.orderDataId === orderDataEnum.totalCompletedOrders && (
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item
                      name={["conditions", index, "orderNumber"]}
                      rules={[
                        {
                          pattern: new RegExp(inputNumberRange0To999999999.range),
                          message: pageData.valueShouldBeFrom0to999999999,
                        },
                        {
                          required: true,
                          message: pageData.valueShouldBeFrom0to999999999,
                        },
                      ]}
                      label={pageData.condition.orderNumber.label}
                    >
                      <InputNumber
                        className="w-100 fnb-input-number"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={(value) => {
                          onChangeOrderNumber(value, index);
                        }}
                        defaultValue={0}
                      />
                    </Form.Item>
                  </div>
                )}
                {condition?.orderDataId === orderDataEnum.totalPurchasedAmount && (
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item
                      name={["conditions", index, "purchasedAmount"]}
                      rules={[
                        {
                          pattern: new RegExp(
                            getCurrency() === currency.vnd
                              ? inputNumberRange0To999999999.range
                              : inputNumberRange0To999999999DotAllow.range,
                          ),
                          message: pageData.valueShouldBeFrom0to999999999,
                        },
                        {
                          required: true,
                          message: pageData.valueShouldBeFrom0to999999999,
                        },
                      ]}
                      label={pageData.condition.totalPurchaseAmount.label}
                    >
                      <InputNumber
                        className="w-100 fnb-input-number"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={(value) => {
                          onChangePurchasedAmount(value, index);
                        }}
                        defaultValue={0}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                      />
                    </Form.Item>
                  </div>
                )}
                {condition?.orderDataId === orderDataEnum.days && (
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item
                      name={["conditions", index, "days"]}
                      rules={[
                        {
                          pattern: new RegExp(inputNumberRange0To10000.range),
                          message: pageData.valueShouldBeFrom0to10000,
                        },
                      ]}
                      label={pageData.condition.days}
                    >
                      <InputNumber
                        className="w-100 fnb-input-number"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={(value) => {
                          onChangeDays(value, index);
                        }}
                        defaultValue={0}
                      />
                    </Form.Item>
                  </div>
                )}
              </Col>
              <Col
                sm={24}
                xs={24}
                lg={
                  condition?.orderDataId === orderDataEnum.totalPurchasedAmount
                    ? 6
                    : condition?.orderDataId === orderDataEnum.totalCompletedOrders
                    ? window.innerWidth < 1900
                      ? 10
                      : 7
                    : 8
                }
                style={isMobileMode ? { maxWidth: "266px", marginLeft: "40px" } : { marginLeft: "0px" }}
              >
                {condition?.orderDataId !== orderDataEnum.days ? (
                  <div className={`${!isMobileMode && "ml-2"}`}>
                    <Form.Item name={["conditions", index, "orderStartTime"]} className="d-none"></Form.Item>
                    <Form.Item name={["conditions", index, "orderEndTime"]} className="d-none"></Form.Item>
                    <Form.Item name={["conditions", index, "orderTypeFilterTime"]} className="d-none"></Form.Item>
                    <Form.Item label={pageData.condition.time.label}>
                      <FnbDatePicker
                        blurEffect={true}
                        selectedDate={formData.conditions[index].orderTime}
                        setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate, index)}
                        orderTypeFilterTime={formData.conditions[index].orderTypeFilterTime}
                        containAllTime={true}
                        className={"date-picker-customer-segment"}
                        popoverPlacement={"bottomLeft"}
                        isShowTime={false}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <div className="ml-2 day-box">
                    <h4>&nbsp;</h4>
                    <p className="day-text">{pageData.condition.daysTillNow}</p>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const onCompleted = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/customer/segment");
    }, DELAYED_TIME);
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      onCompleted();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const onChangeRadio = () => {
    updateFormData(formData);
  };

  return (
    <>
      <FnbHeadingPage
        textAddNew={pageData.btnSave}
        title={pageData.title}
        onCreate={onSubmitForm}
        permissionCreate={PermissionKeys.CREATE_SEGMENT}
        listButtons={[<FnbButton variant="tertiary" onClick={onCancel} text={pageData.btnCancel} />]}
      />
      <div className="clearfix"></div>
      <Form
        form={form}
        className="custom-form create-customer-segment-form"
        layout="vertical"
        autoComplete="off"
        onFieldsChange={(e) => changeForm(e)}
      >
        <Content>
          <FnbCard title={pageData.generalInformation}>
            <Row className="mb-2">
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: pageData.name.required,
                      message: pageData.name.validateMessage,
                    },
                  ]}
                  label={pageData.name.label}
                >
                  <FnbInput maxLength={pageData.name.maxLength} placeholder={pageData.name.placeholder} />
                </Form.Item>
              </Col>
            </Row>
          </FnbCard>
        </Content>
        <div className="clearfix"></div>

        <Content>
          <FnbCard title={pageData.condition.condition} className="mt-3">
            <div className="wrapper-btn-add-condition">
              <FnbButton text={pageData.condition.add} onClick={onAddCondition} iconHeader={<PlusCircleIcon />} />
            </div>
            <Row className="mt-4 mb-2 row-customer-segment-condition" style={{ width: "100" }}>
              <Col span={24}>
                <Form.Item name="isAllMatch">
                  {!isMobileMode ? (
                    <Radio.Group onChange={onChangeRadio}>
                      <Radio value={false}>{pageData.condition.ifAnyMatch}</Radio>
                      <Radio value={true}>{pageData.condition.allMatch}</Radio>
                    </Radio.Group>
                  ) : (
                    <Radio.Group onChange={onChangeRadio}>
                      <Radio value={false}>{pageData.condition.ifAnyMatch}</Radio>
                      {!formData.isAllMatch && renderConditions()}
                      <Radio value={true}>{pageData.condition.allMatch}</Radio>
                      {formData.isAllMatch && renderConditions()}
                    </Radio.Group>
                  )}
                </Form.Item>
                {!isMobileMode && renderConditions()}
              </Col>
            </Row>
          </FnbCard>
        </Content>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.okText}
        onCancel={onDiscard}
        onOk={onCompleted}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
