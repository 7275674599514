export const Package = {
  POS: "6C626154-5065-6361-6B61-676500000001",
  WEB: "6C626154-5065-6361-6B61-676500000002",
  APP: "6C626154-5065-6361-6B61-676500000003",
  SOCIAL_CHANNEL: "6C626154-5065-6361-6B61-676500000008",
};

export const PackageName = {
  POS : "POS",
  WEB : "WEB",
  APP : "APP",
  BRANCH : "Branch",
  POS_WEB : "POS + WEB",
  POS_APP : "POS + APP",
  POS_APP_WEB : "POS + APP + WEB",
  SOCIAL_CHANNEL : "SOCIAL CHANNEL",
  TRIAL : "TRIAL",
};

export const EnumOrderPackageStatus = {
  PENDING : "PENDING",
  APPROVED : "APPROVED",
  CANCELED : "CANCELED",
  EXPIRED : "EXPIRED",
};
