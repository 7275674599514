export const OrderPackageType = {
    STORE_ACTIVATE: 0,
    BRANCH_PURCHASE: 1,
  };
  
  export const AutoRenewStatus = {
    CREATED: "CREATED",
    ACTIVED: "ACTIVED",
    CANCELED: "CANCELED",
    RETRYING: "RETRYING",
    SUPPENDED: "SUPPENDED",
  }

  export const OrderPackageStatus = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    CANCELED: "CANCELED",
  }