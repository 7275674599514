import { Drawer, Row, message } from "antd";
import { StaffRevenueTable } from "./components/staff-revenue-table.component";
import "./staff-revenue.style.scss";
import Col from "antd/es/grid/col";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbButton } from "components/fnb-button/fnb-button";
import { OptionDateTime } from "constants/option-date.constants";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import reportDataService from "data-services/report/report-data.service";
import languageService from "services/language/language.service";
import { DateFormat } from "constants/string.constants";
import SummaryStaffRevenue from "./components/summary-staff-revenue.component";
import WrapperStaffRevenueChart from "./components/wrapper-staff-revenue-chart.component";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_FORMAT_DATE,
  fetchStaffSummary,
  staffRevenueActions,
  staffRevenueSelector,
} from "store/modules/staff-revenue/staff-revenue.reducer";
import { ExportOutlinedIcon } from "constants/icons.constants";
import { CURRENCY_CODE } from "themes/constants/string.constants";
import { useTranslation } from "react-i18next";
import { sessionSelector } from "store/modules/session/session.reducers";
import { handleDownloadFile } from "utils/helpers";
import { PermissionKeys } from "constants/permission-key.constants";
import { useMediaQuery } from "react-responsive";

function StaffRevenue() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const staffRevenue = useSelector(staffRevenueSelector);
  const session = useSelector(sessionSelector);
  const { isShowStaffSummary, staffSummaryParams, staffSummaryInfo } = staffRevenue;
  const { currentUser } = session;
  const isTablet = useMediaQuery({ maxWidth: 1200, minWidth: 576 });
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().startOf("day").locale("en-US").format(DEFAULT_FORMAT_DATE),
    endDate: moment().endOf("day").locale("en-US").format(DEFAULT_FORMAT_DATE),
  });
  const [branchId, setBranchId] = useState("");
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);
  const pageData = {
    title: t("report.staff.title"),
    summary: t("report.staff.summary"),
    export: t("button.export"),
    date: {
      today: "optionDatetime.today",
      yesterday: "optionDatetime.yesterday",
      thisWeek: "optionDatetime.thisWeek",
      lastWeek: "optionDatetime.lastWeek",
      thisMonth: "optionDatetime.thisMonth",
      lastMonth: "optionDatetime.lastMonth",
      thisYear: "optionDatetime.thisYear",
      lastYear: "optionDatetime.lastYear",
      lastPeriod: "optionDatetime.lastPeriod",
      previousDay: "dashboard.compareDate.previousDay",
      previousWeek: "dashboard.compareDate.previousWeek",
      previousMonth: "dashboard.compareDate.previousMonth",
      previousSession: "dashboard.compareDate.previousSession",
    },
    tabOverView: t("report.customer.tabOverView"),
    tabView: t("report.customer.tabView"),
    tabAccessTimes: t("report.customer.tabAccessTimes"),
    allBranch: t("dashboard.allBranch"),
  };
  const [titleConditionCompare, setTitleConditionCompare] = useState(pageData.date.yesterday);
  const [titleCondition, setTitleCondition] = useState(pageData.date.today);
  const [totalDate, setTotalDate] = useState(0);
  const tableRevenueRef = useRef(null);
  const filterBranchRef = useRef(null);
  const filterDatetimeRef = useRef(null);

  useEffect(() => {
    dispatch(fetchStaffSummary(staffSummaryParams));
    return () => {
      dispatch(staffRevenueActions.resetStaffSummaryInfo());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffSummaryParams]);

  const handleChangeBranch = (branchId) => {
    setBranchId(branchId);
    dispatch(
      staffRevenueActions.setStaffSummaryParams({
        ...staffSummaryParams,
        branchId,
      }),
    );
    dispatch(staffRevenueActions.setSortTableWhenStaffSummaryClose(false));
  };

  const onConditionCompare = (key) => {
    switch (key) {
      case OptionDateTime.today:
        setTitleCondition(pageData.date.today);
        setTitleConditionCompare(pageData.date.yesterday);
        break;
      case OptionDateTime.yesterday:
        setTitleCondition(pageData.date.yesterday);
        setTitleConditionCompare(pageData.date.previousDay);
        break;
      case OptionDateTime.thisWeek:
        setTitleCondition(pageData.date.thisWeek);
        setTitleConditionCompare(pageData.date.lastWeek);
        break;
      case OptionDateTime.lastWeek:
        setTitleCondition(pageData.date.lastWeek);
        setTitleConditionCompare(pageData.date.previousWeek);
        break;
      case OptionDateTime.thisMonth:
        setTitleCondition(pageData.date.thisMonth);
        setTitleConditionCompare(pageData.date.lastMonth);
        break;
      case OptionDateTime.lastMonth:
        setTitleCondition(pageData.date.lastMonth);
        setTitleConditionCompare(pageData.date.previousMonth);
        break;
      case OptionDateTime.thisYear:
        setTitleCondition(pageData.date.thisYear);
        setTitleConditionCompare(pageData.date.lastYear);
        break;
      default:
        setTitleCondition(pageData.date.lastPeriod);
        setTitleConditionCompare(pageData.date.previousSession);
        break;
    }
  };

  const handleVisibleStaffSummary = (visible) => {
    dispatch(staffRevenueActions.setShowStaffSummary(visible));
  };

  const handleChangeDatePicker = (date, businessSummaryWidgetFilter) => {
    setTypeOptionDate(businessSummaryWidgetFilter);
    const { startDate, endDate } = date;
    setSelectedDate(date);
    if (titleCondition === pageData.date.lastPeriod) {
      setTotalDate(Math.ceil((moment(endDate).valueOf() - moment(startDate).valueOf()) / (1000 * 3600 * 24) + 1));
      setTitleCondition(pageData.date.lastPeriod);
    }
    dispatch(
      staffRevenueActions.setStaffSummaryParams({
        ...staffSummaryParams,
        startDate,
        endDate,
        businessSummaryWidgetFilter,
      }),
    );
    dispatch(staffRevenueActions.setSortTableWhenStaffSummaryClose(false));
  };

  const renderSummaryStaffRevenue = (children) => {
    if (isTablet) {
      return (
        <Drawer
          placement="right"
          className="staff-summary-drawer"
          open={isShowStaffSummary}
          onClose={() => handleVisibleStaffSummary(false)}
          footer={null}
        >
          {children}
        </Drawer>
      );
    }
    return children;
  };

  const exportStaffAsync = async () => {
    let languageCode = languageService.getLang();
    var timeZone = new Date().getTimezoneOffset() / 60;
    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);
    let param = {
      startDate,
      endDate,
      businessSummaryWidgetFilter: typeOptionDate,
      languageCode,
      branchId,
      timeZone,
    };
    try {
      var response = await reportDataService.exportStaffRevenueAsync(param);
      handleDownloadFile(response);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };


  const onScroll = (event) => {
    if (tableRevenueRef.current !== null) {
      const seeMoreElement = document.getElementById(tableRevenueRef.current);
      seeMoreElement.click();
    }
    document.getElementById("multiple-select")?.blur?.()
  };

  const onClearFilterParams = () => {
    filterBranchRef.current.setSelectedAllBranch();
    filterDatetimeRef.current.setSelectedToday();
    dispatch(staffRevenueActions.clearStartAndEndRevenue());
    dispatch(
      staffRevenueActions.setShowSelectedRange({
        isShow: false,
      }),
    );
    dispatch(staffRevenueActions.setSortTableWhenStaffSummaryClose(false));
    dispatch(staffRevenueActions.resetStaffSummaryParams());
    setTimeout(() => {
      dispatch(staffRevenueActions.reloadColorChart());
    },  50)
  }

  return (
    <>
      <FnbHeadingPage
        title={pageData.title}
         isCompactButtons={false}
        listButtons={[
          <FnbListBranches ref={filterBranchRef} onChangeEvent={handleChangeBranch} />,
          <FnbDatePicker
            selectedDate={{ startDate: staffSummaryParams.startDate, endDate: staffSummaryParams.endDate }}
            setSelectedDate={(date, typeOptionDate) => handleChangeDatePicker(date, typeOptionDate)}
            setConditionCompare={onConditionCompare}
            orderTypeFilterTime={staffSummaryParams.businessSummaryWidgetFilter}
            ref={filterDatetimeRef}
          />,
          <FnbButton
            variant="secondary"
            iconHeader={<ExportOutlinedIcon />}
            text={pageData.export}
            permission={PermissionKeys.EXPORT_STAFF_REPORT}
            onClick={() => exportStaffAsync()}
          />,
          <>
          {!isShowStaffSummary && (
            <FnbButton variant="secondary" text={pageData.summary} onClick={() => handleVisibleStaffSummary(true)} />
          )}
        </>,
        ]}
      />

      <Row className="fnb-form-title staff-revenue-report" gutter={[12, 12]} onScroll={(event) => onScroll(event)}>
        <Col className={`${isShowStaffSummary ? "show-summary" : "hide-summary"} staff-revenue-info`}>
          <WrapperStaffRevenueChart t={t} onClearFilterParams={onClearFilterParams} />
          <StaffRevenueTable
            ref={tableRevenueRef}
            titleCondition={titleCondition}
            titleConditionCompare={titleConditionCompare}
            totalDate={totalDate}
            selectedDate={selectedDate}
          />
        </Col>
        <Col className="sticky-summary">
          {renderSummaryStaffRevenue(
            <SummaryStaffRevenue
              t={t}
              isShow={isShowStaffSummary}
              setIsShow={(visible) => handleVisibleStaffSummary(visible)}
              summaryInfo={staffSummaryInfo}
              currencyCode={currentUser?.currencyCode || CURRENCY_CODE.VND}
            />,
          )}
        </Col>
      </Row>
    </>
  );
}

export default StaffRevenue;
