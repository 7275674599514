import { Select, Typography } from "antd";
import {
  AllOption,
  Container,
  GroupVariants,
  Menus,
  NoDataFounded,
  ProductGroupOtions,
  ProductOption,
  TooltipContent,
} from "./styled";
import { ArrowDown } from "constants/icons.constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DefaultThumbnail from "assets/images/product-img-default.png";
import NoItems from "assets/images/no-item.png";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { formatCurrencyWithCode, formatCurrencyWithSymbol, getFirstLetterString } from "utils/helpers";

const { OptGroup, Option } = Select;

const DEFAULT_VALUE = [];
const DEFAULT_LIST_HEIGHT = 395;

const FnbSelectProduct = (props) => {
  const {
    placeholder,
    options,
    listHeight,
    onChange,
    value,
    hiddenSelectAll = false,
    allowClear = true,
    ...rest
  } = props;
  const [t] = useTranslation();
  const [selectedAll, setSelectedAll] = useState(!hiddenSelectAll);

  const pageData = {
    noDataFound: t("productManagement.noDataFound"),
    selectAll: t("productManagement.selectAll"),
  };

  const DEFAULT_PLACEHOLDER = !hiddenSelectAll ? pageData.selectAll : "";

  const filterOption = (input, product) => {
    const keysearch = input?.removeVietnamese()?.trim()?.toLowerCase();
    if (product?.options?.length > 1) {
      return product?.options?.some(
        (option) => option?.name?.includes(keysearch) || option?.shortName?.includes(keysearch),
      );
    } else if (typeof product?.label === "string") {
      return product?.name?.includes(keysearch) || product?.shortName?.includes(keysearch);
    }
  };

  const convertRegexUnicode = (value) => {
    if (value) {
      return value?.removeVietnamese()?.trim()?.toLowerCase() || "";
    }
    return "";
  };

  const handleChangeOptions = (value, option) => {
    const hasOptions = value?.length > 0;
    setSelectedAll(!hasOptions);
    onChange && onChange(value, option);
  };

  const handleSelectAll = () => {
    setSelectedAll(true);
    onChange && onChange(DEFAULT_VALUE, DEFAULT_VALUE);
  };

  const renderMaxTagPlaceholder = (omittedValues) => (
    <FnbTooltip
      variant="secondary"
      placement="bottomRight"
      title={
        <TooltipContent>
          {Array.from(omittedValues)?.map((value, index) => (
            <li key={index}>{value?.label}</li>
          ))}
        </TooltipContent>
      }
    >
      <span>{Array.from(omittedValues)?.length}+</span>
    </FnbTooltip>
  );

  const renderProductOptions = () => (
    <>
      {!hiddenSelectAll && (
        <OptGroup
          label={
            <AllOption onClick={handleSelectAll} checked={selectedAll}>
              {pageData.selectAll}
            </AllOption>
          }
        />
      )}
      {options?.map((option) => {
        const oneSizeOnly = option?.productPrices?.length === 1 && !option?.productPrices?.[0]?.priceName;
        return oneSizeOnly ? renderOneSizeOnly(option) : renderMultipleSizes(option);
      })}
    </>
  );

  const renderOneSizeOnly = (option) => {
    const { id, priceValue } = option?.productPrices?.[0];
    return (
      <Option
        key={id}
        value={id}
        label={option?.name}
        name={convertRegexUnicode(option?.name)}
        shortName={getFirstLetterString(option?.name)}
        productId={option?.id}
        productPriceId={id}
        priceValue={priceValue}
      >
        <ProductOption>
          <img src={option?.thumbnail || DefaultThumbnail} alt={option?.name} />
          <div title={option?.name} className="product-name">
            {option?.name}
            {option?.isTopping && (
              <div className="fnb-chip fnb-chip__option--default" style={{ marginLeft: 12 }}>
                <Typography style={{ color: "#ff7d07" }}>Topping</Typography>
              </div>
            )}
          </div>
          {(priceValue || option?.unit?.name) && (
            <div className="product-unit">
              {priceValue && <div>{formatCurrencyWithCode(priceValue)}</div>}
              {option?.unit?.name && <div>{option?.unit?.name}</div>}
            </div>
          )}
        </ProductOption>
      </Option>
    );
  };

  const renderMultipleSizes = (option) => {
    return (
      <OptGroup
        label={
          <GroupVariants>
            <img src={option?.thumbnail || DefaultThumbnail} alt={option?.name} />
            <span title={option?.name}>{option?.name}</span>
          </GroupVariants>
        }
      >
        {option?.productPrices?.map((productPrice) => (
          <Option
            key={productPrice?.id}
            value={productPrice?.id}
            label={`${option?.name} (${productPrice?.priceName})`}
            name={convertRegexUnicode(`${option?.name} ${productPrice?.priceName}`)}
            shortName={getFirstLetterString(`${option?.name} ${productPrice?.priceName}`)}
            productId={option?.id}
            productPriceId={productPrice?.id}
            priceValue={productPrice?.priceValue}
          >
            <ProductGroupOtions>
              <div title={option?.name} className="product-name">
                {productPrice?.priceName}
              </div>
              {(productPrice?.priceValue || option?.unit?.name) && (
                <div className="product-unit">
                  {productPrice?.priceValue && <div>{formatCurrencyWithCode(productPrice?.priceValue)}</div>}
                  {option?.unit?.name && <div>{option?.unit?.name}</div>}
                </div>
              )}
            </ProductGroupOtions>
          </Option>
        ))}
      </OptGroup>
    );
  };

  return (
    <Container selectedAll={selectedAll} hiddenSelectAll={hiddenSelectAll}>
      <Select
        showArrow
        mode="multiple"
        value={value}
        onChange={handleChangeOptions}
        dropdownRender={(menu) => <Menus>{menu}</Menus>}
        placeholder={placeholder || DEFAULT_PLACEHOLDER}
        suffixIcon={<ArrowDown />}
        maxTagCount="responsive"
        allowClear={allowClear}
        maxTagPlaceholder={renderMaxTagPlaceholder}
        listHeight={listHeight || DEFAULT_LIST_HEIGHT}
        notFoundContent={
          <NoDataFounded>
            <img src={NoItems} alt="" />
            <span>{pageData.noDataFound}</span>
          </NoDataFounded>
        }
        filterOption={filterOption}
        optionLabelProp="label"
        style={{ width: "100%" }}
        getPopupContainer={(trigger) => trigger.parentNode}
        {...rest}
      >
        {renderProductOptions()}
      </Select>
    </Container>
  );
};

export default FnbSelectProduct;
