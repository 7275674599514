export const variants = {
  // xs: screen 428x926
  // xl: screen 1024x768
  // xxl: screen 1920x1080
  screen: {
    xs: "428px",
    sm: "575px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px",
  },
  position: {
    top: {
      sm: "20px",
      xl: "20px",
      xxl: "20px",
    },
    left: {
      sm: "0px",
      xl: "20px",
      xxl: "20px",
    },
  },
  header: {
    paddingY: {
      sm: "18px",
    },
    padding: {
      sm: "24px 16px 32px 16px",
      xl: "24px",
      xxl: "24px",
    },
    gap: {
      xl: "24px",
      xxl: "24px",
    },
    iconSize: {
      sm: "24px",
      xl: "32px",
      xxl: "32px",
    },
  },
  body: {
    padding: {
      sm: "16px",
      xl: "24px",
      xxl: "24px",
    },
    borderRadius: {
      sm: "16px 16px 0 0",
      xxl: "24px",
    },
  },
};
