import { useState } from "react";
import { Form, Row, Card, message } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PermissionKeys } from "constants/permission-key.constants";
import { formatPhoneNumber, hasPermission } from "utils/helpers";
import { DeleteOutlined } from "constants/icons.constants";
import DeleteBranch from "../components/delete-branch.component";
import purchaseOrderDataService from "data-services/purchase-order/purchase-order-data.service";
import branchDataService from "data-services/branch/branch-data.service";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { ActivateStatus } from "constants/activate-status.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useSelector } from "react-redux";

export default function TableBranchManagement(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState({});
  const [infoPurchaseOrders, setInfoPurchaseOrders] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { dataSource, total, onChangePage, pageSize, pageNumber, onSearch, numberRecordCurrent } = props;
  const phoneCode = useSelector((state) => state?.session?.prepareAddressData)?.defaultCountryStore?.phonecode;

  const pageData = {
    no: t("table.no"),
    branchName: t("table.branchName"),
    phone: t("table.phone"),
    action: t("table.action"),
    status: t("table.status"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    searchNameOrPhone: t("form.searchNameOrPhone"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    notificationTitle: t("form.notificationTitle"),
    isDeletedSuccessfully: t("messages.isDeletedSuccessfully"),
    deleteButton: t("button.delete"),
  };

  //name columns Branch management
  const columnsBranchManagement = [
    {
      title: pageData.no,
      dataIndex: "no",
      key: "no",
      width: "5rem",
      align: "center",
      render: (_, record) => <div className="text-name">{record?.no}</div>,
    },
    {
      title: pageData.branchName,
      dataIndex: "name",
      ellipsis: true,
      key: "name",
      render: (_, record) => {
        return (
          <div>
            <FnbTooltip title={record?.name} onlyShowWhenEllipsis>
              <FnbTypography
                color={theme.colors.default.black}
                variant={theme.typography["b1-regular"]}
                text={record?.name}
              />
            </FnbTooltip>
            <FnbTooltip title={record?.addressInfo} onlyShowWhenEllipsis>
              <FnbTypography color={theme.colors.gray[70]} text={record?.addressInfo} />
            </FnbTooltip>
          </div>
        );
      },
    },
    {
      title: pageData.phone,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "322px",
      render: (_, record) => {
        return formatPhoneNumber(record?.phoneCode, record?.phoneNumber);
      },
    },
    {
      title: pageData.status,
      dataIndex: "status",
      key: "status",
      width: "9rem",
      align: "center",
      render: (_, record) => {
        return (
          <Row justify={"center"}>
            <FnbBadge
              variant={record?.isActive === ActivateStatus.Activate ? "success" : "error"}
              text={record?.isActive === ActivateStatus.Activate ? pageData.active : pageData.inactive}
            ></FnbBadge>
          </Row>
        );
      },
    },
  ];

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onEditItem = (_selectedRowKeys, selectedRows) => {
    const { id } = selectedRows?.[0];
    history.push(`/branch/edit/${id}`);
  };

  const onDeleteItem = async (selectedRows) => {
    const { id } = selectedRows?.[0];
    const res = await purchaseOrderDataService.getPurchaseOrderByBranchIdAsync(id);
    if (res) {
      const { isOpenPurchaseOrder } = res;
      if (isOpenPurchaseOrder) {
        setTitleModal(pageData.notificationTitle);
      } else {
        setTitleModal(pageData.confirmDelete);
      }
      setInfoPurchaseOrders(res);
      setIsModalVisible(true);
    }
  };

  const onDelete = async (id, name) => {
    const res = await branchDataService.deleteStoreBranchByIdAsync(id);
    if (res) {
      setIsModalVisible(false);
      setSelectedRowKeys([]);
      onChangePage(pageNumber, pageSize);
      message.success(`${name} ${pageData.isDeletedSuccessfully}`);
    }
  };

  return (
    <>
      <Form className="form-staff">
        <Card className={"fnb-card-custom"}>
          <FnbTable
            columns={columnsBranchManagement}
            dataSource={dataSource}
            pageSize={pageSize}
            currentPageNumber={pageNumber}
            total={total}
            onChangePage={onChangePage}
            numberRecordCurrent={numberRecordCurrent}
            editPermission={PermissionKeys.ADMIN}
            search={{
              placeholder: pageData.searchNameOrPhone,
              onChange: onSearch,
            }}
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedRowKeysChange,
              onEdit: onEditItem,
              renderActions: (_selectedRowKeys, selectedRows) => {
                const multipleSelectedRows = selectedRows?.length > 1;
                const hasIsDefault = selectedRows?.some((reccord) => reccord?.isDefault);
                return (
                  <>
                    {hasPermission(PermissionKeys.ADMIN) && !hasIsDefault && (
                      <FnbTooltip placement="top" title={pageData.deleteButton} zIndex={10}>
                        <FnbButton
                          variant="text"
                          className="row-delete-button"
                          iconHeader={<DeleteOutlined />}
                          onClick={() => onDeleteItem(selectedRows)}
                          disabled={multipleSelectedRows}
                        />
                      </FnbTooltip>
                    )}
                  </>
                );
              },
            }}
          />
        </Card>
      </Form>
      <DeleteBranch
        isModalVisible={isModalVisible}
        infoPurchaseOrder={infoPurchaseOrders}
        titleModal={titleModal}
        handleCancel={() => setIsModalVisible(false)}
        onDelete={onDelete}
      />
    </>
  );
}
