import { message, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { DeleteOutlined, EditOutlined, StopFill } from "constants/icons.constants";
import {
  NotificationCampaignEvent,
  NotificationCampaignSendingType,
  NotificationCampaignStatus,
} from "constants/notification-campaign.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import notificationCampaignDataService from "data-services/notification-campaign/notification-campaign.service";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { executeAfter, formatDate, hasPermission, stringDate } from "utils/helpers";
import FilterPopover from "./filter-popover.component";
import "./table-notification-campaign.component.styles.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import theme from "theme";

export default function TableNotificationCampaign(props) {
  const [t] = useTranslation();
  const MAX_LENGTH_SEARCH = 100;
  const history = useHistory();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [dataFilter, setDataFilter] = useState({});
  const [selectedDate, setSelectedDate] = useState({
    startDate: stringDate("en-US"),
    endDate: stringDate("en-US"),
  });

  const pageData = {
    no: t("table.no", "No"),
    campaignName: t("marketing.notificationCampaign.campaignName", "Campaign Name"),
    time: t("promotion.table.time", "Time"),
    status: t("promotion.table.status", "Status"),
    action: t("promotion.table.action", "Action"),
    search: t("marketing.notificationCampaign.search"),
    type: t("marketing.notificationCampaign.type"),
    notificationCampaignInstallEvent: t("marketing.notificationCampaign.notificationCampaignInstallEvent"),
    scheduled: t("marketing.notificationCampaign.status.scheduled"),
    active: t("marketing.notificationCampaign.status.active"),
    finished: t("marketing.notificationCampaign.status.finished"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmStop: t("leaveDialog.confirmStop"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    button: {
      addNew: t("button.addNew"),
      filter: t("button.filter"),
      delete: t("button.delete"),
      btnIgnore: t("button.ignore"),
      btnStop: t("button.stop"),
      btnConfirm: t("button.confirm"),
      edit: t("button.edit"),
    },
    stop: t("button.stop"),
    deleteNotificationCampaignMessage: t("marketing.notificationCampaign.deleteNotificationCampaignMessage"),
    confirmStopNotificationCampaign: t("marketing.notificationCampaign.confirmStopNotificationCampaign"),
    deleteNotificationCampaignSuccess: t("marketing.notificationCampaign.deleteNotificationCampaignSuccess"),
    deleteNotificationCampaignFail: t("marketing.notificationCampaign.deleteNotificationCampaignFail"),
    stopNotificationCampaignSuccess: t("marketing.notificationCampaign.stopNotificationCampaignSuccess"),
    stopNotificationCampaignFail: t("marketing.notificationCampaign.stopNotificationCampaignFail"),
    toCreateData: t("area.toCreateData"),
  };

  const filterPopoverRef = useRef();
  const [countFilter, setCountFilter] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);
  const [isShowConfirmStop, setIsShowConfirmStop] = useState(false);
  const getColumns = () => {
    const columns = [
      {
        title: pageData.no,
        dataIndex: "no",
        key: "no",
        align: "left",
        width: "5rem",
      },
      {
        title: pageData.campaignName,
        dataIndex: "campaignName",
        key: "campaignName",
        align: "left",
        render: (_, record) => {
          return (
            <FnbTooltip title={record?.campaignName} onlyShowWhenEllipsis>
              <FnbTypography text={record?.campaignName} />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.type,
        dataIndex: "type",
        key: "type",
        align: "left",
        width: "15%",
        render: (_, record) => {
          switch (record?.type) {
            case NotificationCampaignSendingType.SendByEvent:
              return <div>{t("marketing.notificationCampaign.types.sendByEvent")}</div>;
            case NotificationCampaignSendingType.SendBySpecificTime:
              return <div>{t("marketing.notificationCampaign.types.sendBySpecificTime")}</div>;
            default:
              return <div>{t("marketing.notificationCampaign.types.sendNow")}</div>;
          }
        },
      },
      {
        title: pageData.time,
        dataIndex: "sendingTime",
        key: "sendingTime",
        className: "col-time",
        width: "20%",
        render: (_, record) => {
          switch (record?.type) {
            case NotificationCampaignSendingType.SendByEvent:
              if (record?.event == NotificationCampaignEvent.InstallTheAppEvent) {
                return "-";
              } else {
                return "";
              }
            case NotificationCampaignSendingType.SendBySpecificTime:
              return (
                <div className="lastSavedTime">
                  <span>{formatDate(record?.specificTime, DateFormat.HH_MM)}</span>
                  <span className="lastSavedTimeDate">{formatDate(record?.specificTime, DateFormat.DD_MMM_YYYY)}</span>
                </div>
              );
            default:
              return <div> - </div>;
          }
        },
      },
      {
        title: pageData.status,
        dataIndex: "status",
        key: "status",
        width: "9rem",
        align: "center",
        className: "col-status",
        render: (_, record) => {
          switch (record?.statusId) {
            case NotificationCampaignStatus.Scheduled:
              return <FnbBadge variant="warning" text={pageData.scheduled} />;
            case NotificationCampaignStatus.Active:
              return <FnbBadge variant="success" text={pageData.active} />;
            default:
              return <FnbBadge variant="error" text={pageData.finished} />;
          }
        },
      },
    ];
    return columns;
  };

  const onEditItem = (id) => {
    history.push(`/marketing/notification-campaign/edit/${id}`);
  };

  const onChangePage = async (page, pageSize) => {
    await fetchDatableAsync(
      page,
      pageSize,
      keySearch,
      dataFilter?.status,
      selectedDate?.startDate,
      selectedDate?.endDate,
    );
  };

  const onSearch = (keySearch) => {
    setKeySearch(keySearch);
    executeAfter(500, async () => {
      await fetchDatableAsync(
        1,
        tableSettings.pageSize,
        keySearch,
        dataFilter?.status,
        selectedDate?.startDate,
        selectedDate?.endDate,
      );
    });
  };

  const filterComponent = () => {
    return <FilterPopover ref={filterPopoverRef} fetchData={handleFilter} />;
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
    setDataFilter();
    setCountFilter(0);
    fetchDatableAsync(1, tableSettings.pageSize, keySearch, "", selectedDate?.startDate, selectedDate?.endDate);
  };

  const handleFilter = (data) => {
    setCountFilter(data?.count);
    setDataFilter(data);
    fetchDatableAsync(
      1,
      tableSettings.pageSize,
      keySearch,
      data?.status,
      selectedDate?.startDate,
      selectedDate?.endDate,
    );
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    fetchDatableAsync(1, tableSettings.pageSize, keySearch, dataFilter?.status, date?.startDate, date?.endDate);
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch, status, startDate, endDate) => {
    const response = await notificationCampaignDataService.getAllNotificationCampaignAsync(
      pageNumber,
      pageSize,
      keySearch,
      status ?? "",
      startDate ?? "",
      endDate ?? "",
    );
    const data = response?.notificationCampaigns.map((s, index) => mappingRecordToColumns(s, index));
    setTotalRecords(response.total);
    setCurrentPageNumber(pageNumber);
    setDataSource(data);
  };

  const mappingRecordToColumns = (item, index) => {
    return {
      id: item?.id,
      event: item?.event,
      campaignName: item?.name,
      type: item?.sendingTypeId,
      sendingTypeId: item?.sendingTypeId,
      statusId: item?.statusId,
      specificTime: item?.specificTime,
      no: index + 1,
      sendNotificationTime: item?.sendNotificationTime,
    };
  };

  const onDeleteNotificationCampaign = async (id) => {
    let reloadPage = false;
    await notificationCampaignDataService.deleteNotificationCampaignByIdAsync(id).then((res) => {
      if (res) {
        reloadPage = true;
        message.success(pageData.deleteNotificationCampaignSuccess);
        setSelectedRows([]);
        setSelectedRowKeys([]);
      } else {
        message.error(pageData.deleteNotificationCampaignFail);
      }
      setIsShowConfirmDelete(false);
    });

    if (reloadPage) {
      await fetchDatableAsync(
        1,
        tableSettings.pageSize,
        keySearch,
        dataFilter?.status,
        selectedDate?.startDate,
        selectedDate?.endDate,
      );
    }
  };

  const onStopNotificationCampaign = async (id) => {
    let reloadPage = false;
    await notificationCampaignDataService.stopNotificationCampaignByIdAsync(id).then((res) => {
      if (res) {
        reloadPage = true;
        message.success(pageData.stopNotificationCampaignSuccess);
      } else {
        message.error(pageData.stopNotificationCampaignFail);
      }
      setIsShowConfirmStop(false);
    });
    if (reloadPage) {
      await fetchDatableAsync(
        1,
        tableSettings.pageSize,
        keySearch,
        dataFilter?.status,
        selectedDate?.startDate,
        selectedDate?.endDate,
      );
    }

    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const onEditRowKeys = (selectedRowKeys, selectedRows) => {
    onEditItem(selectedRows[0]?.id);
  };

  const onDeleteRowKeys = (selectedRowKeys, selectedRows) => {
    setIsShowConfirmDelete(true);
  };

  const onStopRowKeys = (selectedRowKeys, selectedRows) => {
    setIsShowConfirmStop(true);
  };

  const onRowClick = (record) => {
    history.push(`/marketing/notification-campaign/view/${record?.id}`);
  };

  return (
    <Row className="form-staff">
      <FnbTable
        className="table-striped-rows notification-campaign-table"
        columns={getColumns()}
        pageSize={tableSettings.pageSize}
        dataSource={dataSource}
        currentPageNumber={currentPageNumber}
        total={totalRecords}
        onChangePage={onChangePage}
        search={{
          maxLength: MAX_LENGTH_SEARCH,
          placeholder: pageData.search,
          onChange: onSearch,
        }}
        filter={{
          totalFilterSelected: countFilter,
          onClearFilter: onClearFilter,
          buttonTitle: pageData.filter,
          component: filterComponent(),
        }}
        calendarComponent={{
          onSelectedDatePicker: onSelectedDatePicker,
          selectedDate: selectedDate,
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectedRowKeysChange,
          renderActions: (selectedRowKeys, selectedRows) =>
            selectedRows[0]?.statusId !== NotificationCampaignStatus.Finished && (
              <>
                {selectedRowKeys?.length >= 1 &&
                  hasPermission(PermissionKeys.EDIT_NOTIFICATION_CAMPAIGN) &&
                  (selectedRows[0]?.statusId === NotificationCampaignStatus.Scheduled ||
                    selectedRows[0]?.sendingTypeId === NotificationCampaignSendingType.SendByEvent) && (
                    <>
                      <FnbTooltip
                        placement="top"
                        title={pageData.button.edit}
                        zIndex={10}
                        hideTooltip={selectedRowKeys.length > 1}
                      >
                        <FnbButton
                          permission={PermissionKeys.EDIT_NOTIFICATION_CAMPAIGN}
                          variant="text"
                          className="row-edit-button"
                          iconHeader={<EditOutlined />}
                          onClick={() => onEditRowKeys(selectedRowKeys, selectedRows)}
                          disabled={selectedRowKeys.length > 1}
                        />
                      </FnbTooltip>
                    </>
                  )}

                {selectedRowKeys?.length >= 1 &&
                  hasPermission(PermissionKeys.DELETE_NOTIFICATION_CAMPAIGN) &&
                  selectedRows[0]?.statusId === NotificationCampaignStatus.Scheduled && (
                    <>
                      <FnbTooltip
                        placement="top"
                        title={pageData.button.delete}
                        zIndex={10}
                        hideTooltip={selectedRowKeys.length > 1}
                      >
                        <FnbButton
                          iconHeader={<DeleteOutlined />}
                          permission={PermissionKeys.DELETE_NOTIFICATION_CAMPAIGN}
                          variant="text"
                          className="row-delete-button"
                          onClick={() => onDeleteRowKeys(selectedRowKeys, selectedRows)}
                          disabled={selectedRowKeys.length > 1}
                        />
                      </FnbTooltip>
                    </>
                  )}

                {selectedRowKeys?.length >= 1 &&
                  hasPermission(PermissionKeys.STOP_NOTIFICATION_CAMPAIGN) &&
                  selectedRows[0]?.statusId === NotificationCampaignStatus.Active && (
                    <>
                      <FnbTooltip
                        placement="top"
                        title={pageData.button.btnStop}
                        zIndex={10}
                        hideTooltip={selectedRowKeys.length > 1}
                      >
                        <FnbButton
                          permission={PermissionKeys.STOP_FLASH_SALE}
                          variant="tertiary"
                          iconHeader={<StopFill />}
                          className="row-edit-button"
                          onClick={() => onStopRowKeys(selectedRowKeys, selectedRows)}
                          disabled={selectedRowKeys.length > 1}
                        />
                      </FnbTooltip>
                    </>
                  )}
              </>
            ),
        }}
        onRowClick={hasPermission(PermissionKeys.CREATE_NOTIFICATION_CAMPAIGN) && onRowClick}
        emptyText={
          hasPermission(PermissionKeys.CREATE_EMAIL_CAMPAIGN) && (
            <div className="content-collapse-empty-text">
              <FnbTypography.Link
                variant={theme.typography["b1-bold-underlined"]}
                underline={true}
                text={pageData.button.addNew}
                onClick={() => history.push("/marketing/notification-campaign/create-notification-campaign")}
              />
              {pageData.toCreateData}
            </div>
          )
        }
      />
      <ConfirmDialogComponent
        ItemNameInContent={selectedRows[0]?.campaignName}
        visible={isShowConfirmDelete}
        onOk={() => onDeleteNotificationCampaign(selectedRows[0]?.id)}
        onCancel={() => setIsShowConfirmDelete(false)}
        type={ConfirmStyle.DELETE}
      />

      <ConfirmDialogComponent
        ItemNameInContent={selectedRows[0]?.campaignName}
        visible={isShowConfirmStop}
        onOk={() => onStopNotificationCampaign(selectedRows[0]?.id)}
        onCancel={() => setIsShowConfirmStop(false)}
        type={ConfirmStyle.STOP}
      />
    </Row>
  );
}
