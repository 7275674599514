import { Col, Row, Switch } from "antd";
import PromotionPopover from "../PromotionPopover";
import {
  Container,
  InnerHtml,
  PromotionCheck,
  PromotionDescriptionChecked,
  PromotionDescriptionSwitch,
  PromotionOption,
  PromotionSwitch,
  TitleSetting,
  WrapperConfig,
  WrapperExample,
} from "./styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { PromotionConfigType } from "themes/constants/enums";
import promotionConfigDataService from "data-services/promotion-config/promotion-config-data.service";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { formatNumberDecimalOrInteger } from "utils/helpers";
import { useSelector } from "react-redux";
import { authUserSelector } from "store/modules/session/session.reducers";
import { CURRENCY_SYMBOL_CONSTANT } from "constants/currency-symbol.constants";

const PromotionConfig = (props) => {
  const { open, onCancel, config } = props;
  const [t] = useTranslation();
  const [isApplyProduct, setIsApplyProduct] = useState();
  const [isApplyOrder, setIsApplyOrder] = useState();
  const [isDiscountCombined, setIsDiscountCombined] = useState();
  const [isDiscountManual, setIsDiscountManual] = useState();
  const currencySymbol = useSelector(authUserSelector)?.currencySymbol ?? "đ";

  const pageData = {
    title: t("promotion.promotionSetting.title"),
    promotionType: t("promotion.promotionSetting.promotionType"),
    applyByProduct: t("promotion.promotionSetting.applyByProduct"),
    noteApplyByProduct: t("promotion.promotionSetting.noteApplyByProduct"),
    applyByOrder: t("promotion.promotionSetting.applyByOrder"),
    noteApplyByOrder: t("promotion.promotionSetting.noteApplyByOrder"),
    multistagePromotion: t("promotion.promotionSetting.multistagePromotion"),
    applyMultiStage: t("promotion.promotionSetting.applyMultiStage"),
    noteApplyMultiStageProduct: t("promotion.promotionSetting.noteApplyMultiStageProduct"),
    manualPromotion: t("promotion.promotionSetting.manualPromotion"),
    applyPromotionManually: t("promotion.promotionSetting.applyPromotionManually"),
    noteApplyPromotionManually: t("promotion.promotionSetting.noteApplyPromotionManually"),
    exampleProduct: t("promotion.promotionSetting.exampleProduct", { currency: currencySymbol }),
    exampleOrder: t("promotion.promotionSetting.exampleOrder", { currency: currencySymbol }),
    exampleCombined: t("promotion.promotionSetting.exampleCombined", { currency: currencySymbol }),
  };

  useEffect(() => {
    if (config) {
      setIsApplyProduct(config?.isApplyProduct);
      setIsApplyOrder(config?.isApplyOrder);
      setIsDiscountCombined(config?.isDiscountCombined);
      setIsDiscountManual(config?.isDiscountManual);
    }
  }, [config]);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleChangeConfig = async (checked, configType) => {
    let payload = { isApplyProduct, isApplyOrder, isDiscountCombined, isDiscountManual };
    switch (configType) {
      case PromotionConfigType.PRODUCT:
        payload = {
          ...payload,
          isApplyProduct: checked,
        };
        setIsApplyProduct(checked);
        break;
      case PromotionConfigType.ORDER:
        payload = {
          ...payload,
          isApplyOrder: checked,
        };
        setIsApplyOrder(checked);
        break;
      case PromotionConfigType.COMBINED:
        payload = {
          ...payload,
          isDiscountCombined: checked,
        };
        setIsDiscountCombined(checked);
        break;
      case PromotionConfigType.MANUAL:
        payload = {
          ...payload,
          isDiscountManual: checked,
        };
        setIsDiscountManual(checked);
        break;
      default:
        break;
    }
    await promotionConfigDataService.updatePromotionConfigAsync(payload);
  };

  const renderExample = () => {
    let exampleType = "";
    if (isApplyProduct && isApplyOrder) {
      exampleType = pageData.exampleCombined;
    } else if (isApplyProduct) {
      exampleType = pageData.exampleProduct;
    } else if (isApplyOrder) {
      exampleType = pageData.exampleOrder;
    }
    return parse(exampleType, {
      replace(domNode) {
        if (domNode.attribs && domNode?.name === "tooltip" && domNode.attribs?.title) {
          renderTitleTooltip(domNode.attribs.title);
          return (
            <FnbTooltip
              type="popover"
              placement="topLeft"
              title={renderTitleTooltip(domNode.attribs.title)}
              maxWidth={700}
            >
              <u className={domNode.attribs?.class || ""}>{renderContentTooltip(domNode)}</u>
            </FnbTooltip>
          );
        }
        if (domNode.attribs && domNode?.name === "custom_price" && domNode.attribs?.price) {
          return <span>{customPrice(domNode.attribs?.price)}</span>;
        }
      },
    });
  };

  const renderContentTooltip = (domNode) => {
    return (
      <>
        {domNode.children?.map((item) => {
          if (item?.type === "text") return item?.data;
          else if (item?.name === "custom_price") return customPrice(item?.attribs?.price);
          else if (item?.name === "span") return item?.children[0]?.data;
          else return "";
        })}
      </>
    );
  };

  const renderTitleTooltip = (text) => {
    const element = document.createElement("div");
    element.innerHTML = text;
    let value = "";
    if (element.children?.length > 0) {
      for (let i = 0; i < element.children?.length; i++) {
        if (element.children[i].nodeName.toLocaleLowerCase() === "custom_price") {
          value += customPrice(element.children[i]?.attributes[0]?.value);
        }
        if (element.children[i].nodeName.toLocaleLowerCase() === "span") {
          value += element.children[i]?.innerText;
        }
      }
    } else {
      value = text;
    }
    return value;
  };

  const customPrice = (price) => {
    let priceValue = price?.replace("{", "").replace("}", "");
    switch (currencySymbol) {
      case CURRENCY_SYMBOL_CONSTANT.USD:
        priceValue /= 1e3;
        break;
      default:
        break;
    }
    return formatNumberDecimalOrInteger(priceValue);
  };

  return (
    <PromotionPopover open={open} title={pageData.title} onCancel={handleCancel}>
      <Container>
        <Row gutter={24}>
          <Col xs={24} sm={11} md={11} lg={11} xl={8} xxl={8}>
            <WrapperConfig>
              <TitleSetting>{pageData.promotionType}</TitleSetting>
              <PromotionCheck>
                <FnbCheckBox
                  checked={isApplyProduct}
                  onChange={(event) => handleChangeConfig(event?.target?.checked, PromotionConfigType.PRODUCT)}
                >
                  <FnbTypography variant={theme.typography["h3-regular"]} text={pageData.applyByProduct} />
                </FnbCheckBox>
                <PromotionDescriptionChecked>{pageData.noteApplyByProduct}</PromotionDescriptionChecked>
              </PromotionCheck>
              <PromotionCheck>
                <FnbCheckBox
                  checked={isApplyOrder}
                  onChange={(event) => handleChangeConfig(event?.target?.checked, PromotionConfigType.ORDER)}
                >
                  <FnbTypography variant={theme.typography["h3-regular"]} text={pageData.applyByOrder} />
                </FnbCheckBox>
                <PromotionDescriptionChecked>{pageData.noteApplyByOrder}</PromotionDescriptionChecked>
              </PromotionCheck>
              <TitleSetting>{pageData.multistagePromotion}</TitleSetting>
              <PromotionCheck>
                <PromotionSwitch>
                  <Switch
                    checked={isDiscountCombined}
                    onChange={(checked) => handleChangeConfig(checked, PromotionConfigType.COMBINED)}
                  />
                  <span>{pageData.applyMultiStage}</span>
                </PromotionSwitch>
                <PromotionDescriptionSwitch>{pageData.noteApplyMultiStageProduct}</PromotionDescriptionSwitch>
              </PromotionCheck>
              <TitleSetting>{pageData.manualPromotion}</TitleSetting>
              <PromotionCheck>
                <PromotionSwitch>
                  <Switch
                    checked={isDiscountManual}
                    onChange={(checked) => handleChangeConfig(checked, PromotionConfigType.MANUAL)}
                  />
                  <span>{pageData.applyPromotionManually}</span>
                </PromotionSwitch>
                <PromotionDescriptionSwitch>{pageData.noteApplyPromotionManually}</PromotionDescriptionSwitch>
              </PromotionCheck>
            </WrapperConfig>
          </Col>
          <Col xs={24} sm={13} md={13} lg={13} xl={16} xxl={16}>
            <WrapperExample>
              <InnerHtml>{renderExample()}</InnerHtml>
            </WrapperExample>
          </Col>
        </Row>
      </Container>
    </PromotionPopover>
  );
};

export default PromotionConfig;
