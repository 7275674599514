/* eslint-disable jsx-a11y/anchor-is-valid */
import { Radio, Form } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { EnumTransferMaterialStatus } from "constants/transfer-material-history.constant";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import FnbRadioButton from "components/fnb-radio-button";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { fromWarehouseList, toWarehouseList, updatedByList } = props;
  const [selectedFromWarehouseId, setSelectedFromWarehouseId] = useState("");
  const [selectedToWarehouseId, setSelectedToWarehouseId] = useState("");
  const [selectedUpdatedId, setSelectedUpdatedId] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const defaultValue = "";
  const filterTypes = {
    fromWarehouse: "fromWarehouse",
    toWarehouse: "toWarehouse",
    updatedBy: "updatedId",
    status: "status",
  };

  useEffect(() => {
    let countBranch = selectedFromWarehouseId !== "" ? 1 : 0;
    let countAction = selectedToWarehouseId !== "" ? 1 : 0;
    let countMaterial = selectedUpdatedId !== "" ? 1 : 0;
    let countStatus = selectedStatusId !== "" ? 1 : 0;

    const filterOptions = {
      fromWarehouseId: selectedFromWarehouseId,
      toWarehouseId: selectedToWarehouseId,
      updatedId: selectedUpdatedId,
      statusId: selectedStatusId,
      count: countMaterial + countBranch + countAction + countStatus,
    };
    setResetFilter(filterOptions?.count < 1 ? false : true);
    props.fetchDataTransferMaterialHistory(filterOptions);
  }, [selectedFromWarehouseId, selectedToWarehouseId, selectedUpdatedId, selectedStatusId]);

  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      from: {
        title: t("transferMaterialHistory.from"),
        all: t("transferMaterialHistory.all"),
        placeholder: t("material.filter.branch.placeholder"),
        specialOptionKey: null,
      },
      destination: {
        title: t("transferMaterialHistory.destination"),
        all: t("transferMaterialHistory.all"),
        specialOptionKey: null,
      },
      updatedBy: {
        title: t("transferMaterialHistory.updatedBy"),
        all: t("transferMaterialHistory.all"),
        specialOptionKey: null,
      },
      status: {
        title: t("transferMaterialHistory.status"),
        all: t("transferMaterialHistory.all"),
        new: t("transferMaterialHistory.new"),
        inProgress: t("transferMaterialHistory.inProgress"),
        delivering: t("transferMaterialHistory.delivering"),
        completed: t("transferMaterialHistory.completed"),
        canceled: t("transferMaterialHistory.cancelled"),
        specialOptionKey: null,
      },
    },
  };

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    setSelectedFromWarehouseId(defaultValue);
    setSelectedToWarehouseId(defaultValue);
    setSelectedUpdatedId(defaultValue);
    setSelectedStatusId(defaultValue);
  };

  const onFilterIngredient = (id, filterName) => {
    switch (filterName) {
      case filterTypes.fromWarehouse:
        setSelectedFromWarehouseId(id);
        break;
      case filterTypes.toWarehouse:
        setSelectedToWarehouseId(id);
        break;
      case filterTypes.updatedBy:
        setSelectedUpdatedId(id);
        break;
      default: //status
        setSelectedStatusId(id);
        break;
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={pageData.filter.from.title}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => onFilterIngredient(value, filterTypes.fromWarehouse)}
          value={selectedFromWarehouseId}
          defaultValue={defaultValue}
          option={toWarehouseList}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.destination.title}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => onFilterIngredient(value, filterTypes.toWarehouse)}
          value={selectedToWarehouseId}
          defaultValue={defaultValue}
          option={fromWarehouseList}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.updatedBy.title}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => onFilterIngredient(value, filterTypes.updatedBy)}
          value={selectedUpdatedId}
          defaultValue={defaultValue}
          option={updatedByList}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.status.title}>
        <Radio.Group
          value={selectedStatusId}
          defaultValue={defaultValue}
          buttonStyle="solid"
          onChange={(e) => onFilterIngredient(e.target.value, filterTypes.status)}
        >
          <FnbRadioButton value={defaultValue} isChecked={selectedStatusId === ""}>
            {pageData.filter.status.all}
          </FnbRadioButton>
          <FnbRadioButton
            value={EnumTransferMaterialStatus.Completed}
            isChecked={selectedStatusId === EnumTransferMaterialStatus.Completed}
          >
            {pageData.filter.status.completed}
          </FnbRadioButton>
          <FnbRadioButton
            value={EnumTransferMaterialStatus.Inprogress}
            isChecked={selectedStatusId === EnumTransferMaterialStatus.Inprogress}
          >
            {pageData.filter.status.inProgress}
          </FnbRadioButton>
          <FnbRadioButton
            value={EnumTransferMaterialStatus.Delivering}
            isChecked={selectedStatusId === EnumTransferMaterialStatus.Delivering}
          >
            {pageData.filter.status.delivering}
          </FnbRadioButton>
          <FnbRadioButton
            value={EnumTransferMaterialStatus.Canceled}
            isChecked={selectedStatusId === EnumTransferMaterialStatus.Canceled}
          >
            {pageData.filter.status.canceled}
          </FnbRadioButton>
          <FnbRadioButton
            value={EnumTransferMaterialStatus.Draft}
            isChecked={selectedStatusId === EnumTransferMaterialStatus.Draft}
          >
            {pageData.filter.status.new}
          </FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
