import { Card, Dropdown } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import {
  ArrowDownOutlinedIcon,
  ArrowUpOutlinedIcon,
  ImportOutlinedIcon,
  PrinterOutlined,
} from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PrintBarcodeDialogComponent from "./components/print-barcode-dialog.component";
import TableProduct from "./components/table-product.component";
import "./product.scss";
import FnbSuffixHeadingGuideline from "components/fnb-heading-guidline/fnb-suffix-heading-guideline.component";
import { GuidancePurposeType } from "constants/guidance-link.constants";

/**
 * Page Product Management
 * description: page manage product primary template
 */
export default function ProductManagementPage(props) {
  const [t] = useTranslation();
  const [isShowPrintBarcodeDialog, setIsShowPrintBarcodeDialog] = useState(false);
  const [isOpenAddNewDropdown, setIsOpenAddNewDropdown] = useState(false);
  const dataToShowCreateTopping = { isTopping: true };

  const pageData = {
    title: t("productManagement.title"),
    button: {
      addNew: t("button.addNew"),
      addProduct: t("productManagement.addProduct"),
      export: t("button.export"),
      import: t("button.import"),
      print: t("button.print"),
    },
    btnMenuCreateProduct: t("productManagement.btnMenuCreateProduct"),
    btnMenuCreateTopping: t("productManagement.btnMenuCreateTopping"),
  };

  const handleCancelPrintBarcode = (data) => {
    setIsShowPrintBarcodeDialog(data?.visible ?? false);
  };

  const handleMenuClick = (e) => {
    switch (e?.key) {
      case "1":
        props.history.push("/product/create-new");
        break;
      case "2":
        props.history.push("/product/create-new", dataToShowCreateTopping);
        break;
      default:
    }
  };
  const items = [
    {
      label: `${pageData.btnMenuCreateProduct}`,
      key: "1",
    },
    {
      label: `${pageData.btnMenuCreateTopping}`,
      key: "2",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <FnbHeadingPage
        title={pageData.title}
        listButtons={[
          <FnbButton
            variant="secondary"
            iconHeader={<PrinterOutlined />}
            text={pageData.button.print}
            onClick={() => setIsShowPrintBarcodeDialog(true)}
          />,
          <FnbButton
            variant="secondary"
            iconHeader={<ImportOutlinedIcon />}
            text={pageData.button.import}
            permission={PermissionKeys.IMPORT_PRODUCT}
            onClick={() => props.history.push("/product/import")}
          />,
          <Dropdown
            trigger={"click"}
            menu={menuProps}
            onOpenChange={(isOpen) => {
              setIsOpenAddNewDropdown(isOpen);
            }}
            overlayClassName="dropdown-create-product-menu"
          >
            <FnbButton
              text={pageData.button.addNew}
              iconFooter={isOpenAddNewDropdown ? <ArrowUpOutlinedIcon /> : <ArrowDownOutlinedIcon />}
              permission={PermissionKeys.CREATE_PRODUCT}
            ></FnbButton>
          </Dropdown>,
        ]}
        suffix={<FnbSuffixHeadingGuideline purposeType={GuidancePurposeType.Product} />}
      />
      <div className="clearfix"></div>
      <Card className="fnb-card-custom">
        <TableProduct />
      </Card>
      {isShowPrintBarcodeDialog && (
        <PrintBarcodeDialogComponent
          isClearBarcodeContentWhenClose={true}
          visible={isShowPrintBarcodeDialog}
          onCancel={handleCancelPrintBarcode}
        />
      )}
    </div>
  );
}
