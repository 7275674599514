import { Avatar, Drawer, Image, Layout, Menu, Popover } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbParagraph from "components/fnb-paragraph/fnb-paragraph";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import {
  CpuSettingOutlined,
  LogoutIcon,
  MenuIcon,
  MessageQuestionIcon,
  ShopIcon,
  StaffUserFill
} from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DefaultConstants } from "constants/string.constants";
import storeDataService from "data-services/store/store-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { store } from "store";
import { setInformationPublishStore, setStoreInformation } from "store/modules/session/session.actions";
import { currentUserSelector, storeInfoSelector } from "store/modules/session/session.reducers";
import { hasPermission } from "utils/helpers";
import goFnbLogo from "../../assets/images/go-fnb-login-logo.png";
import storeDefault from "../../assets/images/store-default.jpg";
import ChangeLanguage from "../change-language/change-language.component";
import TrialPackageExpiredComponent from "../../pages/home/components/trial-package-expired"
import TawkToWidget from "components/tawkto-widget";
import { isStoreInternational } from "utils/helpers";
import "./index.scss";

const { Header } = Layout;
const { SubMenu } = Menu;

function TopBar(props) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const currentUser = useSelector(currentUserSelector);
  const { isTrial } = useSelector(storeInfoSelector);
  const { signedInUser, signOut, menuItems, route, isChild, parentKey, collapsed } = props;
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePopoverUser, setVisiblePopoverUser] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [currentSubMenuKeys, setCurrentSubMenuKeys] = useState([]);
  const [isShowWidget, setIsShowWidget] = useState(true);
  const [storeInfo, setStoreInfo] = useState({});
  const [isDefaultCountry, setIsDefaultCountry] = useState(true);
  const [hasPermissionAdmin] = useState(hasPermission(PermissionKeys.ADMIN));
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const history = useHistory();
  const isInternational = isStoreInternational();

  useEffect(() => {
    getStoreInfo();
    if (route.focus) {
      setSelectedKey(route.focus);
    } else {
      setSelectedKey(route.key);
    }

    if (isChild) {
      setCurrentSubMenuKeys([parentKey]);
    }
  }, [route]);

  useEffect(() => {
    const info = reduxState?.session?.informationPublishStore;
    setStoreInfo(info);
  }, [reduxState?.session?.informationPublishStore]);

  const logOut = () => {
    setIsShowWidget(false);
    setTimeout(() => {
      history.push('/login');
      var request = { UserId: signedInUser?.userId };
      signOut(request);
    }, 700);
  };

  const getShortName = (name) => {
    const names = name?.split(" ") ?? "";
    if (names.length > 1) {
      return names[0][0] + names[names.length - 1][0];
    }
    if (names.length === 1) {
      return names[0][0];
    }
    return names;
  };

  const showAndHideLanguageBox = (value) => {
    setIsVisiblePopover(value);
  };

  const getStoreInfo = () => {
    storeDataService.getStoreByIdAsync().then((data) => {
      setStoreInfo(data?.store);
      dispatch(setInformationPublishStore(data?.store));
      setIsDefaultCountry(data?.isDefaultCountry);
      const phoneCode = data?.store?.address?.country?.phonecode;
      if (phoneCode) {
        dispatch(
          setStoreInformation({
            phoneCode: data?.store?.address?.country?.phonecode,
            isTrial: data?.store?.isTrial,
          }),
        );
      }
    });
  };

  const getAddressUser = () => {
    const address1 = storeInfo?.address?.address1 ? storeInfo?.address?.address1 : ""
    const prefix = storeInfo?.address?.ward?.prefix ? ("," + storeInfo?.address?.ward?.prefix + ",") : ""
    const name = storeInfo?.address?.ward?.name ? (storeInfo?.address?.ward?.name + ",") : ""
    const districtPrefix = storeInfo?.address?.district?.prefix ? (storeInfo?.address?.district?.prefix + ",") : ""
    const districtName = storeInfo?.address?.district?.name ? (storeInfo?.address?.district?.name + ",") : ""
    const finalAddress = `${address1} ${prefix} ${name} ${districtPrefix} ${districtName}`
    return finalAddress
  }

  const renderAvatarPopover = (
    <>
      <div className="avatar-account-popover">
        <div className="header-avatar">
          <Avatar src={signedInUser?.thumbnail ?? null} className="avatar-popover">
            {getShortName(signedInUser?.fullName)}
          </Avatar>
        </div>
        <div className="avatar-infor account-name">{signedInUser?.fullName}</div>
        <div className="avatar-infor account-email">{signedInUser?.emailAddress}</div>
        <div className="store-info">
          <div className="account-store-name">
            <ShopIcon className="shop-icon" />
          </div>
          <div className="account-store-name">
            <span>
              <FnbParagraph>{storeInfo?.title}</FnbParagraph>
            </span>
          </div>
          <div span={24} className="account-store-address">
            <Paragraph
              className="paragraph-style"
              placement="top"
              ellipsis={{
                tooltip: isDefaultCountry
                  ? getAddressUser()
                  : `${storeInfo?.address?.address1 ? storeInfo?.address?.address1 + "," : ""} ${storeInfo?.address?.cityTown ? storeInfo?.address?.cityTown +"," : ""}`,
              }}
              color="#50429B"
            >
              <span>
                {isDefaultCountry
                  ? getAddressUser()
                  : `${storeInfo?.address?.address1 ? storeInfo?.address?.address1 + "," : ""} ${storeInfo?.address?.cityTown ? storeInfo?.address?.cityTown +"," : ""}`}
              </span>
            </Paragraph>
            <span>
              {isDefaultCountry
                 ? storeInfo?.address?.city?.name ?? ""
                 : `${storeInfo?.address?.state?.name ? storeInfo?.address?.state?.name +"," : ""} ${storeInfo?.address?.postalCode ? storeInfo?.address?.postalCode : ""}`}
            </span>
          </div>
        </div>
        <div className="account-popover-content">
          <div onClick={() => onOpenMyAccount()} className="pointer manage-account">
            <span className="avt-staff-icon">
              <StaffUserFill width={28} height={28} />
            </span>
            <a>{t("topBar.myAccount")}</a>
          </div>
          <hr />
          <div onClick={() => logOut()} className="pointer log-out-border">
            <span className="avt-menu-icon">
              <LogoutIcon width={28} height={28} />
            </span>
            <a>{t("topBar.logOut")}</a>
          </div>
        </div>
      </div>
      {isInternational &&  <TawkToWidget showWidget={isShowWidget}/>}
    </>
  );

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const renderMenuMobile = () => {
    showDrawer(true);
  };

  const onOpenMyAccount = () => {
    history.push("/my-account");
  };

  const onClickAvatar = () => {
    if (!visiblePopoverUser) {
      storeDataService.getStoreByIdAsync().then((data) => {
        setStoreInfo(data?.store);
        setIsDefaultCountry(data?.isDefaultCountry);
      });
    }
    setVisiblePopoverUser(!visiblePopoverUser);
  };

  /* Side Menu */
  const onOpenChange = (items) => {
    const latestOpenKey = items.find((key) => currentSubMenuKeys.indexOf(key) === -1);
    setCurrentSubMenuKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const renderMenusItems = () => {
    const { session } = store.getState();
    const { user } = session?.auth;

    const html = menuItems.map((item) => {
      if (item.child && item.child.length > 0) {
        const childs = item.child;
        let isAccess = false;
        childs.forEach((child) => {
          if (hasPermission(child.permission) === true) {
            isAccess = true;
          }
        });
        if (isAccess === true) {
          return (
            <SubMenu key={item.key} icon={item.icon} title={t(item.name)}>
              {childs.map((child) => {
                let isShow = child?.permission && hasPermission(child.permission);
                if (child.isMenu === true && isShow === true)
                  return (
                    <Menu.Item style={{ paddingLeft: "0px !important" }} key={child.key}>
                      <Link to={child.path} />
                      {t(child.name)}
                    </Menu.Item>
                  );
              })}
            </SubMenu>
          );
        }
      } else {
        let isShow = item?.permission && hasPermission(item.permission);
        /// If item is menu, then check if it has permission
        if (item.isMenu === true && isShow === true) {
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path} />
              {t(item.name)}
            </Menu.Item>
          );
        } else if (!item?.permission && user?.accountType === DefaultConstants.ADMIN_ACCOUNT) {
          /// If item is menu, then check if it has not permission
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path} />
              {t(item.name)}
            </Menu.Item>
          );
        }
      }
    });
    return html;
  };

  const onClickConfiguration = () => {
    if (hasPermissionAdmin) {
      if (window.location.pathname === "/config") {
        setVisible(false);
      } else {
        history.push("/config");
      }
    }
  };

  const getTrialPackageExpired = (trialExpired) => {
    const currentDate = moment().local();
    const expiredDate = moment.utc(trialExpired).local();
    const startOfCurrentDate = moment().local().startOf("date");
    const startOfExpiredDate = moment.utc(trialExpired).local().startOf("date");
    const diffHours = expiredDate.diff(currentDate, "minutes") / 60;
    const diffDays = startOfExpiredDate.diff(startOfCurrentDate, "days");

    if (expiredDate.isBefore(currentDate)) return <></>;

    if (diffHours > 24 || diffDays > 0) {
      return (
        <>
          {t("configuration.yourTrialExpired")} <strong>{Math.floor(diffHours / 24) + 1}</strong> {t("configuration.days")}
        </>
      );
    } else {
      const duration = moment.duration(expiredDate.diff(currentDate));
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      return (
        <>
          {t("configuration.yourTrialExpired")}{" "}
          {hours > 0 && (
            <>
              <strong>{hours}</strong> {t("configuration.hours")}{" "}
            </>
          )}
          <strong>{minutes}</strong> {t("configuration.minutes")}
        </>
      );
    }
  };

  return (
    <>
      <Header className={collapsed ? "collapse-header" : "expand-header"}>
        <div className="header-content">
          <div className="header-mobile">
            <div className="menu-icon" onClick={renderMenuMobile}>
              <MenuIcon />
            </div>
            <div className="logo">
              <Image preview={false} src={goFnbLogo} width={55} />
            </div>
          </div>
          <div className="header-web">
            <div className="store-info-box">
              <div className="store-logo">
                <img
                  src={!Boolean(storeInfo?.logo) ? storeDefault : storeInfo?.logo}
                  alt={!Boolean(storeInfo?.logo) ? storeDefault : storeInfo?.logo}
                  title={!Boolean(storeInfo?.logo) ? storeDefault : storeInfo?.logo}
                />
              </div>

              <div className="store-information">
                <span className="store-label" title={storeInfo?.title}>
                  {storeInfo?.title}
                </span>
                {storeInfo?.domainName && (
                  <a href={storeInfo?.domainName} className="store-name" target={"_blank"} rel="noreferrer">
                    {storeInfo?.domainName}
                  </a>
                )}
              </div>
            </div>
            {isTrial && currentUser?.trialPackageExpired && (
              <TrialPackageExpiredComponent isTrial={isTrial} trialExpired={currentUser?.trialPackageExpired} />
            )}
          </div>
        </div>
        <Link to="/how-to-use-pos" target="_blank">
          <FnbTooltip title={t("howToUsePos.title")} hideTooltip={isTouchDevice} placement="bottom">
            <FnbButton
              iconHeader={<MessageQuestionIcon />}
              text={!isMobile && t("howToUsePos.title")}
              style={{ marginRight: 12 }}
              variant="secondary-purple"
            />
          </FnbTooltip>
        </Link>
        <div className="header-change-language">
          <ChangeLanguage showAndHideLanguageBox={showAndHideLanguageBox} visible={isVisiblePopover} />
        </div>
        <div className="header-avatar">
          <Popover
            open={visiblePopoverUser}
            content={renderAvatarPopover}
            trigger="click"
            placement="bottomRight"
            overlayClassName="avatar-top-bar"
            onOpenChange={(visible) => {
              setVisiblePopoverUser(visible);
            }}
          >
            <Avatar onClick={onClickAvatar} src={signedInUser?.thumbnail ?? null}>
              {getShortName(signedInUser?.fullName)}
            </Avatar>
          </Popover>
        </div>
      </Header>
      <Drawer
        className="left-drawer-menu-wrapper"
        placement={"left"}
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div className="menu menu-mobile">
          <Menu
            selectedKeys={[selectedKey]}
            openKeys={currentSubMenuKeys}
            mode="inline"
            onOpenChange={(e) => onOpenChange(e)}
          >
            {renderMenusItems()}
          </Menu>
        </div>
        <div className={`trigger-footer-mobile ${!hasPermissionAdmin ? "trigger-footer-mobile--disabled" : ""}`}>
          <FnbTooltip
            hideTooltip={hasPermissionAdmin}
            title={
              <div
                className="text-default-tooltip"
                dangerouslySetInnerHTML={{
                  __html: t("menu.configurationNotPermission"),
                }}
              ></div>
            }
            placement="topLeft"
            variant="secondary"
            overlayStyle={{ paddingLeft: "12px", paddingRight: "12px" }}
          >
            <div className="settings-mobile" onClick={onClickConfiguration}>
              <span className="icon-setting">
                <CpuSettingOutlined />
              </span>
              <span className="title-setting">{t("menu.configuration")}</span>
            </div>
          </FnbTooltip>
        </div>
      </Drawer>
    </>
  );
}

export default TopBar;
