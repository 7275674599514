import MyAccountPage from "../my-account/my-account-page";
import ConfigPage from "pages/settings";
import { SettingFill } from "constants/icons.constants";
import i18n from "utils/i18n";
import { PermissionKeys } from "constants/permission-key.constants";
const { t } = i18n;

const route = [
  {
    key: "app.settings",
    position: 100,
    path: "/settings",
    icon: <SettingFill />,
    name: t("menu.settings"),
    isMenu: false,
    exact: true,
    auth: true,
    component: MyAccountPage,
    child: [],
  },
  {
    key: "app.config",
    position: 100,
    path: "/config",
    name: t("menu.storeManagement.config"),
    isMenu: false,
    exact: true,
    auth: true,
    permission: PermissionKeys.ADMIN,
    component: ConfigPage,
    child: [],
  },
];
export default route;
