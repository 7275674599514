import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import TranslatedLanguage from "./language-config/translated-language";
import "./index.style.scss";
import { InfoCircleFill, LanguageSquareFillIcon } from "constants/icons.constants";
import { useMediaQuery } from "react-responsive";

const { TabPane } = Tabs;
export default function Localization(props) {
  const [t] = useTranslation();
  const isMobileDevice = useMediaQuery({ maxWidth: 575 });
  const screens = {
    translatedLanguages: 1,
  };
  const pageData = {
    translatedLanguages: t("languageConfig.translatedLanguages"),
  };

  const tabs = [
    {
      key: screens.translatedLanguages,
      title: (
        <>
          <LanguageSquareFillIcon className="square-language-icon" />
          <span>{pageData.translatedLanguages}</span>
          <InfoCircleFill />
        </>
      ),
      component: <TranslatedLanguage />,
    },
  ];

  return (
    <div className="localization-tab">
      <Tabs tabPosition={isMobileDevice ? "top" : "left"} defaultActiveKey={screens.translatedLanguages}>
        {tabs.map((screen) => {
          return (
            <TabPane key={screen.key} tab={screen.title}>
              {screen.component}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}
