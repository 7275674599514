import { useTranslation } from "react-i18next";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "./index.scss";

export default function TrialPackageExpiredComponent({ isTrial, trialExpired }) {
  const [t] = useTranslation();
  const history = useHistory();

  const RennderTrialPackageExpired = () => {
    const currentDate = moment().local();
    const expiredDate = moment.utc(trialExpired).local();
    const startOfCurrentDate = moment().local().startOf("date");
    const startOfExpiredDate = moment.utc(trialExpired).local().startOf("date");
    const diffHours = expiredDate.diff(currentDate, "minutes") / 60;
    const diffDays = startOfExpiredDate.diff(startOfCurrentDate, "days");

    if (expiredDate.isBefore(currentDate)) return <></>;

    if (diffHours > 24 || diffDays > 0) {
      return (
        <>
          {t("configuration.yourTrialExpired")} <strong>{Math.floor(diffHours / 24) + 1}</strong> {t("configuration.days")}
        </>
      );
    } else {
      const duration = moment.duration(expiredDate.diff(currentDate));
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      return (
        <>
          {t("configuration.yourTrialExpired")}{" "}
          {hours > 0 && (
            <>
              <strong>{hours}</strong> {t("configuration.hours")}{" "}
            </>
          )}
          <strong>{minutes}</strong> {t("configuration.minutes")}
        </>
      );
    }
  };

  return (
    <>
      {isTrial && trialExpired && (
        <div className="trial-package">
          <span className="trial-package-expired">
            <RennderTrialPackageExpired />
          </span>
          <FnbButton text={t("activateAccount.upgradeAccount")} onClick={() => history.push("/my-account?tab=subscription&step=1")} />
        </div>
      )}
    </>
  );
}
