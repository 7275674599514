const AddressWorkIcon = ({ fillColor = "#BBAC5E" }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0949 20.6667H20.1425V1.66666C20.1425 1.11436 19.6309 0.666656 18.9997 0.666656H2.99967C2.36848 0.666656 1.85682 1.11436 1.85682 1.66666V20.6667H0.904436C0.588865 20.6667 0.333008 20.8905 0.333008 21.1667V22H21.6663V21.1667C21.6663 20.8905 21.4105 20.6667 21.0949 20.6667ZM6.42825 3.83332C6.42825 3.5572 6.6841 3.33332 6.99967 3.33332H8.90444C9.22001 3.33332 9.47586 3.5572 9.47586 3.83332V5.49999C9.47586 5.77611 9.22001 5.99999 8.90444 5.99999H6.99967C6.6841 5.99999 6.42825 5.77611 6.42825 5.49999V3.83332ZM6.42825 7.83332C6.42825 7.5572 6.6841 7.33332 6.99967 7.33332H8.90444C9.22001 7.33332 9.47586 7.5572 9.47586 7.83332V9.49999C9.47586 9.77612 9.22001 9.99999 8.90444 9.99999H6.99967C6.6841 9.99999 6.42825 9.77612 6.42825 9.49999V7.83332ZM8.90444 14H6.99967C6.6841 14 6.42825 13.7761 6.42825 13.5V11.8333C6.42825 11.5572 6.6841 11.3333 6.99967 11.3333H8.90444C9.22001 11.3333 9.47586 11.5572 9.47586 11.8333V13.5C9.47586 13.7761 9.22001 14 8.90444 14ZM12.5235 20.6667H9.47586V17.1667C9.47586 16.8905 9.73172 16.6667 10.0473 16.6667H11.9521C12.2676 16.6667 12.5235 16.8905 12.5235 17.1667V20.6667ZM15.5711 13.5C15.5711 13.7761 15.3152 14 14.9997 14H13.0949C12.7793 14 12.5235 13.7761 12.5235 13.5V11.8333C12.5235 11.5572 12.7793 11.3333 13.0949 11.3333H14.9997C15.3152 11.3333 15.5711 11.5572 15.5711 11.8333V13.5ZM15.5711 9.49999C15.5711 9.77612 15.3152 9.99999 14.9997 9.99999H13.0949C12.7793 9.99999 12.5235 9.77612 12.5235 9.49999V7.83332C12.5235 7.5572 12.7793 7.33332 13.0949 7.33332H14.9997C15.3152 7.33332 15.5711 7.5572 15.5711 7.83332V9.49999ZM15.5711 5.49999C15.5711 5.77611 15.3152 5.99999 14.9997 5.99999H13.0949C12.7793 5.99999 12.5235 5.77611 12.5235 5.49999V3.83332C12.5235 3.5572 12.7793 3.33332 13.0949 3.33332H14.9997C15.3152 3.33332 15.5711 3.5572 15.5711 3.83332V5.49999Z"
        fill={fillColor}
      />
    </svg>
  );
};
export default AddressWorkIcon;
