import { useEffect, useState } from "react";
import { executeAfter } from "utils/helpers";
import { getStorage, localStorageKeys, setStorage } from "utils/localStorage.helpers";

const LOCAL_STORAGE_EVENT = "storage";

export const useModifiedDataLocalStorage = () => {
  // region Ingredient
  const [needToReloadIngredients, setNeedToReloadIngredients] = useState(false);
  const [latestTimeModifiedIngredients, setLatestTimeModifiedIngredients] = useState(new Date().getTime());

  const updateLastTimeGetIngredients = () => {
    setNeedToReloadIngredients(false);
    setLatestTimeModifiedIngredients(new Date().getTime());
  };
  // end region Ingredient

  const handleCheckModified = () => {
    const lastModifiedIngredientFromStorage = getStorage(localStorageKeys.LAST_UPDATED_INGREDIENT);
    if (lastModifiedIngredientFromStorage > latestTimeModifiedIngredients) {
      setNeedToReloadIngredients(true);
      setLatestTimeModifiedIngredients(new Date().getTime());
      executeAfter(200, () => {
        setNeedToReloadIngredients(false);
      });
    }
  };

  const onModifiedIngredient = () => {
    setStorage(localStorageKeys.LAST_UPDATED_INGREDIENT, new Date().getTime());
  };

  useEffect(() => {
    window.addEventListener(LOCAL_STORAGE_EVENT, handleCheckModified);
    return () => {
      window.removeEventListener(LOCAL_STORAGE_EVENT, handleCheckModified);
    };
  });
  return {
    needToReloadIngredients,
    onModifiedIngredient,
    updateLastTimeGetIngredients,
  };
};

export const useModifiedBranchLocalStorage = () => {
  const [needToReloadBranches, setNeedToReloadBranches] = useState(false);
  const [latestTimeModified, setLatedTimeModified] = useState(new Date().getTime());

  const updateLastTimeGetBranches = () => {
    setNeedToReloadBranches(false);
    setLatedTimeModified(new Date().getTime());
  };

  const handleCheckModified = async () => {
    const lastModifiedFromStorage = getStorage(localStorageKeys.LAST_UPDATED_BRANCH);
    if (lastModifiedFromStorage > latestTimeModified) {
      setNeedToReloadBranches(true);
      setLatedTimeModified(new Date().getTime());
      setNeedToReloadBranches(false);
    }
  };

  const onModifiedBranch = () => {
    setStorage(localStorageKeys.LAST_UPDATED_BRANCH, new Date().getTime());
  };

  useEffect(() => {
    window.addEventListener(LOCAL_STORAGE_EVENT, handleCheckModified);
    return () => {
      window.removeEventListener(LOCAL_STORAGE_EVENT, handleCheckModified);
    };
  });

  return {
    needToReloadBranches,
    onModifiedBranch,
    updateLastTimeGetBranches,
  };
};
