import { Col, Form, Layout, message, Row } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbGoogleMap } from "components/fnb-google-map/google-map.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingleIcon } from "components/fnb-select-single-icon/fnb-select-single-icon";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { PlacesAutocomplete } from "components/places-auto-complete/places-auto-complete.component";
import { mappingWorkingHours } from "constants/day-of-week.constants";
import { DELAYED_TIME, EnumStoreRegion, POSTAL_CODE_REGEX } from "constants/default.constants";
import { FORMAT_GOOGLE_ADDRESS } from "constants/google.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import storeDataService from "data-services/store/store-data.service";
import { useEffect, useRef, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { useDispatch } from "react-redux";
import { setBranchWorkingHoursData } from "store/modules/branch/branch.actions";
import { getAddressDetails } from "utils/google";
import { getUrlQrCode, getValidationMessages, getDefaultCountry, getFullAddress } from "utils/helpers";
import i18n from "utils/i18n";
import { BranchBankTransferComponent } from "../components/branch-bank-transfer/Index";
import BranchKitchenComponent from "../components/branch-kitchen/branch-kitchen.component";
import { EditBranchWorkingHourComponent } from "../components/branch-working-hour/edit-branch-working-hour.component";
import { getUserInfo } from "services/auth.service";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { IsoPhoneNumberConstants } from "constants/phone-number.constants";
import FnbInputPhoneNumber from "components/fnb-input/fnb-input-phone-number.component";

export default function EditBranchPage(props) {
  const { t, branchDataService, match, history } = props;

  const { Content } = Layout;
  const [form] = Form.useForm();
  const [formSelectTimeOption] = Form.useForm();
  const dispatch = useDispatch();
  const loggedUserInfo = getUserInfo();
  const isInternationalRegion = loggedUserInfo?.storeType === EnumStoreRegion.INTERNATIONAL;

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    labelBranchName: t("settings.createBranch.branchName"),
    inputBranchName: t("settings.createBranch.inputBranchName"),
    validBranchName: t("settings.createBranch.validBranchName"),
    titleEditBranch: t("settings.editBranch.titleEditBranch"),
    branchUpdatedSuccessfully: t("settings.editBranch.branchUpdatedSuccessfully"),
    mustBeBetweenThreeAndOneHundredsCharacters: t("form.mustBeBetweenThreeAndOneHundredsCharacters"),

    labelCountry: t("form.country"),

    labelPhone: t("form.phone"),
    inputPhone: t("form.inputPhone"),
    validPhone: t("form.validPhone"),
    validPhonePattern: t("form.validPhonePattern"),

    labelEmail: t("form.email"),
    inputEmail: t("form.inputEmail"),
    validEmail: t("form.validEmail"),
    invalidMessageEmail: t("staffManagement.generalInformation.emailInvalidMessage"),

    labelAddress: t("form.address"),
    inputAddress: t("form.inputAddress"),
    validAddress: t("form.validAddress"),
    inputAddressOne: t("form.inputAddressOne"),
    inputAddressTwo: t("form.inputAddressTwo"),
    labelAddressTwo: t("form.addressTwo"),

    labelProvince: t("form.province"),
    selectProvince: t("form.selectProvince"),
    validProvince: t("form.validProvince"),

    labelDistrict: t("form.district"),
    selectDistrict: t("form.selectDistrict"),
    validDistrict: t("form.validDistrict"),

    labelWard: t("form.ward"),
    selectWard: t("form.selectWard"),
    validWard: t("form.validWard"),

    labelCity: t("form.city"),
    inputCity: t("form.inputCity"),
    validCity: t("form.validCity"),

    labelState: t("form.state"),
    validState: t("form.validState"),

    labelZip: t("form.zip"),
    inputZip: t("form.inputZip"),
    validZip: t("form.validZip"),
    invalidZip: t("form.invalidZip"),

    leaveForm: t("messages.leaveForm"),
    confirmation: t("messages.confirmation"),
    confirmLeave: t("button.confirmLeave"),
    discard: t("button.discard"),
    backBtn: t("settings.createBranch.back"),
    generalInformation: t("settings.createBranch.generalInformation"),
    mustBeBetweenThreeAndFifteenCharacters: t("form.mustBeBetweenThreeAndFifteenCharacters"),
    allowNumberOnly: t("form.allowNumberOnly"),
    inputBranchAddressPlaceholder: t("settings.createBranch.inputBranchAddressPlaceholder"),
    inputBranchAddressValidateMessage: t("settings.createBranch.inputBranchAddressValidateMessage"),
    phoneValidation: t("customer.addNewForm.phoneValidation"),
    validateAddressMessage: t("settings.createBranch.validateAddress"),

    titleBank: t("BranchBankTransfer.title"),
    useBankAccount: t("BranchBankTransfer.useBankAccount"),
    accountHolder: t("BranchBankTransfer.accountHolder"),
    inputAccountHolder: t("BranchBankTransfer.inputAccountHolder"),
    validAccountHolder: t("BranchBankTransfer.validAccountHolder"),
    bankName: t("BranchBankTransfer.bankName"),
    selectBankName: t("BranchBankTransfer.selectBankName"),
    validBankName: t("BranchBankTransfer.validBankName"),
    inputBankName: t("BranchBankTransfer.inputBankName"),
    validInputBankName: t("BranchBankTransfer.validInputBankName"),
    accountNumber: t("BranchBankTransfer.accountNumber"),
    inputAccountNumber: t("BranchBankTransfer.inputAccountNumber"),
    validAccountNumber: t("BranchBankTransfer.validAccountNumber"),
    country: t("BranchBankTransfer.country"),
    selectCountry: t("BranchBankTransfer.selectCountry"),
    validCountry: t("BranchBankTransfer.validCountry"),
    qRCode: t("BranchBankTransfer.qRCode"),
    bankBranchName: t("BranchBankTransfer.bankBranchName"),
    inputBankBranchName: t("BranchBankTransfer.inputBankBranchName"),
    swiftCode: t("BranchBankTransfer.swiftCode"),
    inputSwiftCode: t("BranchBankTransfer.inputSwiftCode"),
    routingNumber: t("BranchBankTransfer.routingNumber"),
    inputRoutingNumber: t("BranchBankTransfer.inputRoutingNumber"),
    download: t("BranchBankTransfer.download"),
    swiftCodeMaxLength: 100,
    routingNumberMaxLength: 100,
  };

  const [isChangeForm, setIsChangeForm] = useState(false);
  const [initData, setInitData] = useState({});
  const [countries, setCountries] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const [isDefaultCountry, setIsDefaultCountry] = useState(true);

  //Address Info
  const [fullStates, setFullStates] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [districtsByCityId, setDistrictsByCityId] = useState([]);
  const [wards, setWards] = useState([]);
  const [wardsByDistrictId, setWardsByDistrictId] = useState([]);
  const [fullAddressMap, setFullAddressMap] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [branchLocation, setBranchLocation] = useState(null);
  const autoCompleteRef = useRef();
  const googleMapRef = useRef();
  const workingHourRef = useRef();
  const [isFullTime, setIsFullTime] = useState(true);
  const [isAllDays, setIsAllDays] = useState(false);
  const [currentWorkingHoursData, setCurrentWorkingHoursData] = useState([]); ///Current working hours
  const [isUseStoreBankAccount, setIsUseStoreBankAccount] = useState(false);
  const [bankName, setBankName] = useState("");
  const bankTransferRef = useRef();
  const [countryOption, setCountryOption] = useState([]);
  const [kitchens, setKitchens] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await getInitDataAsync();
    }
    fetchData();
    dispatch(setBranchWorkingHoursData(null));
  }, []);

  const getInitDataAsync = async () => {
    let promises = [];
    promises.push(storeDataService.getPrepareAddressDataAsync());
    promises.push(branchDataService.getBranchByIdAsync(match?.params?.id));
    let [prepareAddressDataResponse, branchData] = await Promise.all(promises);

    bankTransferRef?.current?.setInitBankAccountData(branchData, prepareAddressDataResponse);

    const { states, countries, cities, districts, wards, defaultCountry } = prepareAddressDataResponse;
    setInitData(prepareAddressDataResponse);
    setCountries(countries);
    setCities(cities);
    setDistricts(districts);
    setStates(states);
    setWards(wards);
    setFullStates(states);
    countryOptionSelector(countries);

    /// Set customer data
    if (branchData) {
      const { branch } = branchData;
      setKitchens(branch?.kitchens);
      getInitDataWorkingHours(branch);

      let address1 = branch?.address1;
      let cityId = branch?.cityId;
      let districtId = branch?.districtId;
      let wardId = branch?.wardId;
      let countryId = branch?.countryId;
      let stateId = branch?.stateId;
      let fullAddress = "";

      onChangeCity(cityId);
      onChangeDistrict(districtId);
      const initField = {
        ...branch,
      };
      form.setFieldsValue(initField);
      const isDefault = getDefaultCountry(defaultCountry?.iso);
      if (isInternationalRegion) {
        setIsDefaultCountry(false);
      } else {
        setIsDefaultCountry(isDefault);
      }

      let country = countries?.find((country) => country?.id === countryId);
      setPhoneCode(country?.phonecode);

      const countryStates = states?.filter((state) => state?.countryCode === country.iso);
      setStates(countryStates);

      let districtsFilteredByCity = districts?.filter((district) => district?.cityId === cityId) ?? [];
      setDistrictsByCityId(districtsFilteredByCity);

      let wardsFilteredByCity = wards?.filter((ward) => ward?.districtId === districtId) ?? [];
      setWardsByDistrictId(wardsFilteredByCity);

      ///Set location
      let currentAddress = address1 || "";
      let currentWard = "";
      let districtName = "";
      let cityName = "";
      let stateName = "";
      let countryName = "";
      if (isDefault) {
        currentWard = wards?.find((ward) => ward?.id === wardId);
        districtName = districts?.find((district) => district?.id === districtId)?.name;
        cityName = cities?.find((city) => city?.id === cityId)?.name;
      } else {
        stateName = countryStates?.find((state) => state.id === stateId)?.name;
        countryName = countries?.find((country) => country?.id === countryId).name;
      }

      fullAddress = getFullAddress(isDefault, currentAddress, currentWard, districtName, cityName, stateName, countryName);

      setFullAddressMap(fullAddress);

      ///Set google map marker
      const currentBranchLocation = { lat: branch?.lat, lng: branch?.lng };

      if (autoCompleteRef && autoCompleteRef.current) {
        autoCompleteRef.current.setAddressWhenEdit(fullAddress);
      }

      setBranchLocation({
        center: currentBranchLocation,
        address: fullAddress,
      });

      if (googleMapRef && googleMapRef.current) {
        googleMapRef.current.setCenter(currentBranchLocation);
      }

      setIsUseStoreBankAccount(branch?.isUseStoreBankAccount);
    }
  };

  const countryOptionSelector = (countries) => {
    let result = countries?.map((country) => {
      return {
        label: (
          <Row className="col-country">
            <CircleFlag className="circle-icon" countryCode={country?.iso?.toLowerCase()} height={20} />
            <div className="country-name">{t(country?.nicename)}</div>
          </Row>
        ),
        value: country?.id,
        labelSearch: country?.nicename,
      };
    });
    setCountryOption(result);
  };

  const getInitDataWorkingHours = (branch) => {
    setIsFullTime(branch?.isFullTime);
    setIsAllDays(branch?.isWorkingAllDays);
    setCurrentWorkingHoursData(branch?.storeBranchWorkingHours ?? []);
    workingHourRef?.current?.setInitData(branch?.isFullTime, branch?.isWorkingAllDays, branch?.storeBranchWorkingHours);
  };

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

    form.validateFields(errorFields);
  }, [i18n.language]);

  const onCountryChange = (countryId) => {
    let country = countries?.find((country) => country?.id === countryId);
    setPhoneCode(country?.phonecode);
    const countryStates = fullStates?.filter((state) => state?.countryCode === country?.iso);
    setStates(countryStates);

    if (isInternationalRegion) {
      setIsDefaultCountry(false);
    } else {
      setIsDefaultCountry(getDefaultCountry(country?.iso));
    }

    let formValue = form.getFieldsValue();
    formValue.address1 = null;
    formValue.cityId = null;
    formValue.districtId = null;
    formValue.wardId = null;
    formValue.stateId = null;
    formValue.phoneNumber = null;
    form.setFieldsValue(formValue);

    ///Set region for select address autocomplete
    if (autoCompleteRef && autoCompleteRef.current) {
      autoCompleteRef.current.setDefaultCountry(countryId === initData?.defaultCountry?.id ? true : false);
      autoCompleteRef.current.clearCurrentLocation();
    }
  };

  const onChangeCity = (event) => {
    let districtsFilteredByCity = districts?.filter((district) => district?.cityId === event) ?? [];
    setDistrictsByCityId(districtsFilteredByCity);

    let formValue = form.getFieldsValue();
    formValue.districtId = null;
    formValue.wardId = null;
    form.setFieldsValue(formValue);
  };

  const onChangeDistrict = (event) => {
    let wardsFilteredByCity = wards?.filter((ward) => ward?.districtId === event) ?? [];
    setWardsByDistrictId(wardsFilteredByCity);

    let formValue = form.getFieldsValue();
    formValue.wardId = null;
    form.setFieldsValue(formValue);
  };

  const prefixSelector = <label>+{phoneCode}&nbsp;&nbsp;|</label>;

  const onFinish = async (values) => {
    if (!branchLocation) {
      message.error(pageData.inputBranchAddressValidateMessage);
      if (autoCompleteRef && autoCompleteRef.current) {
        autoCompleteRef.current.setIsError(true);
      }
      return;
    } else {
      if (autoCompleteRef && autoCompleteRef.current) {
        autoCompleteRef.current.setIsError(false);
      }
    }

    var newFullAddressMap = handleGenerateNewAddressByForm();

    const isValidate = validateAddress(values, newFullAddressMap);
    if (isValidate) {
      message.error(pageData.validateAddressMessage);
      return;
    }

    ///Validate working hours before saving
    if (isFullTime === false && currentWorkingHoursData?.length > 0) {
      await formSelectTimeOption.validateFields();
    }
    const qRCode = getUrlQrCode(values.acpId, values.accountNumber);

    var editModel = {
      ...values,
      branchId: match?.params.id,
      location: {
        lat: branchLocation?.center?.lat,
        lng: branchLocation?.center?.lng,
      },
      isFullTime: isFullTime,
      isWorkingAllDays: isAllDays,
      storeBranchWorkingHours: isFullTime ? [] : mappingWorkingHours(currentWorkingHoursData),
      isUseStoreBankAccount: isUseStoreBankAccount,
      bankName: bankName,
      qRCode: qRCode,
      phoneNumber: parsePhoneNumber(values?.phoneNumber, IsoPhoneNumberConstants[phoneCode])?.nationalNumber,
      iso: countries?.find((country) => country?.id === values?.countryId)?.iso,
    };

    let res = null;
    try {
      res = await branchDataService.updateBranchByIdAsync(editModel);
    } catch (errs) {
      form.setFields(getValidationMessages(errs));
    }

    if (res) {
      navigateToManagementPage({
        savedSuccessfully: true,
        message: pageData.branchUpdatedSuccessfully,
      });
      onResetFields();
    }
  };

  const handleGenerateNewAddressByForm = () => {
    let formValue = form.getFieldsValue();
    let address1 = formValue.address1;
    let cityId = formValue.cityId;
    let districtId = formValue.districtId;
    let wardId = formValue.wardId;
    let stateId = formValue.stateId;
    let countryId = formValue.countryId;
    let fullAddress = "";

    ///Set location
    if (isDefaultCountry) {
      const currentAddress = address1;
      const currentWard = wards?.find((x) => x.id === wardId);
      const districtName = districts?.find((x) => x.id === districtId)?.name;
      const cityName = cities?.find((x) => x.id === cityId)?.name;
      fullAddress = `${currentAddress}, ${currentWard?.prefix} ${currentWard?.name}, ${districtName}, ${cityName}`;
    } else {
      const currentAddress = address1;
      const stateName = states?.find((x) => x.id === stateId)?.name;
      const countryName = countries?.find((x) => x.id === countryId).name;
      fullAddress = `${currentAddress}, ${stateName}, ${countryName}`;
    }

    return fullAddress;
  };

  /**
   * This function is used to set the form status,
   * if value is true when you leave this page then a confirmation box will be displayed.
   *
   */
  const onFormChanged = () => {
    if (form.getFieldsValue()) {
      setIsChangeForm(true);
      setDisableSaveButton(false);
    } else {
      setIsChangeForm(false);
      setDisableSaveButton(true);
    }
  };

  /**
   * This function is used to navigate to the Staff Management page.
   * @param  {any} data This data will be called at the Branch Management page.
   */
  const navigateToManagementPage = (data) => {
    setIsChangeForm(false);
    dispatch(setBranchWorkingHoursData(null));
    setTimeout(() => {
      history.push({ pathname: "/branch", state: data });
    }, DELAYED_TIME);
  };

  /**
   * This function is used to get location from the google map
   * @param {*} location
   */
  const onSelectLocation = (location, addressInfo) => {
    setBranchLocation(location);
    const addressInfoData = getAddressDetails(addressInfo ?? []);
    onSetFormAddressValue(location.address, addressInfoData);

    ///Set google map marker
    if (googleMapRef && googleMapRef.current) {
      googleMapRef.current.setCenter(location.center);
    }
    if (autoCompleteRef && autoCompleteRef.current) {
      autoCompleteRef.current.setIsError(false);
    }
  };

  /// Set form address value when input address google map
  const onSetFormAddressValue = (address, addressInfo) => {
    if (address) {
      if (isDefaultCountry) {
        const addressGoogleMap = getAddressGoogleMap(address, addressInfo);

        ///Filter address id
        setDistrictsByCityId(addressGoogleMap.districtsFilteredByCity);
        setWardsByDistrictId(addressGoogleMap.wardsFilteredByCity);

        ///Set address field value
        let formValue = form.getFieldsValue();
        formValue.address1 = addressGoogleMap.address;
        formValue.cityId = addressGoogleMap.cityId;
        formValue.districtId = addressGoogleMap.districtId;
        formValue.wardId = addressGoogleMap.wardId;
        form.setFieldsValue(formValue);
      } else {
        const [street, ...otherAddressInfo] = address.split(",");
        const mainAddress = street ?? "";
        let stateId = states?.find((x) =>
          addressInfo?.city?.trim().toLowerCase().includes(x.name?.trim().toLowerCase()),
        )?.id;

        ///Set address field value
        let formValue = form.getFieldsValue();
        formValue.address1 = mainAddress;
        formValue.stateId = stateId;
        formValue.cityTown = otherAddressInfo?.length > 0 ? otherAddressInfo[0] : "";
        form.setFieldsValue(formValue);
      }
      setFullAddressMap(address);
    }
  };

  const onSetEmptyLocation = () => {
    setBranchLocation(null);
  };

  const onResetFields = () => {
    if (autoCompleteRef && autoCompleteRef.current) {
      autoCompleteRef.current.clearCurrentLocation();
    }
    form.resetFields();
    onSetEmptyLocation();
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  //This function is used to compare the address in form with the address Google Map
  function validateAddress(values, addressMap) {
    if (!Boolean(values) || !Boolean(addressMap)) {
      return;
    }

    const addressGoogleMap = getAddressGoogleMap(addressMap);
    if (
      addressGoogleMap.address?.trim().toLowerCase() !== values.address1?.trim().toLowerCase() &&
      addressGoogleMap.cityId !== values.cityId &&
      addressGoogleMap.districtId !== values.districtId &&
      addressGoogleMap.wardId !== values.wardId
    ) {
      return true;
    } else {
      return false;
    }
  }

  //This function is used to get the address from Google Map
  function getAddressGoogleMap(addressMap, addressInfo) {
    if (!Boolean(addressMap)) {
      return;
    }

    const splitAddress = addressMap.split(",");
    const mainAddress =
      splitAddress.length === FORMAT_GOOGLE_ADDRESS.defaultLength
        ? splitAddress[0]
        : splitAddress[0].concat(", ", splitAddress[1]);
    let city = splitAddress[splitAddress.length === FORMAT_GOOGLE_ADDRESS.defaultLength ? 3 : 4];
    let district = splitAddress[splitAddress.length === FORMAT_GOOGLE_ADDRESS.defaultLength ? 2 : 3];
    let fullWard = splitAddress[splitAddress.length === FORMAT_GOOGLE_ADDRESS.defaultLength ? 1 : 2];

    if (splitAddress && splitAddress?.length <= 3) {
      city = addressInfo?.city;
      district = addressInfo?.district;
      fullWard = addressInfo?.ward;
    }

    const ward = fullWard?.replace("phường", "")?.replace("Phường", "");

    ///Find address id
    let cityId = cities?.find((x) => city?.trim().toLowerCase().includes(x.name?.trim().toLowerCase()))?.id;

    let districtsFilteredByCity = districts?.filter((item) => item.cityId === cityId) ?? [];
    let districtId = districtsFilteredByCity?.find(
      (x) => district?.trim().toLowerCase() === x.name?.trim().toLowerCase(),
    )?.id;

    let wardsFilteredByCity = wards?.filter((item) => item.districtId === districtId) ?? [];
    let wardId = wardsFilteredByCity?.find((x) => ward?.trim().toLowerCase() === x.name?.trim().toLowerCase())?.id;
    if (!wardId) {
      wardId = wardsFilteredByCity?.find(
        (x) =>
          ward?.trim().toLowerCase().includes("(") & ward?.trim().toLowerCase().includes(")") &&
          ward?.trim().toLowerCase().includes(x.name?.trim().toLowerCase()),
      )?.id;
    }
    return {
      address: mainAddress?.trim(),
      cityId: cityId,
      districtsFilteredByCity: districtsFilteredByCity,
      districtId: districtId,
      wardsFilteredByCity: wardsFilteredByCity,
      wardId: wardId,
    };
  }

  return (
    <>
      <Form
        autoComplete="off"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        form={form}
        onChange={onFormChanged}
        onValuesChange={(e) => changeForm(e)}
        className="custom-form"
        layout="vertical"
      >
        <FnbHeadingPage
          title={pageData.titleEditBranch}
          listButtons={[
            <FnbCancelButton showWarning={isChangeForm} onOk={() => navigateToManagementPage()} />,
            <FnbButton
              type="submit"
              text={pageData.btnSave}
              permission={PermissionKeys.ADMIN}
              disabled={disableSaveButton}
              minWidth={100}
            />,
          ]}
        />
        <Content>
          <FnbCard title={pageData.generalInformation} underlineTitle>
            <Row gutter={[24, 0]}>
              <Col sm={24} xs={24} lg={12} className="mb-28">
                <Form.Item
                  label={pageData.labelBranchName}
                  name="branchName"
                  rules={[
                    { required: true, message: <InputValidateMessage message={pageData.validBranchName} /> },
                    { type: "string", warningOnly: true },
                    {
                      type: "string",
                      max: 100,
                      min: 3,
                      message: (
                        <InputValidateMessage
                          message={`${pageData.labelBranchName} ${pageData.mustBeBetweenThreeAndOneHundredsCharacters}`}
                        />
                      ),
                    },
                  ]}
                >
                  <FnbInput
                    showCount
                    maxLength={100}
                    className="fnb-input-with-count"
                    placeholder={pageData.inputBranchName}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} lg={12} className="mb-28">
                <Form.Item
                  name="countryId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={pageData.labelCountry}
                >
                  <FnbSelectSingleIcon
                    size="large"
                    showSearch
                    autoComplete="none"
                    onChange={(e) => {
                      onCountryChange(e);
                    }}
                    option={countryOption}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col sm={24} xs={24} lg={12} className="mb-28">
                <Form.Item
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.phoneValidation} />,
                    },
                    () => ({
                      validator(_, value) {
                        if (value?.length > 0) {
                          const isValid = isValidPhoneNumber(value, IsoPhoneNumberConstants[phoneCode]);
                          if (isValid) return Promise.resolve();
                          else return Promise.reject(<InputValidateMessage message={pageData.validPhonePattern} />);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  label={pageData.labelPhone}
                >
                  <FnbInputPhoneNumber
                    className="fnb-input-addon-before"
                    size="large"
                    placeholder={pageData.inputPhone}
                    addonBefore={prefixSelector}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} lg={12} className="mb-28">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: <InputValidateMessage message={pageData.invalidMessageEmail} />,
                    },
                  ]}
                  label={pageData.labelEmail}
                >
                  <FnbInput className="fnb-input" size="large" placeholder={pageData.inputEmail} />
                </Form.Item>
              </Col>
            </Row>

            {isDefaultCountry ? (
              <>
                <Row gutter={[24, 0]}>
                  <Col sm={24} xs={24} lg={12}>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item
                          name="address1"
                          rules={[
                            {
                              required: true,
                              message: <InputValidateMessage message={pageData.validAddress} />,
                            },
                          ]}
                          label={pageData.labelAddress}
                        >
                          <FnbInput
                            showCount
                            maxLength={255}
                            className="fnb-input-with-count"
                            size="large"
                            placeholder={pageData.inputAddress}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item
                          name="cityId"
                          rules={[
                            { required: true, message: <InputValidateMessage message={pageData.validProvince} /> },
                          ]}
                          label={pageData.labelCity}
                        >
                          <FnbSelectSingle
                            placeholder={pageData.inputCity}
                            option={cities?.map((item, index) => ({
                              id: item.id,
                              name: item.name,
                              key: index + item.id,
                            }))}
                            onChange={onChangeCity}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item
                          name="districtId"
                          rules={[
                            { required: true, message: <InputValidateMessage message={pageData.validDistrict} /> },
                          ]}
                          label={pageData.labelDistrict}
                        >
                          <FnbSelectSingle
                            placeholder={pageData.selectDistrict}
                            option={districtsByCityId?.map((item, index) => ({
                              id: item.id,
                              name: item.name,
                              key: index + item.id,
                            }))}
                            onChange={onChangeDistrict}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-16">
                        <Form.Item
                          name="wardId"
                          rules={[{ required: true, message: <InputValidateMessage message={pageData.validWard} /> }]}
                          label={pageData.labelWard}
                        >
                          <FnbSelectSingle
                            placeholder={pageData.selectWard}
                            option={wardsByDistrictId?.map((item, index) => ({
                              id: item.id,
                              name: item?.prefix + " " + item.name,
                              key: index + item.id,
                            }))}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  {/* Google map */}
                  <Col sm={24} xs={24} lg={12} className="mb-16">
                    <div className="branch-google-map-container">
                      <FnbGoogleMap ref={googleMapRef} className="google-map-box">
                        <PlacesAutocomplete
                          inputClassName="input-address"
                          addressPopoverClassName="input-address-popover"
                          textOverflowClassName="input-address-text-overflow"
                          ref={autoCompleteRef}
                          inputPlaceholder={pageData.inputBranchAddressPlaceholder}
                          onSelectLocation={onSelectLocation}
                          onEmptyLocation={onSetEmptyLocation}
                        ></PlacesAutocomplete>
                      </FnbGoogleMap>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[24, 0]}>
                  <Col sm={24} xs={24} lg={12}>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item
                          name="address1"
                          rules={[
                            {
                              required: true,
                              message: <InputValidateMessage message={pageData.validAddress} />,
                            },
                          ]}
                          label={pageData.labelAddress}
                        >
                          <FnbInput className="fnb-input" placeholder={pageData.inputAddressTwo} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item name="address2" label={pageData.labelAddressTwo}>
                          <FnbInput className="fnb-input" placeholder={pageData.inputAddressTwo} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item
                          name="cityTown"
                          rules={[{ required: true, message: <InputValidateMessage message={pageData.validCity} /> }]}
                          label={pageData.labelCity}
                        >
                          <FnbInput className="fnb-input" placeholder={pageData.inputCity} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-28">
                        <Form.Item
                          name="stateId"
                          rules={[{ required: true, message: <InputValidateMessage message={pageData.validState} /> }]}
                          label={pageData.labelProvince}
                        >
                          <FnbSelectSingle
                            placeholder={pageData.selectProvince}
                            option={states?.map((item, index) => ({
                              id: item.id,
                              name: item.name,
                              key: index + item.id,
                            }))}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="mb-16">
                        <Form.Item
                          name="postalCode"
                          rules={[
                            {
                              pattern: POSTAL_CODE_REGEX,
                              message: <InputValidateMessage message={pageData.invalidZip} />,
                            },
                          ]}
                          label={pageData.labelZip}
                        >
                          <FnbInput
                            className="fnb-input"
                            placeholder={pageData.inputZip}
                            maxLength={50}
                            onInput={(event) => (event.target.value = event.target.value?.toUpperCase())}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  {/* Google map */}
                  <Col sm={24} xs={24} lg={12} className="mb-16">
                    <div className="branch-google-map-container">
                      <FnbGoogleMap ref={googleMapRef} className="google-map-box" zoom={18}>
                        <PlacesAutocomplete
                          inputClassName="input-address"
                          addressPopoverClassName="input-address-popover"
                          textOverflowClassName="input-address-text-overflow"
                          ref={autoCompleteRef}
                          inputPlaceholder={pageData.inputBranchAddressPlaceholder}
                          onSelectLocation={onSelectLocation}
                          onEmptyLocation={onSetEmptyLocation}
                        ></PlacesAutocomplete>
                      </FnbGoogleMap>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </FnbCard>
        </Content>

        {/* Branch Bank Transfer */}
        <BranchBankTransferComponent
          ref={bankTransferRef}
          t={t}
          isUseStoreBankAccount={isUseStoreBankAccount}
          setIsUseStoreBankAccount={setIsUseStoreBankAccount}
          setBankName={setBankName}
          form={form}
          countryOption={countryOption}
        />
      </Form>

      <BranchKitchenComponent data={kitchens} />

      {/* Branch working hours */}
      <EditBranchWorkingHourComponent
        setCurrentWorkingHoursData={setCurrentWorkingHoursData}
        setIsCheckedFullTime={setIsFullTime}
        setIsSelectedAllDays={setIsAllDays}
        formSelectTimeOption={formSelectTimeOption}
        ref={workingHourRef}
        setIsChangeForm={(isChangeForm) => {
          setIsChangeForm(isChangeForm);
          setDisableSaveButton(!isChangeForm);
        }}
      />
    </>
  );
}
