import { Card, Col, message, Row } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import areaTableDataService from "data-services/area-table/area-table-data.service";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { executeAfter, formatNumber, getThumbnailUrl, hasPermission } from "utils/helpers";
import EditAreaTable from "./edit-table.component";
import { images } from "constants/images.constants";
import { ViewDetailComponent } from "./view-detail.component";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useMediaQuery } from "react-responsive";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import "../area-table.page.scss";

const TableManagement = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const {
    storeBranchId,
    areaId,
    setHeaderCollapse,
    listArea,
    setActiveKey,
    listButtons,
    setIsChangedCollapse,
    setShowAddAreaTable,
  } = props;
  const [listAreaTableManagement, setListAreaTableManagement] = useState([]);
  const [showUpdateAreaTable, setShowUpdateAreaTable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalTableManagement, setTotalTableManagement] = useState(0);
  const [selectedAreaTable, setSelectedAreaTable] = useState(null);
  const [showViewDetail, setShowViewDetail] = useState(false);
  const [dataViewDetail, setDataViewDetail] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();

  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [isLoading, setIsLoading] = useState(true);
  const pageSize = 8;

  const pageData = {
    no: t("table.no"),
    thumbnail: t("area.thumbnail"),
    areaName: t("areaTable.areaName"),
    tableName: t("areaTable.tableName"),
    description: t("form.description"),
    seat: t("areaTable.seat"),
    seats: t("areaTable.seats"),
    status: t("areaTable.status"),
    action: t("table.action"),
    addNew: t("button.addNew"),
    active: t("areaTable.active"),
    inactive: t("areaTable.inactive"),
    btnFilter: t("button.filter"),
    btnDelete: t("button.yesDelete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    areaTableDeleteSuccess: t("areaTable.areaTableDeleteSuccess"),
    areaTableDeleteFail: t("areaTable.areaTableDeleteFail"),
    title: t("areaTable.title"),
    titleViewDetail: t("areaTable.titleViewDetail"),
    notFoundData: t("messages.cannotFindAreaTableInfo"),
    titleViewDescription: t("areaTable.titleViewDescription"),
    search: t("areaTable.search"),
    viewDetail: t("areaTable.viewDetail"),
    toCreateData: t("area.toCreateData"),
    titleAddNewTable: t("areaTable.tableForm.titleAddNewTable"),
  };

  useEffect(() => {
    async function fetchData() {
      await initDataTableAreaTableManagement(tableSettings.page, pageSize, storeBranchId);
    }
    props.tableFuncs.current = fetchData;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeBranchId]);

  useImperativeHandle(ref, () => ({
    resetData: () => {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    },
    initData: (activeKey) => {
      initDataTableAreaTableManagement(tableSettings.page, pageSize, storeBranchId, "", activeKey);
    },
  }));

  const initDataTableAreaTableManagement = async (pageNumber, pageSize, storeBranchId, textSearch = "", activeKey) => {
    setIsLoading(true);
    setPageNumber(pageNumber);
    if (storeBranchId) {
      let res = await areaTableDataService.getAreaTableByBranchAsync(
        pageNumber,
        pageSize,
        storeBranchId,
        textSearch,
        activeKey || areaId,
      );
      if (res) {
        let areaTableManagements = mappingToDataTableAreaTableManagements(res.areaTables);
        setListAreaTableManagement(areaTableManagements);
        setTotalTableManagement(res.total);

        let numberRecordCurrent = pageNumber * pageSize;
        if (numberRecordCurrent > res.total) {
          numberRecordCurrent = res.total;
        }
        setNumberRecordCurrent(numberRecordCurrent);
        setIsLoading(false);
      }
    }
  };

  const mappingToDataTableAreaTableManagements = (areaTableManagements) => {
    return areaTableManagements?.map((item) => {
      return {
        id: item?.id,
        areaName: item?.areaName,
        tableName: item?.name,
        seat: item?.numberOfSeat,
        status: item?.isActive,
        description: item?.description,
        thumbnail: item?.imageUrl,
      };
    });
  };

  const onEditItem = (_selectedRowKeys, selectedRows) => {
    const { id } = selectedRows?.[0];

    let request = {
      id: id,
      storeBranchId: storeBranchId,
    };

    areaTableDataService.getAreaTableByIdAsync(request).then((response) => {
      if (response) {
        const { areaTable } = response;
        setSelectedAreaTable(areaTable);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setShowUpdateAreaTable(true);
      }
    });
  };

  const onHandleViewDetail = async (id) => {
    setDataViewDetail(null);
    let request = {
      id: id,
      storeBranchId: storeBranchId,
    };
    const res = await areaTableDataService.getAreaTableByIdAsync(request);
    if (res) {
      const { areaTable } = res;
      const data = {
        title: pageData.titleViewDetail,
        name: areaTable?.name,
        numberOfSeat: areaTable?.numberOfSeat,
        status: areaTable?.isActive,
        statusName: areaTable?.isActive ? pageData.active : pageData.inactive,
        imageUrl: areaTable?.imageUrl,
        description: areaTable?.description,
        parentName: areaTable?.areaName,
      };

      setShowViewDetail(true);
      setDataViewDetail(data);
    } else {
      message.error(pageData.notFoundData);
    }
  };

  const getColumnAreaTables = () => {
    const columns = [
      {
        title: pageData.tableName,
        dataIndex: "tableName",
        key: "tableName",
        width: "75%",
        ellipsis: true,
        sorter: (pre, current) => pre?.tableName.localeCompare(current?.tableName),
        render: (_, record) => {
          const id = record?.id;
          const tableName = record?.tableName;
          const areaName = record?.areaName;
          const description = record?.description?.replace(/<.*?>/gm, "") || "-";
          const thumbnail = record?.thumbnail;
          const seat = record?.seat;
          const status = record?.status;
          return (
            <FnbTooltip
              variant="secondary"
              overlayClassName="tooltip-table-name"
              placement="bottomLeft"
              title={titleTooltip(id, tableName, areaName, description, thumbnail, seat, status)}
              disabledInnerPadding
              zIndex={999}
              trigger={isMobile ? "click" : "hover"}
              disabledStopPropagation={isMobile ? false : true}
            >
              <div className="column-table-name">
                <img src={getThumbnailUrl(thumbnail, "mobile") || images.imgDefault} alt={tableName} />
                <div className="column-text">
                  <span className="column-text-name">{tableName}</span>
                  <div className="column-text-description" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.seat,
        dataIndex: "seat",
        key: "seat",
        width: "15%",
        align: "right",
        render: (_, record) => {
          return (
            <Row justify={"end"}>
              <span>{formatNumber(record?.seat)}</span>
            </Row>
          );
        },
      },
      {
        title: pageData.status,
        dataIndex: "status",
        key: "status",
        width: "10%",
        align: "center",
        render: (_, record) => (
          <>
            <Row justify={"center"}>
              <FnbBadge
                variant={record?.status ? "success" : "error"}
                text={record?.status ? pageData.active : pageData.inactive}
              />
            </Row>
          </>
        ),
      },
    ];

    return columns;
  };

  const onRowClick = (record) => {
    onHandleViewDetail(record?.id);
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const onChangePage = async (pageNumber) => {
    initDataTableAreaTableManagement(pageNumber, pageSize, storeBranchId);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onCancelUpdateForm = (id) => {
    setShowUpdateAreaTable(false);
    if (id) setActiveKey(id);
    initDataTableAreaTableManagement(tableSettings.page, pageSize, storeBranchId);
  };

  const onDeleteItem = (_selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const handleDeleteItem = async (id) => {
    var res = await areaTableDataService.deleteAreaTableByIdAsync(id);
    if (res) {
      message.success(pageData.areaTableDeleteSuccess);
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setShowConfirmDeleteModal(false);
      setIsChangedCollapse(true);
    } else {
      message.error(pageData.areaTableDeleteFail);
    }
    await initDataTableAreaTableManagement(tableSettings.page, pageSize, storeBranchId);
  };

  const onHandleDelete = async (id) => {
    setShowUpdateAreaTable(false);
    var res = await areaTableDataService.deleteAreaTableByIdAsync(id);
    if (res) {
      message.success(pageData.areaTableDeleteSuccess);
    } else {
      message.error(pageData.areaTableDeleteFail);
    }
    await initDataTableAreaTableManagement(tableSettings.page, pageSize, storeBranchId);
  };

  const onSearch = async (keySearch) => {
    executeAfter(500, async () => {
      await initDataTableAreaTableManagement(1, pageSize, storeBranchId, keySearch);
    });
  };

  const titleTooltip = (id, tableName, areaName, description, thumbnail, seat, status) => {
    const desc = `${pageData.description}: ${description}`;
    const seats = `${seat} ${pageData.seats} - ${areaName}`;
    return (
      <div className="content-tooltip-table">
        <div className="column-table-name">
          <img src={getThumbnailUrl(thumbnail, "mobile") || images.imgDefault} alt={tableName} />
          <div className="column-text">
            <FnbBadge variant={status ? "success" : "error"} text={status ? pageData.active : pageData.inactive} />
            <span className="column-text-name">{tableName}</span>
            <span className="column-text-seats">{seats}</span>
          </div>
        </div>
        <div className="column-text-description" dangerouslySetInnerHTML={{ __html: desc }} />
        <FnbButton
          variant="secondary-purple"
          className="button-view-details"
          permission={PermissionKeys.VIEW_AREA_TABLE}
          onClick={() => onHandleViewDetail(id)}
          text={pageData.viewDetail}
          minWidth={isMobile ? 320 : 450}
        />
      </div>
    );
  };

  return (
    <>
      <Card className="fnb-card-custom tab-table-management">
        <Row>
          <Col span={24}>
            <FnbTable
              loading={isLoading}
              className="table-management-list"
              columns={getColumnAreaTables()}
              pageSize={pageSize}
              dataSource={listAreaTableManagement}
              pageNumber={pageNumber}
              currentPageNumber={pageNumber}
              numberRecordCurrent={numberRecordCurrent}
              total={totalTableManagement}
              onChangePage={onChangePage}
              editPermission={PermissionKeys.EDIT_AREA_TABLE}
              deletePermission={PermissionKeys.DELETE_AREA_TABLE}
              cursorGrabbing="false"
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                onEdit: onEditItem,
                onDelete: onDeleteItem,
              }}
              onRowClick={hasPermission(PermissionKeys.VIEW_AREA_TABLE) && onRowClick}
              isRenderTableController={false}
              isSetHeaderCollapse={true}
              setHeaderCollapse={setHeaderCollapse}
              // TODO
              // search={{
              //   maxLength: 100,
              //   placeholder: pageData.search,
              //   onChange: onSearch,
              // }}
              listButtons={listButtons}
              isCompactButtons={false}
              emptyText={
                hasPermission(PermissionKeys.CREATE_AREA_TABLE) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.titleAddNewTable}
                      onClick={() => setShowAddAreaTable(true)}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Col>
        </Row>
      </Card>
      <EditAreaTable
        areaTableDataService={areaTableDataService}
        isModalVisible={showUpdateAreaTable}
        selectedAreaTable={selectedAreaTable}
        listArea={listArea}
        storeBranchId={storeBranchId}
        onCancel={onCancelUpdateForm}
        handleDelete={(id) => {
          onHandleDelete(id);
        }}
      />
      <ViewDetailComponent
        isModalVisible={showViewDetail}
        dataViewDetail={dataViewDetail}
        closeViewDetail={() => setShowViewDetail(false)}
        titleDescription={pageData.titleViewDescription}
      />
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.tableName)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={() => handleDeleteItem(selectedRows[0]?.id)}
        dangerOkButton={true}
      />
    </>
  );
});

export default TableManagement;
