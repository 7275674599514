import { Modal, Space, Table } from "antd";
import { FnbNotifyDialog } from "components/fnb-notify-dialog/fnb-notify-dialog.component";
import { DELAYED_TIME } from "constants/default.constants";
import { TrashFill } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { hasPermission } from "utils/helpers";

import "./delete-confirm.component.scss";
import { ConfirmStyle } from "constants/level-menu.constants";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

export default function DeleteConfirmComponent({
  className = "",
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
  permission,
  okType,
  buttonIcon,
  canClose = false,
  visible,
  skipPermission,
  buttonText,
  buttonType, //ICON | TERTIARY TEXT-BORDER | NONE
  tooltipTitle,
  isChangeForm,
  okButtonProps,
  cancelButtonProps,
  centered,
  productCategoryId,
  productCategoryName,
  modalContainerStyle,
  type = ConfirmStyle.LEAVER,
  width = 420,
  contentElement,
  variantOkButton = "primary",
  dangerOkButton = false,
  minWidthBtnCancel = "144px",
  minWidthBtnOk = "166px",
  idBtnOk,
  onAfterClose,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRedirectPath, setCurrentRedirectPath] = useState(null);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationDataSource, setNotificationDataSource] = useState(null);
  const [notificationTable, setNotificationTable] = useState(null);
  const pageData = {
    productCategoryRelatedMessage: t("productCategory.productCategoryRelatedMessage"),
    notificationTitle: t("form.notificationTitle"),
    buttonIGotIt: t("form.buttonIGotIt"),
    no: t("table.no"),
    comboName: t("combo.generalInformation.comboName"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
    DeleteDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };
  if (buttonType === undefined) {
    buttonType = "ICON";
  }

  buttonType = buttonType ?? "ICON";

  if (type === ConfirmStyle.LEAVER) {
    title = title ?? pageData.leaveDialog.confirmation;
    content = content ?? pageData.leaveDialog.content;
    cancelText = cancelText ?? pageData.leaveDialog.discardBtn;
    okText = okText ?? pageData.leaveDialog.btnConfirmLeave;
  } else if (type === ConfirmStyle.DELETE) {
    title = title ?? pageData.leaveDialog.confirmation;
    dangerOkButton = true;
  }

  const tableComboSettings = [
    {
      title: pageData.comboName,
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (value, record, index) => {
        return (
          <Link to={`/combo/detail/${record?.id}`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {index + 1}.{record?.name}
          </Link>
        );
      },
    },
  ];

  const tableCombo403Settings = [
    {
      title: pageData.comboName,
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (value, record, index) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {index + 1}.{record?.name}
          </Link>
        );
      },
    },
  ];

  const handleDeleteAction = async () => {
    if (productCategoryId) {
      var res = await productCategoryDataService.getCombosByProductCategoryIdAsync(productCategoryId);
      if (res && res?.activeCombos?.length > 0) {
        setIsModalNotificationVisible(true);
        let message = t(pageData.productCategoryRelatedMessage, {
          name: productCategoryName,
        });
        setNotificationMessage(message);
        if (hasPermission(PermissionKeys.VIEW_COMBO)) {
          setNotificationTable(tableComboSettings);
          setNotificationDataSource(res.activeCombos);
        } else {
          setNotificationTable(tableCombo403Settings);
        }
      } else {
        showModal();
      }
    } else {
      showModal();
    }
  };

  const showModal = (route) => {
    if (route) {
      const { pathname } = route;
      setCurrentRedirectPath(pathname);
    }
    setIsModalVisible(true);
    return false;
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (onOk) {
      onOk();

      if (currentRedirectPath !== null) {
        setTimeout(() => {
          return history.push(currentRedirectPath);
        }, DELAYED_TIME);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (onCancel) {
      onCancel();
    }
  };

  const renderModal = () => {
    return (
      <>
        <Modal
          afterClose={onAfterClose}
          className={`dialog-confirm-modal ${className} ${modalContainerStyle}`}
          title={title}
          visible={isModalVisible || visible}
          okText={okText}
          okType={okType ? okType : "danger"}
          closable={canClose}
          cancelText={cancelText}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={okButtonProps}
          centered={centered}
          cancelButtonProps={cancelButtonProps}
          footer={[
            <FnbButton minWidth={minWidthBtnCancel} text={cancelText} variant="tertiary" onClick={handleCancel} />,
            <FnbButton
              id={idBtnOk}
              minWidth={minWidthBtnOk}
              text={okText}
              variant={variantOkButton ?? ""}
              danger={dangerOkButton}
              onClick={handleOk}
            />,
          ]}
          width={width}
        >
          {contentElement ? (
            contentElement
          ) : (
            <span dangerouslySetInnerHTML={{ __html: `${content}` }}></span>
          )}
        </Modal>
      </>
    );
  };

  const renderWithText = () => {
    return (!permission || hasPermission(permission) || skipPermission) &&
      buttonText !== "" &&
      buttonText !== undefined ? (
      <>
        <FnbButton variant="tertiary" danger onClick={showModal} className={className} text={buttonText ?? ""} />
        {renderModal()}
      </>
    ) : (
      <></>
    );
  };

  const renderWithTextAndColorBorder = () => {
    return (!permission || hasPermission(permission) || skipPermission) &&
      buttonText !== "" &&
      buttonText !== undefined ? (
      <>
        <FnbButton variant="primary" danger onClick={showModal} text={buttonText ?? ""} />
        {renderModal()}
      </>
    ) : (
      <></>
    );
  };

  const renderWithIcon = () => {
    return !permission || hasPermission(permission) || skipPermission ? (
      <>
        <Space wrap className={className}>
          {!skipPermission && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={() => handleDeleteAction()}>
              {buttonIcon ? (
                tooltipTitle ? (
                  <FnbTooltip placement="top" title={tooltipTitle} zIndex={10}>
                    {buttonIcon}
                  </FnbTooltip>
                ) : (
                  { buttonIcon }
                )
              ) : (
                <div className="fnb-table-action-icon">
                  <FnbTooltip placement="top" title={t("button.delete")} zIndex={10}>
                    <TrashFill className="icon-svg-hover" />
                  </FnbTooltip>
                </div>
              )}
            </a>
          )}
          {renderModal()}
        </Space>
      </>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Prompt when={isChangeForm ? isChangeForm : false} message={showModal} />
      {(buttonType ?? "ICON") === "ICON"
        ? renderWithIcon()
        : buttonType === "TEXT-BORDER"
        ? renderWithTextAndColorBorder()
        : buttonType === "NONE"
        ? renderModal()
        : buttonType === "TERTIARY" && renderWithText()}
      <FnbNotifyDialog
        title={pageData.notificationTitle}
        open={isModalNotificationVisible}
        hideCancelButton={true}
        okText={pageData.buttonIGotIt}
        onOk={() => {
          setIsModalNotificationVisible(false);
        }}
        onCancel={() => {
          setIsModalNotificationVisible(false);
        }}
        content={() => {
          return (
            <>
              <div
                className="text-content-notification-product-dependencies"
                dangerouslySetInnerHTML={{
                  __html: notificationMessage,
                }}
              />
              <Table
                className="table-category-product-dependencies"
                columns={notificationTable}
                dataSource={notificationDataSource}
                pagination={false}
              />
            </>
          );
        }}
      />
    </>
  );
}
