const theme = {
  typography: {
    /**
     * @fontSize 12px
     * @fontWeight
     */
    "c-regular": "c-regular",
    //___________
    /**
     * @fontSize  12px
     * @fontWeight 500
     */
    "c-medium": "c-medium",
    //___________
    /**
     * @fontSize  12px
     * @fontWeight 700
     */
    "c-bold": "c-bold",
    //___________
    /**
     * @fontSize  12px
     * @fontWeight 400
     */
    "c-italic": "c-italic",
    //___________
    /**
     * @fontSize  12px
     * @fontWeight 400
     */
    "c-underlined": "c-underlined",
    //___________
    /**
     * @fontSize  12px
     * @fontWeight 700
     */
    "c-bold-underlined": "c-bold-underlined",
    //___________
    /**
     * @fontSize 14px
     * @fontWeight 400
     */
    "b2-regular": "b2-regular",
    //___________
    /**
     * @fontSize 14px
     * @fontWeight 500
     */
    "b2-medium": "b2-medium",
    //___________
    /**
     * @fontSize 14px
     * @fontWeight 700
     */
    "b2-bold": "b2-bold",
    //___________
    /**
     * @fontSize 14px
     * @fontWeight 400
     */
    "b2-italic": "b2-italic",
    //___________
    /**
     * @fontSize 14px
     * @fontWeight 400
     */
    "b2-underlined": "b2-underlined",
    //___________
    /**
     * @fontSize 14px
     * @fontWeight 700
     */
    "b2-bold-underlined": "b2-bold-underlined",
    //___________
    /**
     * @fontSize 16px
     * @fontWeight 400
     */
    "b1-regular": "b1-regular",
    //___________
    /**
     * @fontSize 16px
     * @fontWeight 500
     */
    "b1-medium": "b1-medium",
    //___________
    /**
     * @fontSize 16px
     * @fontWeight 700
     */
    "b1-bold": "b1-bold",
    //___________
    /**
     * @fontSize 16px
     * @fontWeight 400
     */
    "b1-italic": "b1-italic",
    //___________
    /**
     * @fontSize 16px
     * @fontWeight 400
     */
    "b1-underlined": "b1-underlined",
    //___________
    /**
     * @fontSize 16px
     * @fontWeight 700
     */
    "b1-bold-underlined": "b1-bold-underlined",
    //___________
    /**
     * @fontSize 18px
     * @fontWeight 400
     */
    "h3-regular": "h3-regular",
    //___________
    /**
     * @fontSize 18px
     * @fontWeight 500
     */
    "h3-medium": "h3-medium",
    //___________
    /**
     * @fontSize 18px
     * @fontWeight 700
     */
    "h3-bold": "h3-bold",
    //___________
    /**
     * @fontSize 18px
     * @fontWeight 400
     */
    "h3-italic": "h3-italic",
    //___________
    /**
     * @fontSize 18px
     * @fontWeight 400
     */
    "h3-underlined": "h3-underlined",
    //___________
    /**
     * @fontSize 18px
     * @fontWeight 700
     */
    "h3-bold-underlined": "h3-bold-underlined",
    //___________
    /**
     * @fontSize 20px
     * @fontWeight 400
     */
    "h2-regular": "h2-regular",
    //___________
    /**
     * @fontSize 20px
     * @fontWeight 500
     */
    "h2-medium": "h2-medium",
    //___________
    /**
     * @fontSize 20px
     * @fontWeight 700
     */
    "h2-bold": "h2-bold",
    //___________
    /**
     * @fontSize 20px
     * @fontWeight 400
     */
    "h2-italic": "h2-italic",
    //___________
    /**
     * @fontSize 20px
     * @fontWeight 400
     */
    "h2-underlined": "h2-underlined",
    //___________
    /**
     * @fontSize 20px
     * @fontWeight 700
     */
    "h2-bold-underlined": "h2-bold-underlined",
    //___________
    /**
     * @fontSize 24px
     * @fontWeight 400
     */
    "h1-regular": "h1-regular",
    //___________
    /**
     * @fontSize 24px
     * @fontWeight 500
     */
    "h1-medium": "h1-medium",
    //___________
    /**
     * @fontSize 24px
     * @fontWeight 700
     */
    "h1-bold": "h1-bold",
    //___________
    /**
     * @fontSize 24px
     * @fontWeight 400
     */
    "h1-italic": "h1-italic",
    //___________
    /**
     * @fontSize 24px
     * @fontWeight 400
     */
    "h1-underlined": "h1-underlined",
    //___________
    /**
     * @fontSize 24px
     * @fontWeight 700
     */
    "h1-bold-underlined": "h1-bold-underlined",
    //___________
    /**
     * @fontSize 32px
     * @fontWeight 400
     */
    "d1-regular": "h1-regular",
    //___________
    /**
     * @fontSize 32px
     * @fontWeight 500
     */
    "d1-medium": "d1-medium",
    //___________
    /**
     * @fontSize 32px
     * @fontWeight 700
     */
    "d1-bold": "d1-bold",
    //___________
    /**
     * @fontSize 32px
     * @fontWeight 400
     */
    "d1-italic": "d1-italic",
    //___________
    /**
     * @fontSize 32px
     * @fontWeight 400
     */
    "d1-underlined": "d1-underlined",
    //___________
    /**
     * @fontSize 32px
     * @fontWeight 700
     */
    "d1-bold-underlined": "d1-bold-underlined",
    //___________
  },
  colors: {
    /*
    Figma https://www.figma.com/file/XBzTWl8VKGqsHTIg4OtGw6/%5BAdmin-Portal%5D-UI-Components-Kit?node-id=1%3A2&mode=dev
    - If add more colors in this file, please also add 
    go-food-beverage\src\stylesheets\variable.scss
    */

    /* Color from Figma */
    default: {
      black: "#2F2D39",
      white: "#fff",
    },
    primary: {
      200: "#40367D",
      100: "#50429B",
      90: "#5A4BAF",
      80: "#6E5DC8",
      70: "#7461DB",
      60: "#8574E7",
      50: "#9B8CF2",
      40: "#B9AEFA;",
      30: "#E1DBFF",
      20: "#F5F5FF",

      main: "#50429B",
      light: "#F7F5FF",
    },
    secondary: {
      200: "#833E00",
      100: "#AF5300",
      90: "#DA6800",
      80: "#FF7D07",
      70: "#FF8C24",
      60: "#FFA350",
      50: "#FFBA7C",
      40: "#FFD1A7",
      30: "#FFE4CC",
      20: "#FFFAF5",

      main: "#A5ABDE",
    },
    success: {
      200: "#024C1B",
      100: "#036523",
      90: "#047E2C",
      80: "#059735",
      70: "#05A83B",
      60: "#06C946",
      50: "#1AF864",
      40: "#5BFA90",
      30: "#9DFCBC",
      20: "#DEFEE9",
    },
    warning: {
      200: "#A4610E",
      100: "#B4720E",
      90: "#D08B0B",
      80: "#E2A108",
      70: "#F7B801",
      60: "#FEC92E",
      50: "#FED662",
      40: "#FFE18A",
      30: "#FFEBB1",
      20: "#FFF5D8",

      main: "#ffc107",
      toast: "#EC9FA6",
      toastText: "#D52D2D",
    },
    critical: {
      200: "#580B0B",
      100: "#820014",
      90: "#780F0F",
      80: "#BA1717",
      70: "#DB1B1B",
      60: "#E73737",
      50: "#EB5A5A",
      40: "#f18989",
      30: "#f6b8b8",
      20: "#FCE7E7",
    },
    gray: {
      200: "#45434F",
      100: "#585664",
      90: "#6B6879",
      80: "#767387",
      70: "#8B8899",
      60: "#A5A3B0",
      50: "#B8B6C1",
      40: "#CBCAD2",
      30: "#DFDEE3",
      20: "#F2F2F4 ",
    },
    /* End Color from Figma */


    error: {
      main: "#D52D3E",
      backgound: "#FBEAEC",
      border: "#EC9FA6",
      bland: "#FBEAEC",
    },

    text: {
      main: "#282828",
      light: "rgba(18,18,18,.5)",
      placeholder: "#9F9F9F",
      default: "#848484",
      sub: "#737373",
      davyGrey: "#575757",
      vampireGrey: "#525252",
      title: "#121212",
      slateBlue: "#50429B",
      white: "#FFFFFF",
      neonCarrot: "#FF912D",
      carrotOrange: "#FF8C21",
      yellowOrange: "#F9B403",
      limeGreen: "#33B530",
      darkPastelGreen: "#4EAD4E",
      jade: "#1CA362",
      greenWhite: "#DCEFDC",
      curiousBlue: "#428BC1",
      lightGrey: "#CCCCCC",
      timberwolf: "#D9D9D9",
      sun: "#FF8B3D",
      description: "#6A6A6A",
      blueberry: "#50429B",
      orange: "#FF912D",
      redOrange: "#FD2E2E",
      error: "#D52D3E",
      success: "#299C44",
      persianRed: "#D52D3E",
      cloverGreen: "#299C44",
      richBlue: "#5645AC",
      moodyBlue: "#9083CE",
      primaryAda: "#ADA4DB",
      blueLavender: "#E4E0FF",
      subTitle: "#8A8A8A",
      blueMarguerite: "#6F5FBF",
      tangerine: "#FF7900",
      blueMagenta: "#817CBA",
      darkSlateBlue: "#433686",
      violentViolet: "#2B2162",
      blueOrchid: "#3051FF",
      ferrariRed: "#FC0D1B",
      neutralBlack: "#282828",
      translucentGreen: "#33B530",
      translucentOrange: "#FF8C21",
      neonBlue: "#428BC1",
      tomatoRed: "#DB1B1B",
    },
    confirm: {
      main: "#FF8C21",
      white: "#FFFFFF",
    },
    border: {
      main: "#817CBA",
      white: "#FFFFFF",
      error: "#EC9FA6",
      success: "#299C44",
      pinkRose: "#EC9FA6",
      shamrockGreen: "#05A83B",
    },
    table: {
      border: "#817CBA",
      headerBackground: "rgba(222, 216, 249, 0.8)",
      borderRow: "#FFF",
    },
    info: {
      main: "#0692E1",
      veryLightGrey: "#CDCDCD",
    },
    common: {
      white: "#FFFFFF",
      whiteRGBA: "rgba(255, 255, 255, 0)",
      black: "#000000",
      transparent: "transparent",
      lavender: "#EBE8FE",
      harleyDavidsonOrange: "#DB1B1B",
      harleyDavidsonOrangeRGBA: "rgba(219, 27, 27, 0.10)",
    },
    background: {
      white: "#FFF",
      orange: "#FF912D",
      sandyBeach: "#FFE8D2",
      dairyCream: "#FFDFC2",
      vistaWhite: "#FFF7F7",
      lightPink: "#FFF6ED",
      iceBerg: "#E4EFF6",
      oldLace: "#FFF2E5",
      bianca: "#FFFAF5",
      overlayColor: "#00000099",
      productList: "#F5F2FF",
      productListPaging: "#E7E1FF",
      linkWater: "#E6E0FC",
      mercury: "#E6E6E6",
      blueMagenta: "#F1EEFF",
      slateBlue: "#50429B",
      fog: "#DBD6FF",
      purple: "#ECE8FE",
      main: "#F7F5FF",
      green: "#12B76A",
      offGreen: "#E7F9E7",
      snowDrift: "#F4FBF8",
      whiteOpacity078: "rgba(255, 255, 255, 0.78)",
      whiteRgba095: "rgba(255, 255, 255, 0.95)",
      lavender: "#ECE8FF",
      tutuRgba08: "rgba(255, 236, 236, 0.8)",
      coralRedRgba02: "rgba(248, 58, 58, 0.2)",
      neroRgba012: "rgba(40, 40, 40, 0.12)",
      whiteRGBA: "rgba(255, 255, 255, 0.3)",
      whiteBackgroundColorFee: "rgba(255, 255, 255, 0)",
      whisper: "#E4E4E4",
      neonCarrot: "#FF912D",
      limeGreen: "#33b5301a",
      payment: "#F1F0F2",
      mistyRose: "#FFF4E9",
      magnolia: "#F2EFFF",
      magnoliaRgba078: "rgba(247, 245, 255, 0.78)",
      whiteSmoke: "#F5F5F5",
      lightPurple: "#FBEAEC",
      lightGreen: "#EFFBF2",
      amour: "#FBEAEC",
      aquaSqueeze: "#E6F6EB",
      whiteIce: "#E6F4EF",
      titanWhite: "#F3F0FF",
      paleLavender: "#DBD6F9",
      tagRGBA: "rgba(80, 66, 155, 1)",
      richBlue: "#5645AC",
      aliceBlue: "#F0EEFF",
      popoverBackgroundBlue: "#31285F",
      blueChalk: "#F0EDFF",
      greyish: "#D9D9D9",
      mintGreen: "#E7F9E7",
      lightOrange: "#FFF4EB",
      lightNeonBlue: "#E4EFF6",
      lightRed: "#FDEDED",
    },
    button: {
      main: "#FF8C21",
      disable: "#FFD1A6",
      mainRadian: "rgba(255, 255, 255, 0.78)",
      oldLace: "#FFF3E8",
    },
    shadowColor: "#000",
    scrollView: "#CDC6FF",
    textField: {
      text: "#9F9F9F",
      border: "#9F9F9F",
      disabled: "#F9FAFB",
      background: "#FFF",
      shadowColor: "#D6BBFB",
      shadowColorError: "#FDA29B",
    },
    drawer: {
      first: "rgba(0,0,0,.5)",
      mid: "rgba(0,0,0,.2)",
      last: "rgba(0,0,0,0)",
    },
    mainMenu: {
      background: "#FFF",
    },
    price: {
      main: "#282828",
      salePrice: "#FF8C21",
      listedPrice: "#9F9F9F",
    },
    dropdown: {
      borderColor: "#D0D5DD",
      outlineColor: "#D6BBFB",
    },
    barCode: {
      inactiveColor: "#5645AC",
      activeColor: "#FF8C21",
      raysColor: "#FF7900",
      backgroundColor: "#FFFFFF",
    },
  },
  fonts: ["Plus Jakarta Sans"],
  fontSizes: {
    small: "16px",
    medium: "24px",
    large: "32px",
  },
  screen: {
    /**
     * @breakpoint 480px
     */
    xs: "480px",
    /**
     * @breakpoint 576px
     */
    sm: "576px",
    /**
     * @breakpoint 768px
     */
    md: "768px",
    /**
     * @breakpoint 992px
     */
    lg: "992px",
    /**
     * @breakpoint 1200px
     */
    xl: "1200px",
    /**
     * @breakpoint 1600px
     */
    xxl: "1600px",
  },
};

export default theme;
