import { Card, Col, Form, Image, Row, message } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { tableSettings } from "constants/default.constants";
import { images } from "constants/images.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import { default as blogDataService } from "data-services/blog/blog-data.service";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatDate, hasPermission } from "utils/helpers";
import "../blog-management-list/blog-management-list.page.scss";
import FilterBlogPopover from "./filter-popover.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

export const TableBlog = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    action: t("table.action"),
    filter: t("button.filter"),
    blogs: t("blog.blogs"),
    category: t("blog.category"),
    tag: t("blog.tag"),
    select_tag: t("blog.select_tag"),
    creator: t("blog.creator"),
    author: t("blog.author"),
    reset_all_filters: t("blog.reset_all_filters"),
    search_by_blog_title: t("blog.search_by_blog_title"),
    no: t("table.no"),
    title: t("blog.title"),
    edit: t("blog.edit"),
    delete: t("blog.delete"),
    latest_update_time: t("blog.latest_update_time"),
    thumbnail: t("blog.thumbnail"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    blogConfirmDeleteMessage: t("messages.blogConfirmDeleteMessage"),
    blogDeletedSuccess: t("messages.blogDeletedSuccess"),
    blogDeletedFailed: t("messages.blogDeletedFailed"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  const [totalBlog, setTotalBlog] = useState(0);
  const [listBlog, setListBlog] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [exportFilter, setExportFilter] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [dataFilter, setDataFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const filterPopoverRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [titleBlog, setTitleBlog] = useState("");
  const [blogId, setBlogId] = useState();

  useEffect(() => {
    initDataTableBlogs(tableSettings.page, tableSettings.pageSize, keySearch);
  }, []);

  const initDataTableBlogs = (pageNumber, pageSize, keySearch) => {
    /// get list blogs
    setIsLoading(true);
    blogDataService.getBlogManagementsAsync(pageNumber, pageSize, keySearch).then((res) => {
      let blogs = mappingToDataTableBlogs(res.blogs);
      setListBlog(blogs);
      setTotalBlog(res.total);
      setCurrentPageNumber(pageNumber);
      setIsLoading(false);
    });
  };

  const onChangePage = (page, pageSize) => {
    initDataTableBlogs(page, pageSize, "");
  };

  const mappingToDataTableBlogs = (blogs) => {
    return blogs?.map((i, index) => {
      return {
        ...i,
        index: index + 1,
        id: i.id,
        title: i.title,
        bannerImageUrl: i.bannerImageUrl,
        blogCategory: i.blogCategory,
        creator: i.creator,
        date: i?.lastSavedTime,
        time: i?.lastSavedTime,
      };
    });
  };

  // Insert the name into the message
  const formatConfirmDeleteMessage = (textContent, textReplace) => {
    let mess = t(textContent, { blogName: textReplace });
    return mess;
  };

  const onEditItem = async (id) => {
    history.push(`/online-store/blog-management/edit-blog/${id}`);
  };

  const onDeleteItem = (_selectedRowKeys, selectedRows) => {
    const { id: blogId, title: blogName } = selectedRows?.[0];
    setTitleBlog(blogName);
    setBlogId(blogId);
    setShowModalDelete(true);
  };

  const onDeleteBlogManagement = async (id) => {
    setShowModalDelete(false);
    if (id) {
      var res = await blogDataService.deleteBlogByIdAsync(id);
      if (res) {
        message.success(formatConfirmDeleteMessage(pageData.blogDeletedSuccess, titleBlog));
        onChangePage(1, tableSettings.pageSize);
      } else {
        message.error(formatConfirmDeleteMessage(pageData.blogDeletedFailed, titleBlog));
      }
    } else {
      message.error(formatConfirmDeleteMessage(pageData.blogDeletedFailed, titleBlog));
    }
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.no,
        dataIndex: "index",
        width: "5rem",
        render: (val) => val + 1,
      },
      {
        title: pageData.thumbnail,
        dataIndex: "bannerImageUrl",
        width: "14%",
        render: (value) => {
          return (
            <div className="boxImage">
              <Image
                preview={false}
                className="thumbnail"
                width={160}
                height={110}
                src={value ?? "error"}
                fallback={images.defaultImageBlog}
              />
            </div>
          );
        },
      },
      {
        title: pageData.title,
        dataIndex: "title",
        width: "45%",
        ellipsis: true,
        render: (_, record) => {
          return (
            <div>
              <FnbTooltip placement="top" title={record.title.replace(/<.*?>/gm, "")} onlyShowWhenEllipsis>
                <FnbTypography text={record.title.replace(/<.*?>/gm, "")} variant={theme.typography["b1-bold"]} />
              </FnbTooltip>
              <div className="boxContent">
                <span
                  className="contentBlog"
                  style={{
                    maxHeight: "100px",
                    overflow: "hidden",
                  }}
                  dangerouslySetInnerHTML={{ __html: record.description }}
                />
              </div>
            </div>
          );
        },
      },
      {
        title: pageData.category,
        dataIndex: "blogCategory",
        width: "10%",
        render: (value) => {
          return (
            <div>
              {value !== "" ? (
                <FnbTooltip placement="top" title={value} onlyShowWhenEllipsis>
                  <FnbTypography text={value} />
                </FnbTooltip>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        title: pageData.author,
        dataIndex: "creator",
        width: "10%",
        render: (value) => {
          return <div>{value}</div>;
        },
      },
      {
        title: pageData.latest_update_time,
        dataIndex: "lastSavedTime",
        width: "15%",
        render: (_, record) => {
          return (
            <div className="lastSavedTime">
              <span>{formatDate(record?.time, DateFormat.HH_MM)}</span>
              <span className="lastSavedTimeDate">{formatDate(record?.date, DateFormat.DD_MMM_YYYY)}</span>
            </div>
          );
        },
      },
    ].filter((item) => !item.hidden);
    return columns;
  };

  const onSearch = (keySearch) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setKeySearch(keySearch);
        searchKeyAndFilterBlogs(tableSettings.page, tableSettings.pageSize, keySearch, exportFilter);
      }, 500),
    );
  };

  const onClickFilterButton = async (event) => {
    const dataFilter = await blogDataService.getBlogFilterAsync();
    setDataFilter(dataFilter);
  };

  const searchKeyAndFilterBlogs = (pageNumber, pageSize, keySearch, filter) => {
    blogDataService
      .getBlogManagementsAsync(pageNumber, pageSize, keySearch, filter?.categoryId || "", filter?.creatorId || "")
      .then((res) => {
        let blogs = mappingToDataTableBlogs(res.blogs);
        setListBlog(blogs);
        setTotalBlog(res.total);
      });
  };

  const handleFilterBlog = (data) => {
    setExportFilter(data);
    blogDataService
      .getBlogManagementsAsync(tableSettings.page, tableSettings.pageSize, keySearch, data?.categoryId, data?.creatorId)
      .then((res) => {
        let blogs = mappingToDataTableBlogs(res.blogs);
        setListBlog(blogs);
        setTotalBlog(res.total);
        setCountFilter(Object.values(data).filter((e) => e !== "").length);
      });
  };

  const onClearFilter = () => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
    setCountFilter(0);
  };

  const filterComponent = () => {
    return dataFilter && dataFilter.blogAuthors && dataFilter.blogCatetories ? (
      <FilterBlogPopover
        ref={filterPopoverRef}
        dataFilter={dataFilter}
        onChangeFilter={(data) => {
          handleFilterBlog(data);
        }}
      />
    ) : null;
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <>
      <Form className="blog-management-list">
        <Card className="w-100 fnb-card-custom">
          <Row className="total-cost-amount-row">
            <Col span={24}>
              <FnbTable
                className="table-striped-rows blogTable"
                columns={getColumns()}
                pageSize={tableSettings.pageSize}
                dataSource={listBlog}
                currentPageNumber={currentPageNumber}
                total={totalBlog}
                onChangePage={onChangePage}
                search={{
                  placeholder: pageData.search_by_blog_title,
                  onChange: onSearch,
                  maxLength: 100,
                }}
                filter={{
                  onClickFilterButton: onClickFilterButton,
                  totalFilterSelected: countFilter,
                  onClearFilter: onClearFilter,
                  buttonTitle: pageData.filter,
                  component: filterComponent(),
                }}
                rowKey={"id"}
                loading={isLoading}
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectedRowKeysChange,
                  onEdit: onEditItem,
                  onDelete: onDeleteItem,
                }}
                editPermission={PermissionKeys.EDIT_BLOG}
                deletePermission={PermissionKeys.DELETE_BLOG}
                emptyText={
                  hasPermission(PermissionKeys.CREATE_BLOG) && (
                    <div className="content-collapse-empty-text">
                      <FnbTypography.Link
                        variant={theme.typography["b1-bold-underlined"]}
                        underline={true}
                        text={pageData.addNew}
                        onClick={() => history.push("/online-store/blog-management/create-blog")}
                      />
                      {pageData.toCreateData}
                    </div>
                  )
                }
              />
            </Col>
            <ConfirmDialogComponent
              title={pageData.confirmDelete}
              visible={showModalDelete}
              okText={pageData.btnDelete}
              cancelText={pageData.btnIgnore}
              onCancel={() => setShowModalDelete(false)}
              type={ConfirmStyle.DELETE}
              onOk={() => onDeleteBlogManagement(blogId)}
              content={formatConfirmDeleteMessage(pageData.blogConfirmDeleteMessage, titleBlog)}
            />
          </Row>
        </Card>
      </Form>
    </>
  );
};
