import FnbTypography from "components/fnb-typography/fnb-typography";
import {
  Number1DotStyleIcon,
  Number2DotStyleIcon,
  Number3DotStyleIcon,
  Number4DotStyleIcon,
  Number5DotStyleIcon,
  Number6DotStyleIcon,
  Number7DotStyleIcon,
  TickedStepIcon,
} from "constants/icons.constants";
import theme from "theme";
import {
  CircleStep,
  ItemStepGuidelineStyle,
  WrapperIconTick,
  WrapperStepNumberIcon,
} from "./item-step-guideline.styled";

const stepIconMapping = {
  1: Number1DotStyleIcon,
  2: Number2DotStyleIcon,
  3: Number3DotStyleIcon,
  4: Number4DotStyleIcon,
  5: Number5DotStyleIcon,
  6: Number6DotStyleIcon,
  7: Number7DotStyleIcon,
};

const ItemStepGuideline = ({ keyItem, isFinish = false, index = 0, title, isSelected = false, onClick }) => {
  const StepNumberIcon = stepIconMapping[index + 1] ?? <></>;

  const onClickComponent = () => {
    onClick && onClick(keyItem);
  };

  return (
    <ItemStepGuidelineStyle isSelected={isSelected} onClick={onClickComponent}>
      <WrapperIconTick>{isFinish ? <TickedStepIcon /> : <CircleStep />}</WrapperIconTick>
      <WrapperStepNumberIcon isFinish={isFinish}>
        <StepNumberIcon />
      </WrapperStepNumberIcon>
      <FnbTypography
        variant={isFinish ? theme.typography["b1-bold"] : theme.typography["b1-medium"]}
        text={title}
        color={theme.colors.primary.main}
      />
    </ItemStepGuidelineStyle>
  );
};

export default ItemStepGuideline;
