import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { PurchaseOrderStatus } from "constants/purchase-order-status.constants";
import React from "react";
import { useTranslation } from "react-i18next";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { branches, supplierFilter } = props;
  const [form] = Form.useForm();
  const DEFAULT_VALUE = "";

  const allBranchOption = {
    id: "",
    name: t("material.filter.branch.all"),
  };

  const allSuppliers = {
    id: "",
    name: t("purchaseOrder.allSupplier"),
  };

  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      status: {
        title: t("material.filter.status.title"),
        all: t("material.filter.status.all"),
        new: t("purchaseOrder.status.new"),
        approved: t("purchaseOrder.status.approved"),
        ordering: t("purchaseOrder.status.ordering"),
        canceled: t("purchaseOrder.status.canceled"),
        completed: t("purchaseOrder.status.completed"),
      },
      startDate: t("promotion.form.startDate"),
      endDate: t("promotion.form.endDate"),
    },
  };

  React.useImperativeHandle(ref, () => ({
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    form.resetFields();
    props.fetchDataPurchaseOrder({});
  };

  const handleOnValueChange = (changedValues, allValues) => {
    props.fetchDataPurchaseOrder(allValues);
  };

  return (
    <Form layout="vertical" onValuesChange={handleOnValueChange} form={form}>
      <Form.Item label={t("purchaseOrder.branch")} name="branchId">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={[allBranchOption, ...branches]}
        />
      </Form.Item>
      <Form.Item label={t("supplier.title")} name="supplierId">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={[allSuppliers, ...supplierFilter]}
        />
      </Form.Item>
      <Form.Item label={t("table.status")} name="statusId">
        <Radio.Group defaultValue={DEFAULT_VALUE} buttonStyle="solid">
          <FnbRadioButton value={DEFAULT_VALUE}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.New}>{pageData.filter.status.new}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Approved}>{pageData.filter.status.approved}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Ordering}>{pageData.filter.status.ordering}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Completed}>{pageData.filter.status.completed}</FnbRadioButton>
          <FnbRadioButton value={PurchaseOrderStatus.Canceled}>{pageData.filter.status.canceled}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
