import { DepositStatus } from "constants/deposit-status.contants";
import { useTranslation } from "react-i18next";
import "./BadgeDepositStatus.scss";

/**
 * Badge Status
 * @param {bool} status - Status of the badge
 * @returns Active or Inactive label
 */
export function BadgeDepositStatus(props) {
  const [t] = useTranslation();
  const { status = -1 } = props;

  const pageData = {
    notReceived: t("reservation.notReceived"),
    received: t("reservation.received"),
    waitToRefund: t("reservation.waitToRefund"),
    refunded: t("reservation.refunded"),
    noRefund: t("reservation.noRefund"),
  };
  
  const renderStatus = () => {
    switch (status) {
      case DepositStatus.NotReceived:
        return (
          <span className="badge-status not-received">
            <span> {pageData.notReceived}</span>
          </span>
        );
      case DepositStatus.Received:
        return (
          <span className="badge-status received">
            <span> {pageData.received}</span>
          </span>
        );
      case DepositStatus.WaitToRefund:
        return (
          <span className="badge-status wait-to-refund">
            <span> {pageData.waitToRefund}</span>
          </span>
        );
      case DepositStatus.Refunded:
        return (
          <span className="badge-status refunded">
            <span> {pageData.refunded}</span>
          </span>
        );
      case DepositStatus.NoRefund:
        return (
          <span className="badge-status no-Refund">
            <span> {pageData.noRefund}</span>
          </span>
        );
      default:
        return (
          <>
            <span className="badge-status not-received">
              <span> {pageData.notReceived}</span>
            </span>
          </>
        );
    }
  };

  return <>{renderStatus()}</>;
}
