import branchDataService from "data-services/branch/branch-data.service";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TableManagement from "./components/list-table.component";
import { PermissionKeys } from "constants/permission-key.constants";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbCollapse from "components/fnb-collapse/fnb-collapse";
import areaDataService from "data-services/area/area-data.service";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import AddNewAreaTable from "./components/create-new-table.component";
import areaTableDataService from "data-services/area-table/area-table-data.service";
import { FnbButton } from "components/fnb-button/fnb-button";
import { EditAreaComponent } from "./components/edit-area.component";
import { ViewDetailComponent } from "./components/view-detail.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { message } from "antd";
import AddNewArea from "./components/create-new-area.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { hasPermission } from "utils/helpers";
import { useMediaQuery } from "react-responsive";
import "./area-table.page.scss";

export default function AreaTableManagement(props) {
  const [t] = useTranslation();
  const tableAreaTableFunc = React.useRef(null);
  const [listItems, setListItems] = useState([]);
  const [headerCollapse, setHeaderCollapse] = useState();
  const tableManagementRef = React.useRef(null);
  const [getStoreBranchId, setGetStoreBranchId] = useState(null);
  const [showAddAreaTable, setShowAddAreaTable] = useState(false);
  const [listArea, setListArea] = useState([]);
  const [activeKey, setActiveKey] = useState();
  const [areaId, setAreaId] = useState();
  const [showEditArea, setShowEditArea] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showViewArea, setShowViewArea] = useState(false);
  const [dataViewArea, setDataViewArea] = useState(null);
  const [showAddArea, setShowAddArea] = useState(false);
  const editAreaRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [isChangedCollapse, setIsChangedCollapse] = useState(false);

  const pageData = {
    area: t("area.area"),
    areaManagement: t("area.title"),
    areaTable: t("areaTable.table"),
    areaTableManagement: t("areaTable.title"),
    areaTableNoBranch: t("area.areaTableNoBranch"),
    titleAddNewTable: t("areaTable.tableForm.titleAddNewTable"),
    editCategory: t("area.editCategory"),
    viewCategory: t("area.viewCategory"),
    deleteCategory: t("area.deleteCategory"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    btnDelete: t("button.yesDelete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    areaDeleteSuccess: t("area.areaDeleteSuccess"),
    areaDeleteFail: t("area.areaDeleteFail"),
    titleViewDetail: t("area.titleViewDetail"),
    notFoundData: t("messages.cannotFindAreaInfo"),
    titleViewDescription: t("area.titleViewDescription"),
    addNewArea: t("area.addNewArea"),
    or: t("area.or"),
    toCreateData: t("area.toCreateData"),
    areaTables: t("areaTable.tables"),
    confirmDeleteMessageNew: t("area.confirmDeleteMessage"),
  };

  const listButtons = [
    <FnbListBranches
      onChangeEvent={(branchId) => onChangeBranch(branchId)}
      showAllBranch={false}
      initSelectedBranchId={getStoreBranchId}
    />,
    <FnbButton
      variant="secondary"
      permission={PermissionKeys.CREATE_AREA_TABLE}
      onClick={() => setShowAddArea(true)}
      text={pageData.addNewArea}
    />,
    <FnbButton
      permission={PermissionKeys.CREATE_AREA_TABLE}
      onClick={() => showAddAreaTableForm()}
      text={pageData.titleAddNewTable}
    />,
  ];

  useEffect(() => {
    getInitDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeKey !== areaId) {
      getDataTableAreaManagement(getStoreBranchId, true, activeKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  useEffect(() => {
    if (isChangedCollapse) {
      getDataTableAreaManagement(getStoreBranchId, true, activeKey);
      setIsChangedCollapse(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedCollapse]);

  useEffect(() => {
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = () => {
    const element = document.querySelector(".action-group");
    const tableActions = document.querySelector(".table-actions-group-row");
    if (element && tableActions) {
      if (window.scrollY >= 60) {
        element.style.display = "flex";
        tableActions.style.boxShadow = "0 2.5px 2px -2px rgba(0, 0, 0, .1)";
      } else {
        element.style.display = "none";
        tableActions.style.boxShadow = "none";
      }
    }
  };

  const getInitDataAsync = async () => {
    await branchDataService.getAllBranchsAsync().then((res) => {
      if (res.branchs.length > 0) {
        setGetStoreBranchId(res?.branchs[0]?.id);
        initDataTableAreaManagement(res?.branchs[0]?.id);
      }
    });
  };

  const initDataTableAreaManagement = async (storeBranchId) => {
    if (storeBranchId) {
      let res = await areaDataService.getAreasByBranchIdAsync(storeBranchId);
      if (res?.areas) {
        let areas = mappingToDataTableAreaManagements(res?.areas, storeBranchId);
        setListItems(areas);
        setListArea(res?.areas);
        setAreaId(res?.areas[0]?.id);
        setActiveKey(res?.areas[0]?.id);
        const element = document.querySelector(".action-group");
        if (element) {
          element.style.display = "none";
        }
      }
    }
  };

  const getDataTableAreaManagement = async (storeBranchId, setKey = false, activeKey) => {
    if (storeBranchId) {
      let res = await areaDataService.getAreasByBranchIdAsync(storeBranchId);
      if (res?.areas) {
        let areas = mappingToDataTableAreaManagements(res?.areas, storeBranchId);
        setListItems();
        setListItems(areas);
        setListArea(res?.areas);
        if (setKey) {
          const key = activeKey || res?.areas[0]?.id;
          setAreaId(key);
          setActiveKey(key);
          const element = document.querySelector(".action-group");
          if (element) {
            element.style.display = "none";
          }
          setTimeout(() => {
            let paddingTop = 77;
            const elementKey = document.getElementById(key);
            window.scrollTo({
              top: elementKey?.offsetTop - paddingTop,
              behavior: "smooth",
            });
          }, 500);
        }
      }
    }
  };

  const showAddAreaTableForm = async () => {
    setShowAddAreaTable(true);
  };

  const mappingToDataTableAreaManagements = (areaManagements, storeBranchId) => {
    if (areaManagements?.length > 0) {
      return areaManagements?.map((i, index) => {
        return {
          key: i?.id,
          label: i?.name,
          isActive: i?.isActive,
          children: renderTableManagement(i?.id, storeBranchId, areaManagements),
          total: i?.areaTables?.length ?? 0,
        };
      });
    }
    return [];
  };

  const renderTableManagement = (areaId, storeBranchId, areaManagements) => {
    const listButtonsTable = [
      <FnbButton
        permission={PermissionKeys.CREATE_AREA_TABLE}
        onClick={() => showAddAreaTableForm()}
        text={pageData.titleAddNewTable}
      />,
      <FnbButton
        variant="secondary-purple"
        permission={PermissionKeys.CREATE_AREA_TABLE}
        onClick={() => setShowAddArea(true)}
        text={pageData.addNewArea}
      />,
      !isMobile && (
        <FnbListBranches
          onChangeEvent={(branchId) => onChangeBranch(branchId)}
          showAllBranch={false}
          initSelectedBranchId={storeBranchId}
          className="list-branch-table"
        />
      ),
    ];

    return (
      <TableManagement
        areaId={areaId}
        storeBranchId={storeBranchId}
        setGetStoreBranchId={setGetStoreBranchId}
        tableFuncs={tableAreaTableFunc}
        setHeaderCollapse={setHeaderCollapse}
        ref={tableManagementRef}
        listArea={areaManagements}
        setActiveKey={setActiveKey}
        listButtons={listButtonsTable}
        setIsChangedCollapse={setIsChangedCollapse}
        setShowAddAreaTable={setShowAddAreaTable}
      />
    );
  };

  const onChangeCollapse = (key) => {
    setAreaId(key);
    setActiveKey(key);
    tableManagementRef?.current?.resetData();
    if (key) {
      setTimeout(() => {
        let paddingTop = 77;
        const element = document.getElementById(key);
        window.scrollTo({
          top: element?.offsetTop - paddingTop,
          behavior: "smooth",
        });
      }, 500);
    }
  };
  const onCancelAddNewAreaTable = () => {
    setShowAddAreaTable(false);
  };
  const onOkAddNewAreaTable = (areaId) => {
    setShowAddAreaTable(false);
    getDataTableAreaManagement(getStoreBranchId, true, areaId);
  };
  const onViewArea = (event) => {
    onHandleViewArea(areaId);
    event.stopPropagation();
  };

  const onEditArea = (event) => {
    editAreaRef.current.initData(areaId);
    setShowEditArea(true);
    event.stopPropagation();
  };

  const onDeleteArea = (event) => {
    setShowConfirmDeleteModal(true);
    event.stopPropagation();
  };

  const onCancelEditArea = () => {
    setShowEditArea(false);
  };

  const onHandleDelete = async () => {
    const area = listArea?.filter((item) => item?.id === areaId);
    const id = area[0]?.id;
    setShowEditArea(false);
    const res = await areaDataService.deleteAreaByIdAsync(id);
    if (res) {
      message.success(pageData.areaDeleteSuccess);
      setShowConfirmDeleteModal(false);
      getDataTableAreaManagement(getStoreBranchId, true, null);
    } else {
      message.error(pageData.areaDeleteFail);
    }
  };

  const onHandleOk = () => {
    getDataTableAreaManagement(getStoreBranchId, true, areaId);
    setShowEditArea(false);
  };

  const onHandleViewArea = async (id) => {
    setDataViewArea(null);
    let request = {
      id: id,
      storeBranchId: getStoreBranchId,
    };
    const res = await areaDataService.getAreaByIdAsync(request);
    if (res) {
      const { area } = res;
      const data = {
        title: pageData.titleViewDetail,
        name: area?.name,
        status: area?.isActive,
        statusName: area?.isActive ? pageData.active : pageData.inactive,
        imageUrl: area?.imageUrl,
        description: area?.description,
        parentName: "",
      };

      setShowViewArea(true);
      setDataViewArea(data);
    } else {
      message.error(pageData.notFoundData);
    }
  };

  const formatDeleteMessage = () => {
    const area = listArea?.filter((item) => item?.id === areaId);
    const areaName = area[0]?.name || "";
    const totalTable = area[0]?.areaTables?.length || 0;
    let mess = "";
    if (totalTable > 0) mess = t(pageData.confirmDeleteMessageNew, { areaName: areaName, totalTable: totalTable });
    else mess = t(pageData.confirmDeleteMessage, { name: areaName });
    return mess;
  };

  const onChangeBranch = (branchId) => {
    setGetStoreBranchId(branchId);
    getDataTableAreaManagement(branchId, true);
  };

  const onCancelAddNewArea = () => {
    setShowAddArea(false);
  };

  const onOkAddNewArea = () => {
    setShowAddArea(false);
  };

  return (
    <Fragment>
      <FnbHeadingPage
        title={pageData.areaManagement}
        isCompactButtons={false}
        listButtons={listButtons}
        className="heading-table-area"
      />
      <FnbCollapse
        listItems={listItems}
        activeKey={activeKey}
        onChange={onChangeCollapse}
        header={headerCollapse}
        emptyText={
          hasPermission(PermissionKeys.CREATE_AREA_TABLE) && (
            <>
              <FnbTypography.Link
                variant={theme.typography["b1-bold-underlined"]}
                underline={true}
                text={pageData.addNewArea}
                onClick={() => setShowAddArea(true)}
              />
              {` ${pageData.or} `}
              <FnbTypography.Link
                variant={theme.typography["b1-bold-underlined"]}
                underline={true}
                text={pageData.titleAddNewTable}
                onClick={() => setShowAddAreaTable(true)}
              />
              {pageData.toCreateData}
            </>
          )
        }
        listButtons={[
          <FnbButton
            variant="tertiary"
            permission={PermissionKeys.VIEW_AREA_TABLE}
            text={pageData.viewCategory}
            onClick={(event) => onViewArea(event)}
            minWidth="150px"
          />,
          <FnbButton
            variant="tertiary"
            permission={PermissionKeys.EDIT_AREA_TABLE}
            text={pageData.editCategory}
            onClick={(event) => onEditArea(event)}
            minWidth="150px"
          />,
          <FnbButton
            variant="tertiary"
            danger={true}
            permission={PermissionKeys.DELETE_AREA_TABLE}
            text={pageData.deleteCategory}
            onClick={(event) => onDeleteArea(event)}
            minWidth="150px"
          />,
        ]}
        setKey={setAreaId}
        category={pageData.areaTables}
      />

      <AddNewAreaTable
        areaTableDataService={areaTableDataService}
        isModalVisible={showAddAreaTable}
        listArea={listArea}
        areaId={activeKey}
        onCancel={onCancelAddNewAreaTable}
        onOk={onOkAddNewAreaTable}
        storeBranchId={getStoreBranchId}
      />
      <AddNewArea
        isModalVisible={showAddArea}
        handleCancel={onCancelAddNewArea}
        storeBranchId={getStoreBranchId}
        onOk={onOkAddNewArea}
        setActiveKey={setActiveKey}
      />
      <EditAreaComponent
        isModalVisible={showEditArea}
        handleCancel={onCancelEditArea}
        handleDelete={(id) => {
          onHandleDelete(id);
        }}
        handleOk={() => {
          onHandleOk();
        }}
        storeBranchId={getStoreBranchId}
        ref={editAreaRef}
      />
      <ViewDetailComponent
        isModalVisible={showViewArea}
        dataViewDetail={dataViewArea}
        closeViewDetail={() => setShowViewArea(false)}
        titleDescription={pageData.titleViewDescription}
      />
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage()}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={() => onHandleDelete()}
        dangerOkButton={true}
      />
    </Fragment>
  );
}
