import ForgotPasswordPage from "./forgot-password.page";

const route = {
  key: "app.forgot.password",
  position: 0,
  path: "/forgot-password",
  icon: "fa fa-plus-square",
  name: "Forgot password",
  isMenu: false,
  exact: true,
  auth: false,
  component: ForgotPasswordPage,
  child: [],
};
export default route;
