import React, { useEffect, useState } from "react";
import { FnbTable } from "components/fnb-table/fnb-table";
import "./table-shift-detail.style.scss";
import shiftDataService from "data-services/shift/shift-data.service";
import { useParams } from "react-router-dom";
import theme from "theme";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { Space } from "antd";

export default function TableSellingProduct(props) {
  const { t } = props;
  const params = useParams();
  const PAGESIZE_DEFAULT = 20,
    PAGENUMBER_DEFAULT = 1;

  const pageData = {
    no: t("table.no"),
    product: t("report.shiftDetail.sellingProduct.product"),
    topping: t("report.shiftDetail.sellingProduct.topping"),
    productType: t("report.shiftDetail.sellingProduct.productType", "Product type"),
    quantity: t("report.shiftDetail.sellingProduct.quantity"),
    total: t("report.shiftDetail.sellingProduct.total"),
  };
  const [total, setTotal] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [listSellingProducts, setListSellingProducts] = useState([]);

  const getListSellingProduct = async (pageNumber) => {
    const id = params?.shiftId;
    const resSellingProduct = await shiftDataService.getShiftDetailSellingProductAsync(
      id,
      pageNumber,
      PAGESIZE_DEFAULT,
    );
    if (resSellingProduct) {
      setTotal(resSellingProduct?.total);
      setListSellingProducts(resSellingProduct?.sellingProducts);
      setCurrentPageNumber(pageNumber);
    }
  };

  const onChangePageNumber = (pageNumber) => {
    getListSellingProduct(pageNumber);
  };

  useEffect(() => {
    getListSellingProduct(PAGENUMBER_DEFAULT, PAGESIZE_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnSellingProduct = [
    {
      title: pageData.no,
      dataIndex: "index",
      key: "index",
      width: '5%',
      render: (val) => {
        return <FnbTypography text={val + 1} />;
      },
    },
    {
      title: pageData.product,
      key: "productName",
      align: "left",
      width: '35%',
      render: (_, record) => {
        return (
          <Space direction="vertical" size={8} className="d-flex" style={{alignItems: 'flex-start'}}>
            <FnbTypography variant={theme.typography["b1-bold"]} text={record?.product} />
            {record?.priceName && <FnbTypography color={theme.colors.gray[80]} text={record?.priceName} />}
          </Space>
        );
      },
    },
    {
      title: pageData.productType,
      key: "productType",
      width: '35%',

      render: (_, record) => {
        return <FnbTypography text={record?.isTopping ? pageData.topping : pageData.product} />;
      },
    },
    {
      title: pageData.quantity,
      dataIndex: "quantity",
      key: "quantity",
      width: '25%',

    },
  ];

  return (
    <div style={{ background: theme.colors.default.white, borderRadius: "12px", paddingTop: "12px" }}>
      <FnbTable
        columns={getColumnSellingProduct}
        dataSource={listSellingProducts}
        pageSize={PAGESIZE_DEFAULT}
        total={total}
        currentPageNumber={currentPageNumber}
        onChangePage={onChangePageNumber}
      />
    </div>
  );
}
