import "./store-general-configuration.style.scss";
import { GeneralInformationIcon, MainBankAccountIcon } from "constants/icons.constants";
import { useEffect, useRef, useState } from "react";
import { CardGeneralInfo } from "./components/general-info.component";
import { CardBankAccount } from "./components/bank-account.component";
import { Card } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbButtonCompound from "components/fnb-button-compound/fnb-button-compound";
import theme from "theme";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getBankInfoAsync } from "store/modules/settings/settings.reducer";
const tab = {
  GENERAL: 1,
  BANK_ACCOUNT: 2,
};
export default function StoreGeneralConfiguration(props) {
  const { t, storeDataService, onChangeForm, isChangeFormData, setIsChangeForm } = props;
  const dispatch = useDispatch();
  const generalInfoRef = useRef();
  const bankAccountRef = useRef();
  const operationConfigRef = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const [nextTab, setNextTab] = useState(tab.GENERAL);
  const [isChangeForm, setIsChangeFormData] = useState(false);
  const [currentTab, setCurrentTab] = useState(tab.GENERAL);
  const [isConfirmGeneral, setIsConfirmGeneral] = useState(false);
  const [isConfirmBank, setIsConfirmBank] = useState(false);
  const [generalAddress, setGeneralAddress] = useState({});

  const pageData = {
    general: t("store.configTab.general"),
    bankAccount: t("store.configTab.bankAccount"),
    operation: t("store.configTab.operation"),
    qrCode: t("store.configTab.qrCode"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };
  useEffect(() => {
    async function fetchData() {
      await getInitDataAsync();
    }
    fetchData();
  }, []);

  const getInitDataAsync = async () => {
    let promises = [];
    promises.push(storeDataService.getPrepareAddressDataAsync());
    promises.push(storeDataService.getStoreByIdAsync());

    let [prepareAddressDataResponse, storeDataResponse] = await Promise.all(promises);
    if (storeDataResponse) {
      generalInfoRef?.current?.setInitGeneralInfoData(prepareAddressDataResponse, storeDataResponse);
      operationConfigRef?.current?.setInitOperationData(storeDataResponse?.store);
      setGeneralAddress(storeDataResponse?.store?.address);
      bankAccountRef?.current?.setInitBankAccountData(prepareAddressDataResponse, storeDataResponse);
    }

    dispatch(getBankInfoAsync());
  };

  const onChangeTab = (tab) => {
    if (isChangeFormData) {
      setShowConfirm(true);
      setNextTab(tab);
    } else {
      setCurrentTab(tab);
    }
  };
  const onOk = () => {
    setShowConfirm(false);
    setIsChangeFormData(false);
    setCurrentTab(nextTab);
    setIsChangeForm(false);
    if (currentTab === tab.GENERAL) setIsConfirmGeneral(true);
    else setIsConfirmBank(true);
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  return (
    <div className="store-general-config-wrapper">
      <div className="left-content">
        <div className="tab-general-config-container">
          <Card className="fnb-card w-100 card-general">
            {/* General */}
            <FnbButtonCompound
              isActive={currentTab === tab.GENERAL}
              onClick={() => onChangeTab(tab.GENERAL)}
              title={pageData.general}
              iconHeader={
                <GeneralInformationIcon
                  color={currentTab === tab.GENERAL ? theme.colors.default.white : theme.colors.primary[100]}
                />
              }
              className="w-100"
            />
            <FnbButtonCompound
              isActive={currentTab === tab.BANK_ACCOUNT}
              onClick={() => onChangeTab(tab.BANK_ACCOUNT)}
              title={pageData.bankAccount}
              iconHeader={
                <MainBankAccountIcon
                  color={currentTab === tab.BANK_ACCOUNT ? theme.colors.default.white : theme.colors.primary[100]}
                />
              }
              className="w-100"
            />
          </Card>
        </div>
      </div>
      <div className="right-content">
        <CardGeneralInfo
          ref={generalInfoRef}
          t={t}
          setGeneralAddress={setGeneralAddress}
          className={currentTab === tab.GENERAL ? "d-block" : "d-none"}
          storeDataService={storeDataService}
          onChangeForm={onChangeForm}
          isConfirmOk={isConfirmGeneral}
        />
        <CardBankAccount
          ref={bankAccountRef}
          t={t}
          generalAddress={generalAddress}
          className={currentTab === tab.BANK_ACCOUNT ? "d-block" : "d-none"}
          onChangeForm={onChangeForm}
          isConfirmOk={isConfirmBank}
        />
      </div>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.leaveDialog.discardBtn}
        okText={pageData.leaveDialog.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={onOk}
        isChangeForm={isChangeForm}
      />
    </div>
  );
}
