import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utils/helpers";
import ListFeeComponent from "./components/list-fee.component";
import "./fee-tax.component.scss";
import TaxManagement from "./tax/tax.page";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import { useState } from "react";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

const KEY_TAB_PANE = {
  FEE: "fee",
  TAX: "tax",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.FEE]: 1,
  [KEY_TAB_PANE.TAX]: 2,
};

export function FeeAndTaxManagement(props) {
  const [t] = useTranslation();
  const [activeScreen, setActiveScreen] = useState(KEY_TAB_PANE.FEE);
  const [showModalAddFee, setShowModalAddFee] = useState(false);
  const [showAddTax, setShowAddTax] = useState(false);

  const pageData = {
    titleFeeAndTax: t("feeAndTax.title"),
    feeManagement: t("feeAndTax.feeManagement"),
    taxManagement: t("feeAndTax.tax.taxManagement"),
    fee: {
      title: t("feeAndTax.feeTabTitle"),
      feeManagement: t("feeAndTax.feeManagement"),
    },
    tax: {
      title: t("feeAndTax.tax.taxTabTitle"),
      taxManagement: t("feeAndTax.tax.taxManagement"),
    },
  };

  const renderHeadingPage = (activeKey) => {
    switch (activeKey) {
      case KEY_TAB_PANE.TAX:
        return (
          <FnbHeadingPage
            title={pageData.tax.taxManagement}
            permissionCreate={PermissionKeys.CREATE_TAX}
            onCreate={() => setShowAddTax(true)}
          />
        );
      default:
        return (
          <FnbHeadingPage
            title={pageData.fee.feeManagement}
            permissionCreate={PermissionKeys.CREATE_FEE}
            onCreate={handleAddNewFee}
          />
        );
    }
  };

  const screens = [
    {
      name: pageData.fee.title,
      key: KEY_TAB_PANE.FEE,
      component: (
        <>
          {hasPermission(PermissionKeys.VIEW_FEE) && (
            <ListFeeComponent visibleAddFee={showModalAddFee} onChangeVisibleAddFee={setShowModalAddFee} />
          )}
        </>
      ),
    },
    {
      name: pageData.tax.title,
      key: KEY_TAB_PANE.TAX,
      component: (
        <>
          {hasPermission(PermissionKeys.VIEW_TAX) && (
            <TaxManagement visibleAddTax={showAddTax} onChangeVisibleAddTax={setShowAddTax} />
          )}
        </>
      ),
    },
  ];

  const changeTab = (activeKey) => {
    setActiveScreen(activeKey);
  };

  const handleAddNewFee = () => {
    setShowModalAddFee(true);
  };

  return (
    <>
      <div className="clearfix"></div>
      {renderHeadingPage(activeScreen)}
      <FnbTabPane
        screens={screens}
        activeScreen={activeScreen}
        indexKeyTabPane={INDEX_KEY_TAB_PANE}
        onChange={changeTab}
        defaultScreen={activeScreen}
        firstKeyTabPane={KEY_TAB_PANE.FEE}
        lastKeyTabPane={KEY_TAB_PANE.TAX}
      />
    </>
  );
}
