import { StarFilled } from "@ant-design/icons";
import { Image, Space, message } from "antd";
import defaultUserIcon from "assets/images/default-user.png";
import { FnbBadge, FnbBadgeVariant } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbSelect } from "components/fnb-select/fnb-select";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { EyeCloseFillIcon, EyeFilledIcon } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { REVIEW_STATUS } from "constants/review-status.constants";
import { DateFormat } from "constants/string.constants";
import branchDataService from "data-services/branch/branch-data.service";
import reviewDataService from "data-services/review/review-data.service";
import i18next from "i18next";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { storeInfoSelector } from "store/modules/session/session.reducers";
import theme from "theme";
import { formatDate, hasPermission } from "utils/helpers";
import { CustomBranchStyled } from "./SelectMultipleBranchStyled";
import { FilterPopover } from "./components/Filter/filter-popover.component";
import "./index.scss";

const DEFAULT_PAGESIZE = 20;

const ReviewManagement = () => {
  const { storeName } = useSelector(storeInfoSelector);
  const { t } = useTranslation();
  const allBranchOption = {
    id: "",
    name: t("material.filter.branch.all"),
  };
  const filterPopoverRef = useRef();
  const pageData = {
    titleManagement: t("review.reviewManagement"),
    no: t("table.noDot"),
    orderId: t("promotion.usageDetail.orderId"),
    rating: t("review.rating"),
    status: t("table.status"),
    shownReview: t("review.status.shownReview"),
    hiddenReview: t("review.status.hiddenReview"),
    createdTime: t("review.createdTime"),
    review: t("review.menuTitle"),
    button: {
      editButton: t("button.edit"),
      deleteButton: t("button.delete"),
    },
    branch: t("kitchen.branch"),
    filter: t("combo.filter"),
    searchByOrderId: t("review.search.searchByOrderID"),
    showReviewOnWebsite: t("review.showReviewOnWebsite"),
    hideReviewOnWebsite: t("review.hideReviewOnWebsite"),
    updateSuccess: t("messages.updateSuccess"),
  };
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate(),
    endDate: moment().toDate(),
  });
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);
  const [dataSource, setDataSource] = useState([]);
  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    setTypeOptionDate(typeOptionDate);
  };
  const [pageNumber, setPageNumber] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [branches, setBranches] = useState([]);
  const [branchIds, setBranchIds] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [keySearchOrderId, setKeySearchOrderId] = useState("");
  const [statusReview, setStatusReview] = useState("");
  const [branchValuesSelect, setBranchValuesSelect] = useState([]);
  const isBranchRef = useRef(false);
  const filterData = async () => {
    const data = {
      pageNumber: pageNumber,
      pageSize: DEFAULT_PAGESIZE,
      status: statusReview,
      keySearchOrderId: keySearchOrderId,
      startDate: selectedDate?.startDate,
      endDate: selectedDate?.endDate,
      branchIds: branchIds,
      ratings: ratings,
    };

    const response = await reviewDataService.getFilterReviewAsync(data);
    if (response && response.success) {
      setDataSource(response.data?.result);

      setTotalData(response.data.total);
      setPageNumber(response.data.currentPage);
    }
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [dataSource, i18next.language]);

  useEffect(() => {
    let changes = 0;
    if (ratings?.length > 0) {
      changes++;
    }

    //All label == "string", otherwise is number and count it.
    if (typeof statusReview !== "string") {
      changes++;
    }

    setCountFilter(changes);
    filterData();
    setSelectedRowKeys([]);
  }, [pageNumber, branchIds, ratings, keySearchOrderId, statusReview, selectedDate]);

  const tableSettings = {
    pageSize: DEFAULT_PAGESIZE,
    columns: [
      {
        title: pageData.no,
        width: "5rem",
        align: "center",
        render: (val, record, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        title: pageData.createdTime,
        dataIndex: "createdTime",
        key: "createdTime",
        width: "15rem",
        align: "left",
        render: (val) => {
          return <span>{formatDate(val, DateFormat.HH_MM_D_MMMM_YYYY)}</span>;
        },
      },
      {
        title: pageData.branch,
        dataIndex: "branchName",
        key: "branchName",
        width: "10rem",
        align: "left",
        ellipsis: true,
        render: (val) => {
          return (
            <FnbTooltip placement="top" onlyShowWhenEllipsis={true} title={val}>
              <span>{val}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.orderId,
        align: "left",
        width: "8rem",
        align: "center",
        render: ({ orderIdAsString, orderId }) => {
          return (
            <FnbTypography.Link
              to={`/report/order/detail/${orderId}`}
              target="_blank"
              variant={theme.typography["b1-bold"]}
              color={theme.colors.primary[100]}
              text={orderIdAsString}
            />
          );
        },
      },
      {
        title: pageData.rating,
        dataIndex: "rating",
        key: "rating",
        width: "8rem",
        align: "center",
        className: "col-rating",
        render: (val) => {
          return (
            <Space direction="horizontal" style={{ justifyContent: "center" }} size={6}>
              <FnbTypography text={val} variant={theme.typography["b1-medium"]} />
              <StarFilled />
            </Space>
          );
        },
      },
      {
        title: pageData.review,
        ellipsis: true,
        width: "39rem",
        className: "col-review",
        render: (text, { reviewCustomerName, reviewComment, customerThumbnail, customerId }) => {
          const thumbNail = customerThumbnail ?? defaultUserIcon;
          return (
            <Space direction="horizontal" size={8} className="space">
              <Image src={thumbNail} width={52} height={52} />
              <Space direction="vertical" size={6} style={{ minWidth: 0 }}>
                <span className="review-customer">
                  <FnbTypography.Link
                    to={`/customer/detail/${customerId}`}
                    variant={theme.typography["b1-bold"]}
                    color={theme.colors.primary[100]}
                    text={reviewCustomerName}
                    target="_blank"
                  />
                </span>
                <FnbTooltip width={400} onlyShowWhenEllipsis={true} title={reviewComment}>
                  <FnbTypography
                    variant={theme.typography["b2-regular"]}
                    color={theme.colors.gray[70]}
                    text={reviewComment}
                  />
                </FnbTooltip>
              </Space>
            </Space>
          );
        },
      },
      {
        title: pageData.status,
        width: "9rem",
        align: "center",
        render: (text, { statusAsString, status }) => {
          return <FnbBadge variant={getVariantReviewStauts(status)} text={t(statusAsString)} size={6} />;
        },
      },
    ],
  };

  const getVariantReviewStauts = (status) => {
    switch (status) {
      case REVIEW_STATUS.Shown:
        return FnbBadgeVariant.SUCCESS;
      default:
        return FnbBadgeVariant.HIDDEN;
    }
  };

  const onChangePage = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const filterComponent = () => {
    return (
      <FilterPopover
        ref={filterPopoverRef}
        branches={branches}
        setRatings={setRatings}
        setStatusReview={setStatusReview}
        setPageNumber={setPageNumber}
      />
    );
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.clear();
    }
    setCountFilter(0);
  };

  const onSearch = (keySearch) => {
    setKeySearchOrderId(keySearch);
    setPageNumber(1);
  };

  useEffect(() => {
    const getBranchs = async () => {
      const res = await branchDataService.getAllBranchsAsync();
      if (res && res?.branchs?.length > 0) {
        const branches = [allBranchOption, ...res.branchs];
        setBranches(branches);
      }
    };
    getBranchs();
  }, []);

  const handleSelectBranch = (values) => {
    const isAllBranch = values.find((value) => value === "");
    //Select all branch
    if (typeof isAllBranch === "string" && isAllBranch === "") {
      if (isBranchRef.current === true) {
        setBranchIds([]);
        setBranchValuesSelect([]);
        isBranchRef.current = false;
        return;
      }
      const branchsExcludeAll = branches?.filter((branch) => branch.id !== "");
      const ids = branchsExcludeAll?.map((branch) => branch.id);
      setBranchIds(ids);
      setBranchValuesSelect(ids);
      isBranchRef.current = true;
    } else {
      setBranchIds(values);
      setBranchValuesSelect(values);
    }
    setPageNumber(1);
  };

  const handleUpdateStatus = async (status, selectRows) => {
    const reviewIds = selectRows?.map((row) => row.reviewId);
    const data = {
      statusReview: status,
      reviewIds: reviewIds,
    };
    const response = await reviewDataService.updateStatusReviewAsync(JSON.stringify(data));
    if (response && response.success) {
      setSelectedRowKeys([]);
      filterData();
      message.success(pageData.updateSuccess);
    } else {
      message.error(response.errorMessage);
    }
  };

  return (
    <div className="review-management">
      <FnbHeadingPage
        title={pageData.titleManagement}
        isCompactButtons={false}
        listButtons={[
          <FnbSelect
            value={branchValuesSelect}
            variant={theme.typography["b1-medium"]}
            dropdownRender={(option) => <CustomBranchStyled>{option}</CustomBranchStyled>}
            onChange={handleSelectBranch}
            isShowPrefixHomeIcon={true}
            mode={"multiple"}
            multiLine={true}
            options={branches?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))}
            className="select-branch"
            placeholder={storeName}
            removeIcon={<></>}
          />,
          <FnbDatePicker
            selectedDate={selectedDate}
            setSelectedDate={(date, typeOptionDate) => {
              onSelectedDatePicker(date, typeOptionDate);
              setPageNumber(1);
            }}
          />,
        ]}
      />
      <FnbCard className="fnb-card-custom w-100">
        <FnbTable
          className={"review-table"}
          columns={tableSettings.columns}
          pageSize={tableSettings.pageSize}
          currentPageNumber={pageNumber}
          dataSource={dataSource}
          onChangePage={onChangePage}
          total={totalData}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            renderActions: (_, selectedRows) => {
              return (
                <>
                  {selectedRows?.find((row) => row.status === REVIEW_STATUS.Shown) &&
                    hasPermission(PermissionKeys.SHOW_HIDE_REVIEW) && (
                      <FnbTooltip placement="top" title={pageData.hideReviewOnWebsite} zIndex={10}>
                        <FnbButton
                          className="btn-change-status"
                          variant="text"
                          text={pageData.hideReviewOnWebsite}
                          onClick={() => handleUpdateStatus(REVIEW_STATUS.Hidden, selectedRows)}
                          iconHeader={<EyeCloseFillIcon />}
                        />
                      </FnbTooltip>
                    )}
                  {selectedRows?.find((row) => row.status === REVIEW_STATUS.Hidden) &&
                    hasPermission(PermissionKeys.SHOW_HIDE_REVIEW) && (
                      <FnbTooltip placement="top" title={pageData.showReviewOnWebsite} zIndex={10}>
                        <FnbButton
                          className="btn-change-status"
                          variant="text"
                          text={pageData.showReviewOnWebsite}
                          onClick={() => handleUpdateStatus(REVIEW_STATUS.Shown, selectedRows)}
                          iconHeader={<EyeFilledIcon />}
                        />
                      </FnbTooltip>
                    )}
                </>
              );
            },
          }}
          search={{
            placeholder: pageData.searchByOrderId,
            onChange: onSearch,
          }}
          filter={{
            totalFilterSelected: countFilter,
            onClearFilter: onClearFilter,
            buttonTitle: pageData.filter,
            component: filterComponent(),
          }}
        />
      </FnbCard>
    </div>
  );
};

export default ReviewManagement;
