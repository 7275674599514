import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ImgDefault from "../../../assets/icons/img_default.png";
// Import Swiper styles
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { checkOutOfStockProductList } from "../../../../services/material/check-out-of-stock.service";
import { formatTextCurrency, formatTextNumber } from "../../../../utils/helpers";
import { CartPlusIcon, StarVoteIcon } from "../../../assets/icons.constants";
import FnbDisplayImageComponent from "../../../components/fnb-display-image/fnb-display-image.component";
import "./similar-product.component.scss";
export function Theme1SimilarProduct({ similarProducts, addToCart, swiperRef }) {
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  let spaceBetween = 52;
  if (isMaxWidth575) {
    spaceBetween = 16;
  }
  const branchAddress = useSelector((state) => state.session?.deliveryAddress?.branchAddress);
  const [productPricesOOS, setProductPricesOOS] = useState([]);
  const fitSpaceRightLastSlide = -50 * (similarProducts?.length - 1) - spaceBetween;
  const [t] = useTranslation();
  const translatedData = {
    addProductToCart: t("storeWebPage.productDetailPage.addProductToCart", "Add to cart"),
    outOfStock: t("storeWebPage.productDetailPage.outOfStock", "Out Of Stock"),
  };

  async function fetchOutOfStockData(productPrices) {
    const outOfStockData = await checkOutOfStockProductList(branchAddress?.id, true, productPrices);
    setProductPricesOOS(outOfStockData);
  }

  useEffect(() => {
    let productPricesData = [];
    similarProducts?.forEach((combo, index) => {
      const products = combo?.comboProductPrices || combo?.comboPricingProducts;
      if (products) {
        products?.forEach((product) => {
          productPricesData.push({
            productPriceId: product?.productPriceId,
            comboId: combo?.id,
            index: index,
          });
        });
      } else {
        productPricesData.push({
          productPriceId: combo?.productPriceId,
          index: index,
        });
      }
    });

    fetchOutOfStockData(productPricesData);
  }, [similarProducts]);

  return (
    <Swiper
      navigation={false}
      slidesPerView={"auto"}
      spaceBetween={52}
      freeMode={true}
      ref={swiperRef}
      pagination={{
        clickable: true,
      }}
      modules={[FreeMode, Pagination, Navigation]}
      slidesOffsetAfter={fitSpaceRightLastSlide}
      breakpoints={{
        576: {
          slidesOffsetAfter: fitSpaceRightLastSlide,
        },
      }}
      className="similar-product-content"
    >
      {similarProducts?.map((item, index) => {
        const findOOS = productPricesOOS?.find(
          (i) =>
            (i.comboId && i.outOfStock == true && item.id == i.comboId) ||
            (i.productPriceId && i.productPriceId == item.productPriceId && i.outOfStock == true),
        );
        const isOutOfStock = findOOS != undefined;
        return (
          <SwiperSlide key={index}>
            <div className={`product-card ${item?.isFlashSale ? "product-card-flash-sale" : ""}`} key={index}>
              <a className={isOutOfStock ? "w-100 out-of-stock" : "w-100"} href={item?.navigateTo ?? "#"}>
                <div className={`product-list-image`}>
                  <FnbDisplayImageComponent
                    className={item?.isFlashSale ? "flash-sale-border" : ""}
                    src={item?.thumbnail ?? ImgDefault}
                    isFlashSale={item?.isFlashSale}
                    isPromotion={item?.isPromotion}
                    promotionTitle={item?.promotionTitle}
                    isOutOfStock={isOutOfStock}
                    outOfStock={translatedData.outOfStock}
                    isSimilarProduct={true}
                  />
                </div>

                <div className="product-card-content">
                  <div className="product-name">
                    {item?.priceName ? `${item?.name} (${item?.priceName})` : item?.name}
                  </div>
                  <div className="star-vote">
                    <StarVoteIcon /> 4,0
                  </div>
                  <div className="product-price">
                    <div className="price-container">
                      <div className="selling-price">{formatTextCurrency(item?.sellingPrice)}</div>
                      {item?.originalPrice > 0 && item?.originalPrice !== item?.sellingPrice ? (
                        <div className="original-price">{formatTextCurrency(item?.originalPrice)}</div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <Button
                      onClick={(e) => {
                        e.preventDefault(); // Ngăn chặn hành động mặc định của thẻ <a>
                        if (addToCart) addToCart(item);
                      }}
                      className={`add-to-cart ${isOutOfStock ? "out-of-stock" : ""}`}
                      disabled={isOutOfStock ? true : false}
                      icon={<CartPlusIcon />}
                    ></Button>
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
