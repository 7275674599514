/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Form, Input, Radio, Row, message, Space } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbFroalaEditor from "components/fnb-froala-editor";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { ActivateStatus } from "constants/activate-status.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { SizeScreen } from "constants/size-screen.constants";
import areaDataService from "data-services/area/area-data.service";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "utils/check-screen";
import "./edit-area.component.scss";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import theme from "theme";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import { UploadLogoIcon } from "constants/icons.constants";

export const EditAreaComponent = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { isModalVisible, handleCancel, storeBranchId, handleDelete, handleOk } = props;
  const [form] = Form.useForm();
  const imageRef = useRef(null);

  const checkScreen = useWindowDimensions();
  const widthModal = checkScreen === SizeScreen.IS_TABLET ? 984 : 1336;

  const pageData = {
    active: t("area.active"),
    inActive: t("area.inactive"),
    area: t("area.area"),
    image: t("area.image"),
    status: t("area.status"),
    updateArea: t("area.updateArea"),
    areaName: t("area.areaName"),
    areaNameMaxLength: 100,
    description: t("form.description"),
    descriptionMaxLength: 2000,
    cancel: t("button.cancel"),
    save: t("button.save"),
    enterAreaName: t("area.enterAreaName"),
    pleaseEnterAreaName: t("area.pleaseEnterAreaName"),
    areaUpdatedSuccessfully: t("area.areaUpdatedSuccessfully"),
    areaUpdatedUnSuccessfully: t("area.areaUpdatedUnSuccessfully"),
    upload: {
      addFile: t("productManagement.generalInformation.uploadImages"),
      addFromUrl: t("upload.addFromUrl"),
    },
    update: t("button.update"),
    deActive: t("button.deactive"),
    delete: t("button.delete"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
  };

  useImperativeHandle(ref, () => ({
    initData(recordId) {
      form.setFieldsValue({
        storeBranchId: storeBranchId,
      });
      setInitData(recordId);
    },
  }));

  const [image, setImage] = useState(null);
  const [descriptionArea, setDescriptionArea] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [formDataChanged, setFormDataChanged] = useState(false);

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  const setInitData = (recordId) => {
    let request = {
      id: recordId,
      storeBranchId: storeBranchId,
    };
    areaDataService.getAreaByIdAsync(request).then((res) => {
      if (res.area) {
        const { id, description, name, isActive, imageUrl } = res.area;
        const formValues = {
          id: id,
          name: name,
          description: description ?? "",
          isActive: isActive,
        };
        setIsActive(isActive);
        setId(id);
        setName(name);
        setDescriptionArea(description ?? "");
        form.setFieldsValue(formValues);
        if (imageUrl) {
          setImage({ url: imageUrl });
          imageRef?.current?.setImage(imageUrl);
        } else {
          setImage(null);
          imageRef?.current?.setImage(null);
        }
      } else {
        form.resetFields();
        setIsActive(false);
        setId(0);
        setName("name");
        setDescriptionArea("");
      }
    });
  };

  const onFinish = async (values) => {
    message.destroy();
    values.imageUrl = image?.url;
    values.isActive = isActive;
    var req = { ...values, shortDescription: values?.description?.replace(/<.*?>/gm, "").slice(0, 200) };
    let res = await areaDataService.updateAreaAsync(req);
    if (res?.succeeded) {
      form.resetFields();
      message.success(pageData.areaUpdatedSuccessfully);
      handleOk();
    } else {
      if (res?.message) {
        message.error(t(res?.message));
      } else {
        message.success(pageData.areaUpdatedUnSuccessfully);
      }
    }
  };

  const onUploadImage = (file) => {
    setFormDataChanged(true);
    setImage(file);
  };

  const onHandleDelete = async () => {
    await setFormDataChanged(false);
    handleDelete(id);
  };

  const onCancelAfterConfirm = async () => {
    setFormDataChanged(false);
    await form.resetFields();
    resetImage();
    handleCancel();
  };

  const resetImage = () => {
    setImage(null);
    imageRef?.current?.setImage(null);
  };

  const renderInputImage = () => {
    return (
      <Col xl={7} md={7} xs={24} className="w-100" style={{ marginBottom: 24 }}>
        <Row className="w-100 upload-product-image" gutter={[0, 8]}>
          <FnbTypography variant={theme.typography["b1-medium"]} text={t(pageData.image)} />
          <Row className={`non-image ${image !== null ? "have-image" : ""}`} style={{ marginTop: 8 }}>
            <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
              <Form.Item name={"imageUrl"}>
                <FnbCustomUploadImageComponent
                  ref={imageRef}
                  iconUpload={<UploadLogoIcon />}
                  buttonText={pageData.upload.addFile}
                  onChange={onUploadImage}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
      </Col>
    );
  };

  return (
    <FnbModal
      className="modal-add-area"
      title={pageData.updateArea}
      visible={isModalVisible}
      footer={(null, null)}
      centered
      width={widthModal}
      hideFooter={true}
      handleCancel={onCancelAfterConfirm}
      content={
        <Form
          autoComplete="off"
          form={form}
          name="basic"
          onFinish={onFinish}
          onFieldsChange={() => {
            if (formDataChanged === false) {
              setFormDataChanged(true);
            }
          }}
          className="custom-form"
          layout="vertical"
        >
          <Row gutter={16}>
            {checkScreen === SizeScreen.IS_MOBILE ? renderInputImage() : null}
            <Col xl={17} md={17} xs={24} className="container-input">
              <Space direction="vertical" size={24}>
                <Space size={24}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.pleaseEnterAreaName} />,
                      },
                    ]}
                    label={pageData.areaName}
                  >
                    <FnbInput
                      style={{ width: "400px" }}
                      showCount
                      placeholder={pageData.enterAreaName}
                      maxLength={pageData.areaNameMaxLength}
                    />
                  </Form.Item>
                  <Form.Item name="isActive" className="checkbox-status" label={pageData.status}>
                    <Radio.Group onChange={(e) => setIsActive(e.target.value)} value={isActive}>
                      <Radio value={ActivateStatus.Activate}>
                        <FnbTypography text={pageData.active} />
                      </Radio>
                      <Radio value={ActivateStatus.Deactivate}>
                        <FnbTypography text={pageData.inActive} />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="id" className="d-none">
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item name="storeBranchId" className="d-none">
                    <Input type="hidden" />
                  </Form.Item>
                </Space>
                <Form.Item name="description" label={pageData.description} style={{ minHeight: "570px" }}>
                  <FnbFroalaEditor
                    value={descriptionArea}
                    onChange={(value) => {
                      if (value !== "" && value !== "<div></div>");
                      setDescriptionArea(value);
                    }}
                    charCounterMax={-1}
                    heightMin={400}
                    heightMax={400}
                  />
                </Form.Item>
              </Space>
            </Col>
            {checkScreen !== SizeScreen.IS_MOBILE ? renderInputImage() : null}
          </Row>
          <Row className="float-center justify-content-center" style={{ marginTop: 24 }}>
            <div className="d-flex flex-wrap text-white justify-content-center">
              <div class="p-2 flex-fill">
                <DeleteConfirmComponent
                  buttonText={pageData.delete}
                  buttonType={"NotIcon"}
                  title={pageData.confirmDelete}
                  content={formatDeleteMessage(name)}
                  okText={pageData.btnDelete}
                  className={"delete-button"}
                  cancelText={pageData.btnIgnore}
                  permission={PermissionKeys.DELETE_AREA_TABLE}
                  cancelButtonProps={{
                    style: { border: "none !important", color: "none !important" },
                  }}
                  onOk={onHandleDelete}
                />
              </div>
              <FnbCancelButton
                buttonText={pageData.cancel}
                className="mr-1 width-100 btnCancel"
                key="back"
                showWarning={formDataChanged}
                onOk={onCancelAfterConfirm}
              />
              <FnbButton type="submit" disabled={!formDataChanged} text={pageData.update} style={{ minWidth: 95 }} />
            </div>
          </Row>
        </Form>
      }
    />
  );
});
