import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import classNames from "classnames";
import FnbTypography from "components/fnb-typography/fnb-typography";
import React from "react";
import theme from "theme";
import { hasPermission } from "utils/helpers";
import "./fnb-button.scss";

export function FnbButton({
  className = "",
  onClick,
  text = "",
  permission = undefined,
  disabled = false,
  size = "default", // 'default' | 'large'
  variant = "primary", // 'default' | 'primary' | 'secondary' | 'secondary-purple' | 'tertiary'
  danger = false,
  iconHeader = undefined,
  iconFooter = undefined,
  width = undefined, // 120px | 50%
  minWidth = "fit-content",
  id,
  type = "button",
  form,
  style = {},
  textStyle = {},
  loading = false,
  hiddenTextOnMobile = false,
  textTransform,
  ...rest
}) {
  /* 
  Usage: 
   <FnbButton
      text="button"
      variant="secondary"
      iconHeader={<InfoCircleFlashSaleIcon />}
      iconFooter={<InfoCircleFlashSaleIcon />}
    />
 */

  const classNameButton = classNames({
    "fnb-button": true,
    [`fnb-button--size-${size}`]: true,
    [`fnb-button--${variant}`]: true,
    [`fnb-button--disabled--fulfill`]: variant !== "tertiary" && disabled,
    [`fnb-button--disabled--outlined`]: variant === "tertiary" && disabled,
    [`fnb-button--${variant}--danger`]: danger,
    [`fnb-button--hidden-text-on-mobile`]: hiddenTextOnMobile,
    [`${className}`]: true,
  });

  const renderButton = () => {
    const onClickButton = (e) => {
      if (disabled) {
        e.stopPropagation();
      } else {
        onClick && onClick(e);
      }
    };
    return (
      <button
        id={id}
        className={classNameButton}
        onClick={onClickButton}
        style={{ width: width ? width : "fit-content", minWidth: minWidth ? minWidth : "fit-content", ...style }}
        type={type}
        form={form}
        disabled={disabled}
        {...rest}
      >
        {
          loading ?<Spin
          indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
          style={{ color: theme.colors.gray[50], marginTop: 3 }}
        />: <></>
        }
        {iconHeader &&
          React.cloneElement(iconHeader, {
            ...iconHeader?.props,
            className: `fnb-button__icon ${iconHeader?.props?.className ? iconHeader?.props?.className : ""}`,
          })}
        {text && (
          <FnbTypography
            style={textStyle}
            textTransform={textTransform}
            variant={theme.typography["b1-medium"]}
            className="fnb-button__label"
            text={text}
          />
        )}
        {iconFooter &&
          React.cloneElement(iconFooter, {
            ...iconFooter?.props,
            className: `fnb-button__icon ${iconFooter?.props?.className ? iconFooter?.props?.className : ""}`,
          })}
      </button>
    );
  };

  return <>{permission ? hasPermission(permission) && renderButton() : renderButton()}</>;
}
