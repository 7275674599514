export const EnumServiceType = {
  /// <summary>
  /// InStore
  /// </summary>
  InStore: 0,

  /// <summary>
  /// Delivery
  /// </summary>
  Delivery: 1,

  /// <summary>
  /// Take Away
  /// </summary>
  TakeAway: 2,

  /// <summary>
  /// Delivery orders from Store App/Store Web
  /// </summary>
  Online: 3,

  /// <summary>
  /// Pickup
  /// </summary>
  PickUp: 4,
};

export const ServiceTypeObject = [
  {
    key: EnumServiceType.InStore,
    name: "serviceType.inStore",
  },
  {
    key: EnumServiceType.Delivery,
    name: "serviceType.delivery",
  },
  {
    key: EnumServiceType.TakeAway,
    name: "serviceType.takeAway",
  },
  {
    key: EnumServiceType.PickUp,
    name: "serviceType.pickup",
  },
];

export const getServiceTypeById = (serviceTypeId) => {
  const result = ServiceTypeObject.find((item) => item.key === serviceTypeId);
  return result;
};

export const ServiceTypesByPlatform = [
  {
    id: "6c626154-5065-616c-7466-6f7200000002",
    name: "POS",
    serviceTypes: [
      getServiceTypeById(EnumServiceType.InStore),
      getServiceTypeById(EnumServiceType.TakeAway),
      getServiceTypeById(EnumServiceType.Delivery),
    ],
  },
  {
    id: "6c626154-5065-616c-7466-6f7200000004",
    name: "Store Web",
    serviceTypes: [getServiceTypeById(EnumServiceType.Delivery), getServiceTypeById(EnumServiceType.PickUp)],
  },
  {
    id: "6c626154-5065-616c-7466-6f7200000005",
    name: "Store App",
    serviceTypes: [getServiceTypeById(EnumServiceType.Delivery), getServiceTypeById(EnumServiceType.PickUp)],
  },
];
