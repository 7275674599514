export function FnbTabItem(props) {
  const { title, onClick = () => {}, isCurrentScreen = false, className = '', screen } = props;

  return (
    <div
      key={screen}
      onClick={(screen) => onClick(screen)}
      className={`fnb-tab__nav-tab ${className} ${isCurrentScreen && "active"}`}
    >
      {title}
    </div>
  );
}
