import React from "react";
import { Row, Col, Space } from "antd";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
export default function ViewTaxDetail(props) {
  const { t, taxDetail } = props;

  const pageData = {
    name: t("feeAndTax.tax.name"),
    value: t("feeAndTax.tax.value"),
    type: t("feeAndTax.tax.type"),
    description: t("feeAndTax.tax.description"),
  };

  const renderRowItem = (title, value) => {
    return (
      <Row style={{ alignItems: "baseline" }} gutter={[24, 8]}>
        <Col span={8}>
          <FnbTypography variant={theme.typography["h3-medium"]} text={title} />
        </Col>
        <Col span={16}>
          <FnbTypography text={value} />
        </Col>
      </Row>
    );
  };
  return (
    <Space direction="vertical" size={8} className="w-100">
      {renderRowItem(pageData.name, taxDetail?.name)}
      {renderRowItem(pageData.value, `${taxDetail?.percentage} %`)}
      {renderRowItem(pageData.type, t(taxDetail?.taxType))}
      {renderRowItem(pageData.description, taxDetail?.description)}
    </Space>
  );
}
