import styled from "styled-components";

export const WrapPlatform = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  .wrapper-children-fnb-content {
    display: flex;
    align-items: center;
  }
`;
