import { CallOutlined, ChromeOutlined, InfoCircleFillOutlined, MessagesOutlined } from "constants/icons.constants";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { useLayoutEffect, useState, useEffect } from "react";
import { EnumDeliveryMethod } from "constants/delivery-method.constants";
import deliveryConfigService from "data-services/delivery-config/delivery-config.service";
import parse from "html-react-parser";
import i18next from "i18next";
import "./index.scss";

const { Option } = Select;

const AhaMoveConfig = (props) => {
  const { t, countryCodes, id, deliveryConfig, onChangeForm, reLoadFormData } = props;
  const [form] = Form.useForm();
  const [isValuesChange, setIsValuesChange] = useState(false);

  const pageData = {
    apiKey: {
      title: t("deliveryMethod.apiKey.title"),
      validateMessage: t("deliveryMethod.apiKey.validateMessage"),
      placeholder: t("deliveryMethod.apiKey.placeholder"),
    },
    storePhone: {
      title: t("deliveryMethod.storePhone.title"),
      validateMessage: t("deliveryMethod.storePhone.validateMessage"),
      placeholder: t("deliveryMethod.storePhone.placeholder"),
    },
    storeName: {
      title: t("deliveryMethod.storeName.title"),
      placeholder: t("deliveryMethod.storeName.placeholder"),
    },
    storeAddress: {
      title: t("deliveryMethod.storeAddress.title"),
      placeholder: t("deliveryMethod.storeAddress.placeholder"),
    },
    titleAhaMoveNote: t("deliveryMethod.titleAhaMoveNote"),
    saveChanges: t("button.saveChanges"),
    cancel: t("button.cancel"),
    updateAhaMoveConfigSuccess: t("deliveryMethod.updateAhaMoveConfigSuccess"),
    updateAhaMoveConfigError: t("deliveryMethod.updateAhaMoveConfigError"),
    linkWebsite: "https://www.ahamove.com",
    website: "www.ahamove.com",
    email: "support@ahamove.com",
    hotline: "1900 545 411",
    hotlineText: "1900-545-411",
  };

  useLayoutEffect(() => {
    getInitFormData();
  }, [deliveryConfig]);

  useEffect(() => {
    onChangeForm && onChangeForm(isValuesChange);
  }, [isValuesChange]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
  }, [i18next.language]);

  const getInitFormData = async () => {
    let formValues = form.getFieldsValue();
    const { ahaMoveConfig } = formValues;
    ahaMoveConfig.deliveryMethodId = id;
    form.setFieldsValue(formValues);
    setIsValuesChange(false);

    if (deliveryConfig) {
      ahaMoveConfig.apiKey = deliveryConfig?.apiKey;
      ahaMoveConfig.phoneNumber = deliveryConfig?.phoneNumber;
      ahaMoveConfig.name = deliveryConfig?.name;
      ahaMoveConfig.address = deliveryConfig?.address;
      form.setFieldsValue(formValues);
    }
  };
  const resetInitFormData = async () => {
    setIsValuesChange(false);
    form.resetFields();
    await getInitFormData();
  };

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      deliveryConfigService.updateAhaMoveConfigAsync(values).then((res) => {
        if (res) {
          message.success(pageData.updateAhaMoveConfigSuccess);
          reLoadFormData(EnumDeliveryMethod.AhaMove);
        } else {
          message.error(pageData.updateAhaMoveConfigError);
        }
      });
    });
    setIsValuesChange(false);
  };

  const handleValuesChange = () => {
    setIsValuesChange(true);
  };

  const renderSelectCountryCodes = (
    <Select
      defaultValue="VN"
      optionLabelProp="label"
      bordered={false}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => <div className="country-codes-dropdown">{menu}</div>}
      className="addon-country-codes"
      disabled={!deliveryConfig?.isActivated}
    >
      {countryCodes?.map((countryCode, index) => (
        <Option key={index} value={countryCode?.iso} label={countryCode?.phonecode}>
          <span>{countryCode?.nicename || countryCode?.name}</span>
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="ahamove-express-container">
      <div className="ahamove-express-config">
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          scrollToFirstError
          autoComplete="off"
          onFinish={handleSubmit}
          onValuesChange={handleValuesChange}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div className="ahamove-express-title">
                <div className="ahamove-express-note">
                  <InfoCircleFillOutlined />
                  <span>{parse(pageData.titleAhaMoveNote)}</span>
                </div>
                {isValuesChange && (
                  <div className="ahamove-express-group-button">
                    <Button type="text" onClick={resetInitFormData}>
                      <span>{pageData.cancel}</span>
                    </Button>
                    <Button htmlType="submit" type="primary">
                      <span>{pageData.saveChanges}</span>
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item hidden="true" name={["ahaMoveConfig", "deliveryMethodId"]}></Form.Item>
              <Form.Item
                label={pageData.apiKey.title}
                name={["ahaMoveConfig", "apiKey"]}
                rules={[
                  {
                    required: true,
                    message: pageData.apiKey.validateMessage,
                  },
                ]}
              >
                <Input.Password placeholder={pageData.apiKey.placeholder} disabled={!deliveryConfig?.isActivated} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <Form.Item
                label={pageData.storePhone.title}
                name={["ahaMoveConfig", "phoneNumber"]}
                rules={[
                  {
                    required: true,
                    message: pageData.storePhone.validateMessage,
                  },
                ]}
              >
                <Input
                  placeholder={pageData.storePhone.placeholder}
                  addonBefore={renderSelectCountryCodes}
                  onKeyPress={(event) => {
                    if (!/[0-9-]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={!deliveryConfig?.isActivated}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item label={pageData.storeName.title} name={["ahaMoveConfig", "name"]}>
                <Input placeholder={pageData.storeName.placeholder} disabled={!deliveryConfig?.isActivated} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item label={pageData.storeAddress.title} name={["ahaMoveConfig", "address"]}>
                <Input placeholder={pageData.storeAddress.placeholder} disabled={!deliveryConfig?.isActivated} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row gutter={[16, 16]} className="ahamove-express-contact">
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="ahamove-express-contact-item">
            <ChromeOutlined />
            <a href={pageData.linkWebsite} target="_blank" rel="noreferrer">
              {pageData.website}
            </a>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="ahamove-express-contact-item">
            <MessagesOutlined />
            <a href={`mailto:${pageData.email}`}>{pageData.email}</a>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          <div className="ahamove-express-contact-item">
            <CallOutlined />
            <a href={`tel:${pageData.hotline}`}>{pageData.hotlineText}</a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AhaMoveConfig;
