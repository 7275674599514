import { Select } from "antd";
import { Container, Menus, NoDataFounded } from "./styled";
import NoItems from 'assets/images/no-item.png';
import { useTranslation } from "react-i18next";

const MultipleSelect = (props) => {
  const { options, placeholder, onChange, value, onClean, cleanTitle, cleanIcon } = props;
  const [t] = useTranslation();

  const pageData = {
    noDataFound: t("reservation.noDataFound"),
  };

  const handleSelect = (newValue) => {
    onChange && onChange(newValue);
  }

  return (
    <Container>
      <Select
        id="multiple-select"
        mode="multiple"
        style={{ width: "100%" }}
        value={value}
        options={options || []}
        onChange={handleSelect}
        dropdownRender={(menu) => <>
          <Menus>{menu}</Menus>
          <a onClick={onClean}>
          <div className="clean-option">
            {cleanIcon}
            <span>{cleanTitle}</span>
          </div></a></>}
        placeholder={placeholder}
        notFoundContent={
          <NoDataFounded>
            <img src={NoItems} alt="" />
            <span>{pageData.noDataFound}</span>
          </NoDataFounded>
        }
        filterOption={(input, option) =>
          (typeof(option?.label) === "string" ? (option?.label?.removeVietnamese()?.trim()?.toLowerCase() || "") : option?.labelSearch?.removeVietnamese()?.trim()?.toLowerCase())?.includes(
            input?.removeVietnamese()?.trim()?.toLowerCase(),
          )
        }
      />
    </Container>
  );
};

export default MultipleSelect;
