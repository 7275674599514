import { Card, Col, DatePicker, Form, Input, InputNumber, message, Radio, Row, Typography } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { DELAYED_TIME } from "constants/default.constants";
import { CalendarNewIconBold, InfoCircleIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { ListPromotionType, PromotionType } from "constants/promotion.constants";
import { currency, DateFormat } from "constants/string.constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { checkOnKeyPressValidation, convertUtcToLocalTime, getCurrency, getEndDate, getStartDate } from "utils/helpers";
import "../promotion.scss";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbSelectProduct from "components/fnb-select-product";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";
import i18n from "utils/i18n";

const { Text } = Typography;

export default function EditPromotionManagement(props) {
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { t, productDataService, productCategoryDataService, promotionDataService, branchDataService, history, match } =
    props;

  const [startDate, setStartDate] = useState(null);
  const [promotionTypeId, setPromotionTypeId] = useState(0);
  const [listProduct, setListProduct] = useState([]);
  const [listProductCategory, setListProductCategory] = useState([]);
  const [listBranches, setListBranches] = useState([]);
  const [isPercentDiscount, setIsPercentDiscount] = useState(true);
  const [isMinimumPurchaseAmount, setIsMinimumPurchaseAmount] = useState(false);
  const [isSpecificBranch, setIsSpecificBranch] = useState(false);
  const [disableAllBranches, setDisableAllBranches] = useState(false);
  const [isApplyAllProducts, setIsApplyAllProducts] = useState(false);
  const [isApplyAllCategories, setIsApplyAllCategories] = useState(false);
  const [productIds, setProductIds] = useState([]);

  const pageData = {
    leaveForm: t("messages.leaveForm"),
    edit: t("promotion.edit"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    editPromotionSuccess: t("promotion.editPromotionSuccess"),
    allBranch: t("productCategory.branch.all"),
    discount: {
      total: t("promotion.discount.total"),
      product: t("promotion.discount.product"),
      productCategory: t("promotion.discount.productCategory"),
    },
    form: {
      general: t("promotion.form.general"),
      name: t("promotion.form.name"),
      PlaceholderName: t("promotion.form.PlaceholderName"),
      maxLengthName: 100,
      pleaseEnterPromotionName: t("promotion.form.pleaseEnterPromotionName"),
      promotionType: t("promotion.form.promotionType"),
      selectPromotionType: t("promotion.form.selectPromotionType"),
      pleaseSelectPromotionType: t("promotion.form.pleaseSelectPromotionType"),
      product: t("promotion.form.product"),
      selectProduct: t("promotion.form.selectProduct"),
      pleaseSelectProduct: t("promotion.form.pleaseSelectProduct"),
      productCategory: t("promotion.form.productCategory"),
      selectProductCategory: t("promotion.form.selectProductCategory"),
      pleaseSelectProductCategory: t("promotion.form.pleaseSelectProductCategory"),
      percent: "%",
      discountValue: t("promotion.form.discountValue"),
      pleaseEnterPrecent: t("promotion.form.pleaseEnterPrecent"),
      maxDiscount: t("promotion.form.maxDiscount"),
      pleaseEnterMaxDiscount: t("promotion.form.pleaseEnterMaxDiscount"),
      startDate: t("promotion.form.startDate"),
      PleaseStartDate: t("promotion.form.pleaseStartDate"),
      endDate: t("promotion.form.endDate"),
      PlaceholderDateTime: t("promotion.form.placeholderDateTime"),
      termsAndConditions: t("promotion.form.termsAndConditions"),
      maxLengthTermsAndConditions: 2000,
      condition: {
        title: t("promotion.form.condition.title"),
        checkboxPurchaseAmount: t("promotion.form.condition.checkboxPurchaseAmount"),
        pleaseEnterMinimum: t("promotion.form.condition.pleaseEnterMinimum"),
        checkboxSpecificBranches: t("promotion.form.condition.checkboxSpecificBranches"),
        pleaseSelectSpecificBranches: t("promotion.form.condition.pleaseSelectSpecificBranches"),
        selectBranchPlaceholder: t("promotion.form.condition.selectBranchPlaceholder"),
        specificBranchesTooltip: t("promotion.form.condition.specificBranchesTooltip"),
        includedTopping: t("promotion.form.condition.includedTopping"),
      },
      allProduct: t("discountCode.formCreate.allProducts"),
      allCategories: t("discountCode.formCreate.allCategories"),
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    guideline: {
      title: t("promotion.guideline.title"),
      content: t("promotion.guideline.content"),
    },
  };

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
  }, [i18n.language]);

  useEffect(() => {
    if (match?.params?.id) {
      getInitialData(match.params?.id);
    }
  }, []);

  const getInitialData = (id) => {
    promotionDataService.getPromotionByIdAsync(id).then((res) => {
      if (res) {
        let data = res?.promotion;
        setPromotionTypeId(data?.promotionTypeId);
        setIsApplyAllCategories(data?.isApplyAllCategories);
        setIsApplyAllProducts(data?.isApplyAllProducts);
        let startDateToLocal = convertUtcToLocalTime(data?.startDate);
        setStartDate(startDateToLocal);
        let formValue = {
          promotion: {
            id: data?.id,
            name: data?.name,
            promotionTypeId: data?.promotionTypeId,
            isMinimumPurchaseAmount: data?.isMinimumPurchaseAmount,
            isIncludedTopping: data?.isIncludedTopping,
            isPercentDiscount: data?.isPercentDiscount,
            isSpecificBranch: data?.isSpecificBranch,
            maximumDiscountAmount: data?.maximumDiscountAmount > 0 ? data?.maximumDiscountAmount : null,
            minimumPurchaseAmount: data?.minimumPurchaseAmount,
            percentNumber: data?.percentNumber,
            startDate: startDateToLocal,
            endDate: data?.endDate !== null ? convertUtcToLocalTime(data?.endDate) : null,
            termsAndCondition: data?.termsAndCondition,
            productPriceIds: data?.isApplyAllProducts ? [] : data?.productPrices?.map((price) => price.id),
          },
        };

        if (data?.promotionTypeId === PromotionType.DiscountProduct) {
          getListProducts();
        } else if (data?.promotionTypeId === PromotionType.DiscountProductCategory) {
          getListProductCategories();
          const productCategoryIds = data?.isApplyAllCategories ? [] : data?.productCategories?.map((item) => item.id);
          formValue.promotion.productCategoryIds = productCategoryIds;
        }

        if (data?.isSpecificBranch === true) {
          getListBranches();
          setIsSpecificBranch(true);
          const branchIds = data?.branches?.map((item) => item.id);
          formValue.promotion.branchIds = branchIds;
        }

        if (data?.isPercentDiscount === false) {
          setIsPercentDiscount(false);
        }

        if (data?.isMinimumPurchaseAmount === true) {
          setIsMinimumPurchaseAmount(true);
        }

        form.setFieldsValue(formValue);
      }
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const disabledDateByStartDate = (current) => {
    // Can not select days before today and today
    return current && current < moment(startDate);
  };

  const onEditPromotion = async (values) => {
    const { promotion } = values;
    const formValues = {
      ...values,
      promotion: {
        ...promotion,
        isApplyAllCategories: isApplyAllCategories,
        isApplyAllProducts: isApplyAllProducts,
        startDate: getStartDate(promotion.startDate),
        endDate: getEndDate(promotion.endDate),
        productIds: [...productIds],
      },
    };

    const res = await promotionDataService.updatePromotionAsync(formValues);
    if (res) {
      onCancel();
      message.success(pageData.editPromotionSuccess);
    }
  };

  const onChangePromotionType = (key) => {
    setPromotionTypeId(key);
    if (key === PromotionType.DiscountProduct) {
      getListProducts();
    } else if (key === PromotionType.DiscountProductCategory) {
      getListProductCategories();
    }
  };

  const getListProducts = async () => {
    await productDataService.getAllProductsAsync().then((res) => {
      const productOptions =
        res?.products?.sort((pre, current) => {
          return pre?.name?.localeCompare(current?.name);
        }) || [];
      setListProduct(productOptions);
    });
  };

  const getListProductCategories = async () => {
    var res = await productCategoryDataService.getAllProductCategoriesAsync();
    if (res) {
      setListProductCategory(res.allProductCategories);
    }
  };

  const getListBranches = async () => {
    var res = await branchDataService.getAllBranchsAsync();
    if (res) {
      setListBranches(res.branchs);
    }
  };

  const onCheckSpecificBranches = (e) => {
    if (e.target.checked) {
      getListBranches();
    }
    setIsSpecificBranch(e.target.checked);
  };

  const clickCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      onCancel();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onCancel = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/store/promotion/discount");
    }, DELAYED_TIME);
  };

  const onSelectBranches = (values) => {
    const listBranch = listBranches.filter((b) => values.find((v) => v === b.id));
    if (values && values.length > 0) {
      let formValues = form.getFieldsValue();
      let branchIds = [];
      branchIds = listBranch?.map((item) => item?.id);

      form.setFieldsValue({ ...formValues, promotion: { branchIds: branchIds } });
    }
  };

  const onSelectAllBranches = (event) => {
    let isChecked = event.target.checked;
    setDisableAllBranches(isChecked);
    let branchIds = [];
    if (isChecked) {
      branchIds = listBranches?.map((item) => item?.id);
      onSelectBranches(branchIds);
      let formValue = {
        promotion: {
          branchIds: branchIds,
        },
      };
      form.setFieldsValue(formValue);
    }
  };

  const onChangeOption = (e) => {
    const isChecked = e.target.checked;
    if (promotionTypeId === PromotionType.DiscountProductCategory) {
      setIsApplyAllCategories(isChecked);
    }
    if (promotionTypeId === PromotionType.DiscountProduct) {
      setIsApplyAllProducts(isChecked);
    }
  };

  const onSelectProductPrice = (_value, options) => {
    setProductIds(new Set(options?.map((option) => option?.productId)));
  };

  const renderSelectProducts = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.form.product}
          <span className="text-danger">*</span>
        </h4>
        <div className="check-box-select-all">
          <FnbCheckBox onChange={(event) => onChangeOption(event)} checked={isApplyAllProducts}>
            {pageData.form.allProduct}
          </FnbCheckBox>
        </div>
        {isApplyAllProducts ? (
          <Form.Item hidden={!isApplyAllProducts} className="w-100">
            <FnbSelect mode="multiple" disabled={true} />
          </Form.Item>
        ) : (
          <Form.Item
            hidden={isApplyAllProducts}
            name={["promotion", "productPriceIds"]}
            className="w-100"
            rules={[
              {
                required: !isApplyAllProducts,
                message: <InputValidateMessage message={pageData.form.pleaseSelectProduct} />,
              },
            ]}
          >
            <FnbSelectProduct
              options={listProduct}
              placeholder={pageData.form.selectProduct}
              listHeight={500}
              onChange={onSelectProductPrice}
              hiddenSelectAll
            />
          </Form.Item>
        )}
      </>
    );
  };

  const renderSelectCategorys = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.form.productCategory}
          <span className="text-danger">*</span>
        </h4>
        <div className="check-box-select-all">
          <FnbCheckBox onChange={(event) => onChangeOption(event)} checked={isApplyAllCategories}>
            {pageData.form.allCategories}
          </FnbCheckBox>
        </div>
        <Form.Item
          hidden={isApplyAllCategories}
          name={["promotion", "productCategoryIds"]}
          className="w-100"
          rules={[
            {
              required: !isApplyAllCategories,
              message: <InputValidateMessage message={pageData.form.pleaseSelectProductCategory} />,
            },
          ]}
        >
          <FnbSelect
            mode="multiple"
            placeholder={pageData.form.selectProductCategory}
            className="w-100"
            allowClear
            options={listProductCategory?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))}
          />
        </Form.Item>
        <Form.Item hidden={!isApplyAllCategories} className="w-100">
          <FnbSelect mode="multiple" disabled={true} />
        </Form.Item>
      </>
    );
  };

  return (
    <>
      <Form
        onFinish={onEditPromotion}
        form={form}
        onFieldsChange={() => {
          if (!isChangeForm) setIsChangeForm(true);
        }}
        layout="vertical"
        autoComplete="off"
      >
        <FnbHeadingPage
          title={pageData.edit}
          guideline={{
            title: pageData.guideline.title,
            content: pageData.guideline.content,
          }}
          listButtons={[
            <FnbButton variant="tertiary" text={pageData.btnCancel} onClick={clickCancel} />,
            <FnbButton
              type="submit"
              text={pageData.btnSave}
              permission={PermissionKeys.EDIT_PROMOTION}
              style={{ minWidth: 100 }}
            />,
          ]}
        />
        <Row>
          <Card className="fnb-card w-100">
            <Row>
              <h3 className="label-information">{pageData.form.general}</h3>
            </Row>
            <Row>
              <h4 className="fnb-form-label mt-32">
                {pageData.form.name}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item name={["promotion", "id"]} className="d-none">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name={["promotion", "name"]}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.form.pleaseEnterPromotionName} />,
                  },
                ]}
                className="w-100"
              >
                <Input
                  className="fnb-input-with-count"
                  showCount
                  maxLength={pageData.form.maxLengthName}
                  placeholder={pageData.form.PlaceholderName}
                />
              </Form.Item>
            </Row>
            <Row>
              <h4 className="fnb-form-label">
                {pageData.form.promotionType}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item
                name={["promotion", "promotionTypeId"]}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.form.pleaseSelectPromotionType} />,
                  },
                ]}
                className="w-100"
              >
                <FnbSelect
                  options={ListPromotionType?.map((item) => ({
                    value: item?.key,
                    label: t(item?.name),
                  }))}
                  onChange={(key) => onChangePromotionType(key)}
                />
              </Form.Item>
            </Row>
            {promotionTypeId === PromotionType.DiscountProduct && renderSelectProducts()}
            {promotionTypeId === PromotionType.DiscountProductCategory && renderSelectCategorys()}

            <Row gutter={[32, 16]}>
              <Col xs={24} lg={12}>
                <Input.Group size="large">
                  <h4 className="fnb-form-label">{pageData.form.discountValue}<span className="text-danger">*</span></h4>
                  {isPercentDiscount ? (
                    <Form.Item
                      name={["promotion", "percentNumber"]}
                      rules={[
                        { required: true, message: <InputValidateMessage message={pageData.form.pleaseEnterPrecent} /> },
                        {
                          min: 0,
                          max: 100,
                          type: "integer",
                          message: <InputValidateMessage message={pageData.form.pleaseEnterPrecent} />,
                        },
                      ]}
                    >
                      <InputNumber
                        id="discountPercent"
                        className="fnb-input-number w-100 discount-amount"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        min={1}
                        max={100}
                        addonAfter={
                          <Form.Item name={["promotion", "isPercentDiscount"]} style={{ display: "contents" }}>
                            <Radio.Group
                              className="radio-group-discount"
                              defaultValue={isPercentDiscount}
                              onChange={(e) => setIsPercentDiscount(e.target.value)}
                            >
                              <Radio.Button value={true} className="percent-option">
                                {pageData.form.percent}
                              </Radio.Button>
                              <Radio.Button value={false} className="currency-option">
                                {getCurrency()}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        }
                        onKeyPress={(event) => {
                          const checkStatus = checkOnKeyPressValidation(event, "discountPercent", 1, 100, 0);
                          if (!checkStatus) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name={["promotion", "maximumDiscountAmount"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseEnterMaxDiscount} />,
                        },
                        () => ({
                          validator(_, value) {
                            if (value && (value <= 0 || value >= 1e9)) {
                              return Promise.reject(
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.form.pleaseEnterMaxDiscount}
                                />,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        id="discountAmount"
                        className="w-100 fnb-input-number discount-amount"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                        addonAfter={
                          <Form.Item name={["promotion", "isPercentDiscount"]} style={{ display: "contents" }}>
                            <Radio.Group
                              className="radio-group-discount"
                              defaultValue={isPercentDiscount}
                              onChange={(e) => setIsPercentDiscount(e.target.value)}
                            >
                              <Radio.Button value={true} className="percent-option">
                                {pageData.form.percent}
                              </Radio.Button>
                              <Radio.Button value={false} className="currency-option">
                                {getCurrency()}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        }
                        onKeyPress={(event) => {
                          const checkStatus = checkOnKeyPressValidation(
                            event,
                            "discountAmount",
                            0,
                            null,
                            getCurrency() === currency.vnd ? 0 : 2,
                          );
                          if (!checkStatus) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  )}
                </Input.Group>
              </Col>
              {isPercentDiscount && (
                <Col xs={24} lg={12}>
                  <h4 className="fnb-form-label">{pageData.form.maxDiscount}</h4>
                  <Form.Item
                    name={["promotion", "maximumDiscountAmount"]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && (value <= 0 || value >= 1e9)) {
                            return Promise.reject(
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.form.pleaseEnterMaxDiscount}
                              />,
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    className="w-100"
                  >
                    <InputNumber
                      id="maximumDiscountAmount"
                      addonAfter={getCurrency()}
                      className="fnb-input-number w-100"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      precision={getCurrency() === currency.vnd ? 0 : 2}
                      onKeyPress={(event) => {
                        const checkStatus = checkOnKeyPressValidation(
                          event,
                          "maximumDiscountAmount",
                          0,
                          null,
                          getCurrency() === currency.vnd ? 0 : 2,
                        );
                        if (!checkStatus) event.preventDefault();
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[32, 16]}>
              <Col xs={24} lg={12}>
                <h4 className="fnb-form-label">{pageData.form.startDate}<span className="text-danger">*</span></h4>
                <Form.Item
                  name={["promotion", "startDate"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.PleaseStartDate} />,
                    },
                  ]}
                >
                  <DatePicker
                    suffixIcon={<CalendarNewIconBold />}
                    className="fnb-date-picker w-100"
                    disabledDate={disabledDate}
                    format={DateFormat.DD_MM_YYYY}
                    onChange={(date) => {
                      setStartDate(date);

                      // Clear end date after select start date if endate < startdate only
                      const formValues = form.getFieldsValue();
                      if (formValues.promotion?.endDate != null && formValues.promotion?.endDate < date) {
                        form.setFieldsValue({
                          ...formValues,
                          promotion: {
                            endDate: null,
                          },
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <h4 className="fnb-form-label">{pageData.form.endDate}</h4>
                <Form.Item name={["promotion", "endDate"]}>
                  <DatePicker
                    suffixIcon={<CalendarNewIconBold />}
                    className="fnb-date-picker w-100"
                    disabledDate={disabledDateByStartDate}
                    format={DateFormat.DD_MM_YYYY}
                    disabled={startDate ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <h4 className="fnb-form-label">{pageData.form.termsAndConditions}</h4>
              <Form.Item name={["promotion", "termsAndCondition"]} className="w-100">
                <FnbTextArea showCount maxLength={pageData.form.maxLengthTermsAndConditions} rows={4}></FnbTextArea>
              </Form.Item>
            </Row>
          </Card>
        </Row>
        <Row className="mt-3">
          <Card className="fnb-card w-100">
            <Row>
              <h5 className="title-group">{pageData.form.condition.title}</h5>
            </Row>
            {promotionTypeId === PromotionType.DiscountTotal && (
              <>
                <Row className="mb-2">
                  <Form.Item name={["promotion", "isMinimumPurchaseAmount"]} valuePropName="checked">
                    <FnbCheckBox
                      valuePropName="checked"
                      noStyle
                      onChange={(e) => setIsMinimumPurchaseAmount(e.target.checked)}
                    >
                      <Text>{pageData.form.condition.checkboxPurchaseAmount}</Text>
                    </FnbCheckBox>
                  </Form.Item>
                </Row>
                {isMinimumPurchaseAmount && (
                  <Row>
                    <Form.Item
                      name={["promotion", "minimumPurchaseAmount"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.condition.pleaseEnterMinimum} />,
                        },
                        () => ({
                          validator(_, value) {
                            if (value && (value <= 0 || value >= 1e9)) {
                              return Promise.reject(
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.form.pleaseEnterMaxDiscount}
                                />,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      className="w-100"
                    >
                      <InputNumber
                        className="w-100 fnb-input-number"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                        id="promotion-minimumPurchaseAmount"
                        onKeyPress={(event) => {
                          const checkValidKey = checkOnKeyPressValidation(
                            event,
                            "promotion-minimumPurchaseAmount",
                            0,
                            null,
                            getCurrency() === currency.vnd ? 0 : 2,
                          );
                          if (!checkValidKey) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}
              </>
            )}
            <Row className="mb-2" align={"middle"}>
              <Form.Item name={["promotion", "isSpecificBranch"]} valuePropName="checked">
                <FnbCheckBox onChange={onCheckSpecificBranches}>
                  <Text>{pageData.form.condition.checkboxSpecificBranches}</Text>
                </FnbCheckBox>
              </Form.Item>
              <FnbTooltip type="popover" placement="topLeft" title={pageData.form.condition.specificBranchesTooltip}>
                <InfoCircleIcon className="mb-4" size={24} />
              </FnbTooltip>
            </Row>
            {isSpecificBranch && (
              <>
                <h3 className="fnb-form-label mt-16">{pageData.branch}</h3>
                <div className="material-check-box-select-all-branch">
                  <FnbCheckBox onChange={(event) => onSelectAllBranches(event)}>{pageData.allBranch}</FnbCheckBox>
                </div>
                <Row>
                  <Col span={24} hidden={disableAllBranches}>
                    <Form.Item
                      name={["promotion", "branchIds"]}
                      rules={[
                        {
                          required: true,
                          message: (
                            <InputValidateMessage message={pageData.form.condition.pleaseSelectSpecificBranches} />
                          ),
                        },
                      ]}
                      className="w-100"
                    >
                      <FnbSelect
                        mode="multiple"
                        onChange={(values) => onSelectBranches(values)}
                        placeholder={pageData.form.condition.selectBranchPlaceholder}
                        className="w-100"
                        allowClear
                        options={listBranches?.map((item) => ({
                          value: item?.id,
                          label: t(item?.name),
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} hidden={!disableAllBranches}>
                    <Form.Item name="tmpBranchIds">
                      <FnbSelect disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Form.Item name={["promotion", "isIncludedTopping"]} valuePropName="checked">
                <FnbCheckBox>
                  <Text>{pageData.form.condition.includedTopping}</Text>
                </FnbCheckBox>
              </Form.Item>
            </Row>
          </Card>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onCancel}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
