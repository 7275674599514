import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import styled from "styled-components";
import "swiper/css";
import { ScrollHeaderType } from "../../../../constants/enums";
import { ArrowLeftProductListIcon, ArrowRightIcon } from "../../../assets/icons.constants";
import { backgroundTypeEnum } from "../../../constants/store-web-page.constants";
import "./navigation-category.component.scss";

function NavigationCategory(props) {
  const { listCategories, styleProductList, headerConfig, isCustomize = false, initializingProducts } = props;
  const sliderRef = React.useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listRefSectionProduct = [];
  const scrollRef = React.useRef(null);
  const refContainListCategory = React.useRef(null);

  const isDesktop = useMediaQuery({ minWidth: 1281 });
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1280 });
  const isMaxWidth1280 = useMediaQuery({ minWidth: 741, maxWidth: 1280 });
  const isMaxWidth740 = useMediaQuery({ minWidth: 576, maxWidth: 740 });
  const isMobile = useMediaQuery({ maxWidth: 575 });

  const [itemSelected, setItemSelected] = useState("");
  const [isOverflowCategory, setIsOverflowCategory] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [heightHeader, setHeightHeader] = useState(100);
  const [currentIndexCategory, setCurrentIndexCategory] = useState("");
  const [classNameSlider, setClassNameSlider] = useState("");

  const PADDING_TOP_NAV = isCustomize ? 375 : 75;
  const [widthSectionProductList, setWidthSectionProductList] = useState(
    `${document.getElementsByClassName("product-list-card-theme1")[0]?.offsetWidth + 2}px`,
  );

  const handleClickItem = (id, isCombo, index) => {
    setItemSelected(id);
    setCurrentIndexCategory(index);
    const element = document.getElementById(`list-products-section-id-${id}`);
    if (isCustomize) {
      element.style.scrollMargin = "300px";
      element.scrollIntoView();
    }
    window.removeEventListener("scroll", handleScrollItemSelected);
    const isScrollHeader = headerConfig?.scrollType !== ScrollHeaderType.FIXED;
    let paddingValue = 0;
    // if is scroll up
    if (isScrollHeader) {
      let rowGap = -50;
      if (isTablet) rowGap = -35;
      if (isMobile) rowGap = -45;
      paddingValue = index < currentIndexCategory ? heightHeader + rowGap : rowGap;
    } else {
      paddingValue = heightHeader;
    }
    window.scrollTo({
      top: element?.offsetTop - paddingValue,
      behavior: "smooth",
    });
  };

  const handleScrollShadow = () => {
    const position = window.pageYOffset;
    if (isDesktop) {
      setIsSticky(position >= 352);
    } else if (isMaxWidth1280) {
      setIsSticky(position >= 290);
    } else if (isMaxWidth740) {
      setIsSticky(position >= 200);
    } else if (isMobile) {
      setIsSticky(position >= 225);
    }
  };

  const handleScrollItemSelected = () => {
    if (scrollRef.current) clearTimeout(scrollRef.current);
    scrollRef.current = setTimeout(
      () => {
        const rowGap = isDesktop ? 80 : isTablet ? 36 : 16;
        const heightHeader = document.getElementById("header")?.offsetHeight;
        const offsetHeader = heightHeader + rowGap;

        let currentCategory = null;
        const paddingIcon = getPaddingIconNavCategory();
        let index = 0;
        if (
          document.documentElement.scrollTop <
          document.getElementById(`list-products-section-id-${listCategories[0]?.id}`)?.offsetTop
        ) {
          currentCategory = listCategories[0];
          index = 0;
        } else {
          currentCategory = listCategories.find((category) => {
            const currentScrollY = document.documentElement.scrollTop + offsetHeader;
            const offsetTopCategory = document.getElementById(`list-products-section-id-${category?.id}`)?.offsetTop;
            const heightCategory = document.getElementById(`list-products-section-id-${category?.id}`)?.offsetHeight;
            index++;
            return currentScrollY >= offsetTopCategory - rowGap && currentScrollY < offsetTopCategory + heightCategory;
          });
          index--;
        }

        if (currentCategory === undefined || currentCategory === null) {
          currentCategory = listCategories[listCategories.length - 1];
          index = listCategories.length;
        }
        window.history.replaceState(null, null, `/product-list/${currentCategory.id}`);
        setItemSelected(currentCategory.id);
        setCurrentIndexCategory(index);
        const elementNav = document.getElementById("slider-category-product-list-theme1-id");
        const { beginIndex, endIndex } = getBeginAndEndIndexNavCategory();
        // If cateogories position is overflow left
        if (index < beginIndex) {
          if (isOverflowCategory) {
            sliderRef.current.slickGoTo(index);
          }
        }

        // If cateogories position is overflow right
        let indexNeedToSlide = 0;
        if (index > endIndex) {
          for (let i = index, sumWidth = 0; i > 0; i--) {
            const element = document.getElementById(`item-slide-category-id-${listCategories[i].id}`);
            sumWidth += element.offsetWidth;
            if (sumWidth > elementNav.offsetWidth - paddingIcon * 2) {
              break;
            }
            indexNeedToSlide = i;
          }
          sliderRef.current.slickGoTo(indexNeedToSlide);
        }
      },
      isDesktop ? 20 : 100,
    );
  };

  useEffect(() => {
    const elementHeader = document.getElementById("header");
    if (elementHeader) {
      setHeightHeader(elementHeader.offsetHeight);
    }
  }, []);
  useEffect(() => {
    const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
    const eventListener = !isTouchDevice ? "scrollend" : "scroll";
    window.addEventListener(eventListener, handleScrollShadow, { passive: true });
    window.addEventListener(eventListener, handleScrollItemSelected, { passive: true });

    return () => {
      window.removeEventListener(eventListener, handleScrollShadow);
      window.removeEventListener(eventListener, handleScrollItemSelected);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverflowCategory]);

  useEffect(() => {
    if (!initializingProducts) {
      if (isOverflowCategory === true) {
        setClassNameSlider("slider-category-product-list-theme1--refresh-width");
        setTimeout(() => {
          setClassNameSlider("");
        }, 40);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSelected, initializingProducts]);

  const settings = {
    swipeToSlide: true,
    variableWidth: true,
    draggable: true,
  };

  useEffect(() => {
    if (listRefSectionProduct.length > 0 && listRefSectionProduct[0].current) {
      // set default category or combo selected
      const pathNames = window.location.pathname.split("/");
      if (!pathNames[2]) {
        setItemSelected(listCategories[0]?.id);
      }
      let sumWidth = 0;
      listRefSectionProduct.forEach((item) => {
        sumWidth += item.current.offsetWidth;
      });
      if (sumWidth > refContainListCategory.current.offsetWidth && refContainListCategory.current.offsetWidth > 0) {
        setIsOverflowCategory(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRefSectionProduct]);

  const handleChangeSizeBrowser = () => {
    setWidthSectionProductList(`${document.getElementsByClassName("product-list-card-theme1")[0]?.offsetWidth + 2}px`);
  };

  useEffect(() => {
    window.addEventListener("resize", handleChangeSizeBrowser);
    return () => {
      window.removeEventListener("resize", handleChangeSizeBrowser);
    };
  }, []);

  const getPaddingIconNavCategory = () => {
    return isTablet ? 56 : isMobile ? 0 : 72;
  };

  const getBeginAndEndIndexNavCategory = () => {
    const paddingIcon = getPaddingIconNavCategory();
    const elementNav = document.getElementById("slider-category-product-list-theme1-id");
    const offsetLeftNav = elementNav.offsetLeft;
    const beginIndex = listCategories.findIndex((item) => {
      const elementItem = document.getElementById(`item-slide-category-id-${item.id}`);
      return elementItem.getBoundingClientRect().left >= offsetLeftNav - 5;
    });
    let endIndex = beginIndex;
    for (let i = beginIndex, sumWidth = 0; i < listCategories.length; i++) {
      const element = document.getElementById(`item-slide-category-id-${listCategories[i]?.id}`);
      if (element) {
        sumWidth += element.offsetWidth;
        if (sumWidth > elementNav.offsetWidth - paddingIcon * 2) {
          break;
        }
        endIndex++;
      }
    }

    return { beginIndex, endIndex: endIndex - 1 };
  };

  const onClickIconNav = (position) => {
    const paddingIcon = getPaddingIconNavCategory();
    const elementNav = document.getElementById("slider-category-product-list-theme1-id");

    if (position === "left") {
      const beginIndex = getBeginAndEndIndexNavCategory().beginIndex;
      let indexNeedToSlide = beginIndex;
      let sumWidth = 0;
      for (let i = beginIndex - 1; i >= 0; i--) {
        const element = document.getElementById(`item-slide-category-id-${listCategories[i].id}`);
        sumWidth += element.offsetWidth;
        if (sumWidth > elementNav.offsetWidth - paddingIcon * 2) {
          break;
        }
        indexNeedToSlide--;
      }
      sliderRef.current.slickGoTo(indexNeedToSlide);
    }
    if (position === "right") {
      let sumWidth = 0;
      const endIndex = getBeginAndEndIndexNavCategory().endIndex;
      let indexNeedToSlide = endIndex + 1;
      let isNeedToCountReverse = true;
      for (let i = endIndex + 1; i < listCategories?.length; i++) {
        const element = document.getElementById(`item-slide-category-id-${listCategories[i].id}`);
        sumWidth += element.offsetWidth;
        if (sumWidth > elementNav.offsetWidth - paddingIcon * 2) {
          isNeedToCountReverse = false;
          sumWidth -= element.offsetWidth;
          break;
        }
      }
      if (isNeedToCountReverse) {
        for (let i = endIndex; i >= 0; i--) {
          const element = document.getElementById(`item-slide-category-id-${listCategories[i].id}`);
          sumWidth += element.offsetWidth;

          if (sumWidth > elementNav.offsetWidth - paddingIcon * 2) {
            break;
          }
          indexNeedToSlide--;
        }
      }

      sliderRef.current.slickGoTo(indexNeedToSlide);
    }
  };

  const StyledNavigationCategories = styled.div`
    .item-slider-category {
      &--is-selected {
        background: ${styleProductList?.colorGroup?.buttonBackgroundColor};
        color: ${styleProductList?.colorGroup?.buttonTextColor};
      }
      &--not-selected {
        &__title {
          color: ${styleProductList?.colorGroup?.textColor};
        }
        &:hover {
          .arrow-up {
            border-bottom-color: ${styleProductList?.colorGroup?.buttonBackgroundColor} !important;
          }
        }
      }
    }

    @media (min-width: 1281px) {
      .item-slider-category {
        &--not-selected {
          &:hover {
            .arrow-up {
              border-bottom-color: ${styleProductList?.colorGroup?.buttonBackgroundColor} !important;
            }
          }
        }
      }
    }
  `;

  return (
    <div
      className={`wrapper-sticky-slider-category-product-list-theme1 
        ${isSticky ? "wrapper-sticky-slider-category-product-list-theme1--is-sticky" : ""}`}
      id="wrapper-sticky-slider-category-product-list-theme1-id"
      style={{
        background:
          styleProductList?.backgroundType === backgroundTypeEnum.Color ? styleProductList?.backgroundColor : "unset",
        top:
          headerConfig?.scrollType === ScrollHeaderType.FIXED
            ? `${isMobile ? 8 : isTablet ? 2 : 38}px`
            : isCustomize
            ? 50
            : 0,
      }}
    >
      <div
        ref={refContainListCategory}
        className={`slider-category-product-list-theme1 slider variable-width ${classNameSlider}`}
        id="slider-category-product-list-theme1-id"
        style={{
          width: widthSectionProductList,
        }}
      >
        <ArrowLeftProductListIcon
          className="icon-arrow-slider-catetory icon-arrow-slider-catetory__left"
          onClick={() => onClickIconNav("left")}
        />
        <ArrowRightIcon
          className="icon-arrow-slider-catetory icon-arrow-slider-catetory__right"
          onClick={() => onClickIconNav("right")}
        />
        <Slider infinite={false} className="slider-category" {...settings} ref={sliderRef}>
          {listCategories.map((item, index) => {
            listRefSectionProduct[index] = React.createRef();
            return (
              <StyledNavigationCategories>
                <div
                  ref={listRefSectionProduct[index]}
                  key={item.id}
                  className={`item-slider-category ${
                    item.id === itemSelected
                      ? "item-slider-category--is-selected"
                      : "item-slider-category--not-selected"
                  } `}
                  onClick={() => handleClickItem(item.id, item.isCombo, index)}
                  id={`item-slide-category-id-${item.id}`}
                >
                  <span className="item-slider-category__title">{item.name}</span>
                  <span className="arrow-up"></span>
                </div>
              </StyledNavigationCategories>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default NavigationCategory;
