import React, { useLayoutEffect, useState } from "react";
import { Form, Card, Row, message } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { executeAfter, hasPermission } from "utils/helpers";
import TableCustomerBySegmentComponent from "./table-customer-by-segment.component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import customerSegmentDataService from "data-services/customer-segment/customer-segment-data.service";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { CloseModalIcon } from "constants/icons.constants";
import "./list-customer-segment.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbTypography from "components/fnb-typography/fnb-typography";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import theme from "theme";

/**
 * Page Customer Segment
 * description: page manage customer segment primary template
 */
export default function ListCustomerSegment(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showTableCustomersModal, setShowTableCustomersModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const tableCustomersFunc = React.useRef(null);

  const pageData = {
    title: t("customerSegment.management"),
    linkAddNew: "/customer/segment/create-new",
    button: {
      addNew: t("button.addNew"),
      delete: t("button.delete"),
      ignore: t("button.ignore"),
    },
    table: {
      searchPlaceholder: t("customerSegment.searchPlaceholder"),
      no: t("customerSegment.no"),
      segment: t("customerSegment.segment"),
      numberOfCustomers: t("customerSegment.numberOfCustomers"),
      action: t("customerSegment.action"),
    },
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    customerSegmentDeleteSuccess: t("customerSegment.customerSegmentDeleteSuccess"),
    customerSegmentDeleteFail: t("customerSegment.customerSegmentDeleteFail"),
    toCreateData: t("area.toCreateData"),
  };

  useLayoutEffect(() => {
    fetchDataTableAsync(currentPageNumber, tableSettings.pageSize, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRowClick = (record, _index) => {
    history.push(`/customer/segment/view/${record?.id}`);
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        key: "index",
        title: pageData.table.no,
        dataIndex: "index",
        width: "5rem",
        render: (value) => value + 1,
      },
      {
        key: "name",
        title: pageData.table.segment,
        dataIndex: "name",
        ellipsis: true,
        width: "50%",
        sorter: (pre, current) => pre?.name.localeCompare(current?.name),
        render: (value) => {
          return (
            <FnbTooltip title={value} onlyShowWhenEllipsis>
              <FnbTypography text={value} />
            </FnbTooltip>
          );
        },
      },
      {
        key: "member",
        title: pageData.table.numberOfCustomers,
        dataIndex: "member",
        width: "45%",
        align: "right",
        render: (value, record) => {
          if (value && value > 0) {
            return (
              <FnbTypography.Link
                text={value}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedSegment(record);
                  setShowTableCustomersModal(true);

                  if (tableCustomersFunc.current) {
                    tableCustomersFunc.current(record);
                  }
                }}
              />
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDataTableAsync(page, pageSize, "");
    },
    onSearch: async (keySearch) => {
      executeAfter(500, async () => {
        await fetchDataTableAsync(1, tableSettings.pageSize, keySearch);
      });
    },
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    return t(pageData.confirmDeleteMessage, { name });
  };

  const fetchDataTableAsync = async (pageNumber, pageSize, keySearch) => {
    const response = await customerSegmentDataService.getCustomerSegmentsAsync(pageNumber, pageSize, keySearch);
    const data = response?.customerSegments.map((s) => mappingRecordToColumns(s));
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
  };

  const mappingRecordToColumns = (item) => {
    return {
      index: item?.no,
      id: item?.id,
      name: item?.name,
      member: item?.member,
    };
  };

  const renderContent = () => {
    const segment = dataSource?.find((s) => s.id === selectedSegment?.id);
    return <TableCustomerBySegmentComponent segment={segment} tableFuncs={tableCustomersFunc} />;
  };

  const renderTableCustomerModal = () => {
    const segment = dataSource?.find((s) => s.id === selectedSegment?.id);
    return (
      <FnbModal
        className="modal-customer-segment"
        closeIcon={<CloseModalIcon />}
        width={"1004px"}
        title={segment?.name}
        visible={showTableCustomersModal}
        handleCancel={() => setShowTableCustomersModal(false)}
        content={renderContent()}
        hideFooter={true}
      />
    );
  };

  const onEditItem = (_, selectedRows) => {
    const { id } = selectedRows?.[0];
    setSelectedRowKeys([]);
    history.push(`/customer/segment/edit/${id}`);
  };

  const onDeleteRowKeys = (_, selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const onRemoveItem = async () => {
    const { id } = selectedRows?.[0];
    var res = await customerSegmentDataService.deleteCustomerSegmentByIdAsync(id);
    if (res) {
      message.success(pageData.customerSegmentDeleteSuccess);
      setSelectedRowKeys([]);
      setShowConfirmDeleteModal(false);
    } else {
      message.error(pageData.customerSegmentDeleteFail);
    }

    await fetchDataTableAsync(1, tableSettings.pageSize, "");
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <>
      {renderTableCustomerModal()}
      <FnbHeadingPage
        title={pageData.title}
        permissionCreate={PermissionKeys.CREATE_SEGMENT}
        onCreate={() => history.push(pageData.linkAddNew)}
      />
      <Card className="fnb-card-custom">
        <Form className="form-staff">
          <Row>
            <FnbTable
              columns={tableSettings.columns}
              pageSize={tableSettings.pageSize}
              dataSource={dataSource}
              currentPageNumber={currentPageNumber}
              total={totalRecords}
              onChangePage={tableSettings.onChangePage}
              editPermission={PermissionKeys.EDIT_SEGMENT}
              deletePermission={PermissionKeys.DELETE_SEGMENT}
              search={{
                placeholder: pageData.table.searchPlaceholder,
                onChange: tableSettings.onSearch,
              }}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                onEdit: onEditItem,
                onDelete: onDeleteRowKeys,
              }}
              onRowClick={onRowClick}
              emptyText={
                hasPermission(PermissionKeys.CREATE_SEGMENT) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.button.addNew}
                      onClick={() => history.push(pageData.linkAddNew)}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Row>
        </Form>
        <ConfirmDialogComponent
          title={pageData.confirmDelete}
          content={formatDeleteMessage(selectedRows[0]?.name)}
          visible={showConfirmDeleteModal}
          skipPermission={true}
          cancelText={pageData.button.ignore}
          okText={pageData.button.delete}
          onCancel={() => {
            setShowConfirmDeleteModal(false);
          }}
          onOk={onRemoveItem}
          type={ConfirmStyle.DELETE}
        />
      </Card>
    </>
  );
}
