import { BoxOutlined } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import MaterialManagement from "./material";

import MaterialCategoryManagement from "./material-category/material-category-management.page";
import ImportRemoveIngredientRequest from "./material-control/import";
import MaterialDetail, { DETAIL_MATERIAL_TYPE } from "./material/detail-material/detail-material.page";
import ImportMaterial from "./material/import-material";
import PurchaseOrder from "./purchase-order";
import DetailPurchaseOrder from "./purchase-order/detail-purchase-order";
import EditPurchaseOrder from "./purchase-order/edit-purchase-oder";
import AddNewPurchaseOrder from "./purchase-order/new-purchase-order/new-purchase-order.page";
import Supplier from "./supplier";
import SupplierDetail from "./supplier/components/detail-supplier.component";
import EditSupplier from "./supplier/components/edit-supplier.component";

import i18n from "utils/i18n";
import HistoryRemoveIngredient from "./history-remove-ingredient";
import InventoryHistoryPage from "./inventory-history/inventory-history.page";
import AddNewIngredientRemoveRequest from "./material-control/create-ingredient-remove-request/new-ingredient-remove-request.page";
import DetailIngredientRemoveRequest from "./material-control/detail-ingredient-remove-request/detail-ingredient-remove-request.page";
import EditIngredientRemoveRequest from "./material-control/edit-ingredient-remove-request/edit-ingredient-remove-request";
import TableInventoryControlComponent from "./material-control/table-inventory-control.component";
import NewTransferMaterialComponent from "./material-transfer-list/new-transfer-material/new-transfer-material.page";
import PurchaseOrderHistoryManagement from "./purchase-order-history/purchase-order-history.page";
import ImportPurchaseOrderPage from "./purchase-order/import-purchase-order/import-purchase-order.page";
import EditTransferMaterial from "./transfer-material/edit-transfer-material";
import { TransferMaterialManagement } from "./transfer-material/transfer-material-management/transfer-material-management.page";
import ViewTransferMaterial from "./transfer-material/view-transfer-material/view-transfer-material.page";
import ImportUpdateStockRequest from "./material-control/update-stock/import/import.page";
import CreateUpdateStockRequest from "./material-control/update-stock/create-update-stock-request/create-update-stock-request.page";
import HistoryUpdateStockRequest from "./material-control/update-stock/history-update-stock-request";
import DetailIngredientUpdateRequest from "./material-control/update-stock/detail-page/detail-ingredient-update-request.page";
import EditUpdateStockRequest from "./material-control/update-stock/edit-update-stock-request/edit-update-stock-request";

const { t } = i18n;

const route = {
  key: "app.inventory",
  position: 2,
  path: "#",
  icon: <BoxOutlined />,
  name: t("menu.inventory"),
  isMenu: true,
  exact: true,
  auth: true,
  child: [
    {
      key: "app.inventory.material",
      position: 2,
      path: "/inventory/material",
      name: t("menu.inventoryManagement.material"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_MATERIAL,
      component: MaterialManagement,
      child: [],
    },
    {
      key: "app.inventory.material.new",
      focus: "app.inventory.material",
      position: 2,
      path: "/material/add-new",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_MATERIAL,
      component: () => <MaterialDetail detailMaterialType={DETAIL_MATERIAL_TYPE.CREATE} />,
      child: [],
    },
    {
      key: "app.inventory.material",
      position: 2,
      path: "/inventory/material/edit-material/:id",
      name: "EditMaterialManagement",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_MATERIAL,
      component: () => <MaterialDetail detailMaterialType={DETAIL_MATERIAL_TYPE.EDIT} />,
      child: [],
    },
    {
      key: "app.inventory.material",
      position: 2,
      path: "/inventory/material/detail/:id",
      name: "MaterialDetail",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_MATERIAL,
      component: () => <MaterialDetail detailMaterialType={DETAIL_MATERIAL_TYPE.VIEW} />,
      child: [],
    },
    {
      key: "app.inventory.material",
      position: 2,
      path: "/inventory/material/import",
      name: "MaterialImport",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.IMPORT_MATERIAL,
      component: ImportMaterial,
      child: [],
    },
    {
      key: "app.inventory.material-category",
      position: 2,
      path: "/inventory/material-category",
      name: t("menu.inventoryManagement.materialCategory"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_MATERIAL_CATEGORY,
      component: MaterialCategoryManagement,
      child: [],
    },
    {
      key: "app.inventory.supplier",
      position: 2,
      path: "/inventory/supplier",
      name: t("menu.inventoryManagement.supplier"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_SUPPLIER,
      component: Supplier,
      child: [],
    },
    {
      key: "app.inventory.supplier",
      position: 2,
      path: "/inventory/supplier/:id",
      name: "SupplierDetail",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_SUPPLIER,
      component: SupplierDetail,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      position: 2,
      path: "/inventory/purchase-orders",
      name: t("menu.inventoryManagement.purchaseOrder"),
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_PURCHASE,
      component: PurchaseOrder,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/purchase-order/create-new",
      name: "Create Purchase Order",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_PURCHASE,
      component: AddNewPurchaseOrder,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      position: 2,
      path: "/inventory/edit-purchase-order/:id",
      name: "Edit Purchase Order",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_PURCHASE,
      component: EditPurchaseOrder,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      position: 2,
      path: "/inventory/purchase-orders/import",
      name: "Import Purchase Order",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_PURCHASE,
      component: ImportPurchaseOrderPage,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/detail-purchase-order/:id",
      name: "Detail Purchase Order",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_PURCHASE,
      component: DetailPurchaseOrder,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/purchase-order-history",
      name: t("menu.inventoryManagement.purchaseOrderHistory"),
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_PURCHASE,
      component: PurchaseOrderHistoryManagement,
      child: [],
    },
    {
      key: "app.inventory.purchase-order",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/purchase-order-history/:id/:code",
      name: t("menu.inventoryManagement.purchaseOrderHistory"),
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_PURCHASE,
      component: PurchaseOrderHistoryManagement,
      child: [],
    },
    {
      key: "app.inventory.supplier.edit-supplier",
      focus: "app.inventory.supplier",
      position: 2,
      path: "/inventory/supplier/edit/:supplierId",
      name: "Supplier",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_SUPPLIER,
      component: EditSupplier,
      child: [],
    },
    {
      key: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/transfer-control",
      name: t("menu.inventoryManagement.inventoryControlCapitalize"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: [
        PermissionKeys.VIEW_INGREDIENT_REMOVE_REQUEST,
        PermissionKeys.VIEW_STOCK_UPDATE_REQUEST,
        PermissionKeys.VIEW_PURCHASE,
        PermissionKeys.VIEW_TRANSFER_MATERIAL,
      ],
      component: TableInventoryControlComponent,
      child: [],
    },
    {
      key: "app.inventory.inventory-history",
      position: 3,
      path: "/inventory/inventory-history",
      name: t("inventoryHistory.title"),
      isMenu: true,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_INVENTORY_HISTORY,
      component: InventoryHistoryPage,
      child: [],
    },
    {
      key: "app.inventory.transfer-material",
      position: 2,
      path: "/inventory/transfer-material",
      name: t("menu.inventoryManagement.transferMaterial"),
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_TRANSFER_MATERIAL,
      component: TransferMaterialManagement,
      child: [],
    },
    {
      key: "app.inventory.view-transfer-material",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/transfer-material/view/:id",
      name: t("menu.inventoryManagement.transferMaterial"),
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_TRANSFER_MATERIAL,
      component: ViewTransferMaterial,
      child: [],
    },
    {
      key: "app.inventory.transfer-material",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/transfer-material/create-new",
      name: "Create Transfer Material",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_NEW_TRANSFER_MATERIAL,
      component: NewTransferMaterialComponent,
      child: [],
    },
    {
      key: "app.inventory.material-transfer",
      position: 2,
      path: "/inventory/transfer-material/edit/:id",
      name: "Edit Transfer Material",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_TRANSFER_MATERIAL,
      component: EditTransferMaterial,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/create-new-ingredient-remove-request",
      name: "Create Ingredient Remove Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_INGREDIENT_REMOVE_REQUEST,
      component: AddNewIngredientRemoveRequest,
      child: [],
    },
    {
      key: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/history-ingredient-remove-request",
      name: "History Ingredient Remove Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_INGREDIENT_REMOVE_REQUEST,
      component: HistoryRemoveIngredient,
      child: [],
    },
    {
      key: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/remove-ingredient-request/import",
      name: "RemoveIngredientRequestImport",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.IMPORT_INGREDIENT_REMOVE_REQUEST,
      component: ImportRemoveIngredientRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/edit-ingredient-remove-request/:id",
      name: "Edit Ingredient Remove Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_INGREDIENT_REMOVE_REQUEST,
      component: EditIngredientRemoveRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/detail/:id",
      name: "Detail Ingredient Remove Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_INGREDIENT_REMOVE_REQUEST,
      component: DetailIngredientRemoveRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/update-stock/import",
      name: "UpdateStockRequestImport",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.IMPORT_STOCK_UPDATE_REQUEST,
      component: ImportUpdateStockRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/update-stock/history",
      name: "History Update Stock Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_STOCK_UPDATE_REQUEST,
      component: HistoryUpdateStockRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/create-update-stock-request",
      name: "Create Update Stock Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.CREATE_STOCK_UPDATE_REQUEST,
      component: CreateUpdateStockRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/edit-update-stock-request/:id",
      name: "Edit Update Stock Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.EDIT_STOCK_UPDATE_REQUEST,
      component: EditUpdateStockRequest,
      child: [],
    },
    {
      key: "app.inventory.material-control",
      focus: "app.inventory.transfer-control",
      position: 2,
      path: "/inventory/material-control/update-stock/:id",
      name: "View Update Stock Request",
      isMenu: false,
      exact: true,
      auth: true,
      permission: PermissionKeys.VIEW_STOCK_UPDATE_REQUEST,
      component: DetailIngredientUpdateRequest,
      child: [],
    },
  ],
};
export default route;
