import { Col, Image, Layout, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { CrownIcon, ExclamationIcon } from "constants/icons.constants";
import { images } from "constants/images.constants";
import { useEffect, useState } from "react";
import { formatDate, formatNumberDecimalOrInteger, getCurrency } from "utils/helpers";
import "./view-material.page.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbCard from "components/fnb-card/fnb-card.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { FnbTable } from "components/fnb-table/fnb-table";
import { DateFormat } from "constants/string.constants";
const { Content } = Layout;

export default function ViewMaterialPage({
  t,
  initData,
  materialQuantityInBranch,
  ingredientHistory,
  updateCostPerUnit,
  costPerUnitNumber,
  pageSize,
  setCurrentPageNumberQuantityInStock,
  currentPageNumberQuantityInStock,
  setCurrentPageNumberIngredientHistory,
  currentPageNumberIngredientHistory,
}) {
  const pageData = {
    backTo: t("material.back"),
    materialManagement: t("material.materialManagement"),
    name: t("material.name"),
    description: t("material.description"),
    category: t("form.category"),
    general: t("material.generalInformation"),
    priceAndConversion: t("material.priceAndConversion"),
    currentCostPerUnit: t("material.currentCostPerUnit"),
    tooltipCostPerUnit: t("material.tooltipCostPerUnit"),
    baseUnit: t("material.baseUnit"),
    inventory: t("material.inventory.title"),
    inventoryMinQuantityTooltip: t("material.inventory.minQuantityTooltip"),
    sku: t("table.sku"),
    minQuantity: t("material.inventory.minQuantity"),
    branch: t("material.inventory.branch"),
    image: t("material.inventory.image"),
    btnEdit: t("button.edit"),
    btnDelete: t("button.delete"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    isDeletedSuccessfully: t("messages.isDeletedSuccessfully"),
    notificationTitle: t("form.notificationTitle"),
    isUpdatedSuccessfully: t("messages.isUpdatedSuccessfully"),
    btnLeave: t("button.leave"),
    updateMaterialCost: t("material.updateMaterialCost"),
    productHasExpiryDate: t("material.inventory.productHasExpiryDate"),
    othersSettings: {
      title: t("material.otherSettings"),
      haveExpiryDate: t("material.ingredientHasExpiryDate"),
      notHaveExpiryDate: t("material.ingredientNotHasExpiryDate"),
    },
    viewMore: t("button.viewMore"),
    viewLess: t("button.viewLess"),
    branchAndWarehouse: t("material.branchAndWarehouse"),
    currentQuantityInStock: t("material.currentQuantityInStock", { unit: initData?.material?.unitName }),
    ingredientQuantityInStockByBranch: t("material.ingredientQuantityInStockByBranch"),
    ingredientHistory: t("material.ingredientHistory"),
    mainStore: t("material.mainStore"),
    time: t("table.time"),
    activityEvent: t("table.activityEvent"),
    change: t("table.change", { unit: initData?.material?.unitName }),
    balance: t("table.balance", { unit: initData?.material?.unitName }),
    unitConversions: t("material.unitConversions"),
    tooltipCostPerUOM: t("material.tooltipCostPerUOM"),
    tooltipMinimumStock: t("material.tooltipMinimumStock"),
  };

  const [initMaterialInventoryBranches, setInitMaterialInventoryBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [isShowLessDescription, setIsShowLessDescription] = useState(false);
  const [isShowLink, setIsShowLink] = useState(false);
  const [shortDescription, setShortDescription] = useState();

  const [listQuantityInStock, setListQuantityInStock] = useState([]);
  const [totalQuantityInStock, setTotalQuantityInStock] = useState(0);

  const [listIngredientHistory, setListIngredientHistory] = useState([]);
  const [totalIngredientHistory, setTotalIngredientHistory] = useState(0);

  useEffect(() => {
    getInitDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  useEffect(() => {
    initQuantityInStockByBranchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialQuantityInBranch]);

  useEffect(() => {
    initIngredientHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientHistory]);

  const getInitDataAsync = () => {
    var res = initData;
    if (res?.material) {
      setInitMaterialInventoryBranches(res?.material?.materialInventoryBranches);
      setSelectedBranches(
        res.material?.materialInventoryBranches?.map((i) => {
          return {
            id: i.id,
            name: i.branch.name,
            position: i.position,
            quantity: i.quantity,
            branchId: i.branch.id,
          };
        }),
      );

      if (res?.material?.description?.length > 255) {
        setIsShowLessDescription(true);
        setIsShowLink(true);
        var shortText = res?.material?.description;
        shortText = shortText.substring(0, 255) + "...";
        setShortDescription(shortText);
      } else {
      }
    }
  };

  const initQuantityInStockByBranchData = () => {
    var res = materialQuantityInBranch;
    if (res) {
      setListQuantityInStock(res.materialQuantityInBranches);
      setTotalQuantityInStock(res.total);
    }
  };

  const initIngredientHistoryData = () => {
    var res = ingredientHistory;
    if (res) {
      setListIngredientHistory(res.ingredientHistories);
      setTotalIngredientHistory(res.total);
    }
  };

  const getColumnsQuantityInStock = () => {
    const columns = [
      {
        title: pageData.branchAndWarehouse,
        dataIndex: "name",
        className: "col-name",
        width: "70%",
        render: (_, record) => {
          return (
            <div className="table-branch-and-warehouse">
              <div className="table-branch-and-warehouse-name">
                <FnbTypography text={record.branchName} />
                {record.isDefault && (
                  <div>
                    <CrownIcon width={20} height={20} />
                    <FnbTypography className="main-store-text" text={pageData.mainStore}></FnbTypography>
                  </div>
                )}
              </div>
              <FnbTypography className="branch-and-warehouse-address" text={record.addressInfo} />
            </div>
          );
        },
      },
      {
        title: pageData.currentQuantityInStock,
        dataIndex: "name",
        className: "col-name",
        width: "30%",
        align: "right",
        ellipsis: true,
        render: (_, record) => {
          return <div className="table-quantity-in-stock">{formatNumberDecimalOrInteger(record.materialQuantity)}</div>;
        },
      },
    ];

    return columns;
  };

  const onChangePageQuantityInStock = (page, pageSize) => {
    setCurrentPageNumberQuantityInStock(page);
  };

  const getColumnsIngredientHistory = () => {
    const columns = [
      {
        title: pageData.time,
        dataIndex: "time",
        align: "left",
        width: "20%",
        render: (_, record) => {
          return <>{<span>{formatDate(record?.time, DateFormat.HH_mm_DD_MMM_YYYY)}</span>}</>;
        },
      },
      {
        title: pageData.activityEvent,
        dataIndex: "activity",
        align: "left",
        render: (_, record) => {
          return (
            <div className="column-activity-event">
              <img src={record?.createdByThumbnail || images.imgDefault} alt={record?.createdByThumbnail} />
              <div className="column-activity-event-content">
                <span className="column-activity-event-action">
                  <FnbTypography text={getCreatorShortName(record.createdBy) + " " + t(record.action)}></FnbTypography>
                </span>

                <span className="column-activity-event-code">
                  <FnbTypography.Link
                    style={{ color: getRefCodeColor(record) }}
                    text={" " + getRefCode(record)}
                    className={
                      record?.purchaseOrderId || record?.ingredientRemoveRequestId || record?.updateStockRequestId
                        ? "reference-code"
                        : ""
                    }
                    onClick={() => onClickRefCode(record)}
                  ></FnbTypography.Link>
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: pageData.branch,
        dataIndex: "branchName",
        ellipsis: true,
        width: "18%",
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.branchName}>
              <span>{record.branchName}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.change,
        align: "right",
        width: "14%",
        render: (_, record) => {
          return (
            <div
              style={{ color: getRefCodeColor(record) }}
              className={
                record.purchaseOrderId || record?.ingredientRemoveRequestId || record?.updateStockRequestId
                  ? "ingredient-change"
                  : ""
              }
            >
              {" "}
              {record?.changeQuantity > 0
                ? "+" + formatNumberDecimalOrInteger(record?.changeQuantity)
                : formatNumberDecimalOrInteger(record?.changeQuantity)}
            </div>
          );
        },
      },
      {
        title: pageData.balance,
        dataIndex: "newQuantity",
        align: "right",
        width: "14%",
        render: (_, record) => {
          return (
            <>
              <Row>
                <Col
                  span={24}
                  onClick={(e) => {
                    record?.unitConversion?.length > 0 && e.stopPropagation();
                  }}
                >
                  {record?.refund < 0
                    ? 0
                    : record?.newQuantity < 0
                    ? 0
                    : formatNumberDecimalOrInteger(record?.newQuantity)}
                </Col>
              </Row>
            </>
          );
        },
      },
    ];
    return columns;
  };

  const onChangePageIngredientHistory = (page, pageSize) => {
    setCurrentPageNumberIngredientHistory(page);
  };

  const renderBranchAndQuantity = (branch) => {
    return (
      <>
        <Row gutter={[16, 16]} className="material-view-row-branch-margin">
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col sm={24} xs={24} lg={18}>
                <Paragraph
                  style={{ maxWidth: "inherit" }}
                  placement="top"
                  ellipsis={{ tooltip: branch?.name }}
                  color="#50429B"
                  className="material-view-text material-view-lable-text-color"
                >
                  {branch?.name}
                </Paragraph>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col sm={16} xs={16} lg={2}>
                <span className="material-view-text">{branch?.quantity ? formatNumberDecimalOrInteger(branch?.quantity) : 0}</span>
              </Col>
              <Col sm={8} xs={8} lg={16}>
                <span className="material-view-text">{initData?.material?.unitName}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const renderToolTipCostPerUnit = () => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: pageData.tooltipCostPerUOM,
        }}
      ></span>
    );
  };

  const renderTooltipMinimumStock = () => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: pageData.tooltipMinimumStock,
        }}
      ></span>
    );
  };

  const handleClickViewMore = (isShowMore) => {
    if (isShowMore) {
      setIsShowLessDescription(false);
    } else {
      setIsShowLessDescription(true);
    }
  };

  const onClickRefCode = (record) => {
    record?.orderId
      ? window.open(`/report/order/detail/${record?.orderId}`, "_blank")
      : record?.purchaseOrderId
      ? window.open(`/inventory/detail-purchase-order/${record?.purchaseOrderId}`, "_blank")
      : record?.shiftId
      ? window.open(`/shift/detail/${record?.shiftId}`, "_blank")
      : record?.transferMaterialId
      ? window.open(`/inventory/transfer-material/view/${record?.transferMaterialId}`, "_blank")
      : record?.ingredientRemoveRequestId
      ? window.open(`/inventory/material-control/detail/${record?.ingredientRemoveRequestId}`, "_blank")
      : record?.updateStockRequestId
      ? window.open(`/inventory/material-control/update-stock/${record?.updateStockRequestId}`, "_blank")
      : record.referenceCode && window.open(`/inventory/inventory-history/detail/${record.id}`, "_blank");
  };

  const getRefCode = (record) => {
    if (record.referenceCode) {
      var prefix = " #";
      return prefix + record.referenceCode;
    } else {
      return "";
    }
  };

  const getRefCodeColor = (record, isCode) => {
    var refCodeColor = isCode ? "#50429B" : "#2F2D39";
    if (record?.purchaseOrderId) {
      refCodeColor = "#059735";
    } else if (record?.ingredientRemoveRequestId) {
      refCodeColor = "#DB1B1B";
    } else if (record?.updateStockRequestId) {
      refCodeColor = "#FF7D07";
    }

    return refCodeColor;
  };

  const getCreatorShortName = (creatorName) => {
    if (creatorName) {
      if (creatorName?.length > 14) {
        creatorName = creatorName?.substring(0, 14) + "...";
      }
    } else {
      creatorName = "";
    }

    return creatorName;
  };

  return (
    <>
      <Content className="view-material">
        <div className="view-ingredient-left">
          <FnbCard title={pageData.general} className="margin-b">
            <div className="text-title-underlined"></div>
            <Row>
              <Col xs={24} sm={24} md={8} lg={8}>
                <h4 className="fnb-form-label material-view-lable-text-color">{pageData.name}</h4>
                <FnbTooltip onlyShowWhenEllipsis title={initData?.material?.name ?? "-"}>
                  <FnbTypography text={initData?.material?.name ?? "-"} />
                </FnbTooltip>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <h4 className="fnb-form-label material-view-lable-text-color">{pageData.sku}</h4>
                <FnbTooltip onlyShowWhenEllipsis title={initData?.material?.sku ?? "-"}>
                  <FnbTypography text={initData?.material?.sku ?? "-"} />
                </FnbTooltip>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <h4 className="fnb-form-label material-view-lable-text-color">{pageData.category}</h4>
                <FnbTooltip onlyShowWhenEllipsis title={initData?.material?.materialCategory?.name ?? "-"}>
                  <FnbTypography text={initData?.material?.materialCategory?.name ?? "-"} />
                </FnbTooltip>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <h4 className="fnb-form-label material-view-lable-text-color">{pageData.description}</h4>
                <div className="material-view-text material-view-description-style">
                  {isShowLessDescription ? (
                    <span>{shortDescription}</span>
                  ) : (
                    <span>{initData?.material?.description ?? "-"}</span>
                  )}
                  {isShowLink ? (
                    isShowLessDescription ? (
                      <FnbTypography.Link
                        text={" " + pageData.viewMore}
                        onClick={() => handleClickViewMore(true)}
                        underline={true}
                      ></FnbTypography.Link>
                    ) : (
                      initData?.material?.description && (
                        <FnbTypography.Link
                          text={" " + pageData.viewLess}
                          onClick={() => handleClickViewMore(false)}
                          underline={true}
                        ></FnbTypography.Link>
                      )
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </FnbCard>
          <FnbCard title={pageData.priceAndConversion} className="margin-b">
            <div className="text-title-underlined"></div>
            <Row>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Row>
                  <h4 className="fnb-form-label material-view-lable-text-color">{pageData.currentCostPerUnit}</h4>
                  <FnbTooltip
                    className="fnb-form-label-tooltip"
                    placement="top"
                    title={renderToolTipCostPerUnit()}
                    variant="secondary"
                    width={385}
                  >
                    <ExclamationIcon width={14} height={14} />
                  </FnbTooltip>
                </Row>
                <Row>
                  <p className="material-view-cost-per-unit-text">
                    {costPerUnitNumber ? (
                      <b>{`${formatNumberDecimalOrInteger(costPerUnitNumber)} ${getCurrency()} / ${
                        initData?.material?.unitName
                      }`}</b>
                    ) : (
                      <b>{`0 ${getCurrency()} / ${initData?.material?.unitName}`}</b>
                    )}
                  </p>
                </Row>
                {initData?.unitConversions?.length > 0 && (
                  <>
                    <Row>
                      <h4 className="fnb-form-label material-view-lable-text-color">{pageData.minQuantity}</h4>
                      <FnbTooltip
                        className="fnb-form-label-tooltip"
                        placement="top"
                        title={() => renderTooltipMinimumStock()}
                        variant="secondary"
                        width={420}
                      >
                        <ExclamationIcon width={14} height={14} />
                      </FnbTooltip>
                    </Row>
                    <Row>
                      <FnbTooltip onlyShowWhenEllipsis>
                        <FnbTypography text={initData?.material?.minQuantity + " " + initData?.material?.unitName} />
                      </FnbTooltip>
                    </Row>
                  </>
                )}
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                {initData?.unitConversions?.length > 0 ? (
                  <>
                    <Row>
                      <h4 className="fnb-form-label material-view-lable-text-color">{pageData.unitConversions}</h4>
                    </Row>
                    <Row>
                      <Col>
                        {initData?.unitConversions.map((item) => (
                          <>
                            <p className="material-view-unit-conversions-text">
                              {`1 ${item?.unitName} = ` +
                                formatNumberDecimalOrInteger(`${item?.capacityWithPreUnitConversion}`) +
                                " " +
                                `${item?.preUnitConversionName ?? initData?.material?.unitName}`}
                              {item?.extendPreUnitConversions
                                ? ` ( ` +
                                  formatNumberDecimalOrInteger(`${item?.extendPreUnitConversions?.capacity}`) +
                                  " " +
                                  `${item?.extendPreUnitConversions?.name ?? initData?.material?.unitName} )`
                                : ""}
                            </p>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <h4 className="fnb-form-label material-view-lable-text-color">{pageData.minQuantity}</h4>
                      <FnbTooltip
                        className="fnb-form-label-tooltip"
                        placement="top"
                        title={() => renderTooltipMinimumStock()}
                        variant="secondary"
                        width={420}
                      >
                        <ExclamationIcon width={14} height={14} />
                      </FnbTooltip>
                    </Row>
                    <Row>
                      <FnbTooltip onlyShowWhenEllipsis>
                        <FnbTypography text={initData?.material?.minQuantity + " " + initData?.material?.unitName} />
                      </FnbTooltip>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </FnbCard>
          <FnbCard title={pageData.ingredientQuantityInStockByBranch} className="margin-b">
            <div className="text-title-underlined"></div>
            <FnbTable
              columns={getColumnsQuantityInStock()}
              pageSize={pageSize}
              dataSource={listQuantityInStock}
              currentPageNumber={currentPageNumberQuantityInStock}
              total={totalQuantityInStock}
              onChangePage={onChangePageQuantityInStock}
              className="quantity-in-stock-table"
            />
          </FnbCard>
          <FnbCard title={pageData.ingredientHistory}>
            <div className="text-title-underlined"></div>
            <FnbTable
              columns={getColumnsIngredientHistory()}
              pageSize={pageSize}
              dataSource={listIngredientHistory}
              currentPageNumber={currentPageNumberIngredientHistory}
              total={totalIngredientHistory}
              onChangePage={onChangePageIngredientHistory}
              className="ingredient-history-table"
            />
          </FnbCard>
        </div>
        <div className="view-ingredient-right">
          <FnbCard className="margin-b">
            <div className="form-image">
              <div className="content-img">
                <Image width={"100%"} src={initData?.material?.thumbnail ?? "error"} fallback={images.imgDefault} />
              </div>
            </div>
          </FnbCard>
          <FnbCard>
            <div className="form-other-settings">
              <FnbTypography className="title-other-setting" text={pageData.othersSettings.title} />
              <Row className="isHasExpiryDate-title">
                {initData?.material?.hasExpiryDate
                  ? pageData.othersSettings.haveExpiryDate
                  : pageData.othersSettings.notHaveExpiryDate}
              </Row>
            </div>
          </FnbCard>
        </div>
      </Content>
    </>
  );
}
