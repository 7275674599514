import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StepsSystemGuidelineKeysConstants, TOTAL_STEP_SYSTEM_GUIDELINE } from "constants/system-guideline.constants";
import storeDataService from "data-services/store/store-data.service";

const PREFIX = "systemGuideline";

const initialState = {
  storeId: undefined,
  defaultBranchId: undefined,
  stepStatus: {
    [StepsSystemGuidelineKeysConstants.ADD_INGREDIENT]: false,
    [StepsSystemGuidelineKeysConstants.ADD_PRODUCT]: false,
    [StepsSystemGuidelineKeysConstants.BUILD_WEBSITE]: false,
    [StepsSystemGuidelineKeysConstants.OPERATION]: false,
    [StepsSystemGuidelineKeysConstants.PAYMENT_METHOD]: false,
    [StepsSystemGuidelineKeysConstants.DELIVERY_METHOD]: false,
    [StepsSystemGuidelineKeysConstants.SET_UP_ADDRESS]: false,
  },
  totalStepCompleted: 0,
  totalStepNeedToCompleted: TOTAL_STEP_SYSTEM_GUIDELINE,
  isActivatedPackageWeb: false,
};

export const getSystemGuidelineAsync = createAsyncThunk(`${PREFIX}/getSystemGuidelineAsync`, async () => {
  const response = await storeDataService.getStatusSystemGuidelineAsync();
  return response;
});

const systemGuidelineSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    resetState: (state) => {
      state.storeId = undefined;
      state.defaultBranchId = undefined;
      state.stepStatus = {
        [StepsSystemGuidelineKeysConstants.ADD_INGREDIENT]: false,
        [StepsSystemGuidelineKeysConstants.ADD_PRODUCT]: false,
        [StepsSystemGuidelineKeysConstants.BUILD_WEBSITE]: false,
        [StepsSystemGuidelineKeysConstants.OPERATION]: false,
        [StepsSystemGuidelineKeysConstants.PAYMENT_METHOD]: false,
        [StepsSystemGuidelineKeysConstants.DELIVERY_METHOD]: false,
        [StepsSystemGuidelineKeysConstants.SET_UP_ADDRESS]: false,
      };
      state.totalStepCompleted = 0;
      state.totalStepNeedToCompleted = TOTAL_STEP_SYSTEM_GUIDELINE;
      state.isActivatedPackageWeb = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSystemGuidelineAsync.fulfilled, (state, action) => {
      if (action.payload?.succeeded) {
        const { defaultBranchId, storeId, stepStatus, totalStepCompleted, isActivatedPackageWeb } = action.payload.data;
        state.defaultBranchId = defaultBranchId;
        state.stepStatus = stepStatus;
        state.totalStepCompleted = totalStepCompleted;
        state.storeId = storeId;
        state.isActivatedPackageWeb = isActivatedPackageWeb;
        state.totalStepNeedToCompleted = isActivatedPackageWeb
          ? TOTAL_STEP_SYSTEM_GUIDELINE
          : TOTAL_STEP_SYSTEM_GUIDELINE - 1;
      }
    });
  },
});

export const systemGuidelineActions = systemGuidelineSlice.actions;
export const systemGuidelineSelector = (state) => state.systemGuideline;

const systemGuidelineReducer = systemGuidelineSlice.reducer;
export default systemGuidelineReducer;
