import React from "react";
import { Collapse, Popover } from "antd";
import { FolderIcon, OutlineMoreIcon } from "constants/icons.constants";
import "./fnb-collapse.scss";
import { t } from "i18next";

const { Panel } = Collapse;
function FnbCollapse({
  className = "",
  listItems = [],
  defaultActiveKey = 1,
  activeKey = "",
  bordered = false,
  showArrow = true,
  expandIconPosition = "start", // start, end
  collapsible = "", //'header' | 'icon' | 'disabled';
  accordion = true,
  onChange,
  header,
  listButtons = [],
  setKey,
  emptyText,
  category, //Localization category ex: category, unit, area...
}) {
  const genExtra = (key) => (
    <Popover
      content={
        <>
          {listButtons.map((button) => {
            return React.cloneElement(button, {});
          })}
        </>
      }
      trigger="click"
      placement="bottom"
      overlayClassName="popover-button-collapse"
    >
      <OutlineMoreIcon
        onClick={(event) => {
          setKey(key);
          event.stopPropagation();
        }}
      />
    </Popover>
  );

  const getItems = listItems.map((item) => {
    const { key, label, total, children, className, style } = item;
    const totalTable = `-  ${total || 0} ${category || t("areaTable.tables")}`;
    const labelHtml = (
      <>
        <span class="text-b1-bold">{label}</span>
        {<span class="text-b1-regular">{totalTable}</span>}
      </>
    );

    return (
      <Panel
        id={key}
        header={labelHtml}
        key={key}
        className={className}
        style={style}
        showArrow={showArrow}
        extra={genExtra(key)}
      >
        {children}
      </Panel>
    );
  });

  return (
    <>
      {header && (
        <div className="fnb-header-collapse">
          <div className="fnb-table-wrapper">{getItems.length > 0 && header}</div>
        </div>
      )}
      {getItems.length === 0 && (
        <div className="content-collapse-empty">
          <div className="border-top-collapse"></div>
          <p className="text-center" style={{ marginBottom: "12px", marginTop: "105px" }}>
            <FolderIcon />
          </p>
          <p className="text-center body-2 mb-0">{t("table.noDataFound")}</p>
          <p className="text-center body-2 content-collapse-empty-text" style={{ marginBottom: "181px" }}>
            {emptyText}
          </p>
        </div>
      )}
      <div className="content-collapse-admin">
        <Collapse
          className={`fnb-collapse-admin ${className}`}
          bordered={bordered}
          defaultActiveKey={[defaultActiveKey]}
          activeKey={activeKey}
          onChange={onChange}
          expandIconPosition={expandIconPosition}
          collapsible={collapsible}
          accordion={accordion}
        >
          {getItems}
        </Collapse>
      </div>
    </>
  );
}

export default FnbCollapse;
