export const DateFormat = {
  DD_MM_YYYY: "DD/MM/YYYY",
  YYYY_MM_DD: "YYYY/MM/DD",
  MM_DD_YYYY: "MM/DD/YYYY",
  HH_MM_DD_MM_YYYY: "hh:mm A DD/MM/yyyy",
  DD_MM_YYYY_HH_MM_SS: "DD/MM/YYYY, hh:mm:ss",
  MM_DD_YYYY_HH_MM_SS: "MM/DD/YYYY, hh:mm:ss",
  DD_MM_YYYY_DASH: "DD-MM-YYYY",
  DDMMYYYYHHmmss: "DDMMYYYYHHmmss",
  YYYY_MM_DD_HH_MM_SS: "yyyy-MM-DD HH:mm:ss",
  YYYY_MM_DD_HH_MM_SS_2: "YYYY-MM-DD HH:mm:ss",
  HH_MM: "HH:mm",
  HH_MM_DD_MM_YYYY_: "HH:mm DD/MM/yyyy",
  HH_MM____DD_MM_YYYY_: "HH:mm      DD/MM/yyyy",
  YYYY_MM_DD_HH_MM: "yyyy-MM-DD HH:mm",
  DD_MM_YYYY_HH_MM: "DD/MM/YYYY, HH:mm",
  DD_MM_YYYY_HH_MM_SS_: "DD/MM/YYYY HH:mm:ss",
  DD_MM: "DD/MM",
  DD_MM_YYYY_HH_MM_NO_COMMA: "DD/MM/YYYY HH:mm",
  HH_MM_dash_DD_MM_YYYY: "HH:mm - DD/MM/YYYY",
  HH_MM_DD_MMMM_YYYY_: "HH:mm D MMMM YYYY",
  HH_MM_D_MMMM_YYYY: "hh:mm DD MMM YYYY", //07:16 18 Mar 2024 , 07:16 18 Thg 03 2024
  DD_MMM_YYYY: "DD MMM YYYY", //18 Mar 2024
  DD_MMM_YYYY_HH_MM: "DD MMM YYYY HH:mm", //18 Mar 2024 19:16
  HH_mm_DD_MMM_YYYY: "HH:mm DD MMM YYYY", // 19:16 18 Mar 2024
};

export const DefaultConstants = {
  ADMIN_ACCOUNT: "ADMIN",
  STAFF_ACCOUNT: "STAFF",
};

export const currency = {
  vnd: "VND",
  d: "đ",
  usd: "USD",
};

export const DefaultCountryISO = {
  vn: "VN",
};

export const LanguageCodeEnum = {
  En: "en",
  Vi: "vi",
  Ja: "ja",
};

export const TrackingSteps = {
  error: "error",
  process: "process",
};

export const Percent = "%";

export const ClassicMember = "Classic Member";

export const NoDataFound = "No Data Found";

export const EnDash = "-";

export const RevenueMaxValue = 500;

export const OrderMaxValue = 10;

export const ImageSizeDefault = 5242880;

export const StoreLogoSizeLimit = 10485760;

export const AllowedNumberOfPhotosDefault = 1;

export const contentArticleEmailCampaign = {
  mainArticle: 0,
  firstSubArticle: 1,
  secondSubArticle: 2,
};

export const guidIdEmptyValue = "00000000-0000-0000-0000-000000000000";

export const theValueLastDayOfTheWeekend = 7;

// This pattern allows enter space at the before and after of the email string
export const emailPattern = /^\s*[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}\s*$/;

export const MaxCharacterForLongText = 65;

export const MaxNumberInput = 999999999;

export const blank = "_blank";

export const blankWindowDimensions = "height=650,width=570";

export const paymentStatus = {
  successPayment: "Successful",
  failedPayment: "Failed",
  newPayment: "WaitingForPayment",
};

export const EnvironmentConstants = {
  DEVELOPMENT: "development",
  QA: "qa",
  STAGING: "staging",
  PRODUCTION: "production"
};