import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import contactHeaderImageDefault from "../../../../assets/images/contact-header-default.png";
import { backgroundTypeEnum, theme1ElementCustomize } from "../../../../constants/store-web-page.constants";
import "./contact.styles.scss";

const ContactHeader = (props) => {
  const { isCustomize, config, clickToFocusCustomize, general } = props;
  const { header } = config;
  const colorGroup =
    general?.color?.colorGroups?.find((a) => a?.id === header?.colorGroupId) ?? general?.color?.colorGroups?.[0];
  const [t] = useTranslation();

  const translateData = {
    contact: t("contact.contactTitle", "Contact"),
  };

  function getBackgroundContactHeader() {
    let styles = {};
    if (header?.backgroundType === backgroundTypeEnum.Color) {
      styles = {
        backgroundColor: header?.backgroundColor,
      };
    } else {
      styles = {
        background: `url(${
          header?.backgroundImage ? header?.backgroundImage : contactHeaderImageDefault
        }) no-repeat center center`,
      };
    }
    return styles;
  }

  return (
    <Row
      className={`contact-wrapper-theme1 ${isCustomize ? "contact-header-customize-theme1" : "contact-header-theme1"}`}
      onClick={() => clickToFocusCustomize && clickToFocusCustomize(theme1ElementCustomize.HeaderContact)}
    >
      <Col className="contact-section container-fluid contact-header" style={getBackgroundContactHeader()}>
        <h1 style={{ color: colorGroup?.textColor }}>{header?.title ?? translateData.contact}</h1>
      </Col>
    </Row>
  );
};

export default ContactHeader;
