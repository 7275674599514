import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import discountCodeDataService from "../../../data-services/discount-code-data.service";
import { formatDateLocale, formatTextNumber, getCurrencySymbol, getStoreConfig } from "../../../utils/helpers";
import { CheckDiscountCodeIcon, DatetimeDiscountCodeIcon, PercentDiscountCodeIcon } from "../../assets/icons.constants";
import { ListPromotionType } from "../../constants/enums";
import { DateFormat, Percent, currency } from "../../constants/string.constants";
import { DiscountCodeDetailComponent } from "../discount-code-detail-component/discount-code-detail.component";
import "./discount-code-card.component.scss";

export function DiscountCodeCard(props) {
  const {
    data,
    isShowRedeem,
    isHomepage,
    onClickRedeem,
    onClickUse,
    isActive,
    isCheckOutPage,
    isApply,
    colorConfig,
    hiddenDiscount,
    isPromotionCard = false,
  } = props;
  const isDiscountCode = data?.code ?? false;
  const currencySymbol = getCurrencySymbol();
  const [isOpenDiscountCodeDetail, setIsOpenDiscountCodeDetail] = useState(false);
  const [discountCodeDetail, setDiscountCodeDetail] = useState(null);
  const languageCode = useSelector((state) => state.session?.languageSession?.default?.languageCode);
  const [t] = useTranslation();
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });

  const translateData = {
    currentlyBeingApplied: t("promotion.discountCode.currentlyBeingApplied", "Đang được áp dụng"),
    use: t("promotion.discountCode.use", "Sử dụng"),
    unChecked: t("promotion.discountCode.unChecked", "Bỏ chọn"),
    redeem: t("promotion.discountCode.redeem", "Lấy mã"),
    unlimited: t("promotion.discountCode.unlimited", "Không có ngày hết hạn"),
  };

  const onHandleClickUse = (code) => {
    onClickUse(code);
  };

  const renderEndDateStore = (endDate) => {
    if (!endDate) {
      return <span>{translateData.unlimited}</span>;
    }
    return <span>{formatDateLocale(data?.endDate, DateFormat.ll_HH_MM, languageCode ?? "VN")}</span>;
  };

  const showDiscountCodeDetail = async () => {
    const storeConfig = getStoreConfig();
    const discountCodeDetail = await discountCodeDataService.getDiscountCodeDetailByIdAsync(
      storeConfig?.storeId,
      data?.id,
    );
    if (discountCodeDetail?.data?.discountCode) {
      // Mobile device got issue not show DiscountCodeDetailComponent when hiddenDiscount
      !isMaxWidth575 && hiddenDiscount && hiddenDiscount(true);
      setIsOpenDiscountCodeDetail(true);
      setDiscountCodeDetail(discountCodeDetail?.data?.discountCode);
    }
  };

  const handleCloseDiscountCodeDetail = () => {
    hiddenDiscount && hiddenDiscount(false);
    setIsOpenDiscountCodeDetail(false);
  };
  return (
    <div
      className={`fnb-discount-code-card-theme-1 ${isCheckOutPage ? "discount-code-card-check-out-modal" : ""} ${
        isActive && "apply"
      } ${isPromotionCard ? "promotion-card" : ""}`}
    >
      <DiscountCodeDetailComponent
        discountCodeDetail={discountCodeDetail}
        isOpenDiscountCodeDetail={isOpenDiscountCodeDetail}
        handleCancel={handleCloseDiscountCodeDetail}
        colorConfig={colorConfig}
      />
      {!isPromotionCard && (
        <div className={`discount-code-apply ${isActive && "apply-total"}`}>
          <CheckDiscountCodeIcon />
          <span>{translateData.currentlyBeingApplied}</span>
        </div>
      )}
      <div
        className={`${
          isShowRedeem
            ? "discount-code-content"
            : isApply || !isDiscountCode || isHomepage
            ? "discount-code-content-store-discount"
            : "discount-code-content-store-discount disabled"
        }`}
      >
        <div className="discount-code-content-name" onClick={showDiscountCodeDetail}>
          <div>
            <span className="discount-icon">
              <PercentDiscountCodeIcon />
            </span>
          </div>
          <span className="text-line-clamp-1">{data?.name}</span>
        </div>
        {data?.code && (
          <div className="discount-code-code">
            <span>{data?.code}</span>
          </div>
        )}
        <div className="discount-code-type-redeem">
          <p className="discount-code-type" style={{maxWidth: isDiscountCode ? (isActive ? 200 : 260) : '100%'}}>
            {ListPromotionType?.map((item) => {
              let discountType =
                data?.discountCodeTypeId !== undefined ? data?.discountCodeTypeId : data?.promotionTypeId;
              if (item.key === discountType) {
                return t(item.name);
              }
            })}
          </p>
          {isShowRedeem === true && !isMaxWidth575 && (
            <p className="discount-code-redeem" onClick={() => onClickRedeem(data?.id)}>
              {translateData.redeem}
            </p>
          )}
        </div>
        <div className="discount-code-currency">
          <div className="value">
            <span>
              {formatTextNumber(data?.isPercentDiscount === true ? data?.percentNumber : data?.maximumDiscountAmount)}
            </span>
          </div>
          <div className="currency">
            <span>{data?.isPercentDiscount === true ? Percent : currencySymbol}</span>
          </div>
        </div>
        <hr className="discount-code-hr" />
        <div className="discount-code-inverted"></div>
        <div className="discount-code-datetime">
          {
            <>
              <DatetimeDiscountCodeIcon />
              {isShowRedeem === true && data?.endDate ? (
                <span>{formatDateLocale(data?.endDate, DateFormat.ll_HH_MM, languageCode ?? "VN")}</span>
              ) : (
                renderEndDateStore(data?.endDate)
              )}
            </>
          }

          <span>
            {isShowRedeem === true && isMaxWidth575 && (
              <p className="discount-code-redeem" onClick={() => onClickRedeem(data?.id)}>
                {translateData.redeem}
              </p>
            )}
          </span>
        </div>
        {isHomepage === true || (
          <>
            <div
              className={`discount-code-card${isActive ? "-active" : isApply ? "" : " disabled"}`}
              onClick={() => {
                onHandleClickUse(data?.code);
              }}
            >
              <span>{isActive ? translateData.unChecked : translateData.use}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
