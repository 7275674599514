import { Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import resetPasswordService from "../../../../../data-services/reset-password-data.service";
import { authActions, resetPasswordSelector, STATUS_RESET_PASSWORD } from "../../../../../modules/auth/auth.reducer";
import { localStorageKeys } from "../../../../../utils/localStorage.helpers";
import { ArrowLeftForgotPassword } from "../../../../assets/icons.constants";
import { EnumLogin } from "../../../../constants/login.constants";
import { ButtonConfirm, IconBackForgotPassword, TitleHeading } from "./forgot-password.styled";
import { Heading, InputNewpasswordForm, ListFormItemContainer } from "./input-new-password.styled";
import { parsePhoneNumber } from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const FORM_ITEM_NAME = {
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirmPassword",
};

const MINIMUM_LENGTH_PASSWORD = 6;

const InputNewPassword = ({ themePageConfig, t, setMode, setIsLoginByPassword }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { token, phoneNumber, iso } = useSelector(resetPasswordSelector);
  const history = useHistory();
  const [requestingSetPassword, setRequestingSetPassword] = useState(false);

  const pageData = {
    changePassword: t("myProfile.accountInfo.changePassword"),
    inputNewPassword: t("resetPassword.inputNewPassword"),
    inputConfirmPassword: t("resetPassword.inputConfirmPassword"),
    pleaseInputYourPassword: t("resetPassword.pleaseInputYourPassword"),
    pleaseInputYourConfirmPassword: t("resetPassword.pleaseInputYourConfirmPassword"),
    atLeast6Characters: t("loginPage.invalidPassword"),
    passwordNotMatch: t("resetPassword.passwordNotMatch"),
    confirm: t("myProfile.accountInfo.confirm"),
    resetPasswordSuccessfully: t("resetPassword.resetPasswordSuccessfully"),
  };

  const onFinish = async (event) => {
    const payload = {
      phoneNumber: parsePhoneNumber(phoneNumber, iso).nationalNumber,
      storeId: JSON.parse(localStorage.getItem(localStorageKeys.STORE_CONFIG))?.storeId,
      password: event[FORM_ITEM_NAME.PASSWORD],
      confirmPassword: event[FORM_ITEM_NAME.CONFIRM_PASSWORD],
      token: token?.token,
    };
    setRequestingSetPassword(true);
    const response = await resetPasswordService.resetPasswordAsync(payload);
    if (response?.data?.succeeded) {
      message.success(pageData.resetPasswordSuccessfully);
      setMode(EnumLogin.LOGIN);
      setIsLoginByPassword(true);
      dispatch(authActions.resetState());
      history.push("/login");
    } else {
      message.error(t(response?.data?.message));
    }
    setRequestingSetPassword(false);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    if (
      password?.length >= MINIMUM_LENGTH_PASSWORD &&
      password === form.getFieldValue(FORM_ITEM_NAME.CONFIRM_PASSWORD)
    ) {
      form.setFields([
        {
          name: FORM_ITEM_NAME.CONFIRM_PASSWORD,
          errors: [],
        },
      ]);
    }
  };

  return (
    <InputNewpasswordForm>
      <Heading>
        <IconBackForgotPassword loading={requestingSetPassword}>
          <ArrowLeftForgotPassword
            onClick={() => {
              if (!requestingSetPassword) {
                dispatch(authActions.setResetPasswordState({ currentStatus: STATUS_RESET_PASSWORD.INPUT_OTP }));
              }
            }}
          />
        </IconBackForgotPassword>
        <TitleHeading style={{ color: themePageConfig?.colorGroup?.titleColor }}>
          {pageData.changePassword}
        </TitleHeading>
      </Heading>
      <Form form={form} onFinish={onFinish}>
        <ListFormItemContainer>
          <Form.Item
            name={FORM_ITEM_NAME.PASSWORD}
            rules={[
              {
                required: true,
                message: pageData.pleaseInputYourPassword,
              },
              {
                message: pageData.atLeast6Characters,
                validator: (_, value) => {
                  if (value?.length > 0 && value?.length < 6) return Promise.reject();
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder={pageData.inputNewPassword} onChange={onChangePassword} />
          </Form.Item>
          <Form.Item
            name={FORM_ITEM_NAME.CONFIRM_PASSWORD}
            rules={[
              {
                required: true,
                message: pageData.pleaseInputYourConfirmPassword,
              },
              {
                message: pageData.passwordNotMatch,
                validator: (_, value) => {
                  if (value?.length > 0 && value !== form.getFieldValue(FORM_ITEM_NAME.PASSWORD)) {
                    return Promise.reject();
                  } else return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder={pageData.inputConfirmPassword} />
          </Form.Item>
        </ListFormItemContainer>
      </Form>
      <ButtonConfirm
        backgroundColor={themePageConfig?.colorGroup?.buttonBackgroundColor}
        textColor={themePageConfig?.colorGroup?.buttonTextColor}
        onClick={() => form.submit()}
        loading={requestingSetPassword}
      >
        {pageData.confirm}
      </ButtonConfirm>
    </InputNewpasswordForm>
  );
};

export default InputNewPassword;
