import { unwrapResult } from "@reduxjs/toolkit";
import { Card, Col, Form, Row, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingleIcon } from "components/fnb-select-single-icon/fnb-select-single-icon";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { BANK_DATA } from "constants/bank.constants";
import { GeneralConfigInfoCircle } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DefaultCountryISO } from "constants/string.constants";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { settingsSelector, updateBankInfoAsync } from "store/modules/settings/settings.reducer";
import i18n from "utils/i18n";
import "../store-general-configuration.style.scss";
import { env } from "env";
import { Region } from "constants/region.constants";
import { getUserInfo } from "services/auth.service";
import { EnumStoreRegion } from "constants/default.constants";

export const CardBankAccount = forwardRef((props, ref) => {
  const { t, className, generalAddress, isConfirmOk = false } = props;
  const dataBankInfo = BANK_DATA;
  const [formBankAccount] = Form.useForm();
  const dispatch = useDispatch();
  const [initDataAddress, setInitDataAddress] = useState(null);
  const [isDefaultBankCountry, setIsDefaultBankCountry] = useState(env.REACT_APP_REGION === Region.Local);
  const [cities, setCities] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [acpId, setAcpId] = useState("");
  const [bankName, setBankName] = useState("");
  const [prepareAddressDataResponse, setPrepareAddressDataResponse] = useState(null);
  // const [storeBankAccountRespon se, setStoreBankAccountResponse] = useState(null);
  const [countryOption, setCountryOption] = useState([]);
  const [isCheckboxProvice, setIsCheckboxProvince] = useState(false);
  const isMobileMode = useMediaQuery({ maxWidth: 1000 });
  const [storeDataResponse, setStoreDataResponse] = useState(null);
  const bankAccountInfo = useSelector(settingsSelector)?.bankAccountInfo;
  const loggedUserInfo = getUserInfo();
  const isInternationalRegion = loggedUserInfo?.storeType === EnumStoreRegion.INTERNATIONAL;

  useEffect(() => {
    setIsDefaultBankCountry(!isInternationalRegion);
  }, [isInternationalRegion]);

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSaveChanges: t("button.saveChanges"),
    btnUpdate: t("button.update"),
    enter: t("form.enter"),
    storeName: t("registerAccount.storeName"),
    store: t("settings.tabStore"),
    updateSuccess: t("messages.isUpdatedSuccessfully"),
    province: t("form.province"),
    selectProvince: t("form.selectProvince"),
    validSelectProvince: t("form.validSelectProvince"),
    leaveForm: t("messages.leaveForm"),
    confirmation: t("leaveDialog.confirmation"),
    confirmLeave: t("button.confirmLeave"),
    country: t("form.country"),
    discard: t("button.discard"),
    titleBank: t("storeBankAccount.title"),
    accountHolder: t("storeBankAccount.accountHolder"),
    accountNumber: t("storeBankAccount.accountNumber"),
    bankName: t("storeBankAccount.bankName"),
    bankBranchName: t("storeBankAccount.bankBranchName"),
    swiftCode: t("storeBankAccount.swiftCode"),
    routingNumber: t("storeBankAccount.routingNumber"),
    swiftCodeMaxLength: 65,
    routingNumberMaxLength: 100,
    kitchenSetting: t("store.kitchenSetting"),
    storeHasKitchen: t("store.storeHasKitchen.title"),
    ifNotHaveKitchen: t("store.storeHasKitchen.tooltip.ifNotHaveKitchen"),
    orderNotHaveKitchen: t("store.storeHasKitchen.tooltip.orderNotHaveKitchen"),
    ifHasKitchen: t("store.storeHasKitchen.tooltip.ifHasKitchen"),
    orderHasKitchen: t("store.storeHasKitchen.tooltip.orderHasKitchen"),
    autoPrintStamp: t("store.autoPrintStamp.title"),
    operation: t("store.operation"),
    paymentFirst: t("store.paymentFirst"),
    paymentLater: t("store.paymentLater"),
    paymentType: t("store.paymentType"),
    tooltipPaymentFirst: t("store.tooltipPaymentFirst"),
    tooltipPaymentLater: t("store.tooltipPaymentLater"),
    inventoryTitle: t("table.inventory"),
    inventory: t("store.inventory"),
    tooltipInventory: t("store.tooltipInventory"),
    generalInformation: t("title.generalInformation"),
    automaticallySaveBank: t("storeBankAccount.automaticallySaveBank"),
    helpText: t("storeBankAccount.helpText"),
    inputAccountHolder: t("BranchBankTransfer.inputAccountHolder"),
    validAccountHolder: t("BranchBankTransfer.validAccountHolder"),
    selectBankName: t("BranchBankTransfer.selectBankName"),
    validBankName: t("BranchBankTransfer.validBankName"),
    inputBankName: t("BranchBankTransfer.inputBankName"),
    validInputBankName: t("BranchBankTransfer.validInputBankName"),
    allowNumberOnly: t("form.allowNumberOnly"),
    inputAccountNumber: t("BranchBankTransfer.inputAccountNumber"),
    validAccountNumber: t("BranchBankTransfer.validAccountNumber"),
    selectCountry: t("BranchBankTransfer.selectCountry"),
    validCountry: t("BranchBankTransfer.validCountry"),
    mustBeBetweenThreeAndOneHundredsCharacters: t("form.mustBeBetweenThreeAndOneHundredsCharacters"),
    checkAddress: t("BranchBankTransfer.checkAddress"),
  };

  useImperativeHandle(ref, () => ({
    setInitBankAccountData(prepareAddressDataResponse, storeDataResponse) {
      setInitDataAddress(prepareAddressDataResponse);
      setPrepareAddressDataResponse(prepareAddressDataResponse);
      setStoreDataResponse(storeDataResponse);
      initDataBankAccount(prepareAddressDataResponse, storeDataResponse);
    },
  }));

  useEffect(() => {
    handleChangeForm(isChangeForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeForm]);

  const handleChangeForm = () => {
    const { onChangeForm } = props;
    if (onChangeForm) onChangeForm(isChangeForm);
  };

  useEffect(() => {
    const errorFields = formBankAccount
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    formBankAccount.validateFields(errorFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const initDataBankAccount = (prepareAddressDataResponse, storeDataResponse) => {
    const { countries, cities, defaultCountry } = prepareAddressDataResponse;
    setCities(cities);
    countryOptionSelector(countries);
    setIsCheckboxProvince(false);

    if (Object.keys(bankAccountInfo || {}).length !== 0) {
      if (bankAccountInfo) {
        formBankAccount.setFieldsValue({
          storeBankAccount: {
            ...bankAccountInfo,
          },
        });
        onChangeCountryBank(bankAccountInfo?.countryId);
        if (bankAccountInfo?.acpId) {
          setBankName(dataBankInfo?.find((bank) => bank?.bin === bankAccountInfo?.acpId)?.name);
          formBankAccount.setFieldValue(["storeBankAccount", "acpId"], bankAccountInfo?.acpId);
        } else {
          setBankName(bankAccountInfo?.bankName);
          formBankAccount.setFieldValue(["storeBankAccount", "bankName"], bankAccountInfo?.bankName);
        }
      } else {
        onChangeCountryBank(prepareAddressDataResponse?.defaultCountryStore?.id);
        let formValue = formBankAccount.getFieldsValue();
        formValue.storeBankAccount.countryId = prepareAddressDataResponse?.defaultCountryStore?.id;
        formBankAccount.setFieldsValue(formValue);
      }

      if (isInternationalRegion) {
        setIsDefaultBankCountry(false);
      } else {
        bankAccountInfo?.countryId === defaultCountry?.id
          ? setIsDefaultBankCountry(true)
          : setIsDefaultBankCountry(false);
      }
    } else {
      if (isInternationalRegion) {
        setIsDefaultBankCountry(false);
      } else {
        storeDataResponse?.isDefaultCountry ? setIsDefaultBankCountry(true) : setIsDefaultBankCountry(false);
      }
      formBankAccount.setFieldValue(["storeBankAccount", "countryId"], storeDataResponse?.store?.address?.countryId);
      formBankAccount.setFieldValue(["storeBankAccount", "acpId"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "bankName"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "bankBranchName"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "accountHolder"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "accountNumber"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "cityId"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "swiftCode"], null);
      formBankAccount.setFieldValue(["storeBankAccount", "routingNumber"], null);
      setIsCheckboxProvince(false);
    }
  };

  const onChangeCountryBank = (countryId) => {
    if (isInternationalRegion) {
      setIsDefaultBankCountry(false);
    } else {
      if (
        countryId === initDataAddress?.defaultCountry?.id &&
        DefaultCountryISO.vn === initDataAddress?.defaultCountry?.iso
      ) {
        setIsDefaultBankCountry(true);
      } else {
        setIsDefaultBankCountry(false);
      }
    }

    formBankAccount.setFieldValue(["storeBankAccount", "acpId"], null);
    formBankAccount.setFieldValue(["storeBankAccount", "bankName"], null);
  };

  const onChangeForm = () => {
    if (!isChangeForm) {
      setIsChangeForm(true);
    }
    handleChangeForm(true);
  };

  const onSaveBankAccount = async () => {
    formBankAccount.validateFields().then(async (values) => {
      const { storeBankAccount } = values;
      const response = unwrapResult(await dispatch(updateBankInfoAsync({ ...storeBankAccount, bankName })));
      if (response) {
        message.success(`${pageData.titleBank} ${pageData.updateSuccess}`);
        setIsChangeForm(false);
      }
    });
  };

  const onChangeBankName = (bin) => {
    if (!bin) return;
    setAcpId(bin);
    setBankName(dataBankInfo.find((x) => x.bin === bin).name);
    setIsChangeForm(true);
  };

  const onChangeInputBankName = (e) => {
    setBankName(e.target.value || "");
  };

  const onCancel = () => {
    initDataBankAccount(prepareAddressDataResponse, storeDataResponse);
    setIsChangeForm(false);
  };

  useEffect(() => {
    if (bankAccountInfo && prepareAddressDataResponse) {
      initDataBankAccount(prepareAddressDataResponse, storeDataResponse);
      setIsChangeForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmOk, bankAccountInfo]);

  const countryOptionSelector = (countries) => {
    let result = countries?.map((country) => {
      return {
        label: (
          <Row className="col-country">
            <CircleFlag className="circle-icon" countryCode={country?.iso.toLowerCase()} height={20} />
            <div className="country-name">{t(country?.nicename)}</div>
          </Row>
        ),
        value: country?.id,
        labelSearch: country?.nicename,
      };
    });
    setCountryOption(result);
  };

  const renderInternational = () => {
    return (
      <Form form={formBankAccount} autoComplete="off" onChange={onChangeForm}>
        {cardTitleBox()}
        <Row className="my-24" gutter={[28, 28]}>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">
              {pageData.country}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "countryId"]}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validCountry} />,
                },
              ]}
              className="mb-0"
            >
              <FnbSelectSingleIcon
                size="large"
                showSearch
                autoComplete="none"
                onChange={(e) => {
                  onChangeCountryBank(e);
                  onChangeForm();
                }}
                option={countryOption}
              />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">
              {pageData.bankName}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "bankName"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validInputBankName} />
                  ),
                },
                { type: "string", warningOnly: true },
                {
                  type: "string",
                  max: 100,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={`${pageData.bankName} ${pageData.mustBeBetweenThreeAndOneHundredsCharacters}`}
                    />
                  ),
                },
              ]}
              className="mb-0"
            >
              <FnbInput
                showCount
                maxLength={100}
                className="fnb-input-with-count"
                placeholder={pageData.inputBankName}
                onChange={onChangeInputBankName}
              />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">
              {pageData.accountHolder}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "accountHolder"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validAccountHolder} />
                  ),
                },
                { type: "string", warningOnly: true },
                {
                  type: "string",
                  max: 100,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={`${pageData.accountHolder} ${pageData.mustBeBetweenThreeAndOneHundredsCharacters}`}
                    />
                  ),
                },
              ]}
              className="mb-0"
            >
              <FnbInput placeholder={`${pageData.enter} ${pageData.accountHolder}`} maxLength={100} />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">
              {pageData.accountNumber}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "accountNumber"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validAccountNumber} />
                  ),
                },
                {
                  pattern: /^\d+$/g,
                  max: 20,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.allowNumberOnly} />
                  ),
                },
              ]}
              className="mb-0"
            >
              <FnbInput placeholder={`${pageData.enter} ${pageData.accountNumber}`} maxLength={100} />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">{pageData.swiftCode}</h4>
            <Form.Item name={["storeBankAccount", "swiftCode"]} className="mb-0">
              <FnbInput
                maxLength={pageData.swiftCodeMaxLength}
                placeholder={`${pageData.enter} ${pageData.swiftCode}`}
              />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">{pageData.routingNumber}</h4>
            <Form.Item
              name={["storeBankAccount", "routingNumber"]}
              rules={[
                {
                  pattern: /^\d+$/g,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.allowNumberOnly} />
                  ),
                },
              ]}
              className="mb-0"
            >
              <FnbInput
                maxLength={pageData.routingNumberMaxLength}
                placeholder={`${pageData.enter} ${pageData.routingNumber}`}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const cardTitleBox = () => {
    return (
      <Row className="card-title-box">
        <Col span={18} className="sub-title-bank content-help">
          <Row className="w-100">
            <Col span={24} className="d-flex-align-center">
              <div className="icon-help">
                <GeneralConfigInfoCircle />
              </div>
              <div className="auto-save-title">{pageData.automaticallySaveBank}</div>
            </Col>
          </Row>
          <Row className="w-100 mt-2">
            <Col span={24} className="d-flex-align-center">
              <div className="icon-help">
                <GeneralConfigInfoCircle />
              </div>
              <div className="auto-save-title">{pageData.helpText}</div>
            </Col>
          </Row>
        </Col>
        <Col span={6} className="content-btn">
          {isChangeForm && (
            <div className="list-group-button">
              <FnbButton minWidth="100px" text={pageData.btnCancel} variant="tertiary" onClick={onCancel} />
              <FnbButton
                minWidth="140px"
                text={pageData.btnSaveChanges}
                onClick={onSaveBankAccount}
                permission={PermissionKeys.ADMIN}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  };

  const onChangeCheckboxProvice = (checked) => {
    setIsCheckboxProvince(checked.target.checked);
    if (!checked.target.checked) return;
    let formValue = formBankAccount.getFieldsValue();
    formValue.storeBankAccount.cityId = generalAddress?.city?.id;
    formBankAccount.setFieldsValue(formValue);
  };

  const renderDefaultBankCountry = () => {
    return (
      <Form form={formBankAccount} autoComplete="off" onChange={onChangeForm}>
        {cardTitleBox()}
        <Row className="my-24" gutter={[28, 28]}>
          <Col lg={isMobileMode ? 12 : 8} span={isMobileMode ? 24 : 16}>
            <h4 className="fnb-form-label">
              {pageData.country}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "countryId"]}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validCountry} />,
                },
              ]}
              className="mb-0"
            >
              <FnbSelectSingleIcon
                size="large"
                showSearch
                autoComplete="none"
                onChange={(e) => {
                  onChangeCountryBank(e);
                  onChangeForm();
                }}
                option={countryOption}
              />
            </Form.Item>
          </Col>
          <Col lg={isMobileMode ? 12 : 8} span={isMobileMode ? 24 : 16}>
            <h4 className="fnb-form-label">
              {pageData.bankName}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "acpId"]}
              rules={[
                {
                  message: <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validBankName} />,
                  required: true,
                },
              ]}
              className="mb-0"
            >
              <FnbSelectSingle
                placeholder={pageData.selectBankName}
                option={dataBankInfo?.map((item, index) => ({
                  id: item.bin,
                  name: item.shortName + " - " + item.name,
                  key: item.id,
                }))}
                onChange={onChangeBankName}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col lg={isMobileMode ? 12 : 8} span={isMobileMode ? 24 : 16}>
            <h4 className="fnb-form-label">{pageData.bankBranchName}</h4>
            <Form.Item name={["storeBankAccount", "bankBranchName"]} className="mb-0">
              <FnbInput placeholder={`${pageData.enter} ${pageData.bankBranchName}`} maxLength={100} />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">
              {pageData.accountHolder}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "accountHolder"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validAccountHolder} />
                  ),
                },
                { type: "string", warningOnly: true },
                {
                  type: "string",
                  max: 100,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={`${pageData.accountHolder} ${pageData.mustBeBetweenThreeAndOneHundredsCharacters}`}
                    />
                  ),
                },
              ]}
              className="mb-0"
            >
              <FnbInput placeholder={`${pageData.enter} ${pageData.accountHolder}`} maxLength={100} />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <h4 className="fnb-form-label">
              {pageData.accountNumber}
              <span className="required-field">*</span>
            </h4>
            <Form.Item
              name={["storeBankAccount", "accountNumber"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validAccountNumber} />
                  ),
                },
                {
                  pattern: /^\d+$/g,
                  max: 20,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.allowNumberOnly} />
                  ),
                },
              ]}
              className="mb-0"
            >
              <FnbInput placeholder={`${pageData.enter} ${pageData.accountNumber}`} maxLength={100} />
            </Form.Item>
          </Col>
          <Col lg={isMobileMode ? 12 : 24} span={isMobileMode ? 24 : 48}>
            <h4 className="fnb-form-label">{pageData.province}</h4>
            <Form.Item>
              <FnbCheckBox checked={isCheckboxProvice} onChange={onChangeCheckboxProvice}>
                {pageData.checkAddress}
              </FnbCheckBox>
            </Form.Item>
            <Form.Item name={["storeBankAccount", "cityId"]} className="mb-0">
              <FnbSelectSingle
                size="large"
                placeholder={pageData.selectProvince}
                showSearch
                disabled={isCheckboxProvice}
                autoComplete="none"
                onChange={onChangeForm}
                option={cities?.map((item, index) => ({
                  id: item.id,
                  name: item.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div className={className}>
      <Card className="fnb-card w-100 card-bank-account">
        {isDefaultBankCountry ? renderDefaultBankCountry() : renderInternational()}
      </Card>
    </div>
  );
});
