import { getInternationalRegion } from "../../../utils/helpers";
import {
  bestSellingProductDefaultInternational,
  blogDefaultInternational,
  categoryListDefaultInternational,
  discountCodeDefaultInternational,
  flashSaleDefaultInternational,
  signatureProductsInternational,
  storeDiscountDefaultInternational,
} from "./customize-default-data/en";
import {
  bestSellingProductDefaultLocal,
  blogDefaultLocal,
  categoryListDefaultLocal,
  discountCodeDefaultLocal,
  flashSaleDefaultLocal,
  signatureProductsLocal,
  storeDiscountDefaultLocal,
} from "./customize-default-data/vi";

const isInternationalRegion = getInternationalRegion();

export const bannerImgs = [
  {
    url: "javascript:;",
    image: "/images/default-theme/theme1-banner-default-first.png",
  },
  {
    url: "javascript:;",
    image: "/images/default-theme/theme1-banner-default-second.png",
  },
  {
    url: "javascript:;",
    image: "/images/default-theme/theme1-banner-default-thirst.png",
  },
];

export const categoryListDefault = isInternationalRegion ? categoryListDefaultInternational : categoryListDefaultLocal;

export const bestSellingProductDefault = isInternationalRegion
  ? bestSellingProductDefaultInternational
  : bestSellingProductDefaultLocal;

export const signatureProducts = isInternationalRegion ? signatureProductsInternational : signatureProductsLocal;

export const storeDiscountDefault = isInternationalRegion
  ? storeDiscountDefaultInternational
  : storeDiscountDefaultLocal;

export const discountCodeDefault = isInternationalRegion ? discountCodeDefaultInternational : discountCodeDefaultLocal;

export const flashSaleDefault = isInternationalRegion ? flashSaleDefaultInternational : flashSaleDefaultLocal;

export const blogDefault = isInternationalRegion ? blogDefaultInternational : blogDefaultLocal;
