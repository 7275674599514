export const EnumLogin = {
  LOGIN: 1,
  OTP: 2,
  SUCCESS: 3,
  INPUT_INFO: 4,
  REGISTER: 5,
  FORGOT_PASSWORD: 6,
};

export const KEYBOARD = {
  ENTER: "Enter",
};

export const PASSWORD_CHARACTER = {
  minPasswordChar: 6,
  maxPasswordChar: 100,
};
