import http from "../../utils/http-common";

const controller = "onlineStore";

const getAllStoreWebPageAsync = () => {
  return http.get(`/${controller}/get-all-store-web-page`);
};

const getStoreThemesAsync = () => {
  return http.get(`/${controller}/get-store-themes`);
};

const getAdvanceSettingsAsync = () => {
  return http.get(`/${controller}/advanced-settings`);
};

const createAdvanceSettingsAsync = (data) => {
  return http.post(`/${controller}/advanced-settings`, data);
};

const getAppDownloadLinkAsync = () => {
  return http.get(`/storeapp/downloads`);
};

const createStoreThemeAsync = (data) => {
  return http.post(`/${controller}/create-store-theme`, data);
};

const getStoreThemeConfiguration = (storeThemeId) => {
  return http.get(`/${controller}/get-store-theme-configuration?storeThemId=${storeThemeId}`);
};

const getThemeIdByStoreThemeId = (storeThemeId) => {
  return http.get(`/${controller}/get-theme-id-by-store-theme-id?storeThemId=${storeThemeId}`);
};

const updateStoreThemeConfiguration = (data) => {
  return http.post(`/${controller}/update-store-theme-configuration`, data);
};

const deleteThemeByIdAsync = (data) => {
  return http.delete(`/${controller}/delete-theme-by-id/${data}`);
};

const updateThemeName = (data) => {
  return http.put(`/${controller}/update-store-theme-name`, data);
};

const buildStoreApp = (data) => {
  return http.post(`/storeapp/build`, data);
};

const getStoreAppInfoAsync = (storeId) => {
  return http.get(`/storeapp/${storeId}`);
};

const onlineStoreDataService = {
  getAllStoreWebPageAsync,
  getStoreThemesAsync,
  getAdvanceSettingsAsync,
  createAdvanceSettingsAsync,
  getAppDownloadLinkAsync,
  createStoreThemeAsync,
  getStoreThemeConfiguration,
  updateStoreThemeConfiguration,
  getThemeIdByStoreThemeId,
  deleteThemeByIdAsync,
  updateThemeName,
  buildStoreApp,
  getStoreAppInfoAsync,
};
export default onlineStoreDataService;
