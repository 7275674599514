import { Typography } from "antd";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import React, { useEffect, useState, memo } from "react";
import { getUniqueId } from "utils/helpers";
import "./fnb-list-chips.scss";

//Compared prop to re-render component
const arePropsEqual = (prev, current) => {
  if (!(prev || current) && !(prev.data || current.data)) {
    return false;
  }
  const length = prev.data.length === current.data.length;
  const result =
    prev.data.length === 0 && current.data.length === 0
      ? true
      : prev.data.every((prevData, index) => {
          const currentData = current.data[index];
          // Check if prop is the JSX or plain text
          // For example
          // title: <FnbTypography.Link to={group?.link} text={group?.name} />
          // title: "Test title"
          if (typeof prevData?.title === "object") {
            return prevData?.title?.props?.text === prevData?.detail?.props?.text;
          } else {
            return prevData?.title === currentData?.title && prevData?.detail === currentData?.detail;
          }
        });

  if (result && length) {
    return true;
  } else {
    return false;
  }
};

const MemoizedFnbListChips = memo(FnbListChips, arePropsEqual);

export { MemoizedFnbListChips };

export function FnbListChips(props) {
  const { className = "", data, id, notShowDot = false, isOptionChip = false } = props;
  const [maxItem, setMaxItem] = useState(1);
  const [idList] = useState(id ?? getUniqueId());
  const [parentWidth, setParentWidth] = useState();

  useEffect(() => {
    setTimeout(() => {
      const widthParent = document.getElementById(`id-list-tooltip-${idList}`)?.offsetWidth;
      let items = 0;
      let totalWidth = 0;
      for (let i = 0; i < data?.length; i++) {
        const childElement = document.getElementById(`child-tooltip-${i}-${idList}`);
        const childOffsetWidth = childElement?.offsetWidth;
        if (childElement) {
          if (childOffsetWidth > widthParent - 50) {
            setParentWidth(widthParent - 50);
          }
          totalWidth += childElement?.offsetWidth;

          if (totalWidth > widthParent - 50) {
            break;
          } else {
            items++;
          }
        }
      }
      setMaxItem(items + 1);
    }, 200);
  }, [data]);

  const renderListItem = (listItem) => {
    const data = listItem.map((item, index) => {
      return (
        <div style={{ display: maxItem > index ? "" : "none" }}>
          <FnbChip
            title={item?.title}
            detail={item?.detail}
            isDisplay={listItem?.length > 1 ? maxItem - 1 > index : maxItem > index}
            className={`${isOptionChip && item?.isSetDefault ? "fnb-chip__option--default" : ""}`}
            width={parentWidth && (listItem?.length < 2 || maxItem - 1 === 1) ? "fit-content" : parentWidth - 8}
            minWidth={"32px"}
            maxWidth={parentWidth && listItem?.length < 2 ? parentWidth + 50 : parentWidth - 8}
          ></FnbChip>
        </div>
      );
    });
    return data;
  };

  const renderSiblingListItem = (listItem) => {
    const data = listItem.map((item, index) => {
      return (
        <FnbChip
          id={`child-tooltip-${index}-${idList}`}
          title={item?.title}
          detail={item?.detail}
          isVisible={false}
          className={`${isOptionChip && item?.isSetDefault ? "fnb-chip__option--default" : ""}`}
          isDisableToolTip={true}
          width={parentWidth && (listItem?.length < 2 || maxItem - 1 === 1) ? "fit-content" : parentWidth - 8}
          minWidth={"32px"}
          maxWidth={parentWidth && listItem?.length < 2 ? parentWidth + 50 : parentWidth - 8}
        ></FnbChip>
      );
    });
    return data;
  };

  const renderDetailItem = () => {
    const detailData = data.slice(maxItem - 1, data?.length).map((item, index) => {
      return (
        <ul className="ul-text">
          <li>
            <Typography className="detail-text">{item?.detail}</Typography>
          </li>
        </ul>
      );
    });
    return <div className="more-detail-container">{detailData}</div>;
  };

  const renderDefaultDetailItem = () => {
    const detailData = data.slice(maxItem, data?.length).map((item, index) => {
      return <Typography>{item?.detail}</Typography>;
    });
    return detailData;
  };

  return (
    <div>
      <div id={`id-list-tooltip-${idList}`} className={`list-tooltip-sibling ${className}`}>
        {renderSiblingListItem(data)}
      </div>
      <div className={`list-tooltip ${className}`}>
        {renderListItem(data)}
        <div className="list-chips">
          <FnbChip
            isVisible={data?.length > 1 && maxItem - 1 < data?.length}
            title={<Typography>+{data?.length - maxItem + 1}</Typography>}
            detail={notShowDot ? renderDefaultDetailItem() : renderDetailItem()}
            variant={"secondary"}
            isDisplay={data?.length > 1 && maxItem - 1 < data?.length}
            placement={"bottomRight"}
          ></FnbChip>
        </div>
      </div>
    </div>
  );
}
