import "moment/locale/vi";
import "./online-store-management.scss";

import React, { useEffect, useState } from "react";

import { Card } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import onlineStoreDataService from "data-services/online-store/online-store-data.service";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasPermission } from "utils/helpers";

import OnlineStoreMobileTab from "./components/mobile-tab/mobile-tab.component";
import OnlineStoreWebsiteTab from "./components/website-tab/website-tab.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import FnbSuffixHeadingGuideline from "components/fnb-heading-guidline/fnb-suffix-heading-guideline.component";
import { GuidancePurposeType } from "constants/guidance-link.constants";

const KEY_TAB_PANE = {
  WEBSITE_TAB: "website-tab",
  MOBILE_APP_TAB: "mobile-app-tab",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.WEBSITE_TAB]: 0,
  [KEY_TAB_PANE.MOBILE_APP_TAB]: 1,
};

export default function OnlineStoreManagementPage(props) {
  const [t] = useTranslation();
  const state = useSelector((state) => state);
  // eslint-disable-next-line no-unused-vars
  const [userInformation, setUserInformation] = useState(null);
  const [downloadLink, setDownloadLink] = useState({ androidFileUrl: "", iosFileUrl: "" });

  const pageData = {
    titlePage: t("myAccount.title"),
    onlineTitle: t("onlineStore.title"),
    websiteTitle: t("onlineStore.website"),
    mobileAppTitle: t("onlineStore.mobileApp.title"),
    tabNameAccount: t("myAccount.tabName.account"),
    subscription: t("myAccount.tabName.subscription"),
  };
  const screens = [
    {
      key: KEY_TAB_PANE.WEBSITE_TAB,
      name: pageData.websiteTitle,
      component: (
        <div className="website-box">
          <OnlineStoreWebsiteTab />
        </div>
      ),
    },
  ];
  if (hasPermission(PermissionKeys.STORE_APP_PACKAGE)) {
    screens.push(
      {
        key: KEY_TAB_PANE.MOBILE_APP_TAB,
        name: pageData.mobileAppTitle,
        component: (
          <div className="mobile-app-box">
            <OnlineStoreMobileTab downloadLink={downloadLink} />
          </div>
        ),
      },
    );
  }


  const [activeScreen, setActiveScreen] = React.useState(KEY_TAB_PANE.WEBSITE_TAB);

  useEffect(() => {
    let userInfo = state.session?.auth?.user;
    setUserInformation(userInfo);

    if (hasPermission(PermissionKeys.STORE_APP_PACKAGE)) {
      async function fetchDownloadLinks() {
        const downloadLinkResponse = await onlineStoreDataService.getAppDownloadLinkAsync();
        setDownloadLink(downloadLinkResponse);
      }
      fetchDownloadLinks();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const changeTab = (activeKey) => {
    setActiveScreen(activeKey);
  };
  return (
    <>
      <FnbHeadingPage
        title={pageData.onlineTitle}
        suffix={<FnbSuffixHeadingGuideline purposeType={GuidancePurposeType.OnlineStore} />}
      />
      <FnbTabPane
        screens={screens}
        activeScreen={activeScreen}
        indexKeyTabPane={INDEX_KEY_TAB_PANE}
        onChange={changeTab}
        defaultScreen={KEY_TAB_PANE.WEBSITE_TAB}
        firstKeyTabPane={screens[0].key}
        lastKeyTabPane={screens[screens.length - 1].key}
        classNameContent={"online-store-management-tabs"}
      />
    </>
  );
}
