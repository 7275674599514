import { Button, Col, DatePicker, Form, InputNumber, message, Row, Space } from "antd";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import ActionButtonGroup from "components/action-button-group/action-button-group.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbCard from "components/fnb-card/fnb-card.component";
import { CalendarNewIconBold, InfoCircleFlashSaleIcon } from "constants/icons.constants";
import { DateFormat, currency } from "constants/string.constants";
import customerDataService from "data-services/customer/customer-data.service";
import moment, { locale } from "moment";
import "moment/locale/vi";
import { useTranslation } from "react-i18next";
import { formatNumberDecimalOrInteger, getCurrency, checkOnKeyPressValidation } from "utils/helpers";
import "./loyalty-point-configuration.page.style.scss";
import i18n from "i18next";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";

const LoyaltyPointConfiguration = forwardRef((props, ref) => {
  const { res, fetchInitData, switcherLoyaltyPointConfig, onChangeForm } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showExpiryDate, setShowExpiryDate] = useState(false);
  const [showExpiryMembership, setShowExpiryMembership] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiryMembershipDate, setExpiryMembershipDate] = useState(null);
  const [earningExchangeNumber, setEarningExchangeNumber] = useState(0);
  const [redeemExchangeNumber, setRedeemExchangeNumber] = useState(0);
  const [currencyCode, setCurrencyCode] = useState();
  const [language, setLanguage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isLoyaltyPointConfigActive, setIsLoyaltyPointConfigActive] = useState(!switcherLoyaltyPointConfig);

  const pageData = {
    btnSave: t("button.saveChanges"),
    btnCancel: t("button.cancel"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    title: t("loyaltyPoint.title"),
    exchangeRate: t("loyaltyPoint.exchangeRate"),
    pointProgram: t("loyaltyPoint.pointProgram"),
    earningPoint: t("loyaltyPoint.earningPoint"),
    redeemPoint: t("loyaltyPoint.redeemPoint"),
    expiryDateMessage: t("loyaltyPoint.expiryDateMessage"),
    enableExpiryDateMessage: t("loyaltyPoint.enableExpiryDateMessage"),
    earningMessage: t("loyaltyPoint.earningMessage"),
    redeemMessage: t("loyaltyPoint.redeemMessage"),
    earningOnePoint: t("loyaltyPoint.earningOnePoint"),
    redeemOnePoint: t("loyaltyPoint.redeemOnePoint"),
    pointValidateMessage: t("loyaltyPoint.pointValidateMessage"),
    modifySuccessMessage: t("loyaltyPoint.modifySuccessMessage"),
    modifySuccessErr: t("loyaltyPoint.modifySuccessErr"),
    enable: t("status.enable"),
    disable: t("status.disable"),
    point: {
      min: 0,
      max: 999999999,
    },
    btnConfirmLeave: t("button.confirmLeave"),
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    selectMembershipDate: t("loyaltyPoint.selectMembershipDate"),
    enableMembershipDate: t("loyaltyPoint.enableMembershipDate"),
    titleMembershipDate: t("loyaltyPoint.titleMembershipDate"),
    formatDateTime: "dd/mm",
    endTimeUTC: "23:59:59 (UTC + 7)",
    loyaltyPointIsToIncentivizeCustomers: t("loyaltyPoint.loyaltyPointIsToIncentivizeCustomers"),
    meaning: t("loyaltyPoint.meaning"),
    pleaseEnterMax1e9: t("messages.valueShouldBeFrom0to999999999")
  };

  useImperativeHandle(ref, () => ({
    reload() {
      isChangeForm && reloadPage();
    },
  }));

  useEffect(() => {
    setLanguage(t.language);
    setCurrencyCode(getCurrency());
  }, [t.language]);

  useEffect(() => {
    form.validateFields();
  }, [i18n.language]);

  useEffect(() => {
    setInitialFormValue(res);
  }, [res]);

  useEffect(() => {
    setIsLoyaltyPointConfigActive(!switcherLoyaltyPointConfig);
  }, [switcherLoyaltyPointConfig]);

  const setInitialFormValue = async (res) => {
    if (res.hasData) {
      const { configuration } = res;
      const loyaltyPointConfig = {
        isExpiryDate: configuration.isExpiryDate,
        expiryDate: configuration.isExpiryDate ? moment.utc(configuration.expiryDate).local() : null,
        earningPointExchangeValue: configuration.earningPointExchangeValue,
        redeemPointExchangeValue: configuration.redeemPointExchangeValue,
        isExpiryMembershipDate: configuration.isExpiryMembershipDate,
        expiryMembershipDate: configuration.isExpiryMembershipDate
          ? moment.utc(configuration.expiryMembershipDate).local()
          : null,
      };
      setShowExpiryDate(configuration.isExpiryDate);
      setShowExpiryMembership(configuration.isExpiryMembershipDate);
      setEarningExchangeNumber(formatNumberDecimalOrInteger(configuration.earningPointExchangeValue));
      setRedeemExchangeNumber(formatNumberDecimalOrInteger(configuration.redeemPointExchangeValue));
      form.setFieldsValue({ loyaltyPointConfig });
    } else {
      const loyaltyPointConfig = {
        isExpiryDate: false,
        earningPointExchangeValue: 1,
        redeemPointExchangeValue: 1,
      };
      setShowExpiryDate(false);
      setEarningExchangeNumber(formatNumberDecimalOrInteger(loyaltyPointConfig.earningPointExchangeValue));
      setRedeemExchangeNumber(formatNumberDecimalOrInteger(loyaltyPointConfig.redeemPointExchangeValue));
      form.setFieldsValue({ loyaltyPointConfig });
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const onFinish = async (value) => {
    var timeZone = new Date().getTimezoneOffset() / 60;
    if (!value.loyaltyPointConfig.expiryDate && value.loyaltyPointConfig.isExpiryDate) {
      message.error(pageData.modifySuccessErr);
      return;
    }
    if (!value.loyaltyPointConfig.expiryMembershipDate && value.loyaltyPointConfig.isExpiryMembershipDate) {
      message.error(pageData.selectMembershipDate);
      return;
    }

    let { loyaltyPointConfig } = value;
    if (loyaltyPointConfig.expiryDate) {
      let expiryDate = new Date(
        moment(loyaltyPointConfig.expiryDate).year(),
        moment(loyaltyPointConfig.expiryDate).month(),
        moment(loyaltyPointConfig.expiryDate).date(),
        12,
        0,
        0,
      );

      loyaltyPointConfig.expiryDate = moment(expiryDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2);
    }

    if (loyaltyPointConfig.expiryMembershipDate) {
      let expiryMembershipDate = new Date(
        moment(loyaltyPointConfig.expiryMembershipDate).year(),
        moment(loyaltyPointConfig.expiryMembershipDate).month(),
        moment(loyaltyPointConfig.expiryMembershipDate).date(),
        12,
        0,
        0,
      );
      loyaltyPointConfig.expiryMembershipDate = moment(expiryMembershipDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2);
    }

    loyaltyPointConfig.timeZone = timeZone;

    await customerDataService.modifyLoyaltyPointAsync(value.loyaltyPointConfig).then((res) => {
      if (res) {
        message.success(pageData.modifySuccessMessage);
        reloadPage();
      }
    });
  };

  const onChangeEarningPoint = (value) => {
    setEarningExchangeNumber(formatNumberDecimalOrInteger(value));
  };

  const onChangeRedeemPoint = (value) => {
    setRedeemExchangeNumber(formatNumberDecimalOrInteger(value));
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      reloadPage();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    onChangeForm(true);
    setIsChangeForm(true);
  };

  // Redirect to home page
  const reloadPage = () => {
    setIsChangeForm(false);
    setShowConfirm(false);
    fetchInitData();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={(e) => changeForm(e)}
        className="loyalty-point-configuration loyalty-point"
      >
        <Row className="fnb-row-page-header loyalty-point__header">
          <Col xxl={20} span={24} className="loyalty-point__col--info">
            <div className="loyalty-point__dv--flex loyalty-point__dv--margin">
              <div className="loyalty-point__dv-icon">
                <InfoCircleFlashSaleIcon />
              </div>
              <span>{pageData.loyaltyPointIsToIncentivizeCustomers}</span>
            </div>
          </Col>
          <Col xxl={4} span={24}>
            {isChangeForm && (
              <ActionButtonGroup
                gap={24}
                arrayButton={[
                  {
                    action: (
                      <Button htmlType="submit" type="primary" className="btn-save-data">
                        {pageData.btnSave}
                      </Button>
                    ),
                    permission: null,
                  },
                  {
                    action: (
                      <a onClick={() => onCancel()} className="action-cancel btn-cancel-data">
                        {pageData.btnCancel}
                      </a>
                    ),
                    permission: null,
                  },
                ]}
              />
            )}
          </Col>
        </Row>
        <Space align="left" direction="vertical" style={{ display: "flex" }} size={12}>
          <FnbCard
            title={pageData.pointProgram}
            underlineTitle={true}
            className="row-point-program loyalty-point__card loyalty-point__card--program title_point_program"
          >
            <Space className="loyalty-point__space" align="left">
              <div className="loyalty-point__dv">
                <Form.Item name={["loyaltyPointConfig", "isExpiryDate"]} valuePropName="checked">
                  <FnbCheckBox
                    className="loyalty-point__checkbox"
                    disabled={isLoyaltyPointConfigActive}
                    onClick={() => setShowExpiryDate(!showExpiryDate)}
                  >
                    {pageData.enableExpiryDateMessage}
                  </FnbCheckBox>
                </Form.Item>
                {showExpiryDate && (
                  <Row className="loyalty-point__row-pt-program">
                    <Col className="loyalty-point__col-txt">
                      <span className="date-message">{pageData.expiryDateMessage}</span>
                    </Col>
                    <Col className="loyalty-point__col-date">
                      <Form.Item name={["loyaltyPointConfig", "expiryDate"]} className="input-date">
                        <DatePicker
                          suffixIcon={<CalendarNewIconBold />}
                          className="fnb-date-picker"
                          disabledDate={disabledDate}
                          format={DateFormat.DD_MM}
                          onChange={(date) => setExpiryDate(date)}
                          placeholder={pageData.formatDateTime}
                          locale={language === "vi" ? locale : ""}
                          disabled={isLoyaltyPointConfigActive}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="loyalty-point__col-date-utc">
                      <span className="end-time-utc">at {pageData.endTimeUTC}</span>
                    </Col>
                  </Row>
                )}
              </div>
              <div className="loyalty-point__dv">
                <Form.Item name={["loyaltyPointConfig", "isExpiryMembershipDate"]} valuePropName="checked">
                  <FnbCheckBox
                    className="loyalty-point__checkbox"
                    onClick={() => setShowExpiryMembership(!showExpiryMembership)}
                    disabled={isLoyaltyPointConfigActive}
                  >
                    {pageData.enableMembershipDate}
                  </FnbCheckBox>
                </Form.Item>
                {showExpiryMembership && (
                  <Row className="loyalty-point__row-pt-program">
                    <Col className="loyalty-point__col-txt">
                      <span className="date-message">{pageData.titleMembershipDate}</span>
                    </Col>
                    <Col className="loyalty-point__col-date">
                      <Form.Item name={["loyaltyPointConfig", "expiryMembershipDate"]} className="input-date">
                        <DatePicker
                          suffixIcon={<CalendarNewIconBold />}
                          className="fnb-date-picker"
                          disabledDate={disabledDate}
                          format={DateFormat.DD_MM}
                          onChange={(date) => setExpiryMembershipDate(date)}
                          placeholder={pageData.formatDateTime}
                          locale={language === "vi" ? locale : ""}
                          disabled={isLoyaltyPointConfigActive}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="loyalty-point__col-date-utc">
                      <span className="end-time-utc">at {pageData.endTimeUTC}</span>
                    </Col>
                  </Row>
                )}
              </div>
            </Space>
          </FnbCard>

          <FnbCard
            title={pageData.earningPoint}
            underlineTitle={true}
            className="row-earn-point loyalty-point__card loyalty-point__card--earn-pt title_point_program"
          >
            <Row className="loyalty-point__row--title-exchange">
              <span className="loyalty-point__span--title-exchange">{pageData.exchangeRate}</span>
            </Row>
            <Row className="loyalty-point__row-exchange">
              <Col className="loyalty-point__col--flex-1">
                <Form.Item
                  name={["loyaltyPointConfig", "earningPointExchangeValue"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.pointValidateMessage} />,
                    },
                    () => ({
                      validator(_, value) {
                        if (value && (value <= 0 || value >= 1e9)) {
                          return Promise.reject(
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.pleaseEnterMax1e9}
                            />,
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  className="earning-point-change loyalty-point__form"
                >
                  <FnbInputNumber
                    id={"loyaltyPointConfig-earningPointExchangeValue"}
                    placeholder="0"
                    min={getCurrency() === currency.vnd ? 1 : 0}
                    max={getCurrency() === currency.vnd ? 999999999 : null}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => onChangeEarningPoint(value)}
                    onKeyPress={(event) => {
                      const checkStatus = checkOnKeyPressValidation(
                        event,
                        "loyaltyPointConfig-earningPointExchangeValue",
                        0,
                        null,
                        getCurrency() === currency.vnd ? 0 : 2,
                      );
                      if (!checkStatus) event.preventDefault();
                    }}
                    addonAfter={getCurrency()}
                    disabled={isLoyaltyPointConfigActive}
                    onMouseLeave
                  />
                </Form.Item>
              </Col>
              <Col className="loyalty-point__col--flex-1">
                <span className="earn-point">{pageData.earningOnePoint}</span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span className="loyalty-point__span-spending">
                  {`${t(pageData.meaning)}: ${t(pageData.earningMessage, {
                    number: earningExchangeNumber,
                    currency: currencyCode,
                  })}`}
                </span>
              </Col>
            </Row>
          </FnbCard>

          <FnbCard
            title={pageData.redeemPoint}
            underlineTitle={true}
            className="row-redeem-point loyalty-point__card loyalty-point__card--redeem-pt title_point_program"
          >
            <Row className="loyalty-point__row--title-exchange">
              <span className="loyalty-point__span--title-exchange">{pageData.exchangeRate}</span>
            </Row>
            <Row className="loyalty-point__row-exchange">
              <Col className="loyalty-point__col--flex-1">
                <Form.Item
                  name={["loyaltyPointConfig", "redeemPointExchangeValue"]}
                  rules={[
                    {
                      required: true,
                      message: pageData.pointValidateMessage,
                    },
                    {
                      pattern: new RegExp(pageData.point.format),
                      message: pageData.pointValidateMessage,
                    },
                    {
                      pattern: new RegExp(pageData.point.range),
                      message: pageData.pointValidateMessage,
                    },
                    () => ({
                      validator(_, value) {
                        if (value && (value <= 0 || value >= 1e9)) {
                          return Promise.reject(
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={pageData.pleaseEnterMax1e9}
                            />,
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  className="earning-point-change loyalty-point__form"
                >
                  <FnbInputNumber
                    id={"loyaltyPointConfig-redeemPointExchangeValue"}
                    placeholder="0"
                    min={getCurrency() === currency.vnd ? 1 : 0}
                    max={getCurrency() === currency.vnd ? 999999999 : null}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(value) => onChangeRedeemPoint(value)}
                    onKeyPress={(event) => {
                      const checkStatus = checkOnKeyPressValidation(
                        event,
                        "loyaltyPointConfig-redeemPointExchangeValue",
                        0,
                        null,
                        getCurrency() === currency.vnd ? 0 : 2,
                      );
                      if (!checkStatus) event.preventDefault();
                    }}
                    addonAfter={getCurrency()}
                    disabled={isLoyaltyPointConfigActive}
                  />
                </Form.Item>
              </Col>
              <Col className="loyalty-point__col--flex-1">
                <span className="earn-point">{pageData.redeemOnePoint}</span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span className="loyalty-point__span-spending">
                  {`${pageData.meaning}: ${t(pageData.redeemMessage, {
                    number: redeemExchangeNumber,
                    currency: currencyCode,
                  })}`}
                </span>
              </Col>
            </Row>
          </FnbCard>
        </Space>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={reloadPage}
        isChangeForm={isChangeForm}
        buttonType={"NONE"}
      />
    </>
  );
});

export default LoyaltyPointConfiguration;
