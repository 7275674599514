import { FnbPieChart } from "components/fnb-pie-chart/fnb-pie-chart";
import { FnbTable } from "components/fnb-table/fnb-table";
import { NoDataFoundComponent } from "components/no-data-found/no-data-found.component";
import { BackToPieChartIcon } from "constants/icons.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { formatCurrency, formatCurrencyWithoutSuffix, formatNumberDecimalOrInteger, getCurrency } from "utils/helpers";
import "./index.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { Table } from "antd";

export default function RevenuePieChart(props) {
  const {
    dataSourceRevenue,
    getDataForPieChart,
    titleBack,
    chartName,
    maxRow = 5,
    minHeight = 260,
    maxHeight = 320,
    maxHeightScroll = 270,
    donutSize = "70%",
    titlePlacementLeft = false,
    totalLabelInner = true,
    hiddenRevenue = false,
    visibleDetail = true,
    maxContentPieChart = false,
    maxContentDescripton = false,
    viewAllText,
    hideText,
  } = props;
  const [t] = useTranslation();

  const [isChart, setIsChart] = useState(true);
  const [sumTotalOrder, setSumTotalOrder] = useState();
  const [sumTotalAmount, setSumTotalAmount] = useState();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isMaxWidth965 = useMediaQuery({ maxWidth: 965 });
  const isMaxWidth1080 = useMediaQuery({ maxWidth: 1080 });
  const isMinWidth1900 = useMediaQuery({ minWidth: 1900 });
  const [isViewAll, setIsViewAll] = useState(false);

  useEffect(() => {
    convertRevenueObjectToArray(dataSourceRevenue);
    setIsChart(true);
  }, [dataSourceRevenue]);

  const convertRevenueObjectToArray = (revenueList) => {
    let totalOrder = 0;
    let totalAmount = 0;
    revenueList?.map((item) => {
      if (item.name) {
        totalOrder += item?.totalOrder;
        totalAmount += item?.totalAmount;
      }
    });
    setSumTotalOrder(totalOrder);
    setSumTotalAmount(totalAmount);
  };

  const pageData = {
    totalOrder: t("order.totalOrder"),
    totalAmount: t("order.totalAmount"),
    detail: t("table.detail"),
    back: t("form.button.back"),
    total: t("table.total"),
    revenue: t("order.revenue", "Doanh thu hôm nay"),
    viewAll: t("reportRevenue.viewAll"),
    hide: t("reportRevenue.hide"),
  };

  const tableSettings = {
    columns: [
      {
        title: chartName,
        dataIndex: "name",
        key: "name",
        align: "left",
        width: "34%",
        render: (value) => {
          return t(value);
        },
      },
      {
        title: pageData.totalOrder,
        dataIndex: "totalOrder",
        key: "totalOrder",
        align: "center",
        width: "33%",
      },
      {
        title: pageData.totalAmount,
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "right",
        width: "34%",
        render: (value) => {
          return formatCurrency(value);
        },
      },
    ],
  };

  function renderRevenueOrderPieChartDescription(pieChartDes) {
    let row = 0;
    const colorDescriptions = pieChartDes?.map((item) => {
      if (isViewAll) {
        return (
          <div className="pie-chart-report-legend">
            <div className="legend-name">
              <div className="icon-text legend-name__icon-text">
                <div className="marker" style={{ backgroundColor: item?.color }}></div>
                <FnbTooltip
                  width={400}
                  maxWidthContent={isMinWidth1900 ? 150 : 400}
                  title={t(item?.label, item?.label)}
                  onlyShowWhenEllipsis
                >
                  <FnbTypography variant={theme.typography["b1-medium"]} text={t(item?.label, item?.label)} />
                </FnbTooltip>
              </div>
              <span className="legend-currency">{formatCurrency(item?.totalAmount)}</span>
            </div>
          </div>
        );
      } else if (row < maxRow) {
        row++;
        return (
          <div className="pie-chart-report-legend">
            <div className="legend-name">
              <div className="icon-text legend-name__icon-text">
                <div className="marker" style={{ backgroundColor: item?.color }}></div>
                <FnbTooltip
                  maxWidth={400}
                  maxWidthContent={isMinWidth1900 ? 150 : 400}
                  title={t(item?.label, item?.label)}
                  onlyShowWhenEllipsis
                >
                  <FnbTypography variant={theme.typography["b1-medium"]} text={t(item?.label, item?.label)} />
                </FnbTooltip>
              </div>
              <span className="legend-currency">{formatCurrency(item?.totalAmount)}</span>
            </div>
          </div>
        );
      }
    });
    return (
      <>
        <div
          style={
            isViewAll && dataSourceRevenue?.length > maxRow
              ? { maxHeight: maxHeightScroll, overflowY: "auto", paddingTop: "6px" }
              : {}
          }
        >
          <div className="pie-chart-report-legend__title">
            <span>Platform</span>
            <span>Revenue</span>
          </div>
          {colorDescriptions}
        </div>
        {dataSourceRevenue?.length > maxRow &&
          (isViewAll ? (
            <div className="pie-chart-report-legend">
              <div className="legend-link" onClick={() => setIsViewAll(false)}>
                {hideText ? <u>{t(hideText)}</u> : <>{pageData.hide}&nbsp;&gt;</>}
              </div>
            </div>
          ) : (
            <div className="pie-chart-report-legend">
              <div className="legend-link" onClick={() => setIsViewAll(true)}>
                {viewAllText ? <u>{t(viewAllText)}</u> : <>{pageData.viewAll}&nbsp;&gt;</>}
              </div>
            </div>
          ))}
      </>
    );
  }

  const convertDataSource = (dataSource) => {
    return dataSource.map((data) => {
      return { ...data, label: data.label ? t(data.label) : "" };
    });
  };

  const handleVisibleDetail = () => {
    if (!visibleDetail) return;
    setIsChart(!isChart);
  };

  return (
    <div className="revenue-order-chart">
      <div className="revenue-order-chart-wrapper" style={{ display: !isChart ? "none" : "flex" }}>
        <>
          <div className="header-chart-detail pie-chart-header">
            <span className="pie-chart-title" onClick={handleVisibleDetail}>
              {titleBack}
            </span>
            {visibleDetail && dataSourceRevenue?.length > 0 && (
              <span className="pie-chart-title-detail" onClick={handleVisibleDetail}>
                {pageData.detail}
              </span>
            )}
          </div>
          <div className="pie-chart-total-label">
            {!totalLabelInner && (
              <span>
                {formatNumberDecimalOrInteger(sumTotalAmount)} {getCurrency()}
              </span>
            )}
          </div>
          {sumTotalOrder > 0 ? (
            <div className="pie-chart-content">
              <FnbPieChart
                className={`order-report-pie-chart ${maxContentPieChart && "max-content-pie-chart"}`}
                height={isMaxWidth1080 ? minHeight : maxHeight}
                plotOptions={{
                  pie: {
                    customScale: 1,
                    offsetX: 0,
                    offsetY: 0,
                    donut: {
                      size: donutSize,
                      labels: {
                        show: true,
                        name: {},
                        value: {
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#50429B",
                        },
                        total: {
                          show: true,
                          showAlways: true,
                          label: totalLabelInner ? `${formatNumberDecimalOrInteger(sumTotalAmount)} ${getCurrency()}` : "",
                          fontSize: `${isMaxWidth965 ? "16px" : "20px"}`,
                          fontWeight: "800",
                          lineHeight: "25px",
                          color: "#50429B",
                          formatter: () => {
                            return !hiddenRevenue ? pageData.revenue : "";
                          },
                        },
                      },
                    },
                  },
                }}
                title={pageData.revenue}
                dataSource={convertDataSource(getDataForPieChart)}
                ratioMobile={isMobile ? 100 : 65}
              />
              <div className={`revenue-chart-description ${maxContentDescripton && "max-content-description"}`}>
                {renderRevenueOrderPieChartDescription(getDataForPieChart)}
              </div>
            </div>
          ) : (
            <div className="no-data">
              <NoDataFoundComponent />
            </div>
          )}
        </>
      </div>
      <div className="revenue-order-chart-wrapper" style={{ display: isChart ? "none" : "flex" }}>
        <div className={titlePlacementLeft ? "header-chart-detail pie-chart-table-title" : "header-chart-detail"}>
          <span className="back-to-icon" onClick={handleVisibleDetail}>
            <BackToPieChartIcon />
          </span>
          <span>{titleBack}</span>
        </div>
        {sumTotalOrder > 0 ? (
          <div className="table-chart-detail">
            <FnbTable
              columns={tableSettings.columns}
              dataSource={dataSourceRevenue}
              className="table-revenue"
              summary={() => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>{pageData.total}</Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="center">
                        {sumTotalOrder}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="right">
                        {formatCurrencyWithoutSuffix(sumTotalAmount)}
                        <span className="currency-unit">{getCurrency()}</span>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </div>
        ) : (
          <div className="no-data">
            <NoDataFoundComponent />
          </div>
        )}
      </div>
    </div>
  );
}
