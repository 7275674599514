import { Col, Row, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import { ExportIcon } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { DateFormat } from "constants/string.constants";
import reportDataService from "data-services/report/report-data.service";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import languageService from "services/language/language.service";
import { handleDownloadFile } from "utils/helpers";
import ListShift from "./components/list-shift.component";
import SummaryShift from "./components/summary-shift.component";
import { useLocation } from "react-router-dom";
/**
 * Page Shift Management
 * description: page manage Shift primary template
 */
export default function ShiftManagement(props) {
  const [t] = useTranslation();
  const { search } = useLocation();
  const prevTypeOptionDate = new URLSearchParams(search).get("typeOptionDate");
  const prevStartDate = new URLSearchParams(search).get("startDate");
  const prevEndDate = new URLSearchParams(search).get("endDate");
  const filterDateTimeRef = useRef(null);

  window.history.replaceState({}, document.title, window.location.pathname);

  const pageData = {
    summary: t("report.shift.summary"),
    date: {
      yesterday: t("dashboard.compareDate.yesterday"),
      previousDay: t("dashboard.compareDate.previousDay"),
      lastWeek: t("dashboard.compareDate.lastWeek"),
      previousWeek: t("dashboard.compareDate.previousWeek"),
      lastMonth: t("dashboard.compareDate.lastMonth"),
      previousMonth: t("dashboard.compareDate.previousMonth"),
      lastYear: t("dashboard.compareDate.lastYear"),
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    tabOverView: t("report.customer.tabOverView"),
    tabView: t("report.customer.tabView"),
    tabAccessTimes: t("report.customer.tabAccessTimes"),
    allBranch: t("dashboard.allBranch"),
    btnExport: t("button.export"),
    exportSuccessful: t("messages.exportSuccessful"),
  };
  const [branchId, setBranchId] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: prevStartDate ? prevStartDate : moment().startOf("date").toDate(),
    endDate: prevEndDate ? prevEndDate : moment().endOf("date").toDate(),
  });
  const [typeOptionDate, setTypeOptionDate] = useState(prevTypeOptionDate ? prevTypeOptionDate : OptionDateTime.today);
  const [titleConditionCompare, setTitleConditionCompare] = useState(pageData.date.yesterday);

  useEffect(() => {
    filterDateTimeRef.current.setSelectedDate();
  }, [filterDateTimeRef]);

  const handleChangeBranch = (branchId) => {
    setBranchId(branchId);
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    setTypeOptionDate(typeOptionDate);
  };

  const onConditionCompare = (key) => {
    switch (key) {
      case OptionDateTime.today:
        setTitleConditionCompare(pageData.date.yesterday);
        break;
      case OptionDateTime.yesterday:
        setTitleConditionCompare(pageData.date.previousDay);
        break;
      case OptionDateTime.thisWeek:
        setTitleConditionCompare(pageData.date.lastWeek);
        break;
      case OptionDateTime.lastWeek:
        setTitleConditionCompare(pageData.date.previousWeek);
        break;
      case OptionDateTime.thisMonth:
        setTitleConditionCompare(pageData.date.lastMonth);
        break;
      case OptionDateTime.lastMonth:
        setTitleConditionCompare(pageData.date.previousMonth);
        break;
      case OptionDateTime.thisYear:
        setTitleConditionCompare(pageData.date.lastYear);
        break;
      default:
        setTitleConditionCompare(pageData.date.previousSession);
        break;
    }
  };

  const exportShiftsAsync = async () => {
    let languageCode = languageService.getLang();
    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);
    let timeZone = new Date().getTimezoneOffset() / 60;
    let param = {
      languageCode: languageCode,
      branchId: branchId,
      startDate: startDate,
      endDate: endDate,
      timeZone: timeZone,
      typeOptionDate: typeOptionDate,
    };
    try {
      var response = await reportDataService.exportShiftAsync(param);
      handleDownloadFile(response);
      message.success(pageData.exportSuccessful);
    } catch (error) {
      const { statusText } = error;
      message.error(statusText);
    }
  };

  return (
    <>
      <Row className="fnb-form-title shift-report">
        <Col span={24}>
          <FnbHeadingPage
            title={pageData.summary}
            isCompactButtons={false}
            listButtons={[
              <FnbListBranches onChangeEvent={handleChangeBranch} />,
              <FnbDatePicker
                selectedDate={selectedDate}
                setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
                setConditionCompare={onConditionCompare}
                ref={filterDateTimeRef}
                orderTypeFilterTime={prevTypeOptionDate ? parseInt(prevTypeOptionDate) : OptionDateTime.today}
              />,
              <FnbButton
                permission={false}
                text={pageData.btnExport}
                iconHeader={<ExportIcon />}
                onClick={exportShiftsAsync}
              ></FnbButton>,
            ]}
          />
        </Col>
        <Col span={24}>
          <SummaryShift
            selectedDate={selectedDate}
            typeOptionDate={typeOptionDate}
            branchId={branchId}
            titleConditionCompare={titleConditionCompare}
          />
          <ListShift
            selectedDate={selectedDate}
            typeOptionDate={typeOptionDate}
            branchId={branchId}
            titleConditionCompare={titleConditionCompare}
          />
        </Col>
      </Row>
    </>
  );
}
