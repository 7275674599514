import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./delete-material.component.scss";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle, ConfirmViewActiveItemStyle } from "constants/level-menu.constants";

export default function DeleteProductRecipes(props) {
  const [t] = useTranslation();
  const { infoMaterial, isModalVisible, handleCancel, onDelete } = props;
  const pageData = {
    deactivate: t("status.deactivate"),
    deleteProductPriceMaterialNotificationMessage: t("messages.deleteProductPriceMaterialNotificationMessage"),
    ignore: t("button.ignore"),
  };
  const [activeItems, setActiveItems] = useState([]);

  const onCancel = () => {
    handleCancel();
  };

  useEffect(() => {
    setActiveItems(infoMaterial?.products?.map(item => {
      const label = item?.name;
      const link = `/product/details/${item?.id}`;
      return { label, link };
    }));
  }, [infoMaterial]);

  return (
    <>
      <ConfirmDialogComponent
        width={600}
        activeItems={activeItems}
        type={ConfirmStyle.NOTIFICATION}
        visible={isModalVisible && activeItems?.length > 0}
        content={t(pageData.deleteProductPriceMaterialNotificationMessage, { name: infoMaterial?.material?.name })}
        onCancel={onCancel}
        cancelText={pageData.ignore}
        onOk={onDelete}
        okText={pageData.deactivate}
        typeViewActiveItems={ConfirmViewActiveItemStyle.LIST}
      />
    </>
  );
}
