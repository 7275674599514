import styled from "styled-components";

import { BaseFormInput, ButtonConfirm } from "./forgot-password.styled";

export const ForgotPasswordForm = styled(BaseFormInput)`
  padding: 24px 32px 32px 32px;
  row-gap: 32px;
  width: 560px;
  @media (max-width: 576px) {
    padding: 16px 16px 20px 16px;
    row-gap: 20px;
  }
`;

export const Heading = styled.div`
  display: flex;
  column-gap: 16px;
  @media (max-width: 576px) {
    column-gap: 12px;
  }
`;
export const WrapperTextHeading = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const DescriptionInput = styled.span`
  font-size: 14px;
  font-weight: 400px;
  color: #000;
  @media (max-width: 576px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
  }
`;

export const InputPhoneNumberContainer = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  @media (max-width: 576px) {
    padding: 0;
  }
`;

export const RowInputPhoneNumber = styled.div`
  display: flex;
  column-gap: 14px;

  .select-phone-code {
    border-radius: 12px;
    height: 60px;
    .ant-select-selector {
      border: none;
      height: 100%;
      display: flex;
      align-items: center;
      background: #f8f8f8;
      padding: 0 16px;
      .ant-select-selection-item {
        font-size: 18px;
        font-weight: 500;
        line-height: 22.68px;
      }
    }
  }
  .ant-input-affix-wrapper {
    border: none;
    background: #f8f8f8;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 500;
  }
  input {
    background: #f8f8f8;
  }
  @media (max-width: 576px) {
    .select-phone-code {
      height: 56px;
      .ant-select-selector {
        padding: 0 8px;
        .ant-select-selection-item {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.16px;
        }
      }
    }
    .ant-input-affix-wrapper {
      font-size: 15px;
      font-weight: 500;
      line-height: 18.9px;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.38px;
  }
`;

export const RegisterMessage = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18.9px;
  text-decoration: underline;
  color: ${(p) => p.textColor};
  cursor: pointer;
  @media (max-width: 576px) {
    font-size: 12px;
    font-weight: 700;
    line-height: 15.12px;
  }
`;

export const ButtonConfirmPhoneNumber = styled(ButtonConfirm)`
  margin: 0 24px;
  @media (max-width: 576px) {
    margin: 0;
  }
`;
