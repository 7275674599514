import { useTranslation } from "react-i18next";
import { formatTextNumber, formattedDiscountWithCurrencySymbol } from "../../../utils/helpers";
import { EnumPromotionSummary } from "../../constants/enums";
import "./discount-code-popover.scss";
export default function DiscountPopoverComponent({ discount, promotionType }) {
  const [t] = useTranslation();
  if (
    !discount ||
    ![EnumPromotionSummary.DiscountCode, EnumPromotionSummary.Discount, EnumPromotionSummary.ManualDiscount].includes(
      promotionType,
    )
  ) {
    return <></>;
  }

  return (
    <div className="discount-item-popover">
      <div className="name">
        {promotionType === EnumPromotionSummary.DiscountCode ? (
          <div className="discount-code-popover">
            <div className="discount-code-name">{discount?.promotionCode}</div>
          </div>
        ) : (
          <>
            {promotionType === EnumPromotionSummary.ManualDiscount ? t("checkOutPage.off") : discount?.promotionName}
            {promotionType === EnumPromotionSummary.ManualDiscount
              ? discount?.percentNumber > 0
                ? " (" + discount?.percentNumber + "%)"
                : " (" + formatTextNumber(Number(discount?.promotionName)) + ")"
              : discount?.percentNumber > 0 && `( ${discount?.percentNumber}%)`}
          </>
        )}
      </div>
      <div className="amount">
        {formattedDiscountWithCurrencySymbol(discount?.promotionValue ?? discount?.maximunDiscountValue)}
      </div>
    </div>
  );
}
