import qs from "query-string";
import http from "../../utils/http-common";

const controller = "guidanceLink";

const getGuidanceLinkRequest = (params) => {
  return http.get(`/${controller}`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const guidanceLinkDataService = {
  getGuidanceLinkRequest,
};

export default guidanceLinkDataService;
