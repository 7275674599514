import { Row } from "antd";
import FnbTypography from "components/fnb-typography/fnb-typography";
import ModalFinishSystemGuideline from "components/modal-finish-system-guideline/modal-finish-system-guideline.component";
import { EmojiChefIcon } from "constants/icons.constants";
import { StepsSystemGuidelineConstants, StepsSystemGuidelineKeysConstants } from "constants/system-guideline.constants";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import {
  getSystemGuidelineAsync,
  systemGuidelineSelector,
} from "store/modules/system-guideline/system-guideline.reducer";
import theme from "theme";
import { getStorage, localStorageKeys, setStorage } from "utils/localStorage.helpers";
import ContentItemStepGuideline from "./content-item-step-guideline.component";
import ItemStepGuideline from "./item-step-guideline.component";
import {
  BottomContentGuidelineMobile,
  HeadingSystemGuideline,
  InfoStep,
  LeftColumnSteps,
  ProgressStep,
  RightColumnSteps,
  SystemGuidelineStyle,
  WrapperContentItemStepMobile,
  WrapperCurrentStep,
  WrapperItemStepMobileMode,
  WrapperProgress,
} from "./system-guideline.styled";

const SystemGuideline = () => {
  const [t] = useTranslation();

  const pageData = {
    title: t("systemGuideline.title"),
    description: t("systemGuideline.description"),
  };
  const { storeId, totalStepCompleted, stepStatus, defaultBranchId, totalStepNeedToCompleted, isActivatedPackageWeb } =
    useSelector(systemGuidelineSelector);
  const [widthElementProgress, setWidthElementProgress] = useState(0);
  const [positionInfoStep, setPositionInfoStep] = useState(-1);
  const [currentStepSelected, setCurrentStepSelected] = useState(StepsSystemGuidelineKeysConstants.ADD_INGREDIENT);
  const [isShowModalFinish, setIsShowModalFinish] = useState(false);
  const wrapperProgressRef = useRef(null);
  const wrapperCurrentStepRef = useRef(null);
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const dispatch = useDispatch();

  useEffect(() => {
    let position = (totalStepCompleted / totalStepNeedToCompleted) * widthElementProgress;
    // Handle the edge case when no steps are finished
    if (totalStepCompleted === 0) {
      position = 0;
    }
    // Handle the case when all steps are finished
    else if (totalStepCompleted === totalStepNeedToCompleted) {
      const widthInfo = wrapperCurrentStepRef.current?.offsetWidth || 0;
      position -= widthInfo;
    }
    // Handle intermediate steps
    else if (totalStepCompleted > 0 && totalStepCompleted < totalStepNeedToCompleted) {
      position -= 35;
    }
    setPositionInfoStep(position);
  }, [totalStepCompleted, widthElementProgress, totalStepNeedToCompleted]);

  useEffect(() => {
    const element = wrapperProgressRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      const length = entries?.length;
      if (length > 0) {
        setWidthElementProgress(entries[length - 1].contentRect.width);
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }
    // Cleanup observer on component unmount
    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);

  const handleCheckIsFinish = () => {
    const previousProgress = JSON.parse(getStorage(localStorageKeys.SYSTEM_GUIDELINE_PROGRESS));

    const isProgressIncomplete = previousProgress?.progress < totalStepNeedToCompleted;
    const isSameStore = previousProgress?.storeId === storeId;
    const isNewCompletion = totalStepCompleted === totalStepNeedToCompleted;
    if (previousProgress && isSameStore && isProgressIncomplete && isNewCompletion) {
      setIsShowModalFinish(true);
    }
    if (storeId !== undefined) {
      setStorage(
        localStorageKeys.SYSTEM_GUIDELINE_PROGRESS,
        JSON.stringify({
          storeId,
          progress: totalStepCompleted,
        }),
      );
    }
  };

  useEffect(() => {
    handleCheckIsFinish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, totalStepCompleted]);

  useEffect(() => {
    // initData();
    dispatch(getSystemGuidelineAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickStepItem = (key) => {
    if (isMobile) {
      const isSameStep = key === currentStepSelected;
      setCurrentStepSelected(isSameStep ? null : key);
    } else {
      setCurrentStepSelected(key);
    }
  };

  const onClickBtnRightContent = () => {
    let navigateLink = StepsSystemGuidelineConstants[currentStepSelected].navigateLink;
    if (currentStepSelected === StepsSystemGuidelineKeysConstants.SET_UP_ADDRESS) {
      navigateLink = navigateLink.replace("$branchId$", defaultBranchId);
    }
    history.push(navigateLink);

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 100);
  };

  return (
    <>
      <SystemGuidelineStyle isShow={totalStepCompleted < totalStepNeedToCompleted && storeId !== undefined}>
        <HeadingSystemGuideline>
          <FnbTypography
            text={pageData.title}
            color={theme.colors.default.white}
            variant={theme.typography["h1-bold"]}
          />
          <FnbTypography
            text={pageData.description}
            color={theme.colors.default.white}
            variant={theme.typography["b2-regular"]}
          />
        </HeadingSystemGuideline>
        <WrapperProgress ref={wrapperProgressRef}>
          <WrapperCurrentStep
            ref={wrapperCurrentStepRef}
            isShow={positionInfoStep >= 0}
            style={{ marginLeft: `${positionInfoStep}px` }}
          >
            <InfoStep>
              <FnbTypography
                text={`${totalStepCompleted}/${totalStepNeedToCompleted}`}
                color={theme.colors.default.white}
                variant={theme.typography["b1-bold"]}
              />
            </InfoStep>
            <EmojiChefIcon />
          </WrapperCurrentStep>
          <ProgressStep
            percent={(totalStepCompleted / totalStepNeedToCompleted) * 100}
            showInfo={false}
            strokeColor={theme.colors.secondary[70]}
            trailColor="#FFEBB1"
          />
        </WrapperProgress>
        {!isMobile ? (
          <Row>
            <LeftColumnSteps sm={10} lg={8}>
              {Object.entries(StepsSystemGuidelineConstants)
                .filter(([key]) => !(key === StepsSystemGuidelineKeysConstants.BUILD_WEBSITE && !isActivatedPackageWeb))
                .map(([key, value], index) => (
                  <ItemStepGuideline
                    key={key}
                    keyItem={key}
                    index={index}
                    title={t(value.title)}
                    isSelected={key === currentStepSelected}
                    onClick={onClickStepItem}
                    isFinish={stepStatus[key] ?? false}
                  />
                ))}
            </LeftColumnSteps>
            <RightColumnSteps sm={14} lg={16}>
              <ContentItemStepGuideline
                title={t(StepsSystemGuidelineConstants[currentStepSelected].title)}
                content={t(StepsSystemGuidelineConstants[currentStepSelected].content)}
                button={{
                  label: t(StepsSystemGuidelineConstants[currentStepSelected].button.label),
                  onClick: onClickBtnRightContent,
                }}
              />
            </RightColumnSteps>
          </Row>
        ) : (
          <BottomContentGuidelineMobile>
            {Object.entries(StepsSystemGuidelineConstants).map(([key, value], index) => (
              <WrapperItemStepMobileMode isSelected={key === currentStepSelected}>
                <ItemStepGuideline
                  key={key}
                  keyItem={key}
                  index={index}
                  title={t(value.title)}
                  isSelected={key === currentStepSelected}
                  onClick={onClickStepItem}
                  isFinish={stepStatus[key] ?? false}
                />
                <WrapperContentItemStepMobile>
                  <ContentItemStepGuideline
                    title={t(value.title)}
                    content={t(value.content)}
                    button={{
                      label: t(value.button.label),
                      onClick: onClickBtnRightContent,
                    }}
                  />
                </WrapperContentItemStepMobile>
              </WrapperItemStepMobileMode>
            ))}
          </BottomContentGuidelineMobile>
        )}
      </SystemGuidelineStyle>
      <ModalFinishSystemGuideline open={isShowModalFinish} setOpen={setIsShowModalFinish} />
    </>
  );
};

export default SystemGuideline;
