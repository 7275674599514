import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OptionDateTime } from "constants/option-date.constants";
import { DateFormat } from "constants/string.constants";
import reportDataService from "data-services/report/report-data.service";
import moment from "moment";
import { formatDate, randomGuid } from "utils/helpers";

const PREFIX = "report/staff-revenue";
export const DEFAULT_FORMAT_DATE = "M/D/YYYY, hh:mm:ss A";

const initialState = {
  isShowStaffSummary: true,
  loadingStaffSummary: true,
  staffSummaryInfo: undefined,
  staffSummaryParams: {
    startDate: formatDate(moment().startOf("day").locale("en-US").toDate(), DateFormat.MM_DD_YYYY_HH_MM_SS),
    endDate: formatDate(moment().endOf("day").locale("en-US").toDate(), DateFormat.MM_DD_YYYY_HH_MM_SS),
    businessSummaryWidgetFilter: OptionDateTime.today,
    branchId: null,
    isDefault: true,
  },
  filter: {
    startRevenue: null,
    endRevenue: null,
    index: {
      start: null,
      end: null,
    },
    isDefault: true,
    minRevenue: 0,
  },
  chart: {
    isInitialDone: false,
    id: randomGuid(),
    colorId: `COLOR_${randomGuid()}`,
    showStaffNumber: false,
    isShowSelectedRange: false,
    listRevenue: [], // y axis
    listStaffNumber: [], // x axis
    columns: [], // {fromRevenue, endRevenue}
    stepRevenue: 0,
    maxRevenue: 0,
    numberUnitType: 0,
    requesting: false,
  },
  isSortTable: false,
};

export const fetchStaffSummary = createAsyncThunk(`${PREFIX}/summary`, async (params) => {
  const response = await reportDataService.getStaffSummaryAsync(params);
  return response;
});

export const getChartDataStaffRevenue = createAsyncThunk(`${PREFIX}/getChartDataStaffRevenue`, async (params) => {
  const response = await reportDataService.getStaffRevenueChartAsync(params);
  return response;
});

export const getStaffRevenueTable = createAsyncThunk(`${PREFIX}/getStaffRevenueTable`, async (params) => {
  const response = await reportDataService.getStaffRevenueAsync(params);
  return response;
});

const staffRevenueSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setShowStaffSummary(state, action) {
      state.isShowStaffSummary = action.payload;
    },
    setStaffSummaryParams(state, action) {
      state.staffSummaryParams = { ...action.payload, isDefault: false };
    },
    setStartAndEndRevenue: (state, action) => {
      const { startRevenue, endRevenue, startIndex, endIndex } = action.payload;
      state.filter = {
        ...state.filter,
        startRevenue: startRevenue,
        endRevenue: endRevenue,
        index: {
          start: startIndex,
          end: endIndex,
        },
        isDefault: false,
      };
    },
    resetStaffSummaryInfo(state) {
      state.staffSummaryInfo = undefined;
    },
    clearStartAndEndRevenue: (state) => {
      state.filter = {
        ...state.filter,
        startRevenue: null,
        endRevenue: null,
        index: {
          start: null,
          end: null,
        },
        isDefault: true,
      };
    },
    resetChartState: (state) => {
      state.chart = {
        ...state.chart,
        isInitialDone: false,
        id: randomGuid(),
        colorId: `COLOR_${randomGuid()}`,
        showStaffNumber: false,
        isShowSelectedRange: false,
        listRevenue: [], // y axis
        listStaffNumber: [], // x axis
        columns: [],
        stepRevenue: 0,
        maxRevenue: 0,
        numberUnitType: 0,
        requesting: false,
      };
    },
    resetStaffSummaryParams: (state) => {
      state.staffSummaryParams = {
        startDate: moment().startOf("day").locale("en-US").format(DEFAULT_FORMAT_DATE),
        endDate: moment().endOf("day").locale("en-US").format(DEFAULT_FORMAT_DATE),
        businessSummaryWidgetFilter: OptionDateTime.today,
        isDefault: true,
      };
    },
    setShowStaffNumber: (state, action) => {
      const { isShow } = action.payload;
      state.chart = {
        ...state.chart,
        showStaffNumber: isShow,
      };
    },
    reloadColorChart: (state) => {
      state.chart = {
        ...state.chart,
        colorId: `COLOR_${randomGuid()}`,
      };
    },
    reloadChartId: (state) => {
      state.chart = {
        ...state.chart,
        id: randomGuid(),
      };
    },
    setShowSelectedRange: (state, action) => {
      const { isShow } = action.payload;
      state.chart = {
        ...state.chart,
        isShowSelectedRange: isShow,
      };
    },
    setSortTableWhenStaffSummaryClose(state, action) {
      state.isSortTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStaffSummary.pending, (state) => {
        state.loadingStaffSummary = true;
      })
      .addCase(fetchStaffSummary.fulfilled, (state, action) => {
        state.loadingStaffSummary = false;
        state.staffSummaryInfo = action.payload;
      })
      .addCase(fetchStaffSummary.rejected, (state) => {
        state.loadingStaffSummary = false;
        state.staffSummaryInfo = undefined;
      })

      .addCase(getChartDataStaffRevenue.pending, (state) => {
        state.chart.requesting = true;
      })
      .addCase(getChartDataStaffRevenue.fulfilled, (state, action) => {
        const { columns, maxRevenue, stepRevenue, numberUnitType } = action.payload;
        const listRevenue = [];
        const listStaffNumber = [];
        for (let i = 0; i < columns?.length; i++) {
          listRevenue.push(columns[i]?.toRevenueFormatUnit);
          listStaffNumber.push(columns[i]?.staffNumber);
        }

        state.chart.id = randomGuid();
        state.chart.listRevenue = listRevenue;
        state.chart.listStaffNumber = listStaffNumber;
        state.chart.columns = columns;
        state.chart.maxRevenue = maxRevenue;
        state.chart.stepRevenue = stepRevenue;
        state.chart.numberUnitType = numberUnitType;
        state.chart.isInitialDone = true;
        state.chart.requesting = false;
        state.chart.isShowSelectedRange = false;

        // reset filter

        if (columns?.length > 0) {
          state.filter.minRevenue = 0;
          state.filter.index = {
            start: 0,
            end:  columns.length - 1
          };
          state.filter.startRevenue = columns[0]?.fromRevenue;
          state.filter.endRevenue = columns[columns.length - 1]?.toRevenue;
        }
      });
  },
});

export const staffRevenueActions = staffRevenueSlice.actions;

export const staffRevenueSelector = (state) => state.staffRevenue;

const staffRevenueReducer = staffRevenueSlice.reducer;
export default staffRevenueReducer;
