import { Col, Modal, Popover, Row, message, Input } from "antd";
import { CloseOutlined, ExportOutlinedIcon, SearchIcon, FolderIcon } from "constants/icons.constants";
import "./style.scss";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import { formatCurrencyWithoutSuffix, formatDate, hasPermission, handleDownloadFile, formatCurrency, formatPhoneNumber } from "utils/helpers";
import { useEffect, useState } from "react";
import moment from "moment";
import { OptionDateTime } from "constants/option-date.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "constants/order-status.constants";
import reportDataService from "data-services/report/report-data.service";
import languageService from "services/language/language.service";
import { useSelector } from "react-redux";
import { DEFAULT_FORMAT_DATE, staffRevenueSelector } from "store/modules/staff-revenue/staff-revenue.reducer";
import { useMediaQuery } from "react-responsive";
import { DateFormat } from "constants/string.constants";
import { useDebounce } from "themes/theme-1-new/hooks";
import { Link } from "react-router-dom";

const RevenueDetail = (props) => {
  const { open, onCancel, currentStaff } = props;
  const [t] = useTranslation();
  const { staffSummaryParams } = useSelector(staffRevenueSelector);
  const DEFAULT_PAGESIZE = 20;
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isDesktop = useMediaQuery({ minWidth: 1201 });
  const [pageNumber, setPageNumber] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalRevenue, setRevenue] = useState(0);
  const [selectedDate, setSelectedDate] = useState({
    startDate: staffSummaryParams.startDate,
    endDate: staffSummaryParams.endDate
  });
  const [orderTypeFilterTime, setOrderTypeFilterTime] = useState(staffSummaryParams.businessSummaryWidgetFilter)
  const [orderCode, setOrderCode] = useState(null);
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const [itemOrderRevenueDetail, setItemOrderRevenueDetail] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const phoneCode = useSelector((state) => state?.session?.prepareAddressData)?.defaultCountryStore?.phonecode;

  const pageData = {
    draft: t("orderStatus.draft"),
    canceled: t("orderStatus.canceled"),
    toConfirm: t("orderStatus.toConfirm"),
    processing: t("orderStatus.processing"),
    delivering: t("orderStatus.delivering"),
    completed: t("orderStatus.completed"),
    returned: t("orderStatus.returned"),
    new: t("orderStatus.new"),
    showMoreItem: t("report.staff.showMoreItem"),

    revenueDetail: t("report.staff.revenueDetail"),
    date: t("report.staff.date"),
    time: t("report.staff.time"),
    branch: t("report.staff.branch"),
    totalOrder: t("report.staff.totalOrder"),
    revenue: t("report.staff.revenue"),
    position: t("report.staff.position"),
    staffCode: t("report.staff.code"),
    allBranch: t("dashboard.allBranch"),

    createdTime: t("report.staff.createdTime"),
    orderCode: t("order.orderId"),
    detail: t("report.staff.detail"),
    total: t("report.staff.total"),
    method: t("report.staff.method"),
    customer: t("report.staff.customer"),
    status: t("order.status"),
    grossTotal: t("report.staff.grossTotal"),
    discount: t("report.staff.discount"),
    taxFee: t("report.staff.taxFee"),
    allPosition: t("report.staff.allPosition"),
    placeholderSearchByOrderId: t("report.staff.placeholderSearchByOrderId"),
    backToStaffList: t("report.staff.backToStaffList", "Back to staff list"),
    viewDetail: t("report.staff.viewDetail", "View details"),
    noDataFound: t("table.noDataFound"),
    orders: t("report.staff.orders")
  };

  const fetchDatableAsync = async (IsGetStaffInfo = false) => {
    let params = {
      languageCode: languageService.getLang(),
      branchId: staffSummaryParams.branchId ?? "",
      startDate: moment(selectedDate.startDate).locale("en-US").format(DEFAULT_FORMAT_DATE),
      endDate: moment(selectedDate.endDate).locale("en-US").format(DEFAULT_FORMAT_DATE),
      typeOptionDate: selectedDate.businessSummaryWidgetFilter ?? 0,
      timeZone: new Date().getTimezoneOffset() / 60,
      pageSize: DEFAULT_PAGESIZE,
      pageNumber: pageNumber,
      accountId: currentStaff?.accountId,
      IsGetStaffInfo: IsGetStaffInfo,
      orderCode: orderCode
    };
    const res = await reportDataService.getStaffRevenuesDetailAsync(params);
    if (res?.listOrder) {
      const orders = res.listOrder.map(order => mappingRecordToColumns(order));
      if (isMobile && pageNumber > 1) {
        const newList = [...dataSource, ...orders];
        setDataSource(newList);
        setIsFetching(false);
      }
      else {
        setDataSource(orders);
      }
    }
    setTotalRecord(res?.totalOrder ?? 0);
    setRevenue(res?.revenueAmount ?? 0);
  };

  useDebounce(
    () => {
      fetchDatableAsync(false);
    },
    [pageNumber, selectedDate, orderCode],
    100
  );


  useEffect(() => {
    if (currentStaff && currentStaff?.accountId) {
      fetchDatableAsync(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStaff?.accountId]);

  useEffect(() => {
    setSelectedDate({
      startDate: staffSummaryParams.startDate,
      endDate: staffSummaryParams.endDate
    });
    setOrderTypeFilterTime(staffSummaryParams.businessSummaryWidgetFilter)
  }, [staffSummaryParams]);

  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);

  const handleChangePage = (page, pageSize) => {
    setPageNumber(page);
  };

  const handleSearch = (value) => {
    setPageNumber(1);
    setOrderCode(value);
  };

  const handleChangeDatePicker = (date, typeOptionDate) => {
    setPageNumber(1);
    setTypeOptionDate(typeOptionDate);
    setSelectedDate(date);
  };

  const handleExport = async () => {
    let languageCode = languageService.getLang();
    var timeZone = new Date().getTimezoneOffset() / 60;
    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);
    let param = {
      startDate,
      endDate,
      businessSummaryWidgetFilter: typeOptionDate,
      languageCode,
      timeZone,
      accountId: currentStaff.accountId,
      branchId: staffSummaryParams.branchId || "",
      orderCode: orderCode || "",
    }
    try {
      var response = await reportDataService.exportStaffDetailRevenueAsync(param);
      handleDownloadFile(response);
    } catch (error) {
      const { statusText } = error
      message.error(statusText);
    }
  };

  const mappingOrderStatus = (orderStatusId) => {
    switch (orderStatusId) {
      case OrderStatus.New:
        return <span className="order-status-new">{pageData.new}</span>;
      case OrderStatus.Returned:
        return <span className="order-status-returned">{pageData.returned}</span>;
      case OrderStatus.Canceled:
        return <span className="order-status-canceled">{pageData.canceled}</span>;
      case OrderStatus.ToConfirm:
        return <span className="order-status-to-confirm">{pageData.toConfirm}</span>;
      case OrderStatus.Processing:
        return <span className="order-status-processing">{pageData.processing}</span>;
      case OrderStatus.Delivering:
        return <span className="order-status-delivering">{pageData.delivering}</span>;
      case OrderStatus.Completed:
        return <span className="order-status-completed">{pageData.completed}</span>;
      case OrderStatus.Draft:
        return <span className="order-status-draft">{pageData.draft}</span>;
      default:
        return <></>;
    }
  };

  const mappingRecordToColumns = (item) => {
    return {
      id: item.id,
      orderCode: item.orderId,
      customerId: item?.customer ? item?.customer.id : "",
      customerPhone: item?.customer ? item?.customer.phoneNumber : "",
      customerName: item?.customer ? item?.customer.fullName : "",
      paymentMethodName: t(item?.paymentMethodName),
      totalAmount: item?.totalAmount,
      taxAndFee: item?.taxFee,
      discount: item?.discount,
      grossTotal: item?.grossTotal,
      createdDate: item?.createdTime,
      statusId: item?.statusId,
    };
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.createdTime,
        dataIndex: "createdDate",
        width: "14%",
        render: (_, record) => {
          return (
            <div className="column">
              <span className="wrap-item">{pageData.date}: {formatDate(record?.createdDate, "D MMM YYYY")}</span>
              <span className="wrap-item">{pageData.time}: {formatDate(record?.createdDate, "HH:mm:ss")}</span>
            </div>
          );
        },
      },
      {
        title: pageData.orderCode,
        dataIndex: "orderCode",
        width: "12%",
        render: (_, record) => {
          return <Link to={`/report/order/detail/${record.id}`} className="order-code" target="_blank">{record?.orderCode}</Link>;
        },
      },
      {
        title: pageData.detail,
        dataIndex: "totalAmount",
        render: (_, record) => {
          return (
            <div className="column">
              <span className="wrap-item">{pageData.grossTotal}: {formatCurrencyWithoutSuffix(record?.grossTotal)}</span>
              <span className="wrap-item">{pageData.discount}: {formatCurrencyWithoutSuffix(record?.discount)}</span>
              <span className="wrap-item">{pageData.taxFee}: {formatCurrencyWithoutSuffix(record?.taxAndFee)}</span>
            </div>
          );
        },
      },
      {
        title: pageData.total,
        dataIndex: "orderCode",
        width: "15%",
        render: (_, record) => {
          return <div className="order-total-amount">{formatCurrencyWithoutSuffix(record?.totalAmount)}</div>;
        },
      },
      {
        title: pageData.method,
        dataIndex: "paymentMethodName",
        width: "12%",
      },
      {
        title: pageData.customer,
        dataIndex: "customerName",
        width: "15%",
        render: (_, record) => {
          return (
            <div className="column">
              <Link to={`/customer/detail/${record.customerId}`} className="customer-name wrap-item" target="_blank">{record?.customerName}</Link>
              {record?.customerPhone && <span className="wrap-item">{formatPhoneNumber(phoneCode, record?.customerPhone)}</span>}
            </div>
          );
        },
      },
      {
        title: pageData.status,
        dataIndex: "statusId",
        width: "9rem",
        align: "center",
        render: (_, record) => {
          return <div className="order-status-tag">{mappingOrderStatus(record?.statusId)}</div>;
        },
      },
    ];
    return columns;
  };

  const renderPopover = (title, content, trigger) => {
    return (
      <Popover trigger={trigger} placement="bottom" content={content}>
        {title}
      </Popover>
    );
  };

  const renderBtnExport = () => {
    const isHasPermissionExport = hasPermission(PermissionKeys.EXPORT_STAFF_REPORT);
    if (!isHasPermissionExport) return null;
    return (
      <div className="export-button"
        onClick={() => handleExport()}>
        <ExportOutlinedIcon />
      </div>
    );
  };

  const renderBtnSearch = () => {
    return (
      <div className="search-button"
        onClick={() => setIsSearch(true)}>
        <SearchIcon />
      </div>
    );
  };

  const handleVisibleViewDetails = (visible, item) => {
    setOpenViewDetail(visible);
    setItemOrderRevenueDetail(item);
  };

  const renderOrderRevenueDetail = (item, isViewDetail = false) => {
    if (!item) return;
    return (
      <div className="order-revenue-detail" key={item.id} >
        <div className="order-status-tag">
          {mappingOrderStatus(item?.statusId)}
          {isViewDetail && <CloseOutlined onClick={() => handleVisibleViewDetails(false)} />}
        </div>
        <div className="order-code-customer">
          <div className="order-code-title">
            {pageData.orderCode}:
            <Link to={`/report/order/detail/${item.id}`} className="order-code" target="_blank">{item?.orderCode}</Link>
          </div>
          <div className="order-customer-title">
            {pageData.customer}:
            <Link to={`/customer/detail/${item.customerId}`} className="customer-name" target="_blank">{item?.customerName}</Link>
          </div>
        </div>
        <div className="order-createTime-phone">
          <span>{formatDate(item?.createdDate, "D MMM YYYY")} {formatDate(item?.createdDate, "HH:mm:ss")}</span>
          <span>{item?.customerPhone}</span>
        </div>
        {
          isViewDetail &&
          <div className="order-detail-amount">
            <span>
              {pageData.detail}
            </span>
            <div className="column">
              <span>{pageData.grossTotal}: {formatCurrency(item?.grossTotal)}</span>
              <span>{pageData.discount}: {formatCurrency(item?.discount)}</span>
              <span>{pageData.taxFee}: {formatCurrency(item?.taxAndFee)}</span>
            </div>
          </div>
        }
        <div className="order-total-detail">
          <span className="order-total-amount">{pageData.total}: {formatCurrency(item?.totalAmount)}</span>
          {!isViewDetail && item && <span className="view-detail-order" onClick={() => handleVisibleViewDetails(true, item)}>{pageData.viewDetail}</span>}
        </div>
      </div >
    );
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e?.target;
    if ((scrollTop + clientHeight === scrollHeight) && !isFetching && (pageNumber * DEFAULT_PAGESIZE < totalRecord)) {
      setIsFetching(true)
      setPageNumber(pageNumber + 1);
    }
  };

  const renderCalendarFilterButton = () => {
    return (
      <div className="action-button action-button-calendar-component">
        <FnbDatePicker
          selectedDate={selectedDate}
          orderTypeFilterTime={orderTypeFilterTime}
          setSelectedDate={(date, typeOptionDate) => handleChangeDatePicker(date, typeOptionDate)}
        />
      </div>
    );
  };

  return (
    isMobile ? (
      <Modal
        open={open}
        footer={null}
        closable={false}
        className="revenue-detail-container"
        title={
          <>
            <span className="back-revenue-staff" onClick={onCancel}>
              {pageData.backToStaffList}
            </span>
            <div className="button-header-revenue-staff-detail">
              {!isSearch && renderBtnSearch()}
              {renderBtnExport()}
            </div>
          </>
        }
      >
        {isSearch && <div className="search-bar">
          <Input
            autoFocus={true}
            maxLength={100}
            onChange={(e) => {
              setValueSearch(e.target.value);
              handleSearch(encodeURIComponent(e.target.value));
            }}
            allowClear
            size="large"
            onBlur={() => valueSearch === '' && setIsSearch(false)}
            placeholder={pageData.placeholderSearchByOrderId}
            prefix={<SearchIcon width={24} height={24} />}
          />
        </div>
        }
        <span className="revenue-detail-title">
          {t(pageData.revenueDetail, {
            staff_name: currentStaff?.staffName || "",
          })}
        </span>
        {renderCalendarFilterButton()}
        <div className={`list-order-revenue-details${isSearch ? ' search' : ''}`} onScroll={(e) => handleScroll(e)}>
          {
            dataSource?.length > 0 ?
              dataSource.map((item) => (
                <>
                  {renderOrderRevenueDetail(item, false)}
                </>
              ))
              : (
                <div className="no-data-found-component">
                  <FolderIcon />
                  <p className="text-center body-2">
                    {pageData.noDataFound}
                  </p>
                </div>
              )
          }
        </div>
        <div className="staff-detail">
          <div className="staff-detail-code-totalRevenue">
            <div className="staff-detail-code">
              {pageData.staffCode}:
              <span>{currentStaff?.staffCode}</span>
            </div>
            <div className="staff-detail-totalRevenue">{formatCurrency(totalRevenue)}</div>
          </div>
          <div className="staff-detail-position-orders">
            <span className="staff-position">
              {
                currentStaff?.permissions && currentStaff?.permissions.length > 0
                  ? currentStaff?.permissions[0]?.name
                  : "Admin"
              }
            </span>
            <span className="staff-orders">{formatCurrencyWithoutSuffix(totalRecord)} {pageData.orders}</span>
          </div>
          <div className="staff-detail-branch">
            <span className="staff-branch-name">
              {currentStaff && currentStaff?.branches && currentStaff?.branches.length > 0
                ? currentStaff?.branches[0]?.name
                : pageData.allBranch
              }
            </span>
            {currentStaff?.branches?.length - 1 > 0 &&
              <span className="staff-branch-more">
                {`(${t(pageData.showMoreItem, { length: currentStaff?.branches?.length - 1 })})`}
              </span>
            }
          </div>
        </div>
        {
          openViewDetail &&
          <div className="view-details-modal">
            {renderOrderRevenueDetail(itemOrderRevenueDetail, true)}
          </div>
        }
      </Modal >
    ) : (
      <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
        closable={false}
        title={
          <>
            <span className="revenue-detail-title">
              {t(pageData.revenueDetail, {
                staff_name: currentStaff?.staffName || "",
              })}
            </span>
            <div className="revenue-close-icon" onClick={onCancel}>
              <CloseOutlined />
            </div>
          </>
        }
        className="revenue-detail-container"
      >
        <div className="revenue-detail-wrapper desktop">
          <div className="staff-info-component">
            <div className="staff-info-component-left">
              <div className="staff-code">
                <div className="staff-info text-ellipsis">{pageData.staffCode}:  {currentStaff?.staffCode}</div>
              </div>
              <div className="staff-position">
                <div className="label-show-more">
                  <div className={`staff-info text-ellipsis ${currentStaff?.permissions?.length - 1 > 0 ? 'max-width' : ''}`}>
                    {pageData.position}: {"  "} 
                    {currentStaff?.permissions && currentStaff?.permissions.length > 0
                      ? renderPopover(currentStaff?.permissions[0]?.name,
                        <Col className="popover-show-more">
                          <Row span={24}>{currentStaff?.permissions[0]?.name}</Row>
                        </Col>)
                      : "Admin"}
                  </div>
                  {currentStaff?.permissions?.length - 1 > 0 && renderPopover(
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a>({t(pageData.showMoreItem, { length: currentStaff?.permissions?.length - 1 })})</a>,
                    <div className="popover-show-more-wrapper">
                      <Col className="popover-show-more">
                        {currentStaff?.permissions?.slice(1, currentStaff?.permissions?.length).map((item) => (
                          <Row span={24}>{item?.name}</Row>
                        ))}
                      </Col>
                    </div>,
                    "click",
                  )}
                </div>
              </div>
              <div className="staff-branch">
                <div className="label-show-more">
                  <div className={`staff-info text-ellipsis ${currentStaff?.branches?.length - 1 > 0 ? 'max-width' : ''}`}>
                    {pageData.branch}:{" "}
                    {currentStaff && currentStaff?.branches && currentStaff?.branches.length > 0
                      ? renderPopover(currentStaff?.branches[0]?.name,
                        <Col className="popover-show-more">
                          <Row span={24}>{currentStaff?.branches[0]?.name}</Row>
                        </Col>)
                      : pageData.allBranch}
                  </div>
                  {currentStaff?.branches?.length - 1 > 0 && renderPopover(
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a>({t(pageData.showMoreItem, { length: currentStaff?.branches?.length - 1 })})</a>,
                    <div className="popover-show-more-wrapper"><Col className="popover-show-more">
                      {currentStaff?.branches?.slice(1, currentStaff?.branches?.length).map((item) => (
                        <Row span={24}>{item?.name}</Row>
                      ))}
                    </Col></div>,
                    "click",
                  )}
                </div>

              </div>
            </div>
            <div className="staff-info-component-right">
              <div>
                <div className="staff-total hight-line ml-auto">
                  {pageData.totalOrder}: <span>{formatCurrencyWithoutSuffix(totalRecord)}</span>
                </div>
              </div>
              <div>
                <div className="staff-revenue hight-line">
                  {pageData.revenue}: <span>{formatCurrencyWithoutSuffix(totalRevenue)}</span>
                </div>
              </div>
            </div>
          </div>

          <FnbTable
            columns={getColumns()}
            pageSize={20}
            dataSource={dataSource}
            currentPageNumber={pageNumber}
            total={totalRecord}
            onChangePage={handleChangePage}
            scrollX={1400}
            //TO DO: Comment this code because it impact to scroll X for table detail
            // scrollY={`calc(100dvh - ${isDesktop ? "479px" : "750px"})`}
            search={{
              placeholder: pageData.placeholderSearchByOrderId,
              onChange: handleSearch,
            }}
            calendarComponent={{
              onSelectedDatePicker: handleChangeDatePicker,
              selectedDate: selectedDate,
              orderTypeFilterTime: orderTypeFilterTime,
            }}
            exportExcel={
              hasPermission(PermissionKeys.EXPORT_STAFF_REPORT) && {
                buttonTitle: "Export",
                onClick: handleExport,
                icon: <ExportOutlinedIcon />,
              }
            }
          />
        </div>
      </Modal>
    )
  );
};

export default RevenueDetail;
