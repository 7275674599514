import CreateQrOrderPage from "../create-qr-order/create-qr-order.page";
import { store } from "store";
import { useEffect, useState } from "react";

export default function CloneQrOrderPage(props) {
  const [qrOrder, setQrOrder] = useState(store.getState()?.qrCode);

  //Clone the new QrOrder. StartDate = current date. IsStopped set to false
  useEffect(() => {
    const startDate = new Date();
    setQrOrder((previousState) => ({
      ...previousState,
      startDate: startDate,
      isStopped: false,
      endDate:
        previousState.endDate != null && previousState.endDate < previousState.startDate
          ? null
          : previousState?.endDate,
    }));
  }, []);

  return (
    <>
      <CreateQrOrderPage initialData={qrOrder} isClone={true} />
    </>
  );
}
