import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { NoDataFoundComponent } from "components/no-data-found/no-data-found.component";
import {Product, SearchIcon} from "constants/icons.constants";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import FnbCheckBox from "../../../../components/fnb-checkbox/fnb-checkbox";
import { Thumbnail } from "../../../../components/thumbnail/thumbnail";
import "./product-category-search-modal.component.scss";
import { formatNumberWithCommas } from "../../../../themes/utils/helpers";

export default function ProductCategorySearchModal({visible, className, handleCancel, branchId, listSelectedCategories, setListSelectedCategories}) {
  const { t } = useTranslation();
  const pageData = {
    add: t("kitchen.add"),
    selectedCategory: t("kitchen.selectedCategory"),
    categorySearch: t("kitchen.productCategorySearchTitle"),
    products: t("kitchen.products"),
    alreadyIn: t("kitchen.alreadyIn"),
    searchCategoryPlaceholder: t("kitchen.searchCategoryPlaceholder"),
  };

  const [categories, setCategories] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(listSelectedCategories ?? []);
  const [keyword, setKeyword] = useState("");

  const renderListCategories = () => {
    return searchCategories.map((category, index) => {
      return (
        <Row
          key={`content-view-${index}`}
          id={`content-view-${index}`}
          className={"category-modal-content-row"}
          style={{
            borderBottomWidth: index === searchCategories?.length - 1 ? 0 : 1,
          }}
        >
          <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <FnbCheckBox
              defaultChecked={selectedCategories?.findIndex((x) => x.id === category.id) !== -1}
              checked={selectedCategories?.findIndex((x) => x.id === category.id) !== -1}
              value={category.id}
              onChange={(e) => handleCheckCategory(e, category)}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Row style={{ gap: 12 }}>
              <Col>
                {category?.thumbnail ? (
                  <Thumbnail width={64} height={64} src={category?.thumbnail} />
                ) : (
                  <Product width={64} height={64} />
                )}
              </Col>
              <Col style={{ alignSelf: "center", flex: 1, gap: 6 }}>
                <Row>
                  <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>{category?.name}</Typography>
                </Row>
              </Col>
            </Row>
          </Col>
          {category?.kitchenId && category?.kitchenName ? (
            <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingRight: 16 }}>
              <Typography style={{fontWeight: 400, fontSize: 16, color: "#2f2d39"}}>
                {pageData.alreadyIn}&nbsp;
                <span style={{ fontWeight: 700 }}>{category?.kitchenName}</span>
              </Typography>
            </Col>
          ) : null}
          <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>
              {formatNumberWithCommas(category?.numberOfProduct)} {pageData.products}
            </Typography>
          </Col>
        </Row>
      );
    });
  };

  const handleGetCategories = async () => {
    let data = await productCategoryDataService.getAllProductCategoriesByBranchIdAsync(branchId);
    if (data) {
      setCategories(data?.productCategories);
      setSearchCategories(data?.productCategories);
    }
  };

  const handleCheckCategory = (e, category) => {
    if (e.target.checked) {
      let newListCategories = [...selectedCategories, category];
      setSelectedCategories(newListCategories);
    } else {
      let newListCategories = selectedCategories?.filter((x) => x.id !== category.id);
      setSelectedCategories(newListCategories);
    }
  };

  useDebounce(
    () => {
      if (keyword?.length === 0) {
        setSearchCategories(categories);
      } else {
        let newCategories = categories.filter((x) => {
          return (
            x?.name?.removeVietnamese().toLowerCase().includes(keyword?.removeVietnamese().toLowerCase())
          );
        });
        setSearchCategories(newCategories);
      }
    },
    [keyword],
    500,
  );

  const handleSubmitFilter = () => {
    setListSelectedCategories(selectedCategories);
    handleCancel();
  };

  useEffect(() => {
    if (branchId && visible) {
      void handleGetCategories();
      setSelectedCategories([...listSelectedCategories]);
    }
    setKeyword('');
  }, [visible]);

  useEffect(() => {
    if (searchCategories?.length > 0) document.getElementById("content-view-0")?.scrollIntoView();
  }, [searchCategories]);

  return (
    <FnbModal
      visible={visible}
      className={className}
      handleCancel={handleCancel}
      onOk={handleSubmitFilter}
      title={pageData.categorySearch}
      width={1000}
      closeIcon
      okText={`${pageData.add} ${selectedCategories?.length > 0 ? `(${selectedCategories?.length})` : ""} ${pageData.selectedCategory}`}
      content={
        <div className="content-inner">
          <FnbInput
            value={keyword}
            prefix={
              <>
                {selectedCategories?.length === 0 && <SearchIcon />}
                {selectedCategories?.map((category) => {
                  let selectedCategory = categories.find((x) => x.id === category.id);
                  return (
                    <FnbChip
                      className={"category-chip"}
                      id={selectedCategory?.id}
                      isDisableToolTip={true}
                      title={selectedCategory?.name}
                      isRemoveChip={true}
                      onRemoveChip={() => {
                        let newListCategories = selectedCategories?.filter((x) => x.id !== category.id);
                        setSelectedCategories(newListCategories);
                      }}
                    />
                  );
                })}
              </>
            }
            onChange={(e) => setKeyword(e.target.value)}
            className="category-input"
            maxLength={100}
            allowClear
            placeholder={pageData.searchCategoryPlaceholder}
          />
          <div className="category-modal-content">
            <div className="category-modal-content-list">
              {searchCategories?.length > 0 ? (
                renderListCategories()
              ) : (
                <div className="category-search-no-data">
                  <NoDataFoundComponent />
                </div>
              )}
            </div>
          </div>
        </div>
      }
      disabledBtnOk={selectedCategories?.length === 0}
    />
  );
}
