import http, { downloadAsync } from "../../utils/http-common";
import qs from "query-string";

const controller = "updateStockRequest";

const importIngredientUpdateStock = (data) => {
  return http.post(`/${controller}/import`, data);
};

const downloadTemplate = (languageCode) => {
  return downloadAsync(`/${controller}/template?languageCode=${languageCode}`);
};

const downloadFileErrAsync = (data) => {
  return http.post(`/${controller}/template-error`, data, { observe: "response", responseType: "blob" });
};

const approveUpdateStockRequestAsync = (data) => {
  return http.put(`/${controller}/approve`, data);
}

const rejectUpdateStockRequestAsync = (data) => {
  return http.put(`/${controller}/reject`, data);
}
const getHistoryAsync = (params) => {
  // Params: {KeySearch, BranchId, StartDate, EndDate, Type, PageNumber, PageSize, Request Type}
  return http.get(`/${controller}/history`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const createUpdateStockRequest = (data) => {
  return http.post(`/${controller}`, data);
};

const getUpdateStockRequestById = (id,action) => {
  return http.get(`${controller}?id=${id}&action=${action}`);
}

const editUpdateStockRequestById = (data) => {
  return http.put(`/${controller}`, data);
};

const filterUpdateStock = (
  pageNumber,
  pageSize,
  keySearch,
  startDate,
  endDate,
  businessSummaryWidgetFilter,
  reasonType,
  branchId,
  status,
) => {
  let url = `/${controller}/filter?pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}`;
  if (startDate) url += `&startDate=${startDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  if (businessSummaryWidgetFilter) url += `&businessSummaryWidgetFilter=${businessSummaryWidgetFilter}`;
  if (branchId) url += `&branchId=${branchId}`;
  if (reasonType) url += `&reasonType=${reasonType}`;
  if (status !== undefined) url += `&status=${status}`;

  return http.get(url);
};


const ingredientUpdateStockRequestDataService = {
  importIngredientUpdateStock,
  downloadTemplate,
  downloadFileErrAsync,
  approveUpdateStockRequestAsync,
  rejectUpdateStockRequestAsync,
  getHistoryAsync,
  createUpdateStockRequest,
  filterUpdateStock,
  getUpdateStockRequestById,
  editUpdateStockRequestById,
};
export default ingredientUpdateStockRequestDataService;
