export const EnumStoreThemeStatus = {
  New: 0,
  Publishing: 1,
  Published: 2,
};

export const EnumCustomDomainStep = {
  CheckNameServers: 1,
  CheckMappingStatus: 2,
  UpdateCustomDomain: 3
};