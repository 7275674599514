import { Button, Card, Col, Image, Row, Space, Tag } from "antd";
import SocialTiktok from "assets/images/social-tiktok-shop.png";
import SocialZalo from "assets/images/social-zalo-oa.png";
import SocialFacebook from "assets/images/social-facebook.png";

import PageTitle from "components/page-title";
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import "./index.scss";
import loginDataService from "data-services/login/login-data.service";
import { getStorage, localStorageKeys } from "utils/localStorage.helpers";
import http from "utils/http-common";
import { env } from "env";
import { FnbButton } from "components/fnb-button/fnb-button";
import jwt_decode from "jwt-decode";
import socialDataService from "data-services/social/social.service";
export default function SocialChannel(props) {
  const { t, storeId } = props;

  const pageData = {
    title: t("socialChannel.title"),
    description: t("socialChannel.description"),
    tiktokTitle: t("socialChannel.tiktok.title"),
    tiktokDescription: t("socialChannel.tiktok.description"),
    zaloTitle: t("socialChannel.zalo.title"),
    zaloDescription: t("socialChannel.zalo.description"),
    facebookTitle: t("socialChannel.facebook.title"),
    facebookDescription: t("socialChannel.facebook.description"),
    exploreNow: t("socialChannel.exploreNow"),
    comingSoon: t("socialChannel.comingSoon"),
  };
  const handleClickTiktok = async () => {
    const res = await socialDataService.checkHasPackageAsync();
    if (res) {
      let token = getStorage(localStorageKeys.TOKEN);
      if (res.isSuccess && res.isPackageExists) {
        const urlWithToken = `${env.REACT_APP_SOCIAL_DOMAIN}?token=${encodeURIComponent(token)}`;
        window.open(urlWithToken, "_blank");
      } else {
        const loginUrl = new URL(`${env.REACT_APP_URL}/billing`);
        document.cookie = `token=${token}; max-age=3600; path=/`;
        loginUrl.searchParams.append("accountId", res.accountId);
        window.open(loginUrl.toString(), "_blank");
      }
    }
  };

  return (
    <Row className="social-channel">
      <Row className="mb-4 social-channel-title">{pageData.title}</Row>
      <Row className="social-channel-description">{pageData.description}</Row>
      <Col className="social-channel-cover">
        <Col md={8} lg={8}>
          <Row className="social-channel-card">
            <Row>
              <Image src={SocialTiktok} preview={false} />
            </Row>
            <Row className="social-channel-card-info">
              <Row className="mb-3 title-channel">{pageData.tiktokTitle}</Row>
              <Row className="mb-4 description-title-channel">{pageData.tiktokDescription}</Row>
              <Row className="mb-2"></Row>
              <Row>
                <FnbButton variant="tertiary" className="coming-soon-text" text={pageData.comingSoon.toUpperCase()} />
                {/* <Button type="primary" onClick={() => handleClickTiktok()}>
                  {pageData.exploreNow}
                </Button> */}
              </Row>
            </Row>
          </Row>
        </Col>
        <Col md={8} lg={8}>
          <Row className="social-channel-card">
            <Row>
              <Image src={SocialZalo} preview={false} />
            </Row>
            <Row className="social-channel-card-info">
              <Row className="mb-3 title-channel">{pageData.zaloTitle}</Row>
              <Row className="mb-4 description-title-channel">{pageData.zaloDescription}</Row>
              <Row className="mb-2"></Row>
              <FnbButton variant="tertiary" className="coming-soon-text" text={pageData.comingSoon.toUpperCase()} />
            </Row>
          </Row>
        </Col>
        <Col md={8} lg={8}>
          <Row className="social-channel-card">
            <Row>
              <Image src={SocialFacebook} preview={false} />
            </Row>
            <Row className="social-channel-card-info">
              <Row className="mb-3 title-channel">{pageData.facebookTitle}</Row>
              <Row className="mb-4 description-title-channel">{pageData.facebookDescription}</Row>
              <Row className="mb-2"></Row>
              <FnbButton variant="tertiary" className="coming-soon-text" text={pageData.comingSoon.toUpperCase()} />
            </Row>
          </Row>
        </Col>
      </Col>
    </Row>
  );
}
