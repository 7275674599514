import { useEffect, useState } from "react";
import { Form, Row, message } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import supplierDataService from "data-services/supplier/supplier-data.service";
import purchaseOrderDataService from "data-services/purchase-order/purchase-order-data.service";
import { tableSettings } from "constants/default.constants";
import { FnbTable } from "components/fnb-table/fnb-table";
import { formatPhoneNumber, hasPermission } from "utils/helpers";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle, ConfirmViewActiveItemStyle } from "constants/level-menu.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

export default function TableSupplier(props) {
  const { setShowAddNew } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoSupplier, setInfoSupplier] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const linkPO = "/inventory/detail-purchase-order/";

  const pageData = {
    search: t("supplier.search"),
    code: t("table.code"),
    name: t("supplier.title"),
    phone: t("table.phone"),
    email: t("table.email"),
    action: t("table.action"),
    supplierDeleteSuccess: t("messages.isDeletedSuccessfully"),
    supplierDeleteFail: t("supplier.supplierDeleteFail"),
    deleteNotificationMessage: t("supplier.deleteNotificationMessage"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  useEffect(() => {
    setPurchaseOrders(
      infoSupplier?.purchaseOrders?.map((order) => {
        const link = `${linkPO}${order?.id}`;
        const label = order?.code;
        return { label, link };
      }),
    );
  }, [infoSupplier]);

  const getColumnsSupplier = () => {
    const columnsSupplier = [
      {
        title: pageData.code,
        dataIndex: "code",
        key: "code",
        width: "10%",
        align: "left",
        className: "grid-code-column",
        sorter: (pre, cur) => pre?.code.localeCompare(cur?.code),
        render: (_, record) => <FnbTooltip onlyShowWhenEllipsis={true}>{record.code}</FnbTooltip>,
      },
      {
        title: pageData.name,
        dataIndex: "name",
        key: "name",
        width: "50%",
        align: "left",
        className: "grid-text-column",
        ellipsis: true,
        sorter: (pre, cur) => pre?.name.localeCompare(cur?.name),
        render: (_, record) => {
          return (
            <div>
              <FnbTooltip onlyShowWhenEllipsis={true}>{record.name}</FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.phone,
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: "20%",
        align: "left",
        className: "grid-text-column",
        render: (val, record) => {
          return formatPhoneNumber(record.phoneCode, val);
        },
      },
      {
        title: pageData.email,
        dataIndex: "email",
        key: "email",
        width: "20%",
        align: "left",
        className: "grid-text-column",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record.email}</FnbTooltip>;
        },
      },
    ];

    return columnsSupplier;
  };

  const onSelectedRowKeysChange = (keySelected) => {
    setSelectedRowKeys(keySelected);
  };

  const onEditRowKeys = (_, selectedRows) => {
    if (!selectedRows) return;
    return history.push(`/inventory/supplier/edit/${selectedRows[0]?.id}`);
  };

  const onDeleteRowKeys = (_, selectedRows) => {
    if (!selectedRows) return;
    purchaseOrderDataService.getPurchaseOrderBySupplierIdAsync(selectedRows[0]?.id).then((res) => {
      setInfoSupplier(res);
      setIsModalVisible(true);
    });
  };

  const handleDeleteItem = (id, name) => {
    supplierDataService.deleteSupplierByIdAsync(id).then((res) => {
      if (res) {
        setIsModalVisible(false);
        props.refreshDataTable(tableSettings.page, tableSettings.pageSize, "");
        message.success(`${name} ${pageData.supplierDeleteSuccess}`);
        setSelectedRowKeys([]);
      } else {
        message.error(pageData.supplierDeleteFail);
      }
    });
  };

  const formatNotificationMessage = (name) => {
    let mess = t(pageData.deleteNotificationMessage, { name: name });
    return mess;
  };

  const onRowClick = (record, _index) => {
    history.push(`/inventory/supplier/${record.id}`);
  };

  return (
    <Form className="form-staff">
      <Row>
        <FnbTable
          className="form-table"
          columns={getColumnsSupplier()}
          pageSize={props.pageSize}
          dataSource={props.dataSource}
          currentPageNumber={props.currentPageNumber}
          total={props.total}
          onChangePage={props.onChangePage}
          editPermission={PermissionKeys.EDIT_SUPPLIER}
          deletePermission={PermissionKeys.DELETE_SUPPLIER}
          search={{
            placeholder: pageData.search,
            onChange: props.onSearch,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditRowKeys,
            onDelete: onDeleteRowKeys,
          }}
          onRowClick={onRowClick}
          emptyText={
            hasPermission(PermissionKeys.CREATE_SUPPLIER) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={() => setShowAddNew(true)}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
        <ConfirmDialogComponent
          visible={isModalVisible && infoSupplier.isOpenPurchaseOrder}
          onOk={() => setIsModalVisible(false)}
          content={formatNotificationMessage(infoSupplier?.supplier?.name)}
          activeItems={purchaseOrders}
          type={ConfirmStyle.NOTIFICATION}
          typeViewActiveItems={ConfirmViewActiveItemStyle.GRID}
          width={600}
        />

        <ConfirmDialogComponent
          visible={isModalVisible && !infoSupplier.isOpenPurchaseOrder}
          onCancel={() => setIsModalVisible(false)}
          onOk={() => handleDeleteItem(infoSupplier?.supplier?.id, infoSupplier?.supplier?.name)}
          type={ConfirmStyle.DELETE}
        />
      </Row>
    </Form>
  );
}
