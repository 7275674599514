import './fnb-group-button.scss';

function FnbGroupButton(props) {
  const { listButton, className } = props;
  return (
    <div className={`fnb-group-button ${className}`}>
      {listButton?.map((button) => {
        return button;
      })}
    </div>
  );
}

export default FnbGroupButton;
