export const ContactDirectionIcon = (props) => {
  const { color = "#FF8C21", width = 18, height = 18 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5406 18C10.8081 18 10.1563 17.5083 9.95512 16.8043L8.00859 9.99139L1.1957 8.04486C0.49166 7.8437 0 7.19187 0 6.4597C0 5.71724 0.477633 5.07528 1.18856 4.86199L17.3211 0.0222422C17.5069 -0.0334804 17.7083 0.0172852 17.8455 0.154465C17.9827 0.291645 18.0335 0.493055 17.9777 0.678891L13.138 16.8115C12.9247 17.5224 12.2828 18 11.5406 18Z"
        fill={color}
      />
    </svg>
  );
};
