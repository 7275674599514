import { createHttp } from "../utils/http-common";

const controller = "notification";

const getNotificationByCustomerIdAsync = (params) => {
  const http = createHttp();
  return http.get(`/${controller}`, { params });
};

const notificationDataService = {
  getNotificationByCustomerIdAsync,
};

export default notificationDataService;
