import { useEffect } from "react";
import { useSelector } from "react-redux";
import { staffRevenueSelector } from "store/modules/staff-revenue/staff-revenue.reducer";
import { CLASSNAME_CHART_STAFF, ID_ELEMENT_CHART_STAFF } from "../data/chart-staff-revenue.data";
import "./chart-y-axis.style.scss";

function ChartYAxisStaff() {
  const listStaffNumber = useSelector(staffRevenueSelector).chart.listStaffNumber;

  useEffect(() => {
    const elementGrid = document.getElementsByClassName(CLASSNAME_CHART_STAFF.GRID)[0];
    const elementWrap = document.getElementById(ID_ELEMENT_CHART_STAFF.WRAPPER_CHART);
    const elementRect = elementGrid.getBoundingClientRect();
    const beginY = elementRect.top;
    const differentSpace = beginY - elementWrap.getBoundingClientRect().top;
    const height = elementRect.height;
    const rowGap = height / 4;
    const beginPosition = differentSpace;
    for (let index = 0; index < 4; index++) {
      const element = document.getElementById(`chart-staff-revenue-y-axis-line-id-${index + 1}`);
      if (element) {
        element.style.top = `${beginPosition + rowGap * index}px`;
        element.style.visibility = listStaffNumber?.length > 0 ? "visible" : "hidden";
      }
    }
  }, [listStaffNumber]);
  return (
    <>
      <div className="chart-staff-revenue-y-axis-line" id="chart-staff-revenue-y-axis-line-id-1" />
      <div className="chart-staff-revenue-y-axis-line" id="chart-staff-revenue-y-axis-line-id-2" />
      <div className="chart-staff-revenue-y-axis-line" id="chart-staff-revenue-y-axis-line-id-3" />
      <div className="chart-staff-revenue-y-axis-line" id="chart-staff-revenue-y-axis-line-id-4" />
    </>
  );
}

export default ChartYAxisStaff;
