export const objectiveEnum = {
  customerData: 1,
  orderData: 2,
};

export const customerDataEnum = {
  registrationDate: 1,
  birthday: 2,
  gender: 3,
  tag: 4,
  age: 5,
  platform: 6,
};

export const registrationDateConditionEnum = {
  on: 1,
  before: 2,
  after: 3,
};

export const monthsInYear = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
};

export const ageDataEnum = {
  isEqual: 1,
  isLargerThan: 2,
  isLargerThanOrEqual: 3,
  isLessThan: 4,
  isLessThanOrEqual: 5,
};

export const genderEnum = {
  male: 1,
  female: 2,
  other: 3,
};

export const orderDataEnum = {
  totalCompletedOrders: 1,
  totalPurchasedAmount: 2,
  days: 3,
};

export const orderConditionEnum = {
  isEqual: 1,
  isLargerThan: 2,
  isLargerThanOrEqual: 3,
  isLessThan: 4,
  isLessThanOrEqual: 5,
};
