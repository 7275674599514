/* eslint-disable jsx-a11y/anchor-is-valid */
import { Popover } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setLanguageSession } from "../../../modules/session/session.actions";
import i18n from "../../../utils/i18n";
import { ArrowRightIcon, DefaultFlag, LanguageDropdownIcon } from "../../assets/icons.constants";
import "../change-language/change-language.scss";
import { listDefaultLanguage } from "./list-language";
import moment from "moment";
import { storeConfigSelector } from "../../../modules/session/session.reducers";
import { EnumInternationalRegion } from "../../constants/store-web-page.constants";
import storeDataService from "../../../data-services/store-data.service";
import { localStorageKeys } from "../../../utils/localStorage.helpers";
import { getInternationalRegion } from "../../../utils/helpers";

function ChangeLanguage(props) {
  const { onVisibleChange, visible, className, isCustomize = false } = props;
  const { t } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState(listDefaultLanguage[0]);
  const [languageList, setLanguageList] = useState(isCustomize ? listDefaultLanguage : []);
  const dispatch = useDispatch();
  const languageSession = useSelector((state) => state.session?.languageSession);
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  const storeType = useSelector(storeConfigSelector)?.type;
  const storeId = useSelector((state) => state?.session?.currentUser?.storeId);
  const [isGetStoreConfig, setIsGetStoreConfig] = useState(false);
  const isInternational = getInternationalRegion();

  useEffect(() => {
    if (storeType === undefined && !isGetStoreConfig && !isCustomize) {
      setIsGetStoreConfig(true);
      getStoreConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetStoreConfig]);

  useLayoutEffect(() => {
    if (!languageSession) {
      loadLanguage(storeType);
      window.reloadLang = loadLanguage(storeType);
    } else {
      if (storeType !== undefined || isInternational) {
        let languages = listDefaultLanguage;
        let defaultLang = languageSession?.default;
        if (storeType === EnumInternationalRegion.INTERNATIONAL || isInternational) {
          languages = languages?.filter((language) => language?.languageCode !== "vi");
          let languageEN = languages?.filter((language) => language?.languageCode === "en");
          defaultLang = languageEN?.length > 0 ? languageEN[0] : defaultLang;
          if (isInternational) {
            setDefaultLanguage(defaultLang ?? languages[0]);
            setLanguageList(languages);
          }
        }
        if (!isCustomize) {
          setDefaultLanguage(defaultLang ?? languages[0]);
          setLanguageList(languages);
        }
      }
    }
  }, []);

  useEffect(() => {
    const locale = defaultLanguage?.languageCode;
    moment.locale(locale);
  }, [defaultLanguage]);

  const getStoreConfig = async () => {
    const currentStoreId = storeId ?? JSON.parse(localStorage?.getItem(localStorageKeys.STORE_CONFIG))?.storeId;
    if (currentStoreId) {
      const resStoreConfig = await storeDataService.getStoreConfig(currentStoreId);
      if (resStoreConfig?.data) {
        let type = resStoreConfig?.data?.storeConfig?.type;
        loadLanguage(type, false);
      }
    }
  };

  const loadLanguage = (storeType, isSetLanguageSession = true) => {
    let languages = listDefaultLanguage;
    let defaultLang = defaultLanguage;
    if (storeType === EnumInternationalRegion.INTERNATIONAL) {
      languages = languages?.filter((language) => language?.languageCode !== "vi");
      let languageEN = languages?.filter((language) => language?.languageCode === "en");
      defaultLang = languageEN?.length > 0 ? languageEN[0] : defaultLang;
    }
    setLanguageList(languages);
    setDefaultLanguage(defaultLang);
    if (isSetLanguageSession) dispatch(setLanguageSession({ default: defaultLang, list: languages }));
  };

  const onChangeLang = (selectedLang) => {
    i18n.changeLanguage(selectedLang);

    let language = languageList?.find((lang) => lang.languageCode === selectedLang);
    setDefaultLanguage(language);
    dispatch(setLanguageSession({ default: language, list: languageList }));

    onVisibleChange && onVisibleChange(false);
  };

  const jsUcfirst = (string) => {
    string?.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const contentLanguage = () => {
    return (
      <>
        {languageList?.map((item, index) => (
          <div key={index} onClick={() => onChangeLang(item.languageCode)} className="pointer">
            {getFlag(item.languageCode, item.name)}
            <p>{t(item.name)}</p>
          </div>
        ))}
      </>
    );
  };

  const getFlag = (languageCode) => {
    var defaultLanguage = languageList?.find((lang) => lang?.languageCode === languageCode);
    if (defaultLanguage) {
      return <>{defaultLanguage?.flag}</>;
    }
    return (
      <div style={{ width: "40px", height: "40px" }}>
        <DefaultFlag />
      </div>
    );
  };

  const getDefaultFlag = (languageCode, title) => {
    var Flag = languageList?.find((lang) => lang?.languageCode === languageCode);
    return (
      <>
        <div className="Flag-Default">{Flag?.flag}</div>
        <span className="Flag-Default-Title">{t(title)}</span>
        {!isMaxWidth575 && <LanguageDropdownIcon />}
        {isMaxWidth575 && <ArrowRightIcon className="icon-arrow-mobile" />}
      </>
    );
  };

  return (
    <Popover
      className={className}
      placement="bottom"
      overlayClassName="change-language-popover-tropicalfood"
      content={contentLanguage}
      trigger="click"
      open={visible}
      onOpenChange={onVisibleChange && onVisibleChange}
    >
      <div className="link-language">
        {defaultLanguage && getDefaultFlag(defaultLanguage.languageCode, jsUcfirst(defaultLanguage.languageCode))}
      </div>
    </Popover>
  );
}

export default ChangeLanguage;
