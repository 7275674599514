import { Image, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { CalendarDateLinearIcon } from "../../../../assets/icons.constants";
import defaultImageBlog from "../../../../assets/images/default-image-blog.png";
import "./blog-card-recent-post.component.scss";
import { formatDateTimeByLanguageCode } from "../../../../../utils/helpers";

const ELLIPSIS_ROWS = 2;
export function BlogCardRecentPostComponent(props) {
  const { blogRecentPost, isCustomize, colorGroupArticle } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const pageData = {
    viewMore: t("button.viewMore"),
    recentPost: t("blogList.recentPost"),
  };

  const ellipsisConfig = {
    rows: ELLIPSIS_ROWS,
    suffix: "",
    tooltip: true,
  };

  const hanldeClickOnRecentPost = (urlEncode) => {
    if (isCustomize == false || isCustomize == null || isCustomize == undefined) {
      const urlParams = `blog/${urlEncode}`;
      history.replace("");
      history.push(urlParams);
      window.scrollTo(0, 0);
    }
  };

  const isMaxWidth576 = useMediaQuery({ maxWidth: 576 });

  return (
    <div className={isCustomize ? "blog-card-recent-post-customize" : "blog-card-recent-post"}>
      <div className="recent-post">
        <span className="title-recent-post">{pageData.recentPost}</span>
        {blogRecentPost.map((post, index) => (
          <div className="post-component">
            <div className="post-image">
              <Image src={post?.bannerImageUrl ?? defaultImageBlog} preview={!isMaxWidth576} />
            </div>
            <div className="post-content">
              <Typography.Title
                className="post-title"
                style={{ color: colorGroupArticle?.titleColor }}
                ellipsis={ellipsisConfig}
                onClick={(e) => {
                  hanldeClickOnRecentPost(post?.urlEncode);
                }}
              >
                {post?.title}
              </Typography.Title>
              <div className="post-created-time">
                <CalendarDateLinearIcon />
                <span>{formatDateTimeByLanguageCode(post?.createdTime, localStorage.getItem("i18nextLng"))}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
