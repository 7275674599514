import FnbTypography from "components/fnb-typography/fnb-typography";
import { ContentItemStepGuidelineStyle, RightCornerBackgroundStyle } from "./content-item-step-guideline.styled";
import { FnbButton } from "components/fnb-button/fnb-button";
import theme from "theme";
import { useMediaQuery } from "react-responsive";

const ContentItemStepGuideline = ({ title, content, button = { label: "", onClick: undefined } }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 });

  return (
    <ContentItemStepGuidelineStyle>
      <FnbTypography text={title} variant={!isMobile ? theme.typography["h1-bold"] : theme.typography["b1-bold"]} />
      <FnbTypography variant={theme.typography["b2-regular"]} style={{ lineHeight: "22px" }}>
        <span
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </FnbTypography>
      <FnbButton style={{ marginTop: 14 }} text={button.label} onClick={button.onClick} />
      <RightCornerBackgroundStyle />
    </ContentItemStepGuidelineStyle>
  );
};

export default ContentItemStepGuideline;
