import { Col, Row } from "antd";
import { claimTypesConstants } from "constants/claim-types.constants";
import { FnbLogo } from "constants/icons.constants";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setPermissions, setToken } from "store/modules/session/session.actions";
import "./billing-page.scss";
import BillingComponent from "./components/billing.component";
import StepSelectPackage from "./components/step-select-package";
import packageDataService from "data-services/package/package-data.service";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";

export default function BillingPage(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isSocialChannel, setIsSocialChannel] = useState(false);
  const [isExploreNow, setIsExploreNow] = useState(true);
  const [userInformation, setUserInformation] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const accountId = new URLSearchParams(window.location.search).get("accountId");
  const dispatch = useDispatch();
  const cookies = document.cookie.split("; ");
  const billingComponentRef = useRef(null);
  const [packages, setPackages] = useState([]);
  const [periodPackageDurations, setPeriodPackageDurations] = useState([]);
  const [mainGroupFunctions, setMainGroupFunctions] = useState([]);
  const [periodSelected, setPeriodSelected] = useState(12);
  const [defaultHoverPackageId, setDefaultHoverPackageId] = useState(null);

  const goToStep = (step) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("step", step);
    history.push(`/billing?${searchParams.toString()}`);
  };

  const initData = async () => {
    const responsePackages = await packageDataService.getPackagesAsync();
    if (responsePackages?.succeeded) {
      setPackages(responsePackages?.data?.packages);
      setPeriodPackageDurations(responsePackages?.data?.periods);
      const defaultPackageIdSelected =
        responsePackages?.data?.packages[responsePackages?.data?.packages?.length - 1].id;
      setDefaultHoverPackageId(defaultPackageIdSelected);
    }

    const responseFunctions = await packageDataService.getFunctionAsync();
    if (responseFunctions?.succeeded) {
      setMainGroupFunctions(responseFunctions?.data?.mainGroupFunctions);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    let userInfo = state.session?.auth?.userInfo;
    setUserInformation(userInfo);
  }, []);

  useEffect(() => {
    if (accountId) {
      let token = null;
      const tokenCookie = cookies.find((cookie) => {
        const [key, value] = cookie.split("=");
        return key === "token";
      });
      if (tokenCookie) {
        token = tokenCookie.split("=")[1];
      }
      const user = getUserInfo(token);
      setUserInformation(user);
      setUserAuth(user, token, []);
      setIsSocialChannel(true);
      setIsExploreNow(false);
    }
  }, [accountId]);

  useEffect(() => {
    goToStep(currentStep + 1);
  }, [currentStep]);


  const setUserAuth = (auth, token, permissions) => {
    if (auth) {
      dispatch(setAuth(auth));
    }
    if (token) {
      dispatch(setToken(token));
    }
    dispatch(setPermissions(permissions));
  };

  const state = useSelector((state) => state);

  const getUserInfo = (token) => {
    let claims = jwt_decode(token);
    let user = {
      userId: claims[claimTypesConstants.id],
      accountId: claims[claimTypesConstants.accountId],
      fullName: claims[claimTypesConstants.fullName],
      emailAddress: claims[claimTypesConstants.email] ?? claims[claimTypesConstants.phoneNumber],
      accountType: claims[claimTypesConstants.accountType],
      currencyCode: claims[claimTypesConstants.currencyCode],
      storeId: claims[claimTypesConstants.storeId],
      currencySymbol: claims[claimTypesConstants.currencySymbol],
    };
    return user;
  };

  const onClickBuyPackage = (period, packageId) => {
    const packageItem = packages?.find((item) => item.id === packageId);
    const packagePricingInfo = packageItem?.pricings?.find((item) => item.period === period);
    const currencyCode = packagePricingInfo?.currencyCode;
    const indexDuration = periodPackageDurations?.findIndex((item) => item === period);
    if (billingComponentRef.current) {
      billingComponentRef.current?.selectPricingPackage(
        packageId,
        packagePricingInfo,
        currencyCode,
        packageItem?.tax,
        indexDuration,
      );
      setTimeout(() => {
        setCurrentStep(1);
      }, 100);
    }
  };

  const classNameSelectPackage = classNames({
    "d-none": currentStep !== 0,
  });

  const classNameBilling = classNames({
    "billing-package": true,
    "d-none": currentStep === 0,
  });

  const resetDefaultPackage = () => {
    const defaultPackageIdSelected = packages[packages?.length - 1].id;
    setPeriodSelected(12);
    setDefaultHoverPackageId(defaultPackageIdSelected);
  };
  return (
    <>
      <StepSelectPackage
        t={t}
        className={classNameSelectPackage}
        packages={packages}
        periodPackageDurations={periodPackageDurations}
        mainGroupFunctions={mainGroupFunctions}
        periodSelected={periodSelected}
        setPeriodSelected={setPeriodSelected}
        defaultHoverPackageId={defaultHoverPackageId}
        setDefaultHoverPackageId={setDefaultHoverPackageId}
        onClickBuyPackage={onClickBuyPackage}
      />
      <Row className={classNameBilling}>
        <Col span={24} className="logo-container">
          <FnbLogo className="fnb-logo" />
        </Col>
        <Col span={24} className="wrapper-package">
          <div className="package-container">
            <BillingComponent
              packages={packages}
              ref={billingComponentRef}
              userInfo={userInformation}
              isSocialChannel={isSocialChannel}
              isExploreNow={isExploreNow}
              setIsExploreNow={setIsExploreNow}
              isBilling={true}
              currentStep={currentStep}
              onChangeStep={setCurrentStep}
              resetDefaultPackage={resetDefaultPackage}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
