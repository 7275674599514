import styled from "styled-components";
import theme from "theme";
import BackgroundPriceLeftDefault from "../../assets/images/package-price-background-left-gray.png";
import BackgroundPriceLeftHover from "../../assets/images/package-price-background-left-white.png";
import BackgroundPriceRightDefault from "../../assets/images/package-price-background-right-gray.png";
import BackgroundPriceRightHover from "../../assets/images/package-price-background-right-white.png";

export const PackagePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  min-width: 296px;
  height: 265px;
  border-radius: 20px;
  transition: all 0.2s ease-out;
  cursor: pointer;
  position: relative;
  background: ${(p) => (p.defaultHover ? "linear-gradient(120.43deg, #6E5FBE -1.81%, #372A7E 102.18%);" : "")};
  &:hover {
    @media (hover: hover) and (pointer: fine) {
      background: linear-gradient(120.43deg, #6e5fbe -1.81%, #372a7e 102.18%);
      transition: all 0.2s ease-out;
      box-shadow: 8px 8px 32px 0px #50429B14;
    }
  }
`;

export const HeadingPriceContainer = styled.div`
  position: absolute;
  visibility: ${(p) => (p.defaultHover ? "visible" : "hidden")};

  ${PackagePriceContainer}:hover & {
    @media (hover: hover) and (pointer: fine) {
      visibility: visible;
    }
  }
`;

export const Platform = styled.div`
  z-index: 1;
  margin-top: ${(p) => (p.defaultHover ? "14px" : "44px")};
  transition: all 0.2s ease-out;
  span {
    transition: all 0.2s ease-out;
    font-weight: ${(p) => (p.defaultHover ? "700" : "400")};
    color: ${(p) => (p.defaultHover ? `${theme.colors.primary.main}` : `${theme.colors.default.black}`)};
  }
  ${PackagePriceContainer}:hover & {
    @media (hover: hover) and (pointer: fine) {
      transition: all 0.2s ease-out;
      margin-top: 14px;
      span {
        transition: all 0.2s ease-out;
        color: ${theme.colors.primary.main};
        font-weight: 700;
      }
    }
  }
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 102px;
  span {
    color: ${(p) => (p.defaultHover ? `${theme.colors.default.white} !important` : `${theme.colors.primary.main}`)};
  }

  ${PackagePriceContainer}:hover & {
    @media (hover: hover) and (pointer: fine) {
      span {
        color: ${theme.colors.default.white} !important;
      }
    }
  }
`;

export const BackgroundPriceContainer = styled.div`
  height: 92px;
  width: 295px;
  top: 98px;
  position: absolute;
  background-size: contain;
`;

export const BackgroundPriceContainerLeft = styled(BackgroundPriceContainer)`
  background-image: ${(p) =>
    p.defaultHover ? `url(${BackgroundPriceLeftHover})` : `url(${BackgroundPriceLeftDefault})`};
  ${PackagePriceContainer}:hover & {
    @media (hover: hover) and (pointer: fine) {
      background-image: url(${BackgroundPriceLeftHover});
    }
  }
`;

export const BackgroundPriceContainerRight = styled(BackgroundPriceContainer)`
  background-image: ${(p) =>
    p.defaultHover ? `url(${BackgroundPriceRightHover})` : `url(${BackgroundPriceRightDefault})`};
  ${PackagePriceContainer}:hover & {
    @media (hover: hover) and (pointer: fine) {
      background-image: url(${BackgroundPriceRightHover});
    }
  }
`;

export const PriceText = styled.span`
  font-size: 40px;
  font-weight: 800;
  line-height: 50.4px;
  color: ${theme.colors.primary.main};
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 16px;
`;
