import { Radio } from "antd";
import React from "react";
import { formatTextCurrency } from "../../../../../utils/helpers";
import { PaymentMethodType } from "../../../../constants/payment-method.constants";
import { useTranslation } from "react-i18next";

const DepositMethods = (props) => {
  const { depositMethods, depositAmount = 0, value } = props;
  const [t] = useTranslation();

  const pageData = {
    momo: t("deposit.momo", "Momo"),
    bankTransfer: t("deposit.bankTransfer", "Bank Transfer"),
  };

  return (
    <>
      {depositMethods?.map((method) => {
        let paymentName = method?.paymentMethodName;
        if (method?.paymentMethodEnumId === PaymentMethodType.BankTransfer) {
          paymentName = pageData.bankTransfer;
        }

        return (
          <Radio key={method.paymentMethodEnumId} value={method.paymentMethodEnumId} className="payment_method_item">
            <div className="payment_option_item">
              <img src={method?.icon} className="payment_icon" alt={method?.paymentMethodName} />
              <div className="payment_name">{paymentName}</div>
              <div className={`deposit-amount ${value === method.paymentMethodEnumId ? "" : "deposit-amount-disable"}`}>
                {formatTextCurrency(depositAmount)}
              </div>
            </div>
          </Radio>
        );
      })}
    </>
  );
};

export default DepositMethods;
