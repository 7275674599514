import { Card, message } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { tableSettings } from "constants/default.constants";
import { Ellipse } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import optionDataService from "data-services/option/option-data.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { executeAfter, hasPermission } from "utils/helpers";
import "./table-option.component.style.scss";

export const TableOption = React.forwardRef((props, ref) => {
  const { onEditItem, setShowAddNew } = props;
  const [t] = useTranslation();
  const [keySearch, setKeySearch] = useState("");
  const [listOption, setListOption] = useState([]);
  const [pageNumber, setPageNumber] = useState(tableSettings.page);
  const [totalOptionManagement, setTotalOptionManagement] = useState(0);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [rowData, setRowData] = useState({});

  const pageData = {
    optionManagement: t("option.optionManagement"),
    addNew: t("button.addNew"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteContent: t("option.confirmDeleteContent"),
    optionDeleteSuccess: t("option.optionDeleteSuccess"),
    optionDeleteFail: t("option.optionDeleteFail"),
    searchName: t("form.searchName"),
    no: t("table.no"),
    name: t("option.customizationName"),
    options: t("option.customizationLevel"),
    action: t("table.action"),
    percent: "%",
    btnFilter: t("button.filter"),
    btnSort: t("button.sort"),
    setAsdefault: t("option.setAsDefault"),
    toCreateData: t("area.toCreateData"),
  };

  React.useImperativeHandle(ref, () => ({
    refresh() {
      initDataTableOptions(tableSettings.page, tableSettings.pageSize, keySearch);
    },
    refreshSelectRows() {
      setSelectedRowKeys([]);
    },
  }));

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteContent, { optionName: name });
    return mess;
  };

  useEffect(async () => {
    //get list options
    initDataTableOptions(tableSettings.page, tableSettings.pageSize, keySearch);
  }, []);

  const handleShowModalDelete = (selectedRowKeys, selectedRows) => {
    if (!selectedRows?.[0]) return false;
    const data = {
      name: selectedRows[0].name,
      id: selectedRows[0].id,
      key: selectedRows[0].key,
    };
    setRowData(data);
    setIsShowDeleteModal(true);
  };

  const onDeleteItem = async () => {
    if (!(rowData && rowData.id)) return;

    var res = await optionDataService.deleteOptionByIdAsync(rowData.id);
    if (res) {
      message.success(pageData.optionDeleteSuccess);
      // Recount selected items after delete
      if (selectedRowKeys) setSelectedRowKeys([]);
    } else {
      message.error(pageData.optionDeleteFail);
    }
    handleHiddenModalDelete();
    await initDataTableOptions(tableSettings.page, tableSettings.pageSize, keySearch);
  };

  const handleHiddenModalDelete = () => setIsShowDeleteModal(false);

  const initDataTableOptions = async (pageNumber, pageSize, keySearch) => {
    setPageNumber(pageNumber);
    //get list options
    let res = await optionDataService.getOptionsByStoreIdAsync(pageNumber, pageSize, keySearch);
    let options = mappingToDataTableOptions(res.options);
    setListOption(options);
    setTotalOptionManagement(res.total);

    let numberRecordCurrent = pageNumber * pageSize;
    if (numberRecordCurrent > res.total) {
      numberRecordCurrent = res.total;
    }
    setNumberRecordCurrent(numberRecordCurrent);
  };

  const mappingToDataTableOptions = (options) => {
    return options?.map((i, index) => {
      return {
        key: i.id,
        id: i.id,
        no: index + 1,
        name: i.name,
        options: i.optionLevel.sort((a, b) => b.isSetDefault - a.isSetDefault),
      };
    });
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.no,
        dataIndex: "no",
        width: "5rem",
        align: "left",
      },
      {
        title: pageData.name,
        dataIndex: "name",
        ellipsis: true,
        sorter: (pre, current) => pre?.name.localeCompare(current?.name),
        render: (name) => (
          <Paragraph style={{ maxWidth: "inherit" }} placement="top" ellipsis={{ tooltip: name }} color="#50429B">
            <span>{name}</span>
          </Paragraph>
        ),
      },
      {
        title: (
          <div className="option-table__dv-header">
            <span className="option-table__span-title">{pageData.options}</span>
            <div className="option-table__dv--flex-horizon">
              <div>
                <Ellipse className="option-table__icon--eclipse" />
              </div>
              <div className="option-table__dv--flex-horizon option-table__dv-default-txt">
                <div>
                  <span className="option-table__span-default">=</span>
                </div>
                <div>
                  <span className="option-table__span-default">{pageData.setAsdefault}</span>
                </div>
              </div>
            </div>
          </div>
        ),
        dataIndex: "options",
        ellipsis: true,
        render: (optionLevels, index) => {
          const data = [];
          optionLevels.forEach((optionLevel) => {
            let text = optionLevel.name + (optionLevel.quota ? "-" + optionLevel.quota + pageData.percent : "");
            const item = {
              title: text,
              detail: text,
              isSetDefault: optionLevel.isSetDefault === true,
            };
            data.push(item);
          });
          return <MemoizedFnbListChips data={data} isOptionChip={true} />;
        },
      },
    ];

    return columns;
  };

  const onSearch = (keySearch) => {
    executeAfter(500, async () => {
      setKeySearch(keySearch);
      initDataTableOptions(tableSettings.page, tableSettings.pageSize, keySearch);
    });
  };

  const displayTextWidth = (text) => {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    let metrics = context.measureText(text);
    return metrics.width;
  };

  const onChangePage = async (pageNumber, pageSize) => {
    initDataTableOptions(pageNumber, pageSize, keySearch);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <>
      <Card className="fnb-card-custom">
        <FnbTable
          className={`option-table ${(listOption?.length === 0 && "option-table--empty") || ""}`}
          columns={getColumns()}
          pageSize={tableSettings.pageSize}
          dataSource={listOption}
          currentPageNumber={pageNumber}
          total={totalOptionManagement}
          onChangePage={onChangePage}
          editPermission={PermissionKeys.EDIT_OPTION}
          deletePermission={PermissionKeys.DELETE_OPTION}
          search={{
            placeholder: pageData.searchName,
            onChange: onSearch,
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            onEdit: onEditItem,
            onDelete: handleShowModalDelete,
          }}
          emptyText={
            hasPermission(PermissionKeys.CREATE_OPTION) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={() => setShowAddNew(true)}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
      </Card>

      {isShowDeleteModal && (
        <DeleteConfirmComponent
          title={pageData.confirmDelete}
          visible={isShowDeleteModal}
          content={formatDeleteMessage(rowData.name)}
          okText={pageData.btnDelete}
          cancelText={pageData.btnIgnore}
          permission={PermissionKeys.DELETE_OPTION}
          onOk={onDeleteItem}
          onCancel={handleHiddenModalDelete}
        />
      )}
    </>
  );
});
