import ResetPassword from "./reset-password.page";

// Define the route
const route = {
  key: "app.resetPassword",
  position: 0,
  path: "/reset-password",
  name: "Reset Password",
  isMenu: false,
  exact: true,
  auth: false,
  component: ResetPassword,
  child: [],
};
export default route;
