import { Col, Form, Image, Radio, Row, Select } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingleOptGroup } from "components/fnb-select-single-opt-group/fnb-select-single-opt-group";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { CustomerFilterIcon } from "constants/icons.constants";
import { images } from "constants/images.constants";
import { paymentMethodType } from "constants/payment-method.constants";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "utils/helpers";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
const { Option } = Select;

const CustomerName = ({ customerName, keySearch, id }) => {
  const startIndex = customerName
    ?.removeVietnamese()
    ?.toLowerCase()
    .indexOf(keySearch?.removeVietnamese()?.toLowerCase());
  const endIndex = startIndex + keySearch?.length;
  return (
    <>
      {id && startIndex >= 0 ? (
        <>
          <FnbTypography text={customerName?.slice(0, startIndex)} variant={theme.typography["b1-regular"]} />
          <FnbTypography
            text={customerName?.slice(startIndex, endIndex)}
            variant={theme.typography["b1-bold"]}
            color={theme.colors.primary.main}
          />
          <FnbTypography
            text={customerName?.slice(endIndex, customerName?.length)}
            variant={theme.typography["b1-regular"]}
          />
        </>
      ) : (
        <FnbTypography text={customerName} variant={theme.typography["b1-regular"]} />
      )}
    </>
  );
};
const FilterOrderReport = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const {
    serviceTypes,
    paymentMethods,
    customers,
    orderStatus,
    onShowFilter,
    className,
    fetchDataOrderReport,
    setCountFilter,
    salesChannel,
  } = props;
  const defaultValue = "";

  //#region PageData
  const pageData = {
    serviceType: t("reportRevenue.serviceType"),
    paymentMethod: t("report.shift.paymentMethod"),
    customer: t("table.customer"),
    status: t("table.status"),
    filter: {
      resetAllFilters: t("productManagement.filter.resetallfilters"),
    },
    allTypes: t("order.allTypes"),
    allMethods: t("order.allMethods"),
    allCustomer: t("order.allCustomer"),
    allStatus: t("order.allStatus"),
    all: t("order.all"),
    enterprise: t("order.enterprise"),
    personal: t("order.personal"),
    platform: t("platform.title"),
    platformAll: t("productManagement.filter.status.all"),
  };

  const salesChannelRadios = [
    {
      id: "",
      name: pageData.platformAll,
    },
    ...salesChannel,
  ];
  //#endregion

  useImperativeHandle(ref, () => ({
    clearFilter: () => {
      form?.resetFields();
      onApplyFilter();
      if (onShowFilter) {
        onShowFilter(false);
      }
    },
    getDataForm: () => {
      return form.getFieldsValue() ?? {};
    },
  }));

  const onApplyFilter = () => {
    let formValue = form.getFieldsValue() ?? {};
    const quantityChanges = countFilterControl(formValue);
    setCountFilter(quantityChanges);
    const dataOrder = {
      pageNumber: 1,
    };
    fetchDataOrderReport(dataOrder);
  };

  const countFilterControl = (formValue) => {
    let countServiceType = formValue.serviceTypeId === "" || formValue.serviceTypeId === undefined ? 0 : 1;

    let countPaymentMethod = formValue.paymentMethodId === "" || formValue.paymentMethodId === undefined ? 0 : 1;
    let countCustomer = formValue.customerId === "" || formValue.customerId === undefined ? 0 : 1;

    let countStatus = formValue.orderStatusId === "" || formValue.orderStatusId === undefined ? 0 : 1;

    let salesChannel = formValue.salesChannel === "" || formValue.salesChannel === undefined ? 0 : 1;
    return countServiceType + countPaymentMethod + countCustomer + countStatus + salesChannel;
  };

  const [keySearchCustomer, setKeySearchCustomer] = useState("");
  const refSearchCustomer = useRef(null);

  const renderCustomerOption = (customer) => {
    return (
      <Option
        key={customer?.id}
        value={customer?.id}
        label={customer?.name}
        customerName={customer?.name}
        customerPhone={customer?.phone}
      >
        <Row className="row-customer-filter-order-transaction">
          <Col span={13}>
            <Row gutter={12}>
              <Col span={3}>
                {customer?.id ? (
                  <Image
                    src={customer?.thumbnail ?? "error"}
                    width={22}
                    height={22}
                    style={{ borderRadius: 4, objectFit: "cover" }}
                    fallback={images.imgDefault}
                    className="avt-customer"
                  />
                ) : (
                  <CustomerFilterIcon width={20} height={20} className="all-customer-img" />
                )}
              </Col>
              <Col span={21}>
                <FnbTooltip onlyShowWhenEllipsis title={customer?.name} maxWidthContent={170} maxWidth={400}>
                  <CustomerName customerName={customer?.name} keySearch={keySearchCustomer} id={customer?.id} />
                </FnbTooltip>
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            {customer?.phone && <p style={{ float: "right" }}>{formatPhoneNumber(customer?.phoneCode, customer?.phone)}</p>}
          </Col>
        </Row>
      </Option>
    );
  };

  const onChangeKeySearch = (value) => {
    if (refSearchCustomer.current) {
      clearTimeout(refSearchCustomer.current);
    }
    refSearchCustomer.current = setTimeout(() => {
      setKeySearchCustomer(value);
    }, 50);
  };

  return (
    <Form
      form={form}
      onFieldsChange={onApplyFilter}
      className={`${className} form-filter-order-report`}
      layout="vertical"
    >
      <Form.Item name="serviceTypeId" label={pageData.serviceType}>
        <FnbSelectSingle
          placeholder={pageData.allTypes}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={serviceTypes}
        />
      </Form.Item>
      <Form.Item name="paymentMethodId" label={pageData.paymentMethod}>
        <FnbSelectSingleOptGroup
          className="payment-group item-input"
          popupClassName="payment-group-dropdown"
          placeholder={pageData.allMethods}
          noTranslateOptionName={true}
          optionGroup={paymentMethods?.map((item) => ({
            id: item.id,
            name: item.name,
            group:
              item.type === paymentMethodType.All
                ? ""
                : item.type === paymentMethodType.Enterprise
                ? pageData.enterprise
                : pageData.personal,
          }))}
          showSearch
          defaultValue={defaultValue}
        />
      </Form.Item>
      <Form.Item name="customerId" label={pageData.customer}>
        <Select
          className="fnb-select-single-admin form-filter-order-report__select-customer"
          popupClassName="fnb-select-single-dropdown-admin form-filter-order-report__select-customer--overlay"
          placeholder={pageData.allCustomer}
          showSearch
          defaultValue={defaultValue}
          filterOption={(input, option) => {
            return (
              option?.customerName
                ?.normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                ?.toLowerCase()
                .includes(
                  input
                    ?.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    ?.toLowerCase(),
                ) ||
              option?.customerName?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
              option?.customerPhone?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            );
          }}
          onSearch={onChangeKeySearch}
        >
          {customers?.map((customer) => {
            return renderCustomerOption(customer);
          })}
        </Select>
      </Form.Item>
      <Form.Item name="orderStatusId" label={pageData.status}>
        <Radio.Group defaultValue={defaultValue} buttonStyle="solid">
          {orderStatus?.map((item) => (
            <FnbRadioButton value={item?.id}>{item?.name}</FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name="salesChannel" label={pageData.platform}>
        <Radio.Group defaultValue={defaultValue} buttonStyle="solid">
          {salesChannelRadios?.map((item) => (
            <FnbRadioButton value={item?.id}>{item?.name}</FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default FilterOrderReport;
