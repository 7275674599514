import { Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Radio, Row, TimePicker } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { DELAYED_TIME } from "constants/default.constants";
import { ArrowDownDropdown, CalendarNewIconBold, InfoCircleFlashSaleIcon, PromoIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { defaultPlatforms, Platform } from "constants/platform.constants";
import { ListPromotionType, PromotionType } from "constants/promotion.constants";
import { ServiceTypeObject, ServiceTypesByPlatform } from "constants/service-type.constants";
import { currency, DateFormat } from "constants/string.constants";
import branchDataService from "data-services/branch/branch-data.service";
import customerSegmentDataService from "data-services/customer-segment/customer-segment-data.service";
import discountCodeDataService from "data-services/discount-code/discount-code-data.service";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import productDataService from "data-services/product/product-data.service";
import storeDataService from "data-services/store/store-data.service";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { checkOnKeyPressValidation, fileNameNormalize, getCurrency, getValidationMessagesWithParentField } from "utils/helpers";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbSelectProduct from "components/fnb-select-product";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";
import theme from "theme";
import i18n from "utils/i18n";

export default function CreateNewDiscountCode(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [discountCodeTypeId, setDiscountCodeTypeId] = useState(0);
  const [listProduct, setListProduct] = useState([]);
  const [listProductCategory, setListProductCategory] = useState([]);
  const [listbranches, setListbranches] = useState([]);
  const [isPercentDiscount, setIsPercentDiscount] = useState(true);
  const [isMinimumPurchaseAmount, setIsMinimumPurchaseAmount] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isAllBranches, setIsAllBranches] = useState(true);
  const [isLimitNumberCouponUse, setIsLimitNumberCouponUse] = useState(false);
  const [isIncludedTopping, setIsIncludedTopping] = useState(false);
  const [isLimitOneTimeUse, setIsLimitOneTimeUse] = useState(true);
  const [customerSegmentInSore, setCustomerSegmentInStore] = useState([]);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [totalEmail, setTotalEmail] = useState(0);
  const [totalSegment, setTotalSegment] = useState(0);
  const [listCustomerId, setListCustomerId] = useState([]);
  const [listCustomerSegmentId, setListCustomerSegmentId] = useState([]);
  const [showCountCustomerSegment, setShowCountCustomerSegment] = useState(false);
  const [isAllCustomers, setIsAllCustomers] = useState(true);
  const [isApplyAllProducts, setIsApplyAllProducts] = useState(false);
  const [isApplyAllCategories, setIsApplyAllCategories] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const pageData = {
    leaveForm: t("messages.leaveForm"),
    create: t("discountCode.btnNewDiscountCodeTitle"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    createDiscountCodeSuccess: t("discountCode.formCreate.createDiscountCodeSuccess"),
    selectDate: t("promotion.selectDate"),
    form: {
      general: t("discountCode.formCreate.generalInformation"),
      name: t("discountCode.formCreate.name"),
      placeholderName: t("discountCode.formCreate.enterDiscountCodeName"),
      maxLengthName: 100,
      pleaseEnterDiscountCodeName: t("discountCode.formCreate.pleaseEnterDiscountCodeName"),
      applicableType: t("discountCode.formCreate.applicableType"),
      product: t("discountCode.formCreate.product"),
      selectProduct: t("discountCode.formCreate.selectProduct"),
      pleaseSelectProduct: t("discountCode.formCreate.pleaseSelectProduct"),
      productCategory: t("discountCode.formCreate.productCategory"),
      selectProductCategory: t("discountCode.formCreate.selectProductCategory"),
      pleaseSelectProductCategory: t("discountCode.formCreate.pleaseSelectProductCategory"),
      percent: "%",
      discountValue: t("discountCode.formCreate.discountValue"),
      maxDiscount: t("discountCode.formCreate.maxDiscount"),
      pleaseEnterPrecent: t("discountCode.formCreate.pleaseEnterPrecent"),
      pleaseEnterMaxDiscount: t("discountCode.formCreate.pleaseEnterMaxDiscount"),
      startDate: t("discountCode.formCreate.startDatetime"),
      pleaseStartDate: t("discountCode.formCreate.pleaseStartDate"),
      pleaseStartTime: t("discountCode.formCreate.pleaseStartTime"),
      pleaseEndDate: t("discountCode.formCreate.pleaseEndDate"),
      pleaseEndTime: t("discountCode.formCreate.pleaseEndTime"),
      endDate: t("discountCode.formCreate.endDatetime"),
      PlaceholderDateTime: t("discountCode.formCreate.placeholderDateTime"),
      termsAndConditions: t("discountCode.formCreate.termsAndConditions"),
      maxLengthTermsAndConditions: 2000,
      maximum: 999999999,
      couponConditions: t("discountCode.formCreate.couponConditions"),
      minimumPurchaseAmountOnBill: t("discountCode.formCreate.minimumPurchaseAmountOnBill"),
      pleaseInputValue: t("discountCode.formCreate.pleaseInputValue"),
      pleaseSelectBranch: t("discountCode.formCreate.pleaseSelectBranch"),
      allBranches: t("discountCode.formCreate.allBranches"),
      selectBranches: t("discountCode.formCreate.selectBranches"),
      includedTopping: t("discountCode.formCreate.includedTopping"),
      platform: t("discountCode.formCreate.platform"),
      pleaseSelectPlatform: t("discountCode.formCreate.pleaseSelectPlatform"),
      code: t("discountCode.formCreate.code"),
      usageLimits: t("discountCode.formCreate.usageLimits"),
      limitNumberOfTimesThisCouponCanBeUsed: t("discountCode.formCreate.limitNumberOfTimesThisCouponCanBeUsed"),
      limitOneUsedPerCustomer: t("discountCode.formCreate.limitOneUsedPerCustomer"),
      discountCode: t("discountCode.formCreate.discountCode"),
      enterDiscountCode: t("discountCode.formCreate.enterDiscountCode"),
      generateCodes: t("discountCode.formCreate.generateCodes"),
      thisCodeIsExisted: t("discountCode.formCreate.thisCodeIsExisted"),
      minimumAmounttoApplyDiscountCode: t("discountCode.formCreate.minimumAmounttoApplyDiscountCode"),
      pleaseEnterDiscountCodeOrGenerate: t("discountCode.formCreate.pleaseEnterDiscountCodeOrGenerate"),
      pleaseSelectTimeframeHasBecomePast: t("discountCode.formCreate.pleaseSelectTimeframeHasBecomePast"),
      allCustomers: t("discountCode.formCreate.allCustomers"),
      customerGroupPlaceholder: t("marketing.emailCampaign.generalTab.customerGroupPlaceholder"),
      customerGroupRequiredMessage: t("marketing.emailCampaign.generalTab.customerGroupRequiredMessage"),
      allProduct: t("discountCode.formCreate.allProducts"),
      allCategories: t("discountCode.formCreate.allCategories"),
      serviceType: t("discountCode.formCreate.serviceType"),
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    guideline: {
      title: t("discountCode.guideline.title"),
      content: t("discountCode.guideline.content"),
    },
  };

  const [onChangeGenerateCode, setOnnChangeGenerateCode] = useState(false);

  const defaultSystemPlatforms = defaultPlatforms.filter((item) => item.id !== Platform.GoFnBApp?.toLowerCase());
  const defaultServiceType = ServiceTypeObject.map((serviceType) => {
    const result = { ...serviceType, selected: true };
    return result;
  });

  useEffect(() => {
    getCurrency();
    setDataOnForm();
    getListBranches();
    getCustomerSegment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const setDataOnForm = () => {
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;

    discountCode.isPercentDiscount = true;
    discountCode.isLimitOneTimeUse = true;
    discountCode.isIncludedTopping = false;
    discountCode.discountCodeTypeId = PromotionType.DiscountTotal;
    discountCode.platformIds = defaultSystemPlatforms.map((platform) => {
      return platform.id;
    });
    discountCode.serviceTypeIds = ServiceTypeObject.map((serviceType) => {
      return serviceType.key;
    });
    setServiceTypes(defaultServiceType);
    form.setFieldsValue(formValue);
  };

  const getCustomerSegment = async () => {
    // Get customer segment list
    const customerSegmentListResult = await customerSegmentDataService.getCustomerSegmentByStoreIdAsync();
    setCustomerSegmentInStore(customerSegmentListResult);
  };

  const countDistinceObjectInArray = (arrayDistinct, array) => {
    array?.forEach((y) => {
      if (!arrayDistinct.includes(y)) {
        arrayDistinct.push(y);
      }
    });
    return arrayDistinct;
  };

  const onUpdateCustomerSegment = (values) => {
    const selectedCustomersSegment = customerSegmentInSore?.filter((x) => values?.indexOf(x.id) > -1);

    //Count distinct customers
    const distinctCustomers = [];
    selectedCustomersSegment?.forEach((x) => {
      if (x?.customers?.length > 0) {
        countDistinceObjectInArray(distinctCustomers, x.customers);
      }
    });
    setTotalCustomer(distinctCustomers.length);
    setListCustomerId(distinctCustomers);
    //Count distinct emails
    const distinctEmails = [];
    selectedCustomersSegment?.forEach((x) => {
      if (x?.emails?.length > 0) {
        countDistinceObjectInArray(distinctEmails, x.emails);
      }
    });
    setTotalEmail(distinctEmails.length);

    setTotalSegment(selectedCustomersSegment?.length);
    setListCustomerSegmentId(selectedCustomersSegment?.map((a) => a.id));
    setShowCountCustomerSegment(selectedCustomersSegment?.length > 0 ? true : false);
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const disabledDateByStartDate = (current) => {
    // Can not select days before today and today
    return current && current < startDate;
  };

  const onCreateNewDiscountCode = (values) => {
    let currentDateTime = new Date();
    let { discountCode } = values;
    var discountCodeStartDate = new Date(
      moment(discountCode?.startDate).year(),
      moment(discountCode?.startDate).month(),
      moment(discountCode?.startDate).date(),
      moment(discountCode?.startTime).hours(),
      moment(discountCode?.startTime).minutes(),
      moment(discountCode?.startTime).seconds(),
    );
    var discountCodeEndDate = new Date(
      moment(discountCode?.endDate).year(),
      moment(discountCode?.endDate).month(),
      moment(discountCode?.endDate).date(),
      moment(discountCode?.endTime).hours(),
      moment(discountCode?.endTime).minutes(),
      moment(discountCode?.endTime).seconds(),
    );

    if (discountCodeStartDate > currentDateTime && discountCodeStartDate < discountCodeEndDate) {
      var req = {
        discountCode: {
          ...discountCode,
          startDate: moment.utc(discountCodeStartDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2),
          endDate: moment.utc(discountCodeEndDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2),
          isMinimumPurchaseAmount: isMinimumPurchaseAmount,
          isLimitNumberCouponUse: isLimitNumberCouponUse,
          isAllBranches: isAllBranches,
          isIncludedTopping: isIncludedTopping,
          isLimitOneTimeUse: isLimitOneTimeUse,
          listCustomerId: listCustomerId,
          totalCustomerSegment: totalSegment,
          isAllCustomers: isAllCustomers,
          listCustomerSegmentId: listCustomerSegmentId,
          isApplyAllCategories: isApplyAllCategories,
          isApplyAllProducts: isApplyAllProducts,
          productIds: [...productIds],
        },
      };
      discountCodeDataService
        .createDiscountCodeAsync(req)
        .then((res) => {
          if (res) {
            onCancel();
            message.success(pageData.createDiscountCodeSuccess);
          }
        })
        .catch((errs) => {
          form.setFields(getValidationMessagesWithParentField(errs, "discountCode"));
        });
    } else {
      message.error(pageData.form.pleaseSelectTimeframeHasBecomePast);
    }
  };

  const onChangeDiscountCodeType = (key) => {
    setDiscountCodeTypeId(key);
    if (key === PromotionType.DiscountProduct) {
      getListProducts();
    } else if (key === PromotionType.DiscountProductCategory) {
      getListProductCategorys();
    }
  };

  const getListProducts = async () => {
    await productDataService.getAllProductsAsync().then((res) => {
      const productOptions =
        res?.products?.sort((pre, current) => {
          return pre?.name?.localeCompare(current?.name);
        }) || [];
      setListProduct(productOptions);
    });
  };

  const getListProductCategorys = async () => {
    var res = await productCategoryDataService.getAllProductCategoriesAsync();
    if (res) {
      setListProductCategory(res.allProductCategories);
    }
  };

  const getListBranches = async () => {
    var res = await branchDataService.getAllBranchsAsync();
    if (res) {
      setListbranches(res.branchs);
      onSelectAllBranches(true, res.branchs);
    }
  };

  const clickCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      onCancel();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onCancel = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push("/store/promotion/discountCode");
    }, DELAYED_TIME);
  };

  const onSelectBranches = (values) => {
    const listBranch = listbranches.filter((b) => values.find((v) => v === b.id));
    if (values && values.length > 0) {
      let formValues = form.getFieldsValue();
      let branchIds = [];
      branchIds = listBranch?.map((item) => item?.id);

      form.setFieldsValue({ ...formValues, discountCode: { branchIds: branchIds } });
    }
  };

  const onSelectAllBranches = (isChecked, listbranches) => {
    setIsAllBranches(isChecked);
    let branchIds = [];
    if (isChecked) {
      branchIds = listbranches?.map((item) => item?.id);
      onSelectBranches(branchIds);
      let formValue = {
        discountCode: {
          branchIds: branchIds,
        },
      };
      form.setFieldsValue(formValue);
    }
  };

  const onChangePlatform = (values) => {
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    discountCode.platformIds = values;
    if (values?.length > 0) {
      let arrServiceType = getServiceTypeByPlatform(values);
      const arrServiceTypeIds = arrServiceType.map((item) => item?.key);
      discountCode.serviceTypeIds = arrServiceTypeIds;
      setServiceTypes(arrServiceType);
    } else {
      setServiceTypes([]);
      discountCode.serviceTypeIds = [];
    }
    form.setFieldsValue(formValue);
  };

  const onChangeServiceType = (values) => {
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    discountCode.serviceTypeIds = values;
    form.setFieldsValue(formValue);
  };

  const getServiceTypeByPlatform = (arrPlatformId, setSelected = true) => {
    let arrServiceType = [];
    if (arrPlatformId?.length > 0) {
      arrPlatformId?.forEach((item) => {
        const serviceTypeByPlatform = ServiceTypesByPlatform.find((platform) => platform.id === item);
        if (serviceTypeByPlatform) {
          serviceTypeByPlatform.serviceTypes.forEach((serviceTypeItem) => {
            if (arrServiceType.findIndex((newServiceType) => newServiceType?.key === serviceTypeItem.key) === -1) {
              arrServiceType.push({ ...serviceTypeItem, selected: setSelected });
            }
          });
        }
      });
    }
    return arrServiceType.sort((s1, s2) => s1.key - s2.key);
  };

  /**
   * Disable Hour From Start Time
   */

  const getDisabledHoursStartTime = () => {
    var hours = [];
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    var discountCodeDate = new Date(
      moment(discountCode?.startDate).year(),
      moment(discountCode?.startDate).month(),
      moment(discountCode?.startDate).date(),
    );
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (discountCodeDate.toDateString() === currentDate.toDateString()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  /**
   * Disable Hour Minute From Start Time
   * @param {*} selectedHour
   */

  const getDisabledMinutesStartTime = (selectedHour) => {
    var minutes = [];
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    var discountCodeDate = new Date(
      moment(discountCode?.startDate).year(),
      moment(discountCode?.startDate).month(),
      moment(discountCode?.startDate).date(),
    );
    var currentDate = new Date(moment().year(), moment().month(), moment().date());
    if (discountCodeDate.toDateString() === currentDate.toDateString()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  /**
   * Disable Hour From End Time
   */

  const getDisabledHoursEndTime = () => {
    var hours = [];
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;

    var discountCodeStartDate = new Date(
      moment(discountCode?.startDate).year(),
      moment(discountCode?.startDate).month(),
      moment(discountCode?.startDate).date(),
    );
    var discountCodeEndDate = new Date(
      moment(discountCode?.endDate).year(),
      moment(discountCode?.endDate).month(),
      moment(discountCode?.endDate).date(),
    );

    if (discountCodeEndDate <= discountCodeStartDate) {
      for (var i = 0; i < moment(discountCode.startTime).hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  /**
   * Disable Hour Minute From End Time
   * @param {*} selectedHour
   */
  const getDisabledMinutesEndTime = (selectedHour) => {
    var minutes = [];
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;

    var discountCodeStartDate = new Date(
      moment(discountCode?.startDate).year(),
      moment(discountCode?.startDate).month(),
      moment(discountCode?.startDate).date(),
    );
    var discountCodeEndDate = new Date(
      moment(discountCode?.endDate).year(),
      moment(discountCode?.endDate).month(),
      moment(discountCode?.endDate).date(),
    );

    if (discountCodeEndDate <= discountCodeStartDate) {
      if (selectedHour === moment(discountCode.startTime).hour()) {
        for (var i = 0; i <= moment(discountCode.startTime).minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  const setStartTime = (value) => {
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    discountCode.startTime = value;
    form.setFieldsValue(formValue);
  };

  const setEndTime = (value) => {
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    discountCode.endTime = value;
    form.setFieldsValue(formValue);
  };

  const onGenerateDiscountCode = async () => {
    let res = await discountCodeDataService.generateDiscountCodeByStoreId();
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    discountCode.code = res?.discountCode;
    form.setFieldsValue(formValue);
  };

  // eslint-disable-next-line no-unused-vars
  const onChangeDiscountCode = (e) => {
    let formValue = form.getFieldsValue();
    let { discountCode } = formValue;
    discountCode.code = fileNameNormalize(e.target.value);
    form.setFieldsValue(formValue);
  };

  const onChangeLimitNumberCouponUse = (checked) => {
    setIsLimitNumberCouponUse(checked);
    let discountCode = form.getFieldValue("discountCode");
    if (checked === true && discountCode?.maximumLimitCouponUse === undefined) {
      // Set default value for maximumLimitCouponUse when user check the first time
      discountCode.maximumLimitCouponUse = 1;
    }
    form.setFieldValue("discountCode", discountCode);
  };

  const onSelectAllCustomer = (isChecked) => {
    setIsAllCustomers(isChecked);
    if (isChecked) {
      setShowCountCustomerSegment(false);
    } else {
      onUpdateCustomerSegment([]);
      let formValue = form.getFieldsValue();
      let { discountCode } = formValue;
      discountCode.listCustomerSegmentId = [];
      form.setFieldsValue(formValue);
    }
  };

  const onChangeOption = (e) => {
    const isChecked = e.target.checked;
    if (discountCodeTypeId === PromotionType.DiscountProductCategory) {
      setIsApplyAllCategories(isChecked);
    }
    if (discountCodeTypeId === PromotionType.DiscountProduct) {
      setIsApplyAllProducts(isChecked);
    }
  };

  const onSelectProductPrice = (_value, options) => {
    setProductIds(new Set(options.map((option) => option?.productId)));
  };

  const renderSelectProducts = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.form.product}
          <span className="text-danger">*</span>
        </h4>
        <div className="check-box-select-all">
          <FnbCheckBox onChange={onChangeOption} checked={isApplyAllProducts}>
            {pageData.form.allProduct}
          </FnbCheckBox>
        </div>
        {isApplyAllProducts ? (
          <Form.Item hidden={!isApplyAllProducts} className="w-100">
            <FnbSelect mode="multiple" disabled={true} />
          </Form.Item>
        ) : (
          <Form.Item
            hidden={isApplyAllProducts}
            name={["discountCode", "productPriceIds"]}
            className="w-100"
            rules={[
              {
                required: !isApplyAllProducts,
                message: <InputValidateMessage message={pageData.form.pleaseSelectProduct} />,
              },
            ]}
          >
            <FnbSelectProduct
              options={listProduct}
              placeholder={pageData.form.selectProduct}
              listHeight={500}
              onChange={onSelectProductPrice}
              hiddenSelectAll
            />
          </Form.Item>
        )}
      </>
    );
  };

  const renderSelectCategorys = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.form.productCategory}
          <span className="text-danger">*</span>
        </h4>
        <div className="check-box-select-all">
          <FnbCheckBox onChange={(event) => onChangeOption(event)} checked={isApplyAllCategories}>
            {pageData.form.allCategories}
          </FnbCheckBox>
        </div>
        <Form.Item
          hidden={isApplyAllCategories}
          name={["discountCode", "productCategoryIds"]}
          className="w-100"
          rules={[
            {
              required: !isApplyAllCategories,
              message: <InputValidateMessage message={pageData.form.pleaseSelectProductCategory} />,
            },
          ]}
        >
          <FnbSelect
            mode="multiple"
            placeholder={pageData.form.selectProductCategory}
            className="w-100"
            allowClear
            options={listProductCategory?.map((option) => ({
              value: option?.id,
              label: option?.name,
            }))}
          />
        </Form.Item>
        <Form.Item hidden={!isApplyAllCategories} className="w-100">
          <FnbSelect mode="multiple" disabled={true} />
        </Form.Item>
      </>
    );
  };

  return (
    <>
      <Form
        onFinish={onCreateNewDiscountCode}
        form={form}
        onFieldsChange={() => setIsChangeForm(true)}
        layout="vertical"
        autoComplete="off"
        className="discount-code-page"
      >
        <FnbHeadingPage
          title={pageData.create}
          guideline={{
            title: pageData.guideline.title,
            content: pageData.guideline.content,
          }}
          isCompactButtons={false}
          listButtons={[
            <FnbButton variant="tertiary" text={pageData.btnCancel} onClick={clickCancel} />,
            <FnbButton
              text={pageData.btnSave}
              permission={PermissionKeys.CREATE_DISCOUNT_CODE}
              minWidth={100}
              type="submit"
            />,
          ]}
        />
        <Row>
          <Card className="fnb-card w-100">
            <Row>
              <FnbTypography text={pageData.form.general} variant={theme.typography["h1-bold"]} color="#2B2162" />
            </Row>
            <Row>
              <h4 className="fnb-form-label mt-32">
                {pageData.form.name}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item
                name={["discountCode", "name"]}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.form.pleaseEnterDiscountCodeName} />,
                  },
                  {
                    type: "string",
                    max: pageData.form.maxLengthName,
                  },
                ]}
                className="w-100"
              >
                <FnbInput
                  showCount
                  maxLength={pageData.form.maxLengthName}
                  placeholder={pageData.form.placeholderName}
                />
              </Form.Item>
            </Row>
            <Row>
              <h4 className="fnb-form-label">
                {pageData.form.applicableType}
                <span className="text-danger">*</span>
              </h4>
              <Form.Item name={["discountCode", "discountCodeTypeId"]} className="w-100">
                <FnbSelect
                  options={ListPromotionType?.map((item) => ({
                    value: item?.key,
                    label: t(item?.name),
                  }))}
                  onChange={(key) => onChangeDiscountCodeType(key)}
                />
              </Form.Item>
            </Row>
            {discountCodeTypeId === PromotionType.DiscountProduct && renderSelectProducts()}
            {discountCodeTypeId === PromotionType.DiscountProductCategory && renderSelectCategorys()}

            <Row gutter={[32, 16]}>
              <Col xs={24} lg={12}>
                <Input.Group size="large">
                  <h4 className="fnb-form-label">{pageData.form.discountValue}<span className="text-danger">*</span></h4>
                  {isPercentDiscount ? (
                    <Form.Item
                      name={["discountCode", "percentNumber"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseEnterPrecent} />,
                        },
                        {
                          min: 0,
                          max: 100,
                          type: "integer",
                          message: <InputValidateMessage message={pageData.form.pleaseEnterPrecent} />,
                        },
                      ]}
                    >
                      <InputNumber
                        id="discountPercent"
                        className="fnb-input-number w-100 discount-amount"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        min={1}
                        max={100}
                        addonAfter={
                          <Form.Item name={["discountCode", "isPercentDiscount"]} style={{ display: "contents" }}>
                            <Radio.Group
                              className="radio-group-discount custom-flex"
                              defaultValue={isPercentDiscount}
                              onChange={(e) => setIsPercentDiscount(e.target.value)}
                            >
                              <Radio.Button value={true} className="percent-option custom-flex-cell">
                                {pageData.form.percent}
                              </Radio.Button>
                              <Radio.Button value={false} className="currency-option custom-flex-cell">
                                {getCurrency()}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        }
                        onKeyPress={(event) => {
                          const checkStatus = checkOnKeyPressValidation(event, "discountPercent", 1, 100, 0);
                          if (!checkStatus) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name={["discountCode", "maximumDiscountAmount"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseEnterMaxDiscount} />,
                        },
                        () => ({
                          validator(_, value) {
                            if (value && (value <= 0 || value >= 1e9)) {
                              return Promise.reject(
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.form.pleaseEnterMaxDiscount}
                                />,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        id="discountAmount"
                        className="w-100 fnb-input-number discount-amount"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                        addonAfter={
                          <Form.Item name={["discountCode", "isPercentDiscount"]} style={{ display: "contents" }}>
                            <Radio.Group
                              className="radio-group-discount"
                              defaultValue={isPercentDiscount}
                              onChange={(e) => setIsPercentDiscount(e.target.value)}
                            >
                              <Radio.Button value={true} className="percent-option">
                                {pageData.form.percent}
                              </Radio.Button>
                              <Radio.Button value={false} className="currency-option">
                                {getCurrency()}
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        }
                        onKeyPress={(event) => {
                          const checkStatus = checkOnKeyPressValidation(
                            event,
                            "discountAmount",
                            0,
                            null,
                            getCurrency() === currency.vnd ? 0 : 2,
                          );
                          if (!checkStatus) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  )}
                </Input.Group>
              </Col>
              {isPercentDiscount && (
                <Col xs={24} lg={12}>
                  <h4 className="fnb-form-label">{pageData.form.maxDiscount}</h4>
                  <Form.Item
                    name={["discountCode", "maximumDiscountAmount"]}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value && (value <= 0 || value >= 1e9)) {
                            return Promise.reject(
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.form.pleaseEnterMaxDiscount}
                              />,
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    className="w-100"
                  >
                    <InputNumber
                      id="maximumDiscountAmount"
                      addonAfter={getCurrency()}
                      className="fnb-input-number w-100"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      precision={getCurrency() === currency.vnd ? 0 : 2}
                      onKeyPress={(event) => {
                        const checkStatus = checkOnKeyPressValidation(
                          event,
                          "maximumDiscountAmount",
                          0,
                          null,
                          getCurrency() === currency.vnd ? 0 : 2,
                        );
                        if (!checkStatus) event.preventDefault();
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[32, 16]}>
              <Col xs={24} lg={12}>
                <Row>
                  <h4 className="fnb-form-label">
                    {pageData.form.startDate}
                    <span className="text-danger">*</span>
                  </h4>
                </Row>
                <Row className="w-100">
                  <Col xs={14} lg={14} xl={16}>
                    <Form.Item
                      name={["discountCode", "startDate"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseStartDate} />,
                        },
                      ]}
                    >
                      <DatePicker
                        suffixIcon={<CalendarNewIconBold />}
                        placeholder={pageData.selectDate}
                        className="fnb-date-picker w-100"
                        disabledDate={disabledDate}
                        format={DateFormat.DD_MM_YYYY}
                        onChange={(date) => {
                          setStartDate(date);

                          // Clear end date after select start date if endate < startdate only
                          const formValues = form.getFieldsValue();
                          if (formValues.discountCode?.endDate != null && formValues.discountCode?.endDate < date) {
                            form.setFieldsValue({
                              ...formValues,
                              discountCode: {
                                endDate: null,
                                endTime: null,
                              },
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={10} lg={10} xl={8} className="padding-left">
                    <Form.Item
                      name={["discountCode", "startTime"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseStartTime} />,
                        },
                      ]}
                    >
                      <TimePicker
                        disabled={startDate ? false : true}
                        className="fnb-date-picker fnb-date-picker-discount-code fnb-time-picker w-100"
                        popupClassName="fnb-date-time-picker-dropdown"
                        format={"HH:mm"}
                        placeholder={pageData.selectTime}
                        onSelect={(time) => {
                          setStartTime(moment(time, "HH:mm"));
                          // Clear end date after select start date if endate < startdate only
                          const formValues = form.getFieldsValue();
                          let isSameDay =
                            // eslint-disable-next-line eqeqeq
                            moment(formValues.discountCode?.startDate.toDate()).format("DD/MM/YYYY") ==
                            moment(formValues.discountCode?.endDate.toDate()).format("DD/MM/YYYY");
                          if (
                            formValues.discountCode?.endDate != null &&
                            formValues.discountCode?.endTime < time &&
                            isSameDay
                          ) {
                            form.setFieldsValue({
                              ...formValues,
                              discountCode: {
                                endTime: null,
                              },
                            });
                          }
                        }}
                        showNow={false}
                        showConfirm={false}
                        disabledHours={getDisabledHoursStartTime}
                        disabledMinutes={getDisabledMinutesStartTime}
                        suffixIcon={<ArrowDownDropdown />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Row>
                  <h4 className="fnb-form-label">{pageData.form.endDate}<span className="text-danger">*</span></h4>
                </Row>
                <Row className="w-100">
                  <Col xs={14} lg={14} xl={16}>
                    <Form.Item
                      name={["discountCode", "endDate"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseEndDate} />,
                        },
                      ]}
                    >
                      <DatePicker
                        suffixIcon={<CalendarNewIconBold />}
                        placeholder={pageData.selectDate}
                        className="fnb-date-picker w-100"
                        disabledDate={disabledDateByStartDate}
                        format={DateFormat.DD_MM_YYYY}
                        disabled={startDate ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={10} lg={10} xl={8} className="padding-left">
                    <Form.Item
                      name={["discountCode", "endTime"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseEndTime} />,
                        },
                      ]}
                    >
                      <TimePicker
                        disabled={startDate ? false : true}
                        className="fnb-date-picker fnb-date-picker-discount-code fnb-time-picker w-100"
                        popupClassName="fnb-date-time-picker-dropdown"
                        format={"HH:mm"}
                        placeholder={pageData.selectTime}
                        onSelect={(time) => {
                          setEndTime(moment(time, "HH:mm"));
                        }}
                        showNow={false}
                        showConfirm={false}
                        disabledHours={getDisabledHoursEndTime}
                        disabledMinutes={getDisabledMinutesEndTime}
                        suffixIcon={<ArrowDownDropdown />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <h4 className="fnb-form-label">{pageData.form.termsAndConditions}</h4>
              <Form.Item name={["discountCode", "termsAndCondition"]} className="w-100">
                <FnbTextArea showCount maxLength={pageData.form.maxLengthTermsAndConditions} rows={4}></FnbTextArea>
              </Form.Item>
            </Row>
          </Card>
        </Row>
        <Row className="mt-3">
          <Card className="fnb-card w-100">
            <Row style={{ marginBottom: "32px" }}>
              <FnbTypography
                text={pageData.form.couponConditions}
                variant={theme.typography["h1-bold"]}
                color="#2B2162"
              />
            </Row>
            {discountCodeTypeId === PromotionType.DiscountTotal && (
              <>
                <Row className="mb-2">
                  <FnbCheckBox
                    valuePropName="checked"
                    onChange={(e) => {
                      setIsMinimumPurchaseAmount(e.target.checked);
                    }}
                  >
                    <FnbTypography
                      variant={theme.typography["b1-regular"]}
                      text={pageData.form.minimumPurchaseAmountOnBill}
                    />
                  </FnbCheckBox>
                  <FnbTooltip placement="topLeft" title={pageData.form.minimumAmounttoApplyDiscountCode}>
                    <InfoCircleFlashSaleIcon size={24} />
                  </FnbTooltip>
                </Row>
                {isMinimumPurchaseAmount && (
                  <Row>
                    <Form.Item
                      name={["discountCode", "minimumPurchaseAmount"]}
                      rules={[
                        {
                          required: true,
                          message: <InputValidateMessage message={pageData.form.pleaseInputValue} />,
                        },
                        () => ({
                          validator(_, value) {
                            if (value && (value <= 0 || value >= 1e9)) {
                              return Promise.reject(
                                <InputValidateMessage
                                  type={EnumInputValidateType.ERROR}
                                  message={pageData.form.pleaseEnterMaxDiscount}
                                />,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      className="w-100"
                    >
                      <InputNumber
                        addonAfter={getCurrency()}
                        className="w-100 fnb-input-number"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        min={getCurrency() === currency.vnd ? 1 : 0}
                        precision={getCurrency() === currency.vnd ? 0 : 2}
                        id="discountCode-minimumPurchaseAmount"
                        onKeyPress={(event) => {
                          const checkValidKey = checkOnKeyPressValidation(
                            event,
                            "discountCode-minimumPurchaseAmount",
                            0,
                            null,
                            getCurrency() === currency.vnd ? 0 : 2,
                          );
                          if (!checkValidKey) event.preventDefault();
                        }}
                      />
                    </Form.Item>
                  </Row>
                )}
              </>
            )}
            <h3 className="fnb-form-label mt-16">
              <FnbCheckBox
                defaultChecked={isAllBranches}
                onChange={(event) => onSelectAllBranches(event.target.checked, listbranches)}
              >
                <FnbTypography variant={theme.typography["b1-regular"]} text={pageData.form.allBranches} />
              </FnbCheckBox>
            </h3>
            <Row>
              <Col span={24} hidden={isAllBranches}>
                <Form.Item
                  name={["discountCode", "branchIds"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.pleaseSelectBranch} />,
                    },
                  ]}
                  className="w-100"
                >
                  <FnbSelect
                    mode="multiple"
                    onChange={(values) => onSelectBranches(values)}
                    placeholder={pageData.form.selectBranches}
                    className="w-100"
                    allowClear
                    options={listbranches?.map((item) => ({
                      value: item?.id,
                      label: t(item?.name),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24} hidden={!isAllBranches}>
                <Form.Item name="tmpBranchIds">
                  <FnbSelect mode="multiple" disabled={true} />
                </Form.Item>
              </Col>
            </Row>

            <FnbCheckBox
              defaultChecked={isAllCustomers}
              onChange={(event) => onSelectAllCustomer(event.target.checked)}
            >
              <FnbTypography variant={theme.typography["b1-regular"]} text={pageData.form.allCustomers} />
            </FnbCheckBox>
            <div className="customer-segment-wrapper">
              {!isAllCustomers ? (
                <Form.Item
                  className="select-control mt-2"
                  name={["discountCode", "listCustomerSegmentId"]}
                  rules={[
                    {
                      required: true,
                      message: <InputValidateMessage message={pageData.form.customerGroupRequiredMessage} />,
                    },
                  ]}
                >
                  <FnbSelect
                    mode="multiple"
                    onChange={(e) => onUpdateCustomerSegment(e)}
                    popupClassName="fnb-select-multiple-dropdown"
                    placeholder={pageData.form.customerGroupPlaceholder}
                    showSearch
                    id="fnb-select-multiple-customer-segment"
                    disabled={isAllCustomers}
                    options={customerSegmentInSore?.map((option) => ({ label: option?.name, value: option?.id }))}
                  />
                </Form.Item>
              ) : (
                <Form.Item className="select-control mt-2">
                  <FnbSelect
                    mode="multiple"
                    popupClassName="fnb-select-multiple-dropdown"
                    placeholder={pageData.form.customerGroupPlaceholder}
                    showSearch
                    id="fnb-select-multiple-customer-segment"
                    disabled={isAllCustomers}
                  />
                </Form.Item>
              )}
              {showCountCustomerSegment && (
                <div
                  className="selected-customer-segment-custom selected-customer-segment-position"
                  dangerouslySetInnerHTML={{
                    __html: `${t("discountCode.formCreate.customerSegmentSelected", {
                      totalSegment: totalSegment,
                      totalCustomer: totalCustomer,
                      totalEmail: totalEmail,
                    })}`,
                  }}
                ></div>
              )}
            </div>
            {/* <h4 className="fnb-form-label mb-2 mt-2">{pageData.form.platform}</h4> */}
            <FnbTypography variant={theme.typography["b1-regular"]} text={pageData.form.platform} />
            <Form.Item
              name={["discountCode", "platformIds"]}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage message={pageData.form.pleaseSelectPlatform} />,
                },
              ]}
            >
              <FnbCheckBox.Group onChange={onChangePlatform} className="checkbox-group">
                {defaultSystemPlatforms?.map((p, index) => {
                  return (
                    <div key={`checkbox_create_platform_${p.id}_${index}`} className="checkbox-platform">
                      <FnbCheckBox value={p.id}>
                        <FnbTypography text={p.name} variant={theme.typography["b1-regular"]} />
                      </FnbCheckBox>
                    </div>
                  );
                })}
              </FnbCheckBox.Group>
            </Form.Item>
            {serviceTypes?.length > 0 && (
              <>
                <FnbTypography variant={theme.typography["b1-regular"]} text={pageData.form.serviceType} />
                <Form.Item name={["discountCode", "serviceTypeIds"]}>
                  <Checkbox.Group onChange={onChangeServiceType} className="checkbox-group">
                    {serviceTypes?.map((item, index) => {
                      return (
                        <div key={`checkbox_create_serviceType_${item?.key}_${index}`} className="checkbox-platform">
                          <FnbCheckBox value={item?.key} checked={item?.selected}>
                            <FnbTypography variant={theme.typography["b1-regular"]} text={t(item?.name)} />
                          </FnbCheckBox>
                        </div>
                      );
                    })}
                  </Checkbox.Group>
                </Form.Item>
              </>
            )}
            <Row>
              <Form.Item name={["discountCode", "isIncludedTopping"]} valuePropName="checked">
                <FnbCheckBox onChange={(e) => setIsIncludedTopping(e.target.checked)}>
                  <FnbTypography variant={theme.typography["b1-regular"]} text={pageData.form.includedTopping} />
                </FnbCheckBox>
              </Form.Item>
            </Row>
          </Card>
        </Row>
        <Row className="mt-3">
          <Card className="fnb-card w-100">
            <Row style={{ marginBottom: "32px" }}>
              <FnbTypography variant={theme.typography["h1-bold"]} text={pageData.form.code} color="#2B2162" />
            </Row>
            <Row className="mb-1">
              <FnbTypography variant={theme.typography["b1-regular"]} text={pageData.form.usageLimits} />
            </Row>
            <Row className="mb-1">
              <FnbCheckBox
                valuePropName="checked"
                noStyle
                onChange={(e) => onChangeLimitNumberCouponUse(e.target.checked)}
              >
                <FnbTypography
                  variant={theme.typography["b1-regular"]}
                  text={pageData.form.limitNumberOfTimesThisCouponCanBeUsed}
                />
              </FnbCheckBox>
            </Row>
            {isLimitNumberCouponUse && (
              <Row className="w-100">
                <Col xs={12} lg={8}>
                  <Form.Item
                    name={["discountCode", "maximumLimitCouponUse"]}
                    rules={[
                      { required: true, message: <InputValidateMessage message={pageData.form.pleaseInputValue} /> },
                      {
                        min: 1,
                        max: 1000,
                        type: "integer",
                        message: <InputValidateMessage message={pageData.form.pleaseInputValue} />,
                      },
                    ]}
                  >
                    <InputNumber
                      id="maximumLimitCouponUse"
                      className="fnb-input-number w-100 discount-amount"
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      min={1}
                      max={1000}
                      defaultValue={1}
                      onKeyPress={(event) => {
                        const checkStatus = checkOnKeyPressValidation(event, "maximumLimitCouponUse", 1, 1000, 0);
                        if (!checkStatus) event.preventDefault();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Form.Item name={["discountCode", "isLimitOneTimeUse"]}>
                <FnbCheckBox defaultChecked={true} onChange={(e) => setIsLimitOneTimeUse(e.target.checked)}>
                  <FnbTypography
                    text={pageData.form.limitOneUsedPerCustomer}
                    variant={theme.typography["b1-regular"]}
                  />
                </FnbCheckBox>
              </Form.Item>
            </Row>
            <Row>
              <Col span={24}>
                <Row style={{ marginBottom: "4px" }}>
                  <FnbTypography
                    variant={theme.typography["b1-regular"]}
                    text={
                      <>
                        {pageData.form.discountCode} <span className="text-danger">*</span>{" "}
                      </>
                    }
                  />
                </Row>
                <Row className="w-100" gutter={[12, 0]}>
                  <Col xs={24} md={10} lg={8}>
                    <Form.Item
                      name={["discountCode", "code"]}
                      rules={[
                        {
                          required: true,
                          type: "string",
                          min: 6,
                          max: 20,
                          message: <InputValidateMessage message={pageData.form.pleaseEnterDiscountCodeOrGenerate} />,
                        },
                      ]}
                      validateTrigger={onChangeGenerateCode ? "onBlur" : false}
                    >
                      <Input
                        className="fnb-input-with-count code"
                        showCount
                        minLength={6}
                        maxLength={20}
                        onKeyPress={(event) => {
                          if (/\s/g.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder={pageData.form.enterDiscountCode}
                        onChange={() => setOnnChangeGenerateCode(true)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={10} lg={16}>
                    <FnbButton
                      text={pageData.form.generateCodes}
                      iconHeader={<PromoIcon className="icon-btn-promo" />}
                      onClick={onGenerateDiscountCode}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onCancel}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
