import { Col, Form, Radio, Row, Space, message } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { tableSettings } from "constants/default.constants";
import { DepositStatus } from "constants/deposit-status.contants";
import { ArrivalTimeCalendarOutline, CloseCircleRedIcon, TickCircleIcon } from "constants/icons.constants";
import { paymentMethod } from "constants/payment-method.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { ReservationStatus } from "constants/reservation-constant";
import { DateFormat } from "constants/string.constants";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import reserveTableDataService from "data-services/reserveTable/reserve-table-data.service";
import { useRedirect } from "hooks/useRedirect";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getReserveTables,
  reserveTableActions,
  reserveTableSelector,
} from "store/modules/reservation/reservation.reducers";
import { enumReserveTable } from "themes/theme-2-new/constants/enums";
import { formatDate, formatNumberDecimalOrInteger, formatPhoneNumber, formatTextNumber, getCurrency, hasPermission } from "utils/helpers";
import { PopoverFilter } from "./components";
import {
  ArrivalTime,
  Branch,
  Container,
  DepositStatusStyled,
  Guest,
  Phone,
  Status,
  Text,
  WrapperTable,
} from "./styled";
import "./styles.scss";
const DEFAULT_PAGE_SIZE = 20;
const KEY_PARAMS = ["branchIds", "areaIds", "areaTableIds", "statusIds", "arrivalFromDate", "arrivalToDate"];

const ReservationManagement = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { reserveTableParams, reserveTables, reserveTableCurrentPage, reserveTableTotal } =
    useSelector(reserveTableSelector);
  const [totalFilterSelected, setTotalFilterSelected] = useState(0);
  const popoverRef = useRef();
  const timeOutSearch = useRef(null);
  const timeOutFilter = useRef(null);
  const [titleDialogConfirm, setTitleDialogConfirm] = useState("");
  const [actionDialogConfirm, setActionDialogConfirm] = useState("");
  const [reservationId, setReservationId] = useState();
  const [statusReservation, setStatusReservation] = useState();
  const [isDialogConfirm, setIsDialogConfirm] = useState(false);
  const [depositStatusId, setDepositStatusId] = useState(null);
  const [form] = Form.useForm();
  const [depositAmount, setDepositAmount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [activePaymentMethods, setActivePaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});
  const [isCheckedRefundMethod, setIsCheckedRefundMethod] = useState(true);
  const { handleRedirect } = useRedirect();
  const [textConfirm, setTextConfirm] = useState("");
  const [status, setStatus] = useState(ReservationStatus.Cancelled);
  const [reservationStatus, setReservationStatus] = useState(ReservationStatus.Cancelled);
  const [isReservationDialogRefund, setIsReservationDialogRefund] = useState(true);
  const [statusReservationOld, setStatusReservationOld] = useState(null);

  const pageData = {
    mangementList: t("reservation.mangementList"),
    placeholderSearch: t("reservation.placeholderSearch"),
    name: t("reservation.name"),
    phone: t("reservation.phone"),
    branch: t("reservation.branch"),
    guests: t("reservation.guests"),
    arrivalTime: t("reservation.arrivalTime"),
    status: t("reservation.status"),
    action: t("reservation.action"),
    waitToConfirm: t("reservation.waitToConfirm"),
    serving: t("reservation.serving"),
    completed: t("reservation.completed"),
    cancelled: t("reservation.cancelled"),
    confirmed: t("reservation.confirmed"),
    filter: t("reservation.filter"),
    deposit: t("reservation.deposit"),
    notReceived: t("reservation.notReceived"),
    received: t("reservation.received"),
    waitToRefund: t("reservation.waitToRefund"),
    refunded: t("reservation.refunded"),
    noRefund: t("reservation.noRefund"),
    reject: t("reservation.reject"),
    confirm: t("reservation.confirm"),
    update: t("reservation.update"),
    cancel: t("reservation.cancel"),
    confirmReservation: t("reservation.confirmReservation"),
    cancelReservation: t("reservation.cancelReservation"),
    rejectReservation: t("reservation.rejectReservation"),
    confirmReceived: t("reservation.confirmReceived"),
    doYouReallyWantTo: t("reservation.doYouReallyWantTo"),
    discard: t("reservation.discard"),
    confirmSuccess: t("reservation.confirmSuccess"),
    cancelSuccess: t("reservation.cancelSuccess"),
    rejectSuccess: t("reservation.rejectSuccess"),
    hasNotYetReceivedDeposit: t("reservation.hasNotYetReceivedDeposit"),
    reason: t("reservation.reason"),
    validateReason: t("reservation.validateReason"),
    placeholderReason: t("reservation.placeholderReason"),
    rejectDepositReservation: {
      refundNow: t("reservation.rejectReservationDeposit.refundNow"),
      refundLater: t("reservation.rejectReservationDeposit.refundLater"),
      noRefundReservation: t("reservation.rejectReservationDeposit.noRefundReservation"),
      rejectReservationContent: t("reservation.rejectReservationDeposit.rejectReservationContent"),
    },
    cancelDepositReservation: {
      refundNow: t("reservation.cancelReservationDeposit.refundNow"),
      refundLater: t("reservation.cancelReservationDeposit.refundLater"),
      noRefundReservation: t("reservation.cancelReservationDeposit.noRefundReservation"),
      cancelReservationContent: t("reservation.cancelReservationDeposit.cancelReservationContent"),
    },
    cash: t("payment.cash"),
    bankTransfer: t("payment.bankTransfer"),
    rejectReservationContent: t("reservation.rejectReservationContent"),
    depositMethod: t("reservation.depositMethod"),
    depositAmount: t("reservation.depositAmount"),
    ignore: t("reserve.ignore"),
    pleaseSelectRefundMethod: t("reservation.pleaseSelectRefundMethod"),
  };

  const dataPaymentMethod = [
    {
      id: paymentMethod.Cash,
      name: pageData.cash,
    },
    {
      id: paymentMethod.BankTransfer,
      name: pageData.bankTransfer,
    },
  ];

  const filterPaymentMethod = dataPaymentMethod.filter((dataPaymentMethod) =>
    activePaymentMethods.some(
      (activePaymentMethod) => activePaymentMethod && Object.values(activePaymentMethod).includes(dataPaymentMethod.id),
    ),
  );

  useEffect(() => {
    paymentConfigDataService.getAllStorePaymentConfigAsync().then((resData) => {
      if (resData) {
        if (resData.paymentMethods?.length > 0) {
          const activePaymentMethods = dataPaymentMethod.filter((dataPaymentMethod) =>
            resData.paymentMethods.find(
              (paymentMethod) =>
                paymentMethod.enumId === dataPaymentMethod.id && paymentMethod.paymentConfigs?.[0]?.isActivated,
            ),
          );
          setActivePaymentMethods(activePaymentMethods);
          setDefaultPaymentMethod(activePaymentMethods?.[0]);
        }
      }
    });

    return () => {
      dispatch(reserveTableActions.resetReserveTableParams());
    };
  }, []);

  useEffect(() => {
    dispatch(getReserveTables(reserveTableParams));
  }, [reserveTableParams]);

  const mappingReservationStatus = (statusId) => {
    switch (statusId) {
      case ReservationStatus.WaitToConfirm:
        return pageData.waitToConfirm;
      case ReservationStatus.Serving:
        return pageData.serving;
      case ReservationStatus.Completed:
        return pageData.completed;
      case ReservationStatus.Cancelled:
        return pageData.cancelled;
      case ReservationStatus.Confirmed:
        return pageData.confirmed;
      default:
        return "";
    }
  };

  const mappingDepositStatus = (depositStatusId) => {
    switch (depositStatusId) {
      case DepositStatus.NotReceived:
        return pageData.notReceived;
      case DepositStatus.Received:
        return pageData.received;
      case DepositStatus.WaitToRefund:
        return pageData.waitToRefund;
      case DepositStatus.Refunded:
        return pageData.refunded;
      case DepositStatus.NoRefund:
        return pageData.noRefund;
      default:
        return "-";
    }
  };

  const onChangePage = (pageNumber, pageSize) => {
    dispatch(
      reserveTableActions.setReserveTableParams({
        ...reserveTableParams,
        pageNumber,
        pageSize,
      }),
    );
  };

  const onSearch = (keysearch) => {
    if (timeOutSearch.current) {
      clearTimeout(timeOutSearch.current);
    }
    timeOutSearch.current = setTimeout(() => {
      handleSearch(keysearch);
    }, 300);
    setSelectedRowKeys([]);
  };

  const handleSearch = (keySearch) => {
    dispatch(
      reserveTableActions.setReserveTableParams({
        ...reserveTableParams,
        pageNumber: tableSettings.page,
        keySearch,
      }),
    );
  };

  const onSelectedDatePicker = ({ startDate, endDate }, businessSummaryWidgetFilter) => {
    dispatch(
      reserveTableActions.setReserveTableParams({
        ...reserveTableParams,
        pageNumber: tableSettings.page,
        startDate,
        endDate,
        businessSummaryWidgetFilter,
      }),
    );
    setSelectedRowKeys([]);
  };

  const handleOnChange = (newParams) => {
    const totalSelected = Object.keys(newParams)?.filter(
      (key) => KEY_PARAMS?.includes(key) && (newParams[key]?.length > 0 || newParams[key]),
    )?.length;
    setTotalFilterSelected(totalSelected || 0);

    if (timeOutFilter.current) {
      clearTimeout(timeOutFilter.current);
    }
    timeOutFilter.current = setTimeout(() => {
      dispatch(reserveTableActions.setReserveTableParams(newParams));
    }, 500);
    setSelectedRowKeys([]);
  };

  const onClickFilterButton = () => {
    setSelectedRowKeys([]);
  };

  const onClearFilter = () => {
    popoverRef.current.resetFilter();
    setSelectedRowKeys([]);
  };

  const getAmountCurrency = (amount) => {
    return formatNumberDecimalOrInteger(amount) + " " + getCurrency();
  };

  const onConfirmItem = (reservationId, depositStatusId, depositAmount, reservationStatus) => {
    setDepositAmount(depositAmount);
    setTitleDialogConfirm(pageData.confirmReservation);
    if (!depositStatusId) {
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.confirm }));
      setIsReservationDialogRefund(false);
    } else {
      setDepositStatusId(depositStatusId);
      if (depositStatusId === DepositStatus.NotReceived) {
        setIsReservationDialogRefund(true);
        setTextConfirm(pageData.hasNotYetReceivedDeposit);
        setStatus(ReservationStatus.Confirmed);
        setReservationStatus(reservationStatus);
        renderContent(pageData.hasNotYetReceivedDeposit);
        setTitleDialogConfirm(pageData.confirmReservation);
      } else {
        renderContent(t("reservation.doYouReallyWantTo", { action: pageData.confirm }));
        setIsReservationDialogRefund(false);
      }
    }

    setReservationId(reservationId);
    setStatusReservation(ReservationStatus.Confirmed);
    setIsDialogConfirm(true);
  };

  const onRejectItem = (reservationId, statusId, depositStatusId, depositAmount) => {
    setDepositAmount(depositAmount);
    if (!depositStatusId) {
      onRejectDefault(statusId);
      setIsReservationDialogRefund(false);
    } else {
      setIsReservationDialogRefund(true);
      if (depositStatusId === DepositStatus.Received) {
        setStatus(ReservationStatus.Cancelled);
        setReservationStatus(statusId);
        if (statusId === ReservationStatus.Confirmed) {
          setTextConfirm(
            t("reservation.cancelReservationDeposit.cancelReservationContent", {
              amount: getAmountCurrency(depositAmount),
              action: pageData.cancel,
            }),
          );
          setTitleDialogConfirm(pageData.cancelReservation);
        } else {
          setTextConfirm(
            t("reservation.rejectReservationDeposit.rejectReservationContent", {
              amount: getAmountCurrency(depositAmount),
              action: pageData.reject,
            }),
          );
          setTitleDialogConfirm(pageData.rejectReservation);
        }
      } else {
        setIsReservationDialogRefund(false);
        onRejectDefault(statusId);
      }
    }
    setStatusReservationOld(statusId);
    setReservationId(reservationId);
    setStatusReservation(ReservationStatus.Cancelled);
    setIsDialogConfirm(true);
  };

  const onRejectDefault = (statusId) => {
    setIsReservationDialogRefund(false);
    if (statusId === ReservationStatus.Confirmed) {
      setTitleDialogConfirm(pageData.cancelReservation);
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.cancel }));
    } else {
      setTitleDialogConfirm(pageData.rejectReservation);
      renderContent(t("reservation.doYouReallyWantTo", { action: pageData.reject }));
    }
  };

  const handleHiddenDialog = () => {
    setIsDialogConfirm(false);
  };

  const updateReservationStatus = async (reservationId, status) => {
    form
      .validateFields()
      .then(async (values) => {
        let formValue = values;
        let refundPaymentMethodId = null;
        let paymentMethodId = null;
        let reason = formValue.reason || null;
        let depositStatus = formValue.depositStatus || depositStatusId;
        if (status === enumReserveTable.Confirmed) {
          paymentMethodId = formValue.depositMethod;
          refundPaymentMethodId = null;
          reason = null;
        } else if (formValue.depositStatus === DepositStatus.Refunded) {
          refundPaymentMethodId = formValue.depositMethod;
          paymentMethodId = null;
        }
        if (reservationId && status !== undefined) {
          const data = {
            id: reservationId,
            statusId: status,
            depositStatusId: depositStatus,
            refundPaymentMethodId: refundPaymentMethodId,
            paymentMethodId: paymentMethodId,
            refundAmount: depositAmount,
            Reason: reason,
          };
          await reserveTableDataService.updateReserveTableStatusAsync(data).then((response) => {
            if (response) {
              dispatch(getReserveTables(reserveTableParams));
              if (statusReservationOld === ReservationStatus.Confirmed) {
                message.success(pageData.cancelSuccess);
              } else if (status === ReservationStatus.Confirmed) {
                message.success(pageData.confirmSuccess);
              } else message.success(pageData.rejectSuccess);
            }
          });
          setIsDialogConfirm(false);
          setReservationId();
          setStatusReservation();
          setSelectedRowKeys([]);
        }
      })
      .catch((errorInfo) => {});
  };

  const renderContent = (textConfirm) => {
    setActionDialogConfirm(textConfirm);
  };

  const handleFieldsChange = () => {
    const isFormValid = () =>
      form.getFieldsError().some((item) => {
        return item.errors.length > 0;
      });
    if (!isFormValid()) {
      document.getElementById("btn-submit").classList.remove("fnb-button--disabled--fulfill");
      document.getElementById("btn-submit").removeAttribute("disabled");
    } else {
      document.getElementById("btn-submit").classList.add("fnb-button--disabled--fulfill");
      document.getElementById("btn-submit").setAttribute("disabled", "");
    }
  };

  const handleChangeRadioGroup = ({ target }) => {
    if (target) {
      if (target.value !== DepositStatus.Refunded) {
        setIsCheckedRefundMethod(false);
      } else {
        setIsCheckedRefundMethod(true);
      }
      form.validateFields();
    }
  };

  const renderContentElement = () => {
    return status === ReservationStatus.Cancelled ? (
      <div key={Date()} className="confirm-reservation-page">
        <Form form={form} onFieldsChange={handleFieldsChange}>
          <Row gutter={[28, 32]}>
            <Col span={24}>
              <div className="text-confirm">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${textConfirm}`,
                  }}
                ></span>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                className="form-confirm-reservation"
                name={"depositStatus"}
                initialValue={DepositStatus.Refunded}
              >
                <Radio.Group
                  defaultValue={DepositStatus.Refunded}
                  value={DepositStatus.Refunded}
                  className="list-reservation-settings"
                  onChange={handleChangeRadioGroup}
                >
                  <Radio value={DepositStatus.Refunded}>
                    <span
                      className="label-radio-reservation"
                      dangerouslySetInnerHTML={{
                        __html: `${
                          reservationStatus === ReservationStatus.WaitToConfirm
                            ? pageData.rejectDepositReservation.refundNow
                            : pageData.cancelDepositReservation.refundNow
                        }`,
                      }}
                    ></span>
                  </Radio>
                  <Space direction="vertical" size={12} className="w-100" style={{ paddingLeft: "32px" }}>
                    <Space className="w-100" direction="vertical" size={8}>
                      <Form.Item
                        name={"depositMethod"}
                        initialValue={defaultPaymentMethod?.id}
                        className="form-confirm-reservation"
                        rules={[
                          {
                            required: isCheckedRefundMethod,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.pleaseSelectRefundMethod}
                              />
                            ),
                          },
                        ]}
                      >
                        <FnbSelectSingle
                          disabled={!isCheckedRefundMethod}
                          className="deposit-paymentmethod"
                          option={filterPaymentMethod?.map((item, index) => ({
                            id: item.id,
                            name: item.name,
                            key: index,
                          }))}
                        />
                      </Form.Item>
                    </Space>
                  </Space>
                  <Radio value={DepositStatus.WaitToRefund}>
                    <div className="reservation-coming-soon">
                      <span
                        className="label-radio-reservation"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            reservationStatus === ReservationStatus.WaitToConfirm
                              ? pageData.rejectDepositReservation.refundLater
                              : pageData.cancelDepositReservation.refundLater
                          }`,
                        }}
                      ></span>
                    </div>
                  </Radio>
                  <Radio value={DepositStatus.NoRefund}>
                    <div className="reservation-coming-soon">
                      <span
                        className="label-radio-reservation"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            reservationStatus === ReservationStatus.WaitToConfirm
                              ? pageData.rejectDepositReservation.noRefundReservation
                              : pageData.cancelDepositReservation.noRefundReservation
                          }`,
                        }}
                      ></span>
                    </div>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <h3 className="fnb-form-label">
                {pageData.reason} <span className="required-field">*</span>
              </h3>
              <Form.Item
                name={"reason"}
                className="form-confirm-reservation"
                initialValue={""}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validateReason} />
                    ),
                  },
                ]}
              >
                <FnbTextArea rows={4} maxLength={255} placeholder={pageData.placeholderReason}></FnbTextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    ) : (
      <div className="confirm-reservation-page">
        <Form form={form} onFieldsChange={handleFieldsChange}>
          <Row gutter={[28, 32]}>
            <Col span={24}>
              <div className="text-confirm">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${textConfirm}`,
                  }}
                ></span>
              </div>
            </Col>
            <Col lg={12} span={24}>
              <h3 className="fnb-form-label">{pageData.depositMethod}</h3>
              <Form.Item
                name={"depositMethod"}
                initialValue={defaultPaymentMethod?.id}
                className="form-confirm-reservation"
                rules={[
                  {
                    required: isCheckedRefundMethod,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.pleaseSelectRefundMethod}
                      />
                    ),
                  },
                ]}
              >
                <FnbSelectSingle
                  className="deposit-paymentmethod"
                  option={filterPaymentMethod?.map((item, index) => ({
                    id: item.id,
                    name: item.name,
                    key: index,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col lg={12} span={24}>
              <h3 className="fnb-form-label">{pageData.depositAmount}</h3>
              <p className="text-detail">
                {formatTextNumber(depositAmount) ?? "-"} <span className="text-currency">{getCurrency()}</span>
              </p>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  const tableConfigs = {
    pageSize: DEFAULT_PAGE_SIZE,
    columns: [
      {
        title: "ID",
        dataIndex: "stringCode",
        width: "7rem",
        align: "center",
        sorter: ({ stringCode: preStringCode }, { stringCode: currentStringCode }) =>
          preStringCode.localeCompare(currentStringCode),
        render: (value, { id }) => <span>{value}</span>,
      },
      {
        title: pageData.name,
        dataIndex: "customerName",
        width: "20%",
        ellipsis: true,
        render: (value) => (
          <Text>
            <FnbTooltip onlyShowWhenEllipsis={true} title={value}>
              {value}
            </FnbTooltip>
          </Text>
        ),
      },
      {
        title: pageData.phone,
        dataIndex: "customerPhone",
        width: "20%",
        ellipsis: true,
        render: (_, record) => (
          <Phone>
            <FnbTooltip onlyShowWhenEllipsis={true}>
              {formatPhoneNumber(record?.storeBranchPhoneCode, record?.customerPhone)}
            </FnbTooltip>
          </Phone>
        ),
      },
      {
        title: pageData.branch,
        dataIndex: "storeBranchName",
        width: "20%",
        ellipsis: true,
        render: (value) => (
          <Branch>
            <FnbTooltip onlyShowWhenEllipsis={true}>{value}</FnbTooltip>
          </Branch>
        ),
      },
      {
        title: pageData.guests,
        dataIndex: "numberOfSeats",
        width: "7rem",
        sorter: (pre, current) => pre?.numberOfSeats - current?.numberOfSeats,
        render: (value) => <Guest>{value}</Guest>,
      },
      {
        title: pageData.arrivalTime,
        dataIndex: "arrivalTime",
        width: "13.75rem",
        align: "center",
        sorter: (pre, current) => moment(pre?.arrivalTime).unix() - moment(current?.arrivalTime).unix(),
        render: (value) => (
          <ArrivalTime>
            <span>{formatDate(value, DateFormat.HH_MM)}</span>
            <div>
              <ArrivalTimeCalendarOutline />
              {formatDate(value, DateFormat.DD_MM_YYYY)}
            </div>
          </ArrivalTime>
        ),
        defaultSortOrder: "descend",
      },
      {
        title: pageData.deposit,
        dataIndex: "depositStatusId",
        align: "center",
        width: "11.875rem",
        render: (value) => (
          <DepositStatusStyled DepositStatusId={value}>{mappingDepositStatus(value)}</DepositStatusStyled>
        ),
      },
      {
        title: pageData.status,
        align: "center",
        dataIndex: "status",
        width: "11.875rem",
        render: (value) => <Status statusId={value}>{mappingReservationStatus(value)}</Status>,
      },
    ],
    onSearch,
    onChangePage,
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleOnRowClick = ({ id }) => {
    if (!id) return;
    if (hasPermission(PermissionKeys.VIEW_RESERVATION)) {
      handleRedirect(`/report/reservation-detail/${id}`);
    }
  };

  const handleResetForm = () => {
    form.resetFields();
    setIsCheckedRefundMethod(true);
    const elBtnSubmit = document.getElementById("btn-submit");
    if (elBtnSubmit) {
      elBtnSubmit.classList.remove("fnb-button--disabled--fulfill");
      elBtnSubmit.removeAttribute("disabled");
    }
  };

  return (
    <Container>
      <FnbHeadingPage title={pageData.mangementList} />
      <WrapperTable>
        <FnbTable
          classNameComponent="fnb-table-reservation-report"
          columns={tableConfigs.columns}
          pageSize={tableConfigs.pageSize}
          dataSource={reserveTables || []}
          currentPageNumber={reserveTableCurrentPage || 1}
          total={reserveTableTotal || 0}
          onChangePage={tableConfigs.onChangePage}
          scrollX={1500}
          search={{
            placeholder: pageData.placeholderSearch,
            onChange: tableConfigs.onSearch,
          }}
          calendarComponent={{
            onSelectedDatePicker: onSelectedDatePicker,
            selectedDate: {
              startDate: reserveTableParams?.startDate,
              endDate: reserveTableParams?.endDate,
            },
          }}
          filter={{
            isShowTitle: true,
            buttonTitle: pageData.filter,
            onClickFilterButton: onClickFilterButton,
            onClearFilter: onClearFilter,
            totalFilterSelected: totalFilterSelected,
            component: (
              <PopoverFilter ref={popoverRef} totalFilterSelected={totalFilterSelected} onChange={handleOnChange} />
            ),
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            renderActions: (selectedRowKeys, selectedRows) => (
              <div className="d-flex">
                {selectedRows.length === 1 && selectedRows[0]?.status === ReservationStatus.WaitToConfirm && (
                  <FnbTooltip placement="top" title={pageData.confirmReservation} zIndex={10}>
                    <FnbButton
                      permission={PermissionKeys.EDIT_RESERVATION}
                      variant="text"
                      className="row-confirm-button"
                      text={<TickCircleIcon />}
                      onClick={() =>
                        onConfirmItem(
                          selectedRows[0]?.id,
                          selectedRows[0]?.depositStatusId,
                          selectedRows[0]?.depositAmount,
                          selectedRows[0]?.status,
                        )
                      }
                    />
                  </FnbTooltip>
                )}
                {selectedRows.length === 1 &&
                  (selectedRows[0]?.status === ReservationStatus.WaitToConfirm ||
                    selectedRows[0]?.status === ReservationStatus.Confirmed) && (
                    <FnbTooltip
                      placement="top"
                      title={
                        selectedRows[0]?.status === ReservationStatus.WaitToConfirm
                          ? pageData.rejectReservation
                          : pageData.cancelReservation
                      }
                      zIndex={10}
                    >
                      <FnbButton
                        permission={PermissionKeys.CANCEL_RESERVATION}
                        variant="text"
                        className="row-reject-button"
                        text={<CloseCircleRedIcon />}
                        onClick={() =>
                          onRejectItem(
                            selectedRows[0]?.id,
                            selectedRows[0]?.status,
                            selectedRows[0]?.depositStatusId,
                            selectedRows[0]?.depositAmount,
                          )
                        }
                      />
                    </FnbTooltip>
                  )}
              </div>
            ),
          }}
          onRowClick={handleOnRowClick}
        />
      </WrapperTable>
      <DeleteConfirmComponent
        title={titleDialogConfirm}
        visible={isDialogConfirm}
        skipPermission={true}
        cancelText={pageData.ignore}
        okText={titleDialogConfirm}
        minWidthBtnOk="180px"
        onCancel={handleHiddenDialog}
        onOk={() => updateReservationStatus(reservationId, statusReservation)}
        contentElement={
          isReservationDialogRefund ? (
            renderContentElement()
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: `${actionDialogConfirm}`,
              }}
            ></span>
          )
        }
        width={446}
        idBtnOk={"btn-submit"}
        onAfterClose={handleResetForm}
      />
    </Container>
  );
};

export default ReservationManagement;
