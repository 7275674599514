import BillingPage from "./billing-page";
import PaymentStatus from "./components/payment-status.component";
import TransferPayment from "./transfer-payment";

const route = [
  {
    key: "app.billing",
    position: 0,
    path: "/billing",
    name: "Billing",
    isMenu: false,
    exact: true,
    auth: false,
    permission: "public",
    component: BillingPage,
    child: [],
  },
  {
    key: "app.transferPayment",
    position: 1,
    path: "/transfer-payment",
    name: "transfer payment",
    isMenu: false,
    exact: true,
    auth: false,
    permission: "public",
    component: TransferPayment,
    child: [],
  },
  {
    key: "app.payment.status",
    position: 1,
    path: "/payment-status",
    name: "payment status",
    isMenu: false,
    exact: true,
    auth: false,
    permission: "public",
    component: PaymentStatus,
    child: [],
  },
];
export default route;
