import http from "utils/http-common";
import qs from 'query-string'

const commonAPI = {
  getAllBranchManagement: '/branch/get-all-branch-management',
  getAreasAreaTablesByBranchId: '/area/branch',
  getAllStaff: '/staff',
  getAllGroupPermissions: '/permission/get-group-permissions-management',
  getCountryCodes: '/address/get-countries',
};

const getAllBranchManagementAsync = () => {
  return http.get(commonAPI.getAllBranchManagement);
};

const getAreasAreaTablesByBranchIdAsync = (params) => {
  return http.get(commonAPI.getAreasAreaTablesByBranchId, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

const getAllStaffAsync = () => {
  return http.get(commonAPI.getAllStaff);
};

const getAllGroupPermissionsAsync = () => {
  return http.get(commonAPI.getAllGroupPermissions);
};

const getCountryCodesAsync = () => {
  return http.get(commonAPI.getCountryCodes);
};

const commonDataService = {
  getAllBranchManagementAsync,
  getAreasAreaTablesByBranchIdAsync,
  getAllStaffAsync,
  getAllGroupPermissionsAsync,
  getCountryCodesAsync
};

export default commonDataService;
