import { Radio } from "antd";
import { Container } from "./styled";
import { CheckOutlined } from "constants/icons.constants";
import { useTranslation } from "react-i18next";
import FnbRadioButton from "components/fnb-radio-button";

const DEFAULT_CHECK_ALL = "";

const RadioButtonGroup = (props) => {
  const { options, onChange, value } = props;

  const [t] = useTranslation();

  const pageData = {
    all: t("combo.all"),
  };

  const handleOnChange = (event) => {
    onChange && onChange(event.target.value);
  };

  return (
    <Container>
      <Radio.Group defaultValue={DEFAULT_CHECK_ALL} onChange={handleOnChange} value={value}>
        <FnbRadioButton value={DEFAULT_CHECK_ALL}>{pageData.all}</FnbRadioButton>
        {options?.map((option) => (
          <FnbRadioButton key={option?.value} value={option?.value}>
            {option?.label}
          </FnbRadioButton>
        ))}
      </Radio.Group>
    </Container>
  );
};

export default RadioButtonGroup;
