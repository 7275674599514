export const blank = "_blank";

export const blankWindowDimensions = "height=720,width=620";

export const paymentVisaStatus = {
  successPayment: 0,
};

export const redirectPaymentStatus = "payment-status";

export const creditDedbitCard = "Credit / Debit Card";

export const visa = "Visa/Master/JCB";

export const pathOrderDetail = "my-profile/2";
