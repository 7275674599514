import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PermissionKeys } from "constants/permission-key.constants";
import { TableOption } from "./components/table-option.component";
import CreateNewOptionManagement from "./components/create-new-option-management.component";
import EditOptionManagement from "./components/edit-option-management.component";
import optionDataService from "data-services/option/option-data.service";
import "./option.page.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { hasPermission } from "utils/helpers";

export default function OptionManagement(props) {
  const [t] = useTranslation();
  const optionTableComponentRef = React.useRef();
  const editComponentRef = React.useRef();
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);
  const [showEditOptionForm, setShowEditOptionForm] = useState(false);

  const pageData = {
    optionManagement: t("option.optionManagement"),
    addNew: t("button.addNew"),
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteContent: t("option.confirmDeleteContent"),
    optionDeleteSuccess: t("option.optionDeleteSuccess"),
    optionDeleteFail: t("option.optionDeleteFail"),
    searchName: t("form.searchName"),
    no: t("table.no"),
    name: t("table.name"),
    options: t("table.options"),
    action: t("table.action"),
    percent: "%",
  };

  const onCancelAddNewForm = () => {
    setShowAddNewOptionForm(false);
    reFreshOptionTable();
  };

  const onCancelUpdateForm = () => {
    setShowEditOptionForm(false);
    reFreshOptionTable();
    refreshSelectRows();
  };

  const refreshSelectRows = () => {
    if (optionTableComponentRef && optionTableComponentRef.current) {
      optionTableComponentRef.current.refreshSelectRows();
    }
  };

  const reFreshOptionTable = () => {
    if (optionTableComponentRef && optionTableComponentRef.current) {
      optionTableComponentRef.current.refresh();
    }
  };

  const onEditItem = (selectedRows) => {
    const { id } = selectedRows?.[0];
    setShowEditOptionForm(true);
    optionDataService.getOptionByIdAsync(id).then((response) => {
      if (response) {
        const { option } = response;
        editComponentRef.current({ ...option });
      }
    });
  };

  return (
    <>
      <FnbHeadingPage
        title={pageData.optionManagement}
        permissionCreate={PermissionKeys.CREATE_OPTION}
        onCreate={() => setShowAddNewOptionForm(true)}
      />
      {hasPermission(PermissionKeys.CREATE_OPTION) ? (
        <CreateNewOptionManagement
          isModalVisible={showAddNewOptionForm}
          handleCancel={onCancelAddNewForm}
          onSuccess={onCancelAddNewForm}
        />
      ) : (
        <></>
      )}

      {showEditOptionForm && (
        <EditOptionManagement visible={showEditOptionForm} func={editComponentRef} handleCancel={onCancelUpdateForm} />
      )}

      <TableOption
        ref={optionTableComponentRef}
        onEditItem={(_selectedRowKeys, selectedRows) => onEditItem(selectedRows)}
        setShowAddNew={setShowAddNewOptionForm}
      />
    </>
  );
}
