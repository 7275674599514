import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, message, Modal, Row, Space, Typography } from "antd";
import OnlineStoreBackground from "assets/images/online-store-background.png";
import classNames from "classnames";
import { BadgeStatus } from "components/badge-status";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbInputGroup, { FnbInputGroupType } from "components/fnb-input-group/fnb-input-group";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { BackIcon, CloneIcon, SpinnerIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { SignalRListenerConstants } from "constants/signalR-listener.constants";
import { EnumCustomDomainStep, EnumStoreThemeStatus } from "constants/store-theme.constants";
import { DateFormat } from "constants/string.constants";
import onlineStoreDataService from "data-services/online-store/online-store-data.service";
import packageDataService from "data-services/package/package-data.service";
import storeDataService from "data-services/store/store-data.service";
import moment from "moment";
import "moment/locale/vi";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getUserInfo } from "services/auth.service";
import { getThemeThumbnail } from "services/theme.service";
import adminSocket from "sockets/admin-socket";
import theme from "theme";
import { convertUtcToLocalTime, hasPermission } from "utils/helpers";
import { default as allOriginalThemes } from "../../../../theme-customize/themes";
import { StoreThemeList } from "./../../components/store-theme-list.component";
import "./website-tab.component.scss";

export default function OnlineStoreWebsiteTab(props) {
  const [t] = useTranslation();
  const myThemeRef = useRef(null);
  const allThemes = allOriginalThemes;
  const informationPublishStore = useSelector((state) => state?.session?.informationPublishStore);

  const pageData = {
    title: t("onlineStore.title"),
    selectTheme: t("onlineStore.selectTheme"),
    notSelectTheme: t("onlineStore.notSelectTheme"),
    theme: t("onlineStore.theme"),
    themeStore: t("onlineStore.themeStore"),
    currentTheme: t("onlineStore.currentTheme"),
    themeDetail: t("onlineStore.themeDetail"),
    apply: t("onlineStore.apply"),
    preview: t("onlineStore.preview"),
    includesSupport: t("onlineStore.includesSupport"),
    add: t("button.apply"),
    addThemeSuccess: t("onlineStore.addThemeSuccess"),
    customize: t("onlineStore.myTheme.button.customize"),
    lastSave: t("onlineStore.myTheme.lastSave"),
    publishFailMessage: t("theme.publish.failMessage", "Publish website failed, please try again after few minutes!"),
    configureDomain: {
      title: t("onlineStore.configureDomain.title", "Configure Domain"),
      subTitle: t(
        "onlineStore.configureDomain.subTitle",
        "* In this scope of the guideline, we use GoDaddy as the Domain provider <br /> (Do the same with order Domain providers: Domain.com, Namecheap, bluehost, IONOS,...)",
      ),
      step1: t("onlineStore.configureDomain.step1", "STEP 1: Input your website domain"),
      step1Label: t("onlineStore.configureDomain.step1Label", "Please input your website domain here"),
      step1WarningMessage: t("onlineStore.configureDomain.step1WarningMessage", "Please enter your website domain"),
      step1Placeholder: t("onlineStore.configureDomain.step1Placeholder", "e.g, thecoffeeshop.com"),
      success: t("onlineStore.configureDomain.success", "Success"),
      failed: t("onlineStore.configureDomain.failed", "Failed"),
      step2: t("onlineStore.configureDomain.step2", "STEP 2: Navigate to DNS Management in GoDaddy"),
      step2Label: t(
        "onlineStore.configureDomain.step2Label",
        "Add these 2 nameservers to the DNS management in GoDaddy portal",
      ),
      step3: t("onlineStore.configureDomain.step3", "STEP 3: Check Name server mapping status"),
      step3Label: t(
        "onlineStore.configureDomain.step3Label",
        "To use your Domain, make sure your authoritative DNS or nameservers have been changed. These are your assigned GoF&B nameservers.",
      ),
      nameServerHasBeenUpdatedMessage: t(
        "onlineStore.configureDomain.nameServerHasBeenUpdatedMessage",
        "The nameserver has been updated successfully",
      ),
      waitingToDomainServer: t(
        "onlineStore.configureDomain.waitingToDomainServer",
        "Processing: waiting to domain server update name server...",
      ),
      waitTo24hoursToProcessNameServerChanges: t(
        "onlineStore.configureDomain.waitTo24hoursToProcessNameServerChanges",
        "Registrars take up to 24 hours to process nameserver changes(quicker in most cases).We will email you when {{customDomain}} is active on Cloudflare.",
      ),
      cloudflareWillRespondToDNSQueries: t(
        "onlineStore.configureDomain.cloudflareWillRespondToDNSQueries",
        "While in this pending state, Cloudflare will respond to DNS queries on your assigned nameservers.",
      ),
      automaticOptimizationsWillActivatedWithProxiesDNS: t(
        "onlineStore.configureDomain.automaticOptimizationsWillActivatedWithProxiesDNS",
        "Once activated, SSL/TLS, DDoS protection, caching, and other automatic optimizations will go live for proxied DNS records, along with any custom settings you pre-configure.",
      ),
      onlyCheckOncePerHour: t(
        "onlineStore.configureDomain.onlyCheckOncePerHour",
        "The verification for the nameservers of {{customDomain}} is being checked. You may only submit a re-check once per hour",
      ),
      step4: t("onlineStore.configureDomain.step4", "STEP 4: Submit"),
      step4Label: t(
        "onlineStore.configureDomain.step4Label",
        "Ensure your domain name is not blank and The mapping status is active",
      ),
      updateCustomDomainSuccessMessage: t(
        "onlineStore.configureDomain.updateCustomDomainSuccessMessage",
        "The website is updated to the new domain {{customDomain}}",
      ),
      status: t("onlineStore.configureDomain.status", "Status"),
      active: t("onlineStore.configureDomain.active", "Active"),
      inactive: t("onlineStore.configureDomain.inactive", "Inactive"),
      submit: t("onlineStore.configureDomain.submit", "Submit"),
      checkMappingStatus: t("onlineStore.configureDomain.checkMappingStatus", "Check mapping status"),
      checkMappingStatusWarningMessage: t(
        "onlineStore.configureDomain.checkMappingStatusWarningMessage",
        "The nameservers have not been updated, please wait and check again",
      ),
      copiedSuccess: t("onlineStore.configureDomain.copiedSuccess", "Copied!"),
      warningModal: {
        title: t("onlineStore.configureDomain.warningModal.title", "Submit failed"),
        ok: t("onlineStore.configureDomain.warningModal.ok", "I got it"),
        description: t(
          "onlineStore.configureDomain.warningModal.description",
          "The status of nameserver mapping is <span className='inactive'>inactive</span>. It mean the nameserver have not been added to the DNS management of the provider portal.<br /><br />Please ensure you have added these 2 nameservers to the DNS management in the domain provider portal.",
        ),
      },
    },
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleDetail, setIsModalVisibleDetail] = useState(false);
  const [themes, setThemes] = useState([]);
  const [selectTheme, setSelectTheme] = useState({});
  const [currentTheme, setCurrentTheme] = useState({});

  const loggedUserInfo = getUserInfo();
  const [customDomain, setCustomDomain] = useState();
  const [customDomainPrevious, setCustomDomainPrevious] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isConfigureDomainModalVisible, setIsConfigureDomainModalVisible] = useState(false);
  const [isCustomDomainInvalid, setIsCustomDomainInvalid] = useState(false);
  const [nameServers1, setNameServers1] = useState();
  const [nameServers2, setNameServers2] = useState();
  const [isCheckingNameServers, setIsCheckingNameServers] = useState(false);
  const [isCheckingMappingStatus, setIsCheckingMappingStatus] = useState(false);
  const [isShowMappingStatusInfo, setIsShowMappingStatusInfo] = useState(false);
  const [isCheckingCustomDomain, setIsCheckingCustomDomain] = useState(false);
  const [isStoreWebPackageAvailable, setIsStoreWebPackageAvailable] = useState(false);

  useEffect(() => {
    initSocket();
    const fetchData = async () => {
      await getListMyThemes();
      await checkStoreWebAvailable();
    };

    fetchData();

    // Clean socket
    return () => {
      cleanSocket();
    };
  }, []);

  useEffect(() => {
    if (Boolean(informationPublishStore)) {
      setCustomDomain(informationPublishStore?.customDomain);
      setCustomDomainPrevious(informationPublishStore?.customDomain);
      setIsActive(Boolean(informationPublishStore?.mappingDomainStatus));
      setNameServers1(informationPublishStore?.nameServers1);
      setNameServers2(informationPublishStore?.nameServers2);
    }
  }, [informationPublishStore]);

  const checkStoreWebAvailable = async () => {
    const response = await packageDataService.checkStoreWebAvailableAsync();
    if (response) {
      setIsStoreWebPackageAvailable(response?.data?.isAvailable);
    }
  };

  const getListMyThemes = async () => {
    const res = await onlineStoreDataService.getStoreThemesAsync();
    const themeData = res.storeThemes;
    const activeTheme = themeData.find((themeData) => themeData.isPublished === true);
    setCurrentTheme(activeTheme);
    const result = themeData.filter((themeData) => themeData.isPublished === false);
    if (result) {
      const storeThemes = result;

      ///Check if have theme is publishing
      let isCurrentThemePublishing = false;
      themeData?.forEach((item) => {
        if (item.statusId === EnumStoreThemeStatus.Publishing) {
          isCurrentThemePublishing = true;
        }
      });

      if (myThemeRef && myThemeRef.current) {
        myThemeRef.current.setMyThemeData(storeThemes, isCurrentThemePublishing);
      }
    }

    if (res?.isPublishedFailed === true) {
      message.error(pageData.publishFailMessage);
    }
  };

  const onShowThemes = async () => {
    setThemes([]);
    const data = await storeDataService.getThemesAsync();
    const listThemes = data.themes?.map((t) => {
      return {
        ...t,
        thumbnail: getThemeThumbnail(t?.id),
      };
    });
    setThemes(listThemes);
    setIsModalVisible(true);
  };

  const onShowDetailTheme = async (id) => {
    var theme = themes.find((x) => x.id === id);
    setSelectTheme(theme);
    setIsModalVisibleDetail(true);
  };

  const onClickAddTheme = async (themeId) => {
    const themeInfo = allThemes.find((theme) => theme.themeData.id.toLowerCase() === themeId.toLowerCase());
    let request = {
      themeId: themeId,
      configuration: JSON.stringify(themeInfo.defaultConfig),
    };

    const res = await onlineStoreDataService.createStoreThemeAsync(request);
    const { success } = res;
    if (success) {
      message.success(pageData.addThemeSuccess);
      setIsModalVisible(false);
      setIsModalVisibleDetail(false);
      getListMyThemes();
    }
  };

  const formatCreateTime = (createdTime) => {
    let timeFormat = "-";
    const d = new Date();
    let day = d.getDate();

    if (createdTime) {
      const utcDate = moment.utc(createdTime).local().date();

      // If day is today, show time only
      if (day === utcDate) {
        timeFormat = convertUtcToLocalTime(createdTime).format(DateFormat.HH_MM);
      } else {
        timeFormat = convertUtcToLocalTime(createdTime).format(DateFormat.DD_MM_YYYY);
      }
    }

    return timeFormat;
  };

  const copyButton = async (value) => {
    navigator.clipboard.writeText(value);
    message.success(pageData.configureDomain.copiedSuccess);
  };

  const onCustomDomainChanged = async (e) => {
    const value = e?.target?.value;
    setCustomDomain(value);
    setIsCustomDomainInvalid(value?.length <= 0);
  };

  const onCheckNameServers = async () => {
    if (isCustomDomainInvalid) {
      return;
    } else if (isCustomDomainInvalid === null) {
      setIsCustomDomainInvalid(true);
      return;
    }

    setIsCheckingNameServers(true);
    setCustomDomainPrevious(customDomain);
    updateCustomDomain(EnumCustomDomainStep.CheckNameServers);
  };

  const onCheckMappingStatus = async () => {
    setCustomDomainPrevious(customDomain);
    setIsCheckingMappingStatus(true);
    updateCustomDomain(EnumCustomDomainStep.CheckMappingStatus);
  };

  const onCustomDomain = async () => {
    if (isCustomDomainInvalid) {
      return;
    } else if (isCustomDomainInvalid === null) {
      setIsCustomDomainInvalid(true);
      return;
    }

    setIsCheckingCustomDomain(true);
    if (!isActive) {
      setIsConfigureDomainModalVisible(true);
    } else {
      updateCustomDomain(EnumCustomDomainStep.UpdateCustomDomain);
    }
  };

  const updateCustomDomain = async (step) => {
    const data = { customDomain, step };
    await storeDataService.updateCustomDomainAsync(data);
  };

  const initSocket = async () => {
    try {
      if (loggedUserInfo) {
        let userInfo = {
          storeId: loggedUserInfo?.storeId,
        };

        adminSocket.on(SignalRListenerConstants.SIGNAL_CHECK_NAME_SERVERS, (response) => {
          console.log("SIGNAL_CHECK_NAME_SERVERS", response);
          setIsCheckingNameServers(false);
          setIsActive(false);
          const { nameServers1, nameServers2, isSuccess } = response;
          setNameServers1(nameServers1);
          setNameServers2(nameServers2);
          if (isSuccess) {
            message.success(t(pageData.configureDomain.success));
          } else {
            message.error(t(pageData.configureDomain.failed));
          }
        });

        adminSocket.on(SignalRListenerConstants.SIGNAL_CHECK_MAPPING_STATUS, (response) => {
          console.log("SIGNAL_CHECK_MAPPING_STATUS", response);
          setIsCheckingMappingStatus(false);
          const { isSuccess } = response;
          setIsShowMappingStatusInfo(!isSuccess);
          setIsActive(isSuccess);
          if (isSuccess) {
            message.success(t(pageData.configureDomain.nameServerHasBeenUpdatedMessage));
          } else {
            message.error(t(pageData.configureDomain.checkMappingStatusWarningMessage));
          }
        });

        adminSocket.on(SignalRListenerConstants.SIGNAL_UPDATE_CUSTOM_DOMAIN, (response) => {
          console.log("SIGNAL_UPDATE_CUSTOM_DOMAIN", response);
          setIsCheckingCustomDomain(false);
          const { isSuccess, domainName } = response;
          if (isSuccess) {
            const messageContent = t(pageData.configureDomain.updateCustomDomainSuccessMessage, {
              customDomain: domainName,
            });
            message.success(messageContent, 3, () => {
              window.location.reload();
            });
          } else {
            message.error(t(pageData.configureDomain.checkMappingStatusWarningMessage));
          }
        });

        await adminSocket.start();
        await adminSocket.invoke("JoinRoom", userInfo);
      }
    } catch {}
  };

  const cleanSocket = () => {
    try {
      adminSocket.off(SignalRListenerConstants.SIGNAL_CHECK_NAME_SERVERS);
      adminSocket.off(SignalRListenerConstants.SIGNAL_CHECK_MAPPING_STATUS);
      adminSocket.off(SignalRListenerConstants.SIGNAL_UPDATE_CUSTOM_DOMAIN);
    } catch (err) {
      message.error(err);
    }
  };

  const classNamesDomainCard = classNames({
    "configure-domain-card": true,
    "d-none": !isStoreWebPackageAvailable,
  });

  return (
    <div>
      <div className="online-store-management">
        {isTabletOrMobile ? (
          <Card className="online-store-card-mobile">
            <Col span={12}>
              <div className="title">{pageData.theme}</div>
            </Col>
          </Card>
        ) : (
          <Card className="online-store-card">
            <Col span={12}>
              <div className="title">{pageData.theme}</div>
            </Col>
          </Card>
        )}

        <Card className="online-store-card-main">
          {isTabletOrMobile ? (
            currentTheme ? (
              <>
                <Row>
                  <Col span={24}>
                    <p className="current-theme-name-mobile">{t(currentTheme.name)}</p>
                    <img className="img-prop-mobile" src={getThemeThumbnail(currentTheme?.themeId)} alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="title-mobile">
                      <p>{pageData.currentTheme}</p>
                      <p className="text-time-mobile mb-0">
                        <span>{pageData.lastSave}:</span>
                        <span className="ml-2">
                          {formatCreateTime(
                            currentTheme?.lastSavedTime ? currentTheme?.lastSavedTime : currentTheme?.createdTime,
                          )}
                        </span>
                      </p>
                      {hasPermission(PermissionKeys.EDIT_THEME) && (
                        <a href={`/online-store/theme-customize/${currentTheme?.id}`} target="_blank" rel="noreferrer">
                          <FnbButton text={pageData.customize} />
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={24}>
                    <Image className="img-prop-mobile" src={OnlineStoreBackground} preview={false} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="title-mobile">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: pageData.notSelectTheme,
                        }}
                      ></p>
                      <Button
                        type="primary"
                        className="btn-select-mobile"
                        htmlType="submit"
                        onClick={() => onShowThemes(true)}
                      >
                        {pageData.selectTheme}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )
          ) : currentTheme ? (
            <>
              <Row>
                <Col span={8}>
                  <div className="title-current">
                    <p>{pageData.currentTheme}</p>
                    <p className="text-time mb-0">
                      <span>{pageData.lastSave}:</span>
                      <span className="ml-2">{formatCreateTime(currentTheme?.createdTime)}</span>
                    </p>
                    {hasPermission(PermissionKeys.EDIT_THEME) && (
                      <a href={`/online-store/theme-customize/${currentTheme?.id}`} target="_blank" rel="noreferrer">
                        <FnbButton text={pageData.customize} />
                      </a>
                    )}
                  </div>
                </Col>
                <Col span={16}>
                  <div className="img-current-theme-card">
                    <div className="img-current-theme-container">
                      <p className="current-theme-name">{t(currentTheme.name)}</p>
                      <Image
                        className="img-prop-current"
                        src={getThemeThumbnail(currentTheme?.themeId)}
                        preview={false}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col span={12}>
                  <div className="title">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageData.notSelectTheme,
                      }}
                    ></p>
                    <Button type="primary" className="btn-select" htmlType="submit" onClick={() => onShowThemes(true)}>
                      {pageData.selectTheme}
                    </Button>
                  </div>
                </Col>
                <Col span={12} className="img-back-ground">
                  <Image className="img-prop" src={OnlineStoreBackground} preview={false} />
                </Col>
              </Row>
            </>
          )}
        </Card>

        {/* My theme list */}
        <StoreThemeList ref={myThemeRef} addNewTheme={() => onShowThemes(true)} updateCurrentTheme={getListMyThemes} />

        {/* Configure Domain */}
        <Card className={classNamesDomainCard}>
          <Space className="w-100" direction="vertical" size={24} id="configure-domain-container">
            <Space direction="vertical" size={12} id="configure-domain-title">
              <Row>
                <div className="card-title">{pageData.configureDomain.title}</div>
              </Row>
              <Row>
                <div
                  className="card-sub-title"
                  dangerouslySetInnerHTML={{
                    __html: pageData.configureDomain.subTitle,
                  }}
                ></div>
              </Row>
            </Space>
            <Space className="w-100" direction="vertical" size={16} id="configure-domain-step1">
              <div className="step-title">{pageData.configureDomain.step1}</div>
              <Space direction="vertical" size={8} className="step-content w-100">
                <div>{pageData.configureDomain.step1Label}</div>
                <Row>
                  <Col sm={16} xxl={12}>
                    <FnbInputGroup
                      className="right d-flex"
                      firstInput={{
                        type: FnbInputGroupType.INPUT,
                        width: "100%",
                        style: {
                          maxWidth: "100%",
                        },
                        placeholder: pageData.configureDomain.step1Placeholder,
                        value: customDomain,
                        onChange: onCustomDomainChanged,
                      }}
                      secondInput={{
                        type: FnbInputGroupType.BUTTON,
                        width: "fit-content",
                        text: pageData.configureDomain.submit,
                        disabled:
                          isCustomDomainInvalid || customDomain === customDomainPrevious || isCheckingNameServers,
                        loading: isCheckingNameServers,
                        onClick: onCheckNameServers,
                      }}
                    ></FnbInputGroup>
                    {isCustomDomainInvalid ? (
                      <Typography.Text className="errorMessage">
                        {pageData.configureDomain.step1WarningMessage}
                      </Typography.Text>
                    ) : null}
                  </Col>
                </Row>
              </Space>
            </Space>
            <Space
              direction="vertical"
              size={16}
              id="configure-domain-step2"
              className={customDomainPrevious === undefined ? "step-disabled w-100" : "w-100"}
            >
              <div className="step-title">{pageData.configureDomain.step2}</div>
              <Space direction="vertical" size={16} className="step-content w-100">
                <div>{pageData.configureDomain.step2Label}</div>
                <Space direction="vertical" size={8} className="w-100">
                  <div>DNS 1</div>
                  <Row>
                    <Col sm={16} xxl={12}>
                      <FnbInput
                        value={nameServers1}
                        disabled
                        suffix={
                          nameServers1 !== undefined && (
                            <CloneIcon style={{ cursor: "pointer" }} onClick={() => copyButton(nameServers1)} />
                          )
                        }
                      ></FnbInput>
                    </Col>
                  </Row>
                </Space>
                <Space direction="vertical" size={8} className="w-100">
                  <div>DNS 2</div>
                  <Row>
                    <Col sm={16} xxl={12}>
                      <FnbInput
                        value={nameServers2}
                        disabled
                        suffix={
                          nameServers2 !== undefined && (
                            <CloneIcon style={{ cursor: "pointer" }} onClick={() => copyButton(nameServers2)} />
                          )
                        }
                      ></FnbInput>
                    </Col>
                  </Row>
                </Space>
              </Space>
            </Space>
            <Space
              direction="vertical"
              size={16}
              id="configure-domain-step3"
              className={customDomainPrevious === undefined ? "step-disabled" : ""}
            >
              <div className="step-title">{pageData.configureDomain.step3}</div>
              <Space direction="vertical" size={16} className="step-content">
                <div>{pageData.configureDomain.step3Label}</div>
                <Space size={16}>
                  {pageData.configureDomain.status}:
                  <BadgeStatus
                    isActive={isActive}
                    text={isActive ? pageData.configureDomain.active : pageData.configureDomain.inactive}
                  ></BadgeStatus>
                </Space>
                <Space size={12} className="flex-wrap-container">
                  <FnbButton
                    variant="secondary"
                    text={pageData.configureDomain.checkMappingStatus}
                    iconHeader={<SpinnerIcon className={isCheckingMappingStatus ? "spin-icon" : ""}></SpinnerIcon>}
                    onClick={onCheckMappingStatus}
                    disabled={
                      isCheckingMappingStatus ||
                      isCustomDomainInvalid ||
                      customDomain !== customDomainPrevious ||
                      isCheckingNameServers ||
                      customDomainPrevious === undefined
                    }
                  ></FnbButton>
                  {isShowMappingStatusInfo && (
                    <FnbTypography
                      text={pageData.configureDomain.waitingToDomainServer}
                      variant={theme.typography["b1-regular"]}
                      color={theme.colors.secondary[70]}
                    ></FnbTypography>
                  )}
                </Space>
                {isShowMappingStatusInfo && (
                  <>
                    <Row>
                      <Col xxl={16}>
                        <Space size={12} align="start" className="mapping-information">
                          <InfoCircleOutlined className="info-icon"></InfoCircleOutlined>
                          <Space direction="vertical" size={12}>
                            <FnbTypography
                              text={t(pageData.configureDomain.waitTo24hoursToProcessNameServerChanges, {
                                customDomain,
                              })}
                              variant={theme.typography["b1-bold"]}
                              color="#001AA3"
                            ></FnbTypography>
                            <FnbTypography
                              text={pageData.configureDomain.cloudflareWillRespondToDNSQueries}
                              variant={theme.typography["b1-regular"]}
                              color="#001270"
                            ></FnbTypography>
                            <FnbTypography
                              text={pageData.configureDomain.automaticOptimizationsWillActivatedWithProxiesDNS}
                              variant={theme.typography["b1-regular"]}
                              color="#001270"
                            ></FnbTypography>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col xxl={16}>
                        <Space className="mapping-information">
                          <FnbTypography
                            text={t(pageData.configureDomain.onlyCheckOncePerHour, { customDomain })}
                            variant={theme.typography["b1-regular"]}
                            color="#001270"
                          ></FnbTypography>
                        </Space>
                      </Col>
                    </Row>
                  </>
                )}
              </Space>
            </Space>
            <Space
              direction="vertical"
              size={16}
              id="configure-domain-step4"
              className={customDomainPrevious === undefined ? "step-disabled" : ""}
            >
              <div className="step-title">{pageData.configureDomain.step4}</div>
              <Space direction="vertical" size={16} className="step-content">
                <div>{pageData.configureDomain.step4Label}</div>
                <FnbButton
                  text={pageData.configureDomain.submit}
                  loading={isCheckingCustomDomain}
                  onClick={onCustomDomain}
                  disabled={!isActive || isCheckingCustomDomain}
                />
              </Space>
            </Space>
          </Space>
        </Card>
      </div>
      {/* Modal */}
      <Modal
        onCancel={() => setIsModalVisible(false)}
        className={isTabletOrMobile ? "online-store-modal-mobile" : "online-store-modal"}
        width={1416}
        visible={isModalVisible}
        footer={(null, null)}
      >
        <Row className="online-store-header">
          <h3>{pageData.themeStore}</h3>
        </Row>
        <hr />
        <div className="online-store-list">
          {
            <div className={isTabletOrMobile ? "online-store-wrapper-mobile" : "online-store-wrapper"}>
              {
                <>
                  {themes?.map((theme) => {
                    return (
                      <div className="order-content-card" onClick={() => onShowDetailTheme(theme?.id)}>
                        <div className="order-content-header">
                          <img className="theme-card" src={theme?.thumbnail} alt="" />
                        </div>
                        <div className="order-content">
                          <Row>
                            <p className="title-theme">{t(theme?.name)}</p>
                            {theme?.isDefault && <div className="default-theme">{pageData.currentTheme}</div>}
                          </Row>
                          <Row className="tag-margin">
                            {theme?.tags?.split(",").map((tag) => {
                              return <p className="material-view-branch-select material-view-text">{t(tag)}</p>;
                            })}
                          </Row>
                        </div>
                      </div>
                    );
                  })}
                </>
              }
            </div>
          }
        </div>
      </Modal>

      <Modal
        onCancel={() => setIsModalVisibleDetail(false)}
        className={isTabletOrMobile ? "online-store-modal-mobile" : "online-store-modal"}
        width={1416}
        visible={isModalVisibleDetail}
        footer={(null, null)}
      >
        <Row className="online-store-header">
          <h3>
            {!isTabletOrMobile && <BackIcon className="back-icon" onClick={() => setIsModalVisibleDetail(false)} />}
            {pageData.themeDetail}
          </h3>
        </Row>
        <hr />
        <div className="online-store-list">
          {isTabletOrMobile ? (
            <div className="online-store-wrapper-mobile">
              {
                <>
                  <div className="content-theme-detail-padding">
                    <Row>
                      <Col sm={24} xs={24} lg={14}>
                        <img width={350} height={213} src={selectTheme?.thumbnail} alt="" />
                      </Col>
                      <Col sm={24} xs={24} lg={10}>
                        <Row>
                          <div className="title-theme-detail">{t(selectTheme?.name)}</div>
                        </Row>
                        <Row>
                          <Col span={24} className="content-theme-detail">
                            <div>
                              {t(selectTheme?.description)
                                .split("\n")
                                .map((item, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      {item}
                                      <br />
                                    </React.Fragment>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="title-theme-tag">
                            {pageData.includesSupport}
                          </Col>
                        </Row>
                        {selectTheme?.tags?.split(",").map((tag) => {
                          return (
                            <Row>
                              <Col span={24} className="theme-tag">
                                <ul style={{ listStyleType: "disc", paddingLeft: "24px" }}>
                                  <li>{`${t(tag)}`}</li>
                                </ul>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <BackIcon className="back-icon" onClick={() => setIsModalVisibleDetail(false)} />
                        <a href={`/theme-preview/${selectTheme?.id}/home`} target="_blank" rel="noreferrer">
                          <Button
                            type="button"
                            className="btn-theme-detail-preview-theme"
                            onClick={() => setIsModalVisible(true)}
                          >
                            {pageData.preview}
                          </Button>
                        </a>
                        <Button
                          type="button"
                          className="btn-theme-detail-change-theme"
                          onClick={() => setIsModalVisible(true)}
                        >
                          {pageData.apply}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              }
            </div>
          ) : (
            <div className="online-store-wrapper">
              {
                <>
                  <div className="content-theme-detail-padding">
                    <Row>
                      <Col span={14}>
                        <Image width={771} height={470} src={selectTheme?.thumbnail} preview={false} />
                      </Col>
                      <Col span={10} className="info-theme-detail">
                        <Row className="title-theme-detail">
                          <div>{t(selectTheme?.name)}</div>
                        </Row>
                        <Row>
                          <Col span={24} className="content-theme-detail">
                            <div>
                              {t(selectTheme?.description)
                                .split("\n")
                                .map((item, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      {item}
                                      <br />
                                    </React.Fragment>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} className="title-theme-tag">
                            {pageData.includesSupport}
                          </Col>
                        </Row>
                        {selectTheme?.tags?.split(",").map((tag) => {
                          return (
                            <Row>
                              <Col span={24} className="theme-tag">
                                <ul style={{ listStyleType: "disc", paddingLeft: "36px" }}>
                                  <li>{`${t(tag)}`}</li>
                                </ul>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={14}>
                        <a href={`/theme-preview/${selectTheme?.id}/home`} target="_blank" rel="noreferrer">
                          <Button
                            type="button"
                            className="btn-theme-detail-preview-theme"
                            onClick={() => setIsModalVisible(true)}
                          >
                            {pageData.preview}
                          </Button>
                        </a>
                        <Button
                          type="button"
                          className="btn-theme-detail-change-theme"
                          onClick={() => onClickAddTheme(selectTheme?.id)}
                        >
                          {pageData.add}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              }
            </div>
          )}
        </div>
      </Modal>

      <Modal
        onCancel={() => setIsConfigureDomainModalVisible(false)}
        className="configure-domain-card-modal"
        width={396}
        visible={isConfigureDomainModalVisible}
        footer={(null, null)}
      >
        <Space direction="vertical" size={40} className="w-100">
          <Row>
            <Col span={24}>
              <div className="title">{pageData.configureDomain.warningModal.title}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: pageData.configureDomain.warningModal.description,
                }}
              ></div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="dns">
                DNS 1: anna.ns.cloudflare.com
                <br />
                DNS 2: guy.ns.cloudflare.com
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="button">
              <FnbButton
                text={pageData.configureDomain.warningModal.ok}
                onClick={() => setIsConfigureDomainModalVisible(false)}
              ></FnbButton>
            </Col>
          </Row>
        </Space>
      </Modal>
    </div>
  );
}
