export const ContactLocationIconCustomize = (props) => {
  const { color = "#FF8C21", width = 26, height = 31 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0001 30.0803C12.924 30.0803 12.7333 30.0599 12.605 29.8712L10.256 26.4153C9.70357 25.6026 8.93657 24.9669 8.03793 24.5769C3.42416 22.5743 0.475581 18.0304 0.526028 13.0008C0.559283 9.68313 1.86978 6.57718 4.21607 4.25505C6.56211 1.93317 9.68198 0.654297 13.0001 0.654297H13.0001C19.8787 0.654297 25.4748 6.25047 25.4748 13.129C25.4748 18.1058 22.5232 22.6005 17.9553 24.5799C17.0618 24.9671 16.2972 25.6018 15.7442 26.4154L13.3952 29.8712C13.2669 30.0599 13.0761 30.0803 13.0001 30.0803Z"
        fill={color}
      />
    </svg>
  );
};
