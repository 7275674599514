import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import customerDataService from "data-services/customer/customer-data.service";
import { useEffect, useState } from "react";
import { formatPhoneNumber, formatNumberDecimalOrInteger } from "utils/helpers";

export default function TableMembershipLevelByAccumulatedPointComponent(props) {
  const { t, membershipAccumulatedPoint } = props;
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [accumulatedPoint, setAccumulatedPoint] = useState(0);

  const pageData = {
    title: t("membership.title"),
    accumulatedPoint: t("membership.accumulatedPoint"),
    discount: t("membership.discount"),
    member: t("membership.member"),
    no: t("membership.no"),
    action: t("membership.action"),
    name: t("membership.Name"),
    phone: t("membership.Phone"),
  };

  const tableSettings = {
    page: currentPageNumber,
    pageSize: 10,
    columns: [
      {
        title: pageData.no,
        dataIndex: "index",
        render: (val) => {
          return val + 1;
        },
        width: "7%",
      },
      {
        title: pageData.name,
        dataIndex: "name",
        ellipsis: true,
        render: (val) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis title={val}>
              <FnbTypography text={val} />
            </FnbTooltip>
          );
        },
        width: "30%",
      },
      {
        title: pageData.accumulatedPoint,
        width: "30%",
        dataIndex: "accumulatedPoint",
        render: (val) => {
          return <>{formatNumberDecimalOrInteger(val)}</>;
        },
      },
      {
        title: pageData.phone,
        width: "30%",
        dataIndex: "phoneNumber",
        render: (_, record) => {
          return formatPhoneNumber(record?.phoneCode, record?.phoneNumber);
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize, accumulatedPoint);
    },
  };

  useEffect(() => {
    props.tableFuncs.current = onRefreshTable;
    onRefreshTable(membershipAccumulatedPoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshTable = async (accumulatedPoint) => {
    setAccumulatedPoint(accumulatedPoint);
    await fetchDatableAsync(1, tableSettings.pageSize, accumulatedPoint);
  };

  const fetchDatableAsync = async (page, pageSize, accumulatedPoint) => {
    let responseData = await customerDataService.getCustomerByAccumulatedPointAsync(page, pageSize, accumulatedPoint);

    if (responseData) {
      const { customers, total } = responseData;
      const records = customers?.map((item) => mappingRecordToColumns(item));
      setDataSource(records);
      setTotalRecords(total);
      setCurrentPageNumber(page);
    }
  };

  const mappingRecordToColumns = (item) => {
    return {
      index: item?.no,
      id: item?.id,
      name: [item?.firstName, item?.lastName].join(" ").trim() ?? "N/A",
      accumulatedPoint: item?.accumulatedPoint,
      phoneNumber: item?.phoneNumber,
      phoneCode: item?.phoneCode,
    };
  };

  return (
    <FnbTable
      columns={tableSettings.columns}
      pageSize={tableSettings.pageSize}
      dataSource={dataSource}
      currentPageNumber={currentPageNumber}
      total={totalRecords}
      onChangePage={tableSettings.onChangePage}
    />
  );
}
