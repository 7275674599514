import { Col, Form, Input, Row, message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDeleteIcon } from "components/fnb-delete-icon/fnb-delete-icon";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import {
  CallOutlined,
  ChromeOutlined,
  MessagesOutlined,
  StoreGeneralConfigInfoCircleIcon,
} from "constants/icons.constants";
import { paymentMethod } from "constants/payment-method.constants";
import branchDataService from "data-services/branch/branch-data.service";
import paymentConfigDataService from "data-services/payment-config/payment-config-data.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { randomGuid } from "utils/helpers";
import "./mpos-payment-config.component.scss";

export function MPOSPaymentConfigComponent(props) {
  const { onCompleted, initData, onChangeForm, IsUnActivated } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableAddMachine, setDisableAddMachine] = useState(false);
  const [branches, setBranches] = useState([]);
  const isScreenXL = useMediaQuery({ maxWidth: 1200 });
  const [selectBranchs, setSelectBranchs] = useState([]);

  const pageData = {
    updateSuccess: t("payment.updateSuccess"),
    authenticationSuccessfulMPOS: t("payment.authenticationSuccessfulMPOS"),
    authenticationFailMPOS: t("payment.authenticationFailMPOS"),
    merchantId: t("payment.merchantId"),
    enterMerchantId: t("payment.enterMerchantId"),
    requiredField: t("payment.requiredField"),
    secretKeyMPOS: t("payment.secretKeyMPOS"),
    enterSecretKeyMPOS: t("payment.enterSecretKeyMPOS"),
    branch: t("payment.branch"),
    mPOSDevice: t("payment.mPOSDevice"),
    enterMPOSDevice: t("payment.enterMPOSDevice"),
    mPOSDeviceValidateMessage: t("payment.mPOSDeviceValidateMessage"),
    mPOSDeviceExisted: t("payment.mPOSDeviceExisted"),
    branchPlaceholder: t("payment.branchPlaceholder"),
    branchValidateMessage: t("payment.branchValidateMessage"),
    btnAddMPOSMachine: t("payment.addMPOSMachine"),
    linkWebsite: "https://www.mpos.vn",
    website: "www.mpos.vn",
    email: "hotro@mpos.vn",
    hotline: "0966 41 15 15",
    mPOSTitle: t("payment.mPOSTitle"),
    mPOSSubTitle: t("payment.mPOSSubTitle"),
    mPOSDeviceDescription: t("payment.mPOSDeviceDescription"),
    button: {
      cancel: t("button.cancel"),
      save: t("payment.save"),
    },
    userName: t("payment.userName"),
    userNamePlaceHolder: t("payment.userNamePlaceHolder"),
    password: t("payment.password"),
    passwordPlaceholder: t("payment.passwordPlaceholder"),
    passWordIsTooShort: t("payment.passWordIsTooShort"),
    provideYourAccountOnTheMPOS: t("payment.provideYourAccountOnTheMPOS"),
  };

  useEffect(() => {
    getBranches(initData);
    setInitData(initData);
  }, []);

  useEffect(() => {
    onChangeForm(isChangeForm);
  }, [isChangeForm]);

  useEffect(() => {
    const updateBranchs = branches.map((branch) => {
      return {
        ...branch,
        disabled: selectBranchs.some((branchSelect) => branch.id === branchSelect),
      };
    });
    setBranches(updateBranchs);
  }, [selectBranchs]);

  const setInitData = (paymentMethod) => {
    const { paymentConfigs } = paymentMethod;
    const newMachine = [
      {
        index: 0,
        id: randomGuid(),
        branchId: null,
        mposDeviceId: "",
      },
    ];
    const paymentConfig = paymentConfigs[0] || null;
    const mPOSMachines = paymentConfig?.mposMachines || newMachine;
    let config = {
      paymentMethodId: paymentMethod?.id,
      mposMachines: mPOSMachines,
      ...paymentConfig,
    };
    if (!paymentConfig?.merchantId && !paymentConfig?.secretKey) {
      config = {
        paymentMethodId: paymentMethod?.id,
        ...paymentConfig,
        mposMachines: mPOSMachines,
        merchantId: "",
        secretKey: " ",
      };
    }
    if (config) {
      form.setFieldsValue(config);
      if (config.secretKey === " ") {
        setTimeout(() => {
          config.secretKey = "";
          form.setFieldsValue(config);
        }, 750);
      }
      onCheckAddMachine();
    }
  };

  const getBranches = (initData) => {
    branchDataService.getAllBranchsAsync().then((res) => {
      if (res) {
        setBranches(
          res.branchs?.map((branch) => ({
            id: branch.id,
            name: branch.name,
            disabled: false,
          })),
        );
        if (initData?.paymentConfigs[0]?.mposMachines?.length > 0) {
          const listBranchIdSelect = initData.paymentConfigs[0].mposMachines.map((item) => item.branchId);
          setSelectBranchs(listBranchIdSelect);
        }
      }
    });
  };

  const onClickAuthenticate = () => {
    form
      .validateFields()
      .then((_) => {
        const valuesForm = form.getFieldsValue();
        paymentConfigDataService.updatePaymentConfigAsync(valuesForm).then((response) => {
          const { success, errorMessage } = response;
          if (success === true) {
            message.success(pageData.authenticationSuccessfulMPOS);
            setIsChangeForm(false);
            onCompleted();
          } else {
            message.error(t(errorMessage));
          }
        });
      })
      .catch((_) => {
        setDisableSaveButton(true);
      });
  };

  const onValidForm = (isValid = true) => {
    onCheckAddMachine();
    setIsChangeForm(true);
    if (isValid) {
      form
        .validateFields()
        .then((_) => {
          setDisableSaveButton(false);
        })
        .catch((_) => {
          setDisableSaveButton(true);
        });
    }
  };

  const validateMPOSDeviceId = (value) => {
    if (value) {
      let formValue = form.getFieldsValue();
      let { mposMachines } = formValue;
      if (mposMachines.length > 1) {
        var isExist = mposMachines.filter((machine) => machine.mposDeviceId === value);
        if (isExist.length >= 2) {
          const validateMessage = pageData.mPOSDeviceExisted;
          return Promise.reject(validateMessage);
        }
      }
    }
    return Promise.resolve();
  };

  const onCheckAddMachine = () => {
    let formValue = form.getFieldsValue();
    if (formValue.merchantId && formValue.secretKey) setDisableAddMachine(false);
    else setDisableAddMachine(true);
  };

  const onFormChanged = (changedValues, value) => {
    //If add new mPosMachine with empty data will not update IsChangeForm
    if (!isChangeForm) {
      if (changedValues?.mposMachines) {
        const dataModified = changedValues?.mposMachines?.filter((item) => item !== undefined);
        const initDataMPosMachines = initData.paymentConfigs[0].mposMachines;
        if (dataModified?.length !== initDataMPosMachines?.length) {
          setIsChangeForm(true);
        }
      } else {
        setIsChangeForm(true);
      }
    }
  };

  const onCancel = () => {
    setInitData(initData);
    setIsChangeForm(false);
  };

  const handleOnClearSelect = (value) => {
    const newSelectBranch = [...selectBranchs].filter((branch) => branch !== value);
    setSelectBranchs(newSelectBranch);
  };

  return (
    <>
      <Form
        form={form}
        className="enterprise-payment-config custom-form mpos-config"
        layout="vertical"
        autoComplete="off"
        onValuesChange={onFormChanged}
      >
        <Row span={24} className="pos-header">
          <Col md={17} span={24} className="switch-title">
            <Row className="mpos-name">
              <StoreGeneralConfigInfoCircleIcon width={16} height={16} />
              <span>{pageData.mPOSTitle}</span>
            </Row>
            <Row className="mpos-name" style={{ marginLeft: 16 }}>
              {pageData.mPOSDeviceDescription}
            </Row>
          </Col>
          <Col md={7} span={24}>
            {isChangeForm && (
              <Row className="list-button">
                <FnbButton
                  disabled={IsUnActivated}
                  minWidth={120}
                  text={pageData.button.cancel}
                  variant="tertiary"
                  onClick={onCancel}
                />
                <FnbButton
                  minWidth={120}
                  disabled={disableSaveButton || IsUnActivated}
                  text={pageData.button.save}
                  onClick={onClickAuthenticate}
                />
              </Row>
            )}
          </Col>
        </Row>

        <Row className="pos-merchantIdAndkey" gutter={[24, 24]}>
          <Col span={24} xl={12} className="mb-28">
            <Form.Item
              label={pageData.merchantId}
              name={"merchantId"}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.requiredField} />,
                },
              ]}
            >
              <FnbInputNumber
                showCount
                maxLength={100}
                placeholder={pageData.enterMerchantId}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={onValidForm}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={IsUnActivated}
              />
            </Form.Item>
          </Col>
          <Col span={24} xl={12} className="mb-28">
            <Form.Item
              label={pageData.secretKeyMPOS}
              name={"secretKey"}
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.requiredField} />,
                },
              ]}
            >
              <FnbInput
                type={TYPE_INPUT.PASSWORD}
                placeholder={pageData.enterSecretKeyMPOS}
                onChange={onValidForm}
                disabled={IsUnActivated}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mpos-config__row-description">
          <Col>
            <StoreGeneralConfigInfoCircleIcon className="mpos-config__icon-info" width={16} height={16} />
          </Col>
          <Col className="mpos-config__col-text">
            <span>{pageData.provideYourAccountOnTheMPOS}</span>
          </Col>
        </Row>

        <Row className="pos-component mpos-dynamic">
          <Form.List name="mposMachines">
            {(fields, { add, remove }) => {
              return (
                <React.Fragment>
                  <Row
                    className="mpos__row-add-btn"
                    style={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}
                  >
                    <div className="switch-title">
                      <div className="mpos-name">
                        <StoreGeneralConfigInfoCircleIcon width={16} height={16} />
                        {pageData.mPOSSubTitle}
                      </div>
                    </div>
                    <FnbButton
                      disabled={disableAddMachine || IsUnActivated}
                      text={pageData.btnAddMPOSMachine}
                      onClick={() => {
                        add();
                        setDisableSaveButton(true);
                        setIsChangeForm(true);
                      }}
                    />
                  </Row>
                  {fields.map((field, index) => {
                    let defaultBranchValue = form.getFieldValue(["mposMachines", index, "branchId"]);
                    let previousValue = defaultBranchValue;
                    return (
                      <React.Fragment>
                        <Row className="mpos__row-form--hidden">
                          <Col span={24} style={{ position: "absolute" }}>
                            <Form.Item name={[index, "id"]}>
                              <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name={[index, "index"]}>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="mpos__row-form--dynamic" gutter={[16, 26]} key={field.id}>
                          <Col md={11} span={24}>
                            <Form.Item
                              label={pageData.branch}
                              name={[index, "branchId"]}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <InputValidateMessage
                                      type={EnumInputValidateType.ERROR}
                                      message={pageData.branchValidateMessage}
                                    />
                                  ),
                                },
                              ]}
                            >
                              <FnbSelectSingle
                                placeholder={pageData.branchPlaceholder}
                                onClear={() => handleOnClearSelect(previousValue)}
                                allowClear
                                option={branches?.map((item) => ({
                                  id: item.id,
                                  name: item.name,
                                  disabled: item.disabled,
                                }))}
                                onSelect={(value) => {
                                  const newSelectBranch = [...selectBranchs, value].filter(
                                    (branch) => branch !== previousValue,
                                  );
                                  setSelectBranchs(newSelectBranch);
                                }}
                                onChange={onValidForm}
                                disabled={IsUnActivated}
                              ></FnbSelectSingle>
                            </Form.Item>
                          </Col>
                          <Col md={11} span={24}>
                            <Form.Item
                              label={pageData.mPOSDevice}
                              name={[index, "mposDeviceId"]}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <InputValidateMessage
                                      type={EnumInputValidateType.ERROR}
                                      message={pageData.mPOSDeviceValidateMessage}
                                    />
                                  ),
                                },
                                {
                                  validator: (_, value) => validateMPOSDeviceId(value),
                                },
                              ]}
                            >
                              <FnbInput
                                showCount
                                maxLength={255}
                                placeholder={pageData.enterMPOSDevice}
                                onChange={onValidForm}
                                disabled={IsUnActivated}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={11} span={24}>
                            <Form.Item
                              label={pageData.userName}
                              name={[index, "username"]}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <InputValidateMessage
                                      type={EnumInputValidateType.ERROR}
                                      message={pageData.requiredField}
                                    />
                                  ),
                                },
                              ]}
                            >
                              <FnbInput
                                showCount
                                maxLength={50}
                                placeholder={pageData.userNamePlaceHolder}
                                onChange={onValidForm}
                                disabled={IsUnActivated}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={11} span={24}>
                            <Form.Item
                              label={pageData.password}
                              name={[index, "password"]}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <InputValidateMessage
                                      type={EnumInputValidateType.ERROR}
                                      message={pageData.requiredField}
                                    />
                                  ),
                                },
                                {
                                  min: 6,
                                  message: (
                                    <InputValidateMessage
                                      type={EnumInputValidateType.ERROR}
                                      message={pageData.passWordIsTooShort}
                                    />
                                  ),
                                },
                              ]}
                            >
                              <FnbInput
                                showCount
                                maxLength={20}
                                type="password"
                                placeholder={pageData.passwordPlaceholder}
                                onChange={onValidForm}
                                disabled={IsUnActivated}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={2} span={24} className="action-list">
                            {index !== 0 && !IsUnActivated && (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                className="icon-delete-price"
                                onClick={() => {
                                  const deletedBranch = form.getFieldValue(["mposMachines", index, "branchId"]);
                                  const newSelectBranch = selectBranchs.filter((branch) => branch !== deletedBranch);
                                  setSelectBranchs(newSelectBranch);
                                  remove(field.name);
                                  handleOnClearSelect(previousValue);
                                  setDisableSaveButton(false);
                                  onValidForm(false);
                                }}
                              >
                                <FnbDeleteIcon width={24} height={24} />
                              </a>
                            )}
                          </Col>
                        </Row>
                        {isScreenXL && index !== fields.length - 1 && <div className="hr-row"></div>}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            }}
          </Form.List>
        </Row>
        <Form.Item name="id">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="enumId" initialValue={paymentMethod.mPos}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="paymentMethodId">
          <Input type="hidden" />
        </Form.Item>
      </Form>
      <Row className="w-100 mpos-info">
        <div className="item-info-mpos item-info-mpos__website">
          <ChromeOutlined className="icon-bottom" />
          {
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={pageData.linkWebsite} target="_blank">
              {pageData.website}
            </a>
          }
        </div>
        <div className="item-info-mpos item-info-mpos__email">
          <MessagesOutlined className="icon-bottom" />
          <a href={`mailto:${pageData.email}`}>{pageData.email}</a>
        </div>
        <div className="item-info-mpos item-info-mpos__phone">
          <CallOutlined className="icon-bottom" />
          <a href={`tel:${pageData.hotline}`}>{pageData.hotline}</a>
        </div>
      </Row>
    </>
  );
}
