import http from "utils/http-common";
import qs from "query-string";

const controller = "reservetable";

const getReserveTablesAsync = (params) => {
  return http.get(`/${controller}`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const getReserveTableAsync = (id) => {
  return http.get(`/${controller}/${id}`);
};

const updateReserveTableStatusAsync = (data) => {
  return http.put(`/${controller}/update-status`, data);
};

const getListReservationTablesAsync = (data) => {
  return http.post(`/${controller}/area-areatable`, data);
};

const updateReservationTablesAsync = (data) => {
  return http.put(`/${controller}/update`, data);
};

const reserveTableDataService = {
  getReserveTablesAsync,
  getReserveTableAsync,
  updateReserveTableStatusAsync,
  getListReservationTablesAsync,
  updateReservationTablesAsync,
};

export default reserveTableDataService;
