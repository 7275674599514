import http, { downloadAsync } from "../../utils/http-common";
import qs from "query-string";

const controller = "report";

const getTopCustomerReportAsync = (data) => {
  return http.get(
    `/${controller}/get-top-customer-report?branchId=${data.branchId}&startDate=${data.startDate}&endDate=${data.endDate}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&keySearch=${data.keySearch}&sortNo=${data.sortNo}&sortCustomerName=${data.sortCustomerName}&sortOrderNumber=${data.sortOrderNumber}&sortTotalAmount=${data.sortTotalAmount}`,
  );
};

const getTopSellingProductAsync = (data) => {
  return http.get(
    `/${controller}/get-top-selling-product?branchId=${data.branchId}&startDate=${data.startDate}&endDate=${
      data.endDate
    }&pageNumber=${data.pageNumber ?? ""}&pageSize=${data.pageSize ?? ""}&type=${data.type ?? ""}`,
  );
};

const getOrderDetailTransactionReportAsync = (data) => {
  if (data.customerID)
    return http.get(
      `/${controller}/get-order-detail-transaction-report?orderId=${data.orderID}&customerID=${data.customerID}&type=${
        data.type ?? ""
      }`,
    );
  return http.get(`/${controller}/get-order-detail-transaction-report?orderId=${data.orderID}&type=${data.type ?? ""}`);
};

const exportSoldProductsAsync = (data) => {
  return downloadAsync(
    `/${controller}/export-sold-products?branchId=${data.branchId}&startDate=${data.startDate}&endDate=${data.endDate}&languageCode=${data.languageCode}&timeZone=${data.timeZone}`,
  );
};

const exportShiftDetailAsync = (data, progressCallBack) => {
  return downloadAsync(
    `/${controller}/shift-detail/export?shiftId=${data.shiftId}&languageCode=${data.languageCode}&timeZone=${data.timeZone}`,
    progressCallBack,
  );
};

const exportRevenueAsync = (req) => {
  let url = `/${controller}/export-revenue?languageCode=${req.languageCode}`;
  url += `&branchId=${req.branchId}`;
  url += `&startDate=${req.startDate}`;
  url += `&endDate=${req.endDate}`;
  url += `&BusinessSummaryWidgetFilter=${req.typeOptionDate}`;
  url += `&type=${req.type}`;
  url += `&segmentTimeOption=${req.segmentTimeOption}`;
  url += `&timeZone=${req.timeZone}`;

  return downloadAsync(url);
};

const exportShiftAsync = (req) => {
  return downloadAsync(
    `/${controller}/shift/export?branchId=${req.branchId}&startDate=${req.startDate}&endDate=${req.endDate}&languageCode=${req.languageCode}&BusinessSummaryWidgetFilter=${req.typeOptionDate}&timeZone=${req.timeZone}`,
  );
};

const getShiftsSummaryAsync = (data) => {
  return http.get(
    `/${controller}/shift/summary?branchId=${data.branchId}&startDate=${data.startDate}&endDate=${data.endDate}&timeZone=${data.timeZone}&businessSummaryWidgetFilter=${data.typeOptionDate}`,
  );
};

const getShiftsAsync = (req) => {
  return http.get(
    `/${controller}/shift?branchId=${req.branchId}&startDate=${req.startDate}&endDate=${req.endDate}&pageNumber=${
      req.pageNumber ?? ""
    }&pageSize=${req.pageSize ?? ""}`,
  );
};

const getStaffRevenueAsync = (data) => {
  let api = `/${controller}/staff-revenue`;
  let body = {
    pageNumber: data.pageNumber ?? "",
    pageSize: data.pageSize ?? "",
    branchId: data.branchId,
    startDate: data.startDate,
    endDate: data.endDate,
    timeZone: data.timeZone,
    businessSummaryWidgetFilter: data.typeOptionDate,
    revenueRange: {
      from: data?.revenueRange?.from ?? null,
      to: data?.revenueRange?.to ?? null,
    },
    OrderQuantityRange: {
      from: data?.orderQuantityRange?.from ?? null,
      to: data?.orderQuantityRange?.to ?? null,
    },
    staffIds: data.staffIds ?? [],
    groupPermissionIds: data.groupPermissionIds ?? [],
  };
  return http.post(api, body);
};

const getStaffSummaryAsync = (params) => {
  return http.get(`/${controller}/staff/summary`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const getStaffRevenueChartAsync = (params) => {
  return http.get(`/${controller}/staff-revenue/chart-data`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const exportStaffRevenueAsync = (req) => {
  return downloadAsync(
    `/${controller}/staff/export?branchId=${req.branchId}&startDate=${req.startDate}&endDate=${req.endDate}&languageCode=${req.languageCode}&BusinessSummaryWidgetFilter=${req.businessSummaryWidgetFilter}&timeZone=${req.timeZone}`,
  );
};

const getStaffRevenuesDetailAsync = (params) => {
  return http.get(`/${controller}/staff-revenue/detail`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
  });
};

const exportStaffDetailRevenueAsync = (req) => {
  return downloadAsync(
    `/${controller}/staff-detail/export?accountId=${req.accountId}&branchId=${req.branchId}&orderCode=${req.orderCode}&startDate=${req.startDate}&endDate=${req.endDate}&languageCode=${req.languageCode}&BusinessSummaryWidgetFilter=${req.businessSummaryWidgetFilter}&timeZone=${req.timeZone}`,
  );
};

const exportOrdersAsync = (req) => {
  return downloadAsync(
    `/${controller}/order/export?branchId=${req.branchId}&startDate=${req.startDate}&endDate=${req.endDate}&languageCode=${req.languageCode}&BusinessSummaryWidgetFilter=${req.typeOptionDate}&timeZone=${req.timeZone}`,
  );
};

const reportDataService = {
  getShiftsSummaryAsync,
  getTopCustomerReportAsync,
  getTopSellingProductAsync,
  getOrderDetailTransactionReportAsync,
  exportSoldProductsAsync,
  exportRevenueAsync,
  exportShiftDetailAsync,
  exportShiftAsync,
  getShiftsAsync,
  getStaffRevenueAsync,
  getStaffSummaryAsync,
  getStaffRevenueChartAsync,
  exportStaffRevenueAsync,
  getStaffRevenuesDetailAsync,
  exportStaffDetailRevenueAsync,
  exportOrdersAsync,
};
export default reportDataService;
