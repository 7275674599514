import { message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PermissionGroup from "./permission-group";
import Staff from "./staff/staff.page";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

const KEY_TAB_PANE = {
  STAFF: "staff",
  PERMISSION_GROUP: "permission-group",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.STAFF]: 1,
  [KEY_TAB_PANE.PERMISSION_GROUP]: 2,
};

export default function StaffManagement(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeScreen, setActiveScreen] = useState(KEY_TAB_PANE.STAFF);

  const pageData = {
    title: t("settings.tabStaffManagement"),
    btnAddNew: t("button.addNew"),
    staff: {
      title: t("settings.tabStaff"),
    },
    permissionGroup: {
      title: t("settings.tabPermissionGroup"),
    },
  };

  const renderHeadingPage = (activeKey) => {
    switch (activeKey) {
      case KEY_TAB_PANE.PERMISSION_GROUP:
        return (
          <FnbHeadingPage
            title={pageData.title}
            onCreate={() => history.push("/permission-group/create-new")}
            textAddNew={pageData.btnAddNew}
          />
        );
      default:
        return (
          <FnbHeadingPage
            title={pageData.title}
            onCreate={() => history.push("/staff/create-new")}
            textAddNew={pageData.btnAddNew}
          />
        );
    }
  };

  const screens = [
    {
      name: pageData.staff.title,
      key: KEY_TAB_PANE.STAFF,
      component: <Staff screenKey={INDEX_KEY_TAB_PANE[KEY_TAB_PANE.STAFF]} />,
    },
    {
      name: pageData.permissionGroup.title,
      key: KEY_TAB_PANE.PERMISSION_GROUP,
      component: <PermissionGroup screenKey={INDEX_KEY_TAB_PANE[KEY_TAB_PANE.PERMISSION_GROUP]} />,
    },
  ];

  const changeTab = (activeKey) => {
    message.destroy();
    setActiveScreen(activeKey);
  };

  return (
    <>
      {renderHeadingPage(activeScreen)}
      <FnbTabPane
        screens={screens}
        activeScreen={activeScreen}
        indexKeyTabPane={INDEX_KEY_TAB_PANE}
        onChange={changeTab}
        defaultScreen={KEY_TAB_PANE.STAFF}
        firstKeyTabPane={KEY_TAB_PANE.STAFF}
        lastKeyTabPane={KEY_TAB_PANE.PERMISSION_GROUP}
      />
    </>
  );
}
