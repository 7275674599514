import { Button, Col, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./delete-material.component.scss";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle, ConfirmViewActiveItemStyle } from "constants/level-menu.constants";
import { useEffect, useState } from "react";
// TODO
export default function DeleteMaterial(props) {
  const [t] = useTranslation();
  const { infoMaterial, isModalVisible, handleCancel, onDelete } = props;
  const [activeItems, setActiveItems] = useState([]);
  const linkPO = "/inventory/detail-purchase-order/";
  const pageData = {
    ignore: t("button.ignore"),
    delete: t("button.delete"),
    deleteMaterialNotificationMessage: t("messages.deleteMaterialNotificationMessage"),
    deleteMaterialMessageNew: t("messages.deleteMaterialMessageNew"),
  };

  useEffect(() => {
    setActiveItems(
      infoMaterial?.purchaseOrders?.map((item) => {
        const label = item?.code;
        const link = `${linkPO}${item?.id}`;
        return { label, link };
      }),
    );
  }, [infoMaterial]);

  const formatNotificationMessage = (name) => {
    let mess = t(pageData.deleteMaterialNotificationMessage, { name: name });
    return mess;
  };

  const formatDeleteMessage = (name) => {
    let mess = t(pageData.deleteMaterialMessageNew, { name });
    return mess;
  };

  return (
    <>
      {infoMaterial.isOpenPurchaseOrder ? (
        <ConfirmDialogComponent
          width={500}
          type={ConfirmStyle.NOTIFICATION}
          activeItems={activeItems}
          onOk={handleCancel}
          visible={isModalVisible}
          typeViewActiveItems={ConfirmViewActiveItemStyle.GRID}
          content={formatNotificationMessage(infoMaterial?.material?.name)}
        />
      ) : (
        <ConfirmDialogComponent
          type={ConfirmStyle.DELETE}
          onCancel={handleCancel}
          onOk={() => onDelete(infoMaterial?.material?.id, infoMaterial?.material?.name)}
          visible={isModalVisible}
          content={formatDeleteMessage(infoMaterial?.material?.name)}
        />
      )}
    </>
  );
}
