import { Table } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import FnbCard from "components/fnb-card/fnb-card.component";
import { MemoizedFnbListChips } from "components/fnb-list-chips/fnb-list-chips.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { CrownIcon } from "constants/icons.constants";
import { languageCode } from "constants/language.constants";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import "./branch-kitchen.component.scss";

function BranchKitchenComponent(props) {
  const { data } = props;
  const [t] = useTranslation();
  const pageData = {
    kitchenName: t("kitchen.kitchenName"),
    productCategory: t("kitchen.productCategory"),
    default: t("kitchen.default"),
    defaultKitchenContent1: t("kitchen.defaultKitchenContent1"),
    defaultKitchenContent2: t("kitchen.defaultKitchenContent2"),
    defaultKitchenContent3: t("kitchen.defaultKitchenContent3"),
    kitchen: t("menu.storeManagement.kitchen"),
  };

  const isMobile = useMediaQuery({ maxWidth: 575 });

  const renderTooltipContent = () => {
    return (
      <div className="div-tooltip-default-kitchen-content">
        <div className="">{parse(pageData.defaultKitchenContent1)}</div>
        <div className="spacing-tooltip-content">{parse(pageData.defaultKitchenContent2)}</div>
        <div className="spacing-tooltip-content">{parse(pageData.defaultKitchenContent3)}</div>
      </div>
    );
  };

  const getTextLocalize = (localizationKey, text) => {
    const localizations = [t(localizationKey, { lng: languageCode.vi }), t(localizationKey, { lng: languageCode.en })];
    return localizations?.includes(text.replace(/<[^>]*>/g, "").replace("&nbsp;", "")) ? t(localizationKey) : text;
  };

  const getColumns = () => {
    const columns = [
      {
        title: pageData.kitchenName,
        dataIndex: "name",
        ellipsis: true,
        render: (_, record) => (
          <div className="container-column-kitchen-name">
            <div className="row-data-kitchen-name">
              <Paragraph
                style={{ maxWidth: "inherit" }}
                placement="top"
                ellipsis={{ tooltip: record?.isDefault ? getTextLocalize("kitchen.defaultKitchen", record?.name) : record?.name }}
                color="#50429B"
              >
                <span>{record?.isDefault ? getTextLocalize("kitchen.defaultKitchen", record?.name) : record?.name}</span>
              </Paragraph>
              {record?.isDefault && (
                <div className="div-default-content">
                  <FnbTooltip title={renderTooltipContent()} placement="bottomLeft" variant="secondary" maxWidth={400}>
                    <div className="div-row-default-kitchen-content">
                      <div>
                        <CrownIcon width={20} height={20} />
                      </div>
                      <div>{pageData.default}</div>
                    </div>
                  </FnbTooltip>
                </div>
              )}
            </div>
            {record?.description && (
              <FnbTooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: record?.isDefault
                        ? getTextLocalize("kitchen.defaultKitchenDescription", record?.description)
                        : record?.description?.replace(/<.*?>/gm, ""),
                    }}
                  />
                }
              >
                <div
                  className="kitchen-item-description"
                  dangerouslySetInnerHTML={{
                    __html: record?.isDefault
                      ? getTextLocalize("kitchen.defaultKitchenDescription", record?.description)
                      : record?.description?.replace(/<.*?>/gm, ""),
                  }}
                />
              </FnbTooltip>
            )}
          </div>
        ),
      },
      {
        title: pageData.productCategory,
        dataIndex: "kitchenProductCategories",
        ellipsis: true,
        render: (_, record) => {
          const data = [];
          record?.kitchenProductCategories?.forEach((kitchenProductCategory) => {
            let text = kitchenProductCategory?.name;
            const item = {
              title: text,
              detail: text,
            };
            data.push(item);
          });
          return <MemoizedFnbListChips data={data} isOptionChip={false} />;
        },
      },
    ];

    return columns;
  };

  const propertyScroll = isMobile ? { x: "auto" } : {};

  return (
    <FnbCard className="branch-kitchen-table" title={pageData.kitchen} underlineTitle={true}>
      <Table columns={getColumns()} dataSource={data} pagination={false} scroll={propertyScroll} />
    </FnbCard>
  );
}

export default BranchKitchenComponent;
