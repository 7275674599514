export const claimTypesConstants = {
  id: "ID",
  accountId: "ACCOUNT_ID",
  accountType: "ACCOUNT_TYPE",
  permissions: "PERMISSIONS",
  fullName: "FULL_NAME",
  phoneNumber: "PHONE_NUMBER",
  email: "EMAIL",
  currencyCode: "CURRENCY_CODE",
  storeId: "STORE_ID",
  currencySymbol: "CURRENCY_SYMBOL",
  storeType: "STORE_TYPE",
};
