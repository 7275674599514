import { Form, message } from "antd";
import Logo from "assets/images/go-fnb-login-logo.png";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { EMAIL_REGEX } from "constants/default.constants";
import { AlertIcon, ForbiddenIcon, SmsOutlinedIcon, TickCircle2Icon } from "constants/icons.constants";
import { Region } from "constants/region.constants";
import resetPasswordDataService from "data-services/reset-password/reset-password-data.service";
import { env } from "env";
import parse from "html-react-parser";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authSelector, languageCodeSelector } from "store/modules/session/session.reducers";
import Countdown from "./components/countdown";
import { ButtonGroup, Container, Errors, SendingStatus, Title, Wrapper, WrapperForm, WrapperLogo } from "./styled";

export const StepEnum = {
  ResetForm: 1,
  NotificationForm: 2,
};

const ForgotPasswordPage = (props) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [step, setStep] = useState(StepEnum.ResetForm);
  const [disabled, setDisabled] = useState(true);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [expired, setExpired] = useState("");
  const auth = useSelector(authSelector);
  const languageCode = useSelector(languageCodeSelector);
  const timeoutInput = useRef(null);
  const isInternational = env.REACT_APP_REGION === Region.International;

  const pageData = {
    forgotPassword: t("signIn.forgotPassword"),
    validateMessage: t("signIn.username.validateMessage"),
    pleaseEnterValidEmailAddress: t("messages.pleaseEnterValidEmailAddress"),
    title: t("forgotPassword.title"),
    sendResetLink: t("forgotPassword.sendResetLink"),
    backToLogin: t("forgotPassword.backToLogin"),
    enterYourEmail: t("forgotPassword.enterYourEmail"),
    emailNotExist: t("forgotPassword.emailNotExist"),
    registerNewAccount: t("forgotPassword.registerNewAccount"),
    resetLinkSent: t("forgotPassword.resetLinkSent"),
    alert: t("forgotPassword.alert"),
    emailNull: t("forgotPassword.emailNull"),
    passwordResetFailed: t("forgotPassword.passwordResetFailed"),
  };

  useEffect(() => {
    return () => {
      setDisabled(true);
      setInvalidEmail(false);
      setInvalidToken(false);
      setStep(StepEnum.ResetForm);
    };
  }, []);

  useLayoutEffect(() => {
    if (auth?.token && auth?.user) {
      props.history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const onChange = async (event) => {
    if (timeoutInput.current) {
      clearTimeout(timeoutInput.current);
    }
    timeoutInput.current = setTimeout(() => {
      setDisabled(!event.target.value);
      setInvalidEmail(false);
      form.validateFields();
    }, 300);
  };

  const handleBackToLogin = () => {
    props.history.push("/login");
  };

  const handleRegisterAccount = () => {
    props.history.push("/register-account");
  };

  const onFinish = async (values) => {
    const { email } = values;
    resetPasswordDataService
      .sendMailResetPasswordAsync({ email, languageCode, isInternational })
      .then((res) => {
        if (res?.succeeded && res?.data) {
          const { isEmailValid, isTokenValid, expiredTime } = res?.data;
          // Check exist email
          setInvalidEmail(!isEmailValid);
          form.validateFields();
          if (!isEmailValid) return;

          // Check expired request
          setInvalidToken(!isTokenValid);
          setExpired(expiredTime);

          // Next step
          setStep(StepEnum.NotificationForm);
        } else {
          message.error(t(res?.message || "forgotPassword.passwordResetFailed"));
        }
      })
      .catch(() => {
        message.error(pageData.passwordResetFailed);
      });
  };

  const ResetForm = useMemo(() => {
    return (
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Title>
          <p>{pageData.forgotPassword}</p>
          <span>{pageData.title}</span>
        </Title>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: (
                <InputValidateMessage
                  type={EnumInputValidateType.ERROR}
                  message={t("signIn.username.validateMessage")}
                />
              ),
            },
            {
              pattern: EMAIL_REGEX,
              message: (
                <InputValidateMessage
                  type={EnumInputValidateType.ERROR}
                  message={t("messages.pleaseEnterValidEmailAddress")}
                />
              ),
            },
            {
              validator: (_, value) => {
                if (value && EMAIL_REGEX.test(value) && invalidEmail) {
                  return Promise.reject(
                    <Errors>
                      <ForbiddenIcon />
                      <span>{`${pageData.emailNotExist} `}</span>
                      <span className="register-account" onClick={handleRegisterAccount}>
                        {pageData.registerNewAccount}
                      </span>
                    </Errors>,
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <FnbInput prefix={<SmsOutlinedIcon />} placeholder={pageData.enterYourEmail} onChange={onChange} />
        </Form.Item>
        <ButtonGroup>
          <FnbButton type="submit" text={pageData.sendResetLink} width={"100%"} disabled={disabled} />
          <FnbButton variant="tertiary" text={pageData.backToLogin} width={"100%"} onClick={handleBackToLogin} />
        </ButtonGroup>
      </Form>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, disabled, invalidEmail]);

  const NotificationForm = useMemo(() => {
    return (
      <div className="sent-form">
        <SendingStatus invalidToken={invalidToken}>
          {invalidToken ? <AlertIcon /> : <TickCircle2Icon />}
          {invalidToken ? pageData.alert : pageData.resetLinkSent}
        </SendingStatus>
        <div>{parse(t("forgotPassword.anEmailHasBeenSent", { email: form.getFieldValue("email") }))}</div>
        <div className="expired-time" style={{ fontWeight: invalidToken ? 700 : 400 }}>
          <Countdown expired={expired} onExpired={handleBackToLogin} />
        </div>
        <FnbButton variant="tertiary" text={pageData.backToLogin} width={"100%"} onClick={handleBackToLogin} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, invalidToken, expired]);

  const renderForm = useMemo(() => {
    switch (step) {
      case StepEnum.ResetForm:
        return ResetForm;
      case StepEnum.NotificationForm:
        return NotificationForm;
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, step, disabled, invalidEmail, invalidToken, expired]);

  return (
    <Container>
      <Wrapper>
        <WrapperLogo>
          <img src={Logo} alt="logo" />
        </WrapperLogo>
        <WrapperForm>{renderForm}</WrapperForm>
      </Wrapper>
    </Container>
  );
};

export default ForgotPasswordPage;
