import { Image, Layout, Menu } from "antd";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import {
  ArrowDownOutlined,
  CpuSettingOutlined,
  FastArrowLeftOutlined,
  FastArrowRightOutlined,
} from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { RouteKeyConstants } from "constants/route-key-path.constants";
import { RouteKey } from "constants/route.constants";
import { DefaultConstants } from "constants/string.constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { compose } from "redux";
import { store } from "store";
import { hasPermission, isStoreInternational, sortChildRoute } from "utils/helpers";
import goFnbLogoCollapse from "../../assets/images/go-fnb-login-logo.png";
import goFnbLogo from "../../assets/images/go-fnb-logo-header.png";
import "./index.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

function SideMenu(props) {
  const { t, menuItems, route, isChild, parentKey, onCollapsed } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [currentSubMenuKeys, setCurrentSubMenuKeys] = useState([]);
  const [hasPermissionAdmin] = useState(hasPermission(PermissionKeys.ADMIN));
  const storeInformation = useSelector((state) => state?.session?.informationPublishStore);
  const history = useHistory();
  const isInternational = isStoreInternational();

  useEffect(() => {
    if (route.focus) {
      setSelectedKey(route.focus);
    } else {
      setSelectedKey(route.key);
    }

    if (isChild) {
      setCurrentSubMenuKeys([parentKey]);
    }
  }, []);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    onCollapsed && onCollapsed(collapsed);
  };

  const onOpenChange = (items) => {
    const latestOpenKey = items.find((key) => currentSubMenuKeys.indexOf(key) === -1);
    setCurrentSubMenuKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const onFocusFirstItem = (childs) => {
    if (childs?.length > 0) {
      childs = sortChildRoute(childs).sort((a, b) => {
        return a.position - b.position;
      });
      for (let child of childs) {
        if (hasPermission(child.permission) === true) {
          history.push(child.path);
          break;
        }
      }
    }
  };

  const renderMenusItems = () => {
    const { session } = store.getState();
    const { user } = session?.auth;

    let currentMenuItems = menuItems;

    /// Check if user not purchase web package or app package
    if (
      (user?.accountType === DefaultConstants.ADMIN_ACCOUNT || user?.accountType === DefaultConstants.STAFF_ACCOUNT) &&
      !hasPermission(PermissionKeys.ONLINE_STORE_MENU)
    ) {
      currentMenuItems = currentMenuItems?.filter((item) => item.key !== RouteKey.ONLINE_STORE);
    }

    if (isInternational) {
      currentMenuItems = currentMenuItems?.filter((item) => item.key !== RouteKey.SOCIAL_CHANNEL);
    }

    const html = currentMenuItems?.map((item) => {
      if (item.child && item.child.length > 0) {
        let childs = item.child;
        if (isInternational) {
          childs = childs?.filter(
            (child) =>
              child?.key !== "app.marketing.create-notification-campaign" &&
              child?.key !== "app.marketing.notificationCampaign",
          );
        }
        let isAccess = false;
        childs.forEach((child) => {
          if (hasPermission(child.permission) === true) {
            isAccess = true;
          }
        });
        if (isAccess === true) {
          return (
            <>
              <SubMenu
                onTitleClick={() => onFocusFirstItem(childs)}
                key={item.key}
                icon={item.icon}
                title={t(item.name)}
              >
                {childs.map((child) => {
                  var isShow = child?.permission && hasPermission(child.permission);
                  if (child.isMenu === true && isShow === true) {
                    if (
                      child.key === RouteKeyConstants.kitchenManagement &&
                      !Boolean(storeInformation?.isStoreHasKitchen)
                    ) {
                      return null;
                    }
                    return (
                      <Menu.Item style={{ paddingLeft: "0px !important" }} key={child.key}>
                        <Link to={child.path} />
                        {t(child.name)}
                      </Menu.Item>
                    );
                  }
                })}
              </SubMenu>
            </>
          );
        }
      } else {
        var isShow = item?.permission && hasPermission(item.permission);
        /// If item is menu, then check if it has permission
        if (item.isMenu === true && isShow === true) {
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path} />
              {t(item.name)}
            </Menu.Item>
          );
        } else if (!item?.permission && user?.accountType === DefaultConstants.ADMIN_ACCOUNT) {
          /// If item is menu, then check if it has not permission
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path} />
              {t(item.name)}
            </Menu.Item>
          );
        }
      }
    });
    return html;
  };

  const handleClickSettingNavigate = () => {
    let header = document.getElementById("header");
    if (header.classList.contains("expand-header")) {
      header.classList.remove("expand-header");
      header.classList.add("collapse-header");
    } else {
      header.classList.add("expand-header");
      header.classList.remove("collapse-header");
    }
  };

  const CustomTrigger = () => (
    <div className="trigger-footer">
      <div
        className={`wrapper-settings ${
          hasPermissionAdmin ? "wrapper-settings--default" : "wrapper-settings--disabled"
        }`}
      >
        <FnbTooltip
          variant="secondary"
          hideTooltip={hasPermissionAdmin}
          title={
            <div
              className="text-default-tooltip"
              dangerouslySetInnerHTML={{
                __html: t("menu.configurationNotPermission"),
              }}
            ></div>
          }
          placement="topLeft"
        >
          <NavLink
            to="/config"
            className="settings"
            onClick={(e) => {
              if (!hasPermissionAdmin) e.preventDefault();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !hasPermissionAdmin) e.preventDefault();
            }}
          >
            <div className="icon-setting">
              <CpuSettingOutlined />
            </div>
            <span className="title-setting">{t("menu.configuration")}</span>
          </NavLink>
        </FnbTooltip>
      </div>

      <div onClick={handleClickSettingNavigate} className="icon-navigate">
        <div className="icon-expand">
          <FastArrowRightOutlined />
        </div>
        <div className="icon-collapse">
          <FastArrowLeftOutlined />
        </div>
      </div>
    </div>
  );

  return (
    <Sider
      className="sider-wrapper"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={<CustomTrigger />}
    >
      <div className="bg-logo">
        <Image id="logo-img" preview={false} src={collapsed ? goFnbLogoCollapse : goFnbLogo} />
      </div>
      <div className="menu">
        <Menu
          selectedKeys={[selectedKey]}
          openKeys={currentSubMenuKeys}
          mode="inline"
          onOpenChange={(e) => onOpenChange(e)}
          expandIcon={({ isOpen }) => {
            return (
              <div
                className={isOpen ? "menu-expand-icon" : "menu-collapse-icon"}
                style={{ display: collapsed ? "none" : "" }}
              >
                <ArrowDownOutlined />
              </div>
            );
          }}
        >
          {renderMenusItems()}
        </Menu>
      </div>
    </Sider>
  );
}

export default compose(withRouter)(SideMenu);
