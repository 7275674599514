import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import checkoutSuccess from "../../../assets/icons/checkout-successfully.svg";
import "./checkout-momo-success.scss";
export default function CheckOutMomoSuccess(props) {
  const { t } = useTranslation();
  const pageData = {
    orderNo: t("order.orderNo", "Order No"),
    orderIsCreated: t("order.orderIsCreated", "Your order is created successful"),
    congratulation: t("order.congratulation", "Congratulation"),
    close: t("form.close", "Close"),
    viewOrder: t("order.viewOrder", "View order detail"),
    newOrder: t("order.newOrder", "Create new order"),
  };

  const { visible, onCancel, onViewDetail, onCreateNewOrder, orderCode, translateData = pageData, className } = props;

  const divContent = (
    <>
      <div className="check-out-success-box">
        <div className="success-box-detail">
          <img className="checkoutSuccess" src={checkoutSuccess} alt={orderCode} />
          <div className="title1">{pageData.congratulation}</div>
          <div className="title2">{translateData.orderIsCreated}</div>
        </div>
        <div className="success-box-footer-button">
          <div className="view_detail" onClick={onViewDetail}>
            {translateData.viewOrder}
          </div>
          <div className="new_order" onClick={onCreateNewOrder}>
            {translateData.newOrder}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <Modal
      closable={true}
      className={`check-out-momo-success-theme1 ${className}`}
      open={visible}
      onCancel={onCancel}
      footer={(null, null)}
      title=""
    >
      {divContent}
    </Modal>
  );
}
