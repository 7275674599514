import orderPackagePaymentTransactionService from "data-services/order-package-payment-transaction/order-package-payment-transaction.service";
import paymentDataService from "data-services/payment/payment-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PaymentStatus = () => {
  const [countDown, setCountDown] = useState(3);
  const [t] = useTranslation();
  const [status, setStatus] = useState("");
  const [deepLink, setDeepLink] = useState("");

  useEffect(() => {
    checkPayment();
  }, []);

  const translateData = {
    paymentSuccess: t("package.payment.paymentSuccessful"),
    paymentFailed: t("package.payment.paymentFailed"),
    pagePaymentNotify: t("package.payment.pagePaymentNotify"),
  };

  const checkPayment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams?.get("token");
    const payerID = urlParams?.get("PayerID");
    const isOrderPaymentFromStoreApp = urlParams?.get("isOrderPaymentFromStoreApp");
    const redirectUrl = urlParams?.get("redirectUrl");
    const orderId = urlParams?.get("orderId");
    const executeRequestModel = {
      token: token ?? "",
      payerId: payerID ?? "",
    };
    if (isOrderPaymentFromStoreApp === "true") {
      let newExecuteRequestModel = {
        ...executeRequestModel,
        redirectUrl: redirectUrl ?? "",
        orderId: orderId ?? "",
      };
      executeOrderPayment(newExecuteRequestModel);
    } else executePayment(executeRequestModel);
  };

  const executePayment = async (request) => {
    try {
      const response = await orderPackagePaymentTransactionService.executePayPalPayment(request);
      if (response.success) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      setInterval(() => {
        setCountDown((prevCount) => prevCount - 1);
      }, 1000);
    } catch (ex) {
      console.log("melog ex: ", ex);
    }
  };

  const executeOrderPayment = async (request) => {
    try {
      const response = await paymentDataService.executePayPalPayment(request);
      if (response) {
        const tmpDeepLink = `${response?.data}?isSuccess=${response.success}&orderId=${request?.orderId}`;
        if (response.success === false) {
          setStatus(false);
          window.location = tmpDeepLink;
        } else {
          setDeepLink(tmpDeepLink);
          setStatus(true);
          setInterval(() => {
            setCountDown((prevCount) => prevCount - 1);
          }, 1000);
        }
      }
    } catch (ex) {
      console.log("melog ex: ", ex);
    }
  };

  useEffect(() => {
    if (countDown === 0) {
      window.close();
      if (deepLink !== null && deepLink?.length > 0) window.location = deepLink;
    }
  }, [countDown]);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
      {status !== "" ? (
        <div
          style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1, flexDirection: "column" }}
        >
          <h1 style={{ color: status ? "green" : "red" }}>
            {status ? translateData.paymentSuccess : translateData.paymentFailed}
          </h1>
          <div>{t("package.payment.pageCloseInSeconds", { countDown })}</div>
        </div>
      ) : (
        <>{translateData.pagePaymentNotify}</>
      )}
    </div>
  );
};

export default PaymentStatus;
