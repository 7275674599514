import { Col, Row } from "antd";
import FnbCard from "components/fnb-card/fnb-card.component";
import "./ingredient-price-unit-conversion.page.scss";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import React, { useEffect, useState } from "react";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import FnbSwitch from "components/switch";
import "./image-other-setting.page.scss";

export const ImageAndOtherSettingMaterial = ({ t, form, hasExpiryDate, imageUrl }) => {
  const imageRef = React.useRef();
  const pageData = {
    ingredientImage: t("material.ingredientImage"),
    otherSettings: t("material.otherSettings"),
    ingredientHasExpiryDate: t("material.ingredientHasExpiryDate"),
    uploadImage: t("button.uploadImage"),
    bestDisplayImage: t("messages.imageBestDisplay"),
    textNonImage: t("messages.acceptableFileType"),
  };
  const [image, setImage] = useState(null);
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setImage({ url: imageUrl });
      imageRef?.current?.setImage(imageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    setIsCheck(hasExpiryDate);
  }, [hasExpiryDate]);

  const onChangeImage = (image) => {
    setImage(image);
    form.setFieldValue("image", image);
  };

  return (
    <Col span={24}>
      <FnbCard className="image-component">
        <Row className="upload-product-image">
          <FnbTypography variant={theme.typography["b1-medium"]} text={pageData.ingredientImage} />
          <Row className={`non-image ${image !== null ? "have-image" : ""}`} style={{ marginTop: 8 }}>
            <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
              <FnbFormItem name={"image"}>
                <FnbCustomUploadImageComponent
                  ref={imageRef}
                  buttonText={pageData.uploadImage}
                  onChange={onChangeImage}
                />
              </FnbFormItem>
            </Col>
            {image !== null && (
              <div className="description-wrapper-have-image">
                <FnbTypography variant="b2-regular" color="#B3B3B3" text={pageData.textNonImage} />
                <FnbTypography variant="b2-regular" color="#B3B3B3" text={pageData.bestDisplayImage} />
              </div>
            )}
          </Row>
        </Row>
      </FnbCard>

      <FnbCard className="mt-16 other-setting-component">
        <FnbFormItem label={pageData.otherSettings} name="hasExpiryDate">
          <FnbSwitch checked={isCheck} onChange={(value) => { setIsCheck(value); form.setFieldValue("hasExpiryDate", value);}}/>
          <FnbTypography text={pageData.ingredientHasExpiryDate} />
        </FnbFormItem>
      </FnbCard>
    </Col>
  );
};
