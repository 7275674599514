import { Button, Col, Form, Row } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MomoResultCode } from "../../../../constants/payment-method.constants";
import areaDataService from "../../../../data-services/area-data.service";
import paymentDataService from "../../../../data-services/payment-data.service";
import reserveTableService from "../../../../data-services/reserve-table-data.service";
import storeBranchWorkingHourDataService from "../../../../data-services/store-branch-working-hour.service";
import { useAppCtx } from "../../../../providers/app.provider";
import { getBranchesByCustomerAddress } from "../../../../services/addressServices";
import { getStoreConfig } from "../../../../utils/helpers";
import { getStorage, localStorageKeys } from "../../../../utils/localStorage.helpers";
import NotificationDialog from "../../../components/notification-dialog/notification-dialog.component";
import { PaymentMethodType } from "../../../constants/payment-method.constants";
import { theme1ElementCustomize } from "../../../constants/store-web-page.constants";
import { DateFormat } from "../../../constants/string.constants";
import CheckOutMomo from "../../checkout/components/checkout-momo";
import CheckOutMomoFailed from "../../checkout/components/checkout-momo-failed";
import CheckOutMomoSuccess from "../../checkout/components/checkout-momo-success";
import CheckOutMomoWeb from "../../checkout/components/checkout-momo-web";
import { areasDefault } from "../default-data";
import ReserveTableContentLeft from "./ReserveTableContentLeft";
import ReserveTableContentRight from "./ReserveTableContentRight";
import { StyledReserveTable } from "./StyledReserveTable";

function ReserveTableContent(props) {
  const { config, general, isCustomize, clickToFocusCustomize } = props;
  const [t] = useTranslation();
  const history = useHistory();

  const translateData = {
    okay: t("form.okay"),
    notification: t("loginPage.notification"),
    reserveTableSuccessfully: t("reserve.reserveTableSuccessfully"),
    reserveTableFailed: t("reserve.reserveTableFailed"),
    pleaseSelectTable: t("reserve.pleaseSelectTable"),
    orderIsCreated: t("reserve.reserveTableSuccessfully"),
    viewOrder: t("reserve.viewDetail"),
    newOrder: t("reserve.reservetable"),
    momoInvalidSignature: t("order.momoInvalidSignature"),
    momoInvalidSignaturMessage: t("order.momoInvalidSignaturMessage"),
  };

  const [formRef] = Form.useForm();
  const storeConfig = getStoreConfig();
  const [reserveTime, setReserveTime] = useState();
  const [reserveDate, setReserveDate] = useState(new Date());
  const [reserveTableData, setReserveTableData] = useState(null);
  const [tableSelected, setTableSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const customerInfo = JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO));
  const [selectTimeByDeliveryDay, setSelectTimeByDeliveryDay] = useState(null);
  const [branchesByCustomerAddress, setBranchesByCustomerAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hotline, setHotline] = useState(null);
  const [workingHour, setWorkingHour] = useState(null);

  // Deposit method Momo
  const [isShowMomo, setIsShowMomo] = useState(false);
  const [isShowPaymentMomoFailed, setIsShowPaymentMomoFailed] = useState(false);
  const [isShowPaymentMomoSuccess, setIsShowPaymentMomoSuccess] = useState(false);
  const [momoQRCodeURL, setMomoQRCodeURL] = useState(false);
  const [momoDeeplink, setMomoDeeplink] = useState(false);
  const [usingMomoWeb, setUsingMomoWeb] = useState(false);
  const [momoError, setMomoError] = useState(null);
  const [reverseTableId, setReverseTableId] = useState(null);
  const nearestStoreBranches = useSelector((state) => state?.session?.nearestStoreBranches);
  const [reverseTableStringCode, setReserveTableStringCode] = useState(null);
  const [responseDataMomo, setResponseDataMomo] = useState(null);
  const [isEmptyDepositMethod, setIsEmptyDepositMethod] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const classNameDepositMomoMethod = useMemo(
    () => (isMobile ? "" : "deposit-momo-method-reservation-table"),
    [isMobile],
  );

  const [branchAddressId, setBranchAddressId] = useState(
    useSelector((state) => state?.session?.deliveryAddress?.branchAddress?.id),
  );
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const storeConfigDeposit = useSelector((state) => state?.session?.storeConfig);
  const [depositMethodEnumId, setDepositMethodEnumId] = useState(
    storeConfigDeposit?.isDeposit ? PaymentMethodType.Momo : "",
  );

  const [isShowDialog, setIsShowDialog] = useState();
  const [contentNotification, setContentNotification] = useState();
  const colorGroupColor = general?.color?.colorGroups?.find((c) => c.id === config?.reservation?.colorGroupId);
  const colorGroup = colorGroupColor
    ? colorGroupColor
    : general?.color?.colorGroups[0]
    ? general?.color?.colorGroups[0]
    : {};
  const gutterRow = isCustomize ? [48, 24] : [24, 24];

  const [isLoadingAreaData, setIsLoadingAreaData] = useState(true);
  const { Toast } = useAppCtx();

  const getNearestStoreBranches = async (deliveryAddress, isNotSelectCustomerAddress) => {
    const lat = deliveryAddress?.receiverAddress?.lat ?? 0;
    const lng = deliveryAddress?.receiverAddress?.lng ?? 0;
    const res = await getBranchesByCustomerAddress(lat, lng, isNotSelectCustomerAddress, nearestStoreBranches);
    if (res) {
      const { branchesByCustomerAddress } = res?.data || {};
      setBranchesByCustomerAddress(branchesByCustomerAddress);
    }
  };

  async function getWorkingHour() {
    const workingHour = await storeBranchWorkingHourDataService.getStoreBranchWorkingHourForReserveTable(
      branchAddressId ?? null,
    );
    if (workingHour) {
      setWorkingHour(workingHour?.data?.storeBranchWorkingHours);
      setHotline(workingHour?.data?.hotline);
    }
  }

  useEffect(() => {
    let timeoutId;
    const callGetNearestStoreBranches = () => {
      if (deliveryAddress?.receiverAddress) {
        getNearestStoreBranches(deliveryAddress, false);
      } else {
        getNearestStoreBranches(deliveryAddress, true);
      }
    };
    timeoutId = setTimeout(() => {
      callGetNearestStoreBranches();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [deliveryAddress]);

  useEffect(() => {
    if (branchAddressId) {
      getWorkingHour();
      getInitData(branchAddressId);
    }
  }, [branchAddressId]);

  // Handle deposit Momo method
  useEffect(() => {
    if (!responseDataMomo) return;
    let intervalGetPaymentStatusID = setInterval(() => {
      const { requestId, orderId, amount } = responseDataMomo?.paymentInfo;
      if (!requestId) return;
      try {
        (async () => {
          const responseData = await paymentDataService.updateReserveTableMomoPayment(
            requestId,
            reverseTableId,
            amount,
          );
          const { moMoResponse } = responseData?.data;
          if (moMoResponse.isSuccess) {
            const { resultCode, message } = moMoResponse;
            if (resultCode > 0) {
              onMomoFailed(message);
            } else {
              onMomoSuccess();
              const { id, code } = responseData?.data?.reserveTableResponse;
              setReverseTableId(id);
              setReserveTableStringCode(code);
            }
            clearInterval(intervalGetPaymentStatusID);
          }
        })();
      } catch (error) {
        console.error("Failed to update payment status:", error);
      }
    }, 5000);

    return () => clearInterval(intervalGetPaymentStatusID);
  }, [responseDataMomo]);

  const getInitData = async (branchId) => {
    setIsLoadingAreaData(true);
    const res = await areaDataService.getDetailAreaAndTableAsync(branchId);
    if (res) {
      setReserveTableData(res?.data?.areas);
    }

    setIsLoadingAreaData(false);
  };

  function formatDateTime(reserveDate, reserveTime) {
    const inputString = moment(reserveDate).format(DateFormat.YYYY_MM_DD_2) + " " + reserveTime;
    const inputDate = moment(inputString, DateFormat.YYYY_MM_DD_HH_MM);
    return inputDate.format(DateFormat.YYYY_MM_DDTHH_mm_ss_SSSZ);
  }

  function adjustDateTimeIfNeeded(values) {
    //const formattedDateTime = moment().add(30, "minutes").format(DateFormat.YYYY_MM_DDTHH_mm_ss_SSSZ);
    const formattedDateTime = moment().format(DateFormat.YYYY_MM_DDTHH_mm_ss_SSSZ);
    if (formattedDateTime > values.time) {
      const currentTime = moment();
      // Donot get next time but need save this logic -> comment
      //const currentTime = moment().add(30, "minutes");
      // const targetTime = moment(reserveTime, DateFormat.HH_MM).add(30, "minutes");

      // const nextTime = selectTimeByDeliveryDay.find((item) => {
      //   const itemTime = moment(item.time, DateFormat.HH_MM);
      //   return itemTime.isAfter(currentTime) && itemTime.isSameOrAfter(targetTime);
      // });

      // if (nextTime) {
      //   const inputString2 = moment(reserveDate).format(DateFormat.YYYY_MM_DD_2) + " " + nextTime.time;
      //   const inputDate2 = moment(inputString2, DateFormat.YYYY_MM_DD_HH_MM);
      //   values.time = inputDate2.format(DateFormat.YYYY_MM_DDTHH_mm_ss_SSSZ);
      // }

      const inputString2 = moment(reserveDate).format(DateFormat.YYYY_MM_DD_2) + " " + currentTime.format("HH:mm");
      const inputDate2 = moment(inputString2, DateFormat.YYYY_MM_DD_HH_MM);
      values.time = inputDate2.format(DateFormat.YYYY_MM_DDTHH_mm_ss_SSSZ);
    }
  }

  const getLoginUserInfo = () => {
    const customerInfoJsonString = getStorage(localStorageKeys.CUSTOMER_INFO);
    const customerInfo = JSON.parse(customerInfoJsonString);
    return customerInfo;
  };

  function onSubmitReserveForm() {
    formRef
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        //Format and Set New Time If Needed - Reserve Table Time
        const formattedDateTime = formatDateTime(reserveDate, reserveTime);
        values.time = formattedDateTime;
        adjustDateTimeIfNeeded(values);
        const loginUserInfo = getLoginUserInfo();
        const accountId = loginUserInfo?.accountId;
        const noteString = values?.note;
        const tableIds = tableSelected?.map((item) => item.id);
        if (tableIds?.length === 0 || !tableIds) {
          setIsLoading(false);
          setContentNotification(translateData.pleaseSelectTable);
          setIsShowDialog(true);
        } else {
          const { isDeposit, depositAmount } = storeConfigDeposit;
          const paymentMethod = !isDeposit || isEmptyDepositMethod ? "" : depositMethodEnumId;
          const dataCreate = {
            storeId: storeConfig?.storeId,
            storeBranchId: values?.branch,
            accountId: accountId ?? null,
            customerName: values?.name,
            customerPhone: values?.phone,
            customerEmail: values?.email,
            numberOfSeats: values?.quantity,
            arrivalTime: values?.time,
            customerId: customerInfo?.customerId,
            note: noteString,
            listAreaTableId: tableIds,
            paymentMethod: paymentMethod,
            isDeposit: isDeposit,
            depositAmount: depositAmount,
          };
          createReserveTable(dataCreate);
        }
      })
      .catch(({ errorFields }) => {
        setIsLoading(false); // Set isLoading to false if validation fails
        const firstErrorFieldPath = errorFields?.[0]?.name;
        if (firstErrorFieldPath) {
          formRef.scrollToField(firstErrorFieldPath, {
            behavior: "smooth",
            block: "center",
          });
        }
      });
  }

  const checkIsLoggedIn = () => {
    let isLoggedIn = false;
    if (customerInfo) isLoggedIn = true;
    return isLoggedIn;
  };

  function handleRedirectToReserveTableDetail(reverseTableId) {
    setTimeout(() => {
      setIsLoading(false);
      if (reverseTableId) history.push(`/my-profile/6/${reverseTableId}`);
      else {
        history.push("/my-profile/6");
      }
    }, 1000);
  }

  function handleShowNotifyDialog(messageType = "success", message = translateData.reserveTableSuccessfully) {
    Toast.show({
      messageType: messageType,
      message: message,
      placement: "top",
      duration: 3,
      className: "toast-message-add-to-cart-theme1",
    });
  }

  const createReserveTable = async (dataCreate) => {
    setIsLoading(true);
    try {
      const res = await reserveTableService.createReserveTableAsync(dataCreate);

      if (res) {
        if (res.data?.isSuccess === true) {
          // Deposit method Momo
          let deeplink = null;
          const { id, code, paymentInfo } = res?.data;
          if (paymentInfo && depositMethodEnumId === PaymentMethodType.Momo) {
            const resultCode = paymentInfo?.resultCode;
            if (resultCode > 0) {
              setIsLoading(false);
              if (resultCode === MomoResultCode.InvalidSignature) {
                onMomoFailed(translateData.momoInvalidSignature);
                handleShowNotifyDialog("error", translateData.momoInvalidSignaturMessage);
              } else {
                onMomoFailed(paymentInfo?.message);
              }
            } else {
              const qrCodeUrl = paymentInfo?.qrCodeUrl;
              setMomoQRCodeURL(qrCodeUrl);
              deeplink = paymentInfo?.deepLink;
              setMomoDeeplink(deeplink);

              if (!isMobile || !deeplink) setUsingMomoWeb(true);
              else setIsShowMomo(true);
              setResponseDataMomo(res?.data);
            }

            setReverseTableId(id);
            setReserveTableStringCode(code);
          }

          setContentNotification(translateData.reserveTableSuccessfully);
          const isLoggedIn = checkIsLoggedIn();

          if (!Boolean(paymentInfo)) {
            // Show toast message reserve table success
            handleShowNotifyDialog();
            handleRedirectToReserveTableDetail(id);
          }

          // Save code to local storage if not login
          if (res.data?.code && !isLoggedIn) {
            const code = res.data.code;
            const lstJsonReserveTableCodes = JSON.parse(getStorage(localStorageKeys.RESERVE));
            if (lstJsonReserveTableCodes?.listReserveTableCodes) {
              lstJsonReserveTableCodes.listReserveTableCodes.unshift(code);
              localStorage.setItem(localStorageKeys.RESERVE, JSON.stringify(lstJsonReserveTableCodes));
            } else {
              const obj = { listReserveTableCodes: [code] };
              localStorage.setItem(localStorageKeys.RESERVE, JSON.stringify(obj));
            }
          }
        } else {
          setContentNotification(translateData.reserveTableFailed);
          setIsShowDialog(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onChangeBranch = (branchId) => {
    setBranchAddressId(branchId);
    setTableSelected(null);
  };

  const onChangeDepositMethodEnumId = (depositMethodEnumId) => {
    setDepositMethodEnumId(depositMethodEnumId);
  };

  const onEmptyDepositMethod = (isEmptyDepositMethod) => {
    setIsEmptyDepositMethod(isEmptyDepositMethod);
  };

  const handleConfirmNotify = () => {
    setIsShowDialog(false);
  };

  // Deposit method Momo
  const onViewOrderDetail = () => {
    setIsShowMomo(false);
    setUsingMomoWeb(false);
    setIsShowPaymentMomoFailed(false);
    setIsShowPaymentMomoSuccess(false);
    handleRedirectToReserveTableDetail(reverseTableId);
  };

  const onUsingMomoApp = () => {
    setUsingMomoWeb(false);
    setIsShowMomo(false);
    if (momoDeeplink) {
      window.location.assign(momoDeeplink);
    }
  };

  const onUsingMomoWeb = () => {
    setUsingMomoWeb(true);
    setIsShowMomo(false);
  };

  const onMomoSuccess = () => {
    setUsingMomoWeb(false);
    setIsShowMomo(false);
    setIsShowPaymentMomoSuccess(true);
  };

  const onMomoFailed = (error) => {
    setIsShowPaymentMomoFailed(true);
    setUsingMomoWeb(false);
    setIsShowMomo(false);
    setMomoError(error);
  };

  const handleOnMomoExprire = (isExpried) => {
    if (isExpried === false) return;
    setTimeout(() => {
      onMomoFailed();
      handleRedirectToReserveTableDetail(reverseTableId);
    }, 3000);
  };

  return (
    <div
      id="themeReservationReservation"
      onClick={() => {
        if (clickToFocusCustomize) clickToFocusCustomize(theme1ElementCustomize.ReservationReservation);
      }}
    >
      <div className="reserve-table-content-wrapper">
        <StyledReserveTable isCustomize={isCustomize} colorGroup={colorGroup} config={config}>
          <Row
            gutter={gutterRow}
            className={`reserve-table-content ${isCustomize ? "reserve-table-content-customize" : ""}`}
          >
            <Col span={24} md={24} lg={24} xl={15} xxl={16}>
              <ReserveTableContentLeft
                colorGroup={colorGroup}
                deliveryAddress={deliveryAddress}
                branchesByCustomerAddress={branchesByCustomerAddress}
                tableSelected={tableSelected}
                setTableSelected={setTableSelected}
                areaSelected={areaSelected}
                setAreaSelected={setAreaSelected}
                reserveTableData={isCustomize ? areasDefault : reserveTableData}
                setReserveTableData={setReserveTableData}
                form={formRef}
                onChangeBranch={onChangeBranch}
                isLoadingAreaData={isLoadingAreaData}
                hotline={hotline}
              />
            </Col>{" "}
            {!isCustomize && (
              <Col span={0} xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
                <div className="reserve-table-middle-content"></div>
              </Col>
            )}
            <Col span={24} md={24} lg={24} xl={8} xxl={7}>
              <ReserveTableContentRight
                form={formRef}
                deliveryAddress={deliveryAddress}
                branchesByCustomerAddress={branchesByCustomerAddress}
                onSubmitReserveForm={onSubmitReserveForm}
                setReserveTime={setReserveTime}
                reserveTime={reserveTime}
                reserveDate={reserveDate}
                setReserveDate={setReserveDate}
                onChangeBranch={onChangeBranch}
                branchAddressId={branchAddressId ?? null}
                storeId={storeConfig?.storeId}
                colorGroup={colorGroup}
                selectTimeByDeliveryDay={selectTimeByDeliveryDay}
                setSelectTimeByDeliveryDay={setSelectTimeByDeliveryDay}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onChangeDepositMethodEnumId={onChangeDepositMethodEnumId}
                onEmptyDepositMethod={onEmptyDepositMethod}
                hotline={hotline}
                workingHour={workingHour}
              />
            </Col>
          </Row>
          <NotificationDialog
            open={isShowDialog}
            title={translateData.notification}
            content={contentNotification}
            className="checkout-theme1-notify-dialog"
            footer={[<Button onClick={handleConfirmNotify}>{translateData.okay}</Button>]}
            closable={true}
          />
        </StyledReserveTable>
      </div>

      {/* Deposit method Momo */}
      <CheckOutMomo visible={isShowMomo} onCancel={onUsingMomoWeb} onOk={onUsingMomoApp} />
      <CheckOutMomoWeb
        visible={usingMomoWeb}
        onCancel={() => {
          setUsingMomoWeb(false);
          setIsShowPaymentMomoFailed(true);
        }}
        orderID={reverseTableId}
        orderStringCode={reverseTableStringCode}
        amount={storeConfigDeposit?.depositAmount}
        momoQRCodeURL={momoQRCodeURL}
        momoDeeplink={momoDeeplink}
        className={classNameDepositMomoMethod}
        onMomoExpire={handleOnMomoExprire}
        paymentInfoData={responseDataMomo}
      />
      <CheckOutMomoFailed
        visible={isShowPaymentMomoFailed}
        onCancel={() => {
          setIsShowPaymentMomoFailed(false);
          handleShowNotifyDialog();
          handleRedirectToReserveTableDetail(reverseTableId);
        }}
        orderID={reverseTableId}
        error={momoError}
        className={classNameDepositMomoMethod}
      />
      <CheckOutMomoSuccess
        visible={isShowPaymentMomoSuccess}
        onCancel={() => {
          setIsShowPaymentMomoSuccess(false);
          formRef.resetFields(["quantity"]);
          setResponseDataMomo(null);
        }}
        orderID={reverseTableId}
        onViewDetail={onViewOrderDetail}
        onCreateNewOrder={() => {
          formRef.resetFields(["quantity"]);
          setIsShowPaymentMomoSuccess(false);
          setUsingMomoWeb(false);
          setResponseDataMomo(null);
        }}
        translateData={translateData}
        className={classNameDepositMomoMethod}
      />
    </div>
  );
}

export default ReserveTableContent;
