import React, { useEffect, useState } from "react";
import { Row, message, Col } from "antd";
import { PermissionKeys } from "constants/permission-key.constants";
import { Percent } from "constants/string.constants";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import ViewTaxDetail from "./view-tax-detail.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { hasPermission } from "utils/helpers";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

export default function TableTax(props) {
  const { t, taxDataService, handleAddNewTax } = props;
  const [dataSource, setDataSource] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [showTaxDetailModal, setShowTaxDetailModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [taxDetail, setTaxDetail] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const pageData = {
    btnDelete: t("button.delete"),
    btnIgnore: t("button.ignore"),
    action: t("table.action"),
    no: t("table.no"),
    name: t("feeAndTax.tax.name"),
    type: t("feeAndTax.tax.type"),
    description: t("feeAndTax.tax.description"),
    value: t("feeAndTax.tax.value"),
    titleModal: t("feeAndTax.tax.taxDetail"),
    taxDeleteSuccess: t("feeAndTax.tax.taxDeleteSuccess"),
    taxDeleteFail: t("feeAndTax.tax.taxDeleteFail"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    toCreateData: t("area.toCreateData"),
    addNew: t("button.addNew"),
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.no,
        dataIndex: "no",
        key: "no",
        width: "5rem",
      },
      {
        title: pageData.name,
        dataIndex: "name",
        key: "name",
        width: "20%",
        ellipsis: true,
        render: (value, record) => {
          return (
            <FnbTooltip title={value} onlyShowWhenEllipsis>
              <span>{value}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.type,
        dataIndex: "type",
        key: "type",
        width: "20%",
        ellipsis: true,
        render: (value) => {
          return <span>{t(value)}</span>;
        },
      },
      {
        title: pageData.description,
        dataIndex: "description",
        key: "description",
        ellipsis: true,
        render: (value) => (
          <FnbTooltip title={value} onlyShowWhenEllipsis>
            <span>{value}</span>
          </FnbTooltip>
        ),
      },
      {
        title: pageData.value,
        dataIndex: "value",
        key: "name",
        width: "10%",
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize);
    },
  };

  const onRowClick = (record) => {
    onViewDetailTax(record?.id);
  };

  // Insert the name into the message
  const formatDeleteMessage = (name) => {
    let mess = t(pageData.confirmDeleteMessage, { name: name });
    return mess;
  };

  useEffect(() => {
    props.tableFuncs.current = onRefreshTable;
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize);
  }, []);

  const onViewDetailTax = (taxId) => {
    taxDataService.getTaxByIdAsync(taxId).then((res) => {
      if (res?.tax) {
        setTaxDetail(res?.tax);
        setShowTaxDetailModal(true);
      }
    });
  };

  const renderTaxDetailModal = () => {
    return (
      <FnbModal
        title={pageData.titleModal}
        visible={showTaxDetailModal}
        handleCancel={onCloseModal}
        footer={(null, null)}
        content={<ViewTaxDetail t={t} taxDetail={taxDetail} />}
        hideFooter
      />
    );
  };

  const onCloseModal = async () => {
    setShowTaxDetailModal(false);
    await fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  };

  const onRefreshTable = async () => {
    await fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
  };

  const fetchDatableAsync = async (pageNumber, pageSize) => {
    const response = await taxDataService.getAllTaxAsync(pageNumber, pageSize);
    const data = mappingRecordToColumns(response?.taxes);
    setDataSource(data);
    setTotalRecords(response.total);
    setCurrentPageNumber(response.pageNumber);
  };

  const mappingRecordToColumns = (items) => {
    let taxs = [];
    items.map((item, index) => {
      let tax = {
        no: index + 1,
        id: item?.id,
        name: item?.name,
        type: item?.taxType,
        description: item?.description,
        value: item?.percentage + Percent,
      };
      taxs.push(tax);
    });
    return taxs;
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onDeleteItem = (_selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const onRemoveItem = async (id) => {
    var res = await taxDataService.deleteTaxByIdAsync(id);
    if (res) {
      message.success(pageData.taxDeleteSuccess);
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setShowConfirmDeleteModal(false);
    } else {
      message.error(pageData.taxDeleteFail);
    }
    await fetchDatableAsync(1, tableSettings.pageSize);
  };

  return (
    <>
      {renderTaxDetailModal()}
      <Row>
        <Col span={24}>
          <FnbTable
            dataSource={dataSource}
            columns={tableSettings.columns}
            pageSize={tableSettings.pageSize}
            pageNumber={currentPageNumber}
            total={totalRecords}
            onChangePage={tableSettings.onChangePage}
            deletePermission={PermissionKeys.DELETE_TAX}
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectedRowKeysChange,
              onDelete: onDeleteItem,
            }}
            onRowClick={onRowClick}
            emptyText={
              hasPermission(PermissionKeys.CREATE_TAX) && (
                <div className="content-collapse-empty-text">
                  <FnbTypography.Link
                    variant={theme.typography["b1-bold-underlined"]}
                    underline={true}
                    text={pageData.addNew}
                    onClick={() => handleAddNewTax(true)}
                  />
                  {pageData.toCreateData}
                </div>
              )
            }
          />
        </Col>
      </Row>
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.btnIgnore}
        okText={pageData.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={() => onRemoveItem(selectedRows[0]?.id)}
      />
    </>
  );
}
