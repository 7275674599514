import { Card, message, Row } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbNotifyDialog } from "components/fnb-notify-dialog/fnb-notify-dialog.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { AddCircleIcon, DeleteOutlined, EditOutlined, MinusCircleOutlineIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { PromotionStatus } from "constants/promotion.constants";
import { DateFormat } from "constants/string.constants";
import flashSaleDataService from "data-services/flash-sale/flash-sale-data.service";
import { useRedirect } from "hooks/useRedirect";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { executeAfter, formatDate, hasPermission } from "utils/helpers";
import { usePromotionContextProvider } from "../promotion-context";
import FilterFlashSale from "./filter-flash-sale.component";
import "./list-flashsale.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";

export default function FlashSaleManagement(props) {
  const { isVisible, onChangeVisible } = props;
  const [t] = useTranslation();
  const { handleRedirect } = useRedirect();
  const pageData = {
    title: t("promotion.flashSale.title"),
    search: t("promotion.search"),
    linkAddNew: "/store/flashSale/create-new",
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmStop: t("leaveDialog.confirmStop"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    button: {
      ok: t("button.ok"),
      addNew: t("button.addNew"),
      filter: t("button.filter"),
      btnEdit: t("button.edit"),
      btnDelete: t("button.delete"),
      btnIgnore: t("button.ignore"),
      btnStop: t("button.stop"),
    },
    amount: t("promotion.form.amount"),
    maximum: t("promotion.form.maximum"),
    start: t("promotion.form.start"),
    end: t("promotion.form.end"),
    stop: t("button.stop"),
    scheduledStatus: t("promotion.status.scheduled"),
    activeStatus: t("promotion.status.active"),
    finishedStatus: t("promotion.status.finished"),
    searchPlaceHolder: t("promotion.flashSale.searchPlaceHolderFlashSale"),
    newCampaign: t("promotion.flashSale.newCampaign"),
    deleteFlashSaleMessage: t("promotion.flashSale.deleteFlashSaleMessage"),
    deleteFlashSaleSuccess: t("promotion.flashSale.deleteFlashSaleSuccess"),
    deleteFlashSaleFail: t("promotion.flashSale.deleteFlashSaleFail"),
    confirmStopFlashSale: t("promotion.flashSale.confirmStopFlashSale"),
    stopFlashSaleSuccess: t("promotion.flashSale.stopFlashSaleSuccess"),
    stopFlashSaleFail: t("promotion.flashSale.stopFlashSaleFail"),
    btnFilter: t("button.filter"),
    table: {
      searchPlaceholder: t("inventoryHistory.searchPlaceHolder"),
      no: t("table.no"),
      name: t("table.name"),
      validTime: t("promotion.table.validTime"),
      product: t("promotion.form.product"),
      status: t("promotion.table.status"),
      action: t("promotion.table.action"),
    },
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    today: t("optionDatetime.today"),
    yesterday: "dashboard.compareDate.yesterday",
    flashSale: {
      messCreatePermission: t("promotion.flashSale.warningPermission.messCreatePermission"),
    },
    form: {
      notificationTitle: t("form.notificationTitle"),
    },
    flashSaleTitle: t("promotion.flashSale.newCampaign"),
    addNew: t("button.addNew"),
    toCreateData: t("area.toCreateData"),
  };

  const MAX_LENGTH_SEARCH = 100;

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [dataFilter, setDataFilter] = useState({});
  const [keySearchFilter, setKeySearchFilter] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmStopModal, setShowConfirmStopModal] = useState(false);
  const filterPopoverRef = useRef();
  const { branches } = usePromotionContextProvider();

  const getColumns = () => {
    const columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        width: "5rem",
        align: "center",
        render: (value) => <span>{value}</span>,
      },
      {
        title: pageData.table.name,
        dataIndex: "name",
        width: "50%",
        sorter: (pre, current) => pre?.name.localeCompare(current?.name),
        render: (value) => {
          return (
            <FnbTooltip title={value} onlyShowWhenEllipsis>
              <span>{value}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.validTime,
        dataIndex: "date",
        align: "right",
        render: (_, record) => {
          return (
            <>
              <div className="column-effective-time">
                <span className="start-date">
                  <span>{pageData.start}: </span>
                  <span>{formatDate(record?.date, DateFormat.DD_MMM_YYYY)}</span>
                  <strong>{formatDate(record?.startTime, DateFormat.HH_MM)}</strong>
                </span>
                <span className="end-date">
                  <span>{pageData.end}: </span>
                  <span>{formatDate(record?.date, DateFormat.DD_MMM_YYYY)}</span>
                  <strong>{formatDate(record?.endTime, DateFormat.HH_MM)}</strong>
                </span>
              </div>
            </>
          );
        },
      },
      {
        title: pageData.table.product,
        dataIndex: "productQuantity",
        width: "8rem",
        align: "center",
      },
      {
        title: pageData.table.status,
        dataIndex: "statusId",
        width: "9rem",
        align: "center",
        render: (value) => <Row justify={"center"}>{renderFlashSaleStatus(value)}</Row>,
      },
    ];
    return columns;
  };

  const tableSettings = {
    pageSize: 20,

    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync(page, pageSize, keySearchFilter, dataFilter);
    },
    onSearch: async (keySearch) => {
      setKeySearchFilter(keySearch);
      executeAfter(500, async () => {
        await fetchDatableAsync(1, tableSettings.pageSize, keySearch, dataFilter);
      });
    },
  };

  const onRowClick = (record) => {
    handleRedirect(`/store/flashSale/view/${record?.id}`);
  };

  useEffect(() => {
    fetchDatableAsync(currentPageNumber, tableSettings.pageSize, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatableAsync = async (pageNumber, pageSize, keySearch, dataFilter) => {
    var checkStartDate = moment(dataFilter?.startDate, "YYYY-MM-DD").isValid();
    var checkEndDate = moment(dataFilter?.endDate, "YYYY-MM-DD").isValid();
    var timeZone = new Date().getTimezoneOffset() / 60;
    const response = await flashSaleDataService.getFlashSalesAsync(
      pageNumber,
      pageSize,
      keySearch,
      dataFilter?.branchId ?? "",
      dataFilter?.statusId ?? "",
      checkStartDate ? moment.utc(dataFilter?.startDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2) : "",
      checkEndDate ? moment.utc(dataFilter?.endDate).format(DateFormat.YYYY_MM_DD_HH_MM_SS_2) : "",
      dataFilter?.includeTopping ?? "",
      dataFilter?.minMinimumPurchaseOnBill ?? "",
      dataFilter?.maxMinimumPurchaseOnBill ?? "",
      timeZone,
    );
    const data = response?.flashSales.map((s) => mappingRecordToColumns(s));
    setTotalRecords(response.total);
    setCurrentPageNumber(pageNumber);
    setDataSource(data);
    setCountFilter(dataFilter?.count);
  };

  const mappingRecordToColumns = (item) => {
    return {
      id: item?.id,
      no: item?.no,
      name: item?.name,
      productQuantity: item?.productQuantity,
      startTime: item?.startTime,
      endTime: item?.endTime,
      date: item?.date,
      statusId: item?.statusId,
    };
  };

  const renderFlashSaleStatus = (statusId) => {
    switch (statusId) {
      case PromotionStatus.Schedule:
        return <FnbBadge variant="warning" text={pageData.scheduledStatus} />;
      case PromotionStatus.Active:
        return <FnbBadge variant="success" text={pageData.activeStatus} />;
      default:
        return <FnbBadge variant="error" text={pageData.finishedStatus} />;
    }
  };

  const filterComponent = () => {
    return (
      <FilterFlashSale
        ref={filterPopoverRef}
        fetchDataFlashSale={fetchDatableAsync}
        branches={branches}
        pageSize={tableSettings.pageSize}
        keySearch={keySearchFilter}
        setDataFilter={setDataFilter}
      />
    );
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current.resetFilter();
    }
    setCountFilter(0);
    setDataFilter(null);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, _selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onEditItem = (id) => {
    handleRedirect(`/store/flashSale/edit/${id}`);
  };

  const formatDeleteMessage = (name) => {
    let mess = t(pageData.deleteFlashSaleMessage, { name: name });
    return mess;
  };

  const formatStopMessage = (name) => {
    let mess = t(pageData.confirmStopFlashSale, { name: name });
    return mess;
  };

  const onDeleteItem = (selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmDeleteModal(true);
  };

  const onDeleteFlashSale = async (id) => {
    await flashSaleDataService.deleteFlashSaleByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.deleteFlashSaleSuccess);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setShowConfirmDeleteModal(false);
      } else {
        message.error(pageData.deleteFlashSaleFail);
      }
      fetchDatableAsync(1, tableSettings.pageSize, keySearchFilter, dataFilter);
    });
  };

  const onStopItem = (selectedRows) => {
    setSelectedRows(selectedRows);
    setShowConfirmStopModal(true);
  };

  const onStopFlashSale = async (id) => {
    await flashSaleDataService.stopFlashSaleByIdAsync(id).then((res) => {
      if (res) {
        message.success(pageData.stopFlashSaleSuccess);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setShowConfirmStopModal(false);
      } else {
        message.error(pageData.stopFlashSaleFail);
      }
      fetchDatableAsync(1, tableSettings.pageSize, keySearchFilter, dataFilter);
    });
  };

  const handleVisibleNotification = (visible) => {
    onChangeVisible && onChangeVisible(visible);
  };

  const handleNewFlashSale = () => {
    if (hasPermission(PermissionKeys.CREATE_FLASH_SALE)) {
      handleRedirect("/store/flashSale/create-new");
    }
  };

  return (
    <>
      <div className="clearfix"></div>
      <Card className="fnb-card-custom">
        <FnbTable
          className="table-flash-sale-campaign"
          dataSource={dataSource}
          columns={getColumns()}
          pageSize={tableSettings.pageSize}
          currentPageNumber={currentPageNumber}
          total={totalRecords}
          editPermission={PermissionKeys.EDIT_FLASH_SALE}
          deletePermission={PermissionKeys.DELETE_FLASH_SALE}
          onChangePage={tableSettings.onChangePage}
          search={{
            maxLength: MAX_LENGTH_SEARCH,
            placeholder: pageData.searchPlaceHolder,
            onChange: tableSettings.onSearch,
          }}
          filter={{
            totalFilterSelected: countFilter,
            onClearFilter: onClearFilter,
            buttonTitle: pageData.filter,
            component: filterComponent(),
            filterClassName: "filter-discount-code-management",
          }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectedRowKeysChange,
            renderActions: (_selectedRowKeys, selectedRows) => {
              const multipleSelectedRows = selectedRows?.length > 1;
              const isScheduleStatus = selectedRows?.every((reccord) => reccord?.statusId === PromotionStatus.Schedule);
              const isActiveStatus = selectedRows?.every((reccord) => reccord?.statusId === PromotionStatus.Active);
              return (
                <>
                  {hasPermission(PermissionKeys.EDIT_FLASH_SALE) && !multipleSelectedRows && isScheduleStatus && (
                    <FnbTooltip placement="top" title={pageData.button.btnEdit} zIndex={10}>
                      <FnbButton
                        variant="text"
                        className="row-edit-button"
                        iconHeader={<EditOutlined />}
                        onClick={() => onEditItem(selectedRows?.[0]?.id)}
                      />
                    </FnbTooltip>
                  )}
                  {hasPermission(PermissionKeys.DELETE_FLASH_SALE) && isScheduleStatus && (
                    <FnbTooltip placement="top" title={pageData.button.btnDelete} zIndex={10}>
                      <FnbButton
                        variant="text"
                        className="row-delete-button"
                        iconHeader={<DeleteOutlined />}
                        onClick={() => onDeleteItem(selectedRows)}
                        disabled={multipleSelectedRows}
                      />
                    </FnbTooltip>
                  )}
                  {hasPermission(PermissionKeys.STOP_FLASH_SALE) && !multipleSelectedRows && isActiveStatus && (
                    <FnbTooltip placement="top" title={pageData.button.btnStop} zIndex={10}>
                      <FnbButton
                        variant="text"
                        className="row-stop-button"
                        iconHeader={<MinusCircleOutlineIcon />}
                        onClick={() => onStopItem(selectedRows)}
                      />
                    </FnbTooltip>
                  )}
                </>
              );
            },
          }}
          onRowClick={onRowClick}
          listButtons={[
            <FnbButton
              permission={PermissionKeys.CREATE_FLASH_SALE}
              onClick={handleNewFlashSale}
              text={pageData.addNew}
              iconHeader={<AddCircleIcon fill={theme.colors.default.white} />}
            />,
          ]}
          emptyText={
            hasPermission(PermissionKeys.CREATE_FLASH_SALE) && (
              <div className="content-collapse-empty-text">
                <FnbTypography.Link
                  variant={theme.typography["b1-bold-underlined"]}
                  underline={true}
                  text={pageData.addNew}
                  onClick={handleNewFlashSale}
                />
                {pageData.toCreateData}
              </div>
            )
          }
        />
      </Card>
      <FnbNotifyDialog
        title={pageData.form.notificationTitle}
        open={isVisible}
        hideCancelButton={true}
        okText={pageData.button.ok}
        onOk={() => {
          handleVisibleNotification(false);
        }}
        onCancel={() => {
          handleVisibleNotification(false);
        }}
        content={() => {
          return <p>{pageData.flashSale.messCreatePermission}</p>;
        }}
      />
      <DeleteConfirmComponent
        title={pageData.confirmDelete}
        content={formatDeleteMessage(selectedRows[0]?.name)}
        visible={showConfirmDeleteModal}
        skipPermission={true}
        cancelText={pageData.button.btnIgnore}
        okText={pageData.button.btnDelete}
        onCancel={() => {
          setShowConfirmDeleteModal(false);
        }}
        onOk={() => onDeleteFlashSale(selectedRows[0]?.id)}
      />
      <DeleteConfirmComponent
        title={pageData.confirmStop}
        content={formatStopMessage(selectedRows[0]?.name)}
        visible={showConfirmStopModal}
        skipPermission={true}
        cancelText={pageData.button.btnIgnore}
        okText={pageData.button.btnStop}
        onCancel={() => {
          setShowConfirmStopModal(false);
        }}
        onOk={() => onStopFlashSale(selectedRows[0]?.id)}
      />
    </>
  );
}
