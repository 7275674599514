import styled from "styled-components";
import { ReactComponent as RightCornerBackground } from "assets/images/system-guideline-background-right-corner.svg";

export const ContentItemStepGuidelineStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: 100%;
  padding: 16px 24px;
  background: #fff;
  ul {
    margin-bottom: 0;
    list-style-type: none;
  }
  button {
    z-index: 1;
  }
  @media (max-width: 576px) {
    padding: 16px 12px 20px 12px;
  }
`;

export const RightCornerBackgroundStyle = styled(RightCornerBackground)`
  position: absolute;
  bottom: 0;
  right: 0;
  @media (max-width: 576px) {
    width: 128px;
    height: auto;
  }
`;
