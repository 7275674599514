import theme from "theme";
import "./fnb-typography.scss";
import classNames from "classnames";
import { Link as LinkRouter } from "react-router-dom";
import React from "react";

const Link = ({
  text = "",
  variant = theme.typography["b1-medium"],
  color = null,
  style = {},
  className = "",
  target = "_self", // _top, _parent, _blank ,
  to, //href
  textDecoration = "", //underline
  onClick = undefined,
  underline = false,
  iconHeader,
  disable = false,
  boxShadow = ""
}) => {
  return (
    <>
      {to && !to.startsWith("/") && !to.startsWith("#") ? (
        <div className={`fnb-typography__hyperlink ${disable ? "disabled" : ""}`}>
          <a href={to} target={target}>
            <FnbTypography
              text={text}
              variant={variant}
              color={color}
              style={{ textDecoration: underline ? "underline" : "unset", ...style }}
              className={`fnb-typography__hyperlink ${className}`}
              iconHeader={iconHeader}
              boxShadow={boxShadow}
            />
          </a>
        </div>
      ) : (
        <div className={`fnb-typography__hyperlink ${disable ? "disabled" : ""}`}>
          <LinkRouter
            to={to}
            target={target}
            onClick={(e) => {
              onClick && onClick(e);
            }}
          >
            <FnbTypography
              text={text}
              variant={variant}
              color={color}
              style={{ textDecoration: underline ? "underline" : "unset", ...style }}
              className={`fnb-typography__hyperlink ${className}`}
              iconHeader={iconHeader}
              boxShadow={boxShadow}
            />
          </LinkRouter>
        </div>
      )}
    </>
  );
};

function FnbTypography({
  text = "",
  variant = theme.typography["b1-regular"],
  color = null,
  style = {},
  className = "",
  textTransform = "none", // capitalize | lowercase | uppercase
  textDecoration = "", //underline
  onClick = undefined,
  iconHeader,
  children,
  boxShadow = ""
}) {
  const classNameTypography = classNames({
    [`fnb-typography__type--${variant}`]: true,
    [`${className}`]: true,
    "fnb-typography--default-color": color === null,
    "fnb-typography__have-icon": iconHeader,
  });

  const styleColor = color ? { color: color } : {};

  const onClickText = (e) => {
    onClick && onClick(e);
  };
  return (
    <span
      onClick={onClickText}
      className={classNameTypography}
      style={{ textTransform: textTransform, textDecoration: textDecoration, boxShadow:boxShadow, ...style, ...styleColor }}
    >
      {iconHeader &&
        React.cloneElement(iconHeader, {
          ...iconHeader?.props,
          className: `fnb-button__icon ${iconHeader?.props?.className ? iconHeader?.props?.className : ""}`,
        })}
      {text !== "" ? text : children}
    </span>
  );
}

FnbTypography.Link = Link;

export default FnbTypography;
