import React from "react";
import { Form } from "antd";
import FnbTooltip, { FnbTooltipVariant } from "components/fnb-tooltip/fnb-tooltip";
import { ExclamationIcon } from "constants/icons.constants";
import "./fnb-form-item.component.scss";

export function FnbFormItem({ children, label, rules, tooltip, className, maxWidthTooltip = "fit-content", tooltipVariant = FnbTooltipVariant.PRIMARY, ...props }) {
  const renderLabel = (label, required) => {
    return (
      label && <div className="d-flex fnb-form-item-label" style={{ alignItems: "center" }}>
        <span>{label}</span>
        {required && <span className="text-danger">*</span>}
        {tooltip && <FnbTooltip variant={tooltipVariant} title={tooltip} iconClassName="fnb-form-item-tooltip" maxWidth={maxWidthTooltip}>
          <ExclamationIcon width={16} height={16} />
        </FnbTooltip>}
      </div>
    );
  }
  
  return <Form.Item className={`fnb-form-item ${className}`} label={renderLabel(label, rules?.some(rule => rule.required) ?? false)} rules={rules} {...props}>{children}</Form.Item>;
}
