import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utils/helpers";
import "./index.scss";
import TableInventoryHistoryByMaterialComponent from "./table-inventory-history-by-material.component";
import TableInventoryHistoryDefaultComponent from "./table-inventory-history-default.component";
import "./table-inventory-history.component.scss";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import { useState } from "react";

const KEY_TAB_PANE = {
  VIEW_BY_TRANSACTION: "view-by-transaction",
  VIEW_BY_MATERIAL: "view-by-material",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.VIEW_BY_TRANSACTION]: 0,
  [KEY_TAB_PANE.VIEW_BY_MATERIAL]: 1,
};

const DEFAULT_SCREEN = KEY_TAB_PANE.VIEW_BY_TRANSACTION;

const TableInventoryHistoryComponent = (props) => {
  const [t] = useTranslation();
  const pageData = {
    viewByTransaction: t("inventoryHistory.viewByTransaction"),
    viewByMaterial: t("inventoryHistory.viewByMaterial"),
  };
  const [activeScreen, setActiveScreen] = useState(DEFAULT_SCREEN);

  const screens = [
    {
      name: pageData.viewByTransaction,
      key: KEY_TAB_PANE.VIEW_BY_TRANSACTION,
      component: (
        <>{hasPermission(PermissionKeys.VIEW_INVENTORY_HISTORY) && <TableInventoryHistoryDefaultComponent />}</>
      ),
    },
    {
      name: pageData.viewByMaterial,
      key: KEY_TAB_PANE.VIEW_BY_MATERIAL,
      component: (
        <>{hasPermission(PermissionKeys.VIEW_INVENTORY_HISTORY) && <TableInventoryHistoryByMaterialComponent />}</>
      ),
    },
  ];

  const handleChangeScreen = (activeKey) => {
    setActiveScreen(activeKey);
  };

  return (
    <>
      <FnbTabPane
        screens={screens}
        activeScreen={activeScreen}
        indexKeyTabPane={INDEX_KEY_TAB_PANE}
        onChange={handleChangeScreen}
        defaultScreen={DEFAULT_SCREEN}
        firstKeyTabPane={KEY_TAB_PANE.VIEW_BY_TRANSACTION}
        lastKeyTabPane={KEY_TAB_PANE.VIEW_BY_MATERIAL}
      />
    </>
  );
};

export default TableInventoryHistoryComponent;
