import { Col, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat } from "constants/string.constants";
import reportDataService from "data-services/report/report-data.service";
import { useRedirect } from "hooks/useRedirect";
import moment from "moment";
import { WrapperTable } from "pages/report/reservation/management/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import languageService from "services/language/language.service";
import { formatDate, formatNumberDecimalOrInteger, getUniqueId, hasPermission } from "utils/helpers";
import "./list-shift.component.scss";

export default function ListShift(props) {
  const [t] = useTranslation();
  const { selectedDate, typeOptionDate, branchId } = props;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const pageData = {
    shift_id: t("report.shift.id"),
    branch: t("report.shift.branch"),
    staff: t("report.shift.staff"),
    checkIn: t("report.shift.checkIn"),
    checkOut: t("report.shift.checkOut"),
    revenue: t("report.shift.revenue"),
    grossRevenue: t("report.shift.grossRevenue"),
    paymentMethod: t("report.shift.paymentMethod"),
    cash: t("report.shift.cash"),
    moMo: t("report.shift.moMo"),
    bankTransfer: t("report.shift.bankTransfer"),
    totalOrder: t("report.shift.totalOrder"),
    initialAmount: t("report.shift.initialAmount"),
    discount: t("report.shift.discount"),
    depositAmount: t("report.shift.depositAmount"),
    withdrawalAmount: t("report.shift.withdrawalAmount"),
    remain: t("report.shift.remainAmount"),
    startBy: t("report.shift.startBy"),
    endBy: t("report.shift.endBy"),
  };
  const { handleRedirect } = useRedirect();

  useEffect(() => {
    fetchDatableAsync(1, tableSettings.pageSize);
  }, [selectedDate, branchId, typeOptionDate]);

  const generateLinkStaffDetail = (staffId, staffName) => {
    const handleClickStaffLink = (e) => {
      e.stopPropagation();
      handleRedirect(`/staff/edit/${staffId}`);
    };

    return (
      <span className="p__name-shift bold pl-1" onClick={handleClickStaffLink}>
        {staffName}
      </span>
    );
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.shift_id,
        dataIndex: "code",
        key: "code",
        align: "left",
        width: "10%",
        render: (_, { code }) => {
          return <span># {code}</span>;
        },
      },
      {
        title: pageData.branch,
        dataIndex: "branchName",
        key: "branchName",
        align: "left",
        width: "16%",
      },
      {
        title: pageData.staff,
        dataIndex: "staffName",
        key: "staffName",
        align: "left",
        width: "20%",
        render: (_, record) => {
          record = record ?? {};
          return (
            <Row>
              <Col span={24} className="left mb-2">
                <span>{pageData.startBy}:</span>
                {generateLinkStaffDetail(record.staffId, record.staffNameStart)}
              </Col>
              {record.checkOutDateTime ? (
                <Col span={24} className="left mb-2">
                  <span>{pageData.endBy}:</span>
                  {generateLinkStaffDetail(record.staffId, record.staffNameEnd)}
                </Col>
              ) : (
                <Col span={24} className="left mb-2">
                  <span>{pageData.endBy}:</span>
                </Col>
              )}
              <Col span={24} className="left mb-2">
                <span>{pageData.checkIn}</span>
                <span className="ml-auto">
                  {record.checkInDateTime ? formatDate(record.checkInDateTime, DateFormat.HH_mm_DD_MMM_YYYY) : "-"}
                </span>
              </Col>
              <Col span={24} className="left mb-2">
                <span>{pageData.checkOut}</span>
                <span className="ml-auto">
                  {record.checkOutDateTime ? formatDate(record.checkOutDateTime, DateFormat.HH_mm_DD_MMM_YYYY) : "-"}
                </span>
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.revenue,
        dataIndex: "revenue",
        key: "revenue",
        align: "left",
        width: "25%",
        render: (_, record) => {
          record = record ?? {};
          return (
            <Row>
              <Col span={24} className="left mb-2">
                <span>{pageData.initialAmount}</span>
                <span className="ml-auto">{formatNumberDecimalOrInteger(record.initialAmount)}</span>
              </Col>
              <Col span={24} className="left mb-2">
                <span>{pageData.grossRevenue}</span>
                <span className="ml-auto">{formatNumberDecimalOrInteger(record.grossRevenue)}</span>
              </Col>
              <Col span={24} className="left mb-2">
                <span>{pageData.discount}</span>
                <span className="ml-auto">{formatNumberDecimalOrInteger(record.discount)}</span>
              </Col>
              <Col span={24} className="left mb-2">
                <span>{pageData.depositAmount}</span>
                <span className="ml-auto">{formatNumberDecimalOrInteger(record.depositAmount)}</span>
              </Col>
              <Col span={24} className="left mb-2">
                <span>{pageData.withdrawalAmount}</span>
                <span className="ml-auto">{formatNumberDecimalOrInteger(record.withdrawalAmount)}</span>
              </Col>
              <Col span={24} className="left mb-2">
                <span>{pageData.remain}</span>
                <span className="ml-auto text-danger">{formatNumberDecimalOrInteger(record.remain)}</span>
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.paymentMethod,
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        align: "left",
        width: "18%",
        render: (_, { paymentMethods }) => {
          return <Row>{renderPaymentMethod(paymentMethods)}</Row>;
        },
      },
      {
        title: pageData.totalOrder,
        dataIndex: "totalOrder",
        key: "totalOrder",
        align: "center",
        width: "18%",
      },
    ],

    onChangePage: async (page, pageSize) => {
      fetchDatableAsync(page, pageSize);
    },
  };

  const renderPaymentMethod = (paymentMethods) => {
    const paymentElements = [];
    paymentMethods.forEach((item) => {
      item = item ?? {};
      paymentElements.push(
        <Col span={24} key={getUniqueId()} className="left mb-2">
          <span>{t(item.name)}</span>
          <span className="ml-auto">{formatNumberDecimalOrInteger(item.amount)}</span>
        </Col>,
      );
    });

    return paymentElements;
  };

  const fetchDatableAsync = async (pageNumber, pageSize) => {
    let param = {
      languageCode: languageService.getLang(),
      branchId: branchId,
      startDate: moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY),
      endDate: moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY),
      timeZone: new Date().getTimezoneOffset() / 60,
      typeOptionDate: typeOptionDate,
      pageSize: pageSize,
      pageNumber: pageNumber,
    };
    const response = await reportDataService.getShiftsAsync(param);
    const data = response?.shifts?.map((s) => mappingRecordToColumns(s));

    setDataSource(data);
    setTotalRecords(response?.totalShift);
    setCurrentPageNumber(pageNumber);
  };

  const mappingRecordToColumns = (item) => {
    item = item ?? {};
    return {
      shiftId: item.shiftId,
      staffId: item.staffId,
      code: item.code,
      branchName: item.branchName,
      staffNameStart: item.staffNameStart,
      idStaffNameEnd: item.idStaffNameEnd,
      staffNameEnd: item.staffNameEnd,
      grossRevenue: item.grossRevenue,
      paymentMethods: item.paymentMethods,
      totalOrder: item.totalOrder,
      withdrawalAmount: item.withdrawalAmount,
      depositAmount: item.depositAmount,
      discount: item.discount,
      checkInDateTime: item.checkInDateTime,
      checkOutDateTime: item.checkOutDateTime,
      initialAmount: item.initialAmount,
      remain: item.remain,
    };
  };

  const handleOnRowClick = ({ shiftId }) => {
    if (!shiftId) return;
    var startDate = selectedDate?.startDate;
    var endDate = selectedDate?.endDate;
    if (hasPermission(PermissionKeys.VIEW_SHIFT)) {
      handleRedirect(
        `/shift/detail/${shiftId}?startDate=${startDate}&endDate=${endDate}&typeOptionDate=${typeOptionDate}`,
      );
    }
  };

  return (
    <div className="list-shift">
      <WrapperTable>
        <FnbTable
          className="table-shift mt-3"
          columns={tableSettings.columns}
          pageSize={tableSettings.pageSize}
          dataSource={dataSource}
          currentPageNumber={currentPageNumber}
          total={totalRecords}
          onChangePage={tableSettings.onChangePage}
          scrollX={1478}
          onRowClick={handleOnRowClick}
        />
      </WrapperTable>
    </div>
  );
}
