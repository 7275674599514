import { Col, Row } from "antd";
import { FnbSelect } from "components/fnb-select/fnb-select";
import { BoldAddCircleIcon } from "constants/icons.constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { executeAfter } from "utils/helpers";
import "./fnb-select-add-new-item.style.scss";
import FnbEmpty from "components/fnb-empty/fnb-empty.component";

const FnbSelectAddNewItem = ({
  mode = "single", // single | multiple
  placeholder,
  options,
  onAddNewItem,
  allowClear,
  showSearch,
  fieldItemName = null, // category, branch, product
  onSearch,
  onBlur,
  onMouseDownAddNewItem,
  className,
  ...rest
}) => {
  const [t] = useTranslation();
  const pageData = {
    itemNotFound: t("form.thisCategoryNotFound", { category: fieldItemName ?? "category" }),
    addNewItem: t("form.addNewAsNewCategory", { category: fieldItemName }),
  };

  const [isShowAddItem, setIsShowAddItem] = useState(false);
  const [currentSearchLabel, setCurrentSearchLabel] = useState("");

  const onCheckLabelName = (label) => {
    if (label) {
      const normalizedLabel = label.toLowerCase().removeVietnamese();
      const index = options.findIndex((item) => item.label.toLowerCase().removeVietnamese() === normalizedLabel);
      setIsShowAddItem(index < 0);
    } else {
      setIsShowAddItem(false);
    }
  };

  const onSearchComponent = (e) => {
    onSearch && onSearch(e);
    setCurrentSearchLabel(e);
    onCheckLabelName(e);
  };

  const onBlurComponent = (e) => {
    executeAfter(500, () => {
      onBlur && onBlur(e);
      setCurrentSearchLabel("");
      onCheckLabelName("");
    });
  };

  const onClickAddNew = () => {
    onAddNewItem && onAddNewItem(currentSearchLabel);
  };

  const onMouseDownAddNew = () => {
    onMouseDownAddNewItem && onMouseDownAddNewItem(currentSearchLabel);
  };

  return (
    <FnbSelect
      style={{ width: '100%' }}
      mode={mode}
      placeholder={placeholder}
      options={options}
      allowClear={allowClear}
      showSearch={showSearch}
      onSearch={onSearchComponent}
      onBlur={onBlurComponent}
      popupClassName="fnb-select-add-new-item-dropdown-admin"
      className={className}
      dropdownRender={(menu) => (
        <>
          <Row align="middle" justify="center" className="dropdown-option-add">
            {isShowAddItem ? (
              <>
                <Col span={24}>
                  <div className="empty-text">{pageData.itemNotFound}</div>
                  <Row align="middle" justify="center">
                    <div className="add-new-select w-100" onClick={onClickAddNew} onMouseDown={onMouseDownAddNew}>
                      <BoldAddCircleIcon className="icon-btn-add-new-select" />
                      <div className="keyword-add">{pageData.addNewItem.replace("{{name}}", currentSearchLabel)}</div>
                    </div>
                  </Row>
                </Col>
              </>
            ) : (
              (!options || options?.length === 0) && <FnbEmpty />
            )}
            <Col span={24}>{menu}</Col>
          </Row>
        </>
      )}
      {...rest}
    />
  );
};

export default FnbSelectAddNewItem;
