import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import {
  ButtonTab,
  MainFunctionGroup,
  HeaderTab,
  ListButtons,
  TableTab,
  WrapperHeadingTab,
  WrapperTitledHeader,
  FunctionGroup,
  FunctionGroupTitle,
  FunctionItem,
  ListTicks,
  WrapperIcon,
  MainFunctionGroupTitle,
  ListFunctionGroups,
  FunctionItemTitle,
  TabFeaturePackageContainer,
  TabFeaturePackageContainerScrollX,
} from "./tab-feature-package.styled";
import { MinusSingleOutlinedIcon, TickCircleStepIcon } from "constants/icons.constants";
import { isStoreInternational } from "utils/helpers";

const TabFeaturePackage = ({ t, mainGroupFunctions, minWidthTableFunctions = 850 }) => {
  const isInternational = isStoreInternational();
  return (
    <TabFeaturePackageContainer>
      <TabFeaturePackageContainerScrollX minWidth={minWidthTableFunctions}>
        <WrapperHeadingTab>
          <WrapperTitledHeader>
            <FnbTypography
              text={t("package.coreFeatures")?.toUpperCase()}
              variant={theme.typography["h1-bold"]}
              color={theme.colors.primary.main}
            />
          </WrapperTitledHeader>
          <HeaderTab>
            <ListButtons>
              <ButtonTab>
                <FnbTypography text="POS" variant={theme.typography["h2-medium"]} color={theme.colors.default.white} />
              </ButtonTab>
              {!isInternational && (
                <ButtonTab>
                  <FnbTypography
                    text="APP"
                    variant={theme.typography["h2-medium"]}
                    color={theme.colors.default.white}
                  />
                </ButtonTab>
              )}
              <ButtonTab>
                <FnbTypography text="WEB" variant={theme.typography["h2-medium"]} color={theme.colors.default.white} />
              </ButtonTab>
            </ListButtons>
          </HeaderTab>
        </WrapperHeadingTab>

        <TableTab>
          {mainGroupFunctions?.map((mainGroup) => {
            return (
              <MainFunctionGroup>
                <MainFunctionGroupTitle>
                  <FnbTypography
                    variant={theme.typography["b1-bold"]}
                    color={theme.colors.default.white}
                    text={t(mainGroup?.localizationName)}
                  />
                </MainFunctionGroupTitle>
                <ListFunctionGroups>
                  {mainGroup?.functionGroups?.map((functionGroup) => {
                    return (
                      <FunctionGroup>
                        <FunctionGroupTitle>
                          <FnbTypography
                            text={t(functionGroup?.localizationName)}
                            variant={theme.typography["b1-bold"]}
                          />
                        </FunctionGroupTitle>

                        {functionGroup?.functions?.map((functionItem) => {
                          return (
                            <FunctionItem>
                              <FunctionItemTitle isInternational={isInternational}>
                                <FnbTypography text={t(functionItem?.localizationName)} />
                              </FunctionItemTitle>
                              <ListTicks isInternational={isInternational}>
                                <WrapperIcon>
                                  {functionItem?.isAvailablePOS ? <TickCircleStepIcon /> : <MinusSingleOutlinedIcon />}
                                </WrapperIcon>
                                {!isInternational && (
                                  <WrapperIcon>
                                    {functionItem?.isAvailableAPP ? (
                                      <TickCircleStepIcon />
                                    ) : (
                                      <MinusSingleOutlinedIcon />
                                    )}
                                  </WrapperIcon>
                                )}
                                <WrapperIcon>
                                  {functionItem?.isAvailableWEB ? <TickCircleStepIcon /> : <MinusSingleOutlinedIcon />}
                                </WrapperIcon>
                              </ListTicks>
                            </FunctionItem>
                          );
                        })}
                      </FunctionGroup>
                    );
                  })}
                </ListFunctionGroups>
              </MainFunctionGroup>
            );
          })}
        </TableTab>
      </TabFeaturePackageContainerScrollX>
    </TabFeaturePackageContainer>
  );
};

export default TabFeaturePackage;
