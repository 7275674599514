import BlogDetailDefaultImgTop from '../../../assets/images/blog-detail-default-img-top.png';

export const blogBannerImgs = {
  url: "javascript:;",
  image: "../../../../assets/images/blog-list-header.png",
};

export const blogListDefault = {
  blogs: [
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 1",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 2",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 4",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 4",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
    {
      id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072bf",
      bannerImageUrl: BlogDetailDefaultImgTop,
      createdTime: "2023-01-04T08:31:08.9742141",
      creator: "DiDi",
      title: "Beautiful Sunset",
      totalView: 99,
      blogCategory: "Tag 5",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standa industry. Lorem Ipsum has been the industry's standaindustry. Lorem Ipsum has been the industry's standa",
    },
  ],
  total: 9,
};

export const blogListCategoriesDefault = [
  { id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b1", name: "Tag 1", total: "2" },
  { id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b2", name: "Tag 2", total: "2" },
  { id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b3", name: "Tag 3", total: "2" },
  { id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b4", name: "Tag 4", total: "2" },
  { id: "d1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b5", name: "Tag 5", total: "2" },
];

export const blogListRecentPostDefault = [
  {
    id: "a1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b1",
    bannerImageUrl: BlogDetailDefaultImgTop,
    title: "Lorem Ipsum",
    createdTime: "2023-01-01T08:31:08.9742141",
  },
  {
    id: "a1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b2",
    bannerImageUrl: BlogDetailDefaultImgTop,
    title: "Lorem Ipsum",
    createdTime: "2023-01-01T08:31:08.9742141",
  },
  {
    id: "a1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b3",
    bannerImageUrl: BlogDetailDefaultImgTop,
    title: "Lorem Ipsum",
    createdTime: "2023-01-01T08:31:08.9742141",
  },
  {
    id: "a1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b4",
    bannerImageUrl: BlogDetailDefaultImgTop,
    title: "Lorem Ipsum",
    createdTime: "2023-01-01T08:31:08.9742141",
  },
  {
    id: "a1d50e8a-20c0-4a0b-8ff7-7e8e2e3072b5",
    bannerImageUrl: BlogDetailDefaultImgTop,
    title: "Lorem Ipsum",
    createdTime: "2023-01-01T08:31:08.9742141",
  },
];
