export const PaymentMethodType = {
  /// <summary>
  /// Cash
  /// </summary>
  Cash: 3,

  /// <summary>
  /// Momo
  /// </summary>
  Momo: 0,

  /// <summary>
  /// BankTransfer
  /// </summary>
  BankTransfer: 6,

  /// <summary>
  /// Personal
  /// </summary>
  Personal: 7,
};

export const PaymentMethodId = {
  /// <summary>
  /// PayPal
  /// </summary>
  PayPal: "ebc2e437-7fb3-ee11-9081-e4aaea620f35",
};

export const MomoResultCode = {
  Successful: 0,
  InvalidSignature: 11007,
};
