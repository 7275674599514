import { PlusCircleIcon } from "constants/icons.constants";
import { capitalizeFirstLetterEachWord, hasPermission } from "utils/helpers";
import "./fnb-add-new-button.scss";
import { FnbButton } from "components/fnb-button/fnb-button";
import { useTranslation } from "react-i18next";

export function FnbAddNewButton({
  className = "",
  onClick,
  text,
  htmlType,
  permission,
  disabled,
  hideIcon,
  idControl = "btn-add-new",
}) {
  const [t] = useTranslation();
  const renderButton = () => {
    const titleFormatted = text ? capitalizeFirstLetterEachWord(text) : t("button.addNew");
    if (hasPermission(permission)) {
      return (
        <FnbButton
          iconHeader={hideIcon ? <></> : <PlusCircleIcon />}
          className={`${className}`}
          onClick={onClick}
          type={htmlType}
          disabled={disabled}
          id={idControl}
          text={titleFormatted}
        />
      );
    }

    if (!permission) {
      return (
        <FnbButton
          iconHeader={hideIcon ? <></> : <PlusCircleIcon />}
          className={`${className}`}
          onClick={onClick}
          type={htmlType}
          disabled={disabled}
          id={idControl}
          text={titleFormatted}
        />
      );
    }
  };
  return <>{renderButton()}</>;
}
