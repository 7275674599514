import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContactPage } from "../../assets/icons.constants";
import ContactInformationImageDefault from "../../assets/images/bg-contact-information-default.png";
import ContactHeaderImageDefault from "../../assets/images/contact-header-default.png";
import ContactUsImageDefault from "../../assets/images/contact-us-default.png";
import CustomizationGroup from "../../components/customization-group-component/customization-group.page";
import FnbCheckBox from "../../components/fnb-checkbox/fnb-checkbox";
import FnbFroalaEditor from "../../components/fnb-froala-editor";
import { FnbInput } from "../../components/fnb-input/fnb-input.component";
import { FnbSelectMultiple } from "../../components/fnb-select-multiple/fnb-select-multiple";
import SelectBackgroundComponent from "../../components/select-background.component";
import SelectColorGroupComponent from "../../components/select-color-group.component";
import PageType from "../../constants/page-type.constants";
import { theme1ElementCustomize, theme1IdScrollView } from "../../constants/store-web-page.constants";
import defaultConfig from "../../default-store.config";

const ContactCustomize = (props) => {
  const [t] = useTranslation();
  const { form, updateFormValues, pageConfig, defaultActiveKey, clickToScroll, listBranch = [] } = props;
  const bestDisplay = "1920 x 569 px";
  const [pageContentEditor, setPageContentEditor] = useState("");
  const defaultThemePageConfig = defaultConfig?.pages?.find((p) => p.id === PageType.CONTACT_PAGE);
  const [showHeader, setShowHeader] = useState(true);
  const [showInformation, setShowInformation] = useState(true);
  const [showContactUs, setShowContactUs] = useState(true);
  const [isAllBranches, setIsAllBranches] = useState(pageConfig?.config?.information?.isAllBranches ?? true);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (updateFormValues) {
      updateFormValues();
    }
    const focusElementTimeout = setTimeout(() => {
      setFocusElement(clickToScroll);
    }, 100);

    getInitData();
    initFormDataContact();

    return () => clearTimeout(focusElementTimeout);
  }, []);

  const setColorGroupId = (section, defaultColorGroupId) => {
    let colorGroupId = form?.getFieldsValue()?.config?.[section]?.colorGroupId ?? defaultColorGroupId;
    if (pageConfig?.config?.[section]?.colorGroupId) {
      colorGroupId = pageConfig?.general?.color?.colorGroups?.find(
        (item) => item?.id === pageConfig?.config?.[section]?.colorGroupId,
      )?.id;
    }
    return colorGroupId;
  };

  const initFormDataContact = () => {
    const defaultColorGroupId = pageConfig?.general?.color?.colorGroups?.[0]?.id;

    const headerColorGroupId = setColorGroupId("header", defaultColorGroupId);
    const informationColorGroupId = setColorGroupId("information", defaultColorGroupId);
    const contactUsColorGroupId = setColorGroupId("contactUs", defaultColorGroupId);

    form.setFieldValue(["config", "header", "colorGroupId"], headerColorGroupId);
    form.setFieldValue(["config", "information", "colorGroupId"], informationColorGroupId);
    form.setFieldValue(["config", "contactUs", "colorGroupId"], contactUsColorGroupId);
  };

  const getInitData = () => {
    const contentEditor = form?.getFieldsValue()?.config?.contactUs?.description;
    setPageContentEditor(contentEditor);
  };

  const setFocusElement = (elementId) => {
    try {
      const element = document.querySelector(elementId);
      if (element) {
        element.className = "tc-on-focus";
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        window.oldElements = elementId;
      }
    } catch {}
  };

  const pageData = {
    title: t("storeWebPage.footerThemeConfiguration.title"),
    header: t("storeWebPage.header.title"),
    placeHolder: t("menuManagement.menuItem.hyperlink.dynamic.blog.title"),
    blogTitle: t("menuManagement.menuItem.hyperlink.dynamic.blog.title"),
    titleHeader: t("storeWebPage.footerThemeConfiguration.title"),
    maxSizeUploadMb: 5,
    maxSizeUploadBackgroundImage: 20,
    border: {
      header: "#contactHeader",
      information: "#contactInformation",
      contactUs: "#contactUs",
    },
    contactPage: t("storeWebPage.contact.contactPage", "Contact page"),
    contactTitle: t("storeWebPage.contact.contactTitle", "Contact"),
    information: t("storeWebPage.contact.information", "Information components"),
    contactUs: t("storeWebPage.contact.contactUs", "Contact us components"),
    description: t("storeWebPage.contact.description", "Description"),
    contactUsPlaceholder: t("storeWebPage.contact.contactUsPlaceholder", "Contact us"),
    enterTitle: t("storeWebPage.contact.enterTitle", "Enter title"),
    sendUsFeedback: t("contact.sendUsFeedback", "Send us feedback"),
    subTitleFeedback: t(
      "contact.subTitleFeedback",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas integer nunc mi, ac aliquet pretium platea. Nisl eu nulla nunc, risus eu, volutpat. Blandit in cursus purus nibh.",
    ),
    allBranches: t("productCategory.branch.all"),
    selectBranchValidateMessage: t("productCategory.branch.selectBranchValidateMessage"),
    selectBranchesPlaceholder: t("contact.selectCategoryPlaceholder", "Select Branches"),
  };

  const onSelectAllProduct = (event) => {
    const isChecked = event?.target?.checked;
    setSelectedValues([]);
    setIsAllBranches(isChecked);
  };

  const onChangeCollapse = (key, tag) => {
    switch (tag) {
      case pageData.border.header:
        key.length <= 0 ? setShowHeader(false) : setShowHeader(true);
        break;
      case pageData.border.information:
        key.length <= 0 ? setShowInformation(false) : setShowInformation(true);
        break;
      case pageData.border.contactUs:
        key.length <= 0 ? setShowContactUs(false) : setShowContactUs(true);
        break;
      default:
        break;
    }
  };

  const headerContact = () => {
    return (
      <Row id={`_${pageData.border.header}`} className="mt-2" clickToScroll={clickToScroll}>
        <SelectBackgroundComponent
          {...props}
          formItemPreName={["config", "header"]}
          backgroundCustomize={pageConfig?.config?.header}
          bestDisplay={bestDisplay}
          maxSizeUploadMb={pageData.maxSizeUploadBackgroundImage}
          defaultThemeColor={defaultThemePageConfig?.config?.header?.backgroundColor}
          isRequired={false}
          nameComponents="ContactHeader"
          defaultImage={ContactHeaderImageDefault}
          isImageSelectedDefault={true}
        />
        <SelectColorGroupComponent {...props} formItemPreName={["config", "header"]} />
        <Row gutter={[8, 16]} align="middle" style={{ width: "100%" }}>
          <Col span={24}>{pageData.titleHeader}</Col>
          <Col span={24}>
            <Form.Item
              name={["config", "header", "title"]}
              rules={[
                {
                  type: "string",
                  max: 100,
                },
              ]}
            >
              <FnbInput
                className="fnb-input-with-count"
                placeholder={pageData.contactTitle}
                defaultValue={pageData.contactTitle}
                maxLength={100}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
    );
  };

  const informationContact = () => {
    return (
      <Row id={`_${pageData.border.information}`} className="mt-2" clickToScroll={clickToScroll}>
        <SelectBackgroundComponent
          {...props}
          formItemPreName={["config", "information"]}
          backgroundCustomize={pageConfig?.config?.information}
          bestDisplay={bestDisplay}
          maxSizeUploadMb={pageData.maxSizeUploadBackgroundImage}
          defaultThemeColor={defaultThemePageConfig?.config?.information?.backgroundColor}
          isRequired={false}
          nameComponents="ContactInformation"
          defaultImage={ContactUsImageDefault}
          isImageSelectedDefault={true}
        />
        <SelectColorGroupComponent {...props} formItemPreName={["config", "information"]} />
        <Row>
          <Col span={12} className="">
            <Form.Item
              name={["config", "information", "isAllBranches"]}
              valuePropName="checked"
              initialValue={isAllBranches}
            >
              <FnbCheckBox onChange={(event) => onSelectAllProduct(event)}>
                <span style={{ whiteSpace: "nowrap" }}>{pageData.allBranches}</span>
              </FnbCheckBox>
            </Form.Item>
          </Col>
          <Col span={24} hidden={isAllBranches}>
            <Form.Item
              name={["config", "information", "branchIds"]}
              rules={[
                {
                  required: !isAllBranches,
                  message: pageData.selectBranchValidateMessage,
                },
              ]}
            >
              <FnbSelectMultiple
                placeholder={pageData.selectBranchesPlaceholder}
                defaultValue={selectedValues}
                className="w-100"
                allowClear
                showSearch
                option={listBranch?.map((item) => ({
                  id: item?.id,
                  value: item?.id,
                  name: item?.name,
                }))}
              ></FnbSelectMultiple>
            </Form.Item>
          </Col>

          <Col span={24} hidden={!isAllBranches}>
            <FnbSelectMultiple disabled={true}></FnbSelectMultiple>
          </Col>
        </Row>
      </Row>
    );
  };

  const contactUs = () => {
    return (
      <Row id={`_${pageData.border.contactUs}`} className="mt-2" clickToScroll={clickToScroll}>
        <SelectBackgroundComponent
          {...props}
          formItemPreName={["config", "contactUs"]}
          backgroundCustomize={pageConfig?.config?.contactUs}
          bestDisplay={bestDisplay}
          maxSizeUploadMb={pageData.maxSizeUploadBackgroundImage}
          defaultThemeColor={defaultThemePageConfig?.config?.contactUs?.backgroundColor}
          isRequired={false}
          isImageSelectedDefault={true}
          nameComponents="ContactUs"
          defaultImage={ContactInformationImageDefault}
        />
        <SelectColorGroupComponent {...props} formItemPreName={["config", "contactUs"]} />

        <Row gutter={[8, 16]} align="middle" style={{ width: "100%" }}>
          <Col span={24}>{pageData.header}</Col>
          <Col span={24}>
            <Form.Item
              name={["config", "contactUs", "title"]}
              rules={[
                {
                  type: "string",
                  max: 100,
                },
              ]}
            >
              <FnbInput
                className="fnb-input-with-count"
                placeholder={pageData.sendUsFeedback}
                maxLength={100}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 16]} align="middle" style={{ width: "100%" }}>
          <Col span={24}>{pageData.title}</Col>
          <Col span={24}>
            <Form.Item
              name={["config", "contactUs", "subTitle"]}
              rules={[
                {
                  type: "string",
                  max: 100,
                },
              ]}
            >
              <FnbInput
                className="fnb-input-with-count"
                placeholder={pageData.subTitleFeedback}
                maxLength={100}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 16]} align="middle">
          <Col span={24}>{pageData.description}</Col>
          <Col span={24}>
            <Form.Item className="mb-0" name={["config", "contactUs", "description"]}>
              <FnbFroalaEditor value={pageContentEditor} onChange={(value) => setPageContentEditor(value)} />
            </Form.Item>
          </Col>
        </Row>
      </Row>
    );
  };

  const groupCollapse = [
    {
      title: pageData.header,
      content: headerContact(),
      onChangeEye: pageData.border.header,
      icon: null,
      isShowKey: showHeader,
      clickToScroll: theme1IdScrollView.HeaderContact,
      customizeKey: theme1ElementCustomize.HeaderContact,
      isShowRightIconWhenHoverMouse: true,
      isShowTooltip: false,
      titleIconRight: null,
    },
    {
      title: pageData.information,
      content: informationContact(),
      onChangeEye: pageData.border.information,
      icon: null,
      isShowKey: showInformation,
      clickToScroll: theme1IdScrollView.InformationContact,
      customizeKey: theme1ElementCustomize.InformationContact,
      isShowRightIconWhenHoverMouse: true,
      isShowTooltip: false,
      titleIconRight: null,
    },
    {
      title: pageData.contactUs,
      content: contactUs(),
      onChangeEye: pageData.border.contactUs,
      icon: null,
      isShowKey: showContactUs,
      clickToScroll: theme1IdScrollView.UsContact,
      customizeKey: theme1ElementCustomize.UsContact,
      isShowRightIconWhenHoverMouse: true,
      isShowTooltip: false,
      titleIconRight: null,
    },
  ];

  return (
    <>
      {groupCollapse?.map((group, index) => {
        return (
          <CustomizationGroup
            title={group.title}
            isNormal={true}
            defaultActiveKey={defaultActiveKey + "." + ++index}
            content={group.content}
            icon={group.icon}
            className={"size-group"}
            isShowKey={group.isShowKey}
            onChangeCollapse={(value) => onChangeCollapse(value, group.onChangeEye)}
            clickToScroll={group.clickToScroll}
            customizeKey={group.customizeKey}
            isShowRightIconWhenHoverMouse={group.isShowRightIconWhenHoverMouse}
            isShowTooltip={group.isShowTooltip}
            titleIconRight={group.titleIconRight}
          ></CustomizationGroup>
        );
      })}
    </>
  );
};

export const ContactCustomizePage = [
  {
    icon: <IconContactPage />,
    title: "storeWebPage.contact.contactPage",
    isNormal: true,
    defaultActiveKey: 0,
    iconRight: null,
    collapsible: false,
    isShowRightIconWhenHoverMouse: true,
    name: "contact",
    content: (props) => {
      return <ContactCustomize {...props} />;
    },
  },
];
