import { useEffect, useState } from "react";
import "./reset-password.page.scss";
import { useLocation } from "react-router-dom";
import FnbCard from "components/fnb-card/fnb-card.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { Form, Image, message } from "antd";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { ArrowLeftIcon, CloseCircle2Icon, TickCircle2Icon } from "constants/icons.constants";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { FnbButton } from "components/fnb-button/fnb-button";
import resetPasswordDataService from "data-services/reset-password/reset-password-data.service";
import qs from "query-string";
import storeDefault from "../../assets/images/store-default.jpg";
import { useTranslation } from "react-i18next";
import i18n from "utils/i18n";

export default function ResetPassword() {
  const screens = {
    selectStore: 0,
    resetPassword: 1,
    result: 2,
  };
  const [currentScreen, setCurrentScreen] = useState();
  const [stores, setStores] = useState([]);
  const [isSuccess, setIsSuccess] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [t] = useTranslation();
  const pageData = {
    notFoundStore: t("forgotPassword.notFoundStore"),
    selectStore: {
      title: t("forgotPassword.selectStore.title"),
      subtitle: t("forgotPassword.selectStore.subtitle"),
    },
    resetPasswordForm: {
      title: t("forgotPassword.resetPasswordForm.title"),
      newPassword: t("forgotPassword.resetPasswordForm.newPassword"),
      placeholderNewPassword: t("forgotPassword.resetPasswordForm.placeholderNewPassword"),
      pleaseInputNewPassword: t("forgotPassword.resetPasswordForm.pleaseInputNewPassword"),
      newPasswordWrongLength: t("forgotPassword.resetPasswordForm.newPasswordWrongLength"),
      confirmNewPassword: t("forgotPassword.resetPasswordForm.confirmNewPassword"),
      placeholderConfirmNewPassword: t("forgotPassword.resetPasswordForm.placeholderConfirmNewPassword"),
      confirmFailed: t("forgotPassword.resetPasswordForm.confirmFailed"),
      submit: t("forgotPassword.resetPasswordForm.submit"),
    },
    result: {
      titleSuccess: t("forgotPassword.result.titleSuccess"),
      titleFailed: t("forgotPassword.result.titleFailed"),
    },
  };
  const [form] = Form.useForm();

  const searchUrl = useLocation().search;
  const loadParams = () => {
    // use replace because qs.parse replaces + with " "
    const search = searchUrl.replace(/\+/g, "_____");
    let params = qs.parse(search, { ignoreQueryPrefix: true });
    params.email = params.email.replace("_____", "+");
    return params;
  };

  const params = loadParams();
  const [endTime, setEndTime] = useState(null);

  const calculateTimeLeft = () => {
    const endDate = new Date(endTime).getTime();
    const now = new Date().getTime();
    const timeLeft = endDate - now;
    if (timeLeft <= 0 && endTime) {
      currentScreen(screens.result);
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    if (endTime) {
      const interval = setInterval(() => {
        calculateTimeLeft();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [endTime]);

  useEffect(() => {
    if (params && params.token && params.email) {
      i18n.changeLanguage(params.languageCode);
      resetPasswordDataService.getStoreByEmailAsync({ email: params.email, token: params.token }).then((res) => {
        if (res && res.data) {
          if (!res.data.isTokenValid) {
            setIsSuccess(false);
            setCurrentScreen(screens.result);
          } else if (res.data.stores?.length <= 0) {
            setIsSuccess(false);
            setCurrentScreen(screens.result);
            message.error(t(pageData.notFoundStore));
          } else if (res.data.stores?.length > 1) {
            setStores(res.data.stores);
            setEndTime(res.data.expiredTime);
            setCurrentScreen(screens.selectStore);
          } else {
            setAccountId(res.data.stores[0]?.accountId);
            setEndTime(res.data.expiredTime);
            setCurrentScreen(screens.resetPassword);
          }
        }
      });
    } else {
      setCurrentScreen(null);
    }
  }, []);

  const renderScreen = () => {
    switch (currentScreen) {
      case screens.selectStore:
        return selectStore();
      case screens.resetPassword:
        return resetPassword();
      case screens.result:
        return result();
      default:
        return <></>;
    }
  };

  const selectStore = () => {
    return (
      <FnbCard className="select-store-modal" hideFooter={true}>
        <div className="header">
          <FnbTypography variant="h1-bold" text={pageData.selectStore.title} />
          <p
            className="fnb-typography__type--b1-regular"
            dangerouslySetInnerHTML={{ __html: t(pageData.selectStore.subtitle, { email: params.email }) }}
          />
        </div>
        <div className="content">{stores?.map((store) => renderStoreItem(store))}</div>
      </FnbCard>
    );
  };

  const renderStoreItem = (store) => {
    return (
      <div
        className="store-item"
        onClick={() => {
          setAccountId(store?.accountId);
          setCurrentScreen(screens.resetPassword);
        }}
      >
        <Image
          wrapperClassName="square-frame-pictures"
          src={store.storeThumbnail || storeDefault}
          preview={false}
          width={64}
          height={64}
        />
        <FnbTooltip onlyShowWhenEllipsis title={store?.storeName} />
      </div>
    );
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      resetPasswordDataService
        .resetPasswordAsync({
          email: params.email,
          password: values.newPassword,
          token: params.token,
          accountId: accountId,
        })
        .then((res) => {
          if (res) {
            if (!res.succeeded) {
              message.error(res?.message);
            } else if (!res?.data?.isTokenValid) {
              setIsSuccess(false);
              setEndTime(null);
              setCurrentScreen(screens.result);
            } else if (!res?.data?.isPasswordValid) {
              message.error(res?.message);
            } else {
              setIsSuccess(true);
              setEndTime(null);
              setCurrentScreen(screens.result);
            }
          }
        });
    });
  };

  const onChangePassword = () => {
    if (form.getFieldValue("confirmNewPassword")) {
      form.validateFields();
    }
  };

  const resetPassword = () => {
    return (
      <FnbCard hideFooter={true}>
        <div className="reset-password-modal">
          <div className="header">
            {stores?.length > 1 && (
              <ArrowLeftIcon onClick={() => setCurrentScreen(screens.selectStore)} width={24} height={24} />
            )}
            <FnbTypography variant="h1-bold" text={pageData.resetPasswordForm.title} />
          </div>
          <Form className="content" layout="vertical" form={form} onFinish={onFinish}>
            <FnbFormItem
              label={pageData.resetPasswordForm.newPassword}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage message={pageData.resetPasswordForm.pleaseInputNewPassword} />,
                },
                {
                  validator: (_, value) => {
                    if (value && (value.length < 6 || value.length >= 100)) {
                      return Promise.reject(
                        <InputValidateMessage message={pageData.resetPasswordForm.newPasswordWrongLength} />,
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <FnbInput
                type="password"
                onChange={onChangePassword}
                placeholder={pageData.resetPasswordForm.placeholderNewPassword}
              />
            </FnbFormItem>
            <FnbFormItem
              label={pageData.resetPasswordForm.confirmNewPassword}
              name="confirmNewPassword"
              rules={[
                {
                  required: true,
                  message: <InputValidateMessage message={pageData.resetPasswordForm.pleaseInputNewPassword} />,
                },
                {
                  validator: (_, value) => {
                    if (value && value !== form.getFieldValue("newPassword")) {
                      return Promise.reject(
                        <InputValidateMessage message={pageData.resetPasswordForm.confirmFailed} />,
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <FnbInput type="password" placeholder={pageData.resetPasswordForm.placeholderConfirmNewPassword} />
            </FnbFormItem>
            <FnbButton type="submit" className="submit" width={"100%"} text={pageData.resetPasswordForm.submit} />
          </Form>
        </div>
      </FnbCard>
    );
  };

  const result = () => {
    return (
      <FnbCard className="result-modal" hideFooter={true}>
        {isSuccess != null && (
          <>
            {isSuccess ? <TickCircle2Icon width={80} height={80} /> : <CloseCircle2Icon width={80} height={80} />}
            <FnbTypography
              variant="h2-regular"
              text={isSuccess ? pageData.result.titleSuccess : pageData.result.titleFailed}
            />
          </>
        )}
      </FnbCard>
    );
  };

  return <div className="reset-password-page">{renderScreen()}</div>;
}
