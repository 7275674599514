import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DefaultShippingName } from "../../../../../constants/string.constants";
import { setDeliveryAddress, setNearestStoreBranches } from "../../../../../modules/session/session.actions";
import { getBranchesByCustomerAddress } from "../../../../../services/addressServices";
import { getInitialSelectedBranchInfo } from "../../../../../utils/helpers";
import { CheckoutDeliveryIcon, CheckoutPickupIcon } from "../../../../assets/icons.constants";
import checkoutTimeIcon from "../../../../assets/icons/checkout-time.svg";
import locationIcon from "../../../../assets/icons/location.svg";
import { SelectDeliveryTimeComponent } from "../../../../components/select-delivery-time/select-delivery-time.component";
import { enumOrderType } from "../../../../constants/enums";
import { DateFormat } from "../../../../constants/string.constants";

export default function CheckoutDeliveryInfo(props) {
  const {
    deliveryDate,
    setDeliveryDate,
    timeSlot,
    setTimeSlot,
    workingHour,
    colorGroup,
    pageData,
    isHasAddress,
    openShippingAddressModal,
    estimateTime,
    isReloadSelectTime,
  } = props;
  const [t] = useTranslation();
  const translateData = {
    now: t("deliveryTime.now"),
    today: t("deliveryTime.today", "Today"),
    tomorrow: t("deliveryTime.tomorrow"),
    theDayAfterTomorrow: t("deliveryTime.theDayAfterTomorrow"),
    time: t("deliveryTime.time"),
    deliveryDateTrans: t("deliveryTime.deliveryDateTrans"),
    deliveryTimeTrans: t("deliveryTime.deliveryTimeTrans", "Delivery time transfer"),
    cancel: t("deliveryTime.cancel"),
    confirm: t("deliveryTime.confirm"),
    deliveryTo: t("addUserLocation.deliveryTo", "Delivery to"),
    deliveryFrom: t("addUserLocation.deliveryFrom", "Delivery from"),
    pickUp: t("addUserLocation.pickUp", "Tự lấy hàng"),
    errorNotChoosePickupBranch: t("addUserLocation.errorNotChoosePickupBranch", "Bạn chưa chọn chi nhánh lấy hàng"),
    change: t("addUserLocation.change", "Change"),
    defaultHomeAddressLabel: t("myProfile.addressList.homeLabel"),
    defaultWorkAddressLabel: t("myProfile.addressList.workLabel"),
    editAddress: t("myProfile.addressList.editAddress", "Edit"),
  };
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const nearestStoreBranches = useSelector((state) => state?.session?.nearestStoreBranches);
  const [isOpenDeliveryTime, setIsOpenDeliveryTime] = useState(false);
  const [dayText, setDayText] = useState(null);
  const moment = require("moment");
  const dispatch = useDispatch();

  useEffect(() => {
    if (deliveryAddress?.receiverAddress == null) {
      getNearestStoreBranchesFromApi(0, 0);
    } else {
      getNearestStoreBranchesFromApi(deliveryAddress?.receiverAddress?.lat, deliveryAddress?.receiverAddress?.lng);
    }
  }, []);

  const getNearestStoreBranchesFromApi = async (lat, lng) => {
    if (deliveryAddress && deliveryAddress?.branchAddress) {
      const res = await getBranchesByCustomerAddress(lat, lng, true, nearestStoreBranches);
      if (res) {
        const branchesByCustomerAddress = res?.data?.branchesByCustomerAddress;
        if (branchesByCustomerAddress) {
          dispatch(setNearestStoreBranches(branchesByCustomerAddress));
          const branchAddressDeliveryFromAPI = branchesByCustomerAddress?.find(
            (x) => x.branchId === deliveryAddress?.branchAddress?.id,
          );
          const branchSelectedDefault = getInitialSelectedBranchInfo([branchAddressDeliveryFromAPI]);
          if (
            branchSelectedDefault &&
            (branchSelectedDefault?.distance !== deliveryAddress?.branchAddress?.distance ||
              branchSelectedDefault?.lat !== deliveryAddress?.branchAddress?.lat ||
              branchSelectedDefault?.lng !== deliveryAddress?.branchAddress?.lng)
          ) {
            const currentDeliveryAddress = {
              ...deliveryAddress,
              receiverAddress: deliveryAddress?.receiverAddress,
              branchAddress: branchSelectedDefault,
            };
            dispatch(setDeliveryAddress(currentDeliveryAddress));
          }
        }
      }
    }
  };

  function handleOpenSelectDeliveryTime() {
    if (workingHour) {
      setIsOpenDeliveryTime(true);
    }
  }

  function openBranchAddressModal() {
    const chooseAddressModal = document.getElementsByClassName("store-branch-address-select-button")[0];
    chooseAddressModal?.click();
  }

  function handleCloseSelectDeliveryTime() {
    setIsOpenDeliveryTime(false);
  }

  function getDayTimeText() {
    let text = `${translateData.today}`;
    ///Unselect
    if (!deliveryDate && !timeSlot) {
      text = `${translateData.today}`;
    }
    ///Today
    else if (deliveryDate === moment().format(DateFormat.YYYY_MM_DD)) {
      text = `${translateData.today} - ${timeSlot ?? ""}`;
    }
    ///Tomorrow
    else if (deliveryDate === moment().add(1, "days").format(DateFormat.YYYY_MM_DD)) {
      text = `${translateData.tomorrow} - ${timeSlot ?? ""}`;
    }
    ///Day after Tomorrow
    else if (deliveryDate === moment().add(2, "days").format(DateFormat.YYYY_MM_DD)) {
      text = `${translateData.theDayAfterTomorrow} - ${timeSlot ?? ""}`;
    } else {
      text = `${dayText}, ${moment(deliveryDate).format(DateFormat.DD_MM_YYYY)} - ${timeSlot ?? ""}`;
    }

    return text;
  }

  function getShippingNameText() {
    if (deliveryAddress?.orderType === enumOrderType.PICK_UP) {
      return deliveryAddress?.branchAddress?.title ?? translateData.errorNotChoosePickupBranch;
    } else {
      switch (deliveryAddress?.receiverAddress?.name) {
        case DefaultShippingName.Work:
          return translateData.defaultWorkAddressLabel;

        case DefaultShippingName.Home:
          return translateData.defaultHomeAddressLabel;

        default:
          return deliveryAddress?.receiverAddress?.name?.split(",")[0];
      }
    }
  }

  return (
    <div
      className="box_delivery card-wrapper card-pacing-bottom"
      style={{
        border: deliveryAddress?.orderType === enumOrderType.PICK_UP ? "1px solid #FFF4EB" : "1px solid #E1FFF0",
      }}
    >
      <div
        className="delivery card-header"
        style={{
          backgroundColor: deliveryAddress?.orderType === enumOrderType.PICK_UP ? "#FFF4EB" : "#E1FFF0",
        }}
      >
        <div
          className="header-title"
          style={{ backgroundColor: deliveryAddress?.orderType === enumOrderType.PICK_UP ? "#FF8C21" : "#026F30" }}
        >
          <span className="header-icon">
            {deliveryAddress?.orderType === enumOrderType.PICK_UP ? <CheckoutPickupIcon /> : <CheckoutDeliveryIcon />}
          </span>
          <span className="header-text">
            {deliveryAddress?.orderType === enumOrderType.PICK_UP ? translateData.pickUp : translateData.deliveryTo}
          </span>
        </div>
        <div
          className="header-button cursor-pointer"
          onClick={() => {
            if (deliveryAddress?.orderType === enumOrderType.PICK_UP) {
              openBranchAddressModal();
            } else {
              openShippingAddressModal();
            }
          }}
          style={{ backgroundColor: deliveryAddress?.orderType === enumOrderType.PICK_UP ? "#FF8C21" : "#026F30" }}
        >
          <span className="header-button-text">{translateData.change}</span>
        </div>
      </div>
      <div className="shipping_location_info">
        <div className="shipping_location_detail">
          <img className="shipping_location_icon" src={locationIcon} alt="" />
          <div className="shipping_name_address">
            <span className="shipping_name">{`${translateData.deliveryFrom}: ${deliveryAddress?.branchAddress?.title}`}</span>
            <div className="shipping_address">{deliveryAddress?.branchAddress?.addressDetail}</div>
          </div>
        </div>
        <div
          className="shipping_location_button cursor-pointer"
          onClick={() => {
            if (deliveryAddress?.orderType === enumOrderType.PICK_UP) {
              openBranchAddressModal();
            } else {
              openShippingAddressModal();
            }
          }}
        >
          <span className="shipping_location_button_text">{translateData.editAddress}</span>
        </div>
      </div>
      {deliveryAddress?.orderType === enumOrderType.DELIVERY && (
        <div className="shipping_location_info">
          <div className="shipping_location_detail">
            <img className="shipping_location_icon" src={locationIcon} alt="" />
            <div className="shipping_name_address">
              <span className="shipping_name">{translateData.deliveryTo}</span>
              <div className="shipping_address">
                {!isHasAddress() ? pageData.noAddressMessage : deliveryAddress?.receiverAddress?.address}
              </div>
            </div>
          </div>
          <div
            className="shipping_location_button cursor-pointer"
            onClick={() => {
              if (deliveryAddress?.orderType === enumOrderType.PICK_UP) {
                openBranchAddressModal();
              } else {
                openShippingAddressModal();
              }
            }}
          >
            <span className="shipping_location_button_text">{translateData.editAddress}</span>
          </div>
        </div>
      )}
      <div className="shipping_location_info">
        <div className="shipping_location_detail">
          <img className="shipping_location_icon" src={checkoutTimeIcon} alt="" />
          <div className="shipping_name_address">
            <span className="shipping_name">{translateData.deliveryTimeTrans}</span>
            <div className="shipping_address">
              <span>{getDayTimeText()}</span>
            </div>
          </div>
        </div>
        <div className="shipping_location_button cursor-pointer" onClick={handleOpenSelectDeliveryTime}>
          <span className="shipping_location_button_text">{translateData.editAddress}</span>
        </div>
      </div>
      <SelectDeliveryTimeComponent
        isReloadSelectTime={isReloadSelectTime}
        workingHour={workingHour}
        isOpenDeliveryTime={isOpenDeliveryTime}
        handleCancel={handleCloseSelectDeliveryTime}
        colorConfig={colorGroup}
        deliveryDate={deliveryDate}
        setDeliveryDate={setDeliveryDate}
        timeSlot={timeSlot}
        setTimeSlot={setTimeSlot}
        setDayText={setDayText}
        estimateTime={estimateTime}
      />
    </div>
  );
}
