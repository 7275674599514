import { useState, useEffect } from "react";
import { Card } from "antd";
import NewSupplier from "./components/new-supplier.component";
import TableSupplier from "./components/table-supplier.component";
import { PermissionKeys } from "constants/permission-key.constants";
import { executeAfter } from "utils/helpers";
import { useTranslation } from "react-i18next";
import supplierDataService from "data-services/supplier/supplier-data.service";
import { tableSettings } from "constants/default.constants";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";

export default function Supplier(props) {
  const [t] = useTranslation();
  const [showAddNewSupplier, setShowAddNewSupplier] = useState(false);
  const [listSupplier, setListSupplier] = useState([]);
  const [keySearch, setKeySearch] = useState("");
  const [totalSupplier, setTotalSupplier] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    btnAddNew: t("supplier.addSupplier"),
    supplierManagement: t("supplier.supplierManagement"),
    addNewSupplier: t("supplier.addNewSupplier"),
  };

  useEffect(() => {
    let fetchData = async () => {
      await initDataTable(tableSettings.page, tableSettings.pageSize, keySearch);
    };
    fetchData();
  }, []);

  const initDataTable = async (pageNumber, pageSize, keySearch) => {
    let res = await supplierDataService.getListSupplierAsync(pageNumber, pageSize, keySearch);
    let suppliers = mappingToDataTable(res.suppliers);
    setListSupplier(suppliers);
    setTotalSupplier(res.total);
  };

  const mappingToDataTable = (suppliers) => {
    return suppliers?.map((item) => {
      return {
        id: item.id,
        code: item.code,
        name: item.name,
        phoneNumber: item.phoneNumber,
        phoneCode: item.address.country.phonecode,
        email: item.email,
      };
    });
  };

  const onSearchSupplier = (keySearch) => {
    executeAfter(500, async () => {
      setKeySearch(keySearch);
      initDataTable(tableSettings.page, tableSettings.pageSize, keySearch);
    });
  };

  const onChangePage = async (pageNumber, pageSize) => {
    setCurrentPageNumber(pageNumber);
    initDataTable(pageNumber, pageSize, keySearch);
  };

  const onCancel = () => {
    initDataTable(tableSettings.page, tableSettings.pageSize, keySearch);
    setShowAddNewSupplier(false);
  };

  return (
    <>
      {showAddNewSupplier ? (
        <>
          <NewSupplier onCancel={() => onCancel()} />
        </>
      ) : (
        <>
          <FnbHeadingPage
            title={pageData.supplierManagement}
            permissionCreate={PermissionKeys.CREATE_SUPPLIER}
            onCreate={() => setShowAddNewSupplier(true)}
          />
          <div className="clearfix"></div>
          <Card className="fnb-card-custom">
            <TableSupplier
              refreshDataTable={initDataTable}
              dataSource={listSupplier}
              pageSize={tableSettings.pageSize}
              onChangePage={onChangePage}
              onSearch={onSearchSupplier}
              total={totalSupplier}
              currentPageNumber={currentPageNumber}
              setShowAddNew={setShowAddNewSupplier}
            />
          </Card>
        </>
      )}
    </>
  );
}
