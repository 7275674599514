import Moment from "moment";
import actionTypes from "./collection.type";

const initialState = {
  discountCodeCollection: [],
  lastUpdated: Moment.utc(),
};

const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DISCOUNT_CODE_COLLECTION:
      return {
        ...state,
        isLoading: false,
        discountCodeCollection: action.payload,
      };
    case actionTypes.CLEAR_DISCOUNT_CODE_COLLECTION:
      return {
        ...state,
        isLoading: false,
        discountCodeCollection: [],
      };
    default:
      return state;
  }
};

export const discountCodeCollectionSelector = (state) => state?.collection?.discountCodeCollection ?? [];

export default collectionReducer;
