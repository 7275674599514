import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { actions, branches, materials, category, changeCategoryId, isHidden } = props;

  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedMaterialId, setSelectedMaterialId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const defaultValue = "";
  const filterTypes = {
    branch: "branch",
    action: "action",
    category: "category",
    material: "material",
    status: "isActive",
  };
  const [form] = Form.useForm();

  useEffect(() => {
    let countBranch = selectedBranchId !== "" ? 1 : 0;
    let countAction = selectedAction !== "" ? 1 : 0;
    let countMaterial = selectedMaterialId !== "" ? 1 : 0;
    let countStatus = selectedStatus !== "" ? 1 : 0;
    let countCategory = selectedCategoryId !== "" ? 1 : 0;

    const filterOptions = {
      materialId: selectedMaterialId,
      branchId: selectedBranchId,
      action: selectedAction,
      isActive: selectedStatus,
      count: countMaterial + countBranch + countAction + countStatus + countCategory,
      categoryId: selectedCategoryId,
    };

    setResetFilter(filterOptions?.count < 1 ? false : true);
    props.fetchDataMaterials(filterOptions);
  }, [selectedBranchId, selectedAction, selectedMaterialId, selectedStatus, selectedCategoryId]);

  //#region PageData
  const pageData = {
    filter: {
      buttonResetFilter: t("button.resetData"),
      branch: {
        title: t("material.filter.branch.title"),
        all: t("material.filter.branch.all"),
        placeholder: t("material.filter.branch.placeholder"),
        specialOptionKey: null,
      },
      action: {
        title: t("inventoryHistory.action"),
        all: t("inventoryHistory.allAction"),
        specialOptionKey: null,
      },
      category: {
        title: t("material.filter.category.title"),
        all: t("material.filter.category.all"),
        placeholder: t("material.filter.category.placeholder"),
        specialOptionKey: null,
      },
      material: {
        title: t("material.material"),
        all: t("inventoryHistory.allMaterials"),
        specialOptionKey: null,
      },
      status: {
        title: t("material.filter.status.title"),
        all: t("material.filter.status.all"),
        active: t("material.filter.status.active"),
        inactive: t("material.filter.status.inactive"),
        specialOptionKey: null,
      },
    },
  };
  //#endregion

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    setSelectedBranchId(defaultValue);
    setSelectedAction(defaultValue);
    setSelectedMaterialId(defaultValue);
    setSelectedStatus(defaultValue);
    setSelectedCategoryId(defaultValue);
    changeCategoryId("");
  };

  const onFilterMaterial = (id, filterName) => {
    switch (filterName) {
      case filterTypes.branch:
        setSelectedBranchId(id);
        break;
      case filterTypes.action:
        setSelectedAction(id);
        break;
      case filterTypes.material:
        setSelectedMaterialId(id);
        break;
      case filterTypes.category:
        setSelectedCategoryId(id);
        changeCategoryId(id);
        setSelectedMaterialId("");
        break;
      default: //status
        setSelectedStatus(id);
        break;
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item label={pageData.filter.branch.title}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => onFilterMaterial(value, filterTypes.branch)}
          value={selectedBranchId}
          defaultValue={defaultValue}
          option={branches}
          noTranslateOptionName={true}
        />
      </Form.Item>
      {!isHidden && (
        <Form.Item label={pageData.filter.action.title}>
          <FnbSelectSingle
            className="form-select"
            showSearch
            onChange={(value) => onFilterMaterial(value, filterTypes.action)}
            value={selectedAction}
            defaultValue={defaultValue}
            option={actions}
          />
        </Form.Item>
      )}
      <Form.Item label={pageData.filter.category.title}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => onFilterMaterial(value, filterTypes.category)}
          value={selectedCategoryId}
          defaultValue={defaultValue}
          option={category}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.material.title}>
        <FnbSelectSingle
          fixed
          className="form-select"
          showSearch
          onChange={(value) => onFilterMaterial(value, filterTypes.material)}
          value={selectedMaterialId}
          defaultValue={defaultValue}
          option={materials}
        />
      </Form.Item>
      {!isHidden && (
        <Form.Item label={pageData.filter.status.title}>
          <Radio.Group
            value={selectedStatus}
            defaultValue={defaultValue}
            buttonStyle="solid"
            onChange={(e) => onFilterMaterial(e.target.value, filterTypes.status)}
          >
            <FnbRadioButton value={defaultValue}>{pageData.filter.status.all}</FnbRadioButton>
            <FnbRadioButton value={true}>{pageData.filter.status.active}</FnbRadioButton>
            <FnbRadioButton value={false}>{pageData.filter.status.inactive}</FnbRadioButton>
          </Radio.Group>
        </Form.Item>
      )}
    </Form>
  );
});
