import { Col, Row } from "antd";
import "./printer.scss";
import ReceiptPage from "../receipt";
import billDataService from "data-services/bill-configuration/bill-data.service";
import storeDataService from "data-services/store/store-data.service";
import OrderSlipPage from "../order-slip";
import orderSlipDataService from "data-services/order-slip-configuration/order-slip-data.service";
import StampConfig from "../stamp";
import stampDataService from "data-services/stamp/stamp-data.service";
import BarcodeConfig from "../barcode";
import barcodeDataService from "data-services/barcode/barcode-data.service";
import { useState } from "react";
import { PrinterSettingType } from "constants/store-setting.constants";
import { BarcodeStickers, ItemStickers, OrderSlipsItem, ReceiptItem } from "constants/icons.constants";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import FnbButtonCompound from "components/fnb-button-compound/fnb-button-compound";

function PrinterPage(props) {
  const { t, onChangeForm, isChangeFormData, setIsChangeForm } = props;
  const [currentTab, setCurrentTab] = useState(PrinterSettingType.RECEIPT);
  const [nextTab, setNextTab] = useState(PrinterSettingType.RECEIPT);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeFormData] = useState(false);
  const pageData = {
    receipt: t("billAndTicket.receipts"),
    orderSlip: t("billAndTicket.orderSlips"),
    stamp: t("billAndTicket.itemSticker"),
    barcode: t("billAndTicket.barcodeSticker"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
      discardBtn: t("leaveDialog.discard"),
      btnConfirmLeave: t("leaveDialog.confirmLeave"),
    },
  };

  const items = [
    {
      key: PrinterSettingType.RECEIPT,
      label: pageData.receipt,
      children: (
        <ReceiptPage
          t={t}
          billDataService={billDataService}
          storeDataService={storeDataService}
          activeTab={currentTab}
        />
      ),
    },
    {
      key: PrinterSettingType.ORDER_SLIP,
      label: pageData.orderSlip,
      children: <OrderSlipPage t={t} orderSlipDataService={orderSlipDataService} activeTab={currentTab} />,
    },
    {
      key: PrinterSettingType.STAMP,
      label: pageData.stamp,
      children: (
        <StampConfig t={t} stampDataService={stampDataService} onChangeForm={onChangeForm} activeTab={currentTab} />
      ),
    },
    {
      key: PrinterSettingType.BARCODE,
      label: pageData.barcode,
      children: (
        <BarcodeConfig
          t={t}
          barcodeDataService={barcodeDataService}
          onChangeForm={onChangeForm}
          activeTab={currentTab}
        />
      ),
    },
  ];

  const onChangeTab = (tab) => {
    if (isChangeFormData) {
      setShowConfirm(true);
      setNextTab(tab);
    } else {
      setCurrentTab(tab);
    }
  };

  const renderIconItem = (item) => {
    switch (item.key) {
      case PrinterSettingType.RECEIPT:
        return <ReceiptItem></ReceiptItem>;
      case PrinterSettingType.ORDER_SLIP:
        return <OrderSlipsItem></OrderSlipsItem>;
      case PrinterSettingType.STAMP:
        return <ItemStickers></ItemStickers>;
      case PrinterSettingType.BARCODE:
        return <BarcodeStickers></BarcodeStickers>;
      default:
        return <></>;
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const onOk = () => {
    setShowConfirm(false);
    setIsChangeFormData(false);
    setCurrentTab(nextTab);
    setIsChangeForm(false);
  };

  return (
    <div className="page-printer-outline">
      <Row className="page-printer-container">
        <Col className="left-content" xxl={6} xl={24} sm={24} xs={24}>
          <div className="d-flex w-100" style={{ flexDirection: "column", rowGap: 12, marginTop: 12 }}>
            {items.map((item) => {
              return (
                <FnbButtonCompound
                  title={item.label}
                  onClick={() => onChangeTab(item.key)}
                  isActive={currentTab === item.key}
                  iconHeader={renderIconItem(item)}
                />
              );
            })}
          </div>
        </Col>
        <Col className="right-content" xxl={18} xl={24} sm={24} xs={24}>
          <div className={currentTab === PrinterSettingType.RECEIPT ? "d-block" : "d-none"}>
            <ReceiptPage
              t={t}
              billDataService={billDataService}
              storeDataService={storeDataService}
              onChangeForm={onChangeForm}
              activeTab={currentTab}
            />
          </div>
          <div className={currentTab === PrinterSettingType.ORDER_SLIP ? "d-block" : "d-none"}>
            <OrderSlipPage
              t={t}
              orderSlipDataService={orderSlipDataService}
              onChangeForm={onChangeForm}
              activeTab={currentTab}
            />
          </div>
          <div className={currentTab === PrinterSettingType.STAMP ? "d-block" : "d-none"}>
            <StampConfig t={t} stampDataService={stampDataService} onChangeForm={onChangeForm} activeTab={currentTab} />
          </div>
          <div className={currentTab === PrinterSettingType.BARCODE ? "d-block" : "d-none"}>
            <BarcodeConfig
              t={t}
              barcodeDataService={barcodeDataService}
              onChangeForm={onChangeForm}
              activeTab={currentTab}
            />
          </div>
        </Col>
      </Row>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.leaveDialog.discardBtn}
        okText={pageData.leaveDialog.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={onOk}
        isChangeForm={isChangeForm}
      />
    </div>
  );
}

export default PrinterPage;
