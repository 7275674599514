import { Form, Radio } from "antd";
import FnbRadioButton from "components/fnb-radio-button";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { IngredientRemoveRequestHistoryType } from "constants/ingredient-remove-request.constants";
import {
  UpdateStockHistoryActionType,
  UpdateStockHistoryActionTypeLocalization,
} from "constants/update-stock-request.constants";
import { forwardRef, useImperativeHandle } from "react";

const FilterHistoryUpdateStockRequest = forwardRef((props, ref) => {
  const { t, branches, onChangeFilter } = props;
  const [form] = Form.useForm();
  const defaultValueBranch = "";
  const pageData = {
    branch: t("store.branch"),
    activity: t("materialControl.historyRemoveRequest.activity", "Activity"),
    actionType: {
      all: t("materialControl.historyRemoveRequest.all"),
    },
  };

  const onChangeBranch = (value) => {
    onChangeFilter("branchId", value);
  };

  const onChangeType = (e) => {
    onChangeFilter("actionType", e.target.value);
  };
  const listType = [
    {
      id: null,
      name: pageData.actionType.all,
    },
    {
      id: UpdateStockHistoryActionType.CREATED,
      name: t(UpdateStockHistoryActionTypeLocalization[UpdateStockHistoryActionType.CREATED]),
    },
    {
      id: IngredientRemoveRequestHistoryType.APPROVED,
      name: t(UpdateStockHistoryActionTypeLocalization[UpdateStockHistoryActionType.APPROVED]),
    },
    {
      id: IngredientRemoveRequestHistoryType.EDITED,
      name: t(UpdateStockHistoryActionTypeLocalization[UpdateStockHistoryActionType.EDITED]),
    },
    {
      id: IngredientRemoveRequestHistoryType.REJECTED,
      name: t(UpdateStockHistoryActionTypeLocalization[UpdateStockHistoryActionType.REJECTED]),
    },
  ];

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      form.setFieldsValue({
        actionType: null,
        branchId: "",
      });
    },
  }));

  return (
    <Form className="custom-form" form={form} layout="vertical">
      <Form.Item label={pageData.branch} name="branchId">
        <FnbSelectSingle showSearch defaultValue={defaultValueBranch} option={branches} onChange={onChangeBranch} />
      </Form.Item>
      <Form.Item label={pageData.activity} name="actionType">
        <Radio.Group defaultValue={null} buttonStyle="solid" onChange={onChangeType}>
          {listType?.map((item, index) => (
            <FnbRadioButton value={item?.id} key={index}>
              {item?.name[0]?.toUpperCase() + item?.name?.slice(1)}
            </FnbRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});

export default FilterHistoryUpdateStockRequest;
