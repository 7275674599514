export const GuidancePurposeType = {
  Product: 0,
  Ingredient: 1,
  OnlineStore: 2,
};

export const GuidanceLinkType = {
  Wikipedia: 0,
  YouTube: 1,
};
