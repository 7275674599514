import { t } from "i18next";
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import {
  default as reserveTableDataService,
  default as reserveTableService,
} from "../../../data-services/reserve-table-data.service";
import storeDataService from "../../../data-services/store-data.service";
import { useAppCtx } from "../../../providers/app.provider";
import { localStorageKeys } from "../../../utils/localStorage.helpers";
import { ReserveDepositStatus, ReserveStatus } from "../../constants/reserve.constants";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog.component";
import "./cancel-reservation.component.scss";

export default function CancelReservationComponent(props) {
  const {
    reservationId,
    isReservationList,
    setStatusSelected,
    onChangeStatus,
    branchPhone,
    depositStatusId,
    isDeposit,
  } = props;
  const userInfo = useSelector((state) => state?.session?.userInfo);
  const translateData = {
    confirm: t("deliveryTime.confirm"),
    pickDate: t("reserve.pickDate"),
    pickTime: t("reserve.pickTime"),
    cancel: t("reserve.cancel"),
    confirmation: t("reserve.confirmation"),
    ignore: t("reserve.ignore"),
    cancelReservation: t("reserve.cancelReservation"),
    cancelContent: t("reserve.cancelContent"),
    cancelSuccess: t("reserve.cancelSuccess"),
    cancelDetail: t("reserve.cancelDetail"),
  };

  const [isShowNotifyDialog, setIsShowNotifyDialog] = useState(false);
  const [isCancelDeposit, setIsCancelDeposit] = useState(false);
  const storeId = userInfo?.storeId ?? JSON.parse(localStorage.getItem(localStorageKeys.STORE_CONFIG))?.storeId;
  const { Toast } = useAppCtx();
  const [isDepositConfig, setIsDepositConfig] = useState(false);
  async function onCancelReservation() {
    if (reservationId) {
      const res = await reserveTableService.cancelReserveTableByIdAsync(reservationId);
      if (res) {
        Toast.show({
          messageType: "success",
          message: translateData.cancelSuccess,
          placement: "top",
          duration: 3,
          className: "toast-message-add-to-cart-theme1",
        });
        setTimeout(() => {
          if (onChangeStatus) {
            onChangeStatus(ReserveStatus.Cancelled);
            setStatusSelected(ReserveStatus.Cancelled);
          } else {
            window.location.reload();
          }
        }, 800);
      }
    }
  }

  //US 44776
  useEffect(() => {
    if (isShowNotifyDialog) {
      if (isReservationList && isDeposit && depositStatusId === ReserveDepositStatus.Received) {
        setIsCancelDeposit(true);
      } else {
        getStoreConfig();
      }
    }
  }, [isShowNotifyDialog]);

  const [reserveTableDetailDataChecking, setReserveTableDetailChecking] = useState();

  useEffect(() => {
    if (reserveTableDetailDataChecking) {
      if (reserveTableDetailDataChecking?.status !== ReserveStatus.WaitToConfirm) {
        window.location.reload();
        return;
      }

      if (isDepositConfig && reserveTableDetailDataChecking?.depositStatusId === ReserveDepositStatus.Received) {
        setIsCancelDeposit(true);
      }

      setIsShowNotifyDialog(true);
    }
  }, [reserveTableDetailDataChecking]);

  const getReserveTableDetailDataChecking = async (reserveTableId) => {
    const orderDetailResult = await reserveTableDataService.getReserveTableDetailAsync(reserveTableId);
    setReserveTableDetailChecking(orderDetailResult?.data);
  };

  const getStoreConfig = async () => {
    const res = await storeDataService.getStoreConfig(storeId);
    if (res?.data?.storeConfig?.isDeposit && depositStatusId === ReserveDepositStatus.Received) {
      setIsCancelDeposit(true);
    }
    if (res?.data?.storeConfig?.isDeposit && depositStatusId) {
      setIsDepositConfig(true);
    }
  };

  const onClickButtonCancel = (event) => {
    event.stopPropagation();
    getStoreConfig();
    getReserveTableDetailDataChecking(reservationId);
  };

  const onCancelDialog = (event) => {
    event.stopPropagation();
    setIsShowNotifyDialog(!isShowNotifyDialog);
  };

  const onConfirmDialog = (event) => {
    event.stopPropagation();
    onCancelReservation();
    setIsShowNotifyDialog(!isShowNotifyDialog);
  };

  const renderCancelText = () => {
    let text = translateData.cancelContent;
    if (isCancelDeposit) {
      text = t("reserve.cancelDepositContent", {
        branchPhone: branchPhone ?? "1900 6067",
      });
    }

    return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
  };

  return (
    <div
      className="cancel-reservation"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {isReservationList ? (
        <button className="card-reservation-theme1__btn-cancel" onClick={(e) => onClickButtonCancel(e)}>
          {translateData.cancel}
        </button>
      ) : (
        <div className="reservation-detail__contain-btn-cancel" onClick={(e) => onClickButtonCancel(e)}>
          <button className="reservation-detail__btn-cancel">{translateData.cancelDetail}</button>
        </div>
      )}
      <ConfirmationDialog
        open={isShowNotifyDialog}
        title={translateData.confirmation}
        cancelText={translateData.ignore}
        okText={translateData.cancelReservation}
        content={renderCancelText()}
        closable={false}
        maskClosable={true}
        className={`confirmation-type-2 ${isCancelDeposit && "confirmation-cancel-deposit"}`}
        onCancel={(e) => onCancelDialog(e)}
        onConfirm={(e) => onConfirmDialog(e)}
      />
    </div>
  );
}
