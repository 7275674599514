const { localStorageKeys } = require("utils/localStorage.helpers");

/**
 * Used to save payment transactions, avoiding creating multiple payment transactions with the same information in one payment session
 * @param {*} value
 */
function savePayPalPaymentTransactionInLocalStorage(value) {
  localStorage.setItem(localStorageKeys.PAYPAL_PAYMENT_TRANSACTION_ID, value);
}

/**
 * Remove saved payment transaction, when exiting payment session
 */
function removePayPalPaymentTransactionToLocalStorage() {
  localStorage.setItem(localStorageKeys.PAYPAL_PAYMENT_TRANSACTION_ID, "");
}

const paypalService = {
  savePayPalPaymentTransactionInLocalStorage,
  removePayPalPaymentTransactionToLocalStorage,
};

export default paypalService;
