const AddressHomeIcon = ({ fillColor = "#BBAC5E" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8592_379)">
        <path
          d="M20.713 23.9999H3.55616C3.25806 23.9999 3.02148 23.7609 3.02148 23.4702V22.8468C3.02148 22.5515 3.2628 22.3171 3.55616 22.3171H20.713C21.0111 22.3171 21.2477 22.5562 21.2477 22.8468V23.4702C21.2477 23.7609 21.0063 23.9999 20.713 23.9999Z"
          fill={fillColor}
        />
        <path
          d="M20.145 4.75794V2.25951C20.145 2.01107 19.9416 1.81419 19.6955 1.81419H16.7288C16.478 1.81419 16.2793 2.01576 16.2793 2.25951V2.69076C16.2793 2.85951 16.3787 3.01888 16.5301 3.08919C17.6089 3.60482 18.5694 4.23294 19.3643 5.06263C19.6482 5.35794 20.145 5.16576 20.145 4.75794Z"
          fill={fillColor}
        />
        <path
          d="M20.2689 1.27501H16.2328C16.0057 1.27501 15.8164 1.09219 15.8164 0.862506V0.403131C15.8164 0.178131 16.0009 -0.0093689 16.2328 -0.0093689H20.2689C20.496 -0.0093689 20.6852 0.173444 20.6852 0.403131V0.862506C20.6852 1.09219 20.5007 1.27501 20.2689 1.27501Z"
          fill={fillColor}
        />
        <path
          d="M2.9367 12.8531C3.33888 12.8531 3.67956 12.5484 3.72215 12.1547C4.17165 7.94063 7.76294 4.65469 12.1397 4.65469C16.5117 4.65469 20.1077 7.94063 20.5572 12.1547C20.5998 12.5531 20.9357 12.8531 21.3427 12.8531C21.8111 12.8531 22.1849 12.45 22.1328 11.9859C21.5887 6.98438 17.3255 3.08438 12.1349 3.08438C6.94437 3.08438 2.67646 6.98438 2.14179 11.9859C2.09447 12.45 2.46354 12.8531 2.9367 12.8531Z"
          fill={fillColor}
        />
        <path
          d="M12.0923 5.40943C7.80542 5.43755 4.375 8.96255 4.375 13.2094V20.2641C4.375 20.6672 4.70621 20.9954 5.11313 20.9954H8.30697C8.71389 20.9954 9.0451 20.6672 9.0451 20.2641V18.5626C9.0451 16.8844 10.3747 15.4641 12.0639 15.4219C13.8146 15.3751 15.2482 16.7719 15.2482 18.4922V20.2641C15.2482 20.6672 15.5795 20.9954 15.9864 20.9954H19.1755C19.5824 20.9954 19.9136 20.6672 19.9136 20.2641V13.111C19.9183 8.84068 16.4122 5.3813 12.0923 5.40943ZM12.1443 12.0188C11.2879 12.0188 10.5923 11.3297 10.5923 10.4813C10.5923 9.63286 11.2879 8.9438 12.1443 8.9438C13.0007 8.9438 13.6963 9.63286 13.6963 10.4813C13.6963 11.3344 13.0007 12.0188 12.1443 12.0188Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_8592_379">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AddressHomeIcon;
