import FnbTypography from "components/fnb-typography/fnb-typography";
import "./fnb-breadcrumb.scss";
import { Breadcrumb } from "antd";

export const FnbBreadcrumb = ({ className = "", items = [] }) => {
  return (
    <Breadcrumb className={`fnb-breadcrumb ${className}`}>
      {items &&
        items.length > 0 &&
        items.map((item, index) => (
          <Breadcrumb.Item onClick={item?.onClick} key={index}>
            {item?.href ? (
              <FnbTypography.Link to={item?.href} text={item?.title} />
            ) : (
              <FnbTypography text={item?.title} />
            )}
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
};
