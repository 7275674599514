import { Popover, Space } from "antd";
import classNames from "classnames";
import FnbTypography from "../fnb-typography/fnb-typography";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbBreadcrumb } from "components/fnb-breadcrumb/fnb-breadcrumb";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbGuideline } from "components/fnb-guideline/fnb-guideline.component";
import { AddCircleIcon, ArrowDownIcon } from "constants/icons.constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import theme from "theme";
import { ArrowIcon } from "themes/theme-2-new/assets/icons.constants";
import "./fnb-heading-page.scss";
import { Link } from "react-router-dom";

const FnbHeadingPage = ({
  title = "",
  itemsBreadcrumb,
  listButtons = [],
  //Create button props
  textAddNew = undefined,
  permissionCreate = null,
  onCreate = undefined,
  typeBtnCreate = "button", // submit | reset
  fromBtnCreate = null,
  disabledCreate = false,
  //
  titleType = "capitalize", // 'default' | 'uppercase' | 'capitalize
  guideline = {
    title: undefined,
    content: undefined,
  },
  activeStatus = {
    content: undefined,
  },
  isCompactButtons = true, // compact button to arrow popover in mobile mode
  onlyShowIconButtonInMobileMode = false,
  className = "",
  approveStatus,
  rejectStatus,
  waitingForApproveStatus,
  suffix = undefined,
}) => {
  const isMax768 = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  const [t] = useTranslation();
  const [openPopOver, setOpenPopOver] = useState(false);
  const translateData = {
    btnAddNew: t("button.addNew"),
  };
  const renderListButtons = () => {
    if (isMax768 && listButtons.length > 0 && isCompactButtons) {
      const listButtonCollapse = onCreate ? [...listButtons] : [...listButtons].slice(0, listButtons?.length - 1);
      const lastButton = listButtons[listButtons?.length - 1];
      return (
        <>
          <Space size={12}>
            {listButtonCollapse?.length > 0 && (
              <Popover
                content={
                  <>
                    {listButtonCollapse.reverse().map((button, index) => {
                      return React.cloneElement(button, {
                        variant: "tertiary",
                        key: index,
                        minWidth: "unset",
                        onClick: (e) => {
                          button?.props?.onClick && button?.props?.onClick(e);
                          setOpenPopOver(false);
                        },
                      });
                    })}
                  </>
                }
                trigger="click"
                placement="bottomRight"
                open={openPopOver}
                onOpenChange={setOpenPopOver}
                overlayClassName="fnb-heading-page__popover-overlay"
              >
                <FnbButton variant="tertiary" text={<ArrowDownIcon />} />
              </Popover>
            )}
            {!onCreate && lastButton}
          </Space>
        </>
      );
    }
    return [...listButtons];
  };

  const classNameHeadingPage = classNames({
    "fnb-heading-page": true,
    "fnb-heading-page--disabled-compact-buttons": !isCompactButtons,
    "fnb-heading-page--hide-text-button": onlyShowIconButtonInMobileMode,
    [`${className}`]: true,
  });

  return (
    <div className={classNameHeadingPage}>
      <div
        className="fnb-heading-page__wrapper-title"
        style={{
          maxWidth: isTouchDevice
            ? "100%"
            : `calc(100% - 12px - ${document.getElementById("fnb-heading-page__list-buttons")?.clientWidth}px)`,
        }}
      >
        {itemsBreadcrumb ? (
          <>
            <Link to={itemsBreadcrumb[0]?.href}>
              <ArrowIcon className="fnb-heading-page__arrow" />
            </Link>
            <FnbBreadcrumb items={itemsBreadcrumb} />
          </>
        ) : (
          <FnbTooltip onlyShowWhenEllipsis title={title}>
            {
              <FnbTypography
                className={`fnb-heading-page__title fnb-heading-page__title--${titleType}`}
                variant={theme.typography["b2-bold"]}
                textTransform={titleType}
                text={title}
              />
            }
          </FnbTooltip>
        )}
        {guideline.content && (
          <FnbGuideline
            title={guideline.title}
            content={guideline.content}
            placement={isMobile ? "bottomRight" : "rightTop"}
          />
        )}
        {activeStatus.content && <div>{activeStatus.content}</div>}
        {approveStatus && <p className="fnb-heading-page__approve-new-status">{approveStatus}</p>}
        {rejectStatus && <p className="fnb-heading-page__reject-status">{rejectStatus}</p>}
        {waitingForApproveStatus && <p className="fnb-heading-page__approve-status">{waitingForApproveStatus}</p>}
        {suffix && suffix}
      </div>
      <div id="fnb-heading-page__list-buttons" className="fnb-heading-page__list-buttons">
        {renderListButtons()}
        {(typeBtnCreate !== "button" || onCreate) && (
          <FnbButton
            permission={permissionCreate}
            onClick={onCreate}
            text={textAddNew ?? translateData.btnAddNew}
            iconHeader={<AddCircleIcon fill={theme.colors.default.white} />}
            className="fnb-heading-page__button-create"
            disabled={disabledCreate}
            type={typeBtnCreate}
            form={fromBtnCreate}
          />
        )}
      </div>
    </div>
  );
};

export default FnbHeadingPage;
