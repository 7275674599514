import "../branch-working-hour.scss";
import { CaretDownIcon } from "constants/icons.constants";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";

export default function DayOfWeekSelectorComponent(props) {
  const { index, title, onCheck, onOpenCustomOption, checked } = props;
  return (
    <div className="day-of-week-box" id={`day-of-week-${index}`}>
      <FnbCheckBox onChange={onCheck} valuePropName="checked" checked={checked}>
        <span>{title}</span>
      </FnbCheckBox>
      <div className="icon-box" onClick={onOpenCustomOption}>
        <span class="icon">
          <CaretDownIcon />
        </span>
      </div>
    </div>
  );
}
