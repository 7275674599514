import { DatePicker, Form, InputNumber, Radio } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { inputNumberRange1To999999999 } from "constants/default.constants";
import { CalendarNewIconBold } from "constants/icons.constants";
import { DateFormat } from "constants/string.constants";
import moment from "moment";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "utils/helpers";
import "./filter-discount.component.scss";
import FnbRadioButton from "components/fnb-radio-button";
import { usePromotionContextProvider } from "../promotion-context";

const FilterDiscount = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { pageSize, keySearch, setDataFilter } = props;
  const defaultValue = "";
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedValueType, setSelectedValueType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedIncludeTopping, setSelectedIncludeTopping] = useState("");
  const { branches, promotionTypeOptions } = usePromotionContextProvider();

  const pageData = {
    filter: {
      button: t("button.clear"),
      branch: {
        title: t("productManagement.filter.branch.title"),
        all: t("productManagement.filter.branch.all"),
        placeholder: t("productManagement.filter.branch.placeholder"),
      },
      status: {
        all: t("material.filter.status.all"),
        title: t("material.filter.status.title"),
        active: t("promotion.status.active"),
        scheduled: t("promotion.status.scheduled"),
        finished: t("promotion.status.finished"),
        specialOptionKey: null,
      },
      applicableType: t("promotion.applicableType"),
      valueType: t("promotion.valueType"),
      amount: t("promotion.amount"),
      startDate: t("promotion.form.startDate"),
      endDate: t("promotion.form.endDate"),
      minimumPurchaseOnBill: t("promotion.minimumPurchaseOnBill"),
      resetallfilters: t("productManagement.filter.resetallfilters"),
      fromAmount: t("promotion.fromAmount"),
      toAmount: t("promotion.toAmount"),
      amountValidateMessage: t("productManagement.pricing.priceRange"),
      toAmountValidate: t("promotion.toAmountValidate"),
      includeTopping: t("promotion.includeTopping"),
      notIncludeTopping: t("promotion.notIncludeTopping"),
      topping: t("productManagement.topping"),
    },
  };

  const onApplyFilter = () => {
    var fieldsErrors = form.getFieldsError();
    let isFormValid = fieldsErrors.find((item) => item?.errors?.length > 0);

    if (!isFormValid) {
      let formValue = form.getFieldsValue();
      formValue.count = countFilterControl(formValue);
      setDataFilter(formValue);
      props.fetchDataDiscount(1, pageSize, keySearch, formValue);
    }
  };

  const countFilterControl = (formValue) => {
    var checkStartDate = moment(formValue.startDate, "YYYY-MM-DD").isValid();
    var checkEndDate = moment(formValue.endDate, "YYYY-MM-DD").isValid();

    let countBranch = formValue.branchId === "" || formValue.branchId === undefined ? 0 : 1;

    let countStatus = formValue.statusId === "" || formValue.statusId === undefined ? 0 : 1;

    let countApplicableType = formValue.applicableType === "" || formValue.applicableType === undefined ? 0 : 1;

    let countValueType = formValue.valueType === "" || formValue.valueType === undefined ? 0 : 1;

    let countStartDate = formValue.startDate === "" || formValue.startDate === undefined || !checkStartDate ? 0 : 1;

    let countEndDate = formValue.endDate === "" || formValue.endDate === undefined || !checkEndDate ? 0 : 1;

    let countIncludeTopping = formValue.includeTopping === "" || formValue.includeTopping === undefined ? 0 : 1;

    let countMinMinimumPurchaseOnBill =
      formValue.minMinimumPurchaseOnBill === "" ||
      formValue.minMinimumPurchaseOnBill === undefined ||
      formValue.minMinimumPurchaseOnBill === null
        ? 0
        : 1;

    let countMaxMinimumPurchaseOnBill =
      formValue.maxMinimumPurchaseOnBill === "" ||
      formValue.maxMinimumPurchaseOnBill === undefined ||
      formValue.maxMinimumPurchaseOnBill === null
        ? 0
        : 1;

    return (
      countBranch +
      countValueType +
      countStatus +
      countStartDate +
      countEndDate +
      countMinMinimumPurchaseOnBill +
      countMaxMinimumPurchaseOnBill +
      countApplicableType +
      countIncludeTopping
    );
  };

  const onResetForm = () => {
    form?.resetFields();
    onApplyFilter();
  };

  const onChangeStatus = (id) => {
    setSelectedStatus(id);
  };

  const onChangeValueType = (id) => {
    setSelectedValueType(id);
  };

  const onChangeIncludeTopping = (id) => {
    setSelectedIncludeTopping(id);
  };

  const disabledDateByStartDate = (current) => {
    // Can not select days before today and today
    return current && current < startDate;
  };

  useImperativeHandle(ref, () => ({
    resetFilter() {
      onResetForm();
    },
  }));

  return (
    <Form layout="vertical" form={form} onFieldsChange={onApplyFilter}>
      <Form.Item name="branchId" label={pageData.filter.branch.title}>
        <FnbSelectSingle
          placeholder={pageData.filter.branch.placeholder}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={branches}
        />
      </Form.Item>
      <Form.Item name="statusId" label={pageData.filter.status.title}>
        <Radio.Group
          value={selectedStatus}
          defaultValue={defaultValue}
          buttonStyle="solid"
          onChange={(e) => onChangeStatus(e.target.value)}
        >
          <FnbRadioButton value={defaultValue}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={2}>{pageData.filter.status.active}</FnbRadioButton>
          <FnbRadioButton value={1}>{pageData.filter.status.scheduled}</FnbRadioButton>
          <FnbRadioButton value={3}>{pageData.filter.status.finished}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="applicableType" label={pageData.filter.applicableType}>
        <FnbSelectSingle
          placeholder={pageData.filter.branch.placeholder}
          className="form-select"
          showSearch
          defaultValue={defaultValue}
          option={promotionTypeOptions}
        />
      </Form.Item>
      <Form.Item name="valueType" label={pageData.filter.valueType}>
        <Radio.Group
          value={selectedValueType}
          defaultValue={defaultValue}
          buttonStyle="solid"
          onChange={(e) => onChangeValueType(e.target.value)}
        >
          <FnbRadioButton value={defaultValue}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={1}>{"%"}</FnbRadioButton>
          <FnbRadioButton value={2}>{pageData.filter.amount}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="startDate" label={pageData.filter.startDate}>
        <DatePicker
          suffixIcon={<CalendarNewIconBold />}
          placeholder={DateFormat.DD_MM_YYYY_DASH.toLowerCase()}
          className="fnb-date-picker w-100"
          disabledDate={false}
          format={DateFormat.DD_MM_YYYY_DASH}
          onChange={(date) => {
            setStartDate(date);
            // Clear end date after select start date if endate < startdate only
            const formValues = form.getFieldsValue();
            if (formValues?.endDate != null && formValues?.endDate < date) {
              form.setFieldsValue({
                ...formValues,
                startDate: null,
                endDate: null,
              });
            }
          }}
        />
      </Form.Item>
      <Form.Item name="endDate" label={pageData.filter.endDate}>
        <DatePicker
          suffixIcon={<CalendarNewIconBold />}
          placeholder={DateFormat.DD_MM_YYYY_DASH.toLowerCase()}
          className="fnb-date-picker w-100"
          format={DateFormat.DD_MM_YYYY_DASH}
          disabledDate={disabledDateByStartDate}
        />
      </Form.Item>
      <Form.Item name="includeTopping" label={pageData.filter.topping}>
        <Radio.Group
          value={selectedIncludeTopping}
          defaultValue={defaultValue}
          buttonStyle="solid"
          onChange={(e) => onChangeIncludeTopping(e.target.value)}
        >
          <FnbRadioButton value={defaultValue}>{pageData.filter.status.all}</FnbRadioButton>
          <FnbRadioButton value={1}>{pageData.filter.includeTopping}</FnbRadioButton>
          <FnbRadioButton value={2}>{pageData.filter.notIncludeTopping}</FnbRadioButton>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={pageData.filter.minimumPurchaseOnBill} className="form-item-control-inline">
        <Form.Item
          name="minMinimumPurchaseOnBill"
          rules={[
            {
              pattern: new RegExp(inputNumberRange1To999999999.range),
              message: pageData.filter.amountValidateMessage,
            },
          ]}
        >
          <InputNumber
            className="w-100 fnb-input-number"
            placeholder={pageData.filter.fromAmount}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            addonAfter={getCurrency()}
          />
        </Form.Item>
        <span className="middle-character">-</span>
        <Form.Item
          name="maxMinimumPurchaseOnBill"
          rules={[
            {
              pattern: new RegExp(inputNumberRange1To999999999.range),
              message: pageData.filter.amountValidateMessage,
            },
            () => ({
              validator(_, value) {
                let formValue = form.getFieldsValue();

                if (
                  (value && value <= formValue?.minMinimumPurchaseOnBill) ||
                  (value === 0 && formValue?.minMinimumPurchaseOnBill >= 0)
                ) {
                  return Promise.reject(
                    `${pageData.filter.toAmountValidate} ${formValue?.minMinimumPurchaseOnBill?.toLocaleString() || 0}`,
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            className="w-100 fnb-input-number"
            placeholder={pageData.filter.toAmount}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            addonAfter={getCurrency()}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      </Form.Item>
    </Form>
  );
});

export default FilterDiscount;
