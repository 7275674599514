import { Col, Drawer, Modal, Row } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Platform } from "../../../../constants/platform.constants";
import { calculatePercentageTag, formatTextCurrency } from "../../../../utils/helpers";
import { DeleteProduct, MinusIconCheckout, PlusIconCheckout } from "../../../assets/icons.constants";
import productDefaultImage from "../../../assets/images/product-default-img.jpg";
import ImgDefault from "../../../assets/images/product-default.png";
import FnbDisplayImageCheckoutComponent from "../../../components/fnb-display-image-checkout/fnb-display-image-checkout.component";
import "./checkout.product.item.scss";
import { EditOrderProductDialogComponent } from "./edit-order-product-dialog.component";
export default function CheckOutProductItem(props) {
  const {
    cartItem,
    currentIndex,
    onDeleteProduct,
    onUpdateCartQuantity,
    setCurrentCartItems,
    index,
    calculateShoppingCart,
    onShowToastMessageUpdateCartItem,
    colorGroup,
  } = props;

  const [t] = useTranslation();
  const editOrderRef = useRef();
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalKey, setModalKey] = useState(true);
  const [initCurrentIndex, setInitCurrentIndex] = useState(0);
  const [isShowDetail, setIsShowDetail] = useState(false);

  const translatedData = {
    leaveAMessageForTheStore: t("storeWebPage.generalUse.leaveAMessageForTheStore", "leaveAMessageForTheStore"),
    outOfStock: t("storeWebPage.productDetailPage.outOfStock", "outOfStock"),
    editProduct: t("checkOutPage.editProduct", "Edit"),
    total: t("checkOutPage.total", "Total"),
    collapse: t("storeWebPage.productDetailPage.collapse", "Collapse"),
  };
  if (!cartItem) return <></>;
  const isCombo = cartItem?.isCombo;
  const toppingPrice = isCombo ? cartItem?.totalOfToppingPrice : cartItem?.productPrice?.totalOfToppingPrice;
  const toppingOriginalPrice = isCombo
    ? cartItem?.totalOfToppingPrice
    : cartItem?.productPrice?.totalOfToppingOriginalPrice;
  const price = isCombo ? cartItem?.originalPrice : cartItem?.productPrice?.originalPrice;
  const priceWithDiscount = isCombo ? cartItem?.sellingPrice : cartItem?.productPrice?.priceValue;
  const maxQuantity = 1000;
  const promotionTitle =
    cartItem?.isFlashSale || cartItem?.productPrice?.isApplyPromotion
      ? calculatePercentageTag(cartItem?.productPrice?.priceValue, cartItem?.productPrice?.originalPrice)
      : "";
  const sizeName = isCombo ? undefined : cartItem?.productPrice?.priceName;
  function ProductOptions() {
    const newOptions = cartItem?.options?.filter((e) => e.isSetDefault == false);
    const result = newOptions?.map((item) => item.name + " (" + item.optionLevelName + ")")?.join(", ");
    return <span className="text-line-clamp-2">{result}</span>;
  }

  const renderProductToppings = cartItem?.toppings
    ?.filter((topping) => topping?.quantity > 0)
    ?.map((topping) => {
      return <span>{"x" + topping.quantity + " " + topping.name}</span>;
    });

  const productsInCombo = cartItem?.products?.map((product) => {
    return <span>{"x1 " + product.name}</span>;
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalKey(!modalKey);
  };

  const onClickProductCartItem = (item, index) => {
    editOrderRef?.current?.setProductData(item, currentIndex);
    setIsModalVisible(true);
    setInitCurrentIndex(index);
  };

  const showDetail = () => {
    setIsShowDetail(!isShowDetail);
  };

  return (
    <>
      <Row gutter={[0, 24]} className={`product_cart_theme1 box_info ${cartItem?.isOutOfStock ? "out-of-stock" : ""}`}>
        <Col xs={19} sm={14} md={14} className="product_cart_info">
          <div className="box_product_info">
            <div className="product-cover">
              <div className="delete_product">
                <DeleteProduct onClick={() => onDeleteProduct(cartItem.id, index)} />
              </div>
              <div
                className={`product_image${cartItem?.isFlashSale ? " flash-sale-border" : ""} ${
                  cartItem?.isOutOfStock ? "opacity" : ""
                }`}
                onClick={() => onClickProductCartItem(cartItem, index)}
              >
                {cartItem?.isOutOfStock && <div className="out-of-stock-badge">{translatedData.outOfStock}</div>}
                <FnbDisplayImageCheckoutComponent
                  src={
                    cartItem?.thumbnail == null || cartItem?.thumbnail.trim() === "" ? ImgDefault : cartItem?.thumbnail
                  }
                  isFlashSale={cartItem?.isFlashSale}
                  flashSaleTitle={promotionTitle}
                  outOfStockText={null}
                />
              </div>
            </div>

            <div className="product_info">
              <div className="name text-line-clamp-2" onClick={() => onClickProductCartItem(cartItem)}>
                {cartItem?.comboTypeId === 0
                  ? cartItem?.comboPricingName || cartItem?.dataDetails?.combo?.name
                  : cartItem?.name || cartItem?.dataDetails?.name}
                {sizeName && ` (${sizeName})`}
              </div>
              <div className="description">
                {cartItem?.products && cartItem?.products?.length > 0 ? (
                  productsInCombo
                ) : (
                  <>
                    {renderProductToppings}
                    <ProductOptions />
                  </>
                )}
              </div>
              {isMaxWidth575 && (
                <>
                  <div className="price_box_mobile">
                    <div className="priceWithDiscount">{formatTextCurrency(priceWithDiscount)}</div>
                    {priceWithDiscount !== price && !cartItem?.isCombo && !cartItem?.isFlashSale && (
                      <div className="price">{formatTextCurrency(price + (toppingOriginalPrice ?? 0))}</div>
                    )}
                  </div>
                  <div className="total_price_mobile">
                    {translatedData.total} {": "}
                    {formatTextCurrency(priceWithDiscount * cartItem.quantity)}
                  </div>
                </>
              )}
              <div className="box_product_info_button">
                {cartItem?.isCombo && (
                  <div className="product_info_button cursor-pointer" onClick={showDetail}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          !isShowDetail ? "checkOutPage.viewCombo" : "storeWebPage.productDetailPage.collapse",
                          {
                            quantity: cartItem?.products?.length,
                            products_quantity: "products_quantity",
                          },
                        ),
                      }}
                    ></span>
                  </div>
                )}
                <div className="product_info_button cursor-pointer">
                  <span className="product_info_button_text" onClick={() => onClickProductCartItem(cartItem, index)}>
                    {translatedData.editProduct}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={0} sm={4} md={4}>
          <div className="price_box">
            <div className="priceWithDiscount">{formatTextCurrency(priceWithDiscount)}</div>
            {priceWithDiscount !== price &&
            cartItem?.productPrice?.priceAfterDiscountInStore !== price &&
            !cartItem?.isCombo &&
            !cartItem?.isFlashSale ? (
              <div className="price">{formatTextCurrency(price + (toppingOriginalPrice ?? 0))}</div>
            ) : (
              ""
            )}
          </div>
        </Col>
        <Col xs={5} sm={3} md={3}>
          <div className={`box_quantity`}>
            <MinusIconCheckout
              className="down"
              onClick={() => {
                //If quantity = 1, do nothing
                if (cartItem.quantity <= 1) {
                  return;
                }
                onUpdateCartQuantity(cartItem.id, -1, index, false);
              }}
            />
            <div className="quantity">{cartItem.quantity}</div>
            <PlusIconCheckout
              className="up"
              onClick={() => {
                if (cartItem.quantity >= maxQuantity || cartItem?.isOutOfStock) return;
                onUpdateCartQuantity(cartItem.id, 1, index, true);
              }}
            />
          </div>
        </Col>
        <Col xs={0} sm={3} md={3}>
          <div className="total_price">{formatTextCurrency(priceWithDiscount * cartItem.quantity)}</div>
        </Col>
        <div className={`box_info ${cartItem?.isOutOfStock ? "out-of-stock" : ""}`}></div>
        {isShowDetail && (
          <>
            {cartItem?.isCombo && (
              <Col span={24} className="container_combo_checkout">
                <div className="black_line"></div>
                <div className="box_combo">
                  {cartItem?.products?.map((product) => {
                    const productOptions = product?.options
                      ?.map((item) => item.name + " (" + item.optionLevelName + ")")
                      ?.join(" / ");
                    const productToppings = product?.toppings
                      ?.filter((topping) => topping?.quantity > 0)
                      ?.map((topping) => "x" + topping.quantity + " " + topping.name);
                    return (
                      <>
                        <div className="box_combo_item">
                          <img
                            className="product_image"
                            src={product.thumbnail ? product.thumbnail : productDefaultImage}
                            alt={product.name}
                          />
                          <div className="box_product">
                            <div className="product_name">{product.name}</div>
                            {product?.options?.length > 0 && product?.toppings?.length > 0 && (
                              <div className="product_options">
                                {productToppings.map((topping) => {
                                  return <span>{topping}</span>;
                                })}
                                {productOptions}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </Col>
            )}
          </>
        )}
        <Col span={24} className="product_note">
          {cartItem?.notes && (
            <>
              <div className="black_line"></div>
              <div className="note">
                <div className="message">{cartItem?.notes}</div>
              </div>
            </>
          )}
        </Col>
      </Row>

      {isMaxWidth575 ? (
        <Drawer
          width={500}
          placement="bottom"
          closeIcon
          open={isModalVisible}
          onClose={handleCancel}
          forceRender={true}
          destroyOnClose={true}
          zIndex={9999}
        >
          <EditOrderProductDialogComponent
            ref={editOrderRef}
            onCancel={handleCancel}
            initCurrentIndex={initCurrentIndex}
            setCurrentCartItems={(cartItems) => {
              setCurrentCartItems(cartItems);
              onShowToastMessageUpdateCartItem();
            }}
            calculateShoppingCart={calculateShoppingCart}
            platformId={Platform.StoreWebsite}
          />
        </Drawer>
      ) : (
        <Modal
          width={1400}
          className="modal-product-cart-detail"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={(null, null)}
          centered
          forceRender={true}
          destroyOnClose={true}
          zIndex={9999}
          key={modalKey}
        >
          <EditOrderProductDialogComponent
            colorGroup={colorGroup}
            ref={editOrderRef}
            onCancel={handleCancel}
            initCurrentIndex={initCurrentIndex}
            setCurrentCartItems={(cartItems) => {
              setCurrentCartItems(cartItems);
              onShowToastMessageUpdateCartItem();
            }}
            calculateShoppingCart={calculateShoppingCart}
            platformId={Platform.StoreWebsite}
          />
        </Modal>
      )}
    </>
  );
}
