export const enumBlogResponse = {
  /// <summary>
  /// Created or Edited success
  /// </summary>
  Success: 1,

  /// <summary>
  /// Created or Edited failed
  /// </summary>
  Failed: 2,

  /// <summary>
  /// Blog is not found
  /// </summary>
  NotFound: 3,

  /// <summary>
  /// Is not unique UrlEncode
  /// </summary>
  IsNotUniqueUrlEncode: 4,
};
