import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Form, Input, Row, message } from "antd";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbSelectSingleIcon } from "components/fnb-select-single-icon/fnb-select-single-icon";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { DefaultCountryISO } from "constants/string.constants";
import { useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { useDispatch } from "react-redux";
import { updateBankInfoAsync } from "store/modules/settings/settings.reducer";
import "./bank-account-dialog.component.scss";
import { env } from "env";
import { Region } from "constants/region.constants";
import { getUserInfo } from "services/auth.service";
import { EnumStoreRegion } from "constants/default.constants";
import { BANK_DATA } from "constants/bank.constants";

export const BankAccountDialogComponent = (props) => {
  const { handleCancel, handleSuccess, storeBankAccount, prepareAddressDataResponse, t, onChangeForm } = props;
  const dataBankInfo = BANK_DATA;
  const [formBankAccount] = Form.useForm();
  const [initDataAddress, setInitDataAddress] = useState(null);
  const [isDefaultBankCountry, setIsDefaultBankCountry] = useState(env.REACT_APP_REGION === Region.Local);
  const [cities, setCities] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [bankName, setBankName] = useState("");
  const [countryOption, setCountryOption] = useState([]);
  const dispatch = useDispatch();
  const loggedUserInfo = getUserInfo();
  const isInternationalRegion = loggedUserInfo?.storeType === EnumStoreRegion.INTERNATIONAL;

  useEffect(() => {
    setIsDefaultBankCountry(!isInternationalRegion);
  }, [isInternationalRegion]);

  useEffect(() => {
    initDataBankAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageData = {
    enter: t("form.enter"),
    storeName: t("registerAccount.storeName"),
    store: t("settings.tabStore"),
    updateSuccess: t("messages.isUpdatedSuccessfully"),
    province: t("form.province"),
    selectProvince: t("form.selectProvince"),
    validSelectProvince: t("form.validSelectProvince"),
    leaveForm: t("messages.leaveForm"),
    confirmation: t("leaveDialog.confirmation"),
    confirmLeave: t("button.confirmLeave"),
    country: t("form.country"),
    discard: t("button.discard"),
    titleBank: t("storeBankAccount.title"),
    accountHolder: t("storeBankAccount.accountHolder"),
    accountNumber: t("storeBankAccount.accountNumber"),
    bankName: t("storeBankAccount.bankName"),
    bankBranchName: t("storeBankAccount.bankBranchName"),
    swiftCode: t("storeBankAccount.swiftCode"),
    routingNumber: t("storeBankAccount.routingNumber"),
    swiftCodeMaxLength: 65,
    routingNumberMaxLength: 100,
    kitchenSetting: t("store.kitchenSetting"),
    storeHasKitchen: t("store.storeHasKitchen.title"),
    ifNotHaveKitchen: t("store.storeHasKitchen.tooltip.ifNotHaveKitchen"),
    orderNotHaveKitchen: t("store.storeHasKitchen.tooltip.orderNotHaveKitchen"),
    ifHasKitchen: t("store.storeHasKitchen.tooltip.ifHasKitchen"),
    orderHasKitchen: t("store.storeHasKitchen.tooltip.orderHasKitchen"),
    autoPrintStamp: t("store.autoPrintStamp.title"),
    operation: t("store.operation"),
    paymentFirst: t("store.paymentFirst"),
    paymentLater: t("store.paymentLater"),
    paymentType: t("store.paymentType"),
    tooltipPaymentFirst: t("store.tooltipPaymentFirst"),
    tooltipPaymentLater: t("store.tooltipPaymentLater"),
    inventoryTitle: t("table.inventory"),
    inventory: t("store.inventory"),
    tooltipInventory: t("store.tooltipInventory"),
    generalInformation: t("title.generalInformation"),

    inputAccountHolder: t("BranchBankTransfer.inputAccountHolder"),
    validAccountHolder: t("BranchBankTransfer.validAccountHolder"),
    selectBankName: t("BranchBankTransfer.selectBankName"),
    validBankName: t("BranchBankTransfer.validBankName"),
    inputBankName: t("BranchBankTransfer.inputBankName"),
    validInputBankName: t("BranchBankTransfer.validInputBankName"),
    allowNumberOnly: t("form.allowNumberOnly"),
    inputAccountNumber: t("BranchBankTransfer.inputAccountNumber"),
    validAccountNumber: t("BranchBankTransfer.validAccountNumber"),
    selectCountry: t("BranchBankTransfer.selectCountry"),
    validCountry: t("BranchBankTransfer.validCountry"),
    mustBeBetweenThreeAndOneHundredsCharacters: t("form.mustBeBetweenThreeAndOneHundredsCharacters"),

    button: {
      cancel: t("button.cancel"),
      saveChanges: t("button.saveChanges"),
    }
  };

  const onFinish = () => {
    onSaveBankAccount();
  };
  const onClose = () => {
    handleCancel();
  };

  const initDataBankAccount = () => {
    const { countries, cities, defaultCountry } = prepareAddressDataResponse;
    setInitDataAddress(prepareAddressDataResponse);
    countryOptionSelector(countries);
    setCities(cities);

    if (storeBankAccount) {
      formBankAccount.setFieldsValue({
        storeBankAccount: {
          ...storeBankAccount,
        },
      });
      if (storeBankAccount?.acpId) {
        setBankName(
          dataBankInfo?.find((bank) => bank?.bin === storeBankAccount?.acpId)
            ?.name
        );
      }
      onChangeCountryBank(storeBankAccount?.countryId);
    }
    if (isInternationalRegion) {
      setIsDefaultBankCountry(false);
    } else {
      storeBankAccount?.countryId === defaultCountry?.id
        ? setIsDefaultBankCountry(true)
        : setIsDefaultBankCountry(false);
    }
  };

  const onChangeCountryBank = (countryId) => {
    if (isInternationalRegion) {
      setIsDefaultBankCountry(false);
    } else {
      if (
        countryId === initDataAddress?.defaultCountry?.id &&
        DefaultCountryISO.vn === initDataAddress?.defaultCountry?.iso
      ) {
        setIsDefaultBankCountry(true);
      } else {
        setIsDefaultBankCountry(false);
      }
    }
  };

  const onResetBankName = () => {
    formBankAccount.setFieldValue(["storeBankAccount", "bankName"], "");
    setBankName("");
  };

  const onChangeFormDialog = () => {
    if (!isChangeForm) {
      onChangeForm(false);
      setIsChangeForm(true);
    }
  };

  const onSaveBankAccount = async () => {
    const formValues = formBankAccount.getFieldsValue();
    const { storeBankAccount } = formValues;
    const response = unwrapResult(await dispatch(updateBankInfoAsync({ ...storeBankAccount, bankName })));

    if (response) {
      message.success(`${pageData.titleBank} ${pageData.updateSuccess}`);
      handleSuccess();
    }
  };

  const onChangeBankName = (bin) => {
    if (!bin) return;
    setBankName(dataBankInfo.find((x) => x.bin === bin).name);
  };

  const onChangeInputBankName = (e) => {
    setBankName(e.target.value || "");
  };

  const countryOptionSelector = (countries) => {
    let result = countries?.map((country) => {
      return {
        label: (
          <Row className="col-country">
            <CircleFlag className="circle-icon" countryCode={country?.iso?.toLowerCase()} height={20} />
            <div className="country-name">{t(country?.nicename)}</div>
          </Row>
        ),
        value: country?.id,
        labelSearch: country?.nicename,
      };
    });
    setCountryOption(result);
  };

  const renderContent = () => {
    return (
      <Form form={formBankAccount} layout="vertical" autoComplete="off" onFinish={onFinish} className="custom-form">
        <Row span={24} gutter={[16, 28]}>
          <Col span={24} md={12} sm={24}>
            <Form.Item
              name={["storeBankAccount", "countryId"]}
              rules={[{ required: true }]}
              className="mb-0"
              label={pageData.country}
            >
              <FnbSelectSingleIcon
                size="large"
                showSearch
                autoComplete="none"
                onChange={(e) => {
                  onChangeCountryBank(e);
                  onResetBankName();
                  onChangeFormDialog();
                }}
                option={countryOption}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} sm={24}>
            {isDefaultBankCountry ? (
              <Form.Item
                name={["storeBankAccount", "acpId"]}
                rules={[
                  {
                    message: (
                      <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validBankName} />
                    ),
                    required: true,
                  },
                ]}
                className="mb-0"
                label={pageData.bankName}
              >
                <FnbSelectSingle
                  size="large"
                  showSearch
                  autoComplete="none"
                  onChange={onChangeBankName}
                  placeholder={`${pageData.enter} ${pageData.bankName}`}
                  option={dataBankInfo?.map((item, index) => ({
                    id: item.bin,
                    name: item.shortName + " - " + item.name,
                    key: index + item.id,
                  }))}
                />
              </Form.Item>
            ) : (
              <Form.Item
                name={["storeBankAccount", "bankName"]}
                rules={[
                  { required: true, message: <InputValidateMessage message={pageData.validInputBankName} /> },
                  { type: "string", warningOnly: true },
                  {
                    type: "string",
                    max: 100,
                    message: (
                      <InputValidateMessage
                        message={`${pageData.bankName} ${pageData.mustBeBetweenThreeAndOneHundredsCharacters}`}
                      />
                    ),
                  },
                ]}
                className="mb-0"
                label={pageData.bankName}
              >
                <FnbInput
                  showCount
                  maxLength={100}
                  className="fnb-input-with-count"
                  placeholder={pageData.inputBankName}
                  onChange={onChangeInputBankName}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={24} md={12} sm={24}>
            <Form.Item
              name={["storeBankAccount", "accountNumber"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.validAccountNumber} />
                  ),
                },
                {
                  pattern: /^\d+$/g,
                  max: 20,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.allowNumberOnly} />
                  ),
                },
              ]}
              label={pageData.accountNumber}
            >
              <Input
                className="fnb-input"
                placeholder={`${pageData.enter} ${pageData.accountNumber}`}
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12} sm={24}>
            <Form.Item
              name={["storeBankAccount", "accountHolder"]}
              label={pageData.accountHolder}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={`${pageData.validAccountHolder}`}
                    />
                  ),
                },
                { type: "string", warningOnly: true },
                {
                  type: "string",
                  max: 100,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={`${pageData.mustBeBetweenThreeAndOneHundredsCharacters}`}
                    />
                  ),
                },
              ]}
            >
              <Input
                className="fnb-input"
                placeholder={`${pageData.enter} ${pageData.accountHolder}`}
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            {isDefaultBankCountry ? (
              <Row span={24} gutter={16}>
                <Col span={24} md={12} sm={24}>
                  <Form.Item label={pageData.province} name={["storeBankAccount", "cityId"]}>
                    <FnbSelectSingle
                      size="large"
                      placeholder={pageData.selectProvince}
                      showSearch
                      autoComplete="none"
                      onChange={onChangeForm}
                      option={cities?.map((item, index) => ({
                        id: item.id,
                        name: item.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12} sm={24}>
                  <Form.Item label={pageData.bankBranchName} name={["storeBankAccount", "bankBranchName"]}>
                    <Input
                      className="fnb-input"
                      placeholder={`${pageData.enter} ${pageData.bankBranchName}`}
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row span={24} gutter={16}>
                <Col span={24} md={12} sm={24}>
                  <Form.Item name={["storeBankAccount", "swiftCode"]} label={pageData.swiftCode}>
                    <Input
                      className="fnb-input"
                      maxLength={pageData.swiftCodeMaxLength}
                      placeholder={`${pageData.enter} ${pageData.swiftCode}`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12} sm={24}>
                  <Form.Item
                    name={["storeBankAccount", "routingNumber"]}
                    rules={[
                      {
                        pattern: /^\d+$/g,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={`${pageData.accountHolder} ${pageData.allowNumberOnly}`}
                          />
                        ),
                      },
                    ]}
                    label={pageData.routingNumber}
                  >
                    <Input
                      className="fnb-input"
                      maxLength={pageData.routingNumberMaxLength}
                      placeholder={`${pageData.enter} ${pageData.routingNumber}`}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    );
  };
  return (
    <FnbModal
      className="bank-account-dialog-component"
      width={"100%"}
      title={pageData.titleBank}
      visible={props.isModalVisible}
      handleCancel={onClose}
      cancelText={pageData.button.ignore}
      okText={pageData.button.saveChanges}
      onOk={onFinish}
      closable={false}
      content={renderContent()}
    ></FnbModal>
  );
};