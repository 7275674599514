import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import "./fnb-tooltip.scss";
import classNames from "classnames";
import { getUniqueId } from "utils/helpers";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

/*
  Usage
  <FnbTooltip title={"hello world"} placement="left" variant='secondary'>
      Hello world
  </FnbTooltip>
  <FnbTooltip title={<div></div>}>
          <FnbButton />
  </FnbTooltip>
*/
export const FnbTooltipVariant = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

function FnbTooltip({
  title, // string | JSX.Element
  type = "tooltip", // tooltip | popover
  children,
  variant = FnbTooltipVariant.PRIMARY, // primary | secondary
  placement = "top", // top || left || right || bottom || topLeft || topRight || bottomLeft || bottomRight || leftTop || leftBottom || rightTop || rightBottom
  width = undefined, // 100px
  widthLabel = undefined,
  maxWidth = "fit-content", // 100px
  overlayClassName = "",
  onlyShowWhenEllipsis = false,
  maxWidthContent = undefined, //  100px | 100%
  hideTooltip = false,
  overlayStyle = undefined,
  disabledInnerPadding = false,
  disabledStopPropagation = false,
  zIndex,
  onClick = undefined,
  iconClassName = "",
  ...rest
}) {
  const [id] = useState(getUniqueId());
  const [isShowTooltip, setIsShowTooltip] = useState(!onlyShowWhenEllipsis);
  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  useEffect(() => {
    if (onlyShowWhenEllipsis) {
      const element = document.getElementById(id);
      const elementWidth = document.getElementById(id)?.scrollWidth || 0;

      if (element) {
        let parentWidth = element.parentElement.clientWidth;
        if (maxWidthContent && (parentWidth === 0 || parentWidth === elementWidth)) {
          parentWidth = parseInt(maxWidthContent?.toString()?.replace("px", "")?.replace("%", ""));
        }
        setIsShowTooltip(elementWidth > parentWidth);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyShowWhenEllipsis, children]);

  const onClickContent = (event) => {
    onClick && onClick(event);
  };

  const renderTitle = () => {
    let contentPopOver = isShowTooltip && !hideTooltip ? title ?? children : null;
    if (contentPopOver !== null) {
      if (typeof contentPopOver === "object") {
        //React.Element
        return React.cloneElement(contentPopOver, {
          ...contentPopOver?.props,
          onClick: (e) => {
            contentPopOver?.props?.onClick && contentPopOver?.props?.onClick(e);
            e.stopPropagation();
          },
        });
      } else if (typeof contentPopOver === "string" || typeof contentPopOver === "number") {
        return (
          <FnbTypography
            text={contentPopOver}
            color={variant === FnbTooltipVariant.PRIMARY ? theme.colors.default.white : theme.colors.primary[100]}
            variant={theme.typography["b2-regular"]}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        );
      }
    }

    return contentPopOver;
  };

  const handleClickTooltip = (event) => {
    if (isTouchDevice && isShowTooltip && !disabledStopPropagation) {
      event.stopPropagation();
    }
  };

  // region declare ClassName
  const overlayClassNames = classNames({
    "fnb-tooltip-overlay": true,
    "fnb-tooltip-overlay--disabled-inner-padding": disabledInnerPadding,
    [`fnb-tooltip-overlay--${variant}`]: true,
    [`${overlayClassName}`]: true,
  });

  const classNameWrapperChildren = classNames({
    "wrapper-children-fnb-tooltip": true,
    [`${iconClassName}`]: true,
  });

  const classNameWrapperChildrenContent = classNames({
    "wrapper-children-fnb-content": true,
  });

  return (
    <Tooltip
      title={renderTitle()}
      overlayClassName={overlayClassNames}
      placement={placement}
      overlayStyle={{ width: width ?? "fit-content", maxWidth: maxWidth, ...overlayStyle }}
      zIndex={zIndex}
      {...rest}
      onClick={handleClickTooltip}
    >
      {type === "popover" ? (
        children ?? title
      ) : (
        <div
          className={classNameWrapperChildren}
          style={{ maxWidth: maxWidthContent ?? "100%", width: widthLabel }}
          onClick={onClickContent}
        >
          <div className={classNameWrapperChildrenContent} id={id}>
            {children ?? title}
          </div>
        </div>
      )}
    </Tooltip>
  );
}

export default FnbTooltip;
