import { Col, Row } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import branchDataService from "../../../../../data-services/branch-data.services";
import { getStorage, localStorageKeys } from "../../../../../utils/localStorage.helpers";
import { ArrowDownIcon } from "../../../../assets/icons.constants";
import { ContactDirectionIcon } from "../../../../assets/icons/ContactDirectionIcon";
import { ContactLocationIconCustomize } from "../../../../assets/icons/ContactLocationIconCustomize";
import ContactUsImageDefault from "../../../../assets/images/contact-us-default.png";
import { GooglePlacesAutocompleteInput } from "../../../../components/fnb-google-map/google-map-address-input.component";
import { GoogleMapComponent } from "../../../../components/fnb-google-map/google-map.component";
import { backgroundTypeEnum, theme1ElementCustomize } from "../../../../constants/store-web-page.constants";
import "./styles.scss";

const ContactInformation = (props) => {
  const { isCustomize, config, clickToFocusCustomize, general } = props;
  const { information } = config;
  const colorGroup =
    general?.color?.colorGroups?.find((a) => a?.id === information?.colorGroupId) ?? general?.color?.colorGroups?.[0];

  const googleMapRef = useRef();
  const googleMapInputRef = useRef();
  const [t] = useTranslation();
  const jsonConfig = getStorage(localStorageKeys.STORE_CONFIG);
  const storeConfig = JSON.parse(jsonConfig);
  const storeId = storeConfig?.storeId;
  const [branchesInfo, setBranchesInfo] = useState([]);
  const [branchOpenStates, setBranchOpenStates] = useState({});
  const [selectedColIndex, setSelectedColIndex] = useState(null);
  const [description, setDescription] = useState("");

  function getBranchActiveInConfig() {
    return information?.isAllBranches
      ? branchesInfo
      : information?.branchIds?.length > 0
      ? branchesInfo?.filter((item) => information?.branchIds?.includes(item.id))
      : branchesInfo;
  }

  const COLOR = {
    WHITE: "#FFFFFF",
    PRIMARY: "#FF8C21",
    SECONDARY: "#1E282C",
  };

  const translateData = {
    contactUS: t("contact.contactUS", "CONTACT US"),
    contact: t("menu.contact", "Contact"),
    haveQuestion: t("contact.haveQuestion", "HAVE QUESTION?"),
    callUsOrVisitPlace: t("contact.callUsOrVisitPlace", "CALL US OR VISIT PLACE"),
    open: t("contact.open", "Open"),
    sendUSMessage: t("contact.sendUSMessage", "SEND US MESSAGE"),
    yourName: t("contact.yourName", "Your name"),
    enterYourPhoneNumber: t("contact.enterYourPhoneNumber", "Enter your phone number"),
    enterYourEmail: t("contact.enterYourEmail", "Enter your email"),
    subject: t("contact.subject", "Subject"),
    message: t("contact.message", "Message"),
    pleaseYourName: t("contact.pleaseYourName"),
    pleaseEnterYourPhoneNumber: t("contact.pleaseEnterYourPhoneNumber"),
    pleaseEnterYourEmail: t("contact.pleaseEnterYourEmail"),
    pleaseSubject: t("contact.pleaseSubject"),
    pleaseMessage: t("contact.pleaseMessage"),
    today: t("contact.today", "Today"),
    isOpen: t("contact.isOpen", "Opening"),
    isClosed: t("contact.isClosed", "Closed"),
    sendUsFeedback: t("contact.sendUsFeedback", "Send us feedback"),
    subTitleFeedback: t(
      "contact.subTitleFeedback",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas integer nunc mi, ac aliquet pretium platea. Nisl eu nulla nunc, risus eu, volutpat. Blandit in cursus purus nibh.",
    ),
  };

  async function getBranches() {
    try {
      const branches = await branchDataService.getBranchesInStoreAsync(storeId);
      if (branches) {
        const branchesByCustomerAddress = branches?.data?.storeBranches;
        setBranchesInfo(branchesByCustomerAddress);
        setDescription(branchesByCustomerAddress[0]?.fullAddress);
      }
    } catch (error) {}
  }

  function getBackgroundContact() {
    const contact = information;
    if (!contact) return {};
    let styles = {};
    if (contact?.backgroundType === backgroundTypeEnum.Color) {
      styles = {
        backgroundColor: contact?.backgroundColor,
      };
    } else {
      styles = {
        background: `url(${
          contact?.backgroundImage ? contact?.backgroundImage : ContactUsImageDefault
        }) no-repeat center center`,
        backgroundSize: "cover",
      };
    }
    return styles;
  }

  useEffect(() => {
    document.title = translateData.contact;
    getBranches();
  }, []);

  const toggleOpen = (index) => {
    setBranchOpenStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const StyledIcon = styled.span`
    position: relative;
    top: 4px;
    .arrow-down-icon {
      transform: rotate(180deg);
    }
    svg path {
      stroke: ${colorGroup?.buttonTextColor};
    }
  `;

  const onSelectLocation = (location) => {
    googleMapRef.current.setCenter(location?.center);
  };

  const isWithinOpenHours = (workingHours) => {
    const now = moment();
    return workingHours.some((workingHour) => {
      const openTime = moment(workingHour?.openTime, "HH:mm");
      const closeTime = moment(workingHour?.closeTime, "HH:mm");
      return now.isBetween(openTime, closeTime);
    });
  };

  return (
    <div style={getBackgroundContact()} className="contact-information">
      <Row
        className="contact-information-container"
        wrap
        onClick={() => clickToFocusCustomize && clickToFocusCustomize(theme1ElementCustomize.InformationContact)}
      >
        <div className="google-place-auto-completed-contact" style={{ opacity: 0, visibility: "hidden", height: 2 }}>
          {!isCustomize && (
            <GooglePlacesAutocompleteInput
              maxLength={100}
              initLocation={description}
              ref={googleMapInputRef}
              placeholder={translateData.address}
              onSelectLocation={(location) => {
                onSelectLocation(location);
              }}
              onEmptyLocation={(value) => {}}
              onErrorDataLocation={(isError) => {}}
            />
          )}
        </div>

        {!isCustomize && <GoogleMapComponent ref={googleMapRef} className="google-map-box" />}
        <div className="bottom-content">
          <div className="branch-info-wrapper">
            <Row className="branch-row" gutter={[36, 36]}>
              {getBranchActiveInConfig()?.map((branch, index) => {
                const isSelectedColIndex = selectedColIndex === index;
                const branchInfoClass = isSelectedColIndex ? "border-branch-info" : "";
                const primaryColor = isSelectedColIndex ? colorGroup?.titleColor : COLOR.WHITE;
                const secondaryColor = isSelectedColIndex ? colorGroup?.titleColor : COLOR.PRIMARY;

                return (
                  <Col
                    key={index}
                    span={12}
                    style={{ width: "100%" }}
                    xs={24}
                    sm={24}
                    md={12}
                    onClick={() => {
                      if (isCustomize) return;
                      setSelectedColIndex(index);
                      setDescription(branch?.fullAddress);
                    }}
                  >
                    <div className={`branch-info ${branchInfoClass}`}>
                      <div className="branch-title">
                        <div className="contact-location-icon">
                          <ContactLocationIconCustomize color={secondaryColor} />
                          <span className="branch-number">{index + 1}</span>
                        </div>
                        <div className="name" style={{ color: primaryColor }}>
                          {branch?.branchName}
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <ContactDirectionIcon color={primaryColor} />
                        </div>
                      </div>
                      <div className="branch-description">
                        <p className="branch-text">{branch?.fullAddress}</p>
                        <p className="branch-text mt-12 ">{branch?.phoneNumber}</p>
                        <p className="branch-text mt-12">{branch?.email}</p>
                        <p className="branch-text mt-12">
                          <span className="title-open">{translateData.open}</span>
                          <span
                            className="is-open"
                            onClick={() => {
                              if (isCustomize) return;
                              toggleOpen(index);
                            }}
                            style={{
                              backgroundColor: colorGroup?.buttonBackgroundColor,
                              color: colorGroup?.buttonTextColor,
                            }}
                          >
                            <>
                              {isWithinOpenHours(branch?.storeBranchWorkingHours)
                                ? translateData.isOpen
                                : translateData.isClosed}
                            </>
                            <StyledIcon isSelectedColIndex={isSelectedColIndex}>
                              {branchOpenStates[index] ? (
                                <ArrowDownIcon className="arrow-down-icon" />
                              ) : (
                                <ArrowDownIcon />
                              )}
                            </StyledIcon>
                          </span>
                          {branchOpenStates[index] && (
                            <div className="open-time">
                              {translateData.today}
                              {branch?.storeBranchWorkingHours?.map((workingHour) => (
                                <span className="working-hours" key={workingHour.id} style={{ color: secondaryColor }}>
                                  {workingHour.openTime} - {workingHour.closeTime}
                                </span>
                              ))}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ContactInformation;
