import { Card } from "antd";
import React from "react";
import "./fnb-card.component.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import FnbGroupButton from "components/fnb-button/fnb-group-button";

export default function FnbCard({
  title,
  current,
  className,
  onChange,
  children,
  underlineTitle = false,
  buttons = [],
  style,
  gap = 12,
}) {
  const renderChildren = () => {
    return React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        onChange,
        current,
        index,
      });
    });
  };

  return (
    <Card className={`fnb-card cart-with-no-border ${className}`}>
      {title && (
        <div className="d-flex w-100">
          <div className="fnb-card__space-title" style={{ style, gap: gap }}>
            <FnbTypography variant={theme.typography["h3-bold"]} color="#2b2162" text={title} />
            {underlineTitle && <div className="fnb-card__underline-title"></div>}
          </div>
          {buttons.length > 0 && <FnbGroupButton className="ml-auto" listButton={buttons} />}
        </div>
      )}

      {renderChildren()}
    </Card>
  );
}
