export const PurchaseOrderStatus = {
  /// <summary>
  /// Draft
  /// </summary>
  Canceled: 0,

  /// <summary>
  /// Order
  /// </summary>
  New: 1,

  /// <summary>
  /// In Progress
  /// </summary>
  Approved: 2,

  /// <summary>
  /// Completed
  /// </summary>
  Completed: 3,

  Ordering: 4,
};

export const PurchaseOrderAction = {
  /// <summary>
  /// Approve
  /// </summary>
  Approve: 0,

  /// <summary>
  /// Cancel
  /// </summary>
  Cancel: 1,

  /// <summary>
  /// Complete
  /// </summary>
  Complete: 2,

  Order: 3,
};
