import ChangeLanguage from "../change-language/change-language.component";
import "./ChangeLanguageDesktop.scss";

const ChangeLanguageDesktop = (props) => {
  const { isCustomize = false } = props
  return (
    <div className="change-language-desktop">
      <div className="header-language">
        <span className="header-rectangle"></span>
        <ChangeLanguage isCustomize={isCustomize} />
      </div>
    </div>
  );
};

export default ChangeLanguageDesktop;
