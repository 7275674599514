import { Image } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { images } from "constants/images.constants";
import parse from "html-react-parser";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import languageService from "services/language/language.service";
import theme from "theme";
import "./index.scss";

const gapTitleCard = 6;

export const Hr = (props) => {
  const {
    border = "1px solid #E1DBFF",
    width = "100%",
    margin = "24px 0",
    marginLeft = "auto",
    marginRight = "auto",
  } = props;
  return (
    <hr
      style={{
        border: border,
        width: width,
        margin: margin,
        marginLeft: marginLeft,
        marginRight: marginRight,
      }}
    />
  );
};

export const DialogHowtoSetupUnit = (props) => {
  const { isShowGuideConvertUnitDialog, handleHiddenGuideConvertUnitDialog } = props;
  const [t] = useTranslation();
  const pageData = {
    button: {
      close: t("button.close", "Close"),
    },
    dialogTitle: t("material.inventory.dialogSetupBaseUnit.title"),
    goFnBSystemAndUnit: {
      title: t("material.inventory.dialogSetupBaseUnit.goFnBSystemAndUnit.title"),
      paragraph: t("material.inventory.dialogSetupBaseUnit.goFnBSystemAndUnit.paragraph"),
    },
    howToSetupUOMForAnIngredient: {
      title: t("material.inventory.dialogSetupBaseUnit.howToSetupUOMForAnIngredient.title"),
      paragraph: t("material.inventory.dialogSetupBaseUnit.howToSetupUOMForAnIngredient.paragraph"),
    },
    howToSetupYourOwnUnits: {
      title: t("material.inventory.dialogSetupBaseUnit.howToSetupYourOwnUnits.title"),
      paragraph: t("material.inventory.dialogSetupBaseUnit.howToSetupYourOwnUnits.paragraph"),
    },
    limitationOfGoFnBSystem: {
      title: t("material.inventory.dialogSetupBaseUnit.limitationOfGoFnBSystem.title"),
      paragraph: t("material.inventory.dialogSetupBaseUnit.limitationOfGoFnBSystem.paragraph"),
    },
  };

  const styleImage = { border: `1px solid ${theme.colors.primary[100]}`, borderRadius: "12px" };

  const getFirstImageByLanguageCode = () => {
    switch (languageService.getLang()) {
      case "vi":
        return images.howToSetUpFirstVI;
      default:
        return images.howToSetUpFirstEN;
    }
  };

  const getSecondImageByLanguageCode = () => {
    switch (languageService.getLang()) {
      case "vi":
        return images.howToSetUpSecondVI;
      default:
        return images.howToSetUpSecondEN;
    }
  };

  const content = () => {
    return (
      <Fragment>
        <div className="guide__dv-bg guide__dv-bg--rear">
          <div className="guide__dv-bg guide__dv-bg--fore">
            <div className="guide__dv-sec">
              <FnbCard title={pageData.goFnBSystemAndUnit.title} underlineTitle gap={gapTitleCard}></FnbCard>
              {parse(pageData.goFnBSystemAndUnit.paragraph)}
            </div>
            <Hr />
            <div className="guide__dv-sec">
              <FnbCard title={pageData.howToSetupUOMForAnIngredient.title} underlineTitle gap={gapTitleCard}></FnbCard>
              {parse(pageData.howToSetupUOMForAnIngredient.paragraph)}
            </div>
            <Hr />
            <div className="guide__dv-sec">
              <FnbCard title={pageData.howToSetupYourOwnUnits.title} underlineTitle gap={gapTitleCard}></FnbCard>
              {parse(pageData.howToSetupYourOwnUnits.paragraph)}
              <div className="guide__dv-img">
                <Image style={styleImage} src={getFirstImageByLanguageCode()} />
              </div>
            </div>
            <Hr />
            <div className="guide__dv-sec">
              <FnbCard title={pageData.limitationOfGoFnBSystem.title} underlineTitle gap={gapTitleCard}></FnbCard>
              {parse(pageData.limitationOfGoFnBSystem.paragraph)}
              <div className="guide__dv-img">
                <Image style={styleImage} src={getSecondImageByLanguageCode()} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <FnbModal
      width={1200}
      className="guide"
      title={pageData.dialogTitle}
      visible={isShowGuideConvertUnitDialog}
      handleCancel={handleHiddenGuideConvertUnitDialog}
      content={content()}
      footer={
        <FnbButton text={pageData.button.close} onClick={handleHiddenGuideConvertUnitDialog} minWidth={"120px"} />
      }
      bodyStyle={{ height: `calc(100vh - 241px)` }}
    ></FnbModal>
  );
};
