import { Col, Row } from "antd";
import ComingSoonTag from "components/coming-soon-tag";
import { FnbButton } from "components/fnb-button/fnb-button";
import { AddCircleIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import theme from "theme";
import { hasPermission } from "utils/helpers";

const ListBuyXGetYPage = (props) => {
  const history = useHistory();
  const [t] = useTranslation();
  const pageData = {
    buyXGetYTitle: t("promotionCampaign.buyXGetY"),
    promotionCampaign: {
      linkAddNew: "/store/buy-x-get-y/create-new",
      createBuyXgetYCampaign: t("promotionCampaign.createBuyXgetYCampaign"),
      createCampaign: t("promotionCampaign.createCampaign"),
    },
  };

  const newBuyXGetY = () => {
    if (hasPermission(PermissionKeys.CREATE_BUY_X_GET_Y)) {
      history.push(pageData.promotionCampaign.linkAddNew);
    }
  };

  return (
    <Row style={{ padding: 16 }}>
      {/* Will implement this feature in version 3.5 */}

      {/* <FnbButton
        permission={PermissionKeys.CREATE_BUY_X_GET_Y}
        onClick={newBuyXGetY}
        text={pageData.promotionCampaign.createBuyXgetYCampaign}
        iconHeader={<AddCircleIcon fill={theme.colors.default.white} />}
        disabled
      /> */}

      <Col className="col-switch">
        <ComingSoonTag />
      </Col>
    </Row>
  );
};

export default ListBuyXGetYPage;
