import CashConfigImage from "../../assets/images/CashConfig.png";
import "./no-infomation.component.scss";
import { Image, Row, Col } from "antd";

export function NoInfomationComponent() {
  return (
    <>
    <Col span={24} className="no-infomation-wrapper">
      <Row className="component-body">
        <Image src={CashConfigImage} preview={false}></Image>
      </Row>
    </Col>
    </>
  );
}
