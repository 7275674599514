import { InfoCircleOutlined, PercentageOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Typography, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import Paragraph from "antd/lib/typography/Paragraph";
import ActionButtonGroup from "components/action-button-group/action-button-group.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import { FnbImageSelectComponent } from "components/fnb-image-select/fnb-image-select.component";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import { FnbTable } from "components/fnb-table/fnb-table";
import PageTitle from "components/page-title";
import { ComboType } from "constants/combo.constants";
import { DELAYED_TIME, MaximumNumber } from "constants/default.constants";
import { CalendarNewIconBold, DiscountIcon, TrashFill, UploadLogoIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { DateFormat, currency } from "constants/string.constants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  checkOnKeyPressValidation,
  combinationPossible,
  formatCurrency,
  formatNumberDecimalOrInteger,
  getCurrency,
  roundNumber,
} from "utils/helpers";
import { useMediaQuery } from "react-responsive";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import FnbSelectProduct from "components/fnb-select-product";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";

export default function EditCompoPage(props) {
  const fnbImageSelectRef = React.useRef();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { t, history, match, comboDataService, branchDataService, productDataService, productCategoryDataService } =
    props;

  const combos = {
    flexibleCombo: {
      name: t("combo.flexibleCombo"),
      value: 0,
    },
    specificCombo: {
      name: t("combo.specificCombo"),
      value: 1,
    },
  };

  const prices = {
    fixed: {
      name: t("combo.price.fixed"),
      value: 0,
    },
    specific: {
      name: t("combo.price.specific"),
      value: 1,
    },
  };

  const initProductGroup = {
    categoryId: null,
    quantity: 1,
    productIds: [],
  };

  const [selectedProductCategoryIds, setSelectedProductCategoryIds] = useState([]);
  const [form] = Form.useForm();
  const [comboId, setComboId] = useState("");
  const [blockNavigation, setBlockNavigation] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedComboType, setSelectedComboType] = useState(null);
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [allProductPriceOptions, setAllProductPriceOptions] = useState([]);
  const [productGroups, setProductGroups] = useState([initProductGroup]);
  const [sellingFixedPrice, setSellingFixedPrice] = useState(0);
  const [selectedSpecificProducts, setSelectedSpecificProducts] = useState([]);
  const [discountPercentAmountOfSpecificCombo, setDiscountPercentAmountOfSpecificCombo] = useState(0);
  const [totalOriginalPriceOfSpecificCombo, setTotalOriginalPriceOfSpecificCombo] = useState(0);
  const [selectedPriceType, setSelectedPriceType] = useState(prices.fixed.value);
  const [productCombos, setProductCombos] = useState([]);
  const [productCombosPrice, setProductCombosPrice] = useState([]); // calculateMaxPrice use
  const [selectedProductPriceIds, setSelectedProductPriceIds] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [disableAllBranches, setDisableAllBranches] = useState(false);
  const [allProductsWithCategory, setAllProductsWithCategory] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [maxPrice, setMaxPrice] = useState(MaximumNumber);
  const [comboParentName, setComboName] = useState(null);
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();

  const pageData = {
    title: t("combo.editCombo"),
    btnCancel: t("button.cancel"),
    btnUpdate: t("button.update"),
    btnAddNew: t("button.addNew"),
    selectDate: t("promotion.selectDate"),
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    generalInformation: {
      title: t("combo.generalInformation.title"),
      name: t("combo.generalInformation.name"),
      namePlaceholder: t("combo.generalInformation.namePlaceholder"),
      nameValidateMessage: t("combo.generalInformation.nameValidateMessage"),
      description: t("combo.generalInformation.description"),
      thumbnailValidateMessage: t("combo.generalInformation.thumbnailValidateMessage"),
      branch: t("combo.generalInformation.branch"),
      branchPlaceholder: t("combo.generalInformation.branchPlaceholder"),
      branchValidateMessage: t("combo.generalInformation.branchValidateMessage"),
      allBranches: t("combo.generalInformation.allBranches"),
      startDate: t("promotion.form.startDate"),
      PleaseStartDate: t("promotion.form.pleaseStartDate"),
      endDate: t("promotion.form.endDate"),
      PlaceholderDateTime: t("promotion.form.placeholderDateTime"),
      uploadImage: t("productManagement.generalInformation.uploadImages"),
    },
    product: {
      title: t("combo.product.title"),
      comboType: t("combo.comboType"),
      productPlaceholder: t("combo.product.productPlaceholder"),
      productValidateMessage: t("combo.product.productValidateMessage"),
      tooltipMessage: t("combo.product.tooltipMessage"),
      categoryValidateMessage: t("combo.product.categoryValidateMessage"),
      categoryPlaceholder: t("combo.product.categoryPlaceholder"),
      groups: t("combo.product.groups"),
      group: t("combo.product.group"),
      category: t("combo.product.category"),
      itemQuantity: t("combo.product.itemQuantity"),
      itemQuantityPlaceholder: t("combo.product.itemQuantityPlaceholder"),
      itemQuantityValidateMessage: t("combo.product.itemQuantityValidateMessage"),
      addGroup: t("combo.product.addGroup"),
    },
    price: {
      title: t("combo.price.title"),
      combo: t("combo.price.combo"),
      originalPrice: t("combo.price.originalPrice"),
      sellingPrice: t("combo.price.sellingPrice"),
      sellingPricePlaceholder: t("combo.price.sellingPricePlaceholder"),
      sellingPriceValidateMessage: t("combo.price.sellingPriceValidateMessage"),
      product: t("combo.productTitle"),
      comboNameTitle: t("combo.comboNameTitle"),
      comboItemNameRequiredMessage: t("combo.comboItemNameRequiredMessage"),
      comboItemNameMaxLengthMessage: t("combo.comboItemNameMaxLengthMessage"),
      comboItemNamePlaceholder: t("combo.comboItemNamePlaceholder"),
    },
    upload: {
      addFromUrl: t("material.addFromUrl"),
      uploadImage: t("material.addFile"),
    },
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    comboUpdatedSuccessfully: t("messages.comboUpdatedSuccessfully"),
    leaveWarningMessage: t("productManagement.leaveWarningMessage"),
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
  };

  const tableSettings = {
    columns: [
      {
        title: pageData.price.comboNameTitle,
        dataIndex: "comboName",
        width: "35%",
        render: (comboName, record) => {
          return (
            <Form.Item
              name={["productCombos", record?.index, "customName"]}
              rules={[
                {
                  required: true,
                  message: pageData.price.comboItemNameRequiredMessage,
                },
                {
                  type: "string",
                  max: 100,
                  message: pageData.price.comboItemNameMaxLengthMessage,
                },
              ]}
            >
              <Input
                className="fnb-input"
                placeholder={pageData.price.comboItemNamePlaceholder}
                maxLength={100}
                id="product-name"
                value={comboName}
              />
            </Form.Item>
          );
        },
      },
      {
        title: pageData.price.product,
        dataIndex: "comboName",
        width: "25%",
        render: (comboName) => {
          return (
            <Row align="middle">
              <Paragraph
                placement="top"
                ellipsis={{
                  row: 1,
                  tooltip: (
                    <Typography className={`combo-list-name-tooltip ${isMobile ? "is-tooltip-mobile" : ""}`}>
                      {comboName}
                    </Typography>
                  ),
                }}
                color="#50429B"
              >
                {comboName}
              </Paragraph>
            </Row>
          );
        },
      },
      {
        title: `${pageData.price.originalPrice} (${getCurrency()})`,
        dataIndex: "originalPrice",
        width: "20%",
        render: (value) => {
          return <span>{formatNumberDecimalOrInteger(value)}</span>;
        },
      },
      {
        title: `${pageData.price.sellingPrice} (${getCurrency()})`,
        dataIndex: "sellingPrice",
        width: "20%",
        render: (sellingPrice, record) => {
          const originalPrice = record?.originalPrice ?? 1;
          return (
            <>
              <Form.Item
                initialValue={formatNumberDecimalOrInteger(sellingPrice)}
                name={["productCombos", record?.index, "sellingPrice"]}
                rules={[
                  {
                    required: true,
                    message: pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(originalPrice),
                  },
                  {
                    validator: (rule, value, callback) =>
                      maxComboSellingPriceValidatorForSpecificPrice(rule, value, callback, originalPrice),
                  },
                ]}
              >
                <InputNumber
                  onChange={(value) => onChangeComboSellingPrice(value, record?.index)}
                  disabled={selectedPriceType === prices.fixed.value}
                  addonAfter={getCurrency()}
                  value={sellingPrice}
                  placeholder={pageData.product.quantityPlaceholder}
                  className="w-100 fnb-input-number"
                  min={0}
                  precision={getCurrency() === currency.vnd ? 0 : 2}
                  id={`productCombos_${record?.index}_sellingPrice`}
                  onKeyPress={(event) => {
                    const checkValidKey = checkOnKeyPressValidation(
                      event,
                      `productCombos_${record?.index}_sellingPrice`, // Id compiled from form item name followed antd format
                      0,
                      originalPrice,
                      getCurrency() === currency.vnd ? 0 : 2,
                    );

                    if (!checkValidKey) event.preventDefault();
                  }}
                />
              </Form.Item>

              <div className="float-right color-primary discount-products-wrapper">
                <Row>
                  <Col className="discount-icon-col">
                    <DiscountIcon />
                  </Col>
                  <Col className="discount-percent-col">
                    <span className="ml-2">{originalPrice === 0 ? 0 : roundNumber(((originalPrice - sellingPrice) / originalPrice) * 100, 1)}</span>
                    <PercentageOutlined />
                  </Col>
                </Row>
              </div>
            </>
          );
        },
      },
    ],
  };

  useEffect(() => {
    getBranches();
    getInitData();
  }, []);

  useEffect(() => {
    calculateMaxPrice();
  }, [productCombosPrice]);

  const getBranches = () => {
    branchDataService.getAllBranchsAsync().then((res) => {
      if (res && res?.branchs?.length > 0) {
        setBranches(res?.branchs);
        updateLastTimeGetBranches();
      }
    });
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const getInitData = async () => {
    let allProductPriceOptions = [];
    let cacheSelectedProductPrices = [];
    const allProductResult = await productDataService.getAllProductsActiveAsync();
    if (allProductResult) {
      setProducts(allProductResult.products);
      const productDataOptions = getProductDataOptions(allProductResult.products);
      setAllProductPriceOptions(productDataOptions);
      allProductPriceOptions = productDataOptions;
    }

    productDataService.getAllProductsWithCategoryAsync().then((res) => {
      if (res) {
        setAllProductsWithCategory(res.products);
      }
    });

    setComboId(match?.params?.comboId);
    const result = await comboDataService.getComboByIdAsync(match?.params?.comboId);
    if (result.isSuccess === true) {
      const { combo } = result;
      const { comboTypeId } = combo;
      const initData = {
        combo: {
          comboName: combo?.name,
          thumbnail: combo?.thumbnail,
          startDate: moment.utc(combo?.startDate).local(),
          endDate: combo?.endDate != null ? moment.utc(combo?.endDate).local() : null,
          description: combo?.description,
          isShowAllBranches: combo?.isShowAllBranches,
          comboTypeId: combo?.comboTypeId,
          //<-- EDIT PRICE
          comboPriceTypeId: combo?.comboPriceTypeId,
          sellingPrice: combo?.sellingPrice,
          //--> END EDIT PRICE
        },
        productCombos: [],
      };

      setDisableAllBranches(initData?.combo?.isShowAllBranches);
      //<-- EDIT PRICE
      combo.comboPricings.map((comboPricingItem) => {
        let item = {
          customName: comboPricingItem?.customName,
          sellingPrice: comboPricingItem?.sellingPrice,
        };
        initData.productCombos.push(item);
      });
      const comboPricings = mappingProductComboToDataTable(combo?.comboPricings);
      setProductCombos(comboPricings);
      calculateMaxPrice();

      /// Set edit SelectedProductPriceIds
      const { comboProductGroups } = combo;
      let editSelectedProductPriceIds = [];
      if (comboProductGroups.length > 0) {
        comboProductGroups.forEach((group, index) => {
          const { comboProductGroupProductPrices } = group;
          editSelectedProductPriceIds[index] = comboProductGroupProductPrices?.map((product) => {
            return product?.productPriceId;
          });
        });
      }

      setSelectedPriceType(combo.comboPriceTypeId);

      const listProductSelected = combo?.comboProductPrices?.map((item) => {
        return {
          productId: item?.productPrice?.productId,
          name: item?.productPrice?.product?.name,
          productPriceId: item?.productPriceId,
          price: item?.priceValue,
        };
      });
      const totalOriginalPrice = listProductSelected?.map((p) => p?.price)?.reduce((a, b) => a + b, 0);
      setSelectedSpecificProducts(listProductSelected);
      setTotalOriginalPriceOfSpecificCombo(totalOriginalPrice);
      updateTotalOriginalPriceOfSpecificCombo(combo?.sellingPrice, listProductSelected);
      //--> END EDIT PRICE

      let comboStoreBranches = combo.comboStoreBranches;
      let branchIds = [];
      branchIds = comboStoreBranches.map((branch) => branch.branchId);
      initData.combo.branchIds = branchIds;

      setSelectedComboType(combo?.comboTypeId);
      onChangeComboType(combo?.comboTypeId);

      if (comboTypeId === ComboType.Fixed) {
        let listComboProductGroup = getFormItemProductCategory(combo?.comboProductGroups);
        setProductGroups(listComboProductGroup);
        let productGroupIds = productCategories.filter((pc) =>
          listComboProductGroup.find((cpg) => cpg.productCategoryId !== pc.id),
        );

        setProductCategories(productGroupIds);

        /// Mapping listComboProductGroup to comboProductGroups
        const comboProductGroups = listComboProductGroup.map((group) => {
          const selectedProductPrices = allProductPriceOptions?.filter((p) =>
            group?.productPriceIds?.includes(p?.productPriceId),
          );

          cacheSelectedProductPrices = cacheSelectedProductPrices.concat(selectedProductPrices);
          return {
            ...group,
            productPriceIds: selectedProductPrices.map((p) => p?.productPriceId),
          };
        });

        initData.combo.productGroups = comboProductGroups;
      }

      if (comboTypeId === ComboType.Specific) {
        let productPrices = combo?.comboProductPrices;
        const productPriceIds = productPrices?.map((item) => item?.productPriceId);

        /// Mapping listComboProductGroup to comboProductGroups
        initData.combo.productPriceIds = productPriceIds;
        cacheSelectedProductPrices = cacheSelectedProductPrices.concat(productPriceIds);
      }

      if (fnbImageSelectRef && fnbImageSelectRef.current) {
        fnbImageSelectRef.current.setImage(initData?.combo?.thumbnail);
        setSelectedImage(initData?.combo?.thumbnail);
      }

      if (editSelectedProductPriceIds) {
        setSelectedProductPriceIds(editSelectedProductPriceIds);
      }

      form.setFieldsValue(initData);
      setStartDate(initData?.combo?.startDate);
      setProductCombosPrice(comboPricings);
      setComboName(combo?.name);
    }
  };

  const onChangeComboType = (value) => {
    if (value === combos.flexibleCombo.value) {
      getProductCategories();
    }
    getProducts();
    setSelectedComboType(value);
    calculateMaxPrice();
  };

  const getProducts = () => {
    productDataService.getAllProductsActiveAsync().then((res) => {
      if (res) {
        setProducts(res.products);
        const productDataOptions = getProductDataOptions(res.products);
        setAllProductPriceOptions(productDataOptions);
      }
    });

    productDataService.getAllProductsWithCategoryAsync().then((res) => {
      if (res) {
        setAllProductsWithCategory(res.products);
      }
    });
  };

  const getProductCategories = () => {
    productCategoryDataService.getAllProductCategoriesAsync().then((res) => {
      if (res) {
        setProductCategories(res.allProductCategories);
      }
    });
  };

  const getFormItemProductCategory = (comboProductGroups) => {
    let listComboProductGroup = [];
    comboProductGroups.map((item) => {
      let comboProductGroup = {
        id: item.id,
        productCategoryId: item.productCategoryId,
        quantity: item.quantity,
        productPriceIds: item.comboProductGroupProductPrices.map((i) => i.productPriceId),
      };
      listComboProductGroup.push(comboProductGroup);
    });
    return listComboProductGroup;
  };

  const getProductDataOptions = (products) => {
    let productOptions = [];
    products?.map((product) => {
      if (product?.productPrices.length > 0) {
        product?.productPrices.map((price) => {
          const text = price?.priceName ? `${product?.name} (${price?.priceName})` : product?.name;
          const option = {
            key: price?.id,
            productId: product?.id,
            productName: product?.name,
            text: text,
            productPriceId: price?.id,
            productPriceName: price?.name,
            productPrice: price?.priceValue,
            isSinglePrice: product?.productPrices.length <= 1,
            thumbnail: product?.thumbnail,
            unitName: product?.unit?.name,
            isTopping: product?.isTopping,
          };

          productOptions.push(option);
        });
      }
    });

    return productOptions;
  };

  const onFinish = async () => {
    form.validateFields().then((values) => {
      values.combo.comboId = comboId;
      if (disableAllBranches) {
        values.combo.branchIds = [];
      }
      if (values.combo.comboTypeId === combos.flexibleCombo.value) {
        values.combo.productPriceIds = [];
      } else {
        values.combo.productGroups = [];
      }
      //EDIT PRICE
      const comboPricings = productCombos?.map((combo, index) => {
        return {
          id: combo?.comboData?.[0]?.id,
          comboProductName: combo?.comboData?.map((p) => p.text)?.join(" | "),
          sellingPrice: combo?.sellingPrice,
          customName: values?.productCombos?.[index]?.customName,
          comboPricingProducts: combo?.comboData?.map((item) => {
            return {
              id: item?.idPricingProduct,
              productPriceId: item?.productPriceId,
              productPrice: item?.productPrice,
            };
          }),
        };
      });

      let requestModel = {
        ...values?.combo,
        startDate: startDate.format(DateFormat.MM_DD_YYYY),
        endDate: values.combo.endDate?.format(DateFormat.MM_DD_YYYY),
        comboPricings: comboPricings,
      };

      if (fnbImageSelectRef && fnbImageSelectRef.current) {
        const imageUrl = fnbImageSelectRef.current.getImage();
        if (imageUrl) {
          requestModel = {
            ...requestModel,
            thumbnail: imageUrl[0]?.data_url,
          };
        }
      }

      const { comboTypeId } = values?.combo;
      /// Mapping selected product price name to productPriceIds
      if (comboTypeId === ComboType.Specific) {
        const productPriceIds = allProductPriceOptions
          .filter((p) => requestModel.productPriceIds.includes(p?.productPriceId))
          .map((p) => p.productPriceId);

        requestModel = {
          ...requestModel,
          productPriceIds: productPriceIds,
          isShowAllBranches: disableAllBranches,
        };
      }

      /// Mapping selected product price name to productPriceIds
      if (comboTypeId === ComboType.Fixed) {
        let productGroups = requestModel?.productGroups?.map((group) => {
          const productPriceIds = allProductPriceOptions
            .filter((p) => group.productPriceIds.includes(p?.productPriceId))
            .map((p) => p.productPriceId);

          return {
            ...group,
            productPriceIds: productPriceIds,
          };
        });

        requestModel = {
          ...requestModel,
          productGroups: productGroups,
          isShowAllBranches: disableAllBranches,
        };
      }

      comboDataService.updateComboAsync({ combo: requestModel }).then((res) => {
        if (res) {
          setBlockNavigation(false);
          navigateToManagementPage();
          message.success(pageData.comboUpdatedSuccessfully);
        }
      });
    });
  };

  const onChangeProductCategory = (index) => {
    const formValues = form.getFieldsValue();
    const { combo } = formValues;
    const productCategoryIds = combo.productGroups?.map((group) => group.productCategoryId) ?? [];
    setSelectedProductCategoryIds(productCategoryIds);
    combo.productGroups?.map((productGroup, i) => {
      if (i === index) {
        productGroup.productPriceIds = [];
      }
    });
    form.setFieldsValue({ combo });
    calculateMaxPrice();
  };

  const onAddNewProductGroup = () => {
    const formValues = form.getFieldsValue();
    const { combo } = formValues;
    const newProductGroup = [...combo.productGroups, initProductGroup];
    setProductGroups(newProductGroup);
    combo.productGroups = newProductGroup;
    form.setFieldsValue(formValues);
    calculateMaxPrice();
  };

  const onRemoveProductGroup = (index) => {
    const formValues = form.getFieldsValue();
    const { combo } = formValues;
    const newProductGroup = combo.productGroups.filter((_, i) => i !== index);
    setProductGroups(newProductGroup);
    combo.productGroups = newProductGroup;
    form.setFieldsValue(formValues);

    /// Update selectedProductPriceIds
    selectedProductPriceIds[index] = [];
    refreshOptionsAndCalculateCombos(selectedProductPriceIds);
    calculateMaxPrice();
  };

  ///<-- EDIT PRICE
  const calculatingDiscountPercent = (sellingFixedPrice, totalOriginalPrice) => {
    if (totalOriginalPrice === 0) return 0;
    return ((totalOriginalPrice - sellingFixedPrice) / totalOriginalPrice) * 100;
  };

  const updateTotalOriginalPriceOfSpecificCombo = (sellingFixedPrice, listProductSelected) => {
    if (!listProductSelected) {
      listProductSelected = selectedSpecificProducts;
    }
    const totalOriginalPrice = listProductSelected?.map((p) => p.price)?.reduce((a, b) => a + b, 0);
    var discountPercentAmount = calculatingDiscountPercent(sellingFixedPrice, totalOriginalPrice);
    setDiscountPercentAmountOfSpecificCombo(discountPercentAmount);
  };

  const calculateMaxPrice = (value) => {
    let tempMinPrice = MaximumNumber;
    if (productCombos === undefined || productCombos === null) {
      setMaxPrice(MaximumNumber);
      return;
    }
    productCombos.forEach((c) => {
      const total = c.comboData.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.productPrice;
      }, 0);
      if (tempMinPrice > total) tempMinPrice = total;
    });
    setMaxPrice(tempMinPrice);
  };

  const renderFlexibleCombo = () => {
    return (
      <>
        <div className="groups">
          <h4 className="float-left combo-type-name">
            <span>{pageData.product.groups}</span>
            <FnbTooltip placement="topLeft" title={pageData.product.tooltipMessage}>
              <span className="ml-2 pointer">
                <InfoCircleOutlined />
              </span>
            </FnbTooltip>
          </h4>
          <FnbAddNewButton className="float-right" onClick={onAddNewProductGroup} text={pageData.product.addGroup} />
        </div>
        <div className="clearfix"></div>
        <div className="mt-3">{renderProductGroups()}</div>
      </>
    );
  };

  const renderProductGroups = () => {
    return productGroups?.map((_, index) => {
      return (
        <>
          {index >= 1 && <div className="group-clearfix"></div>}
          <div className="product-group-container">
            <div className="product-group-header">
              <Row>
                <Col span={20}>
                  <h4 className="product-group-name label-information">
                    {pageData.product.group} {productGroups.length > 1 && index + 1}
                  </h4>
                </Col>
                {index > 0 && (
                  <Col span={4}>
                    <div className="product-group-delete-action float-right">
                      <a onClick={() => onRemoveProductGroup(index)}>
                        <TrashFill className="icon-svg-hover" />
                      </a>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="product-group-body">
              <Row gutter={[24, 24]} className="mt-1">
                <Col xs={24} sm={24} md={24} lg={24} span={24}>
                  <Form.Item name={["combo", "productGroups", index, "id"]} className="d-none">
                    <Input type="hidden" />
                  </Form.Item>
                  <h4 className="fnb-form-label">
                    {pageData.product.category}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["combo", "productGroups", index, "productCategoryId"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.product.categoryValidateMessage,
                      },
                    ]}
                  >
                    <FnbSelect
                      getPopupContainer={(trigger) => trigger.parentNode}
                      placeholder={pageData.product.categoryPlaceholder}
                      onChange={(e) => onChangeProductCategory(index)}
                      options={productCategories?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} sm={0} md={0} lg={0} span={0} className="d-none">
                  <Form.Item
                    name={["combo", "productGroups", index, "quantity"]}
                    label={pageData.product.itemQuantity}
                    rules={[
                      {
                        required: false,
                        message: pageData.product.itemQuantityValidateMessage,
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={pageData.product.quantityPlaceholder}
                      className="w-100"
                      defaultValue={3}
                      min={1}
                      max={MaximumNumber}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} span={24}>
                  <h4 className="fnb-form-label">
                    {pageData.product.title}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["combo", "productGroups", index, "productPriceIds"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.product.productValidateMessage,
                      },
                    ]}
                    className="product-flexible-panel"
                  >
                    <FnbSelectProduct
                      options={renderProductFlexibleOptions(index)}
                      placeholder={pageData.product.productPlaceholder}
                      onChange={(value) => onChangeProduct(value, index)}
                      listHeight={500}
                      hiddenSelectAll
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </>
      );
    });
  };

  const renderSpecificCombo = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.product.title}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name={["combo", "productPriceIds"]}
          rules={[
            {
              required: true,
              message: pageData.product.productValidateMessage,
            },
          ]}
          className="product-flexible-panel"
        >
          <FnbSelectProduct
            options={renderProductSpecificOptions()}
            onChange={(_, option) => onSelectSpecificProducts(option)}
            placeholder={pageData.product.productPlaceholder}
            listHeight={500}
            hiddenSelectAll
          />
        </Form.Item>
      </>
    );
  };

  const renderProductSpecificOptions = () => {
    let allProducts = products;
    allProducts?.sort((a, b) => a?.name.localeCompare(b?.name));
    return allProducts;
  };

  const renderProductFlexibleOptions = (index) => {
    let allProducts = [];
    if (index !== null) {
      const formValues = form.getFieldsValue();
      const { combo } = formValues;
      if (combo?.productGroups !== undefined) {
        const productCategory = combo?.productGroups[index];
        const productsByCategory = allProductsWithCategory?.filter(
          (item) => item?.productCategoryId === productCategory?.productCategoryId,
        );
        if (productsByCategory?.length > 0) {
          allProducts = productsByCategory;
        }
      }
    }
    allProducts?.sort((a, b) => a?.name.localeCompare(b?.name));
    return allProducts;
  };

  const maxComboSellingPriceValidatorForSpecificItem = (rule, value, callback) => {
    if (value && value > totalOriginalPriceOfSpecificCombo) {
      const validateMessage =
        pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(totalOriginalPriceOfSpecificCombo);
      callback(validateMessage);
    }
    callback();
  };

  const maxComboSellingPriceValidatorForSpecificPrice = (rule, value, callback, originalPrice) => {
    if (value && value >= originalPrice) {
      const validateMessage = pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(originalPrice);
      callback(validateMessage);
    }
    callback();
  };

  const renderSellingPrice = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.price.sellingPrice}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name={["combo", "sellingPrice"]}
          rules={[
            {
              required: true,
              message: pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(maxPrice),
            },
          ]}
        >
          <InputNumber
            onChange={(value) => {
              onChangeSellingFixedPrice(value);
            }}
            addonAfter={getCurrency()}
            placeholder={pageData.price.sellingPricePlaceholder}
            className="w-100 fnb-input-number"
            min={getCurrency() === currency.vnd ? 1 : 0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            precision={getCurrency() === currency.vnd ? 0 : 2}
            id="combo-sellingPrice"
            onKeyPress={(event) => {
              const checkValidKey = checkOnKeyPressValidation(
                event,
                "combo-sellingPrice",
                0,
                maxPrice,
                getCurrency() === currency.vnd ? 0 : 2,
              );
              if (!checkValidKey) event.preventDefault();
            }}
          />
        </Form.Item>
      </>
    );
  };

  const onChangeComboSellingPrice = (value, index) => {
    productCombos[index].sellingPrice = value;
    setProductCombos([...productCombos]);
    calculateMaxPrice();

    const formValues = form.getFieldsValue();
    let formProductCombos = formValues.productCombos;
    formProductCombos[index].sellingPrice = value;
    form.setFieldsValue({
      productCombos: formProductCombos,
    });
  };

  const getDataTable = () => {
    const dataSource = [];
    productCombos.forEach((combo, index) => {
      const originalPrice = combo?.comboData?.map((p) => p.productPrice)?.reduce((a, b) => a + b, 0);
      let sellingPrice = combo.sellingPrice && combo.sellingPrice > 0 ? combo.sellingPrice : originalPrice;
      if (selectedPriceType === prices.fixed.value && sellingFixedPrice > 0) {
        sellingPrice = sellingFixedPrice;
      }

      const record = {
        index: index,
        productIds: combo?.comboData?.map((p) => p.productId),
        comboName: combo?.comboData?.map((p) => p.text)?.join(" | "),
        originalPrice: originalPrice,
        sellingPrice: sellingPrice,
      };
      dataSource.push(record);
    });

    return dataSource;
  };

  const mappingProductComboToDataTable = (comboPricings) => {
    return comboPricings?.map((item, index) => {
      const { comboPricingProducts, sellingPrice } = item;
      const comboData = comboPricingProducts?.map((c) => {
        const { productPrice, productPriceId } = c;
        let comboName = productPrice?.product?.name ?? "";
        if (productPrice?.priceName) {
          comboName = `${productPrice?.product?.name} ${productPrice?.priceName}`;
        }
        return {
          id: item.id,
          idPricingProduct: c.id,
          key: productPriceId,
          productId: productPrice?.productId,
          productName: productPrice?.product?.name,
          productPrice: productPrice?.priceValue,
          productPriceId: productPriceId,
          productPriceName: productPrice?.priceName,
          text: comboName,
        };
      });

      return {
        comboData: comboData,
        sellingPrice: sellingPrice,
        customName: item?.customName,
      };
    });
  };

  /// Selecting product and find product combos
  const onChangeProduct = async (value, index) => {
    /// update group combos by product price id
    selectedProductPriceIds[index] = value;
    refreshOptionsAndCalculateCombos(selectedProductPriceIds);
    await calculateMaxPrice();
    await form.validateFields();
  };

  const refreshOptionsAndCalculateCombos = (selectedProductPriceIds) => {
    setSelectedProductPriceIds(selectedProductPriceIds);

    /// Find combos
    calculateCombinationPossible(selectedProductPriceIds);
  };

  const calculateCombinationPossible = (selectedProductPriceIds) => {
    const combos = combinationPossible(selectedProductPriceIds);
    const formValue = form.getFieldsValue();
    const formProductCombos = [];
    if (combos.length > 0) {
      /// Mapping product options to product combos
      const productCombosData = combos.map((combo) => {
        const data = combo.map((productPriceId) => {
          const productOption = allProductPriceOptions.find((p) => p.key === productPriceId);
          if (productOption) {
            return productOption;
          }
        });
        const customName = `${comboParentName ? comboParentName + ": " : ""} ${data.map((a) => a.text).join(" | ")}`;
        const originalPrice = data?.map((p) => p?.productPrice)?.reduce((a, b) => a + b, 0);
        let sellingPrice =
          sellingFixedPrice == null
            ? 0
            : sellingFixedPrice && sellingFixedPrice > 0
            ? sellingFixedPrice
            : originalPrice;

        formProductCombos.push({
          customName: customName.length > 100 ? customName.slice(0, 100) : customName,
          sellingPrice: sellingPrice,
        });

        return {
          comboData: data,
          sellingPrice: sellingFixedPrice,
        };
      });

      form.setFieldsValue({
        ...formValue,
        productCombos: formProductCombos,
      });
      setProductCombos(productCombosData);
      setProductCombosPrice(productCombosData);
    }
  };

  const onSelectSpecificProducts = (option) => {
    const listProductSelected =
      option?.map((productPrice) => ({
        productId: productPrice?.productId,
        productPriceId: productPrice?.productPriceId,
        name: productPrice?.label,
        price: productPrice?.priceValue,
      })) ?? [];
    setSelectedSpecificProducts(listProductSelected);
    const totalOriginalPrice = listProductSelected?.map((p) => p.price)?.reduce((a, b) => a + b, 0);
    setTotalOriginalPriceOfSpecificCombo(totalOriginalPrice);
    updateTotalOriginalPriceOfSpecificCombo(sellingFixedPrice, listProductSelected);
    form.validateFields();
  };

  /// Render selling price field if product combo type is specific
  const renderSpecificComboSellingPrice = () => {
    return (
      <>
        <h4 className="fnb-form-label">
          {pageData.price.sellingPrice}
          <span className="text-danger">*</span>
        </h4>
        <Form.Item
          name={["combo", "sellingPrice"]}
          rules={[
            {
              required: true,
              message: pageData.price.sellingPriceValidateMessage + formatNumberDecimalOrInteger(totalOriginalPriceOfSpecificCombo),
            },
            { validator: maxComboSellingPriceValidatorForSpecificItem },
          ]}
        >
          <FnbInputNumber
            onChange={(value) => {
              setSellingFixedPrice(value);
              updateTotalOriginalPriceOfSpecificCombo(value);
            }}
            addonAfter={getCurrency()}
            placeholder={pageData.price.sellingPricePlaceholder}
            className="w-100"
            min={getCurrency() === currency.vnd ? 1 : 0}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            precision={getCurrency() === currency.vnd ? 0 : 2}
            id="combo-specificSellingPrice"
            onKeyPress={(event) => {
              const checkValidKey = checkOnKeyPressValidation(
                event,
                "combo-specificSellingPrice",
                0,
                null,
                getCurrency() === currency.vnd ? 0 : 2,
              );
              if (!checkValidKey) event.preventDefault();
            }}
          />
        </Form.Item>

        <Row gutter={[16, 16]} className="float-right flexible-combo-by-group-of-items">
          <Col className="flexible-combo-discount-icon">
            <b>
              <span>{pageData.price.originalPrice}</span>:
              <span className="ml-2">{formatCurrency(totalOriginalPriceOfSpecificCombo)}</span>
            </b>
          </Col>
          <Col className="color-primary ml-2 flexible-combo-discount-percent">
            <DiscountIcon />
            <span className="ml-2">{roundNumber(discountPercentAmountOfSpecificCombo, 1)}</span>
            <PercentageOutlined />
          </Col>
        </Row>
      </>
    );
  };

  const onSelectAllBranches = (event) => {
    const isChecked = event.target.checked;
    setDisableAllBranches(isChecked);
  };

  const renderProductComponent = () => {
    return (
      <>
        <h3 className="label-information mt-10">{pageData.product.title}</h3>
        <h4 className="combo-type-name mt-3">{pageData.product.comboType}</h4>
        <Form.Item name={["combo", "comboTypeId"]} className="select-product-radio-wrapper">
          <Radio.Group className="product-component-radio" onChange={(e) => onChangeComboType(e.target.value)}>
            <Radio value={combos.flexibleCombo.value}>
              <p>{combos.flexibleCombo.name}</p>
            </Radio>
            <Radio value={combos.specificCombo.value}>
              <p>{combos.specificCombo.name}</p>
            </Radio>
          </Radio.Group>
        </Form.Item>
        {selectedComboType === combos.specificCombo.value ? renderSpecificCombo() : renderFlexibleCombo()}
      </>
    );
  };

  const renderPriceComponent = () => {
    return (
      <>
        <h3 className="label-information mt-10">{pageData.price.title}</h3>
        {selectedComboType === combos.specificCombo.value ? (
          renderSpecificComboSellingPrice()
        ) : (
          <>
            <Form.Item name={["combo", "comboPriceTypeId"]}>
              <Radio.Group
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedPriceType(value);
                }}
              >
                <Radio value={prices.fixed.value}>
                  <p>{prices.fixed.name}</p>
                </Radio>
                <Radio value={prices.specific.value}>
                  <p>{prices.specific.name}</p>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {selectedPriceType === prices.fixed.value && renderSellingPrice()}
            <FnbTable
              className="table-product-combo-prices"
              columns={tableSettings.columns}
              dataSource={getDataTable()}
            />
          </>
        )}
      </>
    );
  };

  const onClickUploadImage = (file) => {
    setSelectedImage(file);
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().startOf("day");
  };

  const disabledDateByStartDate = (current) => {
    // Can not select days before today and today
    return current && current < startDate;
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      navigateToManagementPage();
    }
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const navigateToManagementPage = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/combo");
    }, DELAYED_TIME);
  };

  const onChangeComboName = (value) => {
    const formValue = form.getFieldsValue();
    if (formValue.productCombos) {
      formValue.productCombos.map((item) => {
        let customName = item.customName.split(":");
        item.customName = `${value ? value + ":" : ""} ${customName[customName.length - 1].trim()}`;
      });

      form.setFieldsValue({
        ...formValue,
      });
    }
    setComboName(value);
  };

  const onChangeSellingFixedPrice = (value) => {
    value = value;
    setSellingFixedPrice(value);
    const formValue = form.getFieldsValue();
    productCombos.forEach((combo) => {
      combo.sellingPrice = value;
    });
    formValue.productCombos.map((item) => {
      item.sellingPrice = value;
    });

    form.setFieldsValue({
      ...formValue,
    });

    setProductCombos([...productCombos]);
  };

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  return (
    <>
      <Row className="fnb-row-page-header">
        <Col xs={24} sm={24} lg={12}>
          <PageTitle content={pageData.title} />
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <ActionButtonGroup
            arrayButton={[
              {
                action: (
                  <FnbAddNewButton
                    onClick={(e) => onFinish(e)}
                    className="float-right"
                    type="primary"
                    text={pageData.btnUpdate}
                  />
                ),
                permission: PermissionKeys.EDIT_COMBO,
              },
              {
                action: (
                  <a onClick={() => onCancel()} className="action-cancel">
                    {pageData.btnCancel}
                  </a>
                ),
                permission: null,
              },
            ]}
          />
        </Col>
      </Row>
      <div className="clearfix"></div>
      <Form
        className="combo-form"
        form={form}
        layout="vertical"
        autoComplete="off"
        onFieldsChange={(e) => changeForm(e)}
      >
        <div className="w-100">
          <Content>
            <Card className="w-100 mb-4 fnb-card h-auto">
              <h3 className="label-information mt-10">{pageData.generalInformation.title}</h3>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={24} lg={17} span={17}>
                  <h4 className="fnb-form-label mt-32">
                    {pageData.generalInformation.name}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["combo", "comboName"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.generalInformation.nameValidateMessage,
                      },
                    ]}
                  >
                    <Input
                      className="fnb-input-with-count"
                      showCount
                      maxLength={100}
                      placeholder={pageData.generalInformation.namePlaceholder}
                      onChange={(e) => {
                        onChangeComboName(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Row gutter={[32, 16]}>
                    <Col xs={24} lg={12}>
                      <h4 className="fnb-form-label">{pageData.generalInformation.startDate}<span className="text-danger">*</span></h4>
                      <Form.Item
                        name={["combo", "startDate"]}
                        rules={[
                          {
                            required: true,
                            message: pageData.generalInformation.PleaseStartDate,
                          },
                        ]}
                      >
                        <DatePicker
                          suffixIcon={<CalendarNewIconBold />}
                          placeholder={pageData.selectDate}
                          className="fnb-date-picker w-100"
                          disabledDate={disabledDate}
                          format={DateFormat.DD_MM_YYYY}
                          onChange={(date) => setStartDate(date)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                      <h4 className="fnb-form-label">{pageData.generalInformation.endDate}</h4>
                      <Form.Item name={["combo", "endDate"]}>
                        <DatePicker
                          suffixIcon={<CalendarNewIconBold />}
                          placeholder={pageData.selectDate}
                          className="fnb-date-picker w-100"
                          disabledDate={disabledDateByStartDate}
                          format={DateFormat.DD_MM_YYYY}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <h4 className="fnb-form-label">{pageData.generalInformation.description}</h4>
                  <Form.Item className="fnb-form-description" name={["combo", "description"]}>
                    <TextArea
                      showCount
                      className="fnb-text-area-with-count no-resize combo-description-box"
                      placeholder={pageData.generalInformation.maximum1000Characters}
                      maxLength={1000}
                    />
                  </Form.Item>

                  <h4 className="fnb-form-label">{pageData.generalInformation.branch}<span className="text-danger">*</span></h4>
                  <div className="combo-check-box-select-all-branch">
                    <FnbCheckBox onChange={(event) => onSelectAllBranches(event)} checked={disableAllBranches}>
                      {pageData.generalInformation.allBranches}
                    </FnbCheckBox>
                  </div>
                  <Form.Item
                    hidden={disableAllBranches}
                    name={["combo", "branchIds"]}
                    rules={[
                      {
                        required: !disableAllBranches,
                        message: pageData.generalInformation.branchValidateMessage,
                      },
                    ]}
                  >
                    <FnbSelectAddNewItem
                      mode="multiple"
                      placeholder={pageData.generalInformation.branchPlaceholder}
                      options={branches?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      showSearch
                      allowClear
                      fieldItemName={pageData.generalInformation.branch?.toLowerCase()}
                      onAddNewItem={onAddNewBranch}
                    />
                  </Form.Item>
                  <Form.Item hidden={!disableAllBranches}>
                    <FnbSelectMultiple disabled={true}></FnbSelectMultiple>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={7} span={7} className="w-100 upload-product-image">
                  <h3 className="fnb-form-label mt-32">{pageData.media.title}</h3>
                  <Row className={`non-image ${selectedImage !== null && selectedImage !== undefined ? "have-image" : ""}`}>
                    <Col span={24} className={`image-product ${selectedImage !== null && selectedImage !== undefined ? "justify-left" : ""}`}>
                      <Form.Item name={["combo", "media"]}>
                        <FnbCustomUploadImageComponent
                          ref={fnbImageSelectRef}
                          iconUpload={<UploadLogoIcon />}
                          buttonText={pageData.generalInformation.uploadImage}
                          onChange={onClickUploadImage}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card className="fnb-card mb-4">
              <Row gutter={[16, 16]} className="mb-4">
                <Col span={24}>{renderProductComponent()}</Col>
              </Row>
            </Card>
            <Card className="fnb-card mb-4">
              <Row gutter={[16, 16]}>
                <Col span={24}>{renderPriceComponent()}</Col>
              </Row>
            </Card>
          </Content>
        </div>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.btnConfirmLeave}
        onCancel={onDiscard}
        onOk={navigateToManagementPage}
        isChangeForm={isChangeForm}
      />
    </>
  );
}
